import './PermissionDialog.css';
import switchImg from './PermissionDialogSwitch.png';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';

import Dialog from 'components/ui/Dialog';
import ContextEnhancer from 'components/ContextEnhancer';
import { FastField, Field, Formik } from 'formik';
import { BngForm } from 'components/bng/form/BngForm';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { BngField } from 'components/bng/form/BngField';
import BngSwitch from 'components/bng/form/BngSwitch';
import { ContentContainer } from 'components/bng/ui/ContentContainer';
import BngEmpty from 'components/bng/ui/BngEmpty';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngSelect } from 'components/bng/form/BngSelect';
import Icon from 'components/ui/common/Icon';
import { BngAvatar } from 'components/bng/ui/BngAvatar';
import { BngInput } from 'components/bng/form/BngInput';
import Api from 'components/Api';
import { BngError } from 'components/bng/ui/BngError';
import UiMsg from 'components/ui/UiMsg';
import { isFolder } from 'commonUtils';
import { MODALS } from 'components/ui/redux/Actions';
import UserGroupsDialog from 'components/bng/pages/admin/users/UserGroupsDialog';

const findPermissionsForPath = async (path) => {
    const {permission} = await Api.Project.findPermission(path);
    return {
        path: path,
        isPrivate: !permission.public,
        permissions: (permission.memberAccesses || []).reduce((acc, val) => {
            const type = val.member.hasOwnProperty('users') ? 'GROUP' : 'USER';
            acc[`${val.member.id}-${type}`] = {permission: val.write ? 'WRITE' : 'READ'};
            return acc;
        }, {}),
        replicateToChildren: false,
    };
};

export const PermissionDialog = ContextEnhancer(
    class PermissionDialog extends React.PureComponent {

        static propTypes = {
            className: PropTypes.string,
            open: PropTypes.bool,
            closeModal: PropTypes.func,
            projectId: PropTypes.number,
            findMembers: PropTypes.func,
            findPermissions: PropTypes.func,
            loading: PropTypes.bool,
            saveHandler: PropTypes.func,
            permissionOptionsFilter: PropTypes.func,
            afterContentSlot: PropTypes.func,
            lockCurrentUser: PropTypes.bool,
            hideCurrentUser: PropTypes.bool,
            showPrivateSwitch: PropTypes.bool,
            customPathComp: PropTypes.func,
            onClose: PropTypes.func,
            setSelectedRow: PropTypes.func,
            useParentPermission: PropTypes.bool,
        };

        static defaultProps = {
            className: '',
            findMembers: null,
            findPermissions: null,
            path: '',
            saveHandler() {
                this.props.closeModal()
            },
            permissionOptionsFilter(opts) {
                return opts;
            },
            afterContentSlot: _.noop,
            lockCurrentUser: true,
            hideCurrentUser: false,
            showPrivateSwitch: true,
            onClose: _.noop,
            setSelectedRow: _.noop,
            useParentPermission: false,
        };

        state = {
            members: [],
            userFilter: '',
            permissionFilter: '',
            loading: true,
            error: null,
        };

        initialValues = {
            path: this.props.path,
            isPrivate: true,
            replicateToChildren: false,
            permissions: {},
        };

        findProjectMembers = () => {
            if (this.props.findMembers) {
                return this.props.findMembers();
            }

            const path = this.props.useParentPermission ? this.props.path : undefined;
            return Api.Project.findProjectUsersAndGroups(this.props.projectId, path);
        };

        fetchUserGroups = async () => {
          try {
            let members = await this.findProjectMembers();
            members = [
              ...members.groups.map(g => ({ ...g, type: 'GROUP' })),
              ...members.projectUsers.map(pu => ({
                id: pu.user.id,
                name: pu.user.displayName,
                type: 'USER'
              }))
            ];

            if (this.props.findPermissions) {
              const formState = await this.props.findPermissions();
              const userId = `${this.props.context.user.id}-USER`;
              if (!formState.isPrivate && !formState.permissions.hasOwnProperty(userId)) {
                formState.permissions[userId] = { permission: 'WRITE' };
              }
              this._formik.resetForm({ values: formState });
            }

            this.setState({ members });
          } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
            this.setState({ error: true });
          } finally {
            this.setState({ loading: false });
          }
        }

        async componentDidMount() {
          await this.fetchUserGroups();
        }

        save = async (values, actions) => {
            try {
                if (values.isPrivate) {
                    values.replicateToChildren = false;
                }
                await this.props.saveHandler({values, actions});
                UiMsg.ok(this.props.context.msg.t('permissions.update.success'));
                this.props.closeModal();
                this.props.onClose();
                this.props.setSelectedRow(undefined);
            } catch (e) {
                console.error(e);
                UiMsg.ajaxError(this.props.context.msg.t('permissions.update.error'), e);
                actions.setSubmitting(false);
            }
        };

        getPermissionOptions = memoizeOne(() => {
            return this.props.permissionOptionsFilter(
                [{
                    value: 'NONE',
                    label: 'no.access'
                }, {
                    value: 'READ',
                    label: 'read.only'
                }, {
                    value: 'WRITE',
                    label: 'read.write'
                }]
                    .map(opt => ({
                        ...opt,
                        label: this.props.context.msg.t(opt.label)
                    }))
            );
        });

        filterAndSortMembers = memoizeOne((values, members, permissionFilter, userFilter) => {
            if (this.props.hideCurrentUser) {
                _.remove(members, row => row.id === this.props.context.user.id && row.type === 'USER');
            }

            members.forEach(m => m.permission = _.get(
                values,
                `permissions['${this.buildMemberKey(m)}'].permission`,
                'NONE'
            ));
            return _.orderBy(
                members
                    .filter(m => {
                        if (!_.isEmpty(permissionFilter)) {
                            if (m.permission !== permissionFilter) {
                                return false;
                            }
                        }
                        if (!_.isEmpty(userFilter)) {
                            let effectiveName = m.name;
                            if (m.type === 'GROUP') {
                                effectiveName = `${this.props.context.msg.t('group')} ${effectiveName}`;
                            }
                            effectiveName = effectiveName.toLowerCase();
                            if (!effectiveName.includes(userFilter.toLowerCase())) {
                                return false;
                            }
                        }
                        return true;
                    }),
                ['permission', 'name'],
                ['desc', 'asc']
            );
        });

        getColumnDefinitions = memoizeOne((values) => {
            let disableFn = () => false;
            if (this.props.lockCurrentUser) {
                disableFn = row => row.id === this.props.context.user.id && row.type === 'USER';
            }
            return [
              {
                label: `${this.props.context.msg.t('user')}/${this.props.context.msg.t('group')}`,
                render: (row) => {
                  const isGroup = row.type === 'GROUP';
                  return (
                    <div className="flex-center-items">
                      <BngAvatar
                        userId={row.id}
                        isGroup={isGroup}
                        className="d-r-m"
                      />
                      <span className="d-l-m inline-flex">
                        {isGroup && <strong className="d-r-m">{this.props.context.msg.t('group')}</strong>}
                        {row.name}
                        {isGroup && (
                          <Icon icon="visibility" className="eyeUserGroupsDialog"
                            onClick={this.openUserGroupsDialog({
                              groupId: row.id,
                              isEditing: true,
                              afterSubmit: this.fetchUserGroups,
                              showDeleteButton: false,
                            })}
                          />
                        )}
                      </span>
                    </div>
                  );
                },
                size: '70%'
              },
              {
                label: this.props.context.msg.t('permission'),
                render: (row, idx) => {
                  const name = `permissions['${this.buildMemberKey(row)}'].permission`;
                  const currentValue = _.get(values, name, 'NONE');
                  return (
                    <div
                      className="flex-center-items">
                      <Icon
                        icon={currentValue === 'NONE' ? 'lock' : 'lock_open'}
                        className="d-r-m"
                      />
                      <FastField
                        name={name}
                        component={BngSelect}
                        options={this.getPermissionOptions()}
                        className="m-none d-l-m"
                        emptyOption={false}
                        disabled={disableFn(row)}
                      />
                    </div>

                  );
                },
                size: '30%'
              }
            ];
        });

        buildMemberKey(member) {
            return `${member.id}-${member.type}`
        }

        openUserGroupsDialog(props) {
          return () => window.ReduxStore.dispatch(MODALS.open(UserGroupsDialog, props));
        }

        render() {
            return (
                <Formik initialValues={this.initialValues}
                        innerRef={ref => this._formik = ref}
                        onSubmit={this.save}>
                    {({values, setFieldValue, isSubmitting}) => {
                        const sortedMembers = this.filterAndSortMembers(
                            values,
                            this.state.members,
                            this.state.permissionFilter,
                            this.state.userFilter
                        );
                        const gotError = !!this.state.error;
                        const disableSubmit = gotError || (values.isPrivate && Object.values(values.permissions).filter(p => p.permission !== 'NONE').length === 0);
                        return (
                            <Dialog open={this.props.open}
                                    newDialogLayout={true}
                                    className={`PermissionDialog large ${this.props.className}`}
                                    title={this.props.context.msg.t('permission')}
                                    onClose={() => {
                                        this.props.setSelectedRow(undefined);
                                        this.props.closeModal();
                                    }}
                                    loading={this.props.loading || this.state.loading || isSubmitting}>
                                <BngForm>
                                    <Dialog.Body>


                                        <div className="flex-center-items">
                                            {this.props.customPathComp
                                                ? this.props.customPathComp()
                                                : (
                                                    <div className="flex-grow-1">
                                                        <BngField
                                                            name="path"
                                                            disabled={true}
                                                            inputComponent={BngInput}
                                                            value={values.caption || values.path}
                                                        />
                                                    </div>
                                                )
                                            }

                                            {this.props.showPrivateSwitch &&
                                                <div className={'ml-4'}>
                                                    <Field
                                                        name="isPrivate"
                                                        label={BngField.LABEL_EMPTY}
                                                        component={BngField}
                                                        disabled={gotError}
                                                        inputComponent={BngSwitch}
                                                        asProps={{
                                                            label: this.props.context.msg.t('private'),
                                                            verticalTitle: true,
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <ContentContainer className={`${values.isPrivate ? 'Public' : 'Private'}`}>
                                        <BngError hasError={gotError}>
                                          <BngEmpty img={switchImg}
                                                    isEmpty={!values.isPrivate}
                                                    showMessage={false}
                                                    title={this.props.context.msg.t('permission.dialog.public.message')}>
                                            <div className="Filter flex-center-items">
                                              <Icon icon="search" />
                                              <BngInput
                                                value={this.state.userFilter}
                                                onChange={e => this.setState({ userFilter: e.target.value })}
                                              />
                                              <BngSelect
                                                className="w-25"
                                                emptyOption={false}
                                                options={[
                                                  { value: '', label: this.props.context.msg.t('all') },
                                                  ...this.getPermissionOptions()
                                                ]}
                                                value={this.state.permissionFilter}
                                                onChange={e => this.setState({ permissionFilter: e.target.value })}
                                              />
                                            </div>
                                            <div>
                                              <div className="TableContainer">
                                                <BngTable className={'table-striped'}
                                                          rowKeyBuilder={row => this.buildMemberKey(row)}
                                                          rows={sortedMembers}
                                                          cols={this.getColumnDefinitions(values)} />
                                                <div className="createGroupButton" onClick={this.openUserGroupsDialog({
                                                  afterSubmit: this.fetchUserGroups
                                                })}>
                                                  <Icon icon="add_circle" />
                                                  <span>{this.props.context.msg.t('new.group')}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </BngEmpty>
                                        </BngError>
                                      </ContentContainer>

                                        {this.props.afterContentSlot(values)}

                                    </Dialog.Body>
                                    <Dialog.Footer>
                                        <DefaultDialogActions {...this.props} disabled={disableSubmit}/>
                                    </Dialog.Footer>
                                </BngForm>

                            </Dialog>
                        )
                    }}
                </Formik>
            );
        }
    }
);

const isGroup = member => member.hasOwnProperty('users');

export class OriginPermissionDialog extends React.PureComponent {

    static propTypes = {
        originId: PropTypes.number.isRequired,
        cubeName: PropTypes.string,
    };

    state = {};

    findPermissions = async () => {
        const {origin, permissions} = await Api.Origin.find(this.props.originId);
        const cubePermission = permissions.length === 1 ? permissions[0] : permissions.find(permission => permission.cube === this.props.cubeName);
        return {
            caption: origin.caption === cubePermission.cube ? origin.caption : `${origin.caption}: ${cubePermission.cube}`,
            path: cubePermission.cube,
            isPrivate: !cubePermission.readAccess.public,
            permissions: (cubePermission.readAccess.memberAccesses || []).reduce((acc, val) => {
                const type = isGroup(val.member) ? 'GROUP' : 'USER';
                acc[`${val.member.id}-${type}`] = {permission: 'WRITE'};
                return acc;
            }, {})
        };
    };

    saveHandler = async ({values, actions}) => {
        await Api.Origin.updatePermissions(this.props.originId, values);
    };


    render() {
        return (
            <PermissionDialog {...this.props}
                              findPermissions={this.findPermissions}
                              saveHandler={this.saveHandler}
                              permissionOptionsFilter={opts => {
                                  return opts.filter(opt => opt.value !== 'READ')
                              }}
            />
        );
    }

}

export const MultipleObjectsPermissionDialog = ({
                                                    objects = [],
                                                    afterSave = _.noop(),
                                                    pathMessage,
                                                    ...props
                                                }) => {

    const saveHandler = async ({values}) => {
        await Api.Project.updatePermission({
            ...values,
            objectPaths: objects.map(obj => obj.value)
        });
        await afterSave();
    }

    return (
        <PermissionDialog {...props}
                          path={objects[0].value}
                          findPermissions={() => findPermissionsForPath(objects[0].value)}
                          saveHandler={saveHandler}
                          customPathComp={() => (
                              <div className="SelectedObjectsWrapper">
                                  {objects.map(obj => (
                                      <div className="SelectedObject">
                                          <Icon icon={obj.icon}
                                                className="objectIcon"/>
                                          <span className="objectName">
                                            {obj.text}
                                          </span>
                                      </div>
                                  ))
                                  }
                              </div>
                          )}
        />
    );
}

export const PathPermissionDialog = ContextEnhancer(
    class PathPermissionDialog extends React.PureComponent {

        static propTypes = {
            path: PropTypes.string.isRequired,
            afterSave: PropTypes.func,
            useParentPermission: PropTypes.bool
        };

        static defaultProps = {
            afterSave: _.noop,
            useParentPermission: false,
        }

        state = {};

        saveHandler = async ({values, actions}) => {
            await Api.Project.updatePermission(values);
            await this.props.afterSave();
        };


        render() {
            return (
                <PermissionDialog {...this.props}
                                  findPermissions={() => findPermissionsForPath(this.props.path)}
                                  saveHandler={this.saveHandler}
                                  useParentPermission={this.props.useParentPermission}
                                  afterContentSlot={(values) => {
                                      if (!isFolder(this.props.path) || values.isPrivate) {
                                          return null;
                                      }
                                      return (
                                          <div className="ReplicateToChildrenContainer">
                                              <Field name="replicateToChildren"
                                                     label={this.props.context.msg.t('replicate.to.children')}
                                                     component={BngSwitch}
                                              />
                                          </div>
                                      );
                                  }}
                />
            );
        }

    }
);

export const CockpitPermissionDialog = ContextEnhancer(
    class CockpitPermissionDialog extends React.PureComponent {

        static propTypes = {
            cockpit: PropTypes.object.isRequired,
        };

        state = {};

        buildPermissions = (permissions) => {
            let builtPermissions = {};
            permissions.groups.forEach(group => {
                builtPermissions[`${group.id}-GROUP`] = {permission: 'WRITE'};
            });
            permissions.users.forEach(user => {
                builtPermissions[`${user.id}-USER`] = {permission: 'WRITE'};
            });
            return builtPermissions;
        };

        findPermissions = async () => {
            const permissions = await Api.Cockpit.getPermissions(this.props.cockpit.id);
            const builtPermissions = this.buildPermissions(permissions);
            return {
                caption: this.props.cockpit.name,
                isPrivate: !permissions.publicAccess,
                permissions: builtPermissions,
            };
        };

        saveHandler = async ({values, actions}) => {
            await Api.Cockpit.savePermissions(this.props.cockpit.id, values);
        };

        render() {
            return (
                <PermissionDialog {...this.props}
                                  findPermissions={this.findPermissions}
                                  lockCurrentUser={false}
                                  saveHandler={this.saveHandler}
                                  permissionOptionsFilter={opts => {
                                      return opts.filter(opt => opt.value !== 'READ')
                                  }}
                />
            );
        }

    }
);

export const PresentationPermissionDialog = ContextEnhancer(
    ({context, presentation, ...props}) => {
        const [projectId] = useState(context.findProjectIdFromContext())
        const [shareConfig, setShareConfig] = useState();

        const findPermissions = async () => {
            const sc = await Api.Presentation.findShareConfig(presentation.id);
            setShareConfig(sc);

            const processMembers = (members, type) => {
                return members.reduce((acc, el) => {
                    acc[`${el.memberId}-${type}`] = {permission: el.allowWrite ? 'WRITE' : 'READ'};
                    return acc;
                }, {})
            }

            return {
                path: presentation.name || '',
                isPrivate: true,
                permissions: {
                    ...processMembers(sc.shareUsers, 'USER'),
                    ...processMembers(sc.shareGroups, 'GROUP'),
                }
            };
        };

        const saveHandler = async ({values, actions}) => {
            const byType = {
                USER: [],
                GROUP: [],
            }

            for (const [key, value] of Object.entries(values.permissions)) {
                if (value.permission === 'NONE') continue;

                let [id, type] = key.split('-');
                id = _.parseInt(id);

                const currentSharedMembers = type === 'USER' ? shareConfig.shareUsers : shareConfig.shareGroups;
                const sharedMembers = byType[type];
                const allowWrite = value.permission === 'WRITE';
                const val = {
                    memberId: id,
                    allowWrite,
                }
                sharedMembers.push(val);
                const match = currentSharedMembers.find(m => m.memberId === id);

                if (match) {
                    val.id = match.id;
                }
            }

            await Api.Presentation.saveShareConfig(presentation.id, {
                ...shareConfig,
                shareUsers: byType.USER,
                shareGroups: byType.GROUP,
            });
            await Api.updateJsf();
        };


        return (
            <PermissionDialog {...props}
                              projectId={projectId}
                              findPermissions={findPermissions}
                              saveHandler={saveHandler}
                              showPrivateSwitch={false}
                              hideCurrentUser={true}
            />
        );
    }
);
