export const themes = [
    {
        value: 'default',
        label: 'Default',
        gridConfig: {
            borderOptions: 'none',
            borderColor: '#c9c9c9',
            borderWidth: 1,

            backgroundColor: '#eeeeee',
            stripped: true,
            strippedColor: '#EFF8EF',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#EFF8EF',
            headerFontColor: '#000000',
        },
        columnConfig: {
            textFormat: [],
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },
    {
        value: 'clean',
        label: 'Clean',
        gridConfig: {
            borderOptions: 'horizontal',
            borderColor: '#EAEAEA',
            borderWidth: 1,

            backgroundColor: '#FFFFFF',
            stripped: false,
            strippedColor: '#FFFFFF',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#EAEAEA',
            headerFontColor: '#000000',
        },
        columnConfig: {
            textFormat: [],
            fontSize: '12',
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },
    {
        value: 'green',
        label: 'Green',
        gridConfig: {
            borderOptions: 'all',
            borderColor: '#c9c9c9',
            borderWidth: 1,

            backgroundColor: '#FFFFFF',
            stripped: true,
            strippedColor: '#E8E8E8',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#269C59',
            headerFontColor: '#FFFFFF'
        },
        columnConfig: {
            textFormat: [],
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },
    {
        value: 'red',
        label: 'Red',
        gridConfig: {
            borderOptions: 'all',
            borderColor: '#c9c9c9',
            borderWidth: 1,

            backgroundColor: '#FFFFFF',
            stripped: true,
            strippedColor: '#E8E8E8',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#d32f2f',
            headerFontColor: '#FFFFFF'
        },
        columnConfig: {
            textFormat: [],
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },
    {
        value: 'blue',
        label: 'Blue',
        gridConfig: {
            borderOptions: 'all',
            borderColor: '#c9c9c9',
            borderWidth: 1,

            backgroundColor: '#FFFFFF',
            stripped: true,
            strippedColor: '#E8E8E8',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#2980B9',
            headerFontColor: '#FFFFFF'
        },
        columnConfig: {
            textFormat: [],
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },
    {
        value: 'dark',
        label: 'Dark',
        gridConfig: {
            borderOptions: 'none',
            borderColor: '#c9c9c9',
            borderWidth: 1,

            backgroundColor: '#FFFFFF',
            stripped: true,
            strippedColor: '#e8e8e8',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#404040',
            headerFontColor: '#FFFFFF'
        },
        columnConfig: {
            textFormat: [],
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },
    {
        value: 'silver',
        label: 'Silver',
        gridConfig: {
            borderOptions: 'all',
            borderColor: '#c9c9c9',
            borderWidth: 1,

            backgroundColor: '#f8f8f8 linear-gradient(to bottom, #ffffff, #ededed) repeat-x scroll 0 0',
            stripped: true,
            strippedColor: '#FFFFFF',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#F9F9F9',
            headerFontColor: '#000000'
        },
        columnConfig: {
            textFormat: [],
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },
    {
        value: 'simple-line',
        label: 'Simple Line',
        gridConfig: {
            borderOptions: 'none',
            borderColor: '#FFFFFF',
            borderWidth: 1,

            backgroundColor: '#FFFFFF',
            stripped: true,
            strippedColor: '#F2F2F2',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '12',
            headerBackgroundColor: '#E8E8E8',
            headerFontColor: '#7B7B7B',

            selectedBackgroundColor: '#7B7B7B',

            pagination: 'auto'
        },
        columnConfig: {
            textFormat: [],
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },
    {
        value: 'modern-1',
        label: 'Modern 1',
        gridConfig: {
            borderOptions: 'all',
            borderColor: 'rgba(0, 0, 0, .3)',
            borderWidth: 1,

            backgroundColor: '#777777',
            stripped: true,
            strippedColor: '#666666',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#333333',
            headerFontColor: '#FFFFFF',

            selectedBackgroundColor: '#1E498C',
            selectedTextColor: '#FFFFFF',
        },
        columnConfig: {
            textFormat: ['bold'],
            textColorType: 'automatic',
            textColor: '#FFFFFF',
            cellColorType: 'none',
        }
    },
    {
        value: 'modern-2',
        label: 'Modern 2',
        gridConfig: {
            borderOptions: 'all',
            borderColor: 'rgba(0, 0, 0, .3)',
            borderWidth: 1,

            backgroundColor: '#FFFFFF',
            stripped: true,
            strippedColor: '#F4F4F4',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#666666',
            headerFontColor: '#FFFFFF',

            selectedBackgroundColor: '#B44624',
            selectedTextColor: '#FFFFFF',
        },
        columnConfig: {
            textFormat: ['bold'],
            textColorType: 'automatic',
            textColor: '#000000',
            cellColorType: 'none',
        }
    },

    {
        value: 'modern-3',
        label: 'Modern 3',
        gridConfig: {
            borderOptions: 'all',
            borderColor: '#000000',
            borderWidth: 1,

            backgroundColor: '#323c50',
            stripped: true,
            strippedColor: '#2c3446',

            showHeader: true,
            headerHeight: '20',
            headerFontSize: '14',
            headerBackgroundColor: '#000000',
            headerFontColor: '#FFFFFF'
        },
        columnConfig: {
            textFormat: ['bold'],
            textColorType: 'automatic',
            textColor: '#cccccc',
            cellColorType: 'none',
        }
    },

];