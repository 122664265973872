import './ContentContainer.css';
import React from 'react';
import PropTypes from 'prop-types';

export const ContentContainer = React.forwardRef(({className = '', children, ...props}, ref) => {
    return (
        <div className={`ContentContainer ${className}`}
             ref={ref}
             {...props}>
            {children}
        </div>
    );
});

ContentContainer.propTypes = {
    className: PropTypes.string
};