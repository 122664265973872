//import "./CockpitTabs.css";

import React from 'react';
import PropTypes from "prop-types";

import Api from "components/Api";
import Icon from "components/ui/common/Icon";
import Utils from "components/Utils";

const onSelectPanel = (event, panel, cockpitId) => {
    event.preventDefault();
    !!window.__CANCEL_RENDER_DASHBOARDITEM_REQUEST ? window.__CANCEL_RENDER_DASHBOARDITEM_REQUEST.cancel() : null;
    Api.executeExp(`#{cockpitViewMB.renderPanelId(${panel.id})}`);
    Utils.History.updateQuery({
        cockpitId,
        panelId: panel.id
    });
};

export default function BngCockpitTabs({panels, enablePainelColors, currentPanelId, onSelect}) {
    return (
        <div id="cockpit-header-tabs"
             className="scroll-wrapper cockpit-header-panel-ul-container with-index on-publisher"
             style={{position: 'relative'}}>
            <div className="cockpit-header-panel-ul-container with-index scroll-content scroll-scrollx_visible"
                 style={{height: 'auto', marginBottom: '0px', marginRight: '0px', maxHeight: '37px'}}>
                <div className="icePnlGrp cockpit-header-panel">
                    <ul className="cockpit-header-panel-ul">
                        {panels.map(panel => {
                            const activePanel = currentPanelId === panel.id;
                            const cockpitColor = enablePainelColors && !activePanel ? `${panel.color}dd` : '';
                            return (
                                <li key={panel.id} onClick={e => {
                                    if (onSelect) {
                                        e.preventDefault();
                                        onSelect(panel);
                                    } else {
                                        onSelectPanel(e, panel)
                                    }
                                }}
                                    className={activePanel ? 'active' : ''}>
                                    <div className="cpt-btn-item" title={panel.name}>
                                        <a href={`#panel-${panel.id}`} className="iceCmdLnk">
                                            <Icon className={'icon-cockpit-item'} icon={panel.icon}/>
                                            <nobr>{panel.name}</nobr>
                                        </a>
                                    </div>
                                    <div className="cpt-btn-item-bar-marker"
                                         style={{backgroundColor: cockpitColor}}></div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

BngCockpitTabs.propTypes = {
    cockpit: PropTypes.object,
    currentPanel: PropTypes.object,
    onSelect: PropTypes.func,
};