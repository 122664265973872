// BngMaskedField.css imported on CommonCssImports.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export const sanitizeNumber = (value) => {
  const _value = value;
  if (typeof _value === 'number') return _value;
  return (
    Number(
      _value
        .replace(/\./g, '')
        .replace(/,/g, '.')
        .replace(/[^0-9 .]/g, '')
    ) || 0
  );
};

export const sanitizePercent = (value, percent = 1) => {
  return Number((sanitizeNumber(value) * percent).toPrecision(6));
};

const defaultSeparators = {
  thousandSeparator: '.',
  decimalSeparator: ',',
};

export const Mask = {
  PHONE: { format: '+ ## (##) #####-####', placeholder: '+99 (99) 99999-9999' },
  FIX_PHONE: { format: '+ ## (##) ####-####', placeholder: '+99 (99) 9999-9999' },
  DATE: { format: '##/##/####', placeholder: '01/01/1901', mask: '_' },
  DATE_MONTH_YEAR: { format: '##/####', placeholder: '01/1901', mask: '_' },
  DATE_YEAR: { format: '####', placeholder: '1901', mask: '_' },
  HOUR: { format: '##:##:##', placeholder: '00:00:00' },
  MEASURE_TIME: { format: '##:##', placeholder: '00:00' },
  MEASURE_PERCENT: {
    suffix: '%',
    placeholder: '0%',
    inputMode: 'numeric',
    thousandSeparator: false,
    decimalSeparator: ',',
  },
  MEASURE_MONEY: { prefix: 'R$ ', placeholder: 'R$ 0.00', ...defaultSeparators },
  MEASURE_MONEY_DOLLAR: { prefix: '$ ', placeholder: '$ 0.00', ...defaultSeparators },
  MEASURE_MONEY_EURO: { prefix: 'EUR ', placeholder: 'EUR 0.00', ...defaultSeparators },
  MEASURE_FIXED: { placeholder: '0.00', ...defaultSeparators },
  INTEGER: { placeholder: '0', decimalScale: 0, ...defaultSeparators },
  DOUBLE: { placeholder: '0,00', ...defaultSeparators },
};

export const BngMaskedField = ({
  className = '',
  type = 'text',
  mask = {},
  placeholder = '',
  field,
  form,
  asNumber = false,
  asDate,
  onValueChange = _.noop,
  maxLength = 32,
  ...props
}) => {
  const [inputIsValid, setInputIsValid] = useState();
  if (asDate) {
    onValueChange = ({ value, formattedValue }) => {
      const formattedDate = value.replace(/_/g, '');
      const isBrFormat = asDate === 'br';
      const dateComponents = isBrFormat
        ? formattedDate.match(/(\d{2})(\d{2})(\d{4})/)
        : formattedDate.match(/(\d{4})(\d{2})(\d{2})/);
      if (dateComponents) {
        const formattedValue = isBrFormat
          ? `${dateComponents[1]}/${dateComponents[2]}/${dateComponents[3]}`
          : `${dateComponents[1]}-${dateComponents[2]}-${dateComponents[3]}`;
        form.setFieldValue(field.name, formattedValue);
      } else {
        form.setFieldValue(field.name, formattedValue);
      }
    };
  }

  if (asNumber) {
    onValueChange = ({ floatValue }) => form.setFieldValue(field.name, floatValue);
    field = { ...field };
    delete field.onChange;
  }

  return (
    <NumberFormat
      className={`BngMaskedField BngInput fill-w ${className} ${inputIsValid === false ? 'invalidValue' : ''}`}
      type={type}
      {...mask}
      {...field}
      onValueChange={onValueChange}
      maxLength={maxLength}
      {...props}
      onChange={
        props.onChange
          ? (e) => {
              const valid = props.onChange(e);
              setInputIsValid(valid ?? true);
            }
          : undefined
      }
    />
  );
};

BngMaskedField.propTypes = {
  name: PropTypes.any,
  mask: PropTypes.any,
  placeholder: PropTypes.string,
};

export const BngPhoneField = ({ ...props }) => {
  return <BngMaskedField mask={Mask.PHONE} {...props} />;
};

export const BngDateField = ({ ...props }) => {
  return <BngMaskedField mask={Mask.DATE} {...props} />;
};

export const BngIntegerField = ({ ...props }) => {
  return <BngMaskedField mask={Mask.INTEGER} {...props} />;
};

export const BngDoubleField = ({ ...props }) => {
  return <BngMaskedField mask={Mask.DOUBLE} {...props} />;
};

export const BngHourField = ({ ...props }) => {
  return <BngMaskedField mask={Mask.HOUR} {...props} />;
};
