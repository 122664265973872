import './MenuLink.css';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BetaLabel from 'components/BetaLabel';
import Icon from 'components/ui/common/Icon';
import LoadingSvg from 'components/ui/loading/LoadingSvg';

const stopEvent = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const MenuLink = ({
  link = '#',
  label = 'LABEL HERE',
  icon = '',
  className = '',
  target = '',
  beta = false,
  onClick = undefined,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const clickWrapper = onClick
    ? async (event) => {
        if (loading) return;

        try {
          setLoading(true);
          await onClick(event);
        } finally {
          setLoading(false);
        }
      }
    : undefined;

  return (
    <li className={`MenuLink menu_divider ${className} ${loading ? 'Loading' : ''}`}>
      <div className="clearfix">
        <a href={link} className="menu_sttings" onClick={clickWrapper} target={target} {...props}>
          <Icon icon={icon} />
          {label}
          {beta && <BetaLabel />}
          {loading && (
            <>
              <LoadingSvg className={beta ? 'withBeta' : ''} />
              <div className="Overlay" onClick={stopEvent} />
            </>
          )}
        </a>
      </div>
    </li>
  );
};

MenuLink.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  beta: PropTypes.bool,
};

export default MenuLink;
