import React from 'react';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import useTranslation from 'components/hooks/useTranslation';
import KeyRecyclingHierarchyTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/KeyRecyclingHierarchyTab';

export default function BimQueryKeyRecyclingHierarchyMenuItem({ toggleAccordion = _.noop, ...props }) {
  const { t } = useTranslation();
  return (
    <RightMenuItem
      title={t('data.standards.config.step.title')}
      icon="settings"
      className={`BimQueryKeyRecyclingHierarchyMenuItem`}
      onClick={async () => {
        return toggleAccordion(KeyRecyclingHierarchyTab, BimQueryKeyRecyclingHierarchyMenuItem.KEY);
      }}
      {...props}
    />
  );
}

BimQueryKeyRecyclingHierarchyMenuItem.KEY = 'BimQueryKeyRecyclingHierarchyMenuItem';
