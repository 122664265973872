import styles from './BngTfaCodeInput.module.css';

export default function BngTfaCodeInput({ form, field, size: codeSize = 6 }) {
  const value = _.padEnd(field.value ?? '', codeSize).split('');

  const updateFieldValue = () => {
    form.setFieldValue(field.name, value.join(''));
  };

  return (
    <div className={`BngTfaCodeInput ${styles.main}`}>
      {_.range(codeSize).map((inputIdx) => {
        const onKeyDown = async (e) => {
          const input = e.target;
          const key = e.key || e.target.value;
          const nextInput = input.nextElementSibling;
          const previousInput = input.previousElementSibling;

          if ((key === 'v' || key === 'V') && (e.ctrlKey || e.metaKey)) {
            try {
              const clipboardText = await navigator.clipboard.readText();
              if (clipboardText.length >= codeSize) {
                for (let i = 0; i < codeSize; i++) {
                  value[i] = clipboardText.charAt(i);
                }
                updateFieldValue();
                let lastInput = input;
                for (let i = 0; i < codeSize; i++) {
                  if (lastInput.nextElementSibling) {
                    lastInput = lastInput.nextElementSibling;
                  }
                }
                lastInput?.focus();
              }
            } catch (e) {
              console.error('Error while reading clipboard text', e);
            }
            return false;
          }

          if (key === 'Backspace') {
            if (previousInput && value[inputIdx] === ' ') {
              previousInput.focus();
            } else if (value[inputIdx] !== ' ') {
              value[inputIdx] = ' ';
              updateFieldValue();
            }
          } else if (key.match(/^[0-9a-zA-Z]$/)) {
            value[inputIdx] = key;
            updateFieldValue();
            if (nextInput) {
              nextInput.focus();
              e.preventDefault();
            }
          } else if (key === 'ArrowLeft') {
            if (previousInput) {
              previousInput.focus();
            }
          } else if (key === 'ArrowRight') {
            if (nextInput) {
              nextInput.focus();
            }
          } else if (key === 'Delete') {
            if (nextInput && value[inputIdx + 1] !== ' ') {
              nextInput.focus();
            } else if (value[inputIdx] !== ' ') {
              value[inputIdx] = ' ';
              updateFieldValue();
            }
          } else if (key === 'Enter') {
            for (let i = 0; i < codeSize; i++) {
              if (value[i] === ' ') {
                event.preventDefault();
                return false;
              }
            }
          } else {
            e.preventDefault();
          }
        };
        return (
          <input
            key={`BngTfaCodeInput[${inputIdx}]`}
            type="text"
            maxLength="1"
            value={value[inputIdx].trim()}
            onChange={_.noop}
            onInput={onKeyDown}
            onKeyDown={onKeyDown}
          />
        );
      })}
    </div>
  );
}
