import styles from './BngExportPeriodicityTab.module.css';

import React, { useMemo } from 'react';
import { FastField, Field, useFormikContext } from 'formik';

import useBimContext from 'components/hooks/useBimContext';
import BngButtonGroup from 'components/bng/ui/Buttons/BngButtonGroup';
import BngSelect from 'components/bng/form/BngSelect';
import BngField from 'components/bng/form/BngField';
import bngYup from 'components/bng/form/yup/BngYup';
import Utils from 'components/Utils';
import BngTimePicker from 'components/bng/form/BngTimePicker';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';

const Type = {
  Daily: 'Daily',
  Weekly: 'Weekly',
  Monthly: 'Monthly',
  Interval: 'Interval',
};

export const BngExportPeriodicityTabSchema = bngYup((yup) => {
  return yup.object({
    identifier: yup.string().when('schedulingType', {
      is: Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED,
      then: yup.string().trim().required('Share.errors.identifier').default(''),
    }),
    type: yup
      .string()
      .when('schedulingType', {
        is: Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED,
        then: yup.string().required(''),
      })
      .default(Type.Daily),
    weekDays: yup
      .array()
      .when(['type', 'schedulingType'], (type, schedulingType, schema) => {
        return [Type.Daily, Type.Weekly, Type.Interval].includes(type) &&
          schedulingType === Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED
          ? schema.required('Share.errors.weekDays')
          : schema;
      })
      .nullable()
      .default([]),
    starts: yup
      .string()
      .when(['type', 'schedulingType'], (type, schedulingType, schema) => {
        return [Type.Daily, Type.Weekly, Type.Interval, Type.Monthly].includes(type) &&
          schedulingType === Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED
          ? schema.required('Share.errors.time')
          : schema;
      })
      .nullable()
      .default(''),
    ends: yup
      .string()
      .when(['type', 'schedulingType'], (type, schedulingType, schema) => {
        if (Type.Interval === type && schedulingType === Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED) {
          schema = schema.required('Share.errors.time');
          return schema.test('endsAfterStarts', 'Share.errors.time.startBiggerThanEnd', function () {
            const { path, parent } = this;
            let ends = parent[path];
            let starts = parent.starts;
            if (ends && starts) {
              ends = new Date(`2000-01-01T${ends}:00`);
              starts = new Date(`2000-01-01T${starts}:00`);
              return ends.getTime() > starts.getTime();
            }
            return true;
          });
        }
        return schema;
      })
      .nullable()
      .default(''),
    monthDay: yup
      .string()
      .when(['type', 'schedulingType'], (type, schedulingType, schema) => {
        return Type.Monthly === type && schedulingType === Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED
          ? schema.test('stringTest', 'Share.errors.monthDay', (val) => {
              return val && val !== '';
            })
          : schema;
      })
      .nullable()
      .default(''),
    interval: yup
      .number()
      .integer()
      .when(['type', 'schedulingType'], (type, schedulingType, schema) => {
        return Type.Interval === type && schedulingType === Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED
          ? schema
              .required('Share.errors.interval')
              .min(15)
              .max(720)
              .test('intervalWithinRange', 'Share.errors.intervalRange', function () {
                const { path, parent } = this;
                let interval = parent[path];
                let starts = parent.starts;
                let ends = parent.ends;
                if (interval && starts && ends) {
                  starts = new Date(`2000-01-01T${starts}:00`);
                  ends = new Date(`2000-01-01T${ends}:00`);
                  const timeDifference = ends.getTime() - starts.getTime();
                  const maxAllowedInterval = timeDifference / (1000 * 60);
                  return interval <= maxAllowedInterval;
                }
                return true;
              })
          : schema;
      })
      .nullable()
      .default(15),
  });
});

function DiarySubTab({ weekly = false, namePrefix = '' }) {
  const { msg } = useBimContext();
  return (
    <div>
      <DayButtons name={`${namePrefix}weekDays`} checkbox={!weekly} />
      <div className={`${styles.diarySendTime}`}>
        <Field
          name={`${namePrefix}starts`}
          rootClassName={`${styles.fieldDefault}`}
          label={msg.t('schedule.recurrence.send.time')}
          labelClassName={`${styles.labelDefaultStrong}`}
          component={BngField}
          showErrors={false}
          inputComponent={BngTimePicker}
          inputClassName={`${styles.diarySendTimeInputIcon}`}
          inline
        />
      </div>
    </div>
  );
}

function MonthlySubTab({ namePrefix = '' }) {
  const { msg } = useBimContext();

  const schedulingDaysOptions = useMemo(() => {
    const arr = [];
    for (let i = 1; i < 31; i++) {
      arr.push({ value: i, label: i });
    }
    arr.push({ value: -1, label: msg.t('last') });
    return arr;
  }, []);

  return (
    <div className={`MonthlySubTab mt-5 ${styles.monthlyDaysContainer}`}>
      <BngField
        label={msg.t('schedule.recurrence.send.time')}
        labelClassName={`${styles.labelDefaultStrong}`}
        inputComponent={null}
      >
        <div className={`${styles.inlineTimingContainer}`}>
          <Field
            label={msg.t('schedule.recurrence.monthly.label.1')}
            labelClassName={`${styles.labelDefault}`}
            className={`${styles.monthlyDaysComboBox}`}
            name={`${namePrefix}monthDay`}
            component={BngField}
            rootClassName={`${styles.fieldSelectDefault}`}
            showErrors={false}
            inputComponent={BngSelect}
            options={schedulingDaysOptions}
            inline
          />
          <Field
            label={msg.t('schedule.recurrence.monthly.label.2')}
            labelClassName={`${styles.labelDefault}`}
            name={`${namePrefix}starts`}
            component={BngField}
            rootClassName={`${styles.fieldDefault}`}
            showErrors={false}
            inputComponent={BngTimePicker}
            inputClassName={`${styles.monthlyInputIcon}`}
            type={'time'}
            icon="schedule"
            inline
          />
        </div>
      </BngField>
    </div>
  );
}

function IntervalSubTab({ namePrefix }) {
  const { msg } = useBimContext();
  const formik = useFormikContext();

  const validateIntervalInput = (event) => {
    if (/^\d{0,3}$/.test(event.target.value)) {
      if (event.target.value > 720) {
        formik.setFieldValue(`${namePrefix}interval`, 720);
      } else {
        formik.setFieldValue(`${namePrefix}interval`, event.target.value);
      }
    }
  };

  return (
    <div>
      <DayButtons checkbox={true} name={`${namePrefix}weekDays`} />
      <div className={`${styles.intervalBottomContainer} mb-5`}>
        <BngField
          label={msg.t('schedule.recurrence.send.time')}
          labelClassName={`${styles.labelDefaultStrong}`}
          inputComponent={null}
        >
          <div className={`${styles.intervalTime}`}>
            <label className={`${styles.intervalFirstGapLabel}`}>{msg.t('schedule.recurrence.interval.label.1')}</label>
            <Field
              name={`${namePrefix}starts`}
              component={BngField}
              showErrors={false}
              inputComponent={BngTimePicker}
              inputClassName={`${styles.intervalSendTimeInputIcon}`}
              rootClassName={`${styles.fieldSelectDefault}`}
              type={'time'}
              icon="schedule"
              withLabel={false}
            />
            <label className={`${styles.intervalLabelSize}`}>{msg.t('schedule.recurrence.interval.label.2')}</label>
            <Field
              name={`${namePrefix}ends`}
              component={BngField}
              showErrors={false}
              inputComponent={BngTimePicker}
              inputClassName={`${styles.intervalSendTimeInputIcon}`}
              rootClassName={`${styles.fieldSelectDefault}`}
              icon="schedule"
              type={'time'}
              withLabel={false}
            />
          </div>
          <div className={`${styles.intervalBetween}`}>
            <label className={`${styles.intervalFirstGapLabel}`}>{msg.t('schedule.recurrence.interval.label.3')}</label>
            <Field
              className={`${styles.intervalMinutes}`}
              name={`${namePrefix}interval`}
              component={BngField}
              rootClassName={`${styles.fieldSelectDefault}`}
              showErrors={false}
              maxLength={3}
              onChange={validateIntervalInput}
              withLabel={false}
            />
            <label className={`${styles.intervalLabelSize}`}>{msg.t('minutes')}</label>
          </div>
        </BngField>
      </div>
    </div>
  );
}

function DayButtons({ name, checkbox = false }) {
  const { msg } = useBimContext();

  const opts = useMemo(() => {
    const arr = [];
    for (let i = 1; i < 8; i++) {
      arr.push(msg.t(`dayOfTheWeek.${i}`).substring(0, 3));
    }

    //Value: 0 = Sunday... 1 = Monday
    return [
      { value: 1, label: arr[0] },
      { value: 2, label: arr[1] },
      { value: 3, label: arr[2] },
      { value: 4, label: arr[3] },
      { value: 5, label: arr[4] },
      { value: 6, label: arr[5] },
      { value: 0, label: arr[6] },
    ];
  }, []);

  return (
    <Field
      name={name}
      className={`${styles.dayButtons}`}
      component={BngField}
      showErrors={false}
      inputComponent={BngButtonGroup}
      options={opts}
      checkbox={checkbox}
      withLabel={false}
      selectedIndicator
      forceArray
      fill
    />
  );
}

export default function BngExportPeriodicityTab({ namePrefix = '' }) {
  const { msg } = useBimContext();
  const { values, setFieldValue } = useFormikContext();

  const intervalComponent =
    {
      Daily: <DiarySubTab namePrefix={namePrefix} />,
      Weekly: <DiarySubTab namePrefix={namePrefix} weekly={true} />,
      Monthly: <MonthlySubTab namePrefix={namePrefix} />,
      Interval: <IntervalSubTab namePrefix={namePrefix} />,
    }[values.type] ?? null;

  const handleTypeChange = (value) => {
    if (value === Type.Weekly && values.weekDays.length > 0) setFieldValue('weekDays', [values.weekDays[0]]);
  };

  const handleIdentifierChange = (event) => {
      event.target.value = event.target.value.trim();
  }

  return (
    <div className={`RecurrenceTab ${styles.tabWrapper}`}>
      <FastField
        name={`${namePrefix}identifier`}
        className={`${styles.schedulingIdentifier}`}
        label={msg.t('schedule.recurrence.identifier')}
        placeholder={msg.t('schedule.recurrence.identifier.placeholder')}
        component={BngField}
        onBlur={handleIdentifierChange}
        labelClassName={`${styles.labelDefaultStrong}`}
        maxLength={'120'}
        showErrors={false}
      />
      <div className={`${styles.recurrenceContainer}`}>
        <Field
          name={`${namePrefix}type`}
          label={msg.t('schedule.recurrence.label')}
          labelClassName={`${styles.labelDefaultStrong}`}
          className={`${styles.scheduleTypeButtonGroup}`}
          placeholder={msg.t('schedule.recurrence.identifier.placeholder')}
          rootClassName="mb-0"
          component={BngField}
          showErrors={false}
          inputComponent={BngButtonGroup}
          onChange={handleTypeChange}
          options={[
            { value: Type.Daily, label: msg.t('daily'), icon: 'light_mode' },
            { value: Type.Weekly, label: msg.t('weekly'), icon: 'date_range' },
            { value: Type.Monthly, label: msg.t('monthly'), icon: 'calendar_month' },
            { value: Type.Interval, label: msg.t('interval'), icon: 'timelapse' },
          ]}
          selectedIndicator
          fill
        />
        {intervalComponent}
      </div>
    </div>
  );
}
