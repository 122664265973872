import './Button.css';

import React from "react";
import PropTypes from 'prop-types';

import Icon from "components/ui/common/Icon";

export default class Button extends React.Component {
    static propTypes = {
        icon: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
        componentClass: PropTypes.any,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        newStyleButton: PropTypes.bool,
        type: PropTypes.string,
    };

    static defaultProps = {
        componentClass: 'button',
        loading: false,
        disabled: false,
        className: '',
        type: 'button'
    };

    constructor(props) {
        super(props);
    }

    render() {
        let {className, componentClass, loading, disabled, newStyleButton, type, ...other} = this.props;
        const Component = componentClass;
        const renderedIcon = this.icon();

        let styleClass = newStyleButton ? `btn-new ${className}` : `btn btn-primary ${className}`;
        styleClass += this.props.disabled ? ' disable' : ' enable';

        return (
            <Component type={type}
                       disabled={loading || disabled}
                       className={styleClass}
                       {...other}>
                {renderedIcon} {renderedIcon ? ' ' : ''}
                {this.props.children}
            </Component>
        );
    }

    icon() {
        if (this.props.loading) {
            return <Icon icon="icon-refresh" className="icon-spin"/>
        } else if (!!this.props.icon) {
            return <Icon icon={this.props.icon}/>;
        }
        return null;
    }

}

export const BngCancelButton = ({className = '', ...props}) => {
    return (
        <Button className={`BngCancelButton ${className}`} {...props} />
    );
}

export const BngPrimaryButton = ({className = '', ...props}) => {
    return (
        <Button className={`BngPrimaryButton ${className}`} {...props} />
    );
}

export const BngWarnButton = ({className = '', ...props}) => {
    return (
        <Button className={`BngWarnButton ${className}`} {...props} />
    );
}

export const BngDialogActionButton = ({className = '', ...props}) => {
    return (
        <Button className={`BngDialogActionButton ${className}`} {...props} />
    );
}