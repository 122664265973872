import React from "react";
import {connect} from "react-redux";
import {NAVBAR} from "components/ui/redux/Actions";
import CentralMonitoringMenuButton from "components/ui/navbar/CentralMonitoringMenuButton";


const mapStateToProps = (state) => {
    return {
        events: state.navbar.events
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        findMonitors: () => dispatch(NAVBAR.findMonitors())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CentralMonitoringMenuButton);