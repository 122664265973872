import './KpiMultiStepInfo.css';

import React from 'react';

import BngMultiStep from 'components/bng/ui/BngMultiStep';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';

export default function KpiMultiStepInfo({ projectId, hasWatched = false, onFinish = _.noop, prepend = null }) {
  const context = useBimContext();

  const handleNextClick = async ({ isLastStep }) => {
    if (!isLastStep) {
      return;
    }

    try {
      if (!hasWatched) {
        await Api.UserParam.saveUserKey({
          key: Api.UserParam.Keys.Kpi.WatchedNewKpi,
          value: 'true',
          projectId,
        });
      }
      onFinish();
    } catch (e) {
      console.error('Error on KpiMultiStepInfo.handleNextClick()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  return (
    <BngMultiStep className="KpiMultiStepInfo" prepend={prepend} onNextClick={handleNextClick} overlay={false}>
      <BngMultiStep.Step>
        <iframe
          className="Image IntroVideo"
          src={`${context.msg.t('new.kpi.help.video.url')}?autoplay=1&rel=0&iv_load_policy=3`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <BngMultiStep.Title>{context.msg.t('new.kpi.slide1.title')}</BngMultiStep.Title>
        <BngMultiStep.Subtitle>{context.msg.t('new.kpi.slide1.subtitle')}</BngMultiStep.Subtitle>
      </BngMultiStep.Step>
    </BngMultiStep>
  );
}
