import React, { useMemo } from 'react';
import { Field } from 'formik';
import BngField from 'components/bng/form/BngField';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';

export default {
  render({ name, label, context: { msg }, props }) {

    const options = useMemo(() => {
      return props.options?.map((o) => {
        return {
          ...o,
          label: msg.t(o.label),
        };
      });
    }, [props.options]);

    return (
      <Field
        name={name}
        label={label}
        component={BngField}
        inputComponent={BngSelectSearch}
        rootClassName={'ParamType SelectOption'}
        rootProps={{ 'data-test': 'SelectOption' }}
        {...props}
        options={options}
      />
    );
  },
};
