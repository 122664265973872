"use strict";

import I18n from "components/I18n";
import {QueryApi} from "components/service/data/QueryApi";
import {
    buildUrlWithAuth,
    executeJsfExp,
    findContextPath,
    parseQueryString,
    buildUrl as commonBuildUrl
} from "commonUtils";
import {localStore, SESSION_DATA} from "components/Storage";
import Utils from "components/Utils";

/* APIs */
import NavbarApi from "components/service/bng/NavbarApi";
import GitHubApi from "components/service/in-memory/GithubApi";
import SchemaUpdaterApi from "components/service/admin/SchemaUpdaterApi";
import NewsApi from "components/service/bim-apps/NewsApi";
import AccountApi from "components/service/bng/AccountApi";
import DumpApi from "components/service/bng/DumpApi";
import EventApi from "components/service/bng/EventApi";
import PublisherApi from "components/service/bng/PublisherApi";
import PiperunApi from "components/service/in-memory/PiperunApi";
import PipedriveApi from "components/service/in-memory/PipedriveApi";
import ProjectMergeApi from "components/service/project/ProjectMergeApi";
import ProjectApi from "components/service/project/ProjectApi";
import UploadApi from "components/service/bng/UploadApi";
import DashApi from "components/service/object/DashApi";
import NewMapApi from "components/service/object/NewMapApi";
import UserApi from "components/service/user/UserApi";
import MenuApi from "components/service/project/MenuApi";
import PresentationApi from "components/service/bng/PresentationApi";
import NotificationApi from "components/service/bng/NotificationApi";
import BimIntegrationApi from "components/service/bim-apps/BimIntegrationApi";
import UserParamApi from "components/service/user/UserParamApi";
import ReactNative from "components/service/mobile/ReactNativeApi";
import Api4Com from "components/service/in-memory/Api4ComApi";
import MobileApi from "components/service/mobile/MobileApi";
import KpiApi from "components/service/object/KpiApi";
import ExportSchedulingApi from "components/service/bng/ExportSchedulingApi";
import CentralMonitoringApi from "components/service/bng/CentralMonitoringApi";
import OriginApi from "components/service/in-memory/OriginApi";
import AnalysisApi from "components/service/object/AnalysisApi";
import MonitorApi from "components/service/bng/MonitorApi";
import CockpitApi from "components/service/object/CockpitApi";
import FilterApi from "components/service/bng/FilterApi";
import NoteApi from "components/service/bng/NoteApi";
import BngApi from "components/service/bng/BngApi";
import SupportApi from "components/service/support/SupportApi";
import ExportApi from "components/service/bng/ExportApi";
import EmailBlacklistApi from "components/api/EmailBlacklistApi";
import TwoFactorAuthApi from "components/api/TwoFactorAuthApi";
import AdminApi from "components/service/admin/AdminApi";
import ConnectionApi from "components/service/in-memory/ConnectionApi";
import BigTableApi from "components/bng/pages/bigTable/BigTableApi";
import GroupApi from "components/api/GroupApi";
import TemporaryStorageApi from "components/api/TemporaryStorageApi";
import FeatureApi from "components/service/bng/FeatureApi";
import MdxGlobalFilterApi from "components/api/MdxGlobalFilterApi";
import ProjectQuotaRequestApi from "components/ui/project/ProjectQuotaRequestApi";
import LoadLimitApi from "components/bng/load-limit/LoadLimitApi";
import InMemoryLoadLimitApi from "components/bng/load-limit/inMemory/InMemoryLoadLimitApi";
import ManageFileApi from "components/api/ManageFileApi";
import UserGroupApi from "components/service/user/UserGroupApi";
import ProjectAppApi from "components/api/ProjectAppApi";
import EventsApi from "components/api/EventsApi";
import ObjectExecMonitorApi from "components/api/ObjectExecMonitorApi";
import ActivityApi from "components/service/admin/ActivityApi";
import IconApi from "components/api/IconApi";
import MobileMessageRequestApi from "components/service/mobile/messaging/MobileMessageRequestApi";
import BimUniversityApi from "components/service/bim-apps/BimUniversityApi";
import ProjectExtractorApi from "components/api/ProjectExtractorApi";
import AppCacheApi from "components/api/AppCacheApi";
import GenericFileSourceApi from "components/api/GenericFileSourceApi";
import BreadcrumbApi from "components/api/BreadcrumbApi";
import ValidateMobileApi from "components/api/ValidateMobileApi";
import UserAccessApi from "components/service/user/UserAccessApi";
import ExportTraceApi from "components/service/traceability/ExportTraceApi";
import FeatureProposalApi from 'components/service/bng/FeatureProposalApi';
import ProjectManagementApi from 'components/api/ProjectManagementApi';
import AssistedAnalysisApi from 'components/service/object/AssistedAnalysisApi';
import MarketplaceApi from "components/service/bng/MarketplaceApi";
import AuthApi from 'components/api/AuthApi';
import AccountManagerApi from "components/api/AccountManagerApi";
import AccessTimeRestrictionApi from 'components/api/AccessTimeRestrictionApi'
import BimQueryApi from 'components/api/BimQueryApi';
import ProjectInviteApi from 'components/api/ProjectInviteApi';
import InMemoryBeanApi from "components/api/InMemoryBeanApi";
import AdaAiApi from 'components/api/AdaAiApi';
import MetadataApi from "components/service/bng/MetadataApi";

const $ = jQuery;

let i18nInstance;

class Api {

    static i18nCacheKey = 'i18n';
    static baseUrl = findContextPath;
    static apiPrefix = (() => {
        let cachedValue = null;

        return () => {
            if (cachedValue === null) {
                const baseUrl = Api.baseUrl();
                const path = window.location.pathname.replace(baseUrl, '');
                cachedValue = `${Api.baseUrl()}${path.includes('api/') ? '/api' : '/spr'}`;
            }
            return cachedValue;
        }
    })();

    static Navbar = NavbarApi;
    static Presentation = PresentationApi;
    static Menu = MenuApi;
    static User = UserApi;
    static Support = SupportApi;
    static NewMap = NewMapApi;
    static Dash = DashApi;
    static Upload = UploadApi;
    static Project = ProjectApi;
    static ProjectMerge = ProjectMergeApi;
    static Pipedrive = PipedriveApi;
    static Piperun = PiperunApi;
    static Publisher = PublisherApi;
    static Event = EventApi;
    static Account = AccountApi;
    static News = NewsApi;
    static SchemaUpdater = SchemaUpdaterApi;
    static GitHub = GitHubApi;
    static Query = new QueryApi();
    static Notification = new NotificationApi();
    static Bng = new BngApi();
    static Note = new NoteApi();
    static Filter = new FilterApi();
    static Cockpit = new CockpitApi();
    static Monitor = new MonitorApi();
    static Analysis = new AnalysisApi();
    static Origin = new OriginApi();
    static CentralMonitoring = new CentralMonitoringApi();
    static ExportScheduling = new ExportSchedulingApi();
    static Kpi = new KpiApi();
    static Mobile = new MobileApi();
    static Api4Com = new Api4Com();
    static ReactNative = new ReactNative();
    static UserParam = new UserParamApi();
    static Admin = new AdminApi();
    static EmailBlacklist = new EmailBlacklistApi();
    static TwoFactorAuth = new TwoFactorAuthApi();
    static BimIntegration = new BimIntegrationApi();
    static Export = new ExportApi();
    static Connection = new ConnectionApi();
    static BigTable = new BigTableApi();
    static MdxGlobalFilter = new MdxGlobalFilterApi();
    static Group = new GroupApi();
    static TemporaryStorage = new TemporaryStorageApi();
    static Feature = new FeatureApi();
    static ProjectQuotaRequest = new ProjectQuotaRequestApi();
    static LoadLimit = new LoadLimitApi();
    static InMemoryLoadLimit = new InMemoryLoadLimitApi();
    static ManageFile = new ManageFileApi();
    static UserGroup = UserGroupApi;
    static ProjectApp = new ProjectAppApi();
    static Events = new EventsApi();
    static ObjectExecMonitor = new ObjectExecMonitorApi();
    static Activity = new ActivityApi();
    static Icon = new IconApi;
    static Dump = new DumpApi();
    static MobileMessageRequest = new MobileMessageRequestApi();
    static University = new BimUniversityApi();
    static ProjectExtractor = new ProjectExtractorApi();
    static AppCache = new AppCacheApi();
    static GenericFileSource = new GenericFileSourceApi();
    static Breadcrumb = new BreadcrumbApi();
    static ValidateMobile = new ValidateMobileApi();
    static UserAccess = new UserAccessApi();
    static ExportTrace = new ExportTraceApi();
    static FeatureProposal = new FeatureProposalApi();
    static ProjectManagement = new ProjectManagementApi();
    static Marketplace = new MarketplaceApi();
    static AssistedAnalysis = new AssistedAnalysisApi();
    static Auth = AuthApi;
    static AccountManager = new AccountManagerApi();
    static AccessTimeRestriction = new AccessTimeRestrictionApi();
    static BimQuery = new BimQueryApi();
    static ProjectInvite = new ProjectInviteApi();
    static AdaAi = new AdaAiApi();
    static InMemoryBean = new InMemoryBeanApi();
    static Metadata = new MetadataApi();

    static findUserLocale() {
        return document.querySelector('head').getAttribute('data-lang') || window.navigator.language;
    }

    static invalidateTranslations() {
        const sessionData = localStore.get(SESSION_DATA) || {};
        delete sessionData[Api.i18nCacheKey];
        localStore.put(SESSION_DATA, sessionData);
        i18nInstance = null;
    }

    static translations(prefetchedData = null) {
        let deferred = $.Deferred();

        if (i18nInstance) {
            deferred.resolve(i18nInstance);
        } else {
            const resolveDef = (data) => {
                i18nInstance = new I18n(data);
                deferred.resolve(i18nInstance);

                const sessionData = localStore.get(SESSION_DATA) || {};
                sessionData[Api.i18nCacheKey] = {
                    data,
                    lang: window.__USER_LANG,
                    version: window.__BIMV
                };
                localStore.put(SESSION_DATA, sessionData);
            };

            if (window.__BIM_STORYBOOK_I18N_DATA) {
                resolveDef(window.__BIM_STORYBOOK_I18N_DATA.data);
            } else if (prefetchedData) {
                resolveDef(prefetchedData);
            } else {
                const i18nCache = _.get(localStore.get(SESSION_DATA), Api.i18nCacheKey, null);
                if (i18nCache && i18nCache.lang === window.__USER_LANG && i18nCache.version === window.__BIMV) {
                    resolveDef(i18nCache.data);
                } else {
                    const localeParam = j.param({lang: window.__USER_LANG});
                    $.getJSON(`/spr/translations?${localeParam}`)
                        .then(
                            resolveDef,
                            () => $.getJSON(`${Api.baseUrl()}/api/translations?${localeParam}`, resolveDef)
                        );
                }
            }
        }

        return deferred.promise();
    }

    static executeExp = executeJsfExp;

    static updateJsf() {
        return new Promise((res) => {
            if (!application.ice.isLoaded()) {
                res();
                return;
            }

            const button = document.querySelector('.updateComponentsButton');
            if (button) {
                const listenerName = `updateComponentsButton-listener-${Utils.randomId()}`;
                application.ice.addNewListener(listenerName, _.noop, function () {
                    application.ice.removeListener(listenerName);
                    res();
                });
                button.click();
            } else {
                console.error('.updateComponentsButton not found');
                res();
            }
        });

    }

    static parseQueryString(query) {
        return parseQueryString(query);
    }

    static buildBaseUrl(fullUrl = false) {
        let baseUrl = Api.baseUrl();
        if (fullUrl) {
            baseUrl = window.location.origin + baseUrl;
        }
        return baseUrl
    }

    static loadObjectUrl = ({fullUrl = false, ...props}) => {
        Api.checkContent(props);
        let baseUrl = Api.buildBaseUrl(fullUrl);
        if (application.page.isMobile() || application.utils.isAccessingPrivatePublisher()) {
            return Api.loadMobileUrl({baseUrl, ...props});
        }
        return `${baseUrl}/load.iface?${j.param(props)}`
    };

    static loadMobileUrl = ({baseUrl, ...props}) => {
        const publisherFull = application.utils.isAccessingPublisherFull();

        const newProps = {
          ...props,
          breadcrumb: false,
          openIconObject: true,
          publisherRequestId: publisherFull ? window.__PUBLISHER_REQUEST_ID : undefined,
        };

        const searchParams = Utils.History.currentUrlSearchParams();
        if (application.utils.isAccessingPrivatePublisher()) {
          if (searchParams.has('fromCockpit')) {
            newProps.removeFrame = true;
          }
          const content = searchParams.get('content');
          if (!newProps.publisherContent) {
            newProps.publisherContent = content;
          }
        }

        Object.keys(newProps).forEach((key) => {
          if (_.isUndefined(newProps[key])) {
            delete newProps[key];
          }
        });

        const type = Utils.Object.getObjectType(newProps.content);

        const typeLink = application.utils.isAccessingPublisher()
          ? `publisher${publisherFull ? '/full' : ''}`
          : 'mobile';
        return buildUrlWithAuth(`${baseUrl}/spr/${typeLink}/${type}?${j.param(newProps)}`);
    };

    static checkContent(props) {
        if (!('content' in props)) {
            throw new Error('content property must be set');
        }
    }

    /*add time param to avoid cache*/
    static params(props = {}) {
        return j.param({t: Date.now(), ...props})
    }

    static redirectPersonalPage(context) {
        window.location = `${context.contextPath}/spr/bng/personal-page`;
    }

    static buildUrl = commonBuildUrl;
}

if (window && window.application) {
    window.application.Api = Api;
}

export default Api;
