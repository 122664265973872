import React from 'react';
import { Field } from 'formik';

import { BngField } from 'components/bng/form/BngField';

export default {
  render({ name, label, props }) {
    return (
      <Field
        name={name}
        label={label}
        component={BngField}
        rootClassName={'ParamType String'}
        rootProps={{ 'data-test': 'String' }}
        {...props}
      />
    );
  },
};
