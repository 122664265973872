import { Axios } from 'commonUtils';

class ManageFileApi {
  async findTreeTable(projectId, userId) {
    const { data } = await Axios.get(`/spr/manageFiles/tree`, {
      params: {
        projectId,
        userId,
      },
    });
    return data;
  }

  async findRelatedObjectsCount(parentPaths) {
    const { data } = await Axios.post(`/spr/manageFiles/relatedObjectsCount`, { paths: parentPaths });
    return data;
  }

  async deletePaths(paths) {
    const { data } = await Axios.post(`/spr/manageFiles/removePaths`, { paths });
    return data;
  }
}

export default ManageFileApi;
