import './BngHorizontalCard.css';

import React, { useEffect, useRef, useState } from 'react';
import { ResizeSensor } from 'css-element-queries';
import PropTypes from 'prop-types';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';

export function BngIsNewLabel({ className = '', text }) {
  const { t } = useTranslation();
  return <div className={`BngIsNewLabel ${className}`}>{text ? text : t('is.new')}</div>;
}

export function BngIsComingLabel({ className = '', text }) {
  const { t } = useTranslation();
  return <div className={`BngIsComingLabel ${className}`}>{text ? text : t('is.coming')}</div>;
}

export function BngIsBetaLabel({ className = '', text }) {
  const { t } = useTranslation();
  return <div className={`BngIsBetaLabel ${className}`}>{text ? text : t('is.beta')}</div>;
}

export function BngIsPremiumLabel({ context, className = '', text }) {
  const { t } = useTranslation();
  return (
    <div className={`BngIsPremiumLabel ${className}`}>
      <Icon icon="rocket_launch" />
      {text ? text : t('is.upgrade')}
    </div>
  );
}

export function BngNumberLabel({ className = '', number }) {
  return <div className={`BngNumberLabel ${className}`}>{number}</div>;
}

export const BngHorizontalCard = ({
  className = '',
  icon = '',
  title = 'Change this title :D',
  text = 'Change this text :P',
  isNew = false,
  isComing = false,
  isBeta = false,
  isPremium = false,
  showNumberInLabel = null,
  onClick = _.noop,
  tooltip = undefined,
  labelText = undefined,
  disabled = false,
  image = undefined,
  preventScroll = true,
  ...props
}) => {
  const contentRef = useRef();
  const textRef = useRef();
  const [processedText, setProcessedText] = useState(text);

  useEffect(() => {
    if (!preventScroll || !contentRef.current || !textRef.current || !text) return;

    const processTextSize = () => {
      const result = handleTextSize(contentRef.current, textRef.current, text);
      setProcessedText(result);
    };

    const sensor = new ResizeSensor(contentRef.current, () => {
      window.requestAnimationFrame(() => {
        processTextSize();
      });
    });
    processTextSize();
    return () => {
      sensor.detach();
    };
  }, [contentRef.current, textRef.current, text]);

  const textContent = preventScroll ? processedText : text;
  return (
    <div className={`BngHorizontalCardBorder ${isPremium ? 'premiumBorder' : ''}`}>
      <div
        className={`BngHorizontalCard ${className} ${isComing ? 'isComing' : ''} ${disabled ? 'Disabled' : ''} ${
          isPremium ? 'isPremium' : ''
        }`}
        onClick={disabled ? undefined : onClick}
        title={tooltip}
        {...props}
      >
        <div className={`IconContainer ${image ? 'withImage' : ''}`}>
          {image ? <img src={image} alt={'Icon'} /> : <Icon icon={icon} />}
        </div>
        <div className="Content" ref={contentRef}>
          <div>
            <div className="Title">
              {title}
              {isNew && <BngIsNewLabel text={labelText} />}
              {isBeta && <BngIsBetaLabel />}
              {showNumberInLabel && <BngNumberLabel number={showNumberInLabel} />}
            </div>
            <div className="Text" ref={textRef}>
              {textContent}
            </div>
          </div>
          <div className="LabelWrapper">
            {isPremium && <BngIsPremiumLabel />}
            {isComing && <BngIsComingLabel text={labelText} />}
          </div>
        </div>
      </div>
    </div>
  );
};

BngHorizontalCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  isNew: PropTypes.bool,
  isComing: PropTypes.bool,
  labelText: PropTypes.string,
  onClick: PropTypes.func,
};

const handleTextSize = (ref, $text, originalText) => {
  let content = originalText;
  $text.innerText = content;
  $text.removeAttribute('title');

  if (ref.clientHeight >= ref.scrollHeight) return content;

  $text.setAttribute('title', originalText);

  for (let i = 0; i < 32 && ref.scrollHeight > ref.clientHeight; i++) {
    content = content.slice(0, content.lastIndexOf(' ')) + '...';
    $text.innerText = content;
  }

  return content;
};
