import styles from './PublisherFullTab.module.css';
import React, { useEffect, useMemo, useState } from 'react';

import Pizza from 'components/ui/accounts/tabs/Pizza';
import { calculateUsagePercent } from 'components/bng/accounts/ConsumptionTab';
import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';
import CrudPageLayout from 'components/bng/pages/common/layout/CrudPageLayout';
import Utils from 'components/Utils';
import BngDropdown from 'components/bng/ui/BngDropdown';
import PublisherIncorporateLink from 'components/ui/publisher/PublisherIncorporateLink';
import DetailsDialog from 'components/ui/common/DetailsDialog';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';

const findInteractionIcon = (interaction) => {
  switch (interaction) {
    case 'BngAnalysisDrillDownBar':
      return 'table_chart';
    case 'FilterBar':
      return 'filter_alt';
    case 'IconText':
      return 'mood';
    case 'AnalysisDynamicTable':
      return 'bar_chart';
  }
};

export default function PublisherFullTab(props) {
  const context = useBimContext();

  const [requestStatus, setRequestStatus] = useState({
    maxCapacity: 20,
    currentUsage: 0,
  });
  const [interactionStatus, setInteractionStatus] = useState({
    maxCapacity: 20,
    currentUsage: 0,
  });

  const [requests, setRequests] = useState([]);

  const [filter, setFilter] = useState({ searchTerm: '', filterButton: {} });
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    fetchData();
  }, [props.account]);

  const fetchData = async () => {
    const requests = await Api.Publisher.findRequestsForAccount(props.account.id);
    let totalRequests = 0;
    let totalInteractions = 0;
    requests.forEach((request) => {
      totalRequests += request.total ?? 0;
      request.interactions.forEach((interaction) => {
        totalInteractions += interaction.total ?? 0;
      });
    });
    setRequestStatus((prev) => ({ ...prev, currentUsage: totalRequests }));
    setInteractionStatus((prev) => ({ ...prev, currentUsage: totalInteractions }));
    setRequests(requests);
  };

  const doDrillDown = (row, expand) => {
    if (row.leaf) return;
    expand ? (expanded[row.name] = row) : delete expanded[row.name];
    setExpanded({ ...expanded });
  };

  const columns = useMemo(
    () =>
      buildTableColumns({
        msg: context.msg,
        expanded,
        doDrillDown,
        fetchData,
      }),
    [expanded, requests]
  );

  const expandedNodes = useMemo(() => {
    const buildExpandedNodes = (nodeArray) => {
      if (!nodeArray) return [];

      const showOnSearch = [];

      for (let index = 0; index < nodeArray.length; index++) {
        let children = [];
        const nodeItem = nodeArray[index];
        if (nodeItem.name in expanded) {
          children = buildExpandedNodes(nodeItem.interactions);
          nodeArray.splice(index + 1, 0, ...children);
        }
        if (
          (nodeItem.name || nodeItem.source).toLowerCase().includes(filter.searchTerm.toLowerCase().trim()) ||
          children.length > 0
        ) {
          showOnSearch.push(nodeItem);
        } else {
          showOnSearch.push(...children);
        }
      }

      if (filter) {
        return showOnSearch;
      }

      return nodeArray;
    };
    const copy = _.cloneDeep(requests);
    return buildExpandedNodes(copy);
  }, [requests, expanded, filter]);

  return (
    <div className={`PublisherFullTab ${styles.TabWrapper}`}>
      <h6 className={styles.Title}>{context.msg.t('extension.consumption')}</h6>
      <div className={`${styles.KpisWrapper}`}>
        <Pizza
          className={`${styles.KpiWidth}`}
          percent={calculateUsagePercent(requestStatus.currentUsage, requestStatus.maxCapacity)}
        >
          <div className={`${styles.DescriptionWrapper}`}>
            <div className={`${styles.KpiTitle}`}>
              {requestStatus.currentUsage} {context.msg.t('current.request.usage')}
            </div>
            <div className={`${styles.KpiDescription}`}>
              {context.msg.t('month.capacity')} {requestStatus.maxCapacity}
            </div>
          </div>
        </Pizza>
        <Pizza
          className={`${styles.KpiWidth}`}
          percent={calculateUsagePercent(interactionStatus.currentUsage, interactionStatus.maxCapacity)}
        >
          <div className={`${styles.DescriptionWrapper}`}>
            <div className={`${styles.KpiTitle}`}>
              {interactionStatus.currentUsage} {context.msg.t('current.interaction.usage')}
            </div>
            <div className={`${styles.KpiDescription}`}>
              {context.msg.t('month.capacity')} {interactionStatus.maxCapacity}
            </div>
          </div>
        </Pizza>
      </div>
      <div className={`${styles.ConsumptionInfo}`}>
        <Icon icon="info" />
        <div className={`${styles.InfoText}`}>
          {context.msg.t('publisher.full.extension.info')}
          <a
            className="publisher-full-extension-info-click"
            href={context.msg.t('publisher.full.extension.info.click.link')}
            target="_blank"
          >
            {context.msg.t('publisher.full.extension.info.click')}
          </a>
          .
        </div>
      </div>
      <CrudPageLayout
        pageTitle={context.msg.t('publisher.full.admin.panel')}
        embedded={true}
        showExpandButton={false}
        hasRefresh={false}
        filters={filter}
        setFilters={setFilter}
        tableColumns={columns}
        tableRows={expandedNodes}
        uiBlockerClassName={`${styles.AdminPanel}`}
      />
    </div>
  );
}

const buildTableColumns = ({ msg, expanded, doDrillDown, fetchData }) => {
  const actionOptions = (published, path, name) => {
    return [
      [
        {
          label: msg.t('publisher.full.admin.panel.action.edit.post'),
          icon: 'edit',
          onClick: () => {
            window.ReduxStore.dispatch(
              window.Actions.MODALS.open(PublisherIncorporateLink, {
                path,
                caption: name,
                onUpdate: fetchData,
                fromAccountManager: true,
              })
            );
          },
          closeOnSelect: true,
          className: `btn-only-icon publisher-full-admin-panel-action-edit-post ${styles.ActionsOptionsIcon}`,
        },
        {
          label: msg.t('detailing'),
          icon: 'info',
          onClick: async () => {
            const publisher = await Api.Publisher.getPublisherByPath(path);
            const interactions = (await Api.Publisher.interactionsForPath(path)).map((a) => ({
              id: a.id,
              action: a.source,
              icon: 'ads_click',
              createdAt: a.createdAt,
            }));

            const publisherConfig = publisher.config;

            const dialogConfig = [
              [
                {
                  description: 'filters',
                  text: '-',
                },
                {
                  description: 'fixed.size',
                  text: publisherConfig?.enablefixsize ? msg.t('active') : msg.t('inactive'),
                },
              ],
              [
                {
                  description: 'publisher.frame',
                  text:
                    publisherConfig?.frame || publisherConfig?.enablepublisherframe
                      ? msg.t('active')
                      : msg.t('inactive'),
                },
              ],
            ];

            const buildPublisherUrl = (path, publisherConfig) => {
              const params = new URLSearchParams({
                applyFrame: publisherConfig.enablepublisherframe || false,
                showUpdateDate: publisherConfig.showdateupdate || false,
                showTitle: publisherConfig.showtitle || false,
                chart: publisherConfig.chart || false,
              });
              return `${window.location.origin}${Api.baseUrl()}/spr/publisher/full/${Utils.Object.getObjectType(
                path
              )}?content=${encodeURIComponent(path)}&${params}&appToken=<appToken>`;
            };

            const buildIFrame = (path, publisherConfig) => {
              const link = buildPublisherUrl(path, publisherConfig);

              if (!publisherConfig.fixedSize) return `<iframe src="${link}"></iframe>`;
              if (publisherConfig.width > 0) return `<iframe width="${publisherConfig.width}" src="${link}"></iframe>`;
              if (publisherConfig.height > 0)
                return `<iframe height="${publisherConfig.height}" src="${link}"></iframe>`;
              if (publisherConfig.height > 0 && publisherConfig.width > 0)
                return `<iframe width="${publisherConfig.width}" height="${publisherConfig.height}" src="${link}"></iframe>`;
            };

            const detailDialogChild = (config, path, publisherConfig, actionsList) => {
              return (
                <>
                  {!_.isEmpty(config) && (
                    <div className={`${styles.DetailsDialogConfigWrapper}`}>
                      {config.map((col, colIdx) => {
                        return (
                          <div key={colIdx} className={`${styles.ColumnWrapper}`}>
                            {col.map((item, itemIdx) => {
                              return (
                                <div key={itemIdx} className={`${styles.ConfigOptionWrapper}`}>
                                  <div className={`${styles.BlueBall}`} />
                                  {`${msg.t(item.description)}:`}
                                  <div className={`${styles.Message}`} title={item.text}>
                                    {item.text}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {publisherConfig.sharingfull && (
                    <div className={`${styles.LinksWrapper}`}>
                      <div>
                        <span>Link</span>
                        <input type="text" disabled={true} value={`${buildPublisherUrl(path, publisherConfig)}`} />
                        <Icon
                          className="publisher-full-admin-panel-details-open-publisher"
                          icon="open_in_new"
                          onClick={() =>
                            window.open(
                              buildPublisherUrl(path, publisherConfig),
                              '_blank'
                            )
                          }
                        />
                      </div>
                      <div>
                        <span>iFrame</span>
                        <input type="text" disabled={true} value={buildIFrame(path, publisherConfig)} />
                        <Icon
                          className="publisher-full-admin-panel-details-copy-iframe"
                          icon="content_copy"
                          onClick={() => {
                            navigator.clipboard.writeText(buildIFrame(path, publisherConfig));
                            UiMsg.ok(msg.t('copy.clipboard'));
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className={`${styles.ComponentWrapper}`}>
                    <div className={`${styles.ColumnWrapper}`}>
                      <div className={`${styles.ListTitle}`}>
                        <div className={`${styles.ListQuantity} ${styles.HeaderLeft}`}>{actionsList.length}</div>
                        <div>{msg.t('publisher.full.admin.panel.details.last.interactions')}</div>
                        <Icon icon="info" className={`${styles.ListInfo}`} title={msg.t('last.thirty.days')} />
                      </div>
                      <div className={`${styles.ListComponentWrapper}`}>
                        {actionsList.map((action, idx) => {
                          return (
                            <div
                              key={action.id}
                              className={`${styles.ListItem} ${idx % 2 === 0 ? 'grayscale' : ''}`}
                              style={{ backgroundColor: idx % 2 === 0 ? '#F6F6F6' : '#ffffff' }}
                            >
                              <div>
                                <Icon icon={action.icon} />
                                <div className={`${styles.ListItemAction}`}>{action.action}</div>
                              </div>
                              <div>{Utils.Date.formatDateTime(action.createdAt)}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              );
            };

            window.ReduxStore.dispatch(
              window.Actions.MODALS.open(DetailsDialog, {
                className: '',
                createdBy: { displayName: publisher.user },
                createdAt: publisher.publicationDate,
                updatedBy: { displayName: '' },
                updatedAt: ' ',
                children: detailDialogChild(dialogConfig, publisher.path, publisherConfig, interactions),
                footerComponent: undefined,
                title: (
                  <div className={`${styles.PublisherFullDialogTitle} ${styles.NameWrapper}`}>
                    <Icon icon={Utils.Object.getObjectIcon(path)} />
                    {publisher.caption}
                  </div>
                ),
              })
            );
          },
          closeOnSelect: false,
          className: `btn-only-icon publisher-full-admin-panel-action-detailing ${styles.ActionsOptionsIcon}`,
        },
        {
          label: msg.t(`${published ? 'unpublish' : 'publish'}`),
          icon: `${published ? 'public_on' : 'public_off'}`,
          onClick: async () => {
            try {
              await Api.Publisher.updateConfig(path, { sharingfull: !published });
              await fetchData();
            } catch (e) {
              console.error('Error on updateConfig()', e);
              UiMsg.error(msg.t('publisher.full.admin.panel.update.configuration'), e);
            }
          },
          closeOnSelect: false,
          className: `btn-only-icon publisher-full-admin-panel-action-${published ? 'unpublish' : 'publish'} ${
            styles.ActionsOptionsIcon
          } ${published ? 'enabled' : ''}`,
        },
      ],
    ];
  };

  return [
    {
      key: 'publication',
      label: msg.t('publisher.full.admin.panel.header.publication'),
      render: (row) => {
        const hasInteractions = !_.isEmpty(row.interactions);
        const objectType = Utils.Object.getObjectType(row.path || '');
        return (
          <div
            className={`${styles.NameWrapper} ${row.source ? styles.NameWrapperChildren : ''} pointer`}
            onClick={() => hasInteractions && doDrillDown(row, !(row.name in expanded))}
          >
            {hasInteractions ? (
              <Icon icon={row.name in expanded ? 'remove' : 'add'} className={`${styles.AddRemoveIcon}`} />
            ) : (
              <div style={{ width: '24px', marginRight: '5px' }} />
            )}
            <Icon icon={row.path ? Utils.Object.getObjectIcon(row.path) : findInteractionIcon(row.source)} />
            <div>
              {`${row.path ? objectType.charAt(0).toUpperCase() + objectType.slice(1) + ': ' : ''}`}
              {row.name || row.source}
            </div>
          </div>
        );
      },
    },
    {
      key: 'publicationDate',
      label: msg.t('publisher.full.admin.panel.header.publication.date'),
      render: (row) => <div>{Utils.Date.formatDateTime(row.createdAt)}</div>,
    },
    {
      key: 'requests',
      label: msg.t('publisher.full.admin.panel.header.request'),
      render: (row) => {
        if (row.source) {
          return null;
        }
        return <div className={`${styles.Requests}`}>{row.total}</div>;
      },
    },
    {
      key: 'interactions',
      label: msg.t('publisher.full.admin.panel.header.interactions'),
      render: (row) => {
        const totalInteractions =
          (row.interactions ? row.interactions.reduce((acc, el) => acc + (el.total ?? 0), 0) : row.total) ?? 0;
        return <div className={`${styles.Interactions}`}>{totalInteractions}</div>;
      },
    },
    {
      key: 'actions',
      label: msg.t('publisher.full.admin.panel.header.actions'),
      render: (row) =>
        row.path ? (
          <BngDropdown
            className="publisher-full-admin-panel-header-actions"
            useContainerForPopper={false}
            options={actionOptions(row.published, row.path, row.name, row.interactions)}
          />
        ) : (
          <></>
        ),
    },
  ];
};
