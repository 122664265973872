// css imported on CommonCssImports.js
import React from "react";
import PropTypes from "prop-types";

export class KpiBandsSquare extends React.Component {

    static propTypes = {
        band1Color: PropTypes.string,
        band2Color: PropTypes.string,
        band2MinValue: PropTypes.string,
        band3Color: PropTypes.string,
        band2MaxValue: PropTypes.string,
        kpiId: PropTypes.number,
        div: PropTypes.any

    };

    static defaultProps = {
        band1Color: '',
        band2Color: '',
        band2MinValue: '',
        band3Color: '',
        band2MaxValue: '',
        kpiId: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.resizeBands(this.props.width, this.props.height);
    }

    componentDidUpdate(prevProps) {
        this.resizeBands(this.props.width, this.props.height);
    }


    resizeBands = (width, height, checkScroll = true) => {

        if (this.props.div) {
            let item = this.props.div.id.includes('itemexamplekpi');
            if (item) {
                height = 350;
                width = 850
            }

            let percentIconContainer = 0.4;
            let bandLineHeight = 0.12;
            let minBandLineHeight = 14;
            let chevronFont = 0.07;
            let minChevronFont = 9;
            let valueFontSize = 0.08;
            let minValueFont = 9;

            let tdWidth = (width - (width * percentIconContainer)) / 2;

            const indicatorStyle = {
                height: `${tdWidth * 0.07}px`,
                width: `${tdWidth * 0.07}px`,
            };

            [this.$indicatorRound1El, this.$indicatorRound2El, this.$indicatorRound3El].forEach($indicator => {
                Object.assign($indicator.style, indicatorStyle);
            })

            const chevronFontSize = (tdWidth * chevronFont) < minChevronFont ? `${minChevronFont}px` : `${tdWidth * chevronFont}px`;
            [this.$chevronLeft1El, this.$chevronLeft2El, this.$chevronLeft3El].forEach($chevron => {
                $chevron.style.fontSize = chevronFontSize;
            });

            const rowBandLineHeight = (tdWidth * (bandLineHeight)) < minBandLineHeight ? `${minBandLineHeight}px` : `${tdWidth * (bandLineHeight)}px`;
            [this.$rowBands1El, this.$rowBands2El, this.$rowBands3El].forEach($rowBands => {
                $rowBands.style.lineHeight = rowBandLineHeight;
            });

            const bandValueFontSize = (tdWidth * (valueFontSize)) < minValueFont ? `${minValueFont}px` : `${tdWidth * (valueFontSize)}px`;
            [this.$band1ValueEl, this.$band2ValueEl, this.$band3ValueEl].forEach($band => {
                $band.style.fontSize = bandValueFontSize;
            });

            let widthPreview = item > 0 ? width : this.props.width;
            if (checkScroll && widthPreview < this.props.div.scrollWidth) {
                let x = (widthPreview / this.props.div.scrollWidth) * 0.9;
                height = height * (x);
                this.resizeBands(this.props.width, height, false);
            }

        }
    };


    render() {
        return (
            <center>
                <table className="table-square-bands">
                    <tbody>
                    <tr ref={el => this.$rowBands1El = el}>
                        <td>
                            <div ref={el => this.$indicatorRound1El = el} className="indicator-round"
                                 style={{background: this.props.band1Color}}/>
                        </td>
                        <td>
                            <i ref={el => this.$chevronLeft1El = el} className="icon-chevron-left"/>
                        </td>
                        <td ref={el => this.$band1ValueEl = el}>
                            {this.props.band2MinValue}
                        </td>
                    </tr>
                    <tr ref={el => this.$rowBands2El = el}>
                        <td>
                            <div ref={el => this.$indicatorRound2El = el} className="indicator-round"
                                 style={{background: this.props.band2Color}}/>
                        </td>
                        <td>
                            <i ref={el => this.$chevronLeft2El = el} className="icon-chevron-left"/>
                        </td>
                        <td ref={el => this.$band2ValueEl = el}>
                            {this.props.band2MaxValue}
                        </td>
                    </tr>
                    <tr ref={el => this.$rowBands3El = el}>
                        <td>
                            <div ref={el => this.$indicatorRound3El = el} className="indicator-round"
                                 style={{background: this.props.band3Color}}/>
                        </td>
                        <td>
                            <i ref={el => this.$chevronLeft3El = el} className="icon-chevron-right"/>
                        </td>
                        <td ref={el => this.$band3ValueEl = el}>
                            {this.props.band2MaxValue}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </center>

        )
    }

}


