import './ChartConf.css';
import React, {Component} from 'react';
import PropTypes from "prop-types";

import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import {ChartMeasure} from "components/ui/analysis/ChartMeasure";
import UiMsg from "components/ui/UiMsg";

class ChartConf extends Component {

    static propTypes = {
        advancedMode: PropTypes.bool
    };

    static defaultProps = {
        advancedMode: true,
    };

    state = {};

    setupDroppable = (ref) => {
        if (!this.props.advancedMode) return;

        j(ref).droppable({
            accept: ".draggable",
            activeClass: 'drag-active',
            hoverClass: 'highlight',
            handle: '.dd-handle'
        });
    };

    async componentDidMount() {
        const self = this;

        application.ice.addNewListener("ChartConf.listener", _.noop, () => {
            const $el = j('#chart-visualizations-trigger');
            if ($el.hasClass('open')) {
                $el.click();
            }
        });

        const data = await j.getJSON('/spr/ui/analysis/chart-conf');
        this.setState({data});

        j('body').on('drop', '.ChartConfDropListener .droppable', function (event, ui) {
            const $el = ui.draggable;
            const $droppable = j(this);

            const measure = $el.data('val');
            const oldPlotType = $el.parent('.droppable').data('val');
            const plotType = $droppable.data('val');

            if (oldPlotType === plotType) {
                return;
            }

            const data = self.state.data;

            const oldConf = data.conf[oldPlotType];
            const conf = data.conf[plotType];

            _.remove(oldConf.selected, e => e === measure);
            _.remove(conf.selected, e => e === measure);

            conf.selected.push(measure);
            conf.props[measure] = oldConf.props[measure];

            delete oldConf.props[measure];
            self.setState({data});
        });
    }

    componentWillUnmount() {
        j('.ChartConfDropListener .droppable').droppable('destroy');
        j('body').off('drop', '.ChartConfDropListener .droppable');
        application.ice.removeListener("ChartConf.listener");
    }

    //This is used to block UI for chart types, the implementation should not be removed cause it can be useful in the future.
    isColumnChart(data) {
        const columnCharts = [];
        return columnCharts.includes(data.chartType)
    };

    isWaterfallChart(data) {
        return data.chartType === 29;
    }

    applyChanges = async () => {
        const result = await j.postJSON('/spr/ui/analysis/chart-conf', {
            enabled: this.state.data.enabled,
            confs: this.state.data.conf
        });
        this.setState({data: result});
        Api.updateJsf();
    };

    render() {
        const {data} = this.state;
        if (!data) {
            return null;
        }

        const isCategoryChart = !!data.plotTypes.find(e => e.value === 'BAR_CHART');
        return (
            <ul className="ChartConfDropListener dropdown-menu pull-right dropdown-caret dropdown-close lazy-init chart-visualization-dropdown"
                style={{width: '500px', padding: '5px', left: 201}}
                onClick={e => e.stopPropagation()}>
                <li className="nav-header">
                    <i className="icon-eye-open"></i>
                    {' '}
                    {this.props.context.msg.t('chart.visualization')}

                    <div style={{
                        display: 'inline-block',
                        position: 'absolute',
                        right: '5px',
                        textTransform: 'capitalize',
                        top: '0px',
                    }}>
                        <Checkbox value={{value: '', label: this.props.context.msg.t('enable.customization')}}
                                  selected={data.enabled && !this.isColumnChart(data) && !this.isWaterfallChart(data)}
                                  onChange={() => {
                                      if (this.isColumnChart(data) || this.isWaterfallChart(data)) {
                                          UiMsg.warn(this.props.context.msg.t('chart.visualization.not.available'));
                                          return;
                                      }
                                      data.enabled = !data.enabled;
                                      this.setState({data});
                                  }}
                                  disabled={!this.props.advancedMode && this.isColumnChart(data) || this.isWaterfallChart(data)}
                        />
                    </div>
                </li>
                <li className="divider first"></li>
                <li>
                    <div className={`chart-visualization-list scrollbar-ff-fix scrollbar-macosx ${data.enabled ? '' : 'disabled'}`}
                         ref={ref => j(ref).scrollbar()}>
                        <table id={"chart-config-table"}
                               ref={ref => this.$table = j(ref)}
                               className={'iceDatTbl crud-table crud-grid-form  chart-visualization-table table-striped table-bordered'}>
                            <colgroup>
                                <col style={{width: '140px'}}/>
                                <col style={{width: '20px'}}/>
                                <col style={{width: '20px'}}/>
                                <col style={{width: '320px'}}/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th>
                                    {this.props.context.msg.t('type')}
                                </th>
                                <th>
                                    {this.props.context.msg.t('order')}
                                </th>
                                <th>
                                    {this.props.context.msg.t('range.axis')}
                                </th>
                                <th>
                                    {this.props.context.msg.t('columns')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.plotTypes.map((plotType, pidx) => {
                                const isNotLabel = plotType.value !== 'LABEL';
                                if (!data.conf[plotType.value]) {
                                    data.conf[plotType.value] = {order: 1, props: {}, range: false, selected: []}
                                }
                                const conf = data.conf[plotType.value];
                                return (
                                    <tr key={pidx}>
                                        <td>
                                            {!isNotLabel &&
                                            <i className="icon-info-sign"
                                               style={{lineHeight: '20px', marginRight: '3px'}}
                                               title={this.props.context.msg.t('tooltip.visualization.hint', [Api.baseUrl()])}></i>
                                            }

                                            {plotType.label}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            <input type="text" value={conf.order} className={'order-input'}
                                                   onChange={e => {
                                                       let val = e.target.value;
                                                       if (_.isNumber(val)) {
                                                           val = _.parseInt(e.target.value);
                                                       }
                                                       conf.order = val;
                                                       this.setState({data});
                                                   }}
                                                   disabled={!this.props.advancedMode}
                                            />
                                        </td>
                                        <td>
                                            <Checkbox selected={conf.range}
                                                      onChange={() => {
                                                          conf.range = !conf.range;
                                                          this.setState({data});
                                                      }}
                                                      disabled={!this.props.advancedMode}
                                            />
                                        </td>
                                        <td>
                                            <div className="droppable" style={{minHeight: '25px'}}
                                                 data-val={plotType.value}
                                                 ref={this.setupDroppable}>
                                                {conf.selected.map((m, midx) => {
                                                    const item = data.members.find(si => si.value === m);
                                                    if (!conf.props[m]) {
                                                        conf.props[m].color = '';
                                                    }
                                                    return (
                                                        <ChartMeasure key={m}
                                                                      value={m}
                                                                      label={item.label}
                                                                      color={conf.props[m].color}
                                                                      onChange={val => {
                                                                          conf.props[m].color = val;
                                                                          this.setState({data});
                                                                      }}
                                                                      labelConf={conf.props[m].labelConf}
                                                                      onChangeLabel={(val, replicateToGroup = false) => {
                                                                          if (replicateToGroup) {
                                                                              for (const key of Object.keys(conf.props)) {
                                                                                  conf.props[key].labelConf = _.cloneDeep(val);
                                                                              }
                                                                          } else {
                                                                              conf.props[m].labelConf = val;
                                                                          }
                                                                          this.setState({data});
                                                                      }}
                                                                      isCategory={isCategoryChart}
                                                                      advancedMode={this.props.advancedMode}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {!data.enabled &&
                        <div className={'overlay'}></div>
                        }
                    </div>
                </li>
                <li className="divider"></li>
                <li>
                    <Button icon="icon-ok" className="btn-mini btn-block" onClick={this.applyChanges} disabled={this.isColumnChart(data)}>
                        {this.props.context.msg.t('apply')}
                    </Button>
                </li>
            </ul>
        );
    }
}

export default ContextEnhancer(ChartConf);
