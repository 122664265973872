import Axios from 'axios';
import bimEventBus from 'BimEventBus';

export const EVENT = 'oAuthCallback';

const oAuthCallback = (e, name, cleanupFn = _.noop) => {
  try {
    document.querySelector('.google-sheets-refresh-token')?.setAttribute('value', e.data);
    document.querySelector('.google-sheets-check-sheets-url')?.click();
    bimEventBus.emit(EVENT, { event: e, name: name });
  } finally {
    cleanupFn();
  }
};

class StructuresPageUtils {
  authenticationRedirect({ uri, params, name }) {
    const left = screen.width / 2 - 600 / 2;
    const top = screen.height / 2 - 700 / 2;

    const url = Axios.getUri({
      url: uri,
      params: params,
    });

    const listenerFnRef = (e) =>
      oAuthCallback(e, name, () => {
        window.removeEventListener('message', listenerFnRef);
      });
    window.addEventListener('message', listenerFnRef);

    window.open(
      url,
      'auth_window',
      `scrollbars=yes,menubar=no,height=700,width=600,left=${left},top=${top},resizable=no,toolbar=no,location=no,status=no`
    );
  }
}

export default new StructuresPageUtils();
