import React from 'react';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import DashLayoutAccordion from 'components/ui/dashboard/DashLayoutAccordion';
import useBimContext from 'components/hooks/useBimContext';

export default function LayoutMenuItem({ toggleAccordion = _.noop, ...props }) {
  const context = useBimContext();

  const toggleLayoutAccordion = async (event) => {
    return toggleAccordion(DashLayoutAccordion, 'LayoutMenuItem');
  };

  return (
    <RightMenuItem
      title={context.msg.t('styles')}
      icon="style"
      className="LayoutMenuItem"
      onClick={toggleLayoutAccordion}
      {...props}
    />
  );
}
