import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import BreadcrumbToolbar from './BreadcrumbToolbar';

class ReloadToolbarItem extends PureComponent {

    static propTypes = {
        disabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
        onClick: _.noop
    };

    state = {
        loading: false
    };

    constructor(props) {
        super(props);
    }

    clickHandler = async (e) => {
        this.setState({loading: true});
        try {
            await this.props.onClick(e);
        } finally {
            setTimeout(
                () => this.setState({loading: false}),
                5000
            );
        }
    }

    render() {
        const {disabled, onClick, ...props} = this.props;
        return (
            <BreadcrumbToolbar.Item icon="zoom_out_map"
                                    title={this.props.context.msg.t('adjust.object.to.viewport')}
                                    disabled={disabled || this.state.loading}
                                    onClick={this.clickHandler}
                                    {...props}/>
        );
    }
}

export default ContextEnhancer(ReloadToolbarItem);
