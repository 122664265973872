import './BngSuggestion.css';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from "formik";
import noop from 'lodash/noop'

import Api from "components/Api";
import { BngForm } from "components/bng/form/BngForm";
import { BngField } from "components/bng/form/BngField";
import BngTextArea from "components/bng/form/BngTextArea";
import { bngYup } from "components/bng/form/yup/BngYup";
import Button from "components/ui/Button";
import ContextEnhancer from "components/ContextEnhancer";

const SuggestionSchema = bngYup(yup => yup.object().shape({
    title: yup.string().required().default(''),
    description: yup.string().required().default('')
}));

const BngSuggestion = ({
                           img,
                           title,
                           message,
                           inputTitleLabel,
                           inputTitlePlaceholder,
                           inputDescriptionPlaceholder,
                           onSubmit = noop,
                           context
                       }) => {
    const [isSuggestionSent, setSuggestionSent] = useState(false);

    const handleSubmit = (values, actions) => {
        onSubmit(values, actions)
        setSuggestionSent(true)
    }

    return (
        <div className='BngSuggestion'>
            <div className="Container">
                <img className="Image"
                     src={img || `${Api.baseUrl()}/resources/images/suggestion-box.png`}
                     alt="Suggestion box"
                />
                {isSuggestionSent
                    ? <h4 className="TitleSuccess">{context.msg.t('BngSuggestion.sent.success')}</h4>
                    : <>
                        {title &&
                        <div className="Title">
                            {title}
                        </div>
                        }

                        {message &&
                        <div className="Message">
                            {message}
                        </div>
                        }

                        <Formik initialValues={SuggestionSchema.default()}
                                onSubmit={handleSubmit}
                                validationSchema={SuggestionSchema}
                                enableReinitialize={true}>
                            {({ isValid}) => (
                                <>
                                    <BngForm className="BngSuggestionForm">
                                        <Field key={'title'}
                                               name={'title'}
                                               label={inputTitleLabel || context.msg.t('BngEmpty.title')}
                                               placeholder={inputTitlePlaceholder || context.msg.t('BngSuggestion.default.title.placeholder')}
                                               component={BngField}
                                        />
                                        <Field key={'description'}
                                               name={'description'}
                                               placeholder={inputDescriptionPlaceholder || context.msg.t('BngSuggestion.default.description.placeholder')}
                                               rows={3}
                                               component={BngTextArea}
                                        />
                                        <div className="BngSuggestionButtonWrapper">
                                            <Button type="submit"
                                                    className={'BngButtonSuggestion'}
                                                    disabled={!isValid}
                                                    data-test={"SubmitFormButton"}
                                            >
                                                {context.msg.t('BngSuggestion.submit.suggestion')}
                                            </Button>
                                        </div>
                                    </BngForm>
                                </>
                            )}
                        </Formik>
                    </>
                }
            </div>
        </div>
    );
};

BngSuggestion.propTypes = {
    img: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    inputTitleLabel: PropTypes.string,
    inputTitlePlaceholder: PropTypes.string,
    inputDescriptionPlaceholder: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
}

export default ContextEnhancer(BngSuggestion);
