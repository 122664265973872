import styles from './SecurityCheckupMain.module.css';
import AdaClosedHandImg from './assets/bimachine_shield.png';

import React from 'react';
import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';
import CheckupHeader from 'components/bng/securityCheckup/components/CheckupHeader';

function ActionCardStrip({ icon, title, description }) {
  const context = useBimContext();
  return (
    <div className={`ActionCardStrip ${styles.cardStripContainer}`}>
      <div className={`${styles.cardIcon}`}>
        <Icon icon={icon} />
      </div>
      <div className={`${styles.cardText}`}>
        <span>
          <strong>{context.msg.t(title)}: </strong>
          {context.msg.t(description)}
        </span>
      </div>
    </div>
  );
}

export default function SecurityCheckupMain({ cards }) {
  const context = useBimContext();

  return (
    <div className={`SecurityCheckupMain ${styles.mainWrapper}`}>
      <div className={`${styles.bimShieldContainer}`}>
        <img className={`${styles.bimShield}`} alt={'Escudo BIMachine'} src={AdaClosedHandImg} />
      </div>
      <div className={`${styles.bodyContainer}`}>
        <CheckupHeader
          className={styles.headerDesc}
          title={context.msg.t('checkup.main.title')}
          description={context.msg.t('checkup.main.desc')}
        />
        <div className={`${styles.cardsContainer}`}>
          {cards
            .filter((card) => card.hasOwnProperty('summaryTitleKey'))
            .map((card, idx) => (
              <ActionCardStrip
                key={`card_${idx}`}
                icon={card.summaryIcon}
                title={card.summaryTitleKey}
                description={card.summaryDescriptionKey}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
