import { Axios } from 'commonUtils';

class EventApi {
  static PROCESS_EVENTS = 'EventApi:getAndProcessEvents';

  static async getAndProcessEvents() {
    const data = await Axios.getData('/spr/ui/events');

    for (const js of data) {
      try {
        eval(js);
      } catch (e) {
        console.error('Error  while evaluating event', { js }, e);
      }
    }

    j('.jsf-react-load-placeholder').remove();
  }
}

export default EventApi;
