import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

// This hook is used to create portal for Draggable items that are being dragged.
// The way react-beautiful-dnd works is by setting a 'clone' of the object that follows the mouse while being dragged and hiding the original one
// This approach breaks when the droppable area is inside a dropdown with a relative/absolute position or when the outer div needs to have a scrollbar
// This hook fix this by creating a portal for inDrag items outside the droppable area, but which the correct positions.
// This hook also enables auto scrolling by default
// See: https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-669083882

const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.zIndex = '9999999';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render) => (provided, ...args) => {
    const element = render(provided, ...args);
    if (provided.draggableProps.style.position === 'fixed') {
      return createPortal(element, self.elt);
    }
    return element;
  };
};

export default useDraggableInPortal;
