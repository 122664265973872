import styles from './ProjectBlockAccessTimeDialog.module.css';

import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngField from 'components/bng/form/BngField';
import BngButton from 'components/bng/ui/BngButton';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import useFetchData from 'components/hooks/useFetchData';
import useTranslation from 'components/hooks/useTranslation';
import BngTable from 'components/bng/ui/BngTable';
import LinkedObjects from 'components/ui/LinkedObjects';
import Icon from 'components/ui/common/Icon';
import UiMsg from 'components/ui/UiMsg';
import bngYup from 'components/bng/form/yup/BngYup';

const DialogSchema = bngYup((yup) =>
  yup.object({
    project: yup.object().required(),
  })
);

export default function ProjectBlockAccessTimeDialog({ checks }) {
  const { t } = useTranslation();
  const context = useBimContext();

  const { data: projectsOpts = [], isLoading } = useFetchData(async () => {
    const projects = await Api.Navbar.projectsMenuDetails();
    return projects.map((acc) => ({ label: acc.displayName, value: acc }));
  });

  const restrictions = useMemo(() => checks.map((c) => c.restriction), [checks]);

  const weekDay = useMemo(() => {
    return [
      { value: 0, label: t('dayOfTheWeek.7') },
      { value: 1, label: t('dayOfTheWeek.1') },
      { value: 2, label: t('dayOfTheWeek.2') },
      { value: 3, label: t('dayOfTheWeek.3') },
      { value: 4, label: t('dayOfTheWeek.4') },
      { value: 5, label: t('dayOfTheWeek.5') },
      { value: 6, label: t('dayOfTheWeek.6') },
    ];
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        key: 'days',
        label: t('released.days'),
        render: (row) => (
          <div className="div-information w-100">
            <span className="item-slide-name w-100">
              <LinkedObjects
                itemWidth={40}
                itemNumber={7}
                items={weekDay}
                render={(item, index) => (
                  <div
                    key={index}
                    className={`${
                      row.props.weekDays.includes(item.value) ? styles.releasedDays : styles.restrictionDays
                    } ${styles.day}`}
                    title={item.label}
                  >
                    {item.label.substring(0, 1)}
                  </div>
                )}
              />
            </span>
          </div>
        ),
        rowClassName: styles.days,
      },
      {
        key: 'schedules',
        label: t('release.times'),
        render: (row) => (
          row.props.timeRanges.map((se, index) => (
            <span key={index}>
              {t('time.interval', [se.starts, se.ends])}
              <br />
            </span>
          ))
        ),
      },
    ];
  }, []);

  return (
    <Formik
      initialValues={{ project: '' }}
      validationSchema={DialogSchema}
      onSubmit={async (values) => {
        try {
          const url = await Api.Bng.accessProjectUrl(values.project.name);
          window.location.replace(url);
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Dialog hideHeader={true} className="ProjectBlockAccessTimeDialog large" loading={isLoading || isSubmitting}>
          <Dialog.Body className={styles.container}>
            <Icon icon="lock_clock" className={styles.lockClock} />
            <label className={styles.otherProject}>
              {`${t('restricted.project.environment', context.project.displayName)}:`}
            </label>
            <div className={styles.table}>
              <BngTable rows={restrictions} cols={tableColumns} />
            </div>
            <label>
              {t('adm.contact')}{' '}
              <a href="https://support.bimachine.com/sobre-a-extensao-restricao-de-acesso-data-e-hora/" target="_blank" rel="noopener noreferrer">
                {t('know.more')}
              </a>
              .
            </label>
            <label>{t('go.to.another.project')}</label>
            <Form>
              <div className={styles.inputs}>
                <Field
                  name="project"
                  label=""
                  component={BngField}
                  inputComponent={BngSelectSearch}
                  showErrors={false}
                  options={projectsOpts}
                  className={styles.selectSearch}
                />
                <BngButton btnType="submit" disabled={isSubmitting} className={styles.submitButton}>
                  {t('project.expired.dialog.open.project')}
                </BngButton>
              </div>
            </Form>
            <BngButton className={styles.btnDanger} onClick={Api.User.logout}>
              {t('logout.bimachine')}
            </BngButton>
          </Dialog.Body>
        </Dialog>
      )}
    </Formik>
  );
}
