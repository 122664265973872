import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import useBimContext from 'components/hooks/useBimContext';
import appStartupChecks from 'components/appStartupChecks';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import routes from 'bng/routes';

export default function BngApp({ store }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  useEffect(() => {
    window.__BNG_APP = true;

    appStartupChecks({
      context,
      dispatch,
    });

    return () => {
      delete window.__BNG_APP;
    };
  }, []);

  return (
    <Provider store={store}>
      <div className="BngApp">
        <RouterProvider router={routes} />
      </div>
    </Provider>
  );
}
