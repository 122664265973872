import {Axios} from "commonUtils";

class UserGroupApi {

    static async findProjectUserGroup(groupId) {
        const {data} = await Axios.get(`/spr/user-group/${groupId}/user-group`);
        return data;
    }

    static async findUsersInProject(projectId) {
        const {data} = await Axios.get(`/spr/user-group/${projectId}/user-json`);
        return data;
    }

    static async findProjectUser(projectId, userId) {
        const {data} = await Axios.get(`/spr/user-group/${projectId}/project-user/${userId}`);
        return data;
    }

    static async saveUserGroup(group) {
        await Axios.post(`/spr/user-group/save`, group);
    }

    static async deleteUserGroup(groupId) {
        const {data} = await Axios.delete(`/spr/user-group/${groupId}`);
        return data;
    }

    static async findAllUserGroups(projectId) {
        const {data} = await Axios.get(`/spr/user-group/groups/${projectId}`);
        return data;
    }

    static async addUserToGroup(projectId, userId, groupsToAdd, groupsToRemove) {
        const reqBody = {userId, groupsToAdd, groupsToRemove};
        const {data} = await Axios.post(`/spr/user-group/${projectId}/add-user`, reqBody);
        return data;
    }
}

export default UserGroupApi;