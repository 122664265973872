import styles from './CreateUserDialog.module.css';

import React from 'react';
import { FastField, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';

import { BngForm } from 'components/bng/form/BngForm';
import { BngField } from 'components/bng/form/BngField';
import { bngYup } from 'components/bng/form/yup/BngYup';
import UiMsg from 'components/ui/UiMsg';
import { MODALS } from 'components/ui/redux/Actions';
import ProfileDialog from 'components/ui/profile/ProfileDialog';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

const NewUserSchema = bngYup((yup) => {
  return yup.object().shape({
    email: yup.string().email().required().default(''),
  });
});

export default function CreateUserDialog({ closeModal = _.noop, beforeClose = _.noop }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const createUser = async (email) => {
    try {
      const returnedEmail = await Api.Project.inviteUserIfExists(context.project.id, email);

      if (returnedEmail) {
        dispatch(
          MODALS.open(ProfileDialog, {
            email,
            onSave: async () => {
              closeModal();
              await beforeClose();
            },
          })
        );
      } else {
        UiMsg.ok(context.msg.t('project.invitation.sent'));
        closeModal();
        await beforeClose();
      }
    } catch (e) {
      console.error('Error on CreateUserDialog.createUser', { email }, e);
      switch (e.response?.status) {
        case 409:
          UiMsg.error(context.msg.t('user_added_in_project'));
          break;
        case 402:
          UiMsg.error(context.msg.t('account.user.limit.reached'));
          break;
        default:
          console.error(e);
          UiMsg.error(e.message);
      }
    }
  };

  return (
    <Formik
      initialValues={NewUserSchema.default()}
      validationSchema={NewUserSchema}
      onSubmit={async ({ email }) => await createUser(email.toLowerCase())}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formikProps) => (
        <Dialog
          className={styles.CreateUserDialog}
          title={context.msg.t('create.user')}
          newDialogLayout={true}
          onClose={closeModal}
          loading={formikProps.isSubmitting}
          verticalCenter={true}
        >
          <BngForm>
            <Dialog.Body>
              <FastField name="email" component={BngField} label={context.msg.t('email')} autoFocus required />
            </Dialog.Body>
            <Dialog.Footer>
              <DefaultDialogActions context={context} okLabel={'create'} closeModal={closeModal} />
            </Dialog.Footer>
          </BngForm>
        </Dialog>
      )}
    </Formik>
  );
}
