import './ServerErrorPage.css';
import image from './assets/ServerErrorImg.png';

import React, { useEffect } from 'react';
import { useFetchVersion } from 'components/ui/right-menu/items/GeneralConfigMenuItem';
import Button from 'components/ui/Button';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { BngPanel, BngPanelHeader } from 'components/bng/ui/BngPanel';
import { VersionAccordion } from 'components/ui/common/ConfigureAccordion';
import BaseErrorPage from 'components/bng/pages/errors/BaseErrorPage';
import StackTraceDialog from 'components/bng/pages/errors/StackTraceDialog';
import useBimContext from 'components/hooks/useBimContext';
import useTranslation from 'components/hooks/useTranslation';

export default function ServerErrorPage({
  className = '',
  path = j.QueryString.content,
  stackTrace,
  outOfApp = false,
  requestId,
}) {
  const context = useBimContext();
  const { t } = useTranslation();
  const [versions, fetchVersions] = useFetchVersion({ path, context });

  const containPath = !outOfApp && !_.isEmpty(path);

  useEffect(() => {
    if (containPath) {
      fetchVersions();
    }
  }, []);

  let description = `${t('ServerErrorPage.message1')}. ${t('ServerErrorPage.message2')}`;

  if (containPath) {
    description += ` ${t('ServerErrorPage.restoreMessage')}`;
  }
  description += '.';

  const openStackTraceDialog = () => {
    StackTraceDialog({
      title: 'Log',
      html: stackTrace,
      requestId,
    });
  };

  const onErrorPage = window.location.pathname.includes('500.');

  const refreshAction = () => {
    if (onErrorPage) {
      window.history.back();
    } else {
      window.location.reload();
    }
  };

  return (
    <BaseErrorPage
      className={`ServerErrorPage ${outOfApp ? 'OutOfApp' : ''} ${className}`}
      image={image}
      title={t('something.strange.happened')}
      description={description}
    >
      <div className={`mb-3 `}>
        <Button onClick={refreshAction} className={'bng-button fix save Action'}>
          {t(onErrorPage ? 'refresh.page' : 'try.again')}
        </Button>
      </div>

      {stackTrace && (
        <div className={`mb-3 `}>
          <Button onClick={openStackTraceDialog} className={'bng-button fix save Action'}>
            {t('see.task.stack.trace')}
          </Button>
        </div>
      )}

      {containPath && (
        <>
          <div className={`Description mb-1`}>{t('OR').toLowerCase()}</div>

          <div>
            <BngDropdown
              popperOpts={{
                placement: 'right-start',
              }}
              popperClassName={'ServerErrorPagePopper'}
              customButton={({ openDropdown }) => {
                return (
                  <Button className={'btn-link bng-button fix'} icon={'arrow_right_alt'} onClick={openDropdown}>
                    {t('restorePreviousVersion')}
                  </Button>
                );
              }}
              customOptions={() => (
                <BngPanel>
                  <BngPanelHeader title={t('versions')} />
                  <VersionAccordion
                    path={path}
                    fetchVersions={fetchVersions}
                    versions={versions}
                    accordionProps={{
                      startOpen: true,
                      preventToggle: true,
                    }}
                  />
                </BngPanel>
              )}
            />
          </div>
        </>
      )}
    </BaseErrorPage>
  );
}
