import 'components/ui/publisher/DropdownTable.css';

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import OpConfirmation from "components/ui/OpConfirmation";
import {MODALS} from "components/ui/redux/Actions";
import {BngDropdown} from "components/bng/ui/BngDropdown";
import PublisherIncorporateLink from "components/ui/publisher/PublisherIncorporateLink";

class DropdownTable extends Component {

    static propTypes = {
        onDelete: PropTypes.func,
        dispatch: PropTypes.func,
        path: PropTypes.string,
        caption: PropTypes.string,
        id: PropTypes.number,
    };

    state = {
        displayMenu: false,
        open: false,
    };

    openIncorporateDialog = () => {
        const {path, caption} = this.props;
        this.props.dispatch(MODALS.open(PublisherIncorporateLink, {
            path,
            caption,
            onUpdate: this.props.onDelete
        }));
    };

    openOptions = (event) => {
        this.setState({
            popperRef: event.currentTarget,
            open: true,
        });
    };

    removePublish = () => {
        OpConfirmation({
            title: this.props.caption,
            message: this.props.context.msg.t('published.object.remove.confirmation'),
            onConfirm: async () => {
                await Api.Publisher.removePublisher(this.props.id);
                this.props.onDelete();
            },
            msg: this.props.context.msg
        })
    };

    publisherOpts = [
        {icon: 'edit', label: this.props.context.msg.t('edit'), onClick: this.openIncorporateDialog},
        {icon: 'delete', label: this.props.context.msg.t('unpublish'), onClick: this.removePublish},
    ];

    render() {
        const container = j('.Publisher-list-dialog')[0];
        return (
            <div style={{position: 'relative'}}>
                <a className="dropdown-menu-tst">
                    <BngDropdown container={container}
                                 icon="more_vert"
                                 options={this.publisherOpts}
                    />
                </a>
            </div>
        );
    }

}

export default ContextEnhancer(connect()(DropdownTable));
