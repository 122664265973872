import './ReduxFormSelect.css';
import React from 'react';
import PropTypes from 'prop-types';

export class ReduxFormSelect extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        options: PropTypes.array,
    };

    static defaultProps = {
        style: {},
        className: '',
        options: [],
    };

    render() {
        const {className, options, ...props} = this.props;
        return (
            <div className={`ReduxFormSelect ${className}`}>
                <select {...props}>
                    {this.props.children}
                    {options.map(({value, label}, idx) => (
                        <option key={idx} value={value}>{label}</option>
                    ))}
                </select>
            </div>
        );
    }

}