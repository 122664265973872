import styles from './UserFilterFormDialog.module.css';

import React, { useMemo, useState } from 'react';

import { DefaultDialogActions } from 'components/ui/FormUtils';
import Dialog from 'components/ui/Dialog';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngSearch from 'components/bng/ui/BngSearch';
import BngAvatar from 'components/bng/ui/BngAvatar';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import useTranslation from 'components/hooks/useTranslation';
import Utils from 'components/Utils';
import useFetchData from 'components/hooks/useFetchData';
import { MODALS } from 'components/ui/redux/Actions';
import UserGroupsDialog from 'components/bng/pages/admin/users/UserGroupsDialog';
import Icon from 'components/ui/common/Icon';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

function UserAndMembersListSection({ title, members, onToggle, isMember = false, onGroupChanged = _.noop }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const [search, setSearch] = useState('');

  const filteredMembers = useMemo(() => {
    return members.filter((member) => {
      const s = search.toLowerCase();
      return (member.label ?? member.name ?? '').toLowerCase().includes(s);
    });
  }, [search, members]);

  const openUserGroupsDialog = (props = {}) => {
    dispatch(
      MODALS.open(UserGroupsDialog, {
        ...props,
        showDeleteButton: false,
        afterSubmit: onGroupChanged,
      })
    );
  };

  return (
    <div className={`UserAndMembersListSection ${styles.usersListWrapper}`}>
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <div className={styles.usersListContainer}>
        <BngSearch
          className="pl-1"
          value={search}
          onChange={(val) => setSearch(val)}
          side="left"
          maxLength="128"
          inline
          placeholder={t('search')}
        />
        {filteredMembers.map((member, index) => {
          const isGroup = member.hasOwnProperty('users');
          return (
            <div
              className={`usersListItem ${styles.usersListItem} flex-center-items`}
              key={`${member.type}-${member.id ?? member.value}-${index}`}
            >
              <BngCheckbox
                field={{
                  value: member.selected,
                  onChange: () => {
                    onToggle({ selected: !member.selected, member });
                  },
                }}
                label={isMember ? member.label : ''}
                title={isMember ? member.label : ''}
              />
              {!isMember && (
                <>
                  <BngAvatar userId={member.id} isGroup={member.hasOwnProperty('users')} className="d-r-m" />
                  <span className="d-l-m d-flex align-items-center">
                    {isGroup && <strong className="d-r-m">{t('group')}</strong>}
                    {member.name}
                    {isGroup && (
                      <Icon
                        icon="visibility"
                        className={styles.eyeUserGroupsDialog}
                        onClick={() =>
                          openUserGroupsDialog({
                            groupId: member.id,
                            isEditing: true,
                          })
                        }
                      />
                    )}
                  </span>
                </>
              )}
            </div>
          );
        })}
        {!isMember && (
          <div
            className={`${styles.usersListItem} ${styles.createGroupButton} flex-center-items`}
            onClick={() => openUserGroupsDialog()}
          >
            <Icon icon="add_circle" />
            <span>{t('new.group')}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default function UserFilterFormDialog({
  closeModal = _.noop,
  filter,
  row = null,
  usersAndGroups,
  onChange = _.noop,
  onGroupChanged = _.noop,
}) {
  const { t } = useTranslation();

  const [selectedUsersAndGroups, setSelectedUsersAndGroups] = useState({
    groups: row ? row.accessConfig.groups.slice() : [],
    users: row ? row.accessConfig.users.slice() : [],
  });
  const [selectedMdxMembers, setSelectedMdxMembers] = useState(row ? row.memberReferences.map((mr) => mr.member) : []);

  const projectUsers = useMemo(() => {
    const users = [
      ...usersAndGroups.groups.map((g) => ({
        ...g,
        selected: selectedUsersAndGroups.groups.includes(g.id),
        type: 'groups',
      })),
      ...usersAndGroups.projectUsers.map((pu) => ({
        id: pu.user.id,
        name: Utils.Users.displayName(pu.user),
        email: pu.user.email,
        selected: selectedUsersAndGroups.users.includes(pu.user.id),
        type: 'users',
      })),
    ];

    users.sort((a, b) => {
      if (a.type === b.type) {
        return a.name.localeCompare(b.name);
      }
      return a.type === 'groups' ? -1 : 1;
    });

    users.sort((a, b) => {
      if (a.selected === b.selected) {
        return 0;
      }
      return a.selected ? -1 : 1;
    });

    return users;
  }, [usersAndGroups, selectedUsersAndGroups]);

  const { data: mdxMembers = [], isLoading } = useFetchData(async () => {
    try {
      const result = await Api.Filter.findMembers(filter.id, { allMembers: true });
      return result.members;
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  }, []);

  const sortedMdxMembers = useMemo(() => {
    const members = mdxMembers.map((m) => ({ ...m, selected: selectedMdxMembers.includes(m.value) }));
    members.sort((a, b) => {
      if (a.selected === b.selected) {
        return 0;
      }
      return a.selected ? -1 : 1;
    });
    return members;
  }, [mdxMembers, selectedMdxMembers]);

  const validForSubmit =
    selectedUsersAndGroups.groups.length + selectedUsersAndGroups.users.length > 0 && selectedMdxMembers.length > 0;

  return (
    <Dialog
      className={`UserFilterFormDialog ${styles.UserFilterFormDialog} large`}
      title={row ? t('edit.user.filter', filter.name) : t('new.user.filter')}
      onClose={closeModal}
      loading={isLoading}
    >
      <Dialog.Body>
        <div className="flex-center-items">
          <UserAndMembersListSection
            title={t('userFilter.userList.description')}
            members={projectUsers}
            onToggle={({ selected, member }) => {
              const copy = _.cloneDeep(selectedUsersAndGroups);
              const list = copy[member.type];
              if (selected) {
                list.push(member.id);
              } else {
                const idx = list.indexOf(member.id);
                list.splice(idx, 1);
              }
              setSelectedUsersAndGroups(copy);
            }}
            onGroupChanged={onGroupChanged}
          />

          <UserAndMembersListSection
            title={t('userFilter.memberList.description')}
            members={sortedMdxMembers}
            onToggle={({ selected, member }) => {
              const copy = selectedMdxMembers.slice();
              if (selected) {
                copy.push(member.value);
              } else {
                const idx = copy.indexOf(member.value);
                copy.splice(idx, 1);
              }
              setSelectedMdxMembers(copy);
            }}
            isMember
          />
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <DefaultDialogActions
          closeModal={closeModal}
          okLabel={t('apply_button')}
          title={t('apply_button')}
          disabled={!validForSubmit}
          onClickSaveButton={async () => {
            try {
              await onChange({
                ...selectedUsersAndGroups,
                mdxMembers: selectedMdxMembers,
              });
              closeModal();
            } catch (e) {
              console.error(e);
              UiMsg.error(t('error'), e);
            }
          }}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
