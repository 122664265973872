import React from 'react';

import ContextEnhancer from 'components/ContextEnhancer';
import BngAlert from 'components/bng/ui/BngAlert';
import { BngTable } from 'components/bng/ui/BngTable';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';

const ObjectNameRender = ({ caption, path }) => {
  return (
    <div className="ObjectNameRender flex-center-items">
      <Icon icon={Utils.Object.getObjectIcon(path) || 'table_chart'} />
      <span className="ml-4">{caption}</span>
    </div>
  );
};

export const TableCheckbox = ({
  label,
  labelVisible = true,
  labelStyle,
  value,
  onChange,
  ...props
}) => {

  const labelStyleWithVisibility = { ...(labelVisible ? undefined : { visibility: 'hidden' }), ...labelStyle };

  return (
    <div className={'flex-center-items'}>
      <div style={labelStyleWithVisibility}>
        {label}
      </div>
      <div className="ml-2">
        <BngCheckbox
          field={{
            value,
            onChange,
          }}
          {...props}
        />
      </div>
    </div>
  );
};

const BaseTableStep = ContextEnhancer(
  ({ context: { msg }, className = '', alertMessage, rows, cols = [] }) => {
    return (
      <div className={`BaseTableStep ${className}`}>
        {alertMessage && (
          <BngAlert>
            <div
              dangerouslySetInnerHTML={{
                __html: alertMessage,
              }}
            />
          </BngAlert>
        )}
        <div className="TableContainer mt-4">
          <BngTable
            stickyHeader
            rows={rows}
            cols={[
              {
                key: 'name',
                label: msg.t('name'),
                render: (row) => {
                  return (
                    <ObjectNameRender
                      caption={row.sourceCaption || row.targetCaption}
                      path={row.sourcePath || row.targetPath}
                    />
                  );
                },
              },
            ].concat(cols)}
            showEmptyAlert
            emptyAlertProps={{
                showTitle: false,
                message: msg.t('no.modifications.found'),
            }}
          />
        </div>
      </div>
    );
  }
);

export default BaseTableStep;
