import './PresentationListDialog.css';

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Field, Formik} from "formik";

import ContextEnhancer from 'components/ContextEnhancer';
import Dialog from 'components/ui/Dialog';
import { Tab, TabSet } from 'components/ui/TabSet';
import MyPresentationList from 'components/ui/presentation/MyPresentationList';
import { MODALS } from 'components/ui/redux/Actions';
import CreatePresentation from 'components/ui/presentation/CreatePresentation';
import Icon from 'components/ui/common/Icon';
import {BngDropdown} from 'components/bng/ui/BngDropdown';
import {UiBlocker} from 'components/bng/ui/UiBlocker';
import {BngForm} from 'components/bng/form/BngForm';
import {FormikListener} from 'components/bng/form/formik/FormikListener';
import {bngYup} from 'components/bng/form/yup/BngYup';
import useBimContext from 'components/hooks/useBimContext';
import {BngSelectSearch} from 'components/bng/form/BngSelectSearch';
import {BngField} from 'components/bng/form/BngField';
import BngSearch from "components/bng/ui/BngSearch";
import UiMsg from 'components/ui/UiMsg';
import Api from "components/Api";

class PresentationListDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true)
    };

    state = {
        sharedPresentations: null,
        loading: false,
        account: '',
        shared: '',
        nameOwnerFilter: '',
        presentations: [],
        availableAccounts: [],
    };

    constructor(props) {
        super(props);
        this.presentationList = React.createRef();
    }

    openCreatePresentationDialog = async () => {
        this.props.dispatch(MODALS.open(CreatePresentation, {
            refreshPresentations: this.findPresentations,
        }));
    };

    onFilterChange = (filter) => {
        this.setState({...filter});
    }

    findPresentations = async () => {
        try {
            this.setState({loading: true});
            const {content} = await Api.Presentation.findByOwner();
            content.forEach(c => {
                if (c.accountId === null) {
                    c.accountId = 'null';
                }
            })
            this.setState({presentations: content})
        } catch (e) {
            console.error('Error on findPresentations()', e);
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    fetchAccounts = async () => {
        try {
            this.setState({loading: true});
            const accounts = await Api.Account.findAvailable({includeRelated: true});
            const accountOpts = accounts.map(acc => {
                return {
                    value: acc.id,
                    label: acc.name,
                    account: acc,
                }
            });
            accountOpts.unshift({
                value: 'null',
                label: this.props.context.msg.t('without_account')
            })
            this.setState({availableAccounts: accountOpts});
        } catch (e) {
            console.error('Error fetching available accounts', e);
            UiMsg.ajaxError(null, e)
        } finally {
            this.setState({loading: false});
        }
    }

    render() {
        const filters={
            account: this.state.account,
            shared: this.state.shared,
            nameOwnerFilter: this.state.nameOwnerFilter
        }
        return (
            <Dialog contentFullWidth={true} className="PresentationListDialog Presentation-list-dialog large"
                    open={this.props.open}
                    title={this.props.context.msg.t('presentations')} loading={this.state.loading}
                    onClose={this.props.closeModal}>
                <div className='PresentationButtonsWrapperComponent' style={{marginRight: this.props.context.permissions.isAtLeastExpert() ? 0 : '10px'}}>
                    <BngSearch className='PresentationSearch' onChange={event => this.setState({nameOwnerFilter: event})}/>
                    <PresentationFilterDropdown onChange={this.onFilterChange}
                                                fetchAccounts={this.fetchAccounts}
                                                availableAccounts={this.state.availableAccounts}
                                                loading={this.state.loading}
                                                initialValues={filters}/>
                    {this.props.context.permissions.isAtLeastExpert() &&
                    <div className="button-create-presentation" onClick={this.openCreatePresentationDialog}>
                        <Icon icon="add_circle"/>
                        <label className="label-add-link">{this.props.context.msg.t('new.slide.show')}</label>
                    </div>
                    }
                </div>
                <TabSet className="grey-bg PresentationTabSet" bodyRadius={false} vertical={false}>
                    <Tab label={this.props.context.msg.t('my.presentations')}
                         icon="person">
                        <MyPresentationList ref={this.presentationList}
                                            setLoading={(loading) => this.setState({loading})}
                                            filters={filters}
                                            findPresentations={this.findPresentations}
                                            presentations={this.state.presentations}
                        />
                    </Tab>
                </TabSet>
            </Dialog>
        );
    }
}

const PresentationFilterDropdownSchema = bngYup((yup) => {
    return yup.object().shape({
        account: yup.number().nullable().default(''),
        shared: yup.number().nullable().default(''),
    });
});

function PresentationFilterDropdown({
                                        onChange = _.noop,
                                        fetchAccounts = _.noop,
                                        availableAccounts = [],
                                        loading = false,
                                        initialValues = {}}) {

    const {msg} = useBimContext();

    const isFiltering = useMemo(
        () => initialValues.account !== '' || initialValues.shared !== '',
        [initialValues]
    );
    const initialFormValues = useMemo(
        () => _.merge({}, PresentationFilterDropdownSchema.default(), initialValues),
        [initialValues]
    );

    const sharedOptions = useMemo(() =>[
        {
            value: 1,
            label: msg.t('presentation.filter.option.notShared'),
            render: () => msg.t('presentation.filter.option.isShared'),
        },
        {
            value: 2,
            label: msg.t('presentation.filter.option.notShared'),
            render: () => msg.t('presentation.filter.option.notShared'),
        }
    ], []);

    return(
        <BngDropdown
            title={msg.t('filter')}
            icon='filter_alt'
            className={`PresentationFilterDropdown ${isFiltering ? 'Filtered' : ''}`}
            popperClassName={`PresentationFilterDropdownPopper`}
            onOpen={() => {
                if (_.isEmpty(availableAccounts)) {
                    fetchAccounts();
                }
            }}
            customOptions={({closeDropdown}) => {
                return (
                    <div className="bng-dropdown">
                        <Formik
                            initialValues={initialFormValues}
                            validationSchema={PresentationFilterDropdownSchema}
                            onSubmit={(values, formikHelpers) => {
                                onChange({...values});
                                closeDropdown();
                            }}>
                            <UiBlocker block={loading}>
                                <BngForm>
                                    <FormikListener
                                        onChange={(current, previous) => {
                                            if (_.isEqual(current.values, previous.values)) return;
                                            onChange({...current.values});
                                        }}
                                    />
                                    <div className="flex-center-items mb-4">
                                        <Icon icon='filter_alt' className='mr-1'/>
                                        {msg.t('filter')}
                                    </div>
                                    <Field name='account' className={'PresentationFilterOption'} component={BngField} inputComponent={BngSelectSearch} options={availableAccounts}/>
                                    <Field name='shared' className={'PresentationFilterOption'} component={BngField} inputComponent={BngSelectSearch} options={sharedOptions}/>
                                </BngForm>
                            </UiBlocker>
                        </Formik>
                    </div>
                );
            }}
        />
    );
}

export default ContextEnhancer(connect()(PresentationListDialog));
