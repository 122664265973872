import './NoteView.css';
import React from 'react';
import PropTypes from 'prop-types';
import { FastField, Form, Formik } from 'formik';

import { ScrollContainer } from 'components/ui/ScrollContainer';
import ContextEnhancer from 'components/ContextEnhancer';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import { BngMention } from 'components/bng/form/BngMention';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BngPopper from 'components/bng/ui/BngPopper';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import Api from 'components/Api';
import { ceData } from 'components/CeData';
import BngEmpty from 'components/bng/ui/BngEmpty';
import { BngError } from 'components/bng/ui/BngError';
import { BngPanel, BngPanelFooter, BngPanelHeader } from 'components/bng/ui/BngPanel';
import MessageComponents from 'components/ui/ModalInformation';
import Icon from 'components/ui/common/Icon';

function ChatMessage({ name, avatar, message, date, deleteComment, invert = false }) {
  if (_.isString(avatar)) {
    avatar = <img src={avatar} alt="Avatar" />;
  }

  if (_.isString(message)) {
    message = <div dangerouslySetInnerHTML={{ __html: message }} />;
  }

  return (
    <div className={`ChatMessage ${invert ? 'invert' : ''}`}>
      <div className="Arrow"></div>
      <div className="Avatar">{avatar}</div>
      <div className="Message">
        {!invert && <div className="Name">{name}:</div>}
        {message}
        <div className="Details">
          <Icon icon="delete_outline" className="Delete" onClick={invert ? deleteComment : _.noop()} />
          <time>{date ? date.format('HH:mm') : '-'}</time>
        </div>
      </div>
    </div>
  );
}

const MsgFormSchema = bngYup((yup) => {
  return yup.object().shape({
    message: yup.string().required().min(1).max(4096).trim().default(''),
    messageMentions: yup.array().default([]),
  });
});

export const NoteView = ContextEnhancer(
  class NoteViewInner extends React.PureComponent {
    static propTypes = {
      className: PropTypes.string,
      service: PropTypes.object,
      title: PropTypes.string,
      icon: PropTypes.string,
      afterMessagesSlot: PropTypes.any,
    };

    static defaultProps = {
      className: '',
      service: null,
      title: null,
      afterMessagesSlot: null,
    };

    state = {
      loading: true,
      messages: [],
      users: [],
      initialValues: {
        message: '',
        mentions: [],
      },
      hasError: false,
    };

    formInitialValues = MsgFormSchema.default();

    groupMessages(messages) {
      messages = messages.slice();
      messages.sort((o1, o2) => {
        return o1.date.unix() - o2.date.unix();
      });
      const groups = [];
      let group = null;
      let prev = null;
      for (const d of messages) {
        const start = d.date.format('DD/MM/YYYY');
        if (prev == null || start !== prev) {
          if (group) {
            groups.push(group);
          }
          group = { date: start, messages: [] };
          prev = start;
        }
        group.messages.push(d);
      }
      if (group) {
        groups.push(group);
      }
      return groups;
    }

    getService() {
      return this.props.service || defaultService;
    }

    async componentDidMount() {
      try {
        const { messages, users } = await this.getService().fetchData({
          path: this.props.path,
          page: 0,
          pageSize: 9999,
          filter: '',
          user: this.props.context.user,
          project: this.props.context.project,
        });
        this.setState({
          messages,
          users,
          hasError: false,
        });
      } catch (e) {
        console.error(e);
        this.setState({ hasError: true, error: e });
      } finally {
        this.setState(
          {
            loading: false,
          },
          () => {
            this._scrollContainer.scrollToBottom();
          }
        );
      }
    }

    componentDidCatch(error, info) {
      this.setState({ hasError: true, error: error });
    }

    onSendMessage = async (values, actions) => {
      if (values.message !== '') {
        this.setState({ loading: true });

        try {
          const data = { ...values, date: moment(), path: this.props.path };
          await this.getService().submitMessage(data, this.props.context);
          this.msgFormik.resetForm();
          await this.componentDidMount();
        } catch (e) {
          console.error(e);
          this.setState({ loading: false, hasError: true, error: e.responseText });
        }
      }
    };

    showErrorMessage = () => {
      MessageComponents.ShowMessage({
        title: '',
        text: '<pre style="max-height: 30vh; overflow: auto;">' + this.state.error + '</pre>',
      });
    };

    errorMessage = () => {
      return (
        <div>
          {this.props.context.msg.t('error.into.notes')}
          {', '}
          <a onClick={this.showErrorMessage} className="ClickHere">
            {this.props.context.msg.t('click.here')}
          </a>
        </div>
      );
    };

    deleteComment = async (comment) => {
      try {
        await this.getService().deleteMessage(comment);
        await this.componentDidMount();
      } catch (e) {
        console.error(e);
        this.setState({ hasError: true, error: e.responseText });
      }
    };

    handleKeyPress = async (e, values, setFieldValue) => {
      if (e.keyCode == 13) {
        if (e.altKey) {
          setFieldValue('message', values.message + '\r\n');
        } else {
          await this.onSendMessage(values);
        }
      }
    };

    render() {
      const groupedMessages = this.groupMessages(this.state.messages);
      const noMessagesFound = _.isEmpty(this.state.messages);
      return (
        <BngPanel className="NoteView">
          <UiBlocker block={this.state.loading}>
            <BngPanelHeader icon={this.props.icon} title={this.props.title ?? this.props.context.msg.t('notes')} />
            <ScrollContainer className="Content" ref={(ref) => (this._scrollContainer = ref)}>
              <BngError message={this.errorMessage()} hasError={this.state.hasError}>
                <BngEmpty
                  title={this.props.context.msg.t('you.are.welcome')}
                  message={this.props.context.msg.t('empty.message.wish.to.sent')}
                  img={`${Api.baseUrl()}/resources/images/notes/empty.png`}
                  isEmpty={noMessagesFound}
                >
                  <div className="Messages">
                    {groupedMessages.map((group, idx) => (
                      <div key={idx} className="MessagesGroup">
                        <div className="TimeDiv">
                          <time>{group.date}</time>
                        </div>
                        {group.messages.map((d, idx) => (
                          <ChatMessage
                            key={idx}
                            name={d.user.displayName}
                            message={BngMention.parseMentionStringAsMarkup(d.message)}
                            date={d.date}
                            avatar={this.getService().avatarFor(d.user)}
                            deleteComment={() => this.deleteComment(d)}
                            invert={d.user.id === this.props.context.user.id}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                  {_.isFunction(this.props.afterMessagesSlot)
                    ? this.props.afterMessagesSlot()
                    : this.props.afterMessagesSlot}
                </BngEmpty>
              </BngError>
            </ScrollContainer>
            <BngPanelFooter>
              <Formik
                initialValues={this.formInitialValues}
                onSubmit={this.onSendMessage}
                innerRef={(ref) => (this.msgFormik = ref)}
                validationSchema={MsgFormSchema}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form autoComplete="off">
                      <FastField
                        name="message"
                        component={BngMention}
                        placeholder={this.props.context.msg.t('your.answer')}
                        className="MessageInput"
                        maxLength={4096}
                        style={{ maxHeight: 60, overflowY: 'hidden' }}
                        onKeyDown={(e) => this.handleKeyPress(e, values, setFieldValue)}
                        mentions={{
                          '@': { data: this.state.users },
                        }}
                      />

                      <div className="Actions">
                        <BngIconButton
                          className="Mention"
                          onClick={(event) => {
                            setFieldValue('message', values.message ? `${values.message} @` : '@');
                            j(event.target).closest('form').find('.BngMention__input').focus();
                          }}
                          icon="person_add"
                          title={this.props.context.msg.t('mention.someone.on.your.comment')}
                        />

                        <BngIconButton
                          type="submit"
                          className="Send"
                          icon="send"
                          title={this.props.context.msg.t('send.comment')}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </BngPanelFooter>
          </UiBlocker>
        </BngPanel>
      );
    }
  }
);

export class NoteViewPopper extends React.PureComponent {
  static defaultProps = {
    anchorEl: null,
    onDestroy: _.noop,
    boundaryElement: document.body,
    popperFlipBehaviour: 'flip',
  };

  destroyView = () => {
    this.props.onDestroy();
  };

  render() {
    const { anchorEl, onDestroy, boundaryElement, popperOpts, popperFlipBehaviour, ...props } = this.props;

    return (
      <BngPopper
        className="NoteViewPopper"
        open={true}
        anchorEl={anchorEl}
        modifiers={{
          preventOverflow: {
            boundariesElement: boundaryElement,
          },
          flip: {
            behaviour: popperFlipBehaviour,
          },
        }}
        {...popperOpts}
      >
        <BngClickOutsideOverlay container={boundaryElement} onClick={this.destroyView} />
        <NoteView {...props} />
      </BngPopper>
    );
  }
}

const t = new Date().getTime();

const defaultService = {
  async fetchData({ path, page, pageSize, filter, user, project } = {}) {
    const comments = await Api.Note.findNotes(path);
    const members = await Api.Project.findAvailableMembers(project.id, user.id);

    const users = members.filter((m) => !!m.email);
    const groups = members.filter((m) => !users.includes(m));

    return {
      messages: comments.map((note) => ({
        message: note.comment,
        date: moment(note.date),
        user: note.user,
        id: note.id,
      })),
      users: [
        ..._.sortBy(
          groups.map((group) => ({
            value: `G${group.id}`,
            label: `${ceData.context.msg.t('group')} ${group.name}`,
          })),
          ['label']
        ),
        ..._.sortBy(
          users.map((pu) => ({
            value: `U${pu.id}`,
            label: pu.displayName,
          })),
          ['label']
        ),
      ],
    };
  },
  submitMessage(data, context) {
    const requestData = {
      userId: context.user.id,
      message: data.message,
      path: data.path,
      mentions: data.messageMentions.map((m) => m.id),
    };
    return Api.Note.sendNote(requestData);
  },
  avatarFor(user) {
    return `${Api.baseUrl()}/avatar?user-id=${user.id}&t=${t}`;
  },
  deleteMessage(message) {
    return Api.Note.deleteNote(message.id);
  },
};
