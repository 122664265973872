import styles from './CrudPageLayout.module.css';

import React from 'react';

import UiBlocker from 'components/bng/ui/UiBlocker';
import BngTable from 'components/bng/ui/BngTable';
import PageLayout from 'components/bng/pages/common/layout/PageLayout';

export default function CrudPageLayout({
  showItemsCount = false,
  loading = false,
  tableRows = [],
  tableColumns = [],
  selectedRows = null,
  emptyAlertMessage = '',
  tableSortMode = {},
  setTableSortMode = _.noop,
  hasSearch = true,
  hasRefresh = true,
  $tableScrollRef = null,
  open = true,
  onDropHandler,
  ...props
}) {
  let itemsCount = 0;
  if (showItemsCount) {
    if (_.isArray(selectedRows)) {
      itemsCount = selectedRows.length;
    } else if (_.isObjectLike(selectedRows)) {
      itemsCount = Object.keys(selectedRows).length;
    }
  }

  return (
    <PageLayout
      hasSearch={hasSearch}
      hasRefresh={hasRefresh}
      open={open}
      loading={loading}
      showItemsCount={showItemsCount}
      itemsCount={itemsCount}
      {...props}
    >
      <UiBlocker block={loading}>
        <div className={`${styles.tableScroll} ${open ? '' : styles.closed}`} ref={$tableScrollRef}>
          <BngTable
            rows={tableRows}
            cols={tableColumns}
            selectedRow={selectedRows ?? ''}
            showEmptyAlert={true}
            emptyAlertProps={
              emptyAlertMessage
                ? {
                    message: emptyAlertMessage,
                  }
                : undefined
            }
            sortHandler={(props) => {
              const name = props.currentSortMode.name;
              setTableSortMode({
                [props.col.key]: name === 'DESC' ? 'ASC' : props.currentSortMode.nextMode.name,
              });
            }}
            sortMode={tableSortMode}
            onDropHandler={onDropHandler}
            stickyHeader
          />
        </div>
      </UiBlocker>
    </PageLayout>
  );
}
