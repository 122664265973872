import './ProfileDialog.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';

import Dialog from 'components/ui/Dialog';
import ContextEnhancer from 'components/ContextEnhancer';
import ProfileTab from 'components/ui/profile/ProfileTab';
import RecentActivitiesTab from 'components/ui/profile/RecentActivitiesTab';
import { Tab, TabSet } from 'components/ui/TabSet';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import UserPermissionsTab from 'components/ui/profile/UserPermissionsTab';
import LastLoginsTab from 'components/ui/profile/LastLoginsTab';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import { MODALS } from 'components/ui/redux/Actions';
import ValidatePhoneNumberDialog from 'components/ui/mobile-message/ValidatePhoneNumberDialog';
import CancelConfirmationDialog from 'components/ui/profile/CancelConfirmationDialog';

const ProfileSchema = bngYup((yup) => {
  return yup.object().shape({
    id: yup.number().integer().default(0),
    displayName: yup.string().required().min(2).default(''),
    avatarLink: yup.object().shape({
      link: yup.string().default(''),
      file: yup.object().nullable().default(null),
    }),
    preferredLanguage: yup.string().required().default('pt_BR'),
    email: yup.string().required().default(''),
    occupationArea: yup.string().required().default(''),
    mobile: yup.bool().default(true),
    allowMobileMessaging: yup.bool().default(null).nullable(),
    phone: yup
      .string()
      .default('')
      .when('allowMobileMessaging', {
        is: true,
        then: yup.string().required().default(''),
      }),
    enabled: yup.bool().default(true),
    timeZone: yup
      .string()
      .required()
      .default(moment.tz.guess() || 'America/Sao_Paulo'),
  });
});

export const isCurrentUser = (userId, context) => {
  return context.user && userId === context.user.id;
};

class ProfileDialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    userId: PropTypes.number,
    email: PropTypes.string,
    onSave: PropTypes.func,
  };

  static defaultProps = {
    userId: 0,
    email: null,
    onSave: _.noop,
  };

  state = {
    projectId: 0,
    user: {},
    activities: { initialized: false, list: [] },
    avatars: [],
    groups: { initialized: false, list: [] },
    permissions: { initialized: false, list: [] },
    languages: [],
    occupationAreas: [],
    profiles: [],
    newUser: false,
    loading: true,
    canAlterProfileAndMobile: false,
    canAlterEmail: false,
    mobileenable: true,
  };

  initialFormValues = ProfileSchema.default();

  constructor(props) {
    super(props);
    this.formikRef = React.createRef();
  }

  isChangeableEmail = (email) => {
    const unchangeables = [
      'desenvolvimento@bimachine.com.br',
      'desenvolvimento@sol7.com.br',
      'suporte@bimachine.com.br',
      'suporte@sol7.com.br',
      'system@bimachine.com.br',
      'system@sol7.com.br',
    ];
    for (let i = 0; i < unchangeables.length; i++) {
      if (_.isEqual(unchangeables[i], email)) {
        return false;
      }
    }
    return true;
  };

  canAlterEmail = async (user, projectId = this.state.projectId) => {
    const isBIMEmail = Utils.Project.isBimachineEmail(user.email);
    if (this.props.context.user.superUser) {
      return this.isChangeableEmail(user.email);
    } else if (!isBIMEmail) {
      return await Api.User.isMasterOfProject(projectId);
    }
    return false;
  };

  canAlterProfileAndMobile = async (user, projectId = this.state.projectId) => {
    if (this.props.context.user.superUser || !user || isCurrentUser(user.id, this.props.context)) {
      return true;
    } else {
      const isBIMEmail = Utils.Project.isBimachineEmail(user.email);
      if (!isBIMEmail) {
        return await Api.User.isMasterOfProject(projectId);
      }
      return false;
    }
  };

  getProjectId = async () => {
    const projectName = this.props.context.support.projectName;
    if (!!projectName) {
      try {
        const project = await Api.Project.getByName(projectName);
        return project.id;
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError(null, e);
      }
    }
    return 0;
  };

  getActivities = async () => {
    try {
      this.setState({ loading: true });
      const activities = await Api.User.findLastActivities(this.state.projectId, this.props.userId);
      this.setState({ activities: { initialized: true, list: activities } });
    } catch (e) {
      console.error('Error on getActivities()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  };

  getPermissions = async () => {
    const { projectId } = this.state;
    const { userId, context } = this.props;
    try {
      this.setState({ loading: true });
      const groups = await Api.User.findGroupsForUser(projectId, userId);
      let permissions = [];
      if (isCurrentUser(userId, context.user.id)) {
        permissions = await Api.Menu.findRootsFor({ projectId });
      } else if (userId !== 0) {
        permissions = await Api.Menu.findRootsFor({ projectId, userId });
      }
      this.setState({
        groups: { initialized: true, list: groups },
        permissions: { initialized: true, list: permissions },
      });
    } catch (e) {
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount = async () => {
    const { userId, context } = this.props;
    const projectId = await this.getProjectId();
    let user;
    let mobileenable = true;

    try {
      if (isCurrentUser(userId, context.user.id)) {
        user = context.user;
      } else if (userId !== 0) {
        user = await Api.User.findUser(userId, projectId);
        mobileenable = await Api.Project.getMobileEnable(userId, projectId);
      }
      const data = await Api.Bng.getProfileData();

      const languages = data.languages;
      const avatars = data.avatars;
      const occupationAreas = data.occupations.map((o) => ({
        value: o.value,
        label: context.msg.t(o.translationKey),
      }));

      if (userId !== 0) {
        user.online = await Api.Bng.isUserOnline(user.id);
        try {
          user.lastAccess = (await Api.User.findUserLastAccess(projectId, user.id)).lastAccess;
        } catch (e) {
          console.error('error trying to fetch user last session...', e);
        }

        this.buildUserData(user, mobileenable);

        const canAlterProfileAndMobile = await this.canAlterProfileAndMobile(user, projectId);
        const canAlterEmail = await this.canAlterEmail(user, projectId);

        this.setState({
          projectId,
          canAlterEmail,
          canAlterProfileAndMobile,
          user,
          avatars,
          languages,
          occupationAreas,
          mobileenable,
        });
      } else {
        user = {};
        user.email = this.props.email;
        user.allowMobileMessaging = null;
        this.buildUserData(user, mobileenable);

        this.setState({
          projectId,
          canAlterEmail: true,
          canAlterProfileAndMobile: true,
          user,
          avatars,
          languages,
          occupationAreas,
          newUser: true,
          mobileenable,
        });
      }
    } catch (e) {
      console.error('error trying to fetch basic infos...', e);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  };

  guessNameByEmail(email = '') {
    const username = (email || '').split('@')[0];
    return username
      .replace(/[^a-zA-Z ]/g, ' ')
      .replace(/\b\w/g, (c) => c.toUpperCase())
      .trim();
  }

  buildUserData = (user, mobile) => {
    this.initialFormValues.id = user.id ? user.id : 0;
    this.initialFormValues.avatarLink = { link: user.avatarLink, file: null };
    this.initialFormValues.email = user.email;
    this.initialFormValues.displayName = user.id ? user.displayName : this.guessNameByEmail(user.email);
    this.initialFormValues.phone = user.phone ? user.phone : '';
    this.initialFormValues.preferredLanguage = user.preferredLanguage;
    this.initialFormValues.mobile = mobile;
    this.initialFormValues.allowMobileMessaging = user.allowMobileMessaging;
    this.initialFormValues.enabled = user.enabled;
    this.initialFormValues.occupationArea = user.occupationArea;
    this.initialFormValues.timeZone = user.timeZone || this.initialFormValues.timeZone;
    this.formikRef.current.resetForm({ values: this.initialFormValues });
  };

  beforeHandleSubmit = (values, actions) => {
    this.setState({ loading: true });
    const user = { ...values };

    if (!_.isEmpty(user.phone)) {
      if (!isValidPhoneNumber(user.phone)) {
        this.setState({ loading: false });
        actions.setFieldError('phone', this.props.context.msg.t('user.invalid.number'));
        actions.setSubmitting(false);
        return;
      }
    }

    if (this.state.newUser && (!user.password || user.password !== user.confirmPassword)) {
      this.setState({ loading: false });
      actions.setFieldError('confirmPassword', this.props.context.msg.t('passwords_dont_match'));
      actions.setSubmitting(false);
      return;
    }

    return new Promise(async (resolve, reject) => {
      if (
        this.isPhoneDifferentWithMobileMsg(user) &&
        !this.state.newUser &&
        !this.props.context.user.superUser &&
        !this.props.context.masterOrOwnerOfCurrentAccount
      ) {
        this.props.dispatch(
          MODALS.open(ValidatePhoneNumberDialog, {
            phone: user.phone,
            userId: user.id,
            closeModal: async (valid = false) => {
              try {
                this.setState({ loading: false });
                actions.setSubmitting(false);
                this.props.closeModal();
                if (valid) {
                  await this.handleSubmit(values);
                }
                resolve();
              } catch (e) {
                reject(e);
              }
            },
          }),
        );
      } else {
        try {
          await this.handleSubmit(values);
          resolve();
        } catch (e) {
          reject(e);
        }
      }
    });
  };

  handleSubmit = async (values) => {
    this.setState({ loading: true });
    const user = { ...values };
    const typeSubmit = this.state.newUser ? 'create' : 'update';

    try {
      if (user.avatarLink.file) {
        const uploadResult = await Api.Upload.uploadAndResize(user.avatarLink.file, 100, 100);
        const file = uploadResult[0];
        user.avatarLink = file.path;
      } else {
        user.avatarLink = user.avatarLink.link;
      }

      const mobile = user.mobile;
      delete user.mobile;

      if (_.isNil(user.allowMobileMessaging)) {
        delete user.allowMobileMessaging;
      }

      const persistedUser = await Api.User.saveUser(user, this.props.context.support.projectName, mobile);
      if (this.state.newUser && !!this.state.projectId) {
        await Api.Project.addUser({
          projectId: this.state.projectId,
          userId: persistedUser.id,
        });
        await Api.Project.saveMobileUsers(this.state.projectId, [
          {
            id: persistedUser.id,
            name: '',
            email: '',
            enable: mobile,
            allowMobileMessaging: persistedUser.allowMobileMessaging,
          },
        ]);
      }

      if (typeSubmit === 'update') {
        const universityAccess = await Api.University.findAccess(this.props.userId);

        if (universityAccess && !universityAccess.disabled) {
          try {
            if (this.state.user.email !== user.email) {
              await Api.University.removeAccess(
                this.props.userId,
                this.props.context.project.name,
                this.state.user.email,
              );
            } else if (this.state.user.displayName !== user.displayName) {
              await Api.University.syncUser(this.state.user.id);
            }
          } catch (e) {
            console.error('Error on university process', e);
            UiMsg.ajaxError(null, e);
          }
        }
      }

      UiMsg.ok(this.props.context.msg.t('user.dialog.' + typeSubmit + '.success'));

      const currentUser = this.props.context.user;
      if (
        user.id === currentUser.id &&
        (user.preferredLanguage !== currentUser.preferredLanguage || user.timeZone !== currentUser.timeZone)
      ) {
        window.location.reload();
      } else if (application.ice.isLoaded()) {
        await Api.updateJsf();
      }

      this.props.onSave({ user: persistedUser });
      this.props.closeModal();
    } catch (e) {
      console.error(e);
      UiMsg.error(this.props.context.msg.t('user.dialog.' + typeSubmit + '.error'));
      this.setState({ loading: false });
    }
  };

  isPhoneDifferentWithMobileMsg = (user) => {
    return (
      (user.phone !== this.initialFormValues.phone && user.allowMobileMessaging) ||
      (user.allowMobileMessaging && !this.initialFormValues.allowMobileMessaging)
    );
  };

  handleCloseModal = (values) => {
    const user = { ...values };
    if (user.phone !== this.initialFormValues.phone) {
      this.props.dispatch(
        MODALS.open(CancelConfirmationDialog, {
          onClose: async ({ closeModal, closeProfile }) => {
            closeModal();
            if (closeProfile) {
              this.props.closeModal();
            }
          },
        }),
      );
    } else {
      this.props.closeModal();
    }
  };

  render() {
    const {
      user,
      activities,
      avatars,
      permissions,
      groups,
      languages,
      occupationAreas,
      profiles,
      loading,
      newUser,
      canAlterProfileAndMobile,
      canAlterEmail,
      projectId,
    } = this.state;
    const outProjectCxt = projectId === 0;
    return (
      <Formik
        initialValues={this.initialFormValues}
        validationSchema={ProfileSchema}
        onSubmit={this.beforeHandleSubmit}
        innerRef={this.formikRef}
      >
        {({ values }) => (
          <Dialog
            contentFullWidth={true}
            open={this.props.open}
            className={`ProfileDialog large ${newUser ? 'CreateUserProfile' : 'EditUserProfile'}`}
            title={this.props.context.msg.t(newUser ? 'createNewUser_page_title' : 'detail_my_account')}
            onClose={() => this.handleCloseModal(values)}
            loading={loading}
            verticalCenter={true}
          >
            <Form>
              <TabSet
                bodyRadius={false}
                beforeChange={() => {
                  return !loading;
                }}
                className="grey-bg"
              >
                <Tab icon="person" label={this.props.context.msg.t('profile')}>
                  <ProfileTab
                    allowMobileMessaging={user.allowMobileMessaging}
                    mobile={this.state.mobileenable}
                    canAlterEmail={canAlterEmail}
                    canAlterProfileAndMobile={canAlterProfileAndMobile}
                    avatars={avatars}
                    languages={languages}
                    occupationAreas={occupationAreas}
                    profiles={profiles}
                    user={user}
                    newUser={newUser}
                  />
                </Tab>
                {!newUser && !outProjectCxt && (
                  <Tab contentFullWidth={true} icon="access_time" label={this.props.context.msg.t('recent_activities')}>
                    <RecentActivitiesTab activities={activities} getActivities={this.getActivities} />
                  </Tab>
                )}
                {!newUser && !outProjectCxt && (
                  <Tab icon="lock" label={this.props.context.msg.t('user.permissions')}>
                    <UserPermissionsTab
                      groups={groups}
                      permissions={permissions}
                      getPermissions={this.getPermissions}
                    />
                  </Tab>
                )}
                {this.props.userId === this.props.context.user.id && (
                  <Tab icon="login" label={this.props.context.msg.t('last.logins')}>
                    <LastLoginsTab />
                  </Tab>
                )}
              </TabSet>
              <DefaultDialogActions
                contentFullWidth={true}
                disabled={!canAlterProfileAndMobile}
                closeModal={() => this.handleCloseModal(values)}
                onSave={this.props.onSave}
              />
            </Form>
          </Dialog>
        )}
      </Formik>
    );
  }
}

export default connect()(ContextEnhancer(ProfileDialog));
