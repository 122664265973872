import React, {Component} from 'react';
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import {
    BreadcrumbToolbar,
    DynamicTimeFilterToolbarItem,
    ExportToolbarItem,
    FavoriteToolbarItem,
    InformationToolbarItem,
    MobileToolbarItem,
    NotesToolbarItem,
    PublisherToolbarItem,
    ReloadToolbarItem
} from "components/ui/breadcrumb-toolbar";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import MonitorToolbarItem from "components/ui/breadcrumb-toolbar/MonitorToolbarItem";
import HideHeaderToolbarItem, {HideHeaderPageOpts} from 'components/ui/breadcrumb-toolbar/HideHeaderToolbarItem';
import {checkAddonEnabled} from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';

class KpiBreadcrumbToolbar extends Component {

    static propTypes = {
        isNotKpiPersisted: PropTypes.bool,
        path: PropTypes.string,
        caption: PropTypes.string,
        mobile: PropTypes.bool,
        dataSourceCaption: PropTypes.string,
        isDynamic: PropTypes.bool,
        filters: PropTypes.any,
    };

    static defaultProps = {
        isNotKpiPersisted: false,
        path: '',
        caption: '',
        mobile: true,
        dataSourceCaption: '',
        isDynamic: false,
        isMonitoringOpen: false,
    };

    state = {
        loading: true,
        onAjax: false,
        mobile: true,
        datasource: '',
        isMonitoringOpen: false,
        timeFilter: null,
    };

    constructor(props) {
        super(props);
        this.state.mobile = this.props.mobile;
        this.state.isMonitoringOpen = this.props.isMonitoringOpen;
    }

    reloadKpi = async () => {
        const container = j('.kpi-sample-container');
        ReactDOM.unmountComponentAtNode(container[0]);
        try {
            await Api.executeExp(`#{viewKpiMB.reloadKpi()}`);
            window.__KPI_RENDER_FN();
        } catch (e) {
            console.error(e);
        }
    };

    componentDidMount() {
        this.setState({mobile: this.props.mobile});
    }

    toggleMobile = async (event) => {
        this.setState({onAjax: true});
        try {
            const mobile = await Api.Bng.toggleKpiMobile();
            this.setState({mobile});
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({onAjax: false});
        }
    };

    toggleMonitorDropdown = async (event) => {
        //TODO Migrate monitoring to React when new business rule be defined
        this.props.context.execute(`#{centralMonitoringMB.dashItemEditmonitorToogle(viewKpiMB.kpi.path)}`);
        await Api.updateJsf();
        if (this.state.isMonitoringOpen) {
            j('#cmf').css({display: 'none'});
            this.setState({isMonitoringOpen: false});
        } else {
            j('#cmf').css({display: 'block'});
            this.setState({isMonitoringOpen: true});
        }
    };


    render() {
        const {onAjax, datasource, mobile} = this.state;
        const {isNotKpiPersisted, path, caption, isDynamic, dataSourceCaption} = this.props;

        return (
            <BreadcrumbToolbar loading={onAjax}>
                <ExportToolbarItem disabled={isNotKpiPersisted}
                                   caption={caption}
                                   path={path}
                                   filters={this.props.filters}
                />

                {this.props.context.cockpitEnabled &&
                    <HideHeaderToolbarItem location={HideHeaderPageOpts.BREADCRUMBS}/>
                }
                <ReloadToolbarItem disabled={isNotKpiPersisted}
                                   onClick={this.reloadKpi}
                />

                <PublisherToolbarItem path={path}
                                      caption={caption}
                                      disabled={isNotKpiPersisted}
                />

                <NotesToolbarItem disabled={isNotKpiPersisted}
                                  path={path}
                />

                <FavoriteToolbarItem disabled={isNotKpiPersisted}
                                     path={path}/>

                <MobileToolbarItem disabled={isNotKpiPersisted}
                                   inactive={!mobile}
                                   path={path}
                                   onClick={this.toggleMobile}
                />

                <InformationToolbarItem disabled={isNotKpiPersisted}
                                        title={this.props.context.msg.t('object.information.kpi')}
                                        dataSource={datasource}
                                        dataSourceCaption={dataSourceCaption}
                                        path={path}
                />

                <MonitorToolbarItem disabled={isNotKpiPersisted}
                                    onClick={async () => {
                                        if (checkAddonEnabled(AddonType.KPI_MONITORING.key, true)) {
                                            await this.toggleMonitorDropdown()
                                        }
                                    }}
                />

                <DynamicTimeFilterToolbarItem
                    blocked={true}
                    disabled={!isDynamic || isNotKpiPersisted}
                    path={path}
                    onChange={_.noop}
                    timeFilter={this.findTimeFilter}
                    dataSourceCaption={dataSourceCaption}
                />
            </BreadcrumbToolbar>
        )
    }

    findTimeFilter = async () => {
        if (this.state.timeFilter) {
            return this.state.timeFilter;
        }
        try {
            const timeFilter = await Api.Bng.getKpiDynamicPeriodicities();
            this.setState({timeFilter});
            return timeFilter;
        } catch (e) {
            UiMsg.ajaxError(null, e);
            return null;
        }
    };

}

export default ContextEnhancer(KpiBreadcrumbToolbar);