import { Axios } from 'commonUtils';

const endpoint = '/spr/ui/inMemoryBean';

class InMemoryBeanApi {
  findDimensionsAndFields = async () => {
    return await Axios.getData(`${endpoint}/dimensions`);
  };

  updateDimension = async (dimension = { name: '', levels: [], hierarchies: [], originalDimensionName: '' }) => {
    return await Axios.postData(`${endpoint}/dimensions`, dimension);
  };

  removeDimension = async (dimensionName = '') => {
    return await Axios.delete(`${endpoint}/dimensions`, { params: { dimensionName } });
  };
}

export default InMemoryBeanApi;
