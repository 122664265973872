import styles from './LabsVoting.module.css';

import React, { useEffect, useState } from 'react';

import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import LabsVotingAdminDialog from 'components/ui/labs/voting/LabsVotingAdminDialog';
import { MODALS } from 'components/ui/redux/Actions';
import UiMsg from 'components/ui/UiMsg';
import UiBlocker from 'components/bng/ui/UiBlocker';
import FeatureProposalOption from 'components/ui/labs/voting/FeatureProposalOption';
import BngEmpty from 'components/bng/ui/BngEmpty';
import useFetchData from 'components/hooks/useFetchData';
import Utils from 'components/Utils';
import { useTabContext } from 'components/ui/TabSet';
import BngSearch from 'components/bng/ui/BngSearch';

export const VOTING_FEATURE_STATUS = {
  REJECTED: 'REJECTED',
  ONGOING: 'ONGOING',
  APPROVED: 'APPROVED',
  FINISHED: 'FINISHED',
  IDEA: 'IDEA',
};

const FILTER_BUTTONS = [
  { icon: 'star', value: 'most.voted' },
  { icon: 'add_circle_outline', value: 'labs.new' },
  { icon: 'trending_up', value: 'trending.up' },
];

export default function LabsVoting({ statusFilter, bngEmptyProps = {}, showFilterButtons = true }) {
  const { msg, ...context } = useBimContext();
  const dispatch = useReduxDispatch();
  const { changeTab } = useTabContext();

  const [featureProposals, setFeatureProposals] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedSortType, setSelectedSortType] = useState('');

  const $categoryOpts = useFetchData(async () => {
    return (await Api.FeatureProposal.findCategories())
      .map((category) => ({ value: category.value, label: msg.t(category.titleKey) }))
      .sort((o1, o2) => Utils.Strings.compareIgnoreCase(o1.label, o2.label));
  });

  const $statusOpts = useFetchData(async () => {
    return (await Api.FeatureProposal.findStatus())
      .map((status) => ({ value: status.value, label: msg.t(status.titleKey) }))
      .sort((o1, o2) => Utils.Strings.compareIgnoreCase(o1.label, o2.label));
  });

  const [loading, setLoading] = useState(false);

  const fetchProposals = async ({ showLoading = true, sort = true, sortType = '' } = {}) => {
    try {
      if (showLoading) {
        setLoading(true);
      }

      const featureProposals = await Api.FeatureProposal.findAll({ status: statusFilter });
      if (sort) {
        switch (sortType) {
          case FILTER_BUTTONS[0].value: {
            featureProposals.sort((a, b) => b.votesCount - a.votesCount);
            break;
          }
          case FILTER_BUTTONS[1].value: {
            featureProposals.sort((a, b) => b.createdAt - a.createdAt);
            break;
          }
          case FILTER_BUTTONS[2].value: {
            featureProposals.sort((a, b) => b.totalVotes - a.totalVotes);
            break;
          }
          default: {
            featureProposals.sort(
              (a, b) => Number(a.userVote !== null) - Number(b.userVote !== null) || b.votesCount - a.votesCount,
            );
            break;
          }
        }
      }

      setFeatureProposals(featureProposals);
    } catch (e) {
      console.error('Error while trying to fetch VotingFeatures', e);
      UiMsg.ajaxError(null, e);
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProposals();
  }, []);

  useEffect(() => {
    fetchProposals({ showLoading: false, sortType: selectedSortType, sort: showFilterButtons });
  }, [selectedSortType]);

  return (
    <div className={`LabsVoting ${styles.labsVotingWrapper}`}>
      <div className={`${styles.labsSuggestionContainer}`}>
        <div className={`${styles.votingHeader}`}>
          <div className={`${styles.infoLabel}`}>
            <Icon icon={'tungsten'} />
            <span>{msg.t('publiclabs.voting.infoLabel')}</span>
            <BngButton onClick={(e) => changeTab(e, 3)} variant={Variant.outline}>
              {msg.t('BngSuggestion.submit.suggestion')}
            </BngButton>
          </div>
          {context.labs.features.includes('EDIT_VOTING_FEATURES') && (
            <BngIconButton
              icon={'settings'}
              onClick={() => {
                dispatch(
                  MODALS.open(LabsVotingAdminDialog, {
                    categoryOpts: $categoryOpts.data,
                    statusOpts: $statusOpts.data,
                    afterClose: () => fetchProposals(),
                  }),
                );
              }}
            />
          )}
        </div>

        {statusFilter !== VOTING_FEATURE_STATUS.APPROVED && !_.isEmpty(featureProposals) && (
          <div className={`${styles.orders}`}>
            {showFilterButtons && (
              <div>
                {FILTER_BUTTONS.map(({ value, icon, text }) => {
                  const isSelected = selectedSortType === value;
                  return (
                    <BngIconButton
                      key={value}
                      className={`${styles.ordersButtons} ${isSelected ? styles.ordersSelected : ''}`}
                      title={msg.t(value)}
                      text={msg.t(value)}
                      icon={icon}
                      onClick={() => {
                        setSelectedSortType(isSelected ? '' : value);
                      }}
                    />
                  );
                })}
              </div>
            )}
            <div className={`${styles.search}`}>
              <BngSearch
                value={search}
                onChange={(value) => {
                  setSearch(value);
                }}
                placeholder={msg.t('browse_button')}
                alwaysOpen={true}
              />
            </div>
          </div>
        )}

        <UiBlocker className={`${styles.optionsWrapper}`} block={loading}>
          {_.isEmpty(featureProposals) ? (
            <BngEmpty isEmpty={true} {...bngEmptyProps} />
          ) : (
            <div className={`${styles.optionsContainer}`}>
              {featureProposals
                .filter((item) => item.title.toLowerCase().includes(search.toLowerCase()))
                .map((item) => {
                  return (
                    <FeatureProposalOption
                      key={item.id}
                      feature={item}
                      categoryOpts={$categoryOpts.data}
                      statusOpts={$statusOpts.data}
                      fetchVotes={fetchProposals}
                      showVotes={statusFilter !== VOTING_FEATURE_STATUS.APPROVED}
                      ideaBoxFeature={statusFilter === VOTING_FEATURE_STATUS.IDEA}
                    />
                  );
                })}
            </div>
          )}
        </UiBlocker>
      </div>
    </div>
  );
}
