import './MonitorMessageObjectsTreeDialog.css';

import React, { useMemo, useState } from 'react';
import SelectObjectDialogContainer from 'components/ui/common/SelectObjectDialogContainer';
import useBimContext from 'components/hooks/useBimContext';

export default function MonitorMessageObjectsTreeDialog({ closeModal }) {
  const { msg } = useBimContext();
  const $val = useMemo(() => {
    return document.querySelector('.JsfMessageSelectedItemsJson');
  }, []);

  const [selectedItems, setSelectedItems] = useState(
    JSON.parse($val.value ?? '[]')
  );

  return (
    <SelectObjectDialogContainer
      className={'MonitorMessageObjectsTreeDialog TransparentBg'}
      customDialogProps={{
        title: msg.t('monitor.objects.relation.dialog.title'),
        backdropClassName: 'MonitorMessageObjectsTreeDialogBackdrop',
      }}
      onChangeListener={({ path: selectedPaths } = { path: [] }) => {
        setSelectedItems(selectedPaths);
      }}
      customSaveHandler={() => {
        $val.value = JSON.stringify(selectedItems);
        closeModal();
      }}
      closeModal={closeModal}
      object={selectedItems}
      typeField="checkbox"
    />
  );
}
