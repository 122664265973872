import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import BreadcrumbToolbar from './BreadcrumbToolbar';

class MonitorToolbarItem extends PureComponent {

    static propTypes = {
        disabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
        onClick: _.noop
    };

    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BreadcrumbToolbar.Item icon="icon-bullhorn"
                                    title={this.props.context.msg.t('monitoring')}
                                    {...this.props}/>
        );
    }
}

export default ContextEnhancer(MonitorToolbarItem);
