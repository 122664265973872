import "./UserList.css";
import React, {useState} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import {connect} from "react-redux";
import PaginateTable from "components/ui/common/PaginateTable";
import {BngTable} from "components/bng/ui/BngTable";
import {BngAvatar} from "components/bng/ui/BngAvatar";
import {BngDropdown} from "components/bng/ui/BngDropdown";
import BngSearch from "components/bng/ui/BngSearch";
import UiMsg from "components/ui/UiMsg";
import Api from "components/Api";
import OpConfirmation from "components/ui/OpConfirmation";
import ProfileDialog from "components/ui/profile/ProfileDialog";
import {MODALS} from "components/ui/redux/Actions";
import Button from "components/ui/Button";
import Icon from "components/ui/common/Icon";

const TABLE_INITIAL_PASS = 10;

const SYSTEM_USERS = ['desenvolvimento@sol7.com.br', 'system@bimachine.com.br'];

const UserTable = ({
                       context = {},
                       users = {},
                       handleChangeNumberPerPage = _.noop,
                       handleChangePage = _.noop,
                       editUser = _.noop,
                       deleteUser = _.noop,
                       onClearUserRefs = _.noop,
                   }) => {

    const renderProp = (row, index, prop) => {
        const value = row[prop];
        return <span className={`UserData-${index}-${prop}`}>{value}</span>
    }

    const renderNestedProp = (row, index, prop) => {
        const props = prop.split('.');
        const value = row[props[0]];
        return <span className={`UserData-${index}-${prop}`}>{!!value ? value[props[1]] : ''}</span>
    }

    const userColumns = [
        {label: '', render: (row) => <BngAvatar userId={row.id}/>},
        {label: context.msg.t('display_name'), render: (row, index) => renderProp(row, index, 'displayName')},
        {label: context.msg.t('email'), render: (row, index) => renderProp(row, index, 'email')},
        {
            label: context.msg.t('preferred_language'),
            render: (row, index) => renderProp(row, index, 'preferredLanguage')
        },
        {
            label: context.msg.t('practice.profile'),
            render: (row, index) => renderNestedProp(row, index, 'practiceProfile.name')
        },
        {
            label: context.msg.t('occupation.area'),
            render: (row, index) => <span className={`UserData-${index}-occupationArea`}>{row.occupationArea ? context.msg.t(`OccupationArea.${row.occupationArea}`) : ''}</span>
        },
        {
            label: context.msg.t('enabled'), render: (row, index) => {
                return <span
                    className={`UserData-${index}-enabled`}>{row.enabled ? context.msg.t('yes') : context.msg.t('no')}</span>
            }
        },
        {
            label: '', render: (row) => {
                const opts = [
                    {icon: 'edit', label: context.msg.t('edit'), onClick: () => editUser(row)},
                    {
                        icon: 'clean_hands',
                        label: context.msg.t('clear.user.references'),
                        onClick: () => onClearUserRefs(row)
                    },
                    {
                        icon: 'remove',
                        label: context.msg.t('delete'),
                        onClick: () => deleteUser(row),
                        disabled: SYSTEM_USERS.includes(row.email)
                    },
                ];
                return (
                    <BngDropdown options={opts}/>
                )
            }
        },
    ]

    return (
        <PaginateTable handleChangeNumberPerPage={handleChangeNumberPerPage}
                       handleChangePage={handleChangePage}
                       initialPass={TABLE_INITIAL_PASS}
                       perPageLimit={45}
                       totalItens={users.totalElements}
                       externalChange={users.forceUpdate}
                       height="80vh">
            <BngTable className="Narrow" rows={users.content} cols={userColumns}/>
        </PaginateTable>
    )
}

const UserList = ({context, dispatch}) => {
    const [users, setUsers] = useState({content: [], totalElements: 0, forceUpdate: false});
    const [search, setSearch] = useState('');

    const fetchUsers = async (initial = 0, maxResults = TABLE_INITIAL_PASS, forceUpdate = false, searchTerm = search) => {
        try {
            setUsers({...users, forceUpdate});
            const _users = await Api.Admin.findUsers({initial, maxResults, search: searchTerm});
            setUsers({..._users, forceUpdate: false});
        } catch (e) {
            console.error('Error on fetchUsers', e);
            UiMsg.ajaxError(null, e);
        }
    }

    const handleChangePage = async (pageNumber, firstLoad, perPage) => {
        await fetchUsers(Number(pageNumber) - 1, perPage);
    }

    const handleChangeNumberPerPage = async (perPage, pageNumber) => {
        await fetchUsers(Number(pageNumber) - 1, perPage);
    }

    const editUser = (user) => {
        dispatch(MODALS.open(ProfileDialog, {
            userId: user.id,
            onSave: () => fetchUsers(0, TABLE_INITIAL_PASS, true),
        }))
    }

    const createUser = () => {
        dispatch(MODALS.open(ProfileDialog, {
            onSave: () => fetchUsers(0, TABLE_INITIAL_PASS, true),
        }))
    }

    const handleDelete = (user) => {
        OpConfirmation({
            title: user.displayName,
            message: context.msg.t('confirm.delete.default'),
            onConfirm: () => deleteUser(user),
            msg: context.msg
        })
    }

    const deleteUser = async (user) => {
        try {
            await Api.Admin.deleteUser(user.id);
            UiMsg.ok(context.msg.t('br.com.sol7.ui.jsf.bean.admin.UserProfileBean.DELETE_SUCCESS', [user.displayName]))
            await fetchUsers(0, TABLE_INITIAL_PASS, true);
        } catch (e) {
            console.error('Error on deleteUser', {user}, e);
            UiMsg.ajaxError(null, e);
        }
    }

    const clearUserRefs = async (user) => {
        OpConfirmation({
            title: context.msg.t('attention'),
            message: context.msg.t('clear.user.references.confirm.msg'),
            onConfirm: async () => {
                try {
                    await Api.Admin.clearReferences(user.id);
                    UiMsg.ok(context.msg.t('clear.user.references.success'))
                    await fetchUsers(0, TABLE_INITIAL_PASS, true);
                } catch (e) {
                    console.error('Error on clearUserRefs', {user}, e);
                    UiMsg.ajaxError(null, e);
                }
            },
            msg: context.msg
        })
    }

    const handleSearch = async (search = '', fromClose = false) => {
        await setSearch(search);
        if (fromClose) await fetchUsers(0, TABLE_INITIAL_PASS, true, search);
    }

    const handleSubmitSearch = async (e) => {
        e.preventDefault();
        await fetchUsers();
    }

    return (
        <div className="AdminUserList">
            <div className="AdminUserListHeader">
                <form id="admin-search-user-form" onSubmit={handleSubmitSearch}>
                    <BngSearch value={search} onChange={handleSearch}/>
                </form>
                <Button data-test="AddUser" className="bng-button save AddUserButton" onClick={createUser}
                        type="button">
                    <Icon icon="add"/>
                </Button>
            </div>
            <UserTable context={context}
                       users={users}
                       handleChangeNumberPerPage={handleChangeNumberPerPage}
                       handleChangePage={handleChangePage}
                       deleteUser={handleDelete}
                       editUser={editUser}
                       onClearUserRefs={clearUserRefs}
            />
        </div>
    )
}

export default ContextEnhancer(connect()(UserList));