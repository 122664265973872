import * as React from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import {Tab, TabSet} from "components/ui/TabSet";
import {Field, Validations} from "components/ui/FormUtils";
import {Format} from "components/ui/analysis/expert/format/Format";
import Free from 'components/ui/analysis/expert/Free';


class Constant extends React.Component {

    static propTypes = {
        formatStrings: PropTypes.array
    };

    static defaultProps = {
        formatStrings: []
    };

    state = {};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const f = this.props.formula;
        if (f) {
            this.props.initialize({
                id: f.id,
                name: f.name,
                order: f.order,
                ...f.data,
                format: Free.formatMapping(f.data.format)
            })
        }
    }


    render() {
        return (

            <TabSet vertical
                    className="FreeFormula"
                    beforeChange={async () => {
                        const result = this.props.handleSubmit(_.noop)();
                        return _.isUndefined(result) || !('expression' in result);
                    }}>
                <Tab label={this.props.context.msg.t('constant')}
                     icon="fa fa-cogs">

                    <Field name="expression"
                           label={this.props.context.msg.t('value')}
                           type="number"
                           className="fill-w"
                           validate={[Validations.required, Validations.isNumeric]}
                    />

                </Tab>
                <Tab label={this.props.context.msg.t('format.string')}
                     icon="fa fa-adjust">
                    <Format array={this.props.array}
                            change={this.props.change}
                            dispatch={this.props.dispatch}
                            context={this.props.context}
                            formatStrings={this.props.formatStrings}
                            formValues={this.props.formValues}
                    />
                </Tab>
            </TabSet>
        );
    }

}


export default ContextEnhancer(Constant);