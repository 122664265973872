import './FilterItem.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterService from 'components/filter/FilterService';
import FilterDialog from 'components/filter/FilterDialog';
import Api from 'components/Api';
import Utils from 'components/Utils';
import ContextEnhancer from 'components/ContextEnhancer';
import { MONTH_FORMAT, timeMemberToMoment } from 'components/filter/TimeFilterView';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import bimEventBus from 'BimEventBus';

const OPEN_DIALOG_EVENT = 'FilterItem:openDialog';

class FilterItem extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    filter: PropTypes.object,
    selectedFilter: PropTypes.bool,
    filtered: PropTypes.bool,
    selectedFilters: PropTypes.array,
    loadingListener: PropTypes.func,
    onEditorMode: PropTypes.bool,
    dashboardPath: PropTypes.string,
    inactive: PropTypes.bool,
    type: PropTypes.any,
    className: PropTypes.string,
    customFilterSwitch: PropTypes.any,
    customMember: PropTypes.string,
    onOverflowDropdown: PropTypes.bool,
    showTruncateButton: PropTypes.bool,
    defaultFilter: PropTypes.array,
  };

  static defaultProps = {
    className: '',
    loadingListener: _.noop,
    inactive: false,
    onOverflowDropdown: false,
    showTruncateButton: true,
    customMember: null,
    defaultFilter: null,
  };

  state = {
    truncateDescription: true,
    initialized: null,
    requestData: {
      members: [],
    },
  };

  initializeFilterData = async () => {
    const { type, filter, selectedFilters, dashboardPath, dashChanges } = this.props;
    const cacheKey = `${filter?.id || ''} ${JSON.stringify(selectedFilters || [])} ${dashboardPath || ''}`;
    if (this.state.initialized === cacheKey) {
      return;
    }

    let data = {};
    if (type === 'DASHBOARD_ICON' && filter.notFromDash) {
      data = await Api.Filter.findMembers(filter.id);
    } else {
      data = await FilterService.findMembers({
        id: filter.id,
        filters: selectedFilters,
        dashboardPath,
        dashChanges,
      });
    }

    await new Promise((res) => {
      this.setState(
        {
          initialized: cacheKey,
          requestData: data,
        },
        res,
      );
    });
  };

  openDialogEventHandler = (filter) => {
    if (this.props.filter.id !== filter.id) {
      return;
    }

    this.openDialog();
  };

  openDialog = async (event) => {
    const { context, filter } = this.props;
    event?.preventDefault?.();

    const blockFilter =
      filter.disabled &&
      (context.permissions.isViewer() || !context.permissions.canManipulateObject(this.props.dashboardPath));

    if (blockFilter) {
      return;
    }

    try {
      this.props.loadingListener(true);
      await this.initializeFilterData();

      const isTimeFilter = this.isTimeFilter();
      const { requestData } = this.state;
      const temp = {
        filter: filter,
        items: requestData.members,
        enableModeChange: isTimeFilter,
        dynamicItems: isTimeFilter ? this.state.requestData.dynamicMembers : [],
        onChange: this.onFilterChange,
        maxElements: 20,
        availableDateRange: requestData.availableDateRange,
        containRestriction: requestData.containRestriction,
        restrictionType: requestData.restrictionType,
        restrictionMembers: requestData.restrictionMembers,
        dataRestrictionMembers: requestData.dataRestrictionMembers,
        customFilterSwitch: this.props.customFilterSwitch,
      };

      window.ReduxStore.dispatch(MODALS.open(FilterDialog, temp));
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError('error', e);
    } finally {
      this.props.loadingListener(false);
    }
  };

  componentWillUnmount() {
    this.__beforeDestroy?.();
  }

  onFilterChange = async (selectedMembers, force = false, additionalProps = {}) => {
    if (force || !_.isEqual(this.props.filter.selectedMembers, selectedMembers)) {
      await this.props.onChange(
        this.props.filter,
        selectedMembers,
        this.state.requestData.members,
        force,
        additionalProps,
      );
    }
    additionalProps.closeModal();
  };

  clearFilters = (force = false) => {
    this.props.onChange(this.props.filter, [], [], force, { clearFilters: true });
  };

  resetFilters = (force = false) => {
    this.props.onChange(this.props.filter, [], this.state.requestData.members, force, { clearFilters: false });
  };

  toggleTruncateDescription = () => {
    this.setState({ truncateDescription: !this.state.truncateDescription });
  };

  openConfigureDialog = async (event) => {
    event.preventDefault();
    // Utilizadas strings ao invés de referências para evitar importar coisas desnecessárias na build do mobile
    bimEventBus.emit('RIGHT_MENU_TOGGLE_SUBMENU', {
      component: 'FilterAccordion',
      props: {
        editFilterId: this.props.filter.id,
      },
    });
  };

  async componentDidMount() {
    const removeDialogListener = bimEventBus.on(OPEN_DIALOG_EVENT, this.openDialogEventHandler);
    this.__beforeDestroy = () => {
      removeDialogListener();
    };
    this.fixFilterNames();
  }

  componentDidUpdate() {
    this.fixFilterNames();
  }

  fixFilterNames = async () => {
    if (this.__updating) {
      return;
    }

    const members = _.get(this.props, 'filter.selectedMembers', []).filter(
      (member) => !member.alreadyFixed && member.value === member.label,
    );

    if (members.length === 0) {
      return;
    }

    this.__updating = true;
    try {
      this.props.loadingListener(true);
      const RANGE_SEPARATOR = '] : [';
      const isTimeFilter = this.isTimeFilter();
      await this.initializeFilterData();
      members.forEach((memberWithoutLabel) => {
        const rangeMembers = memberWithoutLabel.value.includes(RANGE_SEPARATOR)
          ? memberWithoutLabel.value.split(' : ')
          : [memberWithoutLabel.value];
        const rangeLabels = [];

        if (isTimeFilter) {
          if (this.state.requestData.dynamicMembers) {
            for (const parent of this.state.requestData.dynamicMembers) {
              rangeMembers.forEach((member, idx) => {
                const match = parent.selectItems.find((dynamicMember) => dynamicMember.value === member);
                if (match) {
                  rangeLabels[idx] = match.label;
                }
              });
            }
          }
          if (rangeLabels.length !== rangeMembers.length) {
            rangeMembers.forEach((member, idx) => {
              if (this.props.context.msg.containKey(member)) {
                rangeLabels[idx] = this.props.context.msg.t(member);
              }
            });
          }
        }

        if (rangeLabels.length !== rangeMembers.length) {
          const systemDateFormat = Utils.Date.findDateFormat('pt-BR');
          const userDateFormat = Utils.Date.findDateFormat();
          rangeMembers.forEach((member, idx) => {
            if (isTimeFilter) {
              const parts = member.split('.');

              if (parts[1]?.includes('[S') && parts.length < 6) {
                if (parts.length < 4) {
                  rangeLabels[idx] = timeMemberToMoment(member, idx === 0).parsedDate.format(systemDateFormat);
                } else if (parts.length === 5) {
                  const weekNumber = parts[4].substring(2, parts[4].length - 1);
                  const year = parts[0].substring(1, 5);
                  rangeLabels[idx] = `${this.props.context.msg.t('week')} ${weekNumber}, ${year}`;
                } else {
                  rangeLabels[idx] = timeMemberToMoment(member).parsedDate.format(MONTH_FORMAT);
                }
              } else {
                let datePart = parts.pop();
                rangeLabels[idx] = datePart.replace(/[\[\]]/g, '');
                if (parts.length !== 0) {
                  rangeLabels[idx] = moment(rangeLabels[idx], systemDateFormat).format(userDateFormat);
                }
              }
            } else {
              const match = this.state.requestData.members.find((m) => m.value === member);
              if (match) {
                rangeLabels[idx] = match.label;
              }
            }
          });
        }

        if (rangeLabels.length === rangeMembers.length) {
          memberWithoutLabel.label = rangeLabels.join(' : ');
        } else {
          memberWithoutLabel.label = memberWithoutLabel.value.replace(/[\[\]]/g, '');
        }

        memberWithoutLabel.alreadyFixed = true;
      });
      this.forceUpdate(() => {
        this.__updating = false;
      });
    } catch (e) {
      console.error(e);
      this.__updating = true;
    } finally {
      this.props.loadingListener(false);
    }
  };

  isTimeFilter() {
    return this.props.filter.type === 'TIME';
  }

  isExceptFilter() {
    return this.props.filter.restrictionType === 'HIDE_SELECTED';
  }

  clearCache() {
    this.setState({
      initialized: null,
      requestData: {
        members: [],
      },
    });
  }

  isEqualToDefaultFilter(appliedMembers, defaultMembers) {
    if (
      !Array.isArray(appliedMembers) ||
      !Array.isArray(defaultMembers) ||
      appliedMembers.length !== defaultMembers.length
    ) {
      return false;
    }

    appliedMembers = appliedMembers.concat().sort();
    defaultMembers = defaultMembers.concat().sort();
    for (let i = 0; i < appliedMembers.length; i++) {
      if (appliedMembers[i] !== defaultMembers[i]) {
        return false;
      }
    }

    return true;
  }

  render() {
    const { filtered, onEditorMode, filter, inactive, defaultFilter } = this.props;
    const { truncateDescription } = this.state;

    const isExceptFilter = this.isExceptFilter();

    let disableFilter = inactive || !!filter.disabled;
    const inDashIcon = this.props.type === 'DASHBOARD_ICON';
    const canEdit = onEditorMode && this.props.context.permissions.isAtLeastExpert();
    if (
      (!canEdit && !inDashIcon && filter.hide) ||
      (disableFilter && !(filter?.dashContainFilter ?? true) && _.isEmpty(filter.selectedMembers))
    ) {
      return null;
    }

    let title = this.props.context.msg.t('no.filter');

    if (this.props.customMember) {
      title = `<b class="mt-1 mb-1" style="font-size: 15px">${this.props.customMember}</b><ul class="mb-1">`;
    } else if (filter.selectedMembers.length > 0) {
      title = `<b class="mt-1 mb-1" style="font-size: 15px">${this.props.context.msg.t(
        'applied.filters',
      )}:</b><ul class="mb-1">`;
      for (let selectedMember of filter.selectedMembers) {
        title += `<li><b>${selectedMember.label}</b>: ${selectedMember.value}</li>`;
      }
      title += `</ul>`;
    }

    const canResetFilters =
      this.props.dashboardPath &&
      defaultFilter?.length > 0 &&
      !this.isEqualToDefaultFilter(
        filter.selectedMembers.map((member) => member.value),
        defaultFilter,
      );
    const canClearFilters = this.props.filter.runtimeRestriction?.length === 0;
    return (
      <div
        className={`dashboard-filter-information filter-block ${filtered ? 'filtered' : ''} ${
          disableFilter ? 'disabled' : ''
        } ${filter.required && !filtered ? 'filter-required' : ''} ${filter.hide ? 'hidden-filter' : ''} ${
          this.props.className
        }  ${this.props.onOverflowDropdown ? 'filterDragging' : ''}`}
        ref={this.props.provider?.innerRef}
        {...this.props.provider?.draggableProps}
        {...this.props.provider?.dragHandleProps}
      >
        {this.props.onEditorMode && (
          <Icon className="FilterIconDraggableIcon" type={Icon.MATERIAL} icon="drag_indicator" />
        )}
        <a
          href="#"
          onClick={this.openDialog}
          title={title}
          id={`dashboard-filter-${filter.id}`}
          className={`label ${filtered ? 'filtered' : ''} ${
            truncateDescription ? 'truncate-text filter-limit-width' : ''
          } nodis flex-grow-1`}
        >
          <Icon icon={'filter_alt'} className={`dashboard-filter-icon`} /> {filter.caption}{' '}
          {isExceptFilter ? `(${this.props.context.msg.t('except')})` : ''}:{' '}
          <span className="dashboard-filter-information-description">
            {this.props.customMember
              ? this.props.customMember
              : filter.selectedMembers.length === 0
                ? this.props.context.msg.t('no.filter')
                : filter.selectedMembers.map((mem) => mem.label).join(', ')}
          </span>
          {!filtered && <i style={{ marginLeft: '5px' }} className="icon-angle-down"></i>}
        </a>
        {canResetFilters && (
          <React.Fragment>
            <a
              href="#"
              className="reset-filter-icon"
              onClick={() => this.resetFilters()}
              title={this.props.context.msg.t('restore.default.filter')}
            >
              <Icon icon={`restart_alt`} />
            </a>
          </React.Fragment>
        )}
        {filtered && (
          <React.Fragment>
            {this.props.showTruncateButton && (
              <a
                href="#"
                className={`label label-info expand-filter disabled-${disableFilter}`}
                onClick={() => this.toggleTruncateDescription()}
              >
                <i className={truncateDescription ? 'icon-double-angle-right' : 'icon-double-angle-left'}></i>
              </a>
            )}
            {canClearFilters && (
              <a
                href="#"
                className="label label-important remove-filter"
                onClick={() => this.clearFilters()}
                title={this.props.context.msg.t('clear.selected.filters')}
              >
                <Icon icon={'close'} />
              </a>
            )}
          </React.Fragment>
        )}

        {canEdit && !disableFilter && (
          <a href="#" className="user-filter-config-icon ConfigFilterGear" onClick={this.openConfigureDialog}>
            <Icon icon="settings" />
          </a>
        )}
      </div>
    );
  }
}

export default ContextEnhancer(FilterItem);
