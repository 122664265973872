import './BngSwitch.css';
import React, { useMemo } from 'react';
import Utils from 'components/Utils';
import HelpIcon from 'components/ui/common/HelpIcon';

export default function BngSwitch({
  id = '',
  field,
  form,
  className = '',
  title = '',
  label = '',
  verticalTitle = false,
  onChange = _.noop,
  hint = { title: '' },
  ...props
}) {
  const effectiveId = useMemo(() => {
    return _.isEmpty(id) ? Utils.randomId() : id;
  }, [id]);

  return (
    <div className={`BngSwitch ${className} ${verticalTitle ? 'vertical-title' : ''}`}>
      <input
        type="checkbox"
        id={effectiveId}
        className="switch-input"
        checked={field ? field.value : props.checked}
        onChange={onChange}
        {...field}
        {...props}
      />
      <label htmlFor={effectiveId} className="switch-label" />
      <div className="BngSwitchLabel flex-center-items gap-1">
        {label || title}
        {hint.title && <HelpIcon title={hint.title} className="m-0" {...hint} />}
      </div>
    </div>
  );
}
