import {bngYup} from "components/bng/form/yup/BngYup";
import React from 'react';
import {Field, Formik} from "formik";
import Api from "components/Api";
import {BngField} from "components/bng/form/BngField";
import {BngForm} from "components/bng/form/BngForm";
import {BngSelect} from "components/bng/form/BngSelect";
import BngDocumentationHelp from "components/bng/ui/BngDocumentationHelp";
import {BngDropdown} from "components/bng/ui/BngDropdown";
import Button from "components/ui/Button";
import Icon from "components/ui/common/Icon";
import UiMsg from "components/ui/UiMsg";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";

const SortRankOptions = (context) => {
    return [
        {value: 0, label: context.msg.t('without.sort')},
        {value: 1, label: context.msg.t('crescente.livre')},
        {value: 2, label: context.msg.t('decrescente.livre')},
        {value: 3, label: context.msg.t('crescente.com.quebra')},
        {value: 4, label: context.msg.t('decrescente.com.quebra')},
        {value: 5, label: context.msg.t('ranking_dos_superiores'), enableLimit: true},
        {value: 6, label: context.msg.t('ranking_dos_inferiores'), enableLimit: true},
    ]
};

const SortRankSchema = bngYup(yup => {
    return yup.object().shape({
        mode: yup.number().default(0),
        count: yup.number().default(10),
    });
});

const SortRankDropdown = ({
                              count,
                              mode,
                              context,
                              closeDropdown = _.noop,
                          }) => {
    const initialValues = {mode, count};
    const rankOpts = SortRankOptions(context);
    const baseUrl = Api.buildBaseUrl(true);

    const applyClassification = async (values, actions) => {
        try {
            await Api.executeExp(`#{analysisBean.changeSortOptions(${values.mode}, ${values.count})}`);
            closeDropdown();
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            actions.setSubmitting(false);
        }
    };


    return (
        <ul className="SortRankDropdown submenu submenu-analysis-visualizations">
            <li>
                <div className="menu-text">
                    {context.msg.t('reportsProperties_popup_tab_sort')}
                    <BngDocumentationHelp iconTitle={context.msg.t('analysis.sort.hint', baseUrl)}
                                          link={context.msg.t('documentation.sort.data')}
                                          className="AnalysisVisualizationHelp"/>
                </div>
            </li>
            <li>
                <Formik initialValues={initialValues}
                        validationSchema={SortRankSchema}
                        onSubmit={applyClassification}>
                    {({values, isSubmitting}) => {
                        const actualRank = rankOpts.find(sortOpt => sortOpt.value == values.mode);
                        return (
                            <BngForm>
                                <Field name="mode"
                                       component={BngField}
                                       withLabel={false}
                                       inputComponent={BngSelect}
                                       emptyOption={false}
                                       options={rankOpts}
                                />
                                <div className="SortRankQuantityWrapper">
                                    <Icon icon="sort_by_alpha"/>
                                    <Field name="count"
                                           disabled={!actualRank || !actualRank.enableLimit}
                                           withLabel={false}
                                           component={BngField}
                                           type="number"
                                           min={0}
                                           max={99999}
                                    />
                                </div>
                                <Button type="submit" style={{width: '100%'}} disabled={isSubmitting}>
                                    {context.msg.t('apply_button')}
                                </Button>
                            </BngForm>
                        )
                    }}
                </Formik>
            </li>
        </ul>
    )
};

const SortRankMenuItem = ({
                              analystSortCount,
                              analystSortMode,
                              context,
                              disabled,
                          }) => {
    return (
        <BngDropdown
            className="SortRankOption"
            popperClassName="RightMenuOptionPopper"
            boundariesElement={j('#page-content')[0]}
            popperOpts={{placement: "left"}}
            customButton={({openDropdown}) => {
                return <RightMenuItem icon="format_line_spacing"
                                           className="SortRankMenuItem"
                                           disabled={disabled}
                                           onClick={openDropdown}
                                           title={context.msg.t('reportsProperties_popup_tab_sort')}/>
            }}
            customOptions={({closeDropdown}) => {
                return <SortRankDropdown context={context}
                                         count={analystSortCount}
                                         mode={analystSortMode}
                                         closeDropdown={closeDropdown}/>
            }}
        />
    )
};

export default SortRankMenuItem;
