import './ValidatePhoneNumberDialog.css';

import React, { useEffect, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import { DefaultDialogActions } from 'components/ui/FormUtils';

const INPUT_COUNT = [1, 2, 3, 4, 5, 6];

function getInputForIdx(idx) {
  return document.getElementById(`validate-mobile-code-input-${idx}`);
}

function isValidInputDigits(data = '') {
  return data.match(/^[a-zA-Z0-9]+$/);
}

function isValidClipboardCode(clipboardData) {
  return clipboardData.length === 6 && isValidInputDigits(clipboardData);
}

export default function ValidatePhoneNumberDialog({ closeModal = _.noop, phone = '', userId = null }) {
  const { msg } = useBimContext();

  const [timeLeft, setTimeLeft] = useState(60);
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [incorrectInputCode, setIncorrectInputCode] = useState(null);
  const [code, setCode] = useState('');

  useEffect(() => {
    resendCode();
  }, []);

  useEffect(() => {
    if (incorrectInputCode && code === incorrectInputCode) {
      setValidated(false);
    } else if (incorrectInputCode && code !== incorrectInputCode) {
      setValidated(null);
    }
  }, [code]);

  const handlePaste = () => {
    navigator.clipboard.readText().then(function (text) {
      if (isValidClipboardCode(text)) {
        for (let i = 0; i < 6 && i < text.length; i++) {
          const input = getInputForIdx(i + 1);
          input.value = text.charAt(i);
        }
        const lastInput = getInputForIdx(6);
        lastInput.focus();
        setCode(INPUT_COUNT.map((idx) => getInputForIdx(idx).value).join(''));
      }
    });
  };

  const verifyCodeAuthentication = async () => {
    try {
      const data = await Api.ValidateMobile.validatePhone(code, phone, userId);
      setValidated(data.validated);
      if (data.validated) {
        UiMsg.ok(msg.t('validate.mobile.dialog.verification.success'));
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          closeModal(true);
        }, 1000);
      } else {
        UiMsg.error(msg.t('validate.mobile.dialog.verification.error'));
        setIncorrectInputCode(code);
      }
    } catch (e) {
      console.error('Error on verifyCodeAuthentication()', e);
    }
  };

  const resendCode = async (fromButton = false) => {
    try {
      await Api.ValidateMobile.generateCode(phone, userId);

      if (fromButton) {
        UiMsg.ok(msg.t('validate.mobile.dialog.resend.code.success'));
        startCountdown();
      }
    } catch (e) {
      UiMsg.error(msg.t('validate.mobile.dialog.send.code.error'));
      console.error('Error on resendCode()', e);
    }
  };

  const startCountdown = () => {
    setDisabled(true);
    let interval = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft <= 1) {
          clearInterval(interval);
          setDisabled(false);
          return 60;
        }
        return timeLeft - 1;
      });
    }, 1000);
  };

  const handleInputChange = async (index, e) => {
    e.preventDefault();
    const { key, ctrlKey, metaKey } = e;
    const isPasteShortcut = (key === 'v' || key === 'V') && (ctrlKey || metaKey);
    const currentInput = getInputForIdx(index);
    const previousInput = getInputForIdx(index - 1);
    const nextInput = getInputForIdx(index + 1);

    if (isPasteShortcut) {
      handlePaste();
    } else if (key === 'Enter') {
      await verifyCodeAuthentication();
    } else if (key === 'Backspace') {
      if (previousInput && currentInput.value.length === 0) {
        previousInput.focus();
      } else if (currentInput.value.length !== 0) {
        currentInput.value = '';
      }
    } else if (key === 'ArrowLeft') {
      if (previousInput) {
        previousInput.focus();
      }
    } else if (key === 'ArrowRight') {
      if (nextInput) {
        nextInput.focus();
      }
    } else if (key === 'Delete') {
      if (nextInput && currentInput.value.length === 0) {
        nextInput.focus();
      } else if (currentInput.value.length !== 0) {
        currentInput.value = '';
      }
    } else if (isValidInputDigits(key) && key.length === 1) {
      currentInput.value = key;
      if (nextInput) {
        nextInput.focus();
      }
    }

    setCode(INPUT_COUNT.map((idx) => getInputForIdx(idx).value).join(''));
  };

  return (
    <Dialog
      onClose={() => closeModal()}
      title={msg.t('user.dialog.security.verification')}
      className="ValidatePhoneNumberDialog"
      loading={loading}
    >
      <Dialog.Body>
        <div className="ValidatePhoneNumberDialog-content">
          <span className="ValidatePhoneNumberDialog-subtitle">{msg.t('user.dialog.confirm.code.mobile')}</span>
          <div className="ValidatePhoneNumberDialog-code">
            {INPUT_COUNT.map((inputNumber) => (
              <input
                type="text"
                onPaste={handlePaste}
                key={inputNumber}
                id={`validate-mobile-code-input-${inputNumber}`}
                className={`${validated === null ? '' : validated ? 'correct' : 'incorrect'}`}
                maxLength="1"
                onKeyDown={(e) => handleInputChange(inputNumber, e)}
              />
            ))}
          </div>
          {validated !== null && (
            <div className="ValidatePhoneNumberDialog-description">
              <Icon
                className={`ValidatePhoneNumberDialog-description-icon ${validated ? 'correct' : 'incorrect'}`}
                icon={validated ? 'check_circle' : 'info'}
              />
              <div style={{ color: validated ? 'green' : 'red' }}>
                {validated
                  ? msg.t('login_two_factor_auth_code_status_correct')
                  : msg.t('login_two_factor_auth_code_status_incorrect')}
              </div>
            </div>
          )}
          <div className="ValidatePhoneNumberDialog-resend">
            {disabled ? (
              <span>{msg.t('resend_code_in_seconds', timeLeft)}</span>
            ) : (
              <a onClick={() => resendCode(true)}>{msg.t('resend_code_validate')}</a>
            )}
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <DefaultDialogActions
          okLabel="verify"
          closeModal={() => closeModal()}
          onClickSaveButton={verifyCodeAuthentication}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
