import "./NotificationMenuButton.css";

import React, {Component} from "react";
import PropTypes from "prop-types";

import Button from "components/ui/Button";
import ContextEnhancer from "components/ContextEnhancer";
import MenuButton from "components/ui/navbar/MenuButton";
import Checkbox from "components/ui/Checkbox";
import Avatar from "components/ui/Avatar";
import Api from "components/Api";
import {ScrollContainer} from "components/ui/ScrollContainer";
import NotificationMessageDialog from "../notification/NotificationMessageDialog"
import Utils from "components/Utils"
import NotificationDialog from "components/ui/notification/NotificationDialog";
import Icon from "components/ui/common/Icon";
import useBimContext from "components/hooks/useBimContext";

class NotificationMenuButton extends Component {

    static propTypes = {
        notifications: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                isSuccess: PropTypes.bool.isRequired,
                type: PropTypes.string.isRequired,
                title: PropTypes.string,
                message: PropTypes.string,
                sentDate: PropTypes.any.isRequired,
                readDate: PropTypes.any,
                criticity: PropTypes.shape({
                    type: PropTypes.string,
                    color: PropTypes.string,
                    icon: PropTypes.string
                }),
                mentionedBy: PropTypes.shape({
                    id: PropTypes.number,
                    displayName: PropTypes.string
                }),
                params: PropTypes.object
            })
        ),
        badgeValue: PropTypes.any
    };

    static defaultProps = {
        notifications: [],
        badgeValue: 0
    };

    constructor(props) {
        super(props);
        this.state = {
            types: ['system', 'markings', 'monitoring'].map(el => {
                    return {
                        value: el,
                        selected: true
                    }
                }
            )
        };

    }

    filterChanged(filter) {
        filter.selected = !filter.selected;
        this.setState({types: this.state.types});
        this.findNotifications();
    }

    findNotifications() {
        const selectedTypes = this.state.types
            .filter(el => el.selected)
            .map(el => el.value);
        this.props.findNotifications({type: selectedTypes});
    }

    render() {
        return (
            <MenuButton className={`NotificationMenuButton`}
                        icon="notifications"
                        badge={{color: 'badge-important', value: this.props.badgeValue}}
                        title={this.props.context.msg.t('notifications')}
                        headerText={this.props.context.msg.t('notifications')}
                        onOpen={() =>
                        {
                            this.notificationMarkAllAsRead();
                            this.findNotifications()
                        }}
                        ref={ref => this._menuButton = ref}>

                <li className="no-hover no-padding nav-notification-filters">
                    {this.state.types.map((filter, idx) =>
                        <Checkbox key={idx} value={{label: this.props.context.msg.t(filter.value)}}
                                  onChange={() => this.filterChanged(filter)}
                                  selected={filter.selected}/>
                    )}
                </li>

                <li className="no-hover no-padding">
                    <ScrollContainer className="notificationsTable-container">
                        <table className="notificationsTable">
                            <tbody>
                            {this.props.notifications.map(_notification => {

                                var typeClass = _notification.criticity.icon.replace('icon-', '');

                                return (
                                    <tr key={_notification.id}>
                                        <td style={{
                                            width: 2,
                                            padding: 0,
                                            border: 0,
                                            background: Utils.Notification.getNotificationColor(_notification)
                                        }} className={`${typeClass}`}>&nbsp;</td>
                                        <td>
                                            <div className={'scrollbar-inner notification-list-content'}
                                                 ref={c => jQuery(c).scrollbar()}>
                                                <div>
                                                    {this.renderMsg(_notification)}
                                                </div>
                                            </div>
                                            {this.renderFooter(_notification)}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </ScrollContainer>
                </li>

                <li className="no-hover">
                    <div className="menu-bottom-btn">
                        <Button className="btn-link btn-small"
                                onClick={async () => {
                                    await this.openNotificationDialog();
                                    this._menuButton.close();
                                }}>
                            {this.props.context.msg.t('see.all.notifications')}
                        </Button>
                    </div>
                </li>

            </MenuButton>
        );
    }

    renderMsg(notification) {

        switch (notification.type) {
            case "load": {
                return <LoadMessage notification={notification} msg={this.props.context.msg}/>
            }
            case "MONITOR":
            case "monitor": {
                return <MonitorMessage notification={notification} msg={this.props.context.msg}/>
            }
            case "NOTE":
            case "mention": {
                return <MentionMessage notification={notification} msg={this.props.context.msg}/>
            }
            case "BOOK_GENERATION":
            case "book": {
                return <BookMessage notification={notification} msg={this.props.context.msg}/>
            }
            case "PROJECT_EXPIRED_USER_ACCESS": {
                return <ProjectExpiredUserAccessMessage notification={notification} msg={this.props.context.msg}/>
            }
            case 'IN_MEMORY_LOAD_LIMIT_ALERT': {
                return (
                    <InMemoryLoadLimitAlertNotification notification={notification}/>
                );
            }
            case 'EXTRACTOR_OFFLINE': {
                return (
                    <ExtractorOfflineNotification notification={notification}/>
                );
            }
            default:
                return <GenericMessage notification={notification} msg={this.props.context.msg}/>
        }
    }

    openNotificationMessageDialog = (id) => {
        window.ReduxStore.dispatch(window.Actions.MODALS.open(NotificationMessageDialog, {notificationId: id}));
    };

    openNotificationDialog = () => {
        window.ReduxStore.dispatch(window.Actions.MODALS.open(NotificationDialog, (this.props)));
    };


    notificationMarkAsRead = async (id) => {
        await j.post(`/spr/notifications/${id}/mark-as-read`);
        await this.props.markAsRead(id);

    };

    notificationMarkAllAsRead = async () => {
        await j.post(`/spr/notifications/markallasread`);
        await this.props.markAllAsRead();
    };

    renderFooter(notification) {
        let hasMessageDetails = notification.message ? notification.message.length > 0 : false;
        let hasBeenRead = !!notification.readDate;
        return (
            <div className="notification-footer">
                <Icon icon="access_time"/>
                &nbsp;
                <span className="date">
                    {moment(notification.sentDate).fromNow()}
                </span>

                {hasMessageDetails &&
                <Button className="btn-link pull-right details"
                        icon="assignment"
                        newStyleButton
                        title={this.props.context.msg.t('see.details')}
                        onClick={async () => {
                            await this.openNotificationMessageDialog(notification.id);
                            this._menuButton.close();
                            this.props.markAsRead(notification.id);
                        }}
                />
                }
            </div>
        );
    }
}

const ProjectExpiredUserAccessMessage = ({notification, msg}) => {
    const {userId, userCaption, projectId, projectCaption} = notification.params;
    return (
        <div className="generic">
            <div className="mention-msg">
                <div className="avatar-container">
                    <Avatar userId={userId}/>
                </div>
            </div>
            <span className="text">
                {msg.t('project.expired.notification.title')}
            </span>
            <div className="descr">
                {msg.t('project.expired.notification.message', [userCaption, projectCaption])}
            </div>
        </div>
    );
};

const UserTag = ({id = 0, email = '', caption = ''}) => (
    <span title={email} className="UserTag">
        <Avatar userId={id}/>
        {' '}
        <b>
            {caption}
        </b>
    </span>
);

const GenericMessage = ({notification, msg}) => {
    return (
        <div className="generic GenericMessage">
            <div className="mention-msg">
                <i className="icon-bim-logo"/>
            </div>
            <span className="text">
                BIMachine
            </span>
            <div className="descr" dangerouslySetInnerHTML={{__html: notification.title}}/>
        </div>
    );
};

const BookMessage = ({notification, msg}) => {
    return (
        <div>
            <div className="book-msg">
                <i className="icon icon-bim-book"/>
            </div>
            <span className="book-text">
                {msg.t('book.generation')}:
            </span>
            {notification.type === 'book' &&
            <div className="book-descr" dangerouslySetInnerHTML={{__html: notification.title}}/>
            }
            {notification.type === 'BOOK_GENERATION' &&
            <div className="book-descr">
                {!notification.params.book.containError &&
                <span>
                    O <a href={`${Api.baseUrl()}${notification.params.book.downloadLink}`}
                         download={`${notification.params.book.bookCaption}.pdf`} target="_blank"
                         style={{color: '#08c !important'}}>
                    Book {notification.params.book.bookCaption}
                </a> do Cockpit "{notification.params.cockpit.name}" foi gerado com sucesso
                </span>
                }

                {notification.params.book.containError &&
                <span>
                    Ocorreu um erro durante a geração do Book "{notification.params.book.bookCaption}" do Cockpit
                    "{notification.params.cockpit.name}". {msg.t('book.generate.notification.error.message')}.
                </span>
                }
            </div>
            }
        </div>
    );
};

const MentionMessage = ({notification, msg}) => {


    const isNote = notification.type === 'NOTE';
    if (isNote) {
        notification.mentionedBy = notification.params.sender;
    }

    if (_.isEmpty(notification.mentionedBy)) {
        return (
            <div>
                <div className="mention-descr" dangerouslySetInnerHTML={{__html: notification.message}}/>
            </div>
        );
    }

    const hasGroup = isNote && _.has(notification.params, 'group');
    const objectUrl = isNote ? Api.loadObjectUrl({content: notification.params.item.path, openNote: true}) : '';

    return (
        <div>
            <div className="mention-msg-info">
                <div className="mention-msg">
                    <div className="avatar-container">
                        <Avatar userId={notification.mentionedBy.id}/>
                    </div>
                </div>
                <span className="mention-text">
                    {notification.mentionedBy.displayName} {msg.t('note.mention')}
                </span>
            </div>
            {!isNote &&
            <div className="mention-descr" dangerouslySetInnerHTML={{__html: notification.message}}/>
            }
            {isNote &&
            <div className="mention-descr">
                {hasGroup &&
                <span>
                    {`O grupo ${notification.params.group.name}, a qual você pertence, foi citado por ${notification.params.sender.displayName} em uma observação no objeto `}
                </span>
                }
                {!hasGroup &&
                <span>
                    {`Você foi citado por ${notification.params.sender.displayName} em uma observação no objeto `}
                </span>
                }
                <a href={objectUrl}>{notification.params.item.caption}</a>
            </div>
            }
        </div>
    );
};

const LoadMessage = ({notification, msg}) => {
    return (
        <div>
            <div className="notification-title-container">
                <div className="notification-title-icon">
                    <i className="icon icon-hdd"/>
                </div>
                <div className="notification-title-description">{msg.t('data.load')}</div>
            </div>
            <div
                className="notification-msg"
                dangerouslySetInnerHTML={{__html: notification.title}}/>
        </div>
    );
};

const MonitorMessage = ({notification, msg}) => {
    if (notification.isSuccess) {
        return (
            <div className="monitor with-success">
                <div className="mon-icon">
                    <i className="icon icon-bullhorn"/>
                </div>
                <span className="mon-type">
                    <span className="label" /*style={{background: notification.criticity.color}}*/>
                        {/*<span className={`icon ${notification.criticity.icon}`}/>*/}
                        {msg.t(notification.criticity.type)}
                    </span>
                </span>
                <span className="mon-msg" dangerouslySetInnerHTML={{__html: notification.message}}/>
            </div>
        );
    } else {
        return (
            <div className="monitor with-error">
                <div className="mon-icon">
                    <i className="icon icon-bullhorn"/>
                </div>
                <span className="mon-type">
                    <span className="label">
                        {/*<span className="icon icon-exclamation"/>*/}
                        {msg.t('error')}
                    </span>
                </span>
                <span className="mon-msg" dangerouslySetInnerHTML={{__html: notification.title}}/>
            </div>
        );
    }
};

export function InMemoryLoadLimitAlertNotification({notification}) {
    const {msg} = useBimContext();
    const message = msg.t(
        'inMemoryLoadLimitAlert.notification.description',
        [
            notification.params.inMemoryScheduling.caption,
            notification.params.inMemory.caption,
            notification.params.project.caption
        ]
    );
    return (
        <div className={`InMemoryLoadLimitAlertNotification`}>
            <div className="notification-title-container">
                <div className="notification-title-icon">
                    <i className="icon icon-hdd"/>
                </div>
                <div className="notification-title-description">
                    {msg.t('inMemoryLoadLimitAlert.notification.title')}
                </div>
            </div>
            <div
                className="notification-msg"
                dangerouslySetInnerHTML={{__html: message}}
            />
        </div>
    );
}

export function ExtractorOfflineNotification ({notification, className = ''}) {
    const {msg} = useBimContext();
    return (
        <div className={`ExtractorOfflineNotification ${className}`}>
            <div className="notification-title-container">
                <div className="notification-title-icon">
                    <i className="icon-bim-logo"/>
                </div>
                <div className="notification-title-description">
                    {msg.t('BIMGateway')}
                </div>
            </div>
            <div
                className="notification-msg"
                dangerouslySetInnerHTML={{__html: msg.t('EXTRACTOR_OFFLINE_ALERT.notification', [notification.params.extractorName, notification.params.projectCaption])}}
            />
        </div>
    );
}
export default ContextEnhancer(NotificationMenuButton);