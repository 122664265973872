import styles from './WhatsAppInfoDialog.module.css';

import React, { useEffect, useState } from 'react';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';

export default function WhatsAppInfoDialog() {
  const [qrCodeInfo, setQrCodeInfo] = useState();
  const [whatsStatus, setWhatsStatus] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        const statusResult = await Api.MobileMessageRequest.findStatus();
        setWhatsStatus(statusResult);
        if (statusResult.whats?.state !== 'CONNECTED') {
          const qrCodeResult = await Api.MobileMessageRequest.findWhatsQrCode();
          setQrCodeInfo(qrCodeResult);
        }
      } catch (e) {
        console.error('Error on findWhatsQrCode()', e);
        UiMsg.ajaxError(null, e);
        setError(e);
      }
    })();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.WhatsAppInfoDialog}>
      {whatsStatus && (
        <div>
          {`WhatsApp current state: ${whatsStatus.whats?.state || 'NOT CONNECTED'}`}
          <br />
          {whatsStatus.whats?.state !== 'CONNECTED' && qrCodeInfo?.qrCode && (
            <div>
              <img src={`data:image/png;base64,${qrCodeInfo.qrCode}`} alt="QR Code" style={{ filter: 'grayscale(1)' }} />
              <div>Last updated: {Utils.Date.formatDateTime(qrCodeInfo.lastUpdated)}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
