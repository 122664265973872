import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {MODALS} from "components/ui/redux/Actions";
import Icon from "components/ui/common/Icon";
import ContextEnhancer from "components/ContextEnhancer";
import CreatePresentation from "components/ui/presentation/CreatePresentation";

class BngPresentationButton extends Component {

    static propTypes = {
        slides: PropTypes.array
    };

    static defaultProps = {
        slides: []
    };

    state = {
        open: false,
    };

    openPresentationDialog = (event) => {
        this.props.dispatch(MODALS.open(CreatePresentation, {
            fromCockpit: true,
            itemsCockpit: this.props.slides
        }));
    };

    render() {
        return (
            <div className={`cockpit-nav-btn`}>
                <a onClick={this.openPresentationDialog}
                   title={this.props.context.msg.t('presentation')}
                >
                    <Icon icon="airplay"/>
                </a>
            </div>
        )
    }
}

export default ContextEnhancer(connect()(BngPresentationButton));