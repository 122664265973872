import styles from './ImageDialog.module.css';
import React from 'react';
import Dialog from 'components/ui/Dialog';

export default function ImageDialog({ title, closeModal, image }) {
  return (
    <Dialog title={title} onClose={closeModal} className={`ImageDialog medium ${styles.dialog}`}>
      <Dialog.Body>
        <div className="image" style={{ backgroundImage: `url(${image})` }} />
      </Dialog.Body>
    </Dialog>
  );
}
