import React from 'react';
import Icon from "components/ui/common/Icon";

const TreeComponent = ({
                           href = '',
                           text = '',
                           icon = '',
                           children,
                           expanded = false,
                       }) => {
    if (href !== "#") {
        return (
            <label className="flex-center-items">
                {children}
                <span className="lbl flex-center-items">
                    <Icon icon={icon} outlined={expanded}/>
                    <div className="icon-object-text">{text}</div>
                </span>
            </label>
        );
    } else {
        return (
            <div className="flex-center-items">
                <Icon icon={icon} className="folder-icon" outlined={expanded}/>
                <div className="tree-table-folder-description">{text}</div>
            </div>
        )
    }
}

export default TreeComponent;