import './useOverlay.css'

import React, { useEffect, useRef, useMemo } from 'react';

const useOverlay = (show = false) => {
    const overlayRef = useRef();

    const showOverlay = useMemo(() => {
        return show ? 'Overlay' : '';
    }, [show]);

    useEffect(() => {
        const overlayContainer = overlayRef.current;
        if (overlayContainer) {
            overlayContainer.className = `${showOverlay}`;
        }
    }, [showOverlay]);

    return [overlayRef];
};

export default useOverlay;