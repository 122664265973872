'use strict';

import { Axios, findContextPath } from 'commonUtils';

const $ = jQuery;

class UserApi {
  static confirmPassword(password) {
    return $.postJSON(`/spr/ui/user/confirm-password`, password);
  }

  static async findUser(idUser, projectId) {
    const { data } = await Axios.get(`/spr/users/${idUser}`, {
      params: {
        projectId,
      },
    });
    return data;
  }

  static logout() {
    window.location.replace(`${findContextPath()}/killsession`);
  }

  static saveUser(user, projectName, mobileenable) {
    if (user.id === 0) {
      return Promise.resolve($.postJSON(`/spr/users`, user));
    }
    return Promise.resolve($.postJSON(`/spr/users/${user.id}?${$.param({ projectName, mobileenable })}`, user));
  }

  static uploadUserAvatar(user, avatar) {
    return Promise.resolve($.postJSON(`/spr/users/${user.id}`, { avatar }));
  }

  static changePassword(userId, password, projectName) {
    return Promise.resolve($.postJSON(`/spr/users/${userId}/change-password?${$.param({ projectName })}`, password));
  }

  static async findLastActivities(projectId, userId) {
    const { data } = await Axios.get(`/spr/ui/user/last-activities`, {
      params: {
        projectId,
        userId,
      },
    });
    return data;
  }

  static findUserLastAccess(projectId, userId) {
    return Promise.resolve($.getJSON(`/spr/ui/user/last-access?${$.param({ userId })}`));
  }

  static async findFavorites(q = undefined) {
    const { data } = await Axios.get(`/spr/ui/user/favorites`, {
      params: {
        q,
      },
    });
    return data;
  }

  static async findGroupsForUser(projectId, userId) {
    const { data } = await Axios.get(`/spr/users/${userId}/groups`, {
      params: {
        projectId,
      },
    });
    return data;
  }

  static isMasterOfProject(projectId) {
    return $.getJSON(`/spr/ui/user/master?${$.param({ projectId })}`);
  }

  static addFavorite({ userId, projectId, path }) {
    return Promise.resolve($.post(`/spr/users/${userId}/favorites`, { projectId, path }));
  }

  static removeFavorite({ userId, projectId, path }) {
    return Promise.resolve($.deleteReq(`/spr/users/${userId}/favorites?${$.param({ projectId, path })}`));
  }

  static getPreferences = async () => {
    return await Axios.get(`/spr/ui/user/preferences`);
  };

  static async changeUserPassword(email) {
    const formData = new FormData();
    formData.append('email', email);
    return await Axios.postData(`/api/users/password-recovery-request`, formData);
  }

  static async updateUserMessaging(reqBody) {
    let prefix = '/spr';
    if (window.location.pathname.indexOf('/api/') !== -1) {
      prefix = '/api';
    }
    const { data } = await Axios.post(`${prefix}/users/update-messaging`, reqBody);
    return data;
  }

  static async publicProfile(changePhoneRequestKey) {
    const { data } = await Axios.get(`/api/users/publicProfile`, {
      params: {
        changePhoneRequestKey,
      },
    });
    return data;
  }

  static async updateTimeZone(timeZone) {
    const { data } = await Axios.post(`/spr/users/timeZone`, {
      timeZone,
    });
    return data;
  }

  static async requestWhatsConsent(userId, projectId) {
    return await Axios.postData(`/spr/users/${userId}/whatsConsent`, { projectId });
  }
}

export default UserApi;
