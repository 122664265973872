import './AssistedObjectPage.css';

import useBimContext from 'components/hooks/useBimContext';
import React, { useEffect, useMemo, useState } from 'react';
import Utils from 'components/Utils';
import UiMsg from 'components/ui/UiMsg';
import BngAnalysisTypeSelected, {
  typeToHorizontalCardProps,
} from 'components/bng/pages/newAnalysis/BngAnalysisTypeSelected';
import Icon from 'components/ui/common/Icon';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import BngSearch from 'components/bng/ui/BngSearch';
import { BngHorizontalCard } from 'components/bng/ui/BngHorizontalCard';
import Api from 'components/Api';

export const assistedObjectService = {
  findSources: Api.Project.findSources,
  sourceCubes: Api.Project.sourceCubes,
  sourceFields: Api.Project.sourceFields,
  dynamicPeriodicities: Api.Filter.dynamicPeriodicities,
  queryStructure: Api.Project.queryStructure,
  findAssistedTypes: () => [],
  createObject: _.noop,
  updateObject: _.noop,
  findPreviewImages: _.noop,
  findValidationSchema: _.noop,
};

export default function AssistedObjectPage({
  className = '',
  service,
  afterFetch = _.noop,
  afterCardsComponent = _.noop,
  headerIcon,
  headerTitle,
  headerMessage,
  headerDocLink,
  searchPlaceholder,
  createObjectLabel,
  formBottomSlot,
  embeddedOpts,
}) {
  const context = useBimContext();

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [types, setTypes] = useState([]);

  const fetchData = async () => {
    try {
      const types = await service.findAssistedTypes({ embeddedOpts });
      types.forEach((type) => {
        delete type.tooltip;
        ['title', 'text', 'explanation'].forEach((prop) => {
          if (!type[prop]) return;

          const key = type[prop];
          if (!context.msg.containKey(key)) {
            delete type[prop];
          } else {
            type[prop] = context.msg.t(key);
          }
        });
      });

      setTypes(types);
      const urlSearchParams = Utils.History.currentUrlSearchParams();
      const selectedType = urlSearchParams.get('analysisType') || urlSearchParams.get('objectType');
      if (selectedType) {
        setSelectedType(selectedType);
      }

      await afterFetch({ types, selectedType });
    } catch (e) {
      console.error('Error on AssistedObjectPage.fetchData', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredTypes = useMemo(() => {
    if (!search) return types;

    const searchLower = search.toLowerCase();

    return types.filter((type) => {
      const props = ['title', 'text'];
      for (const prop of props) {
        if ((type[prop] || '').toLowerCase().includes(searchLower)) {
          return true;
        }
      }
      return false;
    });
  }, [types, search]);

  const onSelectType = (type, callback = _.noop) => {
    setSelectedType(type);
    window.requestAnimationFrame(() => {
      if (!embeddedOpts) {
        Utils.History.updateQuery({ objectType: type });
      }
      if (_.isFunction(callback)) {
        callback();
      }
    });
  };

  const onGoBack = (callback) => {
    onSelectType(null, _.isFunction(callback) ? callback : undefined);
  };

  let wrappedComponent;
  if (selectedType) {
    wrappedComponent = (
      <BngAnalysisTypeSelected
        types={types}
        selectedType={selectedType}
        onGoBack={onGoBack}
        onSelectType={onSelectType}
        service={service}
        createObjectLabel={createObjectLabel}
        formBottomSlot={formBottomSlot}
        embeddedOpts={embeddedOpts}
      />
    );
  } else {
    wrappedComponent = (
      <div className="TypeSelectionContainer">
        {!embeddedOpts && (
          <div className="Header">
            <div className="TextContainer">
              <div className="Title">
                {headerIcon && <Icon icon={headerIcon} />}
                {headerTitle}
              </div>

              <div className="TitleMessage">
                {headerMessage}
                {headerDocLink && (
                  <>
                    <br />
                    <a href={headerDocLink} target="_blank" rel="noreferrer">
                      {context.msg.t('know.more')}.
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <UiBlocker className="Content" block={loading}>
          <div className="SearchContainer">
            <BngSearch
              placeholder={searchPlaceholder ?? context.msg.t('search.by.type')}
              alwaysOpen={true}
              onChange={setSearch}
            />
          </div>

          <div className="CardsContainer">
            {filteredTypes.map((item, idx) => {
              return (
                <BngHorizontalCard
                  key={idx}
                  {...typeToHorizontalCardProps(item, context)}
                  onClick={() => onSelectType(item.type)}
                />
              );
            })}
          </div>

          {afterCardsComponent({})}
        </UiBlocker>
      </div>
    );
  }

  return (
    <div
      className={`AssistedObjectPage ${selectedType ? 'typeSelected' : 'onTypeSelection'} ${
        embeddedOpts ? 'embedded' : ''
      } ${className}`}
    >
      {wrappedComponent}
    </div>
  );
}
