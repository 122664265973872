import './BngSlider.css';
import React, { useEffect, useRef, useState } from 'react';
import useDebounce from 'components/hooks/useDebounce';

export function BngSlider({
  className = '',
  step = 10,
  min = 0,
  max = 100,
  showArrows = true,
  form,
  field,
  showInput = true,
  customTooltipMsg,
  ...props
}) {
  const wrapperEl = useRef(null);
  const spanEl = useRef(null);

  const [internalValue, setInternalValue] = useState(field.value ?? min);
  const [debouncedInternalValue] = useDebounce(internalValue, 500);

  const [inputValue, setInputValue] = useState(internalValue);

  // Update internal value in case value has been changed internally
  useEffect(() => {
    const val = processValue(field.value);
    if (val === internalValue) {
      return;
    }

    setInternalValue(val);
    setInputValue(val);
    updateSliderPosition(val);
  }, [field.value]);

  // Update form value with 500ms delay
  useEffect(() => {
    if (field.value === debouncedInternalValue) {
      return;
    }
    form.setFieldValue(field.name, debouncedInternalValue);
  }, [debouncedInternalValue]);

  const processValue = (val) => {
    if (!Number.isFinite(val)) {
      val = parseInt(val) || min;
    }
    val = Math.max(val, min);
    val = Math.min(val, max);
    return val;
  };

  const updateSliderPosition = (value) => {
    const position = (value * (wrapperEl.current?.offsetWidth ?? 1)) / max;
    wrapperEl.current.querySelector('.BngSliderSelection').style.width = `${position}px`;
    spanEl.current.style.left = `${position - 5}px`;
  };

  useEffect(() => {
    updateSliderPosition(internalValue);
  }, []);

  const changeFieldValue = (val) => {
    val = processValue(val);
    setInputValue(val);
    setInternalValue(val);
    updateSliderPosition(val);
  };

  const updateValue = (draggablePos, wrapperWidth) => {
    let val = (draggablePos * max) / wrapperWidth;
    val = Math.round(val / step) * step;
    changeFieldValue(val);
  };

  useEffect(() => {
    // setupDragElement
    wrapperEl.current.onclick = elementDrag;
    spanEl.current.onmousedown = dragMouseDown;
  }, []);

  const dragMouseDown = (e) => {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  };

  const closeDragElement = () => {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const elementDrag = (e) => {
    const wrapper = wrapperEl.current;
    e = e || window.event;
    e.preventDefault();
    // avoid overflow wrapper
    if (e.clientX < wrapper.getBoundingClientRect().left) {
      updateValue(0, wrapper.offsetWidth);
      return;
    }
    if (e.clientX > wrapper.getBoundingClientRect().left + wrapper.offsetWidth) {
      updateValue(wrapper.offsetWidth, wrapper.offsetWidth);
      return;
    }

    const newPosition = e.clientX - wrapper.getBoundingClientRect().left;
    updateValue(newPosition, wrapper.offsetWidth);
  };

  const position = (internalValue * (wrapperEl.current?.offsetWidth ?? 1)) / max;

  return (
    <div className={`BngSlider ${className}`} {...props}>
      <div className="BngSliderWrapper" ref={wrapperEl}>
        <div className="BngSliderTracker" />
        <div className="BngSliderSelection" style={{ width: `${position}px` }} />
        <span className="BngSliderGrab" ref={spanEl} style={{ left: `${position - 5}px` }} />
      </div>
      {showInput && (
        <input
          type="number"
          min={min}
          max={max}
          step={step}
          className={`${!showArrows && 'show-arrow'} pr-1`}
          value={inputValue}
          onChange={(e) => {
            if (e.target.value && !isNaN(e.target.value)) {
              changeFieldValue(e.target.value);
            }
          }}
          onBlur={(e) => changeFieldValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              const newValue = parseFloat(inputValue);
              if (!isNaN(newValue)) {
                setInputValue(newValue);
                changeFieldValue(newValue);
              }
            }
          }}
        />
      )}
      {customTooltipMsg && (
        <div role="tooltip" className={`tooltip bottom customTooltip`} style={{ left: `${position - 5}px` }}>
          <div className="tooltip-arrow"></div>
          <div className="tooltipInner">{customTooltipMsg}</div>
        </div>
      )}
    </div>
  );
}
