import './TreeTable.css';

import React, {Component} from "react";
import PropTypes from "prop-types";

import ContextEnhancer from "components/ContextEnhancer";
import Icon from "components/ui/common/Icon";
import TreeComponent from "components/ui/common/TreeComponent";
import BngSearch from "components/bng/ui/BngSearch";
import Utils from "components/Utils";

const calculateElementLevel = (path) => {
    return path.split('/').length - 2;
};

class TreeTable extends Component {

    static propTypes = {
        folders: PropTypes.array,
        component: PropTypes.any,
        onChange: PropTypes.func,
        onClearSelection: PropTypes.func,
        onlyTable: PropTypes.bool,
        path: PropTypes.array,
        canSelectObjects: PropTypes.bool,
        additionalFilter: PropTypes.func,
        allowClear: PropTypes.bool,
    };

    static defaultProps = {
        component: TreeComponent,
        folders: [],
        onChange: _.noop,
        onClearSelection: _.noop,
        onlyTable: false,
        path: [],
        canSelectObjects: true,
        additionalFilter: _.noop,
        allowClear: true,
    };

    state = {
        expanded: {},
        viewfolders: [],
    };

    constructor(props) {
        super(props);
        if (Array.isArray(props.objectSelect)) {
            props.objectSelect.forEach(os => {
                while(_.isString(os) && os.split('/').length > 2) {
                    os = Utils.Object.parentPath(os)
                    this.state.expanded[os] = {}
                }
            });
        } else if (_.isString(props.objectSelect)) {
            if (props.objectSelect.split('/').length > 2) {
                const folder = Utils.Object.parentPath(props.objectSelect);
                this.state.expanded[folder] = {}
            }
        }
    }

    componentDidMount() {
        const folders = this.getViewFolders();
        this.setState({viewfolders: folders});
    }

    componentDidUpdate(prevProps) {
        if (this.props.folders !== prevProps.folders) {
            const folders = this.getViewFolders();
            this.setState({viewfolders: folders});
        }
    }

    getViewFolders = (search = '') => {
        let viewFolders = _.cloneDeep(this.props.folders);
        return viewFolders.filter(row => this.returnSearchObjects(row, search));
    }

    cleanSelection = async () => {
        let expand = JSON.deepClone(this.state.expanded);
        this.props.onClearSelection();
        await this.setState({expanded: {}});
        await this.setState({expanded: expand});
    };

    expandAll = () => {
        this.state.viewfolders.forEach(row => this.expandAllFolders(row));
    };

    expandAllFolders = (row) => {
        if (row.href === "#") {
            let expanded = this.state.expanded;
            expanded[row.path] = {};
            this.setState({expanded});
        }
        if (row.children) {
            row.children.forEach(e => this.expandAllFolders(e));
        }
    };

    lessExpanded = () => {
        this.setState({expanded: {}})
    };

    searchObject = (caption) => {
        this.setState({expanded: {}})
        const folders = this.getViewFolders(caption);
        this.setState({viewfolders: folders});
    };

    isFolder = (object) => {
        return object.href === "#";
    }

    returnSearchObjects = (row, search) => {
        search = search.toLowerCase();

        if (this.isFolder(row)) {
            for (let i = 0; i < row.children.length; i++) {
                let childRow = row.children[i];
                const removeChild = () => {
                    row.children.splice(i, 1);
                    i = i - 1;
                }

                if (childRow.children && childRow.children.length) {
                    let showChild = this.returnSearchObjects(childRow, search);
                    if (!showChild) {
                        removeChild();
                    }
                } else if (!this.isFolder(childRow)) {
                    const words = childRow.href.split('.');
                    const fileExtension = words[words.length - 1].trim();

                    if (this.props.additionalFilter({row: childRow, fileExtension}) || !childRow.text.toLowerCase().includes(search) && !fileExtension.includes(search)) {
                        removeChild();
                    }
                } else {
                    removeChild();
                }
            }
            if (row.children.length === 0) {
                return false;
            } else if (!!search) {
                let expanded = this.state.expanded;
                expanded[row.path] = {};
                this.setState({expanded});
            }
        }
        return true;
    };

    toggleExpand = (row) => {
        let expanded = this.state.expanded;
        if (row.path in expanded) {
            delete expanded[row.path];
        } else {
            expanded[row.path] = {};
        }
        this.setState({expanded});
    };

    isExpanded = row => {
        return row.path in this.state.expanded;
    };

    render() {
        const {objectSelect, onlyTable, canSelectObjects, allowClear, context: {msg}} = this.props;
        const isExpanded = Object.keys(this.state.expanded).length === 0;

        return (
            <div className="bng-tree-table-box">
                <div style={{display: onlyTable ? 'none' : ''}} className="bng-tree-table-action-box">
                    <BngSearch className={'flex-grow-1'}
                               onChange={this.searchObject}
                               placeholder={msg.t('object.name.search')}
                               name="tree-table-search"
                    />

                    {(allowClear && canSelectObjects) &&
                        <a className="button-clean-dialog ml-2" onClick={this.cleanSelection}>
                            <Icon icon="indeterminate_check_box"/>
                            <div className="tree-table-text">{msg.t('search.object.clean')}</div>
                        </a>
                    }

                    {isExpanded ?
                        <a className="button-retract-dialog ml-2" onClick={this.expandAll}>
                            <Icon icon="expand_more"/>
                            <div
                                className="tree-table-text">{msg.t('search.object.expand.more')}</div>
                        </a> :
                        <a className="button-retract-dialog ml-2" onClick={this.lessExpanded}>
                            <Icon icon="expand_less"/>
                            <div className="tree-table-text">{msg.t('search.object.retract')}</div>
                        </a>
                    }

                </div>
                <div className="scroll-bar-object-select" style={{paddingLeft: 15}}>
                    <table className="table table-condensed table-border table-hover tree-table-object">
                        <thead></thead>
                        <tbody>
                        {this.state.viewfolders.map(row => {
                            return (
                                <TreeTableRow key={rowKeyBuilder(row)}
                                              row={row}
                                              isExpanded={this.isExpanded}
                                              toggleExpand={this.toggleExpand}
                                              objectSelect={objectSelect}
                                              itemComponent={this.props.component}
                                              handleChange={this.handleChange}
                                />
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    handleChange = (value) => {
        this.props.onChange(this.findFolder(value));
    };

    findFolder = (value, folders = this.state.viewfolders) => {
        let result = false;
        for (let i = 0; i < folders.length && !result; i++) {
            if (folders[i].href === value) {
                result = folders[i];
            } else if (folders[i].children.length > 0) {
                result = this.findFolder(value, folders[i].children);
            }
        }
        return result;
    };

}

const rowKeyBuilder = (row) => {
    return row.path ? row.path : row.href;
}

const TreeTableRow = ({
                          row,
                          objectSelect = [],
                          isExpanded = _.noop,
                          toggleExpand = _.noop,
                          handleChange = _.noop,
                          itemComponent
                      }) => {
    const expanded = isExpanded(row);
    const maxTextLength = 50;
    let rowText = row.text;
    if (row.text.length > maxTextLength) {
        rowText = `${row.text.substring(0, maxTextLength)}...`;
    }
    const level = (calculateElementLevel(rowKeyBuilder(row)) - 1);
    const style = {
        marginLeft: (level > 0 ? 18 : 0) + (level > 1 && level * 12),
        borderLeft: level > 0 ? '1px dashed #ccc' : '',
        paddingLeft: level > 0 ? 15 : 0
    };

    const ObjectComponent = itemComponent;

    return (
        <>
            <tr onClick={() => !row.leaf ? toggleExpand(row) : undefined}>
              <td
                className={`tree-color-objects ${expanded ? 'expanded' : ''}`}
                style={style}
                title={row.text.length > maxTextLength ? row.text : ""}
              >
                    <div className={`select-path ${row.leaf ? 'select-object-input' : ''}`}>
                        <div className="flex-center-items position-relative">
                            <ObjectLevel row={row}
                                         expanded={expanded}
                            />
                            <ObjectComponent {...row}
                                             expanded={expanded}
                                             objectSelect={objectSelect}
                                             onChange={handleChange}
                                             text={rowText}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            {(expanded && row.children) &&
                row.children.map(childRow => {
                    return <TreeTableRow key={rowKeyBuilder(childRow)}
                                         row={childRow}
                                         objectSelect={objectSelect}
                                         isExpanded={isExpanded}
                                         toggleExpand={toggleExpand}
                                         handleChange={handleChange}
                                         itemComponent={itemComponent}
                    />;
                })

            }
        </>
    )
}


const ObjectLevel = ({row, expanded}) => {
    if (row.href === "#") {
        return <Icon className="toggleFolderExpandButton" icon={expanded ? 'remove' : 'add'}/>
    }
    return null
}

export default ContextEnhancer(TreeTable);