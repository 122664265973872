import React from 'react';
import Api from "components/Api";
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";
import Icon from "components/ui/common/Icon";

export class Default extends BaseKpi {

    constructor(props) {
        super(props);
    }

    static defaultProps = {
        $container: null,
    };

    imageFragment = (type) => {
        switch (type) {
            case "IMAGE":
                return (
                  <img
                    alt="KPI Image"
                    ref={(el) => (this.$imgEl = el)}
                    className="kpi-default-image"
                    src={`${Api.baseUrl()}${this.props.image}`}
                    onError={() => {
                      this.$imgEl.src = `${Api.baseUrl()}/resources/images/default_missing_image.svg`;
                    }}
                  />
                );
            case "AVATAR":
                const t = new Date().getTime();
                return (
                    <img alt="KPI Avatar" ref={el => this.$imgEl = el} id={"kpi-default-avatar" + this.state.kpiId}
                         className="kpi-default-avatar"
                         src={`${Api.baseUrl()}/avatar?user-id=${this.props.image}&t=${t}`}/>
                );
            case "ICON":
                return (
                    <Icon ref={el => this.$iconEl = el}
                          icon={this.props.image}
                          className={`infobox-iconcircle`}
                          style={{color: '#ffffff', margin: 0}}
                    />
                )
            default:
                return null
        }
    };


    customResizeKpi = ({height}) => {
        const type = this.props.imageType;
        const circleHeightPercent = 0.65;
        const minHeightCircle = 50;
        const minFontSize = 12;
        const $kpiInternalBody = this.$bodyEl;

        let heightDiv = (height * (circleHeightPercent)) < minHeightCircle ? minHeightCircle : (height * (circleHeightPercent));

        if ($kpiInternalBody.width < 100) {
            heightDiv = 50;
        }

        this.$circleEl.style.height = `${heightDiv}px`;
        this.$circleEl.style.width = `${heightDiv}px`;

        let left = this.props.div.className.includes('kpi-left');

        if (this.props.growthInfoRender) {
            this.$bodyEl.querySelector('.KpiGrowth').style.width = left ? 'auto' : `${heightDiv}px`;
        }


        if (type === 'IMAGE' || type === 'AVATAR') {
            Object.assign(this.$imgEl.style, {
                width: `${heightDiv}px`,
                height: `${heightDiv}px`
            });
        } else if (type === 'ICON') {
            resizeEl(this.$iconEl, {
                fontSize: {minValue: minFontSize, multiplier: 0.5, refSize: heightDiv},
                lineHeight: {minValue: minFontSize, multiplier: 1, refSize: heightDiv},
            });
        }

    };

    render() {
        let color = this.state.color;
        if (this.props.imageType !== 'ICON') {
            color = 'none';
        }

        return (
            <div>
                <div className={`KpiBody Qplot`}>
                    <div ref={el => this.$bodyEl = el}
                         className="KpiInternalBody">
                        <div ref={el => this.$circleEl = el}
                             className="infobox-circle"
                             style={{
                                 backgroundColor: color,
                                 fontWeight: 'normal',
                                 textAlign: 'center'
                             }}>
                            {this.imageFragment(this.props.imageType)}
                        </div>
                        {this.useGrowth()}
                    </div>
                    {this.useRightFragment({renderTable: false, container: this.$bodyEl})}
                </div>
                <div ref={el => this.$containerBandsEl = el}>
                    {this.useBands({container: this.$containerBandsEl})}
                </div>
            </div>
        )
    }

}

