import './TicketForm.css';

import React, { Component } from 'react';
import { Field, Formik } from 'formik';

import BngMultipleUpload from 'components/bng/form/BngMultipleUpload';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import ContextEnhancer from 'components/ContextEnhancer';

import BngField from 'components/bng/form/BngField';
import BngSelect from 'components/bng/form/BngSelect';
import BngTextArea from 'components/bng/form/BngTextArea';
import BngInput from 'components/bng/form/BngInput';
import bngYup from 'components/bng/form/yup/BngYup';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import BngForm from 'components/bng/form/BngForm';
import OpConfirmation from 'components/ui/OpConfirmation';
import Utils from 'components/Utils';

export const MAX_FILES = 5;

export const MAX_UPLOAD_SIZE = 25 * 1024 * 1024; //25MB

const PriorityValues = [
  { value: 1, label: 'low' },
  { value: 2, label: 'normal' },
  { value: 3, label: 'high' },
  { value: 4, label: 'urgent' },
];

const priorityValuesToOptions = (context) => {
  return PriorityValues.map((priority) => ({
    value: priority.value,
    label: context.msg.t(`priority.${priority.label}`),
  }));
};

export const categoriesToOptions = (categories) => {
  return categories.map((cat) => ({
    value: cat.id,
    label: cat.nome,
  }));
};

const ValidationSchema = bngYup((yup) => {
  return yup.object().shape({
    title: yup.string().required().default(''),
    type: yup.string().required().default(''),
    priority: yup.string().required().default(''),
    description: yup.string().required().default(''),
  });
});

class TicketFormBody extends Component {
  static defaultProps = {
    mobile: false,
    title: '',
    description: '',
    type: '',
    priority: null,
  };

  state = {
    priorityOpts: priorityValuesToOptions(this.props.context),
    categoryOpts: [],
    files: [],
    loading: false,
  };

  async componentDidMount() {
    try {
      const projectName = this.findProjectName();
      const categories = await Api.Support.findCategories(projectName);
      categories.forEach((e) => (e.nome = this.props.context.msg.t(`tomticket.category.${_.camelCase(e.nome)}`)));
      this.setState({
        categoryOpts: categoriesToOptions(categories),
      });
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    }
  }

  findePriorityValue = (priorityToFind) => {
    for (const priority of PriorityValues) {
      if (this.props.context.msg.t(`priority.${priority.label}`) === priorityToFind) return priority.value;
    }
  };

  findProjectName() {
    return this.props.context.support?.projectName || this.props.projectName;
  }

  save = async (ticket) => {
    this.setState({ loading: true });
    try {
      if (this.props.mobile) {
        this.messageToMobile({}, 'START_LOADING');
      }
      ticket = _.cloneDeep(ticket);
      ticket.projectId = this.findProjectName();
      await Api.Support.newTicket(ticket, this.state.files);
      if (this.props.mobile) {
        this.messageToMobile({}, 'STOP_LOADING');
        this.messageToMobile(this.props.context.msg.t('support.send.success'), 'MESSAGE');
        this.messageToMobile({}, 'GO_BACK');
      } else {
        UiMsg.ok(this.props.context.msg.t('support.send.success'));
        this.props.closeModal();
      }
    } catch (e) {
      if (this.props.mobile) {
        this.messageToMobile({}, 'STOP_LOADING');
        this.messageToMobile(this.props.context.msg.t('support.send.error'), 'MESSAGE');
        this.messageToMobile(e, 'CONSOLE');
      } else {
        UiMsg.ajaxError(this.props.context.msg.t('support.send.error'));
        console.error(e);
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  confirmSendTicket = async (ticket) => {
    OpConfirmation({
      title: this.props.context.msg.t('attention'),
      message: this.props.context.msg.t('support.send.confirm'),
      onConfirm: async () => await this.save(ticket),
      msg: this.props.context.msg,
      level: 'warning',
    });
  };

  messageToMobile = (data, action) => {
    if (!this.props.mobile) return;
    Api.ReactNative.postMessage(JSON.stringify({ data: data, action: action }));
  };

  handleFile = (newFiles) => {
    const leftOut = [];
    let files = [...this.state.files];
    for (const newFile of newFiles) {
      if (files.length >= MAX_FILES) {
        UiMsg.warn(this.props.context.msg.t('support.message.max.files.warning'));
        break;
      }
      if (this.calculateUsage([...files, newFile]) > 100) {
        leftOut.push(newFile);
        continue;
      }
      files.push(newFile);
    }

    if (!_.isEmpty(leftOut)) {
      UiMsg.warn(this.props.context.msg.t('support.message.max.size.files.warning'));
    }

    this.setState({ files });
  };

  aggregateFileSizes(files) {
    if (_.isArray(files)) {
      return files.reduce((acc, file) => acc + file.size, 0);
    } else {
      return files.size;
    }
  }

  /**
   * Returns the used % of the MAX_UPLOAD_SIZE
   * @param files
   * @returns {number}
   */
  calculateUsage(files) {
    const usage = this.aggregateFileSizes(files);
    return (usage / MAX_UPLOAD_SIZE) * 100;
  }

  removeFile(idx) {
    this.state.files.splice(idx, 1);
    this.setState({ files: this.state.files });
  }

  initialFormValues() {
    const initialValues = ValidationSchema.default();
    if (this.props.title !== '') {
      initialValues.title = this.props.title;
    }

    if (this.props.description !== '') {
      initialValues.description = this.props.description;
    }

    if (this.props.type !== '') {
      initialValues.type = this.props.type;
    }

    if (this.props.priority) {
      initialValues.priority = this.props.priority;
    }

    return initialValues;
  }

  render() {
    const sizeUsage = this.calculateUsage(this.state.files);
    const isMobile = this.props.mobile;
    return (
      <Formik
        onSubmit={this.confirmSendTicket}
        initialValues={this.initialFormValues()}
        validationSchema={ValidationSchema}
      >
        <BngForm className={`TicketFormBody ${isMobile && 'Mobile'}`}>
          <div className="row-fluid">
            {!isMobile && (
              <div className="span4 text-center new-ticket-support-image-parent">
                <img
                  src={`${Api.baseUrl()}/images/support-guy.png`}
                  className="new-ticket-support-image"
                  alt="support-image"
                />
              </div>
            )}
            <div className={`${isMobile ? 'span12' : 'span8'}`}>
              <Field
                name="title"
                type="text"
                className="fill-w"
                component={BngField}
                inputComponent={BngInput}
                placeholder={this.props.context.msg.t('title')}
                required={true}
              />

              <Field
                name="type"
                className="fill-w"
                component={BngField}
                inputComponent={BngSelect}
                options={this.state.categoryOpts}
              />

              <Field
                name="priority"
                className="fill-w"
                component={BngField}
                inputComponent={BngSelect}
                options={this.state.priorityOpts}
              />

              <Field
                name="description"
                className="fill-w"
                rows="4"
                component={BngField}
                inputComponent={BngTextArea}
                placeholder={this.props.context.msg.t('ticket.description.placeholder')}
                required={true}
              />
            </div>
          </div>
          <div className="row-fluid">
            <div className="alert alert-block alert-info min-padding">
              {this.props.context.msg.t('ticket.archive.information')}
            </div>
          </div>

          <BngMultipleUpload height={60} handleFile={this.handleFile} />

          <div className="progress progress-info">
            <div className="text-center w-100 position-absolute">
              <span>
                {Utils.Bytes.toMB(this.aggregateFileSizes(this.state.files))} / {Utils.Bytes.toMB(MAX_UPLOAD_SIZE)}
              </span>
            </div>
            <div
              className={`bar ${sizeUsage > 80 ? 'bar-danger' : sizeUsage > 60 ? 'bar-warning' : ''}`}
              style={{ width: `${sizeUsage}%` }}
            ></div>
          </div>

          {!_.isEmpty(this.state.files) && (
            <div className="row-fluid">
              <div className="span12">
                <div className="files-container">
                  {this.state.files.map((file, idx) => (
                    <div key={idx} className="alert alert-block alert-success support-file-info min-padding">
                      <i className="icon-ok"></i> {file.name} ({Utils.Object.toMBString(file.size)})
                      <button
                        type="button"
                        className="RemoveFileButton btn btn-mini btn-danger text-center pull-right"
                        title={this.props.context.msg.t('remove_file')}
                        onClick={() => this.removeFile(idx)}
                      >
                        <i className="icon-trash" />
                      </button>
                    </div>
                  ))}
                </div>
                {this.state.files.length > 0 && (
                  <div className="text-right">
                    <small>
                      {this.state.files.length} / {MAX_FILES}
                    </small>
                  </div>
                )}
              </div>
            </div>
          )}

          <hr />

          <DefaultDialogActions
            closeModal={this.props.closeModal}
            context={this.props.context}
            okLabel={'send'}
            isMobile={isMobile}
            submitting={this.state.loading}
          />
        </BngForm>
      </Formik>
    );
  }
}

export default ContextEnhancer(TicketFormBody);
