import React from 'react';

import image from 'components/bng/pages/newAnalysis/assets/BngAdvancedModeAlert.svg';
import BaseErrorPage from 'components/bng/pages/errors/BaseErrorPage';
import useTranslation from 'components/hooks/useTranslation';

export default function UserWithoutProjectErrorPage() {
  const { t } = useTranslation();
  return (
    <BaseErrorPage
      className="UserWithoutProjectErrorPage"
      image={image}
      title={t('user.without.project')}
      description={`${t('user.without.project.message')} ${t('you.can.try')} ${t(
        'contact.your.project.administrator'
      ).toLowerCase()}.`}
    />
  );
}
