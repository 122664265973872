import * as React from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import {Tab, TabSet} from "components/ui/TabSet";
import InputCodeMirror from "components/ui/InputCodeMirror";
import {Field, Validations} from "components/ui/FormUtils";
import Button from "components/ui/Button";
import {AutoSizer, Grid} from "imports/ReactVirtualizedImport";
import {Format} from "components/ui/analysis/expert/format/Format";

const formatMapping = (format) => {
    return {
        custom: format.custom,
        expression: format.expression || format.format || '',
        showValues: 'showValues' in format ? format.showValues : true,
        bands: format.bands || []
    };
};

class Free extends React.Component {

    static propTypes = {
        formula: PropTypes.any
    };

    static defaultProps = {
        formatStrings: [],
        formulas: [],
        cubeMeta: {
            cube: {},
            allMeasures: [],
        },
        helpFormulas: []
    };

    componentDidMount() {
        const f = this.props.formula;
        if (f) {
            this.props.initialize({
                id: f.id,
                name: f.name,
                order: f.order,
                expression: f.data.expression,
                format: formatMapping(f.data.format)
            });
            this._ignoreFirstUpdate = true;
        }
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this._ignoreFirstUpdate) {
            this._ignoreFirstUpdate = false;
            return;
        }
        if (prevProps.formValues.format.custom !== this.props.formValues.format.custom) {
            this.props.change('format.expression', '');
        }
    }


    render() {
        return (

            <TabSet vertical
                    className="FreeFormula"
                    beforeChange={async () => {
                        const result = this.props.handleSubmit(_.noop)();
                        return _.isUndefined(result) || !('expression' in result);
                    }}>
                <Tab label={this.props.context.msg.t('formula')}
                     icon="fa fa-cogs">
                    <div className="row-fluid">
                        <div className="span8">
                            <fieldset>
                                <legend>{this.props.context.msg.t('mdx')}</legend>
                                <Field
                                    name="expression"
                                    componentClass={InputCodeMirror}
                                    inputRef={ref => {
                                        this.$mdxInput = ref
                                    }}
                                    validate={[Validations.required]}
                                />
                            </fieldset>
                        </div>
                        <div className="span4" style={{paddingLeft: 8}}>
                            <fieldset className="margin-bottom-default">
                                <legend>{this.props.context.msg.t('metrics')}</legend>
                                <MeasureBox measures={this.props.cubeMeta.allMeasures}
                                            onClick={this.appendToMdx}
                                />
                            </fieldset>
                            <fieldset>
                                <legend>
                                    {this.props.context.msg.t('formulas')}
                                    <a href={this.props.context.msg.t('free.expert.help.link')}
                                       className="pull-right"
                                       target="_blank"
                                       title={this.props.context.msg.t('know.more')}>
                                        <i className="fa fa-info-circle"></i>
                                    </a>
                                </legend>
                                <FormulaBox helpFormulas={this.props.helpFormulas}
                                            onClick={this.appendToMdx}
                                />
                            </fieldset>
                        </div>
                    </div>
                </Tab>
                <Tab label={this.props.context.msg.t('format.string')}
                     icon="fa fa-adjust">
                    <Format array={this.props.array}
                            change={this.props.change}
                            dispatch={this.props.dispatch}
                            context={this.props.context}
                            formatStrings={this.props.formatStrings}
                            formValues={this.props.formValues}
                    />
                </Tab>
            </TabSet>
        );
    }

    appendToMdx = (text) => {
        const val = this.currentMdx();
        this.$mdxInput.appendContent((val ? '\n' : '') + text);
    };

    currentMdx() {
        return this.props.formValues.expression || '';
    }

}


const MeasureButton = ({caption, name, icon, onClick, className = '', ...props}) => {
    return (
        <Button {...props}
                className={`btn-small btn-success btn-block member-btn ${className}`}
                icon={icon}
                style={{textAlign: 'left !important'}}
                onClick={onClick}>
            {caption || name}
        </Button>
    );
};


class MeasureBox extends React.PureComponent {
    render() {
        return (
            <div className={'free-member-box'}>
                {this.props.measures.map(m => {
                    return (
                        <MeasureButton
                            key={m.uniqueName}
                            title={m.uniqueName}
                            caption={m.caption}
                            name={m.name}
                            icon={'level' in m ? 'icon-th' : 'icon-edit'}
                            onClick={() => {
                                this.props.onClick(m.uniqueName);
                            }}
                        />
                    )
                })}
            </div>
        );
    }
}

const FormulaBox = ({helpFormulas, onClick}) => {
    return (
        <div className={'free-member-box'}>
            <AutoSizer>
                {({width, height}) => {
                    return (
                        <Grid
                            cellRenderer={({rowIndex, style}) => {
                                const f = helpFormulas[rowIndex];
                                return (
                                    <div key={rowIndex}
                                         style={{...style}}>
                                        <MeasureButton
                                            className={'btn-blue'}
                                            title={f.description}
                                            caption={f.label}
                                            name={f.value.name}
                                            icon={''}
                                            onClick={() => onClick(f.value.syntax)}
                                        />
                                    </div>
                                );
                            }}
                            height={height}
                            width={width}
                            columnCount={1}
                            columnWidth={width - 20}
                            rowHeight={31}
                            rowCount={helpFormulas.length}

                        />
                    )
                }}
            </AutoSizer>
        </div>
    );
};

Free = ContextEnhancer(Free);
Free.formatMapping = formatMapping;

export default Free;

/*
<Tab label={this.props.context.msg.t('visual')}>
                                    <div className="row-fluid">
                                        <div className="span8">
                                            <div style={{border: '1px solid #ccc', height: '400px'}}>
                                                <div className="droppable"></div>
                                            </div>
                                        </div>
                                        <div className="span4">
                                            <fieldset>
                                                <legend>{this.props.context.msg.t('metrics')}</legend>

                                                {['Valor Faturado'].map((val, idx) => {
                                                    return (
                                                        <div key={val}
                                                             className="btn-small btn-success member-btn draggable"
                                                             data-val={JSON.stringify({type: 'Measure', value: `[Measures].[${val}]`})}
                                                             style={{textAlign: 'left !important'}}>
                                                            <i className="icon-th"></i>
                                                            {val}
                                                        </div>
                                                    )
                                                })}

                                            </fieldset>
                                            <fieldset>
                                                <legend>{this.props.context.msg.t('operators')}</legend>
                                                {['+', '-', '/', '*', '(', ')'].map(op => {
                                                    return (
                                                        <div key={op}
                                                             data-val={JSON.stringify({type: 'Operator', value: op})}
                                                             className="btn-mini btn-table operation-btn draggable">
                                                            {op}
                                                        </div>
                                                    )
                                                })}
                                            </fieldset>
                                        </div>
                                    </div>
                                </Tab>
 */