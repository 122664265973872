import React from "react";
import PropTypes from 'prop-types';

class Badge extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span className={`badge ${this.props.color}`}>
                {this.props.value}
            </span>
        );
    }

}

Badge.propTypes = {
    color: PropTypes.string,
    value: PropTypes.any
};

Badge.defaultProps = {
    color: 'badge-grey',
    value: 0,
};

export default Badge;