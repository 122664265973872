const createType = (namespace, typeName) => namespace + '_' + typeName;

export default class ActionContainer {

    _name;

    constructor(name) {
        this._name = name;
    }

    actionCreator(name, fnName, functionCreator) {
        const type = createType(this._name, name);
        this[name] = type;
        this[fnName] = functionCreator.call(null, type);
        return this;
    }

    get name() {
        return this._name;
    }

    toString() {
        return this.name();
    }

};