import 'components/ui/presentation/CreatePublicLink.css';
import React from "react";
import PropTypes from "prop-types";
import {Field, Form, Formik} from "formik";
import Dialog from "components/ui/Dialog";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import {bngYup} from "components/bng/form/yup/BngYup";
import {DefaultDialogActions} from "components/ui/FormUtils";
import UiMsg from "components/ui/UiMsg";
import {BngField} from "components/bng/form/BngField";
import BngDate from "components/bng/form/BngDate";
import BngSwitch from "components/bng/form/BngSwitch";

const NewPublicLinkSchema = bngYup(yup => {
    return yup.object().shape({
        identifier: yup.string().required().trim().default(''),
        expirationDate: yup.string().required().default(''),
        permanent: yup.boolean().default(false),
        shortLink: yup.boolean().default(false),
    });
});

class CreatePublicLink extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        idPresentation: PropTypes.number,
        updateValues: PropTypes.func
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true),
        idPresentation: 0,
        updateValues: _.noop
    };

    state = {
        loading: false,
        isPermanent: false,
        isShortLink: false
    };

    componentDidMount() {
        this.buildConfigData();
    }

    initialFormValues = NewPublicLinkSchema.default();

    save = async (values, actions) => {
        const expirationDate = moment.utc(values.expirationDate);
        if (expirationDate.isBefore(moment.utc())) {
            actions.setFieldError('expirationDate', this.props.context.msg.t('presentation.public.link.date.warning'));
            actions.setSubmitting(false);
            return;
        }
        this.setState({loading: true});
        try {
            await Api.Presentation.savePublicLink(this.props.idPresentation, {
                ...values,
                expirationDate
            });
            UiMsg.ok(this.props.context.msg.t('presentation.public.link.save.sucess'));
            this.props.closeModal();
            this.props.updateValues();
        } catch (e) {
            console.error('Error on CreatePublicLink.save()', e);
            UiMsg.ajaxError(this.props.context.msg.t('presentation.public.link.save.error'), e);
            this.setState({loading: false});
        }
    };

    buildConfigData = () => {
        this.initialFormValues.expirationDate = moment().add(15, 'd').format('YYYY-MM-DD');
    };

    handleChangePermanent = (event) => {
        this.setState({isPermanent: event.target.checked});
    };

    handleChangeShortLink = (event) => {
        this.setState({isShortLink: event.target.checked});
    };

    render() {
        return (
            <Formik initialValues={this.initialFormValues}
                    validationSchema={NewPublicLinkSchema}
                    onSubmit={this.save}>
                <Dialog contentFullWidth={true} className="CreatePublicLink small" open={this.props.open}
                        title={this.props.context.msg.t('public.link')} loading={this.state.loading}
                        onClose={this.props.closeModal}>
                    <Form>
                        <div className="public-link-form-container">
                            <div className="public-link-field-container">
                                <Field label={this.props.context.msg.t('identifier')}
                                       name="identifier"
                                       className="field-public-link"
                                       component={BngField}/>
                            </div>

                            <div className="public-link-field-container">
                                <Field label={this.props.context.msg.t('expiration.date')}
                                       name="expirationDate"
                                       className="field-public-link fill-w"
                                       component={BngField}
                                       disabled={this.state.isPermanent}
                                       inputComponent={BngDate}/>
                            </div>

                            <div className="PermanentSwitch public-link-field-container">
                                <Field label={this.props.context.msg.t('permanent')}
                                       id="permanent"
                                       name="permanent"
                                       onClick={this.handleChangePermanent}
                                       component={BngField}
                                       inputComponent={BngSwitch}/>
                            </div>

                            <div className="ShortLinkSwitch public-link-field-container">
                                <Field label={this.props.context.msg.t('short.link')}
                                       id="shortLink"
                                       name="shortLink"
                                       onClick={this.handleChangeShortLink}
                                       component={BngField}
                                       inputComponent={BngSwitch}/>
                            </div>

                        </div>

                        <DefaultDialogActions contentFullWidth={true} {...this.props}/>
                    </Form>
                </Dialog>
            </Formik>

        )
    }

}

export default ContextEnhancer(CreatePublicLink);
