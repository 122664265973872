import './BngCheckbox.css';

import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

export function BngCheckboxPure ({
                                    className = '',
                                    title = '',
                                    label = '',
                                    style = {},
                                    disabled = false,
                                    type = '',
                                    size = '',
                                    showTitleOnlyOnOverflow = false,
                                    ...props
                                }) {
    const [lblRef, setLblRef] = useState();

    const showTitle = useMemo(() => {
        if (!showTitleOnlyOnOverflow) return true;

        if (!lblRef) {
            return false;
        }

        return lblRef.scrollWidth > lblRef.clientWidth;
    }, [title, label, lblRef]);

    return (
        <div
            className={`BngCheckbox checkbox ${className || ''} ${type ? `BngCheckboxType-${type}` : ''} ${size ? `BngCheckboxSize-${size}` : ''} ${disabled ? 'Disabled' : ''}`}
            style={style}>
            <label>
                <input {...props}
                       disabled={disabled}
                       type="checkbox"
                       className="ace"
                />
                <span key={`title-${showTitle}`}
                      className="lbl"
                      title={showTitle ? title : undefined}
                      ref={ref => setLblRef(ref)}>
                        {label}
                </span>
            </label>
        </div>
    )
};

export default class BngCheckbox extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.any,
        showTitleOnlyOnOverflow: PropTypes.bool,
    };

    static defaultProps = {};

    render() {
        const {field, form, ...props} = this.props;
        return (
            <BngCheckboxPure checked={field.value}
                             {...field}
                             {...props}
            />
        );
    }

};