import './PublisherListDialog.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import ContextEnhancer from 'components/ContextEnhancer';
import { Tab, TabSet } from 'components/ui/TabSet';
import { bngYup } from 'components/bng/form/yup/BngYup';
import ConfigureLayouPublisher from 'components/ui/publisher/ConfigureLayouPublisher';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import UiMsg from 'components/ui/UiMsg';
import ConfigureLink from 'components/ui/publisher/ConfigureLink';
import Utils from 'components/Utils';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';

const NewPublisherSchema = bngYup((yup) => {
  return yup.object().shape({
    sharingpublic: yup.boolean().default(false),
    sharingprivate: yup.boolean().default(false),
    sharingfull: yup.boolean().default(false),
    enablefixsize: yup.boolean().default(false),
    width: yup.number().integer().default(0),
    height: yup.number().integer().default(0),
    enablepublisherframe: yup.boolean().default(false),
    showtitle: yup.boolean().default(false),
    showdateupdate: yup.boolean().default(false),
    view: yup.string().required().ensure().oneOf(['DEFAULT', 'CHART', 'TABLE', 'JSON', 'CSV']).default('DEFAULT'),
  });
});

class PublisherIncorporateLink extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    path: PropTypes.string,
    caption: PropTypes.string,
    onUpdate: PropTypes.func,
    fromAccountManager: PropTypes.bool,
  };

  static defaultProps = {
    onSubmit: () => Promise.resolve(true),
    path: '',
    caption: '',
    onUpdate: _.noop,
    fromAccountManager: false,
  };

  state = {
    valuesconfig: {},
    loaded: false,
    enabletable: true,
    enablechart: true,
    persistedFormValues: null,
  };

  initialFormValues = NewPublisherSchema.default();

  async componentDidMount() {
    const initialFormVals = _.cloneDeep(this.initialFormValues);
    const publishedObject = await Api.Publisher.getPublisherByPath(this.props.path);
    Object.assign(initialFormVals, publishedObject, publishedObject.config);

    let enablechart = true;
    let enabletable = true;

    const isJsonView = initialFormVals.view === 'JSON';
    if (Utils.Object.isAnalysis(this.props.path)) {
      const view = await Api.Publisher.getAnalysisView(this.props.path);
      if (view === 'table') {
        enablechart = false;
        if (!isJsonView) {
          initialFormVals.view = 'TABLE';
        }
      } else if (view === 'chart') {
        enabletable = false;
        if (!isJsonView) {
          initialFormVals.view = 'CHART';
        }
      }
    } else if (!isJsonView) {
      initialFormVals.view = 'DEFAULT';
    }

    this.$formikRef.resetForm({
      values: initialFormVals,
    });

    window.requestAnimationFrame(() => {
      let enablefixedsize = j('#pubisher-fix-size').val() === 'true';
      this.setState({
        enablefixedsize,
        loaded: true,
        enabletable,
        enablechart,
        persistedFormValues: initialFormVals,
      });
    });
  }

  save = async (values, actions) => {
    values = _.cloneDeep(values);
    values.path = this.props.path;
    values.caption = this.props.caption;

    const response = await Api.Publisher.savePublisher(values);

    if (response === true) {
      UiMsg.ok(this.props.context.msg.t('publisher.config.save'));
      this.setState({
        persistedFormValues: values,
      });
      this.props.onUpdate();
    } else {
      UiMsg.error(this.props.context.msg.t('publisher.config.error'));
    }
  };

  buildLinkParameters = (values) => {
    let params = {};

    params.content = this.props.path;

    if (values.enablefixsize) {
      params.fixedSize = values.enablefixsize;
      if (values.width > 0) {
        params.width = values.width;
      }
      if (values.height > 0) {
        params.height = values.height;
      }
    }

    if (values.enablepublisherframe) {
      params.applyFrame = values.enablepublisherframe;
      params.showUpdateDate = values.showdateupdate;
      params.showTitle = values.showtitle;
    }

    if (!values.enablefixsize && !values.enablepublisherframe) {
      params.removeFrame = true;
    }

    params.view = values.view;

    return params;
  };

  defineObject = (values) => {
    const params = this.buildLinkParameters(values);
    let prefix = this.props.path.substring(this.props.path.lastIndexOf('.') + 1);
    return `/${prefix}?${j.param(params)}`;
  };

  checkPublisherFullEnabled = () => {
    return checkAddonEnabled(AddonType.PUBLISHER_FULL.key, true);
  };

  render() {
    return (
      <Formik
        initialValues={this.initialFormValues}
        validationSchema={NewPublisherSchema}
        onSubmit={this.save}
        innerRef={(ref) => (this.$formikRef = ref)}
      >
        {({ values }) => {
          const link = this.defineObject(values);
          const width = typeof values.width === 'number' ? values.width : 0;
          const height = typeof values.height === 'number' ? values.height : 0;
          const enableFixedSize = values.enablefixsize;
          const enablePublisherFrame = values.enablepublisherframe;

          return (
            <Dialog
              contentFullWidth={true}
              className="large"
              title={this.props.context.msg.t('incorporate')}
              onClose={this.props.closeModal}
            >
              <Form>
                <TabSet bodyRadius={false} vertical={false} className="grey-bg">
                  <Tab
                    label={this.props.context.msg.t('private')}
                    icon="fa fa-adjust"
                    disabled={this.props.fromAccountManager}
                  >
                    {this.state.loaded ? (
                      <ConfigureLink
                        type="private"
                        path={link}
                        width={width}
                        height={height}
                        enablelink={values.sharingprivate}
                        fixedSize={enableFixedSize}
                        enableVisualizeButton={this.state.persistedFormValues?.sharingprivate ?? false}
                      />
                    ) : (
                      <></>
                    )}
                  </Tab>
                  <Tab
                    label={this.props.context.msg.t('public')}
                    icon="fa fa-cogs"
                    disabled={this.props.fromAccountManager}
                  >
                    {this.state.loaded ? (
                      <ConfigureLink
                        type="public"
                        path={link}
                        width={width}
                        height={height}
                        enablelink={values.sharingpublic}
                        fixedSize={enableFixedSize}
                        enableVisualizeButton={this.state.persistedFormValues?.sharingpublic ?? false}
                      />
                    ) : (
                      <></>
                    )}
                  </Tab>
                  <Tab label={`Full`} icon="work" shouldChangeTab={this.checkPublisherFullEnabled}>
                    {this.state.loaded ? (
                      <ConfigureLink
                        type="full"
                        path={link}
                        width={width}
                        height={height}
                        enablelink={values.sharingfull}
                        fixedSize={enableFixedSize}
                        enableVisualizeButton={this.state.persistedFormValues?.sharingfull ?? false}
                      />
                    ) : (
                      <></>
                    )}
                  </Tab>
                  <Tab label={this.props.context.msg.t('layout.config')} icon="fa fa-adjust">
                    {this.state.loaded ? (
                      <ConfigureLayouPublisher
                        enableChart={this.state.enablechart}
                        enableTable={this.state.enabletable}
                        enablePublisherFrame={enablePublisherFrame}
                        enableFixedSize={enableFixedSize}
                        path={this.props.path}
                        values={values}
                        link={link}
                      />
                    ) : (
                      <></>
                    )}
                  </Tab>
                </TabSet>
                <DefaultDialogActions contentFullWidth={true} {...this.props} />
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    );
  }
}

export default ContextEnhancer(PublisherIncorporateLink);
