import './EmailBlacklistDialog.css';
import React, { useEffect, useMemo, useState } from 'react';
import ContextEnhancer from 'components/ContextEnhancer';
import Dialog from 'components/ui/Dialog';
import { useLoadingListener } from 'components/bng/pages/newAnalysis/BngEnableAdvancedModeButton';
import { BngTable } from 'components/bng/ui/BngTable';
import Api from 'components/Api';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import OpConfirmation from 'components/ui/OpConfirmation';
import UiMsg from 'components/ui/UiMsg';
import PaginateTable from 'components/ui/common/PaginateTable';
import { Field, Formik } from 'formik';
import { BngForm } from 'components/bng/form/BngForm';
import { BngField } from 'components/bng/form/BngField';
import Button from 'components/ui/Button';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BngSearch from 'components/bng/ui/BngSearch';
import BngSwitch from 'components/bng/form/BngSwitch';
import usePagination from 'components/hooks/usePagination';

const addToBlacklistFormSchema = bngYup((yup) =>
  yup.object({
    email: yup.string().email().required().default(''),
  })
);

const EmailBlacklistDialog = ({ open, closeModal, context }) => {
  const { loading, listener } = useLoadingListener();

  const tablePagination = usePagination();
  const [search, setSearch] = useState('');
  const [config, setConfig] = useState({ enableEmailBlacklist: true });

  useEffect(() => {
    (async () => {
      const config = await Api.EmailBlacklist.findConfig();
      setConfig(config);
    })();
  }, []);

  useEffect(() => {
    findData();
  }, [tablePagination.pagination]);

  const findData = async () => {
    await listener(async () => {
      const data = await Api.EmailBlacklist.find(
        search,
        tablePagination.pagination.page,
        tablePagination.pagination.size
      );
      tablePagination.setPageInfo(data);
    });
  };

  const cols = useMemo(() => {
    return [
      { label: context.msg.t('id'), size: '60px', render: (row) => row.id },
      { label: context.msg.t('email'), render: (row) => row.email },
      {
        label: context.msg.t('created.at'),
        render: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
      },
      {
        size: '65px',
        render: (row) => (
          <BngIconButton
            icon={'delete'}
            onClick={() => {
              OpConfirmation({
                msg: context.msg,
                title: context.msg.t('attention'),
                message: context.msg.t('delete.confirmation.message'),
                onConfirm: async () => {
                  try {
                    await listener(Api.EmailBlacklist.remove([row.email]));
                    findData();
                  } catch (e) {
                    console.error(e);
                    UiMsg.ajaxError(null, e);
                  }
                },
              });
            }}
          />
        ),
      },
    ];
  }, []);

  return (
    <Dialog
      contentFullWidth={true}
      className="EmailBlacklistDialog large"
      open={open}
      title={context.msg.t('email.blacklist')}
      loading={loading}
      onClose={closeModal}
      newDialogLayout
    >
      <Dialog.Body>
        <div className="row-fluid d-b-m">
          <div className="span4">
            <BngSwitch
              id="enableEmailBlacklist"
              title={context.msg.t('enable.email.blacklist')}
              checked={config.enableEmailBlacklist}
              onChange={(a) => {
                listener(async () => {
                  try {
                    const enableEmailBlacklist = a.target.checked;
                    const newConfig = await Api.EmailBlacklist.updateConfig(
                      enableEmailBlacklist
                    );
                    setConfig(newConfig);
                  } catch (e) {
                    console.error(e);
                    UiMsg.ajaxError(null, e);
                  }
                });
              }}
            />
          </div>
          <div className="span4 position-relative">
            <BngSearch
              value={search}
              onChange={(val) => setSearch(val)}
              placeholder={context.msg.t('blacklist.search.placeholder')}
              alwaysOpen={true}
              simple={true}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  findData();
                }
              }}
            />
          </div>
          <div className="span4">
            <Formik
              initialValues={addToBlacklistFormSchema.default()}
              validationSchema={addToBlacklistFormSchema}
              onSubmit={(values, formikHelpers) => {
                listener(async () => {
                  try {
                    await Api.EmailBlacklist.addToBlacklist([values.email]);
                    formikHelpers.resetForm(addToBlacklistFormSchema.default());
                    await findData();
                  } finally {
                    formikHelpers.setSubmitting(false);
                  }
                });
              }}
            >
              {({ isValid, isSubmitting, dirty }) => {
                return (
                  <BngForm>
                    <div className="d-flex">
                      <Field
                        name="email"
                        as={BngField}
                        rootClassName="flex-grow-1 m-0-force"
                        inline
                        showErrors={false}
                      />
                      <div>
                        <Button
                          type="submit"
                          icon={'add'}
                          disabled={!dirty || !isValid || isSubmitting}
                        />
                      </div>
                    </div>
                  </BngForm>
                );
              }}
            </Formik>
          </div>
        </div>

        <PaginateTable
          handleChangePage={tablePagination.handlePagination}
          handleChangeNumberPerPage={tablePagination.perPageChanged}
          totalItens={tablePagination.pageInfo.totalElements}
          initialPass={10}
        >
          <BngTable
            rows={tablePagination.pageInfo.content}
            cols={cols}
            className={'table-condensed'}
          />
        </PaginateTable>
      </Dialog.Body>
    </Dialog>
  );
};

export default ContextEnhancer(EmailBlacklistDialog);
