import styles from './MktPlanTab.module.css';
import AdaImg from 'components/bng/pages/create-project/assets/ada_first_step.svg';

import React, { useState } from 'react';

import useTranslation from 'components/hooks/useTranslation';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngPlanTable from 'components/bng/pages/marketplace/BngPlanTable';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import ContactUs from 'components/bng/pages/create-project/ContactUs';
import { CheckoutDialog } from 'components/bng/pages/marketplace/Checkout';
import { guessGroupKey, mapCardItemToSelectedItem } from 'components/bng/pages/marketplace/MarketplaceLayout';
import useBimContext from 'components/hooks/useBimContext';

export const FEATURE_TYPES = {
  JSON: 'JSON',
  BOOL: 'BOOL',
  INT: 'INT',
};

function DeprecatedPlanPage() {
  const { t } = useTranslation();
  return (
    <div className={`DeprecatedPlanPage ${styles.deprecatedPageCard}`}>
      <div className={styles.deprecatedPageContent}>
        <div>
          <p className={`${styles.deprecatedPageTitle} mb-6`}>{t('marketplace.deprecated.title')}</p>
          <p className={styles.deprecatedPageText}>{t('marketplace.deprecated.desc')}</p>
        </div>
        <img alt="AdaClosed" src={AdaImg} />
      </div>
      <ContactUs />
    </div>
  );
}

export default function MktPlanTab({
  className = '',
  marketplaceItems,
  accountId,
  isMasterOfCurrentAccount = false,
  reloadMarketplaceItems = _.noop,
  account,
}) {
  const { t } = useTranslation();
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [loading, setLoading] = useState(false);

  const activatePlan = async (selectedPlan, isRequest) => {
    setLoading(true);
    try {
      await Api.Marketplace.changeAccountPlan({
        accountId,
        plan: selectedPlan,
        isRequest,
        projectId: context.project.id,
      });
      if (isRequest) {
        UiMsg.ok(t('plan.requested.successfully'));
      } else {
        UiMsg.ok(t('plan.changed.successfully'));
        await reloadMarketplaceItems();
      }
    } catch (e) {
      console.error('Error on function activatePlan()', e);
      UiMsg.error(t('change.plan.error'));
    } finally {
      setLoading(false);
    }
  };

  const onSelectPlan = async (selectedPlan) => {
    if (isMasterOfCurrentAccount || account.role === 'Administrator') {
      dispatch(
        MODALS.open(CheckoutDialog, {
          items: [mapCardItemToSelectedItem(selectedPlan, guessGroupKey(selectedPlan), 1)],
          account,
          afterSubmit: reloadMarketplaceItems,
          currentPlan: marketplaceItems.activePlanForAccount,
        })
      );
    } else {
      await activatePlan(selectedPlan, true);
    }
  };

  return (
    <div className={`MktPlanTab ${styles.MktPlanTab} ${className}`}>
      {!marketplaceItems.activePlanForAccount?.deprecated ? (
        <div className={styles.MktPlanTabWrapper}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{t('marketplace.plans.title')}</span>
            <span className={styles.description}>{t('marketplace.plans.desc')}</span>
          </div>
          <section className={styles.plansBody}>
            <BngPlanTable
              planArray={marketplaceItems.plans}
              loading={loading}
              account={account}
              isMasterOfCurrentAccount={isMasterOfCurrentAccount}
              currentPlanId={marketplaceItems.activePlanForAccount?.id}
              onSelect={onSelectPlan}
            />
          </section>
        </div>
      ) : (
        <DeprecatedPlanPage />
      )}
    </div>
  );
}
