import { Axios } from 'commonUtils';
import Utils from 'components/Utils';

class ActivityApi {
  findByProject = async ({ projectId, userId, eventType, startDate, endDate, resultLimit }) => {
    const data = await Axios.getData(`/spr/activities/${projectId}`, {
      params: Utils.ObjectUtils.removeEmptyKeys({
        userId,
        eventType,
        startDate,
        endDate,
        resultLimit,
      }),
    });
    data.forEach((row) => {
      row.date = moment(row.date);
    });
    return data;
  };

  findEventTypes = async () => {
    const { data } = await Axios.get(`/spr/activities/event-types`);
    return data;
  };
}

export default ActivityApi;
