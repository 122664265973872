import styles from './AddonsPage.module.css';

import React, { useEffect, useState } from 'react';

import useBimContext from 'components/hooks/useBimContext';
import { BngIsNewLabel } from 'components/bng/ui/BngHorizontalCard';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import EnabledPill from 'components/ui/accounts/EnabledPill';

const ADDONS_WITH_PAGE = Object.keys(AddonType)
  .filter((addon) => AddonType[addon].hasPage)
  .map((addon) => {
    return AddonType[addon].key;
  });

const sortFuncForWhatsapp = (a, b) => {
  if (a.id === AddonType.WHATS_APP.key) {
    return -1;
  } else if (b.id === AddonType.WHATS_APP.key) {
    return 1;
  }
  return 0;
};

const sortFuncForGroup = (a, b) => {
  if (a.id === 'MANAGEMENT') {
    return -1;
  } else if (b.id === 'MANAGEMENT') {
    return 1;
  }
  return 0;
};

const AddonCard = ({ addon, enabled }) => {
  const context = useBimContext();
  const clickAddonCard = async () => {
    if (ADDONS_WITH_PAGE.includes(addon.id)) {
      window.location.replace(`${Api.baseUrl()}/pages/addon/addon-info.iface?addon=${addon.id}&breadcrumb=true`);
    } else if (!enabled) {
      checkAddonEnabled(addon.id, true);
    }
  };

  return (
    <div
      className={`${styles.addonItem} ${enabled && !ADDONS_WITH_PAGE.includes(addon.id) ? styles.addonEnabled : ''}`}
      onClick={clickAddonCard}
      style={{ border: addon.release ? 'var(--blue-default) 1px solid' : '' }}
    >
      {addon.release && (
        <div className={`${styles.AlertContainer}`}>
          <Icon icon={'new_releases'} />
        </div>
      )}
      <div className={styles.addonItemHeader}>
        <Icon icon={addon.iconName} className="addons-icon" style={{ color: addon.iconColor }} />
        <span className="addons-title">{context.msg.t(addon.nameKey)}</span>
      </div>
      <span className="addons-description">{context.msg.t(addon.descKey)}</span>
      <div style={{ marginTop: 10, display: 'flex', gap: 10 }}>
        <EnabledPill enabled={enabled} style={{ marginLeft: 0 }} />
        {addon.release && (
          <EnabledPill enabled text={context.msg.t('new')} style={{ marginLeft: 0, border: '1px solid #005dff' }} />
        )}
      </div>
    </div>
  );
};

const AddonsPage = () => {
  const context = useBimContext();

  const [loading, setLoading] = useState(false);
  const [addons, setAddons] = useState(null);

  const findAccountAddons = async () => {
    setLoading(true);
    try {
      const fetchedAddons = await Api.Account.findAccountAddons(context.accountId);
      setAddons(fetchedAddons);
    } catch (e) {
      console.error('Error on findAccountAddons()', e);
      UiMsg.ajaxError(context.msg.t('addon.fetch.error'), e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findAccountAddons();
  }, []);

  return (
    <UiBlocker block={loading} className={styles.AddonsPageBlocker}>
      <div className={styles.AddonsPageBackground}>
        <div className={styles.AddonsPageBody}>
          <div className={styles.bodyHeadingInfo}>
            <div className={styles.headingInfoWrapper}>
              <BngIsNewLabel className={styles.headingNewLabel} />
              <h4>{context.msg.t('addons.explore.heading')}</h4>
              <p className={styles.headingDescription}>{context.msg.t('addons.explore.description')}</p>
            </div>
            <img
              className={styles.headingImageWrapper}
              src={`${Api.baseUrl()}/resources/images/addons/addons_page_ada_left.svg`}
              alt="Ada using a computer"
            />
          </div>
          <div className={styles.bodyContent}>
            {addons &&
              addons.addonGroups.sort(sortFuncForGroup).map((group, idx) => {
                return (
                  <div key={idx} className={styles.groupRender}>
                    <h5>{context.msg.t(group.titleKey)}</h5>
                    <p>{context.msg.t(group.descriptionKey)}</p>
                    <div className={styles.addonWrapper}>
                      {addons.allAddons
                        .filter((addon) => addon.addonsGroup === group.id)
                        .sort(sortFuncForWhatsapp)
                        .map((addon, idx) => {
                          const enabled = _.some(addons.accountAddons, { id: addon.id }) && addon.id;
                          return <AddonCard key={`${group.id}-${idx}`} addon={addon} enabled={enabled} />;
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </UiBlocker>
  );
};

export default AddonsPage;
