import styles from './ProjectAdminFormDialog.module.css';

import React, { useMemo } from 'react';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import { Tab, TabSet } from 'components/ui/TabSet';
import useTranslation from 'components/hooks/useTranslation';
import BngForm from 'components/bng/form/BngForm';
import BngField from 'components/bng/form/BngField';
import BngSelect from 'components/bng/form/BngSelect';
import BngSwitch from 'components/bng/form/BngSwitch';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import Api from 'components/Api';
import { bngYup } from 'components/bng/form/yup/BngYup';
import useFetchData from 'components/hooks/useFetchData';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import UiMsg from 'components/ui/UiMsg';

const ProjectFormSchema = bngYup((yup) => {
  return yup.object({
    id: yup.number(),
    displayName: yup.string(),
    description: yup.string(),
    projectType: yup.string(),
    account: yup.number(),
    availableOnMobile: yup.boolean(),
    enabled: yup.boolean(),
    supportEnabled: yup.boolean(),
  });
});

function buildInitialValues(data) {
  const val = ProjectFormSchema.getDefault();
  if (data) {
    Object.assign(val, {
      id: data.project.id,
      displayName: data.project.displayName ?? '',
      description: data.project.description ?? '',
      projectType: data.project.projectType ?? 'Demo',
      availableOnMobile: data.options.mobileEnabled ?? false,
      enabled: data.project.enabled ?? false,
      supportEnabled: data.options.supportEnabled ?? false,
      account: data.account.id,
    });
  }
  return val;
}

export default function ProjectAdminFormDialog({ closeModal, projectId = 0 }) {
  const { t } = useTranslation();

  const $project = useFetchData(async () => {
    return await Api.ProjectManagement.findOne(projectId);
  });

  const { data: accountsOpts = [], isLoading } = useFetchData(async () => {
    const accounts = await Api.Account.findAvailable();
    return accounts.map((acc) => ({ label: acc.name, value: acc.id }));
  });

  const projectTypeOpts = useMemo(
    () => ['Template', 'Production', 'Homologation', 'Demo'].map((type) => ({ label: t(type), value: type })),
    []
  );

  const initialValues = useMemo(() => buildInitialValues($project.data), [$project.data]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ProjectFormSchema}
      enableReinitialize
      onSubmit={async (values) => {
        try {
          await Api.ProjectManagement.updateOpts(values);
          closeModal();
        } catch (e) {
          console.error('Error while saving project data', { values }, e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Dialog
          contentFullWidth={true}
          className={`ProjectAdminFormDialog ${styles.dialog} large`}
          title={t('project')}
          onClose={closeModal}
          loading={isSubmitting || isLoading || $project.isLoading}
        >
          <BngForm>
            <TabSet className={`${styles.tabSet} grey-bg`} bodyRadius={false} vertical={false}>
              <Tab label={t('informacoes_gerais')} icon="info">
                <Field component={BngField} name="displayName" label={t('display_name')} disabled={true} />
                <Field component={BngField} name="description" label={t('description')} />
                <Field
                  name="projectType"
                  label={t('project_type')}
                  component={BngField}
                  inputComponent={BngSelect}
                  emptyOption={false}
                  showErrors={false}
                  options={projectTypeOpts}
                />
                <div className={styles.switchesContainer}>
                  <Field component={BngField} inputComponent={BngSwitch} name="enabled" label={t('active')} />
                  <Field component={BngField} inputComponent={BngSwitch} name="supportEnabled" label={t('support')} />
                  <Field
                    component={BngField}
                    inputComponent={BngSwitch}
                    name="availableOnMobile"
                    label={t('avaliable.on.mobile')}
                  />
                </div>
              </Tab>
              <Tab label={t('account')} icon="person">
                <Field
                  name="account"
                  label={t('account')}
                  component={BngField}
                  inputComponent={BngSelectSearch}
                  showErrors={false}
                  options={accountsOpts}
                />
              </Tab>
            </TabSet>
            <Dialog.Footer>
              <DefaultDialogActions closeModal={closeModal} />
            </Dialog.Footer>
          </BngForm>
        </Dialog>
      )}
    </Formik>
  );
}
