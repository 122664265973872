import { connect } from 'react-redux';
import MapEditor from 'components/ui/map/editor/MapEditor';
import { MODALS } from 'components/ui/redux/Actions';
import HeatMapConfDialog from 'components/ui/map/editor/HeatMapConfDialog';

const mapStateToProps = (state) => {
  return {
    id: state.newmap.id,
    source: state.newmap.source,
    cube: state.newmap.cube,
    openOnEditMode: state.newmap.openOnEditMode || state.newmap.id === 0,
    filter: state.newmap.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openHeatMapConf(params) {
      dispatch(MODALS.open(HeatMapConfDialog, params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapEditor);
