/**
 * Created by GiovaniSol7 on 13/12/2018.
 */
import React, {Component} from 'react';
import PropTypes from "prop-types";
import TreeComponent from "./TreeComponent";

class SelectObjectsCheckbox extends Component {

    static propTypes = {
        href: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.string,
        objectSelect: PropTypes.array,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        href: '',
        text: '',
        icon: '',
        objectSelect: [],
    };

    constructor(props){
        super(props);
    }

    handleChange = (e) => {
        this.props.onChange(e.currentTarget.value);
    };

    render(){
        const {href, objectSelect, text} = this.props;
        return (
            <TreeComponent {...this.props}>
                <input id={href} key={href} style={{width:16}} onChange={this.handleChange} defaultChecked={objectSelect.indexOf(href)>=0} type="checkbox" name={text} value={href}/>
            </TreeComponent>
        )
    }
}

export default SelectObjectsCheckbox;