import './FilterDialog.css';
import 'components/bng/ui/BngCalendar.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SelectItems from 'components/filter/SelectItems';
import Radio from 'components/ui/Radio';
import ContextEnhancer from 'components/ContextEnhancer';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import TimeFilterView from 'components/filter/TimeFilterView';
import { BngField } from 'components/bng/form/BngField';
import BngSwitch from 'components/bng/form/BngSwitch';

let filterTypeOpts = [
  { value: 'DYNAMIC', label: 'Dinâmico' },
  { value: 'RANGE', label: 'Faixa' },
  { value: 'NORMAL', label: 'Normal' },
];

const ALL_MEMBER_NAME = '[Todos]';

class FilterDialog extends Component {

    static propTypes = {
        customFilterSwitch: PropTypes.object,
        onlyAllowClear: PropTypes.bool,
    };

    static defaultProps = {
        customFilterSwitch: {
            enabled: (filter) => false,
            disableOnCheck: false,
            checked: _.noop,
            label: ''
        },
        onlyAllowClear: false,
    };

    state = {
        loading: true,
        disabled: false,
        customSwitchChecked: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {props} = this;
        let clearMembers = props.filter.restrictionType === 'HIDE_SELECTED'
            || (props.filter.filterType === 'SINGLE_SELECTION' && props.filter.selectedMembers.length > 1);
        let state = {
            selectedItems: clearMembers ? [] : props.filter.selectedMembers.slice(0),
            filterMode: props.enableModeChange && !_.isEmpty(props.dynamicItems)
                ? props.filter.type === 'TIME' ? filterTypeOpts[1].value : filterTypeOpts[0].value
                : filterTypeOpts[2].value
        };
        if (props.filter.fromMap) {
            state.leftMember = state.selectedItems.length > 0 ? state.selectedItems[0] : null;
            state.filterMode = filterTypeOpts[2].value;
        } else if (state.selectedItems.length > 0) {
            let member = state.selectedItems[0];
            if (member) {
                if (member.value.indexOf(' : ') !== -1) {
                    let split = member.value.split(' : ');
                    let captionSplit = member.label.split(' : ');
                    state.leftMember = {value: split[0], label: captionSplit[0]};
                    state.rightMember = {value: split[1], label: captionSplit[1]};
                    state.filterMode = filterTypeOpts[1].value;
                } else if (member.value.indexOf('[') === -1) {
                    state.filterMode = filterTypeOpts[0].value;
                } else {
                    state.leftMember = member;
                    state.filterMode = filterTypeOpts[2].value;
                }

            }
        }

        if (this.props.customFilterSwitch.enabled(this.props.filter)) {
            state.customSwitchChecked = this.props.customFilterSwitch.checked(this.props.filter);
        }
        state.disabled = this.props.onlyAllowClear || !!this.props.disabled || (state.customSwitchChecked && this.props.customFilterSwitch.disableOnCheck);

        if (props.filter.filterMode) {
            state.filterMode = props.filter.filterMode;
        }

        this.setState({...state, loading: false});
    }

    itemSelectionChanged = (selectedItems, position) => {
        if (position) {
            if (position === 'LEFT') {
                this.setState({leftMember: selectedItems[0]});
            } else {
                this.setState({rightMember: selectedItems[0]});
            }
        } else {
            const match = selectedItems.find(m => m.value === ALL_MEMBER_NAME);
            if (match) {
                selectedItems = [match];
            }
            this.setState({selectedItems: selectedItems});
        }
    };

    forceClearFilters = (event) => {
        this.setState({
            selectedItems: [],
            leftMember: null,
            rightMember: null,
            customSwitchChecked: false,
        }, () => {
            this.applyFilters(event, true);
        });
    };

    applyFilters = (event, force = false) => {
        const additionalOpts = {
            closeModal: this.closeDialog,
            customSwitchChecked: this.state.customSwitchChecked
        };
        const switchStateChanged = this.props.customFilterSwitch.enabled(this.props.filter)
            && this.state.customSwitchChecked !== this.props.customFilterSwitch.checked(this.props.filter);
        force = force || switchStateChanged;
        if (!this.isRange()) {
            this.props.onChange(
                this.state.selectedItems,
                force,
                additionalOpts
            );
        } else {
            let items = [];

            let member;
            if (this.state.leftMember && this.state.rightMember) {
                member = {
                    value: this.state.leftMember.value + ' : ' + this.state.rightMember.value,
                    label: this.state.leftMember.label + ' : ' + this.state.rightMember.label
                };
            } else if (this.state.leftMember) {
                member = {
                    value: this.state.leftMember.value,
                    label: this.state.leftMember.label
                };
            } else if (this.state.rightMember) {
                member = {
                    value: this.state.rightMember.value,
                    label: this.state.rightMember.label
                };
            }

            if (member) {
                items.push(member);
            }

            this.props.onChange(items, force, additionalOpts);
        }
    };

    isRange = () => {
        let prev = false;
        if (this.state) {
            prev = this.state.filterMode === 'RANGE';
        }
        return prev || this.props.filter.filterType === 'RANGE_SELECTION';
    };

    getMode = () => {
        return this.props.filter.filterType === 'MULTIPLE_SELECTION' ? 'MANY' : 'ONE'
    };

    filterModeChanged = (filterMode) => {
        this.setState({
            filterMode: filterMode.value,
            selectedItems: [],
            leftMember: null,
            rightMember: null
        });
    };

    closeDialog = () => {
        const closeFn = this.props.onClose || this.props.closeModal;
        closeFn();
    };

    disableFilterSelection = () => {
        this.setState({disabled: !this.state.disabled})
    }

    render() {
        let mode = this.getMode();
        let content;
        let modeChange;

        const isIphone = application.page.isIphone() ? 'mobileIos' : '';

        if (this.state.loading) {
            content = null;
        } else if (this.props.filter.type === 'TIME') {
            content = (
                <TimeFilterView members={this.props.items}
                                dynamicMembers={this.props.dynamicItems}
                                availableDateRange={this.props.availableDateRange}
                                containRestriction={false}
                                restrictionType={this.props.restrictionType}
                                restrictionMembers={this.props.restrictionMembers}
                                dataRestrictionMembers={[]}
                                onChange={this.itemSelectionChanged}
                                changeMode={this.filterModeChanged}
                                initialSelection={{
                                    left: this.state.leftMember,
                                    right: this.state.rightMember,
                                    selectedItems: this.state.selectedItems
                                }}
                                filterMode={this.state.filterMode}
                />
            );
        } else {
            if (this.isRange()) {
                content = (
                    <div className="row-fluid RANGE">
                        <div className="span6">

                            <SelectItems {...this.props}
                                         value={this.state.leftMember ? [this.state.leftMember] : []}
                                         onChange={items => this.itemSelectionChanged(items, 'LEFT')}
                                         positionFilter={'LEFT'}
                                         mode={mode}
                            />

                        </div>
                        <div className="span6">

                            <SelectItems {...this.props}
                                         value={this.state.rightMember ? [this.state.rightMember] : []}
                                         onChange={items => this.itemSelectionChanged(items, 'RIGHT')}
                                         positionFilter={'RIGHT'}
                                         mode={mode}
                            />

                        </div>
                    </div>
                );
            } else {
                const disableFn = mode === 'MANY' ? item => {
                    if (disableOtherItems) {
                        if (!item.disabled && item.value !== ALL_MEMBER_NAME) {
                            item = {...item};
                            item.disabled = disableOtherItems;
                        }
                    }
                    return item;
                } : undefined;
                const disableOtherItems = disableFn && this.state.selectedItems && !!this.state.selectedItems.find(item => item.value === ALL_MEMBER_NAME);
                content = (
                    <SelectItems {...this.props}
                                 items={this.state.filterMode === "DYNAMIC" ? this.props.dynamicItems : this.props.items}
                                 value={this.state.selectedItems}
                                 onChange={this.itemSelectionChanged}
                                 mode={mode}
                                 disableItemFn={disableFn}
                    />
                );
            }
            if (this.props.enableModeChange) {
                modeChange = (
                    <div className="row-fluid">
                        <div className="span3">
                            Tipo do Filtro
                        </div>
                        <div className="span9">
                            {filterTypeOpts.map((opt, idx) =>
                                <div key={idx} style={{display: 'inline-block'}}>
                                    <Radio name="filterMode"
                                           value={{...opt, disabled: idx === 0 && _.isEmpty(this.props.dynamicItems)}}
                                           onChange={() => this.filterModeChanged(opt)}
                                           selected={this.state.filterMode === opt.value}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                );
            }
        }
        return (
            <Dialog loading={this.state.loading}
                    className={`filter-selection-dialog FilterType-${this.props.filter.type} modal ${isIphone}`}
                    title={this.props.filter.caption}
                    onClose={this.closeDialog}>
                <div className="DialogContent">
                    {modeChange}
                    {this.state.disabled &&
                        <div className="disabledOverlay" onClick={(e) => e.preventDefault()}>
                        </div>
                    }
                    {content}
                </div>
                <div className="Footer">
                    <div className="extraFilterActionsWrapper">
                        <Button className="bng-button clear-filter-action filter-action cancel no-placement"
                                onClick={this.forceClearFilters}>
                            {this.props.context.msg.t('clear')}
                        </Button>
                        {this.props.customFilterSwitch.enabled(this.props.filter) &&
                            <BngField id={`filter-${this.props.filter.id}-dashboard-switch`}
                                      label={BngField.LABEL_EMPTY}
                                      inputComponent={BngSwitch}
                                      onClick={() => {
                                         this.setState({customSwitchChecked: !this.state.customSwitchChecked});
                                         if (this.props.customFilterSwitch.disableOnCheck) {
                                           this.disableFilterSelection()
                                         }
                                      }}
                                      asProps={{
                                           label: this.props.customFilterSwitch.label,
                                           verticalTitle: false,
                                           field: {
                                               value: this.state.customSwitchChecked,
                                           },
                                           className: `filter-${this.props.filter.id}-dashboard-switch KeepDashboardFilterSwitch`,
                                       }}
                            />
                        }
                    </div>
                    <div className="text-right filterActionButtonsWrapper">
                        <Button className="bng-button filter-action cancel"
                                onClick={this.closeDialog}>
                            {this.props.context.msg.t('cancel')}
                        </Button>
                        <Button className="bng-button filter-action save"
                                disabled={this.props.onlyAllowClear}
                                onClick={this.applyFilters}>
                            {this.props.context.msg.t('apply')}
                        </Button>
                    </div>
                </div>
            </Dialog>

        );
    }
}

export default ContextEnhancer(FilterDialog);
