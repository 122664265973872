import styles from './LoginAuthForm.module.css';

import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { Field, Formik } from 'formik';

import useTranslation from 'components/hooks/useTranslation';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngForm from 'components/bng/form/BngForm';
import BngField from 'components/bng/form/BngField';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngCancelButton, BngPrimaryButton } from 'components/ui/Button';
import { useEffect, useRef } from 'react';
import bngYup from 'components/bng/form/yup/BngYup';

const FormSchema = bngYup((yup) =>
  yup.object({
    email: yup.string().email().max(256).required().trim().ensure().default(''),
    password: yup.string().max(256).required().ensure().default(''),
    rememberMe: yup.boolean().default(false),
    afterAuthUrl: yup.string().default(''),
  }),
);

const runAutoLogin = ({ autoLoginParam = '', t, setFieldValue }) => {
  try {
    autoLoginParam = atob(autoLoginParam);
  } catch (ignored) {}

  let creds;
  try {
    creds = JSON.parse(autoLoginParam);
  } catch (e) {
    return;
  }

  if (!creds || !creds.username || !creds.password) {
    return;
  }

  function typeTextOnField(input, text, callback) {
    input.focus();
    input.select();
    input.value = '';
    setFieldValue(input.name, '');

    let textLength = text.length;
    let currentTextIdx = 0;
    let typingInterval = 80;

    const writeText = function () {
      input.value = text.substring(0, currentTextIdx);

      if (currentTextIdx < textLength - 1) {
        currentTextIdx++;
        setTimeout(function () {
          writeText();
        }, typingInterval);
      } else {
        input.setAttribute('value', text);
        setFieldValue(input.name, text);
        if (callback) {
          callback();
        }
      }
    };
    setTimeout(function () {
      writeText();
    }, typingInterval);
  }

  const overlay = document.createElement('div');
  overlay.classList.add(styles.loginBoxWidgetBodyOverlay);
  overlay.onclick = function (event) {
    event.preventDefault();
    event.stopPropagation();
  };
  document.body.appendChild(overlay);

  const submitButton = document.querySelector('.LoginAuthForm .BngPrimaryButton');
  const usernameField = document.querySelector('.LoginAuthForm input[name="email"]');
  const passwordField = document.querySelector('.LoginAuthForm input[name="password"]');

  UiMsg.ok(t('newUser.welcome'), t('login.autoLogin.msg'), { autoClose: false });

  setTimeout(function () {
    typeTextOnField(usernameField, creds.username, function () {
      typeTextOnField(passwordField, creds.password, function () {
        setTimeout(function () {
          submitButton.click();
        }, 1500);
      });
    });
  }, 3000);
};

export function TrialFragment() {
  const { t } = useTranslation();
  return <div className={styles.trialText} dangerouslySetInnerHTML={{ __html: t('login.start.trial') }}></div>;
}

export default function LoginAuthForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const $capsLockIndicator = useRef();
  const { loadingCallback } = useOutletContext();

  return (
    <Formik
      initialValues={FormSchema.default()}
      validationSchema={FormSchema}
      onSubmit={async (values) => {
        loadingCallback(true);
        try {
          const afterAuthUrl = searchParams.get('afterAuthUrl');
          if (afterAuthUrl) {
            values = { ...values, afterAuthUrl };
          }

          const { unlocked, ...props } = await Api.Auth.checkTfa(values);
          if (unlocked) {
            await Api.Auth.defaultLogin(values);
          } else {
            navigate('/login/tfa/unlock', {
              state: {
                credentials: {
                  type: 'EMAIL',
                  ...values
                },
                ...props,
              },
            });
          }
        } catch (e) {
          if (e.response?.status === 401) {
            UiMsg.warn(t(e.response.data?.error ?? 'badCredentials'));
          } else {
            console.error('Error on login', { values }, e);
            UiMsg.ajaxError(t('integration.login.error'), e);
          }
        } finally {
          loadingCallback(false);
        }
      }}
    >
      {({ values, setFieldValue }) => {
        const capslockAlertHandler = (e) => {
          $capsLockIndicator.current.style.display = e?.getModifierState?.('CapsLock') ? '' : 'none';
        };

        useEffect(() => {
          const autoLoginParam = searchParams.get('al');

          if (!autoLoginParam) {
            return;
          }

          runAutoLogin({
            autoLoginParam,
            t,
            setFieldValue,
          });
        }, []);

        return (
          <BngForm className="LoginAuthForm">
            <Field component={BngField} name="email" maxLength={256} floatingLabel autoFocus />
            <div className="position-relative">
              <Field
                component={BngField}
                name="password"
                type="password"
                maxLength={256}
                onMouseDown={capslockAlertHandler}
                onKeyUp={capslockAlertHandler}
                floatingLabel
              />
              <div ref={$capsLockIndicator} style={{ display: 'none' }} className={styles.capsLockAlert}>
                {t('login.caps.lock.enable')}
              </div>
            </div>

            <div className="flex-center-items jc-sb">
              <Field
                rootClassName={`${styles.rememberMeBtn} mb-0`}
                component={BngField}
                name="rememberMe"
                inputComponent={BngCheckbox}
                asProps={{ label: t('login_remember_me') }}
                withLabel={false}
              />
              <BngCancelButton
                className={'fw-500'}
                onClick={() =>
                  navigate('/login/passwordRecovery', {
                    state: {
                      credentials: values,
                    },
                  })
                }
              >
                {t('lost_password')}
              </BngCancelButton>
            </div>

            <BngPrimaryButton type="submit" className="mt-3">
              {t('login_login')}
            </BngPrimaryButton>

            <div className="mt-2">
              <TrialFragment />
              <div
                className={`mt-2 ${styles.termsText}`}
                dangerouslySetInnerHTML={{ __html: t('login.terms.and.policies') }}
              ></div>
            </div>
          </BngForm>
        );
      }}
    </Formik>
  );
}
