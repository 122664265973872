import styles from './UploadSizePill.module.css';

import React from 'react';
import Utils from 'components/Utils';

export default function UploadSizePill({ files = [], uploadLimit = 1 }) {
  const totalUploadsSize = files.reduce((acc, file) => acc + file.size, 0);
  const totalUsage = totalUploadsSize / (uploadLimit || 1);
  const percentUsed = Math.min(Math.floor(totalUsage * 100), 100);
  return (
    <div className={`UploadSizePill ${styles.pillWrapper}`}>
      <div
        className={`${styles.pillContainer}`}
        title={`${Utils.Bytes.toMB(totalUploadsSize)}/${Utils.Bytes.toMB(uploadLimit)}`}
      >
        <div className={`${styles.pillFilledBar}`} style={{ width: `${percentUsed}%` }} />
      </div>
    </div>
  );
}
