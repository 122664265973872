import styles from './LabsReleaseLabel.module.css'

import React from 'react'
import Icon from "components/ui/common/Icon";
import useBimContext from "components/hooks/useBimContext";


export default function LabsReleaseLabel ({}) {
    const context = useBimContext();
    return (
        <div style={{ display: 'flex' }}>
            <div className={`${styles.labelWrapper}`}>
                <span className={`${styles.releaseLabel}`}>{context.msg.t('new')}</span>
                <Icon icon="new_releases" className={`${styles.releaseIcon}`} />
            </div>
        </div>
    )
}