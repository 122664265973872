import React from 'react';

import SelectIconDialog from 'components/ui/common/SelectIconDialog';
import Api from 'components/Api';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';

export default function ChangeFolderIconDialog({ folder, icon, ...props }) {
  const context = useBimContext();
  const type = Icon.parseType(icon);
  const initialIcon = icon && type === Icon.MATERIAL ? { name: icon, type: Icon.MATERIAL } : null;
  return (
    <SelectIconDialog
      icon={initialIcon}
      onSelect={async (icon) => {
        if (!icon || !icon.name) {
          throw new Error(context.msg.t('select.an.icon'));
        }

        // If icon has not been changed there is no reason to persist
        if (initialIcon !== null) {
          if (initialIcon.name === icon.name) {
            return;
          }
        }

        const projectId = context.project.id;
        await Api.Project.updateFolderIcon({ projectId, folder, icon: icon.name });
        await Api.updateJsf();
      }}
      {...props}
    />
  );
}
