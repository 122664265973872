import React, { useState } from 'react';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import ConfigureAccordion from 'components/ui/common/ConfigureAccordion';
import { validateAndConfirmPath } from 'components/ui/common/SaveAsDialog';
import useBimContext from 'components/hooks/useBimContext';
import Utils from 'components/Utils';

export const handleConfigSubmit = async (
  props = { path: '', context: null },
  { description, printer, ...values },
  actions,
  validationProps = {}
) => {
  const result = {
    proceed: false,
    renamed: false,
    newPath: props.path,
  };

  result.proceed = (
    await validateAndConfirmPath({
      values: { projectId: props.context.project.id, path: props.path, ...values },
      props,
      actions,
      isSimpleSave: true,
      ...validationProps,
    })
  ).proceed;

  if (!result.proceed) {
    actions.setSubmitting(false);
    return result;
  }

  result.renamed = props.name !== values.name || props.folder !== values.folder;

  if (result.renamed) {
    const renameResult = await Api.Project.renameObject({
      projectId: props.context.project.id,
      path: props.path,
      folder: values.folder,
      name: values.name,
      description,
    });
    result.newPath = renameResult.newPath;
  }

  await Api.Bng.setObjectConfigs({
    path: result.newPath,
    description: description,
    renamed: result.renamed,
    printer: printer,
  });

  if (!Utils.Object.isDashboard(result.newPath)) {
    if (result.renamed) {
      UiMsg.ok(props.context.msg.t('object.rename.success'));
      window.__LOAD_OBJECT_URL = { content: result.newPath };
    }

    await Api.updateJsf();
  }

  return result;
};

export const useFetchVersion = ({ path = '' }) => {
  const [versions, setVersions] = useState(null);

  const fetchVersions = async () => {
    if (!path) return;
    try {
      const _versions = await Api.Bng.getLatestVersions(path, 6);
      setVersions(_versions);
    } catch (e) {
      console.error('Error on fetchVersions()', { path }, e);
      UiMsg.ajaxError('fetch.versions.fail', e);
      setVersions({ versions: [] });
    }
  };

  return [versions, fetchVersions];
};

export default function GeneralConfigMenuItem({ toggleAccordion = _.noop, ...props }) {
  const context = useBimContext();

  const toggleConfigAccordion = async (event) => {
    return toggleAccordion(ConfigureAccordion, 'GeneralConfigMenuItem');
  };

  return (
    <RightMenuItem
      title={context.msg.t('general.config')}
      icon="menu_open"
      className="GeneralConfigMenuItem"
      onClick={toggleConfigAccordion}
      {...props}
    />
  );
}
