import React from "react";
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";


export class JustGaugePercent extends BaseKpi {

    constructor(props) {
        super(props);
    }

    customResizeKpi = ({height}) => {

        resizeEl(this.$kpiTitleEl, {
            fontSize: {minValue: 12, multiplier: 0.1, refSize: height},
            lineHeight: {minValue: 14, multiplier: 0.13, refSize: height},
        });

        if (this.$kpiTitleEl.offsetWidth >= this.props.div.offsetWidth) {
            const newFontSize = height * (this.props.div.offsetWidth / this.$kpiTitleEl.offsetWidth);

            resizeEl(this.$kpiTitleEl, {
                fontSize: {minValue: 12, multiplier: 0.1, refSize: newFontSize},
                lineHeight: {minValue: 14, multiplier: 0.13, refSize: newFontSize},
            });
        }

        resizeEl(this.$kpiJustGaugeEl, {
            height: {minValue: 25, multiplier: 0.35, refSize: height},
        });

        resizeEl(this.$percentEl, {
            fontSize: {minValue: 12, multiplier: 0.08, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.08, refSize: height},
        });

        resizeEl(this.$kpiDescriptionEl, {
            fontSize: {minValue: 8, multiplier: 0.05, refSize: height},
            lineHeight: {minValue: 10, multiplier: 0.1, refSize: height},
        });

        if (this.$kpiDescriptionEl.offsetWidth >= this.props.div.offsetWidth) {
            let newFontSize = height * (this.props.div.offsetWidth / this.$kpiDescriptionEl.offsetWidth);

            resizeEl(this.$kpiDescriptionEl, {
                fontSize: {minValue: 8, multiplier: 0.05, refSize: newFontSize},
                lineHeight: {minValue: 10, multiplier: 0.1, refSize: newFontSize},
            });
        }
    };

    initComponent = () => {
        const {color} = this.state;

        let kpiId = this.state.kpiId;
        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        j(`#justgaugepercent${kpiId}`).empty();

        let minValue = parseFloat(this.props.minValue);

        let targetValue = this.props.target === 0 ? 1 : this.props.target;

        let val = this.props.measureAsTarget || !this.props.targetIsPercent ?
            this.props.percentage :
            this.props.value / targetValue;

        if (this.props.percentage && !this.props.measureAsTarget && this.props.target === 0) {
            val *= 100;
        }

        if (jQuery('svg', document.getElementById('justgaugepercent' + kpiId)).length === 0) {
            new JustGage({
                id: 'justgaugepercent' + kpiId,
                value: val,
                min: minValue,
                max: 100,
                levelColors: [color],
                relativeGaugeSize: true,
                startAnimationTime: application.utils.disableAnimations() ? 1 : 500,
                refreshAnimationTime: application.utils.disableAnimations() ? 1 : 500,
                hideValue: true,
                hideMinMax: true
            })
        }
    };

    render() {

        let kpiId = this.state.kpiId;

        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        return (
            <table>
                <tbody>
                <tr className="title-kpi-percent">
                    <td>
                        <div className="kpititle" style={{textAlign: 'center'}}>
                            <span ref={el => this.$kpiTitleEl = el}>{this.props.title}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}>
                        <div ref={el => this.$kpiJustGaugeEl = el} id={`justgaugepercent${kpiId}`}/>
                    </td>
                </tr>
                <tr className="title-kpi-percent">
                    <td>
                        <div title={this.state.value} className="kpi-percent" ref={el => this.$percentEl = el}
                             style={{textAlign: 'center'}}>
                            {this.state.value}
                        </div>
                    </td>
                </tr>
                <tr className="title-kpi-description">
                    <td>
                        <div style={{textAlign: 'center'}}>
                                <span className="kpidescription"
                                      ref={el => this.$kpiDescriptionEl = el}>{this.props.label}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <center>
                            <div ref={el => this.$containerBandsEl = el}>
                                {this.useBands({container: this.$containerBandsEl})}
                            </div>
                        </center>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

}

