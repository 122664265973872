import './MenuButton.css';
import React, {Component} from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import Toggleable from "components/ui/Toggleable";
import Icon from "components/ui/common/Icon";

class MenuButtonSearch extends Toggleable {

    static propTypes = {
        actionDisable: PropTypes.bool,
        clearSearch: PropTypes.func,
        enableSearch: PropTypes.bool,
        onSearchChange: PropTypes.func
    };

    static defaultProps = {
        actionDisable: false
    };

    buttonSearchClick = () => {
        jQuery('.search-menu-button-container-enable').css('display','block');
        jQuery('.search-menu-button-input').focus();
    };

    buttonSearchCloseClick = () => {
        if(this.props.enableSearch) {
            this.props.clearSearch();
        }
        jQuery('.search-menu-button-input').val('');
        jQuery('.search-menu-button-container-enable').css('display','none');
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={ this.props.actionDisable ? {marginRight:-80} : {} } className="search-menu-button-container">
                <div className="search-menu-button-container-enable">
                    <Icon icon="search" className="search-menu-button-icon"/>
                    <input style={this.props.actionDisable ? {width:330} : {width:250} } type="text" className="search-menu-button-input" placeholder={this.props.context.msg.t('search.field.placeholder')}
                           onChange={e => this.props.onSearchChange(e.target.value)}/>
                    <Icon icon="close" className="search-menu-button-icon-close" onClick={() => this.buttonSearchCloseClick()}/>
                </div>
                <div className="search-menu-button-container-disable">
                    <Icon icon="search" className="search-menu-button-icon" onClick={() => this.buttonSearchClick()}/>
                </div>
            </div>
        );
    }
}

export default ContextEnhancer(MenuButtonSearch);