import styles from './CreateFilterDialog.module.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Field } from 'formik';

import { DefaultDialogActions } from 'components/ui/FormUtils';
import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { BngField } from 'components/bng/form/BngField';
import { BngInput } from 'components/bng/form/BngInput';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngTable } from 'components/bng/ui/BngTable';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import { FormikListener } from 'components/bng/form/formik/FormikListener';
import { BngForm } from 'components/bng/form/BngForm';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';

const NewFilterSchema = bngYup((yup) => {
  return yup.object().shape({
    name: yup.string().required().default('').min(1).max(50),
    displayName: yup.string().required().default('').min(1).max(50),
    type: yup.string().default('REGULAR'),
    filterType: yup.string().default('SINGLE_SELECTION'),
    mdxFilters: yup
      .array(
        yup.object({
          datasource: yup.string(),
          cube: yup.string(),
          dimension: yup.string(),
        })
      )
      .default([]),
  });
});

export const EVENT = 'CreateFilterDialog:Saved';

const fetchCubes = async (projectId, datasourceName = '') => {
  if (!projectId || !datasourceName) return [];

  const cubes = await Api.Project.sourceCubes({
    projectId,
    sourceName: datasourceName,
  });

  return cubes.map((cube) => {
    return {
      label: cube.label,
      value: cube.value,
      render: () => (
        <span className={styles.optionEllipsis} title={cube.label}>
          {cube.label}
        </span>
      ),
    };
  });
};

const MDX_FILTER_DEFAULT_VALUES = {
  datasource: null,
  cube: null,
  dimension: null,
};

const removeInfoFromLabel = (label, removeBoth) => {
  // Regex splits the string in the '(' indicated by 'removeBoth', to remove (Filtro) (Invisível) from the label
  // '(' was used for separation because the 'filtro' is translated using the project's language witch is not accessible here
  const regex = new RegExp(`(\\([^(]+){${removeBoth ? 2 : 1}}$`);
  return label.split(regex)[0];
};

const filterDimensions = (dimensions, showVisibleDim) => {
  let filteredDimensions = dimensions.slice();

  filteredDimensions = filteredDimensions
    .filter((dimension) => (!showVisibleDim ? !dimension.visible : true))
    .map((dimension) => {
      return {
        ...dimension,
        label: removeInfoFromLabel(dimension.label, !showVisibleDim),
        value: dimension.dimension,
      };
    });

  return filteredDimensions.sort((a, b) => {
    return Utils.Strings.compareIgnoreCase(a.label, b.label);
  });
};

const CreateFilterDialog = ({ closeModal = _.noop, filterId = null }) => {
  const context = useBimContext();

  const [loading, setLoading] = useState(false);
  const [persistedFilter, setPersistedFilter] = useState({});
  const [showVisibleDim, setShowVisibleDim] = useState(false);
  const [datasourceOpts, setDatasourceOpts] = useState([]);
  const [typeOpts, setTypeOpts] = useState([]);
  const [selectionsTypeOpts, setSelectionsTypeOpts] = useState([]);
  const [cubeOpts, setCubeOpts] = useState([]);
  const [dimensionOpts, setDimensionOpts] = useState([]);
  const [cubeStructures, setCubeStructures] = useState({});
  const [valuesForMdxFilter, setValuesForMdxFilter] = useState({ ...MDX_FILTER_DEFAULT_VALUES });

  const $formikRef = useRef();
  const projectId = context.project.id;

  const fetchData = async () => {
    setLoading(true);
    try {
      const datasources = await Api.Project.findSources({ projectId, skipPermissionCheck: true });
      datasources.forEach((group) => {
        group.icon = 'folder';
        group.selectItems.forEach((source) => {
          source.icon = source.attributes.icon || 'insert_chart';
        });
      });
      setDatasourceOpts(datasources);

      const { types, filterTypes } = await Api.MdxGlobalFilter.findRelatedEnums();
      setTypeOpts(
        types.map((type) => {
          return { label: context.msg.t(type), value: type };
        })
      );
      setSelectionsTypeOpts(
        filterTypes.map((filterType) => {
          return { label: context.msg.t(filterType), value: filterType };
        })
      );

      if (filterId) {
        const filter = await Api.MdxGlobalFilter.findOne(filterId);
        filter.mdxFilters = await Promise.all(
          filter.mdxFilters.map(async (mdxFilter) => {
            let cubeStructure;
            let dim;
            try {
              cubeStructure = await fetchCubeStructure(mdxFilter.datasource, mdxFilter.cube);
              dim = cubeStructure.dimensions[mdxFilter.dimension];
            } catch (e) {
              console.warn('Error on fetchCubeStructure() for mdxFilter', { mdxFilter }, e);
            }
            return {
              dimension: mdxFilter.dimension,
              label: (dim?.caption ?? mdxFilter.dimension) + ` (${context.msg.t('invisible')})`,
              visible: dim?.visible ?? true,
              datasource: mdxFilter.datasource,
              datasourceName: cubeStructure?.caption ?? mdxFilter.datasource,
              cube: cubeStructure?.name ?? mdxFilter.cube,
              cubeName: cubeStructure?.caption ?? mdxFilter.cube,
              deletedDimension: !_.isEmpty(cubeStructure) && _.isEmpty(dim),
            };
          })
        );

        setPersistedFilter(filter);
        $formikRef.current.resetForm({
          values: _.cloneDeep(filter),
        });
      }
    } catch (e) {
      console.error(e);
      UiMsg.error(context.msg.t('error.fetching.data.for.filters'), e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchCubeStructure = async (origin, cube) => {
    let cubeStructure = cubeStructures[cube || origin];
    if (cubeStructure) {
      return cubeStructure;
    }

    const { cubes = {} } = await Api.Project.queryStructure({
      projectId,
      sourceName: origin,
    });

    cubeStructure = cube ? cubes[cube] : Object.values(cubes)[0];
    setCubeStructures({
      ...cubeStructure,
      [cubeStructure.name]: cubeStructure,
    });
    return cubeStructure;
  };

  const fetchDimensions = async (origin, cube, type) => {
    setLoading(true);
    try {
      let dimOpts = [];
      if (origin) {
        const cubeStructure = await fetchCubeStructure(origin, cube);
        const dimensions = Object.values(cubeStructure.dimensions);

        const timeDimsOnly = type === 'TIME';
        dimOpts = dimensions
          .filter((d) => (timeDimsOnly ? d.dimensionType === 'TIME' : d.dimensionType !== 'TIME'))
          .map((d) => ({
            datasource: origin,
            datasourceName: cubeStructure.caption,
            cube: cubeStructure.name,
            cubeName: cubeStructure.caption,
            label: d.caption + (!d.visible ? ` (${context.msg.t('invisible')})` : ''),
            visible: d.visible,
            dimension: d.name,
          }));

        if (dimensions.length === 0) {
          UiMsg.warn(context.msg.t(timeDimsOnly ? 'time.dimensions.not.found' : 'dimensions.not.found'));
        }
      }

      setDimensionOpts(dimOpts);
    } catch (e) {
      console.error(e);
      UiMsg.error(context.msg.t('error.fetching.data.for.origin'), e);
    } finally {
      setLoading(false);
    }
  };

  const saveFilter = async (values, actions) => {
    if (values.mdxFilters.length === 0) {
      actions.setSubmitting(false);
      UiMsg.error(context.msg.t('select.at.least.one.dimension'));
    } else {
      try {
        await Api.MdxGlobalFilter.save({
          ...values,
          id: filterId,
          projectId,
        });
        UiMsg.ok(context.msg.t('save_success', [context.msg.t('filter2')]));
        bimEventBus.emit(EVENT, {});
        closeModal();
      } catch (e) {
        if (e.response?.status === 409) {
          UiMsg.error(context.msg.t('name_alredy_taken'));
        } else {
          console.error(e);
          UiMsg.error(context.msg.t('error.creating.filter'), e);
        }
        actions.setSubmitting(false);
      }
    }
  };

  const formValuesChangeListener = async (next, current, setFieldValue) => {
    const typeChanged = next.values.type !== current.values.type;
    if (typeChanged) {
      setValuesForMdxFilter({ ...MDX_FILTER_DEFAULT_VALUES });
      setFieldValue('filterType', 'SINGLE_SELECTION', false);
      if (persistedFilter && next.values.type === persistedFilter.type) {
        setFieldValue('mdxFilters', persistedFilter.mdxFilters, false);
      } else {
        setFieldValue('mdxFilters', [], false);
      }
    }

    if (!_.isEqual(next.values.mdxFilters, current.values.mdxFilters)) {
      const mdxFilters = next.values.mdxFilters ?? [];
      const updatedDatasources = _.cloneDeep(datasourceOpts);
      updatedDatasources.forEach((group) => {
        group.selectItems.forEach((source) => {
          const notLoaded = source.description.contains(context.msg.t('initial.data.load.not.finished.yet'));
          source.disabled = notLoaded || mdxFilters.some((mdxFilter) => source.value === mdxFilter.datasource);
        });
      });
      setDatasourceOpts(updatedDatasources);
    }
  };

  const filterDatasources = (mdxFilters) => {
    const datasources = _.cloneDeep(datasourceOpts);
    datasources.forEach((datasource) => {
      datasource.selectItems.forEach((item) => {
        item.disabled = item.disabled || !!_.find(mdxFilters, { originId: item.value });
      });
    });

    return datasources;
  };

  const addSelectedDimension = (values, setFieldValue, mdxFilterData) => {
    const matcher = (mdxFilter) => {
      return (
        mdxFilter.datasource === mdxFilterData.datasource &&
        mdxFilter.cube === mdxFilterData.cube &&
        mdxFilter.dimension === mdxFilterData.dimension
      );
    };

    if (values.mdxFilters.some(matcher)) return;

    const dimensionData = dimensionOpts.find(matcher);
    setFieldValue('mdxFilters', [...values.mdxFilters, { ...dimensionData }]);
    setValuesForMdxFilter({ ...MDX_FILTER_DEFAULT_VALUES });
  };

  const removeSelectedDimension = (dimensionToRemove, idx) => {
    const copy = $formikRef.current.values.mdxFilters.slice();
    copy.splice(idx, 1);
    $formikRef.current.setFieldValue('mdxFilters', copy);
  };

  const tableColumns = useMemo(() => buildTableColumns({ context, removeSelectedDimension, removeInfoFromLabel }), [
    $formikRef.current?.values.mdxFilters,
  ]);
  const filteredDimensions = filterDimensions(dimensionOpts, showVisibleDim);

  return (
    <Formik
      initialValues={NewFilterSchema.default()}
      onSubmit={saveFilter}
      validationSchema={NewFilterSchema}
      innerRef={$formikRef}
    >
      {({ values, setFieldValue, isSubmitting, isValid }) => {
        const validForSubmit = isValid && values.mdxFilters.length !== 0;
        const filteredDatasources = useMemo(() => filterDatasources(values.mdxFilters), [datasourceOpts, values]);

        return (
          <Dialog
            className={`CreateFilterDialog ${styles.CreateFilterDialog} large`}
            title={context.msg.t(filterId ? 'editing.globalFilter' : 'new.globalFilter', [
              persistedFilter.displayName || persistedFilter.name || '',
            ])}
            loading={loading || isSubmitting}
            onClose={closeModal}
          >
            <BngForm>
              <Dialog.Body>
                <div className={styles.formFieldsWrapper}>
                  <div className={styles.rowWrapper}>
                    <FormikListener
                      onChange={async (next, current) => {
                        await formValuesChangeListener(next, current, setFieldValue);
                      }}
                    />
                    <Field
                      name="name"
                      className={styles.smallFormField}
                      label={context.msg.t('name')}
                      component={BngField}
                      inputComponent={BngInput}
                      required
                    />
                    <Field
                      name="displayName"
                      className={styles.smallFormField}
                      label={context.msg.t('caption')}
                      component={BngField}
                      inputComponent={BngInput}
                      required
                    />
                    <Field
                      name="type"
                      className={styles.smallFormField}
                      label={context.msg.t('type')}
                      component={BngField}
                      clearable={false}
                      inputComponent={BngSelectSearch}
                      options={typeOpts}
                    />
                    <Field
                      name="filterType"
                      className={styles.smallFormField}
                      label={context.msg.t('filter.combo.type')}
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      clearable={false}
                      disabled={values.type === 'TIME'}
                      options={selectionsTypeOpts}
                    />
                  </div>

                  <div className={styles.rowWrapper}>
                    <BngField
                      className={cubeOpts.length > 1 ? styles.smallFormField : styles.largeFormField}
                      label={context.msg.t('origin')}
                      inputComponent={BngSelectSearch}
                      options={filteredDatasources}
                      groupedOpts={true}
                      field={{ value: valuesForMdxFilter.datasource }}
                      onChange={async (value) => {
                        try {
                          const cubeOpts = await fetchCubes(projectId, value);
                          let cubeValue = null;

                          if (cubeOpts.length === 1) {
                            cubeValue = cubeOpts[0].value;
                            await fetchDimensions(value, cubeValue, values.type);
                          }

                          setCubeOpts(cubeOpts);
                          setValuesForMdxFilter({
                            datasource: value,
                            cube: cubeValue,
                            dimension: value ? valuesForMdxFilter.dimension : null,
                          });
                        } catch (e) {
                          console.error('Error while fetching cube and dimensions data', { projectId, value }, e);
                          UiMsg.ajaxError(null, e);
                        }
                      }}
                    />

                    {cubeOpts.length > 1 && (
                      <BngField
                        className={styles.smallFormField}
                        label={context.msg.t('cube')}
                        inputComponent={BngSelectSearch}
                        options={cubeOpts}
                        field={{ value: valuesForMdxFilter.cube }}
                        onChange={async (value) => {
                          try {
                            setValuesForMdxFilter({
                              ...valuesForMdxFilter,
                              cube: value,
                            });
                            fetchDimensions(valuesForMdxFilter.datasource, value, values.type);
                          } catch (e) {
                            console.error('Error while fetching dimensions data', { projectId, value }, e);
                            UiMsg.ajaxError(null, e);
                          }
                        }}
                      />
                    )}

                    <div>
                      <div style={{ display: 'inline-flex', gap: '8px' }}>
                        <BngField
                          className={styles.mediumFormField}
                          label={context.msg.t('dimension')}
                          inputComponent={BngSelectSearch}
                          disabled={_.isEmpty(valuesForMdxFilter.datasource) || dimensionOpts.length === 0}
                          options={filteredDimensions}
                          field={{ value: valuesForMdxFilter.dimension }}
                          onChange={(value) =>
                            setValuesForMdxFilter({
                              ...valuesForMdxFilter,
                              dimension: value,
                            })
                          }
                        />
                        <BngIconButton
                          icon="add"
                          className={styles.addDimensionButton}
                          disabled={_.isEmpty(valuesForMdxFilter.dimension)}
                          onClick={() => {
                            addSelectedDimension(values, setFieldValue, valuesForMdxFilter);
                          }}
                        />
                      </div>
                      <BngCheckbox
                        label={context.msg.t('show.visible.dimensions')}
                        field={{
                          onChange: (event) => {
                            setShowVisibleDim(!showVisibleDim);
                          },
                          value: showVisibleDim,
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.tableContainer}>
                  <BngTable rows={values.mdxFilters} cols={tableColumns} stickyHeader />
                </div>
              </Dialog.Body>
              <Dialog.Footer>
                <DefaultDialogActions
                  closeModal={closeModal}
                  context={context}
                  disabled={!validForSubmit}
                  title={context.msg.t(
                    values.mdxFilters.length === 0 ? 'select.at.least.one.dimension' : 'fill.all.the.required.fields'
                  )}
                />
              </Dialog.Footer>
            </BngForm>
          </Dialog>
        );
      }}
    </Formik>
  );
};

const buildTableColumns = ({ context, removeSelectedDimension, removeInfoFromLabel }) => {
  return [
    {
      key: 'origin',
      label: context.msg.t('origin'),
      render: (row) => {
        return <span>{row.datasource}</span>;
      },
    },
    {
      key: 'cube',
      label: context.msg.t('cube'),
      render: (row) => {
        return <span>{row.cubeName}</span>;
      },
    },
    {
      key: 'dimension',
      label: context.msg.t('dimension'),
      render: (row) => {
        return (
          <div
            title={row.deletedDimension ? context.msg.t('filter.dimension.not.found.title') : null}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span>{removeInfoFromLabel(row.label, false)}</span>
            {row.deletedDimension && (
              <Icon icon="priority_high" className={row.deletedDimension && styles.dimensionDeleted} />
            )}
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: context.msg.t('actions'),
      render: (row, idx) => {
        return <BngIconButton icon="close" onClick={() => removeSelectedDimension(row, idx)} />;
      },
    },
  ];
};
export default CreateFilterDialog;
