import './Activity.css';
import React, { Component } from 'react';
import Api from 'components/Api';
import ContextEnhancer from 'components/ContextEnhancer';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';

const PathAwareRemovedRender = ContextEnhancer(({ context, activity }) => {
  const [path, caption] = activity.parameters;
  const type = Utils.Object.getObjectType(path);

  const objectCaption = caption || Utils.Object.fileName(path);

  return (
    <div className="Activity PathAwareRemovedRender">
      <a className="user" href="#">
        {activity.who.name}
      </a>{' '}
      {context.msg.t(`removed.the.${type}`)}{' '}
      <a href="#" title={path}>
        {objectCaption}
      </a>{' '}
      {context.msg.t('activity.on')} <a href="#">{activity.where.message}</a>
    </div>
  );
});

const GenericMessageHandlerFactory = (msgFactory) => {
  return ({ activity }) => {
    const { msg } = useBimContext();
    return (
      <div className={`Activity GenericMessageHandlerFactory ${activity.type}`}>
        <strong className="user">{activity.who.name}</strong> {msgFactory(activity, msg)}
        {activity.project && (
          <>
            {' '}
            <strong>{msg.t('activity.where.project', [activity.project.caption ?? activity.project.displayName])}</strong>
          </>
        )}
      </div>
    );
  };
};

const AddonRequestMessage = GenericMessageHandlerFactory((activity, msg) => {
  const params = activity.props.params;
  return msg.t(`${activity.type}.msg`, [msg.t('for.the.account', [msg.t(params.addon), params.accountName])]);
});

export const ActivityHandlers = {
  PathAwareRemovedHandler: PathAwareRemovedRender,
  UserRemovedFromProject: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('UserRemovedFromProject.msg');
  }),
  UserRoleUpdatedOnProject: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('UserRoleUpdatedOnProject.msg', [msg.t(activity.props.params.newRole)]);
  }),
  MdxGlobalFilterCreated: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('MdxGlobalFilterCreated.msg', [activity.props.params.caption]);
  }),
  MdxGlobalFilterEdited: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('MdxGlobalFilterEdited.msg', [activity.props.params.caption]);
  }),
  MdxGlobalFilterRemoved: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('MdxGlobalFilterRemoved.msg', [activity.props.params.caption]);
  }),
  MonitorCreated: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('MonitorCreated.msg', [activity.props.params.caption]);
  }),
  MonitorEdited: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('MonitorEdited.msg', [activity.props.params.caption]);
  }),
  MonitorRemoved: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('MonitorRemoved.msg', [activity.props.params.caption]);
  }),
  ProjectCreated: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('ProjectCreated.msg', [activity.props.params.caption]);
  }),
  ProjectEdited: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('ProjectEdited.msg', [activity.props.params.caption]);
  }),
  ProjectRemoved: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('ProjectRemoved.msg', [activity.props.params.caption]);
  }),
  RequestProjectRemoval: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('RequestProjectRemoval.msg', [activity.props.params.caption]);
  }),
  PublisherCreated: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('PublisherCreated.msg', [activity.props.params.caption]);
  }),
  PublisherEdited: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('PublisherEdited.msg', [activity.props.params.caption]);
  }),
  PublisherRemoved: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('PublisherRemoved.msg', [activity.props.params.caption]);
  }),
  ObjectVersionRestored: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('ObjectVersionRestored.msg', [activity.props.params.caption]);
  }),
  PathPermissionUpdated: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('PathPermissionUpdated.msg', [activity.props.params.caption]);
  }),
  UniversityAccessed: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('UniversityAccessed.msg', [activity.props.params.caption]);
  }),
  AddonRequested: AddonRequestMessage,
  AddonRequestAccepted: AddonRequestMessage,
  AddonRequestRejected: AddonRequestMessage,
  EnabledLabsFeature: GenericMessageHandlerFactory((activity, msg) => {
    const val = msg.t('EnabledLabsFeature.msg', [
      `<strong>${msg.t(`LabsFeature.${activity.props.params.feature}.label`)}</strong>`,
    ]);
    return <span dangerouslySetInnerHTML={{ __html: val }} />;
  }),
  DisabledLabsFeature: GenericMessageHandlerFactory((activity, msg) => {
    const val = msg.t('DisabledLabsFeature.msg', [
      `<strong>${msg.t(`LabsFeature.${activity.props.params.feature}.label`)}</strong>`,
    ]);
    return <span dangerouslySetInnerHTML={{ __html: val }} />;
  }),
  UserSendLabsFeatureRequest: GenericMessageHandlerFactory((activity, msg) => {
    const val = msg.t('UserSendLabsFeatureRequest.msg', [
      `<strong>${msg.t(`LabsFeature.${activity.props.params.feature}.label`)}</strong>`,
    ]);
    return <span dangerouslySetInnerHTML={{ __html: val }} />;
  }),
  AccountMasterChanged: GenericMessageHandlerFactory((activity, msg) => {
    return msg.t('AccountMasterChanged.msg', [activity.props.params.newMaster, activity.props.params.accountName]);
  }),
};

class Activity extends Component {
  renderMessage = (activity) => {
    if (ActivityHandlers.hasOwnProperty(activity.type)) {
      const Component = ActivityHandlers[activity.type];
      return <Component activity={activity} />;
    }

    if (activity.type === 'PathAwareLoadedHandler' || activity.type === 'PathAwareSavedHandler') {
      let msg = '';
      if (activity.type === 'PathAwareLoadedHandler') {
        if (
          activity.parameters[0] === 'kpi' ||
          activity.parameters[0] === 'analysis' ||
          activity.parameters[0] === 'dashboard'
        ) {
          msg = this.props.context.msg.t('view.' + activity.parameters[0]);
        } else {
          msg = this.props.context.msg.t('view.object');
        }
      } else {
        if (
          activity.parameters[0] === 'kpi' ||
          activity.parameters[0] === 'analysis' ||
          activity.parameters[0] === 'dashboard'
        ) {
          msg = this.props.context.msg.t('edit.' + activity.parameters[0]);
        } else {
          msg = this.props.context.msg.t('edit.object');
        }
      }

      return (
        <div className="Activity">
          <strong className="user">{activity.who.name}</strong> {msg}{' '}
          <a
            href={
              activity.fileExists ? `${Api.baseUrl()}/load.iface?${j.param({ content: activity.parameters[2] })}` : '#'
            }
          >
            {activity.objectCaption || activity.parameters[1]}{' '}
            {activity.fileExists ? '' : `(${this.props.context.msg.t('removed')})`}
          </a>{' '}
          {this.props.context.msg.t('activity.on')} <a href="#">{activity.where.message}</a>
        </div>
      );
    } else {
      return (
        <div className="Activity">
          <strong className="user">{activity.who.name}</strong> {activity.what.message}{' '}
          <strong>{activity.where.message}</strong>
        </div>
      );
    }
  };

  render() {
    const { activity } = this.props;
    return (
      <div className="profile-activity">
        <div className="profile-activity-msg">{this.renderMessage(activity)}</div>
        <div className="profile-activity-time">{moment(activity.date).fromNow()}</div>
      </div>
    );
  }
}

export default ContextEnhancer(Activity);
