import './StatsPanel.css';

import React from 'react';
import PropTypes from 'prop-types';
import PieChart from 'react-minimal-pie-chart';

import ContextEnhancer from 'components/ContextEnhancer';
import BngPopper from 'components/bng/ui/BngPopper';
import Icon from 'components/ui/common/Icon';
import LoadingSvg from 'components/ui/loading/LoadingSvg';
import {BngIconButton} from "components/bng/ui/BngIconButton";
import Utils from "components/Utils";
import BngQuotaViewer from "components/ui/project/BngQuotaViewer";

class StatsPanel extends React.Component {
  static propTypes = {
    projectStats: PropTypes.object,
    loading: PropTypes.bool,
    lastUpdate: PropTypes.number,
    fetchProjectStats: PropTypes.func,
  };

  static defaultProps = {
    projectStats: null,
    loading: false,
    lastUpdate: null,
    fetchProjectStats: _.noop
  };

  state = {
    popperRefUserDropdown: null,
    openUserDropdown: false,
    popperRefObjectDropdown: null,
    openObjectDropdown: false,
    popperRefQuotaDropdown: null,
    openQuotaDropdown: false,
  };

  openUserDropdown = (event) => {
    this.addActiveDropdownEffect('.painel-metrics-container.MetricUsers');
    this.setState({
      openUserDropdown: true,
      popperRefUserDropdown: event.currentTarget,
      openObjectDropdown: false,
      openQuotaDropdown: false,
    });
  };

  closeUserDropdown = (event) => {
    this.removeActiveDropdownEffect('.painel-metrics-container.MetricUsers');
    this.setState({ openUserDropdown: false });
  };

  openObjectDropdown = (event) => {
    this.addActiveDropdownEffect('.painel-metrics-container.MetricObjects');
    this.setState({
      openObjectDropdown: true,
      popperRefObjectDropdown: event.currentTarget,
      openUserDropdown: false,
      openQuotaDropdown: false,
    });
  };

  closeObjectDropdown = (event) => {
    this.removeActiveDropdownEffect('.painel-metrics-container.MetricObjects');
    this.setState({ openObjectDropdown: false });
  };

  openQuotaDropdown = (event) => {
    this.addActiveDropdownEffect('.painel-metrics-container.MetricStructures');
    this.setState({
      openQuotaDropdown: true,
      popperRefQuotaDropdown: event.currentTarget,
      openUserDropdown: false,
      openObjectDropdown: false,
    });
  };

  closeQuotaDropdown = (event) => {
    this.removeActiveDropdownEffect('.painel-metrics-container.MetricStructures');
    this.setState({ openQuotaDropdown: false });
  };

  addActiveDropdownEffect = (el) => {
    j(el).addClass('stats-panel-open');
  };

  removeActiveDropdownEffect = (el) => {
    j(el).removeClass('stats-panel-open');
  };

  renderItemsUsers = () => {
    let value = [];

    if (this.props.projectStats?.countadmin > 0) {
      let object = {
        title: this.props.context.msg.t('Administrator'),
        value: this.props.projectStats.countadmin,
        color: '#D64949',
      };
      value.push(object);
    }
    if (this.props.projectStats?.countexpert > 0) {
      let object = {
        title: this.props.context.msg.t('Expert'),
        value: this.props.projectStats.countexpert,
        color: '#575BD8',
      };
      value.push(object);
    }
    if (this.props.projectStats?.countexplorer > 0) {
      let object = {
        title: this.props.context.msg.t('Explorer'),
        value: this.props.projectStats.countexplorer,
        color: '#E4B03A',
      };
      value.push(object);
    }
    if (this.props.projectStats?.countviewer > 0) {
      let object = {
        title: this.props.context.msg.t('Viewer'),
        value: this.props.projectStats.countviewer,
        color: '#269C59',
      };
      value.push(object);
    }

    return value;
  };

  renderItemsObjects = () => {
    let value = [];

    if (this.props.projectStats?.countdashboard > 0) {
      let object = {
        title: this.props.context.msg.t('dashBoard_title'),
        value: this.props.projectStats.countdashboard,
        color: '#D64949',
      };
      value.push(object);
    }
    if (this.props.projectStats?.countanalysis > 0) {
      let object = {
        title: this.props.context.msg.t('header_menuItem_maps_analysis'),
        value: this.props.projectStats.countanalysis,
        color: '#575BD8',
      };
      value.push(object);
    }
    if (this.props.projectStats?.countkpi > 0) {
      let object = {
        title: this.props.context.msg.t('kpi'),
        value: this.props.projectStats.countkpi,
        color: '#E4B03A',
      };
      value.push(object);
    }
    if (this.props.projectStats?.countcockpit > 0) {
      let object = {
        title: this.props.context.msg.t('cockpits'),
        value: this.props.projectStats.countcockpit,
        color: '#269C59',
      };
      value.push(object);
    }
    if (this.props.projectStats?.countmap > 0) {
      let object = {
        title: this.props.context.msg.t('pagetitleMaps'),
        value: this.props.projectStats.countmap,
        color: '#16879e',
      };
      value.push(object);
    }

    return value;
  };

  render() {
    const objectsCount = [
      'countdashboard',
      'countanalysis',
      'countkpi',
      'countcockpit',
      'countmap',
    ].reduce((acc, el) => acc + (this.props.projectStats?.[el] || 0), 0);

    const $pageContentEl = document.querySelector('.page-content');

    return (
      <div className={`StatsPanel panel-account-metrics ${this.props.loading ? 'Loading' : ''}`}>
        <table>
          <tbody>
            <tr style={{ textAlign: 'center' }}>
              <td>
                <div
                  className="painel-metrics-container MetricObjects"
                  onClick={this.openObjectDropdown}
                  onMouseLeave={this.closeObjectDropdown}
                >
                  <div style={{ display: 'inline-flex' }}>
                    <Icon icon="bar_chart" />
                    <div className="icon-text-metrics">{this.props.loading ? <LoadingSvg /> : objectsCount}</div>
                  </div>
                  <div style={{ textAlign: 'left' }}>{this.props.context.msg.t('objects')}</div>
                  {!this.props.loading && (
                    <BngPopper
                      style={{ zIndex: 1000000 }}
                      open={this.state.openObjectDropdown}
                      anchorEl={this.state.popperRefObjectDropdown}
                      modifiers={{
                        preventOverflow: {
                          boundariesElement: $pageContentEl,
                        },
                      }}
                    >
                      <div
                        className="bng-dropdown-stats-object"
                        style={{
                          backgroundColor: '#FFFFFF',
                          width: 439,
                          height: 250,
                          borderRadius: '4px 4px 4px 4px',
                          boxShadow: 'inset 0 -1px 0 0 #E7E8EB',
                          display: 'inline-flex',
                          marginTop: -32,
                        }}
                        onMouseLeave={this.closeObjectDropdown}
                      >
                        <div style={{ width: 188 }}>
                          <PieChart
                            className="pie-chart-configure-objects-metrics"
                            lineWidth={30}
                            paddingAngle={2}
                            data={this.renderItemsObjects()}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                textAlign: 'center',
                                width: 142,
                                marginTop: -97,
                              }}
                            >
                              <div className="icon-text-metrics-inside-pie">{objectsCount}</div>
                              <div>{this.props.context.msg.t('objects')}</div>
                            </div>
                          </PieChart>
                        </div>
                        <div className="pie-chart-legend-object-metrics">
                          <div className="label-legend-pie-chart">{this.props.context.msg.t('dashBoard_title')}</div>
                          <div className="label-legend-pie-chart">
                            {this.props.context.msg.t('header_menuItem_maps_analysis')}
                          </div>
                          <div className="label-legend-pie-chart">{this.props.context.msg.t('kpi')}</div>
                          <div className="label-legend-pie-chart">{this.props.context.msg.t('cockpits')}</div>
                          <div className="label-legend-pie-chart">{this.props.context.msg.t('pagetitleMaps')}</div>
                          <div className="container-legend-object-pi-chart">
                            <div style={{ backgroundColor: '#D64949' }} className="user-count-metric">
                              {this.props.projectStats?.countdashboard || 0}
                            </div>
                            <div style={{ backgroundColor: '#575BD8' }} className="user-count-metric">
                              {this.props.projectStats?.countanalysis || 0}
                            </div>
                            <div style={{ backgroundColor: '#E4B03A' }} className="user-count-metric">
                              {this.props.projectStats?.countkpi || 0}
                            </div>
                            <div style={{ backgroundColor: '#269C59' }} className="user-count-metric">
                              {this.props.projectStats?.countcockpit || 0}
                            </div>
                            <div style={{ backgroundColor: '#16879e' }} className="user-count-metric">
                              {this.props.projectStats?.countmap || 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </BngPopper>
                  )}
                </div>
              </td>
              <td>
                <div
                  className="painel-metrics-container MetricUsers"
                  onClick={this.openUserDropdown}
                  onMouseLeave={this.closeUserDropdown}
                >
                  <div style={{ display: 'inline-flex' }}>
                    <Icon icon="group" />
                    <div className="icon-text-metrics">
                      {this.props.loading ? <LoadingSvg /> : this.props.projectStats?.countusers || 0}
                    </div>
                  </div>
                  <div style={{ textAlign: 'left' }}>{this.props.context.msg.t('USERS')}</div>
                  {!this.props.loading && (
                    <BngPopper
                      style={{ zIndex: 1000000 }}
                      open={this.state.openUserDropdown}
                      anchorEl={this.state.popperRefUserDropdown}
                      modifiers={{
                        preventOverflow: {
                          boundariesElement: $pageContentEl,
                        },
                      }}
                    >
                      <div
                        className="bng-dropdown-stats-users"
                        style={{
                          backgroundColor: '#FFFFFF',
                          width: 439,
                          height: 195,
                          borderRadius: '4px 4px 4px 4px',
                          boxShadow: 'inset 0 -1px 0 0 #E7E8EB',
                          display: 'inline-flex',
                          marginTop: -32,
                        }}
                        onMouseLeave={this.closeUserDropdown}
                      >
                        <div style={{ width: 188 }}>
                          <PieChart
                            className="pie-chart-configure-metrics"
                            lineWidth={30}
                            paddingAngle={2}
                            data={this.renderItemsUsers()}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                textAlign: 'center',
                                width: 142,
                                marginTop: -97,
                              }}
                            >
                              <div className="icon-text-metrics-inside-pie">
                                {this.props.projectStats?.countusers || 0}
                              </div>
                              <div>{this.props.context.msg.t('USERS')}</div>
                            </div>
                          </PieChart>
                        </div>
                        <div className="pie-chart-legend-metrics">
                          <div className="label-legend-pie-chart">{this.props.context.msg.t('Administrator')}</div>
                          <div className="label-legend-pie-chart">{this.props.context.msg.t('Expert')}</div>
                          <div className="label-legend-pie-chart">{this.props.context.msg.t('Explorer')}</div>
                          <div className="label-legend-pie-chart">{this.props.context.msg.t('Viewer')}</div>
                          <div className="container-legend-pi-chart">
                            <div style={{ backgroundColor: '#D64949' }} className="user-count-metric">
                              {this.props.projectStats?.countadmin || 0}
                            </div>
                            <div style={{ backgroundColor: '#575BD8' }} className="user-count-metric">
                              {this.props.projectStats?.countexpert || 0}
                            </div>
                            <div style={{ backgroundColor: '#E4B03A' }} className="user-count-metric">
                              {this.props.projectStats?.countexplorer || 0}
                            </div>
                            <div style={{ backgroundColor: '#269C59' }} className="user-count-metric">
                              {this.props.projectStats?.countviewer || 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </BngPopper>
                  )}
                </div>
              </td>
              <td>
                <div
                  className="painel-metrics-container MetricStructures"
                  onClick={this.openQuotaDropdown}
                  onMouseLeave={this.closeQuotaDropdown}
                >
                  <div style={{ display: 'inline-flex' }}>
                    <Icon icon="cloud" />
                    <div className="icon-text-metrics">
                      {this.props.loading ? <LoadingSvg /> : this.props.projectStats?.countdata || 0}
                    </div>
                  </div>
                  <div style={{ textAlign: 'left' }}>{this.props.context.msg.t('structures')}</div>
                  {!this.props.loading && (
                    <BngPopper
                      style={{ zIndex: 1000000 }}
                      open={this.state.openQuotaDropdown}
                      anchorEl={this.state.popperRefQuotaDropdown}
                      modifiers={{
                        preventOverflow: {
                          boundariesElement: $pageContentEl,
                        },
                      }}
                    >
                      <div
                        className="bng-dropdown-stats-quota"
                        style={{
                          backgroundColor: '#FFFFFF',
                          width: 276,
                          height: 178,
                          borderRadius: '4px 4px 4px 4px',
                          boxShadow: 'inset 0 -1px 0 0 #E7E8EB',
                          display: 'inline-flex',
                          marginTop: -32,
                        }}
                        onMouseLeave={this.closeQuotaDropdown}
                      >
                        <div style={{ width: '100%', padding: '20px' }}>
                          <div style={{ height: '60px' }}>
                            <strong>{this.props.context.msg.t('transfers.quota')}</strong>
                            <div style={{ width: '240px', display: 'inline-flex', fontSize: '12px' }}>
                              <BngQuotaViewer projectId={this.props.context.project.id} shouldShowPopover={false} />
                            </div>
                          </div>
                          <div style={{ height: '30px' }}>
                            <strong>{this.props.context.msg.t('analytical.structures')}</strong>{' '}
                            {this.props.projectStats?.analyticalstructure || 0}
                          </div>
                          <div style={{ height: '30px' }}>
                            <strong>{this.props.context.msg.t('data.structure')}:</strong>{' '}
                            {this.props.projectStats?.datastructure || 0}
                          </div>
                          <div style={{ height: '30px' }}>
                            <strong>{this.props.context.msg.t('number.schedules.from.project')}:</strong>{' '}
                            {this.props.projectStats?.activescheduling || 0}
                          </div>
                        </div>
                      </div>
                    </BngPopper>
                  )}
                </div>
              </td>
              {this.props.lastUpdate &&
                <div className="stats-panel last-update">
                  {`${this.props.context.msg.t("stats.panel.last.update")} ${Utils.Date.formatDateTime(this.props.lastUpdate)}`}
                  <BngIconButton
                      icon={'autorenew'}
                      onClick={this.props.fetchProjectStats}
                      className={`${this.props.loading ? 'spin-element' : ''} autorenew`}
                      disabled={this.props.loading}
                      title={this.props.context.msg.t('refresh')}
                  />
                </div>
              }
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ContextEnhancer(StatsPanel);
