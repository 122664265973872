import React from 'react';
import Utils from 'components/Utils';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import { MODALS } from 'components/ui/redux/Actions';
import DeleteFileDialog from 'components/bng/pages/admin/folders/DeleteFileDialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useBimContext from 'components/hooks/useBimContext';

export default function RemoveMenuItem({ clearAccordion = _.noop, remove, name, path }) {
  const { msg } = useBimContext();
  const dispatch = useReduxDispatch();

  const removeItem = () => {
    clearAccordion();
    dispatch(
      MODALS.open(DeleteFileDialog, {
        onConfirm: async () => await remove(),
        nodesToDelete: [{ value: path, text: name, icon: Utils.Object.getObjectIcon(path) }],
        showConfirmCheckbox: true,
        confirmCheckboxLabel: msg.t('delete.files.checkbox.label'),
      })
    );
  };

  return (
    <RightMenuItem
      title={msg.t('remove')}
      icon="delete"
      className="RemoveMenuItem"
      onClick={removeItem}
    />
  );
}
