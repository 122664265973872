import './CancelConfirmationDialog.css';

import React from 'react';

import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import { DefaultDialogActions } from 'components/ui/FormUtils';

export default function CancelConfirmationDialog({ onClose = _.noop, closeModal = _.noop }) {
  const { msg } = useBimContext();

  return (
    <Dialog
      onClose={() => onClose({closeModal})}
      title={msg.t('cancel.confirmation.title')}
      className="CancelConfirmationDialog"
    >
      <Dialog.Body>
        <p className="cancel-confirmation-description">{msg.t('cancel.confirmation.message')}</p>
      </Dialog.Body>
      <Dialog.Footer>
        <DefaultDialogActions
          okLabel="yes"
          cancelLabel="back_button"
          closeModal={() => onClose({closeModal})}
          buttonClass="bng-button cancel-confirmation-dialog-button"
          onClickSaveButton={() => onClose({closeModal, closeProfile: true})}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
