import "./CockpitTabs.css";
import React from 'react';
import Api from "components/Api";
import Icon from "components/ui/common/Icon";
import Utils from "components/Utils";

const onSelectPanel = (event, panel, cockpitId) => {
    event.preventDefault();
    !!window.__CANCEL_RENDER_DASHBOARDITEM_REQUEST ? window.__CANCEL_RENDER_DASHBOARDITEM_REQUEST.cancel() : null;
    Api.executeExp(`#{cockpitViewMB.renderPanelId(${panel.id})}`);
    Utils.History.updateQuery({
        cockpitId,
        panelId: panel.id
    });
};

const CockpitTabs = ({panels, currentPanel, onSelect, enablePainelColors, ...props}) => {
    return (
            <div id="cockpit-header-tabs" className="scroll-wrapper cockpit-header-panel-ul-container with-index on-publisher" style={{position:'relative'}}>
                <div className="cockpit-header-panel-ul-container with-index scroll-content scroll-scrollx_visible" style={{height:'auto',marginBottom:'0px',marginRight:'0px',maxHeight:'37px'}}>
                    <div className="icePnlGrp cockpit-header-panel">
                        <ul className="cockpit-header-panel-ul">
                            {panels.map(panel => {
                                const activePanel = currentPanel === panel.id;
                                const cockpitColor = enablePainelColors && !activePanel ? panel.color + 'dd' : '';
                                return (
                                <li key={panel.id} onClick={e => {
                                    window.__RENDERABLE_PRELOAD_CLEAR_CACHE?.();
                                    onSelect ? onSelect(panel) : onSelectPanel(e, panel, props.id)

                                    const urlParams = new URLSearchParams(window.location.search);
                                    if (urlParams.get('dontCheckStructure')) {
                                        urlParams.delete('dontCheckStructure');
                                        window.location.search = urlParams.toString();
                                    }
                                }} className={activePanel ? 'active' : ''}>
                                    <div className="cpt-btn-item" title={panel.name}>
                                        <a href={`#panel-${panel.id}`} className="iceCmdLnk">
                                            <Icon className={'icon-cockpit-item'} icon={panel.icon} />
                                            <nobr>{panel.name}</nobr>
                                        </a>
                                    </div>
                                    <div className="cpt-btn-item-bar-marker" style={{backgroundColor: cockpitColor}}></div>
                                </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
    );
};

CockpitTabs.propTypes = {};
CockpitTabs.defaultProps = {};

export default CockpitTabs;
