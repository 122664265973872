// css imported on CommonCssImports.js
import React from 'react';

export function BngPanel({ className = '', children, whiteBg = false, ...props }) {
  return (
    <div className={`BngPanel ${whiteBg ? 'WhiteBg' : ''} ${className}`} {...props}>
      {children}
    </div>
  );
}

export function BngPanelHeader({ className = '', title = null, icon = null, children, ...props }) {
  return (
    <div className={`Panel Header ${className}`} {...props}>
      {icon && <span className="Icon">{icon}</span>}
      {title && <span className="Title">{title}</span>}
      {children}
    </div>
  );
}

export function BngPanelFooter({ className = '', children, ...props }) {
  return (
    <div className={`Panel Footer ${className}`} {...props}>
      {children}
    </div>
  );
}
