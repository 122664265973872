import styles from './BngButton.module.css';

import React from 'react';
import Icon from 'components/ui/common/Icon';

export const Size = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

export const Type = {
  primary: 'primary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
};

export const Variant = {
  default: 'default',
  textButton: 'textButton',
  outline: 'outline',
};

function BngButton(
  {
    className = '',
    icon = '',
    btnType = 'button',
    type = Type.primary,
    variant = Variant.default,
    disabled = false,
    size = Size.md,
    hover = true,
    loading = false,
    children,
    ...props
  },
  ref,
) {
  return (
    <button
      type={btnType}
      className={`BngButton ${styles.main} ${variant !== 'default' ? styles[variant] : ''} ${styles[size]} ${
        styles[type]
      } ${hover ? styles.hover : ''} ${className} ${disabled ? styles.disabled : ''}`}
      ref={ref}
      disabled={disabled || loading}
      {...props}
    >
      {icon && <Icon icon={icon} />}
      {children}
    </button>
  );
}

export default React.forwardRef(BngButton);
