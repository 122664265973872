import React, { useMemo, useState } from 'react';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import OpConfirmation from 'components/ui/OpConfirmation';
import ContainerMenu from 'components/ui/dashboard/components/ContainerMenu';
import { RIGHT_MENU_CLOSE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import bimEventBus from 'BimEventBus';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { CONTAINER_EDIT_MENU_EVENT } from 'components/bng/pages/dashboard/menus/ContainerEditMenu';
import useTranslation from 'components/hooks/useTranslation';

function ContainerMenuEditor({ style = {}, item = {}, currentBreakpoint = {}, buttonStyle = {} }) {
  const { t } = useTranslation();

  const {
    addChange,
    editContainer: openContainerEditor,
    moveItem,
    dash,
  } = useDashboardPageCtx.cached((state) => _.pick(state, ['addChange', 'editContainer', 'moveItem', 'dash']));

  const [loading, setLoading] = useState(false);

  const popperContainer = useMemo(() => document.querySelector('.page-content'), []);

  const ungroupElements = (event) => {
    return removeContainer(event, false);
  };

  const removeContainer = async (event, removeChildren = true) => {
    if (loading) return;

    const executeRemovalFn = async () => {
      setLoading(true);
      try {
        await addChange({
          type: 'REMOVE_ITEM',
          data: {
            id: item.id,
            removeChildren,
            breakpoint: currentBreakpoint.effectiveBreakpoint,
          },
        });
      } catch (e) {
        console.error('Error on removeContainer()', e);
        UiMsg.ajaxError(null, e);
      } finally {
        setLoading(false);
      }
    };

    if (removeChildren) {
      OpConfirmation({
        title: t('attention'),
        message: t('container.remove.message'),
        onConfirm: () => {
          executeRemovalFn();
        },
      });
    } else {
      await executeRemovalFn();
    }
  };

  return (
    <BngDropdown
      icon="more_horiz"
      popperOpts={{ placement: 'bottom-start' }}
      boundariesElement={popperContainer}
      className="icon-dropdown"
      popperClassName="ContainerMenuPopper"
      btnIconProps={{ iconProps: { className: 'DashboardItemOptsButton' }, style: buttonStyle }}
      options={[
        { label: t('container.config'), isSeparator: true },

        ...(style.allowTheme
          ? [
              {
                icon: item.highlight ? 'star' : 'star_border',
                label: t(item.highlight ? 'remove.highlight.dashboard.item' : 'highlight.dashboard.item.container'),
                onClick: async () => {
                  setLoading(true);
                  try {
                    await addChange({ type: 'TOGGLE_ITEM_HIGHLIGHT', data: { id: item.id } });
                  } catch (e) {
                    console.error('Error on itemHighlight()', e);
                    UiMsg.ajaxError(null, e);
                  } finally {
                    setLoading(false);
                  }
                },
              },
            ]
          : []),

        {
          icon: 'style',
          label: t('configure.container.style'),
          onClick: () => {
            let containerStyle = dash.dashboard.style.containerStyle;
            if (item.additionalProps.style) {
              containerStyle = item.additionalProps.style;
            }
            bimEventBus.emit(CONTAINER_EDIT_MENU_EVENT, {
              id: item.id,
              container: {
                ...item.additionalProps,
                style: containerStyle,
              },
            });
          },
        },

        {
          icon: 'web',
          label: t('edit.container'),
          onClick: async (event) => {
            await ungroupElements(event);
            bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU);
            openContainerEditor({
              item,
            });
          },
        },

        {
          icon: 'icon-bim-move',
          label: t('select.dashboard.container'),
          onClick: async () => {
            bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU);
            moveItem({ itemId: item.id });
          },
        },

        { icon: 'border_clear', label: t('ungroup.items'), onClick: ungroupElements },

        { icon: 'close', label: t('remove'), onClick: (event) => removeContainer(event) },

        ...(currentBreakpoint.viewBreakpoint !== 'DESKTOP'
          ? [
              {
                icon: 'remove_red_eye',
                label: t(item.availableOnMobile ? 'hide.item.on.mobile' : 'show.item.on.mobile'),
                onClick: async () => {
                  try {
                    await addChange({
                      type: 'ITEM_AVAILABLE_ON_MOBILE',
                      data: {
                        id: item.id,
                        enabled: !item.availableOnMobile,
                      },
                    });
                  } catch (e) {
                    console.error('Error on hideItemOnMobile()', e);
                    UiMsg.error(null, e);
                  }
                },
              },
            ]
          : []),
      ]}
    />
  );
}

export default function ContainerMenuEditorWrapper(props) {
  return <ContainerMenu containerMenuComponent={ContainerMenuEditor} {...props} />;
}
