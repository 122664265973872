import './ManageTraceabilityDialog.css';

import React, { useEffect, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import BngSwitch from 'components/bng/form/BngSwitch';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import AddonType from 'components/bng/accounts/AddonType';
import { LabelHelper } from 'components/ui/dashboard/DashLayoutAccordion';

export default function ManageTraceabilityDialog({ accountId, closeModal }) {
  const context = useBimContext();
  const [addonInfo, setAddonInfo] = useState();

  useEffect(() => {
    (async () => {
      try {
        const addonInfo = await Api.Account.findAccountAddonInfo(accountId, AddonType.TRACEABILITY.key);
        setAddonInfo(addonInfo);
      } catch (e) {
        console.error('Error on findAccountAddonInfo()', e);
        UiMsg.ajaxError(null, e);
        closeModal();
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      await Api.Account.saveAddon(accountId, addonInfo);
      UiMsg.ok(context.msg.t('InputUserInfoDialog.finish.msg'));
      closeModal();
    } catch (e) {
      console.error('Error on saveAddon()', { addonInfo }, e);
      UiMsg.ajaxError(null, e);
    }
  };

  const showWatermark = addonInfo?.props?.config?.showWatermark ?? false;
  const removeBackgroundImage = addonInfo?.props?.config?.removeBackgroundImage ?? false;

  return (
    <Dialog
      contentFullWidth={true}
      className="ManageTraceabilityDialog large"
      title={context.msg.t('addon.traceability.name')}
      onClose={closeModal}
      newDialogLayout
    >
      <Dialog.Body>
        <BngSwitch
          title={context.msg.t('addon.traceability.show.watermark')}
          id="accountAddonShowWatermark"
          onChange={() => {
            const copy = _.cloneDeep(addonInfo);
            copy.props.config.showWatermark = !showWatermark;
            setAddonInfo(copy);
          }}
          checked={showWatermark}
        />
        <div className="flex-center-items">
          <BngSwitch
            id="accountAddonRemoveBackgroundImage"
            onChange={() => {
              const copy = _.cloneDeep(addonInfo);
              copy.props.config.removeBackgroundImage = !removeBackgroundImage;
              setAddonInfo(copy);
            }}
            checked={removeBackgroundImage}
          />
          <LabelHelper
            helpLabel={context.msg.t('addon.traceability.remove.background.image.hint')}
            label={context.msg.t('addon.traceability.remove.background.image')}
          />
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <DefaultDialogActions closeModal={closeModal} onClickSaveButton={handleSubmit} contentFullWidth />
      </Dialog.Footer>
    </Dialog>
  );
}
