import React from 'react';

import image from 'components/bng/pages/newAnalysis/assets/BngAdvancedModeAlert.svg';
import BaseErrorPage from 'components/bng/pages/errors/BaseErrorPage';
import useTranslation from 'components/hooks/useTranslation';

export default function ForbiddenErrorPage() {
  const { t } = useTranslation();
  return (
    <BaseErrorPage
      className="ForbiddenErrorPage"
      image={image}
      title={t('forbidden.access.page.title')}
      description={`${t('forbidden.access.page.message')}.`}
    />
  );
}
