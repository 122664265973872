// css imported on CommonCssImports.js
import React from 'react';
import {Column, Table, AutoSizer} from 'imports/ReactVirtualizedImport';
import ContextEnhancer from "components/ContextEnhancer";
import PropTypes from "prop-types";
import Icon from "components/ui/common/Icon";

class Legend extends React.Component {

    static renderCircle = function ({color}) {
        return (
            <svg height="20" width="20">
                <circle cx="10" cy="10" r="5" fill={color} fillOpacity="0.7"/>
            </svg>
        );
    };

    static propTypes = {
        randomId: PropTypes.string,
        isPresentation: PropTypes.bool,
        exportView: PropTypes.bool,
    };

    static defaultProps = {
        description: '',
        ploted: {},
        legend: [],
        onToggle: _.noop,
        onUpdate: _.noop,
        stats: 0,
        useCircles: false,
        isPresentation: false,
        exportView: false,
    };

    state = {
        open: this.props.showCaption,
    };

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.showCaption !== prevProps.showCaption) {
            this.toggleLegend();
        }
        this.props.onUpdate();
    }

    toggleLegend = (e) => {
        this.setState({open: !this.state.open})
    };

    render() {

        let {ploted, description, legend, onToggle, stats, useCircles} = this.props;
        let totalVisible = 0;
        for (let key in ploted) {
            totalVisible += _.find(legend, {icon: key})?.selected ? ploted[key].length : 0;
        }

        const isExporter = application.utils.isExporter();

        return (
            <div className={`map-legend ${isExporter ? 'export' : ''} ${this.state.open ? 'opened' : 'closed'}`}>
                <table onClick={this.toggleLegend}>
                    <tbody>
                    <tr>
                        <td colSpan={isExporter ? 4 : 5} className="tdLegendMapHeader">
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="legendArrow">
                                            <Icon
                                                icon={isExporter ? 'format_list_bulleted' : this.state.open ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}/>
                                            <span
                                                className="legendButtonLabel">{this.props.context.msg.t(isExporter ? 'map.legend.export' : this.state.open ? 'map.legend.hide' : 'map.legend.show')}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <span style={{
                                            float: 'left',
                                            width: 'auto',
                                            fontWeight: 'bold'
                                        }}>{description}</span>
                                    </td>
                                    <td>
                                        <span className="legendMapTotal">
                                            {this.props.context.msg.t('map.legend.showing')} {' '}
                                            <i id="total-displayed-markers">{totalVisible}</i> {' '}
                                            {this.props.context.msg.t('map.legend.showing.from')} {stats.total} {' '} {this.props.context.msg.t('map.legend.showing.points')}
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="legendMapFilterPoints">
                    <AutoSizer disableHeight={true}>
                        {({width}) => (
                            <Table disableHeader={true}
                                   gridstyle={{}}
                                   rowCount={legend.length}
                                   rowGetter={({index}) => legend[index]}
                                   rowHeight={25}
                                   width={width}
                                   onRowClick={({rowData, index}) => onToggle(rowData, index)}
                                   rowClassName="tdLegendMap"
                                   height={legend.length > 3 ? 100 : legend.length * 25}>

                                {!isExporter &&
                                    <Column dataKey="selected"
                                            width={18}
                                            cellRenderer={({cellData, rowData}) => (
                                                <Icon icon="remove_red_eye"
                                                      className={`view-in-map view-in-map-${cellData ? 'enabled' : 'disabled'}`}/>
                                            )}
                                    />
                                }

                                <Column dataKey="icon"
                                        width={22}
                                        cellRenderer={({cellData}) => {
                                            if (useCircles) {
                                                return Legend.renderCircle({color: cellData});
                                            }
                                            return (
                                                <img src={cellData} alt={cellData}/>
                                            );
                                        }}
                                />

                                <Column dataKey="description"
                                        width={100}
                                        flexGrow={2}
                                        cellRenderer={({cellData}) => cellData}
                                />

                                <Column dataKey="icon"
                                        className="text-right"
                                        width={75}
                                        cellRenderer={({cellData}) => stats.icon[cellData] || 0}
                                />

                                <Column dataKey="markers"
                                        className="text-right"
                                        width={75}
                                        style={{paddingRight: '10px'}}
                                        cellRenderer={({cellData}) => {
                                            return (stats.total ? ((cellData / stats.total) * 100).toFixed(2) : '-') + '%';
                                        }}
                                />

                            </Table>
                        )}
                    </AutoSizer>
                </div>
            </div>
        );
    }
}

export default ContextEnhancer(Legend);