import "./GroupsDialog.css";
import React, {useEffect, useState} from 'react';
import Dialog from "components/ui/Dialog";
import Api from 'components/Api';
import UiMsg from "components/ui/UiMsg";

import BngSearch from 'components/bng/ui/BngSearch';
import {BngIconButton} from "components/bng/ui/BngIconButton";
import BngInputColor from 'components/bng/form/BngInputColor';
import OpConfirmation from "components/ui/OpConfirmation";
import {BngDialogActionButton} from "components/ui/Button";
import useBimContext from "components/hooks/useBimContext";


export const isValidGroupName = (str) => {
    return str?.length !== 0
        && '' !== str?.replace(/^\s+/, '').replace(/\s+$/, '') // true if string is NOT (!) white-space only
        && !/\p{Extended_Pictographic}/u.test(str); // true if string doesn't (!) contain any emoji
}

export default function GroupsDialog({
                                         closeModal = _.noop,
                                         projectId = null,
                                         fetchData = _.noop
                                     }) {
    const context = useBimContext();
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [editing, setEditing] = useState(null);

    const visibleGroups = searchTerm
        ? groups.filter((group) => group.name?.toLowerCase().includes(searchTerm.toLowerCase()))
        : groups;

    useEffect(() => {
        setLoading(true);
        fetchGroups();
        setLoading(false);
    }, [])

    const saveGroup = async (group) => {
        try {
            await Api.Group.saveGroup(group);
            await fetchGroups();

            UiMsg.ok(context.msg.t('fem.save_success', context.msg.t('tag')));
        } catch (error) {
            UiMsg.error(null, error.response.data.message);
        }
    }

    const fetchGroups = async () => {
        try {
            const allGroups = await Api.Group.findForProject(projectId);
            setGroups(allGroups.sort((a, b) => b[name] - a[name]));
        } catch (e) {
            UiMsg.error(null, e);
        }

    }

    const createGroup = async () => {
        if (editing) {
            UiMsg.warn(context.msg.t('finish.editing.tag'));
        } else {
            try {
                setLoading(true);
                const newGroup = await Api.Group.createEmptyGroup(projectId);
                await onEdit(newGroup);
                groups.unshift(newGroup);
            } catch (error) {
                UiMsg.error(error);
            } finally {
                setLoading(false);
            }
        }
    }

    const onDelete = (group) => {
        OpConfirmation({
            title: context.msg.t('attention'),
            message: context.msg.t('tag.delete.confirmation'),
            onConfirm: () => onConfirmDelete(group),
            msg: context.msg
        });
    }

    const onConfirmDelete = async (group) => {
        try {
            setLoading(true);
            group.id === null
                ? groups.splice(groups.indexOf(group), 1)
                : await Api.Group.removeGroup(group.id);

            setEditing(null);
            UiMsg.ok(context.msg.t('fem.remove_success', context.msg.t('tag')));
            await fetchGroups()
        } catch (error) {
            UiMsg.error(null, error);
        } finally {
            setLoading(false);
        }
    }

    const onEdit = async (group) => {
        if (!checkGroupName()) {
            if (editing !== null) {
                group.id === editing.id && !_.isEqual(group, editing)
                    ? await saveGroup(editing)
                    : await onCancelEdit();
            }

            (group.id === editing?.id)
                ? setEditing(null)
                : setEditing(_.cloneDeep(group))
        }
    }

    const onCancelEdit = async () => {
        if (editing.id !== null) {
            setEditing(null);
        } else {
            await onConfirmDelete(editing);
        }
    }

    const checkGroupName = () => {
        if (editing !== null && (editing.name === null || editing.name === '')) {
            UiMsg.warn(context.msg.t('tag.name.cant.be.empty'));
            return true;
        } else if (groups.find(group => group.name === editing?.name && group.id !== editing?.id)) {
            UiMsg.warn(context.msg.t('name.already.in.use.choose.another.one'));
            return true;
        } else if (!isValidGroupName(editing?.name)) { // no emojis or whitespace-only strings allowed
            UiMsg.warn(context.msg.t('enter.valid.name'));
            return true;
        }
        return false;
    }

    const handleColorChange = (color) => {
        setEditing({...editing, color});
    }

    const handleNameChange = (event) => {
        editing.name = event.target.value;
    }

    const renderGroupName = (group, isEditing) => {
        if (isEditing) {
            return (
                <input type="text"
                       maxLength={25}
                       autoFocus
                       className="group-name-input"
                       defaultValue={editing.name}
                       onKeyPress={async (e) => {
                           if (e.key === 'Enter') {
                               e.preventDefault()
                               await onEdit(group);
                           }
                       }}
                       onChange={(event) => handleNameChange(event)}/>
            )
        } else {
            return (group.name);
        }

    }

    return (
        <Dialog className="GroupsDialog large"
                loading={loading}
                title={context.msg.t('tags')}
                onClose={async () => {
                    await fetchData();
                    closeModal();
                }}
                contentFullWidth={true}>
            <div className="groups-header">
                <BngDialogActionButton className="ml-4"
                                       icon="add_circle"
                                       onClick={createGroup}>
                    {context.msg.t('new.tag')}
                </BngDialogActionButton>
                <BngSearch alwaysOpen={false}
                           className="group-search"
                           onChange={setSearchTerm}
                />
            </div>
            <div className="scroll-bar-table-wrapper">
                <div className="scroll-bar-groups-table">
                    <table className="table table-condensed table-border table-hover">
                        <thead className="groups-table-header">
                        <tr>
                            <th className="groups-table-title-color">{context.msg.t('color')}</th>
                            <th className="groups-table-title-name">{context.msg.t('name')}</th>
                            <th className="groups-table-title-actions">{context.msg.t('actions')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {visibleGroups.map((group, index) => {

                            const isEditing = group.id === editing?.id;
                            return (
                                <tr className="grouplist-table-tr" key={`grouplist-item-group-${group.id}`}>
                                    <td className="grouplist-table-td-color">
                                        <BngInputColor size={'sm'}
                                                       title={isEditing ? '' : context.msg.t('color.edit.tooltip')}
                                                       className='group-color-picker'
                                                       addTransparentOptions={false}
                                                       field={{
                                                           onChange: (event) => handleColorChange(event),
                                                           value: isEditing ? editing.color : group.color
                                                       }}
                                                       form={{setFieldValue: (name, value) => handleColorChange(value, editing)}}
                                                       enablePicker={false}
                                                       disabled={!isEditing}
                                                       bannedOptions={context.bannedGroupColors}
                                        />
                                    </td>
                                    <td className="grouplist-table-td-name">
                                        {renderGroupName(group, isEditing)}
                                    </td>
                                    <td className="grouplist-table-td-actions">
                                        <BngIconButton icon={isEditing ? 'save' : 'edit'}
                                                       className="icon-group-edit"
                                                       onClick={() => onEdit(group)}/>
                                        <BngIconButton icon={isEditing ? 'close' : 'delete'}
                                                       className="icon-group-remove"
                                                       onClick={() => isEditing ? onCancelEdit() : onDelete(group)}/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Dialog>
    )
}