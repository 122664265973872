import styles from './SecurityCheckupUpdatePhone.module.css';

import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';

import useBimContext from 'components/hooks/useBimContext';
import CheckupHeader from 'components/bng/securityCheckup/components/CheckupHeader';
import BngField from 'components/bng/form/BngField';
import BngPhoneInput from 'components/bng/form/BngPhoneInput';
import BngForm from 'components/bng/form/BngForm';
import bngYup from 'components/bng/form/yup/BngYup';
import CheckupConfirmationButtons from 'components/bng/securityCheckup/components/CheckupConfirmationButtons';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import ValidatePhoneNumberDialog from 'components/ui/mobile-message/ValidatePhoneNumberDialog';
import { MODALS } from 'components/ui/redux/Actions';

const CheckupUpdatePhoneSchema = bngYup((yup) => {
  return yup.object().shape({
    phone: yup.string().phone().required(),
    name: yup.string(),
  });
});

export default function SecurityCheckupUpdatePhone({
  closeModal = _.noop,
  changeToNextStep = _.noop,
  addCompletedStep = _.noop,
  setEnableNextStep = _.noop,
}) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEnableNextStep(false);
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await Api.User.updateUserMessaging({
        phone: values.phone,
        allowMobileMessaging: true,
        projectName: context.project.name,
      });
      await Api.UserParam.saveUserKey({
        key: Api.UserParam.Keys.SecurityCheckup.UpdatedPhone,
        value: true,
        projectId: context.project.id,
      });

      // Phone is already updated on backend after this step
      if (context.user) {
        context.user.phone = values.phone;
      }
      UiMsg.ok(context.msg.t('user.dialog.update.success'));
      addCompletedStep();
      changeToNextStep(true);
    } catch (e) {
      console.error('Error onSubmit()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const beforeOnSubmit = (values, actions) => {
    if (context.user?.phone !== values.phone) {
      return new Promise(async (resolve, reject) => {
        window.ReduxStore.dispatch(
          MODALS.open(ValidatePhoneNumberDialog, {
            phone: values.phone,
            userId: context.user.id,
            closeModal: async (valid = false) => {
              try {
                closeModal();
                if (valid) {
                  await onSubmit(values, actions);
                }
                resolve();
              } catch (e) {
                console.error('Error on beforeOnSubmit()', e);
                reject(e);
              }
            },
          })
        );
      });
    }

    return onSubmit(values, actions);
  };

  return (
    <UiBlocker className="SecurityCheckupUpdatePhone" block={loading}>
      <CheckupHeader
        title={context.msg.t('checkup.updatePhone.title')}
        description={context.msg.t(`checkup.updatePhone.desc`)}
      />
      <Formik
        initialValues={{ phone: context.user?.phone ?? '', name: context.user?.displayName ?? '' }}
        validationSchema={CheckupUpdatePhoneSchema}
        onSubmit={beforeOnSubmit}
      >
        {() => {
          return (
            <BngForm>
              <Field
                name={'name'}
                component={BngField}
                rootClassName={`${styles.nameFieldContainer}`}
                labelClassName={`${styles.fieldLabel}`}
                label={context.msg.t('name')}
                disabled
              />
              <Field
                name={'phone'}
                component={BngField}
                labelClassName={`${styles.fieldLabel}`}
                label={context.msg.t('phone')}
                inputComponent={BngPhoneInput}
                className={`${styles.phoneInput}`}
              />
              <CheckupConfirmationButtons
                className={`${styles.buttons}`}
                onCancel={() => changeToNextStep(true)}
                confirmLabel={context.msg.t('update.phone')}
              />
            </BngForm>
          );
        }}
      </Formik>
    </UiBlocker>
  );
}
