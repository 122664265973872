import './TicketViewDialog.css';

import React from 'react';
import PropTypes from 'prop-types';
import {Field, Form, Formik} from "formik";

import Dialog from "components/ui/Dialog";
import ContextEnhancer from "components/ContextEnhancer";
import {ScrollContainer} from "components/ui/ScrollContainer";
import Button from "components/ui/Button";
import Api from "components/Api";
import bngYup from "components/bng/form/yup/BngYup";
import FormikListener from "components/bng/form/formik/FormikListener";
import BngTextArea from "components/bng/form/BngTextArea";

const CommentSchema = bngYup(yup => yup.object().shape({comment: yup.string().default('').min(1)}));

class TicketViewDialog extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        mobile: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        ticketData: {},
        mobile: false,
    };

    state = {
        ticketResponse: {
            ticket: this.props.ticketData.ticket,
            comments: [],
        },
        loading: true,
        blockCommentForm: false,
    };

    commentInitialValues = CommentSchema.default();


    setDateValues = (comments) => {
        comments.forEach(c => {
            if (c.comment.pdata_hora) {
                c.comment.data_hora = moment(c.comment.pdata_hora);
            }
        });
    }


    async componentDidMount() {
        try {
            const idChamado = this.props.ticketData.ticket.idchamado;
            const ticketResponse = await Api.Bng.findSupportTicket(idChamado);
            this.setDateValues(ticketResponse.comments)
            this.setState({
                ticketResponse: ticketResponse,
                loading: false
            });
        } catch (e) {
            console.error(e);
        }

    }

    submitComment = async (values, actions) => {
        try {
            const ticketResponse = await Api.Bng.addSupportTicketComment(
                this.props.ticketData.ticket.idchamado,
                values.comment
            );
            this.setDateValues(ticketResponse.comments);
            this.setState({
                ticketResponse: ticketResponse
            });
            actions.resetForm({values: this.commentInitialValues});
        } catch (e) {
            console.error(e);
        } finally {
            actions.setSubmitting(false);
        }
    };

    commentFormListener = (next, current) => {
        if (next.isSubmitting !== current.isSubmitting) {
            this.setState({blockCommentForm: next.isSubmitting});
        }
    };

    render() {
        const {ticketData} = this.props;
        const {ticket, comments} = this.state.ticketResponse;
        const isClosed = ticket.descsituacao === "Finalizado";
        return (
            <Dialog title={this.props.context.msg.t('support.dialog.call.title')}
                    className={`TicketViewDialog ${this.props.mobile ? 'mobile' : 'large'}`}
                    onClose={this.props.closeModal}
                    loading={this.state.loading || this.state.blockCommentForm}
            >
                <ScrollContainer className="details">
                    <div className="page-header position-relative">
                        <div
                            style={{
                                color: '#2679b5',
                                fontSize: '20px',
                                fontWeight: 'lighter',
                                margin: '0 8px',
                                padding: '0',
                            }}>
                            {ticket.titulo}
                        </div>
                        <div style={{color: '#8089a0', fontSize: '14px', fontWeight: 'normal', margin: '6px'}}>
                            <i className="icon-double-angle-right"></i>
                            {' '}
                            {this.props.context.msg.t('apps_id')} {ticket.protocolo}
                            {' '}
                            |
                            {' '}
                            {this.props.context.msg.t('type')} {this.props.context.msg.t(`tomticket.category.${_.camelCase(ticket.categoria)}`)}
                            {' '}
                            |
                            {' '}
                            <div style={{fontSize: '12px', fontWeight: 'normal', textAlign: 'center'}}
                                 className={`label label-${ticketData.priorityColor}`}>{this.props.context.msg.t('priority')} {ticketData.priorityDescription}</div>
                            {' '}
                            |
                            {' '}
                            <div style={{fontSize: '12px', fontWeight: 'normal', textAlign: 'center'}}
                                 className={`label label-${ticketData.statusColor}`}>{this.props.context.msg.t('status')} {this.props.context.msg.t(`tomticket.status.${_.camelCase(ticketData.statusDescription)}`)}</div>
                        </div>
                    </div>

                    <div
                        style={{
                            margin: '20px',
                            fontSize: '14px',
                            lineHeight: '1.5',
                            wordBreak: 'break-word',
                            textAlign: 'justify'
                        }} dangerouslySetInnerHTML={{__html: ticket.mensagem}}>
                    </div>

                </ScrollContainer>


                <div>
                    <div className="widget-header"
                         style={{
                             background: '#ffffff',
                             border: 'none',
                             borderBottom: '1px solid #ededed',
                             marginBottom: '20px',
                             paddingLeft: '0px'
                         }}>
                        <h4 className="lighter smaller">
                            <i className="icon-rss orange"></i>
                            {this.props.context.msg.t('comments')}
                        </h4>
                    </div>
                    <style type="text/css"
                           dangerouslySetInnerHTML={{__html: `.TicketViewDialog .ScrollContainer.historic { height: ${160}px; } `}}
                    />
                    <ScrollContainer className="historic">
                        {comments.map(({comment, avatarUrl, userCaption, parsedMessage}, idx) => (
                            <div className="itemdiv commentdiv" key={idx}>
                                <div className="user" style={{top: '0'}}>
                                    <img src={`${Api.baseUrl()}${avatarUrl}`}
                                         style={{
                                             width: '50px',
                                             height: '50px',
                                             maxWidth: '50px',
                                             border: '3px solid #C9D6E5',
                                             borderRadius: '100%'
                                         }}
                                    />
                                </div>

                                <div className="body" style={{marginLeft: '70px'}}>
                                    <div className="name">
                                        <a href="#">{userCaption}</a>
                                    </div>

                                    <div className="time">
                                        <i className="icon-time"></i>
                                        {' '}
                                        <span className="green">
                                                    {comment.data_hora.fromNow()}
                                                </span>
                                    </div>

                                    <div className="text">
                                        <i className="icon-quote-left"></i>
                                        <span dangerouslySetInnerHTML={{__html: comment.mensagem}}></span>

                                        <i className="icon-quote-right"
                                           style={{color: '#dce3ed', marginLeft: '4px'}}></i>
                                    </div>

                                </div>

                            </div>
                        ))}
                    </ScrollContainer>
                </div>

                {!isClosed &&
                    <div className="comment-form-container">
                        <Formik initialValues={this.commentInitialValues}
                                validationSchema={CommentSchema}
                                onSubmit={this.submitComment}>
                            <Form>
                                <FormikListener onChange={this.commentFormListener}/>
                                <div>
                                    <Field name="comment"
                                           component={BngTextArea}
                                           rows="5"
                                           placeholder={`${this.props.context.msg.t('write.an.comment')}...`}
                                    />
                                </div>
                                <div>
                                    <Button icon="icon-share-alt"
                                            className="btn btn-small btn-info pull-left addComment"
                                            type="submit">
                                        {this.props.context.msg.t('send')}
                                    </Button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                }
            </Dialog>
        );
    }
}

export default ContextEnhancer(TicketViewDialog);