import React, {Component} from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";

class FloatingNotificationAlert extends Component {


    static propTypes = {
        notificationCount: PropTypes.number.isRequired
    };

    static defaultProps = {
        notificationCount: 0
    };

    state = {
        dismissed: false,
        positionmenu: ''
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.alterPosition();
    }



    dismissNotification = () => this.setState({dismissed: true});



    alterPosition = () => {
        const cockpit = this.props.context.cockpitEnabled;
        const accessingproject = this.props.context.isAccessingProject;
        if((cockpit && accessingproject) || (accessingproject===false && cockpit===false) || (accessingproject===false && cockpit===true)){
            this.setState({positionmenu:"notification-floating-alert-cockpit-enable"});
        }else if(this.props.context.cockpitEnabled === false && this.props.context.isAccessingProject){
            this.setState({positionmenu:"notification-floating-alert"});
        }

    };

    render() {
        if (this.state.dismissed || this.props.notificationCount === 0) {
            return null;
        }
        return (
            <div className={this.state.positionmenu}>
                <div className="text-center no-hover">
                    <div
                        className="alert alert-warning pull-right dropdown-navbar dropdown-menu dropdown-caret dropdown-close">
                        <a className="close" onClick={this.dismissNotification}>
                            <i className="icon icon-remove"/>
                        </a>
                        {this.props.context.msg.t('note.you.have')}
                        <span className="message">
                            <strong>
                                {`${this.props.notificationCount} ${this.props.context.msg.t('new.notifications')}`}
                            </strong>
                        </span>
                        {this.props.context.msg.t('not.read')}.
                    </div>
                </div>
            </div>
        );
    }

}

export default ContextEnhancer(FloatingNotificationAlert);