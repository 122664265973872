import { Axios } from 'commonUtils';

const CACHE = {
  categories: null,
  status: null,
};

class FeatureProposalApi {
  findCategories = async () => {
    if (!CACHE.categories) {
      const { data } = await Axios.get(`/spr/featureProposals/categories`);
      CACHE.categories = data;
    }
    return _.cloneDeep(CACHE.categories);
  };

  findStatus = async () => {
    if (!CACHE.status) {
      const { data } = await Axios.get(`/spr/featureProposals/status`);
      CACHE.status = data;
    }
    return _.cloneDeep(CACHE.status);
  };

  findAll = async (params = {}) => {
    const { data } = await Axios.get(`/spr/featureProposals`, {
      params,
    });

    data.forEach((feature) => {
      feature.createdAt = feature.createdAt ? new Date(feature.createdAt) : feature.createdAt;
      feature.editableDate = feature.editableDate?.split('T')[0] ?? feature.editableDate;
    });

    return data;
  };

  save = async (feature) => {
    await Axios.post(`/spr/featureProposals`, feature);
  };

  duplicate = async (id) => {
    return await Axios.postData(`/spr/featureProposals/${id}/duplicate`);
  };

  delete = async (id) => {
    await Axios.delete(`/spr/featureProposals/${id}`);
  };

  submitVote = async (featureId, upvote) => {
    await Axios.post(`/spr/featureProposals/${featureId}/vote`, {
      upvote,
    });
  };

  removeVote = async (featureId) => {
    await Axios.delete(`/spr/featureProposals/${featureId}/vote`);
  };

  fetchNotVoted = async () => {
    const data = await Axios.getData(`/spr/featureProposals/notVoted`);

    data.forEach((feature) => {
      feature.createdAt = feature.createdAt ? new Date(feature.createdAt) : feature.createdAt;
      feature.editableDate = feature.editableDate?.split('T')[0] ?? feature.editableDate;
    });

    return data;
  };
}

export default FeatureProposalApi;
