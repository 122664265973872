import styles from './FeatureProposalOption.module.css';

import React, { useState } from 'react';

import BngIconButton from 'components/bng/ui/BngIconButton';
import useBimContext from 'components/hooks/useBimContext';
import BngTag from 'components/bng/ui/BngTag';
import UiMsg from 'components/ui/UiMsg';
import UiBlocker from 'components/bng/ui/UiBlocker';
import Api from 'components/Api';

export default function FeatureProposalOption({
  feature,
  fetchVotes,
  categoryOpts = [],
  statusOpts = [],
  showVotes = true,
  ideaBoxFeature = false,
}) {
  const { msg } = useBimContext();

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitVote = async (upvote) => {
    try {
      setLoading(true);

      await Api.FeatureProposal.submitVote(feature.id, upvote);
      await fetchVotes({ showLoading: false, sort: false });
    } catch (e) {
      console.error('Error while trying to vote in a feature', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const removeVote = async () => {
    try {
      setLoading(true);

      await Api.FeatureProposal.removeVote(feature.id);
      await fetchVotes({ showLoading: false, sort: false });
    } catch (e) {
      console.error('Error while trying to remove a vote in a feature', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const hasUserVote = !_.isNil(feature.userVote);
  const isUpvote = hasUserVote && feature.userVote === true;
  const isDownVote = hasUserVote && feature.userVote === false;

  const categoryLabel = categoryOpts.find((cat) => cat.value === feature.category)?.label ?? msg.t(feature.category);
  const statusLabel = statusOpts.find((stat) => stat.value === feature.status)?.label ?? msg.t(feature.status);
  return (
    <UiBlocker
      block={loading}
      className={`FeatureProposalOption ${styles.featureWrapper} ${expanded && styles.expanded}`}
    >
      <div className={styles.featureContainer}>
        <div className={styles.headerContainer}>
          {showVotes && (
            <div className={styles.votingCounterContainer}>
              {ideaBoxFeature ? (
                <div className={styles.votingCounterContainer}>
                  <BngIconButton
                    icon={'thumb_up'}
                    className={`${styles.thumbsUp} ${isUpvote && styles.upvote}`}
                    onClick={loading ? undefined : () => (isUpvote ? removeVote() : submitVote(true))}
                  />
                  <span className={`${isUpvote ? styles.upvote : isDownVote ? styles.downvote : ''}`}>
                    {feature.votesCount}
                  </span>
                </div>
              ) : (
                <>
                  <BngIconButton
                    className={`${isUpvote && styles.upvoteArrow}`}
                    icon={'arrow_upward'}
                    onClick={loading ? undefined : () => (isUpvote ? removeVote() : submitVote(true))}
                  />
                  <span className={`${isUpvote ? styles.upvote : isDownVote ? styles.downvote : ''}`}>
                    {feature.votesCount}
                  </span>
                  <BngIconButton
                    className={`${isDownVote && styles.downvoteArrow}`}
                    icon={'arrow_downward'}
                    onClick={loading ? undefined : () => (isDownVote ? removeVote() : submitVote(false))}
                  />
                </>
              )}
            </div>
          )}
          <div className={styles.imageContainer}>
            <img src={Api.Upload.previewUrl(feature.imagePath)} alt={'Image for voting feature'} />
          </div>
        </div>
        <div className={styles.featureTextContainer}>
          <span className={styles.featureCategory} style={{ color: feature.category.color }}>
            {categoryLabel}
          </span>
          <div className={styles.titleHeader}>
            <span className={styles.featureTitle}>{feature.title}</span>
            <div className={styles.subTitleContainer}>
              <span className={styles.subTitle}>{feature.author}</span>
              {showVotes && (
                <>
                  <span className={`${styles.subTitle} ${styles.dot}`}>•</span>
                  <span className={styles.subTitle}>
                    {msg.t('number.of.votes')}: {feature.totalVotes ?? 0}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className={styles.statusTag}>
            <BngTag className={styles[feature.status]} description={statusLabel} />
          </div>
          <span className={styles.featureDesc}>
            {feature.summary}
            {!expanded ? (
              <span className={styles.seeMoreLabel} onClick={() => setExpanded(true)}>
                {msg.t('see.more')}
              </span>
            ) : (
              <div>
                <hr />
                <span dangerouslySetInnerHTML={{ __html: feature.description }} />
                <span className={styles.seeLessLabel} onClick={() => setExpanded(false)}>
                  {msg.t('see.less')}
                </span>
              </div>
            )}
          </span>
        </div>
      </div>
    </UiBlocker>
  );
}
