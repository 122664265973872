import React, { useState } from 'react';
import Accordion from 'components/ui/Accordion';
import { UiBlocker } from 'components/bng/ui/UiBlocker';

export const AccordionContext = React.createContext({
  handleChange: _.noop,
  accordionsState: new Map(),
  getState: _.noop,
});

export const AccordionProvider = ({children}) => {
    const [accordionsState, setAccordions] = useState(new Map());

    const handleChange = (accordion = {key: '', value: false}) => {
        if (!accordion.key) {
            console.warn("Warning: Set an 'id' prop on '<Accordion/>' use AccordionProvider");
            return;
        }
        setAccordions(accordionsState.set(accordion.key, accordion.value));
    }

    const getState = (key) => {
        return accordionsState.get(key);
    }

    return (
        <AccordionContext.Provider value={{handleChange, accordionsState, getState}}>
            {children}
        </AccordionContext.Provider>
    )
}

export default function AccordionList({
  list = [],
  className = '',
  children,
  loading = false,
  actionButton,
  ...props
}) {
  return (
    <div className={`AccordionWrapper ${className} ${actionButton ? 'withAction' : ''}`} {...props}>
      <UiBlocker block={loading}>
        <div className="AccordionChildren">
          {list.map((accordion, index) => {
            if (React.isValidElement(accordion)) {
              return accordion;
            }
            return <Accordion key={index} title={accordion.title} children={accordion.children} />;
          })}
          {children}
        </div>
        {actionButton && <div className="ActionButtonContainer p-2">{actionButton}</div>}
      </UiBlocker>
    </div>
  );
}