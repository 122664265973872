import './ProfileDialog.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';
import { BngField } from 'components/bng/form/BngField';
import { BngSelect } from 'components/bng/form/BngSelect';
import ProfileCard from 'components/ui/profile/ProfileCard';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { passwordValidationFunction } from 'components/ui/profile/ChangePasswordDialog';
import BngPhoneInput from 'components/bng/form/BngPhoneInput';

let TZ_OPTIONS = null;

export async function fetchTimeZoneOptions() {
  if (!TZ_OPTIONS) {
    const {
      countries,
    } = require(/* webpackChunkName: "moment-timezone-meta" */ 'moment-timezone/data/meta/latest.json');
    TZ_OPTIONS = _.sortBy(
      Object.values(countries).map((country) => {
        return {
          label: `${country.name} (${country.abbr})`,
          children: country.zones.map((zoneId) => {
            const name = zoneId.replace(/_/g, ' ').split('/').pop();
            return { value: zoneId, label: `(UTC${moment.tz(zoneId).format('Z')}) ${name || zoneId}` };
          }),
        };
      }),
      ['label']
    );
  }
  return TZ_OPTIONS;
}

class ProfileTab extends Component {
  static propTypes = {
    languages: PropTypes.array,
    occupationAreas: PropTypes.array,
    profiles: PropTypes.array,
    user: PropTypes.object,
    avatars: PropTypes.array,
    newUser: PropTypes.bool,
    canAlterProfileAndMobile: PropTypes.bool,
    canAlterEmail: PropTypes.bool,
    mobile: PropTypes.bool,
  };

  static defaultProps = {
    languages: [],
    occupationAreas: [],
    profiles: [],
    user: {},
    avatars: [],
    canAlterProfileAndMobile: false,
    canAlterEmail: false,
    mobile: true,
  };

  state = {
    username: '',
    customizeOccupation: false,
    timeZoneOptions: [],
    selectedLanguage: this.props.context.user.preferredLanguage,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const timeZoneOptions = await fetchTimeZoneOptions();
    this.setState({ timeZoneOptions });
  }

  validatePassword = passwordValidationFunction(() => this.props.context.msg);

  render() {
    const { languages, occupationAreas, newUser, canAlterProfileAndMobile, canAlterEmail } = this.props;
    const { timeZoneOptions } = this.state;
    const superUser = this.props.context.user.superUser;

    return (
      <div className="profile-tab-personal-data">
        <ProfileCard {...this.props} />

        <div className="profile-form-data">
          <div className="row-fluid">
            <div className="span2">
              <Field
                label={this.props.context.msg.t('id')}
                name="id"
                component={BngField}
                readOnly={true}
                disabled={true}
              />
            </div>

            <div className="span10">
              <Field
                label={this.props.context.msg.t('name')}
                name="displayName"
                component={BngField}
                required={true}
                disabled={!canAlterProfileAndMobile}
              />
            </div>
          </div>

          <div className="row-fluid">
            <div className="span3">
              <Field
                label={this.props.context.msg.t('preferred_language')}
                name="preferredLanguage"
                component={BngField}
                inputComponent={BngSelect}
                className="mb-0"
                options={languages}
                disabled={!canAlterProfileAndMobile}
                onChange={(e) => this.setState({ selectedLanguage: e.target.value })}
                emptyOption={false}
              />
            </div>
            <div className="span4">
              <Field
                label={this.props.context.msg.t('time.zone')}
                name="timeZone"
                component={BngField}
                inputComponent={BngSelect}
                className="mb-0"
                options={timeZoneOptions}
                disabled={!canAlterProfileAndMobile}
                emptyOption={false}
                groupedOpts={true}
              />
            </div>
            <div className="span4">
              <Field
                name="phone"
                component={BngField}
                inputComponent={BngPhoneInput}
                disabled={!canAlterProfileAndMobile}
                userLanguage={this.state.selectedLanguage}
                required={this.props.user.allowMobileMessaging}
              />
            </div>
          </div>

          <Field
            label={this.props.context.msg.t('email')}
            name="email"
            component={BngField}
            required={true}
            disabled={!canAlterEmail}
          />

          <div className="row-fluid">
            <Field
              label={this.props.context.msg.t('occupation.area')}
              name="occupationArea"
              component={BngField}
              className="mb-0"
              inputComponent={BngSelect}
              options={occupationAreas}
              required={true}
              disabled={!canAlterProfileAndMobile}
            />
          </div>

          {!newUser && superUser && (
            <div className="row-fluid">
              <Field
                asProps={{ label: this.props.context.msg.t('enabled') }}
                withLabel={false}
                name="enabled"
                component={BngField}
                inputComponent={BngCheckbox}
              />
            </div>
          )}

          {newUser && (
            <div className="row-fluid">
              <Field
                rootClassName="span6"
                label={this.props.context.msg.t('createNewUser_label_password')}
                name="password"
                component={BngField}
                type="password"
                required={true}
                validate={this.validatePassword}
              />
              <Field
                rootClassName="span6"
                label={this.props.context.msg.t('createNewUser_label_confirmPassword')}
                name="confirmPassword"
                component={BngField}
                type="password"
                required={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ContextEnhancer(connect()(ProfileTab));
