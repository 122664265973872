import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import {createLogger} from "redux-logger";
import Reducers from "components/ui/redux/Reducers";

export default (serverState, devEnv = true) => {
    let mids = [thunk];
    if (devEnv) {
        mids.push(
            createLogger({
                collapsed: true,
                duration: true
            })
        );
    }
    return createStore(Reducers, serverState, applyMiddleware(...mids));
}