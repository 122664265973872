import './DimensionGrouping.css';
import * as React from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import {Tab, TabSet} from "components/ui/TabSet";
import {Field, Validations} from "components/ui/FormUtils";
import {DgMemberTree} from "components/ui/analysis/expert/DgMemberTree";

class DimensionGrouping extends React.Component {

    static propTypes = {
        formatStrings: PropTypes.array
    };

    static defaultProps = {};

    state = {
        hiers: [],
        members: [],
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const f = this.props.formula;
        if (f) {
            const members = {};
            f.data.members.forEach(m => members[m] = {});
            this.props.initialize({
                id: f.id,
                name: f.name,
                order: f.order,
                ...f.data,
                members
            });
        }
    }

    async componentDidUpdate({formValues: {dimension, hierarchy, members}}) {
        const dim = this.props.cubeMeta.dimensions.find(({dimension: {name}}) => name === this.props.formValues.dimension);
        if (dimension !== this.props.formValues.dimension) {
            const hiers = dim ? dim.hierarchies : [];
            this.setState({hiers});
            this.props.change('hierarchy', hiers.length !== 0 ? hiers[0].name : '');
        }

        if (hierarchy !== this.props.formValues.hierarchy && dim) {
            const hier = dim.hierarchies.find(({name}) => name === this.props.formValues.hierarchy);
            let newMembers = [];
            if (hier) {
                newMembers = await j.getJSON(`/spr/ui/analysis/hier-members?${j.param({
                    dimension: dim.dimension.name,
                    hierarchy: hier.name
                })}`);
            }
            this.setState({members: newMembers});
            let changed = false;
            Object.keys(members).forEach(m => {
                if (!newMembers.includes(m)) {
                    delete members[m];
                    changed = true;
                }
            });

            if (changed) {
                this.props.change('members', members);
            }
        }
    }

    toggleMemberSelection = ({uniqueName}) => {
        const members = {...this.props.formValues.members || {}};
        if (uniqueName in members) {
            delete members[uniqueName];
        } else {
            members[uniqueName] = {};
        }
        this.props.change('members', members);
    };

    render() {
        if (!this.props.cubeMeta) return null;
        const emptyOptions = <option value={''}>{this.props.context.msg.t('select.one')}</option>;
        return (

            <TabSet vertical className="DimensionGrouping">
                <Tab label={this.props.context.msg.t('formula')}
                     icon="fa fa-cogs">

                    <div className="row-fluid">
                        <div className="span6">
                            <Field
                                name="dimension"
                                componentClass="select"
                                label={this.props.context.msg.t('dimension')}
                                className="fill-w"
                                validate={Validations.required}
                            >
                                {emptyOptions}
                                {this.props.cubeMeta.dimensions.map(({dimension: {name, caption}}, idx) => {
                                    return (
                                        <option key={idx} value={name}>{caption || name}</option>
                                    )
                                })}
                            </Field>
                        </div>
                        <div className="span6">
                            <Field
                                name="hierarchy"
                                componentClass="select"
                                label={this.props.context.msg.t('hierarchy')}
                                className="fill-w"
                                disabled={!this.props.formValues.dimension}
                                validate={Validations.required}
                            >
                                {emptyOptions}
                                {this.state.hiers.map(({name, caption}, idx) => {
                                    return (
                                        <option key={idx} value={name}>{caption || name}</option>
                                    );
                                })}
                            </Field>
                        </div>
                    </div>

                    <div className="row-fluid">
                        <div className="span8 DimensionGroupingMembersWrapper">
                            <fieldset>
                                <legend>{this.props.context.msg.t('members')}</legend>

                                <div className="tree-container">
                                    <DgMemberTree availableMembers={this.state.members}
                                                  selected={this.props.formValues.members}
                                                  initialValues={this.props.formula}
                                                  onSelect={this.toggleMemberSelection}
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </Tab>
            </TabSet>
        );
    }

}


export default ContextEnhancer(DimensionGrouping);