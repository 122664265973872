import React from "react";
import { FixedSizeList as List } from 'react-window';

const BngLazyListView = ({
                             listProp = {
                                 height: 100,
                                 itemCount: 1,
                                 itemSize: 30,
                                 width: 300,
                             },
                             itemRender = _.noop
                         }) => {
    return (
        <List {...listProp}>
            {({ index, style }) => itemRender({index, style})}
        </List>
    )
};

export default BngLazyListView;