import {
    SET_ROW_META_DATA,
    SET_SELECTED_ROW,
    SET_HOVER_ROW,
    DEFINE_FILTER_MODEL,
    DEFINE_SORT_MODEL
} from "./actionTypes";

export const setRowMetaData = payload => ({
    type: SET_ROW_META_DATA,
    payload
});

export const setSelectedRow = payload => ({
    type: SET_SELECTED_ROW,
    payload
});

export const setHoverRow = payload => ({
    type: SET_HOVER_ROW,
    payload
});

export const setFilterModel = payload => ({
    type: DEFINE_FILTER_MODEL,
    payload
})

export const setSortModel = payload => ({
    type: DEFINE_SORT_MODEL,
    payload
})