import styles from './AccessTimeRestrictionFormDialog.module.css';

import React, { useMemo, useState } from 'react';
import { Field, Formik } from 'formik';

import { DefaultDialogActions } from 'components/ui/FormUtils';
import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import { bngYup } from 'components/bng/form/yup/BngYup';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import { BngForm } from 'components/bng/form/BngForm';
import useTranslation from 'components/hooks/useTranslation';
import {
  buildSortedMemberOptions,
  createMembersForOptions,
} from 'components/bng/exportScheduling/BngExportRecipientsTab';
import BngUserItemSelect from 'components/bng/form/itemSelect/BngUserItemSelect';
import BngTimePicker from 'components/bng/form/BngTimePicker';
import BngField from 'components/bng/form/BngField';
import BngButtonGroup from 'components/bng/ui/Buttons/BngButtonGroup';
import BngSwitch from 'components/bng/form/BngSwitch';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { BngSelect } from 'components/bng/form/BngSelect';
import { fetchTimeZoneOptions } from 'components/ui/profile/ProfileTab';
import useFetchData from 'components/hooks/useFetchData';

const RestrictionFormSchema = bngYup((yup) => {
  return yup.object({
    weekDays: yup.array(yup.number()).required(),
    members: yup.array().required('Share.errors.users').min(1, '').default([]),
    timeZone: yup.string().default('America/Sao_Paulo').required(),
    exceptionMembers: yup.array().default([]),
    extraRestrictions: yup.array(yup.string()),
    active: yup.boolean().default(true),
  });
});

function buildInitialValues({ restriction, availableMembers = [], msg }) {
  const val = RestrictionFormSchema.getDefault();
  if (!_.isEmpty(restriction)) {
    const { memberRestrictions, timeRanges, weekDays, extraRestrictions } = restriction.props;
    const [firstTimeRange, secondTimeRange] = timeRanges;

    const findMemberById = (id, type) => {
      return availableMembers.find(
        (member) =>
          member.type === type &&
          ((type === 'USER' && member.userId === id) ||
            (type === 'GROUP' && member.groupId === id) ||
            (type === 'RULE' && member.id === id))
      );
    };

    const createMembers = (groupIds, userIds, rules = []) => {
      const fetchedMembers = createMembersForOptions({
        msg,
        groups: groupIds.map((groupId) => {
          const group = findMemberById(groupId, 'GROUP');
          return {
            ...group,
            id: group.groupId,
            groupId: group.groupId,
          };
        }),
        projectUsers: userIds.map((userId) => {
          const user = findMemberById(userId, 'USER');
          return {
            user: {
              ...user,
              id: user.userId,
              userId: user.userId,
            },
          };
        }),
      });
      if (rules) {
        return fetchedMembers.concat(
          rules?.map((r) => {
            const rule = findMemberById(r, 'RULE');
            return {
              ...rule,
              id: rule.id,
            };
          })
        );
      } else {
        return fetchedMembers;
      }
    };

    Object.assign(val, {
      id: restriction.id,
      weekDays,
      timeZone: restriction.timeZone,
      members: createMembers(memberRestrictions.groupIds, memberRestrictions.userIds, memberRestrictions.rules),
      exceptionMembers: createMembers([], memberRestrictions.excludedUserIds),
      extraRestrictions,
      firstStarts: firstTimeRange?.starts,
      firstEnds: firstTimeRange?.ends,
      secondStarts: secondTimeRange?.starts,
      secondEnds: secondTimeRange?.ends,
      active: restriction.active,
    });
  }

  return val;
}

export default function AccessTimeRestrictionFormDialog({
  closeModal = _.noop,
  restriction = {},
  availableMembers = [],
  reloadRestrictions = _.noop,
}) {
  const { t } = useTranslation();
  const context = useBimContext();

  const { data: timeZoneOptions = [] } = useFetchData(async () => await fetchTimeZoneOptions());

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    restriction.props?.timeRanges && restriction.props?.timeRanges[0].starts !== '00:00:00' ? 'FIXED_TIMES' : 'FULL_DAY'
  );

  const [showSecondPicker, setShowSecondPicker] = useState(restriction.props?.timeRanges?.length > 1 ?? false);
  const [showExceptionMembers, setShowExceptionMembers] = useState(
    restriction.props?.memberRestrictions?.excludedUserIds?.length > 0 ?? false
  );

  const dayOrTimeOpts = useMemo(
    () => [
      {
        value: 'FULL_DAY',
        label: t('full.day'),
        selected: selectedOption === 'FULL_DAY',
        disabled: false,
      },
      {
        value: 'FIXED_TIMES',
        label: t('fixed.times'),
        selected: selectedOption === 'FIXED_TIMES',
        disabled: false,
      },
    ],
    [selectedOption]
  );

  const extraRestrictionsOpts = useMemo(
    () => [
      { value: 'PUBLISHER', label: t('restrict.publisher'), disabled: true },
      { value: 'PRESENTATION', label: t('restrict.sight.management'), disabled: true },
      { value: 'API', label: t('restrict.api'), disabled: true },
      { value: 'SCHEDULING', label: t('restrict.shipping.schedule'), disabled: true },
    ],
    []
  );

  const initialValues = useMemo(
    () =>
      buildInitialValues({
        restriction,
        msg: context.msg,
        availableMembers,
      }),
    [availableMembers]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RestrictionFormSchema}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          const {
            id,
            weekDays,
            timeZone,
            members,
            exceptionMembers,
            extraRestrictions,
            firstStarts,
            firstEnds,
            secondStarts,
            secondEnds,
            active,
          } = values;

          const { project, user } = context;

          const hours =
            selectedOption === 'FULL_DAY'
              ? [{ starts: '00:00:00', ends: '23:59:59' }]
              : [
                  { starts: firstStarts, ends: firstEnds },
                  ...(showSecondPicker && secondStarts && secondEnds
                    ? [{ starts: secondStarts, ends: secondEnds }]
                    : []),
                ];

          const filterByType = (list, type) => {
            return list
              .filter((item) => item.type === type)
              .map((item) => (item.type === 'USER' ? item.userId : item.type === 'GROUP' ? item.groupId : item.id));
          };

          const restriction = {
            id,
            weekDays,
            timeRanges: hours,
            timeZone,
            users: filterByType(members, 'USER'),
            groups: filterByType(members, 'GROUP'),
            rules: filterByType(members, 'RULE'),
            exceptionUsers: showExceptionMembers ? filterByType(exceptionMembers, 'USER') : [],
            extraRestrictions,
            projectId: project.id,
            userId: user.id,
            active: active,
          };
          await Api.AccessTimeRestriction.save(restriction);
          closeModal();
          await reloadRestrictions();
        } catch (e) {
          console.error('Error on form submit', e);
          UiMsg.ajaxError(null, e);
        } finally {
          setLoading(false);
        }
      }}
    >
      {({ values, isSubmitting }) => {
        const members = values?.members;
        const exceptionMembers = values?.exceptionMembers;
        const filteredAvailableMembers = availableMembers.filter((member) => member.userId !== context.accountMasterId);
        const membersOptions = buildSortedMemberOptions({
          members: filteredAvailableMembers.filter((member) => !exceptionMembers.includes(member)),
          isSharingMobile: false,
          sendTo: members,
          msg: context.msg,
        });

        const exceptionMembersOptions = buildSortedMemberOptions({
          members: filteredAvailableMembers.filter((member) => !members.includes(member)),
          isSharingMobile: false,
          sendTo: exceptionMembers,
          msg: context.msg,
          removeGroups: true,
        });

        return (
          <Dialog
            className={`AccessTimeRestrictionFormDialog ${styles.AccessTimeRestrictionFormDialog} large`}
            title={!_.isEmpty(restriction) ? t('edit.restriction') : t('new.restriction')}
            loading={loading || isSubmitting}
            onClose={closeModal}
          >
            <BngForm>
              <Dialog.Body>
                <div className={styles.container}>
                  <div className={styles.formDays}>
                    <label>{t('released.days')}</label>
                    <DayButtons name={'weekDays'} />
                  </div>
                  <div className={styles.formDayOrTime}>
                    <label>{t('release.times')}</label>
                    <BngButtonGroup
                      name={'dayOrTime'}
                      options={dayOrTimeOpts}
                      selectedIndicator
                      onChange={(value) => setSelectedOption(value)}
                    />
                  </div>
                  {selectedOption === 'FIXED_TIMES' && (
                    <div>
                      <TimeRangePicker namePrefix="first" />
                      {showSecondPicker && (
                        <TimeRangePicker namePrefix="second" onRemove={() => setShowSecondPicker(false)} />
                      )}
                    </div>
                  )}
                  {selectedOption === 'FIXED_TIMES' && !showSecondPicker && (
                    <BngIconButton
                      icon="add_circle"
                      text={t('add.time')}
                      className={styles.intervalTimeButton}
                      iconProps={{ style: { marginRight: '5px' } }}
                      onClick={() => setShowSecondPicker(true)}
                    />
                  )}
                  <div className={styles.timeZone}>
                    <label>{t('time.zone')}</label>
                    <Field
                      name="timeZone"
                      label={''}
                      component={BngField}
                      inputComponent={BngSelect}
                      options={timeZoneOptions}
                      emptyOption={false}
                      groupedOpts={true}
                      className={styles.timeZoneSelect}
                    />
                  </div>

                  <Field
                    name={'members'}
                    label={t('restricted.users.groups')}
                    buttonLabel={t('click.here.to.select')}
                    component={BngField}
                    inputComponent={BngUserItemSelect}
                    options={membersOptions}
                  />
                  <BngSwitch
                    onChange={() => setShowExceptionMembers(!showExceptionMembers)}
                    checked={showExceptionMembers}
                    label={t('enable.user.exception')}
                    className={'mt-3'}
                  />
                  {showExceptionMembers && (
                    <>
                      <Field
                        name={'exceptionMembers'}
                        label={''}
                        buttonLabel={t('click.here.to.select')}
                        component={BngField}
                        inputComponent={BngUserItemSelect}
                        options={exceptionMembersOptions}
                      />
                    </>
                  )}

                  <Field
                    name={'extraRestrictions'}
                    label={
                      <div className={styles.isComing}>
                        {t('extra.restriction')}
                        <span className={styles.labelIscComing}>{t('is.coming')}</span>
                      </div>
                    }
                    className={`${styles.extraRestrictionsButtonGroup}`}
                    checkbox={true}
                    component={BngField}
                    showErrors={false}
                    inputComponent={BngButtonGroup}
                    options={extraRestrictionsOpts}
                    selectedIndicator
                    fill
                  />
                </div>
              </Dialog.Body>
              <Dialog.Footer>
                <DefaultDialogActions
                  closeModal={closeModal}
                  context={context}
                  title={t('save')}
                  disabled={selectedOption === 'FIXED_TIMES' && (!values.firstStarts || !values.firstEnds)}
                />
              </Dialog.Footer>
            </BngForm>
          </Dialog>
        );
      }}
    </Formik>
  );
}

function DayButtons({ name }) {
  const { t } = useTranslation();

  const opts = useMemo(() => {
    const arr = [];
    for (let i = 1; i < 8; i++) {
      arr.push(t(`dayOfTheWeek.${i}`).substring(0, 3));
    }

    return [
      { value: 0, label: arr[6] },
      { value: 1, label: arr[0] },
      { value: 2, label: arr[1] },
      { value: 3, label: arr[2] },
      { value: 4, label: arr[3] },
      { value: 5, label: arr[4] },
      { value: 6, label: arr[5] },
    ];
  }, []);

  return (
    <Field
      name={name}
      className={`${styles.dayButtons}`}
      component={BngField}
      showErrors={false}
      inputComponent={BngButtonGroup}
      options={opts}
      checkbox={true}
      withLabel={false}
      selectedIndicator
      forceArray
      fill
    />
  );
}

function TimeRangePicker({ namePrefix, onRemove }) {
  const { t } = useTranslation();
  const gridClassName = onRemove ? `${styles.intervalTimeGrid} ${styles.hasRemoveButton}` : styles.intervalTimeGrid;

  return (
    <div className={gridClassName}>
      <label>{t('allow.access.between')}</label>
      <Field
        name={`${namePrefix}Starts`}
        component={BngField}
        showErrors={false}
        inputComponent={BngTimePicker}
        type={'time'}
        icon="schedule"
        withLabel={false}
      />
      <label>{t('schedule.recurrence.interval.label.2')}</label>
      <Field
        name={`${namePrefix}Ends`}
        component={BngField}
        showErrors={false}
        inputComponent={BngTimePicker}
        icon="schedule"
        type={'time'}
        withLabel={false}
      />
      <BngIconButton icon={'close'} className={`${styles.buttonClose}`} onClick={onRemove} />
    </div>
  );
}
