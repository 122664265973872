import styles from './DetailsDialog.module.css';

import React from 'react';

import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import Utils from 'components/Utils';

function UpdateInfo({ className = '', user, date, label, ...props }) {
  const { msg } = useBimContext();

  let dateMsg = msg.t('without.information');
  if (date) {
    const [datePart, ...timePart] = Utils.Date.formatDateTime(date).split(' ');
    dateMsg = `${datePart} ${msg.t('atTime')} ${timePart.join(' ')}`;
  }

  return (
    <div className={`UpdateInfo ${className} ${styles.UpdateInfo}`} {...props}>
      <div>
        <span className={`mr-1`}>{label}:</span>
        <b>{Utils.Users.displayName(user) ?? user ?? '-'}</b>
      </div>
      <div>{dateMsg}</div>
    </div>
  );
}

export default function DetailsDialog({
  className = '',
  createdBy,
  createdAt,
  updatedBy,
  updatedAt,
  closeModal = _.noop,
  children,
  footerComponent = undefined,
  ...props
}) {
  const { msg } = useBimContext();
  const FooterComponent = footerComponent;
  return (
    <Dialog className={`DetailsDialog ${className}`} onClose={closeModal} {...props}>
      <Dialog.Body>
        <div className={`flex-center-items`}>
          <UpdateInfo
            className={`UpdatedBy flex-grow-1`}
            user={updatedBy || createdBy}
            date={updatedAt || createdAt}
            label={msg.t('modifiedBy')}
          />
          <UpdateInfo
            className={`CreatedBy flex-grow-1`}
            user={createdBy}
            date={createdAt}
            label={msg.t('createdBy')}
          />
        </div>
        {children}
      </Dialog.Body>
      {footerComponent && (
        <Dialog.Footer className="mt-4">
          <FooterComponent closeModal={closeModal} />
        </Dialog.Footer>
      )}
    </Dialog>
  );
}
