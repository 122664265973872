import './ExpertConf.css';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "../Button";
import ContextEnhancer from "../../ContextEnhancer";
import OpConfirmation from "components/ui/OpConfirmation";
import BlockUi from 'react-block-ui';
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import ExpertFormulaDialog from "components/ui/analysis/ExpertFormulaDialog";
import Icon from "components/ui/common/Icon";

const iconBaseUrl = `${Api.baseUrl()}/resources/images`;
const ops = [
    {type: 'Free', tooltip: 'expert.dialog.Free.tooltip', icon: `${iconBaseUrl}/expert_free.png`},
    {type: 'Share', tooltip: 'expert.dialog.Share.tooltip', icon: `${iconBaseUrl}/expert_part.png`},
    {type: 'DimensionGrouping', tooltip: 'expert.dialog.DimensionGrouping.tooltip', icon: `${iconBaseUrl}/expert_grouping.png`},
    {type: 'Constant', tooltip: 'expert.dialog.Constant.tooltip', icon: `${iconBaseUrl}/expert_constants.png`},
];

class ExpertConf extends Component {

    static propTypes = {
        closeDrop: PropTypes.func
    };

    static defaultProps = {
        closeDrop: _.noop
    };

    state = {
        formulas: [],
        loading: false
    };

    constructor(props) {
        super(props);
    }

    updateFormulas = () => {
        return this.loading(async () => {
            const result = await j.getJSON('/spr/ui/analysis/formulas');
            this.setState({formulas: _.sortBy(result, 'order')});
        });
    };

    componentDidMount() {
        this.updateFormulas();
    }

    handleClose(){
        j('#expert-conf-container').removeClass('open');
    }

    render() {
        if (!this.props.context.permissions.isAtLeastExpert()) {
            return null;
        }

        const {formulas} = this.state;

        return (
            <ul className="ExpertConf dropdown-menu"
                onClick={e => e.stopPropagation()}>
                <li>
                    <div className="scrollbar-inner" ref={ref => j(ref).scrollbar()}>
                        <div className="span" style={{margin: 0}}>
                            <fieldset>
                                <legend>
                                    {this.props.context.msg.t('expert.ops')}
                                    <Icon icon="close" className="close-button" onClick={this.handleClose}/>
                                </legend>
                                <div className="op-boxes">
                                    {ops.map((e, idx) => (
                                        <div key={idx}
                                             className="op-box"
                                             title={this.props.context.msg.t(`${e.tooltip}`)}
                                             onClick={() => this.openFormulaDialog(e.type)}>
                                            {/*<img src={e.icon} alt={e.type}/>*/}
                                            <span>
                                                {this.props.context.msg.t(`expert.dialog.${e.type}.title`)}
                                            </span>
                                        </div>
                                    ))}
                                </div>

                            </fieldset>
                            {/*<fieldset>
                                <legend>{this.props.context.msg.t('in.business.operations')}</legend>
                                <h2 style={{textAlign: 'center', color: '#CCC'}}>
                                    {this.props.context.msg.t('coming.soon')}
                                </h2>
                            </fieldset>*/}
                        </div>
                        <div className="span" style={{margin: 0}}>
                            <BlockUi tag="div" blocking={this.state.loading} className="span12">
                                <fieldset>
                                    <legend>{this.props.context.msg.t('created.operations')}</legend>
                                    <div className="formula-table-container">
                                        <table className="table table-bordered table-striped">
                                            <colgroup>
                                                <col style={{width: '25px'}}/>
                                                <col style={{width: '35px'}}/>
                                                <col style={{width: '160px'}}/>
                                                <col style={{width: '100px'}}/>
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{this.props.context.msg.t('type')}</th>
                                                <th>{this.props.context.msg.t('description')}</th>
                                                <th>{this.props.context.msg.t('actions')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {formulas.map((formula, idx) => {
                                                return (
                                                    <tr key={formula.id}>
                                                        <td className={'text-center'}>
                                                            {idx + 1}
                                                        </td>
                                                        <td className={'text-center'}>
                                                            <i className="fa fa-bar-chart"></i>
                                                        </td>
                                                        <td>
                                                            <div title={formula.name} className="formula-name">
                                                                {formula.name}
                                                            </div>

                                                        </td>
                                                        <td className={'text-center'}>
                                                            {this.props.context.permissions.isAdmin() &&
                                                            <Button icon={'icon-globe'}
                                                                    className={'btn-mini btn-table btn-purple'}
                                                                    title={this.props.context.msg.t('publish.member.on.cube')}
                                                                    onClick={e => {
                                                                        OpConfirmation({
                                                                            title: formula.name,
                                                                            message: this.props.context.msg.t('expert.conf.publish.formula.confimation.message'),
                                                                            msg: this.props.context.msg,
                                                                            level: 'info',
                                                                            onConfirm: () => this.publishFormula(formula)
                                                                        });

                                                                    }}
                                                            />
                                                            }
                                                            {' '}
                                                            <Button icon={'icon-edit'}
                                                                    className={'btn-mini btn-table btn-warning'}
                                                                    title={this.props.context.msg.t('edit')}
                                                                    onClick={() => {
                                                                        this.openFormulaDialog(formula.type, formula);
                                                                    }}
                                                            />
                                                            {' '}
                                                            <Button icon={'icon-remove'}
                                                                    className={'btn-mini btn-table btn-danger'}
                                                                    title={this.props.context.msg.t('remove')}
                                                                    onClick={() => {
                                                                        OpConfirmation({
                                                                            title: formula.name,
                                                                            message: this.props.context.msg.t('remove.formula.confimation.message'),
                                                                            msg: this.props.context.msg,
                                                                            onConfirm: () => this.removeFormula(formula)
                                                                        });
                                                                    }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                </fieldset>
                            </BlockUi>
                        </div>
                    </div>
                </li>
            </ul>
        );
    }

    openFormulaDialog = (type, formula) => {
        window.ReduxStore.dispatch(
            window.Actions.MODALS.open(ExpertFormulaDialog, {
                type,
                formula,
                callback: this.updateFormulas
            })
        );
    };

    loading = async (fn) => {
        this.setState({loading: true});
        try {
            await fn();
        } finally {
            this.setState({loading: false});
        }
    };

    removeFormula = (formula) => {
        return this.loading(
            async () => {
                await j.deleteReq(`/spr/ui/analysis/formulas/${formula.id}`);
                Api.updateJsf();
                this.updateFormulas();
            }
        );
    };

    publishFormula = (formula) => {
        return this.loading(async () => {
            try {
                // modal
                await new Promise(r => {
                    setTimeout(() => {
                        Swal.fire({
                                title: '',
                                html: '<div>Aguarde enquanto a operação é publicada...</div><div><i class="fa fa-refresh fa-spin"></i></div>',
                                icon: 'info',
                                showCancelButton: false,
                                showConfirmButton: false,
                            }
                        );
                        r(true);
                    }, 500);
                });

                await j.post(`/spr/ui/analysis/formulas/${formula.id}/publish`);
                await Api.executeExp('#{analysisBean.reloadCurrentAnalysis()}');
                await this.updateFormulas();
                UiMsg.ok(this.props.context.msg.t('expert.conf.measure.published'));
            } catch (e) {
                console.error(e);
                UiMsg.ajaxError('Error', e)
            } finally {
                Swal.close()
            }
        });
    }
}

export default ContextEnhancer(ExpertConf);
