"use strict";

const $ = jQuery;


class PipedriveApi {

    static saveOrigin(pipeDriveData) {
        return Promise.resolve(
            $.postJSON('/spr/origins/pipedrive', pipeDriveData)
        );
    }


    static editOrigin(id, pipeRunData) {
        return Promise.resolve(
            $.postJSON(`/spr/origins/pipedrive/${id}`, pipeRunData)
        );
    }

    static getValuesWS(id) {
        return Promise.resolve(
            $.getJSON(`/spr/origins/pipedrive/values/${id}`)
        );
    }

    static validateToken(token) {
        return Promise.resolve(
            $.getJSON('/spr/origins/pipedrive/validate', {token})
        );
    }

    static validateName(pipeDriveData) {
        return Promise.resolve(
            $.postJSON('/spr/origins/pipedrive/validatename', pipeDriveData)
        );
    }
}

export default PipedriveApi;