import './ExportButton.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BngIconButton } from 'components/bng/ui/BngIconButton';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import PropTypes from 'prop-types';
import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import ContextEnhancer from 'components/ContextEnhancer';
import BngDropdown from 'components/bng/ui/BngDropdown';
import CopyLinkToClipboard from 'components/ui/CopyLinkToClipboard';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import BngExportDialog from 'components/bng/exportScheduling/BngExportDialog';

class ExportButton extends Component {
  static propTypes = {
    path: PropTypes.string,
    filters: PropTypes.any,
    disableExportToPdf: PropTypes.bool,
    bigtableFilterModel: PropTypes.any,
    bigtableSortModel: PropTypes.any,
    onExcelExport: PropTypes.func,
    onPdfExport: PropTypes.func,
    onMetadataExport: PropTypes.func,
    exportState: PropTypes.any,
  };

  static defaultProps = {
    disableExportToPdf: false,
    path: '',
    onExcelExport: undefined,
    onPdfExport: undefined,
    onMetadataExport: undefined,
    exportState: undefined,
  };

  state = {
    loading: true,
    exportExcelLink: '#',
    exportCSVLink: '#',
  };

  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  async componentDidMount() {
    try {
      let exportExcelLink = this.state.exportExcelLink;
      if (this.isAnalysis() && !this.props.onExcelExport) {
        exportExcelLink = await this.getExcelLink();
      }
      const exportCSVLink = this.getCSVLink();
      this.setState({
        exportExcelLink,
        exportCSVLink,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.path !== this.props.path) {
      this.componentDidMount();
    }
  }

  getPDFLink = async (event) => {
    if (this.props.disableExportToPdf) {
      UiMsg.warn(this.props.context.msg.t('bigtable.not.able.to.export'));
      return;
    }

    event.preventDefault();
    if (
      Utils.Object.isNewMap(this.props.path) ||
      Utils.Object.isKpi(this.props.path) ||
      Utils.Object.isBigTable(this.props.path)
    ) {
      await Api.Export.exportCockpitObject({
        content: this.props.path,
        filters: this.props.filters,
        bigtableFilterModel: Utils.BigTable.returnFilterModelForPath(this.props.path, this.props.bigtableFilterModel),
        bigtableSortModel: Utils.BigTable.returnSortModelForPath(this.props.path, this.props.bigtableSortModel),
      });
    } else {
      await Api.Export.exportCurrentObjectUrl({
        content: this.props.path,
        bigtableFilterModel: this.props.bigtableFilterModel,
        bigtableSortModel: this.props.bigtableSortModel,
      });
    }
  };

  getExcelLink = async () => {
    let exportExcelLink = '#';
    if (this.isAnalysis()) {
      try {
        exportExcelLink = await Api.Bng.getExportExcelLink(this.props.path);
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError(null, e);
      }
    }
    return exportExcelLink;
  };

  getCSVLink = () => {
    let exportCSVLink = '#';
    if (this.isBigTable()) {
      try {
        exportCSVLink = Api.BigTable.generateExportToCsvLink(this.props.path);
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError(null, e);
      }
    }
    return exportCSVLink;
  };

  isAnalysis = () => {
    return Utils.Object.isAnalysis(this.props.path);
  };

  isBigTable = () => {
    return Utils.Object.isBigTable(this.props.path);
  };

  isDashboard = () => {
    return Utils.Object.isDashboard(this.props.path);
  }

  share = (event, messagingType, shareMode = true) => {
    event.preventDefault();
    if (!shareMode && !this.__scheduleAlertShown) {
      UiMsg.warn(this.props.context.msg.t('schedule.sending.alert'));
      this.__scheduleAlertShown = true;
    }
    this.props.dispatch(
      MODALS.open(BngExportDialog, {
        messageType: messagingType,
        contents: [
          {
            name: this.props.activePanel.name,
            path: this.props.path,
            exportType: Utils.Scheduling.EXPORT_TYPE.PDF,
          },
        ],
        schedulingType: shareMode
          ? Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE
          : Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED,
        shareOpts: {
          enabled: shareMode,
          params: shareMode
            ? {
                content: this.props.path,
                filters: this.props.filters,
                bigtableFilterModel: this.props.bigtableFilterModel,
                bigtableSortModel: this.props.bigtableSortModel,
                exportState: this.props.exportState,
              }
            : {},
        },
      })
    );
  };

  renderElements = () => {
    return this.elements().map((element, idx) => {
      const { render, className } = element;
      return (
        <li className={`li-element ${className}`} key={idx}>
          {render()}
        </li>
      );
    });
  };

  elements = () => {
    const { share, getPDFLink, isAnalysis, isBigTable, isDashboard } = this;
    const { disableExportToPdf, context, path, onExcelExport, onPdfExport, onMetadataExport } = this.props;
    const { exportExcelLink, exportCSVLink } = this.state;

    return [
      {
        render() {
          return (
            <div className={'export-header-dropdown'}>
              <span>{context.msg.t('share')}</span>
              <hr style={{ width: '100%' }} />
            </div>
          );
        },
        shouldRender: true,
      },
      {
        render() {
          return (
            <a
              href="#"
              className={`unstyled-link`}
              onClick={(e) => {
                e.preventDefault();
                share(e, 'EMAIL', false);
              }}
            >
              <Icon icon="schedule_send" /> {context.msg.t('schedule.sending')}
            </a>
          );
        },
        shouldRender: !_.isEmpty(path),
      },
      {
        render() {
          return (
            <a
              href="#"
              className={`unstyled-link`}
              onClick={(e) => (checkAddonEnabled(AddonType.WHATS_APP.key, true) ? share(e, 'WHATSAPP') : _.noop)}
            >
              <Icon icon="fa fa-whatsapp" /> {context.msg.t('share.whatsapp')}
            </a>
          );
        },
        shouldRender: !_.isEmpty(path),
      },
      {
        render() {
          return (
            <a href="#" className="unstyled-link" onClick={(e) => share(e, 'EMAIL')}>
              <Icon icon="email" /> {context.msg.t('share.email')}
            </a>
          );
        },
        shouldRender: !_.isEmpty(path),
      },
      {
        render() {
          return (
            <a
              href="#"
              title={context.msg.t('is.coming')}
              className="unstyled-link disabled"
              onClick={(e) => e.preventDefault()}
            >
              <Icon icon="fa fa-telegram" /> {context.msg.t('share.telegram')}
            </a>
          );
        },
        shouldRender: !_.isEmpty(path),
      },
      {
        render() {
          return (
              <a href="#" className="unstyled-link" onClick={onMetadataExport}>
                <Icon icon="import_export" /> {context.msg.t('export.json')}
              </a>
          );
        },
        shouldRender: onMetadataExport && Utils.Project.isBimachineEmail(context.user.email) && isDashboard(path),
      },
      {
        render() {
          return (
            <a
              href="#"
              onClick={onPdfExport ? onPdfExport : getPDFLink}
              className={`unstyled-link ${disableExportToPdf ? 'disabled' : ''}`}
              title={disableExportToPdf ? context.msg.t('bigtable.not.able.to.export') : null}
            >
              <Icon icon="description" /> {context.msg.t('export_pdf')}
            </a>
          );
        },
        shouldRender: !_.isEmpty(path),
      },
      {
        render() {
          return (
            <a href={exportExcelLink} className="unstyled-link" target="_blank" onClick={onExcelExport}>
              <Icon icon="grid_on" /> {context.msg.t('export_excel')}
            </a>
          );
        },
        shouldRender: !_.isEmpty(path) && isAnalysis(),
      },
      {
        render() {
          return (
            <a href={exportCSVLink} className="unstyled-link" onClick={() => Api.BigTable.exportCsvFromObject()}>
              <Icon icon="grid_on" /> {context.msg.t('export_csv')}
            </a>
          );
        },
        shouldRender: isBigTable() && !_.isEmpty(path),
      },
      {
        render() {
          return (
            <CopyLinkToClipboard buttonStyle="ExportButtonLink" linkToCopy={window.location.href} objectLink={false} />
          );
        },
        shouldRender: true,
      },
    ].filter((e) => e.shouldRender);
  };

  render() {
    const { context } = this.props;
    const { loading } = this.state;

    return (
      <BngDropdown
        className={`cockpit-nav-btn share ${loading ? 'Disabled' : ''}`}
        popperClassName="BngOptions export-toolbar dropdown-links"
        customButton={(props) => {
          return (
            <div className="share-button" title={context.msg.t('export')}>
              <BngIconButton
                icon={'share'}
                className={'selected'}
                onClick={(event) => (!loading ? props.openDropdown(event) : null)}
                text={context.msg.t('share')}
              />
            </div>
          );
        }}
        customOptions={() => <ul className={'ul-elements-list'}>{this.renderElements()}</ul>}
      />
    );
  }
}

export default connect((state) => {
  return {
    bigtableFilterModel: state.bigTable.data.bigtableFilterModel,
    bigtableSortModel: state.bigTable.data.bigtableSortModel,
  };
})(ContextEnhancer(ExportButton));
