import styles from './WhatsSupportDialog.module.css';

import { useEffect, useMemo, useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import QRCode from 'react-qr-code';

import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import Button from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { Axios } from 'commonUtils';

const WhatsSupportDialog = ({ closeModal = _.noop }) => {
  const context = useBimContext();

  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(null);

  const fetchPhone = async () => {
    setLoading(true);
    try {
      const fetchedPhone = await Api.Support.findPhone();
      setPhone(fetchedPhone);
    } catch (e) {
      console.error('Error on fetchPhone()', e);
      UiMsg.error('whatsapp.support.error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhone();
  }, []);

  const [whatsUrl, formattedNumber] = useMemo(() => {
    const url = Axios.getUri({
      url: 'https://api.whatsapp.com/send',
      params: { phone, type: 'phone_number', app_absent: '0' },
    });
    const number = phone ? parsePhoneNumber(`+${phone}`).formatInternational() : '';
    return [url, number];
  }, [phone]);

  return (
    <Dialog
      title={context.msg.t('whatsapp.support')}
      className={`${styles.WhatsSupportDialog}`}
      onClose={closeModal}
      newDialogLayout={true}
      loading={loading}
    >
      <Dialog.Body>
        <h5>{context.msg.t('whatsapp.support.scan')}</h5>
        <QRCode value={whatsUrl} />
        <span className={styles.phoneText}>{formattedNumber}</span>
        <h5>{context.msg.t('whatsapp.support.web')}</h5>
        <a href={whatsUrl} target={'_blank'}>
          <Button className={`bng-button ${styles.whatsButton}`}>
            <Icon icon={'fa-whatsapp'} />
            {context.msg.t('contact.us')}
          </Button>
        </a>
      </Dialog.Body>
    </Dialog>
  );
};

export default WhatsSupportDialog;
