import styles from './ProjectConfigurationTab.module.css';

import React, { useMemo } from 'react';
import { Field } from 'formik';

import BngSelect from 'components/bng/form/BngSelect';
import BngField from 'components/bng/form/BngField';
import useTranslation from 'components/hooks/useTranslation';

const buildConfOptions = (t, includeAuto = false) => {
  const confOptions = [
    {
      value: 'ENABLED',
      label: t('ConfigOption.ENABLED'),
    },
    {
      value: 'DISABLED',
      label: t('ConfigOption.DISABLED'),
    },
  ];

  if (includeAuto) {
    confOptions.push({ value: 'AUTO', label: t('ConfigOption.AUTO') });
  }

  return confOptions;
};

export default function ProjectConfigurationTab() {
  const { t } = useTranslation();

  const mdxRemoveUnusedMembersOpts = useMemo(() => buildConfOptions(t, true), []);
  const timeDimensionsLegacyModeOpts = useMemo(() => buildConfOptions(t), []);
  return (
    <div className={`ProjectConfigurationTab ${styles.AdvancedConfigurationWrapper}`}>
      <h6>{t('project.config.advanced.config')}</h6>
      <div className={styles.AdvancedConfigurationTabDiv}>
        <Field
          name="mdxRemoveUnusedMembers"
          label={t('mdx.remove.unused.members')}
          component={BngField}
          inputComponent={BngSelect}
          emptyOption={false}
          showErrors={false}
          options={mdxRemoveUnusedMembersOpts}
        />
      </div>
      <div>
        <Field
          name="timeDimensionsLegacyMode"
          label={t('time.dimensions.legacy.mode')}
          component={BngField}
          inputComponent={BngSelect}
          emptyOption={false}
          showErrors={false}
          options={timeDimensionsLegacyModeOpts}
        />
      </div>
    </div>
  );
}
