import "./BigTableAnalysisHelp.css"

import React from "react";
import PropTypes from "prop-types";

import BngAda from "components/bng/ui/BngAda/BngAda";
import BngVideoModal from "components/bng/ui/BngVideoModal";
import ContextEnhancer from "components/ContextEnhancer";

const BigTableAnalysisHelp = ContextEnhancer(
    class BigTableAnalysisHelpInner extends React.Component {

        static propTypes = {
            context: PropTypes.any,
        }

        state = {
            adaButtonPressed: true,
            showExtraContent: false,
        };

        handleClick = () => {
            this.setState({ adaButtonPressed: !this.state.adaButtonPressed })
        }

        handleExtraContent = () => {
            this.setState({ showExtraContent: !this.state.showExtraContent })
        }

        render() {
            return (
                <>
                    <BngAda
                        className={"BigTableAnalysisHelp"}
                        title={this.props.context.msg.t('BigTableAnalysisHelp.adaTitle')}
                        description={this.props.context.msg.t('BigTableAnalysisHelp.adaDescription')}
                        options={[
                            {
                                icon: 'play_circle_outline',
                                label: this.props.context.msg.t('BigTableAnalysisHelp.videoLinkLabel'),
                                onClick: () => this.handleExtraContent()
                            },
                            {
                                label: this.props.context.msg.t('BigTableAnalysisHelp.adaLinks.label1'),
                                onClick: () => window.open(this.props.context.msg.t('BigTableAnalysisHelp.adaLinks.link1'), "_blank")
                            }
                        ]}
                        buttonLabel={this.props.context.msg.t('BigTableAnalysisHelp.adaButtonLabel')}
                        onClick={this.handleClick}
                        buttonPressed={this.state.adaButtonPressed}
                        showInfo={!this.state.showExtraContent}
                    />

                    {this.state.showExtraContent &&
                        <BngVideoModal
                            className={"BigTableAnalysisHelpContainer"}
                            classNameOverlay={"OverlayBigTableAnalysisHelp"}
                            context={this.props.context}
                            videoUrl={'BigTableAnalysisHelp.video.url'}
                            title={this.props.context.msg.t('BigTableAnalysisHelp.videoTitle')}
                            explanation={this.props.context.msg.t('BigTableAnalysisHelp.videoExplanation')}
                            onClose={() => {
                                this.handleExtraContent(), this.handleClick()
                            }}
                            adaLayout
                        />
                    }
                </>
            );
        }
    }
);

export default BigTableAnalysisHelp;