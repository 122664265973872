import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import BreadcrumbToolbar from './BreadcrumbToolbar';

const ReloadToolbarItem = ({
                               onClick = _.noop,
                               disabled,
                               ...props
                           }) => {

    const [loading, setLoading] = useState(false);

    const handleReload = async () => {
        if (loading) return;

        setLoading(true);
        try {
            await onClick();
            typeof window.__CLOSE_RIGHT_MENU === "function" && window.__CLOSE_RIGHT_MENU();
            typeof window.__OBJECT_RELOAD === "function" && window.__OBJECT_RELOAD({dirty: false});
        } finally {
            setTimeout(() => setLoading(false), 5000);
        }
    }

    return (
        <BreadcrumbToolbar.Item icon="refresh"
                                className="ReloadToolbarItem"
                                title={props.context.msg.t('refresh')}
                                onClick={handleReload}
                                disabled={disabled || loading}
                                {...props}/>
    );
}

ReloadToolbarItem.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default ContextEnhancer(ReloadToolbarItem);
