import { Axios } from 'commonUtils';

class ProjectExtractorUi {
  constructor(data) {
    Object.assign(this, data);
    this.createdAt = moment(this.createdAt);
    this.updatedAt = moment(this.updatedAt);
    this.lastCheckAt = moment(this.lastCheckAt);
  }

  isOnline() {
    if (!this.lastCheckAt) return false;

    const diffInSeconds = moment().diff(this.lastCheckAt) / 1000;
    return diffInSeconds < 30;
  }
}

export default class ProjectExtractorApi {
  async findByProject(projectId) {
    const { data } = await Axios.get(`/spr/projectExtractors`, {
      params: {
        projectId,
      },
    });
    return data.map((d) => new ProjectExtractorUi(d));
  }

  async findById(id) {
    const { data } = await Axios.get(`/spr/projectExtractors/${id}`);
    return new ProjectExtractorUi(data);
  }

  async save(entity) {
    const { data } = await Axios.post(`/spr/projectExtractors${entity.id ? `/${entity.id}` : ''}`, entity);
    return data;
  }

  async remove(id) {
    await Axios.delete(`/spr/projectExtractors/${id}`);
  }

  async generateKey() {
    const { data } = await Axios.get(`/spr/projectExtractors/generateKey`);
    return data.key;
  }

  async ping(projectExtractorKey) {
    const { data } = await Axios.post(`/spr/bimExtractor/${projectExtractorKey}/ping`);
    return data;
  }

  async requestConnection({ id, projectId }) {
    const { data } = await Axios.post(`/spr/projectExtractors/connectionRequests`, { id, projectId });
    return data;
  }

  async checkConnectionStatus(id) {
    const { data } = await Axios.get(`/spr/projectExtractors/connectionRequests/${id}`);
    return data;
  }

  async invalidateConnection(id) {
    try {
      await Axios.delete(`/spr/projectExtractors/connectionRequests/${id}`);
    } catch (e) {
      console.warn('Error on invalidateConnection()', { id }, e);
    }
  }

  async gatewayInfo() {
    const { data } = await Axios.get(`/spr/app/gateway`);
    return data;
  }

  async fetchLog(projectExtractorKey) {
    return await Axios.getData(`/spr/bimExtractor/${projectExtractorKey}/log`);
  }

}
