import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class PizzaPercent extends BaseKpi {
    constructor(props) {
        super(props);
    }

    initComponent = () => {
        let color = this.state.color;

        let kpiId = this.state.kpiId;
        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        if (j('#kpi-pizza' + kpiId).children.length === 2) {
            let canvas = j('#kpi-pizza' + kpiId).children()[1];
            j(canvas).remove();
        }

        j(`.easy-pie-chart-${kpiId}`).each(function () {

            let $box = j(this).closest('.infobox');
            let barColor = color || (!$box.hasClass('infobox-dark') ? $box.css('color') : 'rgba(255,255,255,0.95)');
            j('.percent', this).css('color', barColor);
            j(this).css('color', barColor);
            let trackColor = barColor === 'rgba(255,255,255,0.95)' ? 'rgba(255,255,255,0.25)' : '#E2E2E2';
            let size = parseInt(j('#kpi-pizza' + kpiId).height());
            let color1 = barColor;
            let color2 = trackColor;

            new EasyPieChart(this, {
                barColor: color1,
                trackColor: color2,
                scaleColor: false,
                lineCap: 'butt',
                lineWidth: parseInt(size / 10),
                animate: application.utils.disableAnimations() ? false : 1000,
                size: size
            });

            j(this).addClass("EasyPieChart-initialized");

        });
    };

    customResizeKpi = ({height}) => {
        this.$percentEl.style.whiteSpace = 'nowrap';

        resizeEl(this.$kpiTitleEl, {
            fontSize: {minValue: 12, multiplier: 0.1, refSize: height},
            lineHeight: {minValue: 14, multiplier: 0.13, refSize: height},
        });

        if (this.$kpiTitleEl.offsetWidth >= this.props.div.offsetWidth) {
            const newFontSize = height * (this.props.div.offsetWidth / this.$kpiTitleEl.offsetWidth);

            resizeEl(this.$kpiTitleEl, {
                fontSize: {minValue: 12, multiplier: 0.1, refSize: newFontSize},
                lineHeight: {minValue: 14, multiplier: 0.13, refSize: newFontSize},
            });
        }
        // Estes valores afetam o circul ao redor da porcentagem
        resizeEl(this.$kpiPizzaEl, {
            height: {minValue: 50, multiplier: 0.66, refSize: height},
            width: {minValue: 50, multiplier: 0.66, refSize: height},
        });
        // Este valor afeta o tamanho da fonte da porcentagem
        resizeEl(this.$percentEl, {
            fontSize: {value: `${height/6}px`},
        });

        if (this.$percentEl.offsetWidth >= (this.$kpiPizzaEl.offsetWidth * 0.8)) {
            let heightDiv = height * (this.$kpiPizzaEl.offsetWidth * 0.8 / this.$percentEl.offsetWidth);
            resizeEl(this.$percentEl, {
                fontSize: {value: `${heightDiv/6}px`},
            });
        }

        resizeEl(this.$kpiDescriptionEl, {
            fontSize: {minValue: 8, multiplier: 0.05, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.1, refSize: height},
        });

        if (this.$kpiDescriptionEl.offsetWidth >= this.props.div.offsetWidth) {
            const newFontSize = height * (this.props.div.offsetWidth / this.$kpiDescriptionEl.offsetWidth);

            resizeEl(this.$kpiDescriptionEl, {
                fontSize: {minValue: 8, multiplier: 0.05, refSize: newFontSize},
                lineHeight: {minValue: 12, multiplier: 0.1, refSize: newFontSize},
            });
        }
    };

    render() {
        let kpiId = this.state.kpiId;
        if (j('.kpi-preview-crud-box').length > 0) {
            kpiId = "Preview"
        }

        const targetValue = this.props.target === 0 ? 1 : this.props.target;

        let val = this.props.measureAsTarget || !this.props.targetIsPercent ?
                    this.props.percentage :
                    this.props.value / targetValue;

        if (this.props.percentage && !this.props.measureAsTarget && this.props.target === 0) {
            val *= 100;
        }

        return (
            <table>
                <tbody>
                <tr className="title-kpi-percent">
                    <td>
                        <div className="kpititle" style={{textAlign: 'center'}}>
                            <span ref={el => this.$kpiTitleEl = el}>{this.props.title}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="td-pizza-percent">
                        <div title={this.state.value} ref={el => this.$kpiPizzaEl = el} id={"kpi-pizza" + kpiId}
                             data-percent={val}
                             className={`easy-pie-chart percentage ${kpiId} easyPieChart easy-pie-chart-${kpiId}`}>
                            <div ref={el => this.$percentEl = el} className="percent" style={{
                                color: this.state.color
                            }}>{this.state.value}</div>
                        </div>
                    </td>
                </tr>
                <tr className="title-kpi-description">
                    <td>
                        <div className="kpidescription" style={{textAlign: 'center'}}>
                            <span ref={el => this.$kpiDescriptionEl = el}>{this.props.label}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div ref={el => this.$containerBandsEl = el}>
                            {this.useBands({container: this.$containerBandsEl})}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

}


