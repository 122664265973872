import {Dataset} from "components/service/data/Dataset";

export class QueryApi {

    /**
     *
     * @param projectName
     * @param projectId
     * @param dataSource
     * @param query
     * @param type
     * @returns {Promise<Dataset>}
     */
    async executeQuery({projectName, projectId, dataSource, query, type}) {
        const result = await jQuery.getJSON('/spr/query/execute', {
            projectName,
            projectId,
            dataSource,
            query,
            type
        });
        return new Dataset(result);
    }

}