import 'components/ui/presentation/MyPresentationList.css'
import 'components/ui/presentation/PresentationPublicLinkDialog.css'
import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import Dialog from "components/ui/Dialog";
import DropdownPublicLink from "components/ui/presentation/DropdownPublicLink";
import {MODALS} from "components/ui/redux/Actions";
import UiMsg from "components/ui/UiMsg";
import CreatePublicLink from "components/ui/presentation/CreatePublicLink";
import Icon from "components/ui/common/Icon";

class PresentationPublicLinkDialog extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        idPresentation: PropTypes.number,
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true),
    };

    state = {
        loading: true,
        publicLinks: [],
    };

    componentDidMount() {
        this.onUpdate();
    }

    onUpdate = async () => {
        try {
            const publicLinks = await Api.Presentation.findPublicLinks(this.props.idPresentation);
            this.setState({publicLinks});
        } catch (e) {
            console.error('Error onUpdate()', e);
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    openCreatePublickLink = () => {
        this.props.dispatch(MODALS.open(CreatePublicLink, {
            idPresentation: this.props.idPresentation,
            updateValues: this.onUpdate
        }));
    };

    createTableItem = (publiclink, i) => {

        let expirationDate = publiclink.permanent ? this.props.context.msg.t('permanent') : moment(publiclink.expirationDate).format('DD/MM/YYYY');

        return (
            <tr className="presentationlist-table-tr"
                style={{backgroundColor: i % 2 === 0 ? '#f6f6f6' : '#ffffff', height: 46}}
                key={publiclink.id}>
                <td className="presentationlist-table-td">
                    <div title={publiclink.identifier} className="div-information-presentation">
                        {publiclink.identifier}
                    </div>
                </td>
                <td className="presentationlist-table-td">
                    <div className="div-information-presentation" title={expirationDate}>{expirationDate}</div>
                </td>
                <td className="presentationlist-table-td">
                    <div>
                        <DropdownPublicLink publicLinkValues={publiclink}
                                            onUpdate={this.onUpdate}
                                            idPresentation={this.props.idPresentation}/>
                    </div>
                </td>
            </tr>
        );
    };

    render() {
        return (
            <Dialog contentFullWidth={true} className="PublicLinkListDialog small"
                    open={this.props.open} loading={this.state.loading}
                    title={this.props.context.msg.t('public.links')}
                    onClose={this.props.closeModal}>
                <div className="button-add-public-link-wrapper">
                    <div onClick={this.openCreatePublickLink}
                         title={this.props.context.msg.t('add.public.link')}
                         className="button-add-public-link">
                        <Icon icon="add_circle"/>
                        <label className="label-add-link">{this.props.context.msg.t('add.public.link')}</label>
                    </div>
                </div>

                <div className="scroll-bar-object-select">
                    <table className="table table-condensed table-border table-hover">
                        <thead>
                        <tr>
                            <th className="presentation-table-title">{this.props.context.msg.t('name')}</th>
                            <th className="presentation-table-title">{this.props.context.msg.t('presentation.public.link.has.expired')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.publicLinks.map(this.createTableItem)}
                        </tbody>
                    </table>
                </div>
            </Dialog>
        )
    }

}

export default ContextEnhancer(connect()(PresentationPublicLinkDialog));