// css imported on CommonCssImports.js
import React from "react";
import PropTypes from "prop-types";
import {resizeEl} from "components/ui/kpi/BaseKpi";

export const GrowthStatus = {
    'GT': {
        icon: "icon-arrow-up",
        cssClass: "green"
    }, //greater then
    'LT': {
        icon: "icon-arrow-down",
        cssClass: "red"
    }, //lower then
    'EQ': {
        icon: "icon-arrow-right",
        cssClass: ""
    } //equal
};

export class Growth extends React.Component {

    static propTypes = {
        growthViewModel: PropTypes.object,
        kpiId: PropTypes.any,
        div: PropTypes.any

    };

    static defaultProps = {
        growthViewModel: {}
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        GrowthStatus.LT.cssClass = this.props.growthViewModel.lowerThanColor;
        GrowthStatus.GT.cssClass = this.props.growthViewModel.greaterThanColor;
        this.resizeGrowth(this.props.width, this.props.height);
    }

    componentDidUpdate() {
        this.resizeGrowth(this.props.width, this.props.height);
    }

    resizeGrowth = (width, height, checkScroll = true) => {
        if (this.props.div) {

            resizeEl(this.$growthValueEl, {
                fontSize: {minValue: 0.08, multiplier: 0.06, refSize: height},
            });

            if (checkScroll && this.props.width < this.props.div.scrollWidth) {
                let x = (this.props.width / this.props.div.scrollWidth) * 0.9;
                height = height * (x);
                this.resizeGrowth(this.props.width, height, false);
            }
        }
    };


    render() {
        const growthStatus = GrowthStatus[this.props.growthViewModel.status];
        return (
            <div className="KpiGrowth">
                <span className={`kpi-comp-val-container`}
                      title={this.props.growthViewModel.info}
                      style={{color: growthStatus.cssClass}}>
                    <span style={{borderRadius: 0, padding: 2, fontWeight: 'bold'}}>
                        <span ref={el => this.$growthValueEl = el}>{this.props.growthViewModel.growth}</span>
                        <span>
                            <i className={growthStatus.icon}/>
                        </span>
                    </span>
                </span>
            </div>

        )
    }

}


