import styles from './BngColorPalette.module.css';

import React, { useMemo } from 'react';

import BngColorNode from 'components/bng/colorPicker/BngColorNode';

export default function BngColorPalette({
  themeColors = [],
  transparencyEnabled = true,
  color = { color: '#005dff', opacity: 1, position: 0, id: 0 },
  selectColor = _.noop,
}) {
  const initialColor = useMemo(() => {
    if (
      color.opacity === 0 ||
      (themeColors.find((c) => c === color.color) && (color.opacity === 1 || !color.opacity))
    ) {
      return null;
    } else {
      if (!Object.hasOwn(color, 'id')) {
        return {
          id: 0,
          ...color,
        };
      }
      return color;
    }
  }, [themeColors]);

  return (
    <div className="BngColorPalette">
      <div className={`ColorPaletteWrapper ${styles.colorPaletteWrapper}`}>
        {themeColors.map((c, idx) => {
          return (
            <BngColorNode
              nodeColor={{ color: c, opacity: c === 'transparent' ? 0 : 1, position: 0, id: 0 }}
              selectColor={selectColor}
              key={`node${idx}`}
              selectedColor={color}
            />
          );
        })}
        {transparencyEnabled && (
          <BngColorNode
            nodeColor={{ color: '#ffffff', opacity: 0, position: 0, id: 0 }}
            selectColor={selectColor}
            selectedColor={color}
          />
        )}
        {initialColor && <BngColorNode nodeColor={initialColor} selectColor={selectColor} selectedColor={color} />}
      </div>
    </div>
  );
}
