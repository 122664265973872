import './ProjectInvitePage.css';

import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Field, Formik } from 'formik';

import BngForm from 'components/bng/form/BngForm';
import BngField from 'components/bng/form/BngField';
import { BngPrimaryButton } from 'components/ui/Button';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import UiBlocker from 'components/bng/ui/UiBlocker';
import bngYup from 'components/bng/form/yup/BngYup';
import UiMsg from 'components/ui/UiMsg';
import CommonActivationPage from 'components/bng/pages/project/invites/CommonActivationPage';
import BngAvatarUploadInput from 'components/bng/form/BngAvatarUploadInput';
import BngPhoneInput from 'components/bng/form/BngPhoneInput';
import { passwordValidationFunction, passwordYupRule } from 'components/ui/profile/ChangePasswordDialog';
import useBimContext from 'components/hooks/useBimContext';
import useTranslation from 'components/hooks/useTranslation';

const FormSchema = bngYup((yup) =>
  yup.object({
    invitedUser: yup.object().default({}),
    displayName: yup.string().required().default(''),
    email: yup.string().required().default(''),
    phone: yup.string().default(''),
    password: passwordYupRule,
    passwordConfirmation: yup.string().required().default(''),
  })
);

function ProjectInviteInner({ inviteCode, formikProps }) {
  const context = useBimContext();
  const navigate = useNavigate();

  const $userInfo = useFetchData(async () => {
    try {
      const data = await Api.ProjectInvite.findInvite(inviteCode);

      formikProps.resetForm({
        values: {
          code: inviteCode,
          invitedUser: data?.invitedUser,
          displayName: data.invitedUser.displayName ?? '',
          email: data.invitedUser.email,
          phone: data.invitedUser.phone ?? '',
          avatar: null,
        },
      });

      return data;
    } catch (e) {
      console.error(e);
      navigate('/errors/403');
    }
  }, [inviteCode]);

  const validatePassword = useMemo(() => passwordValidationFunction(() => context.msg), []);

  return (
    <CommonActivationPage className="ProjectInvitePage">
      <UiBlocker block={formikProps.isSubmitting || $userInfo.isLoading} className="ProjectInviteWrapper">
        <div className="ProjectInviteContainer">
          <h2 style={{ marginTop: '0' }}>{context.msg.t('welcome.to.bim')}</h2>
          <div
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: context.msg.t('ProjectInvitePage.description', [
                $userInfo?.data?.whoInvites.displayName,
                $userInfo?.data?.project.displayName,
              ]),
            }}
          />
          <BngForm>
            <div>
              <div className="d-flex gap-4">
                <div className="flex-grow-1">
                  <div className="FieldsTable w-100">
                    <div>
                      <div>
                        <label className="control-label">{context.msg.t('full_name')}</label>
                        <Field name="displayName" component={BngField} withLabel={false} />
                      </div>
                      <div className="InfoContainers">
                        <div className="InfoItens">
                          <label className="control-label">{context.msg.t('email')}</label>
                          <Field name="email" component={BngField} withLabel={false} readOnly />
                        </div>
                        <div className="InfoItens ml-2">
                          <label className="control-label">{context.msg.t('phone')}</label>
                          <Field
                            name="phone"
                            component={BngField}
                            inputComponent={BngPhoneInput}
                            userLanguage={$userInfo?.data?.invitedUser.preferredLanguage ?? 'pt_BR'}
                            withLabel={false}
                          />
                        </div>
                      </div>
                      <>
                        <div className="InfoContainers">
                          <div className="InfoItens">
                            <label className="control-label">{context.msg.t('password')}</label>
                            <Field
                              name="password"
                              component={BngField}
                              type="password"
                              withLabel={false}
                              validate={validatePassword}
                            />
                          </div>
                          <div className="InfoItens ml-2">
                            <label className="control-label">
                              {context.msg.t('createNewUser_label_confirmPassword')}
                            </label>
                            <Field name="passwordConfirmation" type="password" component={BngField} withLabel={false} />
                          </div>
                        </div>
                        <div>
                          <div className="PasswordInfo">
                            <label className="mb-3">{context.msg.t('ProjectInvitePage.password.msg')}</label>
                          </div>
                          <div
                            className="AdditionalInfo mt-1 mb-3"
                            dangerouslySetInnerHTML={{ __html: context.msg.t('ProjectInvitePage.details') }}
                          ></div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-left">
                <BngPrimaryButton type="submit" className="SubmitButton">
                  {context.msg.t('start.now')}
                </BngPrimaryButton>
              </div>
            </div>
          </BngForm>
        </div>
        <div className="LogoAndAvatarContainer">
          <img
            src={`${Api.buildUrl(
              context.theme?.headerLogo !== 'resources/images/logo-bim-black-gray.png'
                ? context.theme?.headerLogo
                : `/resources/images/logo-bim-black-gray.png`
            )}`}
            alt="logo"
            className="ProjectLogo"
          />
          <div className="AvatarInput">
            <Field name="avatar">
              {({ field, form }) => <BngAvatarUploadInput field={field} form={form} selectImageText={true} />}
            </Field>
          </div>
        </div>
      </UiBlocker>
    </CommonActivationPage>
  );
}

export default function ProjectInvitePage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const initialValues = FormSchema.default();
  const inviteCode = searchParams.get('code');
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormSchema}
      onSubmit={async (values, formik) => {
        try {
          if (!values.password || values.password !== values.passwordConfirmation) {
            formik.setFieldError('passwordConfirmation', t('passwords_dont_match'));
            formik.setSubmitting(false);
            return;
          }
          await Api.ProjectInvite.acceptInvite({ code: inviteCode, ...values });
          formik.setStatus({ finished: true });
          window.location.replace(`${Api.baseUrl()}/spr/bng/login`);
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
          formik.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => <ProjectInviteInner formikProps={formikProps} inviteCode={inviteCode} />}
    </Formik>
  );
}
