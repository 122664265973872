import React from "react";
import {connect} from "react-redux";
import {NAVBAR} from "components/ui/redux/Actions";
import PresentationMenuButton from "components/ui/navbar/PresentationMenuButton";


const mapStateToProps = (state) => {
    return {
        presentations: state.navbar.presentations
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        findPresentations: () => dispatch(NAVBAR.findPresentations())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PresentationMenuButton);