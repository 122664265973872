import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class PiePercent extends BaseKpi {

    constructor(props) {
        super(props);
    }

    initComponent = ({height}) => {
        let color = this.state.color;
        let invert = this.props.invert;
        let backgroundColor = '#E0E0E0';

        let heightPercentValue = 0.55;
        let minHeightValue = 50;
        let maxRadiusHeight = 25;

        let heightCanvas = (height * (heightPercentValue)) < minHeightValue ? minHeightValue : (height * (heightPercentValue));

        let radius = (heightCanvas / 2.3);

        if (isNaN(radius)) {
            radius = 0;
        }

        if (invert === 'true') {
            backgroundColor = color;
            color = '#E0E0E0';
        }

        jQuery(this.$pieKpiEl).peity('pie', {
            fill: [color, backgroundColor],
            radius: (radius < maxRadiusHeight ? maxRadiusHeight : radius)
        });
    };

    customResizeKpi = ({height}) => {
        resizeEl(this.$kpiTitleEl, {
            fontSize: {minValue: 12, multiplier: 0.1, refSize: height},
            lineHeight: {minValue: 14, multiplier: 0.13, refSize: height},
        });

        if (this.$kpiTitleEl.offsetWidth >= this.props.div.offsetWidth) {
            const newFontSize = height * (this.props.div.offsetWidth / this.$kpiTitleEl.offsetWidth);

            resizeEl(this.$kpiTitleEl, {
                fontSize: {minValue: 12, multiplier: 0.1, refSize: newFontSize},
                lineHeight: {minValue: 14, multiplier: 0.13, refSize: newFontSize},
            });
        }

        resizeEl(this.$percentEl, {
            fontSize: {minValue: 12, multiplier: 0.08, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.08, refSize: height},
        });

        resizeEl(this.$kpiDescriptionEl, {
            fontSize: {minValue: 8, multiplier: 0.05, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.1, refSize: height},
        });

        if (this.$kpiDescriptionEl.offsetWidth >= this.props.div.offsetWidth) {
            const newFontSize = height * (this.props.div.offsetWidth / this.$kpiDescriptionEl.offsetWidth);

            resizeEl(this.$kpiDescriptionEl, {
                fontSize: {minValue: 8, multiplier: 0.05, refSize: newFontSize},
                lineHeight: {minValue: 12, multiplier: 0.1, refSize: newFontSize},
            });
        }
    };

    render() {
        let targetValue = this.props.target === 0 ? 1 : this.props.target;

        let percent = this.props.measureAsTarget || !this.props.targetIsPercent ?
            this.props.percentage + '' :
            this.props.value / targetValue + '';
        percent = percent.replace('%', '');

        if (this.props.percentage && !this.props.measureAsTarget && this.props.target === 0) {
            percent *= 100;
        }

        percent = this.props.useBands ? ((percent.replace(",", "."))) + "/100" : percent + "/100";

        return (
            <table>
                <tbody>
                <tr className="title-kpi-percent">
                    <td>
                        <div className="kpititle" style={{textAlign: 'center'}}>
                            <span ref={el => this.$kpiTitleEl = el}>{this.props.title}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}>
                        <span ref={el => this.$pieKpiEl = el} className="pie">{`${percent}`}</span>
                    </td>
                </tr>
                <tr className="title-kpi-percent">
                    <td>
                        <div title={this.state.value} className="kpi-percent" ref={el => this.$percentEl = el}
                             style={{textAlign: 'center', marginTop: '3px'}}>
                            {this.props.invert === 'true' ? this.state.percentage : this.state.value}
                        </div>
                    </td>
                </tr>
                <tr className="title-kpi-description">
                    <td>
                        <div>
                            <div style={{textAlign: 'center'}}>
                                    <span className="kpidescription"
                                          ref={el => this.$kpiDescriptionEl = el}>{this.props.label}</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <center>
                            <div ref={el => this.$containerBandsEl = el}>
                                {this.useBands({container: this.$containerBandsEl})}
                            </div>
                        </center>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

}


