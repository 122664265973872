import './SelectIconDialog.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import Api from 'components/Api';
import ContextEnhancer from 'components/ContextEnhancer';
import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import { arrayToOptionsI18n, BngSelect } from 'components/bng/form/BngSelect';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { localStore } from 'components/Storage';
import BngSearch from 'components/bng/ui/BngSearch';
import BngEmpty from 'components/bng/ui/BngEmpty';

const RECENT_ICONS_STORAGE_KEY = 'SelectIconDialog:RECENT_ICONS';
const RECENT_ICONS = localStore.get(RECENT_ICONS_STORAGE_KEY) ?? [];

const recentIcons = [{ icons: RECENT_ICONS, name: 'recent.icons' }];

const SelectIconSchema = bngYup((yup) => {
  return yup.object().shape({
    icon: yup.object().shape({
      name: yup.string().trim().default(''),
      type: yup.number().default(Icon.MATERIAL),
    }),
    icons: yup.array().default([]),
    group: yup.string().trim().default('all'),
  });
});

//This list is used when the user is searching for specific icons, because then it doesn't need groups and so it is faster to load this way.
//It is used to make the dumb translation work as well.
const addSearchPropToIcon = (icons = [], translations = {}) => {
  icons.forEach((group) => {
    group.icons.forEach((icon) => {
      const translation = translations[icon.id] || '';
      icon.caption = _.capitalize(icon.id.replaceAll('_', ' '));
      icon.search = `${translation} ${icon.caption}`.toLowerCase();
    });
  });
};

class SelectIconDialog extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
    icon: PropTypes.object,
    canBeEmpty: PropTypes.bool,
    afterSave: PropTypes.func,
    updateJsf: PropTypes.bool
  };

  static defaultProps = {
    afterSave: _.noop,
    canBeEmpty: false,
    updateJsf: true,
  };

  state = {
    loading: true,
    searchTerm: '',
    availableIcons: [],
    groupOpts: [],
  };

  async fetchIconTranslations() {
    const lang = window.__USER_LANG || 'en-US';
    if (!lang.includes('en')) {
      try {
        return await Api.Icon.findIconTranslations(lang);
      } catch (e) {
        console.error('Error on fetchIconTranslations()', { lang }, e);
      }
    }
    return {};
  }

  async componentDidMount() {
    try {
      const { icon } = this.props;
      if (icon) {
        this.initialFormValues.icon.name = icon.name;
      }

      const [iconsData, translations] = await Promise.all([Api.Icon.findIcons(), this.fetchIconTranslations()]);
      const availableIcons = _.concat(recentIcons, iconsData);
      addSearchPropToIcon(availableIcons, translations);

      const groupOpts = arrayToOptionsI18n(this.props.context, [
        'all',
        ...availableIcons.map((iconsGroup) => iconsGroup.name),
      ]);
      this.setState({
        availableIcons,
        groupOpts,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  handleSearch = (event) => {
    const searchTerm = event.target.value || '';
    if (event.key === 'Enter') {
      event.preventDefault();
      this.setState({ searchTerm });
    } else {
      this.updateSearchValue(searchTerm);
    }
  };

  updateSearchValue = _.debounce((searchTerm = '') => {
    this.setState({ searchTerm });
  }, 200);

  resetSearch = () => {
    this.setState({ searchTerm: '' });
  };

  saveInRecent = (name) => {
    const iconIndex = RECENT_ICONS.findIndex((icon) => icon.id === name);
    if (iconIndex > -1) {
      RECENT_ICONS.splice(iconIndex, 1);
    }

    if (name === '') return;

    RECENT_ICONS.unshift({ id: name });

    if (RECENT_ICONS.length > 14) {
      RECENT_ICONS.pop();
    }
    localStore.put(RECENT_ICONS_STORAGE_KEY, RECENT_ICONS);
  };

  save = async (values, actions) => {
    try {
      await this.props.onSelect(values.icon);
      this.saveInRecent(values.icon.name);
      await this.props.afterSave();
      if(this.props.updateJsf) {
        Api.updateJsf();
      }
      this.props.closeModal();
    } catch (e) {
      UiMsg.error(e);
      actions.setSubmitting(false);
    }
  };

  initialFormValues = SelectIconSchema.default();

  render() {
    return (
      <Formik initialValues={this.initialFormValues} onSubmit={this.save}>
        {({ values, isSubmitting }) => {
          return (
            <Dialog
              title={this.props.context.msg.t('select.icon')}
              onClose={this.props.closeModal}
              loading={isSubmitting || this.state.loading}
              className="SelectIconDialog large"
              contentFullWidth={true}
            >
              <Form>
                <div className="select-icon-dialog-body">
                  <div className="SearchContainer">
                    <div className="w-100">
                      <BngSearch
                        alwaysOpen={true}
                        inline={true}
                        onChange={(searchValue, clear = false) => {
                          if (clear) {
                            this.resetSearch();
                          } else {
                            this.updateSearchValue(searchValue);
                          }
                        }}
                        onKeyDown={this.handleSearch}
                        title={this.props.context.msg.t('search.object.name')}
                      />
                    </div>
                    <div className="flex-center-items jc-center">
                      <Field name="group" component={BngSelect} emptyOption={false} options={this.state.groupOpts} />
                    </div>
                  </div>
                  <Icons
                    icons={this.state.availableIcons}
                    group={values.group}
                    {...this.props}
                    searchTerm={this.state.searchTerm}
                  />
                </div>
                <DefaultDialogActions okLabel="select" buttonClass="bng-button" {...this.props} />
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    );
  }
}

const Icons = ContextEnhancer(
  class IconsInner extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
      const { icons, group, context, searchTerm } = this.props;
      return (
        icons !== nextProps.icons ||
        group !== nextProps.group ||
        context !== nextProps.context ||
        searchTerm !== nextProps.searchTerm
      );
    }

    onSelectIcon = (e, form, icon) => {
      const isSelected = form.values.icon.name === icon.id;
      if (isSelected) {
        form.setFieldValue('icon.name', '');
      } else {
        form.setFieldValue('icon.name', icon.id);
      }
    };

    render() {
      const { icons, group, context, searchTerm } = this.props;

      let iconList = icons || [];

      if (group !== 'all') {
        iconList = [iconList.find((iconGroup) => iconGroup.name === group)];
      }

      const containSearch = !_.isEmpty(searchTerm);
      if (containSearch) {
        const lowerSearch = searchTerm.toLowerCase();
        const filteredIcons = iconList
          .filter((iconGroup) => iconGroup.name !== 'recent.icons')
          .flatMap((iconGroup) => {
            return iconGroup.icons;
          })
          .filter((icon) => icon.search.includes(lowerSearch));
        iconList = [
          {
            icons: filteredIcons,
          },
        ];
      }
      return (
        <div className="Icons">
          <BngEmpty
              isEmpty={(containSearch && _.isEmpty(iconList[0]?.icons))}
              message={`${this.props.context.msg.t('no.result.found.for')} '${searchTerm}'`}
          >
          {iconList
            .filter((icon) => icon.icons.length > 0)
            .map((iconGroup, index) => {
              return (
                <div className="IconsBox" key={index}>
                  {iconGroup.name && (
                    <div className="IconsTitle">
                      <span>{context.msg.t(iconGroup.name)}</span>
                    </div>
                  )}
                    <div className="IconsList">
                      {iconGroup.icons?.map((icon, index) => (
                        <Field
                          name="icon"
                          key={index}
                          component={IconComponent}
                          icon={icon}
                          onClick={this.onSelectIcon}
                        />
                      ))}
                    </div>
                </div>
              );
            })}
          </BngEmpty>
        </div>
      );
    }
  }
);

const IconComponent = ({ icon, field, form, onClick }) => (
  <Icon
    icon={icon.id}
    className={field.value.name === icon.id ? 'selected' : ''}
    type={field.value.type}
    onClick={(e) => onClick(e, form, icon)}
    title={icon.caption}
  />
);

export default ContextEnhancer(SelectIconDialog);
