import './BngAnalysisTypeSelected.css';

import React, { useEffect, useRef } from 'react';

import Button from 'components/ui/Button';
import { BngHorizontalCard } from 'components/bng/ui/BngHorizontalCard';
import { BngHorizontalScroller } from 'components/bng/ui/BngHorizontalScroller';
import BngAssistedAnalysisForm from 'components/bng/pages/newAnalysis/BngAssistedAnalysisForm';
import useBimContext from 'components/hooks/useBimContext';
import useEventBus from 'components/hooks/useEventBus';

export const typeToHorizontalCardProps = (item, context) => {
  return {
    className: item.type,
    'data-test': item.type,
    icon: item.icon,
    title: item.title,
    text: item.text,
    tooltip: item.tooltip,
    isNew: item.isNew,
    isBeta: item.isBeta,
    disabled: item.disabled,
    labelText: item.disabled ? context.msg.t('coming.soon') : undefined,
    image: item.image,
  };
};

export default function BngAnalysisTypeSelected({
  onGoBack = _.noop,
  onSelectType = _.noop,
  types = [],
  selectedType,
  service,
  createObjectLabel,
  formBottomSlot,
  embeddedOpts,
}) {
  const context = useBimContext();
  const $container = useRef();
  const $scroller = useRef();

  const selectedTypeMatch = types.find((data) => data.type === selectedType);
  const previewImage = service.findPreviewImages(selectedTypeMatch);

  const changeType = (type, { event }) => {
    if (type === selectedType) return;

    const eventPath = event.nativeEvent.path ?? event.nativeEvent.composedPath?.();
    onSelectType(type);
    const card = eventPath?.find((e) => e.classList.contains('BngHorizontalCard'));
    if (card) {
      $scroller.current?.centerOn(card);
    }
  };

  const findSelectedCard = () => {
    if (!$container.current) return;
    return $container.current.querySelector('.BngHorizontalCard.selected');
  };

  useEffect(() => {
    application.utils.waitFor(
      () => {
        return !!findSelectedCard();
      },
      () => {
        const element = findSelectedCard();
        $scroller.current.centerOn(element);
      }
    );
  }, []);

  useEventBus('BngAnalysisTypeSelected:goBack', () => {
    onGoBack();
  }, [onGoBack]);

  return (
    <div className="BngAnalysisTypeSelected" ref={$container}>
      {!embeddedOpts && (
        <div className="mb-3">
          <Button className="btn-transparent" icon={'keyboard_backspace'} onClick={onGoBack}>
            <span className="ml-2">{context.msg.t('back_button')}</span>
          </Button>
        </div>
      )}

      <div className="d-flex">
        {!embeddedOpts && (
          <div className="w-100 mr-4">
            <div className="mb-3">
              <BngHorizontalScroller ref={$scroller}>
                {types.map((item, idx) => {
                  const selected = item.type === selectedTypeMatch.type;
                  return (
                    <BngHorizontalCard
                      key={idx}
                      {...typeToHorizontalCardProps(item, context)}
                      onClick={(event) => {
                        changeType(item.type, { event });
                      }}
                      className={`${selected ? 'selected' : ''}`}
                    />
                  );
                })}
              </BngHorizontalScroller>
            </div>
            <div className="BngFlatPanel Preview text-center">
              <div>
                {previewImage ? (
                  (Array.isArray(previewImage) ? previewImage : [previewImage]).map((image, idx) => (
                    <img key={idx} src={image} alt="Type preview" />
                  ))
                ) : (
                  <span>{context.msg.t('no.preview.available')}</span>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="RightPanel">
          {!embeddedOpts && (
            <div>
              <BngHorizontalCard {...typeToHorizontalCardProps(selectedTypeMatch, context)} />
            </div>
          )}
          <div className="ParamsBox">
            {!embeddedOpts && <div className="Explanation">{selectedTypeMatch.explanation}</div>}

            <BngAssistedAnalysisForm
              type={selectedTypeMatch}
              service={service}
              createObjectLabel={createObjectLabel}
              bottomSlot={formBottomSlot}
              embeddedOpts={embeddedOpts}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
