import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import BreadcrumbToolbar from 'components/ui/breadcrumb-toolbar/BreadcrumbToolbar';
import UiMsg from "components/ui/UiMsg";
import Utils from "components/Utils";

class FavoriteToolbarItem extends PureComponent {

    static propTypes = {
        path: PropTypes.string.isRequired
    };

    static defaultProps = {
        path: ''
    };

    state = {
        isFavorite: false,
        path: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.check();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.path !== prevProps.path) {
            this.check();
        }
    }

    check = async () => {
        try {
            const result = await Api.User.findFavorites(this.props.path);
            for (const row of result) {
                if (row.href === this.props.path) {
                    this.setState({isFavorite: true});
                    break;
                }
            }
        } catch (e) {
            this.setState({isFavorite: false});
        }
    };

    toggleFavorite = async () => {
        const {user, project} = this.props.context;
        const params = {userId: user.id, projectId: project.id, path: this.props.path};
        try {
            if (this.state.isFavorite) {
                await Api.User.removeFavorite(params);
                this.setState({isFavorite: false});
                UiMsg.ok(this.props.context.msg.t(`${Utils.Object.getObjectType(this.props.path)}.removed.from.favorite`));
            } else {
                await Api.User.addFavorite(params);
                this.setState({isFavorite: true});
                UiMsg.ok(this.props.context.msg.t(`${Utils.Object.getObjectType(this.props.path)}.added.to.favorite`));
            }
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const {path, disabled} = this.props;
        const {isFavorite} = this.state;
        return (
            <BreadcrumbToolbar.Item icon={`${isFavorite ? 'star' : 'star_border'}`}
                                    disabled={disabled || path.length === 0}
                                    inactive={!isFavorite}
                                    title={this.props.context.msg.t('toggle.favorite.title')}
                                    onClick={this.toggleFavorite}
                                    material={true}
            />
        );
    }
}

export default ContextEnhancer(FavoriteToolbarItem);
