import 'react-toastify/dist/ReactToastify.css';
import './UiMsg.css';

import { toast, ToastContainer } from 'react-toastify';
import React, { useMemo } from 'react';

import useBimContext from 'components/hooks/useBimContext';
import StackTraceDialog from 'components/bng/pages/errors/StackTraceDialog';
import Icon from 'components/ui/common/Icon';
import { ceData } from 'components/CeData';
import Utils from 'components/Utils';

const DEFAULT_OPTS = Object.freeze({
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 7000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  theme: 'colored',
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
});

// Ref: https://stackoverflow.com/a/10574546/10754870
function extractJsonFromString(str) {
  let firstOpen, firstClose, candidate;
  firstOpen = str.indexOf('{');
  do {
    firstClose = str.lastIndexOf('}');
    if (firstClose <= firstOpen) {
      return null;
    }
    do {
      candidate = str.substring(firstOpen, firstClose + 1);
      try {
        return JSON.parse(candidate);
      } catch (ignored) {}
      firstClose = str.substr(0, firstClose).lastIndexOf('}');
    } while (firstClose > firstOpen);
    firstOpen = str.indexOf('{', firstOpen + 1);
  } while (firstOpen !== -1);

  return null;
}

const defaultMessageDetailsProcessor = (details = '') => {
  let message = '' + (details || '');
  let requestId = '';
  let detailsString = message;

  if (_.isObjectLike(details)) {
    message = details.message || '';
    requestId = details.requestId || '';
    detailsString = message;
  }

  try {
    // Aqui caso necessário podemos incrementar com processamentos necessários para erros mais 'específicos' como o caso
    // do SoapFault
    const parsedDetails = extractJsonFromString(detailsString);
    if (parsedDetails) {
      // Identify Soap error
      if (parsedDetails.hasOwnProperty('faultActor')) {
        message = parsedDetails.errorDescription || detailsString;
      }
    }
  } catch (e) {
    console.error('Error on defaultMessageDetailsProcessor() during detail processing', e);
  }

  let isHtml = message.indexOf('<') !== -1;

  let isHugeMessage = message.length > 256 && !isHtml;
  if (isHugeMessage) {
    message = message.slice(0, 256);
  }

  return {
    message,
    details: detailsString,
    isHugeMessage,
    isHtml,
    requestId,
  };
};

const ToastLayout = ({ type, title = '', details = '', traceButton, closeToast }) => {
  const { msg } = useBimContext();

  details = details || ''; // prevent nulls/undefined values

  const processedDetails = useMemo(() => {
    return defaultMessageDetailsProcessor(details);
  }, [details]);

  const showTraceButton =
    traceButton ||
    processedDetails.isHugeMessage ||
    processedDetails.isHtml ||
    (processedDetails.message && processedDetails.details && processedDetails.message !== processedDetails.details);

  return (
    <div className={'toast-parent'}>
      <div className={`toast-flex-up-column ${type}`}>
        <h5 className={`toast-title ${type} ${showTraceButton ? 'hasTrace' : ''}`}>{title}</h5>
        <div className={`toast-buttons ${showTraceButton ? 'hasTrace' : ''}`}>
          {showTraceButton && (
            <Icon
              title={msg.t('view.log')}
              icon="fullscreen"
              onClick={() => {
                StackTraceDialog({
                  msg,
                  title: 'Log',
                  html: _.escape(processedDetails.details),
                  copyText: msg.t('copy.task.stack.trace'),
                  requestId: processedDetails.requestId,
                  onConfirm: async () => {
                    try {
                      await navigator.clipboard.writeText(processedDetails.details);
                      UiMsg.ok(msg.t('copied.stack.trace.to.clipboard'));
                    } catch (e) {
                      console.error('Erro while trying to copy log text to clipboard', e);
                      UiMsg.error(msg.t('copied.stack.trace.to.clipboard.fail'));
                    }
                  },
                });
              }}
              className="zoom-out-toast-button"
            />
          )}
          <Icon title={msg.t('close')} icon="close" onClick={closeToast} className="close-toast-button" />
        </div>
      </div>

      {processedDetails.message && (
        <p className={`toast-details ${type}`}>
          {processedDetails.isHtml ? (
            <span dangerouslySetInnerHTML={{ __html: processedDetails.message }} />
          ) : (
            processedDetails.message
          )}
        </p>
      )}
    </div>
  );
};

export function UiMsgContainer() {
  return <ToastContainer closeButton={false} icon={false} />;
}

class UiMsg {
  static activeToasts = [];

  static ok(title, details = '', opts = {}) {
    return UiMsg.send({
      type: 'info',
      title,
      details,
      opts: {
        closeOnClick: true,
        ...opts,
      },
    });
  }

  static warn(title, details = '', opts = {}) {
    return UiMsg.send({
      type: 'warn',
      title,
      details,
      opts: {
        closeOnClick: true,
        ...opts,
      },
    });
  }

  static error(title, details = '', opts = {}) {
    return UiMsg.send({
      type: 'error',
      title,
      details,
      opts: {
        autoClose: false,
        closeOnClick: false,
        ...opts,
      },
    });
  }

  static send({ type, title, details, opts = {} }) {
    const messageKey = Utils.Strings.hash(`${title}-${type}-${details}`);

    UiMsg.activeToasts.filter((t) => t.messageKey === messageKey).forEach((t) => t.dismiss());

    const typeClass = {
      info: 'info',
      warn: 'warning',
      error: 'error',
    };

    const contentFactory = (props) => {
      return <ToastLayout type={typeClass[type]} traceButton={props.opts?.traceButton ?? false} {...props} />;
    };

    const toastId = toast[type](contentFactory({ title, details, opts }), {
      ...DEFAULT_OPTS,
      ...opts,
      onClose: () => {
        const idx = UiMsg.activeToasts.findIndex((t) => t.id === toastId);
        if (idx !== -1) {
          UiMsg.activeToasts.splice(idx, 1);
        }
        opts?.onClose?.();
      },
    });

    const toastInstance = {
      id: toastId,
      messageKey,
      dismiss: () => {
        toast.dismiss(toastId);
      },
      update: ({ title, details, opts }) => {
        toast.update(toastId, {
          render: contentFactory({ title, details, opts }),
        });
      },
    };

    UiMsg.activeToasts.push(toastInstance);

    return toastInstance;
  }

  static ajaxError(title, e, opts = {}) {
    const i18n = ceData.context?.msg;
    title = title ? i18n?.t(title) : i18n?.t('error') ?? 'Error';
    let details = _.isString(e) ? e : e.message;

    if (e && e.isAxiosError) {
      const {
        response: { data },
      } = e;
      if (data?.requestId) {
        if (!data.message) {
          data.message = details;
        }
        details = data;
      } else {
        details = data?.message ?? data ?? details;
      }
    } else if (e.responseJSON) {
      details = e.responseJSON?.message ?? e.responseText;
    } else if (e.responseText) {
      details = e.responseText;
    }

    UiMsg.error(title, details, opts);
  }

  static dismiss(toastId) {
    toast.dismiss(toastId);
  }
}

export default UiMsg;
