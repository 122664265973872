import Dialog from "components/ui/Dialog";
import Api from "components/Api";
import React, {Component} from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import './LoadLimitDialog.css';
import BngSwitch from 'components/bng/form/BngSwitch';
import LoadingBox from "components/ui/loading/LoadingBox";
import UiMsg from "components/ui/UiMsg";
import {bngYup} from "components/bng/form/yup/BngYup";

const LoadLimitsSchema = bngYup(yup => {
    let rule = yup.number().positive().integer().nullable().max(Number.MAX_SAFE_INTEGER);
    return yup.array().of(yup.object().shape({
        linesIncremental: rule,
        linesTotal: rule,
        queryTime: rule,
    }));
});


class LoadLimitDialog extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        accountId: PropTypes.string
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true)
    };

    state = {
        loading: false,
        error: false,
        success: false,
        loadLimits: [],
        editing: false,
        showSaveButton: false,
        showEditButton: false,
        useCustomLimits: false,
        useCustomLimitsInitial: false,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetchLoadLimits(this.props.accountId);
    }

    fetchLoadLimits = async (accountId) => {
        await this.setState({loading: true});
        let loadLimits = [];
        let showEditButton = false;

        try {
            if (accountId) {
                loadLimits = await Api.LoadLimit.getAccountLimits(accountId);

                if (_.isEmpty(loadLimits)) {
                    loadLimits = await Api.LoadLimit.getGlobalLimits();
                    loadLimits = loadLimits.map((l) => {
                        l.id = null;
                        return l;
                    });
                    this.setState({useCustomLimits: false, useCustomLimitsInitial: false});
                } else {
                    showEditButton = true;
                    this.setState({useCustomLimits: true, useCustomLimitsInitial: true});
                }

            } else {
                showEditButton = true;
                loadLimits = await Api.LoadLimit.getGlobalLimits();
            }

            await this.setState({loadLimits: loadLimits, showEditButton: showEditButton});

        } catch (e) {
            this.setState({error: true});
        } finally {
            this.setState({loading: false});
        }

    };

    onSubmit = async () => {

        if (!(await this.validate(this.state.loadLimits))) {
            this.setState({error: true});
            return;
        }

        this.setState({loading: true});

        try {
            let result;

            if (this.state.useCustomLimitsInitial && !this.state.useCustomLimits) {
                result = await Api.LoadLimit.delete(this.props.accountId);
            } else {
                result = await Api.LoadLimit.save(this.props.accountId, this.state.loadLimits);
            }

            if (result) {
                this.setState({success: true, error: false});
                UiMsg.ok(this.props.context.msg.t('loadLimits.success'));
                this.props.closeModal();
                return;
            } else {
                this.setState({error: true, success: false});
                this.setState({loading: false});
            }

        } catch (e) {
            this.setState({error: true, success: false});
            this.setState({loading: false});
        }
    };

     validate = async (loadLimits) => {

         let data = loadLimits.map((l) => {
             return {
                 ...l,
                 linesIncremental: l.linesIncremental === '' ? null : l.linesIncremental,
                 linesTotal: l.linesTotal === '' ? null : l.linesTotal,
                 queryTime: l.queryTime === '' ? null : l.queryTime,
             }
         })

         try {
             await LoadLimitsSchema.validate(data);
             return true;
         } catch (e) {
             return false;
         }
    };

    onChangeTableValue = (ev, index, field) => {
        let loadLimitsClone = [].concat(this.state.loadLimits);
        loadLimitsClone[index][field] = ev.target.value;
        this.setState({loadLimits: loadLimitsClone});
    };

    onChangeCustomLimits = () => {
        let newUseCustomLimits = !this.state.useCustomLimits;

        if (newUseCustomLimits) {
            this.setState({
                editing: true,
                showSaveButton: true,
                showEditButton: false
            });
        } else {
            if (this.state.useCustomLimitsInitial) {
                this.setState({
                    editing: false,
                    showSaveButton: true,
                    showEditButton: false
                });
            } else {
                this.setState({
                    editing: false,
                    showSaveButton: false,
                    showEditButton: false
                });
            }
        }

        this.setState({
            useCustomLimits: newUseCustomLimits
        });
    };

    formatTableNumber = (number) => {
        let {context} = this.props;

        return number ?
            (number).toLocaleString(navigator.language) :
            context.msg.t('loadLimits.unlimited');
    };

    renderLoadLimitTableLine = (loadLimit, index) => {

        if (this.state.editing) {
            return (
                <tr className="load-limit-table-tr" key={index}>
                    <td style={{paddingLeft: 20}}>{loadLimit.intervalLow}</td>
                    <td><input className="load-limit-table-input"
                               onChange={(ev) => this.onChangeTableValue(ev, index, 'queryTime')}
                               value={loadLimit.queryTime}/></td>
                    <td><input className="load-limit-table-input"
                               onChange={(ev) => this.onChangeTableValue(ev, index, 'linesIncremental')}
                               value={loadLimit.linesIncremental ? loadLimit.linesIncremental : ''}/></td>
                    <td><input className="load-limit-table-input"
                               onChange={(ev) => this.onChangeTableValue(ev, index, 'linesTotal')}
                               value={loadLimit.linesTotal ? loadLimit.linesTotal : ''}/></td>
                </tr>
            );
        } else {
            return (
                <tr className="load-limit-table-tr" key={index}>
                    <td style={{paddingLeft: 20}}>{loadLimit.intervalLow}</td>
                    <td>{this.formatTableNumber(loadLimit.queryTime)}</td>
                    <td>{this.formatTableNumber(loadLimit.linesIncremental)}</td>
                    <td>{this.formatTableNumber(loadLimit.linesTotal)}</td>
                </tr>
            );
        }

    };

    renderDialogHeader = () => {

        let {context} = this.props;

        if (!this.props.accountId) {
            return (
                <div className="load-limit-dialog-header">
                    <span>{context.msg.t('loadLimits.globalConfig')}</span>
                </div>
            );
        }

        return (
            <div className="load-limit-dialog-header">
                <span>{this.state.useCustomLimits ? '' : context.msg.t('loadLimits.usingGlobalConfig')}</span>

                <BngSwitch
                    id="load-limits-switch"
                    onChange={this.onChangeCustomLimits}
                    checked={this.state.useCustomLimits}
                    title={context.msg.t('loadLimits.customLimits')}/>
            </div>
        );
    };

    onClickEditButton = () => {
        this.setState({
            editing: true,
            showEditButton: false,
            showSaveButton: true
        });
    };

    renderDialogContent = () => {

        let {context} = this.props;

        if (this.state.loading) {
            return (
                <LoadingBox/>
            );
        }

        if (this.state.success) {
            return (
                <h4>{context.msg.t('loadLimits.success')}!</h4>
            );
        }

        return (
            <>
                {this.renderDialogHeader()}

                <table
                    className={`load-limit-table table table-condensed table-border table-hover ${this.props.accountId && !this.state.useCustomLimits ? 'load-limit-table-global' : ''}`}>
                    <thead>
                    <tr>
                        <th style={{width: 120, paddingLeft: 20}}>{context.msg.t('loadLimits.interval')}</th>
                        <th style={{width: 250}}>{context.msg.t('loadLimits.queryTime')}</th>
                        <th style={{width: 250}}>{context.msg.t('loadLimits.incrementalLoadLines')}</th>
                        <th style={{width: 250}}>{context.msg.t('loadLimits.totalLoadLines')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.loadLimits.map(this.renderLoadLimitTableLine)}
                    </tbody>
                </table>

                <div className="row-fluid">
                    <div className="span6">
                        <div style={{padding: 10}}>
                            {this.state.editing ? (
                                <p className="load-limit-help-text">{context.msg.t('loadLimits.blankForIlimited')}</p>
                            ) : null}

                            {this.state.error ? (
                                <p className="load-limit-error-text">{context.msg.t('loadLimits.saveError')}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="span6 text-right btn-fix">
                        <Button onClick={this.props.closeModal} className="bng-button cancel">
                            {context.msg.t('close')}
                        </Button>

                        {this.state.showEditButton ? (
                            <Button style={{width: 130}} className="bng-button save" onClick={this.onClickEditButton}>
                                {context.msg.t('edit_button')}
                            </Button>
                        ) : null}

                        {this.state.showSaveButton ? (
                            <Button style={{width: 130}} className="bng-button save" onClick={this.onSubmit}>
                                {context.msg.t('save_button')}
                            </Button>
                        ) : null}

                    </div>
                </div>
            </>
        )
    }

    render() {
        let {context} = this.props;

        return (
            <Dialog contentFullWidth={true} className="large" open={this.props.open}
                    title={context.msg.t('loadLimits')}
                    onClose={this.props.closeModal}>
                {this.renderDialogContent()}
            </Dialog>);
    }

}


export default ContextEnhancer(LoadLimitDialog);