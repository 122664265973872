import './ExpertFormulaDialog.css';
import * as React from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import Dialog from "../Dialog";
import Free from "components/ui/analysis/expert/Free";
import {Field, Validations, wrapForm} from "components/ui/FormUtils";
import Constant from "components/ui/analysis/expert/Constant";
import {SubmissionError} from "redux-form";
import Api from "components/Api";
import Share from "components/ui/analysis/expert/Share";
import DimensionGrouping from "components/ui/analysis/expert/DimensionGrouping";
import UiMsg from "components/ui/UiMsg";

const findCubeMeta = async (formula) => {
    const cubeMeta = await j.getJSON('/spr/ui/analysis/cube-metadata');

    const formulaName = _.get(formula, 'name', '');
    cubeMeta.calculatedMeasures = cubeMeta.calculatedMeasures.filter(m => m.visible && m.name !== formulaName);

    cubeMeta.allMeasures = cubeMeta.measures.concat(cubeMeta.calculatedMeasures);
    return cubeMeta;
};

const ops = {
    Free: {
        component: Free,
        async onMount(instance, formula) {
            const formatStrings = await j.getJSON('/spr/ui/analysis/format-strings');
            const cubeMeta = await findCubeMeta(formula);
            const helpFormulas = await j.getJSON('/spr/ui/analysis/help/formulas');
            helpFormulas.sort((first, second) => first.label.localeCompare(second.label));
            instance.setState({formatStrings, cubeMeta, helpFormulas});
        },
        async onSave(data, instance) {
            return await j.postJSON('/spr/ui/analysis/expert/free', data);
        },
        async onPreview({data, mdxOnly}) {
            return await j.postJSON(`/spr/ui/analysis/expert/free/preview${mdxOnly ? '?mdxOnly=true' : ''}`, data);
        }
    },
    Share: {
        component: Share,
        async onMount(instance, formula) {
            const formatStrings = await j.getJSON('/spr/ui/analysis/format-strings');
            const cubeMeta = await findCubeMeta(formula);
            instance.setState({formatStrings, cubeMeta});
        },
        async onSave(data, instance) {
            return await j.postJSON('/spr/ui/analysis/expert/share', data);
        },
        async onPreview({data, mdxOnly}) {
            return await j.postJSON(`/spr/ui/analysis/expert/share/preview${mdxOnly ? '?mdxOnly=true' : ''}`, data);
        }
    },
    DimensionGrouping: {
        component: DimensionGrouping,
        processData(data) {
            const x = {...data};
            x.members = Object.keys(x.members);
            return x;
        },
        async onMount(instance, formula) {
            const formatStrings = await j.getJSON('/spr/ui/analysis/format-strings');
            const cubeMeta = await findCubeMeta(formula);
            instance.setState({formatStrings, cubeMeta});
        },
        async onSave(data, instance) {
            return await j.postJSON('/spr/ui/analysis/expert/dimension-grouping', this.processData(data));
        },
        async onPreview({data, mdxOnly}) {
            return await j.postJSON(`/spr/ui/analysis/expert/dimension-grouping/preview${mdxOnly ? '?mdxOnly=true' : ''}`, this.processData(data));
        }
    },
    Constant: {
        component: Constant,
        async onMount(instance) {
            const formatStrings = await j.getJSON('/spr/ui/analysis/format-strings');
            instance.setState({formatStrings});
        },
        async onSave(data, instance) {
            return await j.postJSON('/spr/ui/analysis/expert/constant', data);
        },
        async onPreview({data, mdxOnly}) {
            return await j.postJSON(`/spr/ui/analysis/expert/constant/preview${mdxOnly ? '?mdxOnly=true' : ''}`, data);
        }
    }
};


class ExpertFormulaDialog extends React.Component {

    static propTypes = {
        type: PropTypes.oneOf(['Free', 'Share', 'Constant', 'DimensionGrouping']).isRequired,
        callback: PropTypes.func,
        formula: PropTypes.object
    };

    static defaultProps = {
        callback: _.noop
    };

    state = {
        preview: null
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await ops[this.props.type].onMount(this, this.props.formula);
        this.setState({depsLoaded: true})
    }

    componentWillUnmount() {
        this.props.destroy();
    }


    save = async (data) => {
        const cubeMeta = await findCubeMeta();

        cubeMeta.allMeasures.forEach(measure => {
            if (measure.name === data.name && !this.props.formValues.id) {
                throw new SubmissionError({name: this.props.context.msg.t('expert.dialog.name.in.use')});
            }
        });

        try {
            const result = await this.getOp().onSave(data, this);
            this.props.callback(result);
            this.props.closeModal();
            Api.updateJsf();
        } catch (e) {
            this.sendMdxError(e);
        }
    };

    getOp() {
        return ops[this.props.type];
    }

    previewMdx = async data => {
        try {
            const mdxQueries = await this.getOp().onPreview({data, mdxOnly: true});
            this.setState({preview: `<pre class="mdx-box" onclick="event.stopPropagation();">${mdxQueries.join('<hr/>')}</pre>`});
        } catch (e) {
            console.error(e);
            this.sendMdxError(e);
        }
    };

    previewFormula = async data => {
        try {
            const html = await this.getOp().onPreview({data});
            this.setState({preview: html})
        } catch (e) {
            console.error(e);
            this.sendMdxError(e);
        }
    };

    sendMdxError = (e) => {
        const error = JSON.parse(e.responseText);
        let errorMessage = error.message;

        if (e.responseText.includes('OlapException')) {
            const olapException = errorMessage.split('OlapException:')[1];
            const parsed = new DOMParser().parseFromString(olapException, 'text/html');
            errorMessage = parsed.body.textContent;
        }

        UiMsg.error(
          this.props.context.msg.t('remove_confirmation_title'),
          errorMessage,
          { traceButton: true }
        );
    };

    closePreview = () => this.setState({preview: null});

    render() {
        if (!this.props.initialized || !this.props.context.permissions.isAtLeastExpert()) {
            return null;
        }

        const {formValues} = this.props;
        const {preview} = this.state;

        const OperationComponent = this.getOp().component;
        return (

            <Dialog open={this.props.open}
                    className={`ExpertFormulaDialog large ${preview ? 'preview-on' : ''}`}
                    title={this.props.context.msg.t(`expert.dialog.${this.props.type}.title`)}
                    onClose={this.props.closeModal}>


                <div className={`form-container`}>

                    <form onSubmit={this.props.handleSubmit(this.save)}>

                        <div className="row-fluid">
                            <div className="span8">
                                <div className="name-input">
                                    <Field name="name"
                                           label={this.props.context.msg.t('name')}
                                           readOnly={formValues && formValues.id}
                                           type="text"
                                           validate={Validations.required}/>
                                </div>
                            </div>
                            <div className="span4">
                                <div className="name-input">
                                    <Field name="order"
                                           label={this.props.context.msg.t('order')}
                                           type="text"
                                           validate={[Validations.required, Validations.isInt]}/>
                                </div>
                            </div>
                        </div>

                        {this.state.depsLoaded &&
                        <OperationComponent {...this.props}
                                            {...this.state}
                        />
                        }

                        <hr/>

                        <div className="text-right">
                            <Button className="btn-inverse"
                                    icon="icon-code"
                                    disabled={this.props.submitting}
                                    onClick={this.props.handleSubmit(this.previewMdx)}>
                                {this.props.context.msg.t('mdx')}
                            </Button>
                            {' '}
                            <Button className="btn-success"
                                    icon="icon-eye-open"
                                    disabled={this.props.submitting}
                                    onClick={this.props.handleSubmit(this.previewFormula)}>
                                {this.props.context.msg.t('preview')}
                            </Button>
                            {' '}
                            <Button className="btn-danger"
                                    icon="icon-remove"
                                    disabled={this.props.submitting}
                                    onClick={this.props.closeModal}>
                                {this.props.context.msg.t('cancel')}
                            </Button>
                            {' '}
                            <Button icon="icon-check" type="submit" loading={this.props.submitting}>
                                {this.props.context.msg.t('expert.create')}
                            </Button>
                        </div>
                    </form>

                    {preview &&
                    <div onClick={this.closePreview}>
                        <div className="preview-backdrop"></div>
                        <div className="preview-box">
                            <div className="preview-box-inner"
                                 dangerouslySetInnerHTML={{__html: preview}}></div>
                        </div>
                    </div>

                    }
                </div>
            </Dialog>
        );
    }

}


export default wrapForm({
    name: 'expertFormulaDialogForm',
    component: ContextEnhancer(ExpertFormulaDialog),
    destroyOnUnmount: false,
    initialValues: {
        id: '',
        name: '',
        order: 1,
        type: 'DEFAULT',
        format: {
            custom: false,
            expression: '',
            showValues: true,
            bands: []
        }
    }
});