import React from 'react';
import AGTable from "./AGTable";
import Snackbar from "components/ui/common/Snackbar";

class AGTableWrapper extends React.Component {

    state = {
        hasError: false,
        error: false,
    };

    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {

        if (this.state.hasError) {
            return (
                <div className="BngBigTable">
                    <div className="BngBigTable__mainContainer">
                        <div className="BngBigTable__mainPanel">
                            <Snackbar
                                className={'error'}
                                message={'Error'}
                                title={''}
                                icon="error_outline" />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <AGTable {...this.props} />
        );
    }

}


export default AGTableWrapper;
