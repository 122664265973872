import styles from './BngAnimatedTabsBar.module.css';
import React, { useEffect, useRef, useState } from 'react';

export default function BngAnimatedTabsBar({
  className = '',
  barWidth = 390,
  tabs = 3,
  currentTab = 0,
  initialTab = 0,
  backgroundColor = '#f6f6f6',
  barColor = '#005dff',
  height = 2,
}) {
  const [blueBarPosition, setBlueBarPosition] = useState(0);
  const [lastTabValue, setLastTabValue] = useState(initialTab);
  const blueBarRef = useRef(null);
  const $animationIntervalRef = useRef(null);

  useEffect(() => {
    const safeCurrentTab = tabs === 1 ? 0 : currentTab;

    const elem = blueBarRef.current;
    const barPosition = barWidth / tabs;
    const limitBarPosition = (barWidth / tabs) * safeCurrentTab;
    const finalPosition = barPosition * safeCurrentTab;
    const currentTabValue = lastTabValue;
    let position = blueBarPosition;

    if ($animationIntervalRef.current) {
      clearInterval($animationIntervalRef.current);
    }
    $animationIntervalRef.current = setInterval(animation, 1);

    setLastTabValue(safeCurrentTab);
    setBlueBarPosition(finalPosition);

    function animation() {
      if (
        (safeCurrentTab > currentTabValue && position >= finalPosition) ||
        (safeCurrentTab < currentTabValue && position <= finalPosition)
      ) {
        clearInterval($animationIntervalRef.current);
      } else if (safeCurrentTab > currentTabValue) {
        position += 8;
        if (position > limitBarPosition) {
          elem.style.left = limitBarPosition + 'px';
        } else {
          elem.style.left = position + 'px';
        }
      } else if (safeCurrentTab < currentTabValue) {
        position -= 8;
        if (position < limitBarPosition) {
          elem.style.left = limitBarPosition + 'px';
        } else {
          elem.style.left = position + 'px';
        }
      }
    }
  }, [currentTab]);

  return (
    <div className={`BngAnimatedTabsBar ${styles.bottomBar} ${className}`} style={{ width: barWidth, height, backgroundColor }}>
      <div
        ref={blueBarRef}
        className={`${styles.blueBar}`}
        style={{ width: barWidth / tabs, height, backgroundColor: barColor }}
      />
    </div>
  );
}
