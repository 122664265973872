import './Radio.css';
import React from 'react';
import PropTypes from 'prop-types';

function Radio({ className = '', name, value, onChange, selected, inputRight = false }) {
  return (
    <div className={`radio ${className}`}>
      <label className={`${inputRight ? 'input-right' : ''}`}>
        <input
          type="radio"
          name={name}
          className="ace"
          checked={selected}
          disabled={value.disabled}
          onChange={onChange.bind(this, value)}
        />
        <span className="lbl">{value.label}</span>
      </label>
    </div>
  );
}

Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
};

export default Radio;
