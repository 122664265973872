import './BreadcrumbToolbar.css';

import React, {PureComponent} from 'react';
import BlockUi from 'react-block-ui';
import PropTypes from 'prop-types';
import * as ReactDOM from "react-dom";
import Icon from "components/ui/common/Icon";

const Item = ({
                  onClick = _.noop,
                  title = '',
                  icon = 'icon-question',
                  className = '',
                  disabled = false,
                  inactive = false,
                  ...props
                }) => {
    const withChildren = React.Children.count(props.children) > 0;
    return (
        <span className={`toolbox-button relative ${className}`}>
            <a data-toggle={withChildren ? 'dropdown' : ''}
               className={`toolbox-button ${withChildren ? 'dropdown-toggle' : ''} ${inactive ? 'inactive' : ''} ${disabled ? 'disabled' : ''}`}
               title={title}
               onClick={disabled ? _.noop : onClick}
               style={props.style}>
                 <Icon icon={icon} />
            </a>
            {props.children}
        </span>
    );
};

Item.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    material: PropTypes.bool
};

class BreadcrumbToolbar extends PureComponent {

    static propTypes = {
        loading: PropTypes.bool
    };

    static defaultProps = {
        className: '',
        loading: false
    };

    state = {};

    constructor(props) {
        super(props);
    }

    findContainer = () => {
        if (this.__container) {
            return this.__container;
        } else {
            let c = j('#floating-object-container').find('#BreadcrumbToolbarContainer')[0];
            if (c) return c
        }

        this.__container = j('#floating-object-container')
            .removeClass('hide')
            .append(`<div id="BreadcrumbToolbarContainer"/>`)
            .find('#BreadcrumbToolbarContainer')[0];

        return this.__container;
    };

    render() {
        const {className, loading} = this.props;
        return ReactDOM.createPortal(
            <div className={`breadcrumb-toolbar ${className}`}>
                <BlockUi tag="div" blocking={loading}>
                    {this.props.children}
                </BlockUi>
            </div>
            ,
            this.findContainer()
        );
    }
}

BreadcrumbToolbar.Item = Item;

BreadcrumbToolbar.renderIfNotExists = (fn) => {
    if (j('#BreadcrumbToolbarContainer').length > 0) {
        j('#BreadcrumbToolbarContainer').remove();
    }
    return fn();
};

export default BreadcrumbToolbar;
