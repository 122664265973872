import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class MeterGauge extends BaseKpi {

    constructor(props) {
        super(props);
    }

    initComponent = async () => {
        let kpiId = this.state.kpiId;

        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        j(`#metergauge${kpiId}`).empty();

        let val = this.props.value;
        let minVal = this.props.minValue;
        let target = this.props.target;

        let color = [];
        let intervals = [];
        let maxVal = 0;

        if (this.props.bandsInformations.length > 0) {
            this.props.bandsInformations.map((e, i) => {
                if (i > 0) {
                    color.push(e.bandColor);
                    intervals.push(e.valueNotFormated);
                    maxVal = e.valueNotFormated;
                }
            });

            //Crazy Rule When Using Target Measurement
            if (this.props.measureAsTarget) {
                intervals = [];
                maxVal = ((val > target ? val : target) * 1.2);
                intervals.push(this.props.bandsInformations[2].valueNotFormated);
                intervals.push(target);
                intervals.push(maxVal);
            } else {
                //?????
                maxVal = (this.props.bandsInformations[2].valueNotFormated * 1.2);
                if (this.props.bandsInformations[3].valueNotFormated > 0) {
                    maxVal = this.props.bandsInformations[3].valueNotFormated;
                }
                if (this.props.bandsInformations[0].valueNotFormated > 0) {
                    minVal = this.props.bandsInformations[0].valueNotFormated;
                }

                //?????
                if (this.props.bandsInformations[1].valueNotFormated < 0
                    && this.props.bandsInformations[2].valueNotFormated < 0
                    && this.props.bandsInformations[3].valueNotFormated < 0) {
                    maxVal = queryResult.getBandValue(3).asDoubleOr(0);
                }
            }

            intervals = intervals.map((i, idx) => {
                if (i === 0 && idx === 2) {
                    return maxVal;
                } else if (i === 0) {
                    let newValue = intervals[idx + 1];
                    if (newValue === 0) {
                        newValue = intervals[idx + 2]
                    }
                    return newValue;
                }
                return i;
            });

        } else {
            color.push(this.state.color);
            intervals.push(this.props.value);
            maxVal = target < this.props.value ? this.props.value : target;
        }


        let item = 'metergauge' + kpiId;
        await jQuery(function () {
            try {
                jQuery.jqplot(item, [[val]], {
                    gridPadding: {top: 0, right: 0, bottom: 0, left: 0},
                    grid: {background: "transparent"},
                    seriesDefaults: {
                        renderer: jQuery.jqplot.MeterGaugeRenderer,
                        rendererOptions: {
                            label: null,
                            padding: null,
                            labelPosition: 'bottom',
                            labelHeightAdjust: 0,
                            min: minVal,
                            max: maxVal,
                            showTickLabels: false,
                            intervals: intervals,
                            intervalColors: color
                        }
                    }
                });
            } catch (err) {
                console.error(err)
            }
        });
        j(".jqplot-meterGauge-label").css("display", "none");

    };

    customResizeKpi = ({width, height}) => {
        this.props.div.style.padding = 0;
        const widthCanvas = width * 0.65;

        resizeEl(this.$kpiMeterGaugeEl, {
            height: {minValue: 50, multiplier: 0.6, refSize: widthCanvas},
            width: {value: `${widthCanvas}px`},
            float: {value: 'right'},
        });

        if (this.props.growthInfoRender) {
            this.$bodyEl.childNodes[1].style.width = `${widthCanvas}px`;
        }

        this.$bodyEl.style.textAlign = "right";
    };

    render() {
        let kpiId = this.state.kpiId;
        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        return (
            <div style={{margin: 'auto'}}>
                <div className={`KpiBody Canvas`}>
                    <div ref={el => this.$bodyEl = el} className="KpiInternalBody">
                        <div ref={el => this.$kpiMeterGaugeEl = el} id={`metergauge${kpiId}`} style={{margin: 'auto'}}/>
                        {this.useGrowth()}
                    </div>
                    {this.useRightFragment({container: this.$bodyEl})}
                </div>
                <div ref={el => this.$containerBandsEl = el}>
                    {this.useBands({container: this.$containerBandsEl})}
                </div>
            </div>
        )
    }

}
