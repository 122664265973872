import styles from './AdaAiManagementPage.module.css';

import React, { useEffect, useMemo, useState } from 'react';

import Api from 'components/Api';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Utils from 'components/Utils';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import BngDropdown from 'components/bng/ui/BngDropdown';
import OpConfirmation from 'components/ui/OpConfirmation';
import bngYup from 'components/bng/form/yup/BngYup';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import CrudPageLayout from 'components/bng/pages/common/layout/CrudPageLayout';
import AssistantDialog from 'components/bng/pages/admin/ada/ai/assistant/AssistantDialog';
import { llmModelOptions, modelOptions, typeOptions } from 'components/bng/pages/admin/ada/ai/form/OpenAiForm';
import useTranslation from 'components/hooks/useTranslation';
import useFetchData from 'components/hooks/useFetchData';
import useId from 'components/hooks/useId';

const AssistantFilterDropdownSchema = bngYup((yup) => {
  return yup.object({
    llmModels: yup.string().nullable().default(''),
    models: yup.string().nullable().default(''),
    types: yup.string().nullable().default(''),
  });
});

function AssistantFilterDropdown({ onChange = _.noop, initialValues = {}, llmModels = [], models = [], types = [] }) {
  const { t } = useTranslation();

  const llmModelOpts = FilterOpts({
    options: llmModels.sort((o1, o2) => Utils.Strings.compareIgnoreCase(o1.name, o2.name)),
    forceTitle: true,
  });

  const modelsOpts = FilterOpts({
    options: models.sort((o1, o2) => Utils.Strings.compareIgnoreCase(o1.name, o2.name)),
    forceTitle: true,
  });

  const typesOpts = FilterOpts({
    options: types.sort((o1, o2) => Utils.Strings.compareIgnoreCase(o1.name, o2.name)),
    forceTitle: true,
  });

  const initialFormValues = useMemo(() => _.merge({}, AssistantFilterDropdownSchema.default(), initialValues), [
    initialValues,
  ]);

  const filterFields = [
    {
      name: 'llmModels',
      label: t('ada.ai.open.ai.tab.field.llmModel'),
      options: llmModelOpts,
    },
    {
      name: 'models',
      label: t('ada.ai.open.ai.tab.field.model'),
      options: modelsOpts,
    },
    {
      name: 'types',
      label: t('ada.ai.open.ai.tab.field.type'),
      options: typesOpts,
    },
  ];

  return (
    <FilterDropdown
      fields={filterFields}
      dropdownSchema={AssistantFilterDropdownSchema}
      initialFormValues={initialFormValues}
      onChange={onChange}
    />
  );
}

function FloatActionButtonDropdown({ openEditDialog }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const boundaryElement = useMemo(() => document.getElementById('page-content'), []);
  const componentId = useId();

  return (
    <BngDropdown
      popperClassName={`${styles.FloatActionButtonDropdown}`}
      customButton={({ openDropdown }) => (
        <BngIconButton icon={'add'} className={`info ${open ? 'Open' : ''}`} onClick={openDropdown} />
      )}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      boundariesElement={boundaryElement}
      popperOpts={{
        placement: 'bottom-start',
      }}
      customOptions={({ closeDropdown }) => (
        <div className={`${styles.FloatActionButtonDropdown}`}>
          <BngIconButton id={`${componentId}-ada-ai-management-button`} icon="add" style={{ backgroundColor: '#00A355' }} onClick={async () => {openEditDialog(); closeDropdown()}} />
          <label htmlFor={`${componentId}-ada-ai-management-button`}>{t('ada.ai.add.assistant.openAi')}</label>
        </div>
      )}
    />
  );
}

function RowActions({ row, fetchData, setSelectedRow, idx, openEditDialog, closeDropdown }) {
  const { t } = useTranslation();

  return (
    <BngDropdown
      popperOpts={{ placement: 'bottom-end' }}
      popperClassName={styles.actionsDropdownPopper}
      onOpen={() => setSelectedRow(idx)}
      onClose={() => setSelectedRow(null)}
      customOptions={({ closeDropdown }) => {
        return (
          <div className={styles.actionButtonWrapper}>
            <div
              onClick={() => {
                openEditDialog(row);
                closeDropdown();
              }}
              className={styles.actionItem}
            >
              <Icon icon="edit" />
              <span>{t('edit')}</span>
            </div>

            <>
              <hr />
              <div
                onClick={() => {
                  OpConfirmation({
                    title: row.name,
                    message: t('confirm.delete.assistant.openAi'),
                    onConfirm: async () => {
                      try {
                        await Api.AdaAi.remove(row.id);
                        UiMsg.ok(t('ada.ai.delete.assistant.success'));
                        fetchData();
                        closeDropdown();
                      } catch (e) {
                        console.error('Error while removing assistant', { assistantId: row.id }, e);
                        UiMsg.error(t('ada.ai.delete.assistant.error'), e);
                      }
                    },
                  });
                }}
              >
                <Icon icon="delete" />
                <span>{t('delete')}</span>
              </div>
            </>
          </div>
        );
      }}
    />
  );
}

const buildTableColumns = ({ t, fetchData, setSelectedRow, openEditDialog }) => {
  return [
    {
      key: 'name',
      label: t('ada.ai.open.ai.tab.field.name'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span>{row.name}</span>
          </div>
        );
      },
    },
    {
      key: 'type',
      label: t('ada.ai.open.ai.tab.field.type'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span>{row.type}</span>
          </div>
        );
      },
    },
    {
      key: 'description',
      label: t('ada.ai.open.ai.tab.field.description'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span>{row.description}</span>
          </div>
        );
      },
    },
    {
      key: 'model',
      label: t('ada.ai.open.ai.tab.field.model'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span>{row.model}</span>
          </div>
        );
      },
    },
    {
      key: 'temperature',
      label: t('ada.ai.open.ai.tab.field.temperature'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span>{row.temperature}</span>
          </div>
        );
      },
    },
    {
      key: 'llmModel',
      label: t('ada.ai.open.ai.tab.field.llmModel'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span>{row.llmModel}</span>
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: t('actions'),
      size: 70,
      render: (row, idx) => {
        return <RowActions row={row} fetchData={fetchData} setSelectedRow={setSelectedRow} idx={idx} openEditDialog={openEditDialog}/>;
      },
    },
  ];
};

const filterAssistants = (filters, assistants, tableSortMode) => {
  const {
    searchTerm,
    filterButton: { llmModels, models, types },
  } = filters;

  if (searchTerm) {
    const search = searchTerm.toLowerCase();
    assistants = assistants.filter((assistant) =>
      `${assistant.name || ''} ${assistant.description || ''}`.toLowerCase().includes(search)
    );
  }

  if (llmModels) {
    assistants = assistants.filter((assistant) => assistant.llmModel === llmModels);
  }

  if (models) {
    assistants = assistants.filter((assistant) => assistant.model === models);
  }

  if (types) {
    assistants = assistants.filter((assistant) => assistant.type === types);
  }

  Object.entries(tableSortMode).forEach(([prop, direction]) => {
    if (direction === 'NONE') return;

    assistants = _.orderBy(
      assistants,
      [
        (assistant) => {
          let value = assistant && assistant[prop];
          if (prop === 'name') {
            value = assistant.name;
          }

          switch (prop) {
            case 'llmModels': {
              return assistant.llmModel;
            }
            case 'models': {
              return assistant.model;
            }
            case 'types': {
              return assistant.type;
            }
          }
          return _.isString(value) ? value.toLowerCase().normalize('NFD') : value;
        },
      ],
      [direction.toLowerCase()]
    );
  });

  return assistants;
};

export default function AdaAiManagementPage() {
  const context = useBimContext();
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const [tableSortMode, setTableSortMode] = useState({ name: 'ASC' });
  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });
  const [selectedRow, setSelectedRow] = useState();
  const hasAccess = context.user.email.endsWith('@sol7.com.br');

  useEffect(() => {
    if (hasAccess) {
      return;
    }
    window.location.replace(Api.buildUrl('/pages/errors/403.iface'));
  }, []);

  if (!hasAccess) {
    return null;
  }

  const { data: assistants = [], reload: fetchData, isLoading: loading } = useFetchData(async () => {
    try {
      return await Api.AdaAi.findAll();
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  }, []);

  const openEditDialog = (row) => {
    dispatch(
      MODALS.open(AssistantDialog, {
        assistant: row,
        onSave: () => fetchData(),
      })
    );
  };

  const tableColumns = useMemo(
    () =>
      buildTableColumns({
        t,
        fetchData,
        setSelectedRow,
        openEditDialog,
      }),
    []
  );

  const filteredAssistants = filterAssistants(filters, assistants, tableSortMode);
  return (
    <div>
      <CrudPageLayout
        fetchData={fetchData}
        filters={filters}
        tableRows={filteredAssistants}
        loading={loading}
        emptyAlertMessage={context.msg.t('ada.ai.no.assistant')}
        pageTitle={context.msg.t('artificial.intelligence.ada.ai')}
        tableSortMode={tableSortMode}
        setFilters={setFilters}
        tableColumns={tableColumns}
        selectedRows={selectedRow}
        headerButtons={() => (
          <div className="flex-center-items">
            <AssistantFilterDropdown
              onChange={(values) => {
                setFilters({ ...filters, filterButton: values });
              }}
              initialValues={filters.filterButton}
              llmModels={llmModelOptions}
              models={modelOptions}
              types={typeOptions}
            />
          </div>
        )}
        setTableSortMode={setTableSortMode}
        pageButton={() => <FloatActionButtonDropdown openEditDialog={openEditDialog} />}
      />
    </div>
  );
}
