import { Axios } from 'commonUtils';

export default class InMemoryLoadLimitApi {
  async findForProject(params = {}) {
    const { data } = await Axios.get(`/spr/inMemoryLoadLimits`, {
      params,
    });
    return data;
  }
}
