import './LastLoginsTab.css';

import React, { useEffect, useMemo, useState } from 'react';

import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import { BngTable } from 'components/bng/ui/BngTable';
import Icon from 'components/ui/common/Icon';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';

export default function LastLoginsTab({ className = '' }) {
  const { msg } = useBimContext();

  const [logins, setLogins] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const userAccesses = await Api.UserAccess.findAll();
        userAccesses.sort((a, b) => {
          if (a.unlocked === b.unlocked) {
            return b.lastAccess - a.lastAccess;
          }
          return a.unlocked ? -1 : 1;
        });
        setLogins(userAccesses);
      } catch (e) {
        console.error('Error while trying to fetch user accesses', e);
        UiMsg.ajaxError(null, e);
      }
    })();
  }, []);

  const cols = useMemo(() => {
    return [
      {
        label: msg.t('status'),
        render: (row) => {
          const value = row.unlocked ?? false;
          return <span>{value ? msg.t('active') : msg.t('revoked')}</span>;
        },
      },
      {
        label: msg.t('device'),
        render: (row) => {
          const mobile = row.mobile ?? false;
          const device = row.device ?? '';

          return (
            <div className="flex-center-items gap-2">
              <Icon icon={mobile ? 'phone_iphone' : 'laptop'} />
              <span>{device}</span>
            </div>
          );
        },
      },
      {
        label: msg.t('approximate.location'),
        render: (row) => {
          const value = row.location ?? '';
          return (
            <div className="flex-center-items gap-2">
              <Icon icon="location_on" />
              <span>{_.isEmpty(value) || value === 'UNKNOWN' ? msg.t('location.not.identified') : value}</span>
            </div>
          );
        },
      },
      {
        label: msg.t('date'),
        render: (row) => {
          return <span>{Utils.Date.formatDateTime(row.lastAccess)}</span>;
        },
      },
    ];
  }, []);

  return (
    <div className={`LastLoginsTab ${className}`}>
      <BngTable rows={logins} cols={cols} stickyHeader />
    </div>
  );
}
