import React, {Component, PureComponent} from 'react';
import BngClickOutsideOverlay from "./BngClickOutsideOverlay";
import BngPopper from "./BngPopper";
import PropTypes from "prop-types";

export class BngPopperButton extends PureComponent {

    static propTypes = {
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false
    };

    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        const {disabled, onClick, className, children, ...props} = this.props;
        return (
            <div className={`${className ?? ''} ${disabled ? 'disabledButton' : ''}`}
                 onClick={disabled ? undefined : onClick}
                 {...props}
            >
                {children}
            </div>
        )
    }
}

export class BngPopperWrapper extends Component {

    static propTypes = {
        container: PropTypes.any,
        overlayProps: PropTypes.any,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        disabled: PropTypes.bool,
        shouldOpen: PropTypes.func,
    };

    static defaultProps = {
        onOpen: () => {
        },
        onClose: () => {
        },
        disabled: false,
        shouldOpen: () => true,
    };

    state = {
        open: false,
    };

    openPopper = (event) => {
        if (this.props.shouldOpen()) {
            this.setState({
                popperRef: event.currentTarget,
                open: true,
            });
            this.props.onOpen(event);
        }
    };

    closePopper = (event) => {
        this.setState({open: false});
        this.props.onClose(event);
    };

    render() {
        const {children, onOpen, onClose, disabled, overlayProps, ...props} = this.props;

        const container = this.props.container ? this.props.container : document.body;

        let popperBody = [];

        const button = React.Children.map(children, child => {
            if (child.type === BngPopperButton) {
                return React.cloneElement(child, {disabled: disabled, onClick: this.openPopper});
            } else {
                popperBody.push(child);
                return null;
            }
        });

        return (
            <React.Fragment>
                {button[0]}
                <BngPopper open={this.state.open}
                           anchorEl={this.state.popperRef}
                           container={container}
                           {...props} >
                    <BngClickOutsideOverlay onClick={this.closePopper} container={container} {...overlayProps}/>
                    {popperBody.map(el => el)}
                </BngPopper>
            </React.Fragment>
        )
    }
}