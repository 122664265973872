import React, { useState } from 'react';
import BngStepTutorialDialog from 'components/bng/ui/BngStepTutorial/BngStepTutorialDialog';
import SecurityCheckupMain from 'components/bng/securityCheckup/SecurityCheckupMain';
import SecurityCheckupLGPD from 'components/bng/securityCheckup/LGPD/SecurityCheckupLGPD';
import SecurityCheckupDone from 'components/bng/securityCheckup/SecurityCheckupDone';
import SecurityCheckupUpdatePhone from 'components/bng/securityCheckup/Phone/SecurityCheckupUpdatePhone';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';

export const SecurityCheckups = {
  LGPD: {
    summaryIcon: 'lock',
    summaryTitleKey: 'checkup.lgpd.summaryTitle',
    summaryDescriptionKey: 'checkup.lgpd.summaryDescription',
    render: (props = {}) => <SecurityCheckupLGPD {...props} />,
  },
  UpdatePhone: {
    summaryIcon: 'phone',
    summaryTitleKey: 'phone',
    summaryDescriptionKey: 'checkup.updatePhone.summaryDescription',
    render: ({ closeModal = _.noop, addCompletedStep = _.noop, ...props }) => (
      <SecurityCheckupUpdatePhone
        closeModal={closeModal}
        addCompletedStep={() => addCompletedStep('checkup.updatePhone.completed')}
        {...props}
      />
    ),
  },
};

export default function SecurityCheckupDialog({ closeModal = _.noop, checkupsToShow = [] }) {
  const context = useBimContext();
  const [completedSteps, setCompletedSteps] = useState([]);

  const addCompletedStep = (message) => {
    if (!completedSteps.some((step) => message === step)) {
      setCompletedSteps([...completedSteps, message]);
    }
  };

  const handleClose = async () => {
    try {
      await Api.UserParam.saveUserKey({
        key: Api.UserParam.Keys.SecurityCheckup.SeenSecurityCheckup,
        value: true,
        projectId: context.project?.id,
      });
    } catch (e) {
      console.error('Error on handleClose()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      closeModal();
    }
  };

  const steps = [
    {
      render: () => <SecurityCheckupMain cards={checkupsToShow} />,
    },
    ...checkupsToShow.map((step) => {
      return {
        ...step,
        render: (props = {}) => {
          return step.render({
            ...props,
            closeModal,
            addCompletedStep,
          });
        },
      };
    }),
    {
      render: () => <SecurityCheckupDone completedSteps={completedSteps} />,
    },
  ];

  return (
    <BngStepTutorialDialog
      className="SecurityCheckupDialog"
      onClose={handleClose}
      steps={steps}
      enableDotNavigation={false}
    />
  );
}
