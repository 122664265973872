import './MenuButton.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuButtonSearch from './MenuButtonSearch';
import Badge from 'components/ui/Badge';
import Button from 'components/ui/Button';
import DropdownToggler from 'components/ui/navbar/DropdownToggler';
import Icon from 'components/ui/common/Icon';
import BngDropdown from 'components/bng/ui/BngDropdown';

class MenuButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    headerText: PropTypes.string,
    headerAction: PropTypes.any,
    badge: PropTypes.shape(Badge.propTypes),
    toggler: PropTypes.func,
    onOpen: PropTypes.func,
    material: PropTypes.bool,
    button: PropTypes.bool,
    buttonClass: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonActionEnable: PropTypes.bool,
    buttonActionLabel: PropTypes.string,
    buttonActionClick: PropTypes.func,
    onSearchChange: PropTypes.func,
    enableSearch: PropTypes.bool,
    clearSearch: PropTypes.func,
    offset: PropTypes.number,
    alert: PropTypes.string,
    buttonRef: PropTypes.object,
    keepMenuOpen: PropTypes.bool,
    closeMenu: PropTypes.func,
  };

  static defaultProps = {
    color: 'badge-grey',
    value: 0,
    buttonActionClick: _.noop,
    onOpen: _.noop,
    material: true,
    offset: 0,
    keepMenuOpen: false,
    closeMenu: _.noop,
  };

  static instances = [];

  state = {
    open: false,
  };

  constructor(props) {
    super(props);
    MenuButton.instances.push(this);
  }

  isOpen = () => {
    return this.state.open;
  };

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    if (this.props.enableSearch) {
      this.props.clearSearch();
    }
    this.setState({ open: false });
    setTimeout(() => {
      this.props.closeMenu();
    }, 150);
  };

  componentWillUnmount() {
    let idx = MenuButton.instances.indexOf(this);
    if (idx !== -1) {
      MenuButton.instances.splice(idx, 1);
    }
  }

  toggleOpen = async () => {
    let result = true;
    let open = !this.isOpen();
    if (open) {
      MenuButton.closeAllMenus();
      result = await this.props.onOpen();
    }
    if (result !== false) {
      this.open();
    }
    return result !== false;
  };

  buttonActionClick = () => {
    this.props.buttonActionClick();
  };

  static closeAllMenus() {
    MenuButton.instances.forEach((el) => {
      if (el.isOpen()) {
        el.close();
      }
    });
  }

  render() {
    return (
      <BngDropdown
        className={'MenuButtonDropdown'}
        keepOpen={this.props.keepMenuOpen}
        popperClassName={'MenuButtonDropdownPopper'}
        useArrow={true}
        arrowClassName={'MenuButtonDropdownArrow'}
        offset={[0, this.props.offset]}
        visible={this.state.open}
        onClose={this.close}
        customButton={({ openDropdown }) => {
          return (
            <div>
              <li
                className={`nav-bar-button ${this.props.className}`}
                onClick={async (e) => {
                  const shouldOpen = await this.toggleOpen();
                  if (shouldOpen) {
                    await openDropdown(e);
                  }
                }}
                ref={this.props.buttonRef}
              >
                {this.renderToggler()}
              </li>
            </div>
          );
        }}
        customOptions={() => this.renderDropdown()}
      />
    );
  }

  renderToggler() {
    let toggler;
    if (this.props.toggler) {
      toggler = this.props.toggler({
        title: this.props.title,
      });
    } else {
      toggler = (
        <DropdownToggler title={this.props.title}>
          {this.props.button ? (
            <div className={`btn-new ${this.props.buttonClass}`}>{this.props.buttonLabel}</div>
          ) : (
            <Icon icon={this.props.icon} />
          )}
          {this.renderBadge()}
          {this.props.alert && (
            <div className="AlertContainer" title={this.props.alert}>
              <Icon icon="error" />
            </div>
          )}
        </DropdownToggler>
      );
    }
    return toggler;
  }

  renderBadge() {
    let badge;
    if (this.props.badge) {
      if (this.props.badge.value > 0) {
        badge = <Badge {...this.props.badge} />;
      }
    }
    return badge;
  }

  renderDropdown() {
    return (
      <ul className={`nav-bar-button dropdown-navbar dropdown-menu ${this.props.className}`}>
        <li className="nav-header" style={{ textAlign: 'left' }}>
          <i className={this.props.icon} />
          {this.props.headerText ? this.props.headerText : this.props.title}
          {this.props.headerAction}

          {this.props.enableSearch && this.props.buttonActionEnable && (
            <MenuButtonSearch
              onSearchChange={this.props.onSearchChange}
              enableSearch={this.props.enableSearch}
              clearSearch={this.props.clearSearch}
            />
          )}

          {this.props.enableSearch && this.props.buttonActionEnable === false && (
            <MenuButtonSearch
              actionDisable={true}
              onSearchChange={this.props.onSearchChange}
              enableSearch={this.props.enableSearch}
              clearSearch={this.props.clearSearch}
            />
          )}

          {this.props.buttonActionEnable && (
            <Button
              className={`btn-small btn-block ${this.props.buttonClass}`}
              onClick={() => this.buttonActionClick()}
            >
              {this.props.buttonActionLabel}
            </Button>
          )}
        </li>
        {this.props.children}
      </ul>
    );
  }
}

export default MenuButton;
