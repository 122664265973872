import Api from "components/Api";
import {BngDropdown} from "components/bng/ui/BngDropdown";
import UiMsg from "components/ui/UiMsg";
import React, {useState} from 'react';
import BngCheckbox from "components/bng/form/BngCheckbox";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";


const VisualizationDropdown = ({
                                   showChart,
                                   showTable,
                                   context,
                               }) => {
    const [isShowChart, setShowChart] = useState(showChart);
    const [isShowTable, setShowTable] = useState(showTable);

    const toggleTableView = (event) => {
        try {
            const viewTable = event.target.checked;
            if(!isShowChart && !viewTable) {
                return;
            }

            Api.executeExp(`#{analysisBean.setShowTable(${viewTable})}`);
            setShowTable(viewTable);
        } catch (e) {
            UiMsg.error(null, e);
        }
    };

    const toggleChartView = (event) => {
        try {
            const viewChart = event.target.checked;
            if(!isShowTable && !viewChart) {
                return;
            }

            Api.executeExp(`#{analysisBean.setShowChart(${viewChart})}`);
            setShowChart(viewChart);
        } catch (e) {
            UiMsg.error(null, e);
        }
    };

    return (
        <ul className="submenu submenu-analysis-visualizations">
            <li>
                <div className="menu-text">{context.msg.t('visualizations')}</div>
            </li>
            <li className="submenu-analysis-visualizations-action">
                <BngCheckbox onChange={toggleChartView}
                             field={{value: isShowChart}}
                             label={context.msg.t('cbox_showChart')}
                             style={{width: '85% !important'}}/>
            </li>

            <li className="submenu-analysis-visualizations-action">
                <BngCheckbox onChange={toggleTableView}
                             field={{value: isShowTable}}
                             label={context.msg.t('cbox_showTable')}
                             style={{width: '85% !important'}}/>
            </li>
        </ul>
    )
};

const VisualizationMenuItem = ({
                                   showChart,
                                   showTable,
                                   context,
                                   disabled,
                               }) => {
    return (
        <BngDropdown
            className="VisualizationOption"
            popperClassName="RightMenuOptionPopper"
            boundariesElement={j('#page-content')[0]}
            popperOpts={{placement: "left"}}
            customButton={({openDropdown}) => {
                return <RightMenuItem icon="remove_red_eye"
                                           className="VisualizationMenuItem"
                                           disabled={disabled}
                                           onClick={openDropdown}
                                           title={context.msg.t('visualizations')}/>
            }}
            customOptions={({closeDropdown}) => {
                return <VisualizationDropdown context={context}
                                              showChart={showChart}
                                              showTable={showTable}/>
            }}
        />
    )
};

export default VisualizationMenuItem;