import React from "react";

import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import OpConfirmation from "components/ui/OpConfirmation";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";


export function useLoadingListener() {
    const [loading, setLoading] = React.useState(false);

    const listener = async (promise) => {
        setLoading(true);
        try {
            const toWait = _.isFunction(promise)
                ? promise()
                : promise
            return await toWait;
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        } finally {
            setLoading(false);
        }
    }

    return {loading, listener};
}

export const BngEnableAdvancedModeButton = ContextEnhancer(({context, assistedType = '', listener = _.noop}) => {

    const typeLabel = assistedType ? context.msg.t(assistedType) : '';
    const alertMessage = context.msg.t('BngEnableAdvancedMode.alert', [typeLabel]);

    const clickHandler = () => {
        OpConfirmation({
            level: 'warning',
            title: context.msg.t('attention'),
            html: alertMessage,
            onConfirm: () => {
                listener(Api.executeExp(`#{analysisBean.enableAdvancedMode()}`));
            },
            msg: context.msg,
        });
    }

    return (
        <React.Fragment>
            <Button className={'bng-button fix save Action'} onClick={clickHandler}>
                {context.msg.t('enable.advanced.mode')}
            </Button>
        </React.Fragment>
    );

});