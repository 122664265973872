import './InformationDialog.css';
import Dialog from "../Dialog";
import React, {Component} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import Button from "../Button";

class InformationDialog extends Component {

    static propTypes = {};

    static defaultProps = {
        createdBy: '',
        creationTime: '',
        description: '',
        path: '',
        dataSource: '',
        dataSourceCaption: '',
        changes: [],
    };

    state = {};

    constructor(props) {
        super(props);
    }

    formatDate(date) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss')
    }

    renderInformation = (name, data) => {
        return (
            <div className="row-fluid">
                <div className="span12">
                    <label>
                        {name}
                    </label>
                    <span>{data}</span>
                </div>
            </div>
        );
    };

    render() {
        let {createdBy, creationTime, description, path, dataSourceCaption, dataSource, lastDataUpdate, changes, title} = this.props;

        changes = changes.map(c => {
            let changeUser = c.modifiedBy;
            if(!!changeUser) {
                if(_.isNumber(changeUser)) {
                    changeUser = {
                        id: changeUser,
                        displayName: '',
                    }
                }
                c.modifiedBy = changeUser;
            }
            return c;
        });

        const dataSourceFullName = `${dataSourceCaption} (${dataSource})`;

        return (
            <Dialog open={this.props.open}
                    className="lg"
                    title={title}
                    onClose={this.props.closeModal}>

                <div className="basic-info-container">
                    <div>

                        {this.renderInformation(this.props.context.msg.t('analysis_userCreate'), !!createdBy ? createdBy.displayName : this.props.context.msg.t('user.not.found'))}

                        {this.renderInformation(this.props.context.msg.t('analysis_hourCreate'), this.formatDate(creationTime) )}

                        {this.renderInformation(this.props.context.msg.t('dataFeeds_label_description'), description)}

                        {dataSource !== null && this.renderInformation(this.props.context.msg.t('header_menu_in_memory'), dataSourceFullName )}

                        {lastDataUpdate !== null && this.renderInformation(this.props.context.msg.t('last_update'), this.formatDate(lastDataUpdate) )}

                        {this.renderInformation(this.props.context.msg.t('path'), path)}

                        <div className="row-fluid changes-table-container">
                            <div className="span12">
                                <table className="table table-striped table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th>{this.props.context.msg.t('user')}</th>
                                        <th>{this.props.context.msg.t('change.hour')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {changes.map((change, idx) => (
                                        <tr key={idx}>
                                            <td>{!!change.modifiedBy ? change.modifiedBy.displayName : this.props.context.msg.t('user.not.found')}</td>
                                            <td>{this.formatDate(change.changeTime)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <hr/>

                    <div className="row-fluid">
                        <div className="span12 btn-fix text-right">
                            <Button icon="icon-remove" className="btn-danger" onClick={this.props.closeModal}>
                                {this.props.context.msg.t('close')}
                            </Button>
                        </div>
                    </div>
                </div>

            </Dialog>
        );
    }

}

export default ContextEnhancer(InformationDialog);
