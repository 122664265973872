import './ThemeSelector.css';

import React from 'react';

import {themes} from 'components/bng/pages/bigTable/themes';

const rows = 5;
const cols = 4;

function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

const ThemeSelectorTable = (props) => {

    let cellStyle = {
        fontSize: props.gridConfig.fontSize,
        padding: '2px 2px',
        color: props.columnConfig.textColor
    };

    if (props.gridConfig.borderOptions === 'horizontal') {
        cellStyle.borderBottom = `1px solid ${props.gridConfig.borderColor}`;
    } else if (props.gridConfig.borderOptions === 'vertical') {
        cellStyle.borderLeft = `1px solid ${props.gridConfig.borderColor}`;
        cellStyle.borderRight = `1px solid ${props.gridConfig.borderColor}`;
    } else if (props.gridConfig.borderOptions === 'all') {
        cellStyle.border = `1px solid ${props.gridConfig.borderColor}`;
    }


    return (
        <div className={`BngBigTable__themeSelectorTable ${props.active ? `active` : ''}`}
             onClick={() => props.onClick()}>
            <table style={{
                height: '100%',
                width: '100%',
                border: `1px solid ${props.gridConfig.borderColor}`,
                borderCollapse: 'collapse',
                zoom: 0.75
            }}>
                {props.gridConfig.showHeader && (
                    <thead>
                    <tr style={{
                        fontSize: props.gridConfig.fontSize,
                        backgroundColor: props.gridConfig.headerBackgroundColor,
                        height: 15,
                        color: props.gridConfig.headerFontColor
                    }}>
                        {range(1, cols).map(c => (
                            <th key={c}></th>
                        ))}
                    </tr>
                    </thead>
                )}
                <tbody>
                {range(1, rows).map(r => (
                    <tr key={r} style={{
                        height: props.gridConfig.rowHeight,
                        backgroundColor: props.gridConfig.stripped ?
                            (r % 2 == 0 ? props.gridConfig.strippedColor : props.gridConfig.backgroundColor) :
                            props.gridConfig.backgroundColor,
                    }}>
                        {range(1, cols).map(c => (
                            <td style={cellStyle} key={c}></td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default (props) => {

    const pageSize = 6;
    const [page, setPage] = React.useState(0);

    const getThemeIndex = () => {
        let themeIndex = 0;
        for (let i = 0; i < themes.length; i++) {
            if (themes[i].value === props.value.theme) {
                themeIndex = i;
            }
        }
        return themeIndex;
    }

    const getTheme = () => {
        const themeIndex = getThemeIndex();
        return themes[themeIndex];
    }

    const getPages = () => {
        let pages = [];
        for (let i = 0; i < Math.ceil(themes.length / pageSize); i++) {
            pages.push(i);
        }
        return pages;
    }

    const nextPage = () => {
        if (page < (Math.ceil(themes.length / pageSize) - 1)) {
            setPage(page + 1);
        }
    };

    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const selectTheme = (index) => {
        let idx = page * pageSize + index;
        props.onChange({...props.value, theme: themes[idx].value})
    }

    const themesToShow = themes.slice(page * pageSize, (page * pageSize) + pageSize);
    return (
        <div className="BngBigTable__themeSelector">

            <div style={{display: 'flex', flexDirection: 'row'}}>

                {page > 0 ? (
                    <div className={`BngBigTable_themeSelectorPagination`} onClick={() => previousPage()}>
                        <i className={'material-icons'}>{'chevron_left'}</i>
                    </div>
                ) : (
                    <div className={`BngBigTable_themeSelectorPagination`}>
                    </div>
                )}

                <div className="BngBigTable__themeSelectorContainer">
                    <div className="BngBigTable__tables">
                        {themesToShow.map((theme, index) => (
                            <ThemeSelectorTable
                                key={page + "_" + index}
                                gridConfig={theme.gridConfig}
                                columnConfig={theme.columnConfig}
                                onClick={() => selectTheme(index)}
                            />
                        ))}
                        {/* Added to fill flexbox layout gaps*/}
                        {themesToShow.length < pageSize &&
                        _.range(pageSize - themesToShow.length).map(i => (
                                <div className="BngBigTable__themeSelectorTable Dummy" key={i}>
                                </div>
                            )
                        )
                        }
                    </div>

                    <div className="BngBigTable__pages">
                        {getPages().map(p => (
                            <div key={p}
                                 className={`BngBigTable__pageDot ${p === page && 'BngBigTable__pageDot--active'}`}></div>
                        ))}
                    </div>

                </div>

                {page < (Math.ceil(themes.length / pageSize) - 1) ? (
                    <div className={`BngBigTable_themeSelectorPagination`} onClick={() => nextPage()}>
                        <i className={'material-icons'}>{'chevron_right'}</i>
                    </div>
                ) : (
                    <div className={`BngBigTable_themeSelectorPagination`}>
                    </div>
                )}

            </div>

        </div>
    )
}