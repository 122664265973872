import styles from './DimensionEditDialog.module.css';

import React, { useState } from 'react';

import useTranslation from 'components/hooks/useTranslation';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngCheckbox from 'components/bng/form/BngCheckbox';

export function LevelSelectorDropdown({ availableFields = [], value = [], onChange = _.noop }) {
  const { t } = useTranslation();

  const [selectedFields, setSelectedFields] = useState([]);

  return (
    <div className={`LevelSelectorDropdown ${styles.levelSelectorRoot}`}>
      {t('level')}

      <BngDropdown
        popperClassName={`${styles.levelPopperClassName}`}
        overDialog={true}
        customButton={({ openDropdown }) => (
          <BngIconButton
            iconProps={{ className: `${styles.buttonIcon}` }}
            className={`${styles.button}`}
            text={t('add.levels')}
            icon="add"
            onClick={(e) => {
              setSelectedFields(value.slice());
              openDropdown(e);
            }}
          />
        )}
        customOptions={({ closeDropdown }) => {
          return (
            <div className={`${styles.dropdownBlocker}`}>
              <div className={`${styles.fieldsListWrapper}`}>
                {availableFields.map((field, idx) => {
                  const fieldName = field.value.fieldName;
                  return (
                    <BngCheckbox
                      key={idx}
                      className={`${styles.checkboxLevelSelection}`}
                      field={{
                        label: field.label,
                        value: selectedFields.includes(fieldName),
                        onChange: () => {
                          const copy = selectedFields.slice();
                          const idx = selectedFields.indexOf(fieldName);
                          if (idx !== -1) {
                            copy.splice(idx, 1);
                          } else {
                            copy.push(fieldName);
                          }

                          setSelectedFields(copy);
                        },
                      }}
                    />
                  );
                })}
              </div>
              <div className={`${styles.buttonsWrapper}`}>
                <BngIconButton
                  className={`${styles.addFieldsButton}`}
                  icon="add"
                  text={t('apply')}
                  onClick={() => {
                    onChange(selectedFields.slice());
                    closeDropdown();
                  }}
                />
                <BngIconButton
                  className={`${styles.removeAllFieldsButton}`}
                  icon="delete_forever"
                  onClick={() => {
                    onChange([]);
                    closeDropdown();
                  }}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
