import React from 'react';
import { Field } from 'formik';

import BngField from 'components/bng/form/BngField';
import BngCheckbox from 'components/bng/form/BngCheckbox';

export default {
  render({ name, label, props }) {
    return (
      <Field
        name={name}
        component={BngField}
        inputComponent={BngCheckbox}
        rootClassName={'ParamType Boolean position-relative'}
        rootProps={{ 'data-test': 'Boolean' }}
        withLabel={false}
        asProps={{
          label,
        }}
        {...props}
      />
    );
  },
};
