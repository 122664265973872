import React from 'react';

import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';

export default function PreviewVideoLabel({ onClick = _.noop, className = '' }) {
  const { msg } = useBimContext();
  return (
    <a className={className} onClick={onClick}>
      {msg.t('preview_video')}
      <Icon icon={'play_arrow'} />
    </a>
  );
}
