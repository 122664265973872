import React from 'react';

import image from 'components/bng/pages/newAnalysis/assets/BngAdvancedModeAlert.svg';
import BaseErrorPage from 'components/bng/pages/errors/BaseErrorPage';
import useTranslation from 'components/hooks/useTranslation';

export default function ResourceNotFoundErrorPage() {
  const { t } = useTranslation();
  return (
    <BaseErrorPage
      className="ResourceNotFoundErrorPage"
      image={image}
      title={t('application.resource.not.found')}
      description={t('application.resource.not.found.message')}
    />
  );
}
