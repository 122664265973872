import './DynamicTimeFilterToolbarItem.css';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import ContextEnhancer from 'components/ContextEnhancer';
import BreadcrumbToolbar from './BreadcrumbToolbar';
import { BngField } from 'components/bng/form/BngField';
import { BngSelect } from 'components/bng/form/BngSelect';
import { bngYup } from 'components/bng/form/yup/BngYup';
import Button from 'components/ui/Button';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';

export const DynamicTimeFilterListHeader = ContextEnhancer(
  class extends PureComponent {
    static propTypes = {
      path: PropTypes.string,
      lastDataUpdate: PropTypes.any,
    };

    static defaultProps = {
      path: '',
      lastDataUpdate: '',
    };

    render() {
      const { context, lastDataUpdate } = this.props;
      return (
        <li className="dropdown-menu-item-content">
          <div style={{ textAlign: 'center', color: '#565656' }}>
            <div>
              <Icon icon="access_time" style={{ fontSize: '24px' }} />
            </div>
            {!lastDataUpdate ? (
              <div>{context.msg.t('last.datasource.update.not.found')}</div>
            ) : (
              <React.Fragment>
                <div style={{ fontSize: '13px', padding: '4px 0px 0px 0px' }}>
                  {context.msg.t('last.datasource.update')}:
                </div>
                <div style={{ fontWeight: 'bold' }}>{moment(lastDataUpdate).format('DD/MM/YYYY HH:mm')}</div>
              </React.Fragment>
            )}
          </div>
        </li>
      );
    }
  }
);

const DynamicTimeFilterSchema = bngYup((yup) => {
  return yup.object().shape({
    dimension: yup.string().required().default(''),
    periodicity: yup.string().required().default(''),
  });
});

export const DynamicTimeFilterToolbarItem = ContextEnhancer(
  class DynamicTimeFilterToolbarItemInternal extends PureComponent {
    static propTypes = {
      path: PropTypes.string.isRequired,
      dimensions: PropTypes.array,
      onChange: PropTypes.func,
      showForm: PropTypes.bool,
      timeFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      disabled: PropTypes.bool,
    };

    static defaultProps = {
      path: '',
      dimensions: [],
      onChange: _.noop,
      showForm: true,
      disabled: false,
      blocked: false,
    };

    state = {
      lastDataUpdate: null,
      loading: false,
      periodicities: [],
    };

    constructor(props) {
      super(props);
      this.state.initialFormValues = DynamicTimeFilterSchema.default();
    }

    async componentDidUpdate(prevProps) {
      if (this.props.timeFilter !== prevProps.timeFilter) {
        await this.updateFormValues(this.props.timeFilter);
      }
    }

    async updateFormValues(timeFilter) {
      if (this.$formik) {
        let result;
        if (_.isFunction(timeFilter)) {
          result = await timeFilter();
        } else {
          result = timeFilter;
        }

        const sourceFieldsData = await this.sourceFields();
        const timeDimensionsLegacyMode = this.props.context.project.props.timeDimensionsLegacyMode === 'ENABLED';

        const sourceFields = !timeDimensionsLegacyMode
          ? sourceFieldsData
              .filter((item) => !item.visible)
              .map((item) => {
                return {
                  ...item,
                  label: item.label.replace(/\(F[^)]*\)/, '').trim(),
                };
              })
          : sourceFieldsData;

        this.setState({ sourceFields });
        this.$formik.resetForm({ values: result });
      }
    }

    loadData = async () => {
      this.setState({ loading: true });
      try {
        const sourceFields = (!_.isEmpty(this.props.dimensions)
          ? this.props.dimensions
          : !!this.state.sourceFields
          ? this.state.sourceFields
          : await this.sourceFields()
        )
          .filter((item) => !item.visible)
          .map((item) => ({
            ...item,
            label: item.label.replace(/\(F[^)]*\)/, '').trim(),
          }));
        const lastDataUpdate = this.state.lastDataUpdate ? this.state.lastDataUpdate : await this.refreshDate();
        const periodicities = _.isEmpty(this.state.periodicities)
          ? await Api.Filter.dynamicPeriodicities()
          : this.state.periodicities;
        this.setState({ lastDataUpdate, sourceFields, periodicities });
        await this.updateFormValues(this.props.timeFilter);
      } catch (e) {
        console.error('Error on loadData()', e);
        UiMsg.ajaxError(null, e);
      } finally {
        this.setState({ loading: false });
      }
    };

    sourceFields = async () => {
      try {
        if (!!this.props.dataSourceCaption) {
          return await Api.Project.sourceTimeFields({
            projectId: this.props.context.project.id,
            sourceName: this.props.dataSourceCaption,
          });
        }
      } catch (e) {
        console.error('Error on sourceFields()', e);
        UiMsg.ajaxError(null, e);
      }
      return [];
    };

    refreshDate = async () => {
      try {
        if (!this.props.path || this.props.path.startsWith('null')) {
          return await Api.Project.lastDataUpdateByProject({
            projectId: this.props.context.project.name,
            sourceName: this.props.dataSourceCaption,
          });
        }
        const { lastUpdate } = await Api.Project.findLastUpdate(this.props.path);
        return lastUpdate;
      } catch (e) {
        console.error('Error on refreshDate()', e);
        UiMsg.ajaxError(null, e);
      }
      return null;
    };

    clearTimeFilter = () => {
      this.props.onChange(DynamicTimeFilterSchema.default());
    };

    render() {
      const { path, showForm, onChange, timeFilter, disabled, blocked, advancedMode, ...props } = this.props;

      return (
        <BreadcrumbToolbar.Item
          {...props}
          disabled={disabled}
          onClick={this.loadData}
          icon="access_time"
          material={true}
          title={
            disabled
              ? `${this.props.context.msg.t('dynamic.periodicity.disabled')} ${
                  !advancedMode ? ` - ${this.props.context.msg.t('advanced.mode.alert.title')}` : ''
                }`
              : this.props.context.msg.t('dynamic.periodicity')
          }
          style={{ color: timeFilter && timeFilter.dimension && timeFilter.periodicity ? '#0088CC' : undefined }}
        >
          <ul
            className="DynamicTimeFilterToolbarItem dropdown-navbar dropdown-menu dropdown-icon-only pull-right dropdown-caret dropdown-close lazy-init dynamic-options"
            style={{ width: '280px' }}
            ref={(ref) =>
              j(ref).on('click', (e) => {
                if (e.target.tagName !== 'BUTTON') {
                  e.stopPropagation();
                }
              })
            }
          >
            <li className="dropdown-menu-header-title text-center">{this.props.context.msg.t('filters')}</li>

            <DynamicTimeFilterListHeader path={path} lastDataUpdate={this.state.lastDataUpdate} />

            {showForm && (
              <li className="dropdown-menu-item-content">
                <div>
                  <Formik
                    initialValues={this.state.initialFormValues}
                    onSubmit={onChange}
                    validationSchema={DynamicTimeFilterSchema}
                    innerRef={(ref) => (this.$formik = ref)}
                  >
                    {({ values }) => (
                      <Form>
                        <Field
                          name="dimension"
                          component={BngField}
                          inputComponent={BngSelect}
                          options={this.state.sourceFields}
                          disabled={blocked}
                        />

                        <Field
                          name="periodicity"
                          component={BngField}
                          inputComponent={BngSelect}
                          groupedOpts={true}
                          options={this.state.periodicities}
                          disabled={_.isEmpty(values.dimension) || blocked}
                        />

                        {!blocked && (
                          <div className="row-fluid" style={{ marginBottom: '0' }}>
                            <div className="span12 btn-fix text-right" style={{ padding: '14px 0px 14px 0px' }}>
                              <Button
                                icon="icon-remove"
                                className="btn-danger btn-small fill-w-49 dummy"
                                onClick={this.clearTimeFilter}
                              >
                                {this.props.context.msg.t('remove.filter')}
                              </Button>{' '}
                              <Button type="submit" icon="icon-ok" className="btn-small fill-w-49 dummy">
                                {this.props.context.msg.t('apply')}
                              </Button>
                            </div>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </li>
            )}
          </ul>
        </BreadcrumbToolbar.Item>
      );
    }
  }
);
