"use strict";

const $ = jQuery;

class NotificationApi {
    static findNotification(id) {
        return $.getJSON(`/spr/notification/${id}`)
    }
}

export default NotificationApi;