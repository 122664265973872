'use strict';

import { Axios, findContextPath } from 'commonUtils';
import Utils from 'components/Utils';

const j = jQuery;

let MODELS_CACHE;

const contextPath = findContextPath();

class KpiApi {
  KPI_UPDATED_EVENT = 'Kpi:Updated';
  KPI_EDIT_MENU_APPLY_EVENT = 'KPI_EDIT_MENU_APPLY_EVENT';

  saveAs = async ({ path, folder, name, description, mutations }) => {
    return await Axios.postData(`/spr/kpis/saveAs`, { path, folder, name, description, mutations });
  };

  getData = ({ path, publisherContent, filter, mutations }) => {
    return Promise.resolve(
      j.postJSON(
        `/spr/ui/kpi/information?${j.param({
          path,
          publisherContent,
          t: Date.now(),
        })}`,
        {
          filter,
          mutations: mutations ?? null,
        }
      )
    );
  };

  async assistedTypes() {
    const { data } = await Axios.get('/spr/kpis/assisted/types');
    data.forEach((d) => (d.image = d.image ? `${contextPath}${d.image}` : d.image));
    return data;
  }

  async createAssistedKpi(values) {
    const { data } = await Axios.post('/spr/kpis/assisted', values);
    return data;
  }

  async findModels() {
    if (MODELS_CACHE) {
      return MODELS_CACHE;
    }

    const { data } = await Axios.get('/spr/kpis/models');
    data.groups.forEach((group) => {
      group.models.forEach((model) => {
        model.image = model.image ? `${contextPath}${model.image}` : model.image;
      });
    });
    MODELS_CACHE = data;
    return data;
  }

  async findOne(pathOrId) {
    if (_.isString(pathOrId)) {
      pathOrId = Utils.Object.extractIdFromPath(pathOrId);
    }
    const { data } = await Axios.get(`/spr/kpis/${pathOrId}`);
    return new KpiUi(data);
  }

  async update(pathOrId, values = {}, preview = false) {
    if (_.isString(pathOrId)) {
      pathOrId = Utils.Object.extractIdFromPath(pathOrId);
    }
    const data = await Axios.postData(`/spr/kpis/${pathOrId}`, values, {
      params: {
        preview,
      },
    });
    return new KpiUi(data);
  }

  async mdxPreview(pathOrId, values = {}) {
    if (_.isString(pathOrId)) {
      pathOrId = Utils.Object.extractIdFromPath(pathOrId);
    }
    const { data } = await Axios.post(`/spr/kpis/${pathOrId}/mdx`, values);
    return data;
  }
}

class KpiUi {
  constructor(data) {
    Object.assign(this, data);
    this.renderOptions.bands.sort((o1, o2) => o1.order - o2.order);
  }

  targetBand() {
    return this.renderOptions.bands.find((b) => b.order === 3);
  }
}

export default KpiApi;
