import style from './KpiImage.module.css';

import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'formik';

import BngField from 'components/bng/form/BngField';
import BngButtonGroup from 'components/bng/ui/Buttons/BngButtonGroup';
import Button from 'components/ui/Button';
import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngInputIconSelector from 'components/bng/form/BngInputIconSelector';
import Api from 'components/Api';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import UiMsg from 'components/ui/UiMsg';
import Dialog from 'components/ui/Dialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import useFetchData from 'components/hooks/useFetchData';
import Utils from 'components/Utils';
import { BngAvatar } from 'components/bng/ui/BngAvatar';

export default {
  defaultVal() {
    return {
      type: 'ICON',
      value: 'bar_chart',
    };
  },
  render({ name, formikProps, ...props }) {
    return <KpiImageComponent name={name} formikProps={formikProps} {...props} />;
  },
};

export function KpiImageComponent({ name, field, formikProps }) {
  const { msg } = useBimContext();
  if (!name) {
    name = `${name ?? field?.name}`;
  }
  const typeName = `${name ?? field?.name}.type`;
  const type = _.get(formikProps.values, typeName);
  const valueName = `${name}.value`;

  return (
    <div className="ParamType KpiImage position-relative" data-test="KpiImage">
      <Field
        name={typeName}
        component={BngField}
        inputComponent={BngButtonGroup}
        options={[
          { value: 'ICON', label: msg.t('icon'), icon: 'insert_emoticon' },
          { value: 'IMAGE', label: msg.t('image'), icon: 'image' },
          { value: 'AVATAR', label: 'Avatar', icon: 'person' },
        ]}
        onChange={(val) => {
          const defaultValue = val === 'ICON' ? 'bar_chart' : '';
          formikProps.setFieldValue(valueName, defaultValue);
        }}
        withLabel={false}
      />
      {type === 'ICON' && (
        <Field
          name={valueName}
          label={`${msg.t('select.the.icon')}:`}
          component={BngField}
          inputComponent={BngInputIconSelector}
          showErrors={false}
          updateJsf={false}
          inline
          round
        />
      )}
      {type === 'IMAGE' && (
        <Field name={valueName} component={BngField} inputComponent={KpiImageUpload} withLabel={false} />
      )}
      {type === 'AVATAR' && (
        <Field name={valueName} component={BngField} inputComponent={KpiAvatarSelector} withLabel={false} />
      )}
    </div>
  );
}

export const VALID_IMAGE_TYPES = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg', 'image/svg+xml'];

function ImageInfo({ name, onClear, uploadProgress = 100 }) {
  const MAX_STRING_SIZE = 32;
  return (
    <div className={`ImageInfo ${style.uploadInfoContainer} flex-center-items gap-1`}>
      <div className={`flex-center-items flex-grow-1 jc-center gap-1`}>
        {uploadProgress >= 100 && <Icon icon="done" className="text-xl" />}
        <span className="fw-500 text-sm" title={name.length > MAX_STRING_SIZE ? name.name : undefined}>
          {_.truncate(name, { length: MAX_STRING_SIZE })}
        </span>
      </div>
      <BngIconButton className="ml-auto" icon="cancel" size="sm" onClick={onClear} />
    </div>
  );
}

function KpiImageUpload({ form, field }) {
  const { msg } = useBimContext();
  const $uploadInput = useRef();

  const [uploadedFile, setUploadedFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);

  let uploadedFileName = uploadedFile?.name || field?.value;
  if (uploadedFileName.startsWith('/upload?content')) {
    uploadedFileName = msg.t('current.image');
  }

  return (
    <UiBlocker className="KpiImageUpload" block={uploadedFile && uploadProgress < 100}>
      {uploadedFileName ? (
        <ImageInfo
          name={uploadedFileName}
          onClear={() => {
            setUploadedFile(null);
            form.setFieldValue(field.name, '');
          }}
          uploadProgress={uploadProgress}
        />
      ) : (
        <>
          <input
            type="file"
            hidden
            ref={$uploadInput}
            onChange={async (e) => {
              try {
                const file = e.target.files[0];
                if (!VALID_IMAGE_TYPES.includes(file.type)) {
                  UiMsg.warn(msg.t('attention'), msg.t('invalid.image.type'));
                  return;
                }

                if (file.size > 2097152) {
                  UiMsg.warn(msg.t('attention'), msg.t('image.size.limit.exceded', ['2mb']));
                  return;
                }

                setUploadProgress(0);
                setUploadedFile(file);
                const [uploadResult] = await Api.Upload.upload(file, {
                  progressListener: (percentDone) => {
                    setUploadProgress(percentDone);
                  },
                  temp: true,
                });
                form.setFieldValue(field.name, uploadResult.path);
              } catch (e) {
                console.error('Error while uploading file', e);
                UiMsg.ajaxError(null, e);
              }
            }}
            accept={VALID_IMAGE_TYPES.join(',')}
          />
          <Button
            className={`bng-button fix w-100 Action AddButton ${style.doUploadBtn}`}
            icon="cloud_upload"
            onClick={() => $uploadInput.current.click()}
          >
            {msg.t('do.upload')}
          </Button>
        </>
      )}
    </UiBlocker>
  );
}

function KpiAvatarSelector({ form, field }) {
  const { msg, project } = useBimContext();
  const dispatch = useReduxDispatch();

  const $users = useFetchData(
    async ([projectId]) => (await Api.Project.findAllUsers(projectId)).map((pu) => pu.user),
    [project.id],
  );

  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (field.value && field.value !== selectedUser?.id) {
      const match = $users.data?.find((u) => `${u.id}` === `${field.value}`);
      if (match) {
        setSelectedUser(match);
      }
    }
  }, [field.value, selectedUser, $users.data]);

  const openAvatarSelection = () => {
    dispatch(
      MODALS.open(KpiAvatarSelectDialog, {
        onChange({ user, closeModal }) {
          setSelectedUser(user);
          form.setFieldValue(field.name, `${user.id}`);
          closeModal();
        },
      }),
    );
  };

  return (
    <div className="KpiAvatarSelector">
      {selectedUser ? (
        <ImageInfo
          name={Utils.Users.displayName(selectedUser)}
          onClear={() => {
            setSelectedUser(null);
            form.setFieldValue(field.name, '');
          }}
        />
      ) : (
        <Button
          className={`bng-button fix w-100 Action AddButton ${style.doUploadBtn}`}
          icon="person"
          onClick={openAvatarSelection}
        >
          {msg.t('select.user')}
        </Button>
      )}
    </div>
  );
}

function KpiAvatarSelectDialog({ closeModal, onChange }) {
  const { project, msg } = useBimContext();

  const $projectUsers = useFetchData(async () => {
    const projectUsers = await Api.Project.findAllUsers(project.id);
    return _.sortBy(projectUsers, [({ user }) => Utils.Users.displayName(user)]);
  });

  return (
    <Dialog
      className={`KpiAvatarSelectDialog ${style.avatarDialog} large`}
      title={msg.t('select.the.user')}
      onClose={closeModal}
      loading={$projectUsers.isLoading}
      newDialogLayout
    >
      <Dialog.Body>
        <div className={`${style.userList} gap-2`}>
          {$projectUsers.data?.map(({ user }) => {
            return (
              <div key={user.id} className={`${style.userContainer} flex-center-items gap-2 p-1`}>
                <BngAvatar userId={user.id} className="w-30" />
                <div className="w-70">
                  <div className="fw-500 text-sm text-overflow-ellipsis" title={Utils.Users.displayName(user)}>
                    {Utils.Users.displayName(user)}
                  </div>
                  <div className="mt-1">
                    <Button
                      type="primary"
                      className="btn-mini"
                      onClick={() => {
                        onChange({ user, closeModal });
                      }}
                    >
                      {msg.t('select')}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Dialog.Body>
    </Dialog>
  );
}
