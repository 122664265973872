import styles from './BngRadio.module.css';

import React from 'react';

export default function BngRadio({
  className = '',
  name = 'radio-button',
  label,
  field,
  form,
  title,
  value,
  checked,
  ...props
}) {
  return (
    <div className={`BngRadio BngCheckbox radio ${styles.RadioButton} ${className}`}>
      <label>
        <input
          type="radio"
          className="ace"
          name={name}
          value={value}
          checked={_.isNil(checked) ? `${field.value ?? ''}` === `${value ?? ''}` : checked}
          {...field}
          onChange={() => {
            form?.setFieldValue(field.name, value);
          }}
          {...props}
        />
        <span className="lbl" title={title}>
          {label}
        </span>
      </label>
    </div>
  );
}
