"use strict";

const $ = jQuery;

class MenuApi {

    static findItems() {
        return $.getJSON('/spr/ui/menu/items');
    }

    static findRoots(projectId) {
        return $.postJSON('/spr/ui/menu', {projectId: projectId});
    }

    static findRootsFor(props) {
        return $.postJSON('/spr/ui/menu', props);
    }
}

export default MenuApi;