import styles from './BngTimePicker.module.css';

import React, { useEffect, useRef, useState } from 'react';
import BngDropdown from 'components/bng/ui/BngDropdown';
import useBimContext from 'components/hooks/useBimContext';
import BngInputIcon from './BngInputIcon';

const initialNumbersValue = (heightValue = 54, numbersLength = 24, value = null) => {
  const initialHourFormat = [
    {
      number: '00',
      translatedValue: numbersLength === 13 ? heightValue.toString() : (heightValue * 2).toString(),
      selected: false,
      hidden: numbersLength === 13,
    },
    {
      number: '01',
      translatedValue: heightValue.toString(),
      selected: false,
    },
  ];

  const arrayOfSelectedValue = initialHourFormat;
  let count = 0;
  for (let index = 0; index < 3; index++) {
    for (let j = 0; j < numbersLength; j++) {
      if ((index === 0 && j < 2) || (numbersLength === 13 && j === 0)) {
        continue;
      }
      if (index === 1 && j === value) {
        if (j.toString().length === 1) {
          arrayOfSelectedValue.push({
            number: `0${j.toString()}`,
            translatedValue: `-${count}`,
            selected: true,
          });
        } else {
          arrayOfSelectedValue.push({
            number: j.toString(),
            translatedValue: `-${count}`,
            selected: true,
          });
        }
        count += heightValue;
        continue;
      }
      if (j.toString().length === 1) {
        arrayOfSelectedValue.push({
          number: `0${j.toString()}`,
          translatedValue: `-${count}`,
          selected: false,
        });
      } else {
        arrayOfSelectedValue.push({
          number: j.toString(),
          translatedValue: `-${count}`,
          selected: false,
        });
      }

      count += heightValue;
    }
  }

  return arrayOfSelectedValue;
};

const returnSelectedValue = (heightValue = 54, numbersLength = 24) => {
  const arrayOfSelectedValue = [
    {
      number: '00',
      translatedValue: (heightValue * 2).toString(),
      arrayNumber: 0,
    },
    {
      number: '01',
      translatedValue: heightValue.toString(),
      arrayNumber: 1,
    },
  ];
  let count = 0;
  for (let index = 0; index < 3; index++) {
    for (let j = 0; j < numbersLength; j++) {
      if ((index === 0 && j < 2) || (numbersLength === 13 && j === 0)) {
        continue;
      }
      if (j.toString().length === 1) {
        arrayOfSelectedValue.push({
          number: `0${j.toString()}`,
          translatedValue: `-${count}`,
          selected: false,
        });
      } else {
        arrayOfSelectedValue.push({
          number: j.toString(),
          translatedValue: `-${count}`,
          selected: false,
        });
      }

      count += heightValue;
    }
  }
  return arrayOfSelectedValue;
};

const HOURS_SUFFIX = (height, hourFormat) => [
  {
    number: 'AM',
    translatedValue: height ? (height * 2).toString() : 0,
    selected: hourFormat?.hourFormat === 'AM' || false,
    arrayNumber: 0,
  },
  {
    number: 'PM',
    translatedValue: height ? height.toString() : 0,
    selected: hourFormat?.hourFormat === 'PM' || false,
    arrayNumber: 1,
  },
];

const WHEEL_TYPE = {
  HOUR_24: {
    key: 'HOUR_24',
    maxValue: 24,
    parse: (value) => parseInt(value.slice(0, 2)),
    valueForSubmit: ({ prev, item }) => `${item.number}:${prev.slice(3, 6)}`,
    className: styles.HourWheelFormat,
  },
  HOUR_12: {
    key: 'HOUR_12',
    maxValue: 13,
    parse: (value) => parseInt(value.slice(0, 2)),
    valueForSubmit: ({ prev, item }) => `${item.number}:${prev.slice(3, 6)}`,
    className: styles.HourWheelFormat,
  },
  MINUTE: {
    key: 'MINUTE',
    maxValue: 60,
    parse: (value) => parseInt(value.slice(3, 6)),
    valueForSubmit: ({ prev, item }) => `${prev.slice(0, 2)}:${item.number}`,
    className: styles.MinuteWheelFormat,
  },
  HOUR_SUFFIX: {
    cutomInitialValues: (height, hourFormat) => HOURS_SUFFIX(height, hourFormat),
    customTranslateValues: (hours) => parseInt(hours.filter((item) => item.selected)[0].translatedValue),
    valueForSubmit: ({ prev, item }) => {
      return { mount: true, hourFormat: HOURS_SUFFIX().find((s) => s.arrayNumber == item.number).number };
    },
  },
};

const NumberWheel = ({ height, value, setValue, use12Hours, type = WHEEL_TYPE.HOUR_24, hourFormat }) => {
  const [hours, setHours] = useState(
    !!type.cutomInitialValues
      ? type.cutomInitialValues(height, hourFormat)
      : initialNumbersValue(height, type.maxValue, type.parse(value))
  );
  const mainListRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [firstCursorPosition, setFirstCursorPosition] = useState(null);
  const [currentTranslatedValue, setCurrentTranslatedValue] = useState(
    !!type.customTranslateValues
      ? type.customTranslateValues(hours)
      : parseInt(
          initialNumbersValue(height, type.maxValue, type.parse(value)).filter(
            (item) => item.number == type.parse(value) && item.selected
          )[0]?.translatedValue
        )
  );

  const [startCapture, setStartCapture] = useState(false);
  const [showFinalTranslate, setShowFinalTranslate] = useState(false);
  const [dragStartTime, setDragStartTime] = useState(null);
  const [dragEndTime, setDragEndTime] = useState(null);
  const [dragDuration, setDragDuration] = useState(null);
  // drag type fast or slow
  const [dragType, setDragType] = useState(null);
  const [dragDirection, setDragDirection] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);

  const handleMouseDown = (e) => {
    setShowFinalTranslate(false);
    setFirstCursorPosition(e.clientY);
    setStartCapture(true);
    setDragStartTime(performance.now());
  };

  const handleTouchStart = (e) => {
    setShowFinalTranslate(false);
    setFirstCursorPosition(e.targetTouches[0].clientY);
    setStartCapture(true);
    setDragStartTime(performance.now());
  };

  const handleMouseUp = (e) => {
    setStartCapture(false);
    setCurrentTranslatedValue((prev) => prev + cursorPosition);
    setShowFinalTranslate(true);
    setDragEndTime(performance.now());
    if (performance.now() - dragStartTime <= 100) {
      setDragType('fast');
    } else {
      setDragType('slow');
    }
    if (cursorPosition < 0) {
      setDragDirection('down');
    } else {
      setDragDirection('up');
    }
  };

  const handleMouseLeave = (e) => {
    setStartCapture(false);
    setCurrentTranslatedValue((prev) => prev + cursorPosition);
    setShowFinalTranslate(true);
    setDragEndTime(performance.now());
    if (performance.now() - dragStartTime <= 100) {
      setDragType('fast');
    } else {
      setDragType('slow');
    }

    if (cursorPosition < 0) {
      setDragDirection('down');
    } else {
      setDragDirection('up');
    }
  };

  const handleMouseMove = (e) => {
    if (startCapture) {
      setCursorPosition(e.clientY - firstCursorPosition);
    } else {
      setCursorPosition(0);
    }
  };

  const handleTouchMove = (e) => {
    if (startCapture) {
      setCursorPosition(e.targetTouches[0].clientY - firstCursorPosition);
    } else {
      setCursorPosition(0);
    }
  };

  useEffect(() => {
    if (startCapture) {
      mainListRef.current.style.transform = `translateY(${currentTranslatedValue + cursorPosition}px)`;
    }
  }, [cursorPosition]);

  const processFinalValue = (value) => {
    switch (type) {
      case WHEEL_TYPE.HOUR_12:
        if (value < height * -34) value = height * -34;
        if (value > height) value = height;
        break;
      case WHEEL_TYPE.HOUR_24:
        if (value < height * -69) value = height * -69;
        if (value > height * 2) value = height * 2;
        break;
      case WHEEL_TYPE.HOUR_SUFFIX:
        let finalValue = Math.round(currentTranslatedValue / height) * height;
        if (finalValue < height) finalValue = height;
        if (finalValue > height * 2) finalValue = height * 2;
        break;
      default:
        if (value < height * -177) value = height * -177;
        if (value > height * 2) value = height * 2;
    }
    return value;
  };

  useEffect(() => {
    if (showFinalTranslate) {
      setDragDuration(dragEndTime - dragStartTime);
      if (dragEndTime - dragStartTime <= 100 && cursorPosition !== 0) {
        let currentValue;
        if (dragDirection === 'down') {
          currentValue = currentTranslatedValue - (120 / (dragEndTime - dragStartTime)) * 100;
        } else if (dragDirection === 'up') {
          currentValue = currentTranslatedValue + (120 / (dragEndTime - dragStartTime)) * 100;
        }
        let finalValue = Math.round(currentValue / height) * height;
        finalValue = processFinalValue(finalValue);

        mainListRef.current.style.transform = `translateY(${finalValue}px)`;
        setCurrentTranslatedValue(finalValue);
      }
      if (dragEndTime - dragStartTime > 100 && cursorPosition !== 0) {
        let finalValue = Math.round(currentTranslatedValue / height) * height;
        processFinalValue(finalValue);

        mainListRef.current.style.transform = `translateY(${finalValue}px)`;
        setCurrentTranslatedValue(finalValue);
      }
      setCursorPosition(0);
    }
  }, [showFinalTranslate]);

  const handleTransitionEnd = (e) => {
    if (type !== WHEEL_TYPE.HOUR_SUFFIX || e.propertyName === 'transform') {
      returnSelectedValue(height, type.maxValue).map((item) => {
        if (parseInt(item.translatedValue) === currentTranslatedValue) {
          setSelectedNumber(item.arrayNumber);
          setValue((prev) => {
            return type.valueForSubmit({ prev, item });
          });
          setHours(() => {
            const newValue = type.cutomInitialValues
              ? type.cutomInitialValues(height, type.valueForSubmit({ item }))
              : initialNumbersValue(height, type.maxValue).map((hour) => {
                  if (hour.number == item.number && hour.translatedValue == currentTranslatedValue) {
                    return {
                      ...hour,
                      selected: true,
                    };
                  }
                  return hour;
                });
            return newValue;
          });
        }
      });
    }
  };
  const handleClickToSelect = (e) => {
    if (cursorPosition === 0) {
      setCurrentTranslatedValue(parseInt(e.target.dataset.translatedValue));
    }
  };

  const isFastCondition = showFinalTranslate && dragType === 'fast';
  const isSlowCondition = showFinalTranslate && dragType === 'slow';

  /** ***************************   handle wheel scroll ************************* */

  const handleWheelScroll = (e) => {
    let multiplyNumber = 1;

    switch (type) {
      case WHEEL_TYPE.HOUR_12:
        if (e.deltaY > 0) {
          multiplyNumber = 1;
        } else {
          multiplyNumber = -34;
        }
        break;
      case WHEEL_TYPE.HOUR_24:
        if (e.deltaY > 0) {
          multiplyNumber = 2;
        } else {
          multiplyNumber = -69;
        }
        break;
      case WHEEL_TYPE.HOUR_SUFFIX:
        if (e.deltaY > 0) {
          multiplyNumber = 1;
        } else {
          multiplyNumber = 2;
        }
        break;
      default:
        if (e.deltaY > 0) {
          multiplyNumber = 2;
        } else {
          multiplyNumber = -177;
        }
    }

    if (e.deltaY > 0) {
      if (currentTranslatedValue < height * multiplyNumber) {
        setCurrentTranslatedValue((prev) => prev + height);
      }
    } else if (currentTranslatedValue > height * multiplyNumber) {
      setCurrentTranslatedValue((prev) => prev - height);
    }
  };

  return (
    <div
      className={styles.NumberWheel}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{ height: height * 5 }}
      onWheel={handleWheelScroll}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleMouseUp}
    >
      {/* <PickerEffects height={height} /> */}
      <div
        ref={mainListRef}
        className={`${isFastCondition === true && styles.fastScroll} ${isSlowCondition === true && styles.slowScroll}`}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateY(${currentTranslatedValue}px)` }}
      >
        {hours.map((hourObj, index) => {
          return (
            <div key={index} className={`${type.className}`} style={{ height: `${height}px` }}>
              <div
                className={`${styles.cellFormat} ${hourObj.selected ? styles.cellSelected : ''} ${
                  hourObj?.hidden ? styles.cellHidden : ''
                }`}
                onClick={handleClickToSelect}
                data-translated-value={hourObj.translatedValue}
              >
                {hourObj.number}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TimePickerSelection = ({
  initialValue,
  onChange,
  height,
  onCancel,
  cancelButtonText,
  saveButtonText,
  controllers,
  separator,
  use12Hours,
  onAmPmChange,
  context,
  closeDropdown,
  className = '',
  name,
}) => {
  const initialTimeValue = use12Hours ? initialValue.slice(0, 5) : initialValue;
  const [value, setValue] = useState(initialTimeValue);
  const [hourFormat, setHourFormat] = useState({
    mount: false,
    hourFormat: initialValue.slice(6, 8),
  });

  useEffect(() => {
    if (controllers === false) {
      const finalSelectedValue = use12Hours ? `${value} ${hourFormat.hourFormat}` : value;
      onChange({ target: { name: name, value: finalSelectedValue } });
    }
  }, [value]);

  useEffect(() => {
    if (hourFormat.mount) {
      onAmPmChange(hourFormat.hourFormat);
    }
  }, [hourFormat]);

  const handleSave = () => {
    const finalSelectedValue = use12Hours ? `${value} ${hourFormat.hourFormat}` : value;
    onChange({ target: { name: name, value: finalSelectedValue } });
    closeDropdown();
  };
  const handleCancel = () => {
    onCancel();
    closeDropdown();
  };

  const params = {
    height,
    value,
    setValue,
    controllers,
    use12Hours,
    onAmPmChange,
    setHourFormat,
    hourFormat,
  };

  return (
    <div className={`${styles.BngTimePicker} ${className}`}>
      {controllers && (
        <div className={styles.buttonContainer}>
          <button className={`${styles.actionButtons} ${styles.cancelButton}`} onClick={handleCancel}>
            {cancelButtonText || context.msg.t('cancel')}
          </button>
          <button className={`${styles.actionButtons}`} onClick={handleSave}>
            {saveButtonText || context.msg.t('save')}
          </button>
        </div>
      )}
      <div className={styles.WheelsContainer} style={{ height: `${height * 5}px` }}>
        <div
          className={styles.selectedOverlay}
          style={{
            top: `${height * 2 + 20}px`,
            height: `${height}px`,
          }}
        />
        <NumberWheel type={use12Hours ? WHEEL_TYPE.HOUR_12 : WHEEL_TYPE.HOUR_24} {...params} />
        {separator && <div className={styles.WheelsSeparator}>:</div>}
        <NumberWheel type={WHEEL_TYPE.MINUTE} {...params} />
        {use12Hours && <NumberWheel type={WHEEL_TYPE.HOUR_SUFFIX} {...params} setValue={setHourFormat} />}
      </div>
    </div>
  );
};

export default function BngTimePicker({
  cellHeight = 28,
  placeHolder,
  onFocus = _.noop,
  onCancel = _.noop,
  disabled = false,
  required = false,
  cancelButtonText,
  saveButtonText,
  controllers = true,
  separator = true,
  id = null,
  use12Hours = false,
  onAmPmChange = _.noop,
  onOpen = _.noop,
  popupClassName = null,
  inputClassName = null,
  field,
  form,
}) {
  const context = useBimContext();
  const [height, setHeight] = useState(cellHeight);
  const {onChange, name, value, ...fieldProps} = field|| {};

  const handleFocus = () => {
    onFocus();
    onOpen();
  };

  let fieldValue = !!value ? value : '01:00';

  if (use12Hours) {
    fieldValue = `${fieldValue} AM`;
  }

  const handleChange = async (e) => {
    await onChange(e);
  };

  return (
    <>
      <BngDropdown
        popperClassName={styles.BngTimePickerPopup}
        overDialog={true}
        customButton={({ openDropdown }) => {

          const handleSpace= (e) => {
            if (e.keyCode === 32) {
              e.preventDefault();
              e.stopPropagation();
              openDropdown(e);
            }
          };

          return (
            <div className={styles.TimePickerInputWrapper}>
              <BngInputIcon
                id={id}
                name={name}
                className={`${styles.TimePickerInput} ${inputClassName || ''}`}
                type="time"
                placeholder={placeHolder || context.msg.t('select')}
                disabled={disabled}
                required={required}
                onFocus={handleFocus}
                onChange={handleChange}
                icon={'schedule'}
                onClickIcon={openDropdown}
                field={field}
                form={form}
                inputProps={{
                  onKeyDown: handleSpace
                }}
                {...fieldProps}
              />
            </div>
          );
        }}
        customOptions={({ closeDropdown }) => {
          return (
            <TimePickerSelection
              onChange={handleChange}
              height={height}
              onCancel={onCancel}
              cancelButtonText={cancelButtonText}
              saveButtonText={saveButtonText}
              controllers={controllers}
              separator={separator}
              use12Hours={use12Hours}
              onAmPmChange={onAmPmChange}
              initialValue={fieldValue}
              context={context}
              className={popupClassName}
              name={name}
              closeDropdown={closeDropdown}
            />
          );
        }}
      />
    </>
  );
}
