import styles from './PersonalPage.module.css';

import Favorite from 'components/ui/favorite/Favorite';
import RecentActivities from 'components/ui/recent-activities/RecentActivities';
import NewsHighlight from 'components/ui/news/NewsHighlight';
import FoldersPage from 'components/bng/pages/admin/folders/FoldersPage';

export default function PersonalPage() {
  return (
    <div className={`PersonalPage ${styles.personalPageContainer}`}>
      <div className={styles.inlineDivs}>
        <div className={styles.flexPanel}>
          <Favorite />
        </div>
        <div className={styles.flexPanel}>
          <RecentActivities />
        </div>
      </div>
      <div id={'home-academy'}>
        <NewsHighlight />
      </div>
      <div>
        <FoldersPage embedded={true} />
      </div>
    </div>
  );
}
