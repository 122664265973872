import React from 'expose-loader?exposes=React!react';
import 'expose-loader?exposes=ReactDOM!react-dom';
import Popper from 'popper.js';
import Tooltip from 'tooltip.js/dist/esm/tooltip.min';

import 'expose-loader?exposes=FilterFactory!components/filter/FilterFactory';
import 'expose-loader?exposes=ComponentFactory!components/ComponentFactory';
import 'expose-loader?exposes=Actions!components/ui/redux/Actions';
import 'imports/JsfModalRefs';

window.Popper = Popper;
window.Tooltip = Tooltip;

// Prototype conflicts with native toJson on objects, remove these changes after the page is loaded
jQuery(function () {
    if (window.Prototype) {
        delete Object.prototype.toJSON;
        delete Array.prototype.toJSON;
        if (typeof Hash !== 'undefined') {
            delete Hash.prototype.toJSON;
        }
        delete String.prototype.toJSON;
    }
});