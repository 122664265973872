import { Axios } from 'commonUtils';

export default class ProjectQuotaRequestApi {
  async findRequests({ projectId, limit, onlyCurrentMonth }) {
    return await Axios.getData(`/spr/projectQuotaRequests/${projectId}`, {
      params: {
        limit,
        onlyCurrentMonth,
      },
    });
  }

  async requestQuota(projectId) {
    return await Axios.postData(`/spr/projectQuotaRequests/${projectId}/requestQuota`);
  }
}
