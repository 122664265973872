import styles from './LdapUsers.module.css';

import React, { useEffect, useMemo, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import BngTable from 'components/bng/ui/BngTable';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import useBimContext from 'components/hooks/useBimContext';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import BngSearch from 'components/bng/ui/BngSearch';
import Icon from 'components/ui/common/Icon';
import UiMsg from 'components/ui/UiMsg';

export default function LdapUserDialog({ closeModal, beforeClose }) {
  const { project, msg } = useBimContext();

  const [ldapUserList, setLdapUserList] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    fetchLdapUsers();
  }, []);

  const fetchLdapUsers = async () => {
    try {
      const temp = await Api.Project.findLdapUsers(project.id);
      setLdapUserList(temp);
    } catch (e) {
      console.error('Error on fetchLdapUsers()', { e });
      UiMsg.ajaxError(null, e);
    }
  };

  const tableCols = useMemo(() => {
    return [
      {
        render: (row) => (
          <BngCheckbox
            field={{ value: row.enabled }}
            onChange={() => {
              const temp = ldapUserList.map((u) => {
                if (u.mail === row.mail) {
                  return { ...u, enabled: !u.enabled };
                }
                return u;
              });
              setLdapUserList(temp);
            }}
          />
        ),
      },
      {
        label: msg.t('name'),
        render: (row) => row.name,
      },
      {
        label: msg.t('email'),
        render: (row) => row.mail,
      },
      {
        label: msg.t('dn'),
        render: (row) => <Icon icon={'info'} style={{ fontSize: 15 }} title={row.dn} />,
      },
    ];
  }, [ldapUserList]);

  const filterList = useMemo(() => {
    if (!search) {
      return ldapUserList;
    }
    return ldapUserList.filter((u) => u.name.match(search) || u.mail.match(search) || u.dn.match(search));
  }, [ldapUserList, search]);

  const updateLdapUsers = async () => {
    try {
      await Api.Project.updateLdapUsers(project.id, ldapUserList);
      UiMsg.ok(msg.t(`LDAP.user.creation.success`));
      await beforeClose();
      closeModal();
    } catch (e) {
      console.error('Error on updateLdapUsers()', { e });
      UiMsg.ajaxError(msg.t(`LDAP.user.creation.fail`), e);
    }
  };

  return (
    <Dialog
      className={`LdapUserDialog ${styles.dialog}`}
      title={msg.t('available_users')}
      onClose={closeModal}
      afterBody={
        <Dialog.Footer className={`${styles.dialogFooter}`}>
          <DefaultDialogActions okLabel={'add'} onClickSaveButton={updateLdapUsers} closeModal={closeModal} />
        </Dialog.Footer>
      }
    >
      <Dialog.Body className={`${styles.dialogBody}`}>
        <BngSearch onChange={setSearch} className={`${styles.searchBtn}`} />
        <BngTable
          showEmptyAlert={true}
          stickyHeader={true}
          cols={tableCols}
          rows={filterList}
          className={styles.table}
        />
      </Dialog.Body>
    </Dialog>
  );
}
