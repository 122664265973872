import React, {Component} from 'react';
import PropTypes from 'prop-types';

const BetaLabel = (props) => {
    return (
        <span className="label label-important label-beta pull-right">BETA</span>
    );
};

BetaLabel.propTypes = {};

export default BetaLabel;