import styles from './ContactUs.module.css';
import callImg from './assets/call_br.svg';
import chatImg from './assets/chat.svg';
import whatsappImg from './assets/whatsapp.svg';

import React from 'react';

import useBimContext from 'components/hooks/useBimContext';
import useTranslation from 'components/hooks/useTranslation';
import { Axios } from 'commonUtils';

export default function ContactUs() {
  const context = useBimContext();
  const { commercialSupportPhone } = useBimContext();
  const { t } = useTranslation();

  return (
    <div className={`ContactUs ${styles.centeredParagraphs}`}>
      <div>
        <p className={`${styles.subTitle} mb-1`}>{t('contact.first.step')}</p>
        <p className={styles.text}>{t('contact.desc.first.step')}</p>
      </div>
      <div className={styles.buttonGroup}>
        <button
          className={styles.subTitle}
          onClick={() => {
            openSuportChatWithoutProject(context.user.email, context.user.displayName, context.support.chat);
          }}
        >
          <img alt="Chat" src={chatImg} className={'mr-2'} />
          Chat
        </button>

        {!_.isEmpty(commercialSupportPhone) && (
          <button
            className={styles.subTitle}
            onClick={() => {
              const whatsappUrl = Axios.getUri({
                url: 'https://api.whatsapp.com/send',
                params: { phone: commercialSupportPhone, type: 'phone_number', app_absent: '0' },
              });

              window.open(whatsappUrl, '_blank');
            }}
          >
            <img alt="Whatsapp" src={whatsappImg} className={'mr-2'} />
            +55 (51) 3709-2950
          </button>
        )}
        <button className={styles.subTitle}>
          <img alt="Call" src={callImg} className={'mr-2'} />
          0800 648 1088
        </button>
      </div>
    </div>
  );
}
