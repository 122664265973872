import React, { Component } from 'react';
import {
  BreadcrumbToolbar,
  ExportToolbarItem,
  FavoriteToolbarItem,
  InformationToolbarItem,
  NotesToolbarItem,
  PublisherToolbarItem,
  ReloadToolbarItem,
  ResizeToolbarItem,
} from 'components/ui/breadcrumb-toolbar';
import ContextEnhancer from 'components/ContextEnhancer';
import HideHeaderToolbarItem, { HideHeaderPageOpts } from 'components/ui/breadcrumb-toolbar/HideHeaderToolbarItem';

class DashBreadcrumbToolbar extends Component {
  static defaultProps = {
    path: '',
    caption: '',
    containItems: true,
    onResize: _.noop,
    onReload: _.noop,
  };

  state = {
    onAjax: false,
  };

  resizeDashboard = async (event) => {
    event.preventDefault();
    this.props.onResize();
  };

  reloadDashboard = async () => {
    this.props.onReload();
  };

  render() {
    const { onAjax } = this.state;
    const { path, caption, containItems, onExportToPdf } = this.props;

    return (
      <BreadcrumbToolbar loading={onAjax}>
        {this.props.children}

        <ExportToolbarItem
          disabled={!containItems}
          containItems={containItems}
          caption={caption}
          path={path}
          onExportToPdf={onExportToPdf}
        />

        {this.props.context.cockpitEnabled && <HideHeaderToolbarItem location={HideHeaderPageOpts.BREADCRUMBS} />}

        <ResizeToolbarItem onClick={this.resizeDashboard} disabled={false} />

        <ReloadToolbarItem onClick={this.reloadDashboard} />

        <PublisherToolbarItem path={path} caption={caption} />

        <NotesToolbarItem path={path} />

        <FavoriteToolbarItem path={path} />

        <InformationToolbarItem
          title={this.props.context.msg.t('object.information.dashboard')}
          dataSource={null}
          path={path}
        />
      </BreadcrumbToolbar>
    );
  }
}

export default ContextEnhancer(DashBreadcrumbToolbar);
