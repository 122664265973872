import './RequiredFilters.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';

class RequiredFilters extends Component {
  static propTypes = {
    requiredFiltersInfo: PropTypes.array,
  };

  static defaultProps = {
  };

  openFilterDialog = async (event, filter) => {
    // See FilterItem.js
    bimEventBus.emit('FilterItem:openDialog', { id: filter.id });
  };

  renderRequiredFilter = (filter) => {
    if (filter.ok) {
      return null;
    }
    return (
      <li
        className="required-filter-tag"
        title={filter.caption}
        key={filter.id ? filter.id : filter.caption}
        onClick={(e) => this.openFilterDialog(e, filter)}
      >
        <i className="icon-bim-filter"></i> {filter.caption}
      </li>
    );
  };

  render() {
    const { requiredFiltersInfo } = this.props;

    return (
      <div className="dashboard-required-filter-container">
        <div className="required-filter-box">
          <img alt="no-filter" src={`${Api.baseUrl()}/images/bng/nofilter.png`} width="156" height="156" />
          <h3>{this.props.context.msg.t('attention')}</h3>
          <h6>{this.props.context.msg.t('dashboard.required.filters.message')}</h6>
          <ul className="unstyled required-filter-list">
            {requiredFiltersInfo.map((rfi) => this.renderRequiredFilter(rfi))}
          </ul>
        </div>
      </div>
    );
  }
}

export default ContextEnhancer(RequiredFilters);
