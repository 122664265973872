import ContextEnhancer from "components/ContextEnhancer";
import React, {Component} from "react";
import PropTypes from "prop-types";
import './LoadLimitButton.css';
import {connect} from "react-redux";
import {MODALS} from "components/ui/redux/Actions";
import LoadLimitDialog from "components/bng/load-limit/LoadLimitDialog";

class LoadLimitButton extends Component {

    static propTypes = {
        accountId: PropTypes.string,
        small: PropTypes.bool
    };

    static defaultProps = {
        accountId: null,
        small: true
    };

    openLoadLimitDialog = () => {
        this.props.dispatch(MODALS.open(LoadLimitDialog, ({accountId: this.props.accountId})));
    };

    render() {
        let { context, small } = this.props;

        return (
            <button className={`btn iceCmdBtn btn-mini btn-warning ${small ? 'btn-table' : ''}`}
                    title={this.props.accountId ? context.msg.t('loadLimits.editAccount') : context.msg.t('loadLimits.editGlobal')}
                    onClick={() =>{
                        this.openLoadLimitDialog();
                    }}>
                <i className={`material-icons ${small ? null : 'load-limit-button-icon'}`}>grid_on</i>
                {small ? null : ' ' + context.msg.t('loadLimits.editGlobal')}
            </button>
        );
    }

}


export default ContextEnhancer(connect()(LoadLimitButton));