import React, {Component} from "react";
import PropTypes from "prop-types";
import {Field} from "formik";

import ContextEnhancer from "components/ContextEnhancer";
import {BngField} from "components/bng/form/BngField";
import BngRadio from "components/bng/form/BngRadio";
import Button from "components/ui/Button";
import Api from "components/Api";

class ConfigureLink extends Component {
    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        path: PropTypes.string,
        enablelink: PropTypes.bool,
        fixedSize: PropTypes.bool,
        width: PropTypes.number,
        height: PropTypes.number,
        type: PropTypes.string,
        enableVisualizeButton: PropTypes.bool
    };

    static defaultProps = {
        path: '',
        enablelink: false,
        fixedSize: false,
        type: 'public',
        width: 0,
        height: 0,
        enableVisualizeButton: false
    };

    openLink = () => {
        window.open(this.buildLink(), '_blank');
    };


    buildLink() {
        const type = this.props.type === 'public' ? 'api' : 'spr';
        const full = this.props.type === 'full';
        return `${window.location.origin}${Api.baseUrl()}/${type}/publisher${full ? '/full' : ''}${this.props.path}${full ? '&appToken=<appToken>' : ''}`;
    }

    render() {
        const link = this.buildLink();
        let iframe = `<iframe src="${link}"></iframe>`;

        if (this.props.fixedSize) {
            if (this.props.width > 0) {
                iframe = `<iframe width="${this.props.width}" src="${link}"></iframe>`;
            }

            if (this.props.height > 0) {
                iframe = `<iframe height="${this.props.height}" src="${link}"></iframe>`
            }

            if (this.props.height > 0 && this.props.width > 0) {
                iframe = `<iframe width="${this.props.width}" height="${this.props.height}" src="${link}"></iframe>`
            }
        }

        return (
            <div className="publisher-tab-container">
                <div style={{ fontWeight: '500' }}>{this.props.context.msg.t('sharing')}</div>

                <div style={{ display: 'flex' }}>
                    <div style={{ width: 100 }}>
                        <Field name={`sharing${this.props.type}`}
                               component={BngField}
                               inputComponent={BngRadio}
                               asProps={{ label: this.props.context.msg.t('enabled'), value: true }}
                               withLabel={false}
                        />
                    </div>
                    <div style={{ width: 100 }}>
                        <Field name={`sharing${this.props.type}`}
                               component={BngField}
                               inputComponent={BngRadio}
                               asProps={{ label: this.props.context.msg.t('inactive'), value: false }}
                               withLabel={false}
                        />
                    </div>
                </div>
                <div style={{display: this.props.enablelink ? 'block' : 'none'}}>
                    <div style={{fontWeight: '500', marginTop: 20}}>{this.props.context.msg.t('link')}</div>
                    <div style={{position: 'relative'}}>
                        <BngField
                            style={{
                                width: 800,
                                cursor: this.props.enableVisualizeButton ? 'text' : 'not-allowed'
                            }}
                            value={link}
                            readOnly={true}
                            withLabel={false}
                        />
                        <Button
                            onClick={this.props.enableVisualizeButton ? this.openLink : undefined}
                            key={this.props.enableVisualizeButton ? 'btnEnabled' : 'btnDisabled'}
                            title={this.props.enableVisualizeButton ? '' : this.props.context.msg.t('publisher.visualizebutton.disabled')}
                            style={{
                                position: 'absolute',
                                top: '5px',
                                right: '25px'
                            }}
                            className={`btn-publisher-success btn btn-mini ${!this.props.enableVisualizeButton ? 'Disabled' : ''}`}

                        >
                            {this.props.context.msg.t('print')}
                        </Button>
                    </div>

                    <div style={{fontWeight: '500', marginTop: 20}}>{this.props.context.msg.t('incorporate')}</div>
                    <div>
                        <BngField
                            style={{
                                width: 800,
                                cursor: this.props.enableVisualizeButton ? 'text' : 'not-allowed'
                            }}
                            value={iframe}
                            readOnly={true}
                            withLabel={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ContextEnhancer(ConfigureLink);