import React from 'react';
import PropTypes from 'prop-types';

import './ReduxFormSelectRadio.css';

export default class ReduxFormSelectRadio extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        horizontal: PropTypes.bool,
    };

    static defaultProps = {
        style: {},
        options: [],
    };

    render() {
        return (
            <div className={`ReduxFormSelectRadio
            ${this.props.horizontal ? 'horizontal' : ''}
            ${this.props.className || ''}`}>
                {this.props.options.map(({value, label}, idx) => {
                    return (
                        <label key={idx} className="ReduxFormSelectRadioItem">
                            <input type="radio" className="ace"
                                   name={this.props.name}
                                   disabled={this.props.disabled}
                                   value={value}
                                   checked={this.props.value === value}
                                   onChange={this.props.onChange}
                            />
                            <span className="lbl">{label}</span>
                        </label>
                    );
                })}
            </div>
        );
    }

};