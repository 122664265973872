import React, {Component} from "react";
import PropTypes from "prop-types";

class LoadingPlaceholder extends Component {

    static propTypes = {
        path: PropTypes.string.isRequired,
        viewType: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        easeOut: PropTypes.bool,
        animation: PropTypes.bool,
    };

    static defaultProps = {
        path: '',
        viewType: '',
        width: window.innerWidth,
        height: window.innerHeight,
        easeOut: false,
        animation: true,
    };

    calculateFontSize = () => {
        const {width, height} = this.props;
        const size = Math.min(width ?? 99999, height ?? 99999);
        return Math.round(size * 0.8);
    };

    imagePlaceholder = () => {
        const {path, viewType, width, height} = this.props;

        let icon = 'html';

        if (path.length === 0) {
            //implementado para futuras alteracoes
            switch (viewType) {
                case 'label': {
                    icon = 'html';
                    break;
                }
                case 'icon' : {
                    icon = 'html';
                    break;
                }
                case 'imageContent' : {
                    icon = 'html';
                    break;
                }
                case 'text': {
                    icon = 'html';
                    break;
                }
                case 'textNew': {
                    icon = 'html';
                    break;
                }
                default: {
                    icon = 'html';
                    break;
                }
            }
        } else {
            const extension = path.substring(path.lastIndexOf('.') + 1);
            switch (extension) {
                case 'analysis': {
                    if (viewType === 'html') {
                        icon = 'table-1';
                    } else {
                        icon = 'chart';
                    }
                    break;
                }
                case 'newmap': {
                    icon = 'map';
                    break;
                }
                case 'kpi': {
                    icon = 'kpi-';
                    if(width > height) {
                        icon += 'horizon';
                    } else {
                        icon += 'vertical';
                    }
                    break;
                }
                case 'bigtable': {
                    icon = 'table-1';
                    break;
                }
                default:
                    icon = 'html';
                    break;
            }
        }
        return icon;
    };

    render() {
        const easeOut = this.props.easeOut ? 'loading-ease-out' : '';
        const animation = this.props.animation ? 'linear-wipe' : 'static-load';
        const className = _.join([`icon-bim-${this.imagePlaceholder()}`, animation, easeOut], " ");
        return (
            <div className="LoadingPlaceholder">
                <h1 className={className} style={{fontSize: this.calculateFontSize()}}/>
            </div>
        );
    }

}

export default LoadingPlaceholder;