import styles from './AddonInfoPage.module.css';

import React, { useEffect, useRef, useState } from 'react';

import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import Button from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import BngPasswordConfirmation from 'components/bng/ui/BngPasswordConfirmation';
import UiMsg from 'components/ui/UiMsg';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngAddonActivatedHelp from 'components/ui/navbar/BngAddonActivatedHelp';
import BngVideoModal from 'components/bng/ui/BngVideoModal';
import AddonStars from 'components/ui/navbar/addons/AddonStars';
import AddonPageContent from 'components/ui/navbar/addons/AddonPageContent';

const HeaderBlock = ({
  className = '',
  image,
  loading,
  isAdmin,
  scrollReference,
  isEnabled,
  isPlan,
  enabledButtonAction = null,
  enabledButtonLabel = '',
  addon,
}) => {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [showVideo, setShowVideo] = useState(false);

  const scrollToPrice = () => {
    scrollReference.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const enableActions = enabledButtonAction && isEnabled;

  const title = context.msg.translateIfHasKey(`addon.${addon.id}.header.title`) ?? context.msg.t(addon.nameKey);
  const desc = context.msg.t(`addon.${addon.id}.header.desc`);
  const video = context.msg.containKey(`addon.${addon.id}.header.video`) ? `addon.${addon.id}.header.video` : null;
  //TODO:: Change button to BngButton when #6922 is released
  return (
    <div className={`${styles.AddonInfoHeader} ${styles.Block} Addon-${addon.id} ${className}`}>
      <div className={styles.titleWrapper}>
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        <p dangerouslySetInnerHTML={{ __html: desc }}></p>
        <div className={styles.headerButtonWrapper}>
          <button
            onClick={scrollToPrice}
            className={`${styles.primaryButton} ${styles.buttonStyle}`}
            style={{ backgroundColor: `${addon.iconColor}` }}
          >
            {context.msg.t(
              isEnabled ? (isPlan ? 'change.plan' : 'enabled') : isAdmin ? 'activate.addon' : 'request.activation'
            )}
          </button>
          {(video || enableActions) && (
            <Button
              loading={loading}
              onClick={() => {
                enableActions ? enabledButtonAction({ context, dispatch }) : setShowVideo(true);
              }}
              className={`${styles.secondaryButton} ${styles.buttonStyle}`}
            >
              {enableActions ? context.msg.t(enabledButtonLabel) : `${context.msg.t('see.video')} (1 min)`}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.headingImgWrapper}>
        <img src={`${Api.baseUrl()}/resources/images/addons/${addon.id}/${image}`} className={styles.headingImg} />
      </div>
      {showVideo && (
        <BngVideoModal
          className={styles.headingVideoWrapper}
          videoUrl={video}
          onClose={() => {
            setShowVideo(false);
          }}
          fullHeight
        />
      )}
    </div>
  );
};

const MultiInfoBlock = ({ title, infoCards, addon }) => {
  const context = useBimContext();
  const [expandedInfoCard, setExpandedInfoCard] = useState(0);

  const changeExpandedCard = (idx) => {
    setExpandedInfoCard(idx);
  };

  return (
    <div className={`${styles.MultiInfoBlock} ${styles.Block}`}>
      <div className={styles.textContent}>
        <h5 style={{ color: addon.iconColor }}>{context.msg.t(title)}</h5>
      </div>
      <div className={styles.infoContent}>
        <div className={styles.selectInfo}>
          {infoCards.map((addonInfo, idx) => {
            const isExpanded = expandedInfoCard === idx;

            return (
              <InfoContentCard
                key={`${idx}`}
                expanded={isExpanded}
                onClick={() => changeExpandedCard(idx)}
                {...addonInfo}
              />
            );
          })}
        </div>
        <div className={styles.infoMedia}>
          {infoCards.map((addonInfo, idx) => {
            const visible = expandedInfoCard === idx;
            return (
              <img
                key={`${idx}-image`}
                className={`${styles.infoCardImg} ${visible ? styles.visibleInfoCardImg : ''} `}
                src={`${Api.baseUrl()}/resources/images/addons/${addon.id}/${addonInfo.media}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const InfoContentCard = ({ icon, title, description, expanded = false, onClick = _.noop }) => {
  const context = useBimContext();
  return (
    <div className={`${styles.InfoContentCard} ${expanded ? styles.expandedCard : ''}`} onClick={onClick}>
      <div className={styles.infoTitleWrapper}>
        <Icon icon={icon} className={`${styles.contentCardIcon} `} />
        <p className={`${styles.contentCardTitle}`}>{context.msg.t(title)}</p>
      </div>
      <p className={`${styles.contentCardDesc} ${expanded ? styles.descExpanded : ''}`}>{context.msg.t(description)}</p>
    </div>
  );
};

const PlanPriceBlock = ({ title, price, scrollReference, addon, isEnabled, video, videoTitle, requestActivation }) => {
  const context = useBimContext();
  const [showAda, setShowAda] = useState(false);

  const closeAda = () => {
    setShowAda(false);
    window.location.reload();
  };

  const activateAddon = async (addonKey, plan, billingPricingId, agreedPricing) => {
    requestActivation(addon.id, plan, billingPricingId, agreedPricing, () => setShowAda(true));
  };

  return (
    <div className={`${styles.PlanPriceBlock} ${styles.Block}`}>
      <div className={styles.priceHeader} ref={scrollReference}>
        <div className={styles.priceTextContent}>
          <h5 style={{ color: addon.iconColor }}>{context.msg.t(title)}</h5>
        </div>
      </div>
      <div className={styles.plansWrapper}>
        {price.map((price, idx) => {
          const billingPlan = addon.billingAdditional?.activePlan;
          const withOutPlan = !addon.props?.params || !addon.props.params.planKey;
          const currentPlanOrDefault = billingPlan
            ? price.planKey === billingPlan
            : addon.props?.params?.planKey === price.planKey || (withOutPlan && price.value === 800);
          const planSelected = isEnabled && currentPlanOrDefault;
          return (
            <PriceCard
              key={`${idx}-price-card-${price.planKey}`}
              idx={idx}
              selected={planSelected}
              activateAddon={(plan) => {
                const billingPricing = addon.billingAdditional?.pricings.find((p) => p.planKey === price.planKey);
                return activateAddon(addon.id, plan, billingPricing?.id, billingPricing?.pricing);
              }}
              addon={addon}
              isEnabled={isEnabled}
              {...price}
            />
          );
        })}
      </div>
      {showAda && (
        <>
          <BngAddonActivatedHelp
            addonKey={addon.id}
            helpVideoUrl={video}
            videoTitle={videoTitle}
            closeAda={closeAda}
            addonName={context.msg.t(addon.nameKey)}
            knowledgeBaseLink={context.msg.t(addon.linkKey)}
            showHelpVideo={true}
          />
        </>
      )}
    </div>
  );
};

const COLORS = ['#33B577', '#FAA133', '#337DFF', '#ED6962'];

const PriceCard = ({ idx, name, planKey, value, pricing, description, extraInfo, selected = false, activateAddon }) => {
  const context = useBimContext();

  return (
    <div className={styles.PriceCard} style={{ background: COLORS[Math.ceil(idx % 4)] }}>
      <div className={styles.priceName}>
        <span className={styles.priceNameText}>{name}</span>
      </div>
      <div className={styles.priceContent}>
        {description && <div className={styles.planInfo}>{context.msg.t(description)}</div>}
        <div className={styles.priceValueWrapper} style={{ color: COLORS[Math.ceil(idx % 4)] }}>
          <div className={styles.pricePrefix}>R$</div>
          <span className={styles.mainPrice}>{pricing}</span>
          <div className={styles.priceSufix}>00</div>
        </div>
        {extraInfo && <div className={styles.planInfo}>{extraInfo}</div>}

        <div className={`${styles.buttonStyle} ${styles.selectButtonWrapper}`}>
          <Button
            className={`${styles.selectButton} ${selected ? styles.selectedButton : ''}`}
            onClick={async () => {
              if (selected) return;
              await activateAddon({ planKey, value });
            }}
          >
            {context.msg.t(selected ? 'active' : 'select')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const LinkInfoBlock = ({ image, addon, style }) => {
  const context = useBimContext();

  const title = context.msg.t(`addon.${addon.id}.link.title`);
  const desc = context.msg.t(`addon.${addon.id}.link.desc`);
  const link = context.msg.translateIfHasKey(`addon.${addon.id}.link.href`) ?? context.msg.t(addon.linkKey);
  const buttonLbl = context.msg.translateIfHasKey(`addon.${addon.id}.link.buttonLbl`) ?? context.msg.t('learn.how');

  return (
    <div className={`${styles.LinkInfoBlock} ${styles.Block}`} style={style}>
      <img src={`${Api.baseUrl()}/resources/images/addons/${addon.id}/${image}`} className={styles.linkInfoImg} />
      <div className={styles.linkContent}>
        <h5 style={{ color: `${addon.iconColor}` }}>{title}</h5>
        <p dangerouslySetInnerHTML={{ __html: desc }}></p>
        {link && buttonLbl && (
          <a href={link} className={styles.buttonLink} target="_blank">
            <button
              className={`${styles.buttonStyle} ${styles.linkInfoButton}`}
              style={{
                color: `${addon.iconColor}`,
                borderColor: `${addon.iconColor}`,
              }}
            >
              {buttonLbl}
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

const FaqBlock = ({ questions, addon }) => {
  const context = useBimContext();
  return (
    <div className={`${styles.FaqBlock} ${styles.Block}`}>
      <h5 style={{ color: addon.iconColor }}>{context.msg.t('faq')}</h5>
      <div className={styles.questionsWrapper}>
        {questions.map((question, idx) => {
          return (
            <div className={styles.questionCard} key={`${idx}-question`}>
              <p className={styles.questionTitle}>{context.msg.t(question.question)}</p>
              <p
                className={styles.questionAnswer}
                dangerouslySetInnerHTML={{ __html: context.msg.t(question.answer) }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SinglePriceBlock = ({ pricing, addon, requestActivation, isEnabled, scrollReference }) => {
  const context = useBimContext();
  const [showAda, setShowAda] = useState(false);

  const activateAddon = async (addonKey, plan) => {
    if (isEnabled) return;
    const billingPricingId = addon.billingAdditional?.pricings?.[0]?.id;
    requestActivation(addon.id, plan, billingPricingId, pricing, () => setShowAda(true));
  };

  const closeAda = () => {
    setShowAda(false);
    window.location.reload();
  };

  const title = context.msg.t(`addon.${addon.id}.single_price.title`);
  const description = context.msg.t(`addon.${addon.id}.single_price.desc`);
  const pricingSmallPrint = context.msg.translateIfHasKey(`addon.${addon.id}.single_price.smallPrint`);
  const buttonLbl =
    context.msg.translateIfHasKey(`addon.${addon.id}.single_price.buttonLbl`) ?? context.msg.t('activate.addon');
  const video = context.msg.translateIfHasKey(`addon.${addon.id}.single_price.video`);
  const videoTitle = context.msg.translateIfHasKey(`addon.${addon.id}.single_price.videoTitle`);

  return (
    <div className={`${styles.SinglePriceBlock} ${styles.Block}`} ref={scrollReference}>
      <div className={styles.singlePriceContentWrapper}>
        <h5 style={{ color: `${addon.iconColor}` }}>{title}</h5>
        <div className={`${styles.priceValueWrapper}`} style={{ color: addon.iconColor }}>
          <div className={styles.pricePrefix}>R$</div>
          <span className={`${styles.mainPrice}`}>{pricing}</span>
          <div>
            <div className={styles.priceSufix}>00</div>
            <div>{context.msg.t('byMonth')}</div>
          </div>
          <button
            className={`${styles.buttonStyle} ${styles.singlePriceActivate} ${isEnabled ? styles.enabledButton : ''}`}
            style={{ backgroundColor: `${addon.iconColor}` }}
            onClick={activateAddon}
            title={isEnabled ? context.msg.t('addon.request.conflict') : ''}
          >
            {isEnabled ? context.msg.t('enabled') : buttonLbl}
          </button>
        </div>
        {pricingSmallPrint && (
          <span className={styles.priceSmallPrint} dangerouslySetInnerHTML={{ __html: pricingSmallPrint }}></span>
        )}
        <span dangerouslySetInnerHTML={{ __html: description }}></span>
      </div>
      <div className={styles.addonStarsWrapper}>
        <AddonStars fill={addon.iconColor} />
        <Icon icon={addon.iconName} className={styles.pricingIcon} style={{ color: addon.iconColor }} />
      </div>
      {showAda && (
        <>
          <BngAddonActivatedHelp
            addonKey={addon.id}
            helpVideoUrl={video}
            videoTitle={videoTitle}
            closeAda={closeAda}
            addonName={context.msg.t(addon.nameKey)}
            knowledgeBaseLink={context.msg.t(addon.linkKey)}
            showHelpVideo={false}
          />
        </>
      )}
    </div>
  );
};

const LinedMediaBlock = ({ title, description, mediaArray = [], addon }) => {
  const context = useBimContext();

  return (
    <div className={`${styles.Block} ${styles.LinedMediaBlock}`}>
      <h5 style={{ color: addon.iconColor }}>{context.msg.t(title)}</h5>
      <div className={styles.linedMediaWrapper}>
        {mediaArray.map((media, idx) => {
          return (
            <div className={styles.linedMediaContent} key={idx}>
              <span className={styles.linedMediaTitle}>{context.msg.t(media.title)}</span>
              <img
                src={`${Api.baseUrl()}/resources/images/addons/${addon.id}/${media.url}`}
                className={styles.linedMediaImg}
              />
            </div>
          );
        })}
      </div>
      <p dangerouslySetInnerHTML={{ __html: context.msg.t(description) }}></p>
    </div>
  );
};

const BLOCK_TYPES = {
  HEADER: HeaderBlock,
  MULTI_INFO: MultiInfoBlock,
  PLAN_PRICE: PlanPriceBlock,
  LINK_INFO: LinkInfoBlock,
  FAQ: FaqBlock,
  SINGLE_PRICE: SinglePriceBlock,
  LINED_MEDIA: LinedMediaBlock,
};

const AddonInfoPage = ({ addonKey = null }) => {
  const dispatch = useReduxDispatch();
  const context = useBimContext();

  const [loading, setLoading] = useState(true);
  const [addon, setAddon] = useState({});
  const [isEnabled, setIsEnabled] = useState(false);

  const $scrollRef = useRef();

  const findAddonInfo = async (currentAddonKey) => {
    try {
      setLoading(true);
      const fetchedAddons = await Api.Account.findAccountAddons(context.accountId);
      const addonEnabled = context.addons.includes(currentAddonKey);

      const fetchedAddon = fetchedAddons.allAddons.find((addon) => addon.id === currentAddonKey);
      setAddon(fetchedAddon);
      setIsEnabled(addonEnabled);
    } catch (e) {
      console.error('Error on findAddonInfo()', e);
      UiMsg.ajaxError(context.msg.t('addon.fetch.error'), e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentAddonKey = addonKey || new URLSearchParams(window.location.search).get('addon');
    if (!AddonPageContent[currentAddonKey]) {
      window.location.replace(`${Api.baseUrl()}/pages/addon/addons-page.iface?breadcrumb=true`);
      return;
    }
    findAddonInfo(currentAddonKey);
  }, []);

  const requestActivation = async (addonKey, plan, billingPricingId, agreedPricing, showAdaFunc) => {
    setLoading(true);

    try {
      if (isAdmin) {
        dispatch(
          window.Actions.MODALS.open(BngPasswordConfirmation, {
            onConfirm: async () => {
              await Api.Account.changeAddonRequestStatus(addonKey, context.project.id, true, plan, {
                ...addon.billingAdditional,
                billingPricingId,
                agreedPricing,
              });
              UiMsg.ok(context.msg.t(isEnabled ? 'addon.plan.changed.successfully' : 'addon.enabled.successfully'));
              if (!isEnabled) {
                showAdaFunc();
              } else {
                window.location.reload();
              }
            },
          })
        );
      } else {
        await Api.Account.requestAddon(addonKey, context.project.id, {
          ...addon.billingAdditional,
          billingPricingId,
          agreedPricing,
        });
        UiMsg.ok(context.msg.t('addon.request.success.not.master'));
      }
    } catch (e) {
      console.error('Error on requestActivation()', { addonKey, plan }, e);
      UiMsg.error(context.msg.t('addon.request.error'));
    } finally {
      setLoading(false);
    }
  };

  const isAdmin = context.permissions.isAdmin();
  const currentAddon = AddonPageContent[addon.id] ?? {};

  return (
    <UiBlocker
      block={loading}
      className={`AddonInfoPage ${styles.AddonInfoBlocker} ${styles.AddonInfoBlockWrapper} ${addon.id}`}
    >
      {Object.keys(currentAddon).map((block, idx) => {
        const BlockType = BLOCK_TYPES[block];
        const { className = '', ...props } = currentAddon[block].props ?? {};
        return (
          <BlockType
            key={`${idx}-${block}`}
            className={`BLOCK_${block} ${className}`}
            loading={loading}
            setLoading={setLoading}
            isAdmin={isAdmin}
            scrollReference={$scrollRef}
            addon={addon}
            isEnabled={isEnabled}
            requestActivation={requestActivation}
            {...props}
          />
        );
      })}
      <div className={styles.addonPageFooter}>
        <img src={`${Api.baseUrl()}/resources/images/logo.png`} alt="BIM Logo" />
      </div>
    </UiBlocker>
  );
};

export default AddonInfoPage;
