import React, { useEffect, useMemo, useState } from 'react';
import SelectObjectDialogContainer from 'components/ui/common/SelectObjectDialogContainer';

function BypassComponent({ className = '', children }) {
  return <div className={className}>{children}</div>;
}

export default function MonitorValueSourceObjectTree() {
  const $val = useMemo(() => {
    return document.querySelector('.JsfValueProviderPathValue');
  }, []);

  const [selectedItem, setSelectedItem] = useState($val.value ?? '');

  useEffect(() => {
    $val.value = selectedItem;
  }, [$val, selectedItem]);

  return (
    <SelectObjectDialogContainer
      className={'MonitorValueSourceObjectTree TransparentBg'}
      dialogComponent={BypassComponent}
      onChangeListener={({ path: selectedPaths } = { path: [] }) => {
        setSelectedItem(selectedPaths[0] ?? '');
      }}
      object={selectedItem}
      fileExtension="kpi"
    />
  );
}
