import Api from "components/Api";
import React from "react";
import {connect} from "react-redux";
import ContextEnhancer from "components/ContextEnhancer";
import Dialog from "components/ui/Dialog";
import Button from "components/ui/Button";
import {MODALS} from "components/ui/redux/Actions";
import UiMsg from "components/ui/UiMsg";
import ObjectRightMenu from "components/ui/right-menu/ObjectRightMenu";


export const FixedMembersConfirmDialog = ContextEnhancer(({
                                                              context = {},
                                                              mdxHTML = '',
                                                              closeModal = _.noop,
                                                              handleConfirmSave = _.noop,
                                                          }) => {
    const confirmAndClose = () => {
        handleConfirmSave();
        closeModal();
    }

    return (
        <Dialog onClose={closeModal}
                title={context.msg.t('attention')}>
            <div className="row-fluid">
                <div className="span12">
                    <p className="dialog-text">
                        {context.msg.t('fixed.members.alert.message')}
                    </p>
                </div>
            </div>
            <div className="row-fluid">
                <div className="span12">
                    <fieldset>
                        <legend>
                            {context.msg.t('occurrences.found')}
                        </legend>
                    </fieldset>
                    <pre className="fixed-members-mdx" dangerouslySetInnerHTML={{__html: mdxHTML}}/>
                </div>
            </div>
            <div className="row-fluid">
                <div className="span12 text-right btn-fix">
                    <Button onClick={closeModal} className={`bng-button cancel`}>
                        {context.msg.t('cancel')}
                    </Button>
                    {' '}
                    <Button className={`bng-button save`} type="button" onClick={confirmAndClose}
                            style={{width: 'auto'}}>
                        {context.msg.t('save.anyway')}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
})

const AnalysisRightMenu = ({dispatch, ...props}) => {

    const toggleAnalystMenu = (clearAccordion = _.noop) => {
        clearAccordion();
        Api.executeExp(`#{analysisBean.toggleAnalystMenu()}`);
    };

    const closeAnalystMenu = () => {
        Api.executeExp(`#{analysisBean.setCloseAnalystMenu(true)}`);
    }

    const saveAnalysis = async ({submitForm}) => {
        await Api.executeExp(`#{analysisBean.confirmedSaveAnalysis()}`);
        await submitForm();
    }

    const handleSave = async ({submitForm = _.noop}) => {
        try {
            const data = await Api.Analysis.hasFixedMembers();
            if (data.containFixedMembers) {
                dispatch(MODALS.open(FixedMembersConfirmDialog, {
                    mdxHTML: data.mdx,
                    handleConfirmSave: () => saveAnalysis({submitForm}),
                }))
            } else {
                await saveAnalysis({submitForm});
            }
        } catch (e) {
            console.error(e);
            UiMsg.error('Error', e);
        }
    };

    const remove = async () => {
        await Api.executeExp(`#{analysisBean.removeCurrentAnalysis()}`);
    };

    return (
        <ObjectRightMenu {...props}
                         toggleAnalystMenu={toggleAnalystMenu}
                         closeAnalystMenu={closeAnalystMenu}
                         save={handleSave}
                         saveAs={Api.Analysis.saveAs}
                         remove={remove}/>
    )
}

export default ContextEnhancer(connect()(AnalysisRightMenu));