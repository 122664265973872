import './ContainerCreator.css';

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import Snackbar, { SnackbarVariant } from 'components/ui/common/Snackbar';
import ContextEnhancer from 'components/ContextEnhancer';
import Icon from 'components/ui/common/Icon';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { transformLayoutToPersist } from 'components/ui/dashboard/components/common';

const rectangleIntersects = function (r1, r2) {
  return !(r2.x >= r1.x + r1.w || r2.x + r2.w <= r1.x || r2.y >= r1.y + r1.h || r2.y + r2.h <= r1.y);
};

export function processContainerValuesToPersist(values) {
  return {
    ...values,
    items: transformLayoutToPersist(values.items),
  };
}

export const ContainerCreator = ContextEnhancer(
  class ContainerCreatorInner extends React.PureComponent {
    static propTypes = {
      className: PropTypes.string,
      item: PropTypes.object,
      onClose: PropTypes.func,
      onSave: PropTypes.func,
      layout: PropTypes.array,
    };

    static defaultProps = {
      className: '',
      item: null,
      onClose: _.noop,
      onSave: _.noop,
      layout: {},
    };

    state = {
      items: [],
      intersectItems: [],
      loading: true,
    };

    componentDidMount() {
      if (this.props.item) {
        const items = (this.props.item?.additionalProps?.items ?? []).map((layout) => layout.i);
        this.setState({
          items,
          intersectItems: this.findIntersections(items),
        });
      }

      useDashboardPageCtx.setState(() => ({
        dashGridCustomSelect: {
          onSelect: this.selectionHandler,
          itemClass: this.getItemClass,
        },
      }));

      this.setState({ loading: false });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (_.isEqual(prevProps.layout, this.props.layout) && _.isEqual(prevState.items, this.state.items)) {
        return;
      }
      // Update layout for selected items
      const intersectItems = this.findIntersections(this.state.items);
      if (_.isEqual(prevState.intersectItems, intersectItems)) {
        return;
      }

      this.setState({ intersectItems });
    }

    componentWillUnmount() {
      useDashboardPageCtx.setState(() => ({
        dashGridCustomSelect: null,
      }));
    }

    destroyOnClickOut = (event) => {
      this.restoreAndClose();
    };

    getItemClass = ({ i }) => {
      if (this.state.items.includes(i)) {
        return 'selected-by-container select-item-for-container';
      }
      if (this.state.intersectItems.includes(i)) {
        return 'selected-by-container conflict-item-for-container';
      }
      return '';
    };

    isContainer(props) {
      return props[0]?.data?.viewType === 'container';
    }

    selectionHandler = async (selectedItemProps) => {
      selectedItemProps = (Array.isArray(selectedItemProps) ? selectedItemProps : [selectedItemProps]).filter(
        (i) => !i.isContainer
      );
      if (selectedItemProps.length === 0) {
        return;
      }

      const items = new Set(this.state.items);
      selectedItemProps.forEach((p) => {
        const id = p?.layout?.i;
        if (items.has(id)) {
          items.delete(id);
        } else {
          items.add(id);
        }
      });

      const intersectItems = this.findIntersections([...items]);

      return new Promise((res) => {
        this.setState(
          {
            items: [...items],
            intersectItems,
          },
          () => {
            useDashboardPageCtx.setState(() => ({
              selectedItems: [...items],
            }));
            res();
          }
        );
      });
    };

    close = () => {
      this.props.onClose();
    };

    restoreAndClose = async () => {
      if (this.props.item) {
        const changes = useDashboardPageCtx.getState().changes.slice();
        useDashboardPageCtx.setState({
          changes: changes.filter((c) => !(c.type === 'REMOVE_ITEM' && c.data.id === this.props.item.id)),
        });
      }
      this.close();
    };

    findIntersections = (items = this.state.items) => {
      let intersects = [];
      if (items.length > 1) {
        const area = { x: Number.MAX_VALUE, y: Number.MAX_VALUE, w: 0, h: 0 };
        items
          .map((id) => this.props.layout.find((l) => l.i === id))
          .filter((layout) => !!layout)
          .forEach((l) => {
            area.x = Math.min(area.x, l.x);
            area.y = Math.min(area.y, l.y);
            area.w = Math.max(area.w, l.x + l.w);
            area.h = Math.max(area.h, l.y + l.h);
          });
        area.w = area.w - area.x;
        area.h = area.h - area.y;

        for (const l of this.props.layout) {
          if (items.includes(l.i)) {
            continue;
          }
          if (rectangleIntersects(area, l)) {
            intersects.push(l.i);
          }
        }
      }
      return intersects;
    };

    haveSelectItems = () => {
      return this.state.items.length > 0;
    };

    save = async () => {
      this.setState({ loading: true });

      await this.saveContainer(this.state.items);

      this.setState({ loading: false });
      this.close();
    };

    saveContainer = async (items) => {
      const values = processContainerValuesToPersist({
        ...(this.props.item?.additionalProps ?? {}),
        items: items.map((i) => this.props.layout.find((l) => l.i === i)),
        highlight: this.props.item?.highlight,
      });
      await this.props.onSave(values);
    };

    render() {
      const haveSelectItems = this.haveSelectItems();
      const conflicts = this.state.intersectItems.length;

      return (
        <React.Fragment>
          {ReactDOM.createPortal(
            <div className="ContainerCreatorOverlay" onClick={this.destroyOnClickOut} />,
            document.body
          )}

          <div className="ContainerCreatorParent">
            <Snackbar
              key={`${haveSelectItems}-${conflicts}`}
              icon={haveSelectItems && conflicts === 0 ? 'check' : 'touch_app'}
              onClick={conflicts === 0 && haveSelectItems && !this.state.loading ? this.save : null}
              message={this.props.context.msg.t(
                conflicts === 0
                  ? haveSelectItems
                    ? 'click.to.create.container'
                    : 'select.items.to.add.to.container'
                  : 'object.in.conflict.with.container',
                conflicts
              )}
              variant={conflicts === 0 ? SnackbarVariant.Save : SnackbarVariant.Error}
            >
              <div className={'containerCreatorSnackbarClose'}>
                <Icon
                  icon={'close'}
                  className={`cancel-container-creation`}
                  onClick={this.restoreAndClose}
                  title={this.props.context.msg.t('cancel')}
                />
                {conflicts > 0 && (
                  <a
                    href={this.props.context.msg.t('container.conflict.support.link')}
                    target="_blank"
                    className="material-icons ConflictHelp notranslate"
                    title={this.props.context.msg.t('container.conflict.hint')}
                  >
                    help_outline
                  </a>
                )}
              </div>
            </Snackbar>
          </div>
        </React.Fragment>
      );
    }
  }
);
