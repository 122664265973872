import styles from './BngImageButton.module.css';

import React, { forwardRef } from 'react';

function BngImageButton({ type = 'button', image = '', imageAlt = '', className = '', text = '', ...props }, ref) {
  return (
    <button ref={ref} type={type} className={`BngImageButton ${styles.imageButton} ${className}`} {...props}>
      <img src={image} alt={imageAlt} />
      {text && <span>{text}</span>}
    </button>
  );
}

export default forwardRef(BngImageButton);
