import "./CockpitPanelItems.css";

import React, {Component, useEffect} from "react";
import PropTypes from "prop-types";

import ContextEnhancer from "components/ContextEnhancer";
import Icon from "components/ui/common/Icon";


const CockpitPanelTab = ({currentPanel, tab, items}) => {
    const activeTab = tab === 0 ? 'active' : '';

    const changePanel = (event, item) => {
        event.preventDefault();
        application.Api.executeExp(`#{cockpitViewMB.renderPanelId(${item.id})}`);
    };

    return (
        <div className={`cockpit-panel-nav-tab cockpit-panel-nav-tab-${tab} ${activeTab}`}>
            {items.map((item, idx) => {
                return (
                    <div key={idx} className={`cockpit-panel-list-item ${item.id === currentPanel ? 'current-panel' : ''}`}>
                        <a href="#" title={item.name} onClick={(e) => changePanel(e, item)}>
                            <Icon icon={item.icon}/>
                            <span className="text-overflow-ellipsis">{item.name}</span>
                        </a>
                    </div>
                )
            })}
        </div>
    )
};

class CockpitPanelItems extends Component {

    static propTypes = {
        items: PropTypes.array,
        currentPanel: PropTypes.any,
    };

    static defaultProps = {
        items: [],
        currentPanel: 0,
    };

    state = {
        filteredItems: null,
    };

    constructor(props) {
        super(props);
        this.state = {filteredItems: this.props.items};
    }

    onSearchChange(search) {
        const filteredItems = this.props.items.filter(value => value.name.toLowerCase().includes(search.toLowerCase()));
        this.setState({
            filteredItems
        });
    }

    changeActiveTab(event, tab) {
        event.preventDefault();
        j('.cockpit-panel-nav-tab').removeClass('active');
        j('.cockpit-panel-nav-tab-' + tab + '').addClass('active');
        j('.widget-footer-pg-button-cockpit-panel').removeClass('active');
        j('.widget-footer-pg-button-cockpit-panel-tab-' + tab + '').addClass('active');
    }

    render() {
        const perTab = 20;
        const items = this.state.filteredItems;
        let itemsPerTab = [];

        for (let i = 0; i < items.length; i+=perTab) {
            let panelsToRender = [];
            for (let j = i; j < i + perTab && j < items.length; j++) {
                panelsToRender.push(items[j]);
            }
            itemsPerTab.push(panelsToRender);
        }

        return (
            <div className="cockpit-panel-itens-popper cockpit-dropdown-popper">
                <div className="cockpit-panel-itens-search">
                    <div className="cockpit-panel-itens-search-container">
                        <div className="cockpit-panel-itens-search-container-input">
                            <input className="cockpit-panel-item-search-input"
                                   type="text"
                                   placeholder={this.props.context.msg.t('search.in.panels')}
                                   onChange={e => this.onSearchChange(e.target.value)}/>
                        </div>
                        <div className="cockpit-panel-itens-search-container-icon">
                            <Icon icon="search"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="cockpit-panel-itens-list">
                            <div>
                                {itemsPerTab.map((tabs, idx) => {
                                    return (
                                        <CockpitPanelTab key={idx}
                                                         items={tabs}
                                                         currentPanel={this.props.currentPanel}
                                                         tab={idx} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="widget-footer-pg-buttons">
                        {itemsPerTab.map((tabs, idx) => {
                            const activeTab = idx === 0 ? 'active' : '';
                            return (
                                <a key={idx} className="cockpit-panel-item-nav-button" href="#" onClick={(e) => this.changeActiveTab(e, idx)}>
                                    <div className={`widget-footer-pg-button widget-footer-pg-button-cockpit-panel widget-footer-pg-button-cockpit-panel-tab-${idx} ${activeTab}`}/>
                                </a>
                            )
                        })}

                    </div>
                </div>
            </div>
        );
    }


}

export default ContextEnhancer(CockpitPanelItems);