import React, { useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import Api from 'components/Api';
import BngSwitch from 'components/bng/form/BngSwitch';
import Icon from 'components/ui/common/Icon';
import AvatarCarousel from 'components/ui/profile/AvatarCarousel';
import { isCurrentUser } from 'components/ui/profile/ProfileDialog';
import ChangePasswordDialog from 'components/ui/profile/ChangePasswordDialog';
import { MODALS } from 'components/ui/redux/Actions';
import Utils from 'components/Utils';
import HelpIcon from 'components/ui/common/HelpIcon';
import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import BngDropdown from 'components/bng/ui/BngDropdown';
import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';
import useBimContext from 'components/hooks/useBimContext';

function ProfileCard({
  user = {},
  newUser = false,
  avatars = [],
  mobile = true,
  canAlterProfileAndMobile = true,
  dispatch = _.noop,
}) {
  const context = useBimContext();
  const { values, setFieldValue } = useFormikContext();
  const currentUser = isCurrentUser(user.id, context);
  const textOnline = user.online ? 'online' : 'offline';

  const logout = () => {
    Api.User.logout();
  };

  const openChangePasswordDialog = () => {
    dispatch(MODALS.open(ChangePasswordDialog, { userId: user.id }));
  };

  const openMessagingStatusDialog = () => {
    if (!canAlterProfileAndMobile) {
      return;
    }

    dispatch(
      MODALS.open(MessagingStatusDialog, {
        user,
        onChange: (val) => {
          setFieldValue('allowMobileMessaging', val);
        },
      })
    );
  };

  return (
    <div className={`profile-card ${currentUser ? 'user' : ''}`}>
      {!newUser && <div className={`profile-user-status ${textOnline}`}>{context.msg.t(`user.is.${textOnline}`)}</div>}

      <Field
        name="avatarLink"
        component={AvatarCarousel}
        newUser={newUser}
        avatars={avatars}
        defaultImage={user.avatarLink}
        disabled={!newUser && !currentUser}
      />

      <div className="profile-user-description">
        <span className="profile-user-name">
          {values.displayName}
          <BimachineBadge email={user.email} />
        </span>
        {!newUser && (
          <span className="profile-user-last-access">
            {context.msg.t('last_access')}:{' '}
            {user.lastAccess ? moment(user.lastAccess).fromNow() : context.msg.t('no.activity.register')}
          </span>
        )}
      </div>

      <div className="profile-user-configuration">
        <div className="profile-user-mobile">
          <Icon icon="phone_android" />
          {canAlterProfileAndMobile ? (
            <Field
              label={context.msg.t('user.dialog.mobile')}
              name="mobile"
              id="profile-switch-mobile"
              component={BngSwitch}
            />
          ) : (
            <React.Fragment>
              <span>{context.msg.t('user.dialog.mobile')}</span>
              <div
                style={{ marginTop: 1, padding: '0 6px' }}
                className={`profile-user-status ${mobile ? 'online' : 'offline'}`}
              >
                {mobile ? context.msg.t('enabled.label') : context.msg.t('disabled.label')}
              </div>
            </React.Fragment>
          )}
        </div>

        <div
          className={`profile-user-messaging ${canAlterProfileAndMobile ? '' : 'not-allowed'}`}
          onClick={openMessagingStatusDialog}
        >
          <div className="messaging-icons">
            <Icon className="icon-whatsapp" icon="fa fa-whatsapp" />
          </div>
          <span>{context.msg.t('enable.messaging')}</span>
          <HelpIcon className="help-icon-whatsapp" title={context.msg.t('enable.whatsapp')} />
        </div>

        {!newUser && canAlterProfileAndMobile && (
          <div className="profile-user-password" onClick={() => openChangePasswordDialog()}>
            <Icon icon="lock" />
            <span>{context.msg.t('user.dialog.password')}</span>
          </div>
        )}

        {currentUser && (
          <div className="profile-user-logout" onClick={logout}>
            <Icon icon="exit_to_app" />
            <span>{context.msg.t('logout')}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export function BimachineBadge({ email }) {
  const { t } = useTranslation();
  const isBimachine = Utils.Project.isBimachineEmail(email);
  if (!isBimachine) {
    return null;
  }

  return (
    <div className="BimachineBadge" title={t('verified.bim.user')}>
      <Icon icon="icon-bim-logo" />
    </div>
  );
}

function MessagingStatusDialog({ user = { allowMobileMessaging: null }, closeModal, onChange = _.noop }) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(user.allowMobileMessaging);

  const options = useMemo(
    () => [
      {
        label: t('user.messaging.dialog.allowed'),
        icon: 'check_circle_outline',
        className: 'allowed',
      },
      {
        label: t('user.messaging.dialog.not.allowed'),
        icon: 'highlight_off',
        className: 'not-allowed',
      },
    ],
    []
  );

  const handleSubmit = async () => {
    try {
      await Api.User.updateUserMessaging({
        changePhoneRequestKey: '',
        userId: user.id,
        phone: user.phone,
        allowMobileMessaging: selected,
      });
      await onChange(selected);
      closeModal();
      UiMsg.ok(t('user.messaging.dialog.success'));
    } catch (e) {
      console.error('Error on handleSubmit()', e);
      UiMsg.ajaxError(t('user.messaging.dialog.error'), e);
    }
  };

  const selectedIsNull = _.isNil(selected);
  return (
    <Dialog className="MessagingStatusDialog" title={t('enable.messaging')} onClose={closeModal}>
      <Dialog.Body>
        <div className="MessagingDialogBodyWrapper">
          <BngDropdown
            customButton={({ openDropdown }) => {
              return (
                <div className="messaging-permission-selector-dropdown" onClick={openDropdown}>
                  {t('user.messaging.dialog.dropdown.label')}
                  <Icon icon="arrow_drop_down" />
                </div>
              );
            }}
            customOptions={({ closeDropdown }) => {
              return (
                <div className="messaging-permission-options">
                  {options.map((o, idx) => (
                    <div
                      key={idx}
                      className={o.className}
                      onClick={() => {
                        setSelected(idx === 0);
                        closeDropdown();
                      }}
                    >
                      <div>{o.label}</div>
                      <Icon icon={o.icon} />
                    </div>
                  ))}
                </div>
              );
            }}
            popperClassName="messaging-permission-options-popper"
          />
          <div className={`selected-messaging-permission ${selectedIsNull ? '' : options[selected ? 0 : 1].className}`}>
            <Icon icon={selectedIsNull ? 'help_outline' : options[selected ? 0 : 1].icon} />{' '}
            <div>{t(selectedIsNull ? 'user.messaging.dialog.no.answer' : options[selected ? 0 : 1].label)}</div>
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <DefaultDialogActions closeModal={closeModal} onClickSaveButton={handleSubmit} />
      </Dialog.Footer>
    </Dialog>
  );
}

export default ProfileCard;
