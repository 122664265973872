import './BngAda.css';
import AdaOpenHandImg from 'components/bng/pages/newAnalysis/assets/ada_open_hand.svg';
import AdaClosedHandImg from 'components/bng/pages/newAnalysis/assets/ada_closed_hand.svg';

import React, { useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';

import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import BngTag from 'components/bng/ui/BngTag';
import useTranslation from 'components/hooks/useTranslation';
import BngIconButton from 'components/bng/ui/BngIconButton';

const INSTANCE_REGISTRY = [];

function instanceRegistryHook() {
  const instanceId = useMemo(() => Utils.randomId(), []);
  const [updatedAt, setUpdatedAt] = useState(Date.now());

  useEffect(() => {
    INSTANCE_REGISTRY.push({
      instanceId,
      update: () => setUpdatedAt(Date.now()),
    });
    INSTANCE_REGISTRY.forEach((el) => el.update());

    return () => {
      _.remove(INSTANCE_REGISTRY, (el) => el.instanceId === instanceId);
      INSTANCE_REGISTRY.forEach((el) => el.update());
    };
  }, [setUpdatedAt]);

  return {
    instanceId,
    updatedAt,
    isVisible() {
      return instanceId === INSTANCE_REGISTRY[0]?.instanceId;
    },
  };
}

export const ADA_TAGS = {
  BIM_LABS: {
    label: 'bimachine_labs',
    icon: 'science',
  },
  BETA: {
    label: 'is.beta',
    icon: 'bug_report',
  },
  VOTING: {
    label: 'voting.tag',
    icon: 'thumb_up_alt',
  },
  NPS: {
    label: 'nps.tag',
    icon: 'sentiment_very_satisfied',
  },
};

const AdaContent = ({
  title,
  description,
  buttonLabel,
  customOptionsComponent,
  leftButtonLabel,
  onClickLeftButton,
  buttonDisabled,
  children,
  options,
  onClick,
  tags = [],
  step = null,
  toNextStep = null,
  greyBackground = false,
  className = '',
  isFixedSize = false,
  currentStep,
  stepIdx = -1,
  toPreviousStep = null,
  link = null,
  renderButtons = true,
}) => {
  const { t } = useTranslation();

  const stepHidden = currentStep > stepIdx;
  const stepDiff = stepIdx - currentStep;

  const springStyles = useSpring({
    from: {
      top: `-${13 * stepDiff}px`,
      transform: `translateX(0px) scale(${1 - 0.05 * stepDiff})`,
      filter: `brightness(${1 - 0.1 * stepDiff})`,
      opacity: 1,
      zIndex: 50 - stepIdx,
    },
    to: {
      top: stepHidden ? '0px' : `-${13 * stepDiff}px`,
      transform: stepHidden ? 'translateX(1000px) scale(1)' : `translateX(0px) scale(${1 - 0.05 * stepDiff})`,
      filter: stepHidden ? 'brightness(1)' : `brightness(${1 - 0.1 * stepDiff})`,
      opacity: stepHidden ? 0 : 1,
      zIndex: 50 - stepIdx,
    },
  });

  const redirectToLink = () => {
    window.location.replace(link);
  };

  const shouldRenderButtons = useMemo(() => renderButtons || currentStep === -1, [currentStep]);
  return (
    <animated.div
      className={`AdaContent contentWrapper  ${greyBackground ? 'BngAdaGreyBackground' : ''} ${
        isFixedSize ? 'adaContentFixedSize' : ''
      } ${className}`}
      style={springStyles}
    >
      <div>
        {tags.length > 0 && (
          <div className={'bngAdaTagsWrapper'}>
            {tags.map((tag, idx) => {
              return (
                <BngTag
                  key={`Tag-${idx}`}
                  icon={tag.icon}
                  description={t(tag.label)}
                  className={`bngAdaTag ${greyBackground ? 'bngAdaGreyTag' : ''}`}
                />
              );
            })}
            {link && <BngIconButton className={'bngAdaLinkButton'} icon={'open_in_new'} onClick={redirectToLink} />}
          </div>
        )}
        {title && <h1>{title}</h1>}
        {description && <span>{description}</span>}
        {customOptionsComponent?.(step, toNextStep, toPreviousStep)}
        {options && !customOptionsComponent && (
          <div className="optionsContainer">
            {options.map((o, idx) => (
              <div key={o.key ? o.key : idx}>
                <a onClick={o.onClick} target="_blank">
                  <Icon icon={o.icon ? o.icon : 'arrow_right_alt'} />
                  <span>{o.label}</span>
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
      {shouldRenderButtons && (
        <div className={`footerWrapper`}>
          {leftButtonLabel && (
            <button className="leftButton" onClick={() => onClickLeftButton({ toPreviousStep })}>
              {leftButtonLabel}
            </button>
          )}
          {buttonLabel && (
            <button
              className={`mainButton ${leftButtonLabel ? 'righButton' : ''}`}
              onClick={() => onClick({ toNextStep, step })}
              disabled={buttonDisabled}
            >
              {buttonLabel}
            </button>
          )}
          {children}
        </div>
      )}
    </animated.div>
  );
};

export default function BngAda({
  className = '',
  title = '',
  description = '',
  options = [],
  buttonLabel = '',
  onClick = _.noop,
  buttonPressed = true,
  showInfo = true,
  disableBackground = false,
  customOptionsComponent = null,
  leftButtonLabel = null,
  onClickLeftButton = _.noop,
  buttonDisabled = false,
  children,
  clickOutsiteToConfirm = true,
  tags = [],
  greyBackground = false,
  steps,
  fixedSize = false,
  link = null,
  renderButtons = true,
}) {
  const $instance = instanceRegistryHook();
  const [currentStep, setCurrentStep] = useState(-1);

  const isVisible = $instance.isVisible();
  if (!isVisible) {
    return null;
  }

  const toNextStep = () => setCurrentStep(currentStep + 1);
  const toPreviousStep = () => setCurrentStep(currentStep - 1);
  const isFixedSize = fixedSize || steps;

  return (
    <div className={`BngAda ${className}`}>
      {buttonPressed ? (
        <>
          {disableBackground && !showInfo && <div className="disableBackground" />}
          <div className="wrapperAdaOpen">
            <img alt="AdaOpen" src={AdaOpenHandImg} className={'AdaOpenHandImg'} />
          </div>
        </>
      ) : (
        <a className="enableInfo" onClick={clickOutsiteToConfirm ? onClick : _.noop}>
          <div className="wrapperAdaClosed BngAdaBackground">
            <img alt="AdaClosed" src={AdaClosedHandImg} className={'AdaClosedHandImg'} />
          </div>
        </a>
      )}
      {showInfo && buttonPressed && (
        <>
          <div className="disableInfo" onClick={clickOutsiteToConfirm ? onClick : _.noop} />
          <div className={`contentContainer BngAdaBackground ${isFixedSize ? 'adaContentFixedSize' : ''}`}>
            <AdaContent
              title={title}
              description={description}
              buttonLabel={buttonLabel}
              customOptionsComponent={customOptionsComponent}
              leftButtonLabel={leftButtonLabel}
              onClickLeftButton={onClickLeftButton}
              buttonDisabled={buttonDisabled}
              children={children}
              options={options}
              onClick={steps ? toNextStep : onClick}
              toNextStep={toNextStep}
              toPreviousStep={toPreviousStep}
              tags={tags}
              greyBackground={greyBackground}
              isFixedSize={isFixedSize}
              currentStep={currentStep}
              stepIdx={-1}
              link={link}
              renderButtons={renderButtons}
            />
            {steps &&
              steps.map((step, idx) => {
                return (
                  <AdaContent
                    key={`step-${idx}`}
                    title={step.title}
                    description={step.description}
                    buttonLabel={buttonLabel}
                    customOptionsComponent={customOptionsComponent}
                    leftButtonLabel={step.leftButtonLabel}
                    onClickLeftButton={step.leftButtonClick}
                    buttonDisabled={step.buttonDisabled}
                    children={children}
                    options={options}
                    onClick={step.onClick ?? toNextStep}
                    step={step}
                    toNextStep={toNextStep}
                    toPreviousStep={toPreviousStep}
                    greyBackground={greyBackground}
                    className={'BngAdaBackgroundSteps'}
                    isFixedSize={isFixedSize}
                    currentStep={currentStep}
                    stepIdx={idx}
                    link={link}
                    renderButtons={renderButtons}
                  />
                );
              })}
            {steps && (
              <div className={'AdaStepIndicator'}>
                {steps.map((step, idx) => {
                  return (
                    <div
                      key={`Step-${idx}`}
                      className={`AdaStepIndicatorBall ${idx <= currentStep ? 'AdaStepActive' : ''}`}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
