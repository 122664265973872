import React, {Component} from 'react';
import PropTypes from "prop-types";
import TreeComponent from "./TreeComponent";

class SelectObjectRadio extends Component {

    static propTypes = {
        href: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.string,
        objectSelect: PropTypes.array,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        href: '',
        text: '',
        icon: '',
        objectSelect: [],
    };

    constructor(props){
        super(props);
    }

    handleChange = (e) => {
        this.props.onChange(e.currentTarget.value);
    };



    render(){
        const {href, objectSelect} = this.props;
        return (
            <TreeComponent {...this.props}>
                <input id={href} key={href} onChange={this.handleChange} type="radio" defaultChecked={objectSelect.indexOf('load.iface?content=' + href) >= 0 || objectSelect.indexOf(href) >= 0} name="radio-object" value={href} ref="rightPanelRadio"/>
            </TreeComponent>
        )
    }
}

export default SelectObjectRadio;