import './AddItemToDashboardMenuItem.css';

import React, { useState } from 'react';

import UiMsg from 'components/ui/UiMsg';
import Accordion from 'components/ui/Accordion';
import AccordionList from 'components/ui/AccordionList';
import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import DashInputImage from 'components/ui/dashboard/components/DashInputImage';
import SelectObjectDialogContainer from 'components/ui/common/SelectObjectDialogContainer';
import DashboardIconForm from 'components/ui/dashboard/components/DashboardIconForm';
import DashboardTextForm from 'components/ui/dashboard/components/DashboardTextForm';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import DashAddObjectMenu from 'components/ui/right-menu/items/kpi/DashAddObjectMenu';
import BngNewKpiPage from 'components/bng/pages/kpi/BngNewKpiPage';
import { KPI_EDIT_MENU_EVENT } from 'components/bng/pages/kpi/KpiEditMenu';
import BngNewAnalysis from 'components/bng/pages/newAnalysis/BngNewAnalysis';
import UUID from 'bng/utils/UUID';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { useEditTextItem } from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuEditText';
import Api from 'components/Api';

function getDashboardObjectPaths(dash) {
  return Object.values(dash?.gridData?.itemsData ?? {})
    .filter((itemData) => itemData.originalPath)
    .map((itemData) => itemData.originalPath);
}

const addObjectToDash = async (addChange, paths = [], viewType) => {
  await addChange({
    type: 'OBJECT',
    data: {
      paths: paths.map((path) => ({ path, id: UUID.generate() })),
      viewType,
    },
  });

  bimEventBus.emit(Api.Event.PROCESS_EVENTS);
};

function AddItemToDashboard({ toggleAccordion }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const { addChange, editContainer, dash } = useDashboardPageCtx.cached((state) =>
    _.pick(state, ['addChange', 'editContainer', 'dash'])
  );
  const editTextItem = useEditTextItem();

  const [breadcrumb, setBreadcrumb] = useState([]);
  const [loading, setLoading] = useState(false);

  const openSelectObjectDialog = (props = {}) => {
    return dispatch(
      MODALS.open(SelectObjectDialogContainer, {
        onSelectPath: async (path, viewType) => {
          try {
            if (_.isEmpty(path?.itemPath)) {
              UiMsg.warn(context.msg.t('none.objects.added'));
              return false;
            } else {
              await addObjectToDash(addChange, path.itemPath, viewType);
              UiMsg.ok(context.msg.t('successfully.added.object'));
            }
          } catch (e) {
            console.error(e);
            UiMsg.ajaxError(context.msg.t('error'), e);
          }
        },
        typeField: 'checkbox',
        byProject: false,
        blockedPaths: getDashboardObjectPaths(dash),
        ...props,
      })
    );
  };

  const analyticalItems = [
    {
      icon: Utils.Object.getObjectIcon('kpi'),
      label: 'KpiMonitor',
      onClick: () => {
        setBreadcrumb([
          ...breadcrumb,
          () => (
            <DashAddObjectMenu
              label={context.msg.t('add.kpi')}
              onClose={() => setBreadcrumb(breadcrumb.slice(0, -1))}
              opSelectionProps={{
                createLabel: context.msg.t('create.new.kpi'),
                selectExistingLabel: context.msg.t('select.existing.kpi'),
                onSelectExisting: () => {
                  openSelectObjectDialog({ fileExtension: 'kpi' });
                  toggleAccordion();
                },
                createComponent: ({ onClose }) => {
                  return (
                    <BngNewKpiPage
                      embeddedOpts={{
                        afterCreate: async ({ paths, editAfterCreate, newObject }) => {
                          await addObjectToDash(addChange, paths);
                          onClose();
                          if (editAfterCreate) {
                            bimEventBus.emit(KPI_EDIT_MENU_EVENT, {
                              path: newObject.path,
                              showClose: true,
                            });
                          }
                        },
                      }}
                    />
                  );
                },
              }}
            />
          ),
        ]);
      },
      avoidToggle: true,
    },
    {
      icon: Utils.Object.getObjectIcon('analysis', 'showChart'),
      label: 'chart',
      onClick: () => {
        setBreadcrumb([
          ...breadcrumb,
          () => (
            <DashAddObjectMenu
              label={context.msg.t('add.chart')}
              onClose={() => setBreadcrumb(breadcrumb.slice(0, -1))}
              opSelectionProps={{
                createLabel: context.msg.t('create.new.chart'),
                selectExistingLabel: context.msg.t('select.existing.chart'),
                onSelectExisting: () => {
                  openSelectObjectDialog({ fileExtension: 'analysis', viewType: 'chart' });
                  toggleAccordion();
                },
                createComponent: ({ onClose }) => {
                  return (
                    <BngNewAnalysis
                      embeddedOpts={{
                        filterTypes: (types) => {
                          return types.filter((type) => type.producedType === 'CHART');
                        },
                        afterCreate: async ({ paths, viewType }) => {
                          await addObjectToDash(addChange, paths, viewType);
                          onClose();
                        },
                        viewType: 'chart',
                        showFolderOpts: true,
                      }}
                    />
                  );
                },
              }}
            />
          ),
        ]);
      },
      avoidToggle: true,
    },
    {
      icon: Utils.Object.getObjectIcon('analysis'),
      label: 'table',
      onClick: () => {
        setBreadcrumb([
          ...breadcrumb,
          () => (
            <DashAddObjectMenu
              label={context.msg.t('add.table')}
              onClose={() => setBreadcrumb(breadcrumb.slice(0, -1))}
              opSelectionProps={{
                createLabel: context.msg.t('create.new.table'),
                selectExistingLabel: context.msg.t('select.existing.table'),
                onSelectExisting: () => {
                  openSelectObjectDialog({ fileExtension: 'analysis', viewType: 'table' });
                  toggleAccordion();
                },
                createComponent: ({ onClose }) => {
                  return (
                    <BngNewAnalysis
                      embeddedOpts={{
                        filterTypes: (types) => {
                          return types.filter(
                            (type) => type.type !== 'BLANK_ANALYSIS' && type.producedType === 'TABLE'
                          );
                        },
                        afterCreate: async ({ paths, viewType }) => {
                          await addObjectToDash(addChange, paths, viewType);
                          onClose();
                        },
                        viewType: 'table',
                        showFolderOpts: true,
                      }}
                    />
                  );
                },
              }}
            />
          ),
        ]);
      },
      avoidToggle: true,
    },
    {
      icon: Utils.Object.getObjectIcon('newmap'),
      label: 'newmap',
      onClick: () => openSelectObjectDialog({ fileExtension: 'newmap' }),
    },
    {
      icon: Utils.Object.getObjectIcon('bigtable'),
      label: 'bigTable',
      onClick: () => openSelectObjectDialog({ fileExtension: 'bigtable' }),
    },
  ];

  const visualItems = [
    {
      icon: 'text_fields',
      label: 'title',
      onClick: () => {
        dispatch(MODALS.open(DashboardTextForm, {}));
      },
    },
    {
      icon: 'notes',
      label: 'text',
      onClick: () => {
        editTextItem({ type: 'NEW_TEXT', itemId: UUID.generate() });
      },
    },
    {
      icon: 'code',
      label: 'HTML',
      onClick: () => {
        editTextItem({ itemId: UUID.generate() });
      },
    },
    {
      icon: 'insert_photo',
      label: 'image',
      avoidToggle: true,
      customRender: ({ item, itemKey }) => {
        return (
          <>
            <DashInputImage
              startUpload={() => setLoading(true)}
              finishUpload={() => {
                setLoading(false);
                toggleAccordion();
              }}
            />
            <Icon icon={item.icon} />
            <div className={`${itemKey} ItemDashboardDescription`}>{context.msg.t(item.label)}</div>
          </>
        );
      },
    },
    {
      icon: 'sentiment_very_satisfied',
      label: 'icon',
      onClick: () => {
        return dispatch(MODALS.open(DashboardIconForm, {}));
      },
    },
    {
      icon: 'web',
      label: 'container',
      onClick: () => editContainer({}),
    },
  ];

  const renderItemList = ({ item, index }) => {
    const itemKey = `ItemDashboard-${item.label.toUpperCase()}`;
    return (
      <li
        className={itemKey}
        key={index}
        onClick={(e) => {
          if (!!item.onClick) item.onClick(e);
          if (!!item.avoidToggle) return;
          toggleAccordion();
        }}
      >
        {!!item.customRender && item.customRender({ item, itemKey })}
        {!item.customRender && (
          <>
            <Icon icon={item.icon} />
            <div className={`${itemKey} ItemDashboardDescription`}>{context.msg.t(item.label)}</div>
          </>
        )}
      </li>
    );
  };

  return (
    <>
      <AccordionList className="ObjectRightMenuAccordion AddItemToDashboard" loading={loading}>
        <Accordion
          customTitle={() => (
            <div className="AccordionTitle" style={{ cursor: 'default' }}>
              <span className="AccordionDescription">{context.msg.t('drawing')}</span>
            </div>
          )}
        >
          <ul className="ListItemDashboard">{visualItems.map((item, index) => renderItemList({ item, index }))}</ul>
        </Accordion>
        <Accordion
          customTitle={() => (
            <div className="AccordionTitle" style={{ cursor: 'default' }}>
              <span className="AccordionDescription">{context.msg.t('objects')}</span>
            </div>
          )}
        >
          <ul className="ListItemDashboard">{analyticalItems.map((item, index) => renderItemList({ item, index }))}</ul>
        </Accordion>
      </AccordionList>
      {breadcrumb.at(-1)?.()}
    </>
  );
}

export default function AddItemToDashboardMenuItem({ toggleAccordion = _.noop, submenuComponent, ...props }) {
  const context = useBimContext();

  const toggleAddItemAccordion = (event) => {
    return toggleAccordion(AddItemToDashboard, 'AddItemToDashboardMenuItem');
  };

  return (
    <RightMenuItem
      title={context.msg.t('add.elements')}
      icon="add_circle"
      className="AddItemToDashboardMenuItem"
      onClick={toggleAddItemAccordion}
      active={props.active || (submenuComponent && submenuComponent === AddItemToDashboard)}
      {...props}
    />
  );
}
