import styles from './AssistedAnalysisEditMenu.module.css';
import React, { useRef, useState } from 'react';

import AccordionList from 'components/ui/AccordionList';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import { BngAnalystMenuForm } from 'components/bng/pages/newAnalysis/BngAnalystMenu';
import useTranslation from 'components/hooks/useTranslation';
import { RIGHT_MENU_CLOSE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import Accordion from 'components/ui/Accordion';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';

export const ASSISTED_ANALYSIS_EDIT_MENU_EVENT = 'ASSISTED_ANALYSIS_EDIT_MENU_EVENT';

export default function AssistedAnalysisEditMenu({ path, type, assistedData }) {
  const { t } = useTranslation();
  const $formik = useRef();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  return (
    <AccordionList
      className={`ObjectRightMenuAccordion AssistedAnalysisEditMenu ${styles.main}`}
      actionButton={
        <div className="flex-center-items gap-5">
          <BngButton
            className="w-100"
            variant={Variant.textButton}
            onClick={() => bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU)}
          >
            {t('close')}
          </BngButton>
          <BngButton
            className="w-100 fw-500"
            onClick={() => {
              if ($formik.current.isValid) {
                setLoading(true);
              }
              $formik.current.submitForm();
            }}
          >
            {t('save')}
          </BngButton>
        </div>
      }
      loading={loading || formLoading}
    >
      <Accordion key={path} title={t('parameters')} startOpen={true}>
        <BngAnalystMenuForm
          path={path}
          type={type}
          assistedData={assistedData}
          onFormikRef={(ref) => {
            $formik.current = ref;
          }}
          afterUpdate={async () => {
            try {
              UiMsg.ok(t('object.save.success'));
              bimEventBus.emit(Api.AssistedAnalysis.ASSISTED_ANALYSIS_UPDATED, { path });
            } catch (e) {
              console.error('Error on afterUpdate()', e);
              UiMsg.ajaxError(null, e);
            } finally {
              setLoading(false);
            }
          }}
          showLoading={false}
          onLoading={({ loading }) => {
            setFormLoading(loading);
          }}
        />
      </Accordion>
    </AccordionList>
  );
}
