import React from 'react';
import { connect } from 'react-redux';
import { MODALS } from 'components/ui/redux/Actions';

class ModalRootErrorHandler extends React.Component {
  static defaultProps = {
    onError: _.noop,
  };

  state = {
    hasError: false,
    error: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.hasError && this.state.hasError !== prevState.hasError) {
      this.props.onError({ ...this.state });
    }
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}

const ModalRoot = ({ stack, closeModal }) => {
  return (
    <div>
      {stack.map(({ type, params, ...props }, idx) => {
        const Modal = type;
        const { afterClose = _.noop, ...rest } = params;

        const closeWrapper = (values) => {
          closeModal(values);
          afterClose(values);
        };

        return (
          <ModalRootErrorHandler key={idx} onError={closeWrapper}>
            <Modal closeModal={closeWrapper} {...props} {...rest} />
          </ModalRootErrorHandler>
        );
      })}
    </div>
  );
};

export default connect(
  (state) => state.modals,
  (dispatch) => {
    return {
      closeModal: () => dispatch(MODALS.close()),
    };
  }
)(ModalRoot);
