import styles from './ProjectDomainTab.module.css';

import React from 'react';
import { Field } from 'formik';

import BngField from 'components/bng/form/BngField';
import useTranslation from 'components/hooks/useTranslation';

export default function ProjectDomainTab({ className = '' }) {
  const { t } = useTranslation();

  return (
    <div className={`ProjectDomainTab ${styles.DomainCustomizationWrapper} ${className}`}>
      <div className={`${styles.TitleAndDescription}`}>
        <h6>{t('projectManagementTab.domainCustomization')}</h6>
        <p>{t('addon.domain.name.desc')}</p>
      </div>
      <label>{t('project.domainCustomization.url.custom.title')}</label>
      <p dangerouslySetInnerHTML={{ __html: t('project.domainCustomization.url.custom.description') }} />
      <Field name="customUrl" label="" component={BngField} placeholder={t('project.domainCustomization.url.example')} />
    </div>
  );
}
