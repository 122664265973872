import { useEffect, useState } from 'react';

import Api from 'components/Api';

const useBimIntegrationEndpoint = (name = null) => {
  const [endpoints, setEndpoints] = useState({});

  useEffect(() => {
    const getInfoEndpoint = async () => {
      if (name) {
        const data = await Api.BimIntegration.findInfoEndpoint(name);
        data.endpoints = _.orderBy(data.endpoints, ['name'], ['asc']);
        setEndpoints(data);
      }
    };

    getInfoEndpoint();
  }, [name]);

  return [endpoints];
};

export default useBimIntegrationEndpoint;
