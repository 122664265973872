import './BngInputIconSelector.css';

import React from 'react';

import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import SelectIconDialog from 'components/ui/common/SelectIconDialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

export default function BngInputIconSelector({
  className = '',
  defaultType = Icon.MATERIAL,
  field,
  form,
  title,
  value,
  onChange,
  round = false,
  updateJsf = true,
  ...props
}) {
  const dispatch = useReduxDispatch();

  const val = value ?? field?.value ?? '';
  const valueIsString = _.isString(val);

  const openDialog = (e) => {
    dispatch(
      MODALS.open(SelectIconDialog, {
        onSelect: (icon) => {
          const newVal = valueIsString ? icon.name : icon;
          if (onChange) {
            return onChange(newVal);
          } else {
            return form.setFieldValue(field.name, newVal);
          }
        },
        icon: valueIsString ? { name: val, type: defaultType } : val,
        updateJsf,
      })
    );
  };

  const icon = valueIsString ? val : val?.name;
  const type = valueIsString ? defaultType : val?.type ?? Icon.MATERIAL;
  return (
    <div className={`BngInputIconSelector ${round ? 'round' : ''} ${className}`} onClick={openDialog}>
      <label className="Label" {...props}>
        {!_.isEmpty(title) && <span className="lbl">{title}</span>}
        <Icon icon={icon} type={type} />
      </label>
    </div>
  );
}
