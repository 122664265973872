import styles from './NewsPage.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useTranslation from 'components/hooks/useTranslation';
import BngSelect from 'components/bng/form/BngSelect';
import BngButton from 'components/bng/ui/BngButton';
import Api from 'components/Api';

const PAGE_SIZE = 20;
const DEFAULT_LANG = 'pt-BR';

const fixLanguage = (language) => {
  if (language.toLowerCase().endsWith('pt')) {
    return `${language.toLowerCase().split('_')[0]}-br`;
  } else if (language.startsWith('pt')) {
    return language.toLowerCase().replaceAll('_', '-');
  }
  return language.toLowerCase().split('_')[0];
}

let pageIndex = 1;
export default function NewsPage() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [languages, setLanguages] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [pinnedNews, setPinnedNews] = useState([]);
  const [newsType, setNewsType] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showSeeMore, setShowSeeMore] = useState(true);

  const selectedLanguage = searchParams.get('lang') ?? fixLanguage(window.__USER_LANG ?? DEFAULT_LANG);

  useEffect(() => {
    fetchGeneralData();
    fetchInitialNews(selectedLanguage);
  }, [selectedLanguage]);

  const fetchGeneralData = async () => {
    const data = await Api.Bng.getGeneralData();
    setLanguages(data.languages);
  };

  const fetchFilteredNews = async () => {
    try {
      const [news] = await Api.News.allPublicNews({
        language: fixLanguage(selectedLanguage),
        filters: selectedFilters,
        page: 0,
        pageSize: PAGE_SIZE,
        neededInfo: ['news'],
      });
      setShowSeeMore(news.length === PAGE_SIZE);
      setNewsList(news);
    } catch (e) {
      console.error('Error on fetchFilteredNews()', { e });
    }
  };

  useEffect(() => {
    if (selectedFilters.length > 0) {
      fetchFilteredNews();
    } else {
      fetchInitialNews(selectedLanguage);
    }
  }, [selectedFilters]);

  const fetchInitialNews = async (language = DEFAULT_LANG) => {
    try {
      const data = await Api.News.allPublicNews({
        language: fixLanguage(language),
        filters: selectedFilters,
        page: 0,
        pageSize: PAGE_SIZE,
        neededInfo: ['types', 'pinned', 'news'],
      });

      setPinnedNews(data[1]);
      setNewsList(data[2]);
      setShowSeeMore(data[2].length >= PAGE_SIZE);
      setNewsType(data[0]);
    } catch (e) {
      console.error('Error on fetchInitialNews()', { e });
    }
  };

  const filters = useMemo(() => {
    if (newsType.length === 0) {
      return [];
    }

    return newsType.map((nt, idx) => {
      return {
        id: nt.id,
        icon: nt.icon,
        label: nt.name,
        color: nt.color,
        onClick: () => {
          const idx = selectedFilters.findIndex((sf) => sf === nt.id);
          if (idx === -1) {
            setSelectedFilters((prev) => [...prev, nt.id]);
          } else {
            const temp = selectedFilters.slice();
            temp.splice(idx, 1);
            setSelectedFilters(temp);
          }
        },
      };
    });
  }, [newsType, selectedFilters]);

  const newsListFiltered = useMemo(() => {
    if (selectedFilters.length === 0) {
      return newsList;
    }
    return newsList.filter((nl) => {
      if (selectedFilters.filter((sf) => sf === nl.type.id).length > 0) {
        return nl;
      }
    });
  }, [newsList, selectedFilters]);

  const isSelected = (filter) => selectedFilters.find((f) => f === filter);

  const loadMoreNews = async () => {
    try {
      const resp = await Api.News.allPublicNews({
        language: fixLanguage(selectedLanguage),
        filters: selectedFilters,
        page: pageIndex,
        pageSize: PAGE_SIZE,
        neededInfo: ['news'],
      });
      pageIndex += 1;
      setShowSeeMore(resp[0].length === PAGE_SIZE);
      setNewsList((prev) => [...prev, ...resp[0]]);
    } catch (e) {
      console.error('Error on loadMoreNews()', { e });
    }
  };

  const cardImage = (imageLocal, imageLocalPath) => {
    if (!imageLocalPath.includes('/uploads/')) {
      return `${imageLocalPath}/${imageLocal}`;
    }
    return imageLocalPath;
  }

  const cardTypeColor = (color) => {
    if (!color?.includes('#')) {
      return `#${color}`;
    }
    return color;
  }

  return (
    <div className={`NewsPage ${styles.pageWrapper}`}>
      <div
        className={`${styles.blueThing}`}
        style={{ backgroundImage: `url(${Api.buildUrl('/resources/images/news_page_blue_thing.png')})` }}
      >
        <div className={`${styles.languageSelector}`}>
          <BngSelect
            options={languages}
            emptyOption={false}
            value={selectedLanguage}
            onChange={(event) => {
              searchParams.set('lang', event.target.value ?? DEFAULT_LANG);
              setSearchParams(searchParams);
              window.location.reload()
            }}
          />
        </div>
        <div className={`${styles.logoFilters}`}>
          <div>
            {t('news.page.title')}
            <img src={Api.buildUrl('/resources/images/logo-new.png')} />
          </div>
          <div className={`${styles.filters}`}>
            {filters.map((f, idx) => {
              const selected = isSelected(f.id);
              return (
                <BngButton
                  className={`${styles.filter}`}
                  icon={f.icon}
                  onClick={f.onClick}
                  title={f.label}
                  key={idx}
                  style={{ backgroundColor: !!selected ? '#1a6dff' : 'white', color: !!selected ? 'white' : 'black' }}
                >
                  {f.label}
                </BngButton>
              );
            })}
          </div>
        </div>
      </div>
      <div className={`${styles.bimNewsBody}`}>
        <div className={`${styles.newsList}`}>
          {newsListFiltered.slice(0, 5).map((nl, idx) => {
            return (
              <div
                key={nl.id}
                className={`${styles.newsCard} ${idx % 2 === 0 ? '' : 'reverse'}`}
                onClick={() => window.open(nl.link, '_blank')}
              >
                <div className={`${styles.titleDescription}`}>
                  <div
                    className={`${styles.newsType} ${nl.type.name}`}
                    style={{ backgroundColor: cardTypeColor(filters.find((f) => f.label === nl.type.name)?.color) }}
                  >
                    {nl.type.name}
                  </div>
                  <div className={`${styles.newsTitle}`}>{nl.title}</div>
                  <div className={`${styles.versionDate}`}>
                    {nl.version && (
                      <>
                        {nl.version.name}
                        <div />
                      </>
                    )}
                    {moment(nl.date).format('D MMM YYYY')}
                  </div>
                  <div className={`${styles.newsBrief}`}>{nl.brief}</div>
                </div>
                <div className={`${styles.image}`}>
                  <img src={cardImage(nl.image_local, nl.image_local_path)} />
                </div>
              </div>
            );
          })}
        </div>
        <div className={`${styles.socials}`}>
          <div className={`${styles.socialsTitle}`}>{t('news.page.social.title')}</div>
          <div className={`${styles.socialIcons}`}>
            <img
              src={Api.buildUrl('/resources/images/socials/linkedin.png')}
              onClick={() => window.open('https://www.linkedin.com/company/bim-machinells/')}
              alt="Linkedin Logo"
            />
            <img
              src={Api.buildUrl('/resources/images/socials/facebook-black.png')}
              onClick={() => window.open('https://www.facebook.com/bimachineplatform/')}
              alt="Facebook Logo"
            />
            <img
              src={Api.buildUrl('/resources/images/socials/instagram.png')}
              onClick={() => window.open('https://www.instagram.com/bimachine')}
              alt="Instagram Logo"
            />
            <img
              src={Api.buildUrl('/resources/images/socials/youtube.png')}
              onClick={() => window.open('https://www.youtube.com/user/sol7bimachine/')}
              alt="Youtube Logo"
            />
          </div>
          <div className={`${styles.helpCenter}`} onClick={() => window.open('https://support.bimachine.com.br/')}>
            <img src={Api.buildUrl('/resources/images/new_logo_bi.png')} />
            {t('news.page.social.help.center')}
          </div>
          <div className={`${styles.pinnedNewsWrapper}`}>
            <div className={`${styles.pinnedNewsListTitle}`}>{t('news.page.pinned.news')}</div>
            <div className={`${styles.pinnedNewsListWrapper}`}>
              {pinnedNews.map((pn, idx) => {
                return (
                  <div key={pn.id} className={`${styles.pinnedNews}`} onClick={() => window.open(pn.link)}>
                    <div>{pn.title}</div>
                    {pn.brief}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.otherNews}`}>
        <div className={`title ${newsList.length <= 5 ? 'hide' : ''}`}>{t('news.page.other.news')}</div>
        <div className={`${styles.moreNews}`}>
          {newsListFiltered.slice(5).map((nl, idx) => {
            return (
              <div key={nl.id} className={`newsCard ${styles.newsCard} vertical`} onClick={() => window.open(nl.link)}>
                <div className={`${styles.titleDescription}`}>
                  <div
                    className={`${styles.newsType}
                         ${nl.type.name}`}
                    style={{ backgroundColor: filters.find((f) => f.label === nl.type.name)?.color }}
                  >
                    {nl.type.name}
                  </div>
                  <div className={`${styles.newsTitle}`}>{nl.title}</div>
                  <div className={`${styles.versionDate}`}></div>
                  <div className={`${styles.newsBrief}`}>{nl.brief}</div>
                </div>
                <div className={`${styles.image}`}>
                  <img src={cardImage(nl.image_local, nl.image_local_path)} />
                </div>
              </div>
            );
          })}
        </div>
        <div className={`${styles.seeMoreWrapper} ${showSeeMore ? '' : 'hide'}`}>
          <BngButton
            className={`${styles.seeMore}`}
            icon="expand_more"
            onClick={loadMoreNews}
            title={t('news.page.see.more')}
          >
            {t('news.page.see.more')}
          </BngButton>
        </div>
      </div>
    </div>
  );
}
