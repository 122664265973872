import styles from './ProposalsInflatorsDialog.module.css';

import { useEffect, useMemo, useState } from 'react';
import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngTable from 'components/bng/ui/BngTable';
import Utils from 'components/Utils';
import { proposalUtils } from 'components/service/bng/AccountApi';

const buildInflatorColumns = ({ context, initialPricing, allReviewRates = [], currencySymbol = '' }) => {
  return [
    {
      label: context.msg.t('application.date'),
      size: 40,
      render: (row) => {
        return <span className={styles.rowData}>{Utils.Date.formatDate(row.activeDate)}</span>;
      },
    },
    {
      label: context.msg.t('tax'),
      size: 40,
      render: (row) => {
        const readjustment = row.agreedReviewRate > 0 ? `${row.agreedReviewRate}%` : context.msg.t('no.readjustment');
        return <span className={styles.rowData}>{`${readjustment}`}</span>;
      },
    },
    {
      label: context.msg.t('value'),
      size: 40,
      render: (row) => {
        let pricing = initialPricing;
        allReviewRates.forEach((rr) => {
          if (new Date(rr.activeDate) <= new Date(row.activeDate)) {
            pricing = pricing * (rr.agreedReviewRate / 100 + 1);
          }
        });
        return <span className={styles.rowData}>{proposalUtils.formatPricing(pricing, currencySymbol)}</span>;
      },
    },
  ];
};

const calculateFinalPrice = (pricing, quantity, agreedReviewRates = []) => {
  agreedReviewRates.forEach((rr) => {
    pricing = pricing * (rr.agreedReviewRate / 100 + 1);
  });
  pricing = pricing * quantity;
  return pricing.toFixed(2);
};

export default function ProposalsInflatorsDialog({
  closeModal = _.noop,
  accountId,
  proposalPricingId,
  proposalPricingType,
  proposalAcceptDate,
}) {
  const context = useBimContext();
  const [proposalPricingInfo, setProposalPricingInfo] = useState({});
  const [reviewRates, setReviewRates] = useState([]);

  const fetchProposalData = async () => {
    try {
      const fetchedInfo = await Api.Account.fetchProposalPricing(proposalPricingId, proposalPricingType, accountId);
      setProposalPricingInfo(fetchedInfo);
      const reviewRates = await Api.Account.fetchReviewRates(accountId);
      setReviewRates(
        reviewRates
          .filter((rr) => {
            return shouldApplyReviewRate(rr, fetchedInfo);
          })
          .sort((a, b) => {
            return new Date(a.activeDate) - new Date(b.activeDate);
          })
      );
    } catch (e) {
      console.error('Error on function fetchProposalData()', e);
      UiMsg.error(context.msg.t('error.fetching.proposalPricingInfo'));
    }
  };

  const shouldApplyReviewRate = (reviewRate, proposalPricing) => {
    const activeDate = new Date(`${reviewRate.activeDate}T12:00:00`);
    const acceptDate = new Date(`${proposalAcceptDate}T12:00:00`);

    if (proposalPricing.paymentMethod === 'INSTALLMENTS') {
      let monthsDiff;
      monthsDiff = (activeDate.getFullYear() - acceptDate.getFullYear()) * 12;
      monthsDiff -= acceptDate.getMonth() + 1;
      monthsDiff += activeDate.getMonth();

      if (monthsDiff < proposalPricing.installments) {
        return false;
      }
    }

    const isValidForDate = activeDate < new Date() && activeDate > acceptDate;
    const isValidType =
      proposalPricingType !== proposalUtils.PRICING_TYPES.SERVICE &&
      proposalPricing.paymentMethod !== 'LUMP_SUM' &&
      proposalPricing.billingFormat !== 'ONCE';

    return isValidForDate && isValidType;
  };

  useEffect(() => {
    fetchProposalData();
  }, []);

  const currencySymbol = proposalPricingInfo.pricing?.currency.symbol;

  const cols = useMemo(
    () =>
      buildInflatorColumns({
        context,
        initialPricing: proposalPricingInfo.agreedPricing,
        allReviewRates: reviewRates,
        currencySymbol,
      }),
    [reviewRates]
  );

  return (
    <Dialog
      onClose={closeModal}
      newDialogLayout={true}
      title={context.msg.t('inflators')}
      className={'ProposalsInflatorsDialog'}
    >
      {proposalPricingInfo != null && (
        <>
          <div className={styles.agreedPricingIndicator}>
            <strong>{context.msg.t('start.value')}</strong>
            <span>{proposalUtils.formatPricing(proposalPricingInfo.agreedPricing, currencySymbol)}</span>
          </div>
          <div className={styles.proposalInflatorsTableWrapper}>
            <BngTable
              className={styles.proposalInflatorsTable}
              rows={reviewRates}
              cols={cols}
              showEmptyAlert={true}
              emptyAlertProps={{ message: context.msg.t('no.changes.in.price'), showImage: false }}
              stickyHeader={true}
              rowHeight={49}
            />
          </div>
          <div className={styles.quantityIndicator}>
            <strong>{context.msg.t('quantity')}</strong>
            <span>X {proposalPricingInfo.quantity}</span>
          </div>
          <div className={styles.agreedPricingIndicator}>
            <strong>{context.msg.t('end.value')}</strong>
            <span>
              {proposalUtils.formatPricing(
                calculateFinalPrice(proposalPricingInfo.agreedPricing, proposalPricingInfo.quantity, reviewRates),
                currencySymbol
              )}
            </span>
          </div>
        </>
      )}
    </Dialog>
  );
}
