import { Axios } from "commonUtils";

class EmailBlacklistApi {
  async find(search = "", page = 0, size = 10) {
    const { data } = await Axios.get(`/spr/email/blacklist`, {
      params: {
        search,
        page,
        size,
      },
    });
    return data;
  }

  async remove(emails) {
    if (_.isEmpty(emails)) return;

    await Axios.delete(`/spr/email/blacklist`, {
      data: {
        emails,
      },
    });
  }

  async addToBlacklist(emails) {
    if (_.isEmpty(emails)) return;

    await Axios.post(`/spr/email/blacklist`, { emails });
  }

  async findConfig() {
    const { data } = await Axios.get(`/spr/email/blacklist/config`);
    return data;
  }

  async updateConfig(enableEmailBlacklist = true) {
    const { data } = await Axios.post(`/spr/email/blacklist/config`, {
      enableEmailBlacklist,
    });
    return data;
  }
}

export default EmailBlacklistApi;
