import './PaginateTable.css';

import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Pagination} from "imports/ReactBootstrapImport";

import ContextEnhancer from "components/ContextEnhancer";
import LoadingBox from "components/ui/loading/LoadingBox";

class PaginateTable extends Component {

    static propTypes = {
        externalChange: PropTypes.bool,
        handleChangePage: PropTypes.func.isRequired,
        handleChangeNumberPerPage: PropTypes.func.isRequired,
        initialPass: PropTypes.number,
        initialActivePage: PropTypes.number,
        perPageLimit: PropTypes.number,
        tableId: PropTypes.string,
        totalItens: PropTypes.number,
        height: PropTypes.number,
        renderChangerNumberPerPage: PropTypes.bool,
        paginationClassName: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        externalChange: false,
        handleChangePage: _.noop,
        handleChangeNumberPerPage: _.noop,
        height: 420,
        initialActivePage: 1,
        initialPass: 5,
        perPageLimit: 30,
        tableId: "paginate-table",
        totalItens: 0,
        paginationClassName: '',
        className: '',
    };

    state = {
        activePage: this.props.initialActivePage,
        loading: false,
        error: false,
        perPage: this.props.initialPass,
        perPageOptions: [],
    };

    componentDidMount(){
        const perPageOptions = [];
        for (let i = this.props.initialPass; i <= this.props.perPageLimit; i+= this.props.initialPass) {
            perPageOptions.push(<option key={i} value={i}>{i}</option>)
        }
        this.setState({perPageOptions})
        this.handlePerPage(this.state.perPage, this.props.initialActivePage);
    }

    componentDidUpdate(prevProps) {
        if (this.props.externalChange !== prevProps.externalChange) {
            if (this.props.externalChange) {
                this.setState({loading: true, activePage: 1});
            } else {
                this.setState({loading: false});
            }
        }
    }

    handlePerPage = async (perPage, activePage = 1) => {
        const perPageValue = _.parseInt(perPage.target ? perPage.target.value : perPage);
        this.setState({loading: true, activePage, perPage: perPageValue});
        const response = await this.props.handleChangeNumberPerPage(perPageValue, 1);
        this.setState({loading: false, error: response && response.error});
    };

    handlePagination = async (pageNumber, firstLoad = false) => {
        this.setState({loading: true, activePage: pageNumber});
        const response = await this.props.handleChangePage(pageNumber, firstLoad, this.state.perPage);
        this.setState({loading: false, error: response ? response.error : null});
    };

    renderChildren = () => {
        const { children, height, totalItens} = this.props;
        const { loading, error } = this.state;

        return (
            loading || error || totalItens === 0 ?
                loading ? <LoadingBox />
                        : error ? <span style={{lineHeight: height}}>{this.props.context.msg.t('paginate.table.error.fetching')}</span>
                            : <span style={{lineHeight: `${height}px`}}>{this.props.context.msg.t('paginate.table.empty')}</span>
            : children
        )
    };

    render(){
        const { height, totalItens, renderChangerNumberPerPage, paginationClassName } = this.props;
        const { perPageOptions } = this.state;
        const paginationNumberOfItems = Math.ceil(totalItens / this.state.perPage);

        return (
          <div>
              <div className={this.props.className} style={{overflow:'auto',maxHeight: height, minHeight: height, textAlign: 'center'}}>
                  {this.renderChildren()}
              </div>

              <hr style={{margin: '5px 0'}}/>

              <div className="pagination-table-footer">
                  {renderChangerNumberPerPage &&
                      <div className="pagination-select-per-page">
                          <label>{this.props.context.msg.t('items.per.page')}</label>
                          <select name="select-pagination" onChange={this.handlePerPage}>
                              {perPageOptions}
                          </select>
                          <label>{this.props.context.msg.t('of')} {totalItens} {this.props.context.msg.t('items')}</label>
                      </div>
                  }

                  <div className={`pagination pagination-mini ${paginationClassName}`}  style={{margin: 0, padding: '0 1px 0 0', textAlign: 'right'}}>
                      <Pagination bsSize="medium" style={{margin: 0, padding: 0, boxShadow: 'none'}}>
                          <Pagination.First disabled={this.state.activePage === 1}
                                            onClick={() => this.state.activePage !== 1 && this.handlePagination(1)}/>
                          <Pagination.Prev disabled={this.state.activePage === 1}
                                           onClick={() => this.state.activePage !== 1 && this.handlePagination(this.state.activePage - 1)}/>
                          <Pagination.Item>{this.state.activePage}</Pagination.Item>
                          <Pagination.Next disabled={this.state.activePage === (paginationNumberOfItems) || paginationNumberOfItems === 0}
                                           onClick={() => (this.state.activePage !== paginationNumberOfItems && paginationNumberOfItems !== 0) && this.handlePagination(this.state.activePage + 1)}/>
                          <Pagination.Last disabled={this.state.activePage === (paginationNumberOfItems) || paginationNumberOfItems === 0}
                                           onClick={() => (this.state.activePage !== (paginationNumberOfItems) && paginationNumberOfItems !== 0) && this.handlePagination(paginationNumberOfItems)}/>
                      </Pagination>
                  </div>
              </div>
          </div>
        );
    }
}

export default ContextEnhancer(PaginateTable);