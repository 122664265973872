import styles from './BngColorListReorderDialog.module.css';

import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';

const reorder = (palette = [], startIndex = 0, endIndex = 0) => {
  const copy = palette.slice();
  const [removed] = copy.splice(startIndex, 1);
  copy.splice(endIndex, 0, removed);
  return copy;
};

export default function BngColorListReorderDialog({ className = '', closeModal = _.noop, initialPalette = [], onChange = _.noop }) {
  const [palette, setPalette] = useState(initialPalette);
  const { t } = useTranslation();

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const fromIdx = result.source.index;
    const toIdx = result.destination.index;

    if (fromIdx === toIdx) {
      return;
    }

    const newValue = reorder(palette, fromIdx, toIdx);

    setPalette(newValue);
  };

  const removeColor = (idx) => {
    const copy = palette.slice();
    copy.splice(idx, 1);
    setPalette(copy);
  };

  return (
    <Dialog
      className={`BngColorListReorderDialog ${styles.ColorsDialog} ${className}`}
      onClose={closeModal}
      title={t('color.palette.reorder.dialog')}
    >
      <Dialog.Body>
        <div className={styles.ColorsList}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-visible" direction="vertical">
              {(provided) => {
                return (
                  <div ref={provided.innerRef}>
                    {palette.map((color, idx) => (
                      <Draggable key={color} draggableId={idx + 1} index={idx}>
                        {(provided) => (
                          <>
                            <div
                              className={`ColorContainer ${styles.ColorContainer}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Icon icon="drag_indicator" />
                              <div className={styles.ColorDescriptionContainer}>
                                <div className={styles.ColorPickerPreview} style={{ backgroundColor: color }} />
                                <span>{color}</span>
                              </div>
                              <BngIconButton icon="delete" onClick={() => removeColor(idx)} />
                            </div>
                            {provided.placeholder}
                          </>
                        )}
                      </Draggable>
                    ))}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
      </Dialog.Body>
      <Dialog.Footer className={styles.DialogFooter}>
        <DefaultDialogActions
          okLabel={t('ag-grid.applyFilter')}
          closeModal={closeModal}
          onClickSaveButton={async () => {
            try {
              await onChange({ value: palette });
              closeModal();
            } catch (e) {
              console.error('Error on save()', e);
              UiMsg.ajaxError(null, e);
            }
          }}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
