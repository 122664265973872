import './Dialog.css';
import * as React from 'react';
import { Modal } from 'imports/ReactBootstrapImport';
import PropTypes from 'prop-types';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';

export default class Dialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    titleComponent: PropTypes.any,
    hideHeader: PropTypes.bool,
    className: PropTypes.string,
    open: PropTypes.bool,
    onMaximize: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    backdrop: PropTypes.any,
    backdropClassName: PropTypes.string,
    loading: PropTypes.bool,
    contentFullWidth: PropTypes.bool,
    newDialogLayout: PropTypes.bool,
    verticalCenter: PropTypes.bool,
    hideClose: PropTypes.bool,
    draggable: PropTypes.bool,
    icon: PropTypes.string,
    afterBody: PropTypes.any,
  };

  static defaultProps = {
    title: 'Title',
    hideHeader: false,
    className: '',
    open: true,
    onOpen: () => null,
    onClose: () => null,
    backdrop: 'static',
    backdropClassName: '',
    loading: false,
    contentFullWidth: false,
    newDialogLayout: false,
    verticalCenter: false,
    hideClose: false,
    draggable: true,
    icon: null,
    afterBody: null,
  };

  dialogId = Utils.randomId();

  constructor(props) {
    super(props);
  }

  setupDraggable = (r) => {
    if (!this.props.draggable) return;
    let mobile = application.page.isMobile();
    if (r && !this.draggable && !mobile) {
      let $this = jQuery(r);
      let $dialog = $this.parents('.bs-dialog');
      let margin = 0;
      try {
        let leftMarginPx = $dialog.css('margin-left');
        if (leftMarginPx) {
          leftMarginPx = leftMarginPx.replace('px', '');
          margin = Math.abs(Number(leftMarginPx));
        }
      } catch (e) {}
      $dialog.draggable({
        handle: $this,
        containment: [margin, 0, 9999, 9999],
        start: () => {
          j(`.${this.dialogId}`).addClass('Dragged');
        },
      });
      this.draggable = true;
    }
  };

  maximize($dialog) {
    let height = $dialog.find('.modal-body').outerHeight($dialog.height()).height();
    height = $dialog.find('.widget-box').outerHeight(height).height();
    height = $dialog
      .find('.widget-body')
      .outerHeight(height - $dialog.find('.widget-header').outerHeight())
      .height();
    $dialog.find('.widget-main').outerHeight(height);
  }

  minimize($dialog) {
    $dialog.find('.modal-body,.widget-box,.widget-body,.widget-main').css('height', '');
  }

  maximizeButtonAction = (e) => {
    e.preventDefault();
    let $dialog = jQuery(e.target).parents('.bs-dialog');

    let isMaximized = $dialog.hasClass('maximized');
    $dialog.toggleClass('maximized');
    if (!isMaximized) {
      this.maximize($dialog);
    } else {
      this.minimize($dialog);
    }
    this.props.onMaximize(isMaximized);
  };

  closeButtonAction = (e) => {
    e.preventDefault();
    this.props.onClose(e);
  };

  render() {
    return (
      <Modal
        show={this.props.open}
        onHide={this.props.onClose}
        animation={this.props.animation || false}
        className={`
                   bs-dialog ${this.props.className} 
                   ${this.dialogId}
                   ${this.props.newDialogLayout ? 'DialogNewLayout' : ''}  
                   ${this.props.verticalCenter ? 'VerticalCenter' : ''}`}
        backdrop={application.page.isMobile() ? true : this.props.backdrop}
        enforceFocus={false}
        backdropClassName={this.props.backdropClassName}
      >
        <Modal.Body>
          <UiBlocker block={this.props.loading}>
            <div className="widget-box">
              {!this.props.hideHeader && (
                <>
                  <div
                    className={`widget-header ${this.props.draggable ? 'draggable-cursor' : ''} `}
                    ref={this.setupDraggable}
                  >
                    {this.props.icon && <Icon className="DialogIcon" icon={this.props.icon} />}
                    {!this.props.titleComponent && <h5>{this.props.title}</h5>}
                    {this.props.titleComponent && this.props.titleComponent()}
                  </div>
                  <div className="widget-toolbar">
                    {this.props.onMaximize && (
                      <a href="#" onClick={this.maximizeButtonAction}>
                        <div className="btnmodal btnnmodalbtexpand">
                          <Icon icon="close" />
                        </div>
                      </a>
                    )}
                    {!this.props.hideClose && (
                      <a href="#" onClick={this.closeButtonAction}>
                        <div className="btnmodal btnnmodalbtclose">
                          <Icon icon="close" />
                        </div>
                      </a>
                    )}
                  </div>
                </>
              )}
              <div className={`widget-body  ${this.props.contentFullWidth ? 'content-full-width' : ''}`}>
                <div className="widget-main">
                  <div className="dialog-body">{this.props.children}</div>
                  {this.props.afterBody}
                </div>
              </div>
            </div>
          </UiBlocker>
        </Modal.Body>
      </Modal>
    );
  }
}

Dialog.Body = ({ className = '', children }) => <div className={`DialogBody ${className}`}>{children}</div>;

Dialog.Footer = ({ className = '', children }) => <div className={`DialogFooter ${className}`}>{children}</div>;
