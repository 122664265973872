import React, {Component} from "react";
import PropTypes from "prop-types";

import {BngPopperButton, BngPopperWrapper} from "components/bng/ui/BngPopperWrapper";
import FilterItem from "components/filter/FilterItem";
import UiMsg from "components/ui/UiMsg";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import Icon from "components/ui/common/Icon";
import Utils from "components/Utils";
import ContextEnhancer from "components/ContextEnhancer";


class BngGlobalFilterButton extends Component {

    static propTypes = {
        activeFilters: PropTypes.array,
        inactiveFilters: PropTypes.array,
        isFiltered: PropTypes.bool,
        onChangeFilter: PropTypes.func,
        disabled: PropTypes.bool
    };

    state = {
        loading: true,
        openInactives: false,
        disabled: false,
    };

    componentDidMount = async () => {
        this.setState({loading: false});
    };

    setLoading = (loading) => {
        this.setState({loading});
    };

    onChangeFilter = async (filter, selectedMembers, members = [], force = false) => {
        this.setState({loading: true});
        try {
            await this.props.onChangeFilter(filter, selectedMembers);
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError('error', e);
        } finally {
            this.setState({loading: false});
        }
    };

    toggleInactives = (event) => {
        this.setState({openInactives: !this.state.openInactives});
    };

    render() {
        const {activeFilters, inactiveFilters, isFiltered} = this.props;

        let dashboardPath = undefined;

        if (Utils.Object.isDashboard(this.props.path)) {
            dashboardPath = this.props.path;
        }

        return (
            <BngPopperWrapper
                className="cockpit-filter-popper"
                placement="bottom-start"
                container={document.body}
                modifiers={{
                    preventOverflow: {
                        boundariesElement: document.body,
                    }
                }}
                disabled={this.props.disabled}
            >

                <BngPopperButton className="cockpit-nav-btn">
                    <a className={`${isFiltered ? 'filtered' : ''}`} href="#" onClick={e => e.preventDefault()}>
                        <Icon icon="filter_alt"/>
                    </a>
                </BngPopperButton>
                <UiBlocker block={this.state.loading}>
                    <div className="cockpit-dropdown-popper cockpit-filter-popper-list CockpitNavFilterDrop">
                        <div className="cockpit-dropdown-popper-header">
                            <Icon icon="icon-bim-filter" className="cockpit-filter-type-icon" type={Icon.BIM}/>
                            <span className="cockpit-filter-type">{this.props.context.msg.t('active.filters')}</span>
                        </div>
                        <ul className="cockpit-filter-popper-items filter-container">
                            {activeFilters.map(filter => {
                                return (
                                    <li key={filter.id} className="cockpit-filter-item cockpit-filter-item-active">
                                        <FilterItem filter={filter}
                                                    filtered={filter.selectedMembers.length > 0}
                                                    selectedFilters={activeFilters}
                                                    loadingListener={this.setLoading}
                                                    onEditorMode={false}
                                                    onChange={this.onChangeFilter}
                                                    type="COCKPIT"
                                                    dashboardPath={dashboardPath}
                                        />
                                    </li>
                                )
                            })}
                        </ul>
                        <div style={{cursor: 'pointer'}} className="cockpit-dropdown-popper-header inactive"
                             title={this.props.context.msg.t('disabled.filters.title')} onClick={this.toggleInactives}>
                            <Icon icon="block" className="cockpit-filter-type-icon"/>
                            <span style={{width: '100%'}}
                                  className="cockpit-filter-type">{this.props.context.msg.t('inactive.filters')}</span>
                            <Icon icon={this.state.openInactives ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                  className="cockpit-filter-type-icon"/>
                        </div>
                        {this.state.openInactives &&
                        <ul className="cockpit-filter-popper-items filter-container">
                            {inactiveFilters.map(filter => {
                                return (
                                    <li key={filter.id} className="cockpit-filter-item cockpit-filter-item-inactive">
                                        <FilterItem filter={filter} selectedFilters={activeFilters}
                                                    inactive={true} onEditorMode={false}
                                                    type="COCKPIT"
                                        />
                                    </li>
                                )
                            })}
                        </ul>
                        }
                    </div>
                </UiBlocker>

            </BngPopperWrapper>
        )
    }
}


export default ContextEnhancer(BngGlobalFilterButton);