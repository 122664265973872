import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Api from 'components/Api';
import { BREADCRUMB } from 'components/ui/redux/Actions';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

export default function useBimNavigate() {
  const navigate = useNavigate();
  const dispatch = useReduxDispatch();

  return useCallback(async (link, props = {}) => {
    const isSpecialAction = typeof link !== 'string';

    if (!isSpecialAction) {
      const breadCrumbLink = link.startsWith('/spr/bng') ? link : `/spr/bng${link}`
      const state = await Api.Breadcrumb.updateCurrent(breadCrumbLink);
      dispatch(BREADCRUMB.update(state));
    }

    navigate(isSpecialAction ? link : link.replace('/spr/bng', ''), props);
  }, []);
}
