import './GroupRender.css';
import React from 'react';

export function ColorDot({ className = '', color }) {
  return <div className={`Dot ${className}`} style={{ backgroundColor: color }} />;
}

export default function GroupRender({ name, color = '#005DFF', className = '', onClick }) {
  return (
    <div className={`GroupRender ${className}`} onClick={onClick}>
      <ColorDot className="mr-1" color={color} />
      <span className="groupName" style={{ color }}>
        {name}
      </span>
    </div>
  );
}
