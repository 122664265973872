import React, {PureComponent} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import {AutoSizer, Column, Table} from "imports/ReactVirtualizedImport";
import BngInputColor from "components/bng/form/BngInputColor";

class MemberColorList extends PureComponent {

    static propTypes = {};

    static defaultProps = {
        change: _.noop,
        randomColor: _.noop
    };

    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        const {items} = this.props;
        let rowHeight = 25;
        return (
            <div className="legend-items-config">
                <AutoSizer disableHeight={true}>
                    {({width}) => (
                        <Table disableHeader={true}
                               rowClassName={"legend-item-config"}
                               rowCount={items.length}
                               rowGetter={({index}) => items[index]}
                               rowHeight={rowHeight}
                               width={width}
                               height={200}>

                            <Column dataKey="color"
                                    style={{marginLeft: 0}}
                                    width={15}
                                    cellRenderer={this.InputColorRenderer}
                            />

                            <Column dataKey="description"
                                    width={100}
                                    flexGrow={1}
                                    cellRenderer={this.DescriptionCellRenderer}
                            />

                        </Table>
                    )}
                </AutoSizer>

            </div>
        );
    }

    InputColorRenderer = ({rowData, rowIndex}) => {
        const props = {
            addTransparentOptions: false,
            size: 'xxs',
            field: {name: 'color', value: rowData.color},
            form: {
                setFieldValue: (name, value) => {
                    this.props.change(`${this.props.name}[${rowIndex}].color`, value)
                }
            }
        };
        return (
            <BngInputColor {...props} />
        );
    };

    DescriptionCellRenderer = ({rowData}) => {
        const memberName = rowData.caption || rowData.member;
        return (
            <i style={{verticalAlign: 'super'}}
               title={memberName}>
                {memberName}
            </i>
        );
    };
}

export default ContextEnhancer(MemberColorList);
