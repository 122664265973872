import printValue from "yup/lib/util/printValue";

let mixed = {
    default: 'Is invalid',
    required: 'Required field',
    oneOf: 'Must be one of the following values: ${values}',
    notOneOf: 'Must not be one of the following values: ${values}',
    notType: ({ path, type, value, originalValue }) => {
        let isCast = originalValue != null && originalValue !== value;
        let msg =
            `Must be a \`${type}\` type, ` +
            `but the final value was: \`${printValue(value, true)}\`` +
            (isCast
                ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
                : '.');

        if (value === null) {
            msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
        }

        return msg;
    },
};

let string = {
    length: 'Must be exactly ${length} characters',
    min: 'Must be at least ${min} characters',
    max: 'Must be at most ${max} characters',
    matches: 'Must match the following: "${regex}"',
    email: 'Must be a valid email',
    url: 'Must be a valid URL',
    trim: 'Must be a trimmed string',
    lowercase: 'Must be a lowercase string',
    uppercase: 'Must be a upper case string',
};

let number = {
    min: 'Must be greater than or equal to ${min}',
    max: 'Must be less than or equal to ${max}',
    lessThan: 'Must be less than ${less}',
    moreThan: 'Must be greater than ${more}',
    notEqual: 'Must be not equal to ${notEqual}',
    positive: 'Must be a positive number',
    negative: 'Must be a negative number',
    integer: 'Must be an integer',
};

let date = {
    min: 'Field must be later than ${min}',
    max: 'Field must be at earlier than ${max}',
};

let boolean = {};

let object = {
    noUnknown: 'Field cannot have keys not specified in the object shape',
};

let array = {
    min: 'Field must have at least ${min} items',
    max: 'Field must have less than or equal to ${max} items',
};

export default {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
};