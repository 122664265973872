import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
    BreadcrumbToolbar,
    ExportToolbarItem,
    FavoriteToolbarItem,
    InformationToolbarItem,
    MobileToolbarItem,
    NotesToolbarItem,
    PublisherToolbarItem,
    ReloadToolbarItem
} from "components/ui/breadcrumb-toolbar";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import HideHeaderToolbarItem, { HideHeaderPageOpts } from 'components/ui/breadcrumb-toolbar/HideHeaderToolbarItem';

class BigTableBreadcrumbToolbar extends Component {

    static propTypes = {
        id: PropTypes.number,
        path: PropTypes.string,
        caption: PropTypes.string,
        mobile: PropTypes.bool,
        dataSourceCaption: PropTypes.string,
        isDynamic: PropTypes.bool,
        onReload: PropTypes.func,
        disabled: PropTypes.bool,
        filters: PropTypes.array,
        onExportToCsv: PropTypes.func,
        onExportToPdf: PropTypes.func,
        disableExportToPdf: PropTypes.bool,
        exportState: PropTypes.any,
    };

    static defaultProps = {
        id: 0,
        path: '',
        caption: '',
        mobile: true,
        dataSourceCaption: '',
        isDynamic: false,
        disabled: false,
        filters: [],
        onExportToCsv: null,
        onExportToPdf: null,
        disableExportToPdf: false,
        exportState: undefined,
    };

    state = {
        loading: false,
        mobile: this.props.mobile,
        datasource: '',
        timeFilter: null,
    };

    reload = () => {
        this.props.onReload();
    };

    toggleMobile = async () => {
        this.setState({loading: true});
        try {
            const mobile = await Api.BigTable.toggleMobile(this.props.id);
            this.setState({mobile});
        } catch (e) {
            console.error(e)
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    exportToPdfHandler = async (e) => {
        this.setState({loading: true});
        try {
            await this.props.onExportToPdf(e);
        } finally {
            this.setState({loading: false});
        }
    }

    exportCsvHandler = async (e) => {
        this.setState({loading: true});
        try {
            await this.props.onExportToCsv(e);
        } finally {
            this.setState({loading: false});
        }
    }

    render() {
        const {loading, datasource, mobile} = this.state;
        const {disabled, path, caption, dataSourceCaption, disableExportToPdf, context} = this.props;

        const isAtLeastExplorer = context.permissions.isAtLeastExplorer();
        return (
            <BreadcrumbToolbar loading={loading}>

                <ExportToolbarItem disabled={disabled}
                                   caption={caption}
                                   path={path}
                                   filters={this.props.filters}
                                   onExportToPdf={this.props.onExportToPdf ? this.exportToPdfHandler : undefined}
                                   onExportToCsv={this.props.onExportToCsv ? this.exportCsvHandler : undefined}
                                   disableExportToPdf={disableExportToPdf}
                                   exportState={this.props.exportState}
                />

                {this.props.context.cockpitEnabled &&
                    <HideHeaderToolbarItem location={HideHeaderPageOpts.BREADCRUMBS} />
                }

                <ReloadToolbarItem disabled={disabled}
                                   onClick={this.reload}
                />

                {isAtLeastExplorer &&
                <PublisherToolbarItem path={path}
                                      caption={caption}
                                      disabled={disabled}
                />
                }

                <NotesToolbarItem disabled={disabled}
                                  path={path}
                />

                <FavoriteToolbarItem disabled={disabled}
                                     path={path}/>
                {isAtLeastExplorer &&
                <MobileToolbarItem disabled={disabled}
                                   inactive={!mobile}
                                   path={path}
                                   onClick={this.toggleMobile}
                />
                }

                <InformationToolbarItem disabled={disabled}
                                        title={this.props.context.msg.t('object.information.bigtable')}
                                        dataSource={datasource}
                                        dataSourceCaption={dataSourceCaption}
                                        path={path}
                />

            </BreadcrumbToolbar>
        )
    }

    findTimeFilter = async () => {
        if (this.state.timeFilter) {
            return this.state.timeFilter;
        }
        try {
            const timeFilter = await Api.Bng.getKpiDynamicPeriodicities();
            this.setState({timeFilter});
            return timeFilter;
        } catch (e) {
            UiMsg.ajaxError(null, e);
            return null;
        }
    };

}

export default ContextEnhancer(BigTableBreadcrumbToolbar);