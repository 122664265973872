import React from 'react';
import { Field } from 'formik';

import { BngField } from 'components/bng/form/BngField';
import BngFolderFieldInput from 'components/bng/form/BngFolderFieldInput';

export default {
  render({ name, label, props }) {
    return (
      <Field
        name={name}
        label={label}
        placeholder={'Folder'}
        component={BngField}
        inputComponent={BngFolderFieldInput}
        rootClassName={'ParamType Folder'}
        rootProps={{ 'data-test': 'Folder' }}
        {...props}
      />
    );
  },
};
