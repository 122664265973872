import styles from './NPSCensus.module.css';
import scStyles from './ScoreCounter.module.css';

import React from 'react';

export default function ScoreCounter({ className = '', score, setScore }) {
  const buttons = [];
  for (let i = 0; i < 11; i++) {
    const isSelected = score === i;
    buttons.push(
      <div
        key={`ScoreButton-${i}`}
        className={`${styles.voteButton} ${isSelected ? styles.selected : ''}`}
        onClick={() => setScore(i)}
      >
        {i}
      </div>
    );
  }
  return <div className={`ScoreCounter ${scStyles.scoreCounter} ${className}`}>{buttons}</div>;
}
