import 'components/ui/presentation/PresentationProperties.css'

import React, {Component} from "react";
import PropTypes from "prop-types";
import {Field} from "formik";

import ContextEnhancer from "components/ContextEnhancer";
import {BngField} from "components/bng/form/BngField";
import BngCheckbox from "components/bng/form/BngCheckbox";
import {arrayToOptionsI18n, BngSelect} from "components/bng/form/BngSelect";
import BngImageUpload from "components/bng/form/BngImageUpload";
import BngRestrictedColor from "components/bng/form/BngRestrictedColor";

class PresentationProperties extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        transitionStyle: PropTypes.array,
    };

    static defaultProps = {
        transitionStyle: [],
    };

    state = {};

    render() {
        const wrapTransitionStyle = arrayToOptionsI18n(this.props, this.props.transitionStyle);
        return (
            <div className="container-presentation-properties">
                <div className="container-presentation-row">
                    <div>
                        <Field label={this.props.context.msg.t('name')}
                               name="namePresentation"
                               className="field-presentation-name"
                               required={true}
                               component={BngField}
                               maxLength={30}
                        />
                    </div>
                    <div className="container-transition-style">
                        <Field label={this.props.context.msg.t('transition.style')}
                               name="transition"
                               className="presentation-input-transition-style"
                               component={BngField}
                               inputComponent={BngSelect}
                               options={wrapTransitionStyle}
                               emptyOption={false}/>
                    </div>
                    <div className="container-transition-style">
                        <Field label={this.props.context.msg.t('theme')}
                               name="theme"
                               component={BngField}
                               inputComponent={BngRestrictedColor}
                               size="lg"
                        />
                    </div>
                </div>

                <div className="container-presentation-row">
                    <div>
                        <Field label={this.props.context.msg.t('logotype')}
                               name="logotypeImageUpload"
                               component={BngField}
                               inputComponent={BngImageUpload}
                               asProps={{width: 207}}
                        />
                    </div>
                    <div className="container-background-image-upload">
                        <Field label={this.props.context.msg.t('background.image')}
                               name="backgroundImageUpload"
                               component={BngField}
                               inputComponent={BngImageUpload}
                        />
                    </div>

                </div>

                <div className="container-presentation-row last">
                    <div>
                        <div>{this.props.context.msg.t('publisher.config')}</div>
                        <Field label=""
                               name="executiveTemplate"
                               component={BngField}
                               title={this.props.context.msg.t('presentation.executive.template')}
                               inputComponent={BngCheckbox}
                               asProps={{label: this.props.context.msg.t('presentation.executive.template')}}
                        />
                    </div>
                    <div className="container-openObject">
                        <Field label=""
                               name="openObject"
                               component={BngField}
                               title={this.props.context.msg.t('presentation.view.object')}
                               inputComponent={BngCheckbox}
                               asProps={{label: this.props.context.msg.t('presentation.view.object')}}
                        />
                    </div>
                    <div className="container-refresh-time">
                        <Field label={this.props.context.msg.t('presentation.refresh.time.label')}
                               name="refreshPresentation"
                               component={BngField}
                               min="0"
                               max="9999"
                               maxLength="4"
                               type="number"
                               className="presentation-input-refreshPresentation"
                        />
                        <div className="refresh-time-label">{this.props.context.msg.t('minutes')}</div>
                    </div>
                </div>

            </div>

        )
    }

}

export default ContextEnhancer(PresentationProperties);