import styles from './FindTraceabilityDialog.module.css';

import React, { useRef, useState, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { BngField } from 'components/bng/form/BngField';
import { bngYup } from 'components/bng/form/yup/BngYup';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';
import { BngMaskedField } from 'components/bng/form/BngMaskedField';

const ExportTraceValidationSchema = bngYup((yup) =>
    yup.object().shape({
      trackingCode: yup.number().min(0).required(),
    })
);

function TraceabilitySenderInfo({ className = '', user, date, label, ...props }) {
  const { msg } = useBimContext();

  let dateMsg = msg.t('without.information');
  if (date) {
    const [datePart, ...timePart] = Utils.Date.formatDateTime(date).split(' ');
    dateMsg = `${datePart} ${msg.t('atTime')} ${timePart.join(' ')}`;
  }

  return (
      <div className={`TraceabilitySenderInfo ${className}`} {...props}>
        <div>
          <span className={`mr-1`}>{label}:</span>
          <b>{Utils.Users.displayName(user) ?? '-'}</b>
        </div>
        <div>{dateMsg}</div>
      </div>
  );
}

function TraceabilityDeviceInfo({ className = '', device, label, ...props }) {
  const { msg } = useBimContext();

  return (
      <div className={`TraceabilityDeviceInfo ${className}`} {...props}>
        <div>
          <span className={`mr-1`}>{label}:</span>
        </div>
        <div>
          <b>{device !== 'UNKNOWN' ? device ?? '-' : msg.t('unknown.device')}</b>
        </div>
      </div>
  );
}

export default function FindTraceabilityDialog({ closeModal = _.noop }) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const [exportTrace, setExportTrace] = useState(undefined);
  const formikRef = useRef();

  const findExportTrace = async (values) => {
    setLoading(true);
    try {
      const et = await Api.ExportTrace.findByTrackingCode(values.trackingCode, context.project.id);
      setExportTrace(et);
    } catch (e) {
      setExportTrace(null);
      console.error('Error on FindTraceabilityDialog.findExportTrace', { values }, e);
      UiMsg.error(context.msg.t('error.finding.traceability'));
    } finally {
      setLoading(false);
    }
  };

  const formatResourceType = (resourceType) => {
    switch (resourceType) {
      case 'SHARE':
        return context.msg.t('sharing');
      case 'EXPORT':
        return context.msg.t('send.scheduling');
      case 'DOWNLOAD':
        return context.msg.t('downloaded.file');
      default:
        return '';
    }
  };

  const formatServiceType = (serviceType) => {
    switch (serviceType) {
      case 'EMAIL':
        return context.msg.t('email');
      case 'WHATSAPP':
        return context.msg.t('whatsapp');
      case 'PDF_EXPORT':
        return context.msg.t('generated.pdf.file');
      case 'XLS_EXPORT':
        return context.msg.t('generated.xls.file');
      default:
        return '';
    }
  };

  const sendingData = useMemo(() => {
    if (exportTrace) {
      const data = [
        { label: context.msg.t('project'), value: exportTrace?.project.displayName },
        { label: context.msg.t('service'), value: formatResourceType(exportTrace?.resourceType) }
      ];

      if (exportTrace?.serviceType) {
        data.push({ label: context.msg.t('sending.method'), value: formatServiceType(exportTrace?.serviceType) });
      }

      return data;
    }
    return null;
  }, [exportTrace]);

  return (
      <Dialog
          className={`FindTraceabilityDialog ${styles.FindTraceabilityDialog}`}
          loading={loading}
          title={context.msg.t('traceability')}
          onClose={closeModal}
          contentFullWidth
      >
        <Formik
            initialValues={ExportTraceValidationSchema.default()}
            validationSchema={ExportTraceValidationSchema}
            onSubmit={findExportTrace}
            innerRef={formikRef}
        >
          {({ values }) => {
            return (
                <>
                  <div className={styles.dialogDivisionTop} />
                  <div className={styles.findTraceabilitySection}>
                    <Form className={styles.userForm}>
                      <div className={styles.findTraceabilityBodyFooter}>
                        <Dialog.Body>
                          <div className={styles.findTraceabilityImage}>
                            <img
                                src={`${Api.baseUrl()}/resources/images/traceability_find.svg`}
                                alt={context.msg.t('traceability')}
                            />
                          </div>
                          <div className={`${styles.fieldWrapper} ${exportTrace === undefined ? '' : exportTrace ? '' : styles.incorrect}`}>
                            <Field
                                name="trackingCode"
                                label={context.msg.t('tracking.code')}
                                className={styles.trackingCodeField}
                                component={BngField}
                                inputComponent={BngMaskedField}
                                asNumber={true}
                                allowNegative={false}
                                showErrors={false}
                                required
                            >
                              {!loading && (
                                  <Icon
                                      icon={exportTrace === undefined ? '' : exportTrace ? 'check' : 'close'}
                                      className={`${styles.iconFieldWrapper} ${
                                          exportTrace ? styles.correct : styles.incorrect
                                      }`}
                                  />
                              )}
                            </Field>
                          </div>
                        </Dialog.Body>
                        <Dialog.Footer>
                          <DefaultDialogActions
                              okLabel={'trace'}
                              disabled={loading || !values.trackingCode}
                              closeModal={closeModal}
                              saveButtonClassname="save"
                              hideCancelButton
                              contentFullWidth
                          />
                        </Dialog.Footer>
                      </div>
                    </Form>

                    <div className={styles.findTraceabilityResult}>
                      {exportTrace ? (
                          <div className={styles.findTraceabilityDetails}>
                            <div className={styles.gridContainer}>
                              <div className={styles.gridItem}>
                                <div className={styles.senderRectangle} />
                                <TraceabilitySenderInfo
                                    className={`SentBy flex-grow-1 ml-2`}
                                    user={exportTrace.sender}
                                    date={exportTrace.sentDate}
                                    label={context.msg.t(exportTrace.resourceType === 'DOWNLOAD' ? 'generated.by' : 'sent.by')}
                                />

                                <div className={styles.sendingDataWrapper}>
                                  {sendingData.map((item, idx) => (
                                      <div key={idx} className={styles.sendingData}>
                                        <div className={`${styles.blueBall}`} />
                                        {`${item.label}:`}
                                        <b>{`${item.value ? item.value : '-'}`}</b>
                                      </div>
                                  ))}
                                </div>
                              </div>

                              <div className={styles.gridItem}>
                                <TraceabilityDeviceInfo
                                    className={`SendingDevice flex-grow-1 ml-2`}
                                    device={exportTrace.sentDevice}
                                    label={context.msg.t('sending.device')}
                                />
                              </div>

                              <div className={styles.gridItem}>
                                <div className={`${styles.objectList}`}>
                                  <div className={`${styles.objectListTitleWrapper}`}>
                                    <div className={`ObjectListCount ${styles.objectListCount}`}>
                                      {exportTrace?.objects?.length ?? 0}
                                    </div>
                                    <div className={`objectListTitle ${styles.objectListTitle}`}>
                                      {context.msg.t('objects')}
                                    </div>
                                  </div>
                                  <div className={`${styles.listWrapper}`}>
                                    {exportTrace.objects.map((object, idx) => (
                                        <div key={idx} className={`ObjectListItem ${styles.objectListItem}`}>
                                          <Icon
                                              className={`${styles.objectListIcon}`}
                                              icon={Icon.iconIdentifierForPath(object.path) ?? 'insert_drive_file'}
                                          />
                                          <div className={`ObjectListName ${styles.objectListName}`}>{`${context.msg.t(
                                              Utils.Object.getObjectType(object.path)
                                          )}: ${object.caption}`}</div>
                                        </div>
                                    ))}
                                  </div>
                                </div>
                              </div>

                              <div className={styles.gridItem}>
                                <div className={`${styles.receiverList}`}>
                                  <div className={`${styles.receiverListTitleWrapper}`}>
                                    <div className={`ReceiverListCount ${styles.receiverListCount}`}>
                                      {exportTrace?.receivers?.length ?? 0}
                                    </div>
                                    <div className={`ReceiverListTitle ${styles.receiverListTitle}`}>
                                      {context.msg.t('export.wizard.label.recipients')}
                                    </div>
                                  </div>
                                  <div className={`ReceiverList ${styles.receiverWrapper}`}>
                                    {exportTrace.receivers.length > 0 ? (
                                        exportTrace.receivers.map((receiver) => {
                                          const receiverIcon = Utils.Scheduling.iconForMsgType(receiver.messageType);
                                          return (
                                              <div key={receiver.id} className={`ReceiverListItem ${styles.receiverListItem}`}>
                                                <Icon icon="person" className={`${styles.objectListIcon}`} />
                                                {receiverIcon && (
                                                    <Icon icon={receiverIcon} className={`${styles.objectListIcon}`} />
                                                )}
                                                <div
                                                    className={`ReceiverListName ${styles.receiverListName}`}
                                                    title={
                                                      receiver.messageType === Utils.Scheduling.DELIVERY_TYPE.EMAIL
                                                          ? receiver.receiver.email
                                                          : receiver.receiver.phone
                                                    }
                                                >
                                                  <span>{Utils.Users.displayName(receiver.receiver)}</span>
                                                </div>
                                              </div>
                                          );
                                        })
                                    ) : (
                                        <div className={`ReceiverListItem ${styles.receiverListItem}`}>
                                          <Icon icon="person" />
                                          <div className={`ReceiverListName ${styles.receiverListName}`}>
                                            {context.msg.t('no.receivers.found')}
                                          </div>
                                        </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      ) : (
                          <div className={styles.findTraceabilityDetailsEmptyImage}>
                            <img
                                src={`${Api.baseUrl()}/resources/images/traceability_empty.svg`}
                                alt={context.msg.t('traceability')}
                                width="100%"
                                height="auto"
                            />
                            <div className={styles.findTraceabilityDetailsText}>
                              <span className={`material-icons ${styles.findTraceabilityDetailsEmptyIcon}`}>info</span>
                              <span className={styles.findTraceabilityDetailsEmptyText}>
                          {context.msg.t('traceability.type.valid.code')}
                        </span>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </>
            );
          }}
        </Formik>
      </Dialog>
  );
}
