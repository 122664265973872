import styles from './BngLogsDialog.module.css';

import React from 'react';
import Dialog from 'components/ui/Dialog';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BngEmpty from 'components/bng/ui/BngEmpty';
import BngTable from 'components/bng/ui/BngTable';
import useBimContext from 'components/hooks/useBimContext';

export default function BngLogsDialog({
  className = '',
  closeModal = _.noop,
  title = 'Logs',
  loading = false,
  isEmpty = false,
  emptyMessage = '',
  headerButtons = React.ReactNode,
  columns = [],
  rows = [],
}) {
  const context = useBimContext();
  return (
    <Dialog
      className={`BngLogsDialog ${className} ${styles.logsDialog}`}
      onClose={closeModal}
      title={title}
      newDialogLayout
    >
      <UiBlocker block={loading} className={`${styles.dialogInnerWrapper}`}>
        <BngEmpty isEmpty={isEmpty} message={emptyMessage}>
          <div className={`${styles.logsHeader}`}>
            <div className={`${styles.headerButtons}`}>{headerButtons}</div>
          </div>
          <div className={`${styles.logsTableWrapper}`}>
            <BngTable
              showEmptyAlert={true}
              cols={columns}
              rows={rows}
              stickyHeader={true}
              emptyAlertProps={{ message: context.msg.t('empty.filter') }}
            />
          </div>
        </BngEmpty>
      </UiBlocker>
    </Dialog>
  );
}
