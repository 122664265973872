import styles from './DimensionEditDialog.module.css';

import React, { useMemo } from 'react';
import { Field, Formik, useFormikContext } from 'formik';

import Dialog from 'components/ui/Dialog';
import useTranslation from 'components/hooks/useTranslation';
import BngField from 'components/bng/form/BngField';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import HierarchyEditDialog from 'components/ui/in-memory/dialogs/HierarchyEditDialog';
import bngYup from 'components/bng/form/yup/BngYup';
import BngButton from 'components/bng/ui/BngButton';
import BngTable from 'components/bng/ui/BngTable';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import UiMsg from 'components/ui/UiMsg';
import { LevelSelectorDropdown } from 'components/ui/in-memory/dialogs/LevelSelectorDropdown';

const validationSchemaFactory = (nameIsUsed = _.noop) =>
  bngYup((yup) =>
    yup.object({
      name: yup
        .string()
        .test({
          async test(value) {
            const validationError = nameIsUsed(value);
            return validationError ? this.createError({ message: validationError }) : true;
          },
        })
        .required()
        .default(''),
      levels: yup.array(yup.string()).required().default([]),
      hierarchies: yup
        .array(
          yup.object({
            name: yup.string(),
            levels: yup.array(yup.string()),
          })
        )
        .required()
        .default([]),
    })
  );

function HierarchyList({ onEdit = _.noop }) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className={`HierarchyList ${styles.selectedFieldsList}`}>
      <BngTable
        onDropHandler={(result) => {
          if (!result.destination) {
            return;
          }

          const fromIdx = result.source.index;
          const toIdx = result.destination.index;

          if (fromIdx === toIdx) {
            return;
          }

          const copy = values.hierarchies.slice();
          const tmp = copy[fromIdx];
          copy[fromIdx] = copy[toIdx];
          copy[toIdx] = tmp;
          setFieldValue('hierarchies', copy);
        }}
        cols={[
          {
            render: (row, idx, rowProps, dragProps) => (
              <div className={`${styles.draggableIcon}`} {...dragProps.dragHandleProps}>
                <Icon icon="drag_indicator" />
              </div>
            ),
            rowClassName: styles.draggableTd,
          },
          {
            label: t('field'),
            render(row, idx, rowProps, dragProps) {
              return (
                <label className={`${styles.labelStyles}`} {...dragProps.dragHandleProps}>
                  {row.name || t('DEFAULT')}
                </label>
              );
            },
            rowClassName: styles.fieldTd,
          },
          {
            label: t('action'),
            render(row, idx) {
              return (
                <>
                  <BngIconButton
                    icon="edit"
                    onClick={() => {
                      onEdit({ hierarchy: row, idx });
                    }}
                  />

                  <BngIconButton
                    className={`${styles.removeAllFieldsButton}`}
                    icon="delete_forever"
                    onClick={() => {
                      const copy = values.hierarchies.slice();
                      copy.splice(idx, 1);
                      setFieldValue('hierarchies', copy);
                    }}
                  />
                </>
              );
            },
            rowClassName: styles.actionTd,
          },
        ]}
        rows={values.hierarchies}
        stickyHeader
        hideHeader
      />
    </div>
  );
}

export default function DimensionEditDialog({ dimensionName = '', closeModal = _.noop, prefetchedData, onSave }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const { data: { fields = [], dimensions = [] } = {}, isLoading } = useFetchData(async () => {
    try {
      if (prefetchedData) {
        return prefetchedData;
      }

      return await Api.InMemoryBean.findDimensionsAndFields();
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  }, []);

  const dimension = dimensions.find((d) => d.name === dimensionName);

  const validationSchema = useMemo(() => {
    return validationSchemaFactory((value) => {
      if (dimension) {
        if (value === dimension.name) {
          return;
        }
      }

      if (dimensions.find((d) => d.name === value) && value !== dimensionName) {
        return t('dimension.name.in.use');
      }

      if (fields.find((fieldOpt) => fieldOpt.value.caption === value)) {
        return t('dimension.name.in.use.by.data.origin');
      }
    });
  }, [dimension, fields]);

  const initialValues = useMemo(() => {
    const values = validationSchema.default();

    if (dimension) {
      values.name = dimension.name;
      values.hierarchies = dimension.hierarchies.map((h) => ({
        name: h.name,
        levels: h.levels.map((l) => l.field?.fieldName || l),
      }));
      values.levels = dimension.levels.map((l) => l.field?.fieldName || l);
    }

    return values;
  }, [dimension]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values) => {
        try {
          if (onSave) {
            await onSave({ closeModal, dimension: values });
          } else {
            await Api.InMemoryBean.updateDimension({
              ...values,
              originalDimensionName: dimensionName,
            });
            await Api.updateJsf();
            closeModal();
          }
        } catch (e) {
          if (e.response?.status === 409) {
            const msgKey = e.response.data.message.includes('Name already in use by other dimension')
              ? 'dimension.name.in.use'
              : 'dimension.name.in.use.by.data.origin';
            UiMsg.warn(t(msgKey));
          } else {
            console.error('Error on submit', e);
            UiMsg.ajaxError(null, e);
          }
        }
      }}
    >
      {({ values, setFieldValue, isValid, submitForm, isSubmitting }) => {
        const openHierarchyDialog = (hierarchy, idx) => {
          dispatch(
            MODALS.open(HierarchyEditDialog, {
              availableFields: fields.filter((f) => values.levels.includes(f.value.fieldName)),
              hierarchy,
              hierarchies: values.hierarchies,
              onChange: (value) => {
                const copy = values.hierarchies.slice();
                if (Number.isFinite(idx)) {
                  copy[idx] = value;
                } else {
                  copy.push(value);
                }
                return setFieldValue('hierarchies', copy);
              },
            })
          );
        };

        const formIsValid = !_.isEmpty(values.name?.trim()) && !_.isEmpty(values.hierarchies) && isValid;

        return (
          <Dialog
            title={t('new.dimension')}
            className={`DimensionEditDialog large`}
            onClose={closeModal}
            loading={isLoading || isSubmitting}
          >
            <Dialog.Body>
              <Field name="name" rootClassName={`${styles.dimensionName}`} label={t('name')} component={BngField} />

              <div className={`${styles.wrapper}`}>
                <div className={`${styles.levelWrapper}`}>
                  <LevelSelectorDropdown
                    availableFields={fields}
                    value={values.levels}
                    onChange={(val) => setFieldValue('levels', val)}
                  />

                  <div className={`${styles.selectedFieldsList}`}>
                    {values.levels.map((fieldName, idx) => {
                      const fieldOpt = fields.find((f) => f.value.fieldName === fieldName);
                      return (
                        <div key={idx} className={`${styles.selectedField}`}>
                          {fieldOpt.label}

                          <BngIconButton
                            className={`${styles.removeAllFieldsButton}`}
                            icon="delete_forever"
                            onClick={() => {
                              const copy = values.levels.slice();
                              copy.splice(idx, 1);
                              setFieldValue('levels', copy);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className={`${styles.hierarchyWrapper}`}>
                  <div className={`${styles.hierarchyHeader}`}>
                    {t('hierarchy')}
                    <BngIconButton
                      iconProps={{ className: `${styles.buttonIcon}` }}
                      className={`${styles.button} ${values.levels.length === 0 ? 'disabled' : ''}`}
                      text={t('new.hierarchy')}
                      icon="add"
                      onClick={() => {
                        if (values.levels.length <= 0) {
                          return;
                        }
                        openHierarchyDialog();
                      }}
                    />
                  </div>

                  <HierarchyList onEdit={({ hierarchy, idx }) => openHierarchyDialog(hierarchy, idx)} />
                </div>
              </div>

              <div className={`${styles.dialogButtonsWrapper}`}>
                <BngButton
                  className={`${styles.confirmationButton} ${formIsValid ? '' : 'disabled'}`}
                  onClick={formIsValid ? async () => await submitForm() : undefined}
                >
                  {t('ok')}
                </BngButton>

                <BngButton className={`${styles.cancelButton}`} onClick={closeModal}>
                  {t('cancel')}
                </BngButton>
              </div>
            </Dialog.Body>
          </Dialog>
        );
      }}
    </Formik>
  );
}
