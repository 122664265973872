import styles from './AccTraceabilityTab.module.css';

import React, { useState } from 'react';

import useTranslation from 'components/hooks/useTranslation';
import BngSwitch from 'components/bng/form/BngSwitch';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import AddonType from 'components/bng/accounts/AddonType';
import UiBlocker from 'components/bng/ui/UiBlocker';
import useAsyncEffect from 'components/hooks/useAsyncEffect';

export default function AccTraceabilityTab({ account }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showWatermark, setShowWatermark] = useState(false);

  useAsyncEffect({
    onMount: async () => {
      setLoading(true);
      try {
        const accountAddon = await Api.Account.fetchAddonProps({
          accountId: account.id,
          addon: AddonType.TRACEABILITY.key,
        });
        setShowWatermark(accountAddon?.props?.config?.showWatermark || false);
      } catch (e) {
        console.error('Error on function fetchTraceabilityProps()', e);
        UiMsg.error(t('error.fetching.data', [t('traceability.props')]));
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <UiBlocker block={loading} className={`AccTraceabilityTab ${styles.AccTraceabilityTab}`}>
      <span className={styles.configTitle}>{t('settings')}</span>
      <BngSwitch
        checked={showWatermark}
        title={t('addon.traceability.show.watermark')}
        onChange={async (e) => {
          setLoading(true);
          try {
            const accountAddon = await Api.Account.updateAddonProps({
              accountId: account.id,
              addon: AddonType.TRACEABILITY.key,
              props: {
                config: {
                  showWatermark: e.target.checked,
                },
              },
            });
            setShowWatermark(accountAddon.props.config.showWatermark || false);
          } catch (e) {
            console.error('Error on function toggleWatermark()', e);
            UiMsg.error(t('traceability.props.save.error'));
          } finally {
            setLoading(false);
          }
        }}
      />
    </UiBlocker>
  );
}
