import styles from './BngLogDialog.module.css';

import React from 'react';

import Dialog from 'components/ui/Dialog';

export default function BngLogDialog({
  closeModal = _.noop,
  className = '',
  log = '',
  loading = false,
  title,
  footer,
}) {
  return (
    <Dialog className={`BngLogDialog large ${className}`} title={title} onClose={closeModal} loading={loading}>
      <div className={`${styles.schedulingLogBody}`}>
        <textarea className={`${styles.log}`} value={log} disabled={true} />
      </div>
      {footer}
    </Dialog>
  );
}
