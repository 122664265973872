import styles from './ProjectReplicationDialog.module.css';

import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';

import Api from 'components/Api';
import useTranslation from 'components/hooks/useTranslation';
import WizardDialog from 'components/bng/ui/WizardDialog';
import bngYup from 'components/bng/form/yup/BngYup';
import ProjectStep, { ProjectLanguage, ProjectType } from 'components/bng/project/replicationSteps/ProjectStep';
import PermissionsStep from 'components/bng/project/replicationSteps/PermissionsStep';
import ReplicationStep from 'components/bng/project/replicationSteps/ReplicationStep';
import ConnectionStep from 'components/bng/project/replicationSteps/ConnectionStep';
import UiMsg from 'components/ui/UiMsg';
import useFetchData from 'components/hooks/useFetchData';
import { calculateAccountConsumption } from 'components/ui/accounts/tabs/AccFinanceTab';
import useBimContext from '../../hooks/useBimContext';

const ProjectReplicationSchemaFactory = (t, checkNameInUse) => {
  return bngYup((yup) => {
    return yup.object({
      // ProjectStep
      name: yup
        .string()
        .test({
          async test(value) {
            const resp = await checkNameInUse(value);
            return resp.nameInUse ? this.createError({ message: t(resp.msg) }) : true;
          },
        })
        .required()
        .min(3)
        .max(20)
        .trim()
        .default(''),
      displayName: yup.string().required().trim().default(''),
      url: yup.string().required().trim().default('https://.bimachine.com'),
      description: yup.string().trim().default(''),
      accountId: yup.number().required().integer(0),
      projectType: yup.string().oneOf(ProjectType).default('Demo'),
      availableOnMobile: yup.boolean().default(true),
      objectMobileByDefault: yup.boolean().default(true),
      limitSupport: yup.boolean().default(false),
      language: yup.string().required().oneOf(ProjectLanguage),

      // PermissionsStep
      users: yup
        .array(
          yup.object({
            userId: yup.number(),
            role: yup.string(),
            type: yup.string(),
          })
        )
        .default([]),

      // ReplicationStep
      intelligences: yup
        .array(
          yup.object({
            label: yup.string(),
            selected: yup.boolean(),
          })
        )
        .default([]),
      projectResources: yup
        .array(
          yup.object({
            label: yup.string(),
            selected: yup.boolean(),
          })
        )
        .default([]),

      // ConnectionStep
      connections: yup.array(yup.object()).default([]),
    });
  });
};

const validateProjectName = async (value) => {
  try {
    return await Api.Project.isProjectNameInUse(value);
  } catch (e) {
    console.error('Error on ProjectStep.validateProjectName()', { e });
    UiMsg.ajaxError(null, e);
  }
};

function ProjectReplicationDialog({ project, closeModal }) {
  const { t } = useTranslation();
  const context = useBimContext();

  const $accountOpts = useFetchData(async () => {
    const accounts = await Api.Account.findAvailable();
    return accounts.map((account) => {
      return {
        value: account.id,
        label: `${account.id} - ${account.name}`,
        account,
      };
    });
  });

  const validationSchema = useMemo(() => ProjectReplicationSchemaFactory(t, validateProjectName), []);

  const initialFormValues = useMemo(() => {
    const temp = validationSchema.default();
    temp.language = project.language;
    return temp;
  }, []);

  const save = async (values) => {
    const isAccountEnabledInBilling = await Api.Account.isAccountEnabledInBilling(values.accountId);
    try {
      values = _.cloneDeep(values);
      values.connections = values.connections.map((c) => {
        const copy = {
          ...c,
          id: null,
          type: c.type?.name,
        };
        delete copy.databaseStructure;
        delete copy.databaseStructureUpdatedAt;
        return copy;
      });

      if (isAccountEnabledInBilling && values.projectType === 'Production') {
        let dataOriginsReplication;
        if (values.projectResources.length) {
          dataOriginsReplication = values.projectResources?.find((pr) => pr.label === 'data.origins').selected;
        }

        if (dataOriginsReplication) {
          const accountInfo = await Api.Account.fetchBillingAccountInfo(values?.accountId);
          const metrics = calculateAccountConsumption(accountInfo, project);
          if (metrics.limits.structuresExceeds) {
            UiMsg.error(t('replication.project.metrics.limit.error'));
            return;
          }
        }
      }

      await Api.Project.createNewProject({
        ...values,
        id: undefined,
        templateProjectId: project.id,
        projectReplication: true,
      });

      UiMsg.ok(t('project.replication.redirect.message'));

      const redirectUrl = await Api.Bng.accessProjectUrl(values.name);
      window.location.replace(redirectUrl);
    } catch (e) {
      console.error('Error on ProjectReplicationDialog.save()', { e });
      UiMsg.ajaxError(null, e);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={save}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue }) => {
        return (
          <Form>
            <WizardDialog
              title={`${t('new_project_wizard')} - ${t('replication.of.project')} '${project.name}'`}
              className={styles.WizardDialog}
              closeModal={closeModal}
              loading={isSubmitting}
              dialogProps={{
                newDialogLayout: false,
              }}
              steps={[
                {
                  title: t('project'),
                  render: () => <ProjectStep replication={true} accountOpts={$accountOpts.data} />,
                  disableNext: Object.keys(errors).length > 0,
                },
                {
                  title: t('permissions'),
                  render: () => <PermissionsStep projectId={project.id} accountOpts={$accountOpts.data} />,
                },
                {
                  title: t('replication'),
                  render: () => <ReplicationStep />,
                },
                {
                  title: t('connections'),
                  render: () => (
                    <ConnectionStep
                      projectId={project.id}
                      connections={values.connections}
                      onChange={(connections) => setFieldValue('connections', connections)}
                    />
                  ),
                  onNextStep: async () => {
                    await submitForm();
                  },
                },
              ]}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default ProjectReplicationDialog;
