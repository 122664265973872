import styles from './LabsSuggestions.module.css';

import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';

import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';
import bngYup from 'components/bng/form/yup/BngYup';
import BngField from 'components/bng/form/BngField';
import BngTextArea from 'components/bng/form/BngTextArea';
import UiMsg from 'components/ui/UiMsg';
import { categoriesToOptions, MAX_FILES, MAX_UPLOAD_SIZE } from 'components/ui/support/TicketFormBody';
import BngSelect from 'components/bng/form/BngSelect';
import BngMultipleUpload from 'components/bng/form/BngMultipleUpload';
import BngButton from 'components/bng/ui/BngButton';
import UploadSizePill from 'components/bng/ui/UploadSizePill';
import BngTag from 'components/bng/ui/BngTag';
import UiBlocker from 'components/bng/ui/UiBlocker';
import useAsyncEffect from 'components/hooks/useAsyncEffect';

const LabsSuggestionsSchema = bngYup((yup) => {
  return yup.object().shape({
    title: yup.string().required().default(''),
    type: yup.string().required().default('00e22d60debbcb79e1dc109c379c4a14'),
    priority: yup.number().required().default(1),
    description: yup.string().required().default(''),
  });
});

export default function LabsSuggestions() {
  const context = useBimContext();
  const [types, setTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFilesUpload = (newFiles) => {
    const leftOut = [];
    let filesArr = [...files];
    for (const file of newFiles) {
      if (filesArr.length >= MAX_FILES) {
        UiMsg.warn(context.msg.t('support.message.max.files.warning'));
        break;
      }
      if (([...filesArr, file].reduce((acc, file) => acc + file.size, 0) / MAX_UPLOAD_SIZE) * 100 > 100) {
        leftOut.push(file);
        continue;
      }
      filesArr.push(file);
    }

    if (!_.isEmpty(leftOut)) {
      UiMsg.warn(context.msg.t('support.message.max.size.files.warning'));
    }

    setFiles(filesArr);
  };

  const removeFile = (file) => {
    const filesArr = files.filter((item) => item.name !== file.name);
    setFiles(filesArr);
  };

  const sendTicket = async (values, formikProps) => {
    try {
      setLoading(true);
      const ticket = _.cloneDeep(values);
      ticket.projectId = context.project.name;
      await Api.Support.newTicket(ticket, files);
      UiMsg.ok(context.msg.t('suggestions.send.success'));
      setFiles([]);
      formikProps.resetForm();
    } catch (e) {
      console.error('Error while trying to send ticket', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useAsyncEffect({
    onMount: async () => {
      setLoading(true);
      try {
        const categories = await Api.Support.findCategories(context.project.name);
        const selectedCategories = categories.filter((c) =>
          ['nova funcionalidade', 'novo conector'].includes(c.nome.toLowerCase())
        );
        selectedCategories.forEach((e) => (e.nome = context.msg.t(`tomticket.category.${_.camelCase(e.nome)}`)));
        setTypes(categoriesToOptions(selectedCategories));
      } catch (e) {
        console.error('Error while trying to fetch ticket types', e);
        UiMsg.ajaxError(null, e);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className={`LabsSuggestions ${styles.labsSuggestionWrapper}`}>
      <div className={`${styles.labsSuggestionContainer}`}>
        <div className={`${styles.infoLabel}`}>
          <Icon icon={'info'} />
          <span>{context.msg.t('publiclabs.suggestions.infoLabel')}</span>
        </div>
        <Formik
          validationSchema={LabsSuggestionsSchema}
          initialValues={LabsSuggestionsSchema.default()}
          onSubmit={sendTicket}
        >
          <UiBlocker block={loading}>
            <Form className={`${styles.formWrapper}`}>
              <Field
                name={'title'}
                component={BngField}
                label={context.msg.t('publiclabs.suggestions.suggestionLabel')}
              />
              <Field
                name={'type'}
                component={BngField}
                inputComponent={BngSelect}
                label={context.msg.t('publiclabs.suggestions.typeLabel')}
                options={types}
                emptyOption={false}
              />
              <Field
                name={'description'}
                component={BngField}
                inputComponent={BngTextArea}
                label={context.msg.t('publiclabs.suggestions.descLabel')}
              />
              <div className={`${styles.uploadWrapper}`}>
                <Field
                  component={BngField}
                  inputComponent={BngMultipleUpload}
                  label={context.msg.t('managefiles_uploadpopup_title')}
                  hint={context.msg.t('maximum.upload')}
                  height={60}
                  handleFile={handleFilesUpload}
                />
                <div className={`${styles.pill}`}>
                  <UploadSizePill files={files} uploadLimit={MAX_UPLOAD_SIZE} />
                </div>
                <div className={`${styles.filesTag}`}>
                  {files.map((file, idx) => {
                    return (
                      <BngTag
                        key={idx}
                        icon={`${file.type.includes('image') ? 'image' : 'description'}`}
                        description={file.name}
                        onClose={() => removeFile(file)}
                      />
                    );
                  })}
                </div>
              </div>
              <div className={`${styles.formFooter}`}>
                <BngButton className={`${styles.submitBtn}`} btnType={'submit'}>
                  {context.msg.t('BngSuggestion.submit.suggestion')}
                </BngButton>
              </div>
            </Form>
          </UiBlocker>
        </Formik>
      </div>
    </div>
  );
}
