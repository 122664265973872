import styles from './EnabledPill.module.css';

import React from 'react';
import useBimContext from 'components/hooks/useBimContext';

export default function EnabledPill({ enabled, text, ...props }) {
  const { msg } = useBimContext();

  if(!text) {
    text = msg.t(enabled ? 'active' : 'inactive');
  }
  return (
    <div className={`${styles.EnabledPill} ${enabled ? styles.enabled : styles.disabled}`} {...props}>
      <div className={styles.pillDot}></div>
      {text}
    </div>
  );
}
