import './ObjectExecMonitorDialog.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Formik } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';
import Dialog from 'components/ui/Dialog';
import { useLoadingListener } from 'components/bng/pages/newAnalysis/BngEnableAdvancedModeButton';
import { BngTable } from 'components/bng/ui/BngTable';
import Api from 'components/Api';
import PaginateTable from 'components/ui/common/PaginateTable';
import { Tab, TabSet } from 'components/ui/TabSet';
import usePagination from 'components/hooks/usePagination';
import BngCheckbox, { BngCheckboxPure } from 'components/bng/form/BngCheckbox';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import ObjectExecMonitorDetailsDialog from 'components/bng/objectExecMonitor/ObjectExecMonitorDetailsDialog';
import { BngForm } from 'components/bng/form/BngForm';
import { BngField } from 'components/bng/form/BngField';
import { BngCancelButton, BngPrimaryButton } from 'components/ui/Button';
import UiMsg from 'components/ui/UiMsg';
import { bngYup } from 'components/bng/form/yup/BngYup';

const FormSchema = bngYup((yup) =>
  yup.object({
    enabled: yup.boolean().required().default(false),
    logLongerThanSeconds: yup.number().required().positive().integer(),
    enableBlock: yup.boolean().required().default(false),
    blockLongerThanSeconds: yup.number().positive().integer().required(),
    blockTimeSeconds: yup.number().positive().integer().required(),
  })
);

export const DEFAULT_DATE_FORMAT = 'L LTS';

const ObjectExecMonitorDialog = ({
  open,
  closeModal,
  context: { msg },
  dispatch,
}) => {
  const { loading, listener } = useLoadingListener();
  const $formikRef = useRef();
  const runtimePagination = usePagination();
  const logPagination = usePagination();
  const [search, setSearch] = useState('');

  const fetchConfig = async () => {
    const config = await Api.ObjectExecMonitor.findConfig();
    $formikRef.current.resetForm({
      values: config,
    });
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  useEffect(() => {
    findData();
  }, [logPagination.pagination]);

  const findData = async () => {
    await listener(async () => {
      const data = await Api.ObjectExecMonitor.runningExecutions();
      data.sort((o1, o2) => o1.createdAt.valueOf() - o2.createdAt.valueOf());
      runtimePagination.setPageInfo({
        content: data,
        size: runtimePagination.pagination.size,
        totalElements: runtimePagination.length,
        totalPages: Math.ceil(
          runtimePagination.length / runtimePagination.pagination.size
        ),
      });

      const pi = await Api.ObjectExecMonitor.find(
        search,
        logPagination.pagination.page,
        logPagination.pagination.size
      );

      logPagination.setPageInfo(pi);
    });
  };

  const openDetailsDialog = (row) => {
    dispatch(
      MODALS.open(ObjectExecMonitorDetailsDialog, {
        eventData: row,
      })
    );
  };

  const runtimeCols = useMemo(() => {
    return [
      { label: msg.t('user'), size: '80px', render: (row) => row.userId },
      {
        label: msg.t('object'),
        render: (row) => <small>{row.path}</small>,
      },
      {
        label: msg.t('data.structure'),
        size: '240px',
        render: (row) => row.datasource,
      },
      {
        label: msg.t('start.date'),
        render: (row) => row.createdAt.format(DEFAULT_DATE_FORMAT),
      },
      {
        size: '80px',
        render: (row) => (
          <div>
            <BngIconButton
              icon="visibility"
              title={msg.t('see.details')}
              size="sm"
              onClick={() => openDetailsDialog(row)}
            />
          </div>
        ),
      },
    ];
  }, []);

  const logCols = useMemo(() => {
    return runtimeCols.slice(0, runtimeCols.length - 2).concat([
      {
        label: msg.t('duration'),
        render: (row) => {
          const duration = moment.duration(
            row.createdAt.diff(row.finishedAt || moment())
          );
          return (
            <small
              title={`${row.createdAt.format(DEFAULT_DATE_FORMAT)} - ${
                row.finishedAt?.format(DEFAULT_DATE_FORMAT) ||
                msg.t('in.execution')
              }`}
            >
              {duration.humanize()}
            </small>
          );
        },
      },
      {
        label: msg.t('status'),
        size: '70px',
        colClassName: 'text-center',
        rowClassName: 'text-center',
        render: (row) => {
          let icon = 'done';
          let title = 'OK';

          if (row.error) {
            icon = 'error';
            title = msg.t('error');
          } else if (!row.finishedAt) {
            icon = 'loop';
            title = msg.t('in.execution');
          }
          return <Icon title={title} icon={icon} className="StatusIcon" />;
        },
      },
      {
        label: msg.t('blocked'),
        size: '95px',
        colClassName: 'text-center',
        rowClassName: 'text-center',
        render: (row) => (
          <div className="position-relative">
            <BngCheckboxPure
              title={msg.t('ObjectExecMonitorDialog.toggleBlock')}
              checked={row.block}
              onChange={async (e) => {
                listener(async () => {
                  row.block = e.target.checked;
                  await Api.ObjectExecMonitor.updateEvent(row);
                  logPagination.setPageInfo({ ...logPagination.pageInfo });
                });
              }}
            />
          </div>
        ),
      },
      {
        size: '80px',
        render: (row) => (
          <div>
            <BngIconButton
              icon="visibility"
              title={msg.t('see.details')}
              size="sm"
              onClick={() => openDetailsDialog(row)}
            />
            <BngIconButton
              icon="delete_outline"
              title={msg.t('remove')}
              size="sm"
              onClick={async () => {
                await listener(Api.ObjectExecMonitor.removeEvent(row.id));
                findData();
              }}
            />
          </div>
        ),
      },
    ]);
  }, [logPagination]);

  return (
    <Formik
      initialValues={FormSchema.default()}
      validationSchema={FormSchema}
      innerRef={$formikRef}
      onSubmit={async (values, formikHelpers) => {
        try {
          const conf = await Api.ObjectExecMonitor.updateConfig(values);
          $formikRef.current.resetForm({
            values: conf,
          });
          UiMsg.ok(msg.t('project.settings.save'));
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
        } finally {
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {({ values, isValid, isSubmitting }) => {
        return (
          <Dialog
            contentFullWidth={true}
            className="ObjectExecMonitorDialog xlarge"
            open={open}
            title={msg.t('ObjectExecMonitorDialog.title')}
            loading={loading || isSubmitting}
            onClose={closeModal}
            newDialogLayout
          >
            <Dialog.Body>
              <div className="position-relative">
                <BngIconButton
                  icon="refresh"
                  className="RefreshBtn"
                  title={msg.t('update')}
                  onClick={findData}
                />
                <TabSet internal>
                  <Tab label={msg.t('in.execution')} icon="loop">
                    <PaginateTable
                      handleChangePage={runtimePagination.handlePagination}
                      handleChangeNumberPerPage={
                        runtimePagination.perPageChanged
                      }
                      totalItens={runtimePagination.pageInfo.totalElements}
                      initialPass={runtimePagination.pagination.size}
                      initialActivePage={runtimePagination.pagination.page + 1}
                    >
                      <BngTable
                        rows={runtimePagination.pageInfo.content}
                        cols={runtimeCols}
                        stickyHeader
                      />
                    </PaginateTable>
                  </Tab>
                  <Tab label={msg.t('logs')} icon="article">
                    <PaginateTable
                      handleChangePage={logPagination.handlePagination}
                      handleChangeNumberPerPage={logPagination.perPageChanged}
                      totalItens={logPagination.pageInfo.totalElements}
                      initialPass={logPagination.pagination.size}
                      initialActivePage={logPagination.pagination.page + 1}
                    >
                      <BngTable
                        rows={logPagination.pageInfo.content}
                        cols={logCols}
                        stickyHeader
                      />
                    </PaginateTable>
                  </Tab>
                  <Tab label={msg.t('configuration')} icon="settings">
                    <BngForm>
                      <Field
                        component={BngField}
                        inputComponent={BngCheckbox}
                        name="enabled"
                        withLabel={false}
                        asProps={{
                          label: msg.t('enable'),
                        }}
                      />
                      <Field
                        component={BngField}
                        name="logLongerThanSeconds"
                        label={msg.t(
                          'objectExecMonitorConfig.logLongerThanSeconds'
                        )}
                        disabled={!values.enabled}
                      />
                      <Field
                        component={BngField}
                        inputComponent={BngCheckbox}
                        name="enableBlock"
                        withLabel={false}
                        asProps={{
                          label: msg.t('objectExecMonitorConfig.enableBlock'),
                        }}
                      />
                      <Field
                        component={BngField}
                        name="blockLongerThanSeconds"
                        label={msg.t(
                          'objectExecMonitorConfig.blockLongerThanSeconds'
                        )}
                        disabled={!values.enabled || !values.enableBlock}
                      />
                      <Field
                        component={BngField}
                        name="blockTimeSeconds"
                        label={msg.t(
                          'objectExecMonitorConfig.blockTimeSeconds'
                        )}
                        disabled={!values.enabled || !values.enableBlock}
                      />
                      <div className="text-right">
                        <BngCancelButton onClick={fetchConfig}>
                          {msg.t('dashboard_revert_settings')}
                        </BngCancelButton>
                        <BngPrimaryButton
                          type="submit"
                          className="ml-2"
                          disabled={!isValid}
                        >
                          {msg.t('save')}
                        </BngPrimaryButton>
                      </div>
                    </BngForm>
                  </Tab>
                </TabSet>
              </div>
            </Dialog.Body>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ContextEnhancer(connect()(ObjectExecMonitorDialog));
