import React from 'react';

import image from 'components/bng/pages/newAnalysis/assets/BngAdvancedModeAlert.svg';
import BaseErrorPage from 'components/bng/pages/errors/BaseErrorPage';
import useTranslation from 'components/hooks/useTranslation';

export default function ServerTooBusyErrorPage() {
  const { t } = useTranslation();
  return (
    <BaseErrorPage
      className="ServerTooBusyErrorPage"
      image={image}
      title={t('server.too.busy.title')}
      description={`${t('server.too.busy.message')}.`}
    />
  );
}
