import React, { useMemo } from 'react';
import { Field } from 'formik';

import { BngField } from 'components/bng/form/BngField';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';

export default {
  render({ name, label, context: { msg }, props }) {
    const options = useMemo(
      () => [
        { value: 'COLUMNS', label: msg.t('columns') },
        { value: 'BARS', label: msg.t('BAR_CHART') },
        { value: 'CIRCLE', label: msg.t('chartDonut') },
        { value: 'PIZZA', label: msg.t('chartPizza') },
        { value: 'TREE_MAP', label: msg.t('echarts.tree.map.title') },
      ],
      []
    );

    return (
      <Field
        name={name}
        label={label}
        component={BngField}
        inputComponent={BngSelectSearch}
        options={options}
        rootClassName={'ParamType ChartType'}
        rootProps={{ 'data-test': 'ChartType' }}
        clearable={false}
        disabled={props.disabled || false}
      />
    );
  },
};
