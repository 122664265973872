import { Axios } from 'commonUtils';

const processData = (data) => {
  for (const d of data) {
    if (d.createdAt) d.createdAt = moment(d.createdAt);
    if (d.finishedAt) d.finishedAt = moment(d.finishedAt);
  }
};

class ObjectExecMonitorApi {
  async find(search = '', page = 0, size = 10) {
    const { data } = await Axios.get(`/spr/objectExecMonitor`, {
      params: {
        search,
        page,
        size,
      },
    });
    processData(data.content);
    return data;
  }

  async findDetails(id) {
    const { data } = await Axios.get(`/spr/objectExecMonitor/${id}/details`);
    return data;
  }

  async updateEvent(eventData) {
    await Axios.post(`/spr/objectExecMonitor`, {
      id: eventData.id,
      block: eventData.block,
    });
  }

  async removeEvent(id) {
    await Axios.delete(`/spr/objectExecMonitor/${id}`);
  }

  async runningExecutions() {
    const { data } = await Axios.get(`/spr/objectExecMonitor/executing`);
    processData(data);
    return data;
  }

  async findConfig() {
    const { data } = await Axios.get(`/spr/objectExecMonitor/config`);
    return data;
  }

  async updateConfig(config) {
    const { data } = await Axios.post(`/spr/objectExecMonitor/config`, config);
    return data;
  }
}

export default ObjectExecMonitorApi;
