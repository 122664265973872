import React from 'react';

import MktDefaultTab from 'components/bng/pages/marketplace/tabs/MktDefaultTab';

export const TEMPLATES_GROUP = [
  {
    title: 'tabNav.templates',
    desc: 'templates.desc',
    key: 'templates',
  },
];

export default function MktTemplatesTab({ ...props }) {
  return <MktDefaultTab className={'MktTemplatesTab'} groupsToRender={TEMPLATES_GROUP} {...props} />;
}
