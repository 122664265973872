import styles from './BngInMemoryLogDetails.module.css';
import React, { useMemo } from 'react';

import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import Utils from 'components/Utils';
import BngTable from 'components/bng/ui/BngTable';
import Icon from 'components/ui/common/Icon';

export default function BngInMemoryLogDetails({ closeModal, log }) {
  const { msg } = useBimContext();

  const columns = useMemo(() => {
    return [
      { label: msg.t('date'), size: 100, render: (row) => Utils.Date.formatDateTime(row.date) },
      { label: msg.t('duration'), size: 70, render: (row) => `${(row.duration * 100).toFixed(2)}ms` },
      {
        label: msg.t('checks'),
        size: 420,
        rowClassName: styles.innerTableRow,
        render: (row) => {
          const innerRows = row.replicaChecks;

          const innerColumns = useMemo(() => {
            return [
              { label: msg.t('connection'), size: 80, render: (row) => row.connectionName },
              {
                label: msg.t('is.synced'),
                size: 80,
                render: (row) => {
                  return (
                    <Icon
                      className={`${!row.outOfSync ? styles.IconSuccess : styles.IconError}`}
                      icon={!row.outOfSync ? 'done' : 'close'}
                    />
                  );
                },
              },
              { label: msg.t('duration'), size: 80, render: (row) => `${(row.duration * 100).toFixed(2)}ms` },
              {
                label: msg.t('verified'),
                size: 80,
                render: (row) => {
                  const hasErrors = !!row.exception;
                  return (
                    <Icon
                      className={`${!hasErrors ? styles.IconSuccess : styles.IconError}`}
                      icon={!hasErrors ? 'done' : 'close'}
                    />
                  );
                },
              },
              { label: msg.t('replica.date'), size: 120, render: (row) => Utils.Date.formatDateTime(row.replicaDate) },
            ];
          }, []);

          return <BngTable rows={innerRows} cols={innerColumns} />;
        },
      },
    ];
  }, []);

  return (
    <Dialog
      className={`BngInMemoryLogDetails ${styles.dialogWrapper}`}
      onClose={closeModal}
      title={msg.t('checks')}
      newDialogLayout
    >
      <div className={`${styles.tableWrapper}`}>
        <BngTable
          showEmptyAlert={true}
          emptyAlertProps={{ message: msg.t('no.replication.process'), className: styles.emptyTable }}
          rows={log.props.replicationStats?.attempts ?? []}
          cols={columns}
        />
      </div>
      <hr />
      <table className={`${styles.executionTimesTable}`}>
        <thead>
          <tr>
            <th className={`text-left ${styles.executionTimesTableColumn}`}>Process</th>
            <th className={`text-left ${styles.executionTimesTableColumn}`}>Duration</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(log.props.executionTimes).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}ms</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Dialog>
  );
}
