import styles from './LogPreview.module.css';
import React from 'react';
import useTranslation from 'components/hooks/useTranslation';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Api from 'components/Api';

export default function LogPreview({ messageLog = '', openAdaChat }) {
  const { t } = useTranslation();

  return (
    <div className={`LogPreview ${styles.LogPreviewWrapper}`}>
      <div className={styles.title}>{t('error')}:</div>

      <div className={`${styles.errorDescription} mt-3`}>
        {messageLog.split('\n').map((line, index) => {
          return <div key={index}>{line}</div>;
        })}
        <hr />
      </div>
      {openAdaChat && (
        <div className={`AdaAiButton ${styles.AdaAiButton}`}>
          <img
            className={`AdaAiButtonImg ${styles.AdaAiButtonImg}`}
            src={Api.buildUrl('/resources/images/ada-ai/ada.png')}
            alt="Ada AI"
          />
          <BngIconButton
            className="ada-ai"
            icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI"/>}
            text={t('sql.ada.ia.query.assistant.button')}
            onClick={openAdaChat}
          />
        </div>
      )}
    </div>
  );
}
