import React, {useEffect, useState} from 'react';
import BreadcrumbToolbar from 'components/ui/breadcrumb-toolbar/BreadcrumbToolbar';
import { localStore } from 'components/Storage';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import BimEventBus from "BimEventBus";

export const HideHeaderPageOpts = {
  BREADCRUMBS: 'BREADCRUMBS',
  COCKPIT_TABS: 'COCKPIT_TABS',
};

const APP_BASE_ELEMENT = '.AppPageWrapper';
const HIDEABLE_TABS_HEADER_CLASSNAME = 'cockpit-header-hideable-tabs';
const HIDEABLE_BREADCRUMBS_HEADER_CLASSNAME = 'cockpit-header-hideable-breadcrumb';
const HIDDEN_HEADER_CLASSNAME = 'cockpit-header-hidden';
const COCKPIT_HEADER_EXPANDED_STORAGE_KEY = 'CockpitButtons:HEADER_EXPANDED';
export const ON_TOGGLE_EVENT = COCKPIT_HEADER_EXPANDED_STORAGE_KEY;

const isExpanded = () => {
  if (localStorage.getItem(COCKPIT_HEADER_EXPANDED_STORAGE_KEY) === null) return true
  return localStore.get(COCKPIT_HEADER_EXPANDED_STORAGE_KEY);
};

const checkHeaderExpanded = async (location) => {
  const $appEl = document.querySelector(APP_BASE_ELEMENT);

  const expanded = isExpanded();

  if (location === HideHeaderPageOpts.COCKPIT_TABS) {
    $appEl.classList.add(HIDEABLE_TABS_HEADER_CLASSNAME);
  }
  $appEl.classList.add(HIDEABLE_BREADCRUMBS_HEADER_CLASSNAME);

  const expandedState = !$appEl.classList.contains(HIDDEN_HEADER_CLASSNAME);

  if (expanded !== expandedState) {
    await toggleHeaderExpanded(expandedState);
  }
};

const emitToggleEvent = (expanded) => {
  setTimeout(() => {
    window.requestAnimationFrame(() => {
      BimEventBus.emit(ON_TOGGLE_EVENT, {expanded});
    });
  }, 1200);
}

const toggleHeaderExpanded = async (expanded = null) => {
  const $appEl = document.querySelector(APP_BASE_ELEMENT);
  if (expanded === null) {
    expanded = !$appEl.classList.contains(HIDDEN_HEADER_CLASSNAME);
  }
  const $pageContent = document.querySelector('#page-content');

  if (expanded) {
    $appEl.classList.add(HIDDEN_HEADER_CLASSNAME);

    if ($pageContent) {
      $pageContent.style.cssText += `height: ${$pageContent.clientHeight + 48}px !important`;
    }

  } else {
    $appEl.classList.remove(HIDDEN_HEADER_CLASSNAME);

    if ($pageContent) {
      $pageContent.style.cssText += `height: ${$pageContent.clientHeight - 48}px !important`;
    }

  }

  const newValue = !expanded;
  localStore.put(COCKPIT_HEADER_EXPANDED_STORAGE_KEY, newValue);

  emitToggleEvent(newValue);
  await Api.updateJsf();
};

export default function HideHeaderToolBarItem({ disabled, location, ...props }) {
  const context = useBimContext();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkHeaderExpanded(location);

    return () => {
      const $appEl = document.querySelector(APP_BASE_ELEMENT);

      if (location === HideHeaderPageOpts.COCKPIT_TABS) {
        $appEl.classList.remove(HIDEABLE_TABS_HEADER_CLASSNAME);
      }

      $appEl.classList.remove(HIDEABLE_BREADCRUMBS_HEADER_CLASSNAME);
    };
  }, []);

  const clickHandler = async () => {
    setLoading(true);
    try {
      await toggleHeaderExpanded();
    } finally {
      setTimeout(() => setLoading(false), 2500);
    }
  };

  return (
    <BreadcrumbToolbar.Item
      icon={isExpanded() ? 'open_in_full' : 'close_fullscreen'}
      title={context.msg.t('hide.header')}
      disabled={disabled || loading}
      onClick={clickHandler}
      className={location === HideHeaderPageOpts.COCKPIT_TABS ? 'CockpitHideHeaderButton' : ''}
      {...props}
    />
  );
}
