import styles from 'components/ui/profile/ChangePhoneDialog.module.css';
import ChangePhoneDialogBanner from 'components/ui/profile/ChangePhoneDialogBanner.svg';

import React, { useEffect, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js/max';

import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import useBimContext from 'components/hooks/useBimContext';
import BngPhoneInput from 'components/bng/form/BngPhoneInput';
import UiMsg from 'components/ui/UiMsg';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { BngForm } from 'components/bng/form/BngForm';
import Utils from 'components/Utils';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngField } from 'components/bng/form/BngField';
import useBimNavigate from 'components/hooks/useBimNavigate';

const PhoneSchema = bngYup((yup) => {
  return yup.object().shape({
    allowMobileMessaging: yup.bool().required().default(true),
    phone: yup.string().nullable().required().default(''),
  });
});

export default function ChangePhonePage() {
  const { msg } = useBimContext();
  const navigate = useBimNavigate();

  const changePhoneRequestKey = useMemo(() => Utils.History.currentUrlSearchParams().get('CHANGE_PHONE_REQUEST'), []);

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const user = await Api.User.publicProfile(changePhoneRequestKey);
        setUser(user);
        setLoading(false);
      } catch (e) {
        console.error('Error while trying to fetch user param data', e);
        navigate('/errors/403');
      }
    })();
  }, []);

  const formInitialValues = useMemo(
    () => ({
      phone: user?.phone ?? '',
      allowMobileMessaging: user?.allowMobileMessaging ?? true,
    }),
    [user]
  );

  const updatePhoneSettings = async (values, actions) => {
    try {
      if ((values.allowMobileMessaging && !values.phone) || (values.phone && !isValidPhoneNumber(values.phone))) {
        UiMsg.error(msg.t('user.invalid.number'));
        return;
      }

      await Api.User.updateUserMessaging({
        changePhoneRequestKey,
        userId: user.id,
        phone: values.phone,
        allowMobileMessaging: values.allowMobileMessaging,
      });
      UiMsg.ok(msg.t('user.dialog.update.success'));
    } catch (e) {
      console.error('Error on changePhone()', { values }, e);
      actions.setSubmitting(false);
      UiMsg.ajaxError(null, e);
    }
  };

  return (
    <Formik initialValues={formInitialValues} validationSchema={PhoneSchema} onSubmit={updatePhoneSettings}>
      {(formikProps) => {
        return (
          <UiBlocker block={loading}>
            <Dialog
              title={msg.t('update.registered.data')}
              className={'ChangePhoneDialog lg'}
              newDialogLayout
              draggable={false}
              hideClose={true}
            >
              <BngForm>
                <Dialog.Body>
                  <div className="d-flex">
                    <div className={styles.banner}>
                      <img src={ChangePhoneDialogBanner} alt="Change phone number" />
                    </div>
                    <div className="pt-6">
                      <span className={styles.innerTitle}>{msg.t('update.phone.number')}</span>
                      <p className={styles.innerDescription}>{msg.t('whatsapp.messaging.enabled.message')}</p>
                      <Field
                        name="displayName"
                        value={user?.displayName ?? ''}
                        label={msg.t('name')}
                        component={BngField}
                        rootClassName={styles.field}
                        disabled
                      />
                      <Field
                        name="phone"
                        label={msg.t('phone')}
                        component={BngField}
                        inputComponent={BngPhoneInput}
                        rootClassName={styles.field}
                        required
                      />
                      <Field
                        name="allowMobileMessaging"
                        component={BngCheckbox}
                        label={msg.t('whatsapp.messaging.enabled.consent')}
                        required
                      />
                    </div>
                  </div>
                </Dialog.Body>
                <Dialog.Footer>
                  <DefaultDialogActions hideCancelButton={true} />
                </Dialog.Footer>
              </BngForm>
            </Dialog>
          </UiBlocker>
        );
      }}
    </Formik>
  );
}
