import './ApplicationKeyDialog.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, Form, Formik } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';
import { BngField } from 'components/bng/form/BngField';
import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BngSwitch from 'components/bng/form/BngSwitch';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { FormikListener } from 'components/bng/form/formik/FormikListener';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngAddButton } from 'components/bng/ui/BngAddButton';
import { BngInput } from 'components/bng/form/BngInput';

const ApplicationKeySchema = bngYup((yup) =>
  yup.object().shape({
    name: yup.string().required().default(''),
    token: yup.string().required().default(''),
    allowedDomains: yup.array().of(
      yup.object().shape({
        id: yup.number().default(0),
        domain: yup.string().required().default(''),
      })
    ),
    blockByDomain: yup.bool().default(false),
  })
);

class ApplicationKeysTab extends Component {
  static propTypes = {
    accountId: PropTypes.number,
    applicationKey: PropTypes.object,
    forceBlock: PropTypes.bool,
    updateItem: PropTypes.func,
  };

  static defaultProps = {
    applicationKey: {},
    forceBlock: false,
  };

  state = {
    loading: true,
    addDomains: false,
  };

  applicationKeyInitialValues = ApplicationKeySchema.default();

  domainsColumns = [
    { label: this.props.context.msg.t('domain'), render: (row, index) => this.renderDomainField(row, index) },
    { label: '', render: (row, index, rowProps) => this.renderActions(row, index, rowProps) },
  ];

  async componentDidMount() {
    let { applicationKey, forceBlock } = this.props;
    let blocked = forceBlock;
    if (applicationKey) {
      applicationKey = _.cloneDeep(applicationKey);
      this.applicationKeyInitialValues.name = applicationKey.name;
      this.applicationKeyInitialValues.token = applicationKey.token;

      blocked = blocked ? true : applicationKey.blockByDomain;
      this.applicationKeyInitialValues.blockByDomain = blocked;
      this.applicationKeyInitialValues.allowedDomains = applicationKey.allowedDomains;
    } else {
      this.applicationKeyInitialValues.allowedDomains = [];
      this.applicationKeyInitialValues.token = await this.generateToken();
    }

    this.setState({ loading: false, addDomains: blocked });
  }

  updateToken = async (form) => {
    this.setState({ loading: true });
    const token = await this.generateToken(form);
    form.setFieldValue('token', token);
    this.setState({ loading: false });
  };

  generateToken = async () => {
    return await Api.Account.generateToken();
  };

  renderDomainField = (row, index) => {
    return (
      <Field
        id={`allowedDomains.${index}.domain`}
        name={`allowedDomains.${index}.domain`}
        required={true}
        component={BngInput}
      />
    );
  };

  renderActions = (row, index, rowProps) => {
    return (
      <div className="remove-domain-button" onClick={() => rowProps.remove(index)}>
        {this.props.context.msg.t('delete')}
      </div>
    );
  };

  save = async (values, actions) => {
    this.setState({ loading: true });
    try {
      if (_.isArray(values.allowedDomains)) {
        const domains = values.allowedDomains.map((allowedDomain) => allowedDomain.domain);
        const uniqueDomains = new Set(domains);
        if (uniqueDomains.size !== domains.length) {
          UiMsg.warn(this.props.context.msg.t('application.key.domain.error')); //TODO
          return;
        }
      }

      values = _.cloneDeep(values);
      values.id = this.props.applicationKey ? this.props.applicationKey.id : null;

      const applicationKeys = await Api.Account.findApplicationKeys(this.props.accountId);
      if (values.name) {
        const nameExists = applicationKeys.some((key) => key.name.toLowerCase() === values.name.toLowerCase() && key.id !== values.id);
        if (nameExists) {
          UiMsg.warn(this.props.context.msg.t('application.key.name.duplicate'),'');
          return;
        }
      }

      const result = await Api.Account.saveApplicationKey(this.props.accountId, values);
      UiMsg.ok(
        this.props.context.msg.t('success'),
        this.props.context.msg.t(`application.key.${values.id ? 'update' : 'create'}.success`)
      );
      if (_.isFunction(this.props.updateItem)) {
        this.props.updateItem(result);
      }
      this.props.closeModal();
    } catch (e) {
      console.error('Error on ApplicationKeyDialog.save()', { values }, e);
      actions.setSubmitting(false);
      UiMsg.error(this.props.context.msg.t('application.key.create.error'));
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Dialog
        title={this.props.context.msg.t('application.key')}
        className={`ApplicationKeyDialog`}
        onClose={this.props.closeModal}
        loading={this.state.loading}
        contentFullWidth={true}
      >
        <Formik
          initialValues={this.applicationKeyInitialValues}
          validationSchema={ApplicationKeySchema}
          onSubmit={this.save}
        >
          {({ values }) => (
            <Form>
              <FormikListener
                onChange={(next, current) => {
                  if (next.values.blockByDomain !== current.values.blockByDomain) {
                    this.setState({ addDomains: next.values.blockByDomain });
                  }
                }}
              />

              <div className="application-key-dialog-body">
                <Field
                  name="name"
                  component={BngField}
                  value={values.name}
                  required={true}
                  label={this.props.context.msg.t('name')}
                />
                <div className="token-row">
                  <Field
                    name="token"
                    component={BngField}
                    value={values.token}
                    disabled={true}
                    required={true}
                    label={this.props.context.msg.t('token')}
                  />
                  <Field
                    name="handleTokenGeneration"
                    render={({ field, form }) => (
                      <div className="generate-token-button" onClick={() => this.updateToken(form)}>
                        {this.props.context.msg.t('generate.token')}
                      </div>
                    )}
                  />
                </div>
                <FieldArray
                  name="allowedDomains"
                  render={(arrayHelpers) => (
                    <div>
                      <div className="block-by-domain-row">
                        <Field
                          name="blockByDomain"
                          id="blockByDomain"
                          value={values.blockByDomain}
                          title={this.props.context.msg.t('block.by.domain')}
                          component={BngSwitch}
                        />
                        {this.state.addDomains && (
                          <BngAddButton onClick={() => arrayHelpers.push({ domain: '' })}>
                            <span>{this.props.context.msg.t('add')}</span>
                          </BngAddButton>
                        )}
                      </div>
                      {this.state.addDomains && (
                        <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                          <BngTable cols={this.domainsColumns} rows={values.allowedDomains} rowProps={arrayHelpers} />
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>

              <DefaultDialogActions contentFullWidth={true} {...this.props} />
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}

export default ContextEnhancer(ApplicationKeysTab);
