import './BngMobileDrawerMultiStepInfo.css'
import React from 'react';

import BngMultiStep from "components/bng/ui/BngMultiStep";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";

import slide1image from "./assets/slide1animated.svg";
import slide2image from "./assets/slide2image.svg";
import slide3image from "./assets/slide3image.svg";
import slide4image from "./assets/slide4image.svg";

const BngMobileDrawerMultiStepInfo = ({
    projectId,
    hasWatched = false,
    context,
    onFinish = _.noop,
    prepend = null
}) => {

    const handleNextClick = async ({ isLastStep }) => {
        if (isLastStep) {
            try {
                if (!hasWatched) {
                    await Api.UserParam.saveUserKey({
                        key: Api.UserParam.Keys.Analysis.WatchedMobileDrawer,
                        value: 'true',
                        projectId
                    })
                }
                onFinish();
            } catch (e) {
                console.error(e)
            }
        }
    }

    return (
        <BngMultiStep className="BngMobileDrawerMultiStepInfo"
            prepend={prepend}
            onNextClick={handleNextClick}
            overlay={false}>
            <BngMultiStep.Step>
                <img className="Image"
                    src={slide1image}
                    alt={context.msg.t('BngMobileDrawerMultiStepInfo.slide1.title')} />
                <BngMultiStep.Title>
                    {context.msg.t('BngMobileDrawerMultiStepInfo.slide1.title')}
                </BngMultiStep.Title>
                <BngMultiStep.Subtitle>
                    {context.msg.t('BngMobileDrawerMultiStepInfo.slide1.subtitle')}
                </BngMultiStep.Subtitle>
            </BngMultiStep.Step>
            <BngMultiStep.Step>
                <img className="Image"
                    src={slide2image}
                    alt={context.msg.t('BngMobileDrawerMultiStepInfo.slide2.title')} />
                <BngMultiStep.Title>
                    {context.msg.t('BngMobileDrawerMultiStepInfo.slide2.title')}
                </BngMultiStep.Title>
                <BngMultiStep.Subtitle>
                    {context.msg.t('BngMobileDrawerMultiStepInfo.slide2.subtitle')}
                </BngMultiStep.Subtitle>
            </BngMultiStep.Step>
            <BngMultiStep.Step>
                <img className="Image"
                    src={slide3image}
                    alt={context.msg.t('BngMobileDrawerMultiStepInfo.slide3.title')} />
                <BngMultiStep.Title>
                    {context.msg.t('BngMobileDrawerMultiStepInfo.slide3.title')}
                </BngMultiStep.Title>
                <BngMultiStep.Subtitle>
                    {context.msg.t('BngMobileDrawerMultiStepInfo.slide3.subtitle')}
                </BngMultiStep.Subtitle>
            </BngMultiStep.Step>
            <BngMultiStep.Step>
                <img className="Image PurpleBackground"
                    src={slide4image}
                    alt={context.msg.t('BngMobileDrawerMultiStepInfo.slide4.title')} />
                <BngMultiStep.Title>
                    {context.msg.t('BngMobileDrawerMultiStepInfo.slide4.title')}
                </BngMultiStep.Title>
                <BngMultiStep.Subtitle>
                    {`${context.msg.t('BngMobileDrawerMultiStepInfo.slide4.subtitle')} `}
                    <a href={context.msg.t('documentation.mobile.drawer')} rel="noreferrer" target="_blank">
                        {context.msg.t('BngMobileDrawerMultiStepInfo.help.link.description')}
                    </a>
                </BngMultiStep.Subtitle>
            </BngMultiStep.Step>
        </BngMultiStep>
    );
};

export default ContextEnhancer(BngMobileDrawerMultiStepInfo);
