import styles from './LabsTrial.module.css';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import useBimContext from 'components/hooks/useBimContext';
import FeatureOption from 'components/ui/labs/trial/FeatureOption';
import Api from 'components/Api';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import TicketForm from 'components/ui/support/TicketForm';
import BngAda from 'components/bng/ui/BngAda/BngAda';
import GiveFeedbackLabel from 'components/ui/labs/trial/GiveFeedbackLabel';
import UiMsg from 'components/ui/UiMsg';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Utils from 'components/Utils';

const PUBLIC_LABS_ADA_KEY = 'SEEN_ADA_IN_PUBLIC_LABS';

export default function LabsTrial() {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [unlockedFeatures, setUnlockedFeatures] = useState([]);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFeatures, setLoadingFeatures] = useState(new Set());
  const [showAda, setShowAda] = useState(false);

  const fetchUnlockedFeatures = async () => {
    try {
      const data = await Api.Feature.findUserUnlockedFeatures();
      setUnlockedFeatures(data);
    } catch (e) {
      console.error('Error on fetchUnlockedFeatures()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const fetchFeatures = async () => {
    try {
      setLoading(true);
      const features = await Api.Feature.findAvailableFeatures();
      const list = features
        .filter((e) => {
          if (!e.isPublic) {
            return false;
          }

          return true;
        })
        .sort(
          (a, b) =>
            Number(b.isRelease) - Number(a.isRelease) ||
            a.roleWeight - b.roleWeight ||
            Utils.Strings.compareIgnoreCase(a.category.value, b.category.value)
        );

      setFeatures(list);
    } catch (e) {
      console.error('Error on fetchFeatures()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (feature, isEnable) => {
    try {
      setLoadingFeatures((prev) => new Set([...prev, feature.value]));
      const now = Date.now();
      if (isEnable) {
        await Api.Feature.lockForSelectedUser(feature.value, context.user.id, true);
      } else {
        await Api.Feature.unlockForSelectedUser(feature.value, context.user.id, true);
      }
      const minimalWaitingTime = Math.max(0, -(Date.now() - now) + 300);
      await new Promise((resolve) => setTimeout(resolve, minimalWaitingTime));
    } catch (e) {
      console.error('Error on handleChange()', { feature, isEnable }, e);
      UiMsg.ajaxError(null, e);
    } finally {
      await fetchUnlockedFeatures();
      setLoadingFeatures((prev) => {
        const updated = new Set(prev);
        updated.delete(feature.value);
        return updated;
      });
    }
  };

  const triggerUserAccessedPublicLabs = async () => {
    try {
      await Api.Feature.userAccessedPublicLabs();
    } catch (e) {
      console.error('Error on triggerUserAccessedPublicLabs()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const shouldShowAda = async () => {
    try {
      const result = await Api.UserParam.findKey(PUBLIC_LABS_ADA_KEY);
      if (!result) {
        setShowAda(true);
      }
    } catch (e) {
      console.error('Error on shouldShowAda()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const handleAdaButtonClick = async () => {
    try {
      await Api.UserParam.saveUserKey({
        key: PUBLIC_LABS_ADA_KEY,
        value: true,
        projectId: context.project.id,
      });
      setShowAda(false);
    } catch (e) {
      console.error('Error on handleAdaButtonClick()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  useEffect(() => {
    shouldShowAda();
    triggerUserAccessedPublicLabs();
    fetchUnlockedFeatures();
    fetchFeatures();
  }, []);

  return (
    <UiBlocker block={loading}>
      <div className={`${styles.labsWrapper}`}>
        <div className={`${styles.labsFeaturesWrapper}`}>
          <div className={`${styles.labsHeaderBetaFrag}`}>
            <span className={`${styles.labsHeaderBetaSpan}`}>{context.msg.t('publiclabs.header.betamsg')}</span>
            <div className={`${styles.labsHeaderBetaFlag}`}>Beta</div>
            <span className={`${styles.labsHeaderBetaSpan}`}>{context.msg.t('publiclabs.header.betamsglast')}</span>
          </div>
          {features.map((feature) => {
            const enabled = unlockedFeatures.find((e) => e.feature === feature.value);
            return (
            <FeatureOption
              key={`feature_${feature.value}`}
              feature={feature}
              enabled={enabled}
              handleChange={() => handleChange(feature, enabled)}
              loading={loadingFeatures.has(feature.value)}
            />
            );
          })}
          <div className={`${styles.featuresFeedbackWarningContainer}`}>
            <span className={`${styles.featuresFeedbackWarningTitle}`}>
              {context.msg.t('publiclabs.feedback.title')}
            </span>
            <span className={`${styles.featuresFeedbackWarningDesc}`}>{context.msg.t('publiclabs.feedback.desc')}</span>
            <GiveFeedbackLabel
              className={styles.feedbackLink}
              onClick={() =>
                dispatch(
                  MODALS.open(TicketForm, {
                    type: '00e22d60debbcb79e1dc109c379c4a14',
                    priority: 1,
                  })
                )
              }
            />
          </div>
        </div>
      </div>
      {showAda &&
        ReactDOM.createPortal(
          <BngAda
            className={`${styles.adaPublicLabs}`}
            title={context.msg.t('publiclabs.ada.title')}
            description={context.msg.t('publiclabs.ada.description')}
            buttonLabel={context.msg.t('BigTableAnalysisHelp.adaButtonLabel')}
            onClick={handleAdaButtonClick}
          />,
          document.body
        )}
    </UiBlocker>
  );
}
