import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";

class FilterWrapper {

    constructor(data) {
        data.selected = false;
        Object.assign(this, data);
    }

    isMultiple() {
        return 'MULTIPLE_SELECTION' === this.filterType;
    }

    isSingle() {
        return 'SINGLE_SELECTION' === this.filterType;
    }

    isRange() {
        return 'RANGE_SELECTION' === this.filterType;
    }

}

class FilterService {

    findOne(id) {
        return jQuery.getJSON(`/spr/filters/${id}`);
    };

    async findMembers({ id, filters = [], dashboardPath, dashChanges = [] }) {
        if (_.isString(filters)) {
            filters = JSON.parse(filters);
        }

        filters = (filters ?? [])
            .map(filter => ({
                id: filter.id,
                members: (filter.selectedMembers ?? [])
                    .map(member => _.isString(member) ? member : member.value),
                restrictionType: filter.restrictionType
            }))
            .filter(filter => filter.members.length > 0);

        const reqBody = {
            dashboard: dashboardPath
                || new URLSearchParams(window.location.search).get('content')
                || window.___LAST_DASHBOARD_OPTS?.dashboardPath,
            filters,
            dashChanges
        };

        return jQuery.postJSON(`/spr/filters/${id}/members`, reqBody);
    };

    transform(filterDataArray) {
        return filterDataArray.map((f) => new FilterWrapper(f));
    };

    createFilterParam = (filters = [], asObject = false) => {
        if (!_.isString(filters)) {
          if (!_.isEmpty(filters) && filters[0].hasOwnProperty('selectedMembers')) {
            filters = filters.map((f) => ({
              id: f.id,
              members: f.selectedMembers.map((sm) => sm.value),
              runtimeRestriction: f.runtimeRestriction ?? [],
            }));
          }
        }

        let filter;

        if (asObject) {
          filter = _.isString(filters) ? JSON.parse(filters) : filters;
        } else {
          filter = _.isString(filters) ? filters : JSON.stringify(filters);
        }

        return {
          filter,
        };
    };

    addFilterToParams(params, filters) {
        if (!!filters) {
            addPlainFilter(params, filters, false);
        }
        if (!params.basicAuthQp && window.__AUTHORIZATION && window.__AUTHORIZATION.length > 0) {
            params.basicAuthQp = window.__AUTHORIZATION;
        }
    }

    async createFilterRef(filters) {
        if (_.isEmpty(filters)) return undefined;

        const {filter} = this.createFilterParam(filters)
        const {key} = await Api.TemporaryStorage.put(filter)
        return key;
    }
}

const addPlainFilter = function (params, filters, encode = true) {
    delete params['plain-filter'];
    let plainFilterContent = filters.map((filter) => {
        return {
            id: filter.id,
            members: filter.selectedMembers.map(m => m.value),
            restrictionType: filter.restrictionType ?? 'SHOW_SELECTED'
        };
    });
    if (plainFilterContent.length > 0) {
        plainFilterContent = JSON.stringify(plainFilterContent);
        params['plain-filter'] = encode ? encodeURIComponent(plainFilterContent) : plainFilterContent;
    }
};

const instance = new FilterService();

export default instance;
