import { Axios } from 'commonUtils';

class ExportTraceApi {
  findByTrackingCode = async (code, projectId) => {
    const { data } = await Axios.get(`/spr/exportTraces/code`, {
      params: {
        code,
        projectId,
      },
    });
    return data;
  };
}

export default ExportTraceApi;