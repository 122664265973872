import React from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import ComponentFactory from "components/ComponentFactory";

class DashboardItemMenuComments extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {};

    forceClose = () => {
        const $old = j('.NoteViewParent');
        if ($old.length > 0) {
            ReactDOM.unmountComponentAtNode($old[0]);
            $old.remove();
        }
    };

    openNotes = (event) => {
        ComponentFactory.Notes.openNotes({
            anchorEl: event.target,
            path: this.props.item.path,
            popperOpts: {placement: 'right'},
            popperFlipBehaviour: ['right', 'left'],
            boundaryElement: j('.page-content')[0],
        });
    };
    
    render() {
        return (
            <li className="bt-item-new-menu-inside">
                <DashboardItemMenuTitle title={this.props.context.msg.t('notes')}
                                        icon="forum"
                                        onClick={this.openNotes}/>
            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuComments);