import React from "react";


class MessageComponents {
    static ModalInformation = ({
                                   text = '',
                                   buttonText = 'Ok',
                                   onConfirmFunction = _.noop,
                                   successMessage = '',
                                   successTitle = '',
                                   errorMessage = '',
                                   errorTitle = '',
                                   executeFunction = false
                               }) => {
        Swal.fire({
            confirmButtonText: buttonText,
            confirmButtonColor: '#005dff',
            html: text,
            preConfirm: async () => {
                if (!executeFunction) {
                    return;
                }
                const status = await onConfirmFunction();
                Swal.fire({
                    title: status ? successMessage : errorTitle,
                    text: status ? successTitle : errorMessage,
                    icon: status ? 'success' : 'error',
                    confirmButtonColor: '#005dff'
                })
            }
        })
    };

    static ShowMessage = ({text = '', title = '', icon = '', ...additionalOpts}) => {
        Swal.fire({
            title: title,
            icon: icon,
            html: text,
            ...additionalOpts,
        })
    };
}


export default MessageComponents;