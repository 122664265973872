import React, { useState, useEffect } from "react";

import './BngAssistedAnalysisHelp.css';
import BngAda from "components/bng/ui/BngAda/BngAda";

import ContextEnhancer from "components/ContextEnhancer";
import BngVideoModal from "components/bng/ui/BngVideoModal";
import Api from "components/Api";

export const BngAssistedAnalysisHelp = ContextEnhancer(({
    context,
    showIntro = false,
    onFinishIntro = _.noop,
    editing = false,
    ...props
}) => {
    const projectId = context.findProjectIdFromContext();

    const [showModal, setShowModal] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [options, setOptions] = useState([]);
    const [adaButtonPressed, setAdaButtonPressed] = useState(false);
    const [showTitle, setShowTitle] = useState(true);
    const [showHelpText, setShowHelpText] = useState(false);
    const [cardsHelpViewed, setCardsHelpViewed] = useState(true);
    const [popUpAda, setPopUpAda] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const generateOptions = (viewed) => {
        const defaultOpts = [];
        if (props.documentationUrl) {
            defaultOpts.push({
                icon: 'help_outline',
                label: context.msg.t('new.analysis.help.documentation'),
                onClick: () => window.open(context.msg.t(props.documentationUrl), "_blank")
            })
        }
        if (!editing && props.helpVideoUrl) {
            defaultOpts.push({
                icon: 'play_circle_outline',
                label: context.msg.t('analysis.type.help.video'),
                onClick: () => {
                    setShowModal(true)
                }
            })
        }
        if (editing) {
            defaultOpts.push({
                icon: 'close',
                label: context.msg.t('close'),
                onClick: () => setShowHelp(false)
            });
        } else {
            if (!viewed) {
                defaultOpts.push({
                    icon: 'done',
                    label: context.msg.t('ok.understood'),
                    onClick: () => {
                        Api.UserParam.saveUserKey({
                            key: Api.UserParam.Keys.Analysis.CardsHelpViewed,
                            value: true,
                            projectId: projectId
                        });
                    }
                });
            }
        }
        return defaultOpts;
    }

    useEffect(() => {
        (async function () {
            const key = await Api.UserParam.findKey(Api.UserParam.Keys.Analysis.CardsHelpViewed);
            let viewed = !(!key || key.value === 'false');
            setCardsHelpViewed(viewed);
            setOptions(generateOptions(viewed));

            if (!editing) {
                setShowHelp(true);
                if (!viewed) {
                    let icon = j('.BngAssistedAnalysisHelp .HelpIcon')[0];
                    icon?.click();
                }
            } else {
                setTimeout(() => {
                    setShowHelp(true);
                    setAdaButtonPressed(true);
                    setShowTitle(false);
                    setShowHelpText(true);
                    setPopUpAda(true);
                    setOptions([
                        {
                            icon: 'help_outline',
                            label: context.msg.t('new.analysis.help.documentation'),
                            onClick: () => window.open(context.msg.t(props.documentationUrl), "_blank")
                        },
                        {
                            icon: 'save',
                            label: context.msg.t('assisted.analysis.help.save.now'),
                            onClick: () => {
                                setOptions(generateOptions(cardsHelpViewed));
                                let saveButton = j('.SaveMenuItem')[0];
                                saveButton.click();
                                setShowTitle(true);
                                setShowHelpText(false);
                                setAdaButtonPressed(false);
                            }
                        },
                        {
                            icon: 'close',
                            label: context.msg.t('close'),
                            onClick: () => setShowHelp(false)
                        }
                    ]);
                    let icon = j('.BngAssistedAnalysisHelp .HelpIcon')[0];
                    icon.click();

                }, 1000 * 60 * 2);
            }
        })();
    }, []);

    useEffect(() => {
        setOptions(generateOptions(cardsHelpViewed));
    }, [props.documentationUrl])

    useEffect(() => {
        setShowInfo((showHelp && !showModal) ? true : false);
    }, [showHelp, showModal])

    const adaClassName = () => {
        if (popUpAda) return 'PopUpAdaVisible';
        return (adaButtonPressed ? 'AdaVisible' : 'AdaHidden');
    }

    if (!showHelp) {
        return null;
    }

    return (
        <>
            <BngAda
                className={`AssistedAnalysisHelp ${adaClassName()}`}
                title={showTitle && context.msg.t('new.analysis.help.needHelp')}
                description={showHelpText && context.msg.t('assisted.analysis.not.saved.help', [context.user.displayName])}
                options={options}
                onClick={() => {
                    setAdaButtonPressed(!adaButtonPressed);
                }}
                buttonPressed={adaButtonPressed}
                showInfo={showInfo}
            />
            {showModal &&
                <BngVideoModal
                    className={"AssistedAnalysisHelpVideoModal"}
                    classNameOverlay={"AssistedAnalysisHelpVideoModalOverlay"}
                    context={context}
                    videoUrl={props.helpVideoUrl}
                    title={props.title}
                    explanation={props.explanation}
                    onClose={() => {
                        setShowModal(false), setAdaButtonPressed(!adaButtonPressed)
                    }}
                />
            }
        </>
    )
});