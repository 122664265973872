import styles from './BimStorePage.module.css';
import React, { useState } from 'react';
import UiBlocker from 'components/bng/ui/UiBlocker';
import useBimContext from 'components/hooks/useBimContext';
import { Axios } from 'commonUtils';

export default function BimStorePage({ template }) {
  const { user, store } = useBimContext();
  const [isLoaded, setIsLoaded] = useState(false);

  const storeUrl = Axios.getUri({
    url: template ? `${store.url}/${template}` : store.url,
    params: {
      user_id: user.id,
    },
  });


  return (
    <React.Fragment>
      <iframe className={styles.store} src={storeUrl} onLoad={() => setIsLoaded(true)} />
      {!isLoaded && (
        <UiBlocker className={styles.loading} block={true}>
          <div />
        </UiBlocker>
      )}
    </React.Fragment>
  );
}
