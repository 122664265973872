import React from 'react';
import PropTypes from 'prop-types';
import { ceData } from 'components/CeData';

const possibleChildProps = ['selectItems', 'children', 'child', 'options'];

export const findChildProp = (options) => {
  for (const opt of options) {
    const childProp = possibleChildProps.find((key) => opt.hasOwnProperty(key));
    if (childProp) {
      return childProp;
    }
  }
  return null;
};

export const arrayToOptionsI18n = (context, array) => {
  return arrayToOptions(context, array, true);
};

export const listToOptions = (context, array) => {
  const result = {};
  for (const obj of array) {
    const { id, name } = obj;
    result[id] = { value: id, label: name };
  }
  return Object.values(result);
};

export const arrayToOptions = (context, array, i18n = false) => {
  let msg = { t: _.noop };
  if (i18n) {
    if (context.hasOwnProperty('context')) {
      context = context.context;
    }
    if (context.hasOwnProperty('msg')) {
      msg = context.msg;
    }
  }
  const result = {};

  for (const key of array) {
    result[key] = {
      value: key,
      label: i18n ? msg.t(key) : key,
    };
  }
  return Object.values(result);
};

export function BngSelect({
  className = '',
  field,
  form,
  emptyOption = true,
  groupedOpts = false,
  options = [],
  onChange = _.noop,
  ...props
}) {
  const childProp = groupedOpts && options.length > 0 ? findChildProp(options) : null;

  if (field) {
    field.value = _.isEmpty(field.value) && !_.isNumber(field.value) ? '' : field.value;
  }

  return (
    <select
      {...field}
      {...props}
      className={`BngSelect fill-w ${className}`}
      onChange={(event) => {
        if (field) {
          field.onChange(event);
        }
        onChange(event);
      }}
    >
      {emptyOption && <option value="">{ceData.context.msg.t('select.one')}</option>}

      {options.map((opt, idx) => {
        const label = opt.label ?? opt;
        const value = opt.value ?? opt;

        if (childProp) {
          const options = opt[childProp];
          return (
            <optgroup key={idx} label={label} disabled={opt.disabled}>
              {options.map((o, idx2) => (
                <option key={idx2} value={o.value} disabled={o.disabled}>
                  {o.label}
                </option>
              ))}
            </optgroup>
          );
        } else {
          return (
            <option key={idx} value={value} disabled={opt.disabled}>
              {label}
            </option>
          );
        }
      })}
    </select>
  );
}

BngSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  emptyOption: PropTypes.bool,
  groupedOpts: PropTypes.bool,
  options: PropTypes.array,
};

export default BngSelect;
