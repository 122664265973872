"use strict";

import {Axios} from "commonUtils";

const $ = jQuery;


class SchemaUpdaterApi {

    static async findOrigins(search = '', page = 0, size = 10) {
        const { data } = await Axios.get(`/spr/schema-updater/origins`, {
            params: {
                search,
                page,
                size,
            },
        });
        return data;
    }

    static updateSchema(inMemoryId) {
        return Promise.resolve(
            $.post(`/spr/schema-updater/${inMemoryId}/update-schema`)
        );
    }

    static async updateProjectSchemas(projectId) {
        await Axios.post(`/spr/schema-updater/updateProjectSchemas/${projectId}`);
    }

    static async updateAllSchemas() {
        await Axios.post(`/spr/schema-updater/updateAllSchemas`);
    }

    static async removeSchema(inMemoryId) {
        const {data} = await Axios.delete(`/spr/schema-updater/${inMemoryId}/removeSchema`);
        return data;
    }

    static updateLanguage(inMemoryId) {
        return Promise.resolve(
            $.post(`/spr/schema-updater/${inMemoryId}/updateLanguage`)
        );
    }

    static async rebuildDatasourcesXml() {
        await Axios.post(`/spr/schema-updater/rebuildDatasourcesXml`);
    }


}

export default SchemaUpdaterApi;