import React, {Component} from 'react';
import PropTypes from "prop-types";
import ContextEnhancer from "../../ContextEnhancer";
import {BngTable} from "../ui/BngTable";
import ApplicationKeyDialog from "./ApplicationKeyDialog";
import {MODALS} from "../../ui/redux/Actions";
import {connect} from "react-redux";
import BngClickOutsideOverlay from "../ui/BngClickOutsideOverlay";
import Popper from "../ui/BngPopper";
import Api from "../../Api";
import OpConfirmation from "../../ui/OpConfirmation";
import UiMsg from "../../ui/UiMsg";
import {BngAddButton} from "../ui/BngAddButton";
import Icon from "components/ui/common/Icon";


class ApplicationKeysDropdown extends Component {

    static propTypes = {
        container: PropTypes.any,
        editKey: PropTypes.func,
        deleteKey: PropTypes.func,
    };

    state = {
        popperRef: null,
        open: false,
    };

    openOptions = (event) => {
        this.setState({
            popperRef: event.currentTarget,
            open: true,
        });
    };

    render(){
        return (
            <div>
                <Icon icon="more_vert" className="application-keys-action" onClick={this.openOptions}/>
                <Popper className="bng-dropdown-parent"
                        container={this.props.container}
                        open={this.state.open}
                        anchorEl={this.state.popperRef}
                        modifiers={{
                            preventOverflow: {
                                boundariesElement: this.props.container,
                            }
                        }}>
                    <BngClickOutsideOverlay onClick={() => this.setState({open: false})} container={this.props.container}/>
                    <ul className="bng-dropdown">
                        <li onClick={() => {this.setState({open: false}); this.props.editKey()}}>
                            <Icon icon="edit"/>
                            {this.props.context.msg.t('edit')}
                        </li>
                        <li onClick={() => {this.setState({open: false}); this.props.deleteKey()}}>
                            <Icon icon="delete_outline"/>
                            {this.props.context.msg.t('remove')}
                        </li>
                    </ul>
                </Popper>
            </div>
        )
    }

}

class ApplicationKeysTab extends Component {

    static propTypes = {
        applicationKeys: PropTypes.array,
        accountId: PropTypes.number,
        loading: PropTypes.func,
    };

    static defaultProps = {

    };

    applicationKeysColumns = [
        {label: this.props.context.msg.t('application'), render: row => row.name},
        {label: this.props.context.msg.t('token'), render: row => row.token},
        {label: this.props.context.msg.t('domains'), render: row => row.blockByDomain ? this.renderDomains(row.allowedDomains) : ''},
        {label: '', render: row => this.renderActions(row)},
    ];

    renderDomains = (domains = []) => {
        if(domains.length === 0) {
            return null;
        }
        const stringDomains = domains.map(domain => domain.domain).join(', ');
        return (
            <span title={stringDomains} className="application-key-domains">{stringDomains}</span>
        )
    };

    editKey = (key = null, forceBlock = false) => {
        const {accountId, updateItem} = this.props;
        this.props.dispatch(MODALS.open(ApplicationKeyDialog, {applicationKey: key, updateItem, accountId, forceBlock}));
        this.setState({open: false});
    };

    changeBlockDomain = async (key, blockDomain = false) => {
        this.props.loading(true);
        key.blockByDomain = blockDomain;
        try {
            delete key.account;
            key.allowedDomains.map(domain => {delete domain.applicationKey});
            await Api.Account.saveApplicationKey(this.props.accountId, key);
            UiMsg.ok(this.props.context.msg.t('success'), this.props.context.msg.t(`application.key.update.success`));
            this.props.updateItem(key);
        } catch (e) {
            UiMsg.ajaxError('error',e);
        } finally {
            this.props.loading(false);
        }
    };

    onConfirmDelete = async (key) => {
        this.props.loading(true);
        try {
            await Api.Account.removeApplicationKey(key);
            this.props.updateItem(key, true);
            UiMsg.ok(this.props.context.msg.t('success'), this.props.context.msg.t(`application.key.delete.success`));
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(e);
        } finally {
            this.props.loading(false);
        }
    };

    deleteKey = (key) => {
        this.setState({open: false});
        OpConfirmation({
            title: this.props.context.msg.t('attention'),
            message: this.props.context.msg.t('application.key.delete.confirmation'),
            onConfirm: () => this.onConfirmDelete(key),
            msg: this.props.context.msg
        });
    };

    renderActions = (key) => {
        const container = j('.MyAccountsDialog')[0];
        return (
            <div className="application-keys-actions">
                <Icon icon={key.blockByDomain ? 'lock' : 'lock_open'}
                      className="application-keys-action"
                      onClick={() => key.blockByDomain ? this.changeBlockDomain(key) : this.editKey(key, true)}
                />
                <ApplicationKeysDropdown container={container} editKey={() => this.editKey(key)} deleteKey={() => this.deleteKey(key)} {...this.props}/>
            </div>
        )
    };

    render(){
        return (
            <div className="application-keys-tab">
                <BngAddButton onClick={() => this.editKey()} style={{float: 'left'}}>
                    <span>{this.props.context.msg.t('new.application.key')}</span>
                </BngAddButton>
                <BngTable cols={this.applicationKeysColumns} rows={this.props.applicationKeys}/>
            </div>
        )
    }
}

export default ContextEnhancer(
    connect() (ApplicationKeysTab)
);