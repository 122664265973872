import React from "react";
import * as PropTypes from "prop-types";

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";

import './BngError.css';

export const BngError = ContextEnhancer(
    class extends React.PureComponent {

        static propTypes = {
            className: PropTypes.string,
            showTitle: PropTypes.bool,
            title: PropTypes.any,
            showMessage: PropTypes.bool,
            message: PropTypes.any,
            hasError: PropTypes.bool,
            children: PropTypes.any,
            context: PropTypes.any
        };

        static defaultProps = {
            className: '',
            showTitle: true,
            title: undefined,
            showMessage: true,
            message: undefined,
            hasError: false
        };

        render() {
            let {
                className,
                showTitle,
                title,
                showMessage,
                message,
                hasError,
                children,
                context,
                ...props
            } = this.props;

            if (!hasError) {
                return children;
            }

            return (
                <div className={`BngError ${className}`} {...props}>
                    <div className="Container">
                        <img className="Image"
                             src={`${Api.baseUrl()}/resources/images/notes/error.png`}
                             alt="Error"
                        />
                        {showTitle &&
                        <div className="Title">
                            {title || context.msg.t('BngError.title')}
                        </div>
                        }
                        {showMessage &&
                        <div className="Message">
                            {message || context.msg.t('BngError.message')}
                        </div>
                        }
                    </div>
                </div>
            );
        }
    }
);

