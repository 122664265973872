import {Axios} from "commonUtils";


class Api4Com {

    validateToken = async (token) => {
        const {data} = await Axios.get(`/spr/origins/api4com/validate`, {params: {token}});
        return data;
    };

    validateName = async (body) => {
        const {data} = await Axios.post(`/spr/origins/api4com/validateName`, body);
        return data;
    };

    editOrigin = async (inMemoryId, body) => {
        const {data} = await Axios.post(`/spr/origins/api4com/${inMemoryId}`, body);
        return data;
    };

    saveOrigin = async (body) => {
        const {data} = await Axios.post(`/spr/origins/api4com`, body);
        return data;
    };

    getValuesWS = async (id) => {
        const {data} = await Axios.get(`/spr/origins/api4com/values/${id}`);
        return data;
    };

}

export default Api4Com;