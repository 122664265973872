import 'components/bng/ui/BngMultiStep.css';
import PropTypes from 'prop-types';
import React, { cloneElement, useCallback, useState } from 'react';

import Button from "components/ui/Button";
import ContextEnhancer from "components/ContextEnhancer";
import useOverlay from "components/hooks/useOverlay";

const BngMultiStep = ContextEnhancer(({
    children,
    context,
    className = '',
    onNextClick = _.noop,
    onPreviousClick = _.noop,
    prepend = null,
    adaLayout = false,
    overlay = true,
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [overlayRef] = useOverlay(true);

    const childrenArray = React.Children.toArray(children);
    const totalSteps = childrenArray.length;

    const handleNavigation = useCallback((nextStep) => {
        if (nextStep + 1 > totalSteps || nextStep < 0) {
            return;
        }
        setCurrentStep(nextStep);
    }, [totalSteps]);

    return (
        <>
            {overlay ?

            <div ref={overlayRef}>

            {prepend != null && (prepend)}

            <div className={`BngMultiStep ${className} ${adaLayout ? 'BngAdaBackground' : ''}`}>
                <div className="Body">
                    {cloneElement(childrenArray[currentStep], {
                        totalSteps,
                        currentStep,
                        handleNavigation,
                        context,
                        onNextClick,
                        onPreviousClick,
                        })}
                    </div>
                </div>
            </div>
            :
            <div className={`BngMultiStep ${className} ${adaLayout ? 'BngAdaBackground' : ''}`}>
                <div className="Body">
                    {cloneElement(childrenArray[currentStep], {
                        totalSteps,
                        currentStep,
                        handleNavigation,
                        context,
                        onNextClick,
                        onPreviousClick,
                    })}
                </div>
            </div>
            }
        </>
    );
})

BngMultiStep.propTypes = {
    className: PropTypes.string,
    onNextClick: PropTypes.func,
    onPreviousClick: PropTypes.func,
}

BngMultiStep.Step = ({
    children,
    isNextButtonDisabled = false,
    onNextClick,
    onPreviousClick,
    totalSteps,
    currentStep,
    handleNavigation,
    context
}) => {
    const isLastStep = (currentStep + 1) === totalSteps;
    const isFirstStep = currentStep === 0;

    const handleClick = (fn) => {
        fn({ isLastStep, isFirstStep, step: currentStep });
    }

    return (
        <div className="Step">
            <div className="Children">
                {children}
            </div>
            <div className="Footer">
                <div className="Dots">
                    {totalSteps > 1 && (
                        new Array(totalSteps).fill(0).map((_, idx) => (
                            <a key={idx} role="button">
                                <div className={`widget-footer-pg-button ${idx === currentStep ? 'active' : ''}`} />
                            </a>
                        ))
                    )}
                </div>
                <div className="Buttons">
                    {(currentStep - 1 >= 0) && (
                        <Button className={`bng-button cancel`}
                            onClick={() => {
                                handleNavigation(currentStep - 1)
                                handleClick(onPreviousClick)
                            }}>
                            {context.msg.t('previous')}
                        </Button>
                    )}
                    <Button className={'bng-button save'}
                        onClick={() => {
                            handleNavigation(currentStep + 1)
                            handleClick(onNextClick)
                        }}
                        disabled={isNextButtonDisabled}>
                        {isLastStep ? context.msg.t('got.it') : context.msg.t('next')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

BngMultiStep.Title = ({ children }) => {
    return (
        <h4 className="Title">
            {children}
        </h4>
    )
}

BngMultiStep.Subtitle = ({ children }) => {
    return (
        <p className="Subtitle">
            {children}
        </p>
    )
}


export default BngMultiStep;
