import styles from './RightMenuBreadcrumb.module.css';

import React from 'react';

import BngIconButton from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';

export default function RightMenuBreadcrumb({ items = [], onClose = _.noop, onBack }) {
  const canGoBack = !!onBack && items.length > 2;

  let percentPerFragment = 100 / items.length;
  let lastElementPercent = percentPerFragment;
  if (items.length > 2) {
    percentPerFragment = 40 / (items.length - 1);
    lastElementPercent = 50;
  }

  return (
    <div className={`RightMenuBreadcrumb ${styles.main} gap-1`}>
      {canGoBack && <BngIconButton onClick={onBack} icon="arrow_back" size="sm" className="flex-1" />}

      <div className={`flex-center-items gap-1 mr-auto truncate-text`}>
        {items.map((item, idx) => {
          const notLast = idx !== items.length - 1;
          const hideDescription = canGoBack && notLast && !!item.icon && items.length > 1;
          return (
            <React.Fragment key={item.key ?? idx}>
              <div
                onClick={item.onClick}
                className={`flex-center-items gap-1`}
                style={{
                  color: notLast ? 'var(--blue-default)' : undefined,
                  maxWidth: `${notLast ? percentPerFragment : lastElementPercent}%`,
                  cursor: notLast ? 'pointer' : undefined,
                }}
                title={item.label}
              >
                {item.icon && <Icon icon={item.icon} />}
                {!hideDescription && <span className={`fw-500 truncate-text`}>{item.label}</span>}
              </div>
              {notLast && <Icon icon="chevron_right" className={styles.separator} />}
            </React.Fragment>
          );
        })}
      </div>

      <BngIconButton onClick={onClose} icon="close" size="sm" className="flex-1" />
    </div>
  );
}
