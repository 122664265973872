import { Axios } from 'commonUtils';

class GroupApi {
  async findForProject(projectId) {
    if (!projectId) {
      throw new Error(`Must provide projectId param`);
    }
    const { data } = await Axios.get(`/spr/groups`, {
      params: {
        projectId,
      },
    });
    return data;
  }

  async createGroup(groupName, projectId) {
    await Axios.post(`/spr/groups/create`, {groupName, projectId});
  }

  async createEmptyGroup(projectId) {
    const {data} = await Axios.post(`/spr/groups/create/empty`, {projectId});
    return data;
  }

  async applyGroup(groupId, inMemoryId ) {
    await Axios.post(`/spr/groups/apply`, {groupId, inMemoryId});
  }

  async createDefaultGroups(projectId) {
    await Axios.post(`/spr/groups/create/default`, {projectId});
  }

  async saveGroup(group) {
    const {data} = await Axios.post(`/spr/groups/save`, group);
    return data;
  }

  async removeGroup(groupId) {
    await Axios.post(`/spr/groups/${groupId}/remove`);
  }

}

export default GroupApi;
