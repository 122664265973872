import './GradientThemeSelector.css';

import React from 'react';

import {getGradientColor} from 'components/bng/pages/bigTable/DefaultRenderer';

const values = [101, 75, 50, 25, -1];

const themes = [
    {
        ranges: [
            {color: '#FFFFFF', value: 0, valueOption: 'min'},
            {color: '#528DE3', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#FFFFFF', value: 0, valueOption: 'min'},
            {color: '#EB354D', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#FFFFFF', value: 0, valueOption: 'min'},
            {color: '#269C59', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#FFFFFF', value: 0, valueOption: 'min'},
            {color: '#FEC447', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#FFFFFF', value: 0, valueOption: 'min'},
            {color: '#5B5FD6', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#FFFFFF', value: 0, valueOption: 'min'},
            {color: '#303030', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#FFFFFF', value: 0, valueOption: 'min'},
            {color: '#FEC447', value: 50, valueOption: 'percent'},
            {color: '#EB354D', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#5B5FD6', value: 0, valueOption: 'min'},
            {color: '#FEC447', value: 50, valueOption: 'percent'},
            {color: '#269C59', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#EB354D', value: 0, valueOption: 'min'},
            {color: '#FFFFFF', value: 50, valueOption: 'percent'},
            {color: '#269C59', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#EB354D', value: 0, valueOption: 'min'},
            {color: '#FEC447', value: 50, valueOption: 'percent'},
            {color: '#269C59', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#EB354D', value: 0, valueOption: 'min'},
            {color: '#FFFFFF', value: 50, valueOption: 'percent'},
            {color: '#528DE3', value: 100, valueOption: 'max'},
        ]
    },
    {
        ranges: [
            {color: '#EB354D', value: 0, valueOption: 'min'},
            {color: '#A0A2DE', value: 50, valueOption: 'percent'},
            {color: '#5B5FD6', value: 100, valueOption: 'max'},
        ]
    },
];


export default (props) => {

    const pageSize = 6;
    const [page, setPage] = React.useState(0);

    const getPages = () => {
        let pages = [];
        for (let i = 0; i < Math.ceil(themes.length / pageSize); i++) {
            pages.push(i);
        }
        return pages;
    }

    const nextPage = () => {
        if (page < (Math.ceil(themes.length / pageSize) - 1)) {
            setPage(page + 1);
        }
    };

    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const selectTheme = (index) => {
        let idx = page * pageSize + index;
        let clone = _.cloneDeep(themes[idx]);
        props.onChange(clone);
    }

    return (
        <div className="BngGradientSelector">

            <div style={{display: 'flex', flexDirection: 'row'}}>

                {page > 0 ? (
                    <div className={`BngGradientSelector__Pagination`} onClick={() => previousPage()}>
                        <i className={'material-icons'}>{'chevron_left'}</i>
                    </div>
                ) : (
                    <div className={`BngGradientSelector__Pagination`}>
                    </div>
                )}

                <div className="BngGradientSelector__Container">
                    <div className="BngGradientSelector__Gradients">
                        {themes.slice(page * pageSize, (page * pageSize) + pageSize).map((theme, index) => (
                            <div className="BngGradientSelector__Gradient"
                                 key={index + "_" + page}
                                 onClick={() => selectTheme(index)}>

                                {values.map((v, i) => (
                                    <div
                                        key={index + '_' + i}
                                        className={`BngGradientSelector__GradientTick`}
                                        style={{
                                            borderTopLeftRadius: i === 0 ? 4 : 0,
                                            borderBottomLeftRadius: i === 0 ? 4 : 0,
                                            borderTopRightRadius: i === (values.length - 1) ? 4 : 0,
                                            borderBottomRightRadius: i === (values.length - 1) ? 4 : 0,
                                            backgroundColor: getGradientColor(theme.ranges, v, {max: 101, min: -1})
                                        }}></div>
                                ))}

                            </div>
                        ))}
                    </div>

                    <div className="BngGradientSelector__Pages">
                        {getPages().map(p => (
                            <div key={p}
                                 className={`BngGradientSelector__PageDot ${p === page && 'BngGradientSelector__PageDot--active'}`}></div>
                        ))}
                    </div>
                </div>

                {page < (Math.ceil(themes.length / pageSize) - 1) ? (
                    <div className={`BngGradientSelector__Pagination`} onClick={() => nextPage()}>
                        <i className={'material-icons'}>{'chevron_right'}</i>
                    </div>
                ) : (
                    <div className={`BngGradientSelector__Pagination`}>
                    </div>
                )}

            </div>

        </div>
    )
}