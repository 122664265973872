import styles from './ProjectWhiteLabelTab.module.css';

import whiteLabelImg from './assets/whiteLabel.png';
import faviconExampleImg from './assets/faviconExample.png';
import projectImg from './assets/projectImage.png';
import toolbarProjectLogoImg from './assets/toolbarProjectLogo.png';
import backgroundImg from './assets/backgroundImage.png';

import React, { useRef } from 'react';
import { Field, useFormikContext } from 'formik';

import BngField from 'components/bng/form/BngField';
import BngSwitch from 'components/bng/form/BngSwitch';
import BngSelect from 'components/bng/form/BngSelect';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngButtonGroup from 'components/bng/ui/Buttons/BngButtonGroup';
import useTranslation from 'components/hooks/useTranslation';

const buildImageBackgroundSizeOpts = (t) => {
  return [
    { value: 'auto', label: t('auto') },
    { value: 'contain', label: t('contain') },
    { value: 'cover', label: t('cover') },
    { value: '100% 100%', label: t('fit.to.page') },
  ];
};

const buildProjectThemeOpts = (t) => {
  return [
    { value: 'DARK', label: t('dark_mode') },
    { value: 'LIGHT', label: t('light_mode') },
  ];
};

const loginBoxPositionsOpts = [
  { value: 'left', icon: 'align_horizontal_left' },
  { value: 'center', icon: 'align_horizontal_center' },
  { value: 'right', icon: 'align_horizontal_right' },
];

export default function ProjectWhiteLabelTab({}) {
  const { t } = useTranslation();
  const { values, setFieldValue, handleChange } = useFormikContext();

  const handleImageSelector = async (e, image, h, w) => {
    try {
      const files = e.target.files;
      if (files[0].type.includes('image')) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const img = new Image();
          img.addEventListener('load', () => {
            if (img.height > h && img.width > w) {
              UiMsg.warn(t('project.whiteLabel.invalidImage.warning'));
            } else {
              setFieldValue(image, {
                ...values[image],
                relativeLink: img.currentSrc,
                value: img.currentSrc,
                file: files[0],
              });
            }
          });
          img.src = reader.result;
        });
        reader.readAsDataURL(files[0]);
      } else {
        UiMsg.error(t('only.image.support'));
      }
    } catch (e) {
      UiMsg.error(t('project.whiteLabel.error.on.image.upload'));
      console.error('ProjectWhiteLabelTab.handleImageSelector()', { e });
    }
  };

  return (
    <div className={`ProjectWhiteLabelTab ${styles.WhiteLabelWrapper}`}>
      <div className={`${styles.IntroductionCard}`}>
        <img src={whiteLabelImg} alt="whiteLabel img" />
        <div>
          <h5>{t('project.whiteLabel.introductioncard.title')}</h5>
          <div className={`white-label-description`}>{t('project.whiteLabel.introductioncard.description')}</div>
          <div className={`learn-more`}>
            <Icon icon="lock" />
            <div dangerouslySetInnerHTML={{ __html: t('project.whiteLabel.introductioncard.learnmore') }} />
          </div>
        </div>
      </div>

      <div className={`${styles.FaviconConfiguration}`}>
        <div>
          <Field
            name="faviconEnabled"
            component={BngSwitch}
            label={t('project.whiteLabel.favicon.configuration.switch.label')}
          />
          <div>{t('project.whiteLabel.favicon.configuration.description')}</div>
          <img src={faviconExampleImg} alt="favicon img" />
        </div>
        <ImageUpload
          styleHeight={50}
          styleWidth={50}
          imageHeight={32}
          imageWidth={32}
          label={'project.whiteLabel.favicon.configuration.addImage'}
          imageType={'favIcon'}
          relativeLink={values?.favIcon?.relativeLink}
          inputId={'favIcon-change-image'}
          handleImageSelector={handleImageSelector}
        />
      </div>

      <div className={`${styles.ProjectImageConfiguration}`}>
        <div>
          <Field
            name="headerLogoEnabled"
            component={BngSwitch}
            label={t('project.whiteLabel.projectImage.configuration.switch.label')}
          />
          <div>{t('project.whiteLabel.projectImage.configuration.description')}</div>
          <img src={projectImg} alt="project img" />
        </div>
        <ImageUpload
          styleHeight={65}
          styleWidth={270}
          imageHeight={65}
          imageWidth={270}
          label={'project.whiteLabel.projectImage.configuration.addImage'}
          imageType={'headerLogo'}
          relativeLink={values?.headerLogo?.relativeLink}
          inputId={'headerLogo-change-image'}
          handleImageSelector={handleImageSelector}
        />
      </div>

      <div className={`${styles.ToolbarProjectImageConfiguration}`}>
        <div>
          <Field
            name="toolbarLogoEnabled"
            component={BngSwitch}
            label={t('project.whiteLabel.toolbarProjectLogo.configuration.switch.label')}
          />
          <div>{t('project.whiteLabel.toolbarProjectLogo.configuration.description')}</div>
          <img src={toolbarProjectLogoImg} alt="toolbar img" />
        </div>
        <ImageUpload
          styleHeight={30}
          styleWidth={126}
          imageHeight={30}
          imageWidth={126}
          imgClassName={'simuleToolBar'}
          label={'project.whiteLabel.toolbarProjectLogo.configuration.addImage'}
          imageType={'toolbarLogo'}
          relativeLink={values?.toolbarLogo?.relativeLink}
          inputId={'toolbarLogo-change-image'}
          handleImageSelector={handleImageSelector}
        />
      </div>

      <div className={`${styles.BackgroundImageConfiguration}`}>
        <div>
          <Field
            name="loginBackgroundImageEnabled"
            component={BngSwitch}
            label={t('project.whiteLabel.backgroundImage.configuration.switch.label')}
          />
          <div
            className={`BackgroundImageConfiguration-description`}
            dangerouslySetInnerHTML={{
              __html: t('project.whiteLabel.backgroundImage.configuration.description'),
            }}
          />
          <img src={backgroundImg} alt="background img" />
        </div>
        <ImageUpload
          styleHeight={220}
          styleWidth={391}
          imageHeight={1080}
          imageWidth={1920}
          label={'project.whiteLabel.backgroundImage.configuration.addImage'}
          imageType={'loginBackgroundImage'}
          relativeLink={values?.loginBackgroundImage?.relativeLink}
          inputId={'loginBackgroundImage-change-image'}
          handleImageSelector={handleImageSelector}
        />
      </div>

      <div className={`${styles.BackgroundImageSize}`}>
        <div>
          <h5>{t('project.whiteLabel.backgroundImageSize.title')}</h5>
          <div dangerouslySetInnerHTML={{ __html: t('project.whiteLabel.backgroundImageSize.description') }} />
        </div>
        <Field
          name="loginBackgroundImageSize"
          onChange={handleChange}
          rootClassName={`BackgroundImageSize-rootClassName`}
          labelClassName={`BackgroundImageSize-labelClassName`}
          component={BngField}
          inputComponent={BngSelect}
          emptyOption={false}
          withLabel={false}
          showErrors={false}
          options={buildImageBackgroundSizeOpts(t)}
        />
      </div>

      <div className={`${styles.LoginBoxAlignment}`}>
        <div>
          <h5>{t('project.whiteLabel.loginBoxAlignment.title')}</h5>
          <div dangerouslySetInnerHTML={{ __html: t('project.whiteLabel.loginBoxAlignment.description') }} />
        </div>
        <Field
          name="loginBoxAlign"
          className={`${styles.LoginBoxPositionStyle}`}
          onChange={handleChange}
          component={BngField}
          inputComponent={BngButtonGroup}
          options={loginBoxPositionsOpts}
          withLabel={false}
        />
      </div>

      <div className={`${styles.BackgroundImageSize}`}>
        <div>
          <h5>{t('project.whiteLabel.loginTheme.title')}</h5>
          <div dangerouslySetInnerHTML={{ __html: t('project.whiteLabel.loginTheme.description') }} />
        </div>
        <Field
          name="loginTheme"
          onChange={handleChange}
          component={BngField}
          inputComponent={BngSelect}
          emptyOption={false}
          withLabel={false}
          showErrors={false}
          options={buildProjectThemeOpts(t)}
        />
      </div>
    </div>
  );
}

const ImageUpload = ({
  styleHeight,
  styleWidth,
  imageHeight,
  imageWidth,
  relativeLink,
  imageType,
  inputId,
  label,
  handleImageSelector,
  imgClassName = '',
}) => {
  const { t } = useTranslation();
  const _imageChangeImage = useRef();

  return (
    <div>
      <BngIconButton
        className={`${relativeLink === null ? '' : styles.HideField}`}
        icon="add_photo_alternate"
        text={t(label)}
        onClick={() => _imageChangeImage.current.click()}
      />
      <div className={`${relativeLink === null ? styles.HideField : ''}`}>
        <img
          src={`${relativeLink}`}
          className={`${styles.ChangeImageIndication} ${imgClassName}`}
          style={{ height: styleHeight, width: styleWidth }}
          onClick={() => _imageChangeImage.current.click()}
        />
        <input
          id={inputId}
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: 'none' }}
          ref={_imageChangeImage}
          onChange={(event) => handleImageSelector(event, imageType, imageHeight, imageWidth)}
        />
      </div>
      <Field name={imageType} component={BngField} rootClassName={`${styles.HideField}`} />
      <div>{t(`${label}.description`)}</div>
    </div>
  );
};
