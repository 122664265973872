import styles from './AddonAcceptPage.module.css';

import React, { useEffect, useState } from 'react';
import { ActivateAddonComponent, removeUserDuplicates } from 'components/bng/accounts/addons/AddonActivateDialog';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import LoadingCenter from 'components/ui/loading/LoadingCenter';

const sleep = (time) => new Promise((res) => setTimeout(res, time));

export default function AddonAcceptPage({ currentAddonKey = null, accountId = null, projectId = null }) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const [userRequests, setUserRequests] = useState([]);
  const [addon, setAddon] = useState({});
  const [projectInfo, setProjectInfo] = useState(null);

  const fetchAddons = async () => {
    setLoading(true);
    try {
      const addons = await Api.Account.findAccountAddons(accountId);
      const currentRequests = addons.activationRequests.filter((request, idx) => request.addon === currentAddonKey);
      const userRequests = removeUserDuplicates(currentRequests);

      const projectInfo = await Api.Project.findProjectInfoForAddonPage(projectId);

      if (userRequests.length === 0) {
        UiMsg.ok(context.msg.t('addon.request.already.reviewed'));
        await sleep(1000);
        await redirectToProject(projectInfo.projectName);
        return;
      }

      if ((projectInfo != null && !projectInfo.isUserAdmin) || !currentAddonKey) {
        await redirectToErrorPage();
        return;
      }

      setUserRequests(userRequests);
      setAddon(addons.allAddons.find((addon) => addon.id === currentAddonKey));
      setProjectInfo(projectInfo);
    } catch (e) {
      console.error(e);
      UiMsg.error('error', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAddons();
    })();
  }, []);

  const redirectToErrorPage = () => {
    setLoading(true);
    window.location.href = `${Api.baseUrl()}/pages/errors/403.iframe`;
  };

  const redirectToProject = async (projectName) => {
    await context.execute(`#{projectSelectionBean.selectProject('${projectName}')}`);
  };

  return (
    <div className={styles.AddonAcceptPage}>
      {loading ? (
        <LoadingCenter />
      ) : (
        <div className={styles.AddonAcceptContent}>
          {projectInfo != null && projectInfo.isUserAdmin && currentAddonKey && (
            <ActivateAddonComponent
              context={context}
              currentAddon={addon}
              userRequests={userRequests}
              onSubmit={async () => {
                setLoading(true);
                try {
                  await redirectToProject(projectInfo.projectName);
                } finally {
                  setLoading(false);
                }
              }}
              projectId={projectId}
              accountId={accountId}
            />
          )}
        </div>
      )}
    </div>
  );
}
