"use strict";

import {Axios} from "commonUtils";

const $ = jQuery;


class NewMapApi {

    static saveMap = async (mapData = {}) => {
        const {data} = await Axios.post(`/spr/ui/maps`, mapData);
        return data;
    };

    static userCanWriteObject(path) {
        return Promise.resolve($.getJSON(`/spr/ui/maps/canwriteobject`, {path}));
    }

    static deleteOverwrite(folder, object) {
        const obj = {
            folder: folder,
            object: object
        };
        return Promise.resolve($.postJSON(`/spr/ui/maps/delete/overwrite`, obj));
    }

    static findOne({id, ...params}) {
        return Promise.resolve(
            $.getJSON(`/spr/ui/maps/${id}?${j.param({...params})}`)
        );
    }

    static objectexists(folder, object) {
        return Promise.resolve($.getJSON(`/spr/ui/maps/nameisused`, {folder, object}))
    }

    static remove({id}) {
        return Promise.resolve(
            $.deleteReq(`/spr/ui/maps/${id}`)
        );
    }

    static async query(props) {
        let filter = [];
        if (props.filter) {
            filter = (_.isString(props.filter) ? JSON.parse(props.filter) : props.filter) ?? [];
            filter = filter.filter(f => !_.isEmpty(f.members));
        }
        const params = {
            datasource: props.datasource || '',
            geoField: props.geoField || '',
            legendField: props.legendField || '',
            heatMapField: props.heatMapField || '',
            heatMapUseMetric: props.heatMapUseMetric || false,
            titleFields: (props.titleFields || []).filter(i => i && i.name.length > 0),
            filter,
            path: props.path && props.path.length > 0 ? props.path : undefined,
            timeFilter: {
                dimension: props.timeFilter?.dimension ?? '',
                periodicity: props.timeFilter?.periodicity ?? '',
            },
            publisherContent: props.publisherContent,
        };
        const {data} = await Axios.post(`/spr/ui/maps/query`, params, {
            params: {
                path: params.path,
                publisherContent: params.publisherContent,
                t: Date.now()
            }
        });
        return NewMapApi.processGeoJSON(data);
    }

    static processGeoJSON(data) {
        if(data.error && data.error.startsWith('{')) {
            try {
                data.error = JSON.parse(data.error);
            } catch (e) {
                console.error('Error on processGeoJSON()', data, e);
            }
        }
        return data;
    }

    static toggleMobile({id}) {
        return Promise.resolve(
            $.post(`/spr/ui/maps/${id}/toggle-mobile`)
        );
    }

    static getAddress(SourceObject) {
        return Promise.resolve($.postJSON('/spr/ui/maps/getaddress', SourceObject));
    };

    static countAddress(SourceObject) {
        return Promise.resolve($.postJSON('/spr/ui/maps/countaddress', SourceObject));
    };

    static updateAddress(GeoReferenceObject) {
        return Promise.resolve(($.postJSON('/spr/ui/maps/updateaddress', GeoReferenceObject)));
    }

    static searchAddress(params) {
        return Promise.resolve(($.postJSON('/spr/ui/maps/searchAddress', params)));
    }
}

export default NewMapApi;
