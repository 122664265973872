import Utils from "components/Utils";

class FilterUtils {
  // Analog to br.com.sol7.analysis.dynamic.periodicity.Periodicities.isPeriodicityValidOnDate
  isPeriodicityValidOnDate(periodicity = '', date = new Date()) {
    if (periodicity === 'CurrentWeekUntilYesterday') {
      return date.getDay() !== 1;
    } else if (periodicity === 'CurrentYearUntilYesterday') {
      return !(date.getMonth() === 0 && date.getDate() === 1);
    } else if (periodicity === 'CurrentMonthUntilYesterday') {
      return date.getDate() !== 1;
    } else if (periodicity === 'CurrentYearUntilLastMonth') {
      return date.getMonth() !== 0;
    }

    return true;
  }
}

export default new FilterUtils();
