import {Axios} from "commonUtils";

class MdxGlobalFilterApi {

    async findAll(projectId, withDetails = false) {
        const {data} = await Axios.get(`/spr/mdxGlobalFilters`, {
            params: {
                projectId,
                withDetails
            }
        });
        return data;
    }

    async findOne(id) {
        const {data} = await Axios.get(`/spr/mdxGlobalFilters/${id}`);
        return data;
    }

    async save(mdxGlobalFilter) {
        const id = mdxGlobalFilter.id ? `/${mdxGlobalFilter?.id}` : ''
        const {data} = await Axios.post(`/spr/mdxGlobalFilters${id}`, mdxGlobalFilter);
        return data;
    }

    async remove(id) {
        await Axios.delete(`/spr/mdxGlobalFilters/${id}`);
    }

    async findRelatedEnums() {
        const {data} = await Axios.get(`/spr/mdxGlobalFilters/enums`);
        return data;
    }

}

export default MdxGlobalFilterApi;