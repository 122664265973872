import { useEffect } from 'react';

function useAsyncEffect({ onMount = _.noop, onUnmount = _.noop, deps = [] }) {
  useEffect(() => {
    onMount?.();
    return () => {
      onUnmount?.();
    };
  }, deps ?? []);
}

export default useAsyncEffect;
