import './BngIntegrationRequest.css';

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Formik} from "formik";

import Api from "components/Api";
import {BngForm} from "components/bng/form/BngForm";
import {bngYup} from "components/bng/form/yup/BngYup";
import Button from "components/ui/Button";
import ContextEnhancer from "components/ContextEnhancer";

const SuggestionSchema = bngYup(yup => yup.object().shape({
    title: yup.string().default(''),
    description: yup.string().default('')
}));

const BngIntegrationRequest = ({
                                   img,
                                   title,
                                   loading,
                                   onSubmit = _.noop,
                                   onCancel = _.noop,
                                   context
                               }) => {
    const [isSuggestionSent, setSuggestionSent] = useState(false);

    const handleSubmit = (values, actions) => {
        onSubmit(values, actions)
        setSuggestionSent(true)
    }

    const renderContent = () => {

        if (loading) {
            return (
                <>
                    <h4 className="TitleSuccess">{context.msg.t('BngIntegrationRequest.loading')}</h4>
                </>
            )
        }

        if (isSuggestionSent) {
            return (
                <>
                    <div className="Message" style={{marginBottom: 20}}>
                        {context.msg.t('BngIntegrationRequest.sent.success')}
                    </div>

                    <div className="BngIntegrationRequestButtonWrapper">
                        <Button type="button"
                                onClick={onCancel}
                                className={'BngButtonSuggestion Cancel'}
                                data-test={"CancelFormButton"}
                        >
                            {context.msg.t('BngIntegrationRequest.back')}
                        </Button>
                    </div>
                </>
            )
        }

        return (
            <>
                {title &&
                <div className="Title">
                    {title}
                </div>
                }

                <div className="Message">
                    {context.msg.t('BngIntegrationRequest.description')}
                </div>

                <Formik initialValues={SuggestionSchema.default()}
                        onSubmit={handleSubmit}
                        validationSchema={SuggestionSchema}
                        enableReinitialize={true}>
                    {({isValid}) => (
                        <>
                            <BngForm className="BngIntegrationRequestForm">
                                <div className="BngIntegrationRequestButtonWrapper">
                                    <Button type="button"
                                            onClick={onCancel}
                                            className={'BngButtonSuggestion Cancel'}
                                            data-test={"CancelFormButton"}
                                    >
                                        {context.msg.t('BngIntegrationRequest.cancel')}
                                    </Button>
                                    <div className="Spacer"></div>
                                    <Button type="submit"
                                            className={'BngButtonSuggestion'}
                                            disabled={!isValid}
                                            data-test={"SubmitFormButton"}
                                    >
                                        {context.msg.t('BngIntegrationRequest.submit')}
                                    </Button>
                                </div>
                            </BngForm>
                        </>
                    )}
                </Formik>
            </>
        )

    };

    return (
        <div className='BngIntegrationRequest'>
            <div className="Container">
                <img className="Image"
                     src={img || `${Api.baseUrl()}/resources/images/suggestion-box.png`}
                     alt="Suggestion box"
                />
                {renderContent()}
            </div>
        </div>
    );
};

BngIntegrationRequest.propTypes = {
    img: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ContextEnhancer(BngIntegrationRequest);
