import { Axios } from 'commonUtils';

export default class ProjectInviteApi {
  async findInvite(code = '') {
    return await Axios.getData(`/api/projectInvites`, {
      params: {
        code,
      },
    });
  }

  async acceptInvite(data) {
    return await Axios.postData(`/api/projectInvites`, data);
  }
}
