// css imported on CommonCssImports.js
import React from "react";
import PropTypes from "prop-types";
import Icon from "components/ui/common/Icon";


export class KpiBands extends React.Component {

    static propTypes = {
        band1Color: PropTypes.string,
        band2Color: PropTypes.string,
        band2MinValue: PropTypes.string,
        band3Color: PropTypes.string,
        band2MaxValue: PropTypes.string,
        kpiId: PropTypes.number,
        textAlign: PropTypes.string,
        type: PropTypes.string,
        kpiIsPercent: PropTypes.string,
        div: PropTypes.any,
        container: PropTypes.any,
        model: PropTypes.string,

    };

    static defaultProps = {
        band1Color: '',
        band2Color: '',
        band2MinValue: '',
        band3Color: '',
        band2MaxValue: '',
        kpiId: '',
        textAlign: '',
        type: '',
        kpiIsPercent: 'false',
        model: '',
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.resizeBands(this.props.width, this.props.height);
    }

    componentDidUpdate(prevProps) {
        this.resizeBands(this.props.width, this.props.height);
    }

    resizeBands = (width, height) => {
        if (this.props.div) {
            const offsetParent = this.props.container.offsetParent;
            if (this.props.align === 'left' && !!offsetParent && !!offsetParent.querySelector('.KpiInternalBody')) {
                const marginLeft = offsetParent.querySelector('.KpiInternalBody').offsetWidth / 2;
                Object.assign(this.$kpiBandsTable.style, {
                    marginLeft: marginLeft + "px",
                });
            }

            Object.assign(this.props.container.style, {
                marginTop: ['PERCENT', 'PIE'].some(el => this.props.model.includes(el)) ? '0px' : `${height * (0.04)}px`,
                minHeight: 22 + "px",
            });
        }
    };

    render() {
        return (
            <table className="KpiBandsTable" ref={el => this.$kpiBandsTable = el}>
                <tbody>
                <tr id={"row-bands"}>
                    <td className="KpiBandColor">
                        <Icon icon="fiber_manual_record" style={{color: this.props.band1Color}}/>
                    </td>
                    <td className="KpiBandNext">
                        <Icon icon="icon-chevron-left"/>
                    </td>
                    <td className="KpiBandsValue">
                        {this.props.band2MinValue}
                    </td>
                    <td className="KpiBandColor">
                        <Icon icon="fiber_manual_record" style={{color: this.props.band2Color}}/>
                    </td>
                    <td className="KpiBandNext">
                        <Icon icon="icon-chevron-left"/>
                    </td>
                    <td className="KpiBandsValue">
                        {this.props.band2MaxValue}
                    </td>
                    <td className="KpiBandColor">
                        <Icon icon="fiber_manual_record" style={{color: this.props.band3Color}}/>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

}


