import './OpConfirmation.css';
import useTranslation from 'components/hooks/useTranslation';

export default async function OpConfirmation({
  title = '',
  message = '',
  onConfirm = _.noop,
  level = 'error',
  html = null,
  confirmText = 'yes',
  cancelText = 'no',
  reverseButtons = false,
  showCancelButton = true,
  onResult = undefined,
}) {
  const { t } = useTranslation();

  return Swal.fire({
    title: title,
    text: message,
    icon: level,
    html: html,
    showCancelButton: showCancelButton,
    confirmButtonColor: level === 'error' ? '#DD6B55' : level === 'warning' ? 'FFA500' : '',
    confirmButtonText: t(confirmText),
    cancelButtonText: t(cancelText),
    reverseButtons: reverseButtons,
  }).then((result) => {
    if (onResult) {
      onResult(result.value || false);
    } else if (result.value) {
      onConfirm();
    }
    return result.value || false;
  });
}
