import 'components/ui/presentation/PresentationAddObject.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ContextEnhancer from 'components/ContextEnhancer';
import Button from 'components/ui/Button';
import DropdownSelectVisualization from 'components/ui/presentation/DropdownSelectVisualization';
import { MODALS } from 'components/ui/redux/Actions';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import DialogHtmlText from 'components/ui/presentation/DialogHtmlText';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class PresentationAddObject extends Component {
  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    items: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    updateArray: _.noop,
  };

  state = {
    canDragInteractiveElements: false,
  };

  updateFieldSlide = (text, item, field) => {
    let objects = this.props.items;
    objects.find((el) => {
      if (el === item) {
        el[field] = text;
      }
    });
    this.props.onChange(objects);
  };

  updateValue = (items, event, field) => {
    let val = event.target.value;
    this.updateFieldSlide(val, items, field);
  };

  openHtmlTextDialog = (item = this.props.item) => {
    window.ReduxStore.dispatch(
      MODALS.open(DialogHtmlText, {
        updateTextLink: this.updateFieldSlide,
        item,
        ...this.props,
      })
    );
  };

  buildComponent = (item, index) => {
    switch (item.type) {
      case 'EXTERNAL_LINK':
        return (
          <input
            placeholder="https://example.com"
            required={true}
            autoFocus={true}
            id={`link${index}${item.uuid}`}
            onChange={(event) => this.updateValue(item, event, 'path')}
            defaultValue={item.path}
            type="url"
            className="input-link-presentation"
          />
        );
      case 'HTML_COMPONENT':
        return (
          <div
            onClick={(event) => this.openHtmlTextDialog(item)}
            title={!item.path ? this.props.context.msg.t('empty.html') : ''}
            className={`${!item.path ? `empty-html-page-presentation` : ''} button-edit-page-presentation`}
          >
            <Icon icon="settings" />
            <div className="label-edit-button">{this.props.context.msg.t('edit_button')}</div>
          </div>
        );
      default:
        if (Utils.Object.isAnalysis(item.path)) {
          return <DropdownSelectVisualization item={item} onUpdate={this.updateFieldSlide} />;
        } else {
          return (
            <input
              id={`#object${index}${item.uuid}`}
              disabled={true}
              type="text"
              className="input-link-presentation"
              defaultValue={this.props.context.msg.t(Utils.Object.getObjectType(item.path))}
            />
          );
        }
    }
  };

  onDragEnd = (result) => {
    if (!result.destination) return;

    const fromIdx = result.source.index;
    const toIdx = result.destination.index;

    if (fromIdx === toIdx) return;

    const items = reorder(this.props.items, fromIdx, toIdx);

    this.setState({ items });
    this.props.onChange(items);
  };

  removeSlide = (item) => {
    const items = this.props.items.filter((i) => i.uuid !== item.uuid);
    this.setState({ items });
    this.props.onChange(items);
  };

  buildIcon = (item) => {
    const icon =
      item.type === 'EXTERNAL_LINK'
        ? 'insert_link'
        : item.type === 'HTML_COMPONENT'
        ? 'insert_drive_file'
        : Utils.Object.getObjectIcon(item.path);

    return <Icon icon={icon} />;
  };

  render() {
    return (
      <div className="scroll-bar-presentation-table">
        <table className="table table-condensed table-border table-hover">
          <thead>
            <tr>
              <th className="presentation-table-title">{this.props.context.msg.t('move.object.slide.show')}</th>
              <th className="presentation-table-title">{this.props.context.msg.t('slides')}</th>
              <th className="presentation-table-title">{this.props.context.msg.t('show')}</th>
              <th className="presentation-table-title">{this.props.context.msg.t('presentation.time.column.label')}</th>
              <th></th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {this.props.items.map((item, index) => (
                    <Draggable key={item.uuid} draggableId={index + 1} index={index}>
                      {(provided, snapshot) => (
                        <tr className="presentationlist-table-tr" ref={provided.innerRef} {...provided.draggableProps}>
                          <td className="presentationlist-table-td-drag">
                            <div className="div-information-presentation" {...provided.dragHandleProps}>
                              <Icon type={Icon.MATERIAL} icon="drag_indicator" />
                            </div>
                          </td>
                          <td className="presentationlist-table-td-slide">
                            <div title={item.slide} className="div-information-presentation">
                              {this.buildIcon(item)}
                              <span className="presentation-item-slide-name">{item.slide}</span>
                            </div>
                          </td>
                          <td className="presentationlist-table-td-component">
                            <div className="div-information-presentation">{this.buildComponent(item, index)}</div>
                          </td>
                          <td className="presentationlist-table-td-time">
                            <div className="div-information-presentation">
                              <input
                                id={`time${index}${item.uuid}`}
                                className="input-time-presentation"
                                onChange={(event) => this.updateValue(item, event, 'time')}
                                defaultValue={item.time}
                                required={true}
                                type="number"
                                maxLength="4"
                                max="9999"
                                min="15"
                              />
                            </div>
                          </td>
                          <td className="presentationlist-table-td-remove">
                            <div className="button-remove-div">
                              <Button
                                title={this.props.context.msg.t('dashboard_remove_item')}
                                className="btn-only-icon remove-slide"
                                onClick={(event) => this.removeSlide(item)}
                              >
                                <Icon type={Icon.MATERIAL} icon="clear" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
    );
  }
}

export default ContextEnhancer(PresentationAddObject);
