import './StackTraceDialog.css';
import PropTypes from 'prop-types';
import { ceData } from 'components/CeData';
import Utils from 'components/Utils';

const StackTraceDialog = ({
  title = '',
  text = '',
  html = "null",
  closeText = ceData.context.msg.t('close'),
  copyText = ceData.context.msg.t('copy.task.stack.trace'),
  requestId = '',
}) => {
  const showRequestId = !_.isEmpty(requestId);
  Swal.fire({
    customClass: {
      content: 'StackTraceDialog',
    },
    title: title,
    text: text,
    html: `${
      showRequestId
        ? `<div>
          <small>
            <b>Request ID:</b> <code>${requestId || '-'}</code>
          </small>
        </div>`
        : ''
    }<pre class="mt-2">${html}</pre>`,
    width: '90vw',
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonText: closeText,
    confirmButtonText: copyText,
    preConfirm() {
      Utils.copyToClipboard(html);
      return false;
    },
  });
};

StackTraceDialog.propTypes = PropTypes.shape({
  msg: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string,
  level: PropTypes.string,
  onConfirm: PropTypes.func,
});

export default StackTraceDialog;