// css imported on CommonCssImports.js
import React from 'react';
import Api from "components/Api";
import BaseKpi from "components/ui/kpi/BaseKpi";
import Icon from "components/ui/common/Icon";
import {rgbToYIQ} from 'components/bng/pages/bigTable/DefaultRenderer';

export class Square extends BaseKpi {

    constructor(props) {
        super(props);
    }


    useTarget = () => {

        let title = this.state.value.toString().includes(':') ? this.state.value : this.state.titleFormattedValue;
        const textColor = rgbToYIQ(this.state.color) < 190 ? '#F2F2F2' : '#212121';

        if (this.props.target !== 0 && !this.props.useBands && this.props.viewBands) {
            return (
                <tr className="tr-target-square" ref={el => this.$rowTargetValuesEl = el}>
                    <td ref={el => this.$tdIndicatorEl = el} style={{textAlign: 'center'}}>
                        <div className="div-indicator-square" ref={el => this.$divIndicatorEl = el}>
                            <span className="square-target-text" ref={el => this.$indicatorTitleEl = el} style={{color: textColor}}>
                                {this.props.context.msg.t('kpi.value.square')}
                            </span>
                            <br/>
                            <span className="square-target-text" title={title} style={{color: textColor}}
                                  ref={el => this.$indicatorValueEl = el}>
                                {this.props.preffix} {this.state.value} {this.props.suffix}
                            </span>
                        </div>
                    </td>
                    <td ref={el => this.$tdTargetEl = el} style={{textAlign: 'center'}}>
                        <div ref={el => this.$divTargetEl = el}
                             style={{textAlign: 'center'}}>
                            <span className="square-target-text" style={{color: textColor}} ref={el => this.$targetTitleEl = el}>
                                {this.props.targetLabel}
                            </span>
                            <br/>
                            <span className="square-target-text" style={{color: textColor}} ref={el => this.$targetValueEl = el}>
                                {this.props.preffix} {this.state.target} {this.props.suffix}
                            </span>
                        </div>
                    </td>
                </tr>

            );
        } else if (this.props.useBands && this.props.viewBands) {

            return (
                <tr className="tr-target-square" ref={el => this.$rowTargetValuesEl = el}>
                    <td ref={el => this.$tdIndicatorEl = el} style={{textAlign: 'center'}}>
                        <div ref={el => this.$divIndicatorEl = el} className="square-bands-indicator">
                            <span className="square-target-text" style={{color: textColor}} ref={el => this.$indicatorTitleEl = el}>
                               {this.props.context.msg.t('kpi.value.square')}
                            </span>
                            <br/>
                            <span title={title} style={{color: textColor}} className="square-target-text" ref={el => this.$indicatorValueEl = el}>
                                {this.props.preffix} {this.state.value} {this.props.suffix}
                            </span>
                        </div>
                    </td>
                    <td ref={el => this.$tdTargetEl = el} style={{textAlign: 'center'}}>
                        <div ref={el => this.$divTargetEl = el}
                             style={{float: 'right', verticalAlign: 'inherit', textAlign: 'center'}}>
                            <span className="square-target-text" style={{color: textColor}} ref={el => this.$targetTitleEl = el}>
                              {this.props.targetLabel}
                            </span>
                            <br/>
                            {this.useBands({container: this.$divTargetEl})}
                        </div>
                    </td>

                </tr>

            );
        }
    };

    viewPercent = () => {

        if (this.props.imageType === 'IMAGE' || this.props.imageType === 'AVATAR') return;

        let percent = this.props.percent ? this.state.value : (this.state.percentage + '%');

        if (this.props.measureAsTarget && this.props.percent) {
            percent = (this.state.percentage);
        }

        if (this.props.target !== 0 || this.props.useBands) {
            return (
                <div ref={el => this.$percentSquareEl = el} className="percentagevalue">
                    {percent}
                </div>
            )
        } else {
            return;
        }
    };

    imageFragment = (type) => {
        if (type === 'IMAGE') {
            return (
                <img ref={el => this.$imgEl = el} className="kpi-default-image-square"
                     src={`${Api.baseUrl()}${this.props.image}`}
                     onError={() => {
                       this.$imgEl.src = `${Api.baseUrl()}/resources/images/default_missing_image.svg`;
                     }}
                />
            )
        } else if (type === 'AVATAR') {
            var t = new Date().getTime();
            return (
                <img ref={el => this.$imgEl = el} className="kpi-default-avatar-square"
                     src={`${Api.baseUrl()}/avatar?user-id=${this.props.image}&t=${t}`}/>
            )

        } else if (type === 'ICON') {
            return (
                <Icon ref={el => this.$iconEl = el}
                      id={"kpi-default-icon" + this.state.kpiId}
                      icon={this.props.image}
                      className={"infobox-iconcircle"}
                      style={{color: '#ffffff', margin: 0}}
                />
            );
        }
    };

    renderRightFragment = () => {


        let title = this.state.value.toString().includes(':') ? this.state.value : this.state.titleFormattedValue;

        const textColor = rgbToYIQ(this.state.color) < 190 ? '#F2F2F2' : '#212121';

        if ((this.props.target === 0 && !this.props.useBands) || !this.props.viewBands) {
            return (
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div ref={el => this.$containerTitleSquareEl = el}>
                                <div ref={el => this.$TitleSquareEl = el} className="kpiSquareTitle notarget"
                                     style={{color: textColor}}
                                     title={this.props.title}>
                                    {this.props.title}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div ref={el => this.$containerValueSquareEl = el}>
                                <div className="infobox-data-number"
                                     style={{color: this.state.color, fontWeight: '500'}}>
                                        <span
                                            ref={el => this.$ValueSquareEl = el}
                                            title={title}
                                            className="kpiSquareValue notarget"
                                            style={{ color: textColor }}
                                        >
                                            {this.props.preffix} {this.state.value} {this.props.suffix}
                                        </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div ref={el => this.$containerDescriptionSquareEl = el} style={{whiteSpace: 'nowrap'}}>
                                <div ref={el => this.$DescriptionSquareEl = el} className="kpiSquareTitle notarget"
                                     id={"square-label" + this.state.kpiId} title={this.props.label} style={{color: textColor}}>
                                    {this.props.label}
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            );
        } else if (this.props.target !== 0 || (this.props.useBands && this.props.viewBands)) {
            return (
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div ref={el => this.$containerTitleSquareEl = el}>
                                <span ref={el => this.$TitleSquareEl = el} title={this.props.title}
                                      className="kpiSquareTitle target" style={{color: textColor}}>
                                    {this.props.title}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div ref={el => this.$containerDescriptionSquareEl = el}>
                                <span ref={el => this.$DescriptionSquareEl = el} title={this.props.label}
                                      className="kpiSquareTitle targetdescription" style={{color: textColor}}>
                                    {this.props.label}
                                </span>
                            </div>
                        </td>
                    </tr>
                    {this.useTarget()}
                    </tbody>
                </table>

            );

        }
    };

    customResizeKpi = ({width, height}) => {
        let type = this.props.imageType;

        let percentIconContainer = 0.4;

        this.props.div.style.padding = 0;

        let widthContainerValues = width - (width * percentIconContainer);

        Object.assign(
            this.$tableSquareEl.style, {
                height: '100%',
                width: '100%',
            }
        );

        Object.assign(
            this.$tdIconEl.style, {
                height: `${this.$tableSquareEl.clientHeight}px`,
                width: `${this.props.width * percentIconContainer}px`,
            }
        );


        //Se não tiver meta

        if (this.$percentSquareEl) {
            Object.assign(
                this.$percentSquareEl.style, {
                    fontSize: (height * 0.12) < 12 ? 12 + 'px' : (height * 0.12) + 'px',
                    lineHeight: (height * 0.12) < 12 ? 12 + 'px' : (height * 0.12) + 'px',
                }
            );
        }

        this.$containerTitleSquareEl.style.width = widthContainerValues + "px";

        Object.assign(
            this.$TitleSquareEl.style, {
                fontSize: (height * 0.10) < 12 ? `12px` : `${height * 0.10}px`,
                lineHeight: (height * 0.13) < 14 ? `14px` : `${height * 0.13}px`,
                width: widthContainerValues * 0.90 + "px",
            }
        );

        if (this.$containerValueSquareEl) {

            {
                const newFontSize = ((height * 0.23)) < 12 ? `12px` : `${height * 0.23}px`;
                this.$containerValueSquareEl.style.width = `${widthContainerValues * 0.92}px`;
                Object.assign(
                    this.$ValueSquareEl.style, {
                        fontSize: newFontSize,
                        lineHeight: newFontSize,
                    }
                );
            }


            if (this.$ValueSquareEl.offsetWidth > this.$containerValueSquareEl.offsetWidth) {

                let newHeight = (height * (this.$containerValueSquareEl.offsetWidth / this.$ValueSquareEl.offsetWidth) * 0.9);

                {
                    const newFontSize = ((newHeight * 0.23)) < 12 ? `12px` : `${newHeight * 0.23}px`;
                    Object.assign(
                        this.$ValueSquareEl.style, {
                            fontSize: newFontSize,
                            lineHeight: newFontSize,
                        }
                    );
                }

            }

        }

        this.$containerDescriptionSquareEl.style.width = widthContainerValues + "px";

        Object.assign(
            this.$DescriptionSquareEl.style, {
                fontSize: (height * 0.06) < 12 ? `12px` : `${height * 0.06}px`,
                lineHeight: (height * 0.12) < 12 ? `12px` : `${height * 0.12}px`,
                width: `${widthContainerValues * 0.90}px`,
            }
        );

        //Se tiver meta
        if (this.$rowTargetValuesEl) {
            let rowTargetValues = this.$rowTargetValuesEl;

            widthContainerValues = widthContainerValues * 0.92;

            this.$containerBandsEl.style.width = `${widthContainerValues}px`;

            Object.assign(
                rowTargetValues.style, {
                    width: `${widthContainerValues}px`,
                    height: '32%',
                }
            );

            let tdWidth = widthContainerValues / 2;

            let tdHeight = rowTargetValues.offsetHeight;


            Object.assign(
                this.$tdIndicatorEl.style, {
                    width: `${tdWidth - 2}px`,
                    height: `${tdHeight}px`,
                }
            );

            Object.assign(
                this.$divIndicatorEl.style, {
                    width: `${tdWidth - 2}px`,
                    height: `${tdHeight}px`,
                }
            );

            Object.assign(
                this.$indicatorTitleEl.style, {
                    fontSize: `${tdWidth * 0.08}px`,
                    lineHeight: `${tdWidth * 0.15}px`,
                }
            );

            Object.assign(
                this.$indicatorValueEl.style, {
                    fontSize: `${tdWidth * 0.20}px`,
                    lineHeight: `${tdWidth * 0.23}px`,
                }
            );

            if (this.$indicatorValueEl && this.$indicatorValueEl.offsetWidth >= this.$tdIndicatorEl.offsetWidth) {

                let newTdWidth = tdWidth * ((this.$tdIndicatorEl.offsetWidth / this.$indicatorValueEl.offsetWidth) * 0.9);

                Object.assign(
                    this.$indicatorValueEl.style, {
                        fontSize: `${newTdWidth * 0.20}px`,
                        lineHeight: `${newTdWidth * 0.23}px`,
                    }
                );
            }

            Object.assign(
                this.$tdTargetEl.style, {
                    width: `${tdWidth}px`,
                    height: `${tdHeight}px`,
                }
            );

            Object.assign(
                this.$divTargetEl.style, {
                    width: `${tdWidth}px`,
                    height: `${tdHeight}px`,
                }
            );

            Object.assign(
                this.$targetTitleEl.style, {
                    fontSize: `${tdWidth * 0.08}px`,
                    lineHeight: `${tdWidth * 0.15}px`,
                }
            );

            if (this.$targetTitleEl && this.$targetTitleEl.offsetWidth >= this.$tdTargetEl.offsetWidth) {

                let newTdWidth = tdWidth * ((this.$tdTargetEl.offsetWidth / this.$targetTitleEl.offsetWidth) * 0.9);

                Object.assign(
                    this.$targetTitleEl.style, {
                        fontSize: `${newTdWidth * 0.08}px`,
                    }
                );
            }

            if (this.$targetValueEl) {
                Object.assign(
                    this.$targetValueEl.style, {
                        fontSize: `${tdWidth * 0.20}px`,
                        lineHeight: `${tdWidth * 0.23}px`,
                    }
                );
            }

            if (this.$targetValueEl && this.$targetValueEl.offsetWidth >= this.$tdTargetEl.offsetWidth) {

                let newTdWidth = tdWidth * ((this.$tdTargetEl.offsetWidth / this.$targetValueEl.offsetWidth) * 0.9);

                Object.assign(
                    this.$targetValueEl.style, {
                        fontSize: `${newTdWidth * 0.20}px`,
                        lineHeight: `${newTdWidth * 0.23}px`,
                    }
                );
            }

        }

        if (type === 'IMAGE' || type === 'AVATAR') {
            Object.assign(this.$imgEl.style, {
                width: `${width * percentIconContainer}px`,
                height: `${this.props.height}px`,
            });
        } else if (type === 'ICON') {
            Object.assign(
                this.$iconEl.style, {
                    fontSize: `${(width * percentIconContainer) * (0.7)}px`,
                    lineHeight: `${(width * percentIconContainer) * (0.8)}px`
                }
            );

            if (this.$divIconEl && this.$divIconEl.offsetHeight >= this.$tableSquareEl.offsetHeight) {
                let newTdWidth = width * ((this.$tableSquareEl.offsetHeight / this.$divIconEl.offsetHeight) * 0.9);

                Object.assign(
                    this.$iconEl.style, {
                        fontSize: `${(newTdWidth * percentIconContainer) * (0.7)}px`,
                        lineHeight: `${(newTdWidth * percentIconContainer) * (0.8)}px`
                    }
                );
            }

        }
    };

    render() {
        return (
            <table ref={el => this.$tableSquareEl = el} className="infobox"
                   style={{border: 'none', backgroundColor: this.state.color, margin: 0, padding: 0}}>
                <tbody>
                <tr>
                    <td ref={el => this.$tdIconEl = el}
                        style={{backgroundColor: 'hsla(50, 0%, 20%, 0.5)'}}>
                        <div ref={el => this.$divIconEl = el}>
                            <center>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <div id={`icon${this.state.kpiId}`}
                                                 style={{fontWeight: 'normal', textAlign: 'center'}}>
                                                {this.imageFragment(this.props.imageType)}
                                            </div>
                                        </td>
                                    </tr>
                                    {this.props.model !== 'SQUAREPERCENT' &&
                                    <tr>
                                        <td>
                                            <div style={{textAlign: 'center', fontWeight: 'bold', color: '#ffffff'}}>
                                                {this.viewPercent()}
                                            </div>
                                        </td>

                                    </tr>
                                    }
                                    </tbody>
                                </table>
                            </center>
                        </div>
                    </td>
                    <td ref={el => this.$containerBandsEl = el}>
                        <div>
                            {this.renderRightFragment()}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

}

