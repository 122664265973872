export default {
  position(point, params, dom, rect, size) {
    const tooltipWidth = dom.clientWidth;
    const tooltipHeight = dom.clientHeight;

    // calculate the quadrant x of the graphic element
    const quadrantX = rect.x + rect.width / 2 > size.viewSize[0] / 2 ? 'right' : 'left';

    // adjust the tooltip position based on the quadrant
    let tooltipX, tooltipY;
    if (quadrantX === 'right') {
      tooltipX = rect.x - tooltipWidth;
    } else {
      tooltipX = rect.x + rect.width;
    }

    tooltipY = rect.y - tooltipHeight;

    return [tooltipX, tooltipY];
  },
};
