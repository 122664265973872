// Samples:
// Dia: 		{id: '[Periodo.(Dia)].[27/01/2023]', caption: '27/01/2023'}
// Ano: 		{id: '[Periodo].[2023]', caption: '2023'}
// Dia do mês:  {id: '[Periodo.(DiaMes)].[31]', caption: '31'}
// Mês:		    {id: '[Periodo.(Mes)].[202301]', caption: 'JAN/2023'}
// Semana:		{id: '[Periodo.(Semana)].[202304]', caption: '4 SEMANA/2023'}
// Semestre: 	{id: '[Periodo.(Semestre)].[202301]', caption: '1 SEMESTRE/2023'}
// Trimestre:	{id: '[Periodo.(Trimestre)].[202301]', caption: '1 TRIMESTRE/2023'}

const extractValue = (nodeValue) => {
  return nodeValue.id.split('].[')[1].slice(0, -1);
};

const extractHier = (nodeValue) => {
  let hier = nodeValue.id.split('].[')[0];
  if (hier.includes('.(')) {
    hier = hier.slice(hier.indexOf('.(') + 2, -1);
  } else {
    return 'Ano';
  }
  return hier;
};

const PARSERS = {
  Dia: (nodeValue) => {
    const val = extractValue(nodeValue);
    return moment(val, 'DD/MM/YYYY');
  },
  DiaMes: (nodeValue) => {
    const val = extractValue(nodeValue);
    return moment(val, 'DD');
  },
  Mes: (nodeValue) => {
    const val = extractValue(nodeValue);
    return moment(val, 'YYYYMM');
  },
  Semana: (nodeValue) => {
    const val = extractValue(nodeValue);
    return moment(val, 'YYYYWW');
  },
  Semestre: (nodeValue) => {
    const val = extractValue(nodeValue);
    return moment(val, 'YYYYMM');
  },
  Trimestre: (nodeValue) => {
    const val = extractValue(nodeValue);
    return moment(val, 'YYYYMM');
  },
  Ano: (nodeValue) => {
    const val = extractValue(nodeValue);
    return moment(val, 'YYYY');
  },
};

const findDateParser = (nodeValue) => {
  const hier = extractHier(nodeValue);
  return PARSERS[hier];
};

export default function DateSort(valueA, valueB) {
  let valueParser;
  if (!valueA.$moment || !valueB.$moment) {
    valueParser = findDateParser(valueA);
  }

  if (!valueA.$moment) {
    valueA.$moment = valueParser(valueA);
  }

  if (!valueB.$moment) {
    valueB.$moment = valueParser(valueB);
  }

  if (valueA.$moment.isSame(valueB.$moment)) {
    return 0;
  }
  return valueA.$moment.isBefore(valueB.$moment) ? -1 : 1;
}
