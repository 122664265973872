import { Axios } from 'commonUtils';

class EventsApi {
  async findConfig() {
    const { data } = await Axios.get(`/spr/events`);
    return data;
  }

  async reloadEvents() {
    const { data } = await Axios.get(`/spr/events/reload`);
    return data;
  }
}

export default EventsApi;
