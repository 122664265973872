import styles from './TestConnectionDialog.module.css';

import React, { useEffect, useState } from 'react';
import { DefaultDialogActions } from 'components/ui/FormUtils';

import Dialog from 'components/ui/Dialog';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import StackTraceDialog from 'components/bng/pages/errors/StackTraceDialog';
import useBimContext from 'components/hooks/useBimContext';

const CONNECTION_TESTS = [
  {
    isDb: true,
    text: 'access.database.application.server',
    method: async ({ connectionInfo }) => await Api.Connection.testDb(connectionInfo),
  },
  {
    isDb: true,
    text: 'access.database.task.service',
    method: async ({ connectionInfo }) => await Api.Connection.testDbOnBimCron(connectionInfo),
  },
  {
    text: 'access.port.application.server',
    method: async ({ connectionInfo }) => {
      const { host, port } = extractHostAndPortFromUrl(connectionInfo.url);
      await Api.Connection.testHost(host, port);
    },
  },
  {
    text: 'access.port.task.service',
    method: async ({ connectionInfo }) => await Api.Connection.testHostOnBimCron(connectionInfo),
  },
  {
    text: 'internet.access',
    method: async () => await Api.Connection.testInternetAccess(),
  },
];

export default function TestConnectionDialog({ closeModal, connectionInfo, forReplication }) {
  const context = useBimContext();

  const [connectionTests, setConnectionTests] = useState(
    connectionInfo.projectExtractorId
      ? CONNECTION_TESTS.filter((ct, index) => index !== 2 && index !== 3).map((ct) => ({
          ...ct,
          statusIcon: 'sync',
          error: false,
          errorDetails: '',
        }))
      : CONNECTION_TESTS.map((ct) => ({ ...ct, statusIcon: 'sync', error: false, errorDetails: '' }))
  );

  useEffect(() => {
    // Espera 2 segundos para começar os testes
    setTimeout(() => {
      runTests();
    }, 2000);
  }, []);

  const runTests = async () => {
    let databaseAccess = 0;
    const params = { connectionInfo, forReplication };
    for (const connectionTest of connectionTests) {
      try {
        await connectionTest.method(params);
        connectionTest.statusIcon = 'done';
        if (connectionTest.isDb && connectionTest.statusIcon === 'done') {
          databaseAccess++;
        }
        if (databaseAccess === 2) break;
      } catch (e) {
        connectionTest.statusIcon = 'close';
        connectionTest.error = true;
        connectionTest.errorDetails = handlerError(e.response);
        if (e.response?.status === 409) {
          connectionTest.statusIcon = 'done';
          connectionTest.error = false;
        }
      } finally {
        setTimeout(() => {
          setConnectionTests(connectionTests.slice());
        }, 1000);
      }
    }

    if (databaseAccess === 2) {
      connectionTests
        .filter((test) => test.statusIcon !== 'done')
        .forEach((test, index) => {
          setTimeout(() => {
            test.statusIcon = 'done';
            setConnectionTests(connectionTests.slice());
          }, 1000 * index);
        });
    }
  };

  const openStackTraceDialog = () => {
    const errorDetails = connectionTests
      .filter((ct) => ct.error)
      .map((ct) => `${context.msg.t(ct.text)}: ${ct.errorDetails || '-'}`)
      .join('\n');
    StackTraceDialog({
      title: 'Log',
      html: errorDetails,
    });
  };

  const containError = connectionTests.some((ct) => ct.error);
  const fullUrl = isFullUrl(connectionInfo.url);

  return (
    <Dialog title={context.msg.t('test.connection')} onClose={closeModal}>
      <Dialog.Body>
        <div className={`ConnectionTestContainer ${styles.container}`}>
          {connectionTests.map((connectionTest, idx) => {
            const isDone = connectionTest.statusIcon !== 'done' && connectionTest.statusIcon !== 'sync';
            return (
              <div className={`ConnectionTest ${styles.itemWrapper}`} key={idx}>
                <div className={`${styles.firstColumn}`}>
                  <span>{context.msg.t(connectionTest.text)}</span>
                </div>
                <div className={`${styles.centerColumn}`}>
                  <Icon icon={connectionTest.statusIcon} className={`${styles[connectionTest.statusIcon]}`} />
                </div>
                <div className={`${styles.lastColumn}`} style={{ visibility: `${isDone ? 'visible' : 'hidden'}` }}>
                  <a href={context.msg.t('connection.test.help.link')} target="_blank">
                    {context.msg.t('know.more')}
                  </a>
                </div>
              </div>
            );
          })}
          {containError && (
            <div className={`${styles.itemWrapper}`}>
              <div className={`${styles.logFirstColumn}`}>
                <Icon icon={'error_outline'} style={{ fontSize: '20px', marginRight: 'auto' }} />
                <div className={`${styles.textError}`}>{context.msg.t('connection.error')}</div>
              </div>
              <div className={`${styles.logLastColumn}`}>
                <div className={`${styles.visualizeLog}`} onClick={openStackTraceDialog}>
                  <span>{context.msg.t('visualize.log')}</span>
                </div>
              </div>
            </div>
          )}
          {!fullUrl && (
            <div className={`${styles.itemWrapper}`}>
              <div className={`${styles.errorFullUrl} alert alert-danger`}>
                {context.msg.t('complete.information.url')}
              </div>
            </div>
          )}
        </div>
      </Dialog.Body>

      <Dialog.Footer>
        <DefaultDialogActions okLabel={'ok'} context={context} closeModal={closeModal} onClickSaveButton={closeModal} />
      </Dialog.Footer>
    </Dialog>
  );
}

const isFullUrl = (connectionUrl) => {
  try {
    const url = extractHostAndPortFromUrl(connectionUrl);
    return url.host && url.port;
  } catch (e) {
    console.error('Error on isFullUrl()', { connectionUrl }, e);
  }
  return false;
};

const handlerError = (response) => {
  if (response.data?.message) {
    return response.data.message;
  }

  return response.data;
};

const extractHostAndPortFromUrl = (connectionUrl) => {
  let host, port, split;
  switch (true) {
    case connectionUrl.startsWith('jdbc:oracle:thin:@//'):
      split = connectionUrl.split(':');
      host = split[3].split('/')[2];
      port = parseInt(split[4].split('/')[0]);
      break;
    case connectionUrl.startsWith('jdbc:oracle:thin:@'):
      split = connectionUrl.split(':');
      host = split[3].replace('@', '');
      port = parseInt(split[4]);
      break;
    case connectionUrl.startsWith('jdbc:jtds:sqlserver'):
      split = connectionUrl.split('://')[1].split(';')[0].split(':');
      host = split[0];
      port = parseInt(split[1]);
      break;
    case connectionUrl.startsWith('jdbc:datadirect:openedge'):
      split = connectionUrl.split('://')[1].split(';')[0].split(':');
      host = split[0];
      port = parseInt(split[1]);
      break;
    case connectionUrl.startsWith('jdbc:sybase'):
      split = connectionUrl.split(':Tds:')[1].split(':');
      host = split[0];
      port = parseInt(split[1].split('/')[0]);
      break;
    default:
      split = connectionUrl.split(':');
      host = split[2].replace('//', '');
      port = parseInt(split[3].replace(new RegExp('/\\w*', 'g'), ''));
      break;
  }

  return {
    host,
    port,
  };
};
