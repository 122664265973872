import "./GeoreferenceEditor.css";

import React from "react";
import PropTypes from 'prop-types';
import Button from "components/ui/Button";
import ContextEnhancer from "components/ContextEnhancer";

class GeoreferenceItem extends  React.Component {

    static propTypes = {
        valueX: PropTypes.any,
        valueY: PropTypes.any,
        itemName: PropTypes.any,
        executeSearchGeo: PropTypes.func,
        onSave: PropTypes.func,
    };

    state = {
        disableFields: true
    };

    constructor(props) {
        super(props);
    }

    render(){
        const {i, valueX, valueY, itemName, re} = this.props;
        return (
            <tr key={i} id={i}>
                <td style={{width:490}}>
                    <input id={"itemname"+i} name={"itemname"+i} type="text" className={"itemname"+i} disabled={true} defaultValue={itemName} style={{width:'calc(100% - 10px)', fontSize:12}}/>
                </td>
                <td style={{width:210}}>
                    <input id={"itemvalueX"+i} pattern="([0-9\-\.]+)"
                           style={{width:100,fontSize:12}}
                           name={"itemvalueX"+i}
                           disabled={this.state.disableFields}
                           type="text"
                           className={"itemvalueX"+i}
                           defaultValue={valueX}/>
                    <span> , </span>
                    <input id={"itemvalueY"+i} pattern="([0-9\-\.]+)"
                           disabled={this.state.disableFields}
                           style={{width:100,fontSize:12}}
                           name={"itemvalueY"+i}
                           type="text"
                           className={"itemvalueY"+i}
                           defaultValue={valueY}/>
                </td>
                <td style={{ width:50}}>
                    <Button style={{height:25, marginTop:2}}
                            onClick={() => {
                                if (!this.state.disableFields) {
                                    this.props.onSave();
                                }
                                this.setState({disableFields: !this.state.disableFields})
                            }}
                            className={`btn btn-primary iceCmdBtn btn-mini btn-table ${this.state.disableFields ? 'btn-warning' : 'btn-info'}`}
                            title={this.props.context.msg.t('edit.georeference')}
                            icon={`${this.state.disableFields ? 'icon-edit' : 'icon-save' }`}
                    />
                    <Button style={{height:25, marginTop:2,marginLeft:3}}
                            disabled={!re}
                            className="btn btn-primary iceCmdBtn btn-yellow btn-mini btn-table-api-geo"
                            title={this.props.context.msg.t('search.georeference')}
                            onClick={this.props.executeSearchGeo}
                            icon="icon-globe"
                    />
                </td>
            </tr>
        )
    }
}

export default ContextEnhancer(GeoreferenceItem);