import ContextEnhancer from "components/ContextEnhancer";
import React from "react";
import PropTypes from "prop-types";
import Checkbox from "components/ui/Checkbox";
import BngInputColor from "components/bng/form/BngInputColor";


class RenderCheckbox extends React.Component {


    static propTypes = {
        type: PropTypes.string,
        label: PropTypes.string,
        defaultValue: PropTypes.any,
        child: PropTypes.func,
        array: PropTypes.object,
        inputColor: PropTypes.bool,
        idx: PropTypes.any,
    };


    static defaultProps = {
        type: '',
        label: '',
        defaultValue: '',
        child: () => {},
        array: {},
        inputColor: false,
        idx: 0,
        onChange: _.noop,
    };

    render() {
        const {type, typeField, label, formValue, defaultValue, child, array, inputColor, idx, onChange} = this.props;
        const selected = idx !== -1;
        const Comp = child;
        const props = {
            size: 'md',
            field: {name: typeField, value: formValue},
            form: {
                setFieldValue: (name, value) => {
                    onChange(typeField, value)
                }
            }
        };

        return (
            <div>
                <Checkbox
                    selected={selected}
                    value={{value: '', label}}
                    onChange={(e) => {
                        if (selected) {
                            array.remove('elements', idx);
                        } else {
                            array.push('elements', {
                                type,
                                value: defaultValue
                            });
                        }
                    }}
                />




                {inputColor ? <BngInputColor disabled={!selected} {...props} /> :
                    <Comp field={`elements[${idx}].value`} disabled={!selected}/>
                }
            </div>
        );
    }

}

export default ContextEnhancer(RenderCheckbox);