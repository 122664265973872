import './ECharts.css';

import React from 'react';

import BubbleChart from 'components/bng/analysis/echarts/BubbleChart';
import TreeMap from 'components/bng/analysis/echarts/TreeMap';

const CHART_TYPES = {
  BUBBLE_CHART: BubbleChart,
  TREE_MAP: TreeMap,
};

function ChartNotSupported() {
  return <div>Chart not supported</div>;
}

export default function ECharts({ analysisData, queryResult, toolbarContainer, ...props }) {
  if (!analysisData || !queryResult) return null;

  const { assistedAnalysisData } = analysisData;

  const ChartComponent =
    CHART_TYPES[assistedAnalysisData?.params?.chartType] ??
    CHART_TYPES[assistedAnalysisData?.type] ??
    ChartNotSupported;

  return (
    <ChartComponent
      className="fill-h"
      queryResult={queryResult}
      analysisData={analysisData}
      toolbarContainer={toolbarContainer}
      {...props}
    />
  );
}
