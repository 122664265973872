import printValue from "yup/lib/util/printValue";

let mixed = {
    default: 'É inválido',
    required: 'Campo obrigatório',
    oneOf: 'Deve ser um dos seguintes valores: ${values}',
    notOneOf: 'Não deve ser um dos seguintes valores: ${values}',
    notType: ({ path, type, value, originalValue }) => {
        let isCast = originalValue != null && originalValue !== value;
        let msg =
            `Deve ser um \`${type}\` type, ` +
            `mas o valor final foi: \`${printValue(value, true)}\`` +
            (isCast
                ? ` (elenco do valor \`${printValue(originalValue, true)}\`).`
                : '.');

        if (value === null) {
            msg += `\n Se "nulo" é entendido como um valor vazio, certifique-se de marcar o esquema como \`.nullable()\``;
        }

        return msg;
    },
};

let string = {
    length: 'Deve ter exatamente ${length} caracteres',
    min: 'Deve ter pelo menos ${min} caracteres',
    max: 'Deve ter no máximo ${max} caracteres',
    matches: 'Deve corresponder ao seguinte: "${regex}"',
    email: 'Deve ser um email válido',
    url: 'Deve ser uma URL válida',
    trim: 'Deve ser um texto sem espaços adicionais',
    lowercase: 'Deve ser um texto em minúsculo',
    uppercase: 'Deve ser um texto em maiúsculo',
};

let number = {
    min: 'Deve ser maior ou igual a ${min}',
    max: 'Deve ser menor ou igual a ${max}',
    lessThan: 'Deve ser menor que ${less}',
    moreThan: 'Deve ser maior que ${more}',
    notEqual: 'Não deve ser igual a ${notEqual}',
    positive: 'Deve ser um número positivo',
    negative: 'Deve ser um número negativo',
    integer: 'Deve ser um inteiro',
};

let date = {
    min: 'Campo deve ser posterior a ${min}',
    max: 'Campo deve ser anterior a ${max}',
};

let boolean = {};

let object = {
    noUnknown: 'Campo não pode ter chaves não especificadas no formato do objeto',
};

let array = {
    min: 'Campo deve ter pelo menos ${min} itens',
    max: 'Campo deve ter menos do que ou igual a ${max} itens',
};

export default {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
};