import styles from './DimensionMembersList.module.css';

import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Icon from 'components/ui/common/Icon';
import Button from 'components/ui/Button';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import useId from 'components/hooks/useId';
import {
  FilterDropdownButton,
  useDimensionMembers,
} from 'components/bng/pages/newAnalysis/paramTypes/MemberFilterList';

const reorder = (members = [], startIndex = 0, endIndex = 0) => {
  const copy = members.slice();
  const [removed] = copy.splice(startIndex, 1);
  copy.splice(endIndex, 0, removed);
  return copy;
};

export function DimensionMemberList({
  className = '',
  context: { msg },
  name,
  label,
  props: { dimensionField = 'dimension' },
  formikProps: {
    values: { datasource, cube, ...values },
    setFieldValue,
  },
}) {
  const componentId = useId();
  const { data: membersCache, fetch: fetchMembersForDimension } = useDimensionMembers({ datasource, cube });

  const dimension = _.get(values, `params.${dimensionField}`) ?? [];
  const dimensionMembers = _.get(values, name) ?? [];

  const disableAddButton = _.isEmpty(dimension);
  const hasSelectedMembers = dimension && dimensionMembers.length > 0;

  const filterDropdownProps = {
    showDimensionSelector: false,
    mdxFilter: {
      dimension,
      members: dimensionMembers,
    },
    fetchMembersForDimension,
    onChange: (values) => {
      setFieldValue(name, values.members);
    },
  };

  return (
    <div className={`DimensionMemberList ${className}`}>
      <div className={styles.DimensionMembersHeader}>
        <label className="control-label">{label}</label>
        {hasSelectedMembers && (
          <FilterDropdownButton
            {...filterDropdownProps}
            customButton={({ openDropdown }) => {
              return (
                <div className={styles.EditButtonContainer}>
                  <BngIconButton id={componentId} icon="edit" className={'EditButton'} onClick={openDropdown} />
                  <label htmlFor={componentId}>{msg.t('edit')}</label>
                </div>
              );
            }}
          />
        )}
      </div>
      {!hasSelectedMembers && (
        <FilterDropdownButton
          {...filterDropdownProps}
          className="block"
          customButton={({ openDropdown }) => {
            return (
              <Button
                className={`bng-button save w-100 Action AddButton`}
                disabled={disableAddButton}
                onClick={openDropdown}
              >
                {msg.t('add.members')}
              </Button>
            );
          }}
        />
      )}
      <DragDropContext
        onDragEnd={async (result) => {
          if (!result.destination) {
            return;
          }

          const fromIdx = result.source.index;
          const toIdx = result.destination.index;
          if (fromIdx === toIdx) {
            return;
          }

          const newValue = reorder(dimensionMembers, fromIdx, toIdx);

          setFieldValue(name, newValue);
        }}
      >
        <Droppable droppableId={`${componentId}:droppable-visible`} direction="vertical">
          {(provided) => {
            return (
              <div ref={provided.innerRef} className={styles.DimensionMembersList}>
                {dimension &&
                  dimensionMembers?.map((member, idx) => {
                    const key = `dimensionMembers[${idx}]`;
                    const memberCaption =
                      membersCache[dimension]?.find((md) => md.uniqueName === member)?.caption ??
                      member.split('.')[1].slice(1, -1);
                    return (
                      <Draggable key={key} draggableId={key} index={idx}>
                        {(provided) => (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={styles.MemberItemContainer}
                            >
                              <div className={styles.MemberItem}>
                                <Icon className={styles.IconDragIndicator} icon="drag_indicator" />
                                <span>{memberCaption}</span>
                              </div>
                              <BngIconButton
                                icon="delete"
                                onClick={() => {
                                  const copy = dimensionMembers.slice();
                                  copy.splice(idx, 1);
                                  setFieldValue(name, copy);
                                }}
                              />
                            </div>
                            {provided.placeholder}
                          </>
                        )}
                      </Draggable>
                    );
                  })}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default {
  defaultVal() {
    return [];
  },
  render(props = {}) {
    return <DimensionMemberList {...props} />;
  },
};
