"use strict";

const $ = jQuery;


class MonitorApi {
    findData = (path) => {
        return Promise.resolve(
            $.getJSON(`/spr/central-monitoring/monitor/kpi?${j.param({path})}`)
        );
    }
}

export default MonitorApi;