import './Accordion.css';
import React, { useContext, useEffect, useState } from 'react';
import Icon from 'components/ui/common/Icon';
import { AccordionContext } from 'components/ui/AccordionList';

export default function Accordion({
  id = '',
  title = '',
  startOpen = true,
  className = '',
  customTitle = null,
  disabled = false,
  onClick = _.noop,
  onOpen = _.noop,
  children,
  preventToggle = false,
  afterTitle,
  sticky = false,
  containError = false,
  ...props
}) {
  const accordionContext = useContext(AccordionContext);
  const initialState = !!accordionContext ? accordionContext.getState(id) : startOpen;
  const [open, setOpen] = useState(initialState !== undefined ? initialState : startOpen);

  useEffect(() => {
    if (disabled && open) {
      accordionContext.handleChange({ key: id, value: false });
      setOpen(false);
    }
  }, [disabled]);

  const toggleAccordion = () => {
    if (disabled || preventToggle) return;
    onClick();
    if (!open) {
      onOpen();
    }
    accordionContext.handleChange({ key: id, value: !open });
    setOpen(!open);
  };

  return (
    <div
      id={id}
      className={`AccordionSideMenu ${disabled && 'AccordionDisabled'} ${
        open ? `AccordionOpen ${sticky ? 'sticky' : ''}` : 'AccordionClose'
      } ${containError ? 'containError' : ''} ${className}`}
      {...props}
    >
      {!!customTitle && customTitle(toggleAccordion)}
      {!customTitle && (
        <div className="AccordionTitle" onClick={toggleAccordion}>
          <Icon className="AccordionIcon" icon="arrow_drop_up" />
          <span className="AccordionDescription">{title}</span>
          {afterTitle}
        </div>
      )}
      {open &&
        <div className="AccordionContent">
          {_.isFunction(children) ? children({ open, toggleAccordion }) : children}
        </div>
      }
    </div>
  );
}
