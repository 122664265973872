import styles from './ExportSchedulingDetailsDialog.module.css';

import React, { useEffect, useState } from 'react';

import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import Utils from 'components/Utils';
import BngEmpty from 'components/bng/ui/BngEmpty';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import DetailsDialog from 'components/ui/common/DetailsDialog';

export default function ExportSchedulingDetailsDialog({ exportScheduling, closeModal }) {
  const { msg } = useBimContext();
  const [sched, setSched] = useState();

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const sched = await Api.ExportScheduling.findOne(exportScheduling.id, true);
      setSched(sched);
    } catch (e) {
      console.error('ExportSchedulingDetailsDialog.fetchDetails()', { e });
      UiMsg.ajaxError(null, e);
    }
  };

  return (
    <DetailsDialog
      className={`ExportSchedulingDetailsDialog`}
      createdBy={sched?.scheduling?.createdBy}
      createdAt={sched?.createdAt}
      updatedBy={sched?.scheduling?.updatedBy}
      updatedAt={sched?.updatedAt}
      closeModal={closeModal}
      titleComponent={() => {
        return (
          <div className={`TitleWrapper flex-center-items ${styles.titleWrapper}`}>
            <Icon icon="watch_later" className={`${styles.icon}`} />
            <div className={`${styles.identifier}`}>{exportScheduling.scheduling.identifier}</div>
          </div>
        );
      }}
      footerComponent={() => {
        return <div className={`${styles.footerMsg1}`}>{msg.t('export.scheduling.description.footer.message1')}</div>;
      }}
    >
      <div>
        <div className={`${styles.sendingInfos}`}>
          <div className={`${styles.sendingInfosLeftWrapper}`}>
            <div className={`${styles.sendingMethod}`}>
              <div className={`${styles.blueBall}`} />
              {`${msg.t('sending.method')}:`}
              <div>{sched?.messageType ? msg.t(sched.messageType) : '-'}</div>
            </div>
            <div className={`Frequency ${styles.type}`}>
              <div className={`${styles.blueBall}`} />
              {`${msg.t('frequency')}:`}
              <div>{sched?.scheduling?.type ? msg.t(sched.scheduling.type) : '-'}</div>
            </div>
            {sched?.scheduling?.monthDay !== null && sched?.scheduling?.type === "Monthly" ?  (
              <div className={`MonthDay ${styles.monthDay}`}>
                <div className={`${styles.blueBall}`} />
                {`${msg.t('month.day')}:`}
                {sched?.scheduling.monthDay === -1 ? (
                    <div>{msg.t('last.day')}</div>
                ) : (
                    <div>{sched?.scheduling.monthDay}</div>
                )}
              </div>
            ) : (
              <div className={`WeekDays ${styles.weekDays}`}>
                <div className={`${styles.blueBall}`} />
                {`${sched?.scheduling?.type === 'Weekly' ? msg.t('day') : msg.t('days')}:`}
                <div>{(sched?.scheduling?.weekDays ?? []).map((d) => moment().day(d).format('ddd')).join(', ')}</div>
              </div>
            )}
          </div>
          <div className={`${styles.sendingInfosRightWrapper}`}>
            <div className={`TimeInterval ${styles.timeInterval}`}>
              <div className={`${styles.blueBall}`} />
              {`${msg.t('at.time')}:`}
              <div className={`AtTime ${styles.atTime}`}>{`${sched?.scheduling?.starts} ${
                sched?.scheduling?.type === 'Interval' ? `${msg.t('until')} ${sched.scheduling.ends}` : ''
              }`}</div>
            </div>
            {sched?.scheduling?.type === 'Interval' && (
              <div className={`Interval ${styles.frequency}`}>
                <div className={`${styles.blueBall}`} />
                {`${msg.t('interval')}:`}
                <div>{sched.scheduling.interval}</div>
              </div>
            )}
          </div>
        </div>

        <div className={`${styles.objectReceiverListWrapper}`}>
          <div className={`${styles.objectList}`}>
            <div className={`${styles.objectListTitleWrapper}`}>
              <div className={`ObjectListCount ${styles.objectListCount}`}>{sched?.contents?.length ?? 0}</div>
              <div className={`objectListTitle ${styles.objectListTitle}`}>{msg.t('objects')}</div>
            </div>
            <div className={`${styles.listWrapper}`}>
              {_.isEmpty(sched?.contents) ? (
                <BngEmpty
                  isEmpty={true}
                  className={`ObjectListEmpty ${styles.objectListEmpty}`}
                  showTitle={false}
                  message={msg.t('export.scheduling.description.no.objects')}
                />
              ) : (
                sched.contents.map((item, idx) => (
                  <div key={idx} className={`ObjectListItem ${styles.objectListItem}`}>
                    <Icon
                      className={`${styles.objectListIcon}`}
                      icon={Icon.iconIdentifierForPath(item.path) ?? 'insert_drive_file'}
                    />
                    <div className={`ObjectListName ${styles.objectListName}`}>{`${msg.t(
                      Utils.Object.getObjectType(item.path)
                    )}: ${item.caption}`}</div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className={`${styles.receiverList}`}>
            <div className={`${styles.receiverListTitleWrapper}`}>
              <div className={`ReceiverListCount ${styles.receiverListCount}`}>{sched?.members?.length ?? 0}</div>
              <div className={`ReceiverListTitle ${styles.receiverListTitle}`}>
                {msg.t('export.wizard.label.recipients')}
              </div>
            </div>
            <div className={`${styles.receiverWrapper}`}>
              {_.isEmpty(sched?.members) ? (
                <BngEmpty
                  isEmpty={true}
                  className={`ReceiverListEmpty ${styles.receiverListEmpty}`}
                  showTitle={false}
                  message={msg.t('export.scheduling.description.no.user')}
                />
              ) : (
                sched.members.map((member) => {
                  return (
                    <div key={member.id} className={`ReceiverListItem ${styles.receiverListItem}`}>
                      <Icon icon={member.container ? 'people' : 'person'} />
                      <div className={`ReceiverListName ${styles.receiverListName}`} >{`${
                        Utils.Users.displayName(member.container ? member.userGroup : member.user) || '-'
                      }`}</div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </DetailsDialog>
  );
}
