import { Axios } from 'commonUtils';

class NewsApi {
  static async categories() {
    return await Axios.getData('/spr/bimnews/categories');
  }

  static async all(typeId, publicUrl = false) {
    return await Axios.getData(`${publicUrl ? '/api' : '/spr'}/bimnews/news`, {
      params: {
        typeId,
      },
    });
  }

  static async allPublicNews({ language = 'pt-br', filters = [], page = null, pageSize = null, neededInfo = [] }) {
    return await Axios.getData(`/api/bimnews/public-news`, {
      params: {
        language,
        filters: _.isArray(filters) ? filters.join(',') : filters,
        page,
        pageSize,
        neededInfo: _.isArray(neededInfo) ? neededInfo.join(',') : neededInfo,
      },
    });
  }

  static async bimAcademy() {
    return await Axios.getData('/spr/bimnews/highlights');
  }

  static async termsForLocale(locale) {
    return await Axios.getData(`/spr/bimnews/appEulas`, {
      params: {
        locale,
      },
    });
  }

  static async markAsRead() {
    return await Axios.getData('/spr/release/mark-as-read');
  }

  static async showReleaseNotes() {
    return await Axios.getData('/spr/release/is-read');
  }

  static async saveCensusResponse(censusResponse) {
    return await Axios.postData(`/spr/bimnews/census`, censusResponse);
  }
}

export default NewsApi;
