import React, {Component} from 'react';
import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import Api from "components/Api";
import Popper from "components/bng/ui/BngPopper";
import BngClickOutsideOverlay from "components/bng/ui/BngClickOutsideOverlay";
import {MODALS} from "components/ui/redux/Actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import BngPasswordConfirmation from "components/bng/ui/BngPasswordConfirmation";


const projectTypes = [{type: 'Template', badge: 'badge-warning', icon: 'icon-sitemap'},
    {type: 'Demo', badge: 'badge-important', icon: 'icon-bullseye'},
    {type: 'Production', badge: 'badge-primary', icon: 'icon-check'},
    {type: 'Homologation', badge: 'badge-grey', icon: 'icon-bug'}];


class ProjectTypeChangeButton extends Component {


    static propTypes = {
        projectType: PropTypes.string,
        container: PropTypes.any,
    };

    static defaultProps = {
        projectType: '',
    };

    state = {
        open: false,
    };

    constructor(props) {
        super(props);
    }

    renderSpan = (type) => {
        const projectType = projectTypes.find(t => t.type === type);
        return <span style={{fontSize: 10}}
                     className={`badge ${projectType.badge}`}>{this.props.context.msg.t(projectType.type)}</span>
    };

    openOptions = (event) => {
        this.setState({
            popperRef: event.currentTarget,
            open: true,
        })
    };


    changeProjectType = async (value) => {
        try {
            const result = await Api.Project.changeProjectType(value);
            window.location.href = Api.Bng.accessProjectUrl(result);
        } catch (e) {
            console.error('Error on changeProjectType()', e);
        }
    };

    openPasswordConfirmation = (value) => {
        this.props.dispatch(MODALS.open(BngPasswordConfirmation, {onConfirm: () => this.changeProjectType(value)}));
    };

    createItems = (projectType, i) => {
        if (projectType.type !== this.props.projectType) {
            return (
                <li key={i} style={{paddingTop: 10, paddingLeft: 10}} className="li-dropdown" onClick={() => {
                    this.openPasswordConfirmation(projectType.type)
                }}>
                    <div className="publisher-buttons">
                        <Button style={{width: 120, textAlign: 'left', paddingTop: 5}} icon={projectType.icon}
                                title={this.props.context.msg.t('edit')}
                                className="btn-only-icon"
                        >{this.props.context.msg.t(projectType.type)}</Button>
                    </div>
                </li>
            )
        } else {
            return;
        }
    };

    render() {

        const {projectType, container, } = this.props;

        return (
            <div>
                {this.renderSpan(projectType)}

                <Button className='btn-only-icon' type="button" icon='icon-gear' onClick={this.openOptions}
                        style={{marginLeft: 10}}/>
                <a className="dropdown-menu-tst">
                    <Popper container={container} className="bng-dropdown-parent"
                            open={this.state.open}
                            anchorEl={this.state.popperRef}
                            modifiers={{
                                preventOverflow: {
                                    boundariesElement: container,
                                }
                            }}>
                        <BngClickOutsideOverlay onClick={() => this.setState({open: false})} container={container}/>
                        <ul style={{marginTop: 10, marginRight: -55, width: 150}} className="bng-dropdown">
                            {projectTypes.map(this.createItems)}
                        </ul>
                    </Popper>
                </a>
            </div>

        )

    }
}

export default connect()(ContextEnhancer(ProjectTypeChangeButton));