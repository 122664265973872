import './ContainerDescription.css';

import React from 'react';
import PropTypes from 'prop-types';

import ContextEnhancer from 'components/ContextEnhancer';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';

class ContainerDescription extends React.Component {
  static propTypes = {
    description: PropTypes.string,
    titleContainer: PropTypes.string,
    icon: PropTypes.string,
    buttonStyle: PropTypes.object,
  };

  static defaultProps = {
    description: '',
    titleContainer: '',
    icon: '',
  };

  constructor(props) {
    super(props);
  }

  getBoundaryElement = () => {
    return j('#body-wrapper')[0] || j('#page-content, #cockpit-item-panel')[0];
  };

  render() {
    const { icon, titleContainer, description, buttonStyle } = this.props;
    return (
      <div className="ContainerDescription">
        <BngDropdown
          icon="info_outline"
          popperOpts={{ placement: 'bottom-start' }}
          boundariesElement={this.getBoundaryElement()}
          popperFlipBehaviour={['bottom-start', 'top-start']}
          closeOnSelect={false}
          className={`DashboardItemMenuDropdown`}
          popperClassName="container-description-popper dashboard-item-popper"
          btnIconProps={{ style: buttonStyle }}
          customOptions={({}) => (
            <ul
              className={`bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-last-update DescriptionMenuItem`}
            >
              <li>
                <div className="TitleObject">
                  <div className="TitleContainer">
                    {icon && <Icon icon={icon} />}
                    <span>{titleContainer}</span>
                  </div>
                </div>
                <div className="DescriptionObject">
                  <span dangerouslySetInnerHTML={{ __html: description }}></span>
                </div>
              </li>
            </ul>
          )}
        />
      </div>
    );
  }
}

export default ContextEnhancer(ContainerDescription);
