import { forwardRef, useMemo } from 'react';
import ColorUtils from 'components/bng/colorPicker/ColorUtils';
import styles from 'components/bng/colorPicker/BngColorPickerPreview.module.css';

function BngColorPickerPreview({ color, gradientPreview = false, buttonSize, btnContainerClass, btnClass }, ref) {
  const generatedButtonImage = useMemo(() => {
    const size = {
      width: buttonSize - 4,
      height: buttonSize - 4,
    };

    if (gradientPreview && color?.colors?.length > 1) {
      const bgImgString = ColorUtils.genTransparentGradientImg(color.colors, color.rotation);
      return {
        backgroundImage: bgImgString,
        backgroundOrigin: 'border-box',
        ...size,
      };
    } else {
      let colorToUse = gradientPreview && color?.colors?.length > 0 ? color?.colors[0] : color;
      if (!colorToUse) {
        colorToUse = {
          color: '#FFFFFF',
          opacity: 1,
        }
      }
      return {
        backgroundColor: colorToUse.color,
        opacity: colorToUse.opacity,
        ...size,
      };
    }
  }, [color]);

  return (
    <div
      className={`BngColorPickerPreview ${styles.colorPickerButtonTransparentContainer} ${btnContainerClass ?? ''}`}
      style={{
        width: buttonSize,
        height: buttonSize,
      }}
    >
      <div className={`${styles.colorPickerButton} ${btnClass ?? ''}`} style={{ ...generatedButtonImage }} ref={ref} />
    </div>
  );
}

export default forwardRef(BngColorPickerPreview);
