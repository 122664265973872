import React from 'react';
import ContextEnhancer from "components/ContextEnhancer";
import {BngDropdown} from "components/bng/ui/BngDropdown";
import {RightMenuItem} from "components/ui/right-menu/RightMenu";
import MenuDashboardDropdown from "components/ui/right-menu/items/MenuDashboard";


const MenuDashboardMenuItem = (props) => {
    return (
        <BngDropdown
            className="MenuDashboardMenuItem"
            popperClassName="RightMenuOptionPopper"
            boundariesElement={j('#page-content')[0]}
            popperOpts={{placement: "left"}}
            customButton={({openDropdown}) => {
                return <RightMenuItem {...props}
                                      onClick={openDropdown}
                                      className="MenuDashboardMenuItem"
                                      title={props.context.msg.t('add.object.to.dashboard')}
                                      icon="apps"/>
            }}
            customOptions={({closeDropdown}) => {
                return <MenuDashboardDropdown closeDropdown={closeDropdown}
                                              path={props.path}
                                              beanActiveTab={props.beanActiveTab}
                                              showChart={props.showChart}
                                              showTable={props.showTable}
                />
            }}
        />

    );
};

export default ContextEnhancer(MenuDashboardMenuItem);

