import styles from './ProjectStyleTab.module.css';

import React, { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';

import BngField from 'components/bng/form/BngField';
import BngSelect from 'components/bng/form/BngSelect';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngColorPickerDropdown from 'components/bng/colorPicker/BngColorPickerDropdown';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Api from 'components/Api';
import useTranslation from 'components/hooks/useTranslation';
import BngLineInputColor from 'components/bng/form/BngLineInputColor';
import BngColorListReorderDialog from 'components/bng/colorPicker/BngColorListReorderDialog';
import BngImageUpload from 'components/bng/form/BngImageUpload';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useBimContext from 'components/hooks/useBimContext';
import { MODALS } from 'components/ui/redux/Actions';
import { HIGHLIGHT_COLORS, LabelHelper, LAYOUT_DIVISIONS, THEMES } from 'components/ui/dashboard/DashLayoutAccordion';
import { BngSlider } from 'components/bng/form/BngSlider';
import { BngInput } from 'components/bng/form/BngInput';

const ApplicationThemeSchemas = [
  { name: '', imageSource: '/resources/images/layouts/thumbs/blue.png', color: '#438EB9' },
  { name: 'skin-1', imageSource: '/resources/images/layouts/thumbs/black.png', color: '#222A2D' },
  { name: 'skin-2', imageSource: '/resources/images/layouts/thumbs/yellow.png', color: '#e89702' },
  { name: 'skin-3', imageSource: '/resources/images/layouts/thumbs/grey.png', color: '#D0D0D0' },
  { name: 'skin-4', imageSource: '/resources/images/layouts/thumbs/red.png', color: '#CE171F' },
  { name: 'skin-5', imageSource: '/resources/images/layouts/thumbs/green.png', color: '#4DB748' },
];

const DefaultTableLayouts = [
  { name: 'default', imageSource: '/resources/images/table-models/default.png' },
  { name: 'clean', imageSource: '/resources/images/table-models/clean.png' },
  { name: 'blue', imageSource: '/resources/images/table-models/blue.png' },
  { name: 'red', imageSource: '/resources/images/table-models/red.png' },
  { name: 'green', imageSource: '/resources/images/table-models/green.png' },
  { name: 'dark', imageSource: '/resources/images/table-models/dark.png' },
  { name: 'silver', imageSource: '/resources/images/table-models/silver.png' },
  { name: 'simple-line', imageSource: '/resources/images/table-models/simple-line.png' },
  { name: 'modern-1', imageSource: '/resources/images/table-models/modern-1.png' },
  { name: 'modern-2', imageSource: '/resources/images/table-models/modern-2.png' },
  { name: 'modern-3', imageSource: '/resources/images/table-models/modern-3.png' },
  { name: 'modern-4', imageSource: '/resources/images/table-models/modern-4.png' },
  { name: 'modern-5', imageSource: '/resources/images/table-models/modern-5.png' },
  { name: 'modern-6', imageSource: '/resources/images/table-models/modern-6.png' },
  { name: 'modern-7', imageSource: '/resources/images/table-models/modern-7.png' },
];

const buildRowSeparators = (t) => {
  return [
    { value: 'NONE', label: t('none') },
    { value: 'SOLID', label: t('solid') },
    { value: 'DOTTED', label: t('dotted') },
    { value: 'DASHED', label: t('dashed') },
  ];
};

const buildLineHeights = (t) => {
  return [
    { value: 'small', label: t('size.small') },
    { value: 'medium', label: t('size.medium') },
    { value: 'big', label: t('size.big') },
  ];
};

const PaperSizes = [
  { value: 'A4', label: 'A4' },
  { value: 'A3', label: 'A3' },
  { value: 'A2', label: 'A2' },
];

const buildOrientationTypes = (t) => {
  return [
    { value: 'portrait', label: t('printer.portrait') },
    { value: 'landscape', label: t('printer.landscape') },
  ];
};

const buildPaletteSchemes = (t) => {
  return [
    {
      value: 'default',
      label: t('project.style.colorPalette.option.default'),
      palette: [
        '#528DE3',
        '#EB354D',
        '#269C59',
        '#E9B23A',
        '#AC4FC6',
        '#16879E',
        '#DA478B',
        '#E9633A',
        '#5FA4C4',
        '#5B5FD6',
        '#79A93B',
        '#EA9E33',
      ],
    },
    {
      value: 'blue',
      label: t('project.style.colorPalette.option.blue'),
      palette: [
        '#5B5FD6',
        '#528DE3',
        '#5FA4C4',
        '#16879E',
        '#269C59',
        '#79A93B',
        '#FEC447',
        '#ED8C30',
        '#E9633A',
        '#EB354D',
        '#DA478B',
        '#AC4FC6',
      ],
    },
    {
      value: 'green',
      label: t('project.style.colorPalette.option.green'),
      palette: [
        '#79A93B',
        '#269C59',
        '#16879E',
        '#5FA4C4',
        '#528DE3',
        '#5B5FD6',
        '#AC4FC6',
        '#DA478B',
        '#EB354D',
        '#E9633A',
        '#ED8C30',
        '#FEC447',
      ],
    },
    {
      value: 'red',
      label: t('project.style.colorPalette.option.red'),
      palette: [
        '#EB354D',
        '#E9633A',
        '#ED8C30',
        '#FEC447',
        '#79A93B',
        '#269C59',
        '#16879E',
        '#5FA4C4',
        '#528DE3',
        '#5B5FD6',
        '#AC4FC6',
        '#DA478B',
      ],
    },
    {
      value: 'yellow',
      label: t('project.style.colorPalette.option.yellow'),
      palette: [
        '#FEC447',
        '#ED8C30',
        '#E9633A',
        '#EB354D',
        '#DA478B',
        '#AC4FC6',
        '#5B5FD6',
        '#528DE3',
        '#5FA4C4',
        '#16879E',
        '#269C59',
        '#79A93B',
      ],
    },
    {
      value: 'shadesBlue',
      label: t('project.style.colorPalette.option.shadesBlue'),
      palette: [
        '#4780B8',
        '#7AACD2',
        '#A7C8DE',
        '#CADAED',
        '#D55F2B',
        '#ED934F',
        '#F2B176',
        '#F6D2A8',
        '#55A15C',
        '#87C27F',
        '#ADD7A1',
        '#CEE8C3',
        '#736BAC',
        '#9D9AC5',
        '#BCBDD9',
        '#DADAEA',
        '#626363',
        '#979696',
        '#BDBDBD',
        '#DAD9D9',
      ],
    },
    {
      value: 'shadesGreen',
      label: t('project.style.colorPalette.option.shadesGreen'),
      palette: [
        '#55A15C',
        '#87C27F',
        '#ADD7A1',
        '#CEE8C3',
        '#4780B8',
        '#7AACD2',
        '#A7C8DE',
        '#CADAED',
        '#736BAC',
        '#9D9AC5',
        '#BCBDD9',
        '#DADAEA',
        '#D55F2B',
        '#ED934F',
        '#F2B176',
        '#F6D2A8',
        '#626363',
        '#979696',
        '#BDBDBD',
        '#DAD9D9',
      ],
    },
    {
      value: 'shadesPurple',
      label: t('project.style.colorPalette.option.shadesPurple'),
      palette: [
        '#736BAC',
        '#9D9AC5',
        '#BCBDD9',
        '#DADAEA',
        '#D55F2B',
        '#ED934F',
        '#F2B176',
        '#F6D2A8',
        '#4780B8',
        '#7AACD2',
        '#A7C8DE',
        '#CADAED',
        '#55A15C',
        '#87C27F',
        '#ADD7A1',
        '#CEE8C3',
        '#626363',
        '#979696',
        '#BDBDBD',
        '#DAD9D9',
      ],
    },
    {
      value: 'shadesRed',
      label: t('project.style.colorPalette.option.shadesRed'),
      palette: [
        '#D55F2B',
        '#ED934F',
        '#F2B176',
        '#F6D2A8',
        '#736BAC',
        '#9D9AC5',
        '#BCBDD9',
        '#DADAEA',
        '#55A15C',
        '#87C27F',
        '#ADD7A1',
        '#CEE8C3',
        '#4780B8',
        '#7AACD2',
        '#A7C8DE',
        '#CADAED',
        '#626363',
        '#979696',
        '#BDBDBD',
        '#DAD9D9',
      ],
    },
  ];
};

export default function ProjectStyleTab({ theme }) {
  const { t } = useTranslation();
  const { values, setFieldValue, handleChange } = useFormikContext();
  const dispatch = useReduxDispatch();
  const context = useBimContext();

  const paletteSchemes = useMemo(() => {
    return [{ ...theme?.customColorScheme, label: t(theme?.customColorScheme.label) }, ...buildPaletteSchemes(t)];
  }, [theme?.customColorScheme]);

  const selectedPaletteColors = useMemo(
    () =>
      values.selectedPaletteColors?.palette !== undefined ? values.selectedPaletteColors : buildPaletteSchemes(t)[0],
    [values.selectedPaletteColors]
  );

  const manipulateColors = (color, action, idx) => {
    const temp = _.cloneDeep(selectedPaletteColors);
    if (action === 'add') {
      temp.palette.push(color.colors[0].color);
    } else if (action === 'update') {
      temp.palette[idx] = color.colors[0].color;
    } else if (action === 'remove') {
      temp.palette.splice(idx, 1);
    }
    setFieldValue('selectedPaletteColors', temp);
  };

  if (theme === undefined) {
    return null;
  }

  return (
    <div className={`ProjectStyleTab ${styles.StyleWrapper}`}>
      <div className={`${styles.ApplicationTheme}`}>
        <h6>{t('project.style.applicationTheme.title')}</h6>
        <div className={`${styles.ApplicationThemeDescription}`}>{t('project.style.applicationTheme.description')}</div>
        <div className={`${styles.ApplicationThemeOptions}`}>
          {ApplicationThemeSchemas.map((s) => {
            const selected = (values?.colorScheme || '') === s.name;
            return (
              <input
                key={s.name}
                type="button"
                name="colorScheme"
                className={`${selected ? 'selected' : ''}`}
                disabled={selected}
                onClick={(e, event) => handleChange({ ...event, target: { name: 'colorScheme', value: s.name } })}
                style={{ backgroundImage: `url(${Api.baseUrl()}${s.imageSource})` }}
              />
            );
          })}
        </div>
      </div>
      <div className={`${styles.PaletteColor}`}>
        <h6>{t('project.style.colorPallet.title')}</h6>
        <div className={`${styles.ApplicationPaletteColorDescription}`}>
          {t('project.style.colorPallet.description')}
        </div>
        <div className={`${styles.PaletteColorsWrapper}`}>
          <div>
            <Field
              name="selectedPaletteColors"
              label={t('paletteColor')}
              onChange={(event) =>
                setFieldValue(
                  'selectedPaletteColors',
                  paletteSchemes.find((ps) => ps.value === event.target.value)
                )
              }
              value={values.selectedPaletteColors.value}
              component={BngField}
              inputComponent={BngSelect}
              emptyOption={false}
              showErrors={false}
              options={paletteSchemes}
            />
            <div>
              {selectedPaletteColors.value === 'custom' ? (
                <>
                  {selectedPaletteColors.palette.map((p, idx) => (
                    <BngColorPickerDropdown
                      key={idx}
                      color={{ colors: [{ color: p }] }}
                      footerSlot={({ color, closeDropdown }) => {
                        return (
                          <div className="flex-center-items pl-3 pr-3 pb-1">
                            <BngIconButton
                              className="ml-auto"
                              icon="done"
                              title={t('update')}
                              onClick={() => {
                                manipulateColors(color, 'update', idx);
                                closeDropdown();
                              }}
                            />
                          </div>
                        );
                      }}
                      uncontrolled
                    />
                  ))}
                  <BngColorPickerDropdown
                    buttonIcon={() => <BngIconButton icon="add" />}
                    footerSlot={({ color, closeDropdown }) => {
                      return (
                        <div className="flex-center-items jc-end pl-3 pr-3 pb-1">
                          <BngIconButton
                            icon="add"
                            title={t('add')}
                            onClick={() => {
                              manipulateColors(color, 'add');
                              closeDropdown();
                            }}
                          />
                        </div>
                      );
                    }}
                    uncontrolled
                  />
                </>
              ) : (
                <>
                  {selectedPaletteColors.palette.map((p, idx) => (
                    <div key={idx} style={{ backgroundColor: p }} />
                  ))}
                </>
              )}
              <Field name="selectedPaletteColors" component={BngField} rootClassName={`${styles.HideField}`} />
            </div>
          </div>
        </div>
        {selectedPaletteColors.value === 'custom' && (
          <span
            className={styles.ToggleColorsListButton}
            onClick={() =>
              dispatch(
                MODALS.open(BngColorListReorderDialog, {
                  initialPalette: selectedPaletteColors.palette.slice(),
                  onChange: ({ value }) => {
                    setFieldValue('selectedPaletteColors', {
                      ...selectedPaletteColors,
                      palette: value,
                    });
                  },
                })
              )
            }
          >
            {t('color.palette.reorder.dialog')}
          </span>
        )}
      </div>
      <div className={`${styles.DefaultTableLayout}`}>
        <h6>{t('project.style.defaultTableLayout.title')}</h6>
        <div className={`${styles.DefaultTableLayoutOptions}`}>
          {DefaultTableLayouts.map((s) => {
            return (
              <input
                key={s.name}
                type="button"
                name="tableTheme"
                className={`${(values?.tableTheme || '') === s.name ? 'selected' : ''}`}
                disabled={(values?.tableTheme || '') === s.name}
                onClick={(e, event) => handleChange({ ...event, target: { name: 'tableTheme', value: s.name } })}
                style={{ backgroundImage: `url(${Api.baseUrl()}${s.imageSource})` }}
              />
            );
          })}
        </div>
        <div className={`${styles.LayoutOptions}`}>
          <div>
            <Field
              name="rowSeparator"
              label={t('analysis.border.style')}
              component={BngField}
              inputComponent={BngSelect}
              emptyOption={false}
              showErrors={false}
              options={buildRowSeparators(t)}
            />
            <Field
              name="tableRowLineSize"
              label={t('analysis.table.row.line.size')}
              component={BngField}
              inputComponent={BngSelect}
              emptyOption={false}
              showErrors={false}
              options={buildLineHeights(t)}
            />
          </div>
          <div>
            <Field
              name="tableFontSize"
              label={t('analysis.table.font.size')}
              component={BngField}
              type="number"
              min={1}
              max={45}
              step={1}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.DefaultPrintLayout}`}>
        <h6>{t('project.style.defaultPrintLayout.title')}</h6>
        <div className={`${styles.DefaultPrintLayoutDescription}`}>
          {t('project.style.defaultPrintLayout.description')}
        </div>
        <div className={`${styles.PrintingOptions}`}>
          <Field
            name="exportPageSize"
            label={t('printer.setup.paper.size')}
            component={BngField}
            inputComponent={BngSelect}
            emptyOption={false}
            showErrors={false}
            options={PaperSizes}
          />
          <Field
            name="exportPageOrientation"
            label={t('printer.setup.page.orientation')}
            component={BngField}
            inputComponent={BngSelect}
            emptyOption={false}
            showErrors={false}
            options={buildOrientationTypes(t)}
          />
        </div>
        <div className={`${styles.PageOptions}`}>
          <div>
            <Field
              name="showHeader"
              component={BngField}
              inputComponent={BngCheckbox}
              asProps={{ label: t('printer.setup.page.show.header') }}
              withLabel={false}
            />
            <Field
              name="showBackground"
              component={BngField}
              inputComponent={BngCheckbox}
              asProps={{ label: t('printer.setup.page.show.footer') }}
              withLabel={false}
            />
          </div>
          <div>
            <Field
              name="showFooter"
              component={BngField}
              inputComponent={BngCheckbox}
              asProps={{ label: t('printer.setup.page.number.show') }}
              withLabel={false}
            />
            <Field
              name="showPagination"
              component={BngField}
              inputComponent={BngCheckbox}
              asProps={{ label: t('printer.setup.page.show.background') }}
              withLabel={false}
            />
          </div>
        </div>
        <div className={`${styles.HeaderFontStyles}`}>
          <h6>{t('project.style.DefaultHeaderFontStyle.title')}</h6>
          <div>
            <Field
              name="titleSize"
              label={t('title.size')}
              component={BngField}
              type="number"
              min={1}
              max={45}
              step={1}
            />
            <Field
              name="descriptionSize"
              label={t('description.size')}
              component={BngField}
              type="number"
              min={1}
              max={45}
              step={1}
            />
            <Field
              name="filterSize"
              label={t('filter.size')}
              component={BngField}
              type="number"
              min={1}
              max={45}
              step={1}
            />
          </div>
        </div>
        <div className={`${styles.DashLayoutStyles}`}>
          <h6>{t('project.style.DashboardLayoutStyles.title')}</h6>
          <div className={`${styles.DashLayoutDescription}`}>{t('project.style.DashboardLayout.description')}</div>
          <div className={`${styles.PrintingOptions}`}>
            <Field
              label={t('theme')}
              name="dashLayoutTheme"
              component={BngField}
              inputComponent={BngSelect}
              emptyOption={false}
              options={THEMES(context)}
            />
            <Field
              label={t('highlight')}
              name="dashLayoutHighlightColor"
              component={BngField}
              inputComponent={BngLineInputColor}
              enablePicker={true}
              defaultOptions={[]}
              fetchProjectColors={false}
              addTransparentOptions={false}
              options={HIGHLIGHT_COLORS}
              showCustom={true}
            />
          </div>
          <div className={`${styles.PrintingOptions}`}>
            <div>
              <Field
                name="dashLayoutDivisions"
                label={<LabelHelper helpLabel={t('dashboard.divisions.hint')} label={t('divisions')} />}
                component={BngField}
                emptyOption={false}
                inputComponent={BngSelect}
                options={LAYOUT_DIVISIONS(t)}
              />
              <Field
                name="dashLayoutTransparency"
                label={<LabelHelper helpLabel={t('dashboard.transparency.hint')} label={t('transparency')} />}
                component={BngField}
                inputComponent={BngSlider}
              />
              <div className={`${styles.PrintingOptions}`}>
                <div>
                  <div className={`${styles.PrintingOptions}`}>
                    <Field
                      name="dashLayoutItemsShadow"
                      label={
                        <LabelHelper
                          helpLabel={t('dashboard.add.shadow.box.hint')}
                          label={t('dashboard.add.shadow.box')}
                        />
                      }
                      component={BngCheckbox}
                    />
                  </div>
                  <div className={`${styles.PrintingOptions}`}>
                    <Field name="dashLayoutBorderRadius" label={t('dash.border.rounded')} component={BngCheckbox} />
                  </div>
                </div>
                <div className={`${styles.MarginsContainer}`}>
                  <div className={`${styles.PrintingOptions}`}>
                    <Field
                      name="dashLayoutItemsMargin"
                      label={
                        <LabelHelper helpLabel={t('dashboard.add.margin.hint')} label={t('dashboard.add.margin')} />
                      }
                      component={BngCheckbox}
                    />
                    <Field
                      name="dashLayoutMargin"
                      type="number"
                      min={0}
                      max={10}
                      value={values.dashLayoutMargin}
                      onBlur={() => {
                        if (values.dashLayoutMargin > 10) {
                          setFieldValue('dashLayoutMargin', 10);
                        }
                        if (values.dashLayoutMargin < 0) {
                          setFieldValue('dashLayoutMargin', 0);
                        }
                      }}
                      component={BngInput}
                      className={`${styles.dashLayoutInputMargin}`}
                    />
                  </div>
                  <div className={`${styles.PrintingOptions}`}>
                    <Field
                      name="dashLayoutContainerMargin"
                      label={
                        <LabelHelper helpLabel={t('dash.container.margin.hint')} label={t('dash.container.margin')} />
                      }
                      component={BngCheckbox}
                    />
                    <Field
                      name="dashLayoutContainerMarginValue"
                      type="number"
                      min={0}
                      max={10}
                      value={values.dashLayoutContainerMarginValue}
                      onBlur={() => {
                        if (values.dashLayoutContainerMarginValue > 10) {
                          setFieldValue('dashLayoutContainerMarginValue', 10);
                        }
                        if (values.dashLayoutContainerMarginValue < 0) {
                          setFieldValue('dashLayoutContainerMarginValue', 0);
                        }
                      }}
                      component={BngInput}
                      className={`${styles.dashLayoutInputMargin}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Field
              label={t('background.image')}
              name="dashLayoutBackgroundImage"
              component={BngField}
              inputComponent={BngImageUpload}
              asProps={{ height: 150, width: '100%', useRelativeLink: true }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
