import useBimContext from 'components/hooks/useBimContext';
import React, { useMemo } from 'react';
import { createBrowserRouter, Navigate, Outlet, useMatches } from 'react-router-dom';

import DefaultLayout from 'components/layouts/DefaultLayout';
import ServerErrorPage from 'components/bng/pages/errors/ServerErrorPage';
import BngCockpitView from 'components/bng/pages/cockpit/BngCockpitView';
import DumpPage from 'components/bng/pages/admin/dump/DumpPage';
import PublicPageLayout from 'components/layouts/PublicPageLayout';
import ProjectInvitePage from 'components/bng/pages/project/invites/ProjectInvitePage';
import ChangePhonePage from 'components/bng/pages/phone/ChangePhonePage';
import ForbiddenErrorPage from 'components/bng/pages/errors/ForbiddenErrorPage';
import NotFoundErrorPage from 'components/bng/pages/errors/NotFoundErrorPage';
import Api from 'components/Api';
import CockpitManagement from 'components/bng/pages/cockpit/management/CockpitManagement';
import useFetchData from 'components/hooks/useFetchData';
import UiBlocker from 'components/bng/ui/UiBlocker';
import MarketplacePage from 'components/bng/pages/marketplace/MarketplacePage';
import FoldersPage from 'components/bng/pages/admin/folders/FoldersPage';
import AccountsPage from 'components/ui/accounts/AccountsPage';
import MarketplaceLayout from 'components/bng/pages/marketplace/MarketplaceLayout';
import SchedulingPage from 'components/bng/pages/exportScheduling/SchedulingPage';
import ProjectManagementPage from 'components/bng/pages/project/management/ProjectManagement';
import NewsPage from 'components/bng/pages/news/NewsPage';
import BimPublicLabs from 'components/ui/labs/BimPublicLabs';
import LoginPage from 'bng/pages/login/LoginPage';
import LoginAuthForm from 'bng/pages/login/LoginAuthForm';
import LoginTfaForm from 'bng/pages/login/LoginTfaForm';
import LoginPasswordRecoveryForm from 'bng/pages/login/LoginPasswordRecoveryForm';
import LoginTfaUnlockForm from 'bng/pages/login/LoginTfaUnlockForm';
import MapEditorContainer from 'components/ui/map/editor/MapEditorContainer';
import BimStorePage from 'components/ui/bimstore/BimStorePage';
import BimUniversityPage from 'components/ui/university/BimUniversityPage';
import UsersPage from 'components/bng/pages/admin/users/UsersPage';
import PersonalPage from 'components/ui/personal-page/PersonalPage';
import ProposalPage from 'components/bng/pages/marketplace/ProposalPage';
import BimQueryPage from 'components/bng/pages/admin/structures/bimQuery/BimQueryPage';
import DashboardPage from 'bng/pages/dashboard/DashboardPage';
import UiErrorHandler from 'bng/common/UiErrorHandler';
import NewDashboardPage from 'bng/pages/dashboard/NewDashboardPage';
import AdaAiManagementPage from 'components/bng/pages/admin/ada/ai/AdaAiManagementPage';
import BngNewAnalysis from 'components/bng/pages/newAnalysis/BngNewAnalysis';
import BngNewKpiPage from 'components/bng/pages/kpi/BngNewKpiPage';
import useEventBus from 'components/hooks/useEventBus';

export const VIEW = {
  Cockpit: 'Cockpit',
  Discover: 'Discover',
  EmptyPage: 'EmptyPage',
};

function useView() {
  const matches = useMatches();

  const view = useMemo(() => {
    return (
      matches
        .filter((match) => _.isString(match.handle?.view))
        .map((match) => match.handle?.view)
        .filter((view) => _.isString(view))[0] ?? VIEW.Discover
    );
  }, [matches]);

  return { view };
}

function ErrorHandler({ error }) {
  return <ServerErrorPage stackTrace={error?.message} />;
}

const processServerEvents = (() => {
  let running = false;

  return async () => {
    if (running) {
      return;
    }

    running = true;
    try {
      await Api.Event.getAndProcessEvents();
    } catch (e) {
      console.error('Error while processing events', e);
    } finally {
      running = false;
    }
  };
})();

function WrappedDefaultLayout() {
  const context = useBimContext();
  const { view } = useView();

  const renderChild =
    (context.cockpitEnabled && view === VIEW.Cockpit) || (!context.cockpitEnabled && view !== VIEW.Cockpit);
  // Check if context.cockpitEnabled is correct on the backend (legacy pages sometimes depend on it)
  // See: ToggleCockpitFilter.java
  const { isLoading } = useFetchData(async () => {
    if (!renderChild) {
      if (view === VIEW.Cockpit) {
        await Api.Cockpit.accessCockpit(false);
      } else {
        await Api.Bng.loadProjectInfo(context.project.name, true);
      }
      window.location.reload();
    }
  });

  if (!context.user) {
    return <Navigate to="/errors/403" />;
  }

  // TODO o ideal seria implementar um SSE aqui pra lidar com os eventos de maneira automatica,
  //  porém como não sei como o tomcat vai se comportar com uma conexão de sse pendurada para cada usuário
  //  implementei o disparo do 'evento' manualmente aonde necessário para o usuário ter esse 'feedback' no momento da ação.
  //  A alternativa ao SSE é fazer um pooling de tempo em tempo (o que não deve ser pesado pois esta tudo na sessão)
  //  See: https://github.com/sol7/bi-machine/issues/9411
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     processServerEvents();
  //   }, 60000);
  //
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  useEventBus(Api.Event.PROCESS_EVENTS, processServerEvents);

  return (
    <DefaultLayout view={view}>
      <UiBlocker id="wrapper" className="main-container" block={isLoading}>
        {renderChild && (
          <UiErrorHandler errorRenderer={ErrorHandler}>
            <Outlet />
          </UiErrorHandler>
        )}
      </UiBlocker>
    </DefaultLayout>
  );
}

function BngAppContent() {
  const { view } = useView();
  const matches = useMatches();

  const childClassNames = useMemo(() => {
    return [
      ...new Set(
        matches.map((match) => match.handle?.contentClassName ?? '').filter((className) => !_.isEmpty(className))
      ),
    ].join(' ');
  }, [matches]);

  return (
    <div className={`BngAppContent ${view} ${childClassNames}`}>
      <Outlet />
    </div>
  );
}

const routes = createBrowserRouter(
  [
    {
      element: <WrappedDefaultLayout />,
      errorElement: <ServerErrorPage />,
      children: [
        {
          element: <BngAppContent />,
          children: [
            {
              element: <MarketplaceLayout />,
              children: [
                {
                  path: '/marketplace',
                  element: <MarketplacePage />,
                  handle: { view: VIEW.EmptyPage },
                },
              ],
            },
            { path: '/cockpit', element: <BngCockpitView />, handle: { view: VIEW.Cockpit } },
            { path: '/accounts', element: <AccountsPage />, handle: { view: VIEW.EmptyPage } },
            { path: '/project/dump', element: <DumpPage /> },
            { path: '/project/cockpits', element: <CockpitManagement /> },
            { path: '/project/folders', element: <FoldersPage /> },
            { path: '/project/schedulings', element: <SchedulingPage /> },
            { path: '/project/users', element: <UsersPage /> },
            { path: '/project/management', element: <ProjectManagementPage /> },
            { path: '/project/structures/bimQuery', element: <BimQueryPage /> },
            { path: '/project/structures/bimQuery/:id/edit', element: <BimQueryPage /> },
            { path: '/labs', element: <BimPublicLabs />, handle: { view: VIEW.EmptyPage } },
            { path: '/newmap', element: <MapEditorContainer /> },
            { path: '/store', element: <BimStorePage />, handle: { view: VIEW.EmptyPage } },
            { path: '/university', element: <BimUniversityPage />, handle: { view: VIEW.EmptyPage } },
            { path: '/personal-page', element: <PersonalPage /> },
            {
              path: '/proposal',
              element: <ProposalPage />,
              handle: { contentClassName: 'ExternalPage', view: VIEW.EmptyPage },
            },
            { path: '/dashboard/new', element: <NewDashboardPage /> },
            { path: '/dashboard', element: <DashboardPage /> },
            { path: '/ada/ai', element: <AdaAiManagementPage />, handle: { view: VIEW.EmptyPage } },
            { path: '/analysis/new', element: <BngNewAnalysis /> },
            { path: '/kpi/new', element: <BngNewKpiPage />, },
            /*{ path: '/analysis', element: <BngAnalysisView /> },*/
            /*{ path: '/', element: <BngIndex /> },*/
          ],
        },
      ],
    },
    {
      element: (
        <UiErrorHandler errorRenderer={ErrorHandler}>
          <PublicPageLayout />
        </UiErrorHandler>
      ),
      handle: { view: VIEW.EmptyPage },
      errorElement: <ServerErrorPage />,
      children: [
        {
          element: <BngAppContent />,
          children: [
            { path: '/news', element: <NewsPage /> },
            {
              element: <LoginPage />,
              children: [
                {
                  path: '/login',
                  element: <LoginAuthForm />,
                },
                {
                  path: '/login/tfa',
                  element: <LoginTfaForm />,
                },
                {
                  path: '/login/tfa/unlock',
                  element: <LoginTfaUnlockForm />,
                },
                {
                  path: '/login/passwordRecovery',
                  element: <LoginPasswordRecoveryForm />,
                },
              ],
            },
            {
              path: '/updatePhone',
              element: <ChangePhonePage />,
              handle: {
                contentClassName: 'GreyBg',
              },
            },
            {
              path: '/projectInvite',
              element: <ProjectInvitePage />,
              handle: {
                contentClassName: 'GreyBg',
              },
            },
            {
              path: '/errors/500',
              element: <ServerErrorPage />,
            },
            {
              path: '/errors/403',
              element: <ForbiddenErrorPage />,
            },
            {
              path: '/errors/404',
              element: <NotFoundErrorPage />,
            },
            {
              path: '*',
              element: <NotFoundErrorPage />,
            },
          ],
        },
      ],
    },
  ],
  {
    basename: `${Api.apiPrefix()}/bng`,
  }
);

export default routes;
