import './ProjectMenuButton.css';
import Button from 'components/ui/Button';
import ContextEnhancer from 'components/ContextEnhancer';
import MenuButton from 'components/ui/navbar/MenuButton';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from 'components/Api';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';

class ProjectMenuButton extends Component {
  static propTypes = {
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        enabled: PropTypes.bool.isRequired,
        displayName: PropTypes.string.isRequired,
        canManage: PropTypes.bool.isRequired,
        projectType: PropTypes.shape({
          type: PropTypes.string.isRequired,
          badge: PropTypes.string.isRequired,
        }),
        theme: PropTypes.shape({ favIcon: PropTypes.string.isRequired }),
      })
    ),
    badgeValue: PropTypes.number,
  };

  static defaultProps = {
    projects: [],
    badgeValue: 0,
  };

  state = {
    searchValue: '',
  };

  clearSearch = () => {
    this.setState({ searchValue: '' });
  };

  inSearchCondition = (project) => {
    return (
      project.name.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
      project.displayName.toLowerCase().includes(this.state.searchValue.toLowerCase())
    );
  };

  render() {
    const isFavorite = (proj) => proj.id === this.props.defaultProjectId;
    return (
      <MenuButton
        className={'ProjectMenuButton'}
        icon="storage"
        badge={{ value: this.props.badgeValue }}
        onOpen={this.props.findProjects}
        title={this.props.context.msg.t('projects')}
        clearSearch={this.clearSearch}
        buttonClass={'new-project-btn'}
        buttonActionEnable={this.props.context.masterOrOwnerOfAnAccount}
        buttonActionLabel={this.props.context.msg.t('create')}
        buttonActionClick={() => {
          this.props.createNewProject();
          MenuButton.closeAllMenus();
        }}
        enableSearch={true}
        searchValue={this.state.searchValue}
        onSearchChange={_.debounce((searchValue) => {
          this.setState({ searchValue });
        })}
      >
        <div className="scrollbar-macosx project-list" ref={(c) => jQuery(c).scrollbar()}>
          {this.props.projects.map((_project) => {
            if (!this.inSearchCondition(_project)) {
              return null;
            }

            const accessUrl = Api.Bng.accessProjectUrl(_project.name);
            let projectFavIcon = `${this.props.context.contextPath}/resources/images/favicon-menu-projects.png`;
            if (checkAddonEnabled('VISUAL_IDENTITY') && !_project.theme.favIcon.endsWith('favicon.ico')) {
              projectFavIcon = `${this.props.context.contextPath}/${_project.theme.favIcon}`;
            }

            return (
              <li className={`no-hover project-li theme-${_project.themeSkin}`} key={_project.id}>
                <div className="clearfix">
                  <div className={`item-project-menu ${!_project.enabled && !_project.canManage ? 'disabled' : ''}`}>
                    <div className="pull-left">
                      {!_project.enabled && !_project.canManage && (
                        <span href="#" className="item-project-link">
                          <img src={projectFavIcon} className="item-project-menu-logo" />
                          {_project.displayName}
                        </span>
                      )}
                      {(_project.enabled || _project.canManage) && (
                        <a href={accessUrl} className="item-project-link" title={_project.displayName}>
                          <img src={projectFavIcon} className="item-project-menu-logo" />
                          {_project.displayName}
                        </a>
                      )}
                    </div>

                    {(_project.enabled || _project.canManage) && (
                      <div style={{ float: 'right' }}>
                        <Button
                          className="btn-only-icon"
                          icon={isFavorite(_project) ? 'star' : 'star_border'}
                          title={this.props.context.msg.t(
                            _project.enabled ? 'mark.project.as.default' : 'project.disabled'
                          )}
                          //disabled={isFavorite(_project)}
                          onClick={() => {
                            if (isFavorite(_project)) {
                              Api.Bng.favoriteProject(null);
                              this.props.updateFavorite(null);
                            } else {
                              Api.Bng.favoriteProject(_project.id);
                              this.props.updateFavorite(_project.id);
                            }
                          }}
                        />
                        <span
                          title={this.props.context.msg.t('hint.' + _project.projectType.type)}
                          className={`badge ${_project.projectType.badge} type-project-link badge-square`}
                        >
                          {_project.projectType.type.charAt(0)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </div>
      </MenuButton>
    );
  }
}

export default ContextEnhancer(ProjectMenuButton);
