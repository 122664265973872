import styles from './LabsCensus.module.css';
import React, { useRef, useState } from 'react';
import UiMsg from 'components/ui/UiMsg';
import BngAda, { ADA_TAGS } from 'components/bng/ui/BngAda/BngAda';
import Api from 'components/Api';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngTag from 'components/bng/ui/BngTag';
import useTranslation from 'components/hooks/useTranslation';
import { animated, useSpring } from '@react-spring/web';

const AnimatedVoteButton = ({ onClick = _.noop, active = false, leftAlign = true, className, icon, label }) => {
  const [hovering, setHovering] = useState(false);
  const _innerDivRef = useRef();
  const open = active || hovering;
  const springStyles = useSpring({
    width: open ? `${_innerDivRef.current?.offsetWidth ?? 30}px` : '30px',
  });

  return (
    <animated.div
      className={`${styles.AnimatedVoteButton} ${leftAlign ? styles.leftAlign : styles.rightAlign} ${className}`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={springStyles}
      onClick={onClick}
    >
      <div
        ref={_innerDivRef}
        className={`${styles.animatedButtonInnerDiv} ${leftAlign ? styles.leftAlign : styles.rightAlign}`}
      >
        {leftAlign && <BngIconButton className={`${styles.labFeatButton}`} icon={icon} />}
        <span>{label}</span>
        {!leftAlign && <BngIconButton className={`${styles.labFeatButton}`} icon={icon} />}
      </div>
    </animated.div>
  );
};

const LabFeatureVote = ({ labFeat, onVote = _.noop, vote = null, removeVote = _.noop, toNextStep, toPreviousStep }) => {
  const { t } = useTranslation();
  const voteDown = !vote && vote !== null;
  return (
    <>
      <BngTag className={styles.labFeatTag} description={t(`LabsFeatureCategory.${labFeat.category}.title`)} />
      <div className={`LabFeatureVote ${styles.LabFeatureVote}`}>
        <BngIconButton icon={'chevron_left'} className={styles.navigationButton} onClick={toPreviousStep} />
        <img src={Api.Upload.previewUrl(labFeat.imagePath)} />
        <div className={styles.labFeatInfo}>
          <span className={styles.labFeatDescription}>{labFeat.summary}</span>
          <div
            className={styles.labsLink}
            onClick={() => {
              const url = Api.buildUrl('/spr/bng/labs', {
                currentTab: 'forum',
              });
              window.location.replace(url);
            }}
          >
            {t('see.more')}
          </div>
        </div>
        <BngIconButton icon={'chevron_right'} className={styles.navigationButton} onClick={toNextStep} />
      </div>
      <div className={styles.labFeatButtons}>
        <div className={styles.animatedButtonWrapper}>
          <AnimatedVoteButton
            onClick={async () => {
              if (voteDown) {
                removeVote(labFeat);
              } else {
                onVote(false);
                toNextStep();
              }
            }}
            active={voteDown}
            className={styles.voteDownBtn}
            icon={'thumb_down'}
            leftAlign={false}
            label={t('vote.down')}
          />
        </div>
        <div className={styles.animatedButtonWrapper}>
          <AnimatedVoteButton
            onClick={async () => {
              if (vote) {
                await removeVote(labFeat);
              } else {
                await onVote(true);
                toNextStep();
              }
            }}
            active={!!vote}
            className={styles.voteUpBtn}
            icon={'thumb_up'}
            label={t('vote.up')}
          />
        </div>
      </div>
    </>
  );
};

const mapLabFeatsToAda = ({ labsFeatures = [], t = _.noop, closeModal = _.noop }) => {
  return labsFeatures.map((labFeat, idx) => {
    return {
      value: labFeat.id,
      title: labFeat.title,
      leftButtonLabel: idx === 0 ? t('cancel') : t('back_button'),
      onClick: async ({ toNextStep }) => {
        if (idx === labsFeatures.length - 1) {
          UiMsg.ok(t('census.thanks.for.feedback'));
          closeModal();
        } else {
          toNextStep();
        }
      },
      leftButtonClick: async ({ toPreviousStep }) => {
        if (idx === 0) {
          closeModal();
        } else {
          toPreviousStep();
        }
      },
      itemProps: labFeat,
    };
  });
};

export default function LabsCensus({ labsFeatures = [], closeModal = _.noop }) {
  const { t } = useTranslation();

  const [labFeats, setLabFeatures] = useState(mapLabFeatsToAda({ labsFeatures, t, closeModal }));

  const submitLabVoting = async (feat, upVote) => {
    try {
      await Api.FeatureProposal.submitVote(feat.id, upVote);
    } catch (e) {
      console.error('Error while trying to vote in a feature', e);
      UiMsg.ajaxError(t('census.error.sending.feedback'), e);
    }
  };

  const removeVote = async (feat) => {
    try {
      await Api.FeatureProposal.removeVote(feat.id);
    } catch (e) {
      console.error('Error while trying to remove a vote in a feature', e);
      UiMsg.ajaxError(t('census.error.sending.feedback'), e);
    }
  };

  return (
    <BngAda
      className={`LabsCensus ${styles.LabsCensus}`}
      title={t('labs.census.title', [labFeats.length])}
      customOptionsComponent={(item, toNextStep, toPreviousStep) => {
        if (!item) {
          return null;
        }

        const idx = labFeats.findIndex((f) => f.itemProps.id === item.itemProps.id);
        return (
          <LabFeatureVote
            labFeat={item.itemProps}
            onVote={async (value) => {
              await submitLabVoting(item.itemProps, value);
              const updatedFeats = labFeats.map((f, featIdx) => {
                if (idx === featIdx) {
                  f.vote = value;
                }
                return f;
              });
              setLabFeatures(updatedFeats);
            }}
            vote={labFeats[idx].vote}
            toNextStep={() => (idx === labFeats.length - 1 ? closeModal() : toNextStep())}
            toPreviousStep={toPreviousStep}
            removeVote={async (feat) => {
              await removeVote(feat);
              const updatedFeats = labFeats.map((f, featIdx) => {
                if (idx === featIdx) {
                  f.vote = null;
                }
                return f;
              });
              setLabFeatures(updatedFeats);
            }}
          />
        );
      }}
      leftButtonLabel={t('not.now')}
      onClickLeftButton={closeModal}
      buttonLabel={t('next')}
      clickOutsiteToConfirm={false}
      tags={[ADA_TAGS.BIM_LABS, ADA_TAGS.VOTING]}
      steps={labFeats}
      link={Api.buildUrl('/spr/bng/labs', { currentTab: 'forum' })}
      renderButtons={false}
    />
  );
}
