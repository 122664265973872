import React, {Component} from "react";
import PropTypes from "prop-types";

import ComponentFactory from "components/ComponentFactory";
import Icon from "components/ui/common/Icon";

export default class NotesButton extends Component {

    static propTypes = {
        path: PropTypes.string,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
    }

    state = {
        open: false,
    };

    openNotes = (event) => {
        event.preventDefault();
        if(this.props.disabled) {
            return;
        }
        ComponentFactory.Notes.openNotes({anchorEl: event.currentTarget, path: this.props.path});
    };

    render() {
        return(
            <div className="cockpit-nav-btn">
                <a href="#" onClick={this.openNotes} className={this.props.disabled ? 'disabled' : ''}>
                    <Icon icon="forum" style={{lineHeight: '20px'}}/>
                </a>
            </div>
        )
    }
}