import './NotificationMessageDialog.css'

import React from "react";
import PropTypes from 'prop-types';

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Button from "components/ui/Button";
import Dialog from "components/ui/Dialog";
import {ScrollContainer} from "components/ui/ScrollContainer";
import Avatar from "components/ui/Avatar";
import Utils from "components/Utils"
import NotificationMessageDialog from "components/ui/notification/NotificationMessageDialog";
import Icon from "components/ui/common/Icon";
import {
    ExtractorOfflineNotification,
    InMemoryLoadLimitAlertNotification
} from "components/ui/navbar/NotificationMenuButton";


class NotificationDialog extends React.Component {

    static propTypes = {
        closeModal: PropTypes.func,
        onMaximize: PropTypes.func,
    };

    static defaultProps = {};

    state = {
        notification: [],
        first: 0,
        page: 1,
        maxresults: 10
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.notificationMarkAllAsRead();
        this.findNotification(0);
    }

    findNotification = async (val) => {
        let maxresults = this.state.maxresults;
        let first = val < 0 ? 0 : val;
        const not = await j.getJSON(`/spr/notifications/getall`, {first, maxresults});
        this.setState({notification: not});
        this.setState({first: first});
    };

    updateResultOld = async () => {
        let first = this.state.first + this.state.maxresults;
        let pageresult = this.state.page + 1;
        await this.setState({page: pageresult});
        await this.setState({first: first});
        this.findNotification(first);
    };

    updateResultNew = async () => {
        let first = this.state.first - this.state.maxresults;
        let pageresult = this.state.page - 1 < 1 ? 1 : this.state.page - 1;
        await this.setState({page: pageresult});
        await this.setState({first: first});
        this.findNotification(first);
    };

    openNotificationMessageDialog = (id) => {
        window.ReduxStore.dispatch(window.Actions.MODALS.open(NotificationMessageDialog, {notificationId: id}, (this.props)));
    };

    notificationMarkAsRead = async (id) => {
        await j.post(`/spr/notifications/${id}/mark-as-read`);
        await this.props.markAsRead(id);
        this.findNotification(this.state.first);

    };

    notificationMarkAllAsRead = async () => {
        await j.post(`/spr/notifications/markallasread`);
        await this.props.markAllAsRead();
        this.findNotification(this.state.first);
    };

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.closeModal} className="large"
                    title={this.props.context.msg.t('notifications')}>
                <div style={{width: 38}}>
                    <Button className='btn btn-primary iceCmdBtn'
                            onClick={this.updateResultOld} icon={'icon-chevron-left'}
                            disabled={this.state.notification.length < this.state.maxresults}>
                    </Button>
                </div>

                <div style={{width: 38, marginLeft: 753, marginTop: -22}}>
                    <Button disabled={this.state.page === 1} className='btn btn-primary iceCmdBtn'
                            onClick={this.updateResultNew} icon={'icon-chevron-right'}>
                    </Button>
                </div>

                <ScrollContainer className="notificationsTable-container-dialog-list">
                    <table className="notificationsTable" style={{width: 790}}>
                        <tbody>
                        {this.state.notification.map(_notification => {
                            var typeClass = _notification.criticity.icon.replace('icon-', '');
                            return (
                                <tr key={_notification.id}>
                                    <td style={{
                                        width: 2,
                                        padding: 0,
                                        border: 4,
                                        background: Utils.Notification.getNotificationColor(_notification)
                                    }} className={`${typeClass}`}>&nbsp;</td>
                                    <td>
                                        <div className="scrollbar-inner notification-list-content"
                                             ref={c => jQuery(c).scrollbar()}>
                                            <div>
                                                {this.renderMsg(_notification)}
                                            </div>
                                        </div>
                                        {this.renderFooter(_notification)}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </ScrollContainer>


                <div style={{marginLeft: 566, fontSize: 12, marginTop: 10}}>
                    {`${this.props.context.msg.t('page')} ${this.state.page === 1 ? 1 : this.state.page} `}
                </div>
            </Dialog>
        );
    }

    renderMsg(notification) {

        switch (notification.type) {
            case "load": {
                return <LoadMessage notification={notification} msg={this.props.context.msg}/>
            }
            case "MONITOR":
            case "monitor": {
                return <MonitorMessage notification={notification} msg={this.props.context.msg}/>
            }
            case "NOTE":
            case "mention": {
                return <MentionMessage notification={notification} msg={this.props.context.msg}/>
            }
            case "BOOK_GENERATION":
            case "book": {
                return <BookMessage notification={notification} msg={this.props.context.msg}/>
            }
            case "PROJECT_EXPIRED_USER_ACCESS": {
                return <ProjectExpiredUserAccessMessage notification={notification} msg={this.props.context.msg}/>
            }
            case 'IN_MEMORY_LOAD_LIMIT_ALERT': {
                return (
                    <InMemoryLoadLimitAlertNotification notification={notification} />
                );
            }
            case 'EXTRACTOR_OFFLINE': {
                return (
                    <ExtractorOfflineNotification notification={notification} className="ml-3 mt-2" />
                );
            }
            default:
                return <GenericMessage notification={notification} msg={this.props.context.msg}/>
        }
    }

    renderFooter(notification) {
        let hasMessageDetails = notification.message ? notification.message.length > 0 : false;
        let hasBeenRead = !!notification.readDate;
        return (
            <div className="notification-footer">
                <Icon icon="access_time"/>
                &nbsp;
                <span className="date">
                    {moment(notification.sentDate).fromNow()}
                </span>

                {hasMessageDetails &&
                <Button className="btn-link pull-right details"
                        icon="assignment"
                        newStyleButton
                        title={this.props.context.msg.t('see.details')}
                        onClick={async () => {
                            await this.openNotificationMessageDialog(notification.id)
                        }}
                />
                }
            </div>
        );
    }


}

const ProjectExpiredUserAccessMessage = ({notification, msg}) => {
    const {userId, userCaption, projectId, projectCaption} = notification.params;
    return (
        <div style={{paddingLeft: 14}} className="generic">
            <div className="mention-msg">
                <div className="avatar-container">
                    <Avatar userId={userId}/>
                </div>
            </div>
            <span className="text">
                {msg.t('project.expired.notification.title')}
            </span>
            <div className="descr">
                {msg.t('project.expired.notification.message', [userCaption, projectCaption])}
            </div>
        </div>
    );
};

const GenericMessage = ({notification, msg}) => {
    return (
        <div style={{paddingLeft: 14}} className="generic GenericMessage">
            <div className="mention-msg">
                <i className="icon-bim-logo"/>
            </div>
            <span className="text">
                BIMachine
            </span>
            <div className="descr" dangerouslySetInnerHTML={{__html: notification.title}}/>
        </div>
    );
};

const BookMessage = ({notification, msg}) => {
    return (
        <div style={{paddingLeft: 14}}>
            <div className="book-msg">
                <i className="icon icon-bim-book"/>
            </div>
            <span className="book-text">
                {msg.t('book.generation')}:
            </span>
            {notification.type === 'book' &&
            <div className="book-descr" dangerouslySetInnerHTML={{__html: notification.title}}/>
            }
            {notification.type === 'BOOK_GENERATION' &&
            <div className="book-descr">
                {!notification.params.book.containError &&
                <span>
                    O <a href={`${Api.baseUrl()}${notification.params.book.downloadLink}`}
                         download={`${notification.params.book.bookCaption}.pdf`} target="_blank"
                         style={{color: '#08c !important'}}>
                    Book {notification.params.book.bookCaption}
                </a> do Cockpit "{notification.params.cockpit.name}" foi gerado com sucesso
                </span>
                }

                {notification.params.book.containError &&
                <span>
                    {msg.t('error.book.generator')} "{notification.params.book.bookCaption}" do Cockpit
                    "{notification.params.cockpit.name}". {msg.t('book.generate.notification.error.message')}.
                </span>
                }
            </div>
            }
        </div>
    );
};

const MentionMessage = ({notification, msg}) => {


    const isNote = notification.type === 'NOTE';
    if (isNote) {
        notification.mentionedBy = notification.params.sender;
    }

    if (_.isEmpty(notification.mentionedBy)) {
        return (
            <div>
                <div className="mention-descr" dangerouslySetInnerHTML={{__html: notification.message}}/>
            </div>
        );
    }

    const hasGroup = isNote && _.has(notification.params, 'group');
    const objectUrl = isNote ? Api.loadObjectUrl({content: notification.params.item.path, openNote: true}) : '';

    return (
        <div style={{paddingLeft: 14}}>
            <div className="mention-msg-info">
                <div className="mention-msg">
                    <div className="avatar-container">
                        <Avatar userId={notification.mentionedBy.id}/>
                    </div>
                </div>
                <span className="mention-text">
                    {notification.mentionedBy.displayName} {msg.t('note.mention')}
                </span>
            </div>
            {!isNote &&
            <div className="mention-descr" dangerouslySetInnerHTML={{__html: notification.message}}/>
            }
            {isNote &&
            <div className="mention-descr">
                {hasGroup &&
                <span>
                    {` ${msg.t('the.group')} ${notification.params.group.name}, ${msg.t('group.user.marked')} ${notification.params.sender.displayName} ${msg.t('object.observation.marked')} `}
                </span>
                }
                {!hasGroup &&
                <span>
                    {` ${msg.t('user.marked.observation')} ${notification.params.sender.displayName} ${msg.t('user.marked')} `}
                </span>
                }
                <a href={objectUrl}>{notification.params.item.caption}</a>
            </div>
            }
        </div>
    );
};

const LoadMessage = ({notification, msg}) => {
    return (
        <div style={{paddingLeft: 14}}>
            <div className="notification-title-container">
                <div className="notification-title-icon">
                    <i className="icon icon-hdd"/>
                </div>
                <div className="notification-title-description">{msg.t('data.load')}</div>
            </div>
            <div
                className="notification-msg"
                dangerouslySetInnerHTML={{__html: ' ' + notification.title}}/>
        </div>
    );
};

const MonitorMessage = ({notification, msg}) => {
    if (notification.isSuccess) {
        return (
            <div style={{paddingLeft: 14}} className="monitor with-success">
                <div className="mon-icon">
                    <i className="icon icon-bullhorn"/>
                </div>
                <span className="mon-type">
                    <span className="label" /*style={{background: notification.criticity.color}}*/>
                        {/*<span className={`icon ${notification.criticity.icon}`}/>*/}
                        {msg.t(notification.criticity.type)}
                    </span>
                </span>
                <span className="mon-msg" dangerouslySetInnerHTML={{__html: notification.message}}/>
            </div>
        );
    } else {
        return (
            <div style={{paddingLeft: 14}} className="monitor with-error">
                <div className="mon-icon">
                    <i className="icon icon-bullhorn"/>
                </div>
                <span className="mon-type">
                    <span className="label">
                        {/*<span className="icon icon-exclamation"/>*/}
                        {msg.t('error')}
                    </span>
                </span>
                <span className="mon-msg" dangerouslySetInnerHTML={{__html: notification.title}}/>
            </div>
        );
    }
};

export default ContextEnhancer(NotificationDialog);