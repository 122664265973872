import React, {Component} from "react";
import PropTypes from "prop-types";
import CockpitTabs from "./CockpitTabs";
import CockpitNavigator from "./CockpitNavigator";
import CockpitButtons from "./buttons/CockpitButtons";
import Utils from "components/Utils";

class CockpitHeader extends Component {

    static propTypes = {
        context: PropTypes.any,
        panels: PropTypes.any,
        path: PropTypes.string,
        currentPanel: PropTypes.any,
        indexPanel: PropTypes.any,
        onSelect: PropTypes.func,
    };

    state = {
        disableExportToPdf: false,
        exportState: undefined,
    };

    componentDidMount() {
        //See: https://github.com/sol7/bi-machine/issues/4893
        window.__COCKPIT_BIG_TABLE_RESULT_LISTENER = this.bigTableResultListener
    }

    componentWillUnmount() {
        delete window.__COCKPIT_BIG_TABLE_RESULT_LISTENER;
    }

    bigTableResultListener = ({disableExportToPdf, ... exportState}) => {
        this.setState({
            disableExportToPdf,
            exportState
        });
    }

    render() {
        const disableExportToPdf = Utils.Object.isBigTable(this.props.path) && this.state.disableExportToPdf;
        return (
            <div id="cockpit-header" className="iceFrm cockpit-nav-menu-form">
                <CockpitNavigator {...this.props}/>
                <CockpitTabs {...this.props}/>
                <CockpitButtons {...this.props}
                                disableExportToPdf={disableExportToPdf}
                                exportState={this.state.exportState}
                />
            </div>

        );
    }

}

export default CockpitHeader;