import styles from './EvaluationPeriodDropdown.module.css';

import React from 'react';

import BngPopper from 'components/bng/ui/BngPopper';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import Icon from 'components/ui/common/Icon';
import Button from 'components/ui/Button';
import { MODALS } from 'components/ui/redux/Actions';
import ActivateBimDialog from 'components/ui/navbar/ActivateBimDialog';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import Api from 'components/Api';
import UiMsg from "components/ui/UiMsg";

export default function EvaluationPeriodDropdown({ boundaryElement, anchorEl, destroyView = _.noop }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const openActivateDialog = async () => {
    if (context.planSelectionEnabled) {
      dispatch(MODALS.open(ActivateBimDialog));
      destroyView();
    } else {
      try {
        await Api.Project.requestActivation(context.project.id, {plan: 'NotSelected'});
        UiMsg.ok(context.msg.t('activation.request.sent'));
        destroyView();
        window.location.reload();
      } catch (e) {
        console.error(e);
        UiMsg.error(context.msg.t('activation.request.error'));
      }
    }
  };

  return (
    <BngPopper
      className={`EvaluationPeriodDropdown ${styles.EvaluationPeriodDropdownPopper}`}
      open={true}
      anchorEl={anchorEl}
      placement="bottom-start"
      modifiers={{
        offset: [0, 20],
        preventOverflow: {
          boundariesElement: boundaryElement,
        },
      }}
    >
      <BngClickOutsideOverlay
        className={`EvaluationPeriodDropdownOverlay ${styles.evaluationPeriodOverlay}`}
        container={boundaryElement}
        onClick={destroyView}
      />
      <div className={styles.EvaluationPeriodDropdown}>
        <div className={styles.evaluationText}>
          <span className={styles.evaluationTitle}>{context.msg.t('evaluation.title')}</span>
          <ul className={styles.messageList}>
            <li className={styles.evaluationMessage}>
              <Icon icon="done" className={styles.messageIcon} />
              <p dangerouslySetInnerHTML={{ __html: context.msg.t('evaluation.want.msg.1') }} />
            </li>
            <li className={styles.evaluationMessage}>
              <Icon icon="done" className={styles.messageIcon} />
              <p dangerouslySetInnerHTML={{ __html: context.msg.t('evaluation.want.msg.2') }} />
            </li>
            <li className={styles.evaluationMessage}>
              <Icon icon="done" className={styles.messageIcon} />
              <p dangerouslySetInnerHTML={{ __html: context.msg.t('evaluation.want.msg.3') }} />
            </li>
          </ul>
          <Button className={styles.activateButton} onClick={openActivateDialog}>
            {context.msg.t('evaluation.activate')}
          </Button>
        </div>
        <div className={styles.evaluationGraph}>
          <svg viewBox="0 0 260 220" xmlns="http://www.w3.org/2000/svg">
            <circle
              className={styles.backCircle}
              fill="none"
              strokeWidth="8"
              strokeLinecap="round"
              cx="130"
              cy="110"
              r="89"
            />
            <circle
              className={styles.coloredCircle}
              fill="none"
              strokeWidth="8"
              strokeLinecap="round"
              cx="130"
              cy="110"
              r="89"
              strokeDasharray={`${(parseFloat(context.demoExpirationDate) * 560) / 20}, 560`}
            />
          </svg>
          <span
            className={styles.smallTextUnderGraph}
            dangerouslySetInnerHTML={{ __html: context.msg.t('evaluation.period.ending') }}
          />
          <div className={styles.daysLeftWrapper}>
            <span style={{ fontSize: '18px' }}>{context.msg.t('days.left')}</span>
            <span
              style={{
                fontSize: '84px',
                color: '#E92A2A',
                marginTop: '42px',
              }}
            >
              {context.demoExpirationDate > 0 ? context.demoExpirationDate : '0'}
            </span>
          </div>
        </div>
      </div>
    </BngPopper>
  );
}
