import styles from './FilterDropdown.module.css';

import React from 'react';
import { Field, Formik } from 'formik';

import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { BngForm } from 'components/bng/form/BngForm';
import { FormikListener } from 'components/bng/form/formik/FormikListener';
import Icon from 'components/ui/common/Icon';
import { BngField } from 'components/bng/form/BngField';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import Button from 'components/ui/Button';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import useBimContext from 'components/hooks/useBimContext';

const containSomeValue = (value) => {
  if (_.isPlainObject(value)) {
    const values = Object.values(value);
    for (const innerVal of values) {
      if (containSomeValue(innerVal)) {
        return true;
      }
    }
  } else if (_.isArray(value)) {
    return value.length > 0;
  } else if (value) {
    return true;
  }

  return false;
};

export default function FilterDropdown({
  className = '',
  popperClassName = '',
  fields = [],
  onOpen = _.noop,
  dropdownSchema = [],
  initialFormValues = [],
  onChange = _.noop,
  loading = false,
  ...props
}) {
  const { msg } = useBimContext();
  const isFiltered = containSomeValue(initialFormValues);

  return (
    <BngDropdown
      title={msg.t('filter')}
      icon={'filter_alt'}
      className={`FilterDropdown ${isFiltered ? styles.filtered : ''} ${className}`}
      popperClassName={`${popperClassName} FilterDropdownPopper`}
      onOpen={onOpen}
      customOptions={({ closeDropdown }) => {
        return (
          <div className={styles.dropdownBody}>
            <Formik
              initialValues={initialFormValues}
              validationSchema={dropdownSchema}
              onSubmit={(values) => {
                onChange(values);
                closeDropdown();
              }}
            >
              {({ values }) => {
                return (
                  <UiBlocker block={loading}>
                    <BngForm>
                      <FormikListener
                        onChange={(current, previous) => {
                          if (_.isEqual(current.values, previous.values)) return;
                          onChange(current.values);
                        }}
                      />
                      <div className="flex-center-items mb-4">
                        <Icon icon={'filter_alt'} className={'mr-1'} />
                        {msg.t('filter')}
                      </div>
                      {fields.map((field, idx) => {
                        return (
                          <div key={idx}>
                            {field.render ? (
                              field.render({ values })
                            ) : (
                              <Field
                                name={field.name}
                                component={BngField}
                                label={msg.t(field?.label ?? field.name)}
                                inputComponent={BngSelectSearch}
                                options={field.options}
                                className={`${styles.filterDropdownField} ${field.className ?? ''}`}
                                multiple={field.multiple ?? false}
                              />
                            )}
                          </div>
                        );
                      })}
                      <div className="mt-4 d-flex">
                        <Button
                          className={'bng-button cancel borderless btn-small ml-auto'}
                          onClick={() => {
                            onChange(dropdownSchema.default());
                            closeDropdown();
                          }}
                        >
                          {msg.t('clear')}
                        </Button>
                      </div>
                    </BngForm>
                  </UiBlocker>
                );
              }}
            </Formik>
          </div>
        );
      }}
      {...props}
    />
  );
}
