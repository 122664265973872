import './BngTag.css';
import React from 'react';
import Icon from 'components/ui/common/Icon';
import BngButton from 'components/bng/ui/BngButton';

export default function BngTag({
  className = '',
  description = null,
  onClose,
  icon = null,
  textEllipsis = false,
  children,
  iconProps = {},
  ...props
}) {
  return (
    <span className={`BngTag ${className} flex-center-items gap-1`} {...props}>
      {icon && <Icon icon={icon} {...iconProps} />}
      {description && <span className={`description ${textEllipsis ? 'ellipsis' : ''}`}>{description}</span>}
      {children}
      {onClose && <BngButton icon={'close'} className={`BngTagCloseButton`} hover={false} onClick={onClose} />}
    </span>
  );
}

export function BetaTag() {
  return <BngTag description={'Beta'} className={'beta'} />;
}
