import React from "react";
import {connect} from "react-redux";
import {MODALS, NAVBAR} from "components/ui/redux/Actions";
import NotificationMenuButton from "components/ui/navbar/NotificationMenuButton";


const mapStateToProps = (state) => {
    return {
        notifications: state.navbar.notifications
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        findNotifications: (params) => dispatch(NAVBAR.findNotifications(params)),
        markAllAsRead: () => dispatch(NAVBAR.markAllNotificationsAsRead()),
        markAsRead: (id) => dispatch(NAVBAR.markNotificationAsRead(id)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationMenuButton);