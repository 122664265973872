import styles from './FeatureManagement.module.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';

import { BngPanel, BngPanelHeader } from 'components/bng/ui/BngPanel';
import PaginateTable from 'components/ui/common/PaginateTable';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngAvatar } from 'components/bng/ui/BngAvatar';
import BngSearch from 'components/bng/ui/BngSearch';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import Button from 'components/ui/Button';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import Icon from 'components/ui/common/Icon';

const TABLE_INITIAL_PASS = 8;

const FeatureManagementTable = ({
  context = {},
  users = {},
  handleChangeNumberPerPage = _.noop,
  handleChangePage = _.noop,
  handleLockOrUnlockFeatureForUser = _.noop,
}) => {
  const userColumns = [
    {
      key: 'avatar',
      label: '',
      render: (row) => <BngAvatar className={styles.Avatar} userId={row.id} />,
    },
    {
      key: 'name',
      size: 350,
      label: context.msg.t('user'),
      render: (row) => (
        <div>
          <h1 className={styles.Name}>{row.displayName}</h1>
          <span>{row.email}</span>
        </div>
      ),
    },
    {
      size: 150,
      label: context.msg.t('status'),
      render: (row) => {
        return (
          <div className={row.hasFeature ? styles.UnlockedBadge : styles.LockedBadge}>
            {row.hasFeature ? context.msg.t('unlocked') : context.msg.t('locked')}
          </div>
        );
      },
    },
    {
      label: '',
      render: (row) => {
        return (
          <Button
            icon={row.hasFeature ? 'block' : 'check'}
            title={context.msg.t(row.hasFeature ? 'lock' : 'unlock')}
            className={row.hasFeature ? styles.LockButton : styles.UnlockButton}
            onClick={() => handleLockOrUnlockFeatureForUser(row.hasFeature, row.id)}
          ></Button>
        );
      },
    },
  ];

  return (
    <PaginateTable
      handleChangeNumberPerPage={handleChangeNumberPerPage}
      handleChangePage={handleChangePage}
      initialPass={TABLE_INITIAL_PASS}
      perPageLimit={45}
      totalItens={users.totalElements}
      externalChange={users.forceUpdate}
      height="680px"
    >
      <BngTable className="Narrow" rows={users.content} cols={userColumns} stickyHeader />
    </PaginateTable>
  );
};

const FeatureManagementList = ({ context }) => {
  const [users, setUsers] = useState({
    content: [],
    totalElements: 0,
    forceUpdate: false,
  });
  const [selectedFeature, setSelectedFeature] = useState();
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const fetchUsers = async (initial = 0, maxResults = TABLE_INITIAL_PASS, forceUpdate = false, searchTerm = search) => {
    try {
      const fetchedUsers = await Api.Admin.findUsers({
        initial,
        maxResults,
        search: searchTerm,
      });

      if (selectedFeature) {
        const usersWithFeature = await Api.Feature.findUsersWithFeature(selectedFeature);
        fetchedUsers.content.forEach((fu) => {
          fu.hasFeature = usersWithFeature.includes(fu.id);
        });
        setUsers({ ...fetchedUsers, forceUpdate: false });
      } else {
        fetchedUsers.content.forEach((e) => {
          e.hasFeature = null;
        });
      }
      setUsers({ ...fetchedUsers, forceUpdate: false });
    } catch (e) {
      console.error(e);
      UiMsg.error(e);
    }
  };

  const fetchAvailableFeatures = async () => {
    try {
      const features = await Api.Feature.findAvailableFeatures();
      setAvailableFeatures(features);
    } catch (e) {
      console.error('Could not fetch features', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const handleChangePage = async (pageNumber, firstLoad, perPage) => {
    await fetchUsers(Number(pageNumber) - 1, perPage);
  };

  const handleChangeNumberPerPage = async (perPage, pageNumber) => {
    await fetchUsers(Number(pageNumber) - 1, perPage);
  };

  const handleSearch = async (search = '', fromClose = false) => {
    setSearch(search);
    if (fromClose) await fetchUsers(0, TABLE_INITIAL_PASS, true, search);
  };

  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    await fetchUsers();
  };

  const handleLoadUsersWithFeature = (values) => {
    setSelectedFeature(values.feature);
  };

  const handleLockOrUnlockFeatureForUser = async (hasFeature, id) => {
    if (hasFeature) {
      try {
        await Api.Feature.lockForSelectedUser(selectedFeature, id);
        await fetchUsers(0, TABLE_INITIAL_PASS, true);
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await Api.Feature.unlockForSelectedUser(selectedFeature, id);
        await fetchUsers(0, TABLE_INITIAL_PASS, true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleUnlockFeatureForAllUsers = async () => {
    try {
      await Api.Feature.unlockForAllUsers(selectedFeature);
      await fetchUsers(0, TABLE_INITIAL_PASS, true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleLockFeatureForAllUsers = async () => {
    try {
      await Api.Feature.lockForAllUsers(selectedFeature);
      await fetchUsers(0, TABLE_INITIAL_PASS, true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchUsers(0, TABLE_INITIAL_PASS, true);
  }, [selectedFeature]);

  useEffect(() => {
    fetchAvailableFeatures();
  }, []);

  return (
    <div className={styles.FeatureManagement}>
      <BngPanel className={styles.Panel}>
        <BngPanelHeader className={styles.Header}>
          <div>
            <h1 className={styles.PageTitle}>{context.msg.t('feature.management')}</h1>
          </div>

          <div className="flex-center-items flex-grow-1 justify-content-flex-end" width="0">
            <div className={`${styles.HeaderItems} ${showSearch ? 'SearchVisible' : ''} `}>
              <form id="feature-management-search-user-form" onSubmit={handleSubmitSearch}>
                <BngSearch value={search} onChange={handleSearch} stateListener={({ open }) => setShowSearch(open)} />
              </form>
            </div>
          </div>
        </BngPanelHeader>
        <div className={styles.ContentWrapper}>
          <div className={styles.SideMenu}>
            <div className={styles.FormContainer}>
              <Formik initialValues={{ feature: '' }} onSubmit={handleLoadUsersWithFeature}>
                <Form>
                  <label className={styles.FeatureSelectionLabel}>{context.msg.t('feature')}</label>
                  <Field
                    name="feature"
                    component={BngSelectSearch}
                    className={styles.SelectButton}
                    options={availableFeatures}
                  />
                  <div className={styles.SelectFeatureContainer}>
                    <Button type="submit" className={styles.ConfirmSelectButton}>
                      {context.msg.t('select')}
                    </Button>
                  </div>
                </Form>
              </Formik>
            </div>
            <hr className={styles.Divisor} />
            <div className={styles.ButtonsContainer}>
              <Button icon="check" className={styles.UnlockAllButton} onClick={() => handleUnlockFeatureForAllUsers()}>
                {context.msg.t('unlock.all')}
              </Button>
              <Button icon="block" className={styles.LockAllButton} onClick={() => handleLockFeatureForAllUsers()}>
                {context.msg.t('lock.all')}
              </Button>
            </div>
          </div>
          <div className={styles.Body}>
            {_.isEmpty(selectedFeature) ? (
              <div className={styles.FeatureNotSelected}>
                <Icon icon="keyboard_backspace" />
                <span>{context.msg.t('feature.management.instruction')}</span>
              </div>
            ) : (
              <FeatureManagementTable
                context={context}
                users={users}
                handleChangeNumberPerPage={handleChangeNumberPerPage}
                handleChangePage={handleChangePage}
                handleUnlockFeatureForAllUsers={handleUnlockFeatureForAllUsers}
                handleLockFeatureForAllUsers={handleLockFeatureForAllUsers}
                handleLockOrUnlockFeatureForUser={handleLockOrUnlockFeatureForUser}
              />
            )}
          </div>
        </div>
      </BngPanel>
    </div>
  );
};

export default ContextEnhancer(connect()(FeatureManagementList));
