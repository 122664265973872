import styles from './FeatureOption.module.css';
import BUBBLE_CHART from './assets/BUBBLE_CHART.svg';
import TREE_MAP from './assets/TREE_MAP.svg';
import WATERFALL_CHART from './assets/WATERFALL_CHART.svg';
import BIG_TABLE_CLICK_ACTION from './assets/BIG_TABLE_CLICK_ACTION.svg';

import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import BngSwitch from 'components/bng/form/BngSwitch';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import GiveFeedbackLabel from 'components/ui/labs/trial/GiveFeedbackLabel';
import PreviewVideoLabel from 'components/ui/labs/trial/PreviewVideoLabel';
import BngVideoModal from 'components/bng/ui/BngVideoModal';
import BngAda from 'components/bng/ui/BngAda/BngAda';
import AdaCensus from 'components/bng/ui/Census/AdaCensus';
import UiMsg from 'components/ui/UiMsg';
import LabsReleaseLabel from 'components/ui/labs/trial/LabsReleaseLabel';

const FEATURE_IMAGES = {
  BUBBLE_CHART: BUBBLE_CHART,
  TREE_MAP: TREE_MAP,
  WATERFALL_CHART: WATERFALL_CHART,
  BIG_TABLE_CLICK_ACTION: BIG_TABLE_CLICK_ACTION,
};

export default function FeatureOption({
  className = '',
  feature = {},
  enabled = false,
  handleChange = _.noop,
  loading = false,
}) {
  const context = useBimContext();
  const userHasPermission = context.permissions.isAtLeast(feature.minimalRole);

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showAdaHelper, setShowAdaHelper] = useState(false);
  const [showAdaFeedback, setShowAdaFeedback] = useState(false);
  const [requestEmailSent, setRequestEmailSent] = useState(false);

  const submitFeedback = async (values) => {
    setShowAdaFeedback(false);
    try {
      const censusResponse = {
        result: values,
        projectId: context.project.id,
        projectName: context.project.name,
        mobile: application.page.isMobile(),
        type: `LABS_${feature.value}`,
      };
      await Api.News.saveCensusResponse(censusResponse);
      UiMsg.ok(context.msg.t('census.thanks.for.feedback'));
    } catch (e) {
      console.error('Error on submitFeedback()', { values }, e);
      UiMsg.error(context.msg.t('census.error.sending.feedback'), e);
    }
  };

  const adaOpts = () => {
    const arr = [];
    if (context.msg.containKey(feature.videoKey)) {
      arr.push({
        icon: 'play_circle_outline',
        label: context.msg.t('BigTableAnalysisHelp.videoLinkLabel'),
        onClick: () => setShowVideoModal(true),
      });
    }

    arr.push({
      label: context.msg.t('BigTableAnalysisHelp.adaLinks.label1'),
      onClick: () => window.open(context.msg.t(feature.linkKey), '_blank'),
    });

    return arr;
  };

  const sendAdminEmail = async () => {
    try {
      setRequestEmailSent(true);
      UiMsg.ok(context.msg.t('publiclabs.requestsent.msg'));
      await Api.Feature.sendRequestEmail(context.user.id, feature.value, context.project.id);
    } catch (e) {
      UiMsg.error('Error on sendAdminEmail()', e);
      setRequestEmailSent(false);
    }
  };

  return (
    <UiBlocker className={`FeatureOption ${className}`} block={loading}>
      <div className={`${styles.featureWrapper}`}>
        <div className={`${styles.infoContainer}`}>
          <div className={`${styles.imageContainer}`}>
            <img src={FEATURE_IMAGES[feature.value]} alt={`${context.msg.t(feature.labelKey)} image`} />
          </div>
          <div className={`${styles.featureDescriptionContainer}`}>
            <span className={`${styles.featureDescriptionCategory}`} style={{ color: feature.category.color }}>
              {context.msg.t(feature.category.titleKey)}
            </span>
            <div className={`${styles.featureTitleContainer}`}>
              <span className={`${styles.featureTitle}`}>{context.msg.t(feature.labelKey)}</span>
              {feature.isRelease && (
                <div className={`${styles.releaseLabelContainer}`}>
                  <LabsReleaseLabel />
                </div>
              )}
            </div>
            <span className={`${styles.featureDescription}`}>
              {context.msg.t(feature.descKey)}
              <a
                className={`${styles.featureLearnMore}`}
                href={context.msg.t(feature.linkKey)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {' ' + context.msg.t('know.more')}
              </a>
            </span>
          </div>
        </div>
        <div className={`${styles.featureButtonsContainer}`}>
          <div className={`${styles.featureIconButtons}`}>
            {context.msg.containKey(feature.videoKey) && (
              <PreviewVideoLabel className={`${styles.featureFeedbackLink}`} onClick={() => setShowVideoModal(true)} />
            )}
            {enabled && (
              <GiveFeedbackLabel className={`${styles.featureFeedbackLink}`} onClick={() => setShowAdaFeedback(true)} />
            )}
          </div>
          {userHasPermission && (
            <BngSwitch
              className={`${styles.featureSwitchButton}`}
              name={feature.value}
              id={`FeatureOption_${feature.value}`}
              checked={enabled}
              onChange={() => {
                handleChange();
                if (!enabled) {
                  setShowAdaHelper(true);
                  if (context.msg.containKey(feature.videoKey)) setShowVideoModal(true);
                }
              }}
            />
          )}
          {!userHasPermission && (
            <div
              className={`${styles.featuresUnavailableContainer}`}
              title={context.msg.t('publiclabs.features.unavailable.tooltip', [feature.minimalRole])}
            >
              <button
                className={`${styles.requestButton} ${
                  requestEmailSent ? styles.unavailableRequestButton : styles.availableRequestButton
                }`}
                disabled={requestEmailSent}
                onClick={() => sendAdminEmail()}
              >
                {context.msg.t('demand')}
              </button>
            </div>
          )}
        </div>
      </div>
      {showVideoModal &&
        ReactDOM.createPortal(
          <BngVideoModal
            videoUrl={feature.videoKey}
            className={`${styles.featuresVideoModal}`}
            onClose={() => {
              setShowVideoModal(false);
            }}
            classNameOverlay={`${styles.featuresVideoModalOverlay}`}
            description={context.msg.t('video.modal.desc')}
            descriptionLink={context.msg.t(feature.linkKey)}
          />,
          document.body
        )}
      {showAdaHelper &&
        ReactDOM.createPortal(
          <BngAda
            className={`${styles.featuresAdaHelper}`}
            title={context.msg.t('publiclabs.ada.helper.title', [context.msg.t(feature.labelKey)])}
            options={adaOpts()}
            onClick={() => setShowAdaHelper(false)}
          />,
          document.body
        )}
      {showAdaFeedback &&
        ReactDOM.createPortal(
          <AdaCensus
            className={`${styles.featuresAdaHelper}`}
            onSubmit={submitFeedback}
            answerLaterButton={() => setShowAdaFeedback(false)}
            questionTitle={context.msg.t('publiclabs.ada.feedback.title', [context.msg.t(feature.labelKey)])}
            likeMsg={'publiclabs.feedback.like'}
            dislikeMsg={'publiclabs.feedback.dislike'}
            starCounter={true}
          />,
          document.body
        )}
    </UiBlocker>
  );
}
