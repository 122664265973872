import styles from './CodeBlock.module.css';

import React, { Suspense, useMemo, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import BngIconButton from 'components/bng/ui/BngIconButton';
import useTranslation from 'components/hooks/useTranslation';
import Utils from 'components/Utils';
import UiMsg from 'components/ui/UiMsg';

function generateCodeId(content) {
  return `codeBlock-${content.slice(0,10).replace(/[^\w]/g, '')}-${content.length}`;
}

const LazyCodeBlock = React.lazy(async () => {
  const { Prism: SyntaxHighlighter } = await import('react-syntax-highlighter');
  const { darcula: theme } = await import('react-syntax-highlighter/dist/esm/styles/prism');

  function LazyCodeBlockInner({ language, value, copiedCodeId, onCopy = _.noop }) {
    const id = useMemo(() => generateCodeId(value), [value]);
    const { t } = useTranslation();

    const snippetClassName = `code-snippet-${id}`;
    return (
      <div className={`LazyCodeBlockInner ${styles.LazyCodeBlockInner}`}>
        <div className={`CodeBlockHeader ${styles.CodeBlockHeader}`}>
          <span className={`CodeBlockHeaderSpan ${styles.CodeBlockHeaderSpan}`}>{language}</span>
          <BngIconButton
            className={`CodeBlockHeaderButton ${styles.CodeBlockHeaderButton}`}
            icon="content_copy"
            text={
              id === copiedCodeId
                ? t('ada.ai.dialog.body.code.block.header.button.copied')
                : t('ada.ai.dialog.body.code.block.header.button.copy')
            }
            onClick={async () => {
              const snippetEl = document.querySelector(`.${snippetClassName}`);
              await onCopy({ id, content: snippetEl?.innerText ?? '' });
            }}
          />
        </div>
        <SyntaxHighlighter
          style={theme}
          language={language}
          customStyle={{ margin: '0 0', background: '#0d0d0d', borderRadius: '0 0 6px 6px' }}
          className={snippetClassName}
        >
          {value}
        </SyntaxHighlighter>
      </div>
    );
  }

  return {
    default: LazyCodeBlockInner,
  };
});

export default function CodeBlock({ message = '' }) {
  const [copiedCodeId, setCopiedCodeId] = useState(null);

  const content = useMemo(() => {
    return `${message}`.replace(/`/g, '`').replace(/\\n/g, '\n');
  }, [message]);

  return (
    <div className={`CodeBlock ${styles.CodeBlock}`}>
      <ReactMarkdown
        className={`ReactMarkdown ${styles.ReactMarkdown}`}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            const codeContent = `${children}`.replace(/\n$/, '');

            return !inline && match ? (
              <Suspense fallback={<div style={{ textAlign: 'center', padding: '10px' }}>Loading...</div>}>
                <LazyCodeBlock
                  language={match ? match[1] : ''}
                  value={codeContent}
                  copiedCodeId={copiedCodeId}
                  onCopy={async ({ id, content }) => {
                    try {
                      await Utils.copyToClipboard(content);
                      setCopiedCodeId(id);
                      setTimeout(() => {
                        setCopiedCodeId(null);
                      }, 3000);
                    } catch (e) {
                      console.error('Error while trying to copy text to clipboard', e);
                      UiMsg.error(null, e);
                    }
                  }}
                />
              </Suspense>
            ) : (
              <code className={`ReactMarkdownCode ${styles.ReactMarkdownCode}`} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}
