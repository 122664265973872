import React, {Component} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import PropTypes from 'prop-types';
import DashboardItemInformation from "../dashboard/components/DashboardItemInformation";
import ModalInformation from "components/ui/ModalInformation";

class BiSourceError extends Component {

    static propTypes = {
        path: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        error: PropTypes.any,
        reload: PropTypes.func
    };

    static defaultProps = {
        error: ''
    };

    constructor(props) {
        super(props);
    }

    static openErrorDetails(error) {
        const msg = !!error.message ? error.message.split('\r\n').join('<br/>') : '';
        ModalInformation.ShowMessage({
            title: `${error.statusCode} ${error.status}`,
            text: `<pre style="font-size: 11px; white-space: nowrap; overflow: auto; max-height: 300px; max-height: 45vh;">${msg}</pre>`
        });
    }

    tryReload = () => {
        try {
            this.reload();
        } catch(e){
            this.props.reload();
        }
    }

    render(){
        return (
            <DashboardItemInformation path={this.props.path}
                                      width={this.props.width}
                                      height={this.props.height}
                                      message={this.props.context.msg.t('map_load_error')}
                                      reload={this.tryReload}
                                      showErrorDialog={() => BiSourceError.openErrorDetails(this.props.error)}
                                      errorTrace={this.props.error}
            />
        );
    }
}

export default ContextEnhancer(BiSourceError);