import styles from './BimGatewayTutorialLayout.module.css';

import React from 'react';

export default function BimGatewayTutorialLayout({
  className = '',
  component,
  image = '',
  imageAlt = '',
  video = '',
  title = '',
  description = '',
}) {
  return (
    <div className={`BimGatewayTutorialLayout ${styles.wrapper} ${className}`}>
      <div className={`${styles.imgContainer}`}>
        {video ? <video src={video} className={`${styles.video}`} autoPlay /> : <img src={image} alt={imageAlt} />}
      </div>
      <div className={`${styles.textContainer}`}>
        <span className={`BimGatewayLayoutTitle ${styles.title}`}>{title}</span>
        <span
          className={`BimGatewayLayoutDescription ${styles.desc}`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      {component && <div className={`${styles.childrenContainer}`}>{component}</div>}
    </div>
  );
}
