import './NewProjectDialog.module.css';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FastField, Field, Form, Formik } from 'formik';
import Dialog from 'components/ui/Dialog';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';
import { BngField } from 'components/bng/form/BngField';
import { arrayToOptionsI18n, BngSelect } from 'components/bng/form/BngSelect';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { bngYup } from 'components/bng/form/yup/BngYup';
import Button from 'components/ui/Button';
import UiMsg from 'components/ui/UiMsg';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import Utils from 'components/Utils';

export const ProjectType = ['Template', 'Production', 'Homologation', 'Demo'];
const ProjectLanguage = ['pt_BR', 'en_US', 'es_ES', 'pt_PT'];

const NewProjectSchema = bngYup((yup) => {
  return yup.object().shape({
    name: yup.string().required().min(3).max(20).trim().default(''),
    displayName: yup.string().trim().default(''),
    description: yup.string().trim().default(''),
    domainId: yup.number().integer().default(1),
    accountId: yup.number().required().integer(),
    projectType: yup.string().required().oneOf(ProjectType).default('Production'),
    availableOnMobile: yup.boolean().default(true),
    objectMobileByDefault: yup.boolean().default(true),
    limitSupport: yup.boolean().default(false),
    language: yup.string().required().oneOf(ProjectLanguage),
  });
});

export const NewProjectDialog = ContextEnhancer(
  class extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      goToProject: PropTypes.bool,
      afterSubmit: PropTypes.func,
    };

    static defaultProps = {
      className: '',
      goToProject: true,
      afterSubmit: _.noop,
    };

    state = {
      accounts: [],
      domains: [{ value: 1, label: 'bimachine.com' }],
      projectLanguages: arrayToOptionsI18n(this.props.context, ProjectLanguage),
      loading: true,
    };

    initialFormValues = NewProjectSchema.default();

    async componentDidMount() {
      const accounts = (await Api.Account.findAvailable())
        .sort((a, b) => Utils.Strings.compareIgnoreCase(a.name, b.name))
        .map((acc) => ({
          value: acc.id,
          label: `${acc.id} - ${acc.name}`,
          partner: acc.partner,
        }));

      this.setState({
        accounts,
        loading: false,
      });
    }

    save = async (values, actions) => {
      try {
        const result = await Api.Project.isProjectNameInUse(values.name);
        if (result.nameInUse) {
          actions.setFieldError('name', this.props.context.msg.t(result.msg));
          actions.setSubmitting(false);
          return;
        }
        const response = await Api.Project.createNewProject(values);
        await Api.Group.createDefaultGroups(response.id);
        const url = Api.Bng.accessProjectUrl(response.name);
        if (this.props.goToProject) {
          window.location.replace(url);
        } else {
          this.props.closeModal();
          await this.props.afterSubmit();
        }
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError('error', e);
        actions.setSubmitting(false);
      }
    };

    render() {
      return (
        <Formik initialValues={this.initialFormValues} validationSchema={NewProjectSchema} onSubmit={this.save}>
          {({ values, isSubmitting }) => {
            const projectTypeOpts = useMemo(() => {
              const opts = ProjectType.slice();
              const { msg } = this.props.context;
              const selectedAccount = values.accountId
                ? this.state.accounts?.find((acc) => acc.value === parseInt(values.accountId))
                : null;
              if (!selectedAccount || !selectedAccount.partner) {
                opts.shift();
              }
              return arrayToOptionsI18n({ msg }, opts);
            }, [values.accountId]);

            return (
              <Dialog
                title={this.props.context.msg.t('new_project_wizard')}
                className={`NewProjectDialog large`}
                onClose={this.props.closeModal}
                loading={isSubmitting || this.state.loading}
              >
                <Form>
                  <Dialog.Body>
                    <div className="row-fluid">
                      <div className="span6">
                        <Field name="name" component={BngField} maxLength={20} required={true} />
                      </div>
                      <div className="span6">
                        <FastField
                          name="displayName"
                          label={this.props.context.msg.t('caption')}
                          component={BngField}
                          maxLength={60}
                        />
                      </div>
                    </div>

                    <div className="row-fluid">
                      <div className="span6">
                        <Field
                          label={this.props.context.msg.t('url')}
                          name="name"
                          component={BngField}
                          readOnly={true}
                          disabled={true}
                          showErrors={false}
                        />
                      </div>
                      <div className="span6">
                        <Field
                          label={BngField.LABEL_EMPTY}
                          name="domainId"
                          component={BngField}
                          inputComponent={BngSelect}
                          readOnly={true}
                          disabled={true}
                          options={this.state.domains}
                        />
                      </div>
                    </div>

                    <FastField name="description" component={BngField} className="row-fluid" />

                    <div className="row-fluid">
                      <div className="span6">
                        <Field
                          name="accountId"
                          label={this.props.context.msg.t('account')}
                          component={BngField}
                          inputComponent={BngSelectSearch}
                          options={this.state.accounts}
                          required={true}
                        />
                      </div>
                      <div className="span6">
                        <Field
                          name="projectType"
                          label={this.props.context.msg.t('type')}
                          component={BngField}
                          inputComponent={BngSelect}
                          options={projectTypeOpts}
                          emptyOption={false}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row-fluid">
                      <div className="span6">
                        <Field
                          name="language"
                          label={this.props.context.msg.t('language')}
                          component={BngField}
                          inputComponent={BngSelect}
                          options={this.state.projectLanguages}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="row-fluid">
                      <div className="span6">
                        <FastField
                          label=""
                          name="availableOnMobile"
                          component={BngField}
                          title={this.props.context.msg.t('project.general.mobile.hint')}
                          inputComponent={BngCheckbox}
                          asProps={{ label: this.props.context.msg.t('avaliable.on.mobile') }}
                        />

                        <FastField
                          label=""
                          name="objectMobileByDefault"
                          component={BngField}
                          title={this.props.context.msg.t('mobile.default.state.hint')}
                          inputComponent={BngCheckbox}
                          asProps={{ label: this.props.context.msg.t('mobile.default.state') }}
                        />
                      </div>
                      <FastField
                        label=""
                        name="limitSupport"
                        component={BngField}
                        title={this.props.context.msg.t('project.general.limit.support', Api.baseUrl())}
                        inputComponent={BngCheckbox}
                        asProps={{ label: this.props.context.msg.t('limit.support.to.admins.only') }}
                      />
                    </div>
                  </Dialog.Body>
                  <Dialog.Footer>
                    <div className="row-fluid">
                      <hr />
                      <div className="text-right">
                        <Button className={'btn-success'} type="submit">
                          {this.props.context.msg.t('finish')}
                        </Button>
                      </div>
                    </div>
                  </Dialog.Footer>
                </Form>
              </Dialog>
            );
          }}
        </Formik>
      );
    }
  }
);
