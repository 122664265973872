import {connect} from 'react-redux';
import MenuDashboardMenuItemDialog from './MenuDashboardMenuItemDialog';

export default connect(
    state => {
        return {
            folders: state.menu.roots
        }
    }
)(MenuDashboardMenuItemDialog);
