import React from 'react';
import ReactDOM from 'react-dom';

export default function BngPortal({ children, container }) {
  if (container) {
    return ReactDOM.createPortal(children, container);
  } else {
    return children;
  }
}
