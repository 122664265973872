import React from "react";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";

const AnalystMenuItem = ({
                             toggleAnalystMenu =_.noop,
                             context = {},
                             analystMenuClose = false,
                             clearAccordion = _.noop,
                         }) => {
    const handleClick = () => {
        toggleAnalystMenu(clearAccordion);
    };

    return (
        <RightMenuItem onClick={handleClick}
                       className={`AnalystMenuItem ${!analystMenuClose ? 'open' : ''}`}
                       title={context.msg.t('toggle.analyst.menu.title')}
                       icon={!analystMenuClose ? 'skip_next' : 'skip_previous'}/>
    )
};

export default AnalystMenuItem;
