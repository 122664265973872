import './ActionList.css';

import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import ContextEnhancer from "components/ContextEnhancer";
import {BngIconButton} from "components/bng/ui/BngIconButton";
import BngDraggable from "components/bng/ui/BngDraggable";
import BngSearch from "components/bng/ui/BngSearch";
import Icon from "components/ui/common/Icon";
import BngTag from "components/bng/ui/BngTag";
import BngTagContainer from "components/bng/ui/BngTagContainer";


export const ActionTagItem = ({
                                  item: {
                                      description = '',
                                      icon = 'question',
                                      tags,
                                      className = '',
                                      onClick = _.noop,
                                      actions = _.noop,
                                  }
                              }) => {
    return (
        <div className={`ActionTagItem ${className}`} onClick={onClick}>
            <div className="ActionTagItemHeader flex-center-items">
                <div className="ActionTagItemDescription">
                    <Icon icon={icon}/>
                    <span>{description}</span>
                </div>
                <div className="ActionTagItemActions flex-center-items">
                    {actions()}
                </div>
            </div>
            <div className="ActionTagItemTags">
                <BngTagContainer>
                    {!!tags && tags.map((tag, index) => {
                        if (!tag) return null;
                        const {className, ...tagProps} = tag;
                        return (
                            <BngTag key={index}
                                    className={className}
                                    {...tagProps}
                            />
                        );
                    })}
                </BngTagContainer>
            </div>
        </div>
    )
}

const ActionItem = ({item}) => {
    const {description = '', actions, className = '', onClick = _.noop} = item;
    return (
        <div className={`ActionListItem ${className}`} onClick={onClick}>
            <div className="ActionListItemDescription">{description}</div>
            <div className="ActionListItemActions">
                {!!actions && actions.map((action, index) => {
                    const {icon = '', onClick = _.noop, ...actionProps} = action;
                    return (
                        <BngIconButton key={index}
                                       icon={icon}
                                       onClick={onClick}
                                       {...actionProps}
                        />
                    );
                })}
            </div>
        </div>
    )
};

ActionItem.propTypes = {
    description: PropTypes.string,
    className: PropTypes.string,
    actions: PropTypes.array,
};

export const ActionListEmpty = ({
                                    description = '',
                                    link = '',
                                    linkLabel = '',
                                }) => {
    return (
        <div className="ActionListEmpty">
            <div className="ActionListItemsEmpty-Wrapper">
                <Icon icon="feedback"/>
                <div className="ActionListEmpty-Description">{description}</div>
                {!!link &&
                <a href={link}
                   rel="noreferrer"
                   target="_blank"
                   className="ActionListEmpty-Description">
                    {linkLabel}
                </a>
                }
            </div>
        </div>
    )
};

const ActionList = ({
                        context = {},
                        className = '',
                        items = [],
                        addAction = null,
                        searchField = true,
                        draggable = false,
                        onChangeOrder = _.noop,
                        customAction = _.noop,
                        emptyComponent = _.noop,
                        ItemComponent = ActionItem,
                        customSearch = null,
                        ...props
                    }) => {
    const [visibleList, setVisibleList] = useState(items);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        handleSearch();
    }, [items, searchTerm]);

    const handleSearch = (search = searchTerm) => {
        if (!!search) {
            setVisibleList(_.filter(items, (item) => item.description.toLowerCase().includes(search.toLowerCase())));
        } else {
            setVisibleList(items);
        }
    };

    const renderedItems = visibleList.map((item, index) =>
        <ItemComponent key={item.key || index} item={item}/>
    );

    return (
        <div className={`ActionList ${className}`} {...props}>
            {searchField &&
            <div className="ActionListSearch">
                <div style={{position: "relative", width: "100%"}}>
                    {!!customSearch && customSearch}
                    <BngSearch onChange={setSearchTerm}
                               inline={true}
                               placeholder={context.msg.t('header.search')}/>
                </div>
                {!!customAction && customAction()}
                {!!addAction &&
                <BngIconButton icon="add"
                               type="button"
                               className="ActionListAdd"
                               onClick={addAction}
                />
                }
            </div>
            }
            <div className="ActionListItems">
                {draggable ?
                    <BngDraggable items={renderedItems}
                                  onChangeOrder={onChangeOrder}/>
                    : renderedItems
                }

                {items.length === 0 && emptyComponent()}

                {items.length > 0 && visibleList.length === 0 && searchTerm.length > 0 &&
                <span className="ActionListEmpty">{context.msg.t('results.search.not.found')}</span>
                }
            </div>
        </div>
    )
};

ActionList.propTypes = {
    items: PropTypes.array,
    addAction: PropTypes.func,
    searchField: PropTypes.bool,
    draggable: PropTypes.bool,
    className: PropTypes.string,
    ItemComponent: PropTypes.any,
};

export default ContextEnhancer(ActionList);