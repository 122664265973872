import { Axios } from 'commonUtils';

const endpoint = '/spr/bimQuery';

class BimQueryApi {
  validateQuery = async (queryData) => {
    return await Axios.postData(`${endpoint}/validateQuery`, queryData);
  };

  executeQuery = async (queryData) => {
    return await Axios.postData(`${endpoint}/executeQuery`, queryData);
  };

  save = async (body) => {
    return await Axios.postData(`${endpoint}`, body);
  };

  validateName = async (data = { name: '', projectId: 0 }) => {
    return await Axios.postData(`${endpoint}/validateName`, data);
  };

  fetchStructure = async (id) => {
    return await Axios.getData(`${endpoint}/${id}`);
  }
}

export default BimQueryApi;
