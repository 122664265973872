import './InvalidPeriodicityAlert.css';

import React, { useEffect } from 'react';

import DashboardItemInformation from 'components/ui/dashboard/components/DashboardItemInformation';
import { ObjectErrorPopup } from 'components/ui/dashboard/components/ObjectErrorPopup';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';

export default function InvalidPeriodicityAlert({
  className = '',
  path = '',
  caption = '',
  periodicity,
  parentElement,
  showAlert = true,
  useObjectMessage = false,
}) {
  const { msg } = useBimContext();

  const message = useObjectMessage
    ? msg.t('invalid.date.for.periodicity.alert', [msg.t(periodicity), caption])
    : msg.t('invalid.date.filter.alert', [msg.t(periodicity)]);

  useEffect(() => {
    if (showAlert) {
      UiMsg.warn(`${msg.t('attention')}!`, message);
    }
  }, []);

  const { width, height } = parentElement.getBoundingClientRect();

  return (
    <DashboardItemInformation
      className={`InvalidPeriodicityAlert ${className}`}
      path={path}
      width={width}
      height={height}
      showErrorDialog={() =>
        ObjectErrorPopup({
          message,
        })
      }
    />
  );
}
