import React from "react";
import LoadingSvg from "components/ui/loading/LoadingSvg";

const LoadingCenter = ({background = false}) => {
    return (
        <>
            {background &&
            <div className="background-loading-opacity"/>
            }
            <div className="center-spinner">
                <div className="logo-spinner">
                    <span className="icon-bim-logo"/>
                </div>
                <div className="loading-image">
                    <LoadingSvg/>
                </div>
            </div>
        </>
    );
}

export default LoadingCenter;