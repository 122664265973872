import React, { useEffect, useMemo, useRef } from 'react';

import Api from 'components/Api';
import LoadingCenter from 'components/ui/loading/LoadingCenter';
import FilterService from 'components/filter/FilterService';
import FilterBar from 'components/filter/FilterBar';
import UiBlocker from 'components/bng/ui/UiBlocker';
import useEventBus from 'components/hooks/useEventBus';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import useReduxSelector from 'components/hooks/useReduxSelector';
import DashViewConfig from 'components/bng/pages/cockpit/renders/DashViewConfig';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import bimEventBus from 'BimEventBus';
import UiMsg from 'components/ui/UiMsg';

function CockpitDashboardRender({
  path,
  dashData,
  initialFilters = [],
  fromCockpit,
  editMode = false,
  onDashGridRef = _.noop,
  onSaveFilters = _.noop,
  dashKey = '',
  initialFiltersRef,
  initialData,
  DashComponent,
  viewConfig = DashViewConfig.PAGE,
  onCockpitCreation = false,
  onMoveObject = false,
  breakpointView = 'DESKTOP',
  ...props
}) {
  const $dashPageCtx = useDashboardPageCtx.cached((state) =>
    _.pick(state, ['dash', 'loading', 'fetchDash', 'filters', 'changes', 'itemOverrides', 'resetState'])
  );

  const $bigTableState = useReduxSelector(
    (state) => {
      return {
        bigtableFilterModel: state.bigTable.data.bigtableFilterModel,
        bigtableSortModel: state.bigTable.data.bigtableSortModel,
      };
    },
    { listenToChanges: false }
  );

  const $lastFetchProps = useRef();

  const isPublicPublisher = props.isFromPublisher && !props.privateVisibility;

  const fetchDashDeps = isPublicPublisher
    ? []
    : [path, $dashPageCtx.filters, $dashPageCtx.changes, $dashPageCtx.itemOverrides];

  useAsyncEffect({
    onMount: async () => {
      if (_.isEqual($lastFetchProps.current, fetchDashDeps)) {
        return;
      }

      // Path changed, reset store state
      if ($lastFetchProps.current?.[0] !== fetchDashDeps[0]) {
        $dashPageCtx.resetState();
      }

      $lastFetchProps.current = fetchDashDeps;

      try {
        await $dashPageCtx.fetchDash({
          path,
          initialFilters,
          initialFiltersRef,
          initialData,
        });

        // Trigger resize on DashGrid on filter change
        if (!_.isEmpty($dashPageCtx.changes)) {
          const lastChange = $dashPageCtx.changes[$dashPageCtx.changes.length - 1];
          if (lastChange?.type === 'UPDATE_FILTERS') {
            window.requestAnimationFrame(() => {
              bimEventBus.emit('CockpitButtons:HEADER_EXPANDED');
            });
          }
        }
      } catch (e) {
        console.error('Error on fetchDash()', e);
        UiMsg.ajaxError(null, e);
      }
    },
    deps: fetchDashDeps,
  });

  useEventBus(Api.AssistedAnalysis.ASSISTED_ANALYSIS_UPDATED, () => {
    $dashPageCtx.fetchDash({
      path,
      initialFilters,
      initialFiltersRef,
      initialData,
    });
  });

  const $dashGrid = useRef();

  // 🤮 Used by ExportApi.share
  window.__CURRENT_DASHGRID = $dashGrid.current?.dashGridRef?.wrappedComponent;

  const $dashData = {
    data: $dashPageCtx.dash,
    isLoading: $dashPageCtx.loading,
  };

  useEffect(() => {
    if (!$dashData.data) {
      return;
    }
    afterInitJs();

    if (application.page.isMobile()) {
      dashboardRenderFragmentScripts({
        isFromCockpit: props.isFromCockpit ?? false,
      });
    }
  }, [$dashData.data]);

  useEventBus(
    'BngCockpitButtons:ExportToPdf',
    () => {
      const { filters, ...dashState } = $dashGrid.current?.dashGridRef?.wrappedComponent?.stateForExport() ?? {};
      Api.Export.exportCockpitObject({
        content: path,
        filters,
        ...($bigTableState.current ?? {}),
        dashState,
      });
    },
    [path, $bigTableState]
  );

  useEventBus(
    'BngCockpitButtons:MetadataExport',
    async () => {
      try {
        const { filters } = $dashGrid.current?.dashGridRef?.wrappedComponent?.stateForExport() ?? {};
        const url = await Api.Metadata.buildDashboardMetadataUrl({
          content: path,
          filters,
          ...($bigTableState.current ?? {}),
        });
        window.open(url, '_blank');
      } catch (e) {
        console.error('Error on BngCockpitButtons:MetadataExport', e);
        UiMsg.ajaxError(null, e);
      }
    },
    [path, $bigTableState]
  );

  const filterBarProps = useMemo(() => {
    if (!$dashData.data || isPublicPublisher) {
      return null;
    }

    const availableFilters =
      $dashData.data.gridData?.availableFilters.length > 0 ? $dashData.data.gridData.availableFilters : initialFilters;

    const transformedFilters = FilterService.transform(availableFilters);

    return {
      type: 'REACT_COCKPIT_DASHBOARD',
      isPublisher: props.isFromPublisher ?? false,
      filters: transformedFilters,
      dashboardPath: path,
      canSave: editMode,
      onEditorMode: editMode,
      filterPosition: $dashData.data.filterPosition,
      ignoreWrapper: props.ignoreWrapper,
      dashChanges: $dashPageCtx.changes,
      onChangeListener: (filters, force, appendAndReplace = false, targetFilters = [], additionalProps) => {
        return useDashboardPageCtx.getState().filterChangeHandler(targetFilters, force, true, additionalProps);
      },
    };
  }, [$dashData.data, $dashPageCtx.changes, editMode]);

  if (!$dashData.data) {
    return <LoadingCenter />;
  }

  viewConfig = {
    ...DashViewConfig.PAGE,
    ...(viewConfig ?? DashViewConfig.PAGE),
  };

  const hasFilterClass =
    viewConfig.showFilterBar &&
    ($dashData.data.hasFilters ||
      filterBarProps?.filters?.filter((f) => !_.isEmpty(f.selectedMembers) && !f.hide).length > 0)
      ? 'dashboard-wrapper-with-filters'
      : '';
  const editModeClass = $dashData.data.editMode || editMode ? 'edit-mode' : '';

  return (
    <UiBlocker
      id="dashboard-wrapper"
      className={`CockpitDashboardRender ${hasFilterClass} ${
        viewConfig.showFilterBar && filterBarProps && $dashData.data.hasFilters ? $dashData.data.filterPositionClass : ''
      } ${editModeClass} ${props.mobile ? 'fromMobileView' : ''}`}
      block={$dashData.isLoading}
    >
      <div id="body-wrapper">
        {breakpointView !== 'DESKTOP' && (
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: 'div#page-content, #dashboard-wrapper { background: none !important; }',
            }}
          />
        )}

        {viewConfig.showFilterBar && (
          <div id="filters-dashboard-container" key={path || 'default'}>
            {filterBarProps && <FilterBar onSaveFilters={onSaveFilters} {...filterBarProps} />}
          </div>
        )}

        <div className="hide" dangerouslySetInnerHTML={{ __html: $dashData.data.dynamicCss }} />

        <div
          id="body-dashboard-home"
          className={`free-style-marker-class ${onCockpitCreation ? 'OnContainerCreation' : ''} ${
            onMoveObject ? 'OnGridCreation' : ''
          }`}
        >
          <DashComponent
            key={`dash-div${$dashData.data?.gridData.divisions || 24}-${dashKey}-${breakpointView}`}
            ref={(ref) => {
              $dashGrid.current = ref;
              onDashGridRef(ref);
            }}
            useDefaultMenus={true}
            fromCockpit={fromCockpit}
            {...$dashData.data.gridData}
            editMode={editMode}
            changes={$dashPageCtx.changes}
            breakpointView={breakpointView}
            {...props}
          />
        </div>
      </div>
    </UiBlocker>
  );
}

let initialized = false;

const afterInitJs = () => {
  j('#cmf').mouseover();
  j('.fixedtableHeader').each(function (e) {
    j(this).find('.fixed-columns').width(j(this).find('.mdx-table th:eq(0)').outerWidth(true));
  });

  if (initialized) return;

  initialized = true;

  (function () {
    let dashTableId;

    j(document).on('click', '.mdx-table', function () {
      dashTableId = j(this).attr('id');
    });

    j(document).keydown(function (e) {
      var $table = j('#' + dashTableId + ' tbody');
      var $active = j('tr.highlight-row', $table);
      var position = parseInt($active.index());
      j('tr', $table).removeClass('highlight-row');
      switch (e.keyCode) {
        case 38:
          j(j('tr', $table)[position - 1]).addClass('highlight-row');
          break;
        case 40:
          j(j('tr', $table)[position + 1]).addClass('highlight-row');
          break;
      }
    });
  })();
};

// TODO revisar se precisa desses bagulhos
const dashboardRenderFragmentScripts = ({ isFromCockpit = false }) => {
  if (!isFromCockpit && j('.dashboard-filter-box.filter-top-fixed.on-publisher').length > 0) {
    j('#dashboard-wrapper #body-wrapper').css('padding-top', 52);
  }

  const isIphoneDevice = application.page.isIphone();
  const isAndroid = application.page.isAndroid();

  j('#body-dashboard-home').css('overflow-x', 'hidden').css('overflow-y', 'hidden');

  if (isAndroid || isIphoneDevice) {
    j('#dashboard-wrapper #body-wrapper').css('padding', 0);
  }

  if (isIphoneDevice) {
    j('.AllContentWrapper').addClass('on-ios');
    j('#dashboard-wrapper #body-wrapper').css('margin-left', 0);

    //teste para detectar iphone 10
    if (window.screen.height / window.screen.width === 812 / 375 && window.devicePixelRatio === 3) {
      //no iphone 10 e necessario aumentar a altura dos filtros
      j('.dashboard-filter-box.on-publisher.filter-bottom-fixed.on-mobile').css('height', 68);
      j('.dashboard-filter-box.on-publisher.on-mobile .filter-container').css('padding-bottom', 10);
    }

    // necessario para o iphone fazer o repaint da tela em alguna casos como travar cabecalho,
    // onde se tem um posicionamento fixo e uma scroll com uma tabela. ticket #1580
    setInterval(function () {
      j('.mdx-table-container').css('-webkit-transform', 'translate3d(0,0,0)');
    }, 500);
  }

  if (isAndroid) {
    j('.main-content').addClass('on-android');
    if (screen.height > screen.width) {
      j('#dashboard-wrapper').css('width', screen.width - 10);
      j('#dashboard-wrapper #body-wrapper').css('margin', 0);
    } else if (screen.width > screen.height) {
      j('#dashboard-wrapper').css('width', screen.width - 56);
      j('#dashboard-wrapper #body-wrapper').css('margin-top', 0).css('margin-bottom', 0).css('margin-left', 6);
    }
  }

  if (!isAndroid && !isIphoneDevice) {
    j('#dashboard-wrapper #body-wrapper').css('margin-bottom', 0).css('margin-left', 6).css('margin-right', 12);
  }

  // Fixes publisher scrollbar for dashboard fixed bottom filter. See: https://github.com/sol7/bi-machine/issues/3043
  if (j('.dashboard-filter-box.filter-bottom-fixed.on-publisher.embedded').length > 0) {
    var $el = j('.main-embed-panel .render-container-panel');
    $el.height($el.height() - 20);
  }
};

export default CockpitDashboardRender;
