import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import styles from './HourFilter.module.css';

const filterOptions = [
    {
        key: 'equals',
    },
    {
        key: 'notEqual'
    },
    {
        key: 'lessThan'
    },
    {
        key: 'lessThanOrEqual'
    },
    {
        key: 'greaterThan'
    },
    {
        key: 'greaterThanOrEqual'
    },
    {
        key: 'inRange'
    }
]

export default forwardRef((props, ref) => {
    const [filter, setFilter] = useState('');
    const [filterTo, setFilterTo] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('equals');

    useEffect(() => {
        props.filterChangedCallback();
    }, [selectedFilter, filter, filterTo]);
    useEffect(() => {
        if(selectedFilter !== 'inRange' && filterTo !== "") setFilterTo('');
    }, [selectedFilter]);

    const parseToDecimal = (value) => {
        const hourFilter = value.toString().replace(':', '.').split('.');
        return parseFloat((parseFloat(hourFilter[0]) + parseFloat('.' + (hourFilter[1] || 0))/.6).toFixed(2));
    }

    const parseToHour = (value) => {
        const hour = value?.toString().split('.') || [0,0];
        return (parseInt(hour[0]) + (parseFloat('0.' + hour[1])*0.6)).toFixed(2).replace('.', ':');
    }

    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params) {
                const value = parseToDecimal(parseToHour(props.valueGetter(params)));

                switch (selectedFilter) {
                    case 'equals':
                        return parseToDecimal(filter) === value;
                    case 'notEqual':
                        return parseToDecimal(filter) !== value;
                    case 'lessThan':
                        return value < parseToDecimal(filter);
                    case 'lessThanOrEqual':
                        return value <= parseToDecimal(filter);
                    case 'greaterThan':
                        return value > parseToDecimal(filter);
                    case 'greaterThanOrEqual':
                        return value >= parseToDecimal(filter);
                    case 'inRange':
                        return value > parseToDecimal(filter) && value < parseToDecimal(filterTo);
                    default:
                        return false;
                }
            },

            isFilterActive() {
                return filter !== '';
            },

            getModel() {
                if(filter === '') return null;
                return {
                    type: selectedFilter,
                    filter: filter.contains(":") ? filter : parseToHour(parseFloat(filter)),
                    filterTo: filterTo.contains(":") ? filterTo : parseToHour(parseFloat(filterTo)),
                    filterType: 'hour'
                };
            },

            setModel(model) {
                setFilter(model?.filter || '');
                setFilterTo(model?.filterTo || '');
                setSelectedFilter(model?.type || '');
            },
        };
    });

    const mask = (value) => {
        if( value.length <= 2) return value;
        return `${value.slice(0, value.length - 2)}:${value.slice(value.length - 2)}`;
    }

    const onChange = (value, type) => {
        if (type === 'option') {
            setSelectedFilter(value)
        } else if(/\b[0-9]*\d\b$/.test(value) || value.length === 0) {
            if (type === 'filter') {
                setFilter(mask(value));
            } else if (type === 'filterTo') {
                setFilterTo(mask(value))
            }
        }
    };

    const resetFilter = () => {
        setFilter('');
        setFilterTo('');
        setSelectedFilter('equals');
    }

    return (
        <>
            <div className={styles.filterOptionsWrapper} >
                 <select
                     className={`${styles.optionSelector} `}
                     id="option"
                     onChange={(event) => onChange(event.target.value, 'option')}
                     role="listbox">
                         {filterOptions.map(item => {
                            const value = props.context.msg.t(`ag-grid.${item.key}`);
                            return <option selected={selectedFilter === item.key} key={item.key} value={item.key}>{value}</option>;
                        })}
                </select>
                <input
                    className={`${styles.filterInput}`}
                    id="filter"
                    type="text"
                    value={filter || ''}
                    onChange={event => onChange(event.target.value.replaceAll(':', '').replaceAll(' ', ''), 'filter')}
                    placeholder={props.context.msg.t(`${selectedFilter === 'inRange' ? 'ag-grid.inRangeStart' : 'ag-grid.filterOoo'}`)}
                />
                {selectedFilter === 'inRange' &&
                    <input
                        className={`${styles.filterInput}`}
                        id="filterTo"
                        type="text"
                        value={filterTo || ''}
                        onChange={event => onChange(event.target.value.replaceAll(':', '').replaceAll(' ', ''), 'filterTo')}
                        placeholder={props.context.msg.t('ag-grid.inRangeEnd')}
                    />
                }
            </div>
            {props.buttons.find(element => element === 'reset') &&(
                <div className={styles.buttonBorder}>
                    <input
                        className={`${styles.resetButton} ag-standard-button ag-filter-apply-panel-button`}
                        id='button-reset'
                        type="button"
                        value={props.context.msg.t('ag-grid.resetFilter')}
                        onClick={resetFilter}/>
                </div>
            )}
        </>
    );
});