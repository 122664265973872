import './Breadcrumb.css';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import { BREADCRUMB } from 'components/ui/redux/Actions';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

const processPage = (page = { content: '', nameFor: '' }, context) => {
  const [path = '', queryParams = ''] = page.content?.split('?') ?? [];
  let nameFor = page.nameFor ?? '';
  if (path === '/pages/addon/addon-info.iface') {
    const addonKey = new URLSearchParams(queryParams).get('addon');
    nameFor = context.msg.t(`addon.page.${addonKey}`);
  } else if (path === '/spr/bng/marketplace/item') {
    const addonKey = new URLSearchParams(queryParams).get('addon');
    nameFor = context.msg.translateIfHasKey(`marketplace.page.${addonKey}`) ?? context.msg.t(`/spr/bng/marketplace`);
  }
  return {
    ...page,
    nameFor,
  };
};

const processData = ({ currentPage = { content: '', nameFor: '', pathFor: [] }, pages = [], context }) => {
  currentPage = processPage(currentPage, context);

  return {
    name: currentPage.nameFor,
    pages: pages
      .map((p) => processPage(p, context)),
  };
};

function BreadcrumbIcon({ logoIcon }) {
  const context = useBimContext();
  let icon = null;
  if (logoIcon) {
    icon = <Icon icon={logoIcon} />;
  } else if (context.iconFolder) {
    if (context.iconFolder.startsWith('icon-')) {
      const oldIconClass = `${context.iconFolder} oldIconBreadcrumbCompatibility`;
      icon = <i className={oldIconClass}></i>;
    } else {
      icon = <Icon icon={context.iconFolder} />;
    }
  }
  return icon;
}

function PagesFragment({ pages = [] }) {
  const nrPages = pages.length;

  if (nrPages < 1) {
    return null;
  }
  const maxWidth = Math.round(600 / nrPages);
  return pages.map((page, idx) => <PageFragment key={idx} page={page} idx={idx} maxWidth={maxWidth} />);
}

function PageFragment({ page, idx, maxWidth }) {
  const context = useBimContext();

  const className =
    page.highlight || Utils.Object.isValidLink(page.content) ? 'breadcrumb-is-object' : 'breadcrumb-is-folder';
  return (
    <li className={'renderedPages'}>
      <a
        className={className}
        style={{ maxWidth: `${idx === 0 ? 250 : maxWidth}px` }}
        title={page.nameFor}
        href={page.onClick ? '#' : `${context.contextPath}${page.content}`}
        onClick={
          page.onClick
            ? (e) => {
                e.preventDefault();
                page.onClick(e);
              }
            : undefined
        }
      >
        <span>{page.nameFor}</span>
      </a>
    </li>
  );
}

export default function Breadcrumb({
  className = '',
  rendered = true,
  expanded = false,
  currentPage = {},
  pages = [],
  logoIcon = null,
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  let location = null;
  try {
    location = useLocation();
  } catch (e) {}

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    document.body.classList.add('withBreadcrumb');

    return () => {
      document.body.classList.remove('withBreadcrumb');
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    };
  }, []);

  useEffect(() => {
    // This function updates the breadcrumb when the user goes back a page using the browser with the go back one page or back arrow button
    (async () => {
      const page = pages?.find((page) => {
        let lastPart = '';
        if (Utils.Object.isBngLink(page.content) && location) {
          lastPart = page.content.split('?')[0];
        }
        return lastPart === location?.pathname;
      });

      if (page) {
        const state = await Api.Breadcrumb.updateCurrent(page.content, false);
        dispatch(BREADCRUMB.update(state));
      }
    })();
  }, [location]);

  if (!rendered) {
    return null;
  }

  const breadcrumbState = processData({
    currentPage,
    pages,
    context,
  });

  return (
    <div id="breadcrumbs" className={`breadcrumbs ${className ?? ''} ${!expanded ? 'breadcrumbs-expand' : ''}`}>
      <div className="breadcrumb-logo">
        <BreadcrumbIcon logoIcon={logoIcon} />
      </div>
      <ul
        className="breadcrumb"
        ref={(ref) => {
          // Scroll on X with mouse wheel
          ref?.addEventListener('wheel', (event) => {
            event.preventDefault();
            ref.scrollBy({
              left: event.deltaY,
              behavior: 'smooth',
            });
          });
        }}
      >
        <PagesFragment pages={breadcrumbState.pages} />

        <li className="active">
          <span dangerouslySetInnerHTML={{ __html: breadcrumbState.name }} />
        </li>
      </ul>
    </div>
  );
}
