import styles from './BngUserCockpitConfDialog.module.css';
import React, { useEffect, useMemo, useState } from 'react';

import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import Dialog from 'components/ui/Dialog';
import UiMsg from 'components/ui/UiMsg';
import BngSearch from 'components/bng/ui/BngSearch';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { BngCheckboxPure } from 'components/bng/form/BngCheckbox';
import { BngTable } from 'components/bng/ui/BngTable';
import { LabelHelper } from 'components/ui/dashboard/DashLayoutAccordion';
import BngAvatar from 'components/bng/ui/BngAvatar';
import Utils from 'components/Utils';

export default function BngUserCockpitConfDialog({ closeModal = _.noop }) {
  const { project, msg } = useBimContext();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [availableMembers, setAvailableMembers] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await Api.Project.findUsersCockpitConfig(project.id);

      const fetchedMembers = data
        .map((item) => {
          const name = Utils.Users.displayName(item.member);
          return {
            key: `${item.member.container}-${item.id}-${item.member.id}`,
            id: item.id,
            cockpitOnly: item.cockpitOnly,
            type: item.member.container ? 'GROUP' : 'USER',
            name: item.member.container ? `${msg.t('group')} ${name}` : name,
            memberId: item.member.id,
            email: item.member.email,
          };
        })
        .sort((a, b) => {
          return a.type === b.type ? 0 : a.type === 'GROUP' ? -1 : 1;
        });
      setAvailableMembers(fetchedMembers);
    } catch (e) {
      console.error('Error on BngUserCockpitConfDialog.fetchData()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const filteredMembers = useMemo(() => {
    return availableMembers.filter((member) => member.name.toLowerCase().includes(search.toLowerCase()));
  }, [availableMembers, search]);

  useEffect(() => {
    fetchData();
  }, []);

  const activitiesColumns = useMemo(() => {
    return [
      {
        label: msg.t('username'),
        size: '80%',
        render: (item) => {
          return (
            <div className={'flex-center-items'}>
              <BngAvatar
                userId={item.memberId}
                isGroup={item.type === 'GROUP'}
                icon={!item.type ? 'mail' : undefined}
              />
              <div className="ml-2 mr-2 text-overflow-ellipsis flex-grow-1" title={item.email}>
                <div className="d-flex text-overflow-ellipsis flex-direction-column">
                  <span className="text-overflow-ellipsis">{item.name}</span>
                  <span className="base-font-size text-overflow-ellipsis color-blue">{item.email}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        label: <LabelHelper helpLabel={msg.t('users.cockpit.config.hint')} label={msg.t('cockpit.only')} />,
        size: '20%',
        render: (item) => {
          const handleCheckboxChange = () => {
            item.cockpitOnly = !item.cockpitOnly;
            setAvailableMembers((prevMembers) => [...prevMembers]);
          };

          return (
            <BngCheckboxPure
              className={'jc-center d-flex'}
              checked={item.cockpitOnly}
              onChange={handleCheckboxChange}
            />
          );
        },
      },
    ];
  }, []);

  const saveData = async (availableMembers) => {
    setLoading(true);
    try {
      await Api.Project.saveUsersCockpitConfig(project.id, availableMembers);
      UiMsg.ok(msg.t('config.save.success'));
      closeModal();
    } catch (e) {
      console.error('Error on BngUserCockpitConfDialog.saveData()', e);
      UiMsg.ajaxError(msg.t('config.save.error'), e);
      setLoading(false);
    }
  };

  return (
    <Dialog
      className={`${styles.BngUserCockpitConfDialog} large`}
      title={msg.t('users.cockpit.config.title')}
      onClose={closeModal}
      loading={loading}
    >
      <BngSearch
        className={'mb-3 p-1'}
        alwaysOpen={false}
        simple={false}
        placeholder={msg.t('search.for.a.user')}
        onChange={(text) => setSearch(text)}
      />

      <Dialog.Body className={styles.table}>
        <BngTable
          cols={activitiesColumns}
          rows={filteredMembers}
          stickyHeader={true}
          showEmptyAlert={true}
          emptyAlertProps={{ message: undefined }}
          rowIdProp="key"
        />
      </Dialog.Body>

      <Dialog.Footer className="mt-3">
        <DefaultDialogActions
          closeModal={closeModal}
          onClickSaveButton={async () => await saveData(availableMembers)}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
