import './AddonInfoDialog.css';

import React from 'react';

import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import AddonInfoPage from 'components/ui/navbar/addons/AddonInfoPage';

export default function AddonInfoDialog({ addonKey, closeModal = _.noop }) {
  const context = useBimContext();

  return (
    <Dialog
      contentFullWidth={true}
      className={`AddonInfoDialog large`}
      title={context.msg.t('addons')}
      onClose={closeModal}
      newDialogLayout
    >
      <AddonInfoPage addonKey={addonKey} />
    </Dialog>
  );
}
