import styles from './FilterOpts.module.css';

import React from 'react';

import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import { BngCheckboxPure } from 'components/bng/form/BngCheckbox';

export default function FilterOpts({
  options = [],
  hasIcon = false,
  customIcon,
  className = '',
  forceTitle = false,
  useIdAsValue = false,
}) {
  const { msg } = useBimContext();

  return options.map((obj, idx) => {
    const label = obj?.label ?? obj?.name ?? obj;
    const title = obj?.title ?? obj.label ?? obj.name;
    return {
      value: useIdAsValue ? `${obj.id}` : obj.value ?? label,
      label: msg.t(label),
      render: ({ multiple, selected }) => {
        return (
          <div
            className={`flex-center-items ${styles.filterOptsWrapper} ${className}`}
            key={idx}
            title={forceTitle || obj.title !== '' ? msg.t(title) : ''}
          >
            {multiple && (
              <BngCheckboxPure
                className={`share-option-checkbox ${styles.filterCheckbox}`}
                checked={selected}
                onChange={_.noop}
              />
            )}
            {hasIcon && !customIcon && (
              <div className={`${styles.typeOptsIcon}`}>
                <Icon
                  icon={
                    Utils.Object.getObjectIcon(label, 'showChart') ??
                    Utils.Scheduling.iconForMsgType(label) ??
                    obj?.icon
                  }
                />
              </div>
            )}
            {customIcon?.(obj)}
            <div className={`${styles.labelContainer}`}>{msg.t(label)}</div>
          </div>
        );
      },
    };
  });
}
