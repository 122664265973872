import styles from './StructureNotLoadedPage.module.css';

import React, { useEffect } from 'react';

import useBimContext from 'components/hooks/useBimContext';
import LoadingCenter from 'components/ui/loading/LoadingCenter';
import Button from 'components/ui/Button';
import OpConfirmation from 'components/ui/OpConfirmation';

export default function StructureNotLoadedPage({ className = '', onClick = null, renderForCockpit = false }) {
  const context = useBimContext();

  const confirmContinue = () => {
    OpConfirmation({
      title: context.msg.t('attention'),
      message: context.msg.t('continue.anyway.confirmation.message'),
      onConfirm: onClick,
      msg: context.msg,
    });
  };

  useEffect(() => {
    const interval = setInterval(() => getHasStructureLoaded(), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getHasStructureLoaded = async () => {
    window.location.reload();
  };

  return (
    <div className={`${styles.StructureNotLoadedPage} ${className} ${renderForCockpit ? styles.renderForCockpit : ''}`}>
      <LoadingCenter className={styles.notLoadedSpinner} />
      <span className={styles.notLoadedText}>{context.msg.t('structure.not.loaded.page')}</span>
      {onClick && (
        <Button className={`structureNotLoadedContinueButton ${styles.continueButton}`} onClick={confirmContinue}>
          {context.msg.t('continue.anyway')} >
        </Button>
      )}
      <a className={styles.whyLink} href={context.msg.t('why.link')} target={'_blank'}>
        {context.msg.t('why.is.this.happening')}
      </a>
    </div>
  );
}
