class FallbackBackend {
  storage = {};

  setItem = (key, val) => {
    this.storage[key] = val;
  };

  getItem = (key) => {
    return this.storage[key];
  };

  removeItem = (key) => {
    delete this.storage[key];
  };
}

class Storage {
  constructor(backend) {
    this.backend = backend;
  }

  put(key, value) {
    this.backend.setItem(key, JSON.stringify(value));
  }

  get(key) {
    const item = this.backend.getItem(key);
    if (!item) return item;
    return JSON.parse(item);
  }

  remove(key) {
    this.backend.removeItem(key);
  }
}

// Key reserved on LocalStorage to represent user session data
// This key is cleared on the/spr/bng/login page
export const SESSION_DATA = 'SESSION_DATA';

let sessionStore;
try {
  sessionStore = new Storage(window.sessionStorage);
} catch (e) {
  sessionStore = new Storage(new FallbackBackend());
}

let localStore;
try {
  localStore = new Storage(window.localStorage);
} catch (e) {
  localStore = new Storage(new FallbackBackend());
}

export { sessionStore, localStore };
