import styles from './TotalizerCell.module.css';

import React from 'react';

import { numberFormatter } from 'components/bng/pages/bigTable/functions';
import { totalizerOperators } from 'components/bng/pages/bigTable/constants';

export const RenderTotalizerCell = (props) => {
  const style = {
    fontSize: props.colDef.totalizerConfig.fontSize + 'px',
    justifyContent: align(props.colDef.custom.align),
    color: props.colDef.custom.textColor
  };

  return (
    <div className={`TotalizerCell ${styles.totalizerValueWrapper}`} style={style}>
      <div title={title(props.colDef.totalizerConfig.operator, props.context.msg)} className="d-flex">
        {props.value}
      </div>
    </div>
  );
};

const align = (align) => {
  switch (align) {
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'left':
    default:
      return 'flex-start';
  }
};

const title = (operatorName, msg) => {
  const operator = totalizerOperators[operatorName];
  return operator ? msg.t(operator.label) : undefined;
};

export const updateTotalizerValue = ({ rowCallback = _.noop, colDefs = [], prependOperator = false, msg = _.noop }) => {
  const totalizerValues = {};
  colDefs.forEach((col) => {
    if (!col.totalizerConfig.enabled) {
      return;
    }

    const key = col.colId;
    const operator = totalizerOperators[col.totalizerConfig.operator];
    const accumulatorInstance = operator.createAccumulator();

    rowCallback((rowNode) => {
      const val = rowNode.data[key];
      if (!val) return;
      if (val.caption) {
        accumulatorInstance.accumulate(new Number(val.caption));
      } else {
        accumulatorInstance.accumulate(val);
      }
    });

    const result = accumulatorInstance.result();
    const formatter = numberFormatter(col.custom.decimals, col.custom.formatString);
    const formattedResult = (accumulatorInstance.format ?? formatter)({
      value: result,
      defaultFormatter: formatter,
    });

    const formattedValue = `${col.custom.prefix} ${formattedResult} ${col.custom.suffix}`;

    if (prependOperator) {
      totalizerValues[key] = `${title(msg, operator)}: ${formattedValue}`;
    }
    totalizerValues[key] = formattedValue;
  });

  return [totalizerValues];
};
