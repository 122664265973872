import React from 'react';

import ContextEnhancer from 'components/ContextEnhancer';
import BaseTableStep from 'components/bng/project/updateApps/BaseTableStep';
import BngCircleCheckbox from 'components/bng/form/BngCircleCheckbox';
import {ACTION} from "components/bng/project/updateApps/projectUpdateAppsCommons";

const EditObjectsWizardStep = ({
  context: { msg },
  values,
  onChange,
  rows = [],
}) => {
  return (
    <BaseTableStep
      alertMessage={msg.t('project.update.app.dialog.conflict.alert')}
      rows={rows}
      cols={[
        {
          key: 'keep',
          size: '85px',
          label: msg.t('keep'),
          headerClassName: 'jc-center',
          render(row) {
            return (
              <div className="text-center">
                <BngCircleCheckbox
                  type={'danger'}
                  field={{
                    value:
                      values.actions[row.sourcePath]?.action === ACTION.KEEP,
                  }}
                  onChange={() => {
                    const actions = { ...values.actions };
                    actions[row.sourcePath] = {
                      ...actions[row.sourcePath],
                      action: ACTION.KEEP,
                    };
                    onChange(actions);
                  }}
                />
              </div>
            );
          },
        },
        {
          key: 'update',
          size: '85px',
          label: msg.t('update'),
          headerClassName: 'jc-center',
          render(row) {
            return (
              <div className="text-center">
                <BngCircleCheckbox
                  field={{
                    value:
                      values.actions[row.sourcePath]?.action === ACTION.UPDATE,
                  }}
                  onChange={() => {
                    const actions = { ...values.actions };
                    actions[row.sourcePath] = {
                      ...actions[row.sourcePath],
                      action: ACTION.UPDATE,
                    };
                    onChange(actions);
                  }}
                />
              </div>
            );
          },
        },
        {
          key: 'duplicate',
          size: '85px',
          label: msg.t('duplicate'),
          headerClassName: 'jc-center',
          render(row) {
            return (
              <div className="text-center">
                <BngCircleCheckbox
                  type={'success'}
                  field={{
                    value:
                      values.actions[row.sourcePath]?.action === ACTION.CLONE,
                  }}
                  onChange={() => {
                    const actions = { ...values.actions };
                    actions[row.sourcePath] = {
                      ...actions[row.sourcePath],
                      action: ACTION.CLONE,
                    };
                    onChange(actions);
                  }}
                />
              </div>
            );
          },
        },
      ]}
    />
  );
};

export default ContextEnhancer(EditObjectsWizardStep);
