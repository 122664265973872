import React from 'react';

import MktDefaultTab from 'components/bng/pages/marketplace/tabs/MktDefaultTab';

export const ADDITIONAL_GROUPS = [
  {
    title: 'marketplaceTab.additionals',
    desc: 'additionals.desc',
    key: 'additionals',
  },
];

export default function MktAdditionalsTab({ ...props }) {
  return <MktDefaultTab className={'MktAdditionalsTab'} groupsToRender={ADDITIONAL_GROUPS} {...props} />;
}
