import React from 'react';

import ContextEnhancer from "components/ContextEnhancer";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";
import LayoutTab from 'components/bng/pages/bigTable/tabs/LayoutTab';

const BigTableLayoutMenuItem = ({
                                   toggleAccordion =_.noop,
                                   context,
                                   ...props
                               }) => {

    const toggleConfigAccordion = async (event) => {
        return toggleAccordion(LayoutTab, 'BigTableLayoutMenuItem');
    };

    return (
        <RightMenuItem title={context.msg.t('bigTable.layout')}
                       icon="style"
                       className={`BigTableLayoutMenuItem`}
                       onClick={toggleConfigAccordion}
                       {...props}/>
    );
};

export default ContextEnhancer(BigTableLayoutMenuItem);