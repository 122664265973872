import './BngAdvancedModeAlert.css';
import image from "./assets/BngAdvancedModeAlert.svg";

import React from "react";
import PropTypes from "prop-types";


import ContextEnhancer from "components/ContextEnhancer";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import {BngEnableAdvancedModeButton, useLoadingListener} from "components/bng/pages/newAnalysis/BngEnableAdvancedModeButton";

export const BngAdvancedModeAlert = ContextEnhancer(({context, assistedType = ''}) => {

    const {loading, listener} = useLoadingListener();

    return (
        <UiBlocker className="BngAdvancedModeAlert text-center" block={loading}>

            <img src={image} alt="alert"/>

            <h4>{context.msg.t('BngAdvancedModeAlert.title')}</h4>

            <div className="Message"
                 dangerouslySetInnerHTML={{__html: context.msg.t('BngAdvancedModeAlert.message')}}></div>

            <BngEnableAdvancedModeButton className={'bng-button fix save Action'}
                                         listener={listener}
                                         assistedType={assistedType}
            />

        </UiBlocker>
    );

});

BngAdvancedModeAlert.propTypes = {
    assistedType: PropTypes.string,
}