import './BngVideoModal.css';

import React from 'react';

import Button from 'components/ui/Button';
import useOverlay from 'components/hooks/useOverlay';
import useBimContext from 'components/hooks/useBimContext';

export default function BngVideoModal({
  videoUrl,
  title,
  explanation,
  className = '',
  onClose = _.noop,
  classNameOverlay = '',
  fullHeight = false,
  description = '',
  descriptionLink = '#',
}) {
  const context = useBimContext();
  const [overlayRef] = useOverlay(true);

  return (
    <>
      <div className={classNameOverlay}>
        <div ref={overlayRef}></div>
      </div>
      <div className={`BngVideoModal ${className}`}>
        <div className="Body">
          <div className="Step">
            <div className="Children">
              <iframe
                className={`Image IntroVideo ${fullHeight ? 'FullHeight' : ''}`}
                src={`${context.msg.t(videoUrl)}?autoplay=1&rel=0&iv_load_policy=3`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              {title && <h4 className="Title">{title}</h4>}
              {explanation && <p className="Subtitle">{explanation}</p>}
            </div>
            <div className="Footer">
              {description && (
                <a className="Description" href={descriptionLink} target={'_blank'} rel={'noopener noreferrer'}>
                  {description}
                </a>
              )}
              <div className="Buttons">
                <Button className={'bng-button save'} onClick={onClose}>
                  {context.msg.t('got.it')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
