import './BngAvatarUploadInput.css';

import React, { useRef, useState } from 'react';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';

const fileToBase64 = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      res(reader.result);
    };
    reader.onerror = function (error) {
      rej(error);
    };
  });
};

export default function BngAvatarUploadInput({ className = '', field, form, selectImageText = false }) {
  const { t } = useTranslation();
  const inputFile = useRef();
  const [fileBase64, setFileBase64] = useState();

  const avatarImgValue = fileBase64 ?? field?.value?.link;
  return (
    <div
      className={`BngAvatarUploadInput flex-center-items jc-center ${className}`}
      onClick={() => {
        inputFile.current.click();
      }}
    >
      {avatarImgValue ? (
        <div className="ImageContainer">
          <img src={avatarImgValue} />
        </div>
      ) : (
        <Icon icon={'person'} />
      )}

      <div className={`${selectImageText ? 'InnerText' : 'InnerCircle'} flex-center-items jc-center`}>
        {selectImageText ? <div>{t('select.image')}</div> : <Icon icon={'face'} />}
      </div>
      <input
        type="file"
        ref={inputFile}
        accept="image/png, image/jpeg"
        onChange={async (event) => {
          const uploadedFile = event.target.files[0];
          let base64 = '';
          try {
            base64 = await fileToBase64(uploadedFile);
            setFileBase64(base64);
          } catch (e) {
            console.error(e);
          }
          form?.setFieldValue(
            field?.name,
            uploadedFile
              ? {
                  name: uploadedFile.name,
                  type: uploadedFile.type,
                  base64: base64.substring(base64.indexOf(',') + 1),
                }
              : null
          );
        }}
      />
    </div>
  );
}
