import './UserGroupsDialog.css';
import React, { useEffect, useRef, useState } from 'react';
import { Field, FieldArray, Formik } from 'formik';
import { connect } from 'react-redux';

import ContextEnhancer from 'components/ContextEnhancer';
import Dialog from 'components/ui/Dialog';
import { bngYup } from 'components/bng/form/yup/BngYup';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { BngInput } from 'components/bng/form/BngInput';
import BngSearch from 'components/bng/ui/BngSearch';
import UserMiniature from 'components/bng/pages/admin/users/UserMiniature';
import Icon from 'components/ui/common/Icon';
import OpConfirmation from 'components/ui/OpConfirmation';
import { BngForm } from 'components/bng/form/BngForm';

const UserGroupSchema = bngYup((yup) =>
  yup.object().shape({
    name: yup.string().default(''),
    users: yup.array().of(
      yup.object().shape({
        id: yup.number().required().default(null),
        role: yup.object(),
        user: yup.object().required().default({}),
        selected: yup.bool().required().default(false),
      })
    ),
    projectId: yup.number().required().default(null),
  })
);

const UserGroupsDialog = ({
  context = {},
  closeModal = _.noop,
  isEditing = false,
  groupId = null,
  afterSubmit = _.noop,
  showDeleteButton = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [group, setGroup] = useState({});

  let inputRef = useRef(null);

  const fetchUserGroups = async () => {
    try {
      setLoading(true);

      let fetchedUsers = await Api.UserGroup.findUsersInProject(context.project.id);

      if (groupId !== null) {
        const fetchedGroup = await Api.UserGroup.findProjectUserGroup(groupId);
        fetchedGroup.users = fetchedUsers?.map((user) => {
          return {
            ...user,
            selected: fetchedGroup.users.some((selUser) => selUser.id === user.user.id),
          };
        });
        setGroup(fetchedGroup);
      } else {
        fetchedUsers = fetchedUsers?.map((user) => {
          return { ...user, selected: false };
        });
        setGroup({ ...group, users: fetchedUsers });
      }
    } catch (e) {
      console.error(e);
      UiMsg.error(context.msg.t('user.fetch.error'), e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserGroups();
  }, []);

  const filterUsers = (projectUsers) => {
    let filteredUsers = search
      ? projectUsers.users.filter(
        (projectUser) =>
          projectUser.user.displayName.toLowerCase().includes(search.toLowerCase()) ||
          projectUser.user.email.toLowerCase().includes(search.toLowerCase())
      )
      : projectUsers.users;
    return _.orderBy(filteredUsers, ['selected', 'user.displayName'], ['desc', 'asc']);
  };

  const onSubmit = async (values) => {
    if (values.name === '' || !values.name) {
      inputRef.current.querySelector('.usrGrpNameInput').focus();
      UiMsg.error(context.msg.t('group.please.insert.name'));
      return;
    }

    try {
      values = _.cloneDeep(values);
      values.projectId = context.project.id;
      values.users = values.users.filter((user) => user.selected);
      await Api.UserGroup.saveUserGroup(values);
      await Api.executeExp('#{projectSelectionMB.projectCRUD.reloadEntity()}');
      UiMsg.ok(context.msg.t(`group.${isEditing ? 'edited' : 'created'}.successfully`));
      await afterSubmit();
      closeModal();
    } catch (e) {
      console.error(e);
      if (e.response?.status === 409) {
        UiMsg.error(context.msg.t('groups.exists'));
      } else {
        UiMsg.error(context.msg.t('group.save.error'), e);
      }
    }
  };

  const onDelete = async () => {
    try {
      await Api.UserGroup.deleteUserGroup(group.id);
      await Api.executeExp('#{projectSelectionMB.projectCRUD.reloadEntity()}');
      UiMsg.ok(context.msg.t(`remove_success`, 'group'));
      await afterSubmit();
      closeModal();
    } catch (e) {
      console.error(e);
      UiMsg.error(context.msg.t('group.delete.error'), e);
    }
  };

  const getInitialValues = () => {
    let initialValues = UserGroupSchema.default();
    initialValues.id = group.id;
    initialValues.name = group.name;
    initialValues.users = group.users;
    initialValues.projectId = context.project.id;
    return initialValues;
  };

  return (
    <Formik initialValues={UserGroupSchema.default()} validationSchema={UserGroupSchema} onSubmit={onSubmit}>
      {({ values, resetForm }) => {
        useEffect(() => {
          if (!values.user) {
            resetForm({
              values: {
                ...getInitialValues(),
              },
            });
          }
        }, [group]);

        const filteredUsers = filterUsers(values);

        return (
          <>
            <Dialog
              className="UserGroupsDialog large"
              loading={loading}
              title={context.msg.t(isEditing ? 'groups.edit' : 'groups.new')}
              onClose={closeModal}
              contentFullWidth={true}
            >
              <BngForm>
                <Dialog.Body>
                  <div className="usrGrpNameWrapper" ref={inputRef}>
                    <span className="usrGrpNameLbl">{context.msg.t('group.name')}</span>
                    <Field name="name" className="usrGrpNameInput" component={BngInput} maxLength={30} />
                  </div>
                  <BngSearch
                    className={`usrGrpSearch`}
                    alwaysOpen={true}
                    simple={true}
                    placeholder={context.msg.t('search.for.a.user')}
                    onChange={setSearch}
                  />

                  <div className="userList">
                    <FieldArray
                      name="users"
                      render={(arrayHelpers) => (
                        <>
                          {filteredUsers.map((user, index) => (
                            <Field key={`${user.id}-miniature`} name={`users.${index}`}>
                              {() => (
                                <UserMiniature
                                  projectUser={user}
                                  context={context}
                                  selected={user.selected}
                                  onChange={() => {
                                    arrayHelpers.replace(_.findIndex(values.users, { id: user.id }), {
                                      ...user,
                                      selected: !user.selected,
                                    });
                                  }}
                                />
                              )}
                            </Field>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </Dialog.Body>

                <Dialog.Footer>
                  {isEditing && showDeleteButton && (
                    <div
                      className="GroupDeleteButton"
                      onClick={() => {
                        OpConfirmation({
                          title: context.msg.t('attention'),
                          message: context.msg.t('group.remove.confirmation'),
                          onConfirm: async () => await onDelete(),
                          msg: context.msg,
                        });
                      }}
                    >
                      <Icon icon="delete" />
                      <label className="labelDeleteGroup">{context.msg.t('groups.remove')}</label>
                    </div>
                  )}
                  <DefaultDialogActions
                    closeModal={closeModal}
                    submitting={loading}
                    context={context}
                    okLabel={isEditing ? 'save' : 'create'}
                  />
                </Dialog.Footer>
              </BngForm>
            </Dialog>
          </>
        );
      }}
    </Formik>
  );
};

export default connect()(ContextEnhancer(UserGroupsDialog));