import React, { useEffect } from 'react';
import { Field } from 'formik';
import BngInputColor from 'components/bng/form/BngInputColor';
import BngField from 'components/bng/form/BngField';
import { useColorOptions } from 'components/bng/form/BngLineInputColor';

const randomColor = () => {
  return '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');
};

export default {
  render({ name, label, props, context, formikProps, ...rest }) {
    const { mappings = [], fetchProjectColors, options = [], ...propsRest } = props;

    const colorOpts = useColorOptions({
      projectId: context.project.id,
      fetchProjectColors,
      options,
    });

    useEffect(() => {
      if (colorOpts.length <= 0) {
        return;
      }

      mappings.forEach((mapping, idx) => {
        const color = colorOpts[idx] ?? randomColor();
        const field = `${name}.${mapping.value}`;
        if (!_.get(formikProps.values, field)) {
          formikProps.setFieldValue(field, color);
        }
      });
    }, [colorOpts]);

    return (
      <div className="ColorMapperParam">
        <label>{label}</label>
        {colorOpts.length > 0 &&
          mappings.map((mapping, idx) => {
            const isNotLast = mappings.length + 1 !== idx;
            return (
              <div key={idx} className={`flex-center-items gap-2 ${isNotLast ? 'mb-2' : ''}`}>
                <Field
                  name={`${name}.${mapping.value}`}
                  component={BngField}
                  inputComponent={BngInputColor}
                  rootClassName={`mb-0`}
                  withLabel={false}
                  {...propsRest}
                />
                <span className="text-sm">{context.msg.t(mapping.label)}</span>
              </div>
            );
          })}
      </div>
    );
  },
};
