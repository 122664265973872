import Api from "components/Api";
import PropTypes from 'prop-types';
import * as ReactDOM from "react-dom";

import React, {Component} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import EditObject from "components/ui/edit-object/EditObject";

const ICE_FACES_LISTENER = 'EditObjectEventListener';

class EditObjectContainer extends Component {

    static propTypes = {
        checked: PropTypes.bool,
        className: PropTypes.string,
        onChange: PropTypes.func,
        onChangeJSF: PropTypes.string,
        toggleMenu: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        checked: false,
        toggleMenu: true,
        className: '',
        onChange: _.noop,
        onChangeJSF: '',
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checked: props.checked,
        }
    }

    componentDidMount() {
        window.__EditObjectRef = this;
        application.ice.addNewListener(
            ICE_FACES_LISTENER,
            () => {
                this.setState({loading: true});
            },
            () => {
                this.setState({loading: false});
            }
        );
        this.toggleMenu(this.props.checked);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.checked !== prevProps.checked) {
            this.setChecked(this.props.checked);
        }
    }

    componentWillUnmount() {
        delete window.__EditObjectRef;
        application.ice.removeListener(ICE_FACES_LISTENER);
    }

    setChecked = (checked) => {
        this.toggleMenu(checked);
    };

    handleChange = async (event) => {
        const checked = event.target.checked;
        this.toggleMenu(checked);

        // Quick'n'dirty fix to close container creator on change
        if (j('.cancel-container-creation').length > 0) {
            j('.cancel-container-creation').click();
        }

        if(!!this.props.onChangeJSF) {
            this.props.context.execute(`#{${this.props.onChangeJSF}()}`);
            await Api.updateJsf();
        }

        this.props.onChange(checked);
    };

    getRightMenu = () => {
        return j('.sidebar.menu-min.right-side-sidebar');
    };

    isOpen = () => {
        return this.getRightMenu().hasClass('open');
    };

    toggleMenu(checked) {
        if (this.props.toggleMenu) {
            const $el = this.getRightMenu();
            const $pageContent = j('.page-content');
            if (checked) {
                $el.addClass('open');
                $pageContent.addClass('right-menu-open');
            } else {
                $el.removeClass('open');
                $pageContent.removeClass('right-menu-open');
            }
        }
        this.setState({checked});
    }

    findContainer = () => {
        if (this.__container) {
            return this.__container;
        } else {
            let c = j('#floating-object-container').find('#EditObjectContainer')[0];
            if (c) return c
        }

        this.__container = j('#floating-object-container')
            .removeClass('hide')
            .append(`<div id="EditObjectContainer"/>`)
            .find('#EditObjectContainer')[0];

        return this.__container;
    };


    render() {
        if (this.props.context.cockpitEnabled) {
            return null;
        }

        const {className, disabled} = this.props;
        const {loading, checked} = this.state;

        return ReactDOM.createPortal(
            <EditObject
                loading={loading}
                onChange={this.handleChange}
                className={className}
                checked={checked}
                disabled={disabled}
            />,
            this.findContainer()
        );
    }

}

export default ContextEnhancer(EditObjectContainer);