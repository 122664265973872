import * as yupOriginal from 'yup';
import Validator from "validator";
import { isValidPhoneNumber } from 'libphonenumber-js/max';

import {YupLang} from "components/bng/form/yup/YupLang";
import Api from "components/Api";
import {ceData} from "components/CeData";

const userLang = Api.findUserLocale();

const bngYupCurrentLang = YupLang[userLang.substring(0, 2)];

yupOriginal.setLocale(bngYupCurrentLang);

yupOriginal.addMethod(yupOriginal.string, 'validFolderName', function(args) {
    return this.test('validFolderName', function(value) {
        const { path, createError } = this;
        if(Validator.isNumeric(value)) {
            return createError({path, message: ceData.context.msg.t('folder.name.cannot.be.a.number')});
        }
        // Same as br.com.sol7.project.cms.ProjectContentService.VALID_FILE_NAME_PATTERN
        const VALID_FILE_NAME_PATTERN = /^(?:(?![×Þß÷þø])[-_0-9a-zÀ-ÿ ])+$/gi
        if (!VALID_FILE_NAME_PATTERN.test(value)) {
            return createError({path, message: ceData.context.msg.t('folder.name.only.alphanumeric')});
        }
        return true;
    });
});

yupOriginal.addMethod(yupOriginal.string, 'validObjectName', function(args) {
    return this.test('validObjectName', function(value) {
        const { path, createError } = this;

        const processedValue = (value || '').replace(/[^A-Z0-9]+/ig, '');

        if(_.isEmpty(processedValue)) {
            return createError({path, message: ceData.context.msg.t('object.name.cannot.contain.special.chars.only')});
        }

        if(Validator.isNumeric(processedValue)) {
            return createError({path, message: ceData.context.msg.t('name.cannot.be.a.number')});
        }

        return true;
    });
});

const isValidEmail = (value) => {
    //This regex validates any email or list of emails delimited by space, comma and/or semicolon
    return /^([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,5})([,;\s]+[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,5})*$/.test(value.trim());
};

yupOriginal.addMethod(yupOriginal.string, 'emails', function(args) {
    return this.test('emails', function(value) {
        const { path, createError } = this;
        value = (value || '').trim();

        if (value && !isValidEmail(value)) {
            return createError({ path, message: ceData.context.msg.t('invalid.email') });
        }

        return true;
    });
});

yupOriginal.addMethod(yupOriginal.string, 'phone', function(args) {
    return this.test('phone', function (value) {
        const { path, createError } = this;
        if (!isValidPhoneNumber(value)) {
            return createError({ path, message: ceData.context.msg.t('user.invalid.number') });
        }
        return true;
    })
})

export function bngYup (fn) {
    return fn(yupOriginal);
}
export const bngYupLang = (fn) => fn(bngYupCurrentLang);

export default bngYup;