import 'components/ui/presentation/DropdownPublicLink.css';
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import OpConfirmation from "components/ui/OpConfirmation";
import UiMsg from "components/ui/UiMsg";
import Popper from "components/bng/ui/BngPopper";
import BngClickOutsideOverlay from "components/bng/ui/BngClickOutsideOverlay";
import CopyLinkToClipboard from "components/ui/CopyLinkToClipboard";
import Icon from "components/ui/common/Icon";

class DropdownPublicLink extends Component {

    static propTypes = {
        publicLinkValues: PropTypes.object,
        onUpdate: PropTypes.func,
        idPresentation: PropTypes.number
    };

    static defaultProps = {
        publicLinkValues: {}
    };

    state = {
        displayMenu: false,
        link: '',
        open: false
    };

    async componentDidMount() {
        try {
            const link = await Api.Presentation.findLinkToShare(this.props.publicLinkValues.id, this.props.publicLinkValues.shortLink);
            this.setState({link});
        } catch (e) {
            console.log('Error on componentDidMount()', e);
            UiMsg.ajaxError(null, e);
        }
    }

    openOptions = (event) => {
        this.setState({
            popperRef: event.currentTarget,
            open: true,
        });
    };

    removePublicLink = async () => {
        const removeLink = {
            idPresentation: this.props.idPresentation,
            idPublicLink: this.props.publicLinkValues.id
        };
        try {
            await Api.Presentation.removePublicLink(removeLink);
            this.props.onUpdate();
        } catch (e) {
            UiMsg.ajaxError('error', e);
        }
    };

    attemptPublicLink = () => {
        OpConfirmation({
            title: this.props.publicLinkValues.identifier,
            message: this.props.context.msg.t('slide.show.remove.public.link'),
            onConfirm: async () => {
                await this.removePublicLink()
            },
            msg: this.props.context.msg
        });
        this.setState({open: false})
    };

    render() {
        const container = j('.PublicLinkListDialog')[0];

        return (
            <div style={{position: 'relative'}}>
                <a className="dropdown-menu-tst">
                    <div className="button-menu-publisher presentation-public-link" onClick={this.openOptions}>
                        <Icon icon="more_vert"/>
                    </div>
                    <Popper container={container} className="bng-dropdown-parent"
                            open={this.state.open}
                            anchorEl={this.state.popperRef}
                            modifiers={{
                                preventOverflow: {
                                    boundariesElement: container,
                                },
                            }}>
                        <BngClickOutsideOverlay onClick={() => this.setState({open: false})} container={container}/>
                        <ul className="bng-dropdown presentation-public-link">
                            <li className="li-dropdown public-link">
                                <CopyLinkToClipboard linkToCopy={this.state.link} fullUrl={false}/>
                            </li>
                            <li onClick={this.attemptPublicLink} title={this.props.context.msg.t('delete_button')}
                                className="li-dropdown public-link" style={{display: 'flex', alignItems: 'center'}}>
                                <Icon icon="delete"/>
                                <span>{this.props.context.msg.t('delete_button')}</span>
                            </li>
                        </ul>
                    </Popper>
                </a>
            </div>
        );
    }

}

export default ContextEnhancer(connect()(DropdownPublicLink));