import styles from './AdaAiChat.module.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FastField, Form, Formik } from 'formik';

import useTranslation from 'components/hooks/useTranslation';
import useBimContext from 'components/hooks/useBimContext';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import { BngPanelFooter } from 'components/bng/ui/BngPanel';
import BngStepTutorialDialog from 'components/bng/ui/BngStepTutorial/BngStepTutorialDialog';
import { bngYup } from 'components/bng/form/yup/BngYup';
import CodeBlock from 'components/bng/adaAi/CodeBlock';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import useAsyncEffect from 'components/hooks/useAsyncEffect';

const MsgFormSchema = bngYup((yup) => {
  return yup.object({
    message: yup.string().required().min(1).max(4096).trim().default(''),
  });
});

function TypingDots() {
  const { t } = useTranslation();
  return (
    <div className={`TypingDots ${styles.TypingDots}`}>
      {t('sql.ada.ai.query.assistant.typing')}
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

function AdaAiChatMessage({ invert = false, avatar = '', name = '', message = '', typing = false }) {
  const { t } = useTranslation();

  const isCodeMessage = name === t('sql.ada.ai.query.assistant.displayName');

  return (
    <div className={`AdaAiChatMessage ${invert ? styles.AdaAiChatMessageInverter : styles.AdaAiChatMessage}`}>
      <div className={`AdaAiChatMessageArrow ${styles.AdaAiChatMessageArrow}`}></div>
      <div className={`AdaAiChatMessageAvatar ${styles.AdaAiChatMessageAvatar}`}>
        <img src={avatar} alt="Avatar" />
      </div>
      <div className={`AdaAiChatMessageMessage ${styles.AdaAiChatMessageMessage}`}>
        {!invert && <div className={`AdaAiChatMessageName ${styles.AdaAiChatMessageName}`}>{name}:</div>}
        {typing ? (
          <TypingDots />
        ) : isCodeMessage ? (
          <CodeBlock message={message} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </div>
    </div>
  );
}

const findTranslated = (context, translatedItem = {}) => {
  const DEFAULT_LOCALE = 'pt_BR';

  const translations = translatedItem?.translations ?? {};
  return (
    translations[context?.user?.preferredLanguage] ?? translations[DEFAULT_LOCALE] ?? Object.values(translations)[0]
  );
};

const assistantConfig = {
  titleSuffix: (metadataObject) => {
    if (!metadataObject) return '';

    if (metadataObject.dashboardItems.length === 1 ) {
      return ` - ${metadataObject.dashboardItems.map((item) => item.name).join(', ')}`;
    } else if (metadataObject.dashboardTitle) {
      return ` - ${metadataObject.dashboardTitle}`;
    }

    return '';
  },
};


export default function AdaAiChat({
  closeModal = _.noop,
  initialMessages = [],
  onMessages = _.noop,
  initialMessage,
  initialAssistantKey,
  metadataObject,
}) {
  const { t } = useTranslation();
  const context = useBimContext();

  const $formikRef = useRef();
  const $messagesEndRef = useRef();
  const $tutorialApi = useRef();

  const [messages, setMessages] = useState(initialMessages);
  const [currentStep, setCurrentStep] = useState(initialMessages.length > 0 || !_.isEmpty(initialMessage) ? 1 : 0);
  const [isAdaTyping, setIsAdaTyping] = useState(false);
  const [assistantId, setAssistantId] = useState('');
  const [threadId, setThreadId] = useState('');
  const [assistantData, setAssistantData] = useState();
  const [fileId, setFileId] = useState('');


  useAsyncEffect({
    onMount: async () => {
      if (assistantData) {
        return;
      }

      try {
        const assistants = await Api.AdaAi.findAll();
        const assistant = assistants.find((assistant) => assistant.assistantKey === initialAssistantKey);
        setAssistantData(assistant);

      } catch (e) {
        console.error('Error while trying to find assistantKey', e);
        UiMsg.ajaxError(null, e);
      }
    },
  });

  useEffect(() => {
    onMessages({ messages });
    $messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isAdaTyping]);

  useEffect(() => {
    const handleScroll = async () => {
      await Utils.sleep(1000);
      $messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    handleScroll();
  }, [initialMessage, currentStep]);

  useEffect(() => {
    if (!initialMessage || !assistantData) {
      return;
    }

    onSendMessage(initialMessage);
  }, [initialMessage, assistantData]);

  useEffect(() => {
    $tutorialApi.current?.changeStep(currentStep);
  }, [currentStep]);

  const onSendMessage = async (values) => {
    if (_.isEmpty(values.message)) {
      return;
    }


    try {
      setMessages((messages) => [
        ...messages,
        {
          message: values.message,
          user: context.user,
        },
      ]);
      $formikRef.current?.resetForm();

      try {
        setIsAdaTyping(true);

        const response = await Api.AdaAi.sendMessage({
          message: values.messageGpt || values.message,
          metadata: metadataObject,
          accountId: context.accountId,
          assistantId,
          threadId,
          userId: context.user.id,
          name: assistantData.name,
          description: assistantData.description,
          instructions: assistantData.instruction,
          model: assistantData.model,
          type: assistantData.type,
          temperature: assistantData.temperature,
          fileId,
        });

        setMessages((messages) => [
          ...messages,
          {
            message: response.data,
            user: {
              displayName: t('sql.ada.ai.query.assistant.displayName'),
              avatar: Api.buildUrl('/resources/images/ada-ai/ada.png'),
            },
          },
        ]);

        setAssistantId(response.assistantId);
        setThreadId(response.threadId);
        setFileId(response.fileId);
      } catch (e) {
        console.error('Error while trying to send messages to Ai service', e);
        UiMsg.ajaxError(null, e);
      } finally {
        setIsAdaTyping(false);
      }
    } catch (e) {
      console.error('Error while sending user message to AdaAi', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const renderTitle = () => {
    const titleSuffix = assistantConfig?.titleSuffix(metadataObject) || '';
    const fullTitle = `${t('ada.ai.dialog.title')}${titleSuffix}`;

    return (
      <div
        className={`AdaAiChatTitleText ${styles.AdaAiChatTitleText}`}
        title={fullTitle}
      >
        {fullTitle}
      </div>
    );
  };

  const steps = useMemo(
    () => [
      {
        title: () => (
          <div className={`AdaAiChat ${styles.AdaAiChat}`}>
            <img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI Icon" />
            {renderTitle()}
          </div>
        ),
        hideClose: false,
        render: ({ changeToNextStep }) => {
          return (
            <div className={`StepOneWrapper ${styles.StepOneWrapper}`}>
              {assistantData?.props?.phrase?.map((translatedPhrase, index) => {
                const phraseData = findTranslated(context, translatedPhrase);
                return (
                  <div key={index}>
                    <img
                      src={Api.buildUrl('/resources/images/ada-ai/ada-ai-acc.png')}
                      alt="Ada Ai Dialog"
                      className={`AdaAiChatBodyImg ${styles.AdaAiChatBodyImg}`}
                    />
                    <h1>{t('ada.ai.dialog.body.title')}</h1>
                    <h2>{phraseData.introductory}</h2>
                    <h3>{phraseData.benefits}</h3>
                    <h3 className={`Question ${styles.Question}`}>{phraseData.question}</h3>
                  </div>
                );
              })}
              <div className={`AdaAiChatMessageButton ${styles.AdaAiChatMessageButton}`}>
                {assistantData?.props?.questions?.map((translatedQuestion, index) => {
                  const questionData = findTranslated(context, translatedQuestion);
                  return (
                    <BngIconButton
                      key={index}
                      icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-idea.png')} alt="Ada Ai" />}
                      className={`AdaAiChatBodyButton ${styles.AdaAiChatBodyButton}`}
                      text={questionData.caption}
                      onClick={() => {
                        changeToNextStep();
                        if (currentStep === 0) {
                          setCurrentStep(1);
                          onSendMessage({
                            message: questionData.question,
                            user: {
                              displayName: context.user.displayName,
                              avatar: context.user.avatarLink,
                            },
                          });
                        }
                      }}
                    />
                  );
                })}
              </div>
            </div>
          );
        },
        stepDisabled: false,
        nextStepDisabled: false,
        backStepDisabled: false,
      },
      {
        title: ({ changeToPreviousStep }) => (
          <div className={`AdaAiChatTilte ${styles.AdaAiChatTitle}`}>
            <Icon icon="chevron_left" onClick={changeToPreviousStep} className={`BackArrow ${styles.BackArrow}`} />
            <img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI Icon" />
            {renderTitle()}
          </div>
        ),
        hideClose: false,
        render: () => {
          return (
            <div className={`AdaAiChatWrapper ${styles.AdaAiChatWrapper}`}>
              <div className={`AdaAiChatHistoryWrapper ${styles.AdaAiChatHistoryWrapper}`}>
                {messages.map((message, idx) => {
                  return (
                    <AdaAiChatMessage
                      key={`Message-${idx}`}
                      name={message.user.displayName}
                      avatar={message.user.avatarLink || Api.buildUrl('/resources/images/ada-ai/ada.png')}
                      invert={idx % 2 === 0}
                      message={message.messageGpt || message.message}
                    />
                  );
                })}
                {isAdaTyping && (
                  <AdaAiChatMessage
                    name={t('sql.ada.ai.query.assistant.displayName')}
                    avatar={Api.buildUrl('/resources/images/ada-ai/ada.png')}
                    invert={false}
                    typing={true}
                  />
                )}
                <div ref={$messagesEndRef} />
              </div>
              <BngPanelFooter className={`BngPanelFooter ${styles.BngPanelFooter}`}>
                <Formik
                  initialValues={MsgFormSchema.default()}
                  onSubmit={onSendMessage}
                  innerRef={$formikRef}
                  validationSchema={MsgFormSchema}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form
                        className={`FooterAdaAiChat ${styles.FooterAdaAiChat}`}
                        autoComplete="off"
                        style={{ display: 'flex' }}
                      >
                        <FastField
                          className={`MessageInput ${styles.MessageInput}`}
                          name="message"
                          placeholder={t('your.answer')}
                          maxLength={4096}
                          style={{ maxHeight: 60, overflowY: 'hidden' }}
                          onKeyDown={async (e) => {
                            if (e.keyCode !== 13) {
                              return;
                            }

                            if (e.altKey) {
                              setFieldValue('message', values.message + '\r\n');
                            } else {
                              onSendMessage(values);
                            }
                          }}
                        />
                        <BngIconButton className="Send" type="submit" icon="send" title={t('send.comment')} />
                      </Form>
                    );
                  }}
                </Formik>
              </BngPanelFooter>
            </div>
          );
        },
        stepDisabled: false,
        nextStepDisabled: false,
        backStepDisabled: false,
      },
    ],
    [messages, isAdaTyping, currentStep, assistantData, initialMessage]
  );

  return (
    <BngStepTutorialDialog
      enableDotNavigation={false}
      renderDefaultFooter={false}
      steps={steps}
      newDialogLayout={true}
      className={styles.AdaAiChat}
      onClose={closeModal}
      onApi={(api) => ($tutorialApi.current = api)}
    />
  );
}
