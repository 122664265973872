import { Axios } from 'commonUtils';

class UserAccessUi {
  constructor(props = {}) {
    Object.assign(this, props);
    this.lastAccess = new Date(this.lastAccess);
  }
}

class UserAccessApi {
  findAll = async () => {
    const { data } = await Axios.get(`/spr/userAccesses`);
    return data.map((d) => new UserAccessUi(d));
  };
}

export default UserAccessApi;
