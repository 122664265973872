const isObjectBlockedMessage = (message = '') => {
    try {
        return message?.includes('ObjectExecutionBlocked');
    } catch (e) {
        console.warn('Error on isObjectBlockedMessage() for message', message, e);
    }
    return false;
};

const PREFIX = 'ObjectExecutionBlockedData:[__';
const SUFFIX = '__]';

const parseMessageJson = (message = '') => {
    try {
        const startIdx = message.indexOf(PREFIX) + PREFIX.length;
        const jsonString = message.substring(
            startIdx,
            message.indexOf(SUFFIX, startIdx)
        );
        const parsedData = JSON.parse(jsonString);
        parsedData.blockedUntil = moment(parsedData.blockedUntil);
        return parsedData;
    } catch (e) {
        console.warn('Error on parseMessageJson()', message, e);
    }
    return null;
}

const showObjectBlockedAlert = {
    isObjectBlockedMessage,
    parseMessageJson,
};

export default showObjectBlockedAlert;
