import React from "react";

import {BngPopperButton, BngPopperWrapper} from "components/bng/ui/BngPopperWrapper";
import Icon from "components/ui/common/Icon";
import BngCockpitPanelItems from "components/bng/pages/cockpit/duplicated/BngCockpitPanelItems";

export default function BngPanelItemsButton({panels, currentPanelId, onPanelChange}) {
    return (
        <BngPopperWrapper
            className="cockpit-panel-popper"
            placement="bottom-start"
            modifiers={{
                preventOverflow: {
                    boundariesElement: document.body,
                }
            }}>

            <BngPopperButton className="cockpit-nav-btn cockpit-button-group">
                <a href="#" onClick={e => e.preventDefault()}>
                    <Icon icon="dialpad"/>
                </a>
            </BngPopperButton>

            <BngCockpitPanelItems panels={panels}
                                  currentPanelId={currentPanelId}
                                  onPanelChange={onPanelChange}
            />

        </BngPopperWrapper>
    );
}