import styles from './NPSCensus.module.css';

import React, { useState } from 'react';
import { Formik } from 'formik';
import AnswerPool from 'components/bng/ui/Census/AnswerPool';
import { bngYup } from 'components/bng/form/yup/BngYup';
import useBimContext from 'components/hooks/useBimContext';
import BngAda, { ADA_TAGS } from 'components/bng/ui/BngAda/BngAda';

export const NPSSchema = bngYup((yup) => {
  return yup.object().shape({
    score: yup.number().default(null).required(),
    message: yup.string().default(''),
  });
});

export default function AdaCensus({
  className = '',
  questionTitle = '',
  onSubmit = _.noop,
  answerLaterButton = _.noop,
  likeMsg = 'census.very.likely',
  dislikeMsg = 'census.not.likely',
}) {
  const context = useBimContext();
  const [score, setScore] = useState(null);

  return (
    <Formik initialValues={NPSSchema.default()} validationSchema={NPSSchema} onSubmit={onSubmit}>
      {({ values, setFieldValue, submitForm }) => {
        let censusProps;
        const scoreSelected = values.score != null;
        if (scoreSelected) {
          censusProps = {
            title: context.msg.t('census.nps.title.why'),
            leftButtonLabel: 'no',
            onClick: async () => await submitForm(),
            leftButtonClick: async () => {
              setFieldValue('message', '');
              await submitForm();
            },
          };
        } else {
          censusProps = {
            title: questionTitle,
            leftButtonLabel: 'census.answer.later',
            onClick: () => setFieldValue('score', score),
            leftButtonClick: answerLaterButton,
          };
        }

        return (
          <BngAda
            className={`AdaCensus ${styles.AdaNPSCensus} ${className}`}
            title={censusProps.title}
            customOptionsComponent={() => {
              return (
                <AnswerPool
                  score={score}
                  setScore={setScore}
                  showMessageField={scoreSelected}
                  onChange={(val) => setFieldValue('message', val)}
                  likeMsg={context.msg.t(likeMsg)}
                  dislikeMsg={context.msg.t(dislikeMsg)}
                />
              );
            }}
            leftButtonLabel={context.msg.t(censusProps.leftButtonLabel)}
            onClickLeftButton={censusProps.leftButtonClick}
            buttonLabel={context.msg.t('send')}
            buttonDisabled={score === null}
            onClick={censusProps.onClick}
            clickOutsiteToConfirm={false}
            tags={[ADA_TAGS.NPS]}
            greyBackground={true}
          />
        );
      }}
    </Formik>
  );
}
