/**
 * Adicione aqui apenas modais que precisam ser referenciadas no JSF, caso contrario utilize um import direto no componente
 */
import { NewFolderDialog } from 'components/bng/folder/NewFolderDialog';
import ProjectMergeDialog from 'components/ui/project/ProjectMergeDialog';
import DashboardItemOrderForm from 'components/ui/dashboard/components/DashboardItemOrderForm';
import ActionsDialog from 'components/bng/monitoring/ActionsDialog';
import InMemoryLoadLimitAlertDialog from 'components/bng/load-limit/inMemory/InMemoryLoadLimitAlertDialog';
import ProjectUpdateAppsDialog from 'components/bng/project/updateApps/ProjectUpdateAppsDialog';

application.modalRefs = {
  DashboardItemOrderForm,
  ProjectMergeDialog,
  ActionsDialog,
  NewFolderDialog,
  InMemoryLoadLimitAlertDialog,
  ProjectUpdateAppsDialog,
};

application.react = {
  openModal(component, props = {}) {
    ComponentFactory.default.runAfterInit(() => {
      window.ReduxStore.dispatch(window.Actions.MODALS.open(component, props));
    });
  },
};
