import React from "react";

const DropdownToggler = ({className= '', children, ...props}) => {
    return (
        <a className={`dropdown-toggle ${className}`} href="#" {...props}>
            {children}
        </a>
    );
}

export default DropdownToggler;