'use strict';

const $ = jQuery;

import { Axios } from 'commonUtils';

export default class SupportApi {
  static checkStatus() {
    return $.getJSON('/spr/support/status');
  }

  static findCategories(projectId) {
    return $.getJSON('/spr/support/categories', { projectId });
  }

  static newTicket(ticket, files = []) {
    let fd = new FormData();
    for (let key in ticket) {
      fd.append(key, ticket[key]);
    }
    files.forEach((file) => fd.append('files', file));
    return $.postFd('/spr/support/tickets', fd);
  }

  static findStatusPageUrl() {
    return $.getJSON('/spr/support/statusPage');
  }

  static async findPhone() {
    const { data } = await Axios.get(`/spr/support/phoneNumber`);
    return data;
  }

  static async newTicketForMarketplace({ title, items, user, project, account, planKey }) {
    const itemsLabel = items.map((item) => `${item.name} (${item.id})`).join(',');
    const newFeatureType = '00e22d60debbcb79e1dc109c379c4a14';
    const priorityNormal = 2;
    const description = `
        - Usuário que solicitou: ${user.displayName} (${user.email})
        - Projeto que solicitou: ${project.displayName} (${project.name})
        - Conta selecionada que fez a solicitação: ${account.name || ''} (id=${account.id})
        - Item solicitado: ${itemsLabel} 
        ${planKey ? `- Plano solicitado: ${planKey}` : ''}
      `;
    const ticket = {
      projectId: project.name,
      type: newFeatureType,
      priority: priorityNormal,
      title: title,
      description: description,
    };

    return await SupportApi.newTicket(ticket);
  }
}
