import './UserMenuButton.css';

import React from 'react';
import { connect } from 'react-redux';

import Avatar from 'components/ui/Avatar';
import DropdownToggler from 'components/ui/navbar/DropdownToggler';
import Button from 'components/ui/Button';
import ContextEnhancer from 'components/ContextEnhancer';
import MenuButton from 'components/ui/navbar/MenuButton';
import { MODALS } from 'components/ui/redux/Actions';
import ProfileDialog from 'components/ui/profile/ProfileDialog';
import MyAccountsDialog from 'components/bng/accounts/MyAccountsDialog';
import Icon from 'components/ui/common/Icon';
import ActivateBimDialog from 'components/ui/navbar/ActivateBimDialog';
import Utils from 'components/Utils';
import AddonActivateDialog from 'components/bng/accounts/addons/AddonActivateDialog';
import Api from 'components/Api';
import LabsReleaseLabel from 'components/ui/labs/trial/LabsReleaseLabel';
import { BetaTag } from 'components/bng/ui/BngTag';

const menuOpts = (context, dispatch, showFeatureWarning) => {
  const showNewAccountsPage = context.canManageAccounts;

  function help() {
    window.open(
      context.support.accountConfig.knowledgeBaseUrl,
      context.msg.t('detail_support'),
      'toolbar=0,status=0,width=800,height=600'
    );
  }

  function openDialog(action, props) {
    dispatch(MODALS.open(action, { ...props }));
    MenuButton.closeAllMenus();
  }

  function openSelectPlanDialog() {
    dispatch(MODALS.open(ActivateBimDialog));
    MenuButton.closeAllMenus();
  }

  const containAddonRequests = context.addonActivationRequests > 0;

  const alertMsg = context.emailOnBlacklist
    ? context.msg.t('email.on.blacklist.warning')
    : containAddonRequests
    ? context.msg.t('addon.request.present.warning')
    : showFeatureWarning
    ? context.msg.t('features.not.seen.warning')
    : null;
  const alertAction = (e) => {
    if (containAddonRequests) {
      e.stopPropagation();
      dispatch(MODALS.open(AddonActivateDialog));
    }
  };

  const shouldShowCurrentPlan = context.planSelectionEnabled && Utils.Project.isDemo(context.project);

  const upperMenu = [
    <li key={0}>
      <div className="user-menu-info-container">
        <div className="row-fluid">
          <div className="span4">
            <div className="info-div user-menu-info-container-picture">
              <div className="menu-avatar-container">
                <Avatar userId={context.user.id} className="menu-avatar" />
              </div>
            </div>
          </div>
          <div className="span8">
            <div className="info-div user-menu-info-container-data">
              <div className="user-menu-info-display-name">{context.user.displayName}</div>
              <div className="user-menu-info-display-mail">{context.user.email}</div>
              {context.isAccessingProject && !!context.project && (
                <div className="user-menu-info-display-project">{context.project.displayName}</div>
              )}
              <div className="terms-or-policies-container">
                <div className={'termsLink'}>
                  <a href={context.msg.t('user.menu.terms.link')} target="_blank" rel="noreferrer">
                    {context.msg.t('user.menu.terms')}
                  </a>
                </div>
                <div className="div-radius-user-menu" />
                <div className={'policiesLink'}>
                  <a href={context.msg.t('user.menu.policies.link')} target="_blank" rel="noreferrer">
                    {context.msg.t('user.menu.policies')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>,

    <li key={1} style={{ display: shouldShowCurrentPlan ? '' : 'none' }}>
      <div className="user-menu-option user-menu-option-help" onClick={() => openSelectPlanDialog()}>
        <div className="user-menu-option-color"></div>
        <div className="user-menu-option-icon">
          <Icon icon="rocket_launch" />
        </div>
        <div className="user-menu-option-label">
          {context.msg.t('detail_current_plan')}
          <span style={{ color: '#e23d3d' }}>TRIAL</span>
          <span className="activateNowRoundBadge">
            {context.msg.t(context.projectActivated ? 'project.activated' : 'activate.now')}
          </span>
        </div>
      </div>
    </li>,

    <li key={2}>
      <div
        className="user-menu-option user-menu-option-perfil"
        onClick={() => openDialog(ProfileDialog, { userId: context.user.id })}
      >
        <div className="user-menu-option-color"></div>
        <div className="user-menu-option-icon">
          <Icon icon="remove_red_eye" />
        </div>
        <div className="user-menu-option-label">{context.msg.t('detail_my_account')}</div>
      </div>
    </li>,
    <React.Fragment key={3}>
      {context.isAccessingProject && (
        <li>
          <div
            className="user-menu-option user-menu-option-labs"
            onClick={() => {
              try {
                Api.Feature.redirectToLabsPage();
              } catch (e) {
                console.error('Could not load labs page...');
              }
            }}
          >
            <div className="user-menu-option-color" />
            <div className="user-menu-option-icon">
              <Icon icon="science" />
            </div>
            <div className="user-menu-option-label">{context.msg.t('bimachine_labs')}</div>

            {showFeatureWarning && (
              <div className="labs-release-label-container">
                <LabsReleaseLabel />
              </div>
            )}
          </div>
        </li>
      )}
    </React.Fragment>,

    <React.Fragment key={5}>
      {context.isAccessingProject && (
        <li>
          <div
            className="user-menu-option user-menu-option-news"
            onClick={() => window.open(`${Api.baseUrl()}/spr/bng/news`, '_blank')}
          >
            <div className="user-menu-option-color"></div>
            <div className="user-menu-option-icon">
              <Icon icon="newspaper" />
            </div>
            <div className="user-menu-option-label">{context.msg.t('news')}</div>
          </div>
        </li>
      )}
    </React.Fragment>,

    <li key={7}>
      <div className="user-menu-option user-menu-option-help" onClick={() => help()}>
        <div className="user-menu-option-color"></div>
        <div className="user-menu-option-icon">
          <Icon icon="live_help" />
        </div>
        <div className="user-menu-option-label">{context.msg.t('detail_support')}</div>
      </div>
    </li>,

    <React.Fragment key={8}>
      {showNewAccountsPage && (
        <li>
          <div
            className="user-menu-option user-menu-option-manage"
            onClick={() => {
              try {
                Api.Account.redirectToAccountsPage();
              } catch (e) {
                console.error('Error loading Accounts Page', e);
              }
            }}
          >
            <div className="user-menu-option-color"></div>
            <div className="user-menu-option-icon">
              <Icon icon="admin_panel_settings" />
            </div>
            <div className="user-menu-option-label">{context.msg.t('manage.account')} </div>
            <div className={'user-menu-option-tag'}>
              <BetaTag />
            </div>
          </div>
        </li>
      )}
    </React.Fragment>,

    <React.Fragment key={6}>
      {context.masterOrOwnerOfAnAccount && (!showNewAccountsPage || context.user.superUser) && (
        <li className="no-hover">
          <div className="menu-bottom-btn">
            <Button className="btn-link btn-small" onClick={() => openDialog(MyAccountsDialog)}>
              {context.msg.t('my.accounts')}
              {containAddonRequests && context.masterOrOwnerOfCurrentAccount && (
                <div className="AlertContainer" title={alertMsg} style={{ color: '#ff7f50' }}>
                  <Icon icon="error" />
                </div>
              )}
            </Button>
          </div>
        </li>
      )}
    </React.Fragment>,
  ];

  return {
    upperMenu,
    toggler: (props) => (
      <DropdownToggler className="UserMenuAvatarContainer" {...props}>
        <div className="user-menu-avatar-outer">
          <Avatar userId={context.user.id} className="user-menu-avatar" />
        </div>
        {alertMsg && (
          <div
            className="AlertContainer"
            title={alertMsg}
            onClick={alertAction}
            style={{ color: context.emailOnBlacklist || containAddonRequests ? '#ff7f50' : '#005dff' }}
          >
            <Icon icon={context.emailOnBlacklist || containAddonRequests ? 'error' : 'new_releases'} />
          </div>
        )}
      </DropdownToggler>
    ),
  };
};

class UserMenuButton extends React.Component {
  state = {
    showFeatureWarning: false,
  };

  componentDidMount() {
    if (!this.props.context.labs.hasSeenEveryFeature) {
      this.setState({
        showFeatureWarning: true,
      });
    }
  }

  render() {
    let userMenu = menuOpts(this.props.context, this.props.dispatch, this.state.showFeatureWarning);
    const projectRole = this.props.context.masterOrOwnerOfCurrentAccount ? 'Master' : this.props.context.userRole;
    return (
      <MenuButton
        className="user-menu-header-fragment"
        headerText={projectRole}
        buttonClass="btn-danger"
        buttonActionEnable={true}
        material={false}
        buttonActionLabel={this.props.context.msg.t('logout')}
        buttonActionClick={Api.User.logout}
        toggler={userMenu.toggler}
        offset={8}
      >
        {userMenu.upperMenu}
      </MenuButton>
    );
  }
}

export default ContextEnhancer(connect()(UserMenuButton));
