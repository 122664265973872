import "./BookCreationDialog.css";
import React from "react";
import PropTypes from "prop-types";
import {Field, Form, Formik} from "formik";

import ContextEnhancer from "components/ContextEnhancer";
import Dialog from "components/ui/Dialog";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import {bngYup} from "components/bng/form/yup/BngYup";
import {DefaultDialogActions} from "components/ui/FormUtils";
import {BngField} from "components/bng/form/BngField";

const BookSchema = bngYup(yup => {
    return yup.object().shape({
        bookDescription: yup.string().default('').required(),
    });
});

class BookCreationDialog extends React.Component {

    static propTypes = {
        cockpitId: PropTypes.number,
        cockpitName: PropTypes.string,
        refreshBooks: PropTypes.func,
    };

    static defaultProps = {
        refreshBooks: () => {
        },
    };

    state = {
        loading: false,
    };

    initialFormValues = BookSchema.default();

    componentDidMount() {
        if (this.props.cockpitName) {
            this.initialFormValues.bookDescription = this.props.cockpitName;
        }
    }

    handleSubmit = async (values, actions) => {
        this.setState({loading: true});
        try {
            const result = await Api.Cockpit.generateBook({cockpitId: this.props.cockpitId, ...values});

            UiMsg.ok(this.props.context.msg.t('book.generation.scheduled.success', values.bookDescription));

            this.props.refreshBooks();
            this.props.closeModal();
        } catch (e) {
            this.setState({loading: false});
            UiMsg.error('error', e);
        } finally {
            actions.setSubmitting(false);
        }
    };

    render() {
        return (
            <Formik initialValues={this.initialFormValues}
                    validationSchema={BookSchema}
                    onSubmit={this.handleSubmit}>
                <Dialog open={this.props.open} className="BookCreationDialog"
                        title={this.props.context.msg.t('book.generation')}
                        onClose={this.props.closeModal} loading={this.state.loading}>
                    <Form>
                        <div className="book-creation-body">
                            <div className="alert alert-warning">
                                <strong>{this.props.context.msg.t('attention')}</strong>
                                <span>{this.props.context.msg.t('book.generation.confirm.message')}</span>
                            </div>
                            <Field label={this.props.context.msg.t('description')}
                                   name="bookDescription"
                                   component={BngField}
                                   required={true}/>
                        </div>
                        <DefaultDialogActions {...this.props}/>
                    </Form>
                </Dialog>
            </Formik>
        )
    }
}

export default ContextEnhancer(BookCreationDialog);