import './ButtonGroup.css';

import React from 'react';
import PropTypes from 'prop-types';

export default class ButtonGroup extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        multiple: PropTypes.bool,
        items: PropTypes.array,
        value: PropTypes.any,
        onChange: PropTypes.func
    };

    static defaultProps = {
        label: null,
        multiple: false,
        items: [],
        value: [],
        onChange: _.noop
    };


    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    isActive(item) {
        if (this.props.multiple) {
            return this.props.value.indexOf(item.value) >= 0;
        } else {
            return this.props.value === item.value;
        }
    }

    onClick(item) {
        if (this.props.disabled) {
            return;
        }

        if (this.props.multiple) {
            let clone = [].concat(this.props.value);
            let index = this.props.value.indexOf(item.value);
            if (index >= 0) {
                clone.splice(index, 1);
            } else {
                clone.push(item.value);
            }
            this.props.onChange(clone);
        } else {
            this.props.onChange(item.value)
        }
    }

    render() {
        let {label, items, disabled, className} = this.props;

        return (
            <div className={`bng-button-group ${className}`}>
                {label && <label className="control-label">{label}</label>}
                {(label === '') && <label className="control-label">&nbsp;</label>}

                <div className="bng-button-group-container">
                    {items.map((item, index) => (
                        <div key={index}
                             onClick={() => this.onClick(item)}
                             className={`bng-button-group-item ${this.isActive(item) ? 'active' : null} ${disabled ? 'disabled' : null}`}>
                            {item.icon && <i className={'material-icons'}>{item.icon}</i>}
                            {item.label && <span>{item.label}</span>}

                        </div>
                    ))}
                </div>
            </div>
        );
    }

}