import './SpecialistDropdown.css';

import React from 'react';

import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import BngPopper from 'components/bng/ui/BngPopper';
import Button from 'components/ui/Button';
import useBimContext from 'components/hooks/useBimContext';

export default function SpecialistDropdown({boundaryElement, anchorEl, destroyView = _.noop}) {
    const context = useBimContext();

    const callSpecialist = () => {
        window.open(context.msg.t('specialist.hire.link'), '_blank');
    }

    return (
        <BngPopper className="SpecialistDropdownPopper"
                   open={true}
                   anchorEl={anchorEl}
                   placement="bottom-start"
                   modifiers={{
                       offset: [0, 20],
                       preventOverflow: {
                           boundariesElement: boundaryElement
                       },
                   }}>
            <BngClickOutsideOverlay container={boundaryElement} onClick={destroyView}/>
            <div className="SpecialistDropdown">
                <div className="SpecialistDropdownContainer">
                    <div className="SpecialistDetails">
                        <div className="SpecialistTitle">{context.msg.t('hire.specialist.your.project')}</div>
                        <span>{context.msg.t('with.specialist.help.you.can')}</span>
                        <ul className="SpecialistOptions">
                            <li>{context.msg.t('specialist.detail.1')}</li>
                            <li>{context.msg.t('specialist.detail.2')}</li>
                            <li>{context.msg.t('specialist.detail.3')}</li>
                        </ul>
                        <Button className="SpecialistHire" onClick={callSpecialist}>
                            {context.msg.t('find.specialist')}
                        </Button>
                        <div className="SpecialistLink">
                            <a href={context.msg.t('specialist.get.hired.link')} target="_blank">{context.msg.t('want.to.be.specialist')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </BngPopper>
    )
}