import React, {Component} from "react";
import {BngPopperButton, BngPopperWrapper} from "components/bng/ui/BngPopperWrapper";
import CockpitPanelItems from "components/ui/cockpit/CockpitPanelItems";
import Icon from "components/ui/common/Icon";
import PropTypes from "prop-types";

export default class PanelItemsButton extends Component {

    static propTypes = {
        items: PropTypes.array,
        currentPanel: PropTypes.any,
    };

    state = {
        loading: false,
    };

    render() {
        return (
            <BngPopperWrapper
                className="cockpit-panel-popper"
                placement="bottom-start"
                container={document.body}
                disabled={this.state.loading}
                modifiers={{
                    preventOverflow: {
                        boundariesElement: document.body,
                    }
                }}>

                <BngPopperButton className="cockpit-nav-btn cockpit-button-group">
                    <a href="#">
                        <Icon icon="dialpad"/>
                    </a>
                </BngPopperButton>

                <CockpitPanelItems {...this.props}/>

            </BngPopperWrapper>
        )
    }
}