import './Api4ComForm.css';

import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from "formik";

import ContextEnhancer from "components/ContextEnhancer";

import WsBar from "components/ui/in-memory/WsBar";
import Api from "components/Api";
import Button from "components/ui/Button";
import {bngYup} from "components/bng/form/yup/BngYup";
import {BngField} from "components/bng/form/BngField";
import UiMsg from "components/ui/UiMsg";
import {DefaultDialogActions} from "components/ui/FormUtils";

const Api4ComSchema = bngYup(yup => {
    return yup.object().shape({
        name: yup.string().required().default(''),
        token: yup.string().required().default(''),
    });
});

const WS_ICON_URL = `${Api.baseUrl()}/images/data/api4com.png`

const Api4ComForm = ({
                         projectId,
                         open,
                         context,
                         closeModal,
                         inMemoryId = -1,
                         edit = false,
                         nameCube = '',
                         onClose = _.noop
                     }) => {
    const [initialFormValues, setInitialFormValues] = useState(Api4ComSchema.default());
    const [isLoadingValidation, setLoadingValidation] = useState(false);
    const [isTokenValid, setTokenValid] = useState(false);

    useEffect(() => {
        const fetchValuesFormIfEdit = async () => {
            if (inMemoryId !== -1) {
                const formValues = await Api.Api4Com.getValuesWS(inMemoryId);
                setInitialFormValues({
                    name: formValues.name,
                    token: formValues.token
                });
            }
        }

        fetchValuesFormIfEdit();
    }, [inMemoryId]);

    const save = async (values, actions) => {
        try {
            values.projectId = projectId;
            const isNameValid = (nameCube === values.name || await Api.Api4Com.validateName(values));
            if (isNameValid) {
                edit ? await Api.Api4Com.editOrigin(inMemoryId, values) : await Api.Api4Com.saveOrigin(values);
                UiMsg.ok(context.msg.t('api4com.origin.saved'));
                Api.updateJsf();
                closeModal();
            } else {
                UiMsg.error(context.msg.t('api4com.cube.name.already.in.use'));
            }
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        } finally {
            actions.setSubmitting(false);
        }
    };

    const validateToken = async ({token}) => {
        setLoadingValidation(true);
        try {
            const isValid = await Api.Api4Com.validateToken(token);
            if (isValid) {
                UiMsg.ok(context.msg.t('api4com.origin.token.isvalid'))
            } else {
                UiMsg.error(context.msg.t('api4com.origin.token.invalid'))
            }
            setTokenValid(isValid);
        } catch (e) {
            UiMsg.error(context.msg.t('api4com.origin.token.invalid'))
            setTokenValid(false);
        }
        setLoadingValidation(false);
    };

    const invalidateToken = () => {
        setTokenValid(false);
    };

    return (
        <Formik initialValues={initialFormValues}
                validationSchema={Api4ComSchema}
                onSubmit={save}
                enableReinitialize>
            {({values, isSubmitting}) => {
                return (
                    <WsBar open={open}
                           title={context.msg.t('api4com.dialog.title')}
                           onClose={() => {
                               closeModal();
                               onClose();
                           }}
                           help={context.msg.t('api4com.dialog.help')}
                           href={context.msg.t('api4com.dialog.help.link')}
                           icon={WS_ICON_URL}
                           className={'ApiForm'}>
                        <Form>
                            <fieldset className="ws-information-container">
                                <div className="ws-information-container-left">
                                    <div>
                                        <Field placeholder={context.msg.t('api4com.name.visualization')}
                                               name="name"
                                               component={BngField}
                                               type="text"
                                        />
                                    </div>
                                    <div>
                                        <div className="informationtitle">
                                            {context.msg.t('api4com.information.title')}
                                        </div>
                                        <div className="information">
                                            <span className="fa fa-address-card"/>
                                            {context.msg.t('api4com.information')}
                                        </div>
                                        <div className="ws-information-details">
                                            {context.msg.t('api4com.information.description')}
                                        </div>
                                    </div>
                                    <div>
                                        <Field placeholder={context.msg.t('api4com.token')}
                                               name="token"
                                               customChange={invalidateToken}
                                               component={BngField}
                                               type="text"
                                        />
                                    </div>
                                </div>
                                <div className="validate-token">
                                    <Button id="pipe-btn-ok"
                                            className="btn btn-success"
                                            style={{display: isTokenValid ? 'initial' : 'none'}}>
                                        <span className="fa fa-check"/>
                                    </Button>
                                    <Button id="btn-validade"
                                            icon="icon-ok"
                                            onClick={() => validateToken(values)}
                                            loading={isLoadingValidation}
                                            className="pull-left">
                                        {context.msg.t('api4com.validate.token')}
                                    </Button>
                                </div>
                                <div className="is-connected">
                                        <span id="circle-validate"
                                              className="fa fa-circle-o"
                                              style={{color: isTokenValid ? 'green' : 'red'}}
                                        />
                                    {context.msg.t(isTokenValid ? 'api4com.connected' : 'api4com.disconnected')}
                                </div>
                            </fieldset>
                            <DefaultDialogActions context={context}
                                                  submitting={isSubmitting}
                                                  closeModal={closeModal}
                                                  disabled={!isTokenValid}
                            />
                        </Form>
                    </WsBar>
                )
            }}
        </Formik>
    )
};

export default ContextEnhancer(Api4ComForm);