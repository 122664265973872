import { Axios } from 'commonUtils';

const endpoint = '/spr/accessTimeRestrictions';

export default class AccessTimeRestrictionApi {
  save = async (values) => {
    return await Axios.postData(endpoint, values);
  };

  findAll = async (projectId = 0) => {
    return await Axios.getData(endpoint, {
      params: {
        projectId,
      },
    });
  };

  delete = async (id) => {
    await Axios.delete(`${endpoint}/${id}`);
  };

  toggleActiveStatus = async (id) => {
    await Axios.post(`${endpoint}/${id}/toggleActive`);
  };

  findAllLogs = async (projectId = 0) => {
    return await Axios.getData(`${endpoint}/logs`, {
      params: {
        projectId,
      },
    });
  };

  checkAccess = async (values) => {
    return await Axios.postData(`${endpoint}/checkAccess`, values);
  };

}
