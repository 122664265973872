import styles from './RemoveUserDialog.module.css';

import Dialog from 'components/ui/Dialog';
import React, { useMemo, useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import { Form, Formik } from 'formik';
import bngYup from 'components/bng/form/yup/BngYup';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngTable from 'components/bng/ui/BngTable';
import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import OpConfirmation from 'components/ui/OpConfirmation';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';

const RemoveUserSchema = bngYup((yup) => {
  return yup.object().shape({
    userId: yup.number().required(),
    projects: yup.array().of(yup.number()),
  });
});

export const buildProjectsTableColumns = ({ context, projects, values, setFieldValue, reduced = false }) => {
  const selectedProjects = values.projects || [];
  const allChecked = selectedProjects.length === projects.length;

  const changeSelectedRow = (row, isSelected) => {
    let updatedSelectedProjects = selectedProjects;
    if (isSelected) {
      updatedSelectedProjects = selectedProjects.filter((selectedRow) => selectedRow !== row.id);
    } else {
      updatedSelectedProjects.push(row.id);
    }
    setFieldValue('projects', [...updatedSelectedProjects]);
  };

  const selectAllRows = () => {
    selectedProjects.clear();
    if (!allChecked) {
      projects.forEach((row) => {
        selectedProjects.push(row.id);
      });
    }
    setFieldValue('projects', [...selectedProjects]);
  };

  return [
    {
      key: 'rowCheckbox',
      label: (
        <div className={styles.checkboxColumn}>
          <BngCheckbox
            field={{
              onChange: selectAllRows,
              value: allChecked,
            }}
            size="lg"
          />
        </div>
      ),
      size: 4,
      render: (row) => {
        const isSelected = selectedProjects.some((selectedRow) => selectedRow === row.id);
        return (
          <div className={styles.checkboxColumn}>
            <BngCheckbox
              field={{
                onChange: () => {
                  changeSelectedRow(row, isSelected);
                },
                value: isSelected,
              }}
              size="lg"
            />
          </div>
        );
      },
    },
    {
      key: 'projectId',
      label: context.msg.t('project.id'),
      hide: reduced,
      render: (row) => {
        return (
          <div>
            <span>{row.id}</span>
          </div>
        );
      },
    },
    {
      key: 'projectName',
      label: context.msg.t('project.name'),
      render: (row) => {
        return (
          <div>
            <span>{Utils.Project.displayName(row)}</span>
          </div>
        );
      },
    },
    {
      key: 'projectType',
      label: context.msg.t('project.type'),
      render: (row) => {
        const projectType = Utils.Project.types[row.projectType.type || row.projectType];
        return (
          <div className={styles.projectTypeWrapper} style={{ color: projectType.color }}>
            <div className={`${styles.projectTypeBadge} ${projectType.badge}`}>{projectType.name.charAt(0)}</div>
            <span>{projectType.name}</span>
          </div>
        );
      },
    },
    {
      key: 'userType',
      label: context.msg.t('user.type'),
      hide: reduced,
      render: (row) => {
        const role = Utils.Project.roles[row.projectUser.role];
        return (
          <div className={`${styles.roleWrapper}`}>
            <Icon icon={role.icon} />
            <span>{context.msg.t(role.name)}</span>
          </div>
        );
      },
    },
  ];
};

const RemoveUserDialog = ({ closeModal = _.noop, user, projects, refreshData }) => {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);

  const removeUser = async (values) => {
    setLoading(true);
    try {
      await Api.Project.removeAccesses(values.projects, [values.userId]);
      UiMsg.ok(context.msg.t('remove_success', context.msg.t('user')));
      await refreshData();
    } catch (e) {
      console.error('Error on function removeUser()', e);
      UiMsg.error(context.msg.t('remove_error', context.msg.t('user')), e);
    } finally {
      setLoading(false);
    }
  };

  const removeUserConfirmation = async (values) => {
    if (!values.projects || values.projects.length < 1) {
      UiMsg.warn(context.msg.t('select.one.project'));
    } else {
      OpConfirmation({
        title: user.displayName || user.email,
        message: context.msg.t('confirm.delete.user'),
        onConfirm: async () => {
          await removeUser(values);
          closeModal();
        },
        msg: context.msg,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        ...RemoveUserSchema.default(),
        userId: user.id,
      }}
      validationSchema={RemoveUserSchema}
      onSubmit={removeUserConfirmation}
    >
      {({ values, setFieldValue }) => {
        const cols = useMemo(
          () =>
            buildProjectsTableColumns({
              context,
              projects,
              values,
              setFieldValue,
            }),
          [projects, values, setFieldValue]
        );

        return (
          <Dialog
            onClose={closeModal}
            contentFullWidth={true}
            loading={loading}
            title={context.msg.t('remove.dynamic', [user.displayName || user.email])}
            className={`RemoveUserDialog large ${styles.RemoveUserDialog}`}
            titleComponent={() => (
              <div className={styles.headerWrapper}>
                <h5>{`${context.msg.t('remove.dynamic', [user.displayName || user.email])}`}</h5>
                <div className={styles.removeMsg}>
                  <span>{`${context.msg.t('admins.can.still.add')}`}</span>
                </div>
              </div>
            )}
          >
            <Form>
              <Dialog.Body>
                <BngTable cols={cols} rows={projects} stickyHeader={true} />
              </Dialog.Body>
              <DefaultDialogActions
                className={styles.dialogFooter}
                okLabel={context.msg.t('delete_button')}
                buttonClass="bng-button"
                saveButtonClassname={styles.removeButton}
                closeModal={closeModal}
              />
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default RemoveUserDialog;
