import './StructureDetailsDialog.css';
import React, { useEffect, useState } from 'react';
import StructureInfo from 'components/bng/pages/admin/structures/StructureInfo';
import Icon from 'components/ui/common/Icon';
import CoolPabloProgressBar from 'components/bng/pages/admin/structures/CoolPabloProgressBar';
import Api from 'components/Api';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import useBimContext from 'components/hooks/useBimContext';
import DetailsDialog from 'components/ui/common/DetailsDialog';

const TypeIcon = {
  success: 'fiber_manual_record',
  warning: 'stop',
  danger: 'play_arrow',
};

const DimensionType = ['TimeDimension', 'Regular'];

const StructureDetailsDialog = ({ structure, closeModal }) => {
  const { msg } = useBimContext();
  const [labsData, setLabsData] = useState();

  useEffect(() => {
    (async () => {
      const data = await Api.Origin.findLabsData(structure.id);
      setLabsData(data);
    })();
  }, []);

  const { metrics, optimizationPercent } = calculateMetrics({ structure, labsData, msg });

  return (
    <DetailsDialog
      className={'StructureDetailsDialog'}
      createdBy={structure.createdBy}
      createdAt={structure.createdAt}
      updatedBy={structure.updatedBy}
      updatedAt={structure.updatedAt}
      closeModal={closeModal}
      titleComponent={() => {
        return <StructureInfo row={structure} showBadge={false} />;
      }}
      footerComponent={() => {
        return (
          <small>
            {msg.t('StructureDetailsDialog.bottom.message')}{' '}
            <a href={msg.t('StructureDetailsDialog.bottom.link')} target="_blank">
              {msg.t('know.more')}.
            </a>
          </small>
        );
      }}
    >
      <UiBlocker block={_.isEmpty(labsData)}>
        <div>
          <h2 className={'flex-center-items'}>
            {msg.t('data.optimization')} <div className={'ml-auto'}>{`${optimizationPercent}%`}</div>
          </h2>
          <CoolPabloProgressBar progress={optimizationPercent} />
        </div>

        <div className={'mt-2'}>
          <h3>{msg.t('laboratory.data')}</h3>

          <div className={'MetricsPanel d-flex flex-wrap'}>
            {metrics.map((metric, idx) => {
              return (
                <div className={`Metric flex-center-items metric-${metric.type}`} key={idx}>
                  <Icon icon={TypeIcon[metric.type]} />
                  <div className={'ml-2'}>{msg.t(metric.name)}</div>
                  <div className={'metric-value ml-auto'}>
                    {metric.translateValue ? msg.t(`${metric.value}`) : metric.value}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </UiBlocker>
    </DetailsDialog>
  );
};

const calculateMetrics = ({ structure, labsData, msg }) => {
  const selectedFields = labsData?.origin?.fields?.filter((f) => f.selected) || [];

  const dimensionsValue =
    selectedFields.reduce((acc, field) => acc + (DimensionType.includes(field.dimensionType) ? 1 : 0), 0) +
      labsData?.origin?.customDimensions.length || 0;

  const measuresValue = selectedFields.reduce((acc, field) => acc + (field.dimensionType === 'Measure' ? 1 : 0), 0);

  const calculatedMeasuresValue = labsData?.origin?.calculatedMeasures.length;
  const enabledSchedulings = labsData?.schedulings?.filter((s) => s.enabled) || [];

  const totalScheds = enabledSchedulings.filter((s) => s.loadType === 'Total');
  const totalSchedsCount = totalScheds.some((s) => s.scheduling.type === 'Interval')
    ? 2
    : totalScheds.reduce((acc, s) => acc + (s.scheduling.type === 'Daily'), 0);

  const totalSchedsStatus = totalSchedsCount > 1 ? 'danger' : totalSchedsCount === 1 ? 'warning' : 'success';

  const incrementalScheds = enabledSchedulings.filter((s) => s.loadType === 'Incremental');

  const incrementalSchedsCount = incrementalScheds.reduce((acc, s) => {
    let add = 0;
    if (s.scheduling.type === 'Daily') {
      add = 1;
    } else if (s.scheduling.type === 'Interval') {
      const prefix = '1990-01-01 ';
      const starts = moment(prefix + s.scheduling.starts);
      const ends = moment(prefix + s.scheduling.ends);
      add = Math.ceil(ends.diff(starts, 'm') / s.scheduling.interval);
    }
    return acc + add;
  }, 0);

  const rowCount = labsData?.origin?.props?.rowCount || 0;

  const incrementalLines = labsData?.additionalInfo?.lastIncrementalLoadLines || 0;
  const totalLines = labsData?.additionalInfo?.lastTotalLoadLines || 0;

  const incrementalLoadPercent =
    incrementalLines === 0 || totalLines === 0 ? 0 : Math.round((incrementalLines / totalLines) * 100);

  const isDataOnlyStructure = structure.type !== 'ANALYTICAL';
  const yearsOnRows = isDataOnlyStructure ? 0 : labsData?.origin?.props?.yearsOnRows ?? 0;

  const metrics = [
    {
      name: 'lines',
      value: rowCount.toLocaleString(window.__USER_LANG),
      type: rowCount > 1000000 ? 'danger' : rowCount > 500000 ? 'warning' : 'success',
    },
    {
      name: 'frequency.total.loads',
      value: totalSchedsStatus === 'danger' ? '> 1 / Dia' : totalSchedsStatus === 'warning' ? '1 / Dia' : '< 1 / Dia',
      type: totalSchedsStatus,
    },
    {
      name: 'dimensions',
      value: dimensionsValue,
      type: dimensionsValue > 20 ? 'danger' : dimensionsValue > 12 ? 'warning' : 'success',
    },
    {
      name: 'frequency.incremental.loads',
      value: incrementalSchedsCount,
      type: incrementalSchedsCount > 15 ? 'danger' : incrementalSchedsCount > 10 ? 'warning' : 'success',
    },
    {
      name: 'pop.physical.measures',
      value: measuresValue,
      type: measuresValue > 15 ? 'danger' : measuresValue > 10 ? 'warning' : 'success',
    },
    {
      name: 'incremental.load.lines.percentual',
      value: `${incrementalLoadPercent}%`,
      type: incrementalLoadPercent > 30 ? 'danger' : incrementalLoadPercent > 10 ? 'warning' : 'success',
    },
    {
      name: 'in.memory.calculated.measures',
      value: calculatedMeasuresValue,
      type: calculatedMeasuresValue > 15 ? 'danger' : calculatedMeasuresValue > 10 ? 'warning' : 'success',
    },
    {
      name: 'data.history',
      value: isDataOnlyStructure ? '-' : yearsOnRows + ' ' + msg.t('years'),
      type: isDataOnlyStructure || yearsOnRows <= 5 ? 'success' : yearsOnRows <= 7 ? 'warning' : 'danger',
    },
  ];

  const typeCount = metrics.reduce(
    (acc, el) => {
      acc[el.type]++;
      return acc;
    },
    { success: 0, warning: 0, danger: 0 }
  );

  // Ref: https://docs.google.com/spreadsheets/d/1Aj-JvWrTsxhBptCucQbPTwnz4oOdHYMAwoBVjwlaglg/edit#gid=0
  const f1 = typeCount.success > 0 ? typeCount.success * (100 / metrics.length) : 0;
  const f2 = typeCount.warning > 0 ? typeCount.warning * ((100 / metrics.length) * 0.5) : 0;

  return { metrics, optimizationPercent: Math.round(f1 + f2) };
};
export default StructureDetailsDialog;
