import "./BngMobileDrawerHelp.css"

import React, { useState } from "react";

import BngAda from "components/bng/ui/BngAda/BngAda";
import ContextEnhancer from "components/ContextEnhancer";
import BngMobileDrawerMultiStepInfo from "./BngMobileDrawerMultiStepInfo"

const BngMobileDrawerHelp = ({ context, showIntro = false, onFinishIntro = _.noop }) => {
    const projectId = context.findProjectIdFromContext();
    const [introProps, setIntroProps] = useState({ show: showIntro, props: { projectId, hasWatched: !showIntro } });

    return (
        <>
            <BngAda
                className={'BngMobileDrawerHelp IntroOpen'}
                buttonPressed={true}
                showInfo={false}
                disableBackground={true}
            />
            <BngMobileDrawerMultiStepInfo {...introProps.props}
                onFinish={() => {
                    setIntroProps({ ...introProps, show: false });
                    onFinishIntro();
                    handleClick();
                }}
            />
        </>
    );
};


export default ContextEnhancer(BngMobileDrawerHelp);