import './SelectOriginDialog.css';

import Dialog from "../Dialog";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Field, renderSelectItemOpts, wrapForm} from "components/ui/FormUtils";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Button from "../Button";
import BlockUi from 'react-block-ui';
import ModalInformation from "components/ui/ModalInformation";

export const Types = {
    NewMap: 'NewMap'
};

class SelectOriginDialog extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,

        projectId: PropTypes.number,
        type: PropTypes.oneOf(Object.values(Types)).isRequired,

        onSubmit: PropTypes.func
    };

    static defaultProps = {
        type: Types.NewMap,
        projectId: 0,
        onSubmit: () => Promise.resolve(true)
    };

    state = {
        xmlaSources: [],
        xmlaCubes: [],
        loading: true,
        validationResult: null
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.updateOrigins();
    }


    updateOrigins = () => {
        this.inLoading(async () => {
            try {
                let projectId = this.getProjectId();
                let xmlaSources = await Api.Project.findSources({projectId});
                if (this.isNewMap()) {
                    xmlaSources = xmlaSources.filter(src => {
                        src.selectItems = src.selectItems.filter(childSrc => childSrc.containGeoreferences);
                        return src.selectItems.length > 0;
                    });
                }
                this.setState({xmlaSources});
            } catch (e) {
                console.error(e);
            }
        });
    };

    isNewMap() {
        return this.props.type === Types.NewMap;
    }

    getProjectId() {
        return this.props.projectId || this.props.context.project.id;
    }

    save = async (data) => {
        try {
            await this.props.onSubmit(data);
            this.props.closeModal();
        } catch (e) {
            console.error(e);
        }
    };

    inLoading = async (fn) => {
        try {
            this.setState({loading: true});
            await fn();
        } finally {
            this.setState({loading: false});
        }
    };

    sourceChanged = async (e, sourceName) => {
        let success = true;

        await this.inLoading(async () => {
            this.props.change('cube', '');
            let newVal = '';
            let xmlaCubes = [];
            if (sourceName.length > 0) {
                try {
                    let projectId = this.getProjectId();
                    xmlaCubes = await Api.Project.sourceCubes({projectId, sourceName});
                    if (xmlaCubes.length) {
                        newVal = xmlaCubes[0].value;
                    }
                } catch (e) {
                    success = false;
                    console.error(e);
                    this.setState({
                        xmlaCubes,
                        validationResult: {
                            needAction: false,
                            error: _.get(e, 'responseJSON.message', e.message)
                        }
                    });
                    return;
                }
            }
            this.setState({xmlaCubes});
            this.props.change('cube', newVal);
        });

        if (success) {
            this.checkForPrerequisites(null, sourceName);
        }
    };

    checkForPrerequisites = (event, sourceName = this.props.formValues.source) => {
        this.inLoading(async () => {
            let projectId = this.getProjectId();

            let validationResult = null;

            if (this.isNewMap() && sourceName) {
                try {
                    const result = await Api.Project.geoFieldCheck({projectId, sourceName});
                    validationResult = {
                        needAction: result[0].needMigration,
                        error: result[0].error
                    }
                } catch (e) {
                    validationResult = {
                        needAction: false,
                        error: e.message
                    }
                }
            }

            this.setState({validationResult});
        })
    };

    regenerateCubeXml = () => {
        this.inLoading(async () => {
            const projectId = this.getProjectId();
            const sourceName = this.props.formValues.source;
            try {
                const response = await Api.Project.regenerateXml({projectId, sourceName});
                await new Promise(resolve => setTimeout(() => {
                    this.setState({validationResult: null});
                    resolve(true);
                }, 2000));
            } catch (e) {
                ModalInformation.ShowMessage({text: text, icon: "error"});
            }
        });

    };

    showErrorMessage = () => {
        let text = '<pre style="max-height: 80vh; overflow: auto;">' + this.state.validationResult.error + '</pre>';
        ModalInformation.ShowMessage({text: text});
    };

    render() {
        if (!this.props.initialized) return null;

        const {source, cube} = this.props.formValues;
        const {xmlaSources, xmlaCubes, loading, validationResult} = this.state;

        const containValidationError = _.get(validationResult, 'error.length', 0) > 0;
        const needAction = !containValidationError && _.get(validationResult, 'needAction', false);

        return (
            <Dialog open={this.props.open}
                    title={this.props.context.msg.t('select.origin.dialog.title')}
                    onClose={this.props.closeModal}>
                <BlockUi tag="div" blocking={loading}>
                    <form onSubmit={this.props.handleSubmit(this.save)}
                          className="form-horizontal select-origin-form">

                        <div className="row-fluid field">
                            <div className="span12">
                                <Field name="source"
                                       label={`${this.props.context.msg.t('header_menu_in_memory')}:`}
                                       labelClassName="bold"
                                       componentClass="select"
                                       onChange={this.sourceChanged}>
                                    <option value={''}>{this.props.context.msg.t('select.one')}</option>
                                    {renderSelectItemOpts(xmlaSources, true)}
                                </Field>
                                <Button className="btn-link"
                                        icon="icon-refresh"
                                        title={this.props.context.msg.t('refresh')}
                                        onClick={this.updateOrigins}
                                />
                            </div>
                        </div>

                        <div className="row-fluid field">
                            <div className="span12">
                                <Field name="cube"
                                       label={`${this.props.context.msg.t('cube')}:`}
                                       labelClassName="bold"
                                       onChange={this.checkForPrerequisites}
                                       componentClass="select">
                                    <option value={''}>{this.props.context.msg.t('select.one')}</option>
                                    {renderSelectItemOpts(xmlaCubes)}
                                </Field>
                                {containValidationError &&
                                <i className="fa fa-exclamation-circle error-alert" onClick={this.showErrorMessage}></i>
                                }
                                {(needAction && source && cube) &&
                                this.renderAction()
                                }

                            </div>
                        </div>

                        <div className="row-fluid">
                            <div className="span12 text-center select-btn-container btn-fix">
                                <Button type="submit" className="btn-block"
                                        disabled={!source || !cube || containValidationError || needAction}
                                        loading={this.props.submitting}>
                                    {this.props.context.msg.t('select')}
                                </Button>
                            </div>
                        </div>

                    </form>
                </BlockUi>
            </Dialog>
        );
    }

    renderAction() {
        if (!this.isNewMap()) return null;

        let canExecute = this.props.context.canManageCurrentProject;

        return (
            <div className="action-button">
                <Button onClick={this.regenerateCubeXml} title={this.props.context.msg.t('update.origin.title')}
                        className="btn-warning"
                        disabled={!canExecute}>
                    {this.props.context.msg.t('update.origin')}
                </Button>
            </div>
        )
    }
}

export default wrapForm({
    component: ContextEnhancer(SelectOriginDialog),
    initialValues: {
        source: '',
        cube: ''
    },
    name: 'selectOriginDialog',
    mapToState: state => ({params: state.modals.current.params})
});
