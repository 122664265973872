import "./InputColor.css";

import React, {Component} from "react";
import PropTypes from "prop-types";

class InputColor extends Component {

    static propTypes = {
        format: PropTypes.oneOf(['hex']),
        value: PropTypes.string,
        align: PropTypes.string,
        disabled: PropTypes.bool,
        showInput: PropTypes.bool
    };

    static defaultProps = {
        format: 'hex',
        value: '#CCC',
        align: 'right',
        disabled: false,
        showInput: true,
    };

    state = {};

    constructor(props) {
        super(props);
        this.initialized = false;
    }

    componentWillUnmount() {
        if(this.initialized) {
            const $el = jQuery(this.input);
            $el.colorpicker('destroy');
            this.initialized = false;
        }
    }


    initialize = () => {
        if(this.props.disabled) {
            this.componentWillUnmount();
            return;
        }
        if(this.initialized) {
            return;
        }
        const $el = jQuery(this.input);
        const $col = $el.find('.color');
        const $hex = $el.parent().find('.colorhextxt');
        const changeColorFn = _.debounce((newColor) => this.props.onChange(newColor), 100);
        $el.colorpicker({format: this.props.format, color: this.props.value, align: this.props.align})
            .on('changeColor', (e) => {
                const newColor = e.color.toString(this.props.format);
                $col.css('background-color', newColor);
                $hex.val(newColor);
                changeColorFn(newColor);
            });
        $col.css('background-color', this.props.value);
        $hex.val(this.props.value);
        $el.colorpicker('show');
        this.initialized = true;
    };

    change = () => {
        const $el = jQuery(this.input);
        const $col = $el.find('.color');
        const $hex = $el.parent().find('.colorhextxt');
        this.props.onChange($hex.val());
        $col.css('background-color', $hex.val());
    };

    render() {
        return (
            <div className={`InputColor color-container ${this.props.className || ''}`}>
                <div className="color"
                     disabled={this.props.disabled}
                     style={{backgroundColor: this.props.value}}
                     ref={input => this.input = input}
                     onClick={this.initialize}></div>
                {this.props.showInput &&
                <input type="text"
                       className="colorhextxt"
                       onChange={this.change}
                       onBlur={this.change}
                       value={this.props.value}
                       disabled={this.props.disabled}
                       maxLength={7}
                />
                }
            </div>
        );
    }
}

export default InputColor;
