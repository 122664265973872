import styles from './SecurityCheckupDone.module.css';
import SuccessLottie from './assets/success_lottie.mp4';

import React from 'react';
import Icon from 'components/ui/common/Icon';
import CheckupHeader from 'components/bng/securityCheckup/components/CheckupHeader';
import useTranslation from 'components/hooks/useTranslation';

function CompletedStepCardStrip({ message }) {
  return (
    <div className={`CompletedStepCardStrip ${styles.cardStrip}`}>
      <div className={`${styles.contentContainer}`}>
        <Icon icon={'check_circle'} />
        <span>{message}</span>
      </div>
    </div>
  );
}

export default function SecurityCheckupDone({
  completedSteps,
  description,
  animation = null,
  showCompletedSteps = true,
  title,
}) {
  const { t } = useTranslation();

  return (
    <div className="SecurityCheckupDone">
      <div className={`${styles.successAnimationContainer}`}>
        <video src={animation ?? SuccessLottie} className={`${styles.successAnimation}`} autoPlay />
      </div>
      <CheckupHeader title={title ?? t('checkup.done.title')} description={description ?? t('checkup.done.desc')} />
      {showCompletedSteps && (
        <div className={`${styles.stepsContainer}`}>
          {completedSteps.map((message, idx) => (
            <CompletedStepCardStrip key={`Completed_${idx}`} message={t(message)} />
          ))}
        </div>
      )}
    </div>
  );
}
