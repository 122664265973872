import 'components/bng/form/BngRestrictedColor.css'
import React from 'react';
import BngInputColor from "components/bng/form/BngInputColor";

const colors = ['#575BD8', '#2C6AA0', '#E89702', '#393939', '#CE171F', '#4DB748', '#AAAAAA'].map(value => ({value}));

const defaultOptions = [];

export default class BngRestrictedColor extends React.Component {

    render() {
        return (
            <BngInputColor {...this.props}
                           defaultOptions={defaultOptions}
                           fetchProjectColors={false}
                           addTransparentOptions={false}
                           enablePicker={false}
                           options={colors}
            />
        );
    }

};

