import { useState } from 'react';

const usePagination = () => {
  const [pagination, setPagination] = useState({ page: 0, size: 10 });
  const [pageInfo, setPageInfo] = useState({
    content: [],
    size: 10,
    totalElements: 0,
    totalPages: 0,
  });

  const perPageChanged = async (perPage) => {
    perPage = _.parseInt(perPage);
    if (perPage === pagination.size) return;

    setPagination({
      size: perPage,
      page: 0,
    });
  };

  const handlePagination = async (page) => {
    const parsedPage = _.parseInt(page) - 1;
    if (parsedPage === pagination.page) return;

    setPagination({
      ...pagination,
      page: parsedPage,
    });
  };

  return {
    pagination,
    pageInfo,
    setPageInfo,
    perPageChanged,
    handlePagination,
  };
};

export default usePagination;
