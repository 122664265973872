import styles from './PermissionsStep.module.css';
import stepsCommonsStyles from './StepsCommonStyles.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import BngTable from 'components/bng/ui/BngTable';
import BngSelect from 'components/bng/form/BngSelect';
import BngAvatar from 'components/bng/ui/BngAvatar';
import { BngError } from 'components/bng/ui/BngError';
import { ContentContainer } from 'components/bng/ui/ContentContainer';
import { ScrollContainer } from 'components/ui/ScrollContainer';
import Api from 'components/Api';
import BngEmpty from 'components/bng/ui/BngEmpty';
import BngSearch from 'components/bng/ui/BngSearch';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';

const buildMemberKey = (member) => {
  return `${member.id}-${member.type}`;
};

const isSupport = (row) => row.email === 'suporte@sol7.com.br';

export default function PermissionsStep({ projectId, accountOpts = [] }) {
  const { msg, user: currentUser} = useBimContext();
  const { setFieldValue, values } = useFormikContext();

  const [search, setSearch] = useState('');
  const [availableMembers, setAvailableMembers] = useState([]);

  const userRoles = useMemo(() => Utils.Project.getRoles().map((r) => ({ value: r.name, label: r.name })), []);

  useEffect(() => {
    findProjectMembers();
  }, []);

  const selectedAccount = accountOpts.find((opt) => opt.value === parseInt(values.accountId))?.account;

  const isMaster = (row) => {
    return row.id === selectedAccount?.accountMasterId;
  };

  const findUser = (row) => {
    return values.users.find((u) => u.userId === row.id);
  };

  const canBeEdited = (row) => {
    return !(isSupport(row) || isMaster(row));
  };

  const filteredMembers = useMemo(() => {
    let members = availableMembers;
    if (search) {
      members = members.filter((item) => item.name.toUpperCase().includes(search.toUpperCase()));
    }
    const masters = members.filter((member) => isMaster(member));
    const nonMasters = members.filter((member) => !isMaster(member));

    return [...masters, ...nonMasters];
  }, [search, availableMembers]);

  const findProjectMembers = async () => {
    const currentUserId = currentUser.id;
    let members = await Api.Project.findProjectUsersAndGroups(projectId);
    members = [
      ...members.groups.map((g) => ({ ...g, type: 'GROUP' })),
      ...members.projectUsers.map((pu) => ({
        id: pu.user.id,
        name: Utils.Users.displayName(pu.user),
        email: pu.user.email,
        type: 'USER',
      })),
    ];
    members = _.orderBy(members, ['name'], ['asc']);

    // Adiciona automaticamente o master do projeto e o suporte caso esteja ativado
    {
      const support = members.find((u) => isSupport(u));
      const users = values.users?.slice() ?? [];
      const currentUserExists = users.some((u) => u.userId === currentUserId);
      if (support && !users.some((u) => u.type === 'USER' && u.userId === support.id)) {
        users.push({
          userId: support.id,
          role: 'Administrator',
          type: 'USER',
        });
      }

      if (!currentUserExists) {
        users.push({
          userId: currentUserId,
          role: 'Administrator',
          type: 'USER',
        });
      }

      if (!users.some((u) => u.type === 'USER' && u.userId === selectedAccount?.accountMasterId)) {
        users.push({
          userId: selectedAccount.accountMasterId,
          role: 'Administrator',
          type: 'USER',
        });

        // Inclui o master da conta selecionada na lista de membros com permissao no projeto
        if (!members.some((m) => m.id === selectedAccount.accountMasterId)) {
          members.push({
            name: selectedAccount.name,
            id: selectedAccount.accountMasterId,
            type: 'USER',
          });
        }
      }

      if (values.users?.length !== users.length) {
        setFieldValue('users', users);
      }
    }

    setAvailableMembers(members);
  };

  const colDefs = useMemo(() => {
    return [
      {
        label: `${msg.t('user')}/${msg.t('group')}`,
        render: (row) => {
          const selectedUser = findUser(row);
          return (
            <div className="flex-center-items">
              {canBeEdited(row) && (
                <BngCheckbox
                  field={{
                    onChange: () => {
                      const users = values.users.slice();
                      if (selectedUser) {
                        const idx = users.findIndex((su) => su.userId === row.id);
                        users.splice(idx, 1);
                      } else {
                        users.push({ userId: row.id, role: 'Viewer', type: row.type });
                      }
                      setFieldValue('users', users);
                    },
                    value: !!selectedUser,
                  }}
                />
              )}
              <BngAvatar userId={row.id} isGroup={row.type === 'GROUP'} className="d-r-m" />{' '}
              <span className="d-l-m">
                {row.type === 'GROUP' && <strong className="d-r-m">{msg.t('group')}</strong>}
                {row.name}
              </span>
            </div>
          );
        },
        size: '70%',
      },
      {
        label: msg.t('role'),
        render: (row) => {
          if (row.type !== 'GROUP') {
            const selectedUser = findUser(row);
            let value = selectedUser?.role || 'Viewer';
            return (
              <div className="flex-center-items">
                {isMaster(row) ? (
                  'Master'
                ) : canBeEdited(row) && selectedUser ? (
                  <Field
                    name="role"
                    component={BngSelect}
                    options={userRoles}
                    className="m-none d-l-m"
                    emptyOption={false}
                    onChange={(event) => {
                      const role = event.target.value;
                      const users = values.users.slice();
                      const match = users.find((u) => u.userId === selectedUser.userId);
                      if (match) {
                        match.role = role;
                        setFieldValue('users', users);
                      }
                    }}
                    value={value}
                  />
                ) : null}
              </div>
            );
          }
        },
        size: '30%',
      },
    ];
  }, [values]);

  return (
    <ContentContainer className={`PermissionsStep ${stepsCommonsStyles.ContentContainerStyle}`}>
      <BngError hasError={false}>
        <BngEmpty showMessage={false} title={msg.t('permission.dialog.public.message')}>
          <div className={`Filter flex-center-items ${styles.FilterWrapper}`}>
            <BngSearch alwaysOpen={false} className="PermissionsStepSearch" onChange={(val) => setSearch(val)} />
          </div>
          <div>
            <ScrollContainer className="TableContainer">
              <BngTable
                className={'table-striped'}
                rowKeyBuilder={(row) => buildMemberKey(row)}
                rows={filteredMembers}
                cols={colDefs}
              />
            </ScrollContainer>
          </div>
        </BngEmpty>
      </BngError>
    </ContentContainer>
  );
}
