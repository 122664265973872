import { useRef } from 'react';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import { BngCodeMirror } from 'components/bng/form/BngCodeMirror';
import BngField from 'components/bng/form/BngField';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import useBimContext from 'components/hooks/useBimContext';
import BngButton, { Type, Variant } from 'components/bng/ui/BngButton';
import BngForm from 'components/bng/form/BngForm';
import bngYup from 'components/bng/form/yup/BngYup';

const FormSchema = bngYup((yup) => {
  return yup.object({
    mdx: yup.string().required().default(''),
    customMdx: yup.boolean().default(false),
  });
});

export default function KpiMdxPreview({ closeModal, path, values, onChange }) {
  const { msg } = useBimContext();

  const $formik = useRef();

  const $mdxPreview = useFetchData(async () => {
    const { mdx } = await Api.Kpi.mdxPreview(path, values);
    if (!values.customMdx) {
      $formik.current.setFieldValue('mdx', mdx);
    }
    return mdx;
  });

  return (
    <Formik
      initialValues={{ mdx: values.customMdx ?? '', customMdx: !!values.customMdx }}
      validationSchema={FormSchema}
      onSubmit={(values) => {
        onChange(values.customMdx ? values.mdx : '');
        closeModal();
      }}
      innerRef={$formik}
    >
      {({ values, setValues }) => {
        return (
          <Dialog
            className="KpiMdxPreview large"
            title={msg.t('mdx_preview')}
            onClose={closeModal}
            loading={$mdxPreview.isLoading}
          >
            <BngForm>
              <Dialog.Body>
                <Field
                  name="mdx"
                  component={BngField}
                  inputComponent={BngCodeMirror}
                  disabled={!values.customMdx}
                  withLabel={false}
                  mode="text/x-sql"
                />
                <Field
                  name="customMdx"
                  component={BngField}
                  inputComponent={BngCheckbox}
                  asProps={{ label: msg.t('enable.custom.mdx.kpi') }}
                  withLabel={false}
                  readOnly={!values.customMdx}
                />
              </Dialog.Body>
              <Dialog.Footer className="flex-center-items">
                <div className="flex-center-items ml-auto gap-3">
                  <BngButton
                    type={Type.danger}
                    variant={Variant.textButton}
                    onClick={() => {
                      setValues({
                        mdx: $mdxPreview.data,
                        customMdx: false,
                      });
                    }}
                  >
                    {msg.t('restore.default.mdx.kpi')}
                  </BngButton>

                  <BngButton btnType="submit">{msg.t('apply')}</BngButton>
                </div>
              </Dialog.Footer>
            </BngForm>
          </Dialog>
        );
      }}
    </Formik>
  );
}
