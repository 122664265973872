import { create } from 'zustand';
import Api from 'components/Api';

const useBimQueryPageCtx = create((set, get) => {
  return {
    connections: [],
    async fetchConnections(projectId) {
      const connections = await Api.Connection.findAll({ projectId });
      set(() => {
        return { connections };
      });
      return connections;
    },
  };
});

export default useBimQueryPageCtx;
