import { Axios } from 'commonUtils';
import { ceData } from 'components/CeData';

class NavbarApi {
  static navbarDetails() {
    return Axios.getData('/spr/ui/navbar', {
      params: {
        userId: ceData.context.user.id,
      },
    });
  }

  static publicationsMenuDetails() {
    return Axios.getData('/spr/ui/navbar/publications', {
      params: {
        userId: ceData.context.user.id,
      },
    });
  }

  static projectsMenuDetails() {
    return Axios.getData('/spr/ui/navbar/projects', {
      params: {
        userId: ceData.context.user.id,
      },
    });
  }

  static projectsNameByUser() {
    return Axios.getData('/spr/ui/nameprojects', {
      params: {
        userId: ceData.context.user.id,
      },
    });
  }

  static schedulingsMenuDetails() {
    return Axios.getData('/spr/ui/navbar/schedulings', {
      params: {
        userId: ceData.context.user.id,
      },
    });
  }

  static monitoringMenuDetails() {
    return Axios.getData('/spr/ui/navbar/central-monitoring', {
      params: {
        userId: ceData.context.user.id,
      },
    });
  }

  static presentations() {
    return Axios.getData('/spr/ui/navbar/presentations', {
      params: {
        userId: ceData.context.user.id,
      },
    });
  }

  static notificationsMenuDetails(params = {}) {
    return Axios.getData('/spr/ui/navbar/notifications', {
      params: {
        ...params,
        userId: ceData.context.user.id,
      },
    });
  }

  static projectStats(projectId, invalidateCache = false) {
    return Axios.getData('/spr/ui/projectstats', {
      params: {
        userId: ceData.context.user.id,
        projectId,
        invalidateCache,
      },
    });
  }

  static getCurrentProject() {
    return Axios.getData('/spr/ui/navbar/current-project', {
      params: {
        userId: ceData.context.user.id,
      },
    });
  }
}

export default NavbarApi;
