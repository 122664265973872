import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class ProgressBar extends BaseKpi {

    constructor(props) {
        super(props);
    }

    customResizeKpi = ({width, height}) => {
        this.props.div.style.margin = '8px';

        resizeEl(this.$kpiTitleEl, {
            fontSize: {minValue: 13, multiplier: 0.1, refSize: height},
            lineHeight: {minValue: 15, multiplier: 0.18, refSize: height},
        });

        resizeEl(this.$kpiProgressBarEl, {
            width: {value: `${width * 0.8}px`},
            marginBottom: {value: '4px'},
        });

        resizeEl(this.$kpiDescriptionEl, {
            fontSize: {minValue: 10, multiplier: 0.07, refSize: height},
            lineHeight: {minValue: 14, multiplier: 0.11, refSize: height},
        });
    };

    render() {
        let percent = '0';
        let barWidth = 0;

        if (!!this.state.percentage) {
            percent = this.props.percentage + '%';
            barWidth = percent;
        }

        if (this.props.percent) {
            percent = this.state.value;
            barWidth = (this.props.percentage * 100) + '%';
        }

        return (
            <div>
                <table style={{margin: 'auto'}}>
                    <tbody>
                    <tr>
                        <td>
                            <div style={{textAlign: 'center', fontWeight: '500'}} className="kpititle">
                                <span ref={el => this.$kpiTitleEl = el}>{this.props.title}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{textAlign: 'center'}} className="kpivalue">
                                <div ref={el => this.$kpiProgressBarEl = el}
                                     className={`inline middle no-margin progress progress-success progress-striped barprogress${this.state.kpiId} active`}
                                     style={{marginBottom: 4}}
                                     data-percent={`${percent}`}>
                                    <div title={this.state.value}
                                         style={{width: `${barWidth}`, backgroundColor: this.state.color, fontSize: 18}}
                                         className="bar" id={"kpi-progress-bar" + this.state.kpiId}/>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{textAlign: 'center', fontWeight: '500'}} className="kpidescription">
                                <span ref={el => this.$kpiDescriptionEl = el}>{this.props.label}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <center ref={el => this.$containerBandsEl = el}>
                                {this.useBands({container: this.$containerBandsEl})}
                            </center>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>


        )
    }

}

