import './EventNotification.css';

import React from 'react';

import { localStore } from 'components/Storage';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';

const STORAGE_KEY = 'EventNotification.initialIsHidden';

export default function EventNotification() {
  const { msg, currentEvent } = useBimContext();
  const [isHidden, setIsHidden] = React.useState(localStore.get(STORAGE_KEY) || !currentEvent);

  const handleClose = () => {
    setIsHidden(true);
    localStore.put(STORAGE_KEY, true);
  };

  return (
    <div className={`event-notification ${isHidden ? 'hidden' : ''}`}>
      <div className="event-notification-container">
        <a href={currentEvent?.link} target="_blank" className="event-notification-link">
          <div className={`event-notification-live-container ${currentEvent?.glowEnabled ? 'glow' : ''}`} style={{ borderColor: currentEvent?.alertColor, color: currentEvent?.alertColor }}>
            <span className="event-notification-live-text">
              {currentEvent?.alertName ?? msg.t('event.notification.live')}
            </span>
            <Icon
              className={'event-notification-live-icon'}
              icon={currentEvent?.alertIcon ?? 'fiber_manual_record'}
              style={{ color: currentEvent?.alertColor ?? '"#FF0000"' }}
            />
          </div>
          <span className="event-notification-message">{currentEvent?.message}</span>
        </a>
        <div className="event-notification-close">
          <Icon className="event-notification-close-button" icon="close" onClick={handleClose} />
        </div>
      </div>
    </div>
  );
}
