import './BngMultipleUpload.css'

import React from "react";
import PropTypes from "prop-types";
import Dropzone from 'react-dropzone'

import ContextEnhancer from "components/ContextEnhancer";

class BngMultipleUpload extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
        fileLimitReached: PropTypes.bool,
        handleFile: PropTypes.func,
        height: PropTypes.number,
        borderColor: PropTypes.string

    };

    static defaultProps = {
        fileLimitReached: false,
        handleFile: _.noop,
        height: 60,
        borderColor: '#d9edf7'

    };

    render() {
        const {fileLimitReached, height, borderColor, handleFile, context} = this.props;
        return (
            <div className="BngMultipleUpload row-fluid">
                <div className="span12">
                    <div style={{height: height, border: '2px dashed', borderColor: borderColor}}
                         className={`btn-upload-container ${fileLimitReached ? 'disabled' : ''}`}>
                        {!fileLimitReached ?
                            (
                                <Dropzone disabled={fileLimitReached} onDrop={handleFile} multiple>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <div style={{width: '100%', height: '100%'}} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {!isDragActive ?
                                                <span style={{
                                                    fontSize: 14,
                                                    lineHeight: '59px',
                                                    color: '#555'
                                                }}> {context.msg.t('ticket.add.archive')}</span> :
                                                <span style={{
                                                    fontSize: 14,
                                                    lineHeight: '59px',
                                                    color: '#555'
                                                }}> {context.msg.t('support.dialog.drag.archive')}</span>
                                            }

                                        </div>
                                    )}
                                </Dropzone>
                            )
                            :
                            (
                                <span style={{
                                    fontSize: 14,
                                    lineHeight: '59px',
                                    color: '#555'
                                }}>
                                    {context.msg.t('ticket.number.archive.exceeded')}
                                </span>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }


}

export default ContextEnhancer(BngMultipleUpload)