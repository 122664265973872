import React from 'react';

import image from 'components/bng/pages/newAnalysis/assets/BngAdvancedModeAlert.svg';
import BaseErrorPage from 'components/bng/pages/errors/BaseErrorPage';
import Button from 'components/ui/Button';
import useTranslation from 'components/hooks/useTranslation';

export default function TimeoutErrorPage() {
  const { t } = useTranslation();
  return (
    <BaseErrorPage
      className="TimeoutErrorPage"
      image={image}
      title={t('request.timeout.title')}
      description={`${t('request.timeout.message')}.`}
    >
      <div className={`mb-3 `}>
        <Button onClick={() => window.history.back()} className={'bng-button fix save Action'}>
          {t('try.again')}
        </Button>
      </div>
    </BaseErrorPage>
  );
}
