import React, {useState} from 'react';
import Utils from "components/Utils";
import {
    AddItemToDashboardMenuItem,
    AnalystMenuItem,
    BigTableColumnsMenuItem,
    BigTableDataMenuItem,
    BigTableLayoutMenuItem,
    ConfigureMenuItem,
    FiltersMenuItem,
    GeneralConfigMenuItem,
    LayoutMenuItem,
    MenuDashboardMenuItem,
    MobileMenuItem,
    PermissionMenuItem,
    RemoveMenuItem,
    SaveAsMenuItem,
    SaveMenuItem,
    SortRankMenuItem,
    VisualizationMenuItem
} from "components/ui/right-menu/index";
import {handleConfigSubmit, useFetchVersion} from "components/ui/right-menu/items/GeneralConfigMenuItem";
import RightMenuWrapper from "components/ui/right-menu/RightMenu";
import {ConfigureSchema, objectConfigInitialValues} from "components/ui/common/ConfigureAccordion";
import {bngYup} from "components/bng/form/yup/BngYup";
import UiMsg from "components/ui/UiMsg";
import FilterAccordion from 'components/ui/dashboard/FilterAccordion';
import useBimContext from 'components/hooks/useBimContext';

export const availableObjectOpts = (props) => {
    const opts = buildObjectOpts(props);
    return opts.filter(({validOption = true, hide = false}) => validOption && !hide);
};

const buildObjectOpts = ({
                        context = {},
                        path = '',
                        isPersisted = true,
                        hasCubeAccess = true,
                        disabledSortOpts = false,
                        invalid = false,
                        isNewMap = false,
                        objectType = '',
                        dataFormSubmitted = true, // BigTable prop,
                        isSaving = false
                    }) => {
    if (!context.permissions.isAtLeastExplorer()) {
        return [];
    }

    invalid = invalid || !dataFormSubmitted || isSaving;

    return [
        {
            key: 'AnalystMenuItem',
            Component: AnalystMenuItem,
            validOption: isNewMap || Utils.Object.isAnalysis(path),
            disabled: isSaving,
            hide: !hasCubeAccess || !context.permissions.canAnalystMenu(path),
        },
        {
            key: 'MenuDashboardMenuItem',
            Component: MenuDashboardMenuItem,
            validOption: !Utils.Object.isDashboard(path),
            disabled: !isPersisted || isSaving,
        },
        {
            key: 'AddItemToDashboardMenuItem',
            Component: AddItemToDashboardMenuItem,
            validOption: Utils.Object.isDashboard(path),
            disabled: !isPersisted || isSaving,
            hide: !context.permissions.canManipulateObject(path),
        },
        {
            key: 'BigTableLayoutMenuItem',
            Component: BigTableLayoutMenuItem,
            validOption: objectType === 'bigtable',
            hide: objectType !== 'bigtable',
            disabled: invalid,
        },
        {
            key: 'BigTableColumnsMenuItem',
            Component: BigTableColumnsMenuItem,
            validOption: objectType === 'bigtable',
            hide: objectType !== 'bigtable',
            disabled: invalid,
        },
        {
            key: 'BigTableDataMenuItem',
            Component: BigTableDataMenuItem,
            validOption: objectType === 'bigtable',
            hide: objectType !== 'bigtable',
            disabled: invalid,
        },
        {
            key: 'GeneralConfigMenuItem',
            Component: GeneralConfigMenuItem,
            disabled: !isPersisted || isSaving,
            hide: !hasCubeAccess || !context.permissions.canManipulateObject(path),
        },
        {
            key: 'SaveMenuItem',
            Component: SaveMenuItem,
            disabled: invalid,
            fixed: true,
            hide: !hasCubeAccess || !context.permissions.canManipulateObject(path),
        },
        {
            key: 'SaveAsMenuItem',
            Component: SaveAsMenuItem,
            disabled: invalid,
            hide: !hasCubeAccess,
        },
        {
            key: 'ConfigureMenuItem',
            validOption: !isNewMap && !Utils.Object.isDashboard(path) && !Utils.Object.isBigTable(path) && objectType !== 'bigtable',
            Component: ConfigureMenuItem,
            disabled: invalid,
            hide: !hasCubeAccess || !context.permissions.canManipulateObject(path),
        },
        {
            key: 'LayoutMenuItem',
            Component: LayoutMenuItem,
            validOption: Utils.Object.isDashboard(path),
            disabled: !isPersisted || isSaving,
            hide: !context.permissions.canManipulateObject(path),
        },
        {
            key: FilterAccordion.MENU_KEY,
            Component: FiltersMenuItem,
            validOption: Utils.Object.isDashboard(path),
            disabled: !isPersisted || isSaving,
            hide: !context.permissions.canManipulateObject(path),
        },
        {
            key: 'MobileMenuItem',
            Component: MobileMenuItem,
            validOption: Utils.Object.isDashboard(path),
            disabled: isSaving,
        },
        {
            key: 'PermissionMenuItem',
            Component: PermissionMenuItem,
            disabled: !isPersisted || isSaving,
            hide: !hasCubeAccess || !context.permissions.canManipulateObject(path),
        },
        {
            key: 'RemoveMenuItem',
            Component: RemoveMenuItem,
            disabled: !isPersisted || isSaving,
            fixed: true,
            hide: !hasCubeAccess || !context.permissions.canManipulateObject(path),
        },
        {
            key: 'SortRankMenuItem',
            Component: SortRankMenuItem,
            validOption: Utils.Object.isAnalysis(path),
            disabled: disabledSortOpts || isSaving,
        },
        {
            key: 'VisualizationMenuItem',
            Component: VisualizationMenuItem,
            validOption: Utils.Object.isAnalysis(path),
            disabled: isSaving,
        },
    ];
};

const ValidationSchema = bngYup(yup => {
    return yup.object().shape({
        objectConfig: ConfigureSchema,
    })
})

export default function ObjectRightMenu({
                             initialValues = {},
                             handleSubmit = _.noop,
                             validationSchema = null,
                             onAccordionChange = _.noop,
                             onFormikRef = undefined,
                             ...props
                         }) {
    const context = useBimContext();
    const [versions, fetchVersions] = useFetchVersion(props);
    const [appliedValues, setAppliedValues] = useState(null)

    const runtimeVariable = props.isReloaded ? initialValues : appliedValues ?? initialValues;

    const mergeInitialValues = {
        objectConfig: objectConfigInitialValues(props),
        ...runtimeVariable,
    }

    const commonSubmit = async (values, actions) => {
        try {
            await handleConfigSubmit({context, ...props}, values.objectConfig, actions);
            await handleSubmit(values, actions);
            if (!!window.__OBJECT_RELOAD) {
                window.__OBJECT_RELOAD({dirty: false, reset: false});
            }
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError('', e);
        } finally {
            actions.setSubmitting(false);
        }
    }

    return (
        <RightMenuWrapper versions={versions}
                          fetchVersions={fetchVersions}
                          setAppliedValues={setAppliedValues}
                          initialValues={mergeInitialValues}
                          validationSchema={validationSchema || ValidationSchema}
                          handleSubmit={commonSubmit}
                          onAccordionChange={onAccordionChange}
                          onFormikRef={onFormikRef}
                          context={context}
                          {...props}
        />
    )
};