import React, {Component} from "react";
import PropTypes from "prop-types";
import ReactDOM from 'react-dom';
import ObjectRightMenu from "components/ui/right-menu/ObjectRightMenu";

class RightMenuContainer extends Component {

    static propTypes = {
        loading: PropTypes.bool,
        open: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        loading: false,
        open: false,
    };

    findContainer = () => {
        let {container} = this.props;
        if (!container) {
            container = document.getElementById("right-menu-container");
        }

        j(container).removeClass('hide');

        return container;
    };

    render() {
        const container = this.findContainer();
        return ReactDOM.createPortal(
            (<ObjectRightMenu {...this.props}/>),
            container
        );
    }
}

export default RightMenuContainer;