import './BngTable.css';

import React from 'react';
import PropTypes from 'prop-types';
import {useInView} from "react-intersection-observer";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

import BngEmpty from 'components/bng/ui/BngEmpty';
import Icon from 'components/ui/common/Icon';

const SortMode = {
    NONE: {iconProps: {icon: 'code', className: 'WithoutSort'}},
    ASC: {iconProps: {icon: 'keyboard_arrow_down'}},
    DESC: {iconProps: {icon: 'keyboard_arrow_up'}},
};

Object.keys(SortMode).forEach((k) => (SortMode[k].name = k));

SortMode.NONE.nextMode = SortMode.ASC;
SortMode.ASC.nextMode = SortMode.DESC;
SortMode.DESC.nextMode = SortMode.NONE;

const BypassComponent = ({children}) => children;
const BypassFnComponent = ({children}) => children();

export function BngTable({
                             cols = [],
                             rows = [],
                             className = '',
                             children,
                             rowProps,
                             rowIdProp = 'id',
                             rowKeyBuilder,
                             showEmptyAlert = false,
                             emptyAlertProps = {},
                             sortHandler = _.noop,
                             sortMode = {},
                             stickyHeader = false,
                             rowHeight = undefined,
                             onDropHandler,
                             selectedRow = '',
                             hideHeader = false,
                             ...props
                         }) {
    const containData = rows?.length > 0;

    const DropCtxWrapper = onDropHandler ? DragDropContext : BypassComponent;
    const DropWrapper = onDropHandler ? Droppable : BypassFnComponent;
    const DragWrapper = onDropHandler ? Draggable : BypassFnComponent;

    return (
        <DropCtxWrapper onDragEnd={onDropHandler}>
            <table className={`BngTable ${className} ${stickyHeader ? 'StickyHeader' : ''}`} {...props}>
                <colgroup>
                    {cols.map((col, idx) => (
                        <col key={col.key || idx} style={{width: col.size}}/>
                    ))}
                </colgroup>
                {!hideHeader &&
                    <thead>
                    <tr>
                        {cols.map((col, idx) => {
                            const colSortMode = col.sortable ? SortMode[sortMode[col.key || idx] || 'NONE'] : null;
                            return (
                                <BngTableTh key={col.key || idx}
                                            className={`${col.sortable ? 'Sortable' : ''} ${col.colClassName ?? ''}`}>
                                    <div className={`flex-center-items ${col.headerClassName || ''}`} onClick={col.sortable ? (event) => sortHandler({
                                        event,
                                        col,
                                        idx,
                                        currentSortMode: colSortMode
                                    }) : undefined}>
                                        {col.sortable &&
                                            <div className="Sort mr-1">
                                                <Icon {...colSortMode.iconProps} />
                                            </div>
                                        }
                                        <div>
                                            {col.headerRender && col.headerRender()}
                                            {col.label &&
                                                <>
                                                    {col.label}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </BngTableTh>
                            );
                        })}
                    </tr>
                    </thead>
                }
                <DropWrapper droppableId="BngTableDroppableBody">
                    {(droppableBody) => {
                        return (
                            <tbody ref={droppableBody?.innerRef}
                                   {...droppableBody?.droppableProps}>
                            {(!containData && showEmptyAlert) &&
                                <tr>
                                    <td colSpan={cols.length || 1}>
                                        <BngEmpty isEmpty={true} {...emptyAlertProps}/>
                                    </td>
                                </tr>
                            }
                            {containData &&
                                <>
                                    {rows.map(
                                        (row, idx) => {
                                            const rowKey = rowKeyBuilder ? rowKeyBuilder(row, idx) : (row[rowIdProp] || idx);
                                            return (
                                                <DragWrapper
                                                    key={rowKey}
                                                    draggableId={idx + 1}
                                                    index={idx}
                                                >
                                                    {(draggableRow) => {
                                                        return (
                                                            <BngTableTr key={rowKey}
                                                                        rowHeight={rowHeight}
                                                                        ref={draggableRow?.innerRef}
                                                                        className={`${selectedRow?.selectedRowId === idx ? selectedRow.rowClassName : ''}`}
                                                                        {...draggableRow?.draggableProps}>
                                                                {cols.map((col, colIdx) => {
                                                                    return (
                                                                        <BngTableTd key={col.id || colIdx}
                                                                                    className={col.rowClassName}
                                                                                    onClick={col.onClick ? (e) => col.onClick(row, idx, e) : undefined}>
                                                                            {col.render(row, idx, rowProps, draggableRow)}
                                                                        </BngTableTd>
                                                                    );
                                                                })}
                                                            </BngTableTr>
                                                        )
                                                    }}
                                                </DragWrapper>
                                            );
                                        }
                                    )}
                                </>
                            }
                            </tbody>
                        );
                    }}
                </DropWrapper>
            </table>
        </DropCtxWrapper>
    );
};

BngTable.propTypes = {
    className: PropTypes.string,
    cols: PropTypes.arrayOf(PropTypes.shape({
        size: PropTypes.any,
        label: PropTypes.any,
        render: PropTypes.func.isRequired,
    })).isRequired,
    rows: PropTypes.array.isRequired,
    rowKeyBuilder: PropTypes.func,
};

export const BngTableTd = ({className = '', children, ...props}) => (
    <td className={`BngTableTd ${className}`} {...props}>
        {children}
    </td>
);

export const BngTableTh = ({className = '', children, ...props}) => (
    <th className={`BngTableTh ${className}`} {...props}>
        {children}
    </th>
);

// TODO suporte ao drag'n'drop e otimização de render (utilizado na tabela de gerencia de diretórios até o momento)
//  não funcionam simultaneamente no momento, caso necessário utilizar ambas necessário fazer algum fix aqui
export const BngTableTr = React.forwardRef(({
                                                className = '',
                                                children,
                                                rowHeight,
                                                ...props
                                            }, forwardRef) => {
    const {ref, inView} = useInView();

    return (
        <tr className={`BngTableTr ${className}`}
            ref={forwardRef ?? ref}
            {...props}>
            {rowHeight ? (
                inView ? (
                    children
                ) : (
                    <td colSpan={children?.length ?? 1}>
                        <div style={{height: rowHeight}}></div>
                    </td>
                )
            ) : (
                children
            )}
        </tr>
    );
});

export default BngTable;