import React from 'react';
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from "components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle";
import DashInputImage from "components/ui/dashboard/components/DashInputImage";

const DashboardItemMenuEditImage = ({
                                        item = {},
                                        context = {},
                                    }) => {
    return (
        <li style={{position: 'relative'}}>
            <DashInputImage item={item} />
            <DashboardItemMenuTitle title={context.msg.t('edit.content')}
                                    style={{pointerEvents: 'none'}}
                                    icon="fa-pencil-square"
                                    more={false}/>
        </li>
    )
}

export default ContextEnhancer(DashboardItemMenuEditImage);