import styles from './StarCounter.module.css';

import React, { useEffect, useState } from 'react';

const numberOfStars = _.range(5);

export default function StarCounter({ score, setScore }) {
  const [hover, setHover] = useState(score);

  useEffect(() => {
    setHover(score)
  }, [score])

  return (
    <div className={`${styles.starRating}`}>
      {numberOfStars.map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={index <= (hover || score) ? `${styles.on}` : `${styles.off}`}
            onClick={() => setScore(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(score || 0)}
          >
            <span className={`${styles.star}`}>&#9733;</span>
          </button>
        );
      })}
    </div>
  );
}
