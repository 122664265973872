import './ColorRange.css';

import React from 'react';
import PropTypes from "prop-types";

import ContextEnhancer from "components/ContextEnhancer";
import BngInputColor from "components/bng/form/BngInputColor";
import {BngSelectSearch} from "components/bng/form/BngSelectSearch";
import {BngDoubleField} from "components/bng/form/BngMaskedField";

class ColorRange extends React.PureComponent {

    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.array,
        defaultValue: PropTypes.bool,
        addTransparentOptions: PropTypes.bool
    };

    static defaultProps = {
        defaultValue: false,
        addTransparentOptions: true
    };

    state = {};

    onChange(index, name, value) {
        let valueClone = [...this.props.value];
        valueClone[index][name] = value;
        this.props.onChange(valueClone);
    }

    onAdd = () => {
        let valueClone = [...this.props.value];
        let entry = {value: 0, color: '#FFFFFFFF', valueOption: 'number'}
        valueClone.push(entry);
        this.props.onChange(valueClone);
    }

    onRemove(index) {
        let valueClone = [...this.props.value];
        valueClone.splice(index, 1);
        this.props.onChange(valueClone);
    }

    onRevert = () => {
        let valueClone = [];
        let valueCloneReversed = [].concat(this.props.value);
        valueCloneReversed.reverse();

        for (let i = 0; i < this.props.value.length; i++) {
            valueClone.push({
                value: this.props.value[i].value,
                valueOption: this.props.value[i].valueOption,
                color: valueCloneReversed[i].color
            })
        }

        this.props.onChange(valueClone);
    }

    getValueOptions(index) {
        const valueOptions = [
            {label: this.props.context.msg.t('min_value'), value: 'min'},
            {label: this.props.context.msg.t('number'), value: 'number'},
            {label: this.props.context.msg.t('percent'), value: 'percent'},
            {label: this.props.context.msg.t('percentile'), value: 'percentile'},
            {label: this.props.context.msg.t('max_value'), value: 'max'}
        ];

        if (index === 0) {
            return valueOptions.filter(v => v.value !== 'max');
        } else if (index === (this.props.value.length - 1)) {
            return valueOptions.filter(v => v.value !== 'min');
        } else {
            return valueOptions.filter(v => (v.value !== 'min' && v.value !== 'max'));
        }
    }

    render() {
        const {value, defaultValue} = this.props;

        return (
            <div className={`BngColorRange`}>

                <div className={`BngColorRange__header`}>
                    <label>{this.props.context.msg.t('colors')}</label>
                    <div style={{width: '100%'}}></div>
                    <i className={`material-icons BngColorRange__button BngBigTable__columnsForm__help`}
                       onClick={() => window.open(this.props.context.msg.t('color.range.support.link'), '_blank')}>{`help_outline`}</i>
                    <i className={`material-icons BngColorRange__button`}
                       onClick={this.onRevert}>{`swap_vert`}</i>
                    <i className={`material-icons BngColorRange__button`} onClick={this.onAdd}>{`add`}</i>
                </div>

                <div className={`BngColorRange__ranges`}>
                    {value.map((range, index) => (
                        <div className="row-fluid BngColorRange__range" key={index}>
                            <div className="span2">
                                <BngInputColor
                                    form={{setFieldValue: (name, t) => this.onChange(index, 'color', t)}}
                                    field={{onChange: _.noop, value: range.color}}
                                    addTransparentOptions={this.props.addTransparentOptions}
                                />
                            </div>

                            {(index === 0 && defaultValue) ? (
                                <div className="span6" style={{display: 'flex', alignItems: 'center'}}>
                                    <span>{this.props.context.msg.t('default_value')}</span>
                                </div>
                            ) : (
                                <>
                                    <div className="span5">
                                        <BngSelectSearch
                                            options={this.getValueOptions(index)}
                                            form={{
                                                setFieldValue: (name, t) => {
                                                    this.onChange(index, 'valueOption', t)
                                                }
                                            }}
                                            field={{value: range.valueOption, onChange: _.noop}}
                                            clearable={false}
                                        />
                                    </div>
                                    <div className="span4">
                                        <BngDoubleField
                                            disabled={range.valueOption === 'max' || range.valueOption === 'min'}
                                            onValueChange={({floatValue}) => this.onChange(index, 'value', floatValue)}
                                            field={{
                                                value: (range.valueOption === 'max' || range.valueOption === 'min') ? '' : range.value
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            <div className="span1 BngColorRange__deleteButtonContainer">
                                {index !== 0 && (
                                    <i
                                        style={{float: 'right'}}
                                        className={`material-icons BngColorRange__button`}
                                        onClick={() => this.onRemove(index)}>{`delete_outline`}</i>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        );
    }
}

export default ContextEnhancer(ColorRange);