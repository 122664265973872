import {
    SET_HOVER_ROW,
    SET_ROW_META_DATA,
    SET_SELECTED_ROW,
    DEFINE_FILTER_MODEL,
    DEFINE_SORT_MODEL
} from "components/bng/pages/bigTable/redux/actionTypes";

const initialState = {
    rowMetaData: {},
    selectedRowId: {},
    hoverRowId: {},
    bigtableFilterModel: [],
    bigtableTotalPages: 0,
    bigtableSortModel: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ROW_META_DATA: {
            const {bigTableId, data} = action.payload;

            const rowMetaDataClone = {...state.rowMetaData};
            rowMetaDataClone[bigTableId] = data;

            return {
                ...state,
                rowMetaData: rowMetaDataClone
            };
        }
        case SET_SELECTED_ROW: {
            const {bigTableId, data} = action.payload;
            const clone = {...state.selectedRowId};
            clone[bigTableId] = data;
            return {
                ...state,
                selectedRowId: clone
            };
        }
        case SET_HOVER_ROW: {
            const {bigTableId, data} = action.payload;
            const clone = {...state.hoverRowId};
            clone[bigTableId] = data;
            return {
                ...state,
                hoverRowId: clone
            };
        }
        case DEFINE_FILTER_MODEL:
            const currentBigtableFilterModel = state.bigtableFilterModel;
            let hasFilterModel = false;
            state.bigtableFilterModel.forEach((i, idx) => {
                if(i.path === action.payload.path) {
                    currentBigtableFilterModel[idx] = action.payload;
                    hasFilterModel = true;
                }
            });
            if(!hasFilterModel) {
                currentBigtableFilterModel.push(action.payload);
            }
            return {
                ...state,
                bigtableFilterModel: currentBigtableFilterModel
            };
        case DEFINE_SORT_MODEL:
            const currentBigtableSortModel = state.bigtableSortModel;
            let hasSortModel = false;
            state.bigtableSortModel.forEach((i, idx) => {
                if(i.path === action.payload.path) {
                    currentBigtableSortModel[idx] = action.payload;
                    hasSortModel = true;
                }
            });
            if(!hasSortModel) {
                currentBigtableSortModel.push(action.payload);
            }
            return {
                ...state,
                bigtableSortModel: currentBigtableSortModel
            };
        default:
            return state;
    }
}