import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";


export class Pizza extends BaseKpi {

    constructor(props) {
        super(props);
    }

    initComponent = () => {
        let color = this.state.color;
        let kpiId = this.state.kpiId;

        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        let elementKpi = this.$kpiPizzaEl;

        if (j('#kpi-pizza' + kpiId).children.length === 2) {
            let canvas = j('#kpi-pizza' + kpiId).children()[1];
            j(canvas).remove();
        }

        j(`.easy-pie-chart-${kpiId}`).each(function () {

            let $box = j(this).closest('.infobox');
            let barColor = color || (!$box.hasClass('infobox-dark') ? $box.css('color') : 'rgba(255,255,255,0.95)');
            j('.percent', this).css('color', barColor);
            j(this).css('color', barColor);
            let trackColor = barColor === 'rgba(255,255,255,0.95)' ? 'rgba(255,255,255,0.25)' : '#E2E2E2';
            let size = elementKpi.offsetHeight;

            new EasyPieChart(this, {
                barColor: barColor,
                trackColor: trackColor,
                scaleColor: false,
                lineCap: 'butt',
                lineWidth: parseInt(size / 10),
                animate: application.utils.disableAnimations() ? false : 1000,
                size: size
            });

            j(this).addClass("EasyPieChart-initialized");
        });
    };

    customResizeKpi = ({height}) => {
        const heightPercent = 0.65;
        const minHeight = 50;
        const fontSize = 0.23;

        let heightDiv = (height * (heightPercent)) < minHeight ? minHeight : (height * (heightPercent));

        resizeEl(this.$kpiPizzaEl, {
            width: {value: `${heightDiv}px`},
            height: {value: `${heightDiv}px`},
        });

        if (this.props.growthInfoRender) {
            this.$bodyEl.querySelector('.KpiGrowth').style.width = `${heightDiv}px`;
        }

        resizeEl(this.$percentEl, {
            fontSize: {value: `${heightDiv * fontSize}px`},
            lineHeight: {value: `${heightDiv}px`},
            whiteSpace: {value: 'nowrap'},
        });
        if (this.$percentEl.offsetWidth >= (this.$kpiPizzaEl.offsetWidth * 0.8)) {
            heightDiv = height * (((this.$kpiPizzaEl.offsetWidth * 0.8) / this.$percentEl.offsetWidth) * 0.5);
            resizeEl(this.$percentEl, {
                fontSize: {value: `${heightDiv * fontSize}px`},
            });
        }
    };


    render() {
        let kpiId = this.state.kpiId;
        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        return (
            <div>
                <div className={`KpiBody Qplot`}>
                    <div ref={el => this.$bodyEl = el} className="KpiInternalBody">
                        <div ref={el => this.$kpiPizzaEl = el} id={"kpi-pizza" + kpiId}
                             data-percent={this.props.percentage}
                             className={`easy-pie-chart percentage ${kpiId} easyPieChart easy-pie-chart-${kpiId}`}>
                            <span ref={el => this.$percentEl = el}
                                  className="percent"
                                  style={{color: this.state.color}}> {this.state.percentage + "%"}</span>
                        </div>
                        {this.useGrowth()}
                    </div>
                    {this.useRightFragment({container: this.$bodyEl})}
                </div>
                <div ref={el => this.$containerBandsEl = el}>
                    {this.useBands({container: this.$containerBandsEl})}
                </div>
            </div>
        )
    }

}

