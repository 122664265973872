import styles from './BimPublicLabs.module.css';

import React, { useEffect, useState } from 'react';

import LabsTrial from 'components/ui/labs/trial/LabsTrial';
import useBimContext from 'components/hooks/useBimContext';
import { Tab, TabSet } from 'components/ui/TabSet';
import LabsVoting, { VOTING_FEATURE_STATUS } from 'components/ui/labs/voting/LabsVoting';
import LabsSuggestions from 'components/ui/labs/suggestions/LabsSuggestions';
import Utils from 'components/Utils';
import useQueryParams from 'components/hooks/useQueryParams';
import Api from 'components/Api';

const TAB_QUERY_PARAM = 'currentTab';

const TAB_GROUPS = [
  {
    key: 'trial',
    component: LabsTrial,
    icon: 'science',
    tabClassName: styles.firstTab,
    label: 'publiclabs.trial',
    statusFilter: '',
  },
  {
    key: 'forum',
    component: LabsVoting,
    icon: 'thumb_up_alt',
    tabClassName: '',
    label: 'support.menu.voting.forum',
    statusFilter: VOTING_FEATURE_STATUS.ONGOING,
    props: {
      bngEmptyProps: {
        className: 'LabsVotingForm',
        img: `${Api.baseUrl()}/resources/images/empty_vote.png`,
        title: 'LabsVoting.title.vote',
        message: 'LabsVoting.message.vote',
      },
    },
  },
  {
    key: 'roadmap',
    component: LabsVoting,
    icon: 'new_releases',
    tabClassName: '',
    label: 'publiclabs.roadmap.title',
    statusFilter: VOTING_FEATURE_STATUS.APPROVED,
  },
  {
    key: 'suggestions',
    component: LabsSuggestions,
    icon: 'tungsten',
    tabClassName: '',
    label: 'publiclabs.suggestions.title',
    statusFilter: '',
  },
  {
    key: 'ideaBox',
    component: LabsVoting,
    icon: 'archive',
    tabClassName: '',
    label: 'idea.box',
    statusFilter: VOTING_FEATURE_STATUS.IDEA,
    props: {
      showFilterButtons: false,
    },
  },
];

const DEFAULT_TAB = TAB_GROUPS[0];

function findTab(key = '') {
  return TAB_GROUPS.find((tab) => tab.key === key);
}

export default function BimPublicLabs() {
  const context = useBimContext();
  const { queryParams, href } = useQueryParams({ listenToEvents: true });
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB);

  useEffect(() => {
    const queryTab = queryParams.get(TAB_QUERY_PARAM) ?? DEFAULT_TAB.key;
    onSelectTab(queryTab, false);
  }, [href]);

  const onSelectTab = (tabKey, shouldUpdateQuery = true) => {
    const tab = findTab(tabKey) ?? DEFAULT_TAB;
    setSelectedTab(tab);

    if (shouldUpdateQuery) {
      Utils.History.updateQuery({
        [TAB_QUERY_PARAM]: tab.key,
      });
    }
  };

  return (
    <div className={`BimPublicLabs ${styles.labsContainer}`}>
      <div className={`${styles.labsPageWrapper}`}>
        <div className={`${styles.labsHeader}`}>
          <span className={`${styles.labsHeaderTitle}`}>{context.msg.t('publiclabs.header.title')}</span>
          <div
            className={`${styles.labsHeaderDesc}`}
            dangerouslySetInnerHTML={{ __html: context.msg.t('publiclabs.header.description') }}
          />
          <div className={`${styles.labsHeaderScientist}`} />
        </div>
        <div className={`${styles.tabSetContainer}`}>
          <TabSet
            selectedTab={TAB_GROUPS.indexOf(selectedTab)}
            internal
            bodyRadius={false}
            handleTabChange={(selected) => onSelectTab(TAB_GROUPS[selected].key)}
          >
            {TAB_GROUPS.map((tabGroup, idx) => {
              const TabComponent = tabGroup.component;
              return (
                <Tab
                  key={idx}
                  tabClassName={tabGroup.tabClassName}
                  icon={tabGroup.icon}
                  label={context.msg.t(tabGroup.label)}
                >
                  <TabComponent statusFilter={tabGroup.statusFilter} {...(tabGroup.props ?? {})} />
                </Tab>
              );
            })}
          </TabSet>
        </div>
      </div>
    </div>
  );
}
