const {id = '0', source = '', cube = '', openOnEditMode, filter} = jQuery.QueryString;
const initialState = {
    id: parseInt(id),
    source,
    cube,
    filter,
    openOnEditMode: openOnEditMode ? openOnEditMode === 'true' : null,
    map: {}
};

initialState.visible = initialState.source.length > 0
    && initialState.cube.length > 0
    && window.location.pathname.indexOf('/spr/bng/newmap') !== -1;
export default function newmap(state = initialState, action) {
    return state;
};