import Api from 'components/Api';
import { Axios } from 'commonUtils';

class PresentationApi {
  static async findByOwner(pageSize = 0, page = 0) {
    const { data } = await Axios.get(`/spr/ui/presentation`, {
      params: {
        pageSize,
        page,
      },
    });
    return data;
  }

  static async findShareConfig(id) {
    const { data } = await Axios.get(`/spr/ui/presentation/${id}/shareConfig`);
    return data;
  }

  static async saveShareConfig(id, shareConfig) {
    const { data } = await Axios.post(`/spr/ui/presentation/${id}/shareConfig`, shareConfig);
    return data;
  }

  static async savePublicLink(id, publicLink) {
    const { data } = await Axios.post(`/spr/ui/presentation/${id}/link/public`, publicLink);
    return data;
  }

  static async findPublicLinks(id) {
    const { data } = await Axios.get(`/spr/ui/presentation/${id}/link/public`);
    return data;
  }

  static async removePresentation(id) {
    const { data } = await Axios.delete(`/spr/ui/presentation/${id}`);
    return data;
  }

  static async removePublicLink({ idPresentation, idPublicLink }) {
    const { data } = await Axios.delete(`/spr/ui/presentation/${idPresentation}/link/public/${idPublicLink}`);
    return data;
  }

  static async findLinkToShare(publicLinkId, shortLink) {
    const url = shortLink
      ? `/spr/ui/presentation/${publicLinkId}/shortlink/share`
      : `/spr/ui/presentation/${publicLinkId}/link/share`;
    const { data } = await Axios.get(url);
    return data;
  }

  static async findLinkFor(presentationId) {
    const { data } = await Axios.get(`/spr/ui/presentation/${presentationId}/link`);
    return data;
  }

  static async savePresentation(values = {}) {
    const { data } = await Axios.post(`/spr/ui/presentation`, values);
    return data;
  }

  static async findOne(presentationId) {
    const { data } = await Axios.get(`/spr/ui/presentation/${presentationId}`);
    return data;
  }

  static async findSharedPresentation() {
    const { data } = await Axios.get(`/spr/ui/presentation/shared`);
    return data;
  }

  static linkExportImages(id) {
    return `${Api.baseUrl()}/api/presentations/${id}/images`;
  }
}

export default PresentationApi;
