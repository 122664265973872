import ContextEnhancer from "components/ContextEnhancer";
import {MODALS} from "components/ui/redux/Actions";
import PropType from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";
import SaveAsDialogContainer from "components/ui/common/SaveAsDialogContainer";

class SaveAsMenuItem extends PureComponent {

    static propTypes = {
        path: PropType.string,
        folder: PropType.string,
        name: PropType.string,
        description: PropType.string,
        saveAs: PropType.func,
        closeAccordion: PropType.func,
    };

    static defaultProps = {
        path: '',
        folder: '',
        name: '',
        description: '',
        saveAs: _.noop,
        closeAccordion: _.noop,
    };

    state = {};

    constructor(props) {
        super(props);
    }

    openSaveAsDialog = () => {
        this.props.clearAccordion();
        this.props.openSaveAs(this.props, this.props.saveAs);
    };

    render() {
        return (
            <RightMenuItem {...this.props}
                                onClick={this.openSaveAsDialog}
                                title={this.props.context.msg.t('save.as')}
                                className="SaveAsMenuItem"
                                icon="icon-bim-saveas"/>

        );
    }
}

export default connect(
    () => ({}),
    dispatch => ({
        openSaveAs(params, onSubmit) {
            dispatch(
                MODALS.open(SaveAsDialogContainer, {
                    onSubmit,
                    ...params
                })
            );
        }
    })
)(ContextEnhancer(SaveAsMenuItem));
