import { Axios } from 'commonUtils';

const CACHE = {
  icons: null,
  translations: {},
};

class IconApi {
  async findIcons() {
    if (CACHE.icons) {
      return _.cloneDeep(CACHE.icons);
    }
    const { data } = await Axios.get('/spr/icons');
    CACHE.icons = _.cloneDeep(data);
    return data;
  }

  async findIconTranslations(lang = window.__USER_LANG) {
    if (!lang.includes('en')) {
      if (CACHE.translations.hasOwnProperty(lang)) {
        return CACHE.translations[lang];
      }
      const { data } = await Axios.get(`/resources/material-icons/locale/${lang}.json`);
      CACHE.translations[lang] = data;
      return data;
    }
    return {};
  }
}

export default IconApi;
