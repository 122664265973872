import { Axios } from 'commonUtils';

/**
 * @typedef {Object} TfaValidationRes
 * @property {boolean} unlocked
 * @property {string} obfuscatedPhone
 * @property {string} userDisplayName
 * @property {string} supportChat
 */

/**
 * @typedef {Object} TfaUnlockRes
 * @property {boolean} unlocked
 */

export default {
  /**
   * @returns {Promise<TfaValidationRes>}
   */
  async checkTfa(auth = { email: '', password: '', type: '' }) {
    return await Axios.postData('/api/authentication/tfa', auth);
  },

  /**
   * @returns {Promise<TfaUnlockRes>}
   */
  async validateTfa(auth = { email: '', code: '' }) {
    return await Axios.postData('/api/authentication/tfa/unlock', auth);
  },

  async defaultLogin(auth = { email: '', password: '', rememberMe: false, afterAuthUrl: '' }) {
    const loginReq = new URLSearchParams();
    loginReq.append('j_username', auth.email);
    loginReq.append('j_password', auth.password);
    loginReq.append('remember-me', auth.rememberMe);
    loginReq.append('afterAuthUrl', auth.afterAuthUrl);

    const result = await Axios.postData('/j_spring_security_check', loginReq);

    window.location.replace(result.redirectUrl);
  },
};
