import React from "react";
import PropTypes from "prop-types";
import {connect} from "formik";

export const FormikListener = connect(
    class extends React.Component {

        componentDidUpdate(prevProps) {
            if (prevProps.formik !== this.props.formik) {
                this.props.onChange(this.props.formik, prevProps.formik);
            }
        }

        render() {
            return null;
        }
    }
);

FormikListener.propTypes = {
    onChange: PropTypes.func,
};

FormikListener.defaultProps = {
    onChange: _.noop,
};

export default FormikListener;