import React from 'react';
import styles from './BngPulsateLoading.module.css';
import OnBodyPortalWrapper from "components/bng/ui/OnBodyPortalWrapper";

const BngPulsateLoading = ({className = ''}) => {
    return (
        <OnBodyPortalWrapper>
            <div className={`BngPulsateLoading ${styles.PulsateLoading} ${className}`}/>
        </OnBodyPortalWrapper>
    );
}

export default BngPulsateLoading;