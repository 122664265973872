import React from 'react';

import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import BngLogDialog from 'components/bng/logs/BngLogDialog';
import useFetchData from 'components/hooks/useFetchData';

export default function SchedulingLogDialog({ closeModal, exportSchedId, logId }) {
  const context = useBimContext();

  const $executionLog = useFetchData(async () => {
    try {
      return await Api.ExportScheduling.findLog(exportSchedId, logId);
    } catch (e) {
      console.error('Error on findLog()', { exportSchedId, logId }, e);
      UiMsg.ajaxError(null, e);
    }
  });

  return (
    <BngLogDialog
      closeModal={closeModal}
      log={$executionLog.data?.log ?? ''}
      title={context.msg.t('export.scheduling.log')}
      loading={$executionLog.isLoading}
    />
  );
}
