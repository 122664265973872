import styles from './LinkedObjects.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { useEventListener } from 'components/hooks/useEventListener';

export default function LinkedObjects({ items = [], render, itemWidth = 40, itemNumber = null }) {
  const [updating, setUpdating] = useState(true);
  const [visibleElements, setVisibleElements] = useState(8);
  const $ref = useRef();

  const updateVisibleElements = () => {
    const width = $ref.current.getBoundingClientRect().width;
    setVisibleElements(Math.max(itemNumber || 4, Math.floor(width / itemWidth)));
    setUpdating(false);
  };

  useEffect(() => {
    if (updating && $ref.current) {
      updateVisibleElements();
    }
  }, [$ref.current, updating]);

  useEventListener('resize', () => {
    setUpdating(true);
  });

  const remainingItems = items.length - visibleElements;
  const itemsToRender = items.slice(0, visibleElements);

  return (
    <div className={`LinkedObjects`} ref={$ref}>
      {!updating && (
        <>
          {itemsToRender.map((item, index) => {
            return render(item, index);
          })}
          {remainingItems > 0 ? <div className={styles.moreItemsIndicator}>{remainingItems} +</div> : <></>}
        </>
      )}
    </div>
  );
}
