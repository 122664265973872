import styles from './ChangeRoleDropdown.module.css';

import React from 'react';

import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import BngDropdown from 'components/bng/ui/BngDropdown';

const defaultRoleButton = ({ openDropdown, icon, name }) => {
  return (
    <div className={`${styles.roleWrapper}`} onClick={openDropdown}>
      <Icon icon={icon} />
      <span>{name}</span>
    </div>
  );
};

export default function ChangeRoleDropdown({
  roles,
  userId,
  currentRole,
  fetchData = _.noop,
  afterChange = _.noop,
  customButton,
  onClose = _.noop,
  popperClassName = '',
  setLoading = _.noop,
  onChange,
  ...props
}) {
  const context = useBimContext();

  const changeRole = async (projectId, userId, roleName) => {
    setLoading(true);
    try {
      if (_.isFunction(onChange)) {
        await onChange(roleName);
      } else {
        await Api.Project.changeUserRole(projectId, userId, roleName);
        UiMsg.ok(context.msg.t('user.type.changed.successfully'));
      }
      await fetchData();
      onClose();
    } catch (e) {
      console.error('Error on changeRole()', { projectId, userId, roleName }, e);
      UiMsg.error(context.msg.t('user.type.changed.error'), e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BngDropdown
      className="ChangeRoleDropdown"
      overDialog={true}
      onClose={onClose}
      popperClassName={popperClassName}
      customButton={({ openDropdown }) => {
        const btnProps = { openDropdown, icon: currentRole.icon, name: currentRole.name };
        return _.isFunction(customButton) ? customButton(btnProps) : defaultRoleButton(btnProps);
      }}
      customOptions={({ closeDropdown }) => {
        return (
          <ul className={styles.rolesDropdown}>
            {roles.map((role, idx) => (
              <li
                key={`${role.name}-${idx}`}
                className={`${styles.roleListItem} ${currentRole.name === role.name ? styles.SelectedRole : ''}`}
                onClick={async () => {
                  try {
                    await changeRole(context.project?.id, userId, role.name);
                    closeDropdown();
                    await afterChange();
                  } catch (e) {
                    console.error('Error on changeRole action', e);
                    UiMsg.ajaxError(null, e);
                  }
                }}
              >
                <Icon icon={role.icon} />
                <span>{context.msg.t(role.name)}</span>
              </li>
            ))}
          </ul>
        );
      }}
      {...props}
    />
  );
}
