//import "components/ui/cockpit/CockpitNavigator.css";

import React, {Component} from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";

import ContextEnhancer from "components/ContextEnhancer";
import {BngPopperButton, BngPopperWrapper} from "components/bng/ui/BngPopperWrapper";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import BngSearch from "components/bng/ui/BngSearch";
import Icon from "components/ui/common/Icon";


class BngCockpitNavigator extends Component {

    static propTypes = {
        cockpits: PropTypes.array,
        currentCockpit: PropTypes.object,
        favoriteChanged: PropTypes.func,
        onCockpitChange: PropTypes.func,
    };

    static defaultProps = {
        cockpits: [],
        currentCockpit: null,
        favoriteChanged: _.noop,
        onCockpitChange: _.noop,
    };

    baseView = {};

    state = {
        open: false,
        $container: null,
        searchTerm: '',
        loading: false,
        view: {
            listView: false,
            preferredSize: null,
        },
    };

    constructor(props) {
        super(props);
        this.searchChild = React.createRef();
    }

    async componentDidMount() {
        const $container = document.querySelector('.BngAppContent');
        const userPreferences = this.props.context.user.preferences.cockpitPreferences || {};
        const view = Object.assign(
            {},
            {listView: false, preferredSize: null},
            userPreferences
        );
        this.baseView = view;
        this.setState({view, $container});
    }

    changeView = () => {
        const view = {
            listView: !this.state.view.listView,
            preferredSize: this.state.view.preferredSize
        };
        this.setState({view});
    };

    openMenu = () => {
        this.setState({loading: true});
        try {
            this.setState({
                open: true,
                searchTerm: ''
            });
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    handleFavorite = async (event, cockpit) => {
        this.setState({loading: true});
        try {
            await Api.Cockpit.toggleFavorite(cockpit.id);
            this.props.favoriteChanged(cockpit);
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    onSearchChanged = (searchTerm) => {
        this.setState({
            searchTerm
        });
    };

    loadCockpit = async (event, cockpit) => {
        this.props.onCockpitChange(cockpit);
    };

    manageCockpits = async (event) => {
        this.setState({loading: true});
        try {
            await Api.Cockpit.manageCockpits();
        } finally {
            this.setState({loading: false});
        }
    };

    closeDropdown = async (event) => {
        //TODO remover após migrar react-router
        const view = await this.saveCockpitPreferences();
        this.setState({open: false, view});
    };

    async componentWillUnmount() {
        await this.saveCockpitPreferences();
    }

    saveCockpitPreferences = async () => {
        const preferences = {
            listView: this.state.view.listView,
            preferredSize: j('.cockpit-navigator-body').width()
        };

        if (!_.isEqual(preferences, this.baseView)) {
            try {
                await Api.Cockpit.savePreferences(this.props.context.user.id, preferences);
                this.baseView = preferences;
            } catch (e) {
                console.error(e);
            }
        }

        return preferences;
    };

    filterCockpits = memoizeOne((cockpits, searchTerm) => {
        searchTerm = searchTerm ? searchTerm.toUpperCase() : '';
        return searchTerm
            ? this.props.cockpits.filter(cockpit => cockpit.name.toUpperCase().includes(searchTerm))
            : this.props.cockpits;
    });

    render() {
        if (!this.state.$container) {
            return null;
        }

        const canManageCockpits = this.props.context.permissions.canManageCockpits();
        const filteredCockpits = this.filterCockpits(this.props.cockpits, this.state.searchTerm);

        return (
            <div className="cockpit-toolbar-container">
                <BngPopperWrapper
                    onOpen={this.openMenu}
                    onClose={this.closeDropdown}
                    className="cockpit-navigator-dropdown"
                    placement="bottom"
                    container={this.state.$container}
                    modifiers={{
                        preventOverflow: {
                            boundariesElement: this.state.$container,
                        }
                    }}>

                    <BngPopperButton className="cockpit-navigator-button">
                        <Icon icon={this.state.open ? 'close' : 'menu'}/>
                    </BngPopperButton>

                    <UiBlocker block={this.state.loading} style={{height: '100%'}}>
                        <div
                            className={`cockpit-dropdown-popper ${this.state.view.listView ? 'navigator-list' : 'navigator-grid'}`}>

                            <div className="cockpit-dropdown-popper-header">
                                <span style={{width: '100%'}}>{this.props.context.msg.t('cockpits')}</span>
                                <BngSearch className="absolute"
                                           onChange={this.onSearchChanged}
                                           placeholder=""
                                           name="cockpit-search"
                                />
                                <Icon className="change-view-cockpit"
                                      onClick={this.changeView}
                                      icon={this.state.view.listView ? 'view_list' : 'view_module'}/>
                            </div>

                            <div className="cockpit-navigator-body"
                                 style={!!this.state.view.preferredSize ? {width: this.state.view.preferredSize} : {}}>
                                {filteredCockpits.map(cockpit => {
                                    const currentCockpit = cockpit.id === this.props.currentCockpit?.id;
                                    return (
                                        <div key={cockpit.id}
                                             className={`cockpit-navigator-item ${cockpit.favorite ? 'favorite' : ''} ${currentCockpit ? 'selected' : ''}`}>
                                            <Icon icon={cockpit.favorite ? 'star' : 'star_border'}
                                                  onClick={(e) => this.handleFavorite(e, cockpit)}
                                                  className="cockpit-navigator-favorite-icon"
                                                  title={this.props.context.msg.t(cockpit.favorite ? 'unmake.cockpit.favorite' : 'make.cockpit.favorite')}/>
                                            <div className="cockpit-data"
                                                 title={cockpit.name}
                                                 onClick={(e) => this.loadCockpit(e, cockpit)}>
                                                <Icon type={Icon.parseType(cockpit.icon)}
                                                      className="cockpit-navigator-item-icon"
                                                      icon={cockpit.icon}/>
                                                <span className="cockpit-navigator-item-description">
                                                    {cockpit.name}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div
                                className={`cockpit-dropdown-popper-action link ${canManageCockpits ? '' : 'disabled'}`}>

                                {canManageCockpits &&
                                    <a href="#" onClick={this.manageCockpits}>
                                    <span
                                        className="cockpit-generate-book-button">{this.props.context.msg.t('manage.cockpits')}
                                    </span>
                                    </a>
                                }
                            </div>
                        </div>
                    </UiBlocker>

                </BngPopperWrapper>
            </div>
        )
    }
}

export default ContextEnhancer(BngCockpitNavigator);