import React from 'react';

import Dialog from 'components/ui/Dialog';
import useTranslation from 'components/hooks/useTranslation';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Utils from 'components/Utils';
import UiMsg from 'components/ui/UiMsg';

export default function ProjectExtractorDetailsDialog({
  className = '',
  closeModal,
  projectExtractor,
  content = '',
  title,
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      className={`ProjectExtractorDetailsDialog ${className}`}
      onClose={closeModal}
      title={`${projectExtractor.name}: ${title}`}
    >
      <BngIconButton
        icon="content_copy"
        title={t('copy.to.clipboard')}
        style={{ position: 'absolute', top: '10px', right: '55px' }}
        onClick={() => {
          Utils.copyToClipboard(content);
          UiMsg.ok(`${t('content')} ${t('copy.clipboard').toLowerCase()}`);
        }}
      />
      <Dialog.Body>
        <pre style={{ overflow: 'auto', height: `300px` }} dangerouslySetInnerHTML={{ __html: content }} />
      </Dialog.Body>
    </Dialog>
  );
}
