import styles from './DownloadAppNotification.module.css';

import React, { useEffect, useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import { localStore } from 'components/Storage';
import { BngPrimaryButton } from 'components/ui/Button';

const STORAGE_KEY = 'DownloadAppNotification:continueOnWeb';

export default function DownloadAppNotification({ onClose = _.noop }) {
  const context = useBimContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const persistedState = localStore.get(STORAGE_KEY);
    if (persistedState) {
      const date = moment(persistedState.date);
      if (date.date() === moment().date()) {
        continueOnWeb();
        return;
      }
    }
    setLoading(false);
  }, []);

  const downloadApp = () => {
    if (/Android/.test(navigator.userAgent)) {
      window.location = 'intent://bimachinemobile/#Intent;scheme=app;package=com.bimachinemobile;end';
    } else if (/iPhone | iPad | iPod/.test(navigator.userAgent)) {
      window.location = 'https://apps.apple.com/br/app/bimachine/id1402775385';
    }
  };

  const continueOnWeb = () => {
    localStore.put(STORAGE_KEY, { date: moment().toISOString() });
    onClose();
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <div className={styles.backdrop} />
      <div className={`DownloadAppNotification ${styles.main}`}>
        <span className={`${styles.textVertical}`}>{context.msg.t('login.download.app.message')}</span>
        <BngPrimaryButton onClick={downloadApp} className="mt-5 w-100">
          {context.msg.t('login.download.app.button')}
        </BngPrimaryButton>
        <div className="flex-center-items jc-center mt-5">
          <div>
            <span className={`${styles.continueCommon} ${styles.continueOne}`}>
              {context.msg.t('login.download.app.continue.one')}
            </span>
            <span className={`${styles.continueCommon} ${styles.continueTwo} pointer`} onClick={continueOnWeb}>
              {' '}
              {context.msg.t('login.download.app.continue.two')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
