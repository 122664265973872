import React, { useEffect } from 'react';

function BngInputInt(
  { type = 'text', className = '', field, form, customChange = _.noop, autoFocus = false, maxLength = 512, ...props },
  forwardRef
) {
  const { onChange = _.noop, ...fieldProps } = field || {};
  const $inputRef = React.useRef();

  useEffect(() => {
    if (autoFocus) {
      requestAnimationFrame(() => $inputRef.current.focus());
    }
  }, [autoFocus]);

  return (
    <input
      type={type}
      className={`${className} BngInput fill-w`}
      maxLength={maxLength}
      onChange={(e) => {
        customChange(e);
        onChange(e);
      }}
      ref={(ref) => {
        $inputRef.current = ref;
        if (forwardRef) {
          forwardRef.current = ref;
        }
      }}
      {...fieldProps}
      {...props}
    />
  );
}

const BngInput = React.forwardRef(BngInputInt);

export { BngInput };

export default BngInput;