import "./CentralMonitoringMenuButton.css";
import ContextEnhancer from "components/ContextEnhancer";
import MenuButton from "components/ui/navbar/MenuButton";
import React, {Component} from "react";
import Icon from "components/ui/common/Icon";
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';


class CentralMonitoringMenuButton extends Component {

    static propTypes = {};

    static defaultProps = {
        events: [],
    };

    onOpen = async () => {
        const publisherEnabled = checkAddonEnabled(AddonType.KPI_MONITORING.key, true);
        if (publisherEnabled) {
            await this.props.findMonitors()
        }
        return publisherEnabled;
    }

    render() {
        return (
            <MenuButton className={'CentralMonitoringMenuButton'}
                        icon="event"
                        onOpen={this.onOpen}
                        title={this.props.context.msg.t('central.monitoring')}
                        headerText={this.props.context.msg.t('recent.events')}>
                <li className="no-hover no-padding notificationsList">
                    <table className="notificationsTable">
                        <tbody>
                        {this.props.events.map(_event => {
                            return (
                                <tr className="notificationsTableRow" key={_event.id}>
                                    <td className={`notificationCriticityFlag ${_event.criticity.name}`}
                                        style={{background: _event.criticity.color}}>
                                        &nbsp;
                                    </td>
                                    <td>
                                        <div className="msg-container">
                                            <div className="monitorMessageDiv">
                                                <div className="monitorName">{_event.monitor.name}</div>
                                                {_event.ruleName &&
                                                <div
                                                    className="ruleDescription">{this.props.context.msg.t('rule.fired.description', _event.ruleName)}</div>
                                                }
                                                {!_event.ruleName &&
                                                <div
                                                    className="label label-w">{this.props.context.msg.t('rule.info.not.found')}</div>
                                                }
                                            </div>

                                            <div className="details-container">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="details-container-icon">
                                                                <Icon icon="access_time"/>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="details-container-label-time">
                                                                {moment(_event.eventDate).fromNow()}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <div className="menu-bottom-btn">
                        <a href={`${this.props.context.contextPath}/pages/central-monitoring/index.iface`}
                           className="btn btn-link btn-small btn-block link-color">
                            {this.props.context.msg.t('open.central.monitoring')}
                        </a>
                    </div>
                </li>

            </MenuButton>
        );
    }

}


export default ContextEnhancer(CentralMonitoringMenuButton);