import styles from './BngButtonGroup.module.css';

import React, { useMemo } from 'react';

import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import BngDropdown from 'components/bng/ui/BngDropdown';

function RadioIconRender({ className = '', selected = false, type = 'RADIO', ...props }) {
  let iconToRender = '';
  switch (type) {
    case 'RADIO': {
      iconToRender = selected ? 'radio_button_checked' : 'radio_button_unchecked';
      break;
    }
    case 'CHECKBOX': {
      iconToRender = selected ? 'check_box' : 'check_box_outline_blank';
      break;
    }
    default: {
      iconToRender = 'expand_more';
    }
  }

  return <Icon icon={iconToRender} className={`RadioIconRender ${styles.labelIcon} ${className}`} {...props} />;
}

function GroupableRadioButton({
  className = '',
  name,
  value,
  label = '',
  icon = '',
  onClick = _.noop,
  selected = false,
  title = '',
  disabled = false,
  checkbox = false,
  type = 'RADIO',
  selectedIndicator = false,
  onChange = _.noop,
  ...props
}) {
  const buttonId = useMemo(() => Utils.randomId(), []);

  return (
    <div className={`GroupableRadioButton ${styles.btnWrapper}  ${selected ? 'selected': ''} ${className}`} {...props}>
      <input
        id={buttonId}
        className={`${styles.btnInput}`}
        type={`${checkbox ? 'checkbox' : 'radio'}`}
        name={name}
        value={value}
        checked={selected}
        onChange={onChange}
        disabled={disabled}
      />
      <label
        className={`ButtonGroupLabel ${styles.labelInput} ${disabled ? styles.disabledBtn : ''}`}
        htmlFor={buttonId}
        onClick={!disabled ? onClick : undefined}
        title={title}
      >
        {icon !== '' && <Icon icon={icon} className={`${styles.labelIcon}`} />}
        {selectedIndicator && icon === '' && <RadioIconRender selected={selected} type={type} />}
        {label}
        {selectedIndicator && icon !== '' && (
          <RadioIconRender selected={selected} style={{ marginLeft: 'auto' }} type={type} />
        )}
      </label>
    </div>
  );
}

export default function BngButtonGroup({
  className = '',
  value = [],
  onChange = _.noop,
  name,
  selectedIndicator = false,
  checkbox = false,
  options = [],
  field,
  form,
  forceArray = false,
}) {
  let wrappedValue = field?.value ?? value;
  wrappedValue = _.isNil(wrappedValue) ? [] : !_.isArray(wrappedValue) ? [wrappedValue] : wrappedValue;

  let componentName = name;
  if (!componentName && field) {
    componentName = field?.name;
  }

  const handleOnChange = (val) => {
    let newValue;

    if (checkbox) {
      newValue = wrappedValue.slice();
      const idx = newValue.indexOf(val);
      if (idx === -1) {
        newValue.push(val);
      } else {
        newValue.splice(idx, 1);
      }
    } else {
      newValue = val;
    }

    if (forceArray && !Array.isArray(newValue)) {
      newValue = [newValue];
    }

    onChange(newValue);
    form?.setFieldValue(componentName, newValue);
  };

  const handleOnChangeCheckboxField = (event, name) => {
    onChange(event.target.checked);
    form.setFieldValue(name, event.target.checked);
  };

  return (
    <div className={`BngButtonGroup ${className} ${styles.switchField} gap-3`}>
      {options.map(({ key, name, id, value, label, dropdownProps, disabled, title, onChange, ...props }, idx) => {
        //When checkbox needs to be tracked as a unique field in schema
        //Otherwise it will be treated to a single array field
        const hasOwnField = !!name && checkbox;
        const fieldName = hasOwnField ? `${componentName}.${name}` : componentName;
        const isSelected = hasOwnField ? !!field.value[name] : wrappedValue.includes(value);
        const groupableButton = (groupButtonProps = {}) => (
          <GroupableRadioButton
            key={`GroupableRadioButton_${key ?? id ?? idx}`}
            name={fieldName}
            label={label}
            selected={isSelected}
            onChange={
              disabled
                ? undefined
                : onChange
                ? onChange
                : (e) => (hasOwnField ? handleOnChangeCheckboxField(e, fieldName) : handleOnChange(value, e))
            }
            selectedIndicator={selectedIndicator}
            checkbox={checkbox}
            type={dropdownProps ? 'DROPDOWN' : checkbox ? 'CHECKBOX' : 'RADIO'}
            disabled={disabled}
            title={title}
            {...groupButtonProps}
            {...props}
          />
        );

        return (
          <React.Fragment key={`GroupableRadioButton_${key ?? id ?? idx}_${disabled || 'false'}`}>
            {dropdownProps && !disabled ? (
              <BngDropdown
                popperOpts={{ placement: 'bottom-start' }}
                customButton={({ openDropdown }) =>
                  groupableButton({
                    onClick: (event) => openDropdown(event),
                  })
                }
                {...dropdownProps}
              />
            ) : (
              groupableButton()
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
