import styles from './BngCircleCheckbox.module.css';
import React from 'react';

const BngCircleCheckbox = ({
  className = '',
  type = '',
  onChange = _.noop,
  field,
  form,
  ...props
}) => {
  return (
    <div
      className={`BngCircleCheckbox ${styles.circle} ${className} ${
        field.value ? styles.active : ''
      } ${styles[type]}`}
      onClick={() => {
        const newValue = !field.value;
        if (form) {
          form.setFieldValue(field.name, newValue);
        } else {
          onChange(newValue);
        }
      }}
      {...props}
    />
  );
};

export default BngCircleCheckbox;
