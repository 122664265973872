import {reducer as formReducer} from "redux-form";

/**
 * Wrapped redux-form reducer due to existing bug on destroy form action.
 * If more than one form is active, then all data of forms are lost
 * @param state
 * @param action
 */
export default function form(state, action) {
    let newState = formReducer(state, action);
    switch (action.type) {
        case '@@redux-form/DESTROY': {
            let stateCopy = {...state};
            action.meta.form.forEach(formName => delete stateCopy[formName]);
            newState = stateCopy;
            break;
        }
    }
    return newState;
}