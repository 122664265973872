function isSameRequest(t1, t2) {
    if (t1.cacheInfo && t2.cacheInfo) {
        const sameReuest = _.isEqual(t1.cacheInfo, t2.cacheInfo);
        return sameReuest;
    }
    return false;
}

export default class RenderQueue {

    queuedIds = {};
    renderQueue = [];
    rendering = false;
    delay = 5000;

    constructor({delay = 5000}) {
        this.delay = delay;
    }

    add(task) {
        const queuedTask = this.queuedIds[task.id];
        if (queuedTask) {
            // If task already started then wait until completion to add again to queue
            if (queuedTask.running && !isSameRequest(queuedTask.task, task)) {
                queuedTask.queueAfterComplete.push(task);
                return;
            }
            // If task is not started, remove old request
            this.removeTaskId(task.id);
        }

        this.queuedIds[task.id] = {task, running: false, queueAfterComplete: []};
        this.renderQueue.push(task);
        this.triggerNext();
    }

    removeTaskId(id) {
        delete this.queuedIds[id];

        const taskIdx = this.renderQueue.findIndex(t => t.id === id);
        if (taskIdx === -1) return;

        this.renderQueue.splice(taskIdx, 1);
    }

    async triggerNext() {
        if (this.rendering) return;

        this.rendering = true;

        const delay = this.delay;

        try {
            let task = this.renderQueue.pop();
            while (!_.isEmpty(task)) {
                const taskId = task.id;
                const queueTask = this.queuedIds[taskId];
                queueTask.running = true;
                const renderPromise = task.render();
                renderPromise.finally(() => {
                    this.removeTaskId(taskId);
                    queueTask.queueAfterComplete.forEach(t => {
                        this.add(t);
                    });
                });
                const timeoutPromise = new Promise(function (resolve) {
                    setTimeout(resolve, delay, 'timeout');
                });
                await Promise.race([renderPromise, timeoutPromise]);
                task = this.renderQueue.pop();
            }
            application.dashboards.executeResizeContents();
        } finally {
            this.rendering = false;
        }
    }

}