import "components/ui/publisher/PublisherForbiddenAccess.css"
import React from "react";

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Utils from "components/Utils";
import Icon from "components/ui/common/Icon";


const PublisherForbiddenAccess = ({
                                      context,
                                      content,
                                  }) => {
    const objectLink = `${Api.baseUrl()}/load.iface?${j.param({content})}`;
    return (
        <div className="ObjectNotPublished">
            <div className="ObjectNotPublished-Body">
                <Icon icon="visibility_off"/>
                <span className="ObjectNotPublished-Message">{context.msg.t('publisher.forbidden.access')}</span>
                <div className="ObjectNotPublished-Links">
                    {Utils.Object.isObjectLink(objectLink) && application.utils.isAccessingPrivatePublisher() &&
                    <a className="ObjectNotPublished-ObjectLink" href={objectLink} target="_blank" rel="noreferrer">
                        {context.msg.t('publisher.go.configure.object')}
                    </a>
                    }
                    <a className="ObjectNotPublished-DocLink" href={context.msg.t('publisher.help.link')} target="_blank" rel="noreferrer">
                        {context.msg.t('publisher.help.link.description')}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ContextEnhancer(PublisherForbiddenAccess);