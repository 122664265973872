import styles from './ProposalActivationInfoDialog.module.css';

import React from 'react';

import Dialog from 'components/ui/Dialog';
import Utils from 'components/Utils';
import { proposalUtils } from 'components/service/bng/AccountApi';
import { PROPOSAL_STATUS } from 'components/ui/accounts/tabs/AccProposalsTab';
import useTranslation from 'components/hooks/useTranslation';

const ACTIVATION_PROPS = ['userName', 'userEmail', 'role', 'phone', 'ipAddress', 'date'];

const ProposalPropsList = ({ propsList = [], title = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.infoDetails}`}>
      <span className={styles.infoTitle}>{`${title}:`}</span>
      {ACTIVATION_PROPS.map((prop, idx) => {
        let value = propsList[prop];
        if (prop === 'date') {
          value = Utils.Date.formatDateTime(value);
        }
        return (
          <div key={idx}>
            <span className={`mr-1`}>{t(`activationProps.${prop}`)}:</span>
            <b>{value ?? '-'}</b>
          </div>
        );
      })}
    </div>
  );
};

export default function ProposalActivationInfoDialog({
  requestProps = null,
  activationProps = null,
  removalProps = null,
  proposalPricing,
  account,
  closeModal = _.noop,
}) {
  const { t } = useTranslation();

  const itemName = proposalUtils.translationsForProposalPricing(proposalPricing).name;
  const status =
    proposalPricing.proposalStatus === 'ACCEPTED'
      ? PROPOSAL_STATUS[proposalPricing.status]
      : PROPOSAL_STATUS[proposalPricing.proposalStatus];
  const agreedPricing = proposalUtils.formatPricing(
    proposalPricing.calculatedPricing,
    proposalPricing.pricing.currency.symbol
  );

  return (
    <Dialog className={`ProposalActivationInfoDialog large`} title={t('proposal.details')} onClose={closeModal}>
      <div className={styles.detailsWrapper}>
        {requestProps && requestProps.userName && (
          <ProposalPropsList propsList={requestProps} title={t('requested.by')} />
        )}

        {activationProps && activationProps.userName && (
          <ProposalPropsList
            propsList={activationProps}
            title={t(activationProps.type === 'ACCEPTED' ? 'activated.by' : 'rejected.by')}
          />
        )}

        {removalProps && removalProps.userName && (
          <ProposalPropsList propsList={removalProps} title={t('removed.by')} />
        )}
      </div>

      <div className={styles.generalInfo}>
        <div className={styles.infoItem}>
          <div className={`${styles.blueBall}`} />
          {`${t(`name`)}:`}
          <b>{itemName}</b>
        </div>
        <div className={styles.infoItem}>
          <div className={`${styles.blueBall}`} />
          {`${t(`agreed.pricing`)}:`}
          <b>{agreedPricing}</b>
        </div>
        <div className={styles.infoItem}>
          <div className={`${styles.blueBall}`} />
          {`${t(`account.name`)}:`}
          <b>{account.name}</b>
        </div>
        <div className={styles.infoItem}>
          <div className={`${styles.blueBall}`} />
          {`${t(`status`)}:`}
          <b>{t(status)}</b>
        </div>
      </div>
    </Dialog>
  );
}
