import styles from './SimpleAccTableTab.module.css';

import React, { useMemo, useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import UiBlocker from 'components/bng/ui/UiBlocker';
import Icon from 'components/ui/common/Icon';
import BngSearch from 'components/bng/ui/BngSearch';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngTable from 'components/bng/ui/BngTable';
import UiMsg from 'components/ui/UiMsg';

const filterRows = ({ filterFunc, tableSortMode, rowOrderFunc, rows, filters }) => {
  let filteredRows = filterFunc(filters, rows, tableSortMode);

  Object.entries(tableSortMode).forEach(([prop, direction]) => {
    if (direction === 'NONE') return;

    filteredRows = _.orderBy(
      filteredRows,
      [
        (row) => {
          return rowOrderFunc(row, prop);
        },
      ],
      [direction.toLowerCase()]
    );
  });

  return filteredRows;
};

export default function SimpleAccTableTab({
  rows,
  cols,
  initialFilters,
  filterFunc,
  onReload,
  emptyAlertMsg,
  filterComponent,
  loading,
  setLoading,
  headerButtonDisabled = false,
  headerButtonIcon,
  headerButtonAction = _.noop,
  headerButtonLabel,
  initialTableSort = { id: 'ASC' },
  rowOrderFunc = _.noop,
  className = '',
  extraHeaderComponents = [],
}) {
  const context = useBimContext();
  const [tableSortMode, setTableSortMode] = useState(initialTableSort);
  const [filters, setFilters] = useState(initialFilters);

  const filteredRows = useMemo(
    () =>
      filterRows({
        filterFunc,
        tableSortMode,
        rowOrderFunc,
        filters,
        rows,
      }),
    [rows, filters, tableSortMode]
  );

  const FilterComponent = filterComponent;

  return (
    <UiBlocker className={`SimpleAccTableTab ${className} h-100`} block={loading}>
      <div className={styles.tabHeader}>
        <div
          className={`${styles.leftButton} ${headerButtonDisabled && styles.disabledButton}`}
          onClick={headerButtonAction}
        >
          <Icon icon={headerButtonIcon} />
          <span className={styles.buttonLabel}>{headerButtonLabel}</span>
        </div>
        <div className={styles.headerActions}>
          {extraHeaderComponents.map((component, idx) => {
            return <React.Fragment key={idx}>{component.render({ currentRows: filteredRows })}</React.Fragment>;
          })}
          <BngSearch
            value={filters.searchTerm}
            className={styles.AccTabSearch}
            onChange={(val) => {
              setFilters({ ...filters, search: val });
            }}
            title={context.msg.t('search.object.name')}
          />
          {FilterComponent && (
            <FilterComponent
              onChange={(values) => {
                setFilters({ ...filters, filterButton: values });
              }}
              initialValues={filters.filterButton}
              rows={rows}
              filters={filters}
            />
          )}
          <BngIconButton
            icon={'autorenew'}
            onClick={async () => {
              setLoading(true);
              try {
                await onReload();
              } catch (e) {
                console.error('Error on onReload()', e);
                UiMsg.ajaxError(null, e);
              } finally {
                setLoading(false);
              }
            }}
            className={`${loading ? 'spin-element' : ''}`}
            disabled={loading}
            title={context.msg.t('refresh')}
          />
        </div>
      </div>
      <div className={styles.simpleAccTableWrapper}>
        <BngTable
          cols={cols}
          rows={filteredRows}
          stickyHeader={true}
          showEmptyAlert={true}
          emptyAlertProps={{ message: emptyAlertMsg }}
          className={styles.SimpleTable}
          rowHeight={40}
          sortHandler={(props) => {
            setTableSortMode({
              [props.col.key]: props.currentSortMode.nextMode.name,
            });
          }}
          sortMode={tableSortMode}
        />
      </div>
    </UiBlocker>
  );
}
