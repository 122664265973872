import styles from './AccessTimeRestrictionDetails.module.css';

import React, { useMemo } from 'react';

import DetailsDialog from 'components/ui/common/DetailsDialog';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';
import { buildRuleOpts } from 'components/bng/pages/project/management/tabs/AccessTimeRestrictionTab';

export default function AccessTimeRestrictionDetails({ restriction, weekDays, availableMembers = [], closeModal }) {
  const { t } = useTranslation();

  const ruleOpts = useMemo(() => buildRuleOpts(t), []);

  const userAndGroups = [
    ...restriction.props.memberRestrictions.userIds.map((id) => ({ id, type: 'user' })),
    ...restriction.props.memberRestrictions.groupIds.map((id) => ({ id, type: 'group' })),
    ...restriction.props.memberRestrictions.rules.map((id) => ({ id, type: 'rule' })),
  ];
  const exceptionsUsers = restriction.props.memberRestrictions.excludedUserIds.map((id) => ({ id, type: 'user' }));

  const createdBy = availableMembers.find((member) => member.userId === restriction.createdBy) || {};
  const updatedBy = availableMembers.find((member) => member.userId === restriction.updatedBy) || {};

  const mapWeekDaysToNames = (days) => {
    return days.map((day) => {
      const opt = weekDays.find((opt) => opt.value === day);
      return opt ? opt.label : '';
    });
  };

  const weekDaysNames = mapWeekDaysToNames(restriction.props.weekDays);

  function handlerMember(res) {
    const isUser = res.type === 'user';
    const isRule = !isUser && res.type === 'rule';
    const member = availableMembers.find((item) =>
      isUser ? item.userId === res.id : isRule ? item.id === res.id : item.groupId === res.id
    );
    return { isUser, isRule, member };
  }

  return (
    <DetailsDialog
      className={'AccessTimeRestrictionDetails'}
      createdBy={createdBy}
      createdAt={restriction.createdAt}
      updatedBy={updatedBy}
      updatedAt={restriction.updatedAt}
      closeModal={closeModal}
      titleComponent={() => {
        return (
          <div className={`TitleWrapper flex-center-items ${styles.titleWrapper}`}>
            <Icon icon="timer" className={styles.icon} />
            <div className={styles.identifier}>{t('addon.access.time.restriction.name')}</div>
          </div>
        );
      }}
      footerComponent={() => {
        return (
          <small className={styles.footer}>
            {t('AccessTimeRestrictionDetails.bottom.message')}{' '}
            <a href={'https://support.bimachine.com/sobre-a-extensao-restricao-de-acesso-data-e-hora/'} target="_blank">
              {t('know.more')}.
            </a>
          </small>
        );
      }}
    >
      <UiBlocker block={_.isEmpty(restriction)}>
        <div className={styles.containerInfos}>
          <div className={styles.flexInfos}>
            <div className={styles.lineDot}>
              <div className={styles.dot}></div>
              <label className={styles.labelInfos}>
                {t('status')}:{' '}
                <span className={styles.text}>
                  {restriction.active ? t('restriction.active') : t('restriction.disabled')}
                </span>
              </label>
            </div>
            <div className={styles.lineDot}>
              <div className={styles.dot}></div>
              <label className={styles.labelInfos}>
                {t('released.days')}: <span className={styles.text}>{weekDaysNames.join(', ')}</span>
              </label>
            </div>
          </div>
          <div className={styles.flexInfos}>
            <div className={styles.lineDot}>
              <div className={styles.dot}></div>
              <label className={styles.labelInfos}>
                {t('release.times')}:{' '}
                {restriction.props.timeRanges
                  .map((se, index) => (
                    <span key={index} className={styles.text}>
                      {t('time.interval', [se.starts, se.ends])}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ' | ', curr])}
              </label>
            </div>
          </div>
        </div>

        <div className={styles.containerInfos}>
          <div className={styles.flexInfos}>
            <div className={styles.line}>
              <div className={styles.dotBottom}>{userAndGroups.length}</div>
              <label className={styles.labelUsers}>{t('user.and.groups')}</label>
            </div>
            <hr className={styles.hr} />
            {userAndGroups.map((res, idx) => {
              const { isUser, isRule, member } = handlerMember(res);
              const ruleIcon = isRule ? ruleOpts.find((opt) => opt.id === res.id).icon : '';
              return (
                <>
                  <div className={styles.line} key={idx}>
                    <Icon
                      icon={isUser ? 'person' : isRule ? ruleIcon : 'group'}
                      style={{ fontSize: '25px', marginRight: '10px' }}
                    />
                    <label>{member.name}</label>
                  </div>
                  <hr className={styles.hr} />
                </>
              );
            })}
          </div>
          <div className={styles.flexInfos}>
            <div className={styles.line}>
              <div className={styles.dotBottom}>{exceptionsUsers.length}</div>
              <label className={styles.labelUsers}>{t('exception')}</label>
            </div>
            <hr className={styles.hr} />
            {exceptionsUsers.map((res, idx) => {
              const { isUser, member } = handlerMember(res);
              return (
                <>
                  <div className={styles.line} key={idx}>
                    <Icon icon={isUser ? 'person' : 'group'} style={{ fontSize: '25px', marginRight: '10px' }} />
                    <label>{member.name}</label>
                  </div>
                  <hr className={styles.hr} />
                </>
              );
            })}
          </div>
        </div>
      </UiBlocker>
    </DetailsDialog>
  );
}
