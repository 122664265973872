import styles from './CheckupConfirmationButtons.module.css';

import React from 'react';
import useBimContext from 'components/hooks/useBimContext';

export default function CheckupConfirmationButtons({
  className = '',
  onConfirm = _.noop,
  confirmLabel,
  onCancel = _.noop,
  cancelLabel,
}) {
  const { msg } = useBimContext();

  confirmLabel = confirmLabel ?? msg.t('confirm');
  cancelLabel = cancelLabel ?? msg.t('not.now');

  return (
    <div className={`CheckupConfirmationButtons ${styles.btnContainer} ${className}`}>
      <button type={'submit'} className={`${styles.activateButton}`} onClick={onConfirm}>
        {confirmLabel}
      </button>
      <button type={'button'} className={`${styles.notNowButton} fw-500`} onClick={onCancel}>
        {cancelLabel}
      </button>
    </div>
  );
}
