import './ConfigureLayouPublisher.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';
import { BngField } from 'components/bng/form/BngField';
import BngButton from 'components/bng/ui/BngButton';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngRadio from 'components/bng/form/BngRadio';
import BngSwitch from 'components/bng/form/BngSwitch';
import Utils from 'components/Utils';
import Api from 'components/Api';
import CodeBlock from 'components/bng/adaAi/CodeBlock';

const SNIPPETS = [
  {
    lang: 'Php',
    image: Api.buildUrl('/resources/images/php-icon.png'),
    imageClassName: 'php-icon',
    generateSnippet(link) {
      const codePhp = `\`\`\`php\\n<?php\\n$curl = curl_init();\\ncurl_setopt_array($curl, [\\n  CURLOPT_URL => "${link}",\\n  CURLOPT_RETURNTRANSFER => true,\\n  CURLOPT_ENCODING => "",\\n  CURLOPT_MAXREDIRS => 10,\\n  CURLOPT_TIMEOUT => 30,\\n  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\\n  CURLOPT_CUSTOMREQUEST => "GET",\\n  CURLOPT_HTTPHEADER => ["accept: application/json"],\\n]);\\n$response = curl_exec($curl);\\n$err = curl_error($curl);\\ncurl_close($curl);\\nif ($err) {\\n  echo "cURL Error #:" . $err;\\n} else {\\n  echo $response;\\n}\\n\`\`\``;
      return <CodeBlock message={codePhp} />;
    },
  },
  {
    lang: 'Python',
    image: Api.buildUrl('/resources/images/python-icon.png'),
    imageClassName: 'python-icon',
    generateSnippet(link) {
      const codePython = `\`\`\`python\\npython -m pip install requests\\n\\nimport requests\\n\\nurl = "${link}"\\n\\nheaders = {\"accept\": \"application/json\"}\\n\\nresponse = requests.get(url, headers=headers)\\n\\nprint(response.text)\\n\`\`\``;
      return <CodeBlock message={codePython} />;
    },
  },
  {
    lang: 'Java',
    image: Api.buildUrl('/resources/images/java-icon.png'),
    imageClassName: 'java-icon',
    generateSnippet(link) {
      const code = `\`\`\`java\\nHttpRequest request = HttpRequest.newBuilder()\\n      .uri(URI.create("${link}"))\\n      .header("accept", "application/json")\\n      .method("GET", HttpRequest.BodyPublishers.noBody())\\n      .build();\\n\\nHttpResponse<String> response = HttpClient.newHttpClient()\\n      .send(request, HttpResponse.BodyHandlers.ofString());\\n\\nSystem.out.println(response.body());\\n\`\`\``;
      return <CodeBlock message={code} />;
    },
  },
];

class ConfigureLayouPublisher extends Component {
  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    color: PropTypes.string,
    path: PropTypes.string,
    enableTable: PropTypes.bool,
    enableChart: PropTypes.bool,
    enableFixedSize: PropTypes.bool,
    enablePublisherFrame: PropTypes.bool,
  };

  static defaultProps = {
    color: '',
    path: '',
  };

  state = {
    selectedSnippetIdx: 0,
  };

  render() {
    const isAnalysisPath = Utils.Object.isAnalysis(this.props.path);

    const viewOpts = ['DEFAULT'];

    if (isAnalysisPath) {
      viewOpts.shift();
      viewOpts.push('CHART');
      viewOpts.push('TABLE');
    }

    const isBigTable = Utils.Object.isBigTable(this.props.path);
    if (isAnalysisPath || isBigTable || Utils.Object.isKpi(this.props.path) || Utils.Object.isNewMap(this.props.path)) {
      viewOpts.push('JSON');
      if (isBigTable) {
        viewOpts.push('CSV');
      }
    }

    const snippet = SNIPPETS[this.state.selectedSnippetIdx ?? 0];
    const snippetLink = `${window.location.origin}${Api.buildUrl(`/api/publisher${this.props.link}`)}`;
    return (
      <div className="ConfigureLayouPublisher publisher-tab-container">
        <div className="configColumn">
          <div className="publisher-itens-align">
            <div className="publisher-layout-title fixed-size">{this.props.context.msg.t('fixed.size')}</div>
            <div className="container-enablefixsize">
              <Field
                label=""
                onClick={this.handleChangefixedsize}
                id="pubisher-fix-size"
                name="enablefixsize"
                component={BngSwitch}
              />
            </div>
          </div>

          {this.props.enableFixedSize && (
            <div className="container-fixed-size">
              <div className="publisher-layout-title width-publisher">
                {this.props.context.msg.t('width')}
                <Field label="" name="width" type="number" className="publisher-field-size" component={BngField} />
              </div>

              <div className="fixed-size x-title">x</div>

              <div className="publisher-layout-title height-publisher">
                {this.props.context.msg.t('height')}
                <Field label="" name="height" type="number" className="publisher-field-size" component={BngField} />
              </div>
            </div>
          )}

          <div className="publisher-itens-align">
            <div className="publisher-layout-title publisher-frame">{this.props.context.msg.t('publisher.frame')}</div>
            <div className="container-enablepublisherframe">
              <Field
                label=""
                onClick={this.handleChangeframe}
                id="publisherframe1"
                name="enablepublisherframe"
                component={BngSwitch}
              />
            </div>
          </div>
          {this.props.enablePublisherFrame && (
            <div className="container-frame">
              <div className="publisher-layout-title publisher-frame-color">
                {this.props.context.msg.t('publisher.config')}
              </div>

              <div className="config-options mt-2">
                <Field
                  label=""
                  name="showtitle"
                  component={BngField}
                  title={this.props.context.msg.t('show.title')}
                  inputComponent={BngCheckbox}
                  disabled={this.props.enablePublisherFrame ? '' : 'disabled'}
                  asProps={{ label: this.props.context.msg.t('show.title') }}
                />

                <Field
                  label=""
                  rootClassName="mt-2"
                  className="container-showdateupdate"
                  name="showdateupdate"
                  component={BngField}
                  title={this.props.context.msg.t('show.update.date')}
                  inputComponent={BngCheckbox}
                  disabled={this.props.enablePublisherFrame ? '' : 'disabled'}
                  asProps={{ label: this.props.context.msg.t('show.update.date') }}
                />
              </div>
            </div>
          )}

          <div className="publisher-layout-title analysis-type">{this.props.context.msg.t('type')}</div>
          <div className="container-sharingchart">
            {viewOpts.map((opt) => {
              return (
                <Field
                  key={opt}
                  name="view"
                  component={BngField}
                  inputComponent={BngRadio}
                  asProps={{
                    label: this.props.context.msg.t(opt),
                    value: opt,
                    disabled:
                      (opt === 'CHART' && !this.props.enableChart) || (opt === 'TABLE' && !this.props.enableTable),
                  }}
                  withLabel={false}
                />
              );
            })}
          </div>
        </div>
        {this.props.values?.view === 'JSON' && (
          <div className="publisher-json-formatter">
            <div className="json-formatter-language">
              {SNIPPETS.map((snippet, idx) => (
                <BngButton
                  key={snippet.lang}
                  className={`${this.state.selectedSnippetIdx === idx ? 'selected' : ''}`}
                  onClick={() => this.setState({ selectedSnippetIdx: idx })}
                >
                  <img className={snippet.imageClassName} alt={snippet.lang} src={snippet.image} />
                  {snippet.lang}
                </BngButton>
              ))}
            </div>
            <pre className="json-formatter-path">
              {snippet.snippetComponent && <snippet.snippetComponent />}
              <div className="code-snippet">{snippet.generateSnippet(snippetLink)}</div>
            </pre>
          </div>
        )}
      </div>
    );
  }
}

export default ContextEnhancer(ConfigureLayouPublisher);
