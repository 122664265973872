import styles from './AccAppKeysTab.module.css';

import React, { useEffect, useMemo, useState } from 'react';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import SimpleAccTableTab from 'components/ui/accounts/tabs/SimpleAccTableTab';
import useBimContext from 'components/hooks/useBimContext';
import OpConfirmation from 'components/ui/OpConfirmation';
import { MODALS } from 'components/ui/redux/Actions';
import ApplicationKeyDialog from 'components/bng/accounts/ApplicationKeyDialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngIconButton from 'components/bng/ui/BngIconButton';

const filterAppKeys = (filters, rows) => {
  const { search } = filters;
  if (search) {
    rows = rows.filter((key) => {
      return (
        key.name.toLowerCase().includes(search.toLowerCase()) || key.token.toLowerCase().includes(search.toLowerCase())
      );
    });
  }

  return rows;
};

const appKeysOrder = (row, prop) => {
  const value = row[prop];
  return _.isString(value) ? value.toLowerCase() : value;
};

export default function AccAppKeysTab({ account }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [appKeys, setAppKeys] = useState();
  const [loading, setLoading] = useState(false);

  const fetchAppKeys = async () => {
    setLoading(true);
    try {
      const data = await Api.Account.findApplicationKeys(account.id);
      setAppKeys(data);
    } catch (e) {
      console.error('Error while trying to fetch appKeys', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppKeys();
  }, [account]);

  const editKey = (row = null, forceBlock = false) => {
    dispatch(
      MODALS.open(ApplicationKeyDialog, {
        applicationKey: row,
        accountId: account.id,
        forceBlock,
        afterClose: fetchAppKeys,
      })
    );
  };

  const changeBlockDomain = async (row, blockDomain = false) => {
    setLoading(true);
    row = _.cloneDeep(row);
    row.blockByDomain = blockDomain;
    try {
      delete row.account;
      row.allowedDomains.map((domain) => {
        delete domain.applicationKey;
      });
      await Api.Account.saveApplicationKey(account.id, row);
      UiMsg.ok(context.msg.t('success'), context.msg.t(`application.key.update.success`));
    } catch (e) {
      console.error('Error on changeBlockDomain()', { row, blockDomain }, e);
      UiMsg.ajaxError(null, e);
    } finally {
      fetchAppKeys();
      setLoading(false);
    }
  };

  const deleteKey = (row) => {
    OpConfirmation({
      title: context.msg.t('attention'),
      message: context.msg.t('application.key.delete.confirmation'),
      onConfirm: async () => {
        setLoading(true);
        try {
          await Api.Account.removeApplicationKey(row);
          fetchAppKeys();
          UiMsg.ok(context.msg.t('success'), context.msg.t(`application.key.delete.success`));
        } catch (e) {
          console.error('Error on onConfirmDelete()', { row }, e);
          UiMsg.ajaxError(null, e);
        } finally {
          setLoading(false);
        }
      },
      msg: context.msg,
    });
  };

  const cols = useMemo(
    () => [
      {
        key: 'name',
        label: context.msg.t('name'),
        sortable: true,
        size: 180,
        render: (row) => row.name,
      },
      {
        label: context.msg.t('token'),
        size: 180,
        render: (row) => row.token,
      },
      {
        label: context.msg.t('domains'),
        size: 180,
        render: (row) => {
          if (row.blockByDomain && !_.isEmpty(row.allowedDomains)) {
            const domains = row.allowedDomains.map((domain) => domain.domain).join(', ');
            return (
              <span title={domains} className="application-key-domains">
                {domains}
              </span>
            );
          }
          return '';
        },
      },
      {
        label: '',
        size: 90,
        render: (row) => (
          <div className="application-keys-actions">
            <BngIconButton
              icon={row.blockByDomain ? 'lock' : 'lock_open'}
              className="application-keys-action"
              onClick={() => (row.blockByDomain ? changeBlockDomain(row) : editKey(row, true))}
            />
            <BngDropdown
              popperOpts={{ placement: 'bottom-end' }}
              popperClassName={styles.AppKeyActionPopper}
              options={[
                {
                  label: context.msg.t('edit'),
                  icon: 'edit',
                  onClick: () => {
                    editKey(row);
                  },
                },
                {
                  label: context.msg.t('remove'),
                  icon: 'delete_outline',
                  onClick: () => {
                    deleteKey(row);
                  },
                },
              ]}
            />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <SimpleAccTableTab
      className={`AccAppKeysTab ${styles.AccAppKeyTab}`}
      cols={cols}
      rows={appKeys}
      filterFunc={filterAppKeys}
      initialFilters={{
        search: '',
      }}
      onReload={fetchAppKeys}
      emptyAlertMsg={context.msg.t('no.application.key')}
      loading={loading}
      setLoading={setLoading}
      headerButtonIcon={'add_circle'}
      headerButtonAction={editKey}
      headerButtonLabel={context.msg.t('new.application.key')}
      rowOrderFunc={appKeysOrder}
    />
  );
}
