import './ExportToolbarItem.css';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Api from 'components/Api';
import Utils from 'components/Utils';
import ContextEnhancer from 'components/ContextEnhancer';
import UiMsg from 'components/ui/UiMsg';

import CopyLinkToClipboard from 'components/ui/CopyLinkToClipboard';
import BngDropdown from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import BngIconButton from 'components/bng/ui/BngIconButton';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import AddonInfoDialog from 'components/ui/navbar/addons/AddonInfoDialog';
import BngExportDialog from 'components/bng/exportScheduling/BngExportDialog';
import BimEventBus from 'BimEventBus';

class ExportToolbarItem extends PureComponent {
  static propTypes = {
    path: PropTypes.string.isRequired,
    containItems: PropTypes.bool,
    isNotAnalysisPersisted: PropTypes.bool,
    caption: PropTypes.string,
    currentObject: PropTypes.object,
    filters: PropTypes.array,
    onExportToCsv: PropTypes.func,
    onExportToPdf: PropTypes.func,
    bigTableState: PropTypes.object,
    exportState: PropTypes.any,
  };

  static defaultProps = {
    path: '',
    containItems: true,
    isNotAnalysisPersisted: false,
    caption: '',
    currentObject: {},
    filters: [],
    onExportToCsv: null,
    onExportToPdf: null,
    bigTableState: {},
    exportState: undefined,
  };

  state = {
    excelLink: '#',
    linkDialog: { open: false },
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { path, disabled } = this.props;
    let excelLink = '#';

    if (!disabled && Utils.Object.isAnalysis(path)) {
      excelLink = await Api.Bng.getExportExcelLink(path);
    }
    this.setState({ excelLink });
  }

  exportCurrentObject = async (event) => {
    if (this.props.disableExportToPdf) {
      UiMsg.warn(this.props.context.msg.t('bigtable.not.able.to.export'));
      return;
    }

    event.preventDefault();

    const defaultHandler = async () => {
      await Api.Export.exportCurrentObjectUrl({
        content: this.props.path,
        analysisNotPersisted: this.props.isNotAnalysisPersisted,
        object: this.props.currentObject,
        ...this.props.bigTableState,
        onErrorExport: async (error = false) => {
          if (error) {
            console.log(error);
            UiMsg.error(null, error);
          }
        },
      });
    };
    if (this.props.onExportToPdf) {
      await this.props.onExportToPdf({ defaultHandler, event });
    } else {
      await defaultHandler();
    }
  };

  shareElement = async (event, messagingType, shareMode = true) => {
    event.preventDefault();
    const { dispatch, path, caption, filters } = this.props;
    if (!shareMode && !this.__scheduleAlertShown) {
      UiMsg.warn(this.props.context.msg.t('schedule.sending.alert'));
      this.__scheduleAlertShown = true;
    }
    dispatch(
      MODALS.open(BngExportDialog, {
        contents: [
          {
            name: caption,
            path,
            exportType: Utils.Scheduling.EXPORT_TYPE.PDF,
          },
        ],
        messageType: messagingType,
        schedulingType: shareMode
          ? Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE
          : Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED,
        shareOpts: {
          enabled: shareMode,
          params: shareMode
            ? {
                content: path,
                analysisNotPersisted: this.props.isNotAnalysisPersisted,
                filters,
                exportState: this.props.exportState,
                ...this.props.bigTableState,
              }
            : {},
        },
      }),
    );
  };

  openEnableDialog = () => {
    this.props.dispatch(
      MODALS.open(AddonInfoDialog, {
        addonKey: AddonType.WHATS_APP.key,
      }),
    );
  };

  buildCustomOptions = () => {
    const { shareElement, exportCurrentObject } = this;
    const { context, path, isNotAnalysisPersisted, onExportToCsv, disableExportToPdf } = this.props;
    const { excelLink } = this.state;

    return [
      {
        render() {
          return (
            <div className={'export-header-dropdown'}>
              <span>{context.msg.t('share')}</span>
              <hr style={{ width: '100%' }} />
            </div>
          );
        },
        shouldRender: true,
      },
      {
        render() {
          return (
            <a
              href="#"
              className={`unstyled-link ${isNotAnalysisPersisted ? 'disabled' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                if (isNotAnalysisPersisted) {
                  return;
                }
                shareElement(e, 'EMAIL', false);
              }}
            >
              <Icon icon="schedule_send" /> {context.msg.t('schedule.sending')}
            </a>
          );
        },
      },
      {
        render() {
          return (
            <a
              href="#"
              className={`unstyled-link`}
              onClick={(e) => !checkAddonEnabled(AddonType.WHATS_APP.key, true) || shareElement(e, 'WHATSAPP')}
            >
              <Icon icon="fa fa-whatsapp" /> {context.msg.t('share.whatsapp')}
            </a>
          );
        },
        shouldRender: !isNotAnalysisPersisted,
      },
      {
        render() {
          return (
            <a href="#" className="unstyled-link" onClick={(e) => shareElement(e, 'EMAIL')}>
              <Icon icon="email" /> {context.msg.t('share.email')}
            </a>
          );
        },
        shouldRender: !isNotAnalysisPersisted,
      },
      {
        render() {
          return (
            <a
              href="#"
              title={context.msg.t('is.coming')}
              className="unstyled-link disabled"
              onClick={(e) => e.preventDefault()}
            >
              <Icon icon="fa fa-telegram" /> {context.msg.t('share.telegram')}
            </a>
          );
        },
        shouldRender: !isNotAnalysisPersisted,
      },
      {
        render() {
          return (
            <a
              href="#"
              className="unstyled-link"
              onClick={(e) => {
                BimEventBus.emit('BngCockpitButtons:MetadataExport');
              }}
            >
              <Icon icon="import_export" /> {context.msg.t('export.json')}
            </a>
          );
        },
        shouldRender: Utils.Project.isBimachineEmail(context.user.email) && Utils.Object.isDashboard(path),
      },
      {
        render() {
          return (
            <a
              href="#"
              onClick={exportCurrentObject}
              className={`unstyled-link ${disableExportToPdf ? 'disabled' : ''}`}
              title={disableExportToPdf ? context.msg.t('bigtable.not.able.to.export') : null}
            >
              <Icon icon="description" /> {context.msg.t('export_pdf')}
            </a>
          );
        },
        shouldRender: true,
      },
      {
        render() {
          return (
            <a href={excelLink} className="unstyled-link" target="_blank">
              <Icon icon="grid_on" /> {context.msg.t('export_excel')}
            </a>
          );
        },
        shouldRender: Utils.Object.isAnalysis(path),
      },
      {
        render() {
          return (
            <a href="#" className="unstyled-link" onClick={onExportToCsv}>
              <Icon icon="grid_on" /> {context.msg.t('export_csv')}
            </a>
          );
        },
        shouldRender: !!onExportToCsv,
      },
      {
        render() {
          return <CopyLinkToClipboard buttonStyle="ExportButtonLink" linkToCopy={path} objectLink={true} />;
        },
        shouldRender: !isNotAnalysisPersisted,
      },
    ].filter((e) => e.shouldRender ?? true);
  };

  render() {
    const { disabled, containItems, context } = this.props;
    return (
      <BngDropdown
        className={`ExportToolbarItem toolbox-button relative ${disabled ? 'Disabled' : ''}`}
        popperClassName="BngOptions export-toolbar dropdown-links"
        customButton={(props) => {
          return (
            <div
              className="share-button"
              title={context.msg.t(containItems ? 'analysis.hint.export' : 'export.blocked')}
            >
              <BngIconButton
                icon={'share'}
                className={'selected'}
                onClick={(event) => (!disabled ? props.openDropdown(event) : null)}
                text={context.msg.t('share')}
              />
            </div>
          );
        }}
        customOptions={() => (
          <ul className={'ul-elements-list'}>
            {this.buildCustomOptions().map((option, idx) => {
              const { render, className } = option;
              return (
                <li key={idx} className={`li-element ${className}`}>
                  {render()}
                </li>
              );
            })}
          </ul>
        )}
      />
    );
  }
}

export default connect((state) => ({
  bigTableState: {
    bigtableFilterModel: state.bigTable.data.bigtableFilterModel,
    bigtableSortModel: state.bigTable.data.bigtableSortModel,
  },
}))(ContextEnhancer(ExportToolbarItem));
