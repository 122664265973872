import 'components/ui/dashboard/components/itemMenus/DashboardItemMenuExplorerMobile.css';

import React from 'react';
import PropTypes from 'prop-types';

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Utils from "components/Utils";
import {BngDropdown} from "components/bng/ui/BngDropdown";
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import DashboardItemMenuLastDatasourceUpdate
    from "components/ui/dashboard/components/itemMenus/DashboardItemMenuLastDatasourceUpdate";
import {BngIconButton} from "components/bng/ui/BngIconButton";
import FilterService from "components/filter/FilterService.js";

class DashboardItemMenuExplorerMobile extends React.Component {
    static propTypes = {
        item: PropTypes.object,
        dashboardPath: PropTypes.string,
        selectedFilters: PropTypes.array
    };

    static defaultProps = {
        item: {},
        dashboardPath: '',
        selectedFilters: [],
    };

    state = {
        open: false,
        position: {x: 0, y: 0},
        visible: false
    };

    constructor(props) {
        super(props);
        this._refs = {};
    }

    getBoundaryElement = () => {
        return j('#dashboard-wrapper')[0];
    };

    openItemLink = async (path, value = '') => {
        if (Api.ReactNative.hasWebView()) {
            const itemFilters = JSON.parse(this.props.item.filters)
                .concat(this.props.selectedFilters)
                .filter(f => f.members.length > 0);
            const filterRef = await FilterService.createFilterRef(itemFilters);
            const message = {
                action: 'openObject',
                data: path,
                forcefilters: true,
                filterRef,
            }
            Api.ReactNative.postMessage(JSON.stringify(message));
        } else {
            window.location.href = Api.loadObjectUrl({
                content: path,
                height: window.innerHeight,
                width: window.innerWidth,
                breadcrumb: false,
                forcefilters: true,
                chart: value !== 'showTable',
                filter: this.props.item.filters,
            });
        }
    };

    renderComponent = (link, i) => {
        if (Utils.Object.isAnalysis(link.path)) {
            let keys = Object.keys(link.props);
            keys = keys.filter(e => link.props[e]);
            return (<div key={i}>
                {keys.map((value, idx) => {
                    return (
                        <li key={idx + value}>
                            <DashboardItemMenuTitle
                                title={`${link.caption || this.props.item.caption}`}
                                onClick={() => {
                                    this.openItemLink(link.path, value)
                                }}
                                icon={Utils.Object.getObjectIcon(link.path, value)}
                                more={false}
                                value={value}
                            />
                        </li>
                    )
                })}
            </div>)
        }

        return (
            <li key={i}>
                <DashboardItemMenuTitle title={link.caption || this.props.item.caption}
                                        onClick={() => {
                                            this.openItemLink(link.path)
                                        }}
                                        icon={Utils.Object.getObjectIcon(link.path)}
                                        more={false}/>
            </li>
        )
    };

    updateState = () => {
        this.setState({open: false});
    };

    closeOthersMenus = (event, refId) => {
        Object.keys(this._refs).forEach(ref => {
            if (ref !== refId) {
                let component = this._refs[ref].wrappedComponent;
                !!component && !!component.forceClose && component.forceClose();
            }
        });
    };

    verifyPosition = () => {
        let {x, y} = this.exploreMenu.getBoundingClientRect();
        this.setState({
            position:{x: x, y: y}
        });
    }

    defineStyles = () => {
        let style = '';
        if(this.state.position.x > window.innerWidth/2 - 40) style += ' prevent-children-overflow';
        if(this.state.position.y > window.innerHeight/2 - 20) style += ' prevent-children-overflow-top';
        return style;
    }

    renderMenuItems = () => {
        const availablesMenus = _.filter(this.menuItems(), menuItem => !menuItem.shouldNotRender({...this.props}));
        return (
            availablesMenus.map(menuItem => {
                const {Component, key, additionalProps} = menuItem;
                return (
                    <Component ref={(el) => {this._refs[key] = el }}
                               key={key}
                               onOpen={(e) => this.closeOthersMenus(e, key)}
                               {...additionalProps}
                               {...this.props}
                               isMobile={true}
                               orientation={window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'}
                               position={this.state.position}

                    />
                );
            })
        )
    };

    handleClick = () => {
        this.setState(prevState => ({ visible: !prevState.visible }));
    };

    render() {
        const {open, visible} = this.state;
        if (this.props.item.isText) return null;

        const isAnalysis = Utils.Object.isAnalysis(this.props.item.path);
        const showDrillButtons = isAnalysis && (this.props.item.additionalProps.isDrillableChartModel ?? false);
        const showBubbleChartButtons = isAnalysis && (this.props.item.additionalProps.isEchartsModel ?? false);

        return (
            <div ref={el => this.exploreMenu = el}
                 className={`dash-item-newmenu menu-mobile`}
                 data-item-id={this.props.item.id}
                 style={{ opacity: visible ? 1 : 0 }}
                 onClick={this.handleClick}>
                <div className="drop-button dash-item-newmenu-drop-button">
                    <BngDropdown boundariesElement={this.getBoundaryElement()}
                                 popperOpts={{placement: "bottom-start"}}
                                 closeOnSelect={false}
                                 onOpen={this.verifyPosition}
                                 onClose={this.updateState}
                                 className={`DashboardItemMenuDropdown ${open ? 'active' : ''} ${showDrillButtons ? 'withDrillButtons' : ''}`}
                                 popperClassName={`popper-mobile dashboard-item-popper`}
                                 customButton={({openDropdown}) => {
                                     return (
                                       <div className="MenuButtonsContainer">
                                         {showDrillButtons && <div className="DrillButtonsContainer" />}
                                         {showBubbleChartButtons && <div className="EChartsButtonsContainer" />}
                                         <BngIconButton icon="more_vert" onClick={openDropdown} />
                                       </div>
                                     );
                                 }}
                                 customOptions={({closeDropdown}) => (
                                    <ul className={`bng-dropdown container-dash-item-newmenu ${this.defineStyles()}`}>
                                        {this.props.item.itemLinks.map(this.renderComponent)}
                                        {this.renderMenuItems()}
                                    </ul>
                                 )}/>
                </div>
            </div>
        )
    }

    menuItems = () => {
        return([
            {
                key: 'DashboardItemMenuLastDatasourceUpdate',
                Component: DashboardItemMenuLastDatasourceUpdate,
                shouldNotRender: ({editMode, item}) => (editMode || item.isText),
            }
        ]);
    }
}

export default ContextEnhancer(DashboardItemMenuExplorerMobile);