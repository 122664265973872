import './BngField.css';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BngInput } from 'components/bng/form/BngInput';
import { ceData } from 'components/CeData';
import HelpIcon from 'components/ui/common/HelpIcon';

export function BngField({
  label = BngField.LABEL_DEFAULT,
  withLabel = true,
  inputComponent = BngInput,
  inputRef,
  asProps = {},
  showErrors = true,
  showErrorClass = true,
  children,
  required = false,
  rootClassName = '',
  inline = false,
  rootProps = {},
  labelClassName = '',
  labelProps = {},
  labelOnRight = false,
  hint = '',
  floatingLabel = false,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const InputComponent = inputComponent;
  const fieldName = _.get(props, 'field.name', props.name);

  const errorMessage = _.get(props, `form.errors.${fieldName}`, false);
  const addErrorClass =
    showErrorClass &&
    errorMessage &&
    (_.get(props, `form.touched.${fieldName}`, false) || _.get(props, `form.submitCount`, 0) > 0);
  const showErrorContainer = !!fieldName && !props.disabled && showErrors;
  const showErrorMessage = showErrorContainer && addErrorClass;

  if (label === BngField.LABEL_DEFAULT && fieldName) {
    label = ceData.context.msg.t(fieldName);
  }

  const value = props.field?.value ?? props.value;
  const onBlur = props.field?.onBlur ?? props.onBlur ?? _.noop;
  const onFocus = props.field?.onFocus ?? props.onFocus ?? _.noop;

  return (
    <div
      className={`BngField control-group 
        ${rootClassName} 
        ${required ? 'Required' : ''} 
        ${addErrorClass ? 'error' : ''} 
        ${inline ? 'Inline' : ''}
        ${floatingLabel ? 'floatingLabel' : ''}
        ${focused ? 'focused' : ''}
        ${value ? 'withValue' : ''}
        `}
      {...rootProps}
    >
      {withLabel && (
        <label
          className={`control-label flex-center-items ${labelClassName} ${labelProps.title ? 'cursor-help' : ''} ${
            labelOnRight ? 'fo-1' : ''
          }`}
          {...labelProps}
        >
          {label !== BngField.LABEL_EMPTY ? label : <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
          {hint && <HelpIcon title={hint} />}
        </label>
      )}
      <div className="controls">
        {InputComponent && (
          <InputComponent
            ref={inputRef}
            onFocus={(e) => {
              setFocused(true);
              onFocus(e);
            }}
            onBlur={(e) => {
              setFocused(false);
              onBlur(e);
            }}
            {...props}
            {...asProps}
          />
        )}
        {children}
        {showErrorContainer && (
          <div>
            {showErrorMessage && (
              <span
                className="help-inline"
                dangerouslySetInnerHTML={{ __html: ceData.context.msg.t(errorMessage) }}
              ></span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

BngField.propTypes = {
  label: PropTypes.any,
  withLabel: PropTypes.bool,
  inputComponent: PropTypes.any,
  asProps: PropTypes.object,
  showErrors: PropTypes.bool,
  required: PropTypes.bool,
  rootClassName: PropTypes.string,
  inline: PropTypes.bool,
  rootProps: PropTypes.object,
};

BngField.LABEL_DEFAULT = -1;
BngField.LABEL_EMPTY = -2;

export default BngField;
