import './ReduxFormSlider.css';
import React from 'react';
import PropTypes from 'prop-types';

export class ReduxFormSlider extends React.Component {

    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        showValue: PropTypes.bool,
    };

    static defaultProps = {
        style: {},
        className: '',
        min: 0,
        max: 100,
        step: 1,
        showValue: true,
    };

    render() {
        const {className, showValue, ...props} = this.props;
        return (
            <div className={`ReduxFormSlider ${className}`}>
                <input {...props}
                       type="range"
                />
                {showValue &&
                <span className={'value'}>{_.isNumber(props.value) ? props.value : '-'}</span>
                }
            </div>
        );
    }

}