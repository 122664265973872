import styles from './AccessTimeRestrictionTab.module.css';

import React, { useMemo } from 'react';

import { MODALS } from 'components/ui/redux/Actions';
import BngTable from 'components/bng/ui/BngTable';
import BngDropdown from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useTranslation from 'components/hooks/useTranslation';
import AccessTimeRestrictionFormDialog from 'components/bng/pages/project/management/tabs/accessTimeRestriction/AccessTimeRestrictionFormDialog';
import AccessTimeRestrictionLogsDialog from 'components/bng/pages/project/management/tabs/accessTimeRestriction/AccessTimeRestrictionLogsDialog';
import AccessTimeRestrictionDetails from 'components/bng/pages/project/management/tabs/accessTimeRestriction/AccessTimeRestrictionDetails';
import Badge from 'components/ui/Badge';
import LinkedObjects from 'components/ui/LinkedObjects';
import Avatar from 'components/ui/Avatar';
import UiMsg from 'components/ui/UiMsg';
import OpConfirmation from 'components/ui/OpConfirmation';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import useFetchData from 'components/hooks/useFetchData';
import { fetchMembersForOptions } from 'components/bng/exportScheduling/BngExportRecipientsTab';

export const buildRuleOpts = (t) => {
  const type = 'RULE';

  return [
    {
      id: 'ALL',
      name: t('all_users_selection'),
      icon: 'groups',
      type,
    },
    {
      id: 'ADMINISTRATORS',
      name: t('administrator_selection'),
      icon: 'security',
      type,
    },
    {
      id: 'EXPERTS',
      name: t('expert_selection'),
      icon: 'auto_fix_high',
      type,
    },
    {
      id: 'EXPLORERS',
      name: t('explorer_selection'),
      icon: 'saved_search',
      type,
    },
    {
      id: 'VIEWERS',
      name: t('viewer_selection'),
      icon: 'visibility',
      type,
    },
  ];
};

export default function AccessTimeRestrictionTab() {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const context = useBimContext();

  const ruleOpts = useMemo(() => buildRuleOpts(t), []);

  const { data: restrictions = [], reload } = useFetchData(async () => {
    try {
      return await Api.AccessTimeRestriction.findAll(context.project.id);
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  });

  const { data: availableMembers = [] } = useFetchData(async () => {
    try {
      const fetchedMembers = await fetchMembersForOptions({
        projectId: context.project.id,
        msg: context.msg,
      });

      ruleOpts.slice().reverse().forEach((option) => {
        fetchedMembers.unshift(option);
      });
      return fetchedMembers;
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  });

  const weekDays = useMemo(() => {
    const arr = [];
    for (let i = 1; i < 8; i++) {
      arr.push(t(`dayOfTheWeek.${i}`));
    }

    return [
      { value: 0, label: arr[6] },
      { value: 1, label: arr[0] },
      { value: 2, label: arr[1] },
      { value: 3, label: arr[2] },
      { value: 4, label: arr[3] },
      { value: 5, label: arr[4] },
      { value: 6, label: arr[5] },
    ];
  }, []);

  const editRestriction = (row) => {
    dispatch(
      MODALS.open(AccessTimeRestrictionFormDialog, {
        restriction: row,
        availableMembers,
        reloadRestrictions: reload,
      })
    );
  };

  const tableColumns = useMemo(() => {
    return [
      {
        key: 'icon',
        label: '',
        render: (row) => <Icon icon={row.active ? 'lock' : 'lock_open'} className={styles.lockIcons} />,
      },
      {
        key: 'days',
        label: t('released.days'),
        render: (row) => {
          return (
            <div className="div-information w-100">
              <span className="item-slide-name w-100">
                <LinkedObjects
                  itemWidth={40}
                  itemNumber={7}
                  items={weekDays}
                  render={(item, index) => {
                    return (
                      <div
                        key={index}
                        className={`${
                          row.props.weekDays.includes(item.value) ? styles.releasedDays : styles.restrictionDays
                        } ${styles.day}`}
                        title={item.label}
                      >
                        {item.label.substring(0, 1)}
                      </div>
                    );
                  }}
                />
              </span>
            </div>
          );
        },
        rowClassName: styles.days,
      },
      {
        key: 'schedules',
        label: t('release.times'),
        render: (row) => {
          return row.props.timeRanges.map((se, index) => (
            <span key={index}>
              {t('time.interval', [se.starts, se.ends])}
              <br />
            </span>
          ));
        },
      },
      {
        key: 'userAndGroups',
        label: t('user.and.groups'),
        render: (row) => {
          const usersAndGroups = [
            ...row.props.memberRestrictions.userIds.map((id) => ({ id, type: 'user' })),
            ...row.props.memberRestrictions.groupIds.map((id) => ({ id, type: 'group' })),
            ...row.props.memberRestrictions.rules.map((id) => ({ id, type: 'rule' })),
          ];

          return (
            <div className="div-information-user w-100">
              <span className="user-item-slide-name w-100">
                <LinkedObjects
                  itemWidth={50}
                  items={usersAndGroups}
                  render={(item) => {
                    const isUser = item.type === 'user';
                    const isRule = item.type === 'rule';
                    const ruleIcon = isRule ? ruleOpts.find((opt) => opt.id === item.id)?.icon : '';
                    return (
                      <div
                        key={`${isUser ? 'GROUP' : 'USER'}-${item.id}`}
                        className={`${styles.userAvatarOuter} ${!isUser ? styles.groupIcon : ''}`}
                      >
                        {isUser ? (
                          <Avatar userId={item.id} className={styles.userAvatar} />
                        ) : isRule ? (
                          <Icon icon={ruleIcon} style={{ fontSize: '25px' }} />
                        ) : (
                          <Icon icon="group" style={{ fontSize: '25px' }} />
                        )}
                      </div>
                    );
                  }}
                />
              </span>
            </div>
          );
        },
        rowClassName: styles.userAndGroups,
      },
      {
        key: 'status',
        label: t('status'),
        sortable: true,
        render: (row) => {
          const isActive = row.active;
          return (
            <Badge
              value={isActive ? t('enabled') : t('inactive')}
              color={isActive ? 'badge-success' : 'badge-warning'}
            />
          );
        },
      },
      {
        key: 'action',
        label: t('action'),
        render: (row) => {
          const isActive = row.active;
          return (
            <div>
              <BngDropdown
                popperOpts={{ placement: 'bottom-end' }}
                overDialog
                options={[
                  {
                    label: isActive ? t('disable.restriction') : t('enable.restriction'),
                    icon: isActive ? 'lock_open' : 'lock',
                    onClick: async () => {
                      try {
                        await Api.AccessTimeRestriction.toggleActiveStatus(row.id);
                        await reload();
                      } catch (e) {
                        console.error('Error on onActivate', e);
                        UiMsg.ajaxError(null, e);
                      }
                    },
                  },
                  {
                    label: t('detailing'),
                    icon: 'info',
                    onClick: () =>
                      dispatch(
                        MODALS.open(AccessTimeRestrictionDetails, {
                          restriction: row,
                          weekDays,
                          availableMembers,
                        })
                      ),
                  },
                  {
                    label: t('edit_button'),
                    icon: 'edit',
                    onClick: () => editRestriction(row),
                  },
                  {
                    label: t('delete_button'),
                    icon: 'close',
                    onClick: () =>
                      OpConfirmation({
                        title: t('attention'),
                        html: t('delete.access.restriction'),
                        onConfirm: async () => {
                          try {
                            await Api.AccessTimeRestriction.delete(row.id);
                            UiMsg.ok(t('remove_success', t('release.rules')));
                            await reload();
                          } catch (e) {
                            console.error('Error while trying to delete a access time restriction', e);
                            UiMsg.ajaxError(null, e);
                          }
                        },
                        confirmText: t('delete'),
                        reverseButtons: true,
                      }),
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [availableMembers]);

  return (
    <div className={`AccessTimeRestrictionTab ${styles.AccessTimeRestrictionTab}`}>
      <div className={`${styles.warningBadge}`}>
        <Icon icon={'warning'} className={`${styles.warningIcon}`} />
        <span>{t('AccessTimeRestrictionDetails.bottom.message')}</span>
      </div>
      <h5>{t('release.rules')}</h5>
      <div className={`${styles.containerRuleLog}`}>
        <BngIconButton
          icon="add_circle"
          text={t('create.release.rule')}
          className={styles.buttonNewRule}
          iconProps={{ style: { marginRight: '5px' } }}
          onClick={() => editRestriction()}
        />
        <BngIconButton
          icon={'description'}
          className={styles.buttonLog}
          onClick={() => {
            dispatch(MODALS.open(AccessTimeRestrictionLogsDialog, { availableMembers }));
          }}
        />
      </div>
      <div className={`${styles.containerTable}`}>
        <BngTable rows={restrictions} cols={tableColumns} />
      </div>
    </div>
  );
}
