import "./NonEmptyAlertPopup.css";
import React from "react";

import BngPopper from "components/bng/ui/BngPopper";
import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";

export default ContextEnhancer(({ context, closePopup = _.noop }) => {
  const $parent = document.querySelector(".HideCleanRowsBtn");
  return (
    <BngPopper
      open={true}
      anchorEl={$parent}
      placement="bottom-start"
      className="NonEmptyAlertPopup"
    >
      <div className="ArrowUp"></div>
      <div className="Body">
        <div className="WarningBg">
          <span className="text-warning">
            {context.msg.t("non.empty.alert.popup.message")}
          </span>

          <div className="text-right">
            <Button className="btn-small btn-warning" onClick={closePopup}>
              Fechar
            </Button>
          </div>
        </div>
      </div>
    </BngPopper>
  );
});
