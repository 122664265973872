import styles from './TicketListDialog.module.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import ContextEnhancer from 'components/ContextEnhancer';
import { BngSelect } from 'components/bng/form/BngSelect';
import { ScrollContainer } from 'components/ui/ScrollContainer';
import Button from 'components/ui/Button';
import Api from 'components/Api';
import { BngTable } from 'components/bng/ui/BngTable';
import { FormikListener } from 'components/bng/form/formik/FormikListener';
import { bngYup } from 'components/bng/form/yup/BngYup';
import UiMsg from 'components/ui/UiMsg';
import TicketViewDialog from 'components/bng/support/TicketViewDialog';
import BngSearch from 'components/bng/ui/BngSearch';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { UiBlocker } from 'components/bng/ui/UiBlocker';

const FilterSchema = bngYup((yup) =>
  yup.object().shape({
    classification: yup.string().default(''),
    priority: yup.string().default('0'),
    status: yup.string().default(''),
    search: yup.string().default(''),
  })
);

const BypassComponent = ({className = '', children}) => {
    return (
        <div className={className}>
            {children}
        </div>
    );
}

class TicketListDialog extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        closeModal: PropTypes.func,
        mobile: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        closeModal: _.noop,
        mobile: false,
    };

    state = {
        tickets: [],
        filteredTickets: [],
        categories: [
            {value: '', label: this.props.context.msg.t('tomticket.category.classification')}
        ],
        loading: true,
        ticketViewData: null,
    };

    supportPriorityOpts = [
        {value: '0', label: this.props.context.msg.t('priority')},
        {value: '1', label: this.props.context.msg.t('priority.low')},
        {value: '2', label: this.props.context.msg.t('priority.normal')},
        {value: '3', label: this.props.context.msg.t('priority.high')},
        {value: '4', label: this.props.context.msg.t('priority.urgent')},
    ];

    statusOpts = [
        {value: '', label: this.props.context.msg.t('status')},
        {value: "Aberto", label: this.props.context.msg.t('tomticket.status.aberto')},
        {value: "Aguardando", label: this.props.context.msg.t('tomticket.status.aguardando')},
        {value: "Finalizado", label: this.props.context.msg.t('tomticket.status.finalizado')},
    ];

    filterInitialValues = FilterSchema.default();

    async componentDidMount() {
        try {
            let projectName = '';
            if (this.props.mobile) {
               projectName = this.props.support.projectName;
            } else {
                projectName = this.props.context.support.projectName;
            }
            const ticketResponse = await Api.Bng.findSupportTickets(projectName);

            const categories = ticketResponse.categories;

            categories.map((e) => e.label = this.props.context.msg.t(`tomticket.category.${_.camelCase(e.label)}`))
            categories.unshift({value: '', label: this.props.context.msg.t('tomticket.category.classification')});

            const parseDate = (str) => {
                if (_.isEmpty(str)) {
                    return str;
                }
                return moment(str);
            };

            ticketResponse.tickets.forEach(t => {
                t.ticket.data_criacao = parseDate(t.ticket.pdata_criacao);
                t.ticket.dataultimasituacao = parseDate(t.ticket.pdataultimasituacao);
                t.ticket.dataultimostatus = parseDate(t.ticket.pdataultimostatus);
            });
            this.setState({
                tickets: ticketResponse.tickets,
                filteredTickets: ticketResponse.tickets,
                categories
            });

        } catch (e) {
            console.error(e);
            Api.ReactNative.postMessage(JSON.stringify({
                action: 'log',
                data: {e}
            }));
            UiMsg.ajaxError(null, e);
            this.props.closeModal();
        } finally {
            this.setState({loading: false});
        }
    }

    queryTickets = (next, current) => {
        if (current.values == next.values) {
            return;
        }

        let {classification, priority, status, search = ''} = next.values;
        search = search.toLowerCase();
        const filteredTickets = this.state.tickets.filter(row => {
            if (priority !== '0' && '' + row.ticket.prioridade !== priority) {
                return false;
            } else if (status !== '' && status !== row.statusDescription) {
                return false;
            } else if (classification !== '' && classification !== row.ticket.categoria) {
                return false;
            } else if (search !== '' && !(`${row.ticket.titulo.toLowerCase()}${row.ticket.protocolo}`).includes(search)) {
                return false;
            }
            return true;
        });

        this.setState({filteredTickets});
    };

    filterSearch = (filter) => {
        if (filter.length === 0) this.setState({filteredTickets: this.state.tickets})
        this.setState({
            filteredTickets: this.state.tickets.filter((
                {ticket}) => ticket.titulo.toLowerCase().includes(filter.toLowerCase()) || (ticket.protocolo + '').includes(filter)
            )
        });
    }

    columnsForVisualization = () => {
        const columns = [];
        columns.push(
            {
                label: this.props.context.msg.t('apps_id'),
                size: '70px',
                render: row => row.ticket.protocolo
            },
            {
                label: this.props.context.msg.t('title'),
                size: this.props.mobile ? '100%' : '220px',
                render: row => row.ticket.titulo,
                rowClassName: `${styles.titleCol}`
            },
        );
        if (!this.props.mobile) {
            columns.push(
                {
                    label: this.props.context.msg.t('support.classification'),
                    size: '205px',
                    render: row => this.props.context.msg.t(`tomticket.category.${_.camelCase(row.ticket.categoria)}`)
                },
                {
                    label: this.props.context.msg.t('priority'),
                    size: '80px',
                    render: row => (
                        <div
                            style={{
                                width: 80,
                                fontSize: 12,
                                fontWeight: 'normal',
                                textAlign: 'center'
                            }}
                            className={`label label-${row.priorityColor}`}>{row.priorityDescription}</div>
                    )
                },
                {
                    label: this.props.context.msg.t('status'),
                    size: '70px',
                    render: row => (
                        <div>
                            <div
                                style={{
                                    width: 70,
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    textAlign: 'center'
                                }}
                                className={`label label-${row.statusColor}`}>{this.props.context.msg.t(`tomticket.status.${_.camelCase(row.statusDescription)}`)}</div>

                            {' '}
                            {row.hasDetail &&
                                <span className="icon icon-info-sign"
                                      title={row.ticket.descsituacao}></span>
                            }
                        </div>
                    )
                },
            )
        }
        columns.push(
            {
                label: this.props.context.msg.t('date'),
                size: '160px',
                render: row => (
                    <div
                        style={{
                            width: 105,
                            fontSize: 12,
                            fontWeight: 'normal',
                            textAlign: 'center'
                        }}>
                        {row.ticket.data_criacao.format('DD/MM/YYYY HH:mm')}
                    </div>
                )
            },
            {
                label: '',
                size: '0px',
                render: row => (
                    <Button icon="icon-ticket"
                            className="btn-mini btn-table"
                            title={this.props.context.msg.t('visualize.ticket')}
                            onClick={() => {
                                this.setState({ticketViewData: row});
                            }}
                    />
                )
            }
        );
        return columns;
    }

    render() {
        const isPortrait = window.screen.availHeight > window.screen.availWidth;
        const Component = this.props.mobile ? BypassComponent : Dialog;
        const tableColumns = this.columnsForVisualization();
        return (
            <>
                <Component title={this.props.context.msg.t('support.dialog.call.title')}
                           className={`${this.props.mobile ? styles.mobileContainer : ''} xlarge`}
                           onClose={this.props.closeModal}
                           loading={this.state.loading}
                >
                    <Formik
                        initialValues={this.filterInitialValues}
                        validationSchema={FilterSchema}
                        onSubmit={this.queryTickets}>
                        <Form>
                            <FormikListener onChange={this.queryTickets}/>
                            <div className={`${styles.filterDropDownOptions} row-fluid`}>
                                <div className="span5">
                                    <Field name="search"
                                           component={BngSearch}
                                           placeholder={this.props.context.msg.t('search')}
                                           onChange={val => this.filterSearch(val)}
                                           className={'tickets-dialog-search'}
                                    />
                                </div>
                                <div className={`${this.props.mobile && isPortrait ? 'span2' : 'span1'}`}>
                                    <BngDropdown
                                        title={this.props.context.msg.t('filter')}
                                        icon={'filter_alt'}
                                        popperClassName={`${styles.filterDropDown} `}
                                        className={'tickets-filter-dropdown'}
                                        customOptions={() => {
                                            return (
                                                <div className="bng-dropdown">
                                                    <UiBlocker block={false} className={`${styles.UiBlocker}`}>
                                                        <Form>
                                                            <FormikListener onChange={this.queryTickets}/>
                                                            <div className="">
                                                                <Field name="classification"
                                                                       component={BngSelect}
                                                                       emptyOption={false}
                                                                       options={this.state.categories}
                                                                       id={'dropdown-selection'}
                                                                />
                                                                <Field name="status"
                                                                       component={BngSelect}
                                                                       emptyOption={false}
                                                                       options={this.statusOpts}
                                                                       id={'dropdown-status'}
                                                                />
                                                                <Field name="priority"
                                                                       component={BngSelect}
                                                                       emptyOption={false}
                                                                       options={this.supportPriorityOpts}
                                                                       id={'dropdown-priority'}
                                                                />
                                                            </div>
                                                        </Form>
                                                    </UiBlocker>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </Formik>

                    <ScrollContainer className={styles.scrollContainer}>
                        <BngTable rows={this.state.filteredTickets}
                                  cols={tableColumns}
                        />
                    </ScrollContainer>
                </Component>
                {this.state.ticketViewData &&
                    <TicketViewDialog ticketData={this.state.ticketViewData}
                                      mobile={this.props.mobile}
                                      closeModal={() => this.setState({ticketViewData: null})}
                    />
                }
            </>
        );
    }
}

export default ContextEnhancer(TicketListDialog);

