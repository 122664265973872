import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { BngIntegerField } from 'components/bng/form/BngMaskedField';
import React from 'react';

export default function BngInputSpinner({
  min,
  max,
  step = 1,
  value,
  onChange,
  field,
  form,
  inputComponent = BngIntegerField,
  disabled = false,
  disableAddRemoveButton = false,
  ...props
}) {
  let val;
  if (field) {
    val = field.value * 1;
  } else {
    val = value;
    field = {
      name: '',
      value: val,
    };
    form = {
      setFieldValue(n, val) {
        onChange(val);
      },
    };
  }

  const stepUp = () => form.setFieldValue(field.name, Math.min((val || 0) + step, max));
  const stepDown = () => form.setFieldValue(field.name, Math.max((val || 0) - step, min));

  const Comp = inputComponent;
  return (
    <div
      className="BngInputSpinner flex-center-items"
      onKeyUp={(e) => {
        e.stopPropagation();
        const key = e.key?.toLowerCase();
        if (key === 'arrowup') {
          stepUp();
        } else if (key === 'arrowdown') {
          stepDown();
        }
      }}
    >
      <BngIconButton
        icon="remove"
        className="mr-1"
        size="sm"
        disabled={disabled || (!disableAddRemoveButton ? val <= min : disableAddRemoveButton)}
        onClick={stepDown}
      />
      <Comp
        className="mb-0"
        onValueChange={({ floatValue }) => form.setFieldValue(field.name, Math.min(floatValue ?? min, max))}
        field={field}
        form={form}
        disabled={disabled}
        {...props}
      />
      <BngIconButton
        icon="add"
        className="ml-1"
        size="sm"
        disabled={disabled || (!disableAddRemoveButton ? val >= max : disableAddRemoveButton)}
        onClick={stepUp}
      />
    </div>
  );
}
