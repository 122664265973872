import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useEventListener } from 'components/hooks/useEventListener';

export default function StandaloneBarChart({
  data = [],
  xAxisLabels = [],
  rounded = true,
  className = '',
  xLabelFormatter = null,
  yLabelFormatter = null,
  tooltipFormatter = null,
}) {
  const $root = useRef();
  const $chart = useRef();

  useEffect(() => {
    if (!$root.current || $root.current.clientWidth <= 0 || $root.current.clientHeight <= 0) {
      return;
    }

    const chart = echarts.init($root.current);
    $chart.current = chart;

    const itemStyle = {};

    if (rounded) {
      itemStyle.borderRadius = [4, 4, 0, 0];
    }

    const formattedData = data.map((value) => {
      return {
        value: value,
        itemStyle: itemStyle,
      };
    });

    const chartOpts = {
      xAxis: {
        type: 'category',
        data: xAxisLabels,
        axisLabel: {
          formatter: (text) => {
            return xLabelFormatter ? xLabelFormatter(text) : text;
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (text) => {
            return yLabelFormatter ? yLabelFormatter(text) : text;
          },
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          const data = params[0].data.value;
          const name = params[0].name;
          return tooltipFormatter ? tooltipFormatter({ data, name }) : data;
        },
      },
      series: {
        data: formattedData,
        type: 'bar',
      },
    };

    chart.setOption(chartOpts);

    return () => {
      try {
        echarts.dispose(chart);
      } catch (e) {
        console.warn('Error while disposing StandaloneBarChart', { echarts, chartContainerRef: $root }, e);
      }
    };
  }, [$root.current, data]);

  useEventListener('resize', () => {
    $chart.current?.resize();
  });

  return <div className={`StandaloneBarChart ${className}`} ref={$root}></div>;
}
