import React, { useMemo, useState } from 'react';
import ContextEnhancer from 'components/ContextEnhancer';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { BngIconButton } from 'components/bng/ui/BngIconButton';

const BngFloatActionButtonDropdown = ({ customOptions = [] }) => {
  const id = Math.random().toString(36).substr(2, 9);
  const [open, setOpen] = useState(false);
  const boundaryElement = useMemo(() => {
    return document.getElementById('page-content');
  }, []);

  return (
    <BngDropdown
      className={`FloatActionButton`}
      boundariesElements={boundaryElement}
      popperOpts={{
        placement: 'bottom-start',
      }}
      popperClassName={`FloatActionButtonDropdown`}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      customButton={({ openDropdown }) => {
        return (
          <BngIconButton
            icon={'add'}
            className={`info ${open ? 'Open' : ''}`}
            onClick={openDropdown}
          />
        );
      }}
      customOptions={({ closeDropdown }) => {
        return (
          <div className={`ButtonsContainer ${open ? 'Show' : ''}`}>
            {customOptions.map((co, index) => {
              const btnId = `${id}-${index}`;
              return (
                <div key={index}>
                  <BngIconButton
                    id={btnId}
                    type={co.type}
                    size={co.size}
                    icon={co.icon}
                    className={`${co.className}`}
                    onClick={() => {
                      co.onClick();
                      closeDropdown();
                    }}
                    props={co.props}
                  />
                  <label htmlFor={btnId}>{co.label}</label>
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
};

export default ContextEnhancer(BngFloatActionButtonDropdown);
