import 'react-phone-number-input/style.css';

import React, {useState, useEffect} from 'react';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import pt from 'react-phone-number-input/locale/pt-BR.json';
import es from 'react-phone-number-input/locale/es.json';
import en from 'react-phone-number-input/locale/en.json';

export default function BngPhoneInput({
                                  type = 'text',
                                  className = '',
                                  field,
                                  form,
                                  userLanguage = '',
                                  ...props
                              }) {
    const [language, setLanguage] = useState(pt);
    const [phone, setPhone] = useState(field?.value);

    useEffect(() => {
        selectPhoneTranslation();
    }, [userLanguage]);

    const updatePhoneNumber = (phone) => {
        if (phone && phone.length > 4 && phone.length < 18 && isPossiblePhoneNumber(phone)) {
            const phoneObject = parsePhoneNumber(phone);
            phone = phoneObject.formatInternational();
        }

        form.setFieldValue(field.name, phone);
    }

    useEffect(() => {
        updatePhoneNumber(phone);
    }, [phone]);

    const selectPhoneTranslation = () => {
        switch (userLanguage.split('_')[0]) {
            case 'en':
                setLanguage(en);
                return en;
            case 'es':
                setLanguage(es);
                return es;
            default:
                setLanguage(pt);
                return pt;
        }
    }

    return (
        <PhoneInput
            type={type}
            international={false}
            name={field.name}
            className={`${className}`}
            onChange={number => setPhone(number)}
            value={field.value}
            labels={language}
            defaultCountry={'BR'}
            {...props}
        />
    )
}