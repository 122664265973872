class ReactNativeApi {

    hasWebView = (refWindow = window) => {
        return !!refWindow.ReactNativeWebView;
    }

    postMessage = (message = '') => {
        if (this.hasWebView(window)) {
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.postMessage(message, '*');
        }
    };

    openLink = (url = '') => {
        if (this.hasWebView(window)) {
            window.ReactNativeWebView.postMessage({action: "link", data: url});
        } else {
            window.open(url, '_blank');
        }
    }
}

export default ReactNativeApi;