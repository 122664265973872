import React from 'react';

export default function LoadingBox({ className = '', spinnerProps = {}, ...props }) {
  return (
    <div className={`loading-image-box ${className}`}>
      <svg
        className="spinner"
        width="100px"
        height="100px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
        {...spinnerProps}
      >
        <circle className="path" fill="none" strokeWidth="1.5" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>
  );
}
