import './DashLayoutAccordion.css';
import containerCustomStyles from 'components/ui/dashboard/components/ContainerCustomStyles.module.css';

import React, { useEffect, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Button from 'components/ui/Button';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngField } from 'components/bng/form/BngField';
import { BngInput } from 'components/bng/form/BngInput';
import BngImageUpload from 'components/bng/form/BngImageUpload';
import { BngSelect } from 'components/bng/form/BngSelect';
import { BngSlider } from 'components/bng/form/BngSlider';
import BngSwitch from 'components/bng/form/BngSwitch';
import Accordion from 'components/ui/Accordion';
import AccordionList from 'components/ui/AccordionList';
import HelpIcon from 'components/ui/common/HelpIcon';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BngLineInputColor from 'components/bng/form/BngLineInputColor';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import useBimContext from 'components/hooks/useBimContext';
import useTranslation from 'components/hooks/useTranslation';
import { enumAlignOptions, enumTextFormatOptions } from 'components/bng/pages/bigTable/constants';
import ButtonGroup from 'components/ui/button-group/ButtonGroup';
import BngInputColor from 'components/bng/form/BngInputColor';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import useFetchData from 'components/hooks/useFetchData';
import { ContainerStyleSchema } from 'components/bng/pages/dashboard/menus/ContainerEditMenu';
import BngIconButton from 'components/bng/ui/BngIconButton';

export const HIGHLIGHT_COLORS = ['#6925e8', '#528de3', '#269c59', '#fec447', '#ffffff', '#eb354d'].map((color) => ({
  value: color,
  label: color,
}));

export const LAYOUT_DIVISIONS = (t) => {
  return [12, 24, 48, 96].map((d) => ({ value: d, label: `${d} ${t('divisions')}` }));
};

export const THEMES = (context) => {
  return ['WHITE', 'BLACK', 'CORPORATIVE'].map((theme) => ({
    value: theme,
    label: context.msg.t(`css.background.theme.${theme.toLowerCase()}`),
  }));
};

const DashLayoutSchema = bngYup((yup) => {
  return yup.object().shape({
    backgroundTheme: yup.string().default('WHITE'),
    highlightColor: yup.string().default('#528de3'),
    backgroundImage: yup.mixed().nullable().default(null),
    backgroundColor: yup.string().default(''),
    divisions: yup.number().default(24),
    transparency: yup.number().default(0),
    itemsMargin: yup.boolean().default(true),
    margin: yup.number().min(0).max(10).default(6),
    containerMargin: yup.boolean().default(true),
    containerMarginValue: yup.number().min(0).max(10).default(6),
    itemsShadow: yup.boolean().default(false),
    allowTheme: yup.boolean().default(true),
    borderRadius: yup.boolean().default(true),
    migrateToFreeLayout: yup.boolean().default(false),
    containerStyle: ContainerStyleSchema,
  });
});

function dashLayoutInitialValues(dash) {
  const defaultValues = DashLayoutSchema.default();
  if (!dash) {
    return defaultValues;
  }

  const {
    dashboard: { style },
  } = dash;
  return _.merge(defaultValues, {
    backgroundColor: style.background.color,
    backgroundTheme: style.backgroundTheme,
    highlightColor: style.highlightBoxColor,
    backgroundImage: style.backgroundImage,
    itemsMargin: style.allowMargin,
    margin: style.margin,
    containerMargin: style.allowContainerMargin,
    containerMarginValue: style.containerMargin,
    borderRadius: style.borderRadius,
    allowTheme: style.allowTheme,
    itemsShadow: style.allowBoxShadow,
    divisions: style.layout.divisions,
    transparency: style.itemTransparency,
    containerStyle: style.containerStyle,
  });
}

export function LabelHelper({ label, helpLabel }) {
  return (
    <>
      <span>{label}</span> <HelpIcon title={helpLabel} />
    </>
  );
}

function ThemeAccordion({ theme, setFieldValue, allowTheme, onApplyDefaultLayout = _.noop }) {
  const context = useBimContext();

  //Handle allowTheme
  useEffect(() => {
    if (allowTheme && theme === 'NONE') {
      setFieldValue('backgroundTheme', 'WHITE');
      setFieldValue('margin', 6);
      setFieldValue('itemsMargin', true);
      setFieldValue('containerMarginValue', 6);
      setFieldValue('containerMargin', true);
      setFieldValue('borderRadius', true);
    }
  }, [allowTheme]);

  return (
    <Accordion title={context.msg.t('theme')} id="ThemeAccordion">
      <div className="backgroundThemeContainer">
        <div className="backgroundThemeInput">
          <Field
            name="backgroundTheme"
            component={BngField}
            withLabel={false}
            inputComponent={BngSelect}
            emptyOption={false}
            options={THEMES(context)}
          />
        </div>
        <div>
          <BngIconButton
            icon={'autorenew'}
            title={context.msg.t('apply.dash.default.layout')}
            onClick={onApplyDefaultLayout}
          />
        </div>
      </div>
      <Field
        label={context.msg.t('highlight')}
        name="highlightColor"
        component={BngField}
        rootClassName="HighlightColor"
        inputComponent={BngLineInputColor}
        enablePicker={true}
        defaultOptions={[]}
        fetchProjectColors={false}
        addTransparentOptions={false}
        options={HIGHLIGHT_COLORS}
        showCustom={true}
      />
    </Accordion>
  );
}

function BackgroundAccordion() {
  const context = useBimContext();
  return (
    <Accordion startOpen={false} title={context.msg.t('background')} id="BackgroundAccordion">
      <Field
        label={context.msg.t('background.image')}
        name="backgroundImage"
        component={BngField}
        inputComponent={BngImageUpload}
        asProps={{ height: 150, width: '100%' }}
      />
    </Accordion>
  );
}

function ProportionsAccordion({ isFreeLayout, migrateToFreeLayout, setFieldValue, divisions }) {
  const { t } = useTranslation();
  const disableDivisions = !isFreeLayout && !migrateToFreeLayout;
  return (
    <Accordion startOpen={false} title={t('proportions')} id="ProportionsAccordion">
      <label className="control-label" style={{ display: 'flex', alignItems: 'center' }}>
        <LabelHelper helpLabel={t('dashboard.divisions.hint')} label={t('divisions')} />
      </label>
      <Field
        name="divisions"
        disabled={disableDivisions}
        title={disableDivisions ? t('dash.option.available.new.layout') : ''}
        component={BngField}
        withLabel={false}
        emptyOption={false}
        inputComponent={BngSelect}
        options={LAYOUT_DIVISIONS(t)}
        onChange={(event) => setFieldValue('divisions', event.target.value)}
        value={divisions}
      />
      {disableDivisions && (
        <Button
          className="bng-button save"
          style={{ width: '100%', margin: '10px 0 0' }}
          onClick={() => setFieldValue('migrateToFreeLayout', true)}
        >
          {t('dash.migrate.layout')}
        </Button>
      )}
    </Accordion>
  );
}

function AppearanceAccordion() {
  const context = useBimContext();
  return (
    <Accordion startOpen={false} title={context.msg.t('appearance')} id="AppearanceAccordion">
      <Field
        name="transparency"
        label={
          <LabelHelper helpLabel={context.msg.t('dashboard.transparency.hint')} label={context.msg.t('transparency')} />
        }
        component={BngField}
        inputComponent={BngSlider}
        rootClassName="TransparencyField"
      />
      <div className="MarginContainer">
        <div className="CheckInputOption">
          <Field
            name="itemsMargin"
            label={
              <LabelHelper
                helpLabel={context.msg.t('dashboard.add.margin.hint')}
                label={context.msg.t('dashboard.add.margin')}
              />
            }
            component={BngCheckbox}
          />
          <Field name="margin" type="number" min={0} max={10} component={BngInput} />
        </div>
        <div className="CheckInputOption">
          <Field
            name="containerMargin"
            label={
              <LabelHelper
                context={context}
                helpLabel={context.msg.t('dash.container.margin.hint')}
                label={context.msg.t('dash.container.margin')}
              />
            }
            component={BngCheckbox}
          />
          <Field name="containerMarginValue" type="number" min={0} max={10} component={BngInput} />
        </div>
      </div>
      <Field
        name="itemsShadow"
        className="DashItemShadow"
        label={
          <LabelHelper
            context={context}
            helpLabel={context.msg.t('dashboard.add.shadow.box.hint')}
            label={context.msg.t('dashboard.add.shadow.box')}
          />
        }
        component={BngCheckbox}
      />
      <Field name="borderRadius" label={context.msg.t('dash.border.rounded')} component={BngCheckbox} />
    </Accordion>
  );
}

function AdvancedAccordion({ allowTheme }) {
  const context = useBimContext();
  return (
    <Accordion title={context.msg.t('advanced')} id="AdvancedAccordion">
      <Field
        name="allowTheme"
        label={allowTheme ? context.msg.t('disable.themes') : context.msg.t('enable.themes')}
        component={BngSwitch}
      />
    </Accordion>
  );
}

function DashboardAccordion() {
  const context = useBimContext();
  return (
    <Accordion title={context.msg.t('dashboard')} id="DashboardAccordion">
      <Field
        label={context.msg.t('background.color')}
        name="backgroundColor"
        component={BngField}
        inputComponent={BngLineInputColor}
        fetchProjectColors={true}
        addTransparentOptions={false}
        showCustom
      />
    </Accordion>
  );
}

function ThemeOpt({ selected, theme }) {
  return (
    <div className={`ThemeOpt ${selected ? 'selectedContainerStyleOpt' : ''}`}>
      <div className={`fakeContainerHeader ${containerCustomStyles[`headerStyle_${theme}`]}`}></div>
    </div>
  );
}

export function ContainerStyleAccordion({
  setFieldValue,
  containerStyle,
  title,
  startOpen = false,
  className = '',
  disabled = false,
  name = 'containerStyle',
}) {
  const { t } = useTranslation();

  const { data: headerOpts, isLoading } = useFetchData(async () => {
    const headerOpts = await Api.Dash.fetchContainerHeaderOpts();

    const sizeOpts = headerOpts.sizes.map((size) => {
      return {
        value: size,
        label: t(`headerSize.${size}`),
      };
    });

    const themeOpts = headerOpts.styles.map((theme) => {
      return {
        value: theme,
        render: ({ selected }) => <ThemeOpt selected={selected} theme={theme} />,
      };
    });

    return { sizeOpts, themeOpts };
  });

  return (
    <Accordion
      disabled={disabled}
      title={title || t('containerStyle')}
      className={className}
      id="ContainerStyleAccordion"
      startOpen={startOpen}
    >
      <label className="control-label">{t('title')}</label>
      <div className="ContainerStyleTitle">
        <div className="TitleFontSizeInput">
          <Field
            name="title"
            type="number"
            min={6}
            max={28}
            onChange={(event) => setFieldValue(`${name}.fontSize`, event.target.value)}
            value={containerStyle.fontSize}
            onBlur={() => {
              if (containerStyle.fontSize > 28) {
                setFieldValue(`${name}.fontSize`, 28);
              }
              if (containerStyle.fontSize < 6) {
                setFieldValue(`${name}.fontSize`, 6);
              }
            }}
            component={BngInput}
          />
        </div>

        <ButtonGroup
          multiple={true}
          items={enumTextFormatOptions}
          value={containerStyle.textFormat}
          onChange={(val) => setFieldValue(`${name}.textFormat`, val)}
        />

        <BngInputColor
          className={'containerColor'}
          form={{
            setFieldValue: (field, val) => setFieldValue(`${name}.fontColor`, val),
          }}
          field={{
            value: containerStyle.fontColor,
            onChange: _.noop,
          }}
          addTransparentOptions={false}
          transparentInPalette
        />

        <ButtonGroup
          items={enumAlignOptions}
          value={containerStyle.textAlign}
          onChange={(val) => setFieldValue(`${name}.textAlign`, val)}
        />
      </div>

      <div className={'ContainerHeaderStyle'}>
        <Field
          name={`${name}.size`}
          component={BngField}
          label={t('manageFiles_label_size')}
          inputComponent={BngSelectSearch}
          disabled={isLoading}
          options={headerOpts?.sizeOpts ?? []}
        />

        <Field
          name={`${name}.headerStyle`}
          component={BngField}
          label={t('header.style')}
          inputComponent={BngSelectSearch}
          disabled={isLoading}
          options={headerOpts?.themeOpts ?? []}
        />
      </div>

      <div className={'ContainerColors'}>
        <div className={'colorFieldWrapper'}>
          <label id={'headerColorLabel'}>{t('containerStyle.headerColor')}</label>
          <BngInputColor
            for={'headerColorLabel'}
            form={{
              setFieldValue: (field, val) => setFieldValue(field, val),
            }}
            field={{ onChange: _.noop, value: containerStyle.headerColor, name: `${name}.headerColor` }}
            gradientEnabled
            gradientRotationEnabled
            addTransparentOptions={false}
            transparentInPalette
          />
        </div>

        <div className={'colorFieldWrapper'}>
          <label id={'backgroundColorLabel'}>{t('containerStyle.backgroundColor')}</label>
          <BngInputColor
            for={'backgroundColorLabel'}
            form={{
              setFieldValue: (field, val) => {
                setFieldValue(field, val);
              },
            }}
            field={{
              onChange: _.noop,
              value: containerStyle.backgroundColor,
              name: `${name}.backgroundColor`,
            }}
            gradientEnabled
            gradientRotationEnabled
            addTransparentOptions={false}
            transparentInPalette
          />
        </div>
      </div>
    </Accordion>
  );
}

export default function DashLayoutAccordion() {
  const context = useBimContext();
  const { t } = useTranslation();
  const { dash, addChange } = useDashboardPageCtx.cached(({ dash, addChange }) => ({ dash, addChange }));

  const applyDashLayout = async (values, formikProps) => {
    try {
      let submitValues = Object.assign({}, values);

      if (submitValues.backgroundImage && typeof submitValues.backgroundImage !== 'string') {
        const links = await Api.Upload.upload(submitValues.backgroundImage);
        submitValues.backgroundImage = links[0].link;
      }

      await addChange({
        type: 'UPDATE_STYLE',
        data: submitValues,
      });

      UiMsg.ok(t('dash.layout.applied.success'));
    } catch (e) {
      console.error('Error on applyDashLayout()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      formikProps.setSubmitting(false);
    }
  };

  const initialValues = useMemo(() => {
    return dashLayoutInitialValues(dash);
  }, [dash]);

  const isFreeLayout = true; // TODO prop don't exists anymore???? isFreeLayout={!dashboardStyle.layout.freeLayout}

  return (
    <Formik initialValues={initialValues} onSubmit={applyDashLayout} validationSchema={DashLayoutSchema}>
      {({ values, isSubmitting, setFieldValue, submitForm, setValues }) => {
        return (
          <Form>
            <AccordionList className="ObjectRightMenuAccordion DashboardLayoutAccordion HasFixedButton">
              {values.allowTheme && (
                <>
                  <ThemeAccordion
                    setFieldValue={setFieldValue}
                    allowTheme={values.allowTheme}
                    theme={values.backgroundTheme}
                    highlightColor={values.highlightColor}
                    onApplyDefaultLayout={() => {
                      const projectDashTheme = context.theme?.themeDashboard ?? {};
                      const themeValues = _.merge({}, initialValues, {
                        backgroundTheme: projectDashTheme.backgroundTheme,
                        highlightColor: projectDashTheme.highlightBoxColor,
                        backgroundImage: projectDashTheme.backgroundImage,
                        divisions: projectDashTheme.divisions,
                        transparency: projectDashTheme.transparency,
                        itemsMargin: projectDashTheme.hasMargin,
                        margin: projectDashTheme.margin,
                        containerMargin: projectDashTheme.hasContainerMargin,
                        containerMarginValue: projectDashTheme.containerMargin,
                        itemsShadow: projectDashTheme.hasBoxShadow,
                        borderRadius: projectDashTheme.hasBorderRadius,
                      });
                      setValues(themeValues);
                    }}
                  />
                  <BackgroundAccordion />
                </>
              )}

              <ProportionsAccordion
                isFreeLayout={isFreeLayout}
                migrateToFreeLayout={values.migrateToFreeLayout}
                setFieldValue={setFieldValue}
                divisions={values.divisions}
              />

              {values.allowTheme && <AppearanceAccordion />}

              {!values.allowTheme && (
                <>
                  <DashboardAccordion />
                  <AdvancedAccordion allowTheme={values.allowTheme} />
                </>
              )}

              <ContainerStyleAccordion
                setFieldValue={setFieldValue}
                containerStyle={values.containerStyle}
                name={'containerStyle'}
              />
            </AccordionList>

            <Accordion className="ApplyDashLayout AccordionFixedButton" customTitle={() => null}>
              <Button
                className="bng-button save"
                style={{ margin: 0 }}
                disabled={_.isEqual(values, initialValues)}
                loading={isSubmitting}
                onClick={submitForm}
              >
                {t('apply')}
              </Button>
            </Accordion>
          </Form>
        );
      }}
    </Formik>
  );
}
