import styles from './BngExportLogsDialog.module.css';

import React, { useEffect, useMemo, useState } from 'react';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import BngSearch from 'components/bng/ui/BngSearch';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import Utils from 'components/Utils';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import bngYup from 'components/bng/form/yup/BngYup';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import BngTag from 'components/bng/ui/BngTag';
import { MODALS } from 'components/ui/redux/Actions';
import SchedulingLogDialog from 'components/ui/cockpit/SchedulingLogDialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngLogsDialog from 'components/bng/logs/BngLogsDialog';
import useInterval from 'components/hooks/useInterval';

const BngExportLogsSchema = bngYup((yup) => {
  return yup.object().shape({
    endedWith: yup.string().nullable().default(''),
  });
});

const filterRows = (selectedRows, filters) => {
  const {
    searchTerm,
    filterButton: { endedWith },
  } = filters;

  if (!_.isEmpty(searchTerm)) {
    selectedRows = selectedRows.filter((row) => {
      return (
        Utils.Strings.includesIgnoreCase(row.id, searchTerm) ||
        Utils.Strings.includesIgnoreCase(row.user.email, searchTerm) ||
        Utils.Strings.includesIgnoreCase(Utils.Users.displayName(row.user), searchTerm)
      );
    });
  }

  if (!_.isEmpty(endedWith)) {
    selectedRows = selectedRows.filter((row) => {
      return row.endedWith.toLowerCase() === endedWith.toLowerCase();
    });
  }

  return selectedRows;
};

const BngExportLogsFilterDropdown = ({ className = '', onChange = _.noop, initialValues = {} }) => {
  const { msg } = useBimContext();

  const endedWithOpts = useMemo(
    () =>
      FilterOpts({
        options: Object.values(Utils.Scheduling.ENDED_WITH),
      }),
    []
  );

  const initialFormValues = useMemo(() => _.merge({}, BngExportLogsSchema.default(), initialValues), [initialValues]);

  const filterFields = [
    {
      name: 'endedWith',
      label: msg.t('ended.with'),
      options: endedWithOpts,
    },
  ];

  return (
    <FilterDropdown
      className={className}
      fields={filterFields}
      initialFormValues={initialFormValues}
      dropdownSchema={BngExportLogsSchema}
      onChange={onChange}
      overDialog={true}
    />
  );
};

export default function BngExportLogsDialog({ closeModal, exportSchedId }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [schedulingLogs, setSchedulingLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });

  const fetchLogs = async (showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }

    try {
      const result = await Api.ExportScheduling.findLogs(exportSchedId, 20);
      setSchedulingLogs(result);
    } catch (e) {
      console.error('Error on findLogs()', { exportSchedId }, e);
      UiMsg.ajaxError(null, e);
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  useInterval(() => {
    fetchLogs(false);
  }, [10000]);

  useEffect(() => {
    fetchLogs();
  }, []);

  const filteredRows = useMemo(() => {
    return filterRows(schedulingLogs, filters);
  }, [schedulingLogs, filters]);

  const tableColumns = useMemo(() => {
    const renderActionButtons = (row) => {
      return (
        <BngIconButton
          icon={'description'}
          onClick={() => {
            dispatch(MODALS.open(SchedulingLogDialog, { exportSchedId, logId: row.id }));
          }}
        />
      );
    };

    return [
      {
        label: context.msg.t('id'),
        colClassName: styles.tableLeftPadding,
        rowClassName: styles.tableLeftPadding,
        render: (row) => row.id,
      },
      {
        label: context.msg.t('started'),
        render: (row) => Utils.Date.formatDateTime(row.startDate),
      },
      {
        label: context.msg.t('ended'),
        render: (row) => Utils.Date.formatDateTime(row.endDate),
      },
      {
        label: context.msg.t('ended.with'),
        render: (row) => context.msg.t(row.endedWith),
      },
      {
        label: context.msg.t('sent.by'),
        render: (row) => {
          const user = row.user;
          return (
            <BngTag
              description={Utils.Users.displayName(user)}
              title={
                user.email === 'system@bimachine.com.br' ? context.msg.t('Scheduling.logs.email.auto.send') : user.email
              }
              icon={Utils.Project.isBimachineEmail(user.email) ? 'engineering' : 'person'}
            />
          );
        },
      },
      {
        label: context.msg.t('actions'),
        render: (row) => renderActionButtons(row),
      },
    ];
  }, [exportSchedId]);

  return (
    <BngLogsDialog
      className="BngExportLogsDialog"
      closeModal={closeModal}
      title={context.msg.t('export.scheduling.logs')}
      isEmpty={_.isEmpty(schedulingLogs)}
      loading={loading}
      rows={filteredRows}
      columns={tableColumns}
      emptyMessage={context.msg.t('Scheduling.logs.empty')}
      headerButtons={
        <React.Fragment>
          <BngSearch
            className={`${styles.logsSearch}`}
            value={filters.searchTerm}
            onChange={(val) => {
              setFilters({ ...filters, searchTerm: val });
            }}
            title={context.msg.t('search.object.name')}
            alwaysOpen
            inline
          />
          <BngExportLogsFilterDropdown
            alwaysOpen={false}
            onChange={(values) => setFilters({ ...filters, filterButton: values })}
            initialValues={filters.filterButton}
          />
          <BngIconButton icon={'refresh'} onClick={fetchLogs} />
        </React.Fragment>
      }
    />
  );
}
