import styles from './AssistedMode.module.css';

import React from 'react';

import Api from 'components/Api';
import useTranslation from 'components/hooks/useTranslation';

export default function AssistedMode() {
  const { t } = useTranslation();

  return (
    <div className={`AssistedMode ${styles.MessageWrapper}`}>
      <img src={Api.buildUrl('/resources/images/assisted_mode_ada.png')} />
      <div className="coming_soon">{t('coming.soon')}...</div>
      <div className="coming_soon_title">{t('bim.query.tab.assisted.mode.tab.message.title')}</div>
      <div className="coming_soon_message">{t('bim.query.tab.assisted.mode.tab.message.text')}</div>
      <a href={t('support.bimachine.com.bim-query-assisted-mode')} target="_blank">
        {t('know.more')}
      </a>
    </div>
  );
}
