import React from 'react';

import image from 'components/bng/pages/newAnalysis/assets/BngAdvancedModeAlert.svg';
import BaseErrorPage from 'components/bng/pages/errors/BaseErrorPage';
import useTranslation from 'components/hooks/useTranslation';

export default function NotFoundErrorPage() {
  const { t } = useTranslation();
  return (
    <BaseErrorPage
      className="NotFoundErrorPage"
      image={image}
      title={t('page.not.found')}
      description={`${t('page.not.found.error.message')}.`}
    />
  );
}
