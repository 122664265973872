import './MenuItem.css';

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';

const BimMenuItem = ({
  href = '#',
  icon = 'icon-folder',
  text = '',
  path,
  children = [],
  container = false,
  expanded = false,
  onSubmenu = false,
  active = false,
  className = '',
  isObjectHref = true,
  toggleExpand = _.noop,
  lastOpenedFolders = [],
  toggleOpenItem = _.noop,
}) => {
  const submenuItemRef = useRef();

  useEffect(() => {
    const { current } = submenuItemRef;
    if (!current) return;

    if (current.clientWidth < current.scrollWidth) {
      current.setAttribute('title', text);
    } else {
      current.removeAttribute('title', text);
    }
  }, [submenuItemRef.current]);

  const toggleOpen = useCallback(() => {
    toggleOpenItem({ path });
  }, [path]);

  const { loadUrl, lastOpened, isOpen } = useMemo(() => {
    let loadUrl = '#';
    if (href !== '#') {
      loadUrl = isObjectHref ? Api.loadObjectUrl({ content: href }) : href;
    }

    const isAnyFolderOpen = lastOpenedFolders.length > 0;
    const lastOpened = isAnyFolderOpen
      ? lastOpenedFolders[lastOpenedFolders.length - 1] === path
      : false;
    const isOpen = isAnyFolderOpen
      ? lastOpenedFolders.some((f) => f === path)
      : false;

    return {
      loadUrl,
      lastOpened,
      isOpen,
    };
  }, [href, lastOpenedFolders]);

  return (
    <li className={clsx({ open: isOpen, active, lastOpened })}>
      <a
        href={loadUrl}
        className={clsx('dropdown-toggle', className)}
        title={!expanded ? text : ''}
        onClick={(e) => {
          if (!expanded) {
            toggleExpand();
          }
          if (loadUrl !== '#' && !onSubmenu) return;
          if (container) {
            toggleOpen();
            e.preventDefault();
          }
        }}
      >
        {/* When menu is collapsed */}
        {!expanded && <Icon icon={icon} />}

        {!expanded && (
          <div className={container ? 'menu-text' : 'menu_option'}>{text}</div>
        )}

        {/* END */}

        {expanded && (
          <div>
            {!onSubmenu && (
              <table>
                <tbody>
                  <tr>
                    <td className="menutd menutd-icon">
                      <div className="menu-icon-centralized">
                        <Icon icon={icon} />
                      </div>
                    </td>
                    <td className="menutd menutd-text">
                      <div className={container ? 'menu-text' : 'menu_option'}>
                        <span className="menu-text-span">{text}</span>
                      </div>
                      {container && (
                        <Icon
                          icon={isOpen ? 'expand_more' : 'chevron_right'}
                          className="menutd-icon-more"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {onSubmenu && (
              <div>
                {container ? (
                  <Icon
                    icon={isOpen ? 'expand_more' : 'chevron_right'}
                    className="submenu-item-chevron"
                  />
                ) : (
                  <Icon className={`submenu-icon`} icon={icon}/>
                )}

                <div className="submenu-item" ref={submenuItemRef}>
                  {text}
                </div>
              </div>
            )}
          </div>
        )}
      </a>
      {container && isOpen && children.length > 0 && (
        <ul className="submenu" style={{ display: 'block' }}>
          {children.map((item, idx) => (
            <BimMenuItem
              key={idx}
              {...item}
              expanded={expanded}
              onSubmenu={true}
              toggleOpenItem={toggleOpenItem}
              lastOpenedFolders={lastOpenedFolders}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

BimMenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.array,
  container: PropTypes.bool,
  expanded: PropTypes.bool,
  onSubmenu: PropTypes.bool,
  className: PropTypes.string,
  isObjectHref: PropTypes.bool,
  toggleExpand: PropTypes.func,
  active: PropTypes.bool,
  path: PropTypes.string,
  lastOpenedFolders: PropTypes.array,
  toggleOpenItem: PropTypes.func,
};

export default BimMenuItem;
