import './KpiBandConfig.css';

import React from 'react';
import { Field } from 'formik';

import Icon from 'components/ui/common/Icon';
import BngInputColor from 'components/bng/form/BngInputColor';
import { BngDoubleField } from 'components/bng/form/BngMaskedField';
import BngField from 'components/bng/form/BngField';

function Color({ name }) {
  return (
    <Field
      name={`${name}.color`}
      rootClassName="mb-0"
      component={BngField}
      inputComponent={BngInputColor}
      withLabel={false}
      showErrors={false}
    />
  );
}

function Value({ ...props }) {
  return (
    <Field name={"bandsValue"} rootClassName="mb-0" component={BngField} inputComponent={BngDoubleField} showErrors={false} {...props} asNumber/>
  );
}

export default {
  defaultVal() {
    return [
      {
        order: 1,
        minimumValue: 0,
        maximumValue: 0,
        color: '#F83A22',
      },
      {
        order: 2,
        minimumValue: 0,
        maximumValue: 0,
        color: '#FFAD46',
      },
      {
        order: 3,
        minimumValue: 0,
        maximumValue: 0,
        color: '#16A765',
      },
    ];
  },
  render({ name, props, context: { msg }, formikProps }) {
    const typeField = `params.${props.typeField ?? 'targetType'}`;
    const type = _.get(formikProps.values, typeField);

    const bands = _.get(formikProps.values, name) ?? [];

    const isMeasure = type === 'MEASURE';
    return (
      <div className="ParamType KpiBandConfig position-relative mb-3" data-test="KpiBandConfig">
        <div className="flex-center-items gap-1">
          {isMeasure ? (
            bands.map((band, idx) => {
              const bandPrefix = `${name}[${idx}]`;
              return (
                <React.Fragment key={bandPrefix}>
                  {idx !== 0 && <Icon icon="chevron_left" />}
                  <Color name={bandPrefix} />
                  {isMeasure && idx === 1 && (
                    <Value
                      name={`${bandPrefix}.minimumValue`}
                      label={`% ${msg.t('the')} ${msg.t('measure')}`}
                      maxLength={3}
                      allowNegative={false}
                      labelOnRight
                      inline
                    />
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <>
              <Value name={`${name}[0].minimumValue`} withLabel={false} />
              <Color name={`${name}[0]`} />
              <Icon icon="chevron_left" />
              <Value name={`${name}[1].minimumValue`} withLabel={false} />
              <Color name={`${name}[1]`} />
              <Value name={`${name}[1].maximumValue`} withLabel={false} />
              <Icon icon="chevron_left" />
              <Color name={`${name}[2]`} />
              <Value name={`${name}[2].maximumValue`} withLabel={false} />
            </>
          )}
        </div>
      </div>
    );
  },
};
