import styles from './BngPlanTable.module.css';

import React from 'react';

import { proposalUtils } from 'components/service/bng/AccountApi';
import useTranslation from 'components/hooks/useTranslation';
import Icon from 'components/ui/common/Icon';
import BngButton from 'components/bng/ui/BngButton';
import useFetchData from 'components/hooks/useFetchData';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { FEATURE_TYPES } from 'components/bng/pages/marketplace/tabs/MktPlanTab';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { useOutletContext } from 'react-router-dom';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import { localStore, SESSION_DATA } from 'components/Storage';

const COLORS = ['#bf66ff', '#ff7b4d', '#0f82f4', '#FD003D'];
const PRICING_ROWS = [{ label: 'monthly.contract' }];

const disableButton = ({ currentPlan, plan, accountMetrics, account, isMasterOfCurrentAccount, requestedAccounts }) => {
  if (!isMasterOfCurrentAccount && account.role !== 'Administrator' && requestedAccounts.includes(account.id)) {
    return true;
  }

  if (
    !currentPlan ||
    (accountMetrics && !Object.keys(accountMetrics).length) ||
    plan.pricings[0].pricing >= currentPlan.pricings[0].pricing
  ) {
    return false;
  }

  const usersCurrentUsage = accountMetrics.USERS.currentUsage;
  const structuresCurrentUsage = accountMetrics.STRUCTURES.currentUsage;
  const planUsersLimit = plan.planFeatures.find((feat) => feat.features.classification === 'USER').value.value;
  const planStructuresLimit = plan.planFeatures.find((feat) => feat.features.classification === 'STRUCTURE').value
    .value;

  return planUsersLimit < usersCurrentUsage || planStructuresLimit < structuresCurrentUsage;
};

function TitleRow({ title, plans = [], shouldRenderPlanTitles, fullyRounded = false, fixedRow = false }) {
  return (
    <tr className={fixedRow ? styles.fixedRow : ''}>
      <td className={`${styles.infoColumn} ${fullyRounded ? styles.fullyRounded : ''}`}>
        <div>{title}</div>
      </td>
      {plans.map((plan, idx) => {
        const nameTranslation = proposalUtils.translationsForSales(plan)?.name;
        return (
          <td
            className={`${styles.planColumn} ${fullyRounded ? styles.fullyRounded : ''}`}
            key={`TitleRow-plan-${idx}`}
          >
            <div style={{ background: COLORS[idx] }}>{shouldRenderPlanTitles ? nameTranslation : null}</div>
          </td>
        );
      })}
    </tr>
  );
}

function ValueRow({ featJsonKey, plans = [], isLast = false, featParam, intValue = false, isAddon = false }) {
  const { t } = useTranslation();
  const { user } = useBimContext();
  const language = user.preferredLanguage.replace('_', '-');
  const description =
    featParam.displayName?.langs[language]?.value ||
    featParam.features?.props.translations.langs[language]?.description ||
    featParam.props.translations.langs[language]?.description;

  return (
    <tr>
      <td className={`${styles.infoChildren} ${isLast ? styles.lastChild : ''}`}>
        <div className={styles.childBackground}>
          <div>
            {featJsonKey ? t(`feature.${featJsonKey}`) : proposalUtils.translationsForSales(featParam)?.name}
            {!featJsonKey && (
              <span title={description}>
                <Icon className="material-icons-outlined" icon={'info'} />
              </span>
            )}
          </div>
        </div>
      </td>
      {plans.map((plan, idx) => {
        const planFeature = plan.planFeatures.find(
          (pf) => pf.features.id === (isAddon ? featParam.features.id : featParam.id),
        );
        const currentValue = featJsonKey
          ? planFeature?.value.value.find((v) => Object.keys(v)[0] === featJsonKey)
          : planFeature?.value.value;

        return (
          <td
            className={`${styles.infoChildren} ${styles.planChild} ${isLast ? styles.lastChild : ''}`}
            key={`ValueRow-plan-${idx}`}
          >
            <div className={`${styles.childBackground}`} style={{ background: COLORS[idx] }}>
              {planFeature && intValue ? (
                <div>
                  <strong>{`${currentValue} ${planFeature.features.props.suffix ?? ''}`}</strong>
                </div>
              ) : (
                <div>
                  {(featJsonKey ? currentValue && currentValue[featJsonKey] : currentValue) && (
                    <Icon icon={'check_box'} />
                  )}
                </div>
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
}

function PricingRow({ label, childLabel = null, plans, fixedRow = false }) {
  const { t } = useTranslation();
  return (
    <tr className={`${styles.pricingRow} ${fixedRow ? styles.fixedRow : ''}`}>
      <td className={`${styles.infoChildren}`}>
        <div className={styles.childBackground}>
          <div>{label}</div>
        </div>
      </td>
      {plans.map((plan, idx) => {
        let pricingLabel = '';
        if (!childLabel) {
          const pricing = proposalUtils.getDefaultPricing(plan.pricings);
          pricingLabel = `${pricing.currency.symbol} ${pricing.pricing}`;
        }

        return (
          <td className={`${styles.infoChildren} ${styles.planChild}`} key={`PricingRow-plan-${idx}`}>
            <div className={`${styles.childBackground}`} style={{ background: COLORS[idx] }}>
              <div>
                {childLabel ? (
                  <span className={styles.pricingLabel} dangerouslySetInnerHTML={{ __html: t(childLabel) }} />
                ) : (
                  <strong>{pricingLabel}</strong>
                )}
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  );
}

const STORAGE_KEY = 'PlanSelectRow:requestedAccounts';

function PlanSelectRow({
  plans,
  accountMetrics,
  account,
  isMasterOfCurrentAccount,
  currentPlanId,
  fixedRow = false,
  onSelect = _.noop,
}) {
  const context = useBimContext();
  const { t } = useTranslation();
  const { selectedItems, selectCard } = useOutletContext();
  const isConsultant = Utils.Users.isConsultant(context.user);
  const requestedAccounts = localStore.get(SESSION_DATA)?.[STORAGE_KEY] ?? [];
  const currentPlan = plans.find((plan) => plan.id === currentPlanId);

  return (
    <tr className={`${styles.planSelectRow} ${fixedRow ? styles.fixedRow : ''}`}>
      <td className={`${styles.infoChildren} ${styles.lastChild}`}>
        <div className={`${styles.childBackground} ${styles.planSelectRow}`}></div>
      </td>
      {plans.map((plan, idx) => {
        const isSelected = plan.id === currentPlanId;
        const isInCart = selectedItems.find((i) => i.id === plan.id);
        const isDisabled = disableButton({
          currentPlan,
          plan,
          accountMetrics,
          account,
          isMasterOfCurrentAccount,
          requestedAccounts,
        });
        const titleMessage = isSelected
          ? ''
          : isDisabled
            ? requestedAccounts.includes(account.id)
              ? t('change.plan.requested')
              : t('marketplace.disabled.plan')
            : '';

        return (
          <td
            className={`${styles.infoChildren} ${styles.planChild} ${styles.lastChild}`}
            key={`PlanSelectRow-plan-${idx}`}
          >
            <div className={`${styles.childBackground} ${styles.planSelectRow}`} style={{ background: COLORS[idx] }}>
              <span title={titleMessage}>
                <BngButton
                  className={`${styles.selectPlanButton} ${isSelected ? styles.selectedPlan : ''}`}
                  disabled={isSelected ? false : isDisabled}
                  onClick={async () => {
                    if (!isMasterOfCurrentAccount && account.role !== 'Administrator') {
                      const sessionData = localStore.get(SESSION_DATA) || {};
                      requestedAccounts.push(account.id);
                      sessionData[STORAGE_KEY] = requestedAccounts;
                      localStore.put(SESSION_DATA, sessionData);
                    }

                    if (isSelected) {
                      return;
                    }
                    await onSelect(plan);
                  }}
                >
                  {t(isSelected ? 'selected.plan' : 'select.plan')}
                </BngButton>
              </span>
              {!isSelected && isConsultant && (
                <span title={titleMessage}>
                  <BngButton
                    className={`${styles.planCartButton} ${isInCart ? styles.inCart : ''}`}
                    onClick={() => selectCard({ cardItem: plan })}
                    disabled={isDisabled}
                  >
                    <Icon icon={isInCart ? 'remove_shopping_cart' : 'add_shopping_cart'} />
                  </BngButton>
                </span>
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
}

export default function BngPlanTable({
  planArray = [],
  loading = false,
  account,
  isMasterOfCurrentAccount,
  onSelect = _.noop,
  currentPlanId,
}) {
  const { t } = useTranslation();

  const { data: accountMetrics = {} } = useFetchData(async () => {
    const accountMetrics = await Api.Account.findAccountMetrics(account.id);
    return accountMetrics?.metrics;
  }, [account]);

  const plans = planArray.sort((a, b) => {
    const pricingA = proposalUtils.getDefaultPricing(a.pricings).pricing;
    const pricingB = proposalUtils.getDefaultPricing(b.pricings).pricing;

    if (pricingA < pricingB) {
      return -1;
    } else if (pricingB < pricingA) {
      return 1;
    }
    return 0;
  });

  const { data: planFeatures, isLoading } = useFetchData(async () => {
    try {
      if (plans.length === 0) return;

      const features = await Api.Marketplace.fetchFeaturesForPlan(account.id);

      const groupedFeatures = { PRICING: PRICING_ROWS, JSON: [], ADDONS: [], TECHNICAL: [], CONSUMPTION: [] };

      plans[plans.length - 1].planFeatures
        .filter((pf) => pf.features.classification === 'ADDON' && pf.value.value === true)
        .forEach((pf) => groupedFeatures.ADDONS.push(pf));

      features
        .filter((feat) => {
          // Removes features that none of the plans have active
          const planWithMostFeats = plans[plans.length - 1];
          return planWithMostFeats?.planFeatures.find((pf) => pf.features.id === feat.id);
        })
        .forEach((feat) => {
          if (feat.type === FEATURE_TYPES.JSON) {
            groupedFeatures.JSON.push(feat);
          } else if (feat.classification === 'PLAN') {
            groupedFeatures.TECHNICAL.push(feat);
          } else if (
            feat.classification === 'QUOTA' ||
            feat.classification === 'STRUCTURE' ||
            feat.classification === 'USER'
          ) {
            groupedFeatures.CONSUMPTION.push(feat);
          }
        });

      return groupedFeatures;
    } catch (e) {
      console.error('Error on function fetchPlanFeatures()', e);
      UiMsg.ajaxError(t('error.fetching.data', [t('features')]), e);
    }
  }, []);

  return (
    <UiBlocker block={isLoading || loading} className={`BngPlanTable ${styles.BngPlanTable}`}>
      {planFeatures && (
        <div style={{ paddingBottom: '10px' }}>
          <table className={styles.planTable}>
            <tbody>
              {Object.entries(planFeatures).map(([key, value], idx) => {
                switch (key) {
                  case 'JSON':
                    return value.map((featParam, featIdx) => {
                      const featureName = proposalUtils.translationsForSales(featParam)?.name;

                      if (!Array.isArray(featParam.jsonTemplate)) {
                        featParam.jsonTemplate = [];
                      }

                      return (
                        <React.Fragment key={`JsonFeat-${featIdx}`}>
                          <TitleRow
                            title={featureName}
                            plans={plans}
                            shouldRenderPlanTitles={false}
                            fullyRounded={false}
                          />
                          {featParam.jsonTemplate?.map((templateObj, idx) => {
                            const featRow = Object.keys(templateObj).pop();
                            const isLast = featParam.jsonTemplate.length - 1 === idx;
                            return (
                              <ValueRow
                                featJsonKey={featRow}
                                plans={plans}
                                featParam={featParam}
                                isLast={isLast}
                                key={`JsonFeat-Plans-${idx}`}
                              />
                            );
                          })}
                        </React.Fragment>
                      );
                    });
                  case 'TECHNICAL':
                    return (
                      <React.Fragment key={`TechnicalFeat-${idx}`}>
                        <TitleRow title={t(`marketplace.table.${key}`)} plans={plans} shouldRenderPlanTitles={false} />
                        {value.map((feat, idx) => {
                          const isLast = value.length - 1 === idx;
                          const featureName = proposalUtils.translationsForSales(feat)?.name;

                          return (
                            <ValueRow
                              featureName={featureName}
                              plans={plans}
                              featParam={feat}
                              isLast={isLast}
                              key={`TechnicalFeat-plans-${idx}`}
                            />
                          );
                        })}
                      </React.Fragment>
                    );
                  case 'CONSUMPTION':
                    return (
                      <React.Fragment key={`ConsumptionFeat-${idx}`}>
                        <TitleRow title={t(`marketplace.table.${key}`)} plans={plans} shouldRenderPlanTitles={false} />
                        {value.map((feat, idx) => {
                          const isLast = value.length - 1 === idx;
                          const featureName = proposalUtils.translationsForSales(feat)?.name;

                          return (
                            <ValueRow
                              featureName={featureName}
                              plans={plans}
                              featParam={feat}
                              isLast={isLast}
                              intValue={feat.type === FEATURE_TYPES.INT}
                              key={`ConsumptionFeat-plans-${idx}`}
                            />
                          );
                        })}
                      </React.Fragment>
                    );
                  case 'PRICING':
                    return (
                      <React.Fragment key={`PricingFeat-${idx}`}>
                        <TitleRow
                          title={t(`marketplace.table.${key}`)}
                          plans={plans}
                          shouldRenderPlanTitles={true}
                          fixedRow={true}
                        />
                        {value.map(({ label, childLabel }, idx) => {
                          return (
                            <PricingRow
                              label={t(label)}
                              plans={plans}
                              childLabel={childLabel}
                              fixedRow={true}
                              key={`PricingFeat-plan-${idx}`}
                            />
                          );
                        })}
                        <PlanSelectRow
                          plans={plans}
                          accountMetrics={accountMetrics}
                          account={account}
                          isMasterOfCurrentAccount={isMasterOfCurrentAccount}
                          currentPlanId={currentPlanId}
                          fixedRow={true}
                          onSelect={onSelect}
                        />
                      </React.Fragment>
                    );
                  case 'ADDONS':
                    if (value.length) {
                      return (
                        <React.Fragment key={`AddonsFeat-${idx}`}>
                          <TitleRow
                            title={t(`marketplace.table.${key}`)}
                            plans={plans}
                            shouldRenderPlanTitles={false}
                          />
                          {value.map((feat, idx) => {
                            const isLast = value.length - 1 === idx;
                            const featureName = proposalUtils.translationsForSales(feat)?.name;

                            return (
                              <ValueRow
                                featureName={featureName}
                                plans={plans}
                                featParam={feat}
                                isLast={isLast}
                                key={`AddonsFeat-plans-${idx}`}
                                isAddon={true}
                              />
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  default:
                    return null;
                }
              })}
            </tbody>
          </table>
        </div>
      )}
    </UiBlocker>
  );
}
