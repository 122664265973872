import './newTheme.css';
import './resolutions.css';
import './Form.css';
import 'react-block-ui/style.css';
import 'react-virtualized/styles.css';
import './App.css';
import './vars.css';

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import ContextEnhancer from 'components/ContextEnhancer';
import DefaultLayout from 'components/layouts/DefaultLayout';
import appStartupChecks from 'components/appStartupChecks';

class App extends Component {
  static propTypes = {
    store: PropTypes.any,
  };

  static defaultProps = {};

  componentDidMount() {
    appStartupChecks({
      context: this.props.context,
      dispatch: this.props.store.dispatch,
    });
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <div className="App">
          <DefaultLayout />
        </div>
      </Provider>
    );
  }
}

export default ContextEnhancer(App);
