import 'components/ui/CopyLinkToClipboard.css';
import React, {Component} from 'react';
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import Api from "components/Api"
import Icon from "components/ui/common/Icon";

class CopyLinkToClipboard extends Component {

    static propTypes = {
        linkToCopy: PropTypes.string,
        buttonStyle: PropTypes.string,
        msg: PropTypes.object,
        fullUrl: PropTypes.bool,
        objectLink: PropTypes.bool,
    };

    static defaultProps = {
        linkToCopy: '',
        buttonStyle: '',
        fullUrl: true,
        objectLink: false,
    };

    copyLinkToClipboard = () => {
        const link = this.props.objectLink ?
            Api.loadObjectUrl({
            content: this.props.linkToCopy,
            fullUrl: this.props.fullUrl
        }) : this.props.linkToCopy;

        this.$input.value = link;
        this.$input.select();
        document.execCommand("copy");
        UiMsg.ok(this.props.context.msg.t('public.link.copy.clipboard'));
    };

    render() {
        return (
            <div className="CopyClipboardComponent" onClick={this.copyLinkToClipboard}>
                <input ref={el => this.$input = el}
                       className="AuxCopyInput"
                       type="text"/>
                <Icon icon="link" />
                <span className={`btn-only-icon ${this.props.buttonStyle}`}
                      title={this.props.context.msg.t('copy.link')}>
                    {this.props.context.msg.t('copy.link')}
                </span>
            </div>
        )
    }

}

export default ContextEnhancer(CopyLinkToClipboard);
