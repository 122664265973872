import './ActionsDialog.css';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';

import Dialog from 'components/ui/Dialog';
import { BngTable } from 'components/bng/ui/BngTable';
import ContextEnhancer from 'components/ContextEnhancer';
import Button from 'components/ui/Button';
import { MODALS } from 'components/ui/redux/Actions';
import HttpActionDialog from 'components/bng/monitoring/HttpActionDialog';
import Api from 'components/Api';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import BngPopper from 'components/bng/ui/BngPopper';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import UiMsg from 'components/ui/UiMsg';
import { ScrollContainer } from 'components/ui/ScrollContainer';
import Icon from 'components/ui/common/Icon';

class ActionsDialog extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    rule: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    rule: { actions: [] },
  };

  state = {
    actions: _.cloneDeep(this.props.rule.actions),
    popperRef: null,
    rowMenuIdx: null,
    newActionMenuOpen: false,
  };

  componentDidMount() {
    window.__ActionsDialogNotifyChange = (action) => {
      const { actions } = this.state;
      const idx = actions.findIndex((a) => a.uuid === action.uuid);
      if (idx === -1) {
        actions.push(action);
      } else {
        actions[idx] = action;
      }
      this.setState({ actions: Array.from(actions) });
    };
  }

  removeAction = async (idx) => {
    try {
      const { actions } = this.state;
      const action = actions[idx];
      if (action.type.includes('Message')) {
        await Api.executeExp(`#{centralMonitoringMB.monitorCRUD.removeAction('${action.uuid}')}`);
      }
      actions.splice(idx, 1);
      this.setState({ actions: Array.from(actions) });
      this.closeRowMenu();
    } catch (e) {
      console.error(e);
      UiMsg.error(null, e);
    }
  };

  editAction = (row, idx) => {
    try {
      if (row.type === 'MessageAction') {
        Api.executeExp(`#{centralMonitoringMB.monitorCRUD.editMessageAction('${row.uuid}')}`);
      } else {
        this.props.dispatch(
          MODALS.open(HttpActionDialog, {
            action: _.cloneDeep(row),
            onSave: ({ values }) => {
              const { actions } = this.state;
              actions[idx] = values;
              this.setState({ actions: Array.from(actions) });
            },
          })
        );
      }
      this.closeRowMenu();
    } catch (e) {
      console.error(e);
      UiMsg.error(null, e);
    }
  };

  newAction = (type) => {
    try {
      if (type === 'MessageAction') {
        Api.executeExp(`#{centralMonitoringMB.monitorCRUD.newMessageAction()}`);
      } else {
        this.props.dispatch(
          MODALS.open(HttpActionDialog, {
            onSave: ({ values }) => {
              const actions = (this.state.actions ?? []).slice();
              actions.push(values);
              this.setState({ actions });
            },
          })
        );
      }
      this.closeRowMenu();
    } catch (e) {
      console.error(e);
      UiMsg.error(null, e);
    }
  };

  updateActions = async (event) => {
    try {
      event.preventDefault();
      const httpActions = this.state.actions.filter((a) => !a.type.include('Message'));
      httpActions.forEach((action) => {
        if (!action.id) {
          delete action.id;
        }
      });
      await Api.CentralMonitoring.updateActions(httpActions);
      await Api.updateJsf();
      this.props.closeModal();
    } catch (e) {
      console.error(e);
      UiMsg.error(null, e);
    }
  };

  closeRowMenu = () =>
    this.setState({
      popperRef: null,
      rowMenuIdx: null,
      newActionMenuOpen: false,
    });

  getColDef = memoizeOne(() => {
    return [
      { label: 'Id', size: 75, render: (row) => row.id },
      { label: this.props.context.msg.t('name'), render: (row) => row.name },
      {
        label: this.props.context.msg.t('type'),
        size: 120,
        render: (row) => this.props.context.msg.t(row.type),
      },
      {
        label: '',
        size: 75,
        render: (row, idx) => {
          let open = this.state.rowMenuIdx === idx;
          return (
            <div className="drop-button text-center">
              <Icon
                className={`icon-dropdown ${open ? 'open' : ''}`}
                icon="more_vert"
                onClick={(event) => {
                  this.setState({
                    popperRef: event.currentTarget,
                    rowMenuIdx: idx,
                  });
                }}
              />
              <BngPopper
                className="ActionsDialog bng-dropdown-parent"
                open={open}
                anchorEl={this.state.popperRef}
                modifiers={{
                  preventOverflow: {
                    boundariesElement: j('.page-content')[0],
                  },
                }}
              >
                <BngClickOutsideOverlay className="ActionsDialog" onClick={this.closeRowMenu} />
                <ul className="bng-dropdown">
                  <li onClick={() => this.editAction(row, idx)}>
                    <Icon icon="edit" className="mr-1" />
                    {this.props.context.msg.t('edit')}
                  </li>
                  <li onClick={() => this.removeAction(idx)}>
                    <Icon icon="delete" className="mr-1" />
                    {this.props.context.msg.t('remove')}
                  </li>
                </ul>
              </BngPopper>
            </div>
          );
        },
      },
    ];
  });

  render() {
    return (
      <Dialog
        className={`ActionsDialog large ${this.props.className}`}
        title={this.props.context.msg.t('actions')}
        onClose={this.props.closeModal}
        newDialogLayout={true}
      >
        <form onSubmit={this.updateActions}>
          <Dialog.Body>
            <div className="text-right margin-bottom-default">
              <Button
                icon={'add_circle'}
                className={`bng-button borderless cancel action NewActionBtn ${
                  this.state.newActionMenuOpen ? 'open' : ''
                }`}
                onClick={(event) => {
                  this.setState({
                    popperRef: event.currentTarget,
                    newActionMenuOpen: true,
                  });
                }}
              >
                {this.props.context.msg.t('new.action')}
              </Button>
              <BngPopper
                className="ActionsDialog bng-dropdown-parent"
                open={this.state.newActionMenuOpen}
                anchorEl={this.state.popperRef}
                modifiers={{
                  preventOverflow: {
                    boundariesElement: j('.page-content')[0],
                  },
                }}
              >
                <BngClickOutsideOverlay className="ActionsDialog" onClick={this.closeRowMenu} />
                <ul className="bng-dropdown">
                  <li onClick={() => this.newAction('HttpRequestAction')}>
                    <Icon icon="http" className="mr-1" />
                    {this.props.context.msg.t('HttpRequestAction')}
                  </li>
                  <li onClick={() => this.newAction('MessageAction')}>
                    <Icon icon="message" className="mr-1" />
                    {this.props.context.msg.t('MessageAction')}
                  </li>
                </ul>
              </BngPopper>
            </div>

            <ScrollContainer>
              <BngTable rows={this.state.actions} cols={this.getColDef()} />
            </ScrollContainer>
          </Dialog.Body>

          <Dialog.Footer>
            <DefaultDialogActions {...this.props} />
          </Dialog.Footer>
        </form>
      </Dialog>
    );
  }
}

export default ContextEnhancer(connect()(ActionsDialog));
