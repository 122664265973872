import './AnalysisMultiStepInfo.css';
import ANALYSIS_IMAGE from 'components/bng/pages/newAnalysis/assets/headerBackground.png';
const HISTORIC_EVOLUTION = `${Api.baseUrl()}/images/Evolucao_historica.png`;
const BEST_RANKING = `${Api.baseUrl()}/images/RankingMelhores.png`

import React from 'react';

import BngMultiStep from 'components/bng/ui/BngMultiStep';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';

const AnalysisMultiStepInfo = ({
                                   projectId,
                                   hasWatched = false,
                                   context,
                                   onFinish = _.noop,
                                   prepend = null
                               }) => {
    const handleNextClick = async ({isLastStep}) => {
        if (isLastStep) {
            try {
                if (!hasWatched) {
                    await Api.UserParam.saveUserKey({
                        key: Api.UserParam.Keys.Analysis.WatchedNewAnalysis,
                        value: 'true',
                        projectId
                    })
                }
                onFinish();
            } catch (e) {
                console.error(e)
            }
        }
    }

    return (
        <BngMultiStep className="AnalysisMultiStepInfo"
                      prepend={prepend}
                      onNextClick={handleNextClick}
                      overlay={false}>
            <BngMultiStep.Step>
                <iframe className="Image IntroVideo"
                        src={`${context.msg.t('new.analysis.help.video.url')}?autoplay=1&rel=0&iv_load_policy=3`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
                <BngMultiStep.Title>
                    {context.msg.t('new.analysis.slide1.title')}
                </BngMultiStep.Title>
                <BngMultiStep.Subtitle>
                    {context.msg.t('new.analysis.slide1.subtitle')}
                </BngMultiStep.Subtitle>
            </BngMultiStep.Step>
            <BngMultiStep.Step>
                <img className="Image"
                     src={HISTORIC_EVOLUTION}
                     alt={context.msg.t('new.analysis.slide2.title')}/>
                <BngMultiStep.Title>
                    {context.msg.t('new.analysis.slide2.title')}
                </BngMultiStep.Title>
                <BngMultiStep.Subtitle>
                    {context.msg.t('new.analysis.slide2.subtitle')}
                </BngMultiStep.Subtitle>
            </BngMultiStep.Step>
            <BngMultiStep.Step>
                <img className="Image"
                     src={BEST_RANKING}
                     alt={context.msg.t('new.analysis.slide3.title')}/>
                <BngMultiStep.Title>
                    {context.msg.t('new.analysis.slide3.title')}
                </BngMultiStep.Title>
                <BngMultiStep.Subtitle>
                    {context.msg.t('new.analysis.slide3.subtitle')}
                </BngMultiStep.Subtitle>
            </BngMultiStep.Step>
            <BngMultiStep.Step>
                <img className="Image PurpleBackground"
                     src={ANALYSIS_IMAGE}
                     alt={context.msg.t('new.analysis.slide4.title')}/>
                <BngMultiStep.Title>
                    {context.msg.t('new.analysis.slide4.title')}
                </BngMultiStep.Title>
                <BngMultiStep.Subtitle>
                    {context.msg.t('new.analysis.slide4.subtitle')}
                    <a href={context.msg.t('documentation.create.analysis')} rel="noreferrer" target="_blank">
                        {context.msg.t('new.analysis.help.link.description')}
                    </a>
                </BngMultiStep.Subtitle>
            </BngMultiStep.Step>
        </BngMultiStep>
    );
};

export default ContextEnhancer(AnalysisMultiStepInfo);
