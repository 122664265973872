import './BngAnalystMenu.css';

import React from 'react';
import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import {
  BngEnableAdvancedModeButton,
  useLoadingListener,
} from 'components/bng/pages/newAnalysis/BngEnableAdvancedModeButton';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import BngAssistedAnalysisForm from 'components/bng/pages/newAnalysis/BngAssistedAnalysisForm';
import NewAnalysisService from 'components/bng/pages/newAnalysis/NewAnalysisService';
import useBimContext from 'components/hooks/useBimContext';
import { Axios } from 'commonUtils';
import Api from 'components/Api';

export function BngAnalystMenuForm({ type, assistedData, ...props }) {
  return (
    <BngAssistedAnalysisForm
      type={type}
      editing={true}
      initialFormValues={assistedData}
      service={NewAnalysisService}
      {...props}
    />
  );
}

export default function BngAnalystMenu({ type, assistedData }) {
  const context = useBimContext();
  const { loading, listener } = useLoadingListener();

  const showAdvancedMode = assistedData.type !== 'BUBBLE_CHART' || assistedData.type !== 'TREE_MAP';

  return (
    <UiBlocker className="BngAnalystMenu" block={loading}>
      <AccordionList>
        <Accordion title={context.msg.t('parameters')} startOpen={true}>
          <BngAnalystMenuForm
            type={type}
            assistedData={assistedData}
            afterUpdate={async ({ $formik, values }) => {
              const data = await Axios.getData('/spr/ui/analysis/chart-conf');
              await Axios.postData('/spr/ui/analysis/chart-conf', {
                enabled: data.enabled,
                confs: data.conf,
              });

              await Api.updateJsf();
              $formik.resetForm({ values });
            }}
          />
        </Accordion>

        {showAdvancedMode && (
          <Accordion title={context.msg.t('advanced')} startOpen={false}>
            <div>
              <BngEnableAdvancedModeButton assistedType={assistedData.type} listener={listener} />
            </div>
          </Accordion>
        )}
      </AccordionList>
    </UiBlocker>
  );
}
