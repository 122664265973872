export class Dataset {

    constructor(data) {
        Object.assign(this, data.result);
    }

    getAxisTupleSize(axis) {
        return _.get(axis, 'tuples[0].elements.length', 0);
    }

    toTree({axis}) {
        const tupleSize = this.getAxisTupleSize(axis);
        if (tupleSize === 0) {
            return {};
        }
        const {tuples} = axis;
        const numberOfGroups = tupleSize - 1;
        const groups = _.range(numberOfGroups).map(i => `elements[${i}].id`);
        const groupedData = nestFn(tuples, groups);

        const processGroupedData = (current, path = [], elements = {}, parents = []) => {
            if (_.isArray(current)) {
                let process = true;
                current.forEach(({elements}) => {
                    if (process) {
                        process = false;
                        for (let i = 0; i < parents.length; i++) {
                            const obj = elements[i];
                            const parent = parents[i];
                            parent.caption = obj.caption;
                            parent.name = obj.caption;
                        }
                    }
                    const obj = elements[numberOfGroups];
                    if (parents.length !== 0) {
                        parents[parents.length - 1].children.push({
                            id: obj.id,
                            caption: obj.caption,
                            name: obj.caption,
                            value: 1
                        });
                    }
                });
            } else {
                for (const key in current) {
                    const newPath = [...path, key];
                    const elementsKey = newPath.join('>>>');
                    const childs = current[key];
                    let element = elements[elementsKey];
                    if (!element) {
                        element = {
                            id: key,
                            value: _.isArray(childs) ? childs.length : Object.keys(childs).length,
                            children: [],
                        };
                        elements[elementsKey] = element;
                        if (parents.length !== 0) {
                            parents[parents.length - 1].children.push(element);
                        }
                    }
                    processGroupedData(childs, newPath, elements, [...parents, element]);
                }
            }
            return elements;
        };


        return Object.entries(processGroupedData(groupedData)).filter(([key]) => !key.includes('>>>')).map(([key, val]) => val);
    }

}

const nestFn = (seq, keys) => {
    if (!keys.length) {
        return seq;
    }
    let [first, ...rest] = keys;
    return _.mapValues(_.groupBy(seq, first), value => nestFn(value, rest));
};
