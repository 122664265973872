import './MenuDashboard.css';
import React, {PureComponent} from 'react';
import PropType from 'prop-types';
import {connect} from "react-redux";
import ContextEnhancer from "components/ContextEnhancer";
import {MODALS} from "components/ui/redux/Actions";
import Icon from "components/ui/common/Icon";
import MenuDashboardMenuItemDialogContainer from "components/ui/common/MenuDashboardMenuItemDialogContainer";
import {AddToDashboardDialogContainer} from "components/ui/common/AddToDashboardDialog";

const MenuDashboardOption = ({
                                 onClick = _.noop,
                                 message = '',
                                 id = 'id',
                             }) => {
    return (
        <div id={`items-option-${id}`} className="items-option-dashboard" onClick={onClick}>
            <div className="item">
                <div className="item-add-dashboard">
                    <Icon icon="apps"/>
                    <div className="item-container-dashboard-label">
                        {message}
                    </div>
                </div>
            </div>
        </div>
    )
};

class MenuDashboardDropdown extends PureComponent {

    static propTypes = {
        path: PropType.string,
        openDialog: PropType.func,
        openDialogNewDashboard: PropType.func,
    };

    static defaultProps = {
        path: '',
        openDialog: _.noop,
        openDialogNewDashboard: _.noop,
    };

    constructor(props) {
        super(props);
    }

    buildDialogProps = () => {
        return {
            path: this.props.path,
            showTable: this.props.showTable,
            showChart: this.props.showChart,
            beanActiveTab: this.props.beanActiveTab
        };
    }

    addToDashboard = () => {
        const dialogProps = this.buildDialogProps();
        this.props.openDialog(dialogProps);
        this.props.closeDropdown();
    };

    newDashboardWithObject = () => {
        const dialogProps = this.buildDialogProps();
        this.props.openDialogNewDashboard(dialogProps);
        this.props.closeDropdown();
    };

    render() {
        return (
            <div id="item-container-dashboard" className="submenu item-container-dashboard">
                <div id="menu-text-dashboard" className="menu-text">
                    {this.props.context.msg.t('add.object.to.dashboard')}
                </div>
                <div className="item-container-dashboard-options">
                    <MenuDashboardOption id="addToDashboard"
                                         onClick={this.addToDashboard}
                                         message={this.props.context.msg.t('existing.dashboard')}/>
                    <MenuDashboardOption id="createNewDashboard"
                                         onClick={this.newDashboardWithObject}
                                         message={this.props.context.msg.t('dashboard_label_createNewDashboard')}/>
                </div>
            </div>
        );
    }
}

export default connect(
    () => ({}),
    dispatch => ({
        openDialog(props) {
            dispatch(
                MODALS.open(AddToDashboardDialogContainer, props)
            );
        },
        openDialogNewDashboard(props) {
            dispatch(
                MODALS.open(MenuDashboardMenuItemDialogContainer, props)
            );
        }
    })
)(ContextEnhancer(MenuDashboardDropdown));
