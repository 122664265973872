import { Axios } from 'commonUtils';

class DumpApi {
  findDeletedPaths = async (projectId) => {
    const data = await Axios.get(`/spr/dump`, {
      params: {
        projectId,
      },
    });
    return data.data;
  };

  removeDeletedPaths = async (paths) => {
    await Axios.post(`/spr/dump/deletePaths`, { paths });
  };

  removePathsForProject = async (projectId) => {
    await Axios.delete(`/spr/dump/projects/${projectId}`);
  };

  versionsFor = async (path) => {
    const result = await Axios.get(`/spr/dump/versions`, {
      params: {
        path,
      },
    });
    return result.data;
  };

  restoreVersion = async (versionData) => {
    const isId = _.isNumber(versionData);
    const result = await Axios.post(
      `/spr/dump/restore${isId ? `/${versionData}` : ''}`,
      isId ? undefined : versionData
    );
    return result.data;
  };
}

export default DumpApi;
