import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty'

const MATCHER = /{(.*?)}/g;
const WHITE_SPACES_MATCHER = / /g
const ROUTE_PARAM_FIELD_PREFIX = "rp_";

const useRoutePathTranslator = (endpoint) => {

    const [routePathParams, setRoutePathParams] = useState([])

    useEffect(() => {
        if (!endpoint) {
            return
        }
        const routePathParamsTemp = [];
        let result;

        while ((result = MATCHER.exec(endpoint)) !== null) {
            let routeWithoutSquareBrackets = result[1];
            let routeWithSquareBrackets = result[0]

            routePathParamsTemp.push({
                name: ROUTE_PARAM_FIELD_PREFIX + routeWithoutSquareBrackets.replace(WHITE_SPACES_MATCHER, ''),
                label: routeWithoutSquareBrackets,
                originalName: routeWithSquareBrackets
            })
        }
        setRoutePathParams(routePathParamsTemp);
    }, [endpoint])

    const translateRoutePath = (values) => {
        try {
            if (isEmpty(routePathParams) || isEmpty(values))
                return endpoint

            let routeTranslated = endpoint;
            routePathParams.forEach(routeParam => {
                const routeParamValue = values.find(value => value[ROUTE_PARAM_FIELD_PREFIX + routeParam.name])
                routeTranslated = routeTranslated.replace(routeParam.originalName, routeParamValue, '');
            })
            return routeTranslated
        } catch (e) {
            return endpoint
        }
    }

    return [routePathParams, translateRoutePath]
};

export default useRoutePathTranslator;
