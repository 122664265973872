import './WsBar.css';

import React from 'react';
import PropTypes from 'prop-types';

import Dialog from "components/ui/Dialog";

class WsBar extends React.Component {

    static propTypes = {
        help: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
        href: PropTypes.string,
        className: PropTypes.string,
        loading: PropTypes.bool,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {
            open,
            title,
            onClose,
            help,
            href,
            icon,
            className,
            loading
        } = this.props;

        return (
            <Dialog open={open}
                    title={title}
                    className={`${className} large`}
                    onClose={onClose}
                    loading={loading}>
                <div>
                    <div className="span12 bar-style">
                        <img id="img" className="img" src={icon}/>
                        <span className="titlebox">
                            {title}
                        </span>
                        <div className="pipe-help-link-box">
                            <a id="titlehelp"
                               className="titleredirect" target="_blank" rel="noreferrer" href={href}>
                                {help}
                            </a>
                        </div>
                    </div>
                </div>
                {this.props.children}
            </Dialog>
        );
    }
}

export default WsBar;