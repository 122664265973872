import React from 'react';

import AccordionList from "components/ui/AccordionList";
import Accordion from "components/ui/Accordion";
import BngCheckbox from "components/bng/form/BngCheckbox";
import ColumnConfigurationDialog from "components/bng/pages/bigTable/dialogs/ColumnConfigurationDialog";
import {BngSelectSearch} from "components/bng/form/BngSelectSearch";
import BngDraggable from "components/bng/ui/BngDraggable";
import BngInputSpinner from "components/bng/form/BngInputSpinner";
import Icon from "components/ui/common/Icon";
import {iconForDimensionType} from "components/bng/pages/bigTable/functions";
import Api from 'components/Api';

const CACHE = window.__BngBigTableFormCache;

const extractDimensionName = (dim) => {
    if (dim) {
        const idx = dim.indexOf('.');
        if (idx !== -1) {
            dim = dim.slice(0, idx);
        }
    }
    return dim;
};

class ColumnsTab extends React.Component {

    state = {
        showModal: false,
        selectedColumnIndex: null,
        globalFilters: [],
        cube: null,
    };

    async componentDidMount() {
        const projectId = this.props.context.project.id;
        const globalFilters = await Api.MdxGlobalFilter.findAll(projectId, true);
        const cacheKey = `sourceCubes:${this.props.datasourceName}`;
        if (!CACHE.hasOwnProperty(cacheKey)) {
          CACHE[cacheKey] = await Api.Project.sourceCubes({
            projectId: projectId,
            sourceName: this.props.datasourceName,
          });
        }
        this.setState({
          globalFilters,
          cube: CACHE[cacheKey],
        });
    }

    getPaginationOptions() {
        return [
            {value: 'none', label: this.props.context.msg.t('pagination.none')},
            {value: 'auto', label: this.props.context.msg.t('pagination.auto')},
            {value: 'fixed', label: this.props.context.msg.t('pagination.fixed')}
        ];
    }

    onGridConfigChange(field, value) {
        let next = {
            ...this.props.gridConfig,
            [field]: value
        };
        this.props.onGridConfigChange(next);
    }

    findFiltersForDim = (dim) => {
        if (!dim) {
            return [];
        }
        dim = extractDimensionName(dim);
        return this.state.globalFilters.filter((globalFilter) =>
          globalFilter.mdxFilters.some(
            (mf) =>
              mf.datasource === this.props.datasourceName &&
              mf.cube === this.state.cube[0].value &&
              (mf.dimension === dim || mf.dimension === `BIMF${dim}`)
          )
        );
    };

    render() {
        const {
            columns,
            gridConfig,
            measureColumns,
            onColumnsOrderChange,
            onColumnChange,
            onResetColumn,
            toggleColumn
        } = this.props;

        const columnsItems = columns.map((col, index) => (
            <div key={col.key} className="BngBigTable__fieldList__field">
                <Icon icon={iconForDimensionType(col.fieldType)} className='structure-icon'/>
                <span>{col.title}</span>
                <div className="BngBigTable__fieldList__field--container">
                    <BngCheckbox
                        field={{value: col.showColumn}}
                        onChange={_ => toggleColumn(index)}
                    />
                    <div>
                        <i className={`material-icons BngBigTable__dataform-icon ${col.customized ? 'active' : ''}`}
                           onClick={() => this.setState({showModal: true, selectedColumnIndex: index})}>{'settings'}</i>
                    </div>
                </div>
            </div>
        ));

        const selectedColumn = columns[this.state.selectedColumnIndex];

        return (
            <>
                <AccordionList className="BngBigTable__menuTab ObjectRightMenuAccordion">
                    <Accordion title={this.props.context.msg.t('columns')} startOpen={true}>
                        <AccordionList>
                            <div className="BngBigTable__ColumnsMenu BngBigTable__fieldList" style={{margin: 10}}>
                                <BngDraggable items={columnsItems}
                                              onChangeOrder={(columns, change) => onColumnsOrderChange(change)}/>
                            </div>
                        </AccordionList>
                    </Accordion>

                    <Accordion title={this.props.context.msg.t('pagination')} startOpen={false}>
                        <div style={{margin: 10}}>
                            <label className="control-label">{this.props.context.msg.t('pagination')}</label>
                            <BngSelectSearch
                                clearable={false}
                                options={this.getPaginationOptions()}
                                form={{
                                    setFieldValue: (name, t) => this.onGridConfigChange('pagination', t)
                                }}
                                field={{value: gridConfig.pagination, onChange: _.noop}}
                            />

                            {gridConfig.pagination === 'fixed' && (
                                <div className="mt-4">
                                    <label className="control-label">
                                        {this.props.context.msg.t('page_size')}
                                    </label>
                                    <BngInputSpinner
                                        min={10}
                                        max={10000}
                                        step={10}
                                        allowNegative={false}
                                        value={gridConfig.pageSize}
                                        onChange={val => this.onGridConfigChange('pageSize', val)}
                                    />
                                </div>
                            )}
                        </div>
                    </Accordion>
                </AccordionList>

                {this.state.showModal && (
                    <ColumnConfigurationDialog
                        open={true}
                        column={selectedColumn}
                        measureColumns={measureColumns}
                        onReset={() => {
                            onResetColumn(this.state.selectedColumnIndex);
                            this.setState({showModal: false});
                        }}
                        onClose={() => this.setState({showModal: false})}
                        onChange={(data) => onColumnChange(this.state.selectedColumnIndex, data)}
                        globalFilters={this.findFiltersForDim(selectedColumn.key)}
                    />
                )}
            </>
        );
    }
}

export default ColumnsTab;