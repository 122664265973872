import './DashboardPage.module.css';

import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import CockpitDashboardRender from 'components/bng/pages/cockpit/renders/CockpitDashboardRender';
import DashBreadcrumbToolbar from 'components/ui/dashboard/components/DashBreadcrumbToolbar';
import BimEventBus from 'BimEventBus';
import EditObject from 'components/ui/edit-object/EditObject';
import DashboardPageRightMenu from 'bng/pages/dashboard/DashboardPageRightMenu';
import { ContainerCreator } from 'components/ui/dashboard/components/ContainerCreator';
import { transformLayoutToPersist } from 'components/ui/dashboard/components/common';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import MoveObject from 'components/ui/dashboard/components/MoveObject';
import { handleConfigSubmit } from 'components/ui/right-menu/items/GeneralConfigMenuItem';
import useEventBus from 'components/hooks/useEventBus';
import { ON_OPEN_OBJECT_EVENT } from 'components/ui/dashboard/components/IconText';
import DashGridEditor from 'components/ui/dashboard/components/DashGridEditor';
import { useGlobalLoader } from 'bng/common/GlobalLoader';
import FilterService from 'components/filter/FilterService';

const redirectToPath = (content = '') => {
  const url = Api.loadObjectUrl({ content });
  setTimeout(() => {
    window.location.replace(url);
  }, 800);
};

const printerContainsDifference = (persistedPrinter = {}, printer = {}) => {
  if (Object.keys(printer).length === 0) {
    return false;
  }

  return Object.entries(printer).some(([key, value]) => {
    return persistedPrinter[key] !== value;
  });
};

const containObjectConfigDifferences = (dashData, formik) => {
  const objectConfig = formik?.values?.objectConfig;
  const printer = objectConfig?.printer;

  return (
    (!_.isEmpty(dashData) && !_.isEmpty(objectConfig)
      ? dashData.caption !== objectConfig.name ||
        dashData.folder !== objectConfig.folder ||
        dashData.description !== objectConfig.description
      : false) ||
    (!_.isEmpty(printer) && !_.isEmpty(dashData?.printerConfig)
      ? printerContainsDifference(dashData.printerConfig, printer)
      : false)
  );
};

const SAVE_DASHBOARD_EVENT = 'DashboardPage:saveDashboard';

export default function DashboardPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useBimContext();
  const { t } = useTranslation();
  const $globalLoader = useGlobalLoader();
  const $rightMenuFormik = useRef();

  const {
    dash: dashData,
    changes: dashChanges,
    addChange,
    editContainerProps,
    editContainer,
    moveItemProps,
    moveItem,
    loading: isLoading,
    dashKey,
    updateDashKey,
  } = useDashboardPageCtx.cached((state) =>
    _.pick(state, [
      'dash',
      'changes',
      'addChange',
      'editContainerProps',
      'editContainer',
      'moveItemProps',
      'moveItem',
      'loading',
      'dashKey',
      'updateDashKey',
    ])
  );

  const path = searchParams.get('content') || searchParams.get('path');
  const userCanEdit = context.permissions.canCreateObjects() && context.permissions.isPathWritable(path);
  const editMode = userCanEdit && (searchParams.get('edit') || 'false') === 'true';
  const viewType = (editMode ? searchParams.get('viewType') : null) || 'DESKTOP';
  const initialFiltersRef = searchParams.get('_dsf') || searchParams.get('filterRef') || '';

  useEffect(() => {
    if (
      !dashData ||
      dashData.gridData.layouts[viewType] ||
      dashChanges.some((c) => c.type === 'RESET_MOBILE_LAYOUT' && c.data.breakpoint === viewType)
    ) {
      return;
    }

    addChange({
      type: 'RESET_MOBILE_LAYOUT',
      data: {
        breakpoint: viewType,
      },
    });
  }, [dashData, viewType]);

  useEventBus(ON_OPEN_OBJECT_EVENT, async ({ path, forceFilters, filterRef, dashboardFilters }) => {
    const _dsf = await FilterService.createFilterRef(dashboardFilters);
    const url = Api.loadObjectUrl({
      content: path,
      breadcrumb: true,
      forcefilters: forceFilters,
      filterRef,
      _dsf,
    });
    window.location.replace(url);
  });

  const saveDashboard = async (opts = { showSuccessMsg: true }) => {
    await $globalLoader.runTask(async () => {
      try {
        let validationResult = {
          proceed: true,
          newPath: path,
          renamed: false,
        };

        const containConfigDifferences = containObjectConfigDifferences(dashData, $rightMenuFormik.current);

        if (containConfigDifferences) {
          validationResult = await handleConfigSubmit(
            {
              ...dashData,
              path,
              context,
              name: dashData.caption,
            },
            {
              ...$rightMenuFormik.current.values.objectConfig,
              projectId: context.project.id,
            },
            $rightMenuFormik.current,
            { muteMessages: true }
          );
        }

        if (!validationResult.proceed) {
          return;
        }

        if (dashChanges.length > 0) {
          await Api.Dash.save({
            path: validationResult.newPath || path,
            projectId: context.project.id,
            changes: dashChanges,
            configChanged: containConfigDifferences,
          });
        }

        if (opts.showSuccessMsg) {
          UiMsg.ok(t('save_success', t('dashboard')));
        }

        if (validationResult.renamed) {
          redirectToPath(validationResult.newPath);
        } else {
          useDashboardPageCtx.setState({ changes: [] });
        }
      } catch (e) {
        console.error('Error on saving dashboard()', e);
        UiMsg.ajaxError(null, e);
      }
    });
  };

  useEventBus(SAVE_DASHBOARD_EVENT, saveDashboard, [saveDashboard]);

  return (
    <div className={`DashboardPage ${editMode ? 'Editing' : ''}`}>
      {dashData && (
        <DashBreadcrumbToolbar
          path={dashData.dashboardPath}
          caption={dashData.caption}
          containItems={!_.isEmpty(dashData.dashboard.dashboardItems)}
          onResize={async () => {
            // Trigger resize on DashGrid
            BimEventBus.emit('CockpitButtons:HEADER_EXPANDED');
          }}
          onReload={async () => {
            useDashboardPageCtx.setState({ changes: [] });

            const objectConfig = _.cloneDeep($rightMenuFormik.current?.values?.objectConfig ?? {});
            objectConfig.name = dashData.caption;
            objectConfig.folder = dashData.folder;
            objectConfig.description = dashData.description;

            if (objectConfig.printer && dashData?.printerConfig) {
              Object.entries(dashData.printerConfig).forEach(([key, value]) => {
                objectConfig.printer[key] = value;
              });
            }
            $rightMenuFormik.current?.setFieldValue('objectConfig', objectConfig);

            updateDashKey();
          }}
          onExportToPdf={({ event }) => {
            event.preventDefault();
            BimEventBus.emit('BngCockpitButtons:ExportToPdf');
          }}
        >
          {userCanEdit && (
            <EditObject
              loading={isLoading}
              onChange={() => {
                if (editMode) {
                  searchParams.delete('edit');
                } else {
                  searchParams.set('edit', 'true');
                }
                setSearchParams(searchParams);

                // Resize DashGrid items
                window.requestAnimationFrame(() => {
                  window.dispatchEvent(new Event('resize'));
                });
              }}
              checked={editMode}
            />
          )}
        </DashBreadcrumbToolbar>
      )}

      <CockpitDashboardRender
        DashComponent={DashGridEditor}
        path={path}
        editMode={editMode}
        breakpointView={viewType}
        dashKey={dashKey}
        initialFiltersRef={initialFiltersRef}
        onCockpitCreation={!!editContainerProps}
        onMoveObject={!!moveItemProps}
        onLayoutChange={async ({ breakpoint, layout }) => {
          try {
            if (!dashData?.gridData) {
              return;
            }

            const currentLayout = transformLayoutToPersist(dashData.gridData.layouts[breakpoint]);
            const newLayout = transformLayoutToPersist(layout);
            if (_.isEqual(currentLayout, newLayout) || isLoading) {
              return;
            }

            await addChange({
              type: 'LAYOUT',
              data: { breakpoint, layout: newLayout, dashGridLayout: layout },
            });
          } catch (e) {
            console.error('Error onLayoutChange()', e);
            UiMsg.ajaxError(null, e);
          }
        }}
        onSaveFilters={async (filters) => {
          try {
            const filtersToAlert = filters.filter((f) => {
              return dashData.dashboard.requiredFilters.some((rf) => rf.enabled && rf.filterId === f.id);
            });

            if (filtersToAlert.length !== 0) {
              await Swal.fire({
                title: '',
                html: `<span>${t('filter.required.dont.save.info')}</span>${filtersToAlert
                  .map(
                    (f) =>
                      `<li style="width: fit-content;text-align: left;padding-top: 10px;">${f.caption || f.name}</li>`
                  )
                  .join()}`,
              });
            }

            await addChange({
              type: 'SAVE_INITIAL_FILTERS',
              data: {
                filters: filters.map((f) => ({
                  id: f.id,
                  members: f.selectedMembers.map((sm) => sm.value),
                })),
              },
            });

            BimEventBus.emit(SAVE_DASHBOARD_EVENT, { showSuccessMsg: false });
          } catch (e) {
            console.error('Error on onSaveFilters()', e);
            UiMsg.ajaxError(null, e);
          }
        }}
      />

      {editContainerProps && (
        <ContainerCreator
          layout={dashData.gridData.layouts[viewType]}
          onClose={() => editContainer()}
          onSave={async (values) => {
            try {
              await addChange({
                type: 'CONTAINER',
                data: values,
              });
            } catch (e) {
              console.error('Error onSave()', e);
              UiMsg.ajaxError(null, e);
            }
          }}
          {...editContainerProps}
        />
      )}
      {moveItemProps && (
        <MoveObject
          onClose={() => {
            moveItem();
          }}
          onSave={async (values) => {
            try {
              await addChange({
                type: 'MOVE_ITEM',
                updateDashKey: true,
                data: {
                  breakpoint: viewType,
                  ...values,
                },
              });
            } catch (e) {
              console.error('Error onSave()', e);
              UiMsg.ajaxError(null, e);
            }
          }}
          {...moveItemProps}
        />
      )}

      {dashData && (
        <DashboardPageRightMenu
          dashData={dashData}
          viewType={viewType}
          open={editMode}
          dirtyCheckFunction={() =>
            dashChanges.length > 0 || containObjectConfigDifferences(dashData, $rightMenuFormik.current)
          }
          onFormikRef={$rightMenuFormik}
          onSave={() => saveDashboard()}
          onSaveAs={async (values) => {
            await $globalLoader.runTask(async () => {
              try {
                const { path: newPath } = await Api.Dash.saveAs({
                  ...values,
                  changes: dashChanges,
                });
                UiMsg.ok(t('save_success', t('dashboard')));
                redirectToPath(newPath);
              } catch (e) {
                console.error('Error on saveAs()', e);
                UiMsg.ajaxError(null, e);
              }
            });
          }}
          onRemove={async () => {
            if (!path) {
              return;
            }

            await $globalLoader.runTask(async () => {
              try {
                await Api.Dash.remove(path);
                UiMsg.ok(t('remove_success', [t('dashboard')]));
                window.location.replace(`${Api.baseUrl()}/spr/bng/personal-page`);
              } catch (e) {
                console.error('Error on removing dashboard', e);
                UiMsg.ajaxError(null, e);
              }
            });
          }}
        />
      )}
    </div>
  );
}
