'use strict';

import { Axios } from 'commonUtils';

const $ = jQuery;

class FilterApi {
  dynamicPeriodicities = (grouped = true) => {
    return Promise.resolve($.getJSON(`/spr/filters/dynamic-periodicities?grouped=${grouped}`));
  };

  findMembers = async (filterId, { dashboard = null, allMembers = false } = {}) => {
    return await Axios.postData(`/spr/filters/${filterId}/members`, { dashboard, allMembers });
  };
}

export default FilterApi;
