import React, { useState } from 'react';
import BaseTableStep, { TableCheckbox } from 'components/bng/project/updateApps/BaseTableStep';
import { ACTION } from 'components/bng/project/updateApps/projectUpdateAppsCommons';
import useBimContext from 'components/hooks/useBimContext';

const toggleItem = (nextState, actions, row) => {
  if (nextState) {
    actions[row.targetPath] = {
      object: row,
      action: ACTION.REMOVE,
    };
  } else {
    delete actions[row.targetPath];
  }
};

function RemoveObjectsWizardStep({ rows, actions = {}, onChange }) {
  const { msg } = useBimContext();
  const [allCheckbox, setAllCheckbox] = useState(false);

  return (
    <BaseTableStep
      alertMessage={msg.t('project.update.app.dialog.remove.alert')}
      rows={rows}
      cols={[
        {
          key: 'remove',
          size: '122px',
          headerRender: () => {
            return (
              <TableCheckbox
                type={'danger'}
                label={msg.t('remove')}
                value={allCheckbox}
                onChange={() => {
                  const nextState = !allCheckbox;
                  const actionsCopy = {
                    ...actions,
                  };
                  rows.forEach((r) => toggleItem(nextState, actionsCopy, r));
                  setAllCheckbox(nextState);
                  onChange(actionsCopy);
                }}
              />
            );
          },
          render: (row) => {
            const selected = row.targetPath in actions;
            return (
              <TableCheckbox
                type={'danger'}
                label={msg.t('remove')}
                value={selected}
                onChange={() => {
                  const actionsCopy = {
                    ...actions,
                  };
                  toggleItem(!selected, actionsCopy, row);
                  onChange(actionsCopy);
                }}
                labelVisible={false}
              />
            );
          },
        },
      ]}
    />
  );
}

export default RemoveObjectsWizardStep;
