import styles from './BngColorNode.module.css';

import React from 'react';

export default function BngColorNode({
  nodeColor = {
    color: '#005dff',
    opacity: 1,
    position: 0,
    id: 0,
  },
  selectColor = _.noop,
  selectedColor = {},
}) {
  const selectedStyle = () => {
    if (selectedColor.color === nodeColor.color) {
      const semiTransparent = isSemiTransparent();
      return {
        width: 32,
        height: 32,
        borderWidth: 5,
        borderColor: semiTransparent ? 'rgba(0, 0, 0, 0.5)' : '',
        transform: semiTransparent ? '' : 'scale(1.1)',
      };
    }
  };

  const transparentSelectedStyle = () => {
    if (
      (isSemiTransparent() && _.isEqual(selectedColor, nodeColor)) ||
      (selectedColor.opacity === 0 && nodeColor.opacity === 0)
    ) {
      if (isSemiTransparent()) {
        return {
          transform: 'scale(1.1)',
        };
      } else {
        return {
          transform: 'scale(1.1)',
          filter: 'brightness(90%)',
        };
      }
    }
  };

  const isSemiTransparent = () => {
    return nodeColor.opacity > 0 && nodeColor.opacity < 1;
  };

  return (
    <div
      className={`BngColorNode ${nodeColor.opacity < 1 ? styles.transparentNode : styles.colorNodeWrapper}`}
      style={transparentSelectedStyle()}
    >
      <div
        className={`${isSemiTransparent() ? styles.semiTransparentNode : styles.colorNode}`}
        style={{
          backgroundColor: nodeColor.color,
          opacity: nodeColor.opacity,
          ...selectedStyle(),
        }}
        onClick={() => selectColor(nodeColor)}
      ></div>
    </div>
  );
}
