import React, {Component} from "react";
import {connect} from 'react-redux';
import Button from "components/ui/Button";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import {MODALS} from "components/ui/redux/Actions";
import FilterDialog from "components/filter/FilterDialog";

class TitleItems extends Component {

    static propTypes = {};

    static defaultProps = {
        onChange: _.noop,
        options: null,
        context: {},
        dimensionInfoMap: {},
    };

    state = {
        selectedField: ''
    };

    constructor(props) {
        super(props);
    }

    addField = () => {
        const value = this.state.selectedField;
        if (!value) return;
        if ((this.props.fields.getAll() || []).find(e => e.name === value)) return;
        this.props.fields.push({name: value});
        this.setState({selectedField: ''});
        this.props.onChange();
    };

    removeField = (idx) => {
        this.props.fields.remove(idx);
        this.props.onChange();
    };

    selectedFieldChanged = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    moveItem = (idx, idxc) => {
        const {fields} = this.props;
        const size = fields.length - 1;
        let result = idx + idxc;
        if (result < 0) {
            result = size;
        } else if (result > size) {
            result = 0;
        }
        this.props.fields.move(idx, result);
    };

    openSelectHierarchyDialog = async (titleField, idx) => {
        const timeDimension = false
        const hierarchies = true;
        let queryParams = {
            projectName: this.props.context.project.name,
            datasource: this.props.datasource,
            cube: this.props.datasource,
            dimension: titleField.name,
            hierarchy: titleField.hierarchy,
            timeDimension: false
        }

        try {
            const membersInfo = await Api.BigTable.filterMembersInfo(queryParams, true);
            this.props.dispatch(
                MODALS.open(FilterDialog, {
                    maxElements: 100,
                    enableModeChange: timeDimension,
                    items: !timeDimension ? membersInfo : [],
                    dynamicItems: timeDimension ? membersInfo : [],
                    filter: {
                        type: (timeDimension && !hierarchies) ? 'TIME' : 'REGULAR',
                        filterMode: hierarchies ? 'NORMAL' : undefined,
                        caption: this.props.dimensionInfoMap[titleField.name]?.label || titleField.name,
                        filterType: (timeDimension || hierarchies) ? 'SINGLE_SELECTION' : 'MULTIPLE_SELECTION',
                        selectedMembers: queryParams.hierarchy ? [{value: queryParams.hierarchy}] : [],
                        restrictionType: 'SHOW_SELECTED'
                    },
                    restrictionType: 'SHOW_SELECTED',
                    containRestriction: false,
                    restrictionMembers: [],
                    dataRestrictionMembers: [],
                    onChange: (data, force, {closeModal}) => {
                        this.props.fields.insert(idx, {...titleField, hierarchy: data?.[0]?.value ?? ''});
                        this.props.fields.remove(idx + 1);
                        closeModal();
                    }
                })
            );
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const {fields, options, context, dimensionInfoMap} = this.props;
        const {selectedField} = this.state;

        const items = fields.getAll() || [];
        return (
            <div>
                <div className="row-fluid">
                    <div className="span9">
                        <select name="selectedField"
                                className="fill-w" onChange={this.selectedFieldChanged}>
                            <option value="">{context.msg.t('select.one')}</option>
                            {options}
                        </select>
                    </div>
                    <div className="span3 text-center">
                        <Button className="btn-block add-tooltip-btn btn-small"
                                disabled={!selectedField}
                                onClick={this.addField}>
                            {context.msg.t('add')}
                        </Button>
                    </div>
                </div>

                <div>
                    <table className="table table-striped table-hover table-condensed table-bordered">
                        <colgroup>
                            <col span="1" style={{width: '500px'}}/>
                            <col span="1" style={{width: '100px', textAlign: 'right !important'}}/>
                        </colgroup>
                        <tbody>
                        {items.map((item, idx) => {
                            const itemInfo = dimensionInfoMap[item.name];
                            return (
                                <tr key={idx}>

                                    <td className="no-wrap">
                                        <b>{itemInfo?.label || item.name}</b>
                                    </td>

                                    <td className="ta-r no-wrap">
                                        {itemInfo?.type === 'TimeDimension' &&
                                        <>
                                            <Button icon="query_builder"
                                                    className={`btn-mini btn-table btn-${item.hierarchy ? 'blue' : 'light'}`}
                                                    onClick={() => this.openSelectHierarchyDialog(item, idx)}
                                                    title={context.msg.t('hierarchy')}
                                            />
                                            {' '}
                                        </>
                                        }
                                        <Button icon="icon-chevron-up"
                                                className="btn-mini btn-table btn-success"
                                                onClick={() => this.moveItem(idx, -1)}
                                        />
                                        {' '}
                                        <Button icon="icon-chevron-down"
                                                className="btn-mini btn-table btn-warning"
                                                onClick={() => this.moveItem(idx, 1)}
                                        />
                                        {' '}
                                        <Button icon="icon-remove"
                                                className="btn-mini btn-table btn-danger"
                                                onClick={() => this.removeField(idx)}/>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default connect()(ContextEnhancer(TitleItems));
