import './BngAvatar.css';

import React from 'react';
import PropTypes from 'prop-types';
import Api from "components/Api";
import Icon from "components/ui/common/Icon";

const t = Date.now();

export function BngAvatar({className = '', userId = 0, isGroup = false, icon}) {

    const [error, setError] = React.useState(false);

    const iconOnly = isGroup || !!icon;
    return (
        <div className={`BngAvatar ${className}`}>
            {iconOnly ? (
                <Icon icon={icon ?? 'group'}/>
            ) : (
                <React.Fragment>
                    {error &&
                        <Icon icon="person"/>
                    }
                    {!error &&
                        <img src={`${Api.baseUrl()}/avatar?${j.param({"user-id": userId, t})}`}
                             alt={`User ${userId} Avatar`}
                             onError={e => setError(true)}
                        />
                    }

                </React.Fragment>
            )}
        </div>
    );
}

BngAvatar.propTypes = {
    className: PropTypes.string,
    userId: PropTypes.any,
    isGroup: PropTypes.bool,
};

export default BngAvatar;