import { useMemo } from 'react';
import LineColorPicker from 'components/bng/pages/bigTable/components/LineColorPicker';
import useFetchData from 'components/hooks/useFetchData';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';

export function useColorOptions({fetchProjectColors = false, projectId = 0, options = []}) {
  const $themePalette = useFetchData(
    async ([]) => {
      if (!fetchProjectColors) {
        return [];
      }
      const theme = await Api.Project.findTheme(projectId);
      return theme.colorPalette.map((c) => c.color).slice(0, 6);
    },
    [projectId]
  );

  return useMemo(() => {
    if (fetchProjectColors) {
      return $themePalette.data ?? [];
    }

    if (!_.isString(options?.[0])) {
      return options.map((opt) => opt.value);
    }
    return options;
  }, [options, $themePalette.data]);
}

export default function BngLineInputColor({ field, form, options = [], fetchProjectColors = false, ...props }) {
  const context = useBimContext();
  const opts = useColorOptions({
    projectId: context.project.id,
    fetchProjectColors,
    options,
  });

  return (
    <LineColorPicker
      value={field.value}
      onChange={(color) => {
        form.setFieldValue(field.name, _.isString(color) ? color : color.value);
      }}
      options={opts}
      {...props}
    />
  );
}
