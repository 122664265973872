import './ProfileDialog.css';
import React, {Component} from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "../../ContextEnhancer";
import {ScrollContainer} from "../ScrollContainer";
import Activity from "../recent-activities/Activity";

class RecentActivitiesTab extends Component {

    static propTypes = {
        activities: PropTypes.object,
    };

    static defaultProps = {
        activities: {list: []},
    };

    state = {};

    constructor(props){
        super(props);
    }

    async componentDidMount() {
        if(!this.props.activities.initialized){
            await this.props.getActivities();
        }
    }

    render(){
        return (
            <div style={{maxHeight: 400, height: 400}}>
                {this.props.activities.list.length === 0 ?
                    <div style={{textAlign: 'center', lineHeight: '390px'}}>
                        {this.props.context.msg.t('user.dialog.without.recent.activities')}
                    </div> :

                    <ScrollContainer className="widget-body-inner">
                        {this.props.activities.list.map(activity => (
                            <Activity activity={activity} key={activity.id}/>
                        ))}

                    </ScrollContainer>
                }
            </div>
        );
    }
}

export default ContextEnhancer(RecentActivitiesTab);