import './HttpActionDialog.css';
import React from 'react';
import PropTypes from 'prop-types';
import { FastField, Field, FieldArray, Formik } from 'formik';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';

import Dialog from 'components/ui/Dialog';
import BngSelect from 'components/bng/form/BngSelect';
import { Tab, TabSet } from 'components/ui/TabSet';
import BngTable from 'components/bng/ui/BngTable';
import ContextEnhancer from 'components/ContextEnhancer';
import BngField from 'components/bng/form/BngField';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { BngCodeMirror } from 'components/bng/form/BngCodeMirror';
import UiMsg from 'components/ui/UiMsg';
import Button from 'components/ui/Button';
import bngYup from 'components/bng/form/yup/BngYup';
import { ScrollContainer } from 'components/ui/ScrollContainer';
import Api from 'components/Api';
import { MODALS } from 'components/ui/redux/Actions';
import BngForm from 'components/bng/form/BngForm';
import useBimContext from 'components/hooks/useBimContext';

const ParamSchema = bngYup((yup) => {
  return yup.object().shape({
    key: yup.string().max(256).trim().default(''),
    value: yup.string().trim().default(''),
    description: yup.string().nullable().trim().default(''),
  });
});

const HttpRequestActionSchema = bngYup((yup) => {
  return yup.object().shape({
    id: yup.number().integer().nullable(),
    name: yup.string().max(256).trim().default(''),
    type: yup.string().default('HttpRequestAction'),
    uuid: yup.string().default(''),
    method: yup.string().required().default('POST'),
    url: yup.string().required().default(''),
    queryParams: yup.array().of(ParamSchema).default([]),
    headers: yup.array().of(ParamSchema).default([]),
    body: yup.object().shape({
      type: yup.string().default('JSON'),
      content: yup.string().default(''),
      formData: yup.array().of(ParamSchema).default([]),
    }),
  });
});

function KvTable({ name, rows }) {
  const context = useBimContext();
  return (
    <FieldArray name={name}>
      {(arrayHelpers) => {
        return (
          <div className="KvTable">
            <div className={'text-right'}>
              <Button
                icon={'add_circle'}
                className={`AddButton bng-button borderless cancel fix action`}
                onClick={() => arrayHelpers.push(ParamSchema.default())}
              >
                {context.msg.t('add')}
              </Button>
            </div>
            <BngTable
              className="KvTable"
              rows={rows}
              cols={['key', 'value', 'description']
                .map((property) => ({
                  label: context.msg.t(property),
                  render: (row, idx) => (
                    <FastField name={`${name}[${idx}].${property}`} component={BngField} label={BngField.LABEL_EMPTY} />
                  ),
                }))
                .concat({
                  render(row, idx) {
                    return <Button className="btn-only-icon" icon="delete" onClick={() => arrayHelpers.remove(idx)} />;
                  },
                })}
            />
          </div>
        );
      }}
    </FieldArray>
  );
}

function HttpTestResultDialog({ testResult, ...props }) {
  const context = useBimContext();
  const success = testResult.hasOwnProperty('response');
  const { data } = testResult;
  return (
    <Dialog title={context.msg.t('result')} onClose={props.closeModal} className={'HttpTestResultDialog large'}>
      <p>
        {context.msg.t('status')}:{' '}
        <span className={`label label-${success ? 'success' : 'important'}`}>
          {context.msg.t(success ? 'success' : 'error')}
        </span>
      </p>
      <div className="row-fluid">
        <div className="span6">
          <b>{context.msg.t('request')}</b>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
        <div className="span6">
          <b>{context.msg.t('response')}</b>
          <pre>{testResult.response || testResult.callError || testResult.error || ''}</pre>
        </div>
      </div>
    </Dialog>
  );
}

class HttpActionDialog extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    onSave: _.noop,
  };

  state = {
    selectedTab: 0,
  };

  initVals = _.merge(HttpRequestActionSchema.default(), this.props.action ? this.props.action : {});

  onSave = async (values, actions) => {
    try {
      if (this._testAction) {
        const { data } = await Api.CentralMonitoring.testHttpAction(values);
        this.props.dispatch(MODALS.open(HttpTestResultDialog, { testResult: data }));
      } else {
        await this.props.onSave({ values, actions });
        this.props.closeModal();
      }
    } catch (e) {
      console.error(e);
      UiMsg.error(null, e);
    } finally {
      if (this._testAction) {
        actions.setSubmitting(false);
      }
      delete this._testAction;
    }
  };

  render() {
    return (
      <Formik initialValues={this.initVals} validationSchema={HttpRequestActionSchema} onSubmit={this.onSave}>
        {({ values, isSubmitting, submitForm }) => {
          return (
            <Dialog
              className={`HttpActionDialog large ${this.props.className}`}
              title={this.props.context.msg.t('HttpRequestAction')}
              onClose={this.props.closeModal}
              loading={isSubmitting}
              newDialogLayout={true}
            >
              <div ref={(ref) => (this._container = ref)}>
                <BngForm>
                  <Dialog.Body>
                    <div className="position-relative">
                      {this.renderProxyButton(values) && (
                        <Button
                          icon={'add_circle'}
                          className={`ProxyAddButton bng-button borderless cancel fix action`}
                          onClick={() => {
                            try {
                              const addButton = this._container.querySelector('.AddButton');
                              addButton.click();
                            } catch (e) {
                              console.error(e);
                            }
                          }}
                        >
                          {this.props.context.msg.t('add')}
                        </Button>
                      )}

                      <TabSet
                        beforeChange={({ next }) => {
                          this.setState({ selectedTab: next });
                          return true;
                        }}
                      >
                        <Tab label={'Url'} icon={'http'}>
                          <div className="row-fluid">
                            <div className="span12">
                              <FastField name="name" component={BngField} label={this.props.context.msg.t('name')} />
                            </div>
                          </div>

                          <div className="row-fluid">
                            <div className="span2">
                              <FastField
                                name="method"
                                component={BngField}
                                inputComponent={BngSelect}
                                label={this.props.context.msg.t('method')}
                                options={this.getMethodOpts()}
                                emptyOption={false}
                              />
                            </div>
                            <div className="span10">
                              <FastField
                                name="url"
                                component={BngField}
                                label={this.props.context.msg.t('url')}
                                placeholder={'https://example.com'}
                              />
                            </div>
                          </div>
                        </Tab>
                        <Tab label={this.props.context.msg.t('request.body')} icon={'code'}>
                          <div className={'BodyEditorContainer'}>
                            <FastField
                              rootClassName="BodyType"
                              name="body.type"
                              component={BngField}
                              inputComponent={BngSelect}
                              label={this.props.context.msg.t('type')}
                              options={this.getBodyTypeOpts()}
                              emptyOption={false}
                              inline
                            />

                            {this.renderBodyAsKvTable(values) && (
                              <div>
                                <ScrollContainer>
                                  <KvTable name={'body.formData'} rows={values.body.formData} />
                                </ScrollContainer>
                              </div>
                            )}

                            {values.body.type !== 'NONE' && this.renderBodyAsCodeEditor(values) && (
                              <Field
                                name={'body.content'}
                                component={BngCodeMirror}
                                mode={
                                  this.getBodyTypeOpts().find((o) => o.value === values.body.type).mode || 'text/plain'
                                }
                                variables={this.getAvailableVars()}
                                height={200}
                              />
                            )}
                          </div>
                        </Tab>
                        <Tab label={this.props.context.msg.t('request.query.params')} icon={'search'}>
                          <KvTable name="queryParams" rows={values.queryParams} />
                        </Tab>
                        <Tab label={this.props.context.msg.t('request.headers')} icon={'list_alt'}>
                          <KvTable name="headers" rows={values.headers} />
                        </Tab>
                      </TabSet>
                    </div>
                  </Dialog.Body>
                  <Dialog.Footer>
                    <DefaultDialogActions {...this.props}>
                      <Button
                        className={`bng-button save pull-left TestButton`}
                        onClick={() => {
                          this._testAction = true;
                          submitForm();
                        }}
                      >
                        {this.props.context.msg.t('test')}
                      </Button>
                    </DefaultDialogActions>
                  </Dialog.Footer>
                </BngForm>
              </div>
            </Dialog>
          );
        }}
      </Formik>
    );
  }

  renderBodyAsCodeEditor(values) {
    return !this.isKvContent(values.body.type);
  }

  renderBodyAsKvTable(values) {
    return this.isKvContent(values.body.type);
  }

  isKvContent(type) {
    return ['FORM_DATA', 'URL_ENCODED'].includes(type);
  }

  renderProxyButton(values) {
    return this.state.selectedTab !== 0 && (this.renderBodyAsKvTable(values) || this.state.selectedTab > 1);
  }

  getMethodOpts = memoizeOne(() => {
    return ['GET', 'POST', 'PUT', 'DELETE'].map((value) => ({ value, label: value }));
  });

  getBodyTypeOpts = memoizeOne(() => {
    return [
      { value: 'NONE', label: this.props.context.msg.t('empty') },
      { value: 'TEXT', label: 'Text', mode: 'text/plain' },
      { value: 'JSON', label: 'Json', mode: 'application/json' },
      { value: 'XML', label: 'Xml', mode: 'application/xml' },
      { value: 'HTML', label: 'Html', mode: 'text/html' },
      { value: 'FORM_DATA', label: 'Form Data' },
      { value: 'URL_ENCODED', label: 'Url Encoded' },
    ];
  });

  getAvailableVars = memoizeOne(() => {
    return [
      { value: '${date?string["yyyy-MM-dd"]}', label: this.props.context.msg.t('date') },
      { value: '${date?string["HH:mm:ss"]}', label: this.props.context.msg.t('hour') },
      { value: '${date?datetime?iso_local}', label: this.props.context.msg.t('date.time') },
      {
        value: '${lastDataUpdate?datetime?iso_local}',
        label: this.props.context.msg.t('last.data.update.date'),
      },
      { value: '${kpiValue!}', label: this.props.context.msg.t('kpi.value') },
      { value: '${kpiTargetValue!}', label: this.props.context.msg.t('kpi.goal') },
    ];
  });
}

export default ContextEnhancer(connect()(HttpActionDialog));
