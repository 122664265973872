import printValue from "yup/lib/util/printValue";

let mixed = {
    default: 'Es invalido',
    required: 'Campo obligatorio',
    oneOf: 'Debe ser uno de los siguientes valores: ${values}',
    notOneOf: 'No puede ser ninguno de los siguientes valoresdebe ser un: ${values}',
    notType: ({ path, type, value, originalValue }) => {
        let isCast = originalValue != null && originalValue !== value;
        let msg =
            `Debe ser un \`${type}\` type, ` +
            `pero el valor final fue: \`${printValue(value, true)}\`` +
            (isCast
                ? ` (emitido desde el valor \`${printValue(originalValue, true)}\`).`
                : '.');

        if (value === null) {
            msg += `\n Si “nulo” es entendido como un valor vacio, asegúrese de marcar el esquema como: \`.nullable()\``;
        }

        return msg;
    },
};

let string = {
    length: 'Debe ser exacto ${length} characters',
    min: 'Al menos debe ser ${min} characters',
    max: 'Debe ser como máximo ${max} characters',
    matches: 'Debe ser compatible con la siguiente: "${regex}"',
    email: 'Debe ser un email válido',
    url: 'Debe ser un URL válido',
    trim: 'Debe ser un texto sin espacios adicionales',
    lowercase: 'Debe ser un texto en minúscula',
    uppercase: 'Debe ser un texto en mayúscula',
};

let number = {
    min: 'Debe ser mayor o igual que ${min}',
    max: 'Debe ser menor o igual que ${max}',
    lessThan: 'Debe ser menor que ${less}',
    moreThan: 'Debe ser mayor que ${more}',
    notEqual: 'No debe ser igual a ${notEqual}',
    positive: 'Debe ser un número positivo',
    negative: 'Debe ser un número negativo',
    integer: 'Debe ser un entero',
};

let date = {
    min: 'El campo debe ser posterior a ${min}',
    max: 'El campo debe ser anterior de ${max}',
};

let boolean = {};

let object = {
    noUnknown: 'El campo no debe tener claves específicas en el espacio del objeto',
};

let array = {
    min: 'El campo debe tener al menos ${min} items',
    max: 'El campo debe tener menos o igual que ${max} items',
};

export default {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
};