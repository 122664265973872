import { Axios } from 'commonUtils';

class ExportSchedulingApi {
  CHANGE_EVENT = 'ExportScheduleChangedEvent';

  save = async (exportScheduling) => {
    const { data } = await Axios.post(`/spr/exportSchedulings`, exportScheduling);
    return data;
  };

  findLogs = async (exportSchedId, pageSize = 20) => {
    const { data } = await Axios.get(`/spr/exportSchedulings/${exportSchedId}/logs`, {
      params: {
        pageSize,
      },
    });
    return data;
  };

  findLog = async (exportSchedId, logId = 'last') => {
    const { data } = await Axios.get(`/spr/exportSchedulings/${exportSchedId}/logs/${logId}`);
    return data;
  };

  findOne = async (id, allData = false) => {
    const { data } = await Axios.get(`/spr/exportSchedulings/${id}`, {
      params: {
        allData,
      },
    });
    return data;
  };

  findAll = async (projectName, params = { pageSize: undefined }) => {
    const { data } = await Axios.get(`/spr/exportSchedulings`, {
      params: {
        projectName,
        ...params,
      },
    });
    return data;
  };

  updateEnabledOnScheds = async (ids = [], enabled = true) => {
    const { data } = await Axios.put(`/spr/exportSchedulings/updateEnabled`, {
      ids,
      enabled,
    });
    return data;
  };

  sendImmediate = async (id) => {
    return await Axios.post(`/spr/exportSchedulings/${id}/immediate`);
  };

  deleteById = async (id) => {
    return await Axios.delete(`/spr/exportSchedulings/${id}`);
  };

}

export default ExportSchedulingApi;
