import styles from './AssistantDialog.module.css';

import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { OpenAiAssistantModel } from 'components/bng/pages/admin/ada/ai/form/OpenAiForm';
import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import BngForm from 'components/bng/form/BngForm';
import useTranslation from 'components/hooks/useTranslation';
import bngYup from 'components/bng/form/yup/BngYup';

const BaseSchema = bngYup((yup) => {
  return yup.object({
    name: yup.string().trim().required().default(''),
    description: yup.string().required().default(''),
    llmModel: yup.string().required().default('OpenAI'),
    assistantKey: yup.string().required().default(''),
  });
});

const MODELS = {
  OpenAi: OpenAiAssistantModel,
};

export default function AssistantDialog({ closeModal, onSave = _.noop, assistant = { llmModel: 'OpenAI' } }) {
  const { t } = useTranslation();

  const selectedModel = MODELS[assistant?.llmModel ?? 'OpenAI'] ?? MODELS.OpenAi;

  const formSchema = useMemo(() => {
    return BaseSchema.concat(selectedModel.schema);
  }, [selectedModel]);

  const initialValues = useMemo(() => {
    return { ...formSchema.default(), ..._.cloneDeep(assistant) };
  }, [assistant]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      enableReinitialize
      onSubmit={async (values) => {
        if (_.isEqual(values, initialValues)) {
          return;
        }
        try {
          const response = await Api.AdaAi.save(values);
          UiMsg.ok(t('ada.ai.save.assistant.openAi.success'));
          await onSave(response);
          closeModal();
        } catch (e) {
          console.error(`Error on save AdaAi configuration`, e);
          UiMsg.ajaxError(t('ada.ai.save.assistant.openAi.error'), e);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Dialog
            title={`${t('ada.ai.add.new.assistant.openAi')} - ${t('ada.ai.add.assistant.openAi')}`}
            className={`AssistantDialog ${styles.AssistantDialog}`}
            onClose={closeModal}
            loading={isSubmitting}
            newDialogLayout
          >
            <BngForm>
              <Dialog.Body>
                <selectedModel.component editing={!!assistant.id}/>
              </Dialog.Body>
              <Dialog.Footer>
                <DefaultDialogActions closeModal={closeModal} />
              </Dialog.Footer>
            </BngForm>
          </Dialog>
        );
      }}
    </Formik>
  );
}
