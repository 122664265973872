export default class FolderPermission {
    canWrite(path = '') {
        let array = path.split('/').slice(1);
        let root = array[0];
        array = array.slice(1);
        let prev = `/${root}`;
        let newArray = [];
        for (let i of array) {
            prev += '/' + i;
            newArray.push(prev);
        }

        let matched = 0;
        let roots = this.getRoots();
        if (roots) {
            for (let i = 0; i < roots.length; i++) {
                const item = roots[i];
                const itemPath = item.path || item.href;
                if (itemPath === newArray[matched]) {
                    matched++;
                    if (!item.canWrite) {
                        return false;
                    } else {
                        roots = item.children || [];
                        i = 0;
                    }
                }
            }
        }
        return true;
    }

    getRoots() {
        return window.ReduxStore ? window.ReduxStore.getState().menu.roots : null;
    }
}