import './ProfileDialog.css';
import React, {Component} from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "../../ContextEnhancer";
import TreeTable from "../common/TreeTable";
import Icon from "components/ui/common/Icon";

class UserPermissionsTab extends Component {

    static propTypes = {
        permissions: PropTypes.object,
        groups: PropTypes.object,
    };

    static defaultProps = {
        permissions: {list: []},
        groups: {list: []},
    };

    state = {
        groups: [],
    };

    constructor(props){
        super(props);
    }

    async componentDidMount(){
        if(!this.props.groups.initialized) {
            await this.props.getPermissions();
        }
    }

    groupCard = (group) => {
        return (
            <div className="user-group-card" key={group.id}>
                <Icon icon="group"/>
                <span>{group.name}</span>
            </div>
        )
    };

    render(){
        const folders = _.filter(this.props.permissions.list, {canWrite: true});

        return (
            <div className="profile-user-permission">
                <div className="profile-user-groups">
                    <span className="profile-permission-title">{this.props.context.msg.t('user.groups')}</span>
                    <div>
                        {this.props.groups.list.map( group =>
                            this.groupCard(group)
                        )}
                        {this.props.groups.list.length === 0 &&
                            <div className="user-group-card" style={{textAlign: 'center'}}>
                                <span>{this.props.context.msg.t('user.dialog.without.groups')}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="profile-user-tree-table">
                    <span className="profile-permission-title">{this.props.context.msg.t('user.accumulated.permissions')}</span>
                    {folders.length === 0 ?
                        <div className="without-permission-information">
                            <span>{this.props.context.msg.t('user.dialog.without.permissions')}</span>
                        </div>
                        : <TreeTable canSelectObjects={false} folders={folders}/>
                    }
                </div>
            </div>
        );
    }
}

export default ContextEnhancer(UserPermissionsTab);