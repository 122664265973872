import styles from './BngColorGradient.module.css';

import React, { useEffect, useRef, useState } from 'react';

import BngColorSolid from 'components/bng/colorPicker/BngColorSolid';
import useBimContext from 'components/hooks/useBimContext';
import ColorUtils from 'components/bng/colorPicker/ColorUtils';

const BngColorGradient = ({
  color = {
    colors: [{ color: '#005dff', opacity: 1, position: 0, id: 0 }],
    rotation: 0,
  },
  selectColor = _.noop,
  width = 330,
  setMarkerPosition = _.noop,
  markerPosition = [],
  transparencyEnabled = true,
  resetMarkerPosition = _.noop,
  gradientRotationEnabled = false,
}) => {
  const context = useBimContext();

  const [selectedNodeId, setSelectedNodeId] = useState(0);

  const handleColorApply = (c) => {
    selectColor(c, rotationInputRef.current?.value ?? 0);
  };

  const selectedNodeStyle = (clr) => {
    if (_.isEqual(color.colors[selectedNodeId], clr)) {
      return {
        transform: 'scale(1.3)',
      };
    }
  };

  const handleRotationChange = (event) => {
    event.target.value = event.target.value.replace(/\D/g, '');
    if (event.target.value !== '' && Number(event.target.value) > 359) {
      event.target.value = 359;
    }

    rotateClock(event.target.value);
  };

  const rotateClock = (rotationValue) => {
    rotationClockPointerRef.current.style.transform = `rotate(${rotationValue !== '' ? rotationValue : 0}deg)`;
  };

  const handleRotationFocusOut = (event) => {
    handleColorApply(color.colors[0], event.target.value);
  };

  const updateGradientBarColor = (clr) => {
    if (!transparencyEnabled && color.colors[0].opacity !== 1) {
      color.colors[0].opacity = 1;
      handleColorApply(color.colors[0]);
    }

    if (selectionBarRef.current && gradientNodeRef.current) {
      const colorArr = [...color.colors];
      if (clr) {
        colorArr[selectedNodeId] = clr;
        gradientNodeRef.current[selectedNodeId].style.backgroundColor = colorArr[selectedNodeId].color;
        transparentNodeRef.current[selectedNodeId].style.opacity = transparencyEnabled
          ? 1 - colorArr[selectedNodeId].opacity
          : 0;
      } else {
        colorArr.forEach((c) => {
          gradientNodeRef.current[c.id].style.backgroundColor = c.color;
          transparentNodeRef.current[c.id].style.opacity = transparencyEnabled ? 1 - c.opacity : 0;
        });
      }
      selectionBarRef.current.style.backgroundImage = ColorUtils.genTransparentGradientImg(colorArr);
    }
  };

  useEffect(() => {
    updateGradientBarColor();
  }, [color]);

  useEffect(() => {
    if (rotationInputRef.current) {
      rotationInputRef.current.value = color.rotation;
      rotateClock(color.rotation);
    }
  });

  const selectionBarRef = useRef(null);
  const forceColorUpdate = useRef(false);
  const rotationClockPointerRef = useRef(null);
  const gradientNodeRef = useRef([]);
  const transparentNodeRef = useRef([]);
  const rotationInputRef = useRef(null);

  const setForceColorUpdate = (value) => {
    forceColorUpdate.current = value;
  };

  const BAR_WIDTH = width - 40;
  const NODE_SIZE = 14;

  return (
    <div className={`BngColorGradient ${styles.colorGradientWrapper}`}>
      <div
        className={`ColorGradientSelectionBarContainer ${styles.colorGradientSelectionBarContainer}`}
        style={{ width: BAR_WIDTH }}
      >
        <div className={`ColorGradientSelectionBar ${styles.colorGradientSelectionBar}`} ref={selectionBarRef} />
        {color.colors.map((c, idx) => {
          const nodePosition = Math.min(BAR_WIDTH - NODE_SIZE, BAR_WIDTH * c.position);
          return (
            <div
              className={`ColorGradientNode ${styles.colorGradientNode}`}
              key={idx}
              ref={(el) => (gradientNodeRef.current[c.id] = el)}
              style={{
                left: nodePosition + 'px',
                ...selectedNodeStyle(c),
              }}
              onClick={() => {
                resetMarkerPosition(true);
                forceColorUpdate.current = true;
                setSelectedNodeId(c.id);
              }}
            >
              <div
                className={`ColorGradientTransparentSemiCircle ${styles.transparentSemiCircle}`}
                key={`transparentSemiCircle_${idx}`}
                ref={(el) => (transparentNodeRef.current[c.id] = el)}
              />
            </div>
          );
        })}
      </div>
      {gradientRotationEnabled && (
        <div className={`ColorGradientRotationWrapper ${styles.rotationWrapper}`}>
          <div className={`ColorGradientRotationContainer ${styles.rotationContainer}`}>
            <span className={`ColorGradientRotationSpan ${styles.rotationSpan}`}>
              {context.msg.t('color.picker.rotation')}
            </span>
            <div className={`ColorGradientRotationInputContainer ${styles.rotationInputContainer}`}>
              <input
                className={`ColorGradientRotationInput ${styles.rotationInput}`}
                maxLength={3}
                onChange={handleRotationChange}
                onBlur={handleRotationFocusOut}
                placeholder={'0'}
                ref={rotationInputRef}
                type={'number'}
              />
            </div>
            <div className={`ColorGradientRotationClock ${styles.rotationClock}`}>
              <div className={`ColorGradientRotationClockMiddleDot ${styles.rotationClockMiddleDot}`} />
              <div
                className={`ColorGradientRotationClockPointer ${styles.rotationClockPointer}`}
                ref={rotationClockPointerRef}
              />
            </div>
          </div>
        </div>
      )}
      <div className={`ColorGradientSolidWrapper ${styles.gradientColorSolidWrapper}`}>
        <BngColorSolid
          color={color.colors[selectedNodeId]}
          selectColor={handleColorApply}
          width={width}
          setMarkerPosition={setMarkerPosition}
          markerPosition={markerPosition}
          transparencyEnabled={transparencyEnabled}
          forceColorUpdate={forceColorUpdate.current}
          setForceColorUpdate={setForceColorUpdate}
          updateGradientBarColor={updateGradientBarColor}
        />
      </div>
    </div>
  );
};

export default BngColorGradient;
