import "./DashboardItemOrderForm.css";

import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getFormValues, reduxForm} from "redux-form";

import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";

import UiMsg from "components/ui/UiMsg";
import Dialog from "components/ui/Dialog";
import Api from "components/Api";

class DashboardItemOrderForm extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func
    };

    static defaultProps = {
        itemHash: 0,
        formValues: {}
    };

    state = {
        categories: [],
        files: []
    };

    constructor(props) {
        super(props);
        this.state.items = _.sortBy(props.items.slice(0), ['order']);
    }

    componentDidMount() {
        j(this.refs.tableContainer).scrollbar();
    }

    save = () => {
        const payload = this.state.items.map((i, idx) => ({id: i.id, order: idx}));
        return Api.Dash.updateOrder(payload).then(
            () => {
                UiMsg.ok(this.props.context.msg.t('item.config.save.success'));
                Api.updateJsf();
                this.props.closeModal();
            },
            e => UiMsg.ajaxError(null, e)
        );
    };

    render() {
        const {selectedItem, items} = this.state;
        const rowNumStyle = {width: '30px', textAlign: 'center'};
        const typeStyle = {width: '40px', textAlign: 'center'};
        const actionsStyle = {width: '50px', textAlign: 'center'};
        return (

            <Dialog open={this.props.open}
                    title={this.props.context.msg.t('mobile.order')}
                    className={'large item-order-form'}
                    onClose={this.props.closeModal}>
                <form onSubmit={this.props.handleSubmit(this.save)}>

                    <div className="table-container scrollbar-inner" ref="tableContainer">
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th style={rowNumStyle}>#</th>
                                <th style={typeStyle}>{this.props.context.msg.t('type')}</th>
                                <th>{this.props.context.msg.t('description')}</th>
                                <th style={actionsStyle}>{this.props.context.msg.t('actions')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {items.map((item, idx) => (
                                <tr key={item.id}>
                                    <td style={rowNumStyle}>{idx + 1}</td>
                                    <td style={typeStyle}><i className={item.icon}></i></td>
                                    <td>{item.caption}</td>
                                    <td style={actionsStyle}>
                                        {(_.isEmpty(selectedItem) || _.isEqual(item.id, selectedItem.id)) &&
                                        <Button icon="icon-bim-move" className="btn-mini btn-table btn-light"
                                                onClick={() => this.toggleSelect(item.id, idx)}/>
                                        }
                                        {selectedItem && !_.isEqual(item.id, selectedItem.id) &&
                                        <Button icon="icon-bim-place" className="btn-mini btn-table btn-light"
                                                onClick={() => this.moveTo(idx)}/>
                                        }
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </div>

                    <hr/>

                    <div className="row-fluid">
                        <div className="span12 text-right btn-fix">
                            <Button icon="icon-remove" onClick={this.props.closeModal} className="btn-danger"
                                    disabled={this.props.submitting}>
                                {this.props.context.msg.t('cancel')}
                            </Button>
                            {' '}
                            <Button icon="icon-ok" type="submit" loading={this.props.submitting}>
                                {this.props.context.msg.t('save')}
                            </Button>
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }

    moveTo(idx) {
        const {items, selectedItem} = this.state;
        items.splice(idx, 0, items.splice(selectedItem.idx, 1)[0]);
        this.setState({selectedItem: null, items: items});
    }

    toggleSelect(id, idx) {
        this.setState({selectedItem: _.isEmpty(this.state.selectedItem) ? ({id, idx}) : null});
    }
}

const FORM_NAME = 'dashboardItemOrderForm';

DashboardItemOrderForm = reduxForm({
    form: FORM_NAME
})(DashboardItemOrderForm);

DashboardItemOrderForm = connect(
    state => {
        const params = state.modals.current.params;
        return ({
            items: params.items,
            formValues: getFormValues(FORM_NAME)(state),
            initialValues: _.isEmpty(params) ? {} : params
        });
    },
)(DashboardItemOrderForm);

export default ContextEnhancer(DashboardItemOrderForm);