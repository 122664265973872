import React from 'react';
import PropTypes from 'prop-types';

export default class BngDate extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        const {field, form, title, ...props} = this.props;
        return (
            <div className={`BngDate date ${this.props.className || ''}`}>
                <label>
                    <input type="date"
                           className="ace"
                           {...field}
                           {...props}
                    />
                    <span className="lbl" title={title}>{this.props.label}</span>
                </label>
            </div>
        );
    }

};
