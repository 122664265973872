import styles from './FormUtils.module.css';

import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'imports/ReactBootstrapImport';
import { Field as RField, getFormValues, reduxForm } from 'redux-form';
import validator from 'validator';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import BimEventBus from 'BimEventBus';
import Tooltip from 'components/ui/Tooltip';
import useBimContext from 'components/hooks/useBimContext';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import Icon from 'components/ui/common/Icon';

const FieldGroup = ({
  id,
  label,
  labelClassName = '',
  input,
  groupClass,
  meta: { touched, invalid, error, warning },
  ...props
}) => {
  props = Object.assign({}, props, input);
  props.type = props.type || 'text';
  let validationState = null;
  if (touched) {
    if (error) validationState = 'error';
    else if (warning) validationState = 'warning';
  }
  return (
    <FormGroup controlId={id} validationState={validationState} className={groupClass}>
      {label && <ControlLabel className={labelClassName}>{label}</ControlLabel>}
      <FormControl {...props} />
      {touched && invalid && <HelpBlock>{error || warning}</HelpBlock>}
    </FormGroup>
  );
};

FieldGroup.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  labelClassName: PropTypes.any,
  input: PropTypes.any,
  groupClass: PropTypes.any,
};

export const Field = ({ name, type, validate, normalize, parse, format, value, ...props }) => {
  props = Object.assign({}, props, { id: name });

  // Handle multiple validations
  if (validate && validate.constructor === Array) {
    const validateO = validate;
    validate = (val) => {
      for (let i = 0; i < validateO.length; i++) {
        let ret = validateO[i](val);
        if (ret) return ret;
      }
    };
  }

  return (
    <RField
      name={name}
      type={type}
      component={FieldGroup}
      validate={validate}
      normalize={normalize}
      parse={parse}
      format={format}
      value={value}
      {...props}
    />
  );
};

Field.propTypes = {
  name: PropTypes.string,
  type: PropTypes.any,
  validate: PropTypes.any,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  format: PropTypes.func,
  value: PropTypes.any,
};

class Vals {
  constructor() {
    this.msg = { t: (key) => key };
    BimEventBus.on('CeDataInitialized', ({ ceData }) => {
      this.initialize(ceData.context.msg);
    });
  }

  initialize(msg) {
    this.msg = msg;
  }

  required = (value) => {
    return value ? undefined : this.msg.t('javax.faces.component.UIInput.REQUIRED');
  };

  notIn({
    values,
    message = 'theme.name.already.in.use.message',
    valueTransformer = (val) => val,
    caseInsensitive = true,
  }) {
    values = values.map((v) => v.toLowerCase());
    return (value) => {
      value = valueTransformer(value);
      value = caseInsensitive ? value.toLowerCase() : value;
      if (values.indexOf(value) === -1) return undefined;
      return this.msg.t(message);
    };
  }

  folderName = (value) => {
    value = value.trim();
    const msg = this.maxLength(64)(value);
    if (msg) {
      return msg;
    }
    if (validator.isAscii(value) && validator.isAlphanumeric(value.replace(/\s/g, '')) && !validator.isInt(value)) {
      return undefined;
    }
    return this.msg.t('folder.name.only.alphanumeric');
  };

  inMemoryName = (value) => {
    value = value.trim();
    const msg = this.maxLength(30)(value);
    if (msg) {
      return msg;
    }
    if (validator.isAlpha(value.replace(/\s/g, ''))) {
      return undefined;
    }
    return this.msg.t('inMemory_nameContainSpecialChars');
  };

  onlyOne({ values, message = 'theme.name.already.in.use.message', valueTransformer = (val) => val }) {
    return (value) => {
      value = valueTransformer(value);
      const count = values.filter((v) => v === value).length;
      if (count > 1) {
        return this.msg.t(message);
      }
      return undefined;
    };
  }

  maxLength = (max) => {
    return (val) => {
      return validator.isLength('' + val, { max }) ? undefined : this.msg.t('length.must.be.less.than', [max]);
    };
  };

  isInt = (val = '') => {
    return validator.isInt('' + val) ? undefined : this.msg.t('inMemory_invalidValueForIgnoredLines');
  };

  isNumeric = (val = '') => {
    val = '' + val;
    return validator.isInt('' + val) || validator.isFloat(val)
      ? undefined
      : this.msg.t('inMemory_invalidValueForIgnoredLines');
  };

  //TODO
  objectName = (val = '') => {
    return undefined;
  };

  validateEmail = (value) => {
    let error;

    if (_.isEmpty(value)) {
      error = this.msg.t('email.required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = this.msg.t('email.invalid');
    }

    return error;
  };
}

export const Validations = new Vals();

const defaultFn = function () {
  return {};
};

/**
 *
 * @param component
 * @param formName
 * @param initialValues
 * @param destroyOnUnmount
 * @param mapToState
 * @param mapToDispatch
 */
export const wrapForm = function ({
  component,
  name: formName = component.constructor.name,
  initialValues = {},
  destroyOnUnmount = true,
  mapToState = defaultFn,
  mapToDispatch = defaultFn,
}) {
  const wrappedReduxForm = reduxForm({
    form: formName,
    destroyOnUnmount,
    initialValues,
  })(component);

  return connect((state) => {
    const result = mapToState(state);
    result.formValues = getFormValues(formName)(state);
    return result;
  }, mapToDispatch)(wrappedReduxForm);
};

export const DefaultDialogActions = ({
  closeModal,
  submitting,
  disabled = false,
  cancelLabel = 'cancel',
  okLabel = 'save',
  title = undefined,
  buttonClass = 'bng-button',
  children,
  isMobile = false,
  typeSaveButton = 'submit',
  onClickSaveButton = _.noop,
  setSelectedRow = _.noop,
  hideCancelButton = false,
  saveButtonClassname = '',
  errors = {},
  submitCount = 0,
  errorMessage = '',
  className= '',
}) => {
  const context = useBimContext();

  const errorToUse = !!errorMessage ? errorMessage : errors && Object.keys(errors).length >= 1 ? errors : false;
  const hasSubmitted = Number.isFinite(submitCount) ? submitCount >= 1 : true;
  const showErrors = !!errorToUse && hasSubmitted;

  return (
    <div className={`DefaultDialogActions ActionsWrapper ${showErrors ? styles.ActionsErrors : ''} ${className}`}>
      {showErrors && <DefaultDialogError errorMessage={errorToUse} submitCount={submitCount} errors={errors} />}
      <div className={`row-fluid ${styles.buttonsWrapper}`}>
        <div className={`${styles.buttonsContainer} span12 text-right btn-fix`}>
          {children}
          {!isMobile && !hideCancelButton && (
            <BngButton
              onClick={() => {
                setSelectedRow(undefined);
                closeModal();
              }}
              className={`${buttonClass} ${styles.cancelButton}`}
              disabled={submitting}
              variant={Variant.textButton}
            >
              {context.msg.t(cancelLabel)}
            </BngButton>
          )}{' '}
          <Tooltip title={title} disabled={!title || !disabled}>
            <span>
              <BngButton
                btnType={typeSaveButton}
                className={`${buttonClass} ${saveButtonClassname} ${styles.saveButton}`}
                loading={submitting}
                disabled={disabled}
                onClick={onClickSaveButton}
              >
                {context.msg.t(okLabel)}
              </BngButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

function DefaultDialogError({ errorMessage, className = '' }) {
  const context = useBimContext();

  const handleMessage = (message) => {
    if (!message) {
      return null;
    }
    if (typeof message === 'string') {
      return message;
    } else {
      const msg = Object.values(message);
      return handleMessage(msg[0]);
    }
  };

  const message = handleMessage(errorMessage);

  return message ? (
    <div className={`DefaultDialogError ${styles.formErrors} ${className}`}>
      <Icon icon={'error_outline'} className={`${styles.formErrorIcon}`} />
      <span>{context.msg.t(message)}</span>
    </div>
  ) : null;
}

export const renderSelectItemOpts = function (sources, groups = false, filter = () => true) {
  const item = (item, idx) => (
    <option key={item.value} value={item.value} disabled={item.disabled}>
      {item.label}
    </option>
  );
  if (groups) {
    return sources.map((grp, i) => (
      <optgroup key={grp.label} label={grp.label}>
        {grp.selectItems.filter(filter).map(item)}
      </optgroup>
    ));
  } else {
    return sources.filter(filter).map(item);
  }
};

export const clearEntityIds = (e = {}) => {
  if (_.isArray(e)) {
    e.forEach((e) => clearEntityIds(e));
  } else if (_.isPlainObject(e)) {
    delete e['id'];
    Object.keys(e).forEach((key) => {
      const val = e[key];
      if (_.isObjectLike(val)) {
        clearEntityIds(val);
      }
    });
  }
};
