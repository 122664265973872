import React from "react";
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";


export class JustGauge extends BaseKpi {


    constructor(props) {
        super(props);
    }

    customResizeKpi = ({width}) => {
        this.props.div.style.padding = 0;

        const widthCanvas = width * 0.5;
        const heightCanvas = widthCanvas * 0.55;

        if (this.props.growthInfoRender) {
            this.$bodyEl.querySelector('.KpiGrowth').style.width = `${widthCanvas}px`;
        }

        this.$bodyEl.style.textAlign = "center";

        resizeEl(this.$kpiJustGaugeEl, {
            height: {value: `${heightCanvas}px`},
            width: {value: `${widthCanvas}px`},
            marginLeft: {value: 'auto'},
        });

        resizeEl(this.$percentEl, {
            fontSize: {minValue: 12, multiplier: 0.15, refSize: heightCanvas},
            lineHeight: {minValue: 12, multiplier: 0.15, refSize: heightCanvas},
            width: {value: `${widthCanvas}px`},
            marginLeft: {value: 'auto'},
            textAlign: {value: 'center'},
        });
    };

    initComponent = () => {
        let kpiId = this.state.kpiId;

        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        j(`#justgauge${kpiId}`).empty();

        let minValue = parseFloat(this.props.minValue);
        let val = 0;
        if (this.props.percentage !== Infinity) {
            val = this.props.percentage;
        }
        let color = this.state.color;


        if (jQuery('svg', document.getElementById('justgauge' + kpiId)).length === 0) {
            new JustGage({
                id: 'justgauge' + kpiId,
                value: val,
                min: minValue,
                max: 100,
                levelColors: [color],
                relativeGaugeSize: true,
                startAnimationTime: application.utils.disableAnimations() ? 1 : 500,
                refreshAnimationTime: application.utils.disableAnimations() ? 1 : 500,
                hideValue: true,
                hideMinMax: true
            })
        }
    };

    render() {

        let kpiId = this.state.kpiId;

        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        return (
            <div style={{margin: 'auto'}} className="KpiJustGauge">
                <div className={`KpiBody Canvas`}>
                    <div ref={el => this.$bodyEl = el} className="KpiInternalBody">
                        <div ref={el => this.$kpiJustGaugeEl = el} id={`justgauge${kpiId}`}/>
                        <div className="kpi-percent" ref={el => this.$percentEl = el}
                             style={{fontWeight: '500'}}>
                            {this.state.percentage + "%"}
                        </div>
                        {this.useGrowth()}
                    </div>
                    {this.useRightFragment({container: this.$bodyEl})}
                </div>
                <div ref={el => this.$containerBandsEl = el}>
                    {this.useBands({container: this.$containerBandsEl})}
                </div>
            </div>
        )
    }

}

