import FSA from "components/ui/redux/structure/FSA";

const Async = function (finished = false) {
    return {async: true, finished};
};

const ajaxToEvent = (type, request) =>
    (dispatch) => {
        dispatch(FSA(type, {}, Async()));
        return request()
            .then(
                response => dispatch(FSA(type, response, Async(true))),
                error => dispatch(FSA(type, new Error(error), Async(true)))
            );
    };


const processAsyncAction = (state, action, successFn = () => state, runningFn = () => state, errorFn = () => state) => {
    if (action.error) {
        return errorFn(action.payload);
    } else if (action.meta.finished) {
        return successFn(action.payload);
    } else {
        return runningFn();
    }
};


export {ajaxToEvent, processAsyncAction};