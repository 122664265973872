import "./BngDraggable.css";
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Icon from "components/ui/common/Icon";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};


const BngDraggable = ({
                          items = [],
                          className = '',
                          onChangeOrder = _.noop,
                      }) => {

    const [draggableItems, setDragabbleItems] = useState(items);

    useEffect(() => {
        setDragabbleItems(items);
    }, [items]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const fromIdx = result.source.index;
        const toIdx = result.destination.index;

        if (fromIdx === toIdx) return;

        const orderedItems = reorder(
            draggableItems,
            fromIdx,
            toIdx
        );

        setDragabbleItems(orderedItems);
        onChangeOrder(orderedItems, {fromIdx, toIdx})
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div className={`BngDraggableContext ${className}`} ref={provided.innerRef}
                         {...provided.droppableProps}>
                        {draggableItems.map((item, index) => (
                            <Draggable
                                key={index}
                                draggableId={index + 1}
                                index={index}>
                                {(provided, snapshot) => (
                                    <div className={`BngDraggableItem${className} ${item.props?.item?.className || ''}`}
                                         ref={provided.innerRef}
                                         {...provided.draggableProps}>
                                        <div className="BngDraggableIcon" {...provided.dragHandleProps}>
                                            <Icon type={Icon.MATERIAL} icon="drag_indicator"/>
                                        </div>
                                        {item}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
};

export default BngDraggable;