import './SchedulingDropdown.css';

import React, { useEffect, useState } from 'react';

import BngDropdown from 'components/bng/ui/BngDropdown';
import useBimContext from 'components/hooks/useBimContext';
import ExportSchedulingDetailsDialog from 'components/ui/navbar/ExportSchedulingDetailsDialog';
import { MODALS } from 'components/ui/redux/Actions';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import Utils from 'components/Utils';
import MenuButton from 'components/ui/navbar/MenuButton';
import OpConfirmation from 'components/ui/OpConfirmation';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BimEventBus from 'BimEventBus';
import BngExportDialog from 'components/bng/exportScheduling/BngExportDialog';
import BngExportLogsDialog from 'components/bng/exportScheduling/BngExportLogsDialog';

export default function SchedulingDropdown({
  exportScheduling,
  container,
  className = '',
  forceMenuOpen,
  findSchedulings,
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [isEnabled, setIsEnabled] = useState(exportScheduling.scheduling.enabled);

  useEffect(() => {
    //Updates enabled icon in case of manipulation outside of this component (enableAll for instance)
    setIsEnabled(exportScheduling.scheduling.enabled);
  }, [exportScheduling]);

  const openModal = (params) => dispatch(MODALS.open(params.modal, { ...params.props }));

  const toggleOn = async () => {
    if (forceMenuOpen) {
      forceMenuOpen();
    }
    if (!Utils.Project.canExecuteSchedulings(context.project)) {
      UiMsg.warn(
        context.msg.t('attention'),
        context.msg.t('SchedulingPage.enable.all.production.warning', [context.msg.t(context.project.projectType)])
      );
    } else {
      try {
        const enabled = !exportScheduling.scheduling.enabled;
        await Api.ExportScheduling.updateEnabledOnScheds([exportScheduling.id], enabled);
        UiMsg.ok(context.msg.t(enabled ? 'export.scheduling.enabled.message' : 'export.scheduling.disabled.message'));

        if (_.isFunction(findSchedulings)) {
          findSchedulings().then(() => {
            setIsEnabled(!isEnabled);
            BimEventBus.emit(Api.ExportScheduling.CHANGE_EVENT, {});
          });
        } else {
          BimEventBus.emit(Api.ExportScheduling.CHANGE_EVENT, {});
        }
      } catch (e) {
        console.error('Error on SchedulingDropdown.toggleOn', e);
        UiMsg.ajaxError(null, e);
      }
    }
  };

  const openDetailsDialog = () => {
    openModal({
      modal: ExportSchedulingDetailsDialog,
      props: {
        exportScheduling,
      },
    });
  };

  const editScheduling = () => {
    openModal({
      modal: BngExportDialog,
      props: {
        id: exportScheduling.id,
      },
    });
    MenuButton.closeAllMenus();
  };

  const viewLogs = () => {
    openModal({
      modal: BngExportLogsDialog,
      props: {
        exportSchedId: exportScheduling.id,
      },
    });
    MenuButton.closeAllMenus();
  };

  const sendImmediately = () => {
    OpConfirmation({
      title: `<span class='SchedulingDropdownTitle'>${exportScheduling.scheduling.identifier}</span>`,
      message: context.msg.t('confirm.immediate.execution.export.scheduling'),
      onConfirm: async () => {
        try {
          await Api.ExportScheduling.sendImmediate(exportScheduling.id);
          UiMsg.ok(context.msg.t('immediate.execution.scheduled'));
        } catch (e) {
          console.error('Error on sendImmediately()', { exportScheduling }, e);
          if (e.status === 500) {
            UiMsg.error(context.msg.t('immediate.execution.scheduled.error'));
          } else {
            UiMsg.warn(context.msg.t('export.scheduling.immediate.already.requested.exception.message'));
          }
        }
      },
      msg: context.msg,
      level: 'warning',
    });
    MenuButton.closeAllMenus();
  };

  const deleteScheduling = () => {
    OpConfirmation({
      title: `<span class='SchedulingDropdownTitle'>${exportScheduling.scheduling.identifier}</span>`,
      message: context.msg.t('confirm.delete.export.scheduling'),
      onConfirm: async () => {
        try {
          await Api.ExportScheduling.deleteById(exportScheduling.id);
          UiMsg.ok(context.msg.t('in.memory.scheduling.remove.success'));
          BimEventBus.emit(Api.ExportScheduling.CHANGE_EVENT, {});
        } catch (e) {
          console.error('Error on deleteScheduling()', { exportScheduling }, e);
          UiMsg.error(e.body.data);
        }
      },
      msg: context.msg,
    });
    MenuButton.closeAllMenus();
  };

  return (
    <div className={`SchedulingDropdown scheduling-button ${className}`}>
      <BngDropdown
        icon="more_vert"
        popperClassName="export-menu-popper"
        btnIconProps={{ className: 'export-menu-dropdown' }}
        container={container}
        useContainerForPopper={false}
        options={[
          [
            {
              label: context.msg.t('export.scheduling.enable'),
              icon: isEnabled ? 'toggle_on' : 'toggle_off',
              onClick: toggleOn,
              closeOnSelect: false,
              className: `btn-only-icon button-export-container button-export-activation ${isEnabled ? 'enabled' : ''}`,
            },
            {
              label: context.msg.t('detailing'),
              icon: 'info',
              onClick: openDetailsDialog,
              className: 'btn-only-icon button-export-container button-export-info',
            },
            {
              label: context.msg.t('edit'),
              icon: 'edit',
              onClick: editScheduling,
              className: 'btn-only-icon button-export-container button-export-edit',
            },
            {
              label: context.msg.t('view.logs'),
              icon: 'description',
              onClick: viewLogs,
              className: 'btn-only-icon button-export-container button-export-description',
            },
            {
              label: context.msg.t('send.immediately'),
              icon: 'send',
              title: !isEnabled ? context.msg.t('send.immediately.disabled') : '',
              disabled: !isEnabled,
              onClick: sendImmediately,
              className: 'btn-only-icon button-export-container button-export-send',
            },
          ],
          {
            label: context.msg.t('remove'),
            icon: 'delete',
            onClick: deleteScheduling,
            className: 'btn-only-icon button-export-container button-export-delete',
          },
        ]}
      />
    </div>
  );
}
