import './TimeFilterView.css';

import React from "react";
import Calendar from "react-calendar";

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Utils from "components/Utils";
import {BngDateField, Mask} from "components/bng/form/BngMaskedField";
import UiMsg from "components/ui/UiMsg";
import SelectItems from "components/filter/SelectItems";
import {BngDropdown} from "components/bng/ui/BngDropdown";
import Button from "components/ui/Button";
import Icon from "components/ui/common/Icon";
import BngRadio from "components/bng/form/BngRadio";


export const MONTH_FORMAT = 'MM/YYYY';
export const YEAR_FORMAT = 'YYYY';

const INITIAL_STATE = {
    selected: 'PERIOD',
    date: null,
    canSetDate: true,
    selectedItems: [],
    calendarMaxDetail: 'month',
    viewDate: [],
};

const cloneAndAdjustDate = (date) => {
    if(date) {
        date = new Date(date.getTime());
        date.setHours(12);
    }
    return date;
}

class TimeFilterView extends React.PureComponent {

    static defaultProps = {
        blockedMembers: [],
        dynamicMembers: [],
        availableDateRange: {min: '1900-01-01', max: '2100-01-01'},
        containRestriction: false,
        restrictionType: 'SHOW_SELECTED',
        restrictionMembers: [],
        dataRestrictionMembers: [],
        onChange: _.noop,
        changeMode: _.noop,
        initialSelection: null,
        filterMode: 'RANGE'
    };

    state = _.cloneDeep(INITIAL_STATE);

    DAY_MEMBER_FORMAT = Utils.Date.findDateFormat('pt-BR');


    viewType = {
        NONE: {},
        PERIOD: {
            render: () => {
                const userLocale = Api.findUserLocale().replace('_', '-');

                this.DAY_MEMBER_FORMAT = Utils.Date.findDateFormat(userLocale);

                const minDate = new Date(this.props.availableDateRange.min);
                const maxDate = new Date(this.props.availableDateRange.max);

                const maxDetailOpts = {
                    decade: {
                        label: this.props.context.msg.t('year'),
                        onClick: () => changeCalendarView('decade'),
                        mask: Mask.DATE_YEAR,
                        format: YEAR_FORMAT,
                        formatViewDate: (date) => {
                            date = cloneAndAdjustDate(date);
                            return `${date.getFullYear()}`;
                        },
                    },
                    year: {
                        label: this.props.context.msg.t('month'),
                        onClick: () => changeCalendarView('year'),
                        mask: Mask.DATE_MONTH_YEAR,
                        format: MONTH_FORMAT,
                        formatViewDate: (date) => {
                            date = cloneAndAdjustDate(date);
                            return moment(date).format(MONTH_FORMAT);
                        },
                    },
                    month: {
                        label: this.props.context.msg.t('day'),
                        onClick: () => changeCalendarView('month'),
                        mask: Mask.DATE,
                        format: this.DAY_MEMBER_FORMAT,
                        formatViewDate: (date, format = this.DAY_MEMBER_FORMAT) => {
                            date = cloneAndAdjustDate(date);
                            return moment(date).format(format);
                        },
                    },
                };

                const selectedDetailOpt = maxDetailOpts[this.state.calendarMaxDetail];

                const handlerFactory = (onChange) => {
                    return (e) => {
                        const val = e.target.value;
                        if ((val.match(/_/g) || []).length > 0) {
                            return;
                        }
                        try {
                          const momentDate = moment(val, 'DD/MM/YYYY', true);
                          if (!momentDate.isValid()) {
                            return false;
                          }
                          momentDate.hour(12);
                          let date = momentDate.toDate();
                          if (date.getTime() - minDate.getTime() < 0) {
                            date = minDate;
                          } else if (date.getTime() - maxDate.getTime() > 0) {
                            date = maxDate;
                          }
                          onChange(date);
                        } catch (e) {
                            console.error('Error on TimeFilterView.handlerFactory()', {val}, e);
                        }
                    }
                };

                const changeCalendarView = (calendarMaxDetail) => {
                    if (this.state.calendarMaxDetail === calendarMaxDetail) {
                        return;
                    }
                    this.props.onChange([]);
                    this.props.onChange([], 'LEFT');
                    this.props.onChange([], 'RIGHT');
                    this.setState({calendarMaxDetail, date: null, viewDate: []});
                };

                const formatViewDate = (idx) => {
                    let date = _.get(this.state, `viewDate[${idx}]`);
                    if (!date) {
                        return;
                    }
                    date = cloneAndAdjustDate(date);
                    return selectedDetailOpt.formatViewDate(date);
                };

                const isIphone = application.page.isIphone() ? 'mobileIos-dropdown' : '';

                return (
                  <div className={`PeriodView ${this.state.calendarMaxDetail}`}>
                      <div className="Inputs">
                          {/* Adicionada key aqui pra forçar a recriação do componente e zerar inputs */}
                          <div key={this.state.calendarMaxDetail}
                               style={{display: 'flex', justifyContent: 'center'}}>
                              <div>
                                  <BngDateField
                                    locale={userLocale}
                                    className="PeriodField"
                                    value={formatViewDate(0)}
                                    onChange={handlerFactory((date) => {
                                        this.dateChanged([date, _.get(this.state, 'date[1]')]);
                                    })}
                                    mask={selectedDetailOpt.mask}
                                  />
                              </div>
                              <div style={{
                                  padding: '0 5px',
                                  lineHeight: '30px',
                                  fontSize: '30px',
                                  fontWeight: 100
                              }}>
                                  -
                              </div>
                              <div>
                                  <BngDateField
                                    locale={userLocale}
                                    className="PeriodField"
                                    value={formatViewDate(1)}
                                    onChange={handlerFactory((date) => {
                                        this.dateChanged([_.get(this.state, 'date[0]'), date]);
                                    })}
                                    mask={selectedDetailOpt.mask}
                                  />
                              </div>


                          </div>

                          <BngDropdown popperClassName={'TimeFilterViewMaxDetailDrop'}
                                       options={Object.values(maxDetailOpts)}
                                       customButton={({openDropdown}) => (
                                         <Button onClick={openDropdown} className="btn-transparent">
                                             {selectedDetailOpt.label}
                                             <Icon icon="arrow_drop_down" style={{marginLeft: '10px'}}/>
                                         </Button>
                                       )}
                                       customOptions={({closeDropdown}) =>
                                         (
                                           <div className={`bng-dropdown ${isIphone}`}>
                                               {Object.values(maxDetailOpts).map((opt, idx) => (
                                                 <div key={idx}
                                                      className="item no-border"
                                                      onClick={() => {
                                                          opt.onClick();
                                                          closeDropdown();
                                                      }}>
                                                     <BngRadio
                                                       label={opt.label}
                                                       field={{value: opt.label === selectedDetailOpt.label}}
                                                       checked={opt.label === selectedDetailOpt.label}
                                                       onChange={_.noop}
                                                     />
                                                 </div>
                                               ))}
                                           </div>
                                         )}
                          />
                      </div>
                      <div>
                          {/* Adicionada key aqui pra forçar a recriação do componente e evitar os bugs encontrados durante as alterações na prop maxDetail */}
                          <Calendar
                            key={this.state.calendarMaxDetail}
                            className="BngCalendar"
                            selectRange={true}
                            onClickDay={this.daySelected}
                            onClickMonth={this.monthSelected}
                            onClickYear={this.yearSelected}
                            locale={userLocale}
                            onChange={this.dateChanged}
                            value={this.state.date}
                            minDate={minDate}
                            maxDate={maxDate}
                            minDetail="decade"
                            maxDetail={this.state.calendarMaxDetail}
                            formatMonthYear={this.formatMonthYear}
                            formatShortWeekday={this.formatShortWeekday}
                            tileDisabled={this.isCalendarTileDisabled}
                            ref={ref => this.CalendarInstance = ref}
                          />
                      </div>
                  </div>
                );
            },
            isDisabled: ({props, state}) => {
                /**
                 * Quando for Exceto, para este tipo estar bloqueado, TODOS os membros devem estar selecionados.
                 * Não vou implementar pois é extremamente improvável que isso aconteça.
                 */
                if (!props.containRestriction || props.restrictionType === 'HIDE_SELECTED') {
                    return false;
                }

                // Se restrição possuir algum membro não dinâmico então visão fica habilitada
                const containAnyMember = props.restrictionMembers.find(m => m.includes('[')) || props.dataRestrictionMembers.find(m => m.includes('['));
                return !containAnyMember
            }
        },
        DAILY: {
            canHandle: (type) => type === 'DAY',
            render: () => {
                const group = this.props.dynamicMembers.find(m => this.viewType.DAILY.canHandle(m.value));
                return this.renderGroupRadioSelection(group);
            }
        },
        WEEKLY: {
            canHandle: (type) => type === 'WEEK',
            render: () => {
                const group = this.props.dynamicMembers.find(m => this.viewType.WEEKLY.canHandle(m.value));
                return this.renderGroupRadioSelection(group);
            }
        },
        MONTHLY: {
            canHandle: (type) => type === 'MONTH',
            render: () => {
                const group = this.props.dynamicMembers.find(m => this.viewType.MONTHLY.canHandle(m.value));
                return this.renderGroupRadioSelection(group);
            }
        },
        YEARLY: {
            canHandle: (type) => type === 'YEAR',
            render: () => {
                const group = this.props.dynamicMembers.find(m => this.viewType.YEARLY.canHandle(m.value));
                return this.renderGroupRadioSelection(group);
            }
        },
        OTHERS: {
            canHandle: (type) => type !== 'DAY' && type !== 'WEEK' && type !== 'MONTH' && type !== 'YEAR',
            render: () => {
                const members = _.flatMap(
                  this.props.dynamicMembers.filter(m => this.viewType.OTHERS.canHandle(m.value)),
                  sig => sig.selectItems
                );

                return this.renderRadioSelection(members);
            }
        },
    };

    formatMonthYear = (locale, date) => {
        return `${date.toLocaleString(locale, {month: 'long'})} ${date.getFullYear()}`;
    };

    formatShortWeekday = (locale, date) => {
        return date.toLocaleString(locale, {weekday: 'narrow'});
    };

    TILE_DISABLED_CACHE = {};

    /**
     * Bloqueia tile da data caso este filtro possua alguma restrição.
     * Membros de Ano (ex: [2019]) tem precedencia sobre os dias, ou seja, caso um Ano seja selecionado
     * todos os dias estarão liberados para seleção.
     * @param date
     * @param view
     * @returns {boolean|*}
     */
    isCalendarTileDisabled = ({date, view}) => {
        if (!this.props.containRestriction) {
            return false;
        }

        const cacheKey = `${date.getTime()}${view}`;
        if (this.TILE_DISABLED_CACHE.hasOwnProperty(cacheKey)) {
            return this.TILE_DISABLED_CACHE[cacheKey];
        }

        date = moment(date).format(this.DAY_MEMBER_FORMAT);

        let disabled = this.isDateDisabled({
            date,
            view,
            restrictionMembers: this.props.dataRestrictionMembers,
            restrictionType: 'SHOW_SELECTED'
        });
        if (!disabled) {
            disabled = this.isDateDisabled({
                date,
                view,
                restrictionMembers: this.props.restrictionMembers,
                restrictionType: this.props.restrictionType
            });
        }

        this.TILE_DISABLED_CACHE[cacheKey] = disabled;

        return disabled;
    };

    isDateDisabled = ({date, view, restrictionMembers, restrictionType}) => {
        if (restrictionMembers.length === 0) return false;
        /**
         * Formato dos membros:
         *
         * Dinâmico: CurrentDay
         * Ano: [2019]
         * Dia: [2019].[S2].[Q3].[M7].[W28].[12/07/2019]
         */
        const restrictions = restrictionMembers.reduce((acc, val) => {
            if (!val.includes('[')) {
                acc.dynamics[val] = val;
                return acc;
            }
            if (val.includes('.')) {
                val = val.split('.').pop();
                acc.dates[val] = val;
                return acc;
            }
            acc.years[val] = val;
            return acc;
        }, {years: {}, dates: {}, dynamics: {}});


        const member = `[${date}]`;
        let match = view === 'month' ? restrictions.dates[member] : null;

        /**
         * Para verificar se o mês ou o ano estão bloqueados com a opção Exceto é necessário validar
         * se todos os dias do mês ou ano estão bloqueados, não vou implementar no momento pois
         * creio ser um caso de uso inexistente
         */
        if (!match) {
            const monthYear = date.substring(3);
            const year = date.substring(6);
            if (restrictionType === 'SHOW_SELECTED') {
                if (view === 'year') {
                    match = Object.keys(restrictions.dates).find(d => d.includes(monthYear));
                } else if (view === 'decade') {
                    match = Object.keys(restrictions.dates).find(d => d.includes(year)) || Object.keys(restrictions.years).find(d => d.includes(year));
                }
            } else {
                if (view === 'decade') {
                    match = Object.keys(restrictions.years).find(d => d.includes(year))
                }
            }
        }

        let disabled = false;
        if (restrictionType === 'SHOW_SELECTED') {
            disabled = match == null;
        } else {
            disabled = match != null;
        }
        return disabled;
    };

    componentDidMount() {
        if (this.isTypeDisabled(this.state.selected)) {
            const enabledType = Object.keys(this.viewType).find(type => !this.isTypeDisabled(type)) || '';
            this.viewTypeChanged(enabledType);
        }

        if (!this.props.initialSelection) {
            try {
                this.setupInitialDay();
            } catch (e) {
                console.error(e);
            }
            return;
        }

        if (this.props.filterMode === 'RANGE' && !this.isTypeDisabled('PERIOD')) {
            const {left, right} = this.props.initialSelection;
            this.viewTypeChanged('PERIOD');
            if (left && right) {
                const parsedLeft = timeMemberToMoment(left.value);
                const parsedRight = timeMemberToMoment(right.value);
                this.setState({calendarMaxDetail: parsedLeft.detail});
                this.dateChanged(
                  [
                      parsedLeft.parsedDate.toDate(),
                      parsedRight.parsedDate.toDate(),
                  ],
                  true,
                  parsedLeft.detail);
            }
        } else if (!_.isEmpty(this.props.initialSelection.selectedItems)) {
            const memberUname = this.props.initialSelection.selectedItems[0].value;
            const groupValue = _.get(
              this.props.dynamicMembers.find(sig => {
                  const m = sig.selectItems.find(si => si.value === memberUname);
                  return !!m;
              }),
              'value',
              null
            );
            if (groupValue) {
                const [viewType] = Object.entries(this.viewType).find(([key, vt]) => {
                    return vt.canHandle && vt.canHandle(groupValue);
                });
                if (viewType) {
                    this.viewTypeChanged(viewType);
                    // See: https://github.com/sol7/bi-machine/issues/3173
                    const items = [];
                    if (!_.isEmpty(this.props.initialSelection.selectedItems)) {
                        items.push(this.props.initialSelection.selectedItems[0]);
                    }
                    this.selectedItemsChanged(items);
                }
            }

        }
    }

    viewTypeChanged = (newType) => {
        if (newType === 'PERIOD') {
            this.props.changeMode({value: 'RANGE'})
        } else {
            this.props.changeMode({value: 'NORMAL'})
        }
        this.props.onChange([]);
        this.props.onChange([], 'LEFT');
        this.props.onChange([], 'RIGHT');

        this.setState({
            ..._.cloneDeep(INITIAL_STATE),
            selected: newType
        });
    };

    dateChanged = (selectedDates = [], canSetDate = true, calendarMaxDetail = this.state.calendarMaxDetail) => {
        //TODO Tarefa #2158 rever filtros fixos legados
        if ((selectedDates[0] && isNaN(selectedDates[0].getDate())) || (selectedDates[1] && isNaN(selectedDates[1].getDate()))) {
            UiMsg.error(this.props.context.msg.t('load.filters.error'));
        } else {
            let leftDate;
            let rightDate;
            // See: https://github.com/sol7/bi-machine/issues/7374
            {
                const adjustedSelectedDates = selectedDates.map(d => {
                    if(d) {
                        d = cloneAndAdjustDate(d);
                    }
                    return d
                });
                leftDate = moment(adjustedSelectedDates[0]);
                rightDate = moment(adjustedSelectedDates[1]);
            }

            if (calendarMaxDetail === 'month') {
                const DATE_FORMAT = Utils.Date.findDateFormat('pt-BR');
                const left = leftDate.format(this.DAY_MEMBER_FORMAT);
                const leftDefault = leftDate.format(DATE_FORMAT);
                this.props.onChange([{value: `[Dia].[${leftDefault}]`, label: left}], 'LEFT');

                const right = rightDate.format(this.DAY_MEMBER_FORMAT);
                const rightDefault = rightDate.format(DATE_FORMAT);
                this.props.onChange([{value: `[Dia].[${rightDefault}]`, label: right}], 'RIGHT');
            } else if (calendarMaxDetail === 'year') {
                const createMonthMember = (d) => {
                    const quarter = d.quarter();
                    const semester = quarter > 2 ? 2 : 1;
                    const year = d.year();
                    const month = d.month() + 1;
                    return `[${year}].[S${semester}].[Q${quarter}].[M${month}]`;
                };
                // Month member format: [2019].[S1].[Q1].[M1]
                this.props.onChange([{
                    value: createMonthMember(leftDate),
                    label: leftDate.format(MONTH_FORMAT)
                }], 'LEFT');
                this.props.onChange([{
                    value: createMonthMember(rightDate),
                    label: rightDate.format(MONTH_FORMAT)
                }], 'RIGHT');
            } else if (calendarMaxDetail === 'decade') {
                // Year member format: [2019]
                this.props.onChange([{
                    value: `[${leftDate.year()}]`,
                    label: `${leftDate.year()}`
                }], 'LEFT');
                this.props.onChange([{
                    value: `[${rightDate.year()}]`,
                    label: `${rightDate.year()}`
                }], 'RIGHT');
            }

            if (canSetDate) {
                this.setState({
                    viewDate: selectedDates,
                    date: selectedDates,
                    canSetDate
                });
            } else {
                this.setState({
                    viewDate: selectedDates,
                    canSetDate
                });
            }
        }
    };

    daySelected = (selectedDate) => {
        if (this.state.canSetDate) {
            this.dateChanged([selectedDate, selectedDate], false);
        }
    };
    monthSelected = (selectedDate) => {
        if (this.state.canSetDate && this.state.calendarMaxDetail === 'year') {
            this.dateChanged([selectedDate, selectedDate], false);
        }
    };
    yearSelected = (selectedDate) => {
        if (this.state.canSetDate && this.state.calendarMaxDetail === 'decade') {
            this.dateChanged([selectedDate, selectedDate], false);
        }
    };

    selectedItemsChanged = (selectedItems) => {
        this.props.onChange(selectedItems);
        this.setState({selectedItems});
    };

    renderGroupRadioSelection(group) {
        const items = !!group ? group.selectItems : [];
        return (
          <div style={{padding: '0 20px'}}>
              <SelectItems items={items}
                           value={this.state.selectedItems}
                           onChange={this.selectedItemsChanged}
                           mode="ONE"
                           createMemberIfNotFound={false}
                           maxElements={9999}
              />
          </div>
        );
    }

    renderRadioSelection(items) {
        return (
          <div style={{padding: '0 20px'}}>
              <SelectItems items={items}
                           value={this.state.selectedItems}
                           onChange={this.selectedItemsChanged}
                           mode="ONE"
                           createMemberIfNotFound={false}
                           maxElements={9999}
              />
          </div>
        );
    }

    isTypeDisabled = (type) => {
        const viewType = this.viewType[type];
        if (viewType.hasOwnProperty('isDisabled')) {
            return viewType.isDisabled(this);
        }
        return (
          viewType.hasOwnProperty('canHandle')
            ? _.isEmpty(this.props.dynamicMembers.find(m => viewType.canHandle(m.value)))
            : false
        );
    };

    renderTypeItem = (str) => {
        const selected = this.state.selected === str;
        const disabled = this.isTypeDisabled(str);

        return (
          <li key={str} className={selected ? 'selected' : ''}>
              <a href="#"
                 className={`${disabled ? 'disabled' : ''}`}
                 onClick={e => {
                     e.preventDefault();
                     if (!disabled) {
                         this.viewTypeChanged(str);
                     }
                 }}>
                  <div>
                      {this.props.context.msg.t(str)}
                      {selected &&
                        <Icon icon="check" className="pull-right"/>
                      }
                  </div>
              </a>
          </li>
        );
    };

    setupInitialDay = () => {
        if (!this.CalendarInstance) {
            return;
        }

        if (!this.props.containRestriction
          || !this.isCalendarTileDisabled(moment().format(this.DAY_MEMBER_FORMAT))) {
            this.CalendarInstance.setActiveStartDate(
              moment().toDate()
            );
            return;
        }

        for (let i = 1; i <= 5; i++) {
            const enabledDate = [
                moment().add(i, 'y'),
                moment().add(-i, 'y')
            ].find(date => !this.isCalendarTileDisabled(date.format(this.DAY_MEMBER_FORMAT)));
            if (enabledDate) {
                this.CalendarInstance.setActiveStartDate(
                  enabledDate.toDate()
                );
                break;
            }
        }

    };

    render() {

        const viewType = this.viewType[this.state.selected];

        return (
          <div className="row-fluid TimeFilterView">
              <div className="span4 Menu">

                  <div className="Header">
                      {this.props.context.msg.t('filter.position.fixed')}
                  </div>
                  <ul className="unstyled">
                      {['PERIOD'].map(this.renderTypeItem)}
                  </ul>

                  <div className="Header">
                      {this.props.context.msg.t('dynamics')}
                  </div>
                  <ul className="unstyled">
                      {['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY', 'OTHERS'].map(this.renderTypeItem)}
                  </ul>

              </div>
              <div className="span8 Content">
                  {viewType?.render?.()}
              </div>

          </div>
        )
    }

}

const parseDateMemberToParts = (value) => {
    const parts = value.split('.').map(p => p.slice(1, -1));

    if (parts[0] === 'Dia') {
        const [dayHierarchy, date] = parts;
        return {
            date: date,
            parts: []
        }
    } else {
        const [year, semester, quarter, month, week, date] = parts;
        return {
            year,
            semester: _.parseInt(semester?.slice(1)) || null,
            quarter: _.parseInt(quarter?.slice(1)) || null,
            month: _.parseInt(month?.slice(1)) || null,
            week: _.parseInt(week?.slice(1)) || null,
            date,
            parts
        };
    }
}

export const timeMemberToMoment = (value, startDate = null) => {
    const {year, semester, quarter, month, date, parts} = parseDateMemberToParts(value);
    let dateStr;
    let detail;

    switch (parts.length) {
        case 1:
            // Year member format: [2019]
            dateStr = `01/01/${year}`;
            detail = 'decade';
            break;
        case 2:
            // Semester member format: [2019].[S1]
            dateStr = buildSemesterDate(semester, year, startDate);
            detail = 'year';
            break;
        case 3:
            // Quarter member format: [2019].[S1].[Q1]
            dateStr = buildQuarterDate(quarter, year, startDate);
            detail = 'year';
            break;
        case 4:
            // Month member format: [2019].[S1].[Q1].[M1]
            const paddedMonth = _.padStart(month, 2, '0');
            dateStr = `01/${paddedMonth}/${year}`;
            detail = 'year';
            break;
        default:
            // Day format [XX/XX/XXXX]
            dateStr = date;
            detail = 'month';
            break;
    }

    // See: https://github.com/sol7/bi-machine/issues/7374
    dateStr = `${dateStr.split('/').reverse().join('-')}T12:00:00`;

    return {
        parsedDate: moment(dateStr),
        detail
    };
};

function buildSemesterDate(semester, year, startDate) {
    switch (semester) {
        case 1:
            return startDate ? `01/01/${year}` : `30/06/${year}`;
        case 2:
            return startDate ? `01/07/${year}` : `31/12/${year}`;
        default:
            throw new Error(`Invalid semester: ${semester}`);
    }
}

function buildQuarterDate(quarter, year, startDate) {
    switch (quarter) {
        case 1:
            return startDate ? `01/01/${year}` : `31/03/${year}`;
        case 2:
            return startDate ? `01/04/${year}` : `30/06/${year}`;
        case 3:
            return startDate ? `01/07/${year}` : `30/09/${year}`;
        case 4:
            return startDate ? `01/10/${year}` : `31/12/${year}`;
        default:
            throw new Error(`Invalid quarter: ${quarter}`);
    }
}

export default ContextEnhancer(TimeFilterView);