import styles from './BngExportDialog.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';

import bngYup from 'components/bng/form/yup/BngYup';
import BngField from 'components/bng/form/BngField';
import BngForm from 'components/bng/form/BngForm';
import useBimContext from 'components/hooks/useBimContext';
import Dialog from 'components/ui/Dialog';
import Utils from 'components/Utils';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import BngButtonGroup from 'components/bng/ui/Buttons/BngButtonGroup';
import { TabSet } from 'components/ui/TabSet';
import BngExportRecipientsTab, {
  BngExportRecipientsTabSchema,
} from 'components/bng/exportScheduling/BngExportRecipientsTab';
import BngExportPeriodicityTab, {
  BngExportPeriodicityTabSchema,
} from 'components/bng/exportScheduling/BngExportPeriodicityTab';
import BngExportOptionsTab, { BngExportOptionsTabSchema } from 'components/bng/exportScheduling/BngExportOptionsTab';
import Api from 'components/Api';
import BimEventBus from 'BimEventBus';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import UiMsg from 'components/ui/UiMsg';

const BngExportDialogSchema = bngYup((yup) => {
  return yup.object().shape({
    schedulingType: yup.string().default(Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED),
    messageType: yup.string().default(Utils.Scheduling.DELIVERY_TYPE.EMAIL),
  });
});

const schemas = [
  BngExportDialogSchema,
  BngExportRecipientsTabSchema,
  BngExportPeriodicityTabSchema,
  BngExportOptionsTabSchema,
];

const FormSchema = schemas.slice(1).reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), schemas[0]);

export default function BngExportDialog({
  closeModal,
  id,
  schedulingType,
  messageType,
  contents,
  cockpit,
  shareOpts = {
    enabled: false,
    params: {},
  },
}) {
  const context = useBimContext();

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const isEditing = !!id;

  const fetchScheduling = async (id) => {
    setLoading(true);
    try {
      const result = await Api.ExportScheduling.findOne(id);

      const initialValues = {
        ...result,
        sendTo: result.sendTo.map((member) => {
          const type = member.container ? 'GROUP' : 'USER';
          const effectiveMember = member.container ? member.userGroup : member.user;
          return {
            allowMobileMessaging: member.allowMobileMessaging,
            id: `${type}-${effectiveMember.id}`,
            email: member.email,
            deliveryType: member.messageType,
            name: member.container
              ? `${context.msg.t('group')} ${member.username}`
              : Utils.Users.displayName(effectiveMember),
            effectiveName: effectiveMember.name,
            type,
            phone: member.phone,
            userId: effectiveMember.id,
            exportId: member.id,
          };
        }),
        schedulingType: Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED,
        id,
      };
      setInitialValues(initialValues);
    } catch (e) {
      console.error('Error on fetchScheduling(): ', e);
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEditing) {
      fetchScheduling(id);
    } else {
      setInitialValues(FormSchema.default());
    }
  }, []);

  const onSubmit = async (values, formikActions) => {
    setLoading(true);
    try {
      const exportScheduling = {
        id: values.id,
        projectId: context.project.id,
        exportUsers: values.sendTo
          .filter((member) => member.type === 'USER')
          .map((user) => {
            return {
              user: {
                id: user.id.split('-')[1] ?? null,
              },
              messageType: user.deliveryType,
              id: user.exportId,
            };
          }),
        exportUserGroups: values.sendTo
          .filter((member) => member.type === 'GROUP')
          .map((group) => {
            return {
              userGroup: {
                id: group.id.split('-')[1] ?? null,
              },
              messageType: group.deliveryType,
              id: group.exportId,
            };
          }),
        ...values.errorValidation,
        ...values.resources,
        contents: values.resources.contents.map((obj, idx) => {
          return { ...obj, num: idx };
        }),
        scheduling: {
          ...Object.keys(BngExportPeriodicityTabSchema.describe().fields).reduce(
            (acc, key) => ({
              ...acc,
              [key]: values[key],
            }),
            {}
          ),
          identifier: !!values.identifier ? values.identifier.trim() : '',
          id: values.schedulingId,
          starts: Utils.Date.timeStringToDate(values.starts),
          ends: Utils.Date.timeStringToDate(values.ends),
          type:
            values.schedulingType === Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE ? values.schedulingType : values.type,
        },
        message: {
          id: values.messageId,
          title: !!values.title ? values.title : values.identifier,
          message: !!values.message
            ? values.message
            : context.msg.t('Share.export.title.placeholder', [
                context.msg.t(values.type).toLowerCase(),
                values.identifier,
              ]),
          sendReferences: values.sendReferences,
        },
        joinObjects: values.joinObjects,
        messageType: values.messageType,
        sendAttachments: values.sendAttachments,
      };

      if (shareOpts.enabled) {
        await Api.Export.share(exportScheduling, shareOpts.params);
        UiMsg.ok(context.msg.t('object.share.success'));
      } else {
        await Api.ExportScheduling.save(exportScheduling);
        UiMsg.ok(
          context.msg.t(isEditing ? 'in.memory.scheduling.update.success' : 'in.memory.scheduling.save.success')
        );
        BimEventBus.emit(Api.ExportScheduling.CHANGE_EVENT, {});
      }
      closeModal();
    } catch (e) {
      if (e.response?.status === 409) {
        formikActions.setFieldError('identifier', context.msg.t('Share.errors.identifier.exists'));
      } else {
        console.error('Error on onSubmit()', { values }, e);
        UiMsg.ajaxError(context.msg.t('in.memory.scheduling.save.error'), e);
      }
      setLoading(false);
      formikActions.setSubmitting(false);
    }
  };

  return (
    <Dialog
      className={`BngExportDialog large ${styles.dialogWrapper}`}
      onClose={closeModal}
      title={context.msg.t(shareOpts.enabled ? 'share' : 'send.scheduling')}
      newDialogLayout
    >
      <UiBlocker className={`${styles.dialogBodyWrapper}`} block={loading}>
        {initialValues && (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={FormSchema}
            enableReinitialize={true}
          >
            {({ values, errors, submitCount, setFieldValue }) => {
              useEffect(() => {
                if (schedulingType) {
                  setFieldValue('schedulingType', schedulingType);
                }

                if (messageType) {
                  setFieldValue('messageType', messageType);
                }

                if (contents) {
                  setFieldValue('resources.contents', contents);
                  setFieldValue('resources.type', Utils.Scheduling.TYPE.OBJECT);
                } else if (cockpit) {
                  setFieldValue('resources.cockpit', cockpit);
                  setFieldValue('resources.type', Utils.Scheduling.TYPE.COCKPIT);
                }
              }, []);

              useEffect(() => {
                //Setting defaultExportType in yup, as it is just 'visual' and should not be saved in the backend object
                if (_.isEmpty(values.resources.contents)) {
                  setFieldValue('defaultExportType', Utils.Scheduling.EXPORT_TYPE.PDF);
                } else {
                  let defaultExportType = 'PDF';
                  if (!shareOpts.enabled) {
                    defaultExportType = 'custom';
                    for (const type of ['PDF', 'IMG']) {
                      if (values.resources.contents.every((i) => i.exportType === type)) {
                        defaultExportType = type;
                        break;
                      }
                    }
                  }
                  setFieldValue('defaultExportType', defaultExportType);
                }

                if (values.messageType === Utils.Scheduling.DELIVERY_TYPE.WHATSAPP) {
                  setFieldValue('sendReferences', false);
                  setFieldValue('sendAttachments', true);
                }
              }, [values.type, values.messageType]);

              const tabErrors = useMemo(() => {
                const result = schemas.map((schema) => {
                  return submitCount >= 1
                    ? Object.keys(errors).filter((errorKey) => Object.keys(schema.describe().fields).includes(errorKey))
                    : [];
                });

                let idxWithError = result.findIndex((e) => !_.isEmpty(e));
                if (idxWithError !== -1) {
                  idxWithError -= 1;
                  if (idxWithError !== selectedTabIdx) {
                    setSelectedTabIdx(idxWithError);
                  }
                }

                return result;
              }, [errors, submitCount]);

              const errorMessage = useMemo(() => {
                const firstTabWithError = tabErrors.find((tabError) => tabError.length > 0);
                const errorName = firstTabWithError ? firstTabWithError[0] : null;
                if (errorName) {
                  return errors[errorName];
                }
                return null;
              }, [tabErrors]);

              const changeDeliveryType = (type) => {
                if (_.isEmpty(values.sendTo)) {
                  return;
                }
                const updatedSendToArr = [
                  ...values.sendTo.map((user) => {
                    if (
                      !user.allowMobileMessaging &&
                      type !== Utils.Scheduling.DELIVERY_TYPE.EMAIL &&
                      user.type === 'USER'
                    ) {
                      return user;
                    }
                    return { ...user, deliveryType: type };
                  }),
                ];
                setFieldValue('sendTo', updatedSendToArr);
              };

              const schedulingTypeOpts = [
                {
                  value: Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE,
                  label: context.msg.t('Share.shareType.immediate'),
                  icon: 'send',
                  disabled: isEditing,
                },
                {
                  value: Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED,
                  label: context.msg.t('Share.shareType.scheduled'),
                  icon: 'schedule_send',
                  disabled: shareOpts.enabled,
                },
              ];

              const messageTypeOpts = [
                {
                  value: Utils.Scheduling.DELIVERY_TYPE.EMAIL,
                  label: context.msg.t('email'),
                  icon: 'mail',
                  onClick: () => changeDeliveryType(Utils.Scheduling.DELIVERY_TYPE.EMAIL),
                },
                {
                  value: Utils.Scheduling.DELIVERY_TYPE.WHATSAPP,
                  label: context.msg.t('whatsapp'),
                  icon: 'fa-whatsapp',
                  onClick: () =>
                    checkAddonEnabled(AddonType.WHATS_APP.key, true)
                      ? changeDeliveryType(Utils.Scheduling.DELIVERY_TYPE.WHATSAPP)
                      : undefined,
                  onChange: !checkAddonEnabled(AddonType.WHATS_APP.key) ? _.noop : undefined,
                },
                {
                  value: Utils.Scheduling.DELIVERY_TYPE.TELEGRAM,
                  label: context.msg.t('telegram'),
                  icon: 'fa-telegram',
                  disabled: true,
                  title: context.msg.t('coming.soon'),
                },
              ];

              const tabs = [
                {
                  icon: 'group',
                  label: context.msg.t('email_recipient'),
                  render: () => <BngExportRecipientsTab values={values} shareMode={shareOpts.enabled} />,
                },
                {
                  icon: 'schedule',
                  label: context.msg.t('periodicity'),
                  disabled: values.schedulingType === Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE,
                  render: () => <BngExportPeriodicityTab />,
                  visible: shareOpts.enabled === false,
                },
                {
                  icon: 'settings',
                  label: context.msg.t('analyst.options'),
                  render: () => <BngExportOptionsTab shareMode={shareOpts.enabled} />,
                },
              ].filter((t) => t.visible !== false);

              tabs.forEach((tab, idx) => (tab.containError = !_.isEmpty(tabErrors[idx + 1])));

              return (
                <BngForm>
                  <div className={`${styles.headerWrapper}`}>
                    <Field
                      name={`schedulingType`}
                      rootClassName={`${styles.headerButtons}`}
                      label={context.msg.t('Share.shareType')}
                      labelClassName={`${styles.headerButtonsLabelShareType}`}
                      component={BngField}
                      showErrors={false}
                      inputComponent={BngButtonGroup}
                      options={schedulingTypeOpts}
                    />
                    <div className={`${styles.lineSeparator}`} />
                    <Field
                      name={`messageType`}
                      rootClassName={`${styles.headerButtons}`}
                      label={context.msg.t('send.via')}
                      labelClassName={`${styles.headerButtonsLabelMessageType}`}
                      component={BngField}
                      showErrors={false}
                      inputComponent={BngButtonGroup}
                      options={messageTypeOpts}
                    />
                  </div>
                  <div className={`${styles.tabsContainer}`}>
                    <TabSet
                      internal
                      bodyRadius={false}
                      className={`${styles.tabSetContainer}`}
                      tabs={tabs}
                      selectedTab={selectedTabIdx}
                      handleTabChange={(newTabIdx) => {
                        const newTab = tabs[newTabIdx];
                        if ((newTab.disabled ?? false) === false) {
                          setSelectedTabIdx(newTabIdx);
                        }
                      }}
                    />
                  </div>
                  <Dialog.Footer>
                    <DefaultDialogActions
                      okLabel={`${
                        values.schedulingType === Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE ? 'send' : 'save'
                      }`}
                      closeModal={closeModal}
                      submitCount={submitCount}
                      errorMessage={errorMessage}
                    />
                  </Dialog.Footer>
                </BngForm>
              );
            }}
          </Formik>
        )}
      </UiBlocker>
    </Dialog>
  );
}
