import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class SimpleValue extends BaseKpi {

    constructor(props) {
        super(props);
    }

    customResizeKpi = ({height}) => {
        this.$containerBandsEl.style.minHeight = "0px";

        resizeEl(this.$kpiTitleEl, {
            fontSize: {minValue: 12, multiplier: 0.15, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.2, refSize: height},
        });

        const valueElMultiplier = 0.35;
        resizeEl(this.$kpiValueEl, {
            fontSize: {minValue: 14, multiplier: valueElMultiplier, refSize: height},
            lineHeight: {minValue: 17, multiplier: valueElMultiplier, refSize: height},
        });

        if (this.$kpiValueEl.offsetWidth > this.props.div.offsetWidth) {
            const newHeight = height * (this.props.div.offsetWidth / this.$kpiValueEl.offsetWidth);

            resizeEl(this.$kpiValueEl, {
                fontSize: {minValue: 14, multiplier: valueElMultiplier, refSize: newHeight},
                lineHeight: {minValue: 17, multiplier: valueElMultiplier, refSize: newHeight},
            });
        }

        resizeEl(this.$kpiDescriptionEl, {
            fontSize: {minValue: 12, multiplier: 0.1, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.13, refSize: height},
        });
    };

    render() {
        const {title, preffix, suffix, label} = this.props;
        const titleValue = this.state.value.toString().includes(':') ? this.state.value : this.state.titleFormattedValue;

        return (
            <div>
                <table style={{width: '100%'}}>
                    <tbody>
                    {!!title &&
                    <tr>
                        <td>
                            <div style={{textAlign: 'center', whiteSpace: 'nowrap'}}
                                 className="kpititle">
                                <span ref={el => this.$kpiTitleEl = el}>{title}</span>
                            </div>
                        </td>
                    </tr>
                    }
                    <tr>
                        <td>
                            <div className="kpivalue" style={{textAlign: 'center'}}>
                                <div className="infobox-data-number"
                                     style={{color: this.state.color, whiteSpace: 'nowrap'}}>
                                    <span title={titleValue}
                                          ref={el => this.$kpiValueEl = el}>{preffix} {this.state.value} {suffix}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {!!label &&
                    <tr>
                    <td>
                        <div style={{textAlign: 'center', whiteSpace: 'nowrap'}}
                             className="kpidescription">
                            <span ref={el => this.$kpiDescriptionEl = el}>{label}</span>
                        </div>
                    </td>
                    </tr>
                    }
                    <tr>
                        <td>
                            <center ref={el => this.$containerBandsEl = el}>
                                {this.useBands({container: this.$containerBandsEl})}
                            </center>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}
