import { Axios, findContextPath } from 'commonUtils';

class BimUniversityApi {
  redirectToUniversityPage = () => {
    window.location.replace(`${findContextPath()}/spr/bng/university`);
  };

  generateAccess = async (projectId) => {
    const { data } = await Axios.post(`/spr/university`, {
      projectId,
    });
    return data;
  };

  findAccess = async (userId) => {
    const { data } = await Axios.get(`/spr/university/${userId}`);
    return data;
  };

  syncUser = async (userId) => {
    return await Axios.post(`/spr/university/${userId}/sync`);
  };

  removeAccess = async (userId, projectName, oldEmail) => {
    return await Axios.delete(`/spr/university/${userId}`, {
      params: {
        projectName,
        oldEmail,
      },
    });
  };
}

export default BimUniversityApi;
