import Utils from 'components/Utils';
import FolderPermission from './FolderPermission';

export default class Permission {
  static ROLES = ['Viewer', 'Explorer', 'Expert', 'Administrator', 'Master'];

  constructor({ user, role, permissions, userIsOnlyCockpit, labs, bimResources }) {
    this.user = user;
    this.role = role;
    this.permissions = permissions;
    this.userIsOnlyCockpit = userIsOnlyCockpit;
    this.roleLevel = Permission.ROLES.indexOf(this.role);
    this.folderPermission = new FolderPermission();
    this.labs = labs;
    this.bimResources = bimResources;
  }

  canCreateObjects() {
    return this.isAtLeastExpert();
  }

  isAtLeastExpert() {
    return this.roleLevel > 1;
  }

  isAtLeastExplorer() {
    return this.roleLevel > 0;
  }

  isAtLeast(role) {
    const roleIdx = Permission.ROLES.indexOf(role);
    return this.roleLevel >= roleIdx;
  }

  isExplorer() {
    return this.role === 'Explorer';
  }

  canExploreData() {
    return this.isAtLeastExpert();
  }

  canCreateMaps() {
    return this.isAdmin() && (this.isFromDomain('dbseller') || this.isFromDomain('sol7'));
  }

  isFromDomain(domain) {
    return this.user.email.indexOf(`@${domain}.`) !== -1;
  }

  isAdmin() {
    return this.role === 'Administrator';
  }

  isMaster() {
    return this.role === 'Master';
  }

  canManagePresentations() {
    return true;
  }

  canManageMonitors() {
    return this.isAtLeastExpert();
  }

  isPathWritable(path = '') {
    return this.folderPermission.canWrite(path);
  }

  canAnalystMenu(path) {
    const valid = this.isAtLeastExpert() && this.isPathWritable(path);
    if (Utils.Object.isAnalysis(path)) {
      return valid && this.containPermission('analysis', 'analyst-menu');
    }
    return valid;
  }

  canManipulateObject(path) {
    return this.isAtLeastExplorer() && this.isPathWritable(path);
  }

  containPermission(module, action) {
    let permission = module;
    if (!!action) {
      permission += '.' + action;
    }
    return !!this.permissions.find((p) => p === permission);
  }

  canCreatePresentations() {
    return true;
  }

  canManageCockpits() {
    return this.isAtLeastExpert() && !this.userIsOnlyCockpit;
  }

  canAccessBimGateway() {
    return this.isAdmin() && this.bimResources.bimGateway;
  }

  isConsultant() {
    return this.isFromDomain('sol7') || this.isFromDomain('bimachine');
  }

  isViewer() {
    return this.role === 'Viewer';
  }

  canImportProjects() {
    switch (this.user.email) {
      case 'desenvolvimento@sol7.com.br':
      case 'apps@bimachine.com.br':
      case 'suporte@sol7.com.br':
        return true;
      default:
        return this.user.superUser ?? false;
    }
  }

}
