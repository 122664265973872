import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import Icon from "components/ui/common/Icon";

import styles from './DashboardItemMenuLastDatasourceUpdate.module.css';

class DashboardItemMenuLastDatasourceUpdate extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {
        open: false,
        result: null,
    };

    open = async () => {
        if(!this.state.open) {
            this.props.onOpen(event);
            await this.lastUpdate();
        }
        this.setState({open: !this.state.open});
    };

    forceClose = () => {
        this.setState({open: false});
    };

    lastUpdate = async () => {
        try{
            const {lastUpdate} = await Api.Project.findLastUpdate(this.props.item.path);
            this.setState({result: moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss')});
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        }
    };

    defineStyles = () => {
        let {isMobile, orientation, position} = this.props;
        let style = '';
        if(isMobile && orientation === 'portrait') style += ' ' + styles.mobile;
        if(isMobile && orientation === 'landscape') style += ' ' + styles.adjustUpdateCardHeight;
        if(isMobile && orientation === 'portrait' && position.y > window.innerHeight/2 + 150) style += ' ' + styles.moveCardUp
        return style;
    }

    render() {

        const {onClick} = this.props;

        return (
            <li className={`bt-item-new-menu-inside ${this.state.open ? 'active' : ''}`} onClick={onClick}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('last.update')}
                                        icon="icon-time"
                                        more={true}
                                        onClick={this.open}
                />
                {this.state.open &&
                    <ul className={`bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-last-update ${this.defineStyles()}`}>
                        <li>
                            <div className="row-fluid">

                                <div className="lastUpdateItemDataMainContainer">
                                    <div><Icon icon="access_time" style={{fontSize:'24px'}}/></div>
                                    <div className="lastUpdateItemDataLabel">{this.props.context.msg.t('last.datasource.update')}:</div>
                                    <div className="lastUpdateItemData">
                                        {!!this.state.result ? this.state.result  : `${this.props.context.msg.t('loading')}...`}
                                    </div>
                                </div>

                            </div>
                        </li>
                    </ul>
                }

            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuLastDatasourceUpdate);