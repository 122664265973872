import React, { useEffect, useState } from 'react';

import style from './InMemoryLoadLimitAlertDialog.module.css';
import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import ContextEnhancer from 'components/ContextEnhancer';
import { BngTable } from 'components/bng/ui/BngTable';
import Utils from 'components/Utils';

const InMemoryLoadLimitAlertDialog = ({
  context: { project, msg },
  initialStructure,
  inMemoryId,
  inMemorySchedulingId,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [limits, setLimits] = useState([]);
  const [structure, setStructure] = useState(initialStructure);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (!initialStructure) {
          const [inMemory] = await Api.Project.findStructures(project.id, {
            inMemoryId,
          });
          setStructure(inMemory);
        }

        const inMemoryIdFilter = initialStructure?.id || inMemoryId;
        const data = await Api.InMemoryLoadLimit.findForProject({
          projectId: project.id,
          inMemoryId: inMemoryIdFilter,
          inMemorySchedulingId,
        });

        setLimits(data);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Dialog
      className={`InMemoryLoadLimitAlertDialog large ${style.main}`}
      title={`${msg.t(`InMemoryLoadLimitAlertDialog.title`)}: ${
        structure?.caption || '-'
      }`}
      loading={loading}
      onClose={closeModal}
    >
      <Dialog.Body>
        <div className={style.tableContainer}>
          <BngTable
            rows={limits}
            cols={[
              {
                label: msg.t('data.load.schedule'),
                render: (row) => {
                  const sched = structure.schedulings.find(
                    (s) => s.id === row.inMemorySchedulingId
                  );
                  return (
                    <small>
                      {row.inMemorySchedulingId} - {sched.name}
                    </small>
                  );
                },
                size: 400,
              },
              {
                label: msg.t('date'),
                render: (row) => {
                  return (
                    <small>{Utils.Date.formatDateTime(row.createdAt)}</small>
                  );
                },
                size: 160,
              },
              {
                label: msg.t('lines'),
                render: (row) => {
                  if(!row.props.linesLimit) return '-';

                  return (
                    <small
                      className={
                        row.props.lines > row.props.linesLimit
                          ? 'text-error'
                          : ''
                      }
                    >
                      {row.props.lines} / {row.props.linesLimit}
                    </small>
                  );
                },
                size: 200,
              },
              {
                label: msg.t('queryTime'),
                render: (row) => {
                  if(!row.props.queryTimeLimit) return '-';

                  return (
                    <small
                      className={
                        row.props.queryTime > row.props.queryTimeLimit
                          ? 'text-error'
                          : ''
                      }
                    >
                      {formatTime(row.props.queryTime)} /{' '}
                      {formatTime(row.props.queryTimeLimit)}
                    </small>
                  );
                },
                size: 165,
              },
            ]}
            stickyHeader
          />
        </div>
      </Dialog.Body>
    </Dialog>
  );
};

const formatTime = (time) => {
  const minutes = time / 1000 / 60;
  return Math.ceil(minutes) + 'min';
};

export default ContextEnhancer(InMemoryLoadLimitAlertDialog);
