import React from "react";
import {connect} from "react-redux";
import {NAVBAR} from "components/ui/redux/Actions";
import Navbar from "components/ui/navbar/Navbar";

const mapStateToProps = (state) => {
    return {
        info: state.navbar.info
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInfo: () => dispatch(NAVBAR.findInfo())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar);