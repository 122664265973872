import React from 'react';

import ContextEnhancer from "components/ContextEnhancer";
import BlockSelect from 'components/bng/pages/bigTable/components/BlockSelect';
import {
    alignOptions,
    backgroundColors,
    fontColors,
    fontSizeOptions,
    rowHeightOptions,
    textFormatOptions
} from 'components/bng/pages/bigTable/constants';
import {BngInput} from "components/bng/form/BngInput";
import ButtonGroup from "components/ui/button-group/ButtonGroup";
import {BngSelectSearch} from "components/bng/form/BngSelectSearch";
import LineColorPicker from "components/bng/pages/bigTable/components/LineColorPicker";
import BngCheckbox from "components/bng/form/BngCheckbox";

const TextsMenu = ContextEnhancer(
    class TextsMenuInner extends React.PureComponent {

        state = {
            type: null
        };

        async componentDidMount() {
        }

        static getDerivedStateFromError(error) {
            return {hasError: true};
        }

        componentDidCatch(error, errorInfo) {
            console.error(error, errorInfo);
        }

        onGridConfigChange(field, value) {
            let next = {
                ...this.props.gridConfig,
                [field]: value
            };

            if (this.props.gridConfig.rowHeight != next.rowHeight) {
                if (parseInt(this.props.gridConfig.fontSize) > parseInt(next.rowHeight)) {
                    next.fontSize = next.rowHeight;
                }
            }

            this.props.onGridConfigChange(next);
        }

        onTotalizerConfigChange(field, value) {
            let next = {
                ...this.props.totalizerConfig,
                [field]: value
            };
            this.props.onTotalizerConfigChange(next);
        }

        onTitleConfigChange(field, value) {
            let next = {
                ...this.props.titleConfig,
                [field]: value
            };
            this.props.onTitleConfigChange(next);
        }

        getOptions() {
            const {titleConfig, gridConfig} = this.props;

            return [
                {
                    label: this.props.context.msg.t('title'),
                    value: 'title',
                    style: {
                        fontSize: titleConfig.titleFontSize,
                        fontColor: titleConfig.titleColor,
                        textFormat: titleConfig.titleFormat,
                    }
                },
                {
                    label: this.props.context.msg.t('description'),
                    value: 'description',
                    style: {
                        fontSize: titleConfig.descriptionFontSize,
                        fontColor: titleConfig.descriptionColor,
                        textFormat: titleConfig.descriptionFormat,
                    }
                },
                {label: this.props.context.msg.t('body'), value: 'body'},
                {
                    label: this.props.context.msg.t('header'),
                    value: 'header',
                    style: {
                        fontColor: gridConfig.headerFontColor,
                        backgroundColor: gridConfig.headerBackgroundColor,
                        textFormat: ['bold']
                    }
                },
                {
                    label: this.props.context.msg.t('selected_line'),
                    value: 'selected_line',
                    style: {
                        fontColor: gridConfig.selectedTextColor,
                        backgroundColor: gridConfig.selectedBackgroundColor,
                    }
                },
                {
                    label: this.props.context.msg.t('pinned_column'),
                    value: 'pinned_column',
                    style: {
                        backgroundColor: gridConfig.pinnedColumnBackgroundColor,
                    }
                },
                {
                    label: this.props.context.msg.t('bigtable.totalizer.opt'),
                    value: 'totalizer',
                },
            ]
        }

        getFontSizeOptions() {
            const {gridConfig} = this.props;
            let fontSizeOptionsArray = [];
            for (let i = 6; i <= Math.min(parseInt(gridConfig.rowHeight), 32); i++) {
                fontSizeOptionsArray.push({
                    label: `${i} px`,
                    value: String(i)
                });
            }
            return fontSizeOptionsArray;
        }

        renderTitleForm() {
            const {titleConfig} = this.props;

            return (
                <div className="BngForm">

                    <label className="control-label">{this.props.context.msg.t('title')}</label>
                    <BngInput type="text" field={{
                        onChange: (t) => this.onTitleConfigChange('title', t.target.value),
                        value: titleConfig.title
                    }}/>

                    <LineColorPicker
                        label={this.props.context.msg.t('label.color')}
                        options={fontColors}
                        showCustom={true}
                        value={titleConfig.titleColor}
                        onChange={(t) => this.onTitleConfigChange('titleColor', t)}
                    />

                    <div className="BngBigTable__columnsForm__grid">
                        <div className="w-45">
                            <label className="control-label">{this.props.context.msg.t('size')}</label>
                            <BngSelectSearch
                                options={fontSizeOptions}
                                form={{
                                    setFieldValue: (name, t) => this.onTitleConfigChange('titleFontSize', t)
                                }}
                                field={{value: titleConfig.titleFontSize, onChange: _ => _.noop()}}
                                clearable={false}
                            />
                        </div>
                        <div className="w-45" style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <ButtonGroup
                                label={''}
                                multiple={true}
                                items={textFormatOptions}
                                value={titleConfig.titleFormat}
                                onChange={(val) => this.onTitleConfigChange('titleFormat', val)}
                            />
                        </div>
                    </div>

                    <div className="BngBigTable__columnsForm__grid">
                        <div className="w-45">
                        </div>
                        <div className="w-45" style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <ButtonGroup
                                items={alignOptions}
                                value={titleConfig.titleAlign}
                                onChange={(val) => this.onTitleConfigChange('titleAlign', val)}
                            />
                        </div>
                    </div>

                </div>
            )

        }

        renderDescriptionForm() {
            const {titleConfig} = this.props;

            return (
                <div className="BngForm">

                    <label className="control-label">{this.props.context.msg.t('description')}</label>
                    <BngInput type="text" field={{
                        onChange: (t) => this.onTitleConfigChange('description', t.target.value),
                        value: titleConfig.description
                    }}/>

                    <LineColorPicker
                        label={this.props.context.msg.t('font.color')}
                        options={fontColors}
                        showCustom={true}
                        value={titleConfig.descriptionColor}
                        onChange={(t) => this.onTitleConfigChange('descriptionColor', t)}
                    />

                    <div className="BngBigTable__columnsForm__grid">
                        <div className="w-45">
                            <label className="control-label">{this.props.context.msg.t('font.size')}</label>
                            <BngSelectSearch
                                options={fontSizeOptions}
                                form={{
                                    setFieldValue: (name, t) => this.onTitleConfigChange('descriptionFontSize', t)
                                }}
                                field={{value: titleConfig.descriptionFontSize, onChange: _.noop}}
                                clearable={false}
                            />
                        </div>
                        <div className="w-45" style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <ButtonGroup
                                label={''}
                                multiple={true}
                                items={textFormatOptions}
                                value={titleConfig.descriptionFormat}
                                onChange={(val) => this.onTitleConfigChange('descriptionFormat', val)}
                            />
                        </div>
                    </div>

                    <div className="BngBigTable__columnsForm__grid">
                        <div className="w-45">
                        </div>
                        <div className="w-45" style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <ButtonGroup
                                items={alignOptions}
                                value={titleConfig.descriptionAlign}
                                onChange={(val) => this.onTitleConfigChange('descriptionAlign', val)}
                            />
                        </div>
                    </div>

                </div>
            )
        }

        renderBodyForm() {
            const {gridConfig} = this.props;
            return (
                <div className="BngForm">

                    <LineColorPicker
                        label={this.props.context.msg.t('background_color')}
                        options={backgroundColors}
                        showCustom={true}
                        value={gridConfig.backgroundColor}
                        onChange={(t) => this.onGridConfigChange('backgroundColor', t)}
                    />

                    <LineColorPicker
                        label={this.props.context.msg.t('stripped_line')}
                        options={backgroundColors}
                        showCustom={true}
                        value={gridConfig.strippedColor}
                        onChange={(t) => this.onGridConfigChange('strippedColor', t)}
                    />

                    <div className="BngBigTable__columnsForm__grid">
                        <div className="w-45">
                            <label className="control-label">{this.props.context.msg.t('font.size')}</label>
                            <BngSelectSearch
                                options={this.getFontSizeOptions()}
                                form={{
                                    setFieldValue: (name, t) => this.onGridConfigChange('fontSize', t)
                                }}
                                field={{value: gridConfig.fontSize, onChange: _.noop}}
                                clearable={false}
                            />
                        </div>
                        <div className="w-45">
                            <label className="control-label">{this.props.context.msg.t('row_height')}</label>
                            <BngSelectSearch
                                options={rowHeightOptions}
                                form={{
                                    setFieldValue: (name, t) => this.onGridConfigChange('rowHeight', t)
                                }}
                                field={{value: gridConfig.rowHeight, onChange: _.noop}}
                                clearable={false}
                            />
                        </div>
                    </div>

                </div>
            )
        }

        renderHeaderForm() {
            const {gridConfig} = this.props;
            return (
                <div className="BngForm">

                    <BngCheckbox
                        label={this.props.context.msg.t('hide')}
                        field={{value: !gridConfig.showHeader}}
                        onChange={_ => this.onGridConfigChange('showHeader', !gridConfig.showHeader)}
                    />

                    <BngCheckbox
                        label={this.props.context.msg.t('enable_text_slicing')}
                        field={{value: !gridConfig.wrapHeaderText}}
                        onChange={_ => this.onGridConfigChange('wrapHeaderText', !gridConfig.wrapHeaderText)}
                    />

                    <LineColorPicker
                        label={this.props.context.msg.t('font.color')}
                        options={fontColors}
                        showCustom={true}
                        value={gridConfig.headerFontColor}
                        onChange={(t) => this.onGridConfigChange('headerFontColor', t)}
                    />

                    <div className="BngBigTable__columnsForm__grid">
                        <div className="w-45">
                            <label className="control-label">{this.props.context.msg.t('font.size')}</label>
                            <BngSelectSearch
                                options={fontSizeOptions}
                                form={{
                                    setFieldValue: (name, t) => this.onGridConfigChange('headerFontSize', t)
                                }}
                                field={{value: gridConfig.headerFontSize, onChange: _.noop}}
                                clearable={false}
                            />
                        </div>
                        <div className="w-45 mb-10">
                            <label className="control-label">{this.props.context.msg.t('height')}</label>
                            <BngSelectSearch
                                options={rowHeightOptions}
                                form={{
                                    setFieldValue: (name, t) => this.onGridConfigChange('headerHeight', t)
                                }}
                                field={{value: gridConfig.headerHeight, onChange: _.noop}}
                                clearable={false}
                            />
                        </div>
                    </div>

                </div>
            )
        }

        renderStrippedLineForm() {
            const {gridConfig} = this.props;
            return (
                <div className="BngForm">
                    <LineColorPicker
                        label={this.props.context.msg.t('background_color')}
                        options={backgroundColors}
                        showCustom={true}
                        value={gridConfig.strippedColor}
                        onChange={(t) => this.onGridConfigChange('strippedColor', t)}
                    />
                </div>
            )
        }

        renderSelectedLineForm() {
            const {gridConfig} = this.props;
            return (
                <div className="BngForm">
                    <LineColorPicker
                        label={this.props.context.msg.t('background_color')}
                        options={backgroundColors}
                        showCustom={true}
                        value={gridConfig.selectedBackgroundColor}
                        onChange={(t) => this.onGridConfigChange('selectedBackgroundColor', t)}
                    />

                    <LineColorPicker
                        label={this.props.context.msg.t('font.color')}
                        options={fontColors}
                        showCustom={true}
                        value={gridConfig.selectedTextColor}
                        onChange={(t) => this.onGridConfigChange('selectedTextColor', t)}
                    />
                </div>
            )
        }

        renderPinnedColumnForm() {
            const {gridConfig} = this.props;
            return (
                <div className="BngForm">
                    <LineColorPicker
                        label={this.props.context.msg.t('background_color')}
                        options={backgroundColors}
                        showCustom={true}
                        value={gridConfig.pinnedColumnBackgroundColor}
                        onChange={(t) => this.onGridConfigChange('pinnedColumnBackgroundColor', t)}
                    />

                    <LineColorPicker
                        label={this.props.context.msg.t('stripped_line')}
                        options={backgroundColors}
                        showCustom={true}
                        value={gridConfig.pinnedColumnStrippedBackgroundColor}
                        onChange={(t) => this.onGridConfigChange('pinnedColumnStrippedBackgroundColor', t)}
                    />
                </div>
            )

        }

        renderTotalizerForm() {
            const {totalizerConfig} = this.props;
            return (
                <div className="BngForm">
                    <div className="BngBigTable__columnsForm__grid">
                        <div className="w-45">
                            <label className="control-label">{this.props.context.msg.t('font.size')}</label>
                            <BngSelectSearch
                                options={fontSizeOptions}
                                form={{
                                    setFieldValue: (name, t) => this.onTotalizerConfigChange('fontSize', t)
                                }}
                                field={{value: totalizerConfig.fontSize, onChange: _.noop}}
                                clearable={false}
                            />
                        </div>
                    </div>

                </div>
            )
        }

        renderForms() {
            const {type} = this.state;

            switch (type.value) {
                case 'title':
                    return this.renderTitleForm();
                case 'description':
                    return this.renderDescriptionForm();
                case 'body':
                    return this.renderBodyForm();
                case 'header':
                    return this.renderHeaderForm();
                case 'stripped_line':
                    return this.renderStrippedLineForm();
                case 'selected_line':
                    return this.renderSelectedLineForm();
                case 'pinned_column':
                    return this.renderPinnedColumnForm();
                case 'totalizer':
                    return this.renderTotalizerForm();
            }
        }

        render() {

            const {type} = this.state;

            return (
                <div className="BngBigTable__TextsMenu">

                    <BlockSelect
                        options={this.getOptions()}
                        value={type}
                        onChange={(option) => this.setState({type: option})}
                    />

                    {type && (
                        <div style={{padding: 10}}>
                            {this.renderForms()}
                        </div>
                    )}

                </div>
            );
        }
    }
);


export default TextsMenu;