import styles from './NPSCensus.module.css';

import React from 'react';

import useBimContext from 'components/hooks/useBimContext';
import ScoreCounter from 'components/bng/ui/Census/ScoreCounter';
import StarCounter from 'components/bng/ui/Census/StarCounter';

export default function AnswerPool({
  showMessageField = true,
  onChange = _.noop,
  score,
  setScore,
  likeMsg = '',
  dislikeMsg = '',
  starCounter = false,
  textValue = null,
}) {
  const context = useBimContext();
  const CounterComponent = starCounter ? StarCounter : ScoreCounter;

  return (
    <div className={`AnswerPool ${styles.censusWrapper}`} style={{ transform: `translate(${showMessageField ? -35.9 : 0}rem, 0)` }}>
      <div className={styles.censusVote}>
        <div className={styles.voteButtonsWrapper}>
          <CounterComponent score={score} setScore={setScore} />
        </div>
        {!starCounter && (
          <div className={styles.censusCaption}>
            <span>{dislikeMsg}</span>
            <span>{likeMsg}</span>
          </div>
        )}
      </div>
      <div className={styles.censusDescribe}>
        <textarea
          name="message"
          className={styles.captionField}
          cols="6"
          rows="5"
          placeholder={context.msg.t('census.describe.placeholder')}
          onChange={(e) => onChange(e.target.value ?? '')}
          value={textValue}
        ></textarea>
      </div>
    </div>
  );
}
