import { Axios } from 'commonUtils';

const endpoint = '/spr/projectManagement';

class ProjectManagementApi {
  async findAll() {
    return await Axios.getData(endpoint);
  }

  async findOne(projectId) {
    return await Axios.getData(`${endpoint}/${projectId}`);
  }

  async save(projectId, values) {
    await Axios.post(`${endpoint}/${projectId}`, values);
  }

  async validateCustomUrl(projectId, customUrl) {
    return await Axios.postData(`${endpoint}/${projectId}/validate-custom-url`, { customUrl });
  }

  async updateOpts(values) {
    await Axios.postData(`${endpoint}/${values.id}/opts`, values);
  }
}

export default ProjectManagementApi;
