import React, {Component} from "react";
import PropTypes from "prop-types";

class InputCodeMirror extends Component {

    static propTypes = {
        editorLanguage: PropTypes.oneOf(['sql']).isRequired,
        value: PropTypes.string,
        onChange: PropTypes.func
    };

    static defaultProps = {
        editorLanguage: 'sql',
        value: '',
        onChange: _.noop
    };

    state = {};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.cm = CodeMirror(this._div, {
            mode: this.getEditorLanguage(),
            indentWithTabs: true,
            smartIndent: true,
            lineNumbers: true,
            matchBrackets: true,
            readOnly: false
        });
        this.cm.on('change', (cm) => {
            this.props.onChange(cm.getValue());
        });
        if(this.props.value) {
            this.cm.setValue(this.props.value);
        }
    }

    appendContent = (content) => {
        this.cm.replaceRange(content, this.cm.getCursor());
    };

    componentDidUpdate(prevProps, prevState, prevContext) {
        if(this.cm.getValue() !== this.props.value) {
            this.cm.setValue(this.props.value);
        }
    }


    getEditorLanguage() {
        let type = this.props.type;
        if (type === 'sql') {
            type = 'text/x-sql';
        }
        return type;
    }

    componentWillUnmount() {
        this.cm = null;
    }

    render() {
        return (
            <div className={`${this.props.className || ''}`} ref={ref => this._div = ref}>
            </div>
        );
    }
}

export default InputCodeMirror;
