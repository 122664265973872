import './TwoFactorAuthConfigDialog.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';
import Dialog from 'components/ui/Dialog';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngPanel, BngPanelHeader } from 'components/bng/ui/BngPanel';
import Api from 'components/Api';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import OpConfirmation from 'components/ui/OpConfirmation';
import UiMsg from 'components/ui/UiMsg';
import { BngForm } from 'components/bng/form/BngForm';
import { BngField } from 'components/bng/form/BngField';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Button from 'components/ui/Button';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BngSwitch from 'components/bng/form/BngSwitch';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';

const FormSchema = bngYup((yup) =>
    yup.object({
      id: yup.string().optional(),
      value: yup.string().required().default(''),
      useContains: yup.boolean().default(false),
    })
);

function UseContainsIcon({ useContains }) {
  return <Icon icon={useContains ? 'check' : 'close'} style={{ color: useContains ? 'green' : 'red' }} />;
}

export default function TwoFactorAuthConfigDialog({ open, closeModal }) {
  const { msg } = useBimContext();

  const [conf, setConf] = useState({
    enabled: false,
    ignoreRules: [],
  });

  const findConf = async () => {
    try {
      const conf = await Api.TwoFactorAuth.findConf();
      setConf(conf);
    } catch (e) {
      console.error('Error on findConf()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  useEffect(() => {
    findConf();
  }, []);

  const cols = useMemo(() => {
    return [
      {
        label: msg.t('whitelist.use.contains.match'),
        size: '275px',
        render: (row) => <UseContainsIcon useContains={row.useContains} />,
      },
      { label: msg.t('email'), render: (row) => row.value },
      {
        size: '65px',
        render: (row) => (
            row.value !== '@sol7.com' && (
                <BngIconButton
                    icon={'delete'}
                    onClick={() => {
                      OpConfirmation({
                        msg,
                        title: msg.t('attention'),
                        message: msg.t('delete.confirmation.message'),
                        onConfirm: async () => {
                          try {
                            await Api.TwoFactorAuth.removeRule(row.id);
                            await findConf();
                          } catch (e) {
                            console.error('Error while removing rule', { row }, e);
                            UiMsg.ajaxError(null, e);
                          }
                        },
                      });
                    }}
                />
            )
        ),
      },
    ];
  }, []);

  return (
      <Dialog
          contentFullWidth={true}
          className="TwoFactorAuthenticationConfigDialog large"
          open={open}
          title={msg.t('two.factor.authentication')}
          onClose={closeModal}
          newDialogLayout
      >
        <Dialog.Body>
          <div className="row-fluid">
            <div className="tfa-form-header">
              <BngSwitch
                  id="enableTwoFactorAuth"
                  title={msg.t('enable.two.factor.authentication')}
                  checked={conf.enabled}
                  onChange={async (event) => {
                    try {
                      await Api.TwoFactorAuth.toggleEnable(event.target.checked);
                      await findConf();
                    } catch (e) {
                      console.error('Error while toggling enable on TFA', e);
                      UiMsg.ajaxError(null, e);
                    }
                  }}
              />
              <Formik
                  initialValues={FormSchema.default()}
                  validationSchema={FormSchema}
                  onSubmit={async (values, formikHelpers) => {
                    try {
                      await Api.TwoFactorAuth.saveRule(values);
                      formikHelpers.resetForm(FormSchema.default());
                      await findConf();
                    } catch (e) {
                      console.error('Error while saving rule', { values }, e);
                      UiMsg.ajaxError(null, e);
                    } finally {
                      formikHelpers.setSubmitting(false);
                    }
                  }}
              >
                {({ isValid, isSubmitting, dirty }) => {
                  return (
                      <BngForm>
                        <div className="tfa-form-header-fields">
                          <Field
                              name="value"
                              label={msg.t('email')}
                              as={BngField}
                              rootClassName="flex-grow-1 m-0-force"
                              inline
                              showErrors={false}
                          />
                          <Field
                              name="useContains"
                              component={BngField}
                              label={msg.t('whitelist.use.contains.match')}
                              inputComponent={BngCheckbox}
                              rootClassName="tfa-checkbox-field"
                              labelClassName="tfa-checkbox-label"
                          />
                          <Button type="submit" icon={'add'} disabled={!dirty || !isValid || isSubmitting} />
                        </div>
                      </BngForm>
                  );
                }}
              </Formik>
            </div>
          </div>
          <BngPanel className={`tfa-bng-panel`}>
            <BngPanelHeader className="tfa-bng-panel-header">
              <div>
                <h5 className="tfa-title-text">{msg.t('whitelist')}</h5>
              </div>
            </BngPanelHeader>
            <div className="scroll-bar-tfa-table-wrapper">
              <BngTable rows={conf.ignoreRules} cols={cols} className={'table-condensed scrollable'} />
            </div>
          </BngPanel>
        </Dialog.Body>
      </Dialog>
  );
}
