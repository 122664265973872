import { Axios } from 'commonUtils';
import { buildProgressListener } from 'components/service/bng/UploadApi';

export default class GenericFileSourceApi {
  async fetchState(fromScheduler = false) {
    const { data } = await Axios.get(`/spr/ui/inMemoryBean/genericFileSource`, {
      params: {
        fromScheduler,
      },
    });
    return data;
  }

  async updateState(values, fromScheduler = false, invalidate = true) {
    const { data } = await Axios.post(`/spr/ui/inMemoryBean/genericFileSource`, values, {
      params: {
        fromScheduler,
        invalidate,
      },
    });
    return data;
  }

  async validateConnection(values, fromScheduler = false) {
    const { data } = await Axios.post(`/spr/ui/inMemoryBean/genericFileSource/validate`, values, {
      params: {
        fromScheduler,
      },
    });
    return data;
  }

  async uploadFile(file, listener) {
    const fd = new FormData();
    fd.append('files', file);
    const { data } = await Axios.post(`/spr/ui/inMemoryBean/genericFileSource/upload`, fd, {
      onUploadProgress: buildProgressListener(listener),
    });
    return data;
  }

  async findComboOpts() {
    const { data } = await Axios.get(`/spr/ui/inMemoryBean/comboOpts`);
    return data;
  }
}
