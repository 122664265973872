import './HeatMapConfDialog.css';

import React from "react";
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import {Field, Validations, wrapForm} from "components/ui/FormUtils";
import BlockUi from 'react-block-ui';
import Dialog from "components/ui/Dialog";
import Button from "components/ui/Button";
import ReduxFormCheckbox from "components/ui/ReduxFormCheckbox";
import {ReduxFormSlider} from "components/ui/ReduxFormSlider";
import {ReduxFormSelect} from "components/ui/ReduxFormSelect";

class HeatMapConfDialog extends React.Component {


    static propTypes = {
        closeModal: PropTypes.func,
        onMaximize: PropTypes.func
    };

    static defaultProps = {
        formValues: {}
    };

    state = {};


    save = async (data) => {
        this.props.onSave({data, close: this.props.closeModal});
    };

    render() {
        if (!this.props.initialized) {
            return null;
        }

        const {formValues, sourceFields} = this.props;
        const {msg} = this.props.context;

        const availableMeasures = [
            {value: '', label: this.props.context.msg.t('select.one')},
            ...(_.sortBy(sourceFields.filter(f => f.type === 'Measure'), ['label']))
        ];

        return (
            <Dialog open={this.props.open} className="HeatMapConfDialog"
                    title={msg.t('configure.heat.map')}
                    onClose={this.props.closeModal}>
                <BlockUi tag="div" blocking={this.props.submitting}>
                    <form onSubmit={this.props.handleSubmit(this.save)}>

                        <Field name="enabled"
                               componentClass={ReduxFormCheckbox}
                               label={this.props.context.msg.t('enable.heatmap.by.default')}
                               groupClass="inline-checkbox"
                        />

                        <div className="use-metric-container">
                            <Field name="useMetric"
                                   componentClass={ReduxFormCheckbox}
                                   label={this.props.context.msg.t('use.metric')}
                                   groupClass="inline-checkbox"
                            />
                            <i className="fa fa-info-circle"
                               title={this.props.context.msg.t('use.metric.help.title')}
                            />
                        </div>

                        <Field name="metric"
                               componentClass={ReduxFormSelect}
                               label={this.props.context.msg.t('metric')}
                               disabled={!formValues.useMetric}
                               options={availableMeasures}
                               validate={formValues.useMetric ? Validations.required : undefined}
                        />

                        <Field name="radius"
                               componentClass={ReduxFormSlider}
                               label={this.props.context.msg.t('circle.radius')}
                               min={5}
                               max={50}
                               parse={_.parseInt}
                        />

                        <Field name="blur"
                               componentClass={ReduxFormSlider}
                               label={this.props.context.msg.t('circle.blur')}
                               min={1}
                               max={50}
                               parse={_.parseInt}
                        />

                        <Field name="minOpacity"
                               componentClass={ReduxFormSlider}
                               label={this.props.context.msg.t('opacity')}
                               min={1}
                               max={100}
                               parse={_.parseInt}
                        />

                        <hr/>

                        <div className="row-fluid">
                            <div className="span12 text-right btn-fix">
                                <Button icon="icon-remove"
                                        onClick={this.props.closeModal}
                                        className="btn-danger"
                                        disabled={this.props.submitting}>
                                    {this.props.context.msg.t('cancel')}
                                </Button>
                                {' '}
                                <Button icon="icon-save"
                                        type="submit"
                                        loading={this.props.submitting}>
                                    {this.props.context.msg.t('save')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </BlockUi>
            </Dialog>
        );
    }

}

export default ContextEnhancer(
    wrapForm({
            component: HeatMapConfDialog,
            formName: 'heatMapConfDialog',
            mapToState: (state) => ({...state.modals.current.params})
        }
    )
);