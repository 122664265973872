import 'components/ui/presentation/DropdownSlideType.css';

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import {MODALS} from "components/ui/redux/Actions";
import Popper from "components/bng/ui/BngPopper";
import BngClickOutsideOverlay from "components/bng/ui/BngClickOutsideOverlay";
import Utils from "components/Utils";
import SelectObjectDialogContainer from "components/ui/common/SelectObjectDialogContainer";
import Icon from "components/ui/common/Icon";

class DropdownSlideTypeCmp extends Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        items: PropTypes.array.isRequired,
    };

    static defaultProps = {
        onChange: _.noop,
        items: [],
    };

    state = {
        open: false
    };

    getObjectsParams = (items, param) => {
        let path = [];
        for (let x = 0; x < items.length; x++) {
            if (items[x].type === 'APPLICATION_OBJECT') {
                path.push(items[x][param] ?? '');
            }
        }
        return path;
    };

    updateObjects = async (selectedObjects) => {
        let objectSlides = [];
        for (let i = 0; i < selectedObjects.itemPath.length; i++) {
            const path = selectedObjects.itemPath[i];
            const title = selectedObjects.title[i];
            let slide = {
                slide: title,
                path: path,
                time: 60,
                type: 'APPLICATION_OBJECT',
                visualization: 'CHART',
            };
            objectSlides.push(slide);
        }

        // Remove objetos que não foram selecionados
        const items = this.props.items
            .filter(slide =>
                slide.type !== 'APPLICATION_OBJECT'
                || objectSlides.some(o => o.path === slide.path)
            );

        for (const objectSlide of objectSlides) {
            let dontExists = !items.some(i => i.path === objectSlide.path);
            if (dontExists) {
                if (Utils.Object.isAnalysis(objectSlide.path)) {
                    await this.setEnableView(objectSlide);
                }
                items.push(objectSlide);
            }
        }
        this.props.onChange(items);
    };

    setEnableView = async (item) => {
        const viewType = await Utils.Object.getViewType(item.path);
        item.enableViews = viewType;
        item.visualization = viewType.chart ? "CHART" : "TABLE";
    };

    openSelectObjectDialog = (event) => {
        this.props.dispatch(MODALS.open(SelectObjectDialogContainer, {
            onSelectPath: (path) => this.updateObjects(path),
            typeField: 'checkbox',
            object: this.getObjectsParams(this.props.items, 'path'),
            objectTitle: this.getObjectsParams(this.props.items, 'slide'),
            byProject: true
        }))
    };

    openOptions = (event) => {
        this.setState({
            popperRef: event.currentTarget,
            open: true,
        });
    };

    addSlideObjects = (event) => {
        this.openSelectObjectDialog();
        this.setState({open: false});
    };

    addSlide = (slide) => {
        slide.path = "";
        slide.time = 60;
        slide.visualization = "";

        const items = [...this.props.items];
        items.push(slide);

        this.props.onChange(items);
        this.setState({open: false})
    };

    addSlideLink = (event) => {
        let itemValues = {
            slide: 'Link',
            type: 'EXTERNAL_LINK',
        };
        this.addSlide(itemValues);
    };

    addSlidePage = (event) => {
        let itemValues = {
            slide: this.props.context.msg.t('page'),
            type: 'HTML_COMPONENT',
        };
        this.addSlide(itemValues);
    };

    render() {
        const container = j('.CreatePresentationDialog')[0];
        return (
            <div style={{position: 'relative'}}>
                <a>
                    <div className="button-menu-slide-types" onClick={this.openOptions}>
                        <Icon icon="add_circle" />
                        <label className="label-add-link">{this.props.context.msg.t('presentation.add.slide')}</label>
                    </div>

                    <Popper container={container} className="bng-dropdown-parent"
                            open={this.state.open}
                            anchorEl={this.state.popperRef}
                            modifiers={{
                                preventOverflow: {
                                    boundariesElement: container,
                                }
                            }}>
                        <BngClickOutsideOverlay onClick={() => this.setState({open: false})} container={container}/>
                        <ul className="bng-dropdown slide-type">
                            <li onClick={this.addSlideObjects} className="li-dropdown">
                                <Button icon="add_box"
                                        title={this.props.context.msg.t('add.objects')}
                                        className="btn-only-icon slide-type">
                                    {this.props.context.msg.t('add.objects')}
                                </Button>
                            </li>
                            <li onClick={this.addSlideLink} className="li-dropdown">
                                <Button icon="link"
                                        title={this.props.context.msg.t('presentation.add.link')}
                                        className="btn-only-icon slide-type">
                                    {this.props.context.msg.t('presentation.add.link')}
                                </Button>
                            </li>
                            <li onClick={this.addSlidePage} className="li-dropdown">
                                <Button icon="insert_drive_file"
                                        title={this.props.context.msg.t('add.page')}
                                        className="btn-only-icon slide-type">
                                    {this.props.context.msg.t('add.page')}
                                </Button>
                            </li>
                        </ul>
                    </Popper>
                </a>
            </div>
        );
    }

}

export const DropdownSlideType = ContextEnhancer(connect()(DropdownSlideTypeCmp));