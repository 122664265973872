import styles from './PageLayout.module.css';
import React from 'react';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { BngPanel, BngPanelHeader, BngPanelFooter } from 'components/bng/ui/BngPanel';
import useBimContext from 'components/hooks/useBimContext';
import BngSearch from 'components/bng/ui/BngSearch';

const PageLayout = ({
  className = '',
  titleComponent,
  pageTitle = '',
  filters = {},
  setFilters = _.noop,
  headerButtons,
  optionalButtons,
  showItemsCount = false,
  loading = false,
  fetchData = _.noop,
  hasSearch = false,
  hasRefresh = false,
  pageButton,
  embedded = false,
  open = true,
  setOpen = _.noop,
  onSearchChanged = (val) => {
    setFilters({ ...filters, searchTerm: val });
  },
  itemsCount = 0,
  children,
  showExpandButton = true,
  footerChild,
}) => {
  const { msg } = useBimContext();

  return (
    <div className={`PageLayout ${styles.pageWrapper} ${className} ${embedded ? styles.embedded : ''}`}>
      <BngPanel className={`${styles.pagePanel}`}>
        <BngPanelHeader className={`${styles.headerActions} flex-center-items ${embedded ? `widget-header` : ''}`}>
          {embedded && showExpandButton && (
            <BngIconButton
              icon={open ? 'expand_less' : 'expand_more'}
              onClick={() => setOpen(!open)}
              className="mr-1"
            />
          )}
          <div className="flex-center-items">
            {pageTitle && <h1 className={`${styles.pageTitle}`}>{pageTitle}</h1>}
            {titleComponent?.()}
          </div>
          <div className={`flex-center-items flex-grow-1 justify-content-flex-end ${open ? '' : styles.closed}`}>
            {optionalButtons && (
              <div className={`${styles.optionalButtonsWrapper} text-overflow-ellipsis`}>
                {showItemsCount && itemsCount > 0 && (
                  <div className={`${styles.itemsCounterContainer}`}>
                    <span>{msg.t('objects.selected', itemsCount)}</span>
                  </div>
                )}
                {optionalButtons?.({})}
              </div>
            )}

            {hasSearch && (
              <BngSearch value={filters.searchTerm} onChange={onSearchChanged} title={msg.t('search.object.name')} />
            )}

            {headerButtons?.({ filters, setFilters })}

            {hasRefresh && (
              <BngIconButton
                icon={'autorenew'}
                onClick={() => fetchData()}
                className={`${loading ? 'spin-element' : ''}`}
                disabled={loading}
                title={msg.t('refresh')}
              />
            )}
          </div>
        </BngPanelHeader>
        {children}
        {footerChild &&
          <BngPanelFooter>
            {footerChild()}
          </BngPanelFooter>
        }
      </BngPanel>
      {pageButton && <div className={`${styles.floatActionButton}`}>{pageButton({})}</div>}
    </div>
  );
};

export default PageLayout;
