import './DashboardIconForm.css';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContextEnhancer from 'components/ContextEnhancer';
import Button from 'components/ui/Button';
import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import Api from 'components/Api';
import { MODALS } from 'components/ui/redux/Actions';
import { ErrorMessage, Field, Formik } from 'formik';
import { BngField } from 'components/bng/form/BngField';
import { BngSelect } from 'components/bng/form/BngSelect';
import Icon from 'components/ui/common/Icon';
import SelectIconDialog from 'components/ui/common/SelectIconDialog';
import BngInputColor from 'components/bng/form/BngInputColor';
import IconText from 'components/ui/dashboard/components/IconText';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { ContentContainer } from 'components/bng/ui/ContentContainer';
import FilterBar from 'components/filter/FilterBar';
import FilterService from 'components/filter/FilterService';
import { BngForm } from 'components/bng/form/BngForm';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Utils from 'components/Utils.js';
import SelectObjectDialogContainer from 'components/ui/common/SelectObjectDialogContainer';
import FilterDialog from 'components/filter/FilterDialog';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import BngSearch from 'components/bng/ui/BngSearch';
import UiMsg from 'components/ui/UiMsg';
import { VALID_IMAGE_TYPES } from 'components/bng/pages/newAnalysis/paramTypes/KpiImage';
import UiBlocker from 'components/bng/ui/UiBlocker';
import useDashboardPageCtx, { processFilterAfterChange } from 'bng/pages/dashboard/useDashboardPageCtx';
import UUID from 'bng/utils/UUID';
import FormikListener from 'components/bng/form/formik/FormikListener';

const textLimit = 30;

const ActionTypes = {
  NONE: 'NONE',
  OPEN_LINK: 'OPEN_LINK',
  OPEN_OBJECT: 'OPEN_OBJECT',
  APPLY_FILTER: 'APPLY_FILTER',
  EXPORT_OBJECT: 'EXPORT_OBJECT',
};

const FONTS = [
  { value: 'font-family-roboto', label: 'Roboto' },
  { value: 'font-family-open-sans', label: 'Open Sans' },
  { value: 'font-family-heveltica-neue', label: 'Heveltica Neue' },
  { value: 'font-family-georgia', label: 'Georgia' },
  { value: 'font-family-palatino-linotype', label: 'Palatino Linotype' },
  { value: 'font-family-times-new-roman', label: 'Times New Roman' },
  { value: 'font-family-arial', label: 'Arial' },
  { value: 'font-family-arial-black', label: 'Arial Black' },
  { value: 'font-family-comic-sans', label: 'Comic Sans MS' },
  { value: 'font-family-impact', label: 'Impact' },
  { value: 'font-family-lucida-sans-unicode', label: 'Lucida Sans Unicode' },
  { value: 'font-family-tahoma', label: 'Tahoma' },
  { value: 'font-family-trebuchet', label: 'Trebuchet MS' },
  { value: 'font-family-verdana', label: 'Verdana' },
  { value: 'font-family-courier-new', label: 'Courier New' },
  { value: 'font-family-lucida-console', label: 'Lucida Console' },
];

const FONT_SIZE = [{ value: 0, label: 'Auto' }, ..._.range(8, 41).map((value) => ({ value, label: `${value}` }))];

const ExportTypes = {
  PDF: 'PDF',
  EXCEL: 'EXCEL',
  CSV: 'CSV',
};

const LabelFontStyle = [
  { value: 'normal', label: 'label.font.normal' },
  { value: 'italic', label: 'label.font.italic' },
  { value: 'bold', label: 'label.font.bold' },
  { value: 'underline', label: 'label.font.underline' },
];

const ExportTypesList = [
  { value: ExportTypes.PDF, label: ExportTypes.PDF, visible: () => true },
  { value: ExportTypes.EXCEL, label: ExportTypes.EXCEL, visible: (path) => Utils.Object.isAnalysis(path) },
  { value: ExportTypes.CSV, label: ExportTypes.CSV, visible: (path) => Utils.Object.isBigTable(path) },
];

const ActionTypesList = [
  { value: ActionTypes.NONE },
  { value: ActionTypes.OPEN_LINK },
  { value: ActionTypes.OPEN_OBJECT },
  { value: ActionTypes.APPLY_FILTER },
  { value: ActionTypes.EXPORT_OBJECT },
];

const Positions = ['TOP', 'RIGHT', 'BOTTOM', 'LEFT'];

const ACTIONS_WITH_FILTERS = [ActionTypes.APPLY_FILTER, ActionTypes.OPEN_OBJECT, ActionTypes.EXPORT_OBJECT];

const IconContentSchema = bngYup((yup) => {
  return yup.object({
    label: yup.string().max(30).trim().default(''),
    labelFont: yup.string().required().trim().default(FONTS[0].value),
    labelFontSize: yup.number().default(FONT_SIZE[0].value),
    labelFontStyle: yup.string().required().trim().default(LabelFontStyle[0].value),
    labelColor: yup.string().trim(),
    icon: yup.string().trim().default('sentiment_very_satisfied'),
    color: yup.string().trim(),
    position: yup.string().oneOf(Positions).default('BOTTOM'),
    action: yup.string().max(30).oneOf(Object.values(ActionTypes)).default(ActionTypes.NONE),
    actionProps: yup
      .object({
        applyFilters: yup.boolean().default(false),
        exportType: yup.string().oneOf(Object.values(ExportTypes)).default(ExportTypes.PDF),
        link: yup.string().url(),
        path: yup.string(),
        filters: yup.array(
          yup.object({
            id: yup.number(),
            members: yup.array(),
          })
        ),
        filterIds: yup.array(yup.number()),
      })
      .default({
        applyFilters: false,
        exportType: ExportTypes.PDF,
        link: '',
        path: '',
        filters: [],
        filterIds: [],
      }),
  });
});

class DashboardIconForm extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    findFilters: PropTypes.func,
    findStyle: PropTypes.func,
    item: PropTypes.object,
  };

  static defaultProps = {
    findFilters: () => useDashboardPageCtx.getState().dash?.gridData?.availableFilters,
    findStyle: () => useDashboardPageCtx.getState().dash?.dashboard?.style,
    item: null,
    availableFilters: [],
  };

  state = {
    loading: false,
    previewLoading: false,
    boundariesElement: document.getElementById('page-content'),
    filterMembers: [],
    searchTerm: '',
    initialFormValues: IconContentSchema.default(),
  };

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      let initialFormValues = IconContentSchema.default();
      Object.assign(initialFormValues, this.findDefaultColors());

      let filters = _.cloneDeep(this.props.findFilters());

      if (this.props.item) {
        const actionProps = initialFormValues.actionProps;
        initialFormValues = _.cloneDeep(_.merge({}, initialFormValues, this.props.item.additionalProps ?? {}));
        initialFormValues.actionProps = initialFormValues.actionProps || actionProps;
        initialFormValues.labelFontSize = `${initialFormValues.labelFontSize}`;
      } else {
        filters.forEach((f) => {
          initialFormValues.actionProps.filterIds.push(f.id);
        });
      }

      let filterIds = initialFormValues.actionProps?.filterIds ?? [];
      const persistedFilters = initialFormValues?.actionProps?.filters ?? [];
      // Se o filtro não estiver configurado, manda automaticamente para o filterIds
      for (const filter of filters) {
        if (filterIds.includes(filter.id) || persistedFilters.some((pf) => pf.id === filter.id)) {
          continue;
        }

        filterIds.push(filter.id);
      }

      if (this.props.item) {
        for (const persistedFilter of persistedFilters) {
          let filter = filters.find((f) => f.id === persistedFilter.id);

          if (filter) {
            filter.selectedMembers = persistedFilter.members.map((m) => ({
              value: m,
              label: m,
            }));
          } else {
            const members = persistedFilter.members.map((m) => ({
              value: m,
              label: m,
            }));
            filters = await this.filtersForUpdate(persistedFilter, members, filters, () => {
              filterIds = filterIds.filter((id) => persistedFilter.id !== id);
            });
          }
        }
      }

      // Zera membros do filters se o mesmo estiver no filterIds pois deve seguir o filtro do dash
      filters.forEach((f) => {
        if (filterIds.includes(f.id)) {
          f.selectedMembers = [];
        }
      });

      initialFormValues.actionProps.filters = FilterService.transform(filters);
      initialFormValues.actionProps.filterIds = filterIds;

      this.setState({
        initialFormValues,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  fetchFilters = async (currentFilters, currentObjectDatasource) => {
    this.setState({ loading: true });

    try {
      const globalFilters = await Api.MdxGlobalFilter.findAll(this.props.context.project.id, true);
      const filterMembers = globalFilters
        .filter((filter) => {
          return !currentFilters.some((stateFilter) => stateFilter.id === filter.id);
        })
        .map((filter) => {
          const isApplicable = !!filter.mdxFilters.find((mdxFilter) => {
            return currentObjectDatasource ? currentObjectDatasource === mdxFilter.datasource : true;
          });

          return {
            ...filter,
            label: filter.displayName,
            value: filter.id,
            type: filter.type,
            visible: true,
            selectedMembers: [],
            isApplicable: isApplicable,
          };
        });

      this.setState({ filterMembers });
    } catch (e) {
      console.error('Error on fetchFilters()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  };

  openFilterDialog = async (filterId, onSelect) => {
    try {
      const selectedFilter = this.state.filterMembers.find((filter) => filter.value === filterId);
      const isTimeFilter = selectedFilter.type === 'TIME';
      const filterMembers = await Api.Filter.findMembers(filterId);

      const filterDialogProps = {
        filter: selectedFilter,
        enableModeChange: isTimeFilter,
        onChange: async (data, force, additionalProps) => {
          await onSelect(selectedFilter, data);
          additionalProps.closeModal();
        },
        showModal: isTimeFilter,
        items: filterMembers.members,
        dynamicItems: isTimeFilter ? filterMembers.dynamicMembers : [],
        maxElements: 20,
        onClose: this.closeDialog,
        availableDateRange: filterMembers.availableDateRange,
        containRestriction: filterMembers.containRestriction,
        restrictionType: filterMembers.restrictionType,
        restrictionMembers: filterMembers.restrictionMembers,
        dataRestrictionMembers: filterMembers.dataRestrictionMembers,
        disabled: false,
      };

      window.ReduxStore.dispatch(MODALS.open(FilterDialog, filterDialogProps));
    } catch (e) {
      console.error('Error on openFilterDialog()', { filterId }, e);
      UiMsg.ajaxError(null, e);
    }
  };

  filtersForUpdate = async (filterInfo, members, currentFilters, onError = _.noop) => {
    try {
      const filterDetailed = await Api.MdxGlobalFilter.findOne(filterInfo.id);
      filterDetailed.caption = filterDetailed.displayName;
      filterDetailed.selectedMembers = members;
      filterDetailed.notFromDash = true;
      currentFilters = currentFilters.concat([filterDetailed]);
    } catch (e) {
      if (e.response?.status !== 404) {
        console.error('Error on filtersForUpdate()', { filterInfo, members, currentFilters }, e);
      }
      onError(e);
    }
    return currentFilters;
  };

  isDarkTheme = () => {
    const style = this.props.findStyle();
    const backgroundTheme = style?.backgroundTheme ?? '';
    return ['CORPORATIVE', 'BLACK'].includes(backgroundTheme);
  };

  findDefaultColors = () => {
    let color;
    let labelColor;
    if (this.isDarkTheme()) {
      color = '#FFFFFF';
      labelColor = '#FFFFFF';
    } else {
      color = '#333333';
      labelColor = '#333333';
    }

    return {
      color,
      labelColor,
    };
  };

  searchForPathInfo = (path, item) => {
    if (!item) {
      for (const folder of this.props.folders) {
        const result = this.searchForPathInfo(path, folder);
        if (result) {
          return result;
        }
      }
    }

    if (item) {
      if (item.leaf) {
        if ((item.path || item.href) === path) {
          return item;
        }
      } else {
        for (const child of item.children) {
          const result = this.searchForPathInfo(path, child);
          if (result) {
            return result;
          }
        }
      }
    }
  };

  transformFilterResult(filter) {
    return {
      id: filter.id,
      members: filter.selectedMembers.map((member) => member.value),
    };
  }

  save = async (values, actions) => {
    values = _.cloneDeep(values);

    try {
      switch (values.action) {
        case ActionTypes.OPEN_LINK: {
          if (_.isEmpty(_.get(values, 'actionProps.link'))) {
            actions.setFieldError('actionProps.link', this.props.context.msg.t('yup.mixed.required'));
            return;
          }
          break;
        }
        case ActionTypes.OPEN_OBJECT: {
          if (_.isEmpty(_.get(values, 'actionProps.path'))) {
            const msg = this.props.context.msg.t('select.object.to.open');
            actions.setFieldError('actionProps.path', msg);
            return;
          }
          break;
        }
        case ActionTypes.APPLY_FILTER: {
          if (_.isEmpty(_.get(values, 'actionProps.filters'))) {
            const msg = this.props.context.msg.t('select.at.least.one.filter');
            actions.setFieldError('actionProps.filters', msg);
            return;
          }
          break;
        }
        case ActionTypes.EXPORT_OBJECT: {
          if (_.isEmpty(_.get(values, 'actionProps.path'))) {
            const msg = this.props.context.msg.t('select.object.to.export');
            actions.setFieldError('actionProps.path', msg);
            return;
          }
          break;
        }
      }
      values.id = this.props.item?.id;
      if (values.actionProps.filterIds === null) {
        values.actionProps.filterIds = [];
      }

      values.actionProps.filters = values.actionProps.filters
        .filter((filter) => (values.action === ActionTypes.APPLY_FILTER ? !filter.notFromDash : true))
        .map((filter) => {
          return this.transformFilterResult(filter);
        });

      if (!values.id) {
        values.id = UUID.generate();
      }

      await useDashboardPageCtx.getState().addChange({
        type: 'ICON',
        data: values,
      });
      UiMsg.ok(this.props.context.msg.t('changes.applied.successfully'));
      this.props.closeModal();
    } catch (e) {
      console.error('Error on save()', e);
      UiMsg.ajaxError(null, e);
      actions.setSubmitting(false);
    }
  };

  handleSearch(value) {
    this.setState({ searchTerm: value });
  }

  render() {
    return (
      <Formik
        initialValues={this.state.initialFormValues}
        validationSchema={IconContentSchema}
        onSubmit={this.save}
        enableReinitialize
      >
        {({ values, setFieldValue, isSubmitting }) => {
          const selectedObject = values.actionProps?.path || '';
          const objectInfo =
            values.action === ActionTypes.OPEN_OBJECT || (values.action === ActionTypes.EXPORT_OBJECT && selectedObject)
              ? this.searchForPathInfo(selectedObject)
              : {};

          const darkBackground = this.isDarkTheme();

          const disableExportType =
            !Utils.Object.isAnalysis(values.actionProps.path) && !Utils.Object.isBigTable(values.actionProps.path);
          const isApplyFilterAction = values.action === ActionTypes.APPLY_FILTER;
          const showFilterSelection =
            isApplyFilterAction || (ACTIONS_WITH_FILTERS.includes(values.action) && values.actionProps.applyFilters);

          const formFilters = isApplyFilterAction
            ? values.actionProps.filters.filter((filter) => !filter.notFromDash)
            : values.actionProps.filters;
          const filterIds = values.actionProps.filterIds || [];

          return (
            <Dialog
              open={this.props.open}
              newDialogLayout={true}
              className="DashboardIconForm dash-icon-component"
              title={this.props.context.msg.t('icon.component.dialog.title')}
              onClose={this.props.closeModal}
              loading={this.state.loading || isSubmitting}
            >
              <BngForm>
                <FormikListener
                  onChange={(formik, prevFormik) => {
                    if (
                      !formik.dirty ||
                      (formik.values.action === prevFormik.values.action &&
                        formik.values.actionProps.applyFilters === prevFormik.values.actionProps.applyFilters)
                    ) {
                      return;
                    }

                    const applyFilters =
                      formik.values.actionProps.applyFilters || formik.values.action === ActionTypes.APPLY_FILTER;

                    const filters = applyFilters ? this.props.findFilters() : [];

                    setFieldValue(
                      'actionProps.filterIds',
                      filters.map((f) => f.id)
                    );

                    setFieldValue(
                      'actionProps.filters',
                      filters.map((f) => {
                        f = _.cloneDeep(f);
                        f.selectedMembers = [];
                        return f;
                      })
                    );
                  }}
                />

                <Dialog.Body>
                  <div className="row-fluid">
                    <div className="span8 Data">
                      <div>
                        <label>{this.props.context.msg.t('text')}</label>
                        <label className="textLength">
                          <span className="textLengthSpan">{`${values.label?.length ?? 0}/${textLimit}`}</span>
                        </label>
                        <Field name="label" withLabel={false} component={BngField} maxLength={textLimit} />
                      </div>

                      <div className="row-fluid">
                        <div className="span4">
                          <Field
                            name="labelFont"
                            label={this.props.context.msg.t('font')}
                            component={BngField}
                            inputComponent={BngSelect}
                            emptyOption={false}
                            options={FONTS}
                          />
                        </div>
                        <div className="span2">
                          <Field
                            name="labelFontSize"
                            label={this.props.context.msg.t('size')}
                            component={BngField}
                            inputComponent={BngSelect}
                            emptyOption={false}
                            options={FONT_SIZE}
                          />
                        </div>
                        <div className="span3">
                          <Field
                            name="labelFontStyle"
                            label={this.props.context.msg.t('style')}
                            component={BngField}
                            inputComponent={BngSelect}
                            emptyOption={false}
                            options={LabelFontStyle.map((option) => ({
                              value: option.value,
                              label: this.props.context.msg.t(option.label),
                            }))}
                          />
                        </div>
                        <div className="span3 text-center">
                          <Field
                            name="labelColor"
                            label={this.props.context.msg.t('label.color')}
                            component={BngField}
                            inputComponent={BngInputColor}
                            size="lg"
                          />
                        </div>
                      </div>

                      <div className="row-fluid">
                        <div className="span9">
                          <Field
                            name="position"
                            component={BngField}
                            inputComponent={BngSelect}
                            emptyOption={false}
                            options={Positions.map((option) => ({
                              value: option,
                              label: this.props.context.msg.t(option),
                            }))}
                          />
                        </div>
                        <div className="span3 text-center">
                          <Field
                            name="color"
                            label={this.props.context.msg.t('icon.color')}
                            component={BngField}
                            inputComponent={BngInputColor}
                            size="lg"
                          />
                        </div>
                      </div>

                      <div className="row-fluid">
                        <div className={values.action === ActionTypes.EXPORT_OBJECT ? 'span8' : 'span12'}>
                          <Field
                            name="action"
                            label={this.props.context.msg.t('on.click')}
                            component={BngField}
                            inputComponent={BngSelect}
                            emptyOption={false}
                            options={ActionTypesList.map((option) => ({
                              ...option,
                              label: this.props.context.msg.t(`IconContent.Action.${option.value}`),
                            }))}
                          />
                        </div>
                        <div className="span4">
                          {values.action === ActionTypes.EXPORT_OBJECT && (
                            <div className="export-analysis-type">
                              <Field
                                name="actionProps.exportType"
                                component={BngField}
                                disabled={disableExportType}
                                label={this.props.context.msg.t('export.type')}
                                inputComponent={BngSelect}
                                emptyOption={false}
                                options={ExportTypesList.filter((option) => option.visible(values.actionProps.path))}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {values.action === ActionTypes.OPEN_LINK && (
                        <div>
                          <Field
                            name="actionProps.link"
                            withLabel={false}
                            component={BngField}
                            placeholder="Link (Ex: https://google.com)"
                          />
                        </div>
                      )}

                      {(values.action === ActionTypes.OPEN_OBJECT || values.action === ActionTypes.EXPORT_OBJECT) && (
                        <React.Fragment>
                          <div className="open-object-container">
                            <Button
                              className={`bng-button ${selectedObject ? 'save' : 'btn-white'}`}
                              onClick={() =>
                                this.props.dispatch(
                                  MODALS.open(SelectObjectDialogContainer, {
                                    onSelectPath: (path) => {
                                      setFieldValue('actionProps.path', path.itemPath[0]);
                                      setFieldValue('actionProps.exportType', 'PDF');
                                    },
                                    object: selectedObject,
                                    required: true,
                                  })
                                )
                              }
                            >
                              {!selectedObject && <span>{this.props.context.msg.t('select.obj')}</span>}

                              {selectedObject && (
                                <span>
                                  {Icon.iconForPath(selectedObject)}{' '}
                                  {(objectInfo ? objectInfo.text : '') || Utils.Object.fileName(selectedObject)}
                                </span>
                              )}
                            </Button>

                            <div className="apply-filters-container">
                              <Field
                                name="actionProps.applyFilters"
                                component={BngField}
                                inputComponent={BngCheckbox}
                                asProps={{ label: this.props.context.msg.t('apply.filters.in.object.selected') }}
                                withLabel={false}
                              />
                              <i
                                className="fa fa-info-circle"
                                title={this.props.context.msg.t('apply.filters.in.object.description')}
                              />
                            </div>
                          </div>
                          <ErrorMessage name="actionProps.path" className="help-inline error" component="span" />
                        </React.Fragment>
                      )}
                    </div>
                    <div
                      className="span4 Preview"
                      style={{ pointerEvents: this.state.previewLoading ? 'none' : undefined }}
                    >
                      <label>{this.props.context.msg.t('preview')}</label>
                      <UiBlocker block={this.state.previewLoading}>
                        <ContentContainer className={`IconPreview ${darkBackground ? 'DarkBackground' : ''}`}>
                          <IconText
                            icon={values.icon}
                            color={values.color}
                            label={values.label}
                            position={values.position}
                            action={values.action}
                            actionProps={values.actionProps}
                            labelFont={values.labelFont}
                            labelFontSize={Number(values.labelFontSize)}
                            labelFontStyle={values.labelFontStyle}
                            labelColor={values.labelColor}
                            onClick={_.noop}
                            colorbackground={darkBackground ? '#333333' : undefined}
                          />
                        </ContentContainer>
                      </UiBlocker>

                      <div
                        className="ChangeIcon"
                        onClick={() =>
                          this.props.dispatch(
                            MODALS.open(SelectIconDialog, {
                              icon: { name: values.icon, type: Icon.MATERIAL },
                              onSelect: (icon) => setFieldValue('icon', icon.name),
                              canBeEmpty: true,
                            })
                          )
                        }
                      >
                        <div className="ChangeIconBg" />
                        <div className="ChangeIconText">{this.props.context.msg.t('change.icon')}</div>
                      </div>

                      <div className="ChangeIcon Image" onClick={() => this.$imageUploadRef.click()}>
                        <div className="ChangeIconBg" />
                        <div className="ChangeIconText">{this.props.context.msg.t('select.image')}</div>
                        <input
                          type="file"
                          hidden
                          ref={(ref) => (this.$imageUploadRef = ref)}
                          onChange={async (e) => {
                            try {
                              this.setState({
                                previewLoading: true,
                              });

                              const file = e.target.files[0];
                              if (!file) {
                                return;
                              }

                              if (!VALID_IMAGE_TYPES.includes(file.type)) {
                                UiMsg.warn(
                                  this.props.context.msg.t('attention'),
                                  this.props.context.msg.t('invalid.image.type')
                                );
                                return;
                              }

                              if (file.size > 2097152) {
                                UiMsg.warn(
                                  this.props.context.msg.t('attention'),
                                  this.props.context.msg.t('image.size.limit.exceded', ['2mb'])
                                );
                                return;
                              }

                              const [uploadResult] = await Api.Upload.upload(file, {
                                temp: true,
                              });

                              setFieldValue('icon', uploadResult.path);
                            } catch (e) {
                              console.error('Error while uploading file', e);
                              UiMsg.ajaxError(null, e);
                            } finally {
                              this.setState({
                                previewLoading: false,
                              });
                            }
                          }}
                          accept={VALID_IMAGE_TYPES.join(',')}
                        />
                      </div>
                    </div>
                  </div>
                  {showFilterSelection && (
                    <>
                      <ContentContainer className="Filters">
                        <div className="filterBarOptionsWrapper">
                          <div
                            className="filter-block FilterBarSlotButton"
                            title={this.props.context.msg.t('apply.dashboard.filters')}
                            onClick={(e) => {
                              const value = [];
                              this._filterBarInstance.wrappedComponent.updateFilters((filters) => {
                                filters.forEach((filter) => {
                                  if (!filter.notFromDash) {
                                    filter = _.cloneDeep(filter);
                                    filter.selectedMembers = [];
                                    value.push(filter);
                                  }
                                });
                              });
                              setFieldValue('actionProps.filters', value);
                              setFieldValue(
                                'actionProps.filterIds',
                                value.map((f) => f.id)
                              );
                            }}
                          >
                            <Icon icon="settings_backup_restore" />
                          </div>
                          {!isApplyFilterAction && (
                            <BngDropdown
                              icon="edit"
                              className="block"
                              popperClassName={`IconAddFilterDropdown`}
                              customButton={({ openDropdown }) => {
                                return (
                                  <div
                                    className="filter-block FilterBarSlotButton"
                                    title={this.props.context.msg.t('add.icon.filters')}
                                    onClick={async (e) => {
                                      openDropdown(e);
                                      await this.fetchFilters(formFilters, objectInfo?.datasource);
                                    }}
                                  >
                                    <Icon icon="add" />
                                  </div>
                                );
                              }}
                              customOptions={({ closeDropdown }) => {
                                const filteredMembers = this.state.filterMembers.filter((filterMember) => {
                                  const lowerSearch = this.state.searchTerm.toLowerCase();
                                  return (
                                    !this.state.searchTerm ||
                                    filterMember.displayName.toLowerCase().includes(lowerSearch)
                                  );
                                });

                                return (
                                  <div>
                                    <BngSearch
                                      alwaysOpen={true}
                                      onChange={(values) => this.handleSearch(values)}
                                      simple={true}
                                    />
                                    <ul className="filterDropdownList">
                                      {filteredMembers.map((filterMember) => {
                                        return (
                                          <li
                                            key={filterMember.id}
                                            className="filterDropdownItem"
                                            onClick={() =>
                                              this.openFilterDialog(filterMember.id, async (filterInfo, members) => {
                                                const newFilters = await this.filtersForUpdate(
                                                  filterInfo,
                                                  members,
                                                  formFilters
                                                );
                                                setFieldValue('actionProps.filters', newFilters);
                                                closeDropdown();
                                              })
                                            }
                                          >
                                            {filterMember.displayName || filterMember.name}
                                            {!filterMember.isApplicable && (
                                              <Icon
                                                title={this.props.context.msg.t('filter.without.object.relation')}
                                                icon="warning"
                                              />
                                            )}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                );
                              }}
                            />
                          )}
                        </div>
                        <FilterBar
                          ref={(ref) => (this._filterBarInstance = ref)}
                          alwaysShowFilterBar={true}
                          dashChanges={useDashboardPageCtx.getState().changes}
                          customFilterSwitch={{
                            enabled: (filter) => {
                              return (filter && !filter.notFromDash) ?? false;
                            },
                            disableOnCheck: true,
                            checked: (filter) => {
                              return filter && filterIds && filterIds.includes(filter.id);
                            },
                            label: this.props.context.msg.t('keep.dash.filter.member'),
                          }}
                          renderCustomMember={(filter) => {
                            return filterIds.includes(filter.id)
                              ? this.props.context.msg.t('dash.filter.member')
                              : null;
                          }}
                          filters={formFilters}
                          onChangeListener={(filters, force, appendAndReplace, changedFilters, additionalProps) => {
                            const [mainChangedFilter] = changedFilters;
                            if (!mainChangedFilter) {
                              return filters;
                            }

                            let newFilterIds = filterIds.slice();
                            // Changes that keep the dashboard filters dynamically are saved in actionProps.filterIds
                            if (additionalProps.customSwitchChecked || _.isEmpty(mainChangedFilter.selectedMembers)) {
                              if (!newFilterIds.includes(mainChangedFilter.id)) {
                                newFilterIds.push(mainChangedFilter.id);
                              }
                            } else {
                              newFilterIds = newFilterIds.filter((f) => f !== mainChangedFilter.id);

                              filters = filters.slice();
                              const matchedFilter = filters.find((f) => f.id === mainChangedFilter.id);
                              matchedFilter.selectedMembers = mainChangedFilter.selectedMembers.slice();
                              matchedFilter.restrictionType = 'SHOW_SELECTED';

                              const originalFilter = useDashboardPageCtx.getState().dash?.gridData?.filters?.[
                                mainChangedFilter.id
                              ];

                              processFilterAfterChange({
                                originalFilter,
                                forceClear: force,
                                findMembers: () => matchedFilter.selectedMembers.map((m) => m.value),
                                onUpdate: ({ members, restrictionType, runtimeRestriction }) => {
                                  if (members) {
                                    matchedFilter.selectedMembers = members.map((m) => ({
                                      label: m,
                                      value: m,
                                    }));
                                  }

                                  if (restrictionType) {
                                    matchedFilter.restrictionType = restrictionType;
                                  }

                                  if (runtimeRestriction) {
                                    matchedFilter.runtimeRestriction = runtimeRestriction;
                                  }
                                },
                              });

                              // Remove the filter from the bar if it's not from the dashboard
                              if (
                                additionalProps?.clearFilters &&
                                matchedFilter.notFromDash &&
                                _.isEmpty(matchedFilter.selectedMembers)
                              ) {
                                filters = filters.filter((filter) => filter.id !== matchedFilter.id);
                              }
                            }

                            for (const filter of filters) {
                              if (_.isEmpty(filter.selectedMembers) && !newFilterIds.includes(filter.id)) {
                                newFilterIds.push(filter.id);
                              }
                              if (newFilterIds.includes(filter.id)) {
                                filter.selectedMembers = [];
                              }
                            }

                            setFieldValue('actionProps.filters', filters);
                            setFieldValue('actionProps.filterIds', newFilterIds);

                            return filters;
                          }}
                          noDropdown={true}
                          type={'DASHBOARD_ICON'}
                        />
                      </ContentContainer>
                      <ErrorMessage name="actionProps.filters" className="help-inline error" component="span" />
                    </>
                  )}
                </Dialog.Body>

                <Dialog.Footer>
                  <DefaultDialogActions {...this.props} />
                </Dialog.Footer>
              </BngForm>
            </Dialog>
          );
        }}
      </Formik>
    );
  }
}

export default connect((state) => ({ folders: state.menu.roots }))(ContextEnhancer(DashboardIconForm));
