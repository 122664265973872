import './ReduxFormCheckbox.css';
import React from 'react';
import PropTypes from 'prop-types';

export default class ReduxFormCheckbox extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        style: {}
    };

    render() {
        return (
            <div className={`ReduxFormCheckbox checkbox ${this.props.className || ''}`}>
                <label>
                    <input type="checkbox" className="ace"
                           name={this.props.name}
                           disabled={this.props.disabled}
                           checked={this.props.value}
                           onChange={this.props.onChange}
                    />
                    <span className="lbl">{this.props.label}</span>
                </label>
            </div>
        );
    }

};