"use strict";

import {Axios} from "commonUtils";

const $ = jQuery;


class ProjectMergeApi {

    static runMerge = async ({projectFrom, projectTo, parentFolder, folder, origins = {}, cockpits = {}, connections = {}, importObjects = true, importData = true}) => {
        return await Axios.post('/spr/project-merge', {
            projectFrom,
            projectTo,
            parentFolder,
            folder,
            origins,
            cockpits,
            connections,
            importObjects,
            importData
        });
    };

    static checkForConflicts({projectFrom, projectTo, parentFolder, folder, importObjects = true, importData = true}) {
        return Promise.resolve(
            $.getJSON('/spr/project-merge/check-conflicts', {
                projectFrom,
                projectTo,
                parentFolder,
                folder,
                importObjects,
                importData
            })
        );
    }

}

export default ProjectMergeApi;
