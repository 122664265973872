import styles from 'components/ui/labs/trial/FeatureOption.module.css';

import React, { useState } from 'react';
import { Formik } from 'formik';

import useBimContext from 'components/hooks/useBimContext';
import { localStore } from 'components/Storage';
import bngYup from 'components/bng/form/yup/BngYup';
import BngAda, { ADA_TAGS } from 'components/bng/ui/BngAda/BngAda';
import AnswerPool from 'components/bng/ui/Census/AnswerPool';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';

export const FEATURE_CENSUS_STORAGE_KEY = 'FeatureCensus:AnswerLater';

const FeaturesSchema = bngYup((yup) => {
  return yup.object({
    features: yup
      .array(
        yup.object({
          score: yup.number().default(null).nullable(),
          message: yup.string().default(''),
        })
      )
      .default([]),
  });
});

export default function FeatureCensus({ features = [], closeModal }) {
  const context = useBimContext();
  const [score, setScore] = useState(null);

  const answerLaterButton = async () => {
    localStore.put(FEATURE_CENSUS_STORAGE_KEY, true);
    closeModal();
  };

  const submitFeedback = async (feat) => {
    try {
      const censusResponse = {
        result: { message: feat.message, score: feat.score },
        projectId: context.project.id,
        projectName: context.project.name,
        mobile: application.page.isMobile(),
        type: `LABS_${feat.value}`,
      };
      await Api.News.saveCensusResponse(censusResponse);
      await Api.Feature.updateFeedbackFeature(feat.value);
    } catch (e) {
      console.error(e);
      UiMsg.error(context.msg.t('census.error.sending.feedback'), e);
    }
  };

  const finishVoting = () => {
    localStore.put(FEATURE_CENSUS_STORAGE_KEY, true);
    UiMsg.ok(context.msg.t('census.thanks.for.feedback'));
    closeModal();
  }

  return (
    <Formik
      initialValues={{ ...FeaturesSchema.default(), features: features }}
      validationSchema={FeaturesSchema}
      onSubmit={_.noop}
    >
      {({ values, setFieldValue, submitForm }) => {
        const transformedFeatures = values.features.map((feat, idx) => {
          const scoreSelected = feat.score != null;

          if (scoreSelected) {
            return {
              value: feat.value,
              title: context.msg.t('census.nps.title.why'),
              leftButtonLabel: context.msg.t('no'),
              onClick: async ({ toNextStep }) => {
                await submitFeedback(feat);
                if (idx === values.features.length - 1) {
                  finishVoting();
                } else {
                  toNextStep();
                }
              },
              leftButtonClick: async () => {
                await submitFeedback(feat);
              },
            };
          } else {
            return {
              value: feat.value,
              title: context.msg.t('publiclabs.ada.feedback.title', [context.msg.t(feat.labelKey)]),
              leftButtonLabel: context.msg.t('census.answer.later'),
              onClick: ({ step }) => {
                const idx = values.features.findIndex((feat) => feat.value === step.value);
                setFieldValue(`features.[${idx}].score`, score);
                setScore(null);
              },
              leftButtonClick: async () => {
                await answerLaterButton();
              },
              buttonDisabled: score === null,
            };
          }
        });

        return (
          <BngAda
            className={`FeatureCensus ${styles.AdaFeatureCensus} ${styles.featuresAdaHelper}`}
            title={context.msg.t('feature.census.title', [values.features.length])}
            customOptionsComponent={(item) => {
              if (!item) return null;
              const idx = values.features.findIndex((feat) => feat.value === item.value);
              const scoreSelected = !!values.features[idx].score;

              return (
                <AnswerPool
                  score={score}
                  setScore={setScore}
                  showMessageField={scoreSelected}
                  onChange={(val) => setFieldValue(`features.[${idx}].message`, val)}
                  likeMsg={context.msg.t('publiclabs.feedback.like')}
                  dislikeMsg={context.msg.t('publiclabs.feedback.dislike')}
                  starCounter={true}
                  textValue={values.features[idx].message || ''}
                />
              );
            }}
            leftButtonLabel={context.msg.t('not.now')}
            onClickLeftButton={answerLaterButton}
            buttonLabel={context.msg.t('evaluate')}
            clickOutsiteToConfirm={false}
            tags={[ADA_TAGS.BIM_LABS, ADA_TAGS.BETA]}
            steps={transformedFeatures}
          />
        );
      }}
    </Formik>
  );
}
