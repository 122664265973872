import styles from './BngInputIcon.module.css';

import React, { useRef } from 'react';
import Icon from 'components/ui/common/Icon';
import BngInput from 'components/bng/form/BngInput';

export default function BngInputIcon({ className, icon, field, form, type, onClickIcon, inputProps, ...props }) {
  const $inputRef = useRef(null);

  const focusInput = () => {
    $inputRef?.current?.focus();
  };

  return (
    <div className={`BngInputIcon ${className} ${styles.inputIconContainer}`} {...props}>
      <BngInput className={`${styles.inputClassname}`} ref={$inputRef} field={field} form={form} type={type} {...inputProps}/>

      {icon && (
        <span className={`${styles.iconContainer}`} onClick={onClickIcon || focusInput}>
          <Icon icon={icon} className={`${styles.icon}`} />
        </span>
      )}
    </div>
  );
}
