import './ExportMenuButton.css';

import React, { useState } from 'react';

import Button from 'components/ui/Button';
import MenuButton from 'components/ui/navbar/MenuButton';
import BngEmpty from 'components/bng/ui/BngEmpty';
import SchedulingDropdown from 'components/bng/exportScheduling/SchedulingDropdown';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import { MODALS } from 'components/ui/redux/Actions';
import BngExportDialog from 'components/bng/exportScheduling/BngExportDialog';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

export default function ExportMenuButton({ badgeValue }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [keepMenuOpen, setKeepMenuOpen] = useState(false);
  const [forceMenuOpen, setForceMenuOpen] = useState(false);
  const [schedulings, setSchedulings] = useState([]);
  const [loading, setLoading] = useState(false);

  const findSchedulings = async () => {
    setLoading(true);
    try {
      const data = await Api.ExportScheduling.findAll(context.project.name, { pageSize: 5 });
      setSchedulings(data);
    } catch (e) {
      console.error('Error while trying to fetch schedulings', e);
    } finally {
      setLoading(false);
    }
  };

  const createScheduling = (event) => {
    try {
      dispatch(MODALS.open(BngExportDialog, { schedulingType: Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED }));
      closeMenu();
      MenuButton.closeAllMenus();
    } catch (e) {
      console.error('Error while trying to open ExportDialog', e);
    }
  };

  const onOpen = async () => {
    setKeepMenuOpen(true);
    return await findSchedulings();
  };

  const closeMenu = () => {
    if (!forceMenuOpen) {
      setKeepMenuOpen(false);
    } else {
      setForceMenuOpen(false);
    }
  };

  const renderList = () => {
    const container = document.querySelector('.AppPageWrapper');
    return (
      <React.Fragment>
        {schedulings.length === 0 && !loading ? (
          <BngEmpty
            isEmpty={true}
            message={context.msg.t('export.scheduling.emt.message')}
            className="SchedulingEmptyPage"
          />
        ) : (
          <React.Fragment>
            {schedulings.map((exportSched) => {
              return (
                <li key={exportSched.id} className={`${exportSched.scheduling.enabled ? 'enabled' : 'disabled'}`}>
                  <a href="#" className="sched-item">
                    <div className="row-fluid">
                      <div className="title-wrapper">
                        <div className="sched-title">{exportSched.scheduling.identifier}</div>
                        <div className="title-description">
                          {`${context.msg.t(exportSched.scheduling.type)}・${
                              exportSched.scheduling.enabled ? context.msg.t('enabled') : context.msg.t('disabled')
                          }`}
                        </div>
                      </div>
                      <SchedulingDropdown
                        exportScheduling={exportSched}
                        container={container}
                        className="marginDropdown"
                        forceMenuOpen={() => setForceMenuOpen(true)}
                        findSchedulings={findSchedulings}
                      />
                    </div>
                  </a>
                </li>
              );
            })}
          </React.Fragment>
        )}
        <li className="no-hover">
          <div className="menu-bottom-btn">
            <Button
              className="btn-link btn-small"
              onClick={async () => {
                try {
                  window.location.replace(`${Api.baseUrl()}/spr/bng/project/schedulings`);
                } catch (e) {
                  console.error('Error while loading project info', e);
                  UiMsg.ajaxError(null, e);
                }
              }}
            >
              {context.msg.t('see.all.schedulings')}
            </Button>
          </div>
        </li>
      </React.Fragment>
    );
  };
  return (
    <MenuButton
      className={'ExportMenuButton'}
      icon="schedule_send"
      badge={{ value: badgeValue }}
      onOpen={onOpen}
      title={context.msg.t('schedulings.title')}
      headerText={context.msg.t('schedulings')}
      buttonActionEnable={true}
      buttonActionLabel={context.msg.t('create')}
      buttonActionClick={createScheduling}
      keepMenuOpen={keepMenuOpen}
      closeMenu={closeMenu}
    >
      {renderList()}
    </MenuButton>
  );
}
