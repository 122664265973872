import * as React from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import {Tab, TabSet} from "components/ui/TabSet";
import {Field, Validations} from "components/ui/FormUtils";
import ReduxFormCheckbox from "components/ui/ReduxFormCheckbox";
import {Format} from "components/ui/analysis/expert/format/Format";
import Free from 'components/ui/analysis/expert/Free';
import ReduxFormSelectRadio from "components/ui/ReduxFormSelectRadio";

class Constant extends React.Component {

    static propTypes = {
        formatStrings: PropTypes.array
    };

    static defaultProps = {};

    state = {
        hiers: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const f = this.props.formula;
        if (f) {
            this.props.initialize({
                id: f.id,
                name: f.name,
                order: f.order,
                ...f.data,
                format: Free.formatMapping(f.data.format)
            })
        }
        this.props.change('format.custom', false);
        this.props.change('format.expression', 'Percent');
    }

    componentDidUpdate({formValues: {dimension, hierarchy}}) {
        const dim = this.props.cubeMeta.dimensions.find(({dimension: {name}}) => name === this.props.formValues.dimension);
        if (dimension !== this.props.formValues.dimension) {
            const hiers = dim ? dim.hierarchies : [];
            this.setState({hiers});
            this.props.change('hierarchy', hiers.length !== 0 ? hiers[0].name : '');
        }

        if (hierarchy !== this.props.formValues.hierarchy && dim) {
            const hier = dim.hierarchies.find(({name}) => name === this.props.formValues.hierarchy);
            this.props.change('hasAll', hier ? hier.hasAll : false);
            this.props.change('defaultMember', hier ? hier.defaultMember : '');
        }
    }


    render() {
        if (!this.props.cubeMeta) return null;
        const emptyOptions = <option value={''}>{this.props.context.msg.t('select.one')}</option>;
        return (

            <TabSet vertical
                    className="ShareFormula"
                    beforeChange={async () => {
                        const result = this.props.handleSubmit(_.noop)();
                        if (_.isUndefined(result)) {
                            return true;
                        } else if ('dimension' in result) {
                            return false;
                        } else if ('hierarchy' in result) {
                            return false;
                        } else if ('measure' in result) {
                            return false;
                        }
                        return true;
                    }}>
                <Tab label={this.props.context.msg.t('formula')}
                     icon="fa fa-cogs">

                    <div className="row-fluid">
                        <div className="span12">
                            <Field
                                name="type"
                                componentClass={ReduxFormSelectRadio}
                                options={['DEFAULT', 'ACCUMULATED'].map(value => ({value, label: this.props.context.msg.t(value)}))}
                                horizontal
                            />
                        </div>
                    </div>

                    <div className="row-fluid">
                        <div className="span6">
                            <Field
                                name="dimension"
                                componentClass="select"
                                label={this.props.context.msg.t('dimension')}
                                className="fill-w"
                                validate={Validations.required}
                            >
                                {emptyOptions}
                                {this.props.cubeMeta.dimensions.map(({dimension: {name, caption}}, idx) => {
                                    return (
                                        <option key={idx} value={name}>{caption || name}</option>
                                    )
                                })}
                            </Field>
                        </div>
                        <div className="span6">
                            <Field
                                name="hierarchy"
                                componentClass="select"
                                label={this.props.context.msg.t('hierarchy')}
                                className="fill-w"
                                disabled={!this.props.formValues.dimension}
                                validate={Validations.required}
                            >
                                {emptyOptions}
                                {this.state.hiers.map(({name, caption}, idx) => {
                                    return (
                                        <option key={idx} value={name}>{caption || name}</option>
                                    );
                                })}
                            </Field>
                        </div>
                    </div>
                    <div className="row-fluid">
                        <div className="span12">
                            <Field
                                name="measure"
                                componentClass="select"
                                label={this.props.context.msg.t('measure')}
                                className="fill-w"
                                validate={Validations.required}>
                                {emptyOptions}
                                {this.props.cubeMeta.allMeasures.map(({name, caption}, idx) => {
                                    return (
                                        <option key={idx} value={name}>{caption || name}</option>
                                    );
                                })}
                            </Field>
                        </div>
                    </div>
                    <div className="row-fluid">
                        <div className="span12">
                            <Field
                                name="parentBased"
                                componentClass={ReduxFormCheckbox}
                                label={this.props.context.msg.t('parent_based')}
                                groupClass="inline-checkbox"
                            />
                        </div>
                    </div>

                </Tab>
                <Tab label={this.props.context.msg.t('format.string')}
                     icon="fa fa-adjust">
                    <Format array={this.props.array}
                            change={this.props.change}
                            dispatch={this.props.dispatch}
                            context={this.props.context}
                            formatStrings={this.props.formatStrings}
                            formValues={this.props.formValues}
                            disableValueFormat={true}
                    />
                </Tab>
            </TabSet>
        );
    }

}


export default ContextEnhancer(Constant);