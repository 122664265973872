import React, { useEffect, useRef, useState } from 'react';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { Field, Formik } from 'formik';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { BngForm } from 'components/bng/form/BngForm';
import { BngField } from 'components/bng/form/BngField';

const SCHEMA = bngYup((yup) => {
  return yup.object({
    name: yup.string().min(2).max(64).default(''),
    key: yup.string().length(36).default(''),
  });
});

const INITIAL_VALUES = Object.freeze(SCHEMA.default());

export const SAVE_EVENT = 'ProjectExtractorSaved';

export default function ProjectExtractorFormDialog({ className = '', closeModal, id }) {
  const context = useBimContext();
  const { msg } = context;
  const [loading, setLoading] = useState(false);
  const $formikRef = useRef();

  const fetchData = async () => {
    setLoading(true);
    try {
      let data;
      if (id) {
        data = await Api.ProjectExtractor.findById(id);
      } else {
        data = { ...INITIAL_VALUES };
        data.key = await Api.ProjectExtractor.generateKey();
      }
      $formikRef.current.resetForm({ values: data });
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const save = async (values, actions) => {
    try {
      if (!values.projectId) {
        values = {
          ...values,
          projectId: context.project.id,
        };
      }

      const projectExtractor = await Api.ProjectExtractor.save(values);
      UiMsg.ok(msg.t('save_success', [msg.t('extractor')]));
      bimEventBus.emit(SAVE_EVENT, { projectExtractor });
      closeModal();
    } catch (e) {
      console.error('Error while saving ProjectExtractor', { values }, e);
      UiMsg.ajaxError(null, e);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={save} validationSchema={SCHEMA} innerRef={$formikRef}>
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Dialog
            className={`ProjectExtractorFormDialog ${className}`}
            loading={loading || isSubmitting}
            onClose={closeModal}
            title={`${msg.t(id ? 'editing' : 'notifications_new')} ${msg.t('extractor')}`}
          >
            <BngForm>
              <Dialog.Body>
                <Field component={BngField} name="name" maxLength={64} />
                {/*<div className="flex-center-items gap-2">
                  <Field component={BngField} name="key" rootClassName="flex-grow-1" readOnly maxLength={36} />
                  <BngIconButton
                    icon="restart_alt"
                    title={msg.t('generate.key')}
                    onClick={async () => {
                      setLoading(true);
                      try {
                        const key = await Api.ProjectExtractor.generateKey();
                        setFieldValue('key', key);
                      } catch (e) {
                        console.error('Error while trying to generate key', e);
                        UiMsg.ajaxError(null, e);
                      } finally {
                        setLoading(false);
                      }
                    }}
                  />
                </div>*/}
              </Dialog.Body>
              <Dialog.Footer>
                <hr />
                <DefaultDialogActions context={context} closeModal={closeModal} />
              </Dialog.Footer>
            </BngForm>
          </Dialog>
        );
      }}
    </Formik>
  );
}
