import './EventList.css';

import React, { useEffect, useState } from 'react';

import Api from 'components/Api';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngPanel, BngPanelHeader } from 'components/bng/ui/BngPanel';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import useBimContext from 'components/hooks/useBimContext';

export default function EventList() {
  const { msg } = useBimContext();
  const [config, setConfig] = useState();

  const reloadEvents = async () => {
    const result = await Api.Events.reloadEvents();
    setConfig(result.events);
  };

  const findConfig = async () => {
    const result = await Api.Events.findConfig();
    setConfig(result.events);
  };

  useEffect(() => {
    findConfig();
  }, []);

  const renderProp = (row, index, prop) => {
    const value = row[prop];
    if (typeof value === 'boolean') {
      return <span>{value ? msg.t('active') : msg.t('inactive')}</span>;
    }
    return <span className={`EventData-${index}-${prop}`}>{value}</span>;
  };

  const renderLanguageProp = (row, index, prop) => {
    const value = row[prop];
    return <span className={`EventData-${index}-${prop}`}>{msg.t(value)}</span>;
  };

  const renderDayOfTheWeekProp = (row, index, prop) => {
    const value = row[prop];
    return <span className={`EventData-${index}-${prop}`}>{msg.t(value.toLowerCase())}</span>;
  };

  const eventColumns = [
    {
      label: msg.t('preferred_language'),
      render: (row, index) => renderLanguageProp(row, index, 'language'),
    },
    { label: msg.t('display_name'), render: (row, index) => renderProp(row, index, 'name') },
    { label: msg.t('message'), render: (row, index) => renderProp(row, index, 'message') },
    { label: msg.t('link'), render: (row, index) => renderProp(row, index, 'link') },
    { label: msg.t('start.date'), render: (row, index) => renderProp(row, index, 'startTime') },
    { label: msg.t('end.date'), render: (row, index) => renderProp(row, index, 'endTime') },
    {
      label: msg.t('schema.time.dayOfWeek'),
      render: (row, index) => renderDayOfTheWeekProp(row, index, 'dayOfWeek'),
    },
    { label: msg.t('enabled'), render: (row, index) => renderProp(row, index, 'enabled') },
  ];

  return (
    <div>
      {config && (
        <BngPanel className={`eventlist-bng-panel`}>
          <BngPanelHeader className="eventlist-bng-panel-header">
            <div>
              <h1 className="eventlist-title-text">{msg.t('event')}</h1>
            </div>
            <div className="flex-center-items flex-grow-1 justify-content-flex-end">
              <div className={`headerTipWrapper`}>
                <span className={`headerTip`}>{msg.t('events.bimnews.tip')}</span>
              </div>
              <div>
                <BngIconButton icon={'autorenew'} onClick={reloadEvents} title={msg.t('refresh')} />
              </div>
            </div>
          </BngPanelHeader>
          <BngTable className={'eventlist-bng-table'} rows={config} cols={eventColumns} stickyHeader />
        </BngPanel>
      )}
    </div>
  );
}
