export default class KpiValueFormatter {
  static summarizeValues(value) {
    if (value > 0 && value < 1000) {
      return { pow: 0, value };
    }

    if (value > -1000 && value < 0) {
      return { pow: 0, value };
    }

    let pow = 0;
    let valAux = value;

    if (!isFinite(value) || isNaN(value)) {
      return { pow, value: 0 };
    }

    if (value > 0) {
      while (valAux >= 1000) {
        valAux = valAux / 1000;
        pow++;
      }
      return { pow, value: valAux };
    } else {
      while (valAux <= -1000) {
        valAux = valAux / 1000;
        pow++;
      }
      return { pow, value: valAux };
    }
  }

  static formatValueLocale(locale, value, fractionDigits) {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value);
  }

  static formatValue({
    context,
    locale,
    value,
    summarizeValues,
    percent,
    useDecimals,
    decimalCases,
    indicatorPercentage,
    absoluteValue,
    currencyFormat,
  }) {
    let formattedLocale = locale;
    if (locale?.includes("_")) {
      formattedLocale = locale.replace("_", "-")
    }
    let fractionDigits =
      !summarizeValues && useDecimals ? decimalCases : Number.isInteger(value) || indicatorPercentage ? 0 : 2;
    let pow = 0;
    let formattedValue = value;

    const percentFormattedValue = (value) => {
      if (percent) return value * 100;
      return value;
    };

    if (summarizeValues) {
      fractionDigits = 0;

      if (indicatorPercentage && useDecimals) {
        fractionDigits = decimalCases;
      }

      const summarize = KpiValueFormatter.summarizeValues(value);
      pow = summarize.pow;
      formattedValue = summarize.value;

      if (!indicatorPercentage && (value >= 1000 || value <= -1000)) {
        let stringVal = KpiValueFormatter.formatValueLocale(formattedLocale, percentFormattedValue(formattedValue), 2);
        formattedValue = stringVal.substring(0, stringVal.length - 1);
      } else {
        formattedValue = KpiValueFormatter.formatValueLocale(
          formattedLocale,
          percentFormattedValue(formattedValue),
          fractionDigits
        );
      }
    } else {
      if (formattedValue - parseInt(percentFormattedValue(formattedValue)) > 0.98 && !useDecimals) {
        fractionDigits = 0;
      }

      formattedValue = KpiValueFormatter.formatValueLocale(
        formattedLocale,
        percentFormattedValue(formattedValue),
        fractionDigits
      );
    }

    let suffix = '';

    if (percent) {
      suffix = '%';
    } else {
      suffix = KpiValueFormatter.suffix({ context, pow });
    }

    const wrapInParentheses = absoluteValue && formattedValue.includes('-');

    if (wrapInParentheses) {
      formattedValue = formattedValue.replace('-', '');
    }

    formattedValue = formattedValue + (!!suffix ? '' + suffix + '' : suffix);

    if (wrapInParentheses) {
      formattedValue = `(${formattedValue})`;
    }

    if (!_.isNil(currencyFormat)
      && currencyFormat.length === 0
      && !summarizeValues
      && !percent) {
      return Intl.NumberFormat(formattedLocale, { maximumFractionDigits: 0 }).format(value);
    }

    return formattedValue;
  }

  static suffix({ context, pow }) {
    let suffix;
    switch (pow) {
      case 1:
        suffix = ' ' + context.msg.t('thousand.abbr');
        break;
      case 2:
        suffix = ' ' + context.msg.t('million.abbr');
        break;
      case 3:
        suffix = ' ' + context.msg.t('billion.abbr');
        break;
      case 4:
        suffix = ' ' + context.msg.t('trillion.abbr');
        break;
      default:
        suffix = '';
    }
    return suffix;
  }
}