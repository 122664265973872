import {connect} from 'react-redux';
import SelectObjectDialog from './SelectObjectDialog';

export default connect(
    state => {
        return {
            folders: state.menu.roots,
        }
    }
)(SelectObjectDialog);
