import { useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';

import BngAda from 'components/bng/ui/BngAda/BngAda';
import BngVideoModal from 'components/bng/ui/BngVideoModal';
import useBimContext from 'components/hooks/useBimContext';

const BngAddonActivatedHelp = ({
  helpVideoUrl,
  closeAda = _.noop,
  videoTitle,
  showHelpVideo = true,
  container,
  addonName = '',
  knowledgeBaseLink = '',
}) => {
  const context = useBimContext();
  const [showOptions, setShowOptions] = useState(!showHelpVideo);
  const [showModal, setShowModal] = useState(true);

  const portalContainer = useMemo(() => {
    return container ?? document.getElementById('page-content');
  }, [container]);

  const adaOpts = [
    {
      icon: 'help_outline',
      label: context.msg.t('new.analysis.help.documentation'),
      onClick: () => {
        window.open(knowledgeBaseLink, '_blank');
        closeAda();
      },
    },
    {
      icon: 'close',
      label: context.msg.t('close'),
      onClick: () => closeAda(),
    },
  ];

  if (showHelpVideo) {
    adaOpts.unshift({
      icon: 'play_circle_outline',
      label: context.msg.t('watch.tutorial'),
      onClick: () => {
        setShowModal(true);
        setShowOptions(false);
      },
    });
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      <BngAda
        className={`AddonActivatedHelp`}
        title={showOptions && context.msg.t('addon.ada.title', addonName)}
        description={showOptions && context.msg.t('addon.ada.desc')}
        options={adaOpts}
        showInfo={showOptions}
      />
      {showHelpVideo && showModal && (
        <BngVideoModal
          videoUrl={helpVideoUrl}
          title={videoTitle}
          onClose={() => {
            setShowModal(false);
            setShowOptions(true);
          }}
        />
      )}
    </React.Fragment>,
    portalContainer
  );
};

export default BngAddonActivatedHelp;
