import styles from './Pizza.module.css';
import React, { useEffect, useRef } from 'react';

const barColor = (percent) => {
  if (percent >= 0 && percent <= 50) return '#269C59';
  if (percent > 50 && percent <= 75) return '#FAA133';
  else return 'red';
};

export default function Pizza({ percent = 0, title = '', size = 65, className = '', children }) {
  const $container = useRef();

  useEffect(() => {
    try {
      $container.current.querySelectorAll('canvas').forEach((node) => node.remove());
      new EasyPieChart($container.current, {
        barColor: barColor(percent),
        trackColor: '#E2E2E2',
        scaleColor: false,
        lineCap: 'round',
        lineWidth: parseInt(size / 9),
        size: size,
      });
    } catch (e) {
      console.error('Error while creating EasyPieChart', e);
    }
  }, [percent, size]);

  return (
    <div className={`Pizza ${styles.Wrapper} ${className}`} title={title}>
      <div
        ref={$container}
        className="easy-pie-chart percentage easyPieChart easy-pie-chart force-inline-block"
        data-percent={percent}
        data-size={`${size}`}
      >
        <span className="percent" style={{ height: size, width: size, fontSize: size / 4.5, color: barColor(percent) }}>
          {percent}%
        </span>
      </div>
      {children}
    </div>
  );
}
