import styles from './AddonNavButton.module.css';

import React, { useEffect, useRef, useState } from 'react';

import useBimContext from 'components/hooks/useBimContext';
import MenuButton from 'components/ui/navbar/MenuButton';
import Api from 'components/Api';
import Icon from 'components/ui/common/Icon';
import Button from 'components/ui/Button';

const AddonNavButton = () => {
  const context = useBimContext();
  const [showAlert, setShowAlert] = useState(false);
  const [addon, setAddon] = useState(null);
  const $buttonRef = useRef();

  const checkAlert = async () => {
    const data = await Api.Account.findAddonWarning();
    const validations = (addonName) => {
      return addonName === 'WHATS_SUPPORT';
    };

    if (data) {
      if (validations(data?.addon?.id)) {
        setAddon(data);
        setShowAlert(true);
      }
    }
  };

  const openAddonsPage = async () => {
    window.location.replace(`${Api.baseUrl()}/pages/addon/addons-page.iface?breadcrumb=true`);
  };

  const closeAlert = async () => {
    await Api.UserParam.saveUserKey({
      key: Api.UserParam.Keys.Addons.AddonAlert,
      value: 'true',
      projectId: context.project.id,
    });
    setShowAlert(false);
    await checkAlert();
  };

  useEffect(() => {
    checkAlert();
  }, []);

  const AddonAlert = ({ title = '', desc = '' }) => {
    return (
      <>
        <div className={`${styles.addonAlertOverlay}`}></div>
        <div
          className={`${styles.alertPopup} AddonNavButtonPopup`}
          style={{ top: buttonPosition.top + 50, left: buttonPosition.left - 435 }}
        >
          <div className={`${styles.alertArrow}`}></div>
          <div className={`${styles.alertTitle} AddonNavButtonPopupTitle`}>
            <Icon icon={'extension'} />
            <h5>{title}</h5>
          </div>
          <p className={`${styles.alertDescription} AddonNavButtonPopupDescription`}>{desc}</p>
          <div className={`${styles.buttonsContainer}`}>
            <Button
              className={`${styles.seeDetailsButton} AddonNavButtonPopupDetailsButton`}
              onClick={async () => {
                await closeAlert();
                await openAddonsPage();
              }}
            >
              {context.msg.t('see.details')}
            </Button>
            <Button className={`${styles.gotItButton} AddonNavButtonPopupGotItButton`} onClick={closeAlert}>
              {context.msg.t('got.it')}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const alert = showAlert ? (
    addon?.newUser ? (
      <AddonAlert title={context.msg.t('addon.alert.title')} desc={context.msg.t('addon.alert.desc')} />
    ) : (
      <AddonAlert
        title={context.msg.t('addon.alert.new.title', [context.msg.t(addon.addon.nameKey)])}
        desc={context.msg.t(addon.addon.descKey)}
      />
    )
  ) : null;

  const buttonPosition = $buttonRef.current ? $buttonRef.current.getBoundingClientRect() : {};

  return (
    <>
      <MenuButton
        className="AddonNavButton"
        icon="extension"
        title={context.msg.t('addons')}
        onOpen={async () => {
          await openAddonsPage();
          return false;
        }}
        buttonRef={$buttonRef}
      />
      {showAlert && alert}
    </>
  );
};

export default AddonNavButton;
