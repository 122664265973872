import styles from './LoginTfaForm.module.css';

import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useTranslation from 'components/hooks/useTranslation';
import { BngCancelButton } from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import { TfaReason } from 'bng/pages/login/LoginTfaUnlockForm';

export default function LoginTfaForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const state = useMemo(() => _.cloneDeep(locationState), []);

  const goToLoginPage = () => {
    navigate('/login', {
      replace: true,
    });
  };

  useEffect(() => {
    if (!state?.credentials) {
      goToLoginPage();
    }
  }, []);

  const containPhone = !!state?.obfuscatedPhone;
  const opts = useMemo(
    () => [
      { type: 'EMAIL', icon: 'mail_outline', label: t('receive.code.via.email') },
      {
        type: 'SMS',
        icon: 'chat_bubble_outline',
        label: containPhone ? `${t('receive.code.via.sms')} ${state?.obfuscatedPhone}` : t('no.registered.phone'),
        disabled: !containPhone,
      },
      {
        type: 'WHATS',
        icon: 'fa-whatsapp',
        className: styles.whatsappOpt,
        label: containPhone ? `${t('receive.code.via.whats')} ${state?.obfuscatedPhone}` : t('no.registered.phone'),
        disabled: !containPhone,
      },
      {
        type: 'SUPPORT',
        icon: 'help_outline',
        label: t('recover.account.help'),
        onClick: () => {
          window.openSuportChat(state.credentials.email, state.userDisplayName, '', state.supportChat, '');
        },
      },
    ],
    []
  );

  return (
    <div className={`LoginTfaForm ${styles.main}`}>
      {state.lastValidAccess && <TfaReason {...state} />}
      <hr />
      <h1>{t('login_two_factor_auth')}</h1>
      <p
        className="mt-2"
        dangerouslySetInnerHTML={{
          __html: t('tfa.type.selection.msg'),
        }}
      />

      <div className="mt-1">
        {opts.map((opt) => (
          <div
            key={opt.type}
            className={`flex-center-items gap-2 ${styles.tfaOpt} ${opt.disabled ? 'disabled' : ''} ${
              opt.className ?? ''
            }`}
            onClick={
              opt.disabled
                ? undefined
                : opt.onClick ??
                  (() => {
                    state.credentials.type = opt.type;
                    navigate('/login/tfa/unlock', {
                      state,
                    });
                  })
            }
          >
            <Icon icon={opt.icon} />
            {opt.label}
          </div>
        ))}
      </div>
      <div className="flex-center-items jc-center mt-4">
        <BngCancelButton onClick={goToLoginPage} className={`${styles.backToLogin} fw-500`}>
          {t('login_back')}
        </BngCancelButton>
      </div>
    </div>
  );
}
