import 'components/ui/presentation/MyPresentationList.css'

import React, {Component} from "react";
import PropTypes from "prop-types";
import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import DropdownTablePresentation from "components/ui/presentation/DropdownTablePresentation";
import UiMsg from "components/ui/UiMsg";
import Icon from 'components/ui/common/Icon';
import {BngTable} from "components/bng/ui/BngTable";
import Utils from 'components/Utils';

class MyPresentationList extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        setLoading: PropTypes.func,
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true),
        setLoading: _.noop,
    };

    state = {
        presentations: [],
    };

    tableColumns = [
        {
            key: 'name',
            label: this.props.context.msg.t('name'),
            sortable: false,
            render: (presentation) => {
                const isSharedPresentation = presentation.ownerId !== this.props.context.user.id;
                return (
                  <div className='div-information-presentation'>
                      <span title={presentation.name}>{presentation.name}</span>
                      {isSharedPresentation &&
                        <Icon title={this.props.context.msg.t('shared.by.user', presentation.owner)} icon='group' />
                      }
                  </div>
                );
            }
        },
        {
            key: 'owner',
            label: this.props.context.msg.t('owner'),
            sortable: false,
            render: (presentation) => {
                return <div title={presentation.owner}
                            className='div-information-presentation'>{presentation.owner}</div>;
            }
        },
        {
            key: 'createdAt',
            label: this.props.context.msg.t('creation.date'),
            sortable: false,
            render: (presentation) => {
                const date = Utils.Date.formatDateTime(presentation.createdAt);
                return <div className='div-information-presentation'>{date}</div>;
            }
        },
        {
            key: 'updatedAt',
            label: this.props.context.msg.t('change.date'),
            sortable: false,
            render: (presentation) => {
                const updateDate = Utils.Date.formatDateTime(presentation.updatedAt);
                return <div className='div-information-presentation'>{updateDate}</div>;
            }
        },
        {
            key: 'actions',
            label: '',
            sortable: false,
            render: (presentation) => {
                return <DropdownTablePresentation presentation={presentation}
                                                  refreshPresentations={this.props.findPresentations} />;
            }
        }
    ];

    componentDidMount() {
        this.props.findPresentations()
    }

    filterPresentations(presentations = []) {
        return presentations.filter(presentation => {
            if (this.props.filters.account && presentation.accountId !== this.props.filters.account) {
                return false;
            }

            if (this.props.filters.shared) {
                if (this.props.filters.shared === 1 && presentation.ownerId === this.props.context.user.id) {
                    return false;
                } else if (this.props.filters.shared === 2 && presentation.ownerId !== this.props.context.user.id) {
                    return false;
                }
            }

            if (this.props.filters.nameOwnerFilter) {
                const search = this.props.filters.nameOwnerFilter.toLowerCase();
                if (!presentation.name.toLowerCase().includes(search) &&
                  !presentation.owner.toLowerCase().includes(search)) {
                    return false;
                }
            }

            return true;
        });
    }

    render() {
        const filteredPresentations = this.filterPresentations(this.props.presentations);
        return (
            <BngTable
                rows={filteredPresentations}
                cols={this.tableColumns}
                showEmptyAlert={true}
                emptyAlertProps={{
                    message: this.props.context.msg.t('currently.no.structure.available'),
                }}
                stickyHeader
            />
        );
    }
}

export default ContextEnhancer(MyPresentationList);
