import './BngDropdownCheckbox.css'
import React, {useEffect, useState} from 'react';

import {BngDropdown} from "components/bng/ui/BngDropdown";
import {BngCheckboxPure} from "components/bng/form/BngCheckbox";
import BngSearch from "components/bng/ui/BngSearch";
import ContextEnhancer from "components/ContextEnhancer";
import BngLazyListView from "components/bng/ui/BngLazyListView";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import Button from "components/ui/Button";
import Icon from "components/ui/common/Icon";


const BngDropdownCheckbox = ({
                                 items = [],
                                 customButton = null,
                                 context = {},
                                 beforeOpen = _.noop,
                                 onApply = _.noop,
                                 popperClassName = '',
                                 showSearch = true,
                                 titleMessage = null,
                                 titleIcon = 'list',
                                 itemIcon = null,
                                 shouldRenderIcon = _.noop,
                                 itemIconTitle = null,
                                 ...props
                             }) => {
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [visibleItems, setVisibleItems] = useState(items);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchItems();
    }, [items]);

    useEffect(() => {
        handleSearch();
    }, [items, searchTerm]);

    const fetchItems = () => {
        return setSelectedItems(items.filter(i => !!i.value));
    };

    const handleSearch = (search = searchTerm) => {
        let filteredItems = items;
        if (search) {
            filteredItems = items.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
        }
        setVisibleItems(filteredItems);
    };

    const changeItemValue = (event, item) => {
        const value = event.target.checked;
        const _items = selectedItems.filter(i => i.label !== item.label);
        if (value) {
            setSelectedItems(_items.concat([{...item, value: value}]))
        } else {
            setSelectedItems(_items)
        }
    };

    const handleOpen = async () => {
        setLoading(true);
        await beforeOpen();
        fetchItems();
        setLoading(false);
    };

    const handleClose = async () => {
        setSelectedItems([]);
        setSearchTerm('');
    };

    const applyHandler = async (closeDropdown) => {
        setLoading(true);
        try {
            await onApply(selectedItems);
            closeDropdown();
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    return (
        <BngDropdown {...props}
                     icon="plus"
                     className="BngDropdownCheckbox"
                     popperClassName={`${popperClassName} BngDropdownCheckboxPopper`}
                     onOpen={handleOpen}
                     onClose={handleClose}
                     customButton={customButton}
                     customOptions={({closeDropdown}) => (
                         <UiBlocker block={loading}>
                             {titleMessage && !showSearch &&
                             <div className="dropdownTitle">
                                 <Icon icon={titleIcon}/>
                                 <span style={{paddingLeft: '6px'}}>
                                     {titleMessage}
                                 </span>
                             </div>
                             }
                             {showSearch &&
                             <div className="ActionListSearch">
                                 <div style={{position: "relative", width: "100%"}}>
                                     <BngSearch onChange={setSearchTerm}
                                                inline={true}
                                                placeholder={context.msg.t('header.search')}
                                     />
                                 </div>
                             </div>
                             }
                             {!loading && visibleItems.length === 0 &&
                             <span className="ActionListEmpty">{context.msg.t('results.search.not.found')}</span>
                             }
                             <fieldset className="BngDropdownCheckboxOpts mt-3">
                                 <BngLazyListView
                                     listProp={{
                                         height: Math.min(visibleItems.length * 26, 150),
                                         itemCount: visibleItems.length,
                                         itemSize: 26,
                                     }}
                                     itemRender={(props) => (
                                         <CheckboxItem {...props}
                                                       visibleItems={visibleItems}
                                                       selectedItems={selectedItems}
                                                       changeItemValue={changeItemValue}
                                                       itemIcon={itemIcon}
                                                       shouldRenderIcon={shouldRenderIcon}
                                                       itemIconTitle={itemIconTitle}
                                         />
                                     )}
                                 />
                             </fieldset>
                             <div className={'text-right mt-3'}>
                                 <Button className={`bng-button cancel btn-small`}
                                         style={{marginRight: '10px'}}
                                         onClick={() => closeDropdown()}>
                                     {context.msg.t('cancel')}
                                 </Button>
                                 <Button className={`bng-button save btn-small`}
                                         onClick={() => applyHandler(closeDropdown)}>
                                     {context.msg.t('apply')}
                                 </Button>
                             </div>
                         </UiBlocker>
                     )}
        />
    )
};

const CheckboxItem = ({
                          visibleItems,
                          selectedItems,
                          index,
                          changeItemValue,
                          style,
                          shouldRenderIcon,
                          itemIcon,
                          itemIconTitle
                      }) => {
    const item = visibleItems[index];
    const isSelected = selectedItems.find(i => i.key === item.key);
    if (!item) return null;
    const renderIcon = !!shouldRenderIcon && shouldRenderIcon(item);
    if (renderIcon) {
        style = {...style, width: '90%'};
    }
    return (
        <div className="checkbox-item-parent">
            <BngCheckboxPure {...item}
                             key={`${item.key || index}_${item.label}`}
                             name={`dropdown_${item.key || index}`}
                             onChange={(e) => changeItemValue(e, item)}
                             checked={isSelected}
                             style={style}
            />
            {itemIcon && renderIcon &&
            <Icon icon={itemIcon}
                  className='item-icon'
                  style={style}
                  title={itemIconTitle}/>
            }
        </div>
    )
};

export default ContextEnhancer(BngDropdownCheckbox);