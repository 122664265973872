import styles from './SecurityCheckupLGPD.module.css';

import React, { useEffect, useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import CheckupHeader from 'components/bng/securityCheckup/components/CheckupHeader';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import AddonType from 'components/bng/accounts/AddonType';
import UiMsg from 'components/ui/UiMsg';
import { MODALS } from 'components/ui/redux/Actions';
import AddonInfoDialog from 'components/ui/navbar/addons/AddonInfoDialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';

function AddonCard({ addon }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        if (checkAddonEnabled(addon.id, false)) {
          setIsActive(true);
        }
      } catch (e) {
        console.error('Error while fetching Addons', e);
        UiMsg.ajaxError(null, e);
      }
    })();
  }, []);

  const openAddonDialog = () => {
    dispatch(
      MODALS.open(AddonInfoDialog, {
        addonKey: addon.id,
      })
    );
  };

  return (
    <div className={`AddonCard ${styles.cardWrapper}`}>
      <Icon icon={addon.iconName} style={{ color: addon.iconColor }} />
      <div className={`${styles.textContainer}`}>
        <span className={`${styles.title}`}>{context.msg.t(addon.nameKey)}</span>
        <span className={`${styles.desc}`}>{context.msg.t(addon.descKey)}</span>
        <button
          className={`${styles.button} ${isActive && styles.disabled}`}
          onClick={() => !isActive && openAddonDialog()}
        >
          {isActive ? context.msg.t('active') : context.msg.t('to.know')}
        </button>
      </div>
    </div>
  );
}

export default function SecurityCheckupLGPD() {
  const { msg } = useBimContext();
  const [addonInfo, setAddonInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const data = await Api.Account.findAddonsInfo([AddonType.USER_DATA_RESTRICT.key, AddonType.TRACEABILITY.key]);
        setAddonInfo(data);
      } catch (e) {
        console.error('Error while fetching addons info', e);
        UiMsg.ajaxError(null, e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="SecurityCheckupLGPD">
      <CheckupHeader
        className={styles.headerDesc}
        title={msg.t('checkup.lgpd.title')}
        description={msg.t('checkup.lgpd.desc')}
      />
      <UiBlocker block={loading} className={`${styles.cardsContainer}`}>
        {addonInfo.map((addon, idx) => {
          return <AddonCard key={`card_${idx}`} addon={addon} />;
        })}
      </UiBlocker>
    </div>
  );
}
