import React from "react";
import PropTypes from "prop-types";

import {OPERATORS} from "components/ui/map/editor/LegendItems";
import Button from "components/ui/Button";
import {Field, Validations} from "components/ui/FormUtils";
import {MODALS} from "components/ui/redux/Actions";
import {
    BASE_ICON_URL,
    ExpertFormatComponentDialogForm
} from "components/ui/analysis/expert/format/ExpertFormatComponentDialogForm";
import ContextEnhancer from "components/ContextEnhancer";


const FormatRender = ({elements = [], onClick = _.noop}) => {
    const bgColor = elements.find(e => e.type === 'BACKGROUND_COLOR');
    const fontColor = elements.find(e => e.type === 'FONT_COLOR');
    const image = elements.find(e => e.type === 'IMAGE');

    const style = {
        backgroundColor: bgColor ? bgColor.value : '',
        color: fontColor ? fontColor.value : '',

    };
    return (
        <div className="FormatRender"
             style={style}
             onClick={onClick}>
            {fontColor &&
            <span style={{marginRight: image ? '5px' : ''}}>
                123
            </span>
            }
            {image &&
            <img src={`${BASE_ICON_URL}/${image.value}`} alt=""/>
            }
        </div>
    );
};

class BandsConfiguration extends React.Component {

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        change: PropTypes.func.isRequired,
        fields: PropTypes.any.isRequired,
    };

    static SELECT_OPTIONS = (context) => Object.entries(OPERATORS).map(([key, {value, label}], idx) => (
        <option key={idx} value={value}>{context.msg.t(label)}</option>
    ));

    openFormatModal = (band, fields, idx) => {
        this.props.dispatch(MODALS.open(ExpertFormatComponentDialogForm, {
            format: band.format,
            onSave: (data) => {
                this.props.change(`${fields.name}[${idx}].format`, data);
            }
        }))
    };

    render() {
        const {fields} = this.props;

        const formatIsEmpty = (format) => {
            if (!format) return true;
            if (!format.elements) return true;
            return format.elements.length === 0;
        };

        return (
            <div className="BandsConfiguration">
                <table className="table table-condensed fill-w">
                    <tbody>
                    {fields.map((field, idx) => {
                        const band = fields.get(idx);
                        return (
                            <tr key={idx}>
                                <td>

                                    {!formatIsEmpty(band.format) &&
                                    <FormatRender elements={band.format.elements}
                                                  onClick={e => this.openFormatModal(band, fields, idx)}/>
                                    }
                                    {formatIsEmpty(band.format) &&
                                    <Button icon="icon-beaker"
                                            className="btn-inverse btn-mini btn-table"
                                            onClick={e => this.openFormatModal(band, fields, idx)}
                                    />
                                    }
                                </td>
                                <td>
                                    <input type="text"
                                           className="borderless-input fill-w"
                                           defaultValue={`Faixa ${idx + 1}`}
                                           readOnly={true}/>
                                </td>
                                <td>
                                    <Field name={`${fields.name}[${idx}].operator`}
                                           componentClass="select"
                                           className="borderless-input fill-w"
                                           validate={[Validations.required]}>
                                        {BandsConfiguration.SELECT_OPTIONS(this.props.context)}
                                    </Field>
                                </td>

                                <td>
                                    <Field name={`${fields.name}[${idx}].value`}
                                           type="text"
                                           className="borderless-input fill-w"
                                           validate={[Validations.required]}
                                    />
                                </td>
                                <td>
                                    <Button icon="icon-remove" className="btn-danger btn-mini btn-table"
                                            onClick={e => fields.remove(idx)}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}


export default ContextEnhancer(BandsConfiguration);