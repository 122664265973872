import './ProjectExpiredDialog.css';
import React, { Component } from 'react';
import { Field, wrapForm } from 'components/ui/FormUtils';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';
import ProjectTypeChangeButton from 'components/ui/project/ProjectTypeChangeButton';

class ProjectExpiredDialog extends Component {
  static propTypes = {};

  static defaultProps = {
    project: {},
  };

  state = {
    projects: [],
    loading: false,
    open: false,
    typeproject: '',
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const projects = await Api.Navbar.projectsMenuDetails();
      const filtered = this.filterProjects(projects);
      if (filtered.length > 0) {
        this.props.change('selectedProject', filtered[0].id + '');
      }
      let proj = projects.find((p) => p.id === this.props.project.id);
      this.setState({ projects: projects, typeproject: proj.projectType.type });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  save = ({ selectedProject }) => {
    const { projects } = this.state;
    const id = parseInt(selectedProject);
    const p = projects.find((p) => p.id === id);
    if (p) {
      window.location.href = Api.Bng.accessProjectUrl(p.name);
    }
  };

  filterProjects(projects) {
    return projects.filter((p) => !p.isExpired && p.id !== this.props.project.id);
  }

  openOptions = (event) => {
    this.setState({
      popperRef: event.currentTarget,
      open: true,
    });
  };

  render() {
    if (!this.props.initialized) return null;
    const { handleSubmit, formValues } = this.props;
    const { projects, loading } = this.state;
    const proj = projects.find((p) => p.id === this.props.project.id);
    const canManage = proj && proj.isMaster;
    const filteredProjects = this.filterProjects(projects);

    return (
      <Dialog open={this.props.open} hideHeader={true}>
        <form onSubmit={handleSubmit(this.save)} className="project-expired-form" ref={(ref) => (this.__form = ref)}>
          <p>{this.props.context.msg.t('project.expired.dialog.message.expired')}</p>
          <p>{this.props.context.msg.t('project.expired.dialog.message.contact')}</p>
          <p>{this.props.context.msg.t('project.expired.dialog.message.actions')}</p>

          {canManage ? (
            <div className="btn-fix">
              <hr />
              <label>{this.props.context.msg.t('change.project.type')}</label>
              <ProjectTypeChangeButton projectType={this.state.typeproject} container={this.__form} />
            </div>
          ) : (
            <div className="btn-fix">
              <Button className="btn-danger" type="submit" loading={loading}>
                {this.props.context.msg.t('detail_loggof')}
              </Button>
            </div>
          )}

          {filteredProjects.length > 0 && (
            <div className="btn-fix">
              <hr />
              <label>{this.props.context.msg.t('go.to.another.project')}</label>
              <Field component="select" name="selectedProject">
                {filteredProjects.map((p) => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.displayName || p.name}
                    </option>
                  );
                })}
              </Field>

              <Button
                type="submit"
                loading={loading}
                style={{ marginLeft: 10 }}
                disabled={formValues.selectedProject === '0'}
              >
                {this.props.context.msg.t('project.expired.dialog.open.project')}
              </Button>
            </div>
          )}

          <a
            className="project-expired-information-link"
            target="_blank"
            href={this.props.context.msg.t('project.expired.support.link')}
          >
            {this.props.context.msg.t('project.expired.dialog.message.documentation')}
          </a>
        </form>
      </Dialog>
    );
  }
}

export default ContextEnhancer(
  wrapForm({
    component: ProjectExpiredDialog,
    mapToState: (state) => ({
      initialValues: { selectedProject: '0', selectedProjectType: '0' },
      project: state.context.project,
    }),
  })
);
