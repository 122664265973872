import { useCallback, useEffect } from 'react';
import BimEventBus from 'BimEventBus';

const useEventBus = (event, handler, deps = []) => {
  const memoizedHandler = useCallback((data) => handler(data), deps);
  useEffect(() => {
    const unregister = BimEventBus.on(event, memoizedHandler);
    return () => {
      unregister();
    };
  }, [memoizedHandler]);

  return [];
};

export default useEventBus;
