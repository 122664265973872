import { Axios } from 'commonUtils';

class BreadcrumbApi {
  async updateCurrent(uri, addCurrentToHistory = false) {
    return await Axios.postData(`/spr/breadcrumb/updateCurrent`, {
      uri,
      addCurrentToHistory,
    });
  }
}

export default BreadcrumbApi;
