import styles from './CreateProjectPage.module.css';
import AdaFirstStepImg from './assets/ada_first_step.svg';

import React from 'react';

import useBimContext from 'components/hooks/useBimContext';
import useTranslation from 'components/hooks/useTranslation';
import { Axios } from 'commonUtils';
import { MODALS } from 'components/ui/redux/Actions';
import { NewProjectDialog } from 'components/bng/project/NewProjectDialog';
import ContactUs from 'components/bng/pages/create-project/ContactUs';

export default function CreateProjectPage() {
  const context = useBimContext();
  const { isMasterOnAnyAccount } = useBimContext();
  const { t } = useTranslation();

  return (
    <div className={`CreateProjectPage ${styles.card}`}>
      <div className={styles.content}>
        <div>
          <p className={`${styles.title} mb-6`}>{t('welcome.first.step')}</p>
          <p className={styles.subTitle}>{t('no.project.first.step')}</p>
          <p className={styles.text}>{t('text.one.first.step')}</p>
          <p className={styles.text}>{t('text.two.first.step')}</p>
          <button
            className={`${styles.subTitle} ${styles.buttonPrimary} mt-6`}
            onClick={() => {
              const registerUrl = Axios.getUri({
                url: 'https://register.bimachine.com/',
                params: { origin: 'plataforma', suborigin: 'welcomescreen', ps: 1, lead: context.user.email },
              });

              isMasterOnAnyAccount
                ? window.ReduxStore.dispatch(MODALS.open(NewProjectDialog, { goToProject: true }))
                : window.open(registerUrl, '_blank');
            }}
          >
            <span>{isMasterOnAnyAccount ? t('create_new_project') : t('test.now.first.step')}</span>
          </button>
        </div>
        <img alt="AdaClosed" src={AdaFirstStepImg} />
      </div>
      <ContactUs />
    </div>
  );
}
