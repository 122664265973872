import { Axios } from "commonUtils";


class UserParamApi {

    Keys = {
        Analysis: {
            NewAnalysisHelpVideo: 'NEW_ANALYSIS_HELP_VIDEO',
            WatchedNewAnalysis: 'WATCHED_NEW_ANALYSIS',
            CardsHelpViewed: 'CARDS_HELP_VIEWED',
            WatchedMobileDrawer: 'WATCHED_MOBILE_DRAWER',
        },
        Kpi: {
            NewKpiHelpVideo: 'NEW_KPI_HELP_VIDEO',
            WatchedNewKpi: 'WATCHED_NEW_KPI',
        },
        Addons: {
            AddonAlert: 'ADDON_ALERT',
        },
        SecurityCheckup: {
            SeenSecurityCheckup: 'SEEN_SECURITY_CHECKUP',
            UpdatedPhone: 'UPDATED_PHONE',
        }
    };

    findKey = async (key) => {
        return await Axios.getData(`/spr/user-param/${key}`);
    }

    saveUserKey = async ({ key, value, projectId }) => {
        const { data } = await Axios.post('/spr/user-param', {
            projectId,
            key,
            value
        });
        return data;
    }

    findAllForUser = async () => {
        const { data } = await Axios.get('/spr/user-param');
        return data;
    }

}

export default UserParamApi;