import './BngHorizontalScroller.css';

import React from "react";
import {ResizeSensor} from 'css-element-queries';

import Icon from "components/ui/common/Icon";


function computeTotalChildrenWidth(container) {
    return Array.from(container.children).reduce((acc, el) => {
        const style = window.getComputedStyle(el);
        const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        return acc + el.getBoundingClientRect().width + margin;
    }, 0);
}

function isScrolledIntoView(container, scrollPos) {
    const totalWidth = computeTotalChildrenWidth(container);
    const containerRect = container.getBoundingClientRect();
    return totalWidth < (scrollPos + containerRect.width);
}

function isElementVisible(element, container, scrollPos) {
    const parentRect = container.getBoundingClientRect();
    const rect = element.getBoundingClientRect();

    const containerInitialX = parentRect.x + scrollPos;

    if (rect.x < containerInitialX) return false;


    return (rect.x - containerInitialX) + rect.width <= parentRect.width;
}

const SCROLL_BUTTON_STEP = 300;

export class BngHorizontalScroller extends React.PureComponent {

    state = {
        width: 0,
        scrollPosition: 0,
        showForward: true,
    };

    constructor(props, context) {
        super(props, context);
        this.$scroller = React.createRef();
        this.$container = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResizeCallback);
        this._sensor = new ResizeSensor(
            this.$scroller.current,
            this.onResizeCallback
        );
        this.onResizeCallback();

        this.$scroller.current.addEventListener('wheel', (event) => {
            event.preventDefault();

            if (!this.state.showForward && event.deltaY > 0) return;

            const result = this.state.scrollPosition + event.deltaY;
            this.setState({scrollPosition: result >= 0 ? result : 0});
        });
    }

    onResizeCallback = () => {
        this.setState(
            {width: 0},
            () => this.setState({width: Math.max(this.$scroller.current.clientWidth, 1)})
        );
    };


    componentDidUpdate() {
        const childContainer = this.$container.current || {};
        const {children = []} = childContainer;
        const lastElement = children[children.length - 1];

        try {
            const containerWidth = childContainer.getBoundingClientRect().width;
            const childrenWidth = computeTotalChildrenWidth(childContainer);
            const maxScrollPosition = childrenWidth - containerWidth + 1;
            if (this.state.scrollPosition > maxScrollPosition) {
                this.setState({scrollPosition: maxScrollPosition});
                return;
            }
        } catch (e) {
            console.error(e);
        }

        const showForward = lastElement ? !isScrolledIntoView(childContainer, this.state.scrollPosition) : false;
        if (showForward !== this.state.showForward) {
            this.setState({showForward});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResizeCallback);
        if (this._sensor) {
            try {
                this._sensor.detach();
            } catch (e) {
                console.warn(e);
            }
        }
    }

    back = () => {
        this.setState({scrollPosition: Math.max(0, this.state.scrollPosition - SCROLL_BUTTON_STEP)});

    };

    forward = () => {
        this.setState({scrollPosition: this.state.scrollPosition + SCROLL_BUTTON_STEP});
    };

    centerOn(element) {
        const container = this.$container.current;

        if (isElementVisible(element, container, this.state.scrollPosition)) return;

        this.setState({scrollPosition: 0}, () => {
            const rect = element.getBoundingClientRect();
            const parentRect = container.getBoundingClientRect();
            this.setState({scrollPosition: rect.x - parentRect.x - (rect.width / 2)});
        });
    }

    render() {
        const showBack = this.state.scrollPosition > 0;

        return (
            <div className="BngHorizontalScroller" ref={this.$scroller}>

                {showBack &&
                <div className="Arrow Left" onClick={this.back}>
                    <Icon icon={'play_arrow'}/>
                </div>
                }

                <div
                    style={{maxWidth: this.state.width !== 0 ? this.state.width : undefined, overflow: 'hidden'}}>
                    <div className="Children" ref={this.$container}
                         style={{transform: `translateX(-${this.state.scrollPosition}px)`}}>
                        {this.state.width !== 0
                            ? this.props.children
                            : <div/>
                        }
                    </div>
                </div>

                {this.state.showForward &&
                <div className="Arrow Right" onClick={this.forward}>
                    <Icon icon={'play_arrow'}/>
                </div>
                }
            </div>
        );
    }
}