import React from 'react';
import { Field } from 'formik';

import BngField from 'components/bng/form/BngField';
import BngLineInputColor from 'components/bng/form/BngLineInputColor';

export default {
  render({ name, label, props }) {
    return (
      <Field
        name={name}
        label={label}
        component={BngField}
        inputComponent={BngLineInputColor}
        rootClassName={'ParamType Color'}
        rootProps={{ 'data-test': 'Color' }}
        {...props.inputProps}
      />
    );
  },
};
