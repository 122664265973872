import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from 'components/ContextEnhancer';
import LoadingPlaceholder from 'components/ui/loading/LoadingPlaceholder';
import Snackbar, { SnackbarVariant } from 'components/ui/common/Snackbar';
import Icon from 'components/ui/common/Icon';
import showObjectBlockedAlert from 'components/bng/objectExecMonitor/showObjectBlockedAlert';

const DashboardItemInformation = ({
                                      className = '',
                                      context = {},
                                      showErrorDialog = _.noop,
                                      snackbarType = null,
                                      reload = null,
                                      message = '',
                                      path = '',
                                      dashItemId = null,
                                      enableImagePlaceholder = true,
                                      snackbarIcon = null,
                                      errorTrace = '',
                                      showPlaceholder = true,
                                      snackbarVariant,
                                      ...props
                                  }) => {
    let snackbarClassName = snackbarType ? snackbarType : reload ? 'error' : 'no-data';

    let title = reload ? context.msg.t('see.details') : '';
    if (showObjectBlockedAlert.isObjectBlockedMessage(errorTrace)) {
        snackbarClassName = 'warning';
        const parsedData = showObjectBlockedAlert.parseMessageJson(errorTrace);
        if (parsedData) {
            message = context.msg.t('ObjectExecutionMonitorService.executionBlocked', [
                Math.max(1, parsedData.blockedUntil.diff(moment(), 'minutes')),
            ]);
            title = context.msg.t('ObjectExecutionMonitorService.executionBlocked.title');
        }
    }

    useEffect(() => {
        explorerMenuPositionNoData();
    }, [reload, dashItemId, message]);

    const explorerMenuPositionNoData = () => {
        if (!dashItemId || !application.page.isMobile()) return;
        if (snackbarClassName === 'no-data') {
            const dashItem = document.getElementsByClassName(`DashGridItem-${dashItemId}`)[0];
            if (!!dashItem) {
                const menuExplorer = dashItem.getElementsByClassName('dash-item-newmenu menu-mobile')[0];
                if (!!menuExplorer) {
                    menuExplorer.className = 'dash-item-newmenu menu-mobile noData';
                }
            }
        }
    };

    const snackMessage = message || context.msg.t('no.data.for.selected.filter');
    return (
        <div className={`DashboardItemInformation dash-item-information-wrapper ${className}`}>
            {showPlaceholder && <LoadingPlaceholder animation={false} path={path} {...props} />}
            <Snackbar
                onClick={showErrorDialog}
                message={snackMessage}
                title={title}
                icon={snackbarIcon || 'error_outline'}
                width={props.width}
                height={props.height}
                variant={snackbarVariant ? snackbarVariant : (message ? SnackbarVariant.Error : SnackbarVariant.Unloaded)}
            >
                {reload && <Icon icon="refresh" title={context.msg.t('try.again')} onClick={reload}/>}
            </Snackbar>
        </div>
    );
};

DashboardItemInformation.propTypes = {
    showErrorDialog: PropTypes.func,
    reload: PropTypes.func,
    message: PropTypes.string,
};

export default ContextEnhancer(DashboardItemInformation);