import styles from './BngSelectionOverlay.module.css';

import { animated, useSpring } from '@react-spring/web';

import useBimContext from 'components/hooks/useBimContext';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';

export default function BngSelectionOverlay({
  children,
  icon,
  btnAction,
  btnLabel,
  selectedItems = [],
  onChange = _.noop,
  showPopup = true,
  style = { zIndex: 2 },
}) {
  const context = useBimContext();
  const showSelectedOverlay = selectedItems.length > 0 && showPopup;
  const springStyles = useSpring({
    opacity: showSelectedOverlay ? 1 : 0,
    transform: showSelectedOverlay ? 'translateY(0px)' : 'translateY(40px)',
    ...style,
  });

  return (
    <>
      {children}
      <animated.div className={`selectedItemsOverlay ${styles.selectedItemsOverlay}`} style={springStyles}>
        <div className={`${styles.selectedItemsWrapper} ${!showSelectedOverlay ? styles.overlayHidden : ''}`}>
          <div className={styles.actionWrapper}>
            <Icon icon={icon} />
            <strong>{context.msg.t('overlay.itemsSelected', [selectedItems.length])}</strong>
            <div className={styles.actionButton} onClick={(event) => btnAction({ event })}>
              {btnLabel}
            </div>
          </div>
          <BngIconButton
            icon={'close'}
            onClick={() => {
              onChange([]);
            }}
          />
        </div>
      </animated.div>
    </>
  );
}
