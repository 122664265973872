import { Axios } from 'commonUtils';

class TwoFactorAuthApi {
  async findConf() {
    const { data } = await Axios.get(`/spr/tfa`);
    return data;
  }

  async saveRule(rule) {
    await Axios.post(`/spr/tfa/whitelist`, rule);
  }

  async removeRule(id) {
    await Axios.delete(`/spr/tfa/whitelist/${id}`);
  }

  async toggleEnable(enabled) {
    await Axios.post(`/spr/tfa/enable`, {
      enabled,
    });
  }
}

export default TwoFactorAuthApi;
