import React, {Component} from "react";

import {MODALS} from "components/ui/redux/Actions";
import Icon from "components/ui/common/Icon";
import CreatePresentation from "components/ui/presentation/CreatePresentation";

export default class PresentationButton extends Component {

    state = {
        open: false,
    };

    openPresentationDialog = (event) => {
        this.props.dispatch(MODALS.open(CreatePresentation, {
            fromCockpit: true,
            itemsCockpit: this.props.itemsCockpit
        }));
    };

    render() {
        return (
            <div className={`cockpit-nav-btn`}>
                <a onClick={this.openPresentationDialog} title={this.props.context.msg.t('presentation')}>
                    <Icon icon="airplay"/>
                </a>
            </div>
        )
    }
}