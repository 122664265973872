import React from 'react';
import Icon from "components/ui/common/Icon";

const BngDocumentationHelp = ({
                                  link = '#',
                                  className = '',
                                  iconTitle = '',
                                  ...props}) => {
    return (
        <a className={`BngDocumentationHelp ${className}`} href={link} target="_blank" rel="noreferrer" {...props}>
            <Icon className="qtip-hint" icon="icon-info-sign" title={iconTitle}/>
        </a>
    )
};

export default BngDocumentationHelp;