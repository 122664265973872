import Api from 'components/Api';
import Permission from 'components/ui/security/Permission';
import BimEventBus from 'BimEventBus';
import { Validations } from 'components/ui/FormUtils';

class CeDataHidden {
  state = {
    promise: Promise.resolve(),
    initialized: false,
    requested: false,
  };

  context = {};

  initCeData = (context, prefetchedTranslations) => {
    if (this.state.requested && !this.state.initialized) {
      return this.state.promise;
    }

    if (this.state.initialized) {
      return Promise.resolve(this);
    }

    this.state.requested = true;
    this.state.promise = new Promise(async (resolve, reject) => {
      try {
        const msg = await Api.translations(prefetchedTranslations);

        if (!context) {
          if (window.__ceDateInitialState) {
            context = window.__ceDateInitialState; // Mobile/Publisher
          }
        }

        this.initialize(context, msg);

        this.state.initialized = true;
        resolve(this);
        BimEventBus.emit('CeDataInitialized', { ceData: this });
      } catch (e) {
        console.error(e);
        reject(e);
      } finally {
        this.state.requested = false;
      }
    });

    return this.state.promise;
  };

  initialize(context = {}, msg) {
    console.log('CeData: init state');

    this.context = context || {};
    this.context.contextPath = Api.baseUrl();
    this.context.execute = (exp) => Api.executeExp(exp);

    if (this.context.user) {
      this.context.permissions = new Permission({
        user: this.context.user,
        role: this.context.userRole,
        permissions: this.context.rolePermissions,
        userIsOnlyCockpit: this.context.userIsOnlyCockpit,
        labs: this.context.labs,
        bimResources: context.bimResources,
      });
    }
    this.context.msg = msg;
    Validations.initialize(msg);
    this.context.findProjectIdFromContext = () => {
    let projectId = _.get(this.context, 'project.id');
    if (!projectId) {
        projectId = _.get(this.context, 'store.currentProject.id');
    }
    return projectId;
    };
  }
}

export const ceData = new CeDataHidden();
