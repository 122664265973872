import React, {Component} from "react";
import {AutoSizer, Column, Table} from "imports/ReactVirtualizedImport";
import {Field} from "components/ui/FormUtils";
import Button from "components/ui/Button";
import ContextEnhancer from "components/ContextEnhancer";
import BngInputColor from "components/bng/form/BngInputColor";
import {BngMaskedField, Mask, sanitizeNumber, sanitizePercent} from "components/bng/form/BngMaskedField";

class LegendItems extends Component {

    static propTypes = {};

    static defaultProps = {
        manageBands: false,
        onChange: _.noop,
        randomColor: _.noop,
        valueFormat: 'INTEGER'
    };

    state = {};

    constructor(props) {
        super(props);
    }

    addBand = () => {
        this.props.fields.push({
            color: this.props.randomColor(),
            description: '',
            value: 0,
            operator: '>'
        });
    };

    render() {
        const {fields, isAverage, valueFormat} = this.props;
        const items = fields.getAll();
        const isPercent = valueFormat === 'PERCENT';
        return (
            <div className="legend-items-config">
                {!isAverage &&
                <div className="add-band-button-div">
                    <Button type="button" onClick={this.addBand} className="btn-small">
                        {this.props.context.msg.t('add.band')}
                    </Button>
                </div>
                }
                <AutoSizer disableHeight={true}>
                    {({width}) => (
                        <Table disableHeader={true}
                               rowClassName={"legend-item-config"}
                               rowCount={items.length}
                               rowGetter={({index}) => items[index]}
                               rowHeight={isAverage ? 50 : 35}
                               width={width}
                               height={200}>

                            <Column dataKey="color"
                                    style={{marginLeft: 0}}
                                    width={25}
                                    cellRenderer={({rowData, rowIndex}) => {
                                        return this.renderColorInput(fields, rowIndex, rowData);
                                    }}
                            />

                            <Column dataKey="description"
                                    width={100}
                                    flexGrow={1}
                                    cellRenderer={({rowIndex}) => {
                                        return (
                                            <Field name={`${fields.name}[${rowIndex}].description`}
                                                   component="input"
                                                   type="text"
                                                   placeholder={this.props.context.msg.t('without.description')}
                                                   className="borderless-input fill-w"
                                            />
                                        );
                                    }}
                            />

                            {!isAverage &&
                            <Column dataKey="operator"
                                    width={100}
                                    flexGrow={1}
                                    cellRenderer={({rowIndex}) => {
                                        return (
                                            <Field name={`${fields.name}[${rowIndex}].operator`}
                                                   component="select"
                                                   className="borderless-input fill-w">
                                                {Object.keys(OPERATORS).map((k, idx) => {
                                                    const op = OPERATORS[k];
                                                    return (
                                                        <option key={idx} value={k}>{this.props.context.msg.t(op.label)}</option>
                                                    )
                                                })}
                                            </Field>
                                        );
                                    }}
                            />
                            }
                            {!isAverage &&
                            <Column dataKey="value"
                                    width={100}
                                    flexGrow={1}
                                    cellRenderer={({rowIndex}) => {
                                        const mask = Mask[`MEASURE_${valueFormat}`];
                                        return (
                                            <div>
                                                <Field name={`${fields.name}[${rowIndex}].value`}
                                                       componentClass={!!mask ? BngMaskedField : "input"}
                                                       mask={mask}
                                                       type="text"
                                                       format={val => isPercent ? sanitizePercent(val,  100) : val}
                                                       parse={val => isPercent ? sanitizePercent(val, 0.01) : sanitizeNumber(val)}
                                                       className={`borderless-input fill-w ${valueFormat.includes('MONEY') || isPercent ? 'with-add' : ''}`}
                                                />
                                            </div>
                                        );
                                    }}
                            />
                            }
                            {!isAverage &&
                            <Column dataKey="actions"
                                    width={50}
                                    style={{margin: 0}}
                                    cellRenderer={({rowIndex}) => {
                                        return (
                                            <Button type="button" onClick={() => fields.remove(rowIndex)}
                                                    className="btn-mini btn-table btn-danger" icon="icon-remove"/>
                                        );
                                    }}
                            />
                            }
                            {isAverage &&
                            <Column dataKey="value"
                                    width={150}
                                    flexGrow={1}
                                    style={{margin: 0}}
                                    cellRenderer={({rowIndex}) => {
                                        return (
                                            <div>
                                                {rowIndex === 1 &&
                                                <span>&nbsp;</span>
                                                }
                                                {rowIndex !== 1 &&
                                                <label className="bound-config">
                                                    <div className="input-prepend input-append borderless">
                                                        <span className="add-on">
                                                            {this.props.context.msg.t('average.value')} {rowIndex === 0 ? '+' : '-'}
                                                        </span>
                                                        <Field name={`${fields.name}[${rowIndex}].value`}
                                                               component="input"
                                                               type="text"
                                                               placeholder={this.props.context.msg.t('value')}
                                                               props={({min: 1, max: 100})}
                                                               className="borderless-input"
                                                        />
                                                        <span className="add-on">%</span>
                                                    </div>
                                                </label>
                                                }
                                            </div>
                                        );
                                    }}/>
                            }

                        </Table>
                    )}
                </AutoSizer>

            </div>
        );
    }

    renderColorInput(fields, idx, rowData) {
        const props = {
            addTransparentOptions: false,
            size: 'xxs',
            field: {name: 'color', value: rowData.color},
            form: {
                setFieldValue: (name, value) => {
                    this.props.onChange(`${fields.name}[${idx}].color`, value)
                }
            }
        };
        return (
            <BngInputColor {...props} />
        );
    }

}

export const OPERATORS = {
    '>': {value: 'GT', label: 'GT', handler: (v1, v2) => (v1 > v2)},
    '>=': {value: 'GE', label: 'GE', handler: (v1, v2) => (v1 >= v2)},
    '<': {value: 'LT', label: 'LT', handler: (v1, v2) => (v1 < v2)},
    '<=': {value: 'LE', label: 'LE', handler: (v1, v2) => (v1 <= v2)},
    '===': {value: 'EQ', label: 'EQ', handler: (v1, v2) => (v1 === v2)},
    '!==': {value: 'NE', label: 'NE', handler: (v1, v2) => (v1 !== v2)},
};

export default ContextEnhancer(LegendItems);
