import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class OnlyValue extends BaseKpi {

    constructor(props) {
        super(props);
    }

    customResizeKpi = ({height}) => {
        this.$containerBandsEl.style.minHeight = "0px";

        resizeEl(this.$kpiValueEl, {
            fontSize: {minValue: 12, multiplier: 0.8, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.8, refSize: height},
        });

        if (this.$kpiValueEl.offsetWidth >= this.props.div.offsetWidth) {
            const newHeight = height * ((this.props.div.offsetWidth / this.$kpiValueEl.offsetWidth) * 0.85);

            resizeEl(this.$kpiValueEl, {
                fontSize: {minValue: 12, multiplier: 0.8, refSize: newHeight},
                lineHeight: {minValue: 12, multiplier: 0.8, refSize: newHeight},
            });
        }
    };

    render() {
        const title = this.state.value.toString().includes(':') ? this.state.value : this.state.titleFormattedValue;

        return (
            <div>
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td>
                            <div className="kpivalue">
                                <div className="infobox-data-number"
                                     style={{color: this.state.color, fontWeight: '500'}}>
                                    <span title={title} ref={el => this.$kpiValueEl = el}
                                          style={{whiteSpace: 'nowrap'}}>{this.props.preffix} {this.state.value} {this.props.suffix} </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <center ref={el => this.$containerBandsEl = el}>
                                {this.useBands({container: this.$containerBandsEl})}
                            </center>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

