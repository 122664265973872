import * as React from "react";

export default class Toggleable extends React.Component {

    state = {
        open: false,
        over: false
    };

    componentDidMount() {
        window.addEventListener('mousedown', this.clickedOutOfDropdown, false);
        window.addEventListener('blur', this.clickedOutOfDropdown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.clickedOutOfDropdown, false);
        window.removeEventListener('blur', this.clickedOutOfDropdown, false);
    }

    clickedOutOfDropdown = () => {
        if (!this.isOver() && this.isOpen()) {
            this.close();
        }
    };

    over = () => {
        this.setState({over: true});
    };

    out = () => {
        this.setState({over: false});
    };

    isOpen = () => {
        return this.state.open;
    };

    isOver = () => {
        return this.state.over;
    };

    open = () => {
        this.setState({open: true});
    };

    close = () => {
        this.setState({open: false});
    };

}