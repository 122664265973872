import './GenericFileSource.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, Formik } from 'formik';

import { BngForm } from 'components/bng/form/BngForm';
import { BngField } from 'components/bng/form/BngField';
import useBimContext from 'components/hooks/useBimContext';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import Button from 'components/ui/Button';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import useFetchData from 'components/hooks/useFetchData';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { FormikListener } from 'components/bng/form/formik/FormikListener';
import BngTag from 'components/bng/ui/BngTag';
import { Tab, TabSet } from 'components/ui/TabSet';
import BngSwitch from 'components/bng/form/BngSwitch';

const FormSchema = bngYup((yup) => {
  return yup.object({
    id: yup.number().nullable().default(null),
    url: yup
      .string()
      .default('')
      .transform((value) => (_.isString(value) ? value : ''))
      .when('fileSource.type', (sourceType, schema) => {
        return sourceType === 'GATEWAY' ? schema.required() : schema.nullable();
      }),
    projectExtractorId: yup
      .number()
      .default(undefined)
      .transform((value) => (Number.isFinite(value) ? value : undefined))
      .when('fileSource.type', (sourceType, schema) => {
        return sourceType === 'GATEWAY' ? schema.required() : schema.nullable();
      }),
    ignoreLoadErrors: yup.boolean().default(true),
    fileType: yup.string().required().default('XML'),
    fileSource: yup.object({
      type: yup.string().required().default('GATEWAY'),
      uploadedFiles: yup.array().default([]),
    }),
    extractionConfig: yup.object({
      dateFormat: yup.string().required().default('yyyy-MM-dd'),
      numberFormat: yup.string().required().default('#,##0.00'),
    }),
  });
});

const upateState = _.debounce((values, fromScheduler) => {
  Api.GenericFileSource.updateState(values, fromScheduler);
}, 500);

// Maintain currentTab when JSF destroy element
let currentTab = 0;

export default function GenericFileSource({
  className = '',
  fromScheduler = false,
  initialValid = false,
  isEditing = false,
  isDirty = false,
}) {
  const { project, msg, permissions } = useBimContext();
  const [isValid, setIsValid] = useState(initialValid);

  const $formik = useRef();
  const $uploadRef = useRef();

  const { data: comboOpts } = useFetchData(async () => {
    try {
      const result = await Api.GenericFileSource.findComboOpts();
      return result;
    } catch (e) {
      console.error('Error on findComboOpts()', e);
      UiMsg.ajaxError(null, e);
    }
  }, []);

  const $backendState = useFetchData(async () => {
    let isGateway = false;
    try {
      const state = await Api.GenericFileSource.fetchState(fromScheduler);

      state.fileType = state.fileType ?? 'XML';
      state.fileSource.type = state.fileSource.type ?? 'UPLOAD';

      isGateway = state.fileSource.type === 'GATEWAY';

      //     |\__/,|   (`\
      //   _.|o o  |_   ) )
      // -(((---(((--------
      // Update JSF rendered labels ^^
      document
        .querySelectorAll('.ReplaceSourceLabel')
        .forEach((el) => (el.innerHTML = msg.t(`GenericFileSource.${state.fileType}`)));

      $formik.current.resetForm({
        values: _.merge({}, FormSchema.default(), state),
      });
    } catch (e) {
      console.error('Error on fetchState()', e);
      UiMsg.ajaxError(null, e);
    }
    return {
      firstRenderFinished: true,
      isGateway,
    };
  }, []);

  useEffect(() => {
    const btn = document.querySelector('.JsfWizardNextBtn');
    btn.disabled = !isValid;
  }, [isValid]);

  const $extractorOpts = useFetchData(async () => {
    try {
      const extractors = await Api.ProjectExtractor.findByProject(project.id);
      return extractors.map((e) => ({ value: e.id, label: e.name }));
    } catch (e) {
      console.error('Error while fetching project extractors', e);
      UiMsg.ajaxError(null, e);
    }
  }, []);

  const allowBimGateway = $backendState.data?.isGateway || permissions.canAccessBimGateway();

  const sourceTypeOpts = useMemo(() => {
    const opts = [];
    if (allowBimGateway || fromScheduler) {
      opts.push({ value: 'GATEWAY', label: msg.t('extractor') });
    }
    if (!fromScheduler) {
      opts.push({ value: 'UPLOAD', label: 'Upload' });
    }
    return opts;
  }, [allowBimGateway, fromScheduler]);

  const validateConnection = async (values, formikProps) => {
    try {
      setIsValid(false);
      if (values.fileSource.type === 'UPLOAD' && _.isEmpty(values.fileSource.uploadedFiles)) {
        UiMsg.warn(msg.t('add.at.least.one.file.to.load'));
        return;
      }
      const validationResult = await Api.GenericFileSource.validateConnection(values, fromScheduler);
      if (validationResult.valid) {
        UiMsg.ok(msg.t('GenericFileSource.validation.success'));
      } else {
        let error = validationResult.error;
        try {
          const { errors } = JSON.parse(error);
          error = `<ul>${errors.map((s) => '<li>' + s + '</li>').join('')}</ul><p>${msg.t(
            'csv.upload.error.message'
          )}</p>`;
        } catch (e) {}
        UiMsg.error(msg.t('error'), error);
      }
      setIsValid(validationResult.valid);
      await Api.updateJsf();
    } catch (e) {
      console.error('Error on validateConnection', values, e);
      UiMsg.ajaxError(null, e);
      setIsValid(false);
    } finally {
      formikProps.setSubmitting(false);
    }
  };

  const disableFields = isEditing && !isDirty;

  return (
    <Formik
      initialValues={FormSchema.default()}
      onSubmit={validateConnection}
      innerRef={$formik}
      validationSchema={FormSchema}
    >
      {(formikProps) => {
        const fileSourceType = formikProps.values.fileSource.type;

        useEffect(() => {
          if (fileSourceType !== 'GATEWAY' && formikProps.values.projectExtractorId) {
            formikProps.setFieldValue('projectExtractorId', null);
          }
        }, [fileSourceType]);

        const uploadedFileName = formikProps.values.fileSource?.uploadedFiles?.[0]?.originalFileName || '';
        return (
          <UiBlocker className={`GenericFileSource ${className}`} block={formikProps.isSubmitting}>
            <BngForm>
              <FormikListener
                onChange={async ({ values }, { values: prevValues }) => {
                  if (!$backendState.data?.firstRenderFinished || _.isEqual(values, prevValues)) return;

                  upateState(values, fromScheduler);
                  setIsValid(false);
                }}
              />

              <TabSet
                vertical
                tabToOpenIndex={currentTab}
                handleTabChange={(selectedTab) => (currentTab = selectedTab)}
              >
                <Tab icon="cloud_upload" title={msg.t('fileSource.type')}>
                  <div className="flex-center-items gap-3">
                    <Field
                      rootClassName={`w-30`}
                      name="fileSource.type"
                      label={msg.t('fileSource.type')}
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      options={sourceTypeOpts}
                      clearable={false}
                      disabled={disableFields}
                    />

                    {fileSourceType === 'GATEWAY' && (
                      <Field
                        rootClassName={`w-30`}
                        name="projectExtractorId"
                        label={msg.t('extractor')}
                        component={BngField}
                        inputComponent={BngSelectSearch}
                        options={$extractorOpts.data ?? []}
                        disabled={fileSourceType !== 'GATEWAY' || disableFields}
                      />
                    )}
                  </div>

                  {fileSourceType !== 'UPLOAD' ? (
                    <Field name="url" label={msg.t('url')} component={BngField} disabled={disableFields} />
                  ) : (
                    <>
                      <div className="flex-center-items gap-2">
                        <input
                          className="d-none"
                          type="file"
                          accept="application/xml, text/xml, application/zip"
                          onChange={async ({ target }) => {
                            formikProps.setSubmitting(true);
                            try {
                              const [file] = target.files;

                              if (file.size > 50 * 1024 * 1024) {
                                UiMsg.warn(msg.t('fileMaxSizeAlert'));
                                return;
                              }

                              setIsValid(false);
                              const result = await Api.GenericFileSource.uploadFile(file);
                              formikProps.setFieldValue('fileSource.uploadedFiles', result);
                            } finally {
                              target.value = null;
                              formikProps.setSubmitting(false);
                            }
                          }}
                          ref={$uploadRef}
                        />

                        <Button
                          className="btn-success position-relative no-wrap"
                          icon="icon-cloud-upload"
                          onClick={() => $uploadRef.current.click()}
                          disabled={disableFields}
                        >
                          {msg.t('upload_button')}
                        </Button>

                        {!_.isEmpty(formikProps.values.fileSource.uploadedFiles) && (
                          <BngTag
                            key={`uploadedFileName-${uploadedFileName}`}
                            icon="description"
                            onClose={() => formikProps.setFieldValue('fileSource.uploadedFiles', [])}
                            description={_.truncate(uploadedFileName, {
                              length: 96,
                            })}
                            title={uploadedFileName.length > 96 ? uploadedFileName : undefined}
                            textEllipsis
                          />
                        )}
                      </div>

                      <div className="icePnlGrp alert alert-info mt-2">
                        <div className="flex-center-items gap-4">
                          <span className="material-icons">info</span>
                          <div>
                            <div>{msg.t('inMemoryXmlFileHint')}</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>

                <Tab icon="settings" title={msg.t('advanced.options')}>
                  <div className="flex-center-items gap-3">
                    <Field
                      rootClassName={`w-100`}
                      name="extractionConfig.dateFormat"
                      label={msg.t('inMemory_dateFormat')}
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      options={comboOpts?.dateFormatOpts ?? []}
                      clearable={false}
                    />

                    <Field
                      rootClassName={`w-100`}
                      name="extractionConfig.numberFormat"
                      label={msg.t('inMemory_numericFormat')}
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      options={comboOpts?.numberFormatOpts ?? []}
                      clearable={false}
                    />

                    <Field
                      rootClassName={`w-100`}
                      name="ignoreLoadErrors"
                      label={msg.t('newInMemoryIgnoreParseErrors')}
                      labelProps={{ title: msg.t('newInMemoryIgnoreParseErrorsHint') }}
                      component={BngField}
                      inputComponent={BngSwitch}
                    />
                  </div>
                </Tab>
              </TabSet>

              <div className="flex-center-items gap-2 mt-2 jc-end">
                <Button type="submit" disabled={disableFields} icon="icon-ok" className="btn-inverse">
                  {msg.t('validate')}
                </Button>

                <Button
                  icon="icon-eye-open"
                  onClick={async () => {
                    formikProps.setSubmitting(true);
                    try {
                      await Api.GenericFileSource.updateState(formikProps.values, fromScheduler, false);
                      const source = fromScheduler
                        ? 'inMemorySchedulingFormMB.scheduling.source'
                        : 'inMemoryBean.source';
                      await Api.executeExp(`#{sourcePreviewMB.openPreview(inMemoryBean.inMemory, ${source})}`);
                    } finally {
                      formikProps.setSubmitting(false);
                    }
                  }}
                  className="BtnPreview btn-inverse"
                  disabled={disableFields || !isValid}
                >
                  {msg.t('data.preview')}
                </Button>
              </div>
            </BngForm>
          </UiBlocker>
        );
      }}
    </Formik>
  );
}
