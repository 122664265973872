"use strict";

const $ = jQuery;


class PiperunApi {

    static saveOrigin(pipeRunData) {
        return Promise.resolve(
            $.postJSON('/spr/origins/piperun', pipeRunData)
        );
    }

    static editOrigin(id, pipeRunData) {
        return Promise.resolve(
            $.postJSON(`/spr/origins/piperun/${id}`, pipeRunData)
        );
    }

    static getValuesWS(id) {
        return Promise.resolve(
            $.getJSON(`/spr/origins/piperun/values/${id}`)
        );
    }


    static validateToken(token) {
        return Promise.resolve(
            $.getJSON('/spr/origins/piperun/validate', {token})
        );
    }

    static validateName(pipeRunData) {
        return Promise.resolve(
            $.postJSON('/spr/origins/piperun/validatename', pipeRunData)
        );
    }
}

export default PiperunApi;
