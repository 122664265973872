import styles from './UpdateActions.module.css';
import React, { useEffect, useState } from 'react';
import Icon from 'components/ui/common/Icon';
import { ACTION, countActions } from 'components/bng/project/updateApps/projectUpdateAppsCommons';
import useBimContext from 'components/hooks/useBimContext';

export function CountComponent({ icon, count = 0, className = '', showCount = true, ...props }) {
  return (
    <div className={`${styles.countComponent} ${className}`} {...props}>
      <div>
        <Icon icon={icon} />
      </div>
      {showCount && <b className={`ml-1`}>{_.padStart(`${count}`, 2, '0')}</b>}
    </div>
  );
}

export default function UpdateActions({ className = '', actions, dataSources = [] }) {
  const { msg } = useBimContext();
  const [counts, setCounts] = useState({
    [ACTION.CREATE]: 0,
    [ACTION.UPDATE]: 0,
    [ACTION.REMOVE]: 0,
  });

  useEffect(() => {
    const result = countActions(actions);
    setCounts(result);
  }, [actions]);

  return (
    <div className={`flex-center-items jc-sb ${className}`}>
      <CountComponent
        icon={'cloud'}
        count={dataSources.length}
        className={`warning`}
        title={msg.t('updated.structures')}
      />
      <CountComponent icon={'add'} count={counts[ACTION.CREATE]} className={`success`} title={msg.t('added.objects')} />
      <CountComponent icon={'autorenew'} count={counts[ACTION.UPDATE]} title={msg.t('updated.objects')} />
      <CountComponent
        icon={'delete_outline'}
        count={counts[ACTION.REMOVE]}
        className={`danger`}
        title={msg.t('removed.objects')}
      />
    </div>
  );
}
