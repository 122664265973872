import React from "react";
import PropTypes from "prop-types";
import {Field, Form, Formik} from "formik";

import Dialog from "components/ui/Dialog";

import ContextEnhancer from "components/ContextEnhancer";
import {bngYup} from "components/bng/form/yup/BngYup";
import {DefaultDialogActions} from "components/ui/FormUtils";
import {BngField} from "components/bng/form/BngField";
import BngInputRichText from "components/bng/form/BngInputRichText";


const NewHtmlText = bngYup(yup => {
    return yup.object().shape({
        textPage: yup.string().default(''),
    });
});

class DialogHtmlText extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        text: PropTypes.string,
        item: PropTypes.object,
        updateTextLink: PropTypes.func
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true),
        text: '',
        item: {}
    };

    state = {
        value: ''
    };

    initialFormValues = NewHtmlText.default();

    componentDidMount() {
        if (this.props.item.path !== '') {
            this.buildConfigData(this.props.item.path);
            this.setState({value: this.props.item.path});
        }
    }

    buildConfigData = (text) => {
        this.initialFormValues.textPage = text;
    };

    save = (values, actions) => {
        //TODO Substituir componente de HTML, não consegui fazer a atual funcionar com o formik
        //TODO:: Fiz funcionar com formularios, substituir aqui
        let text = j('#textpagepresentation').val();
        this.props.updateTextLink(text, this.props.item, 'path');
        this.props.closeModal();
    };

    render() {
        return (
            <Formik initialValues={this.initialFormValues}
                    onSubmit={this.save}>
                <Dialog contentFullWidth={false} className="large" open={this.props.open}
                        title={this.props.context.msg.t('incorporate')}
                        onClose={this.props.closeModal}>
                    <Form>
                        <div>
                            <Field label=""
                                   id="textpagepresentation"
                                   name="textPage"
                                   component={BngField}
                                   inputComponent={BngInputRichText}
                                   asProps={{value: this.state.value}}
                            />
                        </div>

                        <DefaultDialogActions contentFullWidth={true} {...this.props}/>
                    </Form>

                </Dialog>

            </Formik>
        )
    }


}

export default ContextEnhancer(DialogHtmlText);

