import "components/ui/dashboard/components/itemMenus/DashboardItemMenuObjectInformation.css"
import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import Icon from "components/ui/common/Icon";

class DashboardItemMenuObjectInformation extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {
        open: false,
        result: null,
    };

    open = async () => {
        if (!this.state.open) {
            this.props.onOpen(event);
        }
        this.setState({open: !this.state.open});

    };

    forceClose = () => {
        this.setState({open: false});
    };



    render() {
        const {onClick, item} = this.props;
        let description = !!item.additionalProps.description ? item.additionalProps.description : this.props.context.msg.t('object.without.description');
        return (
            <li className={`bt-item-new-menu-inside ${this.state.open ? 'active' : '' } DescriptionMenuItemContainer `} onClick={onClick}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('object.description')}
                                        icon="info"
                                        more={true}
                                        onClick={this.open}
                />

                {this.state.open &&
                <ul className="bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-last-update DescriptionMenuItem">
                    <li>
                        <div className="TitleObject">
                            <div className="TitleContainer">
                                <Icon icon={item.icon}/>
                                <span title={item.additionalProps.title}> {item?.caption || item.additionalProps.title} </span>
                            </div>
                        </div>
                        <div className="DescriptionObject">
                            <span>
                                {description}
                            </span>
                        </div>
                    </li>
                </ul>
                }

            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuObjectInformation);