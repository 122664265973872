import './ProgressBar.css';

import React from "react";
import PropTypes from "prop-types";
import mergeClassNames from "overrides/merge-class-names";

const ProgressBar = ({
                         progress = 0,
                         type = "info",
                         striped = false,
                         animated = false,
                         className = '',
                         children,
                         ...props
                     }) => {

    return (
        <div className={mergeClassNames('ProgressBar', className)}
             {...props}>
            <div
                className={mergeClassNames('progress', `progress-${type}`, striped && 'progress-striped', animated && 'active')}>
                <div className="bar" style={{width: `${progress}%`}}>
                    <div className="dot"></div>
                </div>
            </div>
            {children}
        </div>
    )
};

ProgressBar.propTypes = {
    progress: PropTypes.number,
    type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
    striped: PropTypes.bool,
    animated: PropTypes.bool,
}

export default ProgressBar;
