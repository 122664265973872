import Api from "components/Api";
import * as React from "react";
import PropTypes from "prop-types";
import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import InputColor from "components/ui/InputColor";
import {Field, wrapForm} from "components/ui/FormUtils";
import Dialog from "components/ui/Dialog";
import UiMsg from "components/ui/UiMsg";
import RenderCheckbox from "components/ui/analysis/expert/format/RenderCheckbox";

export const AVAILABLE_ICONS = [..._.range(5).map(v => `Gauge_Asc${v}.gif`),
    'ball-red.gif', 'ball-yellow.gif', 'green-ball.gif',
    ..._.range(3).map(v => `cylinder${v}.gif`),
    ..._.range(3).map(v => `Smiley${v}.gif`),
    ..._.range(3).map(v => `Arrow_Status_Asc${v + 1}.gif`),
    'tendencia_baixo.png', 'tendencia_igual.png', 'tendencia_cima.png'
];

export const BASE_ICON_URL = `${Api.baseUrl()}/images/kpi`;

const IconSelector = ({value, onChange, disabled}) => {
    return (
        <div className="IconSelector" disabled={disabled}>
            {AVAILABLE_ICONS.map((val, idx) => {
                return (
                    <div key={idx}
                         className={`element-box ${value === val ? 'selected' : ''}`}
                         onClick={disabled ? null : e => onChange(val)}>
                        <span className="helper"></span>
                        <img src={`${BASE_ICON_URL}/${val}`}
                             alt={val}/>
                    </div>
                )
            })}
        </div>
    );
};


class ExpertFormatComponentDialog extends React.Component {

    static propTypes = {
        onSave: PropTypes.func.isRequired,
    };

    state = {};

    componentDidMount() {
        if (this.props.format) {
            this.props.initialize(this.props.format);
        }
    }

    save = (data) => {
        try {
            this.props.onSave(data);
            this.props.closeModal();
        } catch (e) {
            UiMsg.error('Error', e)
        }
    };

    findIndex = (type) => {
        return this.props.formValues.elements.findIndex(e => e.type === type);
    };

    findValue = (idx, defaultValue) => {
        const field = this.props.formValues.elements[idx];
        if (!!field) {
            return field.value;
        }
        return defaultValue;
    };


    render() {
        if (!this.props.initialized) return null;

        let backgroundProps = {
            idx: this.findIndex('BACKGROUND_COLOR'),
            defaultValue: "#FFFFFF",
        };


        let fountProps = {
            idx: this.findIndex('FONT_COLOR'),
            defaultValue: "#333333",
        };

        fountProps.formValue = this.findValue(
            fountProps.idx,
            fountProps.defaultValue
        );


        backgroundProps.formValue = this.findValue(
            backgroundProps.idx,
            backgroundProps.defaultValue
        );

        return (
            <Dialog open={this.props.open}
                    className={`FormatComponent`}
                    title={this.props.context.msg.t('format')}
                    onClose={this.props.closeModal}>
                <form onSubmit={this.props.handleSubmit(this.save)}>

                    <div className="row-fluid margin-bottom-default">
                        <div className="span6">
                            <RenderCheckbox type="BACKGROUND_COLOR"
                                            typeField={`elements[${backgroundProps.idx}].value`}
                                            label={this.props.context.msg.t('expert.format.dialog.alter.background.color')}
                                            array={this.props.array}
                                            findIdx={this.findIndex}
                                            inputColor={true}
                                            onChange={this.props.change}
                                            {...backgroundProps}
                            />
                        </div>
                        <div className="span6">
                            <RenderCheckbox type="FONT_COLOR"
                                            typeField={`elements[${fountProps.idx}].value`}
                                            label={this.props.context.msg.t('expert.format.dialog.alter.font.color')}
                                            array={this.props.array}
                                            findIdx={this.findIndex}
                                            inputColor={true}
                                            onChange={this.props.change}
                                            {...fountProps}
                            />
                        </div>
                    </div>

                    <div>
                        <RenderCheckbox type="IMAGE"
                                        label={this.props.context.msg.t('expert.format.dialog.add.icon')}
                                        defaultValue="Gauge_Asc0.gif"
                                        array={this.props.array}
                                        findIdx={this.findIndex}
                                        child={RenderIconSelector}
                                        idx={this.findIndex('IMAGE')}
                                        {...this.props}
                        />
                    </div>

                    <hr/>

                    <div className="text-right">
                        <Button icon={'icon-remove'} className={'btn-danger'} onClick={this.props.closeModal}>
                            {this.props.context.msg.t('cancel')}
                        </Button>
                        {' '}
                        <Button icon={'icon-save'} type={'submit'}>
                            {this.props.context.msg.t('save')}
                        </Button>
                    </div>

                </form>
            </Dialog>
        )
    }
}

export const ExpertFormatComponentDialogForm = wrapForm({
    name: 'expertFormatComponentDialogForm',
    initialValues: {
        elements: []
    },
    component: ContextEnhancer(ExpertFormatComponentDialog)
});


const RenderIconSelector = ({field, ...props}) => (
    <Field name={field}
           componentClass={IconSelector}
           {...props}
    />
);