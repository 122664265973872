// css imported on CommonCssImports.js
import React from 'react';
import ReactDOM from 'react-dom';

const BngClickOutsideOverlay = ({ className = '', onClick = _.noop, container = document.body, ...props }) => {
  return ReactDOM.createPortal(
    <div className={`BngClickOutsideOverlay ${className}`} onClick={onClick} {...props} />,
    container
  );
};

export default BngClickOutsideOverlay;
