import './UserMiniature.css';

import React from 'react';
import { BngAvatar } from 'components/bng/ui/BngAvatar';
import Icon from 'components/ui/common/Icon';
import { BngCheckboxPure } from 'components/bng/form/BngCheckbox';

const UserMiniature = ({ projectUser = {}, context = {}, selected = false, onChange = _.noop, ...props }) => {
  return (
    <div className={`UserMiniature ${selected ? 'online' : 'offline'}`} onClick={onChange}>
      <div className={`avatarAura`}>
        <BngAvatar userId={projectUser.user.id} />
      </div>
      <div className="userNameWrapper" title={projectUser.user.displayName}>
        <span style={{ fontSize: '14px', fontWeight: 500 }}>{projectUser.user.displayName}</span>
        <div className="userPermissionWrapper">
          <Icon icon={projectUser.role.icon} className="roleIcon" style={{ color: '#005DFF', fontSize: '15px' }} />
          <span style={{ color: 'rgba(0, 0, 0, 0.57)', padding: '0 4px' }}>{projectUser.role.name}</span>
        </div>
      </div>
      <BngCheckboxPure checked={selected} onChange={onChange} className="userMiniatureCheckbox" {...props} />
    </div>
  );
};

export default UserMiniature;
