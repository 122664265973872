import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import ComponentFactory from "components/ComponentFactory";
import BreadcrumbToolbar from './BreadcrumbToolbar';

class NotesToolbarItem extends PureComponent {

    static propTypes = {
        path: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        path: '',
    };

    constructor(props) {
        super(props);
    }

    render() {
        let {path, disabled} = this.props;
        return (
            <BreadcrumbToolbar.Item icon="forum"
                                    disabled={disabled || path.length === 0}
                                    title={this.props.context.msg.t('notes')}
                                    onClick={e => ComponentFactory.Notes.openNotes({anchorEl: e.target, path})}
            />
        );
    }
}

export default ContextEnhancer(NotesToolbarItem);
