import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import BreadcrumbToolbar from 'components/ui/breadcrumb-toolbar/BreadcrumbToolbar';
import PublisherIncorporateLink from "components/ui/publisher/PublisherIncorporateLink";
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';

class PublisherToolbarItem extends PureComponent {

    static propTypes = {
        path: PropTypes.string.isRequired,
        caption: PropTypes.string.isRequired
    };

    static defaultProps = {
        path: '',
        caption: ''
    };

    state = {
        isPublished: false
    };

    constructor(props) {
        super(props);
        window.PublisherToolbarItemInstance = this;
    }

    checkForPublication = async () => {
        try {
            if (this.props.disabled || this.props.path.length === 0) return;
            const {published} = await Api.Publisher.pathIsPublished(this.props.path);
            this.setState({isPublished: published});
        } catch (e) {
            this.setState({isPublished: false});
            console.warn('Error while trying to checkForPublication', e);
        }
    };

    componentDidMount() {
        this.checkForPublication();
    }

    componentWillUnmount() {
        delete window.PublisherToolbarItemInstance;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.path && this.props.path !== prevProps.path) {
            this.checkForPublication();
        }
    }

    openIncorporateDialog = (path, caption) => {
        window.ReduxStore.dispatch(
          window.Actions.MODALS.open(PublisherIncorporateLink, {
              path: path,
              caption: caption,
              onUpdate: this.checkForPublication
          })
        );
    };

    render() {
        let {path, caption, disabled} = this.props;
        if (!this.props.context.permissions.isAdmin()
            || !this.props.context.permissions.isPathWritable(path)) {
            return null;
        }

        const {isPublished} = this.state;
        return (
            <BreadcrumbToolbar.Item icon="public"
                                    disabled={disabled || path.length === 0}
                                    inactive={!isPublished}
                                    title={this.props.context.msg.t('publisher')}
                                    onClick={() => {
                                        if (checkAddonEnabled(AddonType.PUBLISHER.key, true)) {
                                            this.openIncorporateDialog(path, caption);
                                        }
                                    }}
            />
        );
    }
}

export default ContextEnhancer(PublisherToolbarItem);
