import React from 'react';

import ContextEnhancer from "components/ContextEnhancer";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";
import DataTab from 'components/bng/pages/bigTable/tabs/DataTab';

const BigTableDataMenuItem = ({
                                   toggleAccordion =_.noop,
                                   context,
                                   ...props
                               }) => {

    const toggleConfigAccordion = async (event) => {
        return toggleAccordion(DataTab, 'BigTableDataMenuItem');
    };

    return (
        <RightMenuItem title={context.msg.t('bigTable.data')}
                       icon="dns"
                       className={`BigTableDataMenuItem`}
                       onClick={toggleConfigAccordion}
                       {...props}/>
    );
};

export default ContextEnhancer(BigTableDataMenuItem);