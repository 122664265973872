import { useRef, useState } from 'react';

import { BngDropdown } from 'components/bng/ui/BngDropdown';
import BngColorPicker from 'components/bng/colorPicker/BngColorPicker';
import useBimContext from 'components/hooks/useBimContext';
import BngColorPickerPreview from 'components/bng/colorPicker/BngColorPickerPreview';

export default function BngColorPickerDropdown({
  className = '',
  btnContainerClass = '',
  btnClass = '',
  customColorOptions = [],
  color = {
    colors: [
      {
        color: '#005dff',
        opacity: 1,
        position: 0,
        id: 0,
      },
    ],
    rotation: 0,
  },
  onApply = _.noop,
  buttonSize = 30,
  paletteEnabled = true,
  solidEnabled = true,
  gradientEnabled = false,
  transparencyEnabled = false,
  gradientRotationEnabled = false,
  htmlButton,
  buttonIcon,
  disabled = false,
  onClose = _.noop,
  fetchProjectColors,
  transparentInPalette = false,
  ...props
}) {
  const { msg } = useBimContext();
  const [lastTabEvent, setLastTabEvent] = useState(0);
  const $pickerRef = useRef();
  const $dropdownActions = useRef();

  const applyColor = (c) => {
    if (disabled) {
      return;
    }
    const copy = _.cloneDeep(c);
    onApply(copy);
  };

  return (
    <BngDropdown
      title={msg.t('color.picker')}
      className={`BngColorPickerDropdown ${className}`}
      popperClassName={`BngColorPickerDropdownPopper`}
      popperStyle={{ minHeight: $pickerRef.current?.clientHeight ?? 425 }}
      onClose={onClose}
      customButton={({ openDropdown, closeDropdown }) => {
        $dropdownActions.current = { openDropdown, closeDropdown };

        if (htmlButton) {
          htmlButton.openColorPicker = openDropdown;
          return null;
        }

        return (
          <div className={`ColorPickerButton`} onClick={disabled ? undefined : openDropdown}>
            {_.isFunction(buttonIcon) ? (
              buttonIcon({ buttonSize })
            ) : (
              <BngColorPickerPreview
                color={color}
                gradientPreview={true}
                buttonSize={buttonSize}
                btnContainerClass={btnContainerClass}
                btnClass={btnClass}
              />
            )}
          </div>
        );
      }}
      customOptions={() => {
        return (
          <BngColorPicker
            ref={$pickerRef}
            customColorOptions={customColorOptions}
            color={color}
            onApply={applyColor}
            paletteEnabled={paletteEnabled}
            solidEnabled={solidEnabled}
            gradientEnabled={gradientEnabled}
            transparencyEnabled={transparencyEnabled}
            gradientRotationEnabled={gradientRotationEnabled}
            onTabChange={() => setLastTabEvent(Date.now())}
            fetchProjectColors={fetchProjectColors}
            closeDropdown={$dropdownActions.current?.closeDropdown ?? _.noop}
            transparentInPalette={transparentInPalette}
            {...props}
          />
        );
      }}
    />
  );
}
