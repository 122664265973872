import './BlockSelect.css';

import React from 'react';

import BngPopper from "components/bng/ui/BngPopper";
import BngClickOutsideOverlay from "components/bng/ui/BngClickOutsideOverlay";
import ContextEnhancer from "components/ContextEnhancer";

class BlockSelect extends React.Component {

    state = {
        open: false
    }

    onClick = (event) => {
        this.setState({
            popperRef: event.currentTarget,
            open: !this.state.open
        });
    }

    closePopper = (event)=> {
        this.setState({open: false});
    };

    onSelect = (option) => {
        this.props.onChange(option);
        this.setState({open: false});
    }

    render() {

        const { open } = this.state;
        const { options, value, context } = this.props;

        const container = this.props.container ? this.props.container : document.body;

        let selectedOption = null;
        if (value) {
            selectedOption = options.find(o => o.value === value.value);
        }

        return (
            <>
                <div className="BngBlockSelect" onClick={e => this.onClick(e)}>
                    { selectedOption ? selectedOption.label : context.msg.t('BlockSelect.select') }
                    { open ? (
                        <i className={`material-icons`}>{'arrow_drop_up'}</i>
                    ) : (
                        <i className={`material-icons`}>{'arrow_drop_down'}</i>
                    )}
                </div>
                <BngPopper open={open}
                           anchorEl={this.state.popperRef}
                           style={{zIndex: 51}}
                           container={container}>
                    <BngClickOutsideOverlay onClick={() => this.closePopper()} container={container}
                                            style={{zIndex: 50}} />
                    <div className="BngBlockSelect__list" style={{width: this.state.popperRef ? this.state.popperRef.clientWidth : 'unset'}}>
                        {options.map((o, i) => {

                            let style = {
                                margin: 10
                            };

                            if (o.style) {
                                style.color = o.style.fontColor;
                                style.fontSize = !isNaN(o.style.fontSize) ? parseInt(o.style.fontSize) : null;
                                style.backgroundColor = o.style.backgroundColor;

                                if (o.style.textFormat) {
                                    if (o.style.textFormat.indexOf('bold') >= 0) {
                                        style.fontWeight = 'bold';
                                    }

                                    if (o.style.textFormat.indexOf('italic') >= 0) {
                                        style.fontStyle = 'italic';
                                    }

                                    if (o.style.textFormat.indexOf('underline') >= 0) {
                                        style.textDecoration = 'underline';
                                    }
                                }
                            }

                            return (
                                <div key={i}
                                     className={`BngBlockSelect__option ${(selectedOption && selectedOption.value === o.value) && 'selected'}`}
                                     style={style}
                                     title={o.label}
                                     onClick={() => this.onSelect(o)}>
                                    {o.label}
                                </div>
                            )

                        })}
                    </div>
                </BngPopper>
            </>
        );
    }
}

export default ContextEnhancer(BlockSelect)