"use strict";

const $ = jQuery;


class NoteApi {
    findNotes = async (path) => {
        return await $.getJSON(`/spr/note?${j.param({path})}`);
    };

    sendNote = async (data) => {
        return await $.postJSON(`/spr/note`, data);
    };

    deleteNote = async (noteId) => {
        return await $.deleteReq(`/spr/note/${noteId}`);
    }
}

export default NoteApi;