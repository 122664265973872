const AddonStars = ({fill = '#00A355', width = '435', height = '243'}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 565 243" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M114.907 113.271C114.717 113.282 114.526 113.288 114.334 113.288C107.053 113.288 101.15 104.956 101.15 94.6766C101.15 94.4055 101.155 94.1357 101.163 93.8674H101.138C101.146 94.1357 101.15 94.4055 101.15 94.6766C101.15 104.956 95.2479 113.288 87.9672 113.288C87.7752 113.288 87.5841 113.282 87.394 113.271V113.305C87.5841 113.294 87.7752 113.288 87.9672 113.288C95.2479 113.288 101.15 121.621 101.15 131.9C101.15 132.171 101.146 132.441 101.138 132.709H101.163C101.155 132.441 101.15 132.171 101.15 131.9C101.15 121.621 107.053 113.288 114.334 113.288C114.526 113.288 114.717 113.294 114.907 113.305V113.271Z" fill={fill}/>
            <path d="M92.2491 116.509C92.0814 116.519 91.9128 116.525 91.7434 116.525C85.3193 116.525 80.1111 108.887 80.1111 99.4642C80.1111 99.2156 80.1147 98.9684 80.1219 98.7224H80.1003C80.1075 98.9684 80.1111 99.2156 80.1111 99.4642C80.1111 108.887 74.903 116.525 68.4789 116.525C68.3094 116.525 68.1408 116.519 67.9731 116.509V116.541C68.1408 116.53 68.3094 116.525 68.4789 116.525C74.903 116.525 80.1111 124.163 80.1111 133.585C80.1111 133.834 80.1075 134.081 80.1003 134.327H80.1219C80.1147 134.081 80.1111 133.834 80.1111 133.585C80.1111 124.163 85.3193 116.525 91.7434 116.525C91.9128 116.525 92.0814 116.53 92.2491 116.541V116.509Z" fill={fill}/>
            <path d="M92.2491 116.509C92.0814 116.519 91.9128 116.525 91.7434 116.525C85.3193 116.525 80.1111 108.887 80.1111 99.4642C80.1111 99.2156 80.1147 98.9684 80.1219 98.7224H80.1003C80.1075 98.9684 80.1111 99.2156 80.1111 99.4642C80.1111 108.887 74.903 116.525 68.4789 116.525C68.3094 116.525 68.1408 116.519 67.9731 116.509V116.541C68.1408 116.53 68.3094 116.525 68.4789 116.525C74.903 116.525 80.1111 124.163 80.1111 133.585C80.1111 133.834 80.1075 134.081 80.1003 134.327H80.1219C80.1147 134.081 80.1111 133.834 80.1111 133.585C80.1111 124.163 85.3193 116.525 91.7434 116.525C91.9128 116.525 92.0814 116.53 92.2491 116.541V116.509Z" fill="white" fillOpacity="0.5"/>
            <path d="M139.183 18.595C139.004 18.606 138.824 18.6116 138.643 18.6116C131.791 18.6116 126.236 10.6261 126.236 0.775482C126.236 0.515653 126.24 0.257122 126.247 0H126.224C126.232 0.257122 126.236 0.515653 126.236 0.775482C126.236 10.6261 120.68 18.6116 113.828 18.6116C113.647 18.6116 113.467 18.606 113.289 18.595V18.6281C113.467 18.6171 113.647 18.6116 113.828 18.6116C120.68 18.6116 126.236 26.597 126.236 36.4477C126.236 36.7075 126.232 36.966 126.224 37.2231H126.247C126.24 36.966 126.236 36.7075 126.236 36.4477C126.236 26.597 131.791 18.6116 138.643 18.6116C138.824 18.6116 139.004 18.6171 139.183 18.6281V18.595Z" fill={fill}/>
            <path d="M404.6 95.4725C404.421 95.4812 404.241 95.4855 404.06 95.4855C397.208 95.4855 391.653 89.236 391.653 81.5268C391.653 81.3235 391.657 81.1211 391.664 80.9199H391.641C391.649 81.1211 391.653 81.3235 391.653 81.5268C391.653 89.236 386.097 95.4855 379.245 95.4855C379.064 95.4855 378.884 95.4812 378.706 95.4725V95.4985C378.884 95.4898 379.064 95.4855 379.245 95.4855C386.097 95.4855 391.653 101.735 391.653 109.444C391.653 109.648 391.649 109.85 391.641 110.051H391.664C391.657 109.85 391.653 109.648 391.653 109.444C391.653 101.735 397.208 95.4855 404.06 95.4855C404.241 95.4855 404.421 95.4898 404.6 95.4985V95.4725Z" fill={fill}/>
            <path d="M179.642 67.1451C179.441 67.157 179.239 67.1631 179.035 67.1631C171.327 67.1631 165.077 58.4832 165.077 47.776C165.077 47.4936 165.081 47.2126 165.09 46.9331H165.064C165.072 47.2126 165.077 47.4936 165.077 47.776C165.077 58.4832 158.827 67.1631 151.118 67.1631C150.915 67.1631 150.712 67.157 150.511 67.1451V67.1811C150.712 67.1691 150.915 67.1631 151.118 67.1631C158.827 67.1631 165.077 75.8429 165.077 86.5501C165.077 86.8326 165.072 87.1136 165.064 87.393H165.09C165.081 87.1136 165.077 86.8326 165.077 86.5501C165.077 75.8429 171.327 67.1631 179.035 67.1631C179.239 67.1631 179.441 67.1691 179.642 67.1811V67.1451Z" fill={fill}/>
            <path d="M386.797 88.1866C386.607 88.1976 386.416 88.2031 386.224 88.2031C378.944 88.2031 373.041 80.2177 373.041 70.367C373.041 70.1072 373.045 69.8487 373.053 69.5916H373.029C373.037 69.8487 373.041 70.1072 373.041 70.367C373.041 80.2177 367.139 88.2031 359.858 88.2031C359.666 88.2031 359.475 88.1976 359.285 88.1866V88.2197C359.475 88.2087 359.666 88.2031 359.858 88.2031C367.139 88.2031 373.041 96.1886 373.041 106.039C373.041 106.299 373.037 106.558 373.029 106.815H373.053C373.045 106.558 373.041 106.299 373.041 106.039C373.041 96.1886 378.944 88.2031 386.224 88.2031C386.416 88.2031 386.607 88.2087 386.797 88.2197V88.1866Z" fill={fill}/>
            <path d="M325.298 25.0686C325.108 25.0796 324.917 25.0852 324.725 25.0852C317.445 25.0852 311.542 17.0997 311.542 7.24911C311.542 6.98929 311.546 6.73075 311.554 6.47363H311.53C311.538 6.73075 311.542 6.98929 311.542 7.24911C311.542 17.0997 305.639 25.0852 298.359 25.0852C298.167 25.0852 297.976 25.0796 297.786 25.0686V25.1018C297.976 25.0908 298.167 25.0852 298.359 25.0852C305.639 25.0852 311.542 33.0707 311.542 42.9213C311.542 43.1811 311.538 43.4397 311.53 43.6968H311.554C311.546 43.4397 311.542 43.1811 311.542 42.9213C311.542 33.0707 317.445 25.0852 324.725 25.0852C324.917 25.0852 325.108 25.0908 325.298 25.1018V25.0686Z" fill={fill}/>
            <path d="M458.008 38.8242C457.806 38.8356 457.604 38.8414 457.401 38.8414C449.692 38.8414 443.442 30.5088 443.442 20.2299C443.442 19.9587 443.446 19.689 443.455 19.4207H443.429C443.438 19.689 443.442 19.9587 443.442 20.2299C443.442 30.5088 437.192 38.8414 429.483 38.8414C429.28 38.8414 429.078 38.8356 428.876 38.8242V38.8587C429.078 38.8472 429.28 38.8414 429.483 38.8414C437.192 38.8414 443.442 47.1741 443.442 57.453C443.442 57.7241 443.438 57.9939 443.429 58.2622H443.455C443.446 57.9939 443.442 57.7241 443.442 57.453C443.442 47.1741 449.692 38.8414 457.401 38.8414C457.604 38.8414 457.806 38.8472 458.008 38.8587V38.8242Z" fill={fill}/>
            <path d="M485.52 103.563C485.352 103.573 485.184 103.578 485.014 103.578C478.59 103.578 473.382 96.6336 473.382 88.0679C473.382 87.8419 473.386 87.6171 473.393 87.3936H473.371C473.379 87.6171 473.382 87.8419 473.382 88.0679C473.382 96.6336 468.174 103.578 461.75 103.578C461.58 103.578 461.412 103.573 461.244 103.563V103.592C461.412 103.582 461.58 103.578 461.75 103.578C468.174 103.578 473.382 110.521 473.382 119.087C473.382 119.313 473.379 119.538 473.371 119.762H473.393C473.386 119.538 473.382 119.313 473.382 119.087C473.382 110.521 478.59 103.578 485.014 103.578C485.184 103.578 485.352 103.582 485.52 103.592V103.563Z" fill={fill}/>
            <path d="M233.05 52.5859C232.905 52.5941 232.758 52.5982 232.612 52.5982C227.044 52.5982 222.53 46.6959 222.53 39.415C222.53 39.2229 222.533 39.0318 222.54 38.8418H222.521C222.527 39.0318 222.53 39.2229 222.53 39.415C222.53 46.6959 218.017 52.5982 212.449 52.5982C212.302 52.5982 212.156 52.5941 212.011 52.5859V52.6104C212.156 52.6023 212.302 52.5982 212.449 52.5982C218.017 52.5982 222.53 58.5005 222.53 65.7814C222.53 65.9734 222.527 66.1645 222.521 66.3546H222.54C222.533 66.1645 222.53 65.9734 222.53 65.7814C222.53 58.5005 227.044 52.5982 232.612 52.5982C232.758 52.5982 232.905 52.6023 233.05 52.6104V52.5859Z" fill={fill}/>
            <path d="M233.05 52.5859C232.905 52.5941 232.758 52.5982 232.612 52.5982C227.044 52.5982 222.53 46.6959 222.53 39.415C222.53 39.2229 222.533 39.0318 222.54 38.8418H222.521C222.527 39.0318 222.53 39.2229 222.53 39.415C222.53 46.6959 218.017 52.5982 212.449 52.5982C212.302 52.5982 212.156 52.5941 212.011 52.5859V52.6104C212.156 52.6023 212.302 52.5982 212.449 52.5982C218.017 52.5982 222.53 58.5005 222.53 65.7814C222.53 65.9734 222.527 66.1645 222.521 66.3546H222.54C222.533 66.1645 222.53 65.9734 222.53 65.7814C222.53 58.5005 227.044 52.5982 232.612 52.5982C232.758 52.5982 232.905 52.6023 233.05 52.6104V52.5859Z" fill="white" fillOpacity="0.5"/>
            <path d="M182.879 199.051C182.656 199.059 182.431 199.063 182.205 199.063C173.639 199.063 166.695 193.508 166.695 186.655C166.695 186.474 166.7 186.295 166.71 186.116H166.681C166.69 186.295 166.695 186.474 166.695 186.655C166.695 193.508 159.751 199.063 151.186 199.063C150.96 199.063 150.735 199.059 150.511 199.051V199.074C150.735 199.067 150.96 199.063 151.186 199.063C159.751 199.063 166.695 204.618 166.695 211.471C166.695 211.651 166.69 211.831 166.681 212.01H166.71C166.7 211.831 166.695 211.651 166.695 211.471C166.695 204.618 173.639 199.063 182.205 199.063C182.431 199.063 182.656 199.067 182.879 199.074V199.051Z" fill={fill}/>
            <path d="M153.748 175.583C153.58 175.592 153.412 175.596 153.242 175.596C146.818 175.596 141.61 169 141.61 160.862C141.61 160.648 141.614 160.434 141.621 160.222H141.599C141.607 160.434 141.61 160.648 141.61 160.862C141.61 169 136.402 175.596 129.978 175.596C129.808 175.596 129.64 175.592 129.472 175.583V175.61C129.64 175.601 129.808 175.596 129.978 175.596C136.402 175.596 141.61 182.193 141.61 190.331C141.61 190.545 141.607 190.759 141.599 190.971H141.621C141.614 190.759 141.61 190.545 141.61 190.331C141.61 182.193 146.818 175.596 153.242 175.596C153.412 175.596 153.58 175.601 153.748 175.61V175.583Z" fill={fill}/>
            <path d="M87.3943 196.617C87.1707 196.629 86.9459 196.635 86.72 196.635C78.1545 196.635 71.2103 187.955 71.2103 177.248C71.2103 176.966 71.2152 176.685 71.2247 176.405H71.1959C71.2055 176.685 71.2103 176.966 71.2103 177.248C71.2103 187.955 64.2662 196.635 55.7007 196.635C55.4748 196.635 55.25 196.629 55.0264 196.617V196.653C55.25 196.641 55.4748 196.635 55.7007 196.635C64.2662 196.635 71.2103 205.315 71.2103 216.022C71.2103 216.305 71.2055 216.586 71.1959 216.865H71.2247C71.2152 216.586 71.2103 216.305 71.2103 216.022C71.2103 205.315 78.1545 196.635 86.72 196.635C86.9459 196.635 87.1707 196.641 87.3943 196.653V196.617Z" fill={fill}/>
            <path d="M430.494 196.619C430.293 196.63 430.09 196.635 429.887 196.635C422.178 196.635 415.928 188.65 415.928 178.799C415.928 178.54 415.933 178.281 415.941 178.024H415.915C415.924 178.281 415.928 178.54 415.928 178.799C415.928 188.65 409.679 196.635 401.97 196.635C401.766 196.635 401.564 196.63 401.363 196.619V196.652C401.564 196.641 401.766 196.635 401.97 196.635C409.679 196.635 415.928 204.621 415.928 214.472C415.928 214.731 415.924 214.99 415.915 215.247H415.941C415.933 214.99 415.928 214.731 415.928 214.472C415.928 204.621 422.178 196.635 429.887 196.635C430.09 196.635 430.293 196.641 430.494 196.652V196.619Z" fill={fill}/>
            <path d="M517.888 187.714C517.72 187.728 517.551 187.734 517.382 187.734C510.958 187.734 505.75 178.013 505.75 166.021C505.75 165.705 505.753 165.39 505.761 165.077H505.739C505.746 165.39 505.75 165.705 505.75 166.021C505.75 178.013 500.542 187.734 494.118 187.734C493.948 187.734 493.78 187.728 493.612 187.714V187.755C493.78 187.741 493.948 187.734 494.118 187.734C500.542 187.734 505.75 197.456 505.75 209.448C505.75 209.764 505.746 210.079 505.739 210.392H505.761C505.753 210.079 505.75 209.764 505.75 209.448C505.75 197.456 510.958 187.734 517.382 187.734C517.551 187.734 517.72 187.741 517.888 187.755V187.714Z" fill={fill}/>
            <path d="M509.795 184.477C509.628 184.491 509.459 184.497 509.29 184.497C502.866 184.497 497.658 174.776 497.658 162.784C497.658 162.468 497.661 162.153 497.668 161.84H497.647C497.654 162.153 497.658 162.468 497.658 162.784C497.658 174.776 492.449 184.497 486.025 184.497C485.856 184.497 485.687 184.491 485.52 184.477V184.518C485.687 184.504 485.856 184.497 486.025 184.497C492.449 184.497 497.658 194.219 497.658 206.211C497.658 206.527 497.654 206.842 497.647 207.155H497.668C497.661 206.842 497.658 206.527 497.658 206.211C497.658 194.219 502.866 184.497 509.29 184.497C509.459 184.497 509.628 184.504 509.795 184.518V184.477Z" fill={fill}/>
            <path d="M509.795 184.477C509.628 184.491 509.459 184.497 509.29 184.497C502.866 184.497 497.658 174.776 497.658 162.784C497.658 162.468 497.661 162.153 497.668 161.84H497.647C497.654 162.153 497.658 162.468 497.658 162.784C497.658 174.776 492.449 184.497 486.025 184.497C485.856 184.497 485.687 184.491 485.52 184.477V184.518C485.687 184.504 485.856 184.497 486.025 184.497C492.449 184.497 497.658 194.219 497.658 206.211C497.658 206.527 497.654 206.842 497.647 207.155H497.668C497.661 206.842 497.658 206.527 497.658 206.211C497.658 194.219 502.866 184.497 509.29 184.497C509.459 184.497 509.628 184.504 509.795 184.518V184.477Z" fill="white" fillOpacity="0.5"/>
            <path d="M399.745 215.234C399.599 215.243 399.453 215.247 399.306 215.247C393.739 215.247 389.225 208.998 389.225 201.289C389.225 201.085 389.228 200.883 389.235 200.682H389.216C389.222 200.883 389.225 201.085 389.225 201.289C389.225 208.998 384.711 215.247 379.144 215.247C378.997 215.247 378.851 215.243 378.706 215.234V215.26C378.851 215.252 378.997 215.247 379.144 215.247C384.711 215.247 389.225 221.497 389.225 229.206C389.225 229.409 389.222 229.612 389.216 229.813H389.235C389.228 229.612 389.225 229.409 389.225 229.206C389.225 221.497 393.739 215.247 399.306 215.247C399.453 215.247 399.599 215.252 399.745 215.26V215.234Z" fill={fill}/>
            <path d="M399.745 215.234C399.599 215.243 399.453 215.247 399.306 215.247C393.739 215.247 389.225 208.998 389.225 201.289C389.225 201.085 389.228 200.883 389.235 200.682H389.216C389.222 200.883 389.225 201.085 389.225 201.289C389.225 208.998 384.711 215.247 379.144 215.247C378.997 215.247 378.851 215.243 378.706 215.234V215.26C378.851 215.252 378.997 215.247 379.144 215.247C384.711 215.247 389.225 221.497 389.225 229.206C389.225 229.409 389.222 229.612 389.216 229.813H389.235C389.228 229.612 389.225 229.409 389.225 229.206C389.225 221.497 393.739 215.247 399.306 215.247C399.453 215.247 399.599 215.252 399.745 215.26V215.234Z" fill="white" fillOpacity="0.5"/>
            <path d="M564.821 205.525C564.665 205.533 564.507 205.537 564.349 205.537C558.353 205.537 553.492 199.981 553.492 193.129C553.492 192.948 553.496 192.768 553.502 192.589H553.482C553.489 192.768 553.492 192.948 553.492 193.129C553.492 199.981 548.631 205.537 542.636 205.537C542.477 205.537 542.32 205.533 542.164 205.525V205.548C542.32 205.54 542.477 205.537 542.636 205.537C548.631 205.537 553.492 211.092 553.492 217.944C553.492 218.125 553.489 218.305 553.482 218.484H553.502C553.496 218.305 553.492 218.125 553.492 217.944C553.492 211.092 558.353 205.537 564.349 205.537C564.507 205.537 564.665 205.54 564.821 205.548V205.525Z" fill={fill}/>
            <path d="M462.862 174.767C462.661 174.78 462.459 174.787 462.255 174.787C454.546 174.787 448.297 165.066 448.297 153.073C448.297 152.757 448.301 152.442 448.309 152.129H448.284C448.292 152.442 448.297 152.757 448.297 153.073C448.297 165.066 442.047 174.787 434.338 174.787C434.135 174.787 433.932 174.78 433.731 174.767V174.807C433.932 174.794 434.135 174.787 434.338 174.787C442.047 174.787 448.297 184.508 448.297 196.5C448.297 196.817 448.292 197.132 448.284 197.445H448.309C448.301 197.132 448.297 196.817 448.297 196.5C448.297 184.508 454.546 174.787 462.255 174.787C462.459 174.787 462.661 174.794 462.862 174.807V174.767Z" fill={fill}/>
            <path d="M93.8675 192.575C93.6886 192.584 93.5088 192.589 93.328 192.589C86.4757 192.589 80.9203 185.645 80.9203 177.08C80.9203 176.854 80.9242 176.629 80.9318 176.405H80.9088C80.9165 176.629 80.9203 176.854 80.9203 177.08C80.9203 185.645 75.365 192.589 68.5126 192.589C68.3319 192.589 68.152 192.584 67.9731 192.575V192.604C68.152 192.594 68.3319 192.589 68.5126 192.589C75.365 192.589 80.9203 199.533 80.9203 208.099C80.9203 208.325 80.9165 208.55 80.9088 208.773H80.9318C80.9242 208.55 80.9203 208.325 80.9203 208.099C80.9203 199.533 86.4757 192.589 93.328 192.589C93.5088 192.589 93.6886 192.594 93.8675 192.604V192.575Z" fill={fill}/>
            <path d="M93.8675 192.575C93.6886 192.584 93.5088 192.589 93.328 192.589C86.4757 192.589 80.9203 185.645 80.9203 177.08C80.9203 176.854 80.9242 176.629 80.9318 176.405H80.9088C80.9165 176.629 80.9203 176.854 80.9203 177.08C80.9203 185.645 75.365 192.589 68.5126 192.589C68.3319 192.589 68.152 192.584 67.9731 192.575V192.604C68.152 192.594 68.3319 192.589 68.5126 192.589C75.365 192.589 80.9203 199.533 80.9203 208.099C80.9203 208.325 80.9165 208.55 80.9088 208.773H80.9318C80.9242 208.55 80.9203 208.325 80.9203 208.099C80.9203 199.533 86.4757 192.589 93.328 192.589C93.5088 192.589 93.6886 192.594 93.8675 192.604V192.575Z" fill="white" fillOpacity="0.5"/>
            <path d="M25.8944 226.562C25.7155 226.571 25.5356 226.576 25.3549 226.576C18.5025 226.576 12.9472 219.632 12.9472 211.066C12.9472 210.84 12.951 210.616 12.9587 210.392H12.9357C12.9433 210.616 12.9472 210.84 12.9472 211.066C12.9472 219.632 7.39184 226.576 0.539466 226.576C0.358715 226.576 0.178867 226.571 0 226.562V226.59C0.178867 226.581 0.358715 226.576 0.539466 226.576C7.39184 226.576 12.9472 233.52 12.9472 242.086C12.9472 242.312 12.9433 242.536 12.9357 242.76H12.9587C12.951 242.536 12.9472 242.312 12.9472 242.086C12.9472 233.52 18.5025 226.576 25.3549 226.576C25.5356 226.576 25.7155 226.581 25.8944 226.59V226.562Z" fill={fill}/>
            <path d="M38.8421 73.6265C38.5961 73.6336 38.3488 73.6373 38.1003 73.6373C28.6783 73.6373 21.0397 68.4293 21.0397 62.005C21.0397 61.8356 21.045 61.667 21.0555 61.4993H21.0238C21.0344 61.667 21.0397 61.8356 21.0397 62.005C21.0397 68.4293 13.4011 73.6373 3.97907 73.6373C3.73054 73.6373 3.48325 73.6336 3.2373 73.6265V73.648C3.48325 73.6409 3.73054 73.6373 3.97907 73.6373C13.4011 73.6373 21.0397 78.8452 21.0397 85.2695C21.0397 85.4389 21.0344 85.6075 21.0238 85.7752H21.0555C21.045 85.6075 21.0397 85.4389 21.0397 85.2695C21.0397 78.8452 28.6783 73.6373 38.1003 73.6373C38.3488 73.6373 38.5961 73.6409 38.8421 73.648V73.6265Z" fill={fill}/>
            <path d="M449.915 62.2942C449.758 62.3033 449.601 62.3079 449.443 62.3079C443.447 62.3079 438.586 55.7112 438.586 47.5737C438.586 47.3591 438.589 47.1455 438.596 46.9331H438.576C438.583 47.1455 438.586 47.3591 438.586 47.5737C438.586 55.7112 433.725 62.3079 427.729 62.3079C427.571 62.3079 427.414 62.3033 427.257 62.2942V62.3216C427.414 62.3125 427.571 62.3079 427.729 62.3079C433.725 62.3079 438.586 68.9046 438.586 77.042C438.586 77.2567 438.583 77.4703 438.576 77.6827H438.596C438.589 77.4703 438.586 77.2567 438.586 77.042C438.586 68.9046 443.447 62.3079 449.443 62.3079C449.601 62.3079 449.758 62.3125 449.915 62.3216V62.2942Z" fill={fill}/>
            <path d="M449.915 62.2942C449.758 62.3033 449.601 62.3079 449.443 62.3079C443.447 62.3079 438.586 55.7112 438.586 47.5737C438.586 47.3591 438.589 47.1455 438.596 46.9331H438.576C438.583 47.1455 438.586 47.3591 438.586 47.5737C438.586 55.7112 433.725 62.3079 427.729 62.3079C427.571 62.3079 427.414 62.3033 427.257 62.2942V62.3216C427.414 62.3125 427.571 62.3079 427.729 62.3079C433.725 62.3079 438.586 68.9046 438.586 77.042C438.586 77.2567 438.583 77.4703 438.576 77.6827H438.596C438.589 77.4703 438.586 77.2567 438.586 77.042C438.586 68.9046 443.447 62.3079 449.443 62.3079C449.601 62.3079 449.758 62.3125 449.915 62.3216V62.2942Z" fill="white" fillOpacity="0.5"/>
            <path d="M394.889 40.4481C394.666 40.4558 394.441 40.4596 394.215 40.4596C385.65 40.4596 378.705 34.9045 378.705 28.0519C378.705 27.8712 378.71 27.6913 378.72 27.5125H378.691C378.701 27.6913 378.705 27.8712 378.705 28.0519C378.705 34.9045 371.761 40.4596 363.196 40.4596C362.97 40.4596 362.745 40.4558 362.521 40.4481V40.4711C362.745 40.4635 362.97 40.4596 363.196 40.4596C371.761 40.4596 378.705 46.0147 378.705 52.8673C378.705 53.0481 378.701 53.2279 378.691 53.4068H378.72C378.71 53.2279 378.705 53.0481 378.705 52.8673C378.705 46.0147 385.65 40.4596 394.215 40.4596C394.441 40.4596 394.666 40.4635 394.889 40.4711V40.4481Z" fill={fill}/>
        </svg>
    )
}

export default AddonStars;