export const STATUS = {
  NEW: 'NEW',
  UPDATED: 'UPDATED',
  REMOVED: 'REMOVED',
};

export const ACTION = {
  CREATE: 'CREATE',
  KEEP: 'KEEP',
  UPDATE: 'UPDATE',
  CLONE: 'CLONE',
  REMOVE: 'REMOVE',
};

export const MODE = {
  PROJECT: 'PROJECT',
  CUSTOMERS: 'CUSTOMERS',
  UNLINK_PROJECT: 'UNLINK_PROJECT',
};

export const countActions = (actions) => {
  return Object.values(actions)
    .filter((a) => a.action !== ACTION.KEEP)
    .reduce(
      (acc, el) => {
        const action = el.action === ACTION.CLONE ? ACTION.UPDATE : el.action;
        acc[action]++;
        return acc;
      },
      {
        [ACTION.CREATE]: 0,
        [ACTION.UPDATE]: 0,
        [ACTION.REMOVE]: 0,
      }
    );
};
