import React from 'react';
import Icon from 'components/ui/common/Icon';

export const SnackbarVariant = {
  Unloaded: 'snackbarVariantUnloaded',
  Save: 'snackbarVariantSave',
  Error: 'snackbarVariantError',
};

export default function Snackbar({
  children,
  className = '',
  title = '',
  message = null,
  icon = '',
  onClick = _.noop,
  width = 0,
  height = 0,
  variant = SnackbarVariant.Save,
}) {
  const isSmallObject = width !== 0 || height !== 0 ? width < 125 || height < 90 : false;

  return (
    <div className={`bng-snackbar ${className}`}>
      <div
        className={`snackbarContainer ${!!children && 'withChildren'} ${variant}`}
        title={isSmallObject ? message : ''}
        onClick={onClick}
        style={{ cursor: onClick ? 'pointer' : '' }}
      >
        <Icon icon={icon} className="bng-snackbar-icon" />
        {!isSmallObject && (
          <span style={{ cursor: onClick ? 'pointer' : '' }} className="bng-snackbar-message" title={title}>
            {message}
          </span>
        )}
      </div>
      {children && <div className={`snackbarChildrenContainer ${variant}`}>{children}</div>}
    </div>
  );
}
