import './CoolPabloProgressBar.css';

import React from 'react';

const CoolPabloProgressBar = ({ progress = 0 }) => {
  const normalizedProgress = normalizeProgress(progress);
  const progressFraction = normalizedProgress / 100;
  const cursorPosition = 6.4 + normalizedProgress * 0.936;
  return (
    <div className={`CoolPabloProgressBar d-flex`}>
      <div className={'red'}></div>
      <div className={'yellow'}></div>
      <div className={'green'}></div>
      <div
        className={'CoolEmoji text-center'}
        style={{ left: `calc(${cursorPosition}% - 32px)` }}
      >
        {progressFraction < 1 / 3 && '🙁'}
        {progressFraction >= 1 / 3 && progressFraction <= 2 / 3 && '😐'}
        {progressFraction > 2 / 3 && '😊'}
      </div>
    </div>
  );
};

const normalizeProgress = (progress) => {
  if (progress > 100) progress = 100;
  if (progress < 0) progress = 0;
  return progress;
};

export default CoolPabloProgressBar;
