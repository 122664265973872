import {Axios} from "commonUtils";


class MobileApi {

    availableOnMobile = async ({path}) => {
        const {data} = await Axios.get(`/spr/mobile-resource/available-mobile`, {params: {path}});
        return data;
    };
}

export default MobileApi;