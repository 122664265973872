import * as React from "react";
import PropTypes from "prop-types";
import Button from "components/ui/Button";
import {Field} from "components/ui/FormUtils";
import BandsConfiguration from "components/ui/analysis/expert/format/BandsConfiguration";
import ReduxFormCheckbox from "components/ui/ReduxFormCheckbox";
import ContextEnhancer from "components/ContextEnhancer";
import {FieldArray} from "redux-form";
import {OPERATORS} from "components/ui/map/editor/LegendItems";


export const Format = ContextEnhancer(
    ({formValues, formatStrings, context, dispatch, change, array, disableValueFormat=false}) => (
        <div>
            <fieldset className="margin-bottom-default FormulaFormat">
                <legend>{context.msg.t('values')}</legend>
                <div className="row-fluid">
                    <div className="span12">
                        <div style={{position: 'relative'}}>

                            <Field name="format.custom"
                                   componentClass={ReduxFormCheckbox}
                                   label={context.msg.t('tab_customize')}
                                   groupClass="inline-checkbox customize-checkbox"
                                   disabled={disableValueFormat}
                            />

                            {formValues.format.custom &&
                            <Field name="format.expression"
                                   type="text"
                                   label={context.msg.t('expert_mask')}
                                   className="fill-w"
                                   disabled={disableValueFormat}
                            />
                            }

                            {!formValues.format.custom &&
                            <Field name="format.expression"
                                   componentClass="select"
                                   label={context.msg.t('expert_mask')}
                                   className="fill-w"
                                   disabled={disableValueFormat}>
                                <option value="">{context.msg.t('select.one')}</option>
                                {formatStrings.map(fs => {
                                    return (
                                        <option key={fs.type} value={fs.type} disabled={_.get(formValues, 'format.bands.length', 1) !== 0 && fs.type === "Hour"}>
                                            {context.msg.t(fs.label)}
                                        </option>
                                    )
                                })}
                            </Field>
                            }
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <legend>
                    {context.msg.t('expert_valuesBand')}


                    <div className="pull-right">
                        <Field name="format.showValues"
                               componentClass={ReduxFormCheckbox}
                               label={context.msg.t('expert_showValues')}
                               groupClass="inline-checkbox inline d-r-m"
                        />
                        {' '}
                        <Button className="btn-mini" icon="icon-plus"
                                onClick={e => {
                                    array.push('format.bands', {
                                        format: {
                                            elements: []
                                        },
                                        operator: Object.values(OPERATORS)[0].value,
                                        value: '0'
                                    });
                                    if(!formValues.format.custom && formValues.format.expression === 'Hour') {
                                        change('format.expression', 'Fixed');
                                    }
                                }}>
                            {context.msg.t('add.band')}
                        </Button>
                    </div>

                </legend>
                <div className="row-fluid">
                    <FieldArray name="format.bands"
                                component={BandsConfiguration}
                                props={{
                                    dispatch: dispatch,
                                    change: change,
                                }}
                    />

                </div>
            </fieldset>

        </div>
    )
);

Format.propTypes = {
    formValues: PropTypes.any,
    formatStrings: PropTypes.array,
    context: PropTypes.any,
    dispatch: PropTypes.any.isRequired,
    change: PropTypes.any.isRequired,
    array: PropTypes.any.isRequired,
};