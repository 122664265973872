import ProjectMenuButton from "components/ui/navbar/ProjectMenuButton";
import React from "react";
import {connect} from "react-redux";
import {NAVBAR, USER, MODALS} from "components/ui/redux/Actions";
import {NewProjectDialog} from "components/bng/project/NewProjectDialog";


const mapStateToProps = (state) => {
    return {
        projects: state.navbar.projects,
        defaultProjectId: state.context.user.preferences.defaultProjectId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        findProjects: () => dispatch(NAVBAR.findProjects()),
        updateFavorite: (id) => dispatch(USER.setProjectAsFavorite(id)),
        createNewProject: (id) => dispatch(MODALS.open(NewProjectDialog)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectMenuButton);