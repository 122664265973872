import React, { useState } from 'react';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import ContextEnhancer from 'components/ContextEnhancer';
import GroupRender, { ColorDot } from 'components/bng/pages/admin/structures/GroupRender';
import BngDropdownTags from 'components/bng/ui/BngDropdownTags';
import { isValidGroupName } from 'components/ui/group/GroupsDialog';
import useBimContext from 'components/hooks/useBimContext';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';

const BngDropdownTagsWrapper = ({ selectedGroupId, applyGroup, customButton = null }) => {
  const { msg, project } = useBimContext();
  const [groupOpts, setGroupOpts] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});

  const fetchGroups = async () => {
    try {
      const groups = await Api.Group.findForProject(project.id);
      const mappedGroups = groups.map((group) => ({
        value: `${group.id}`,
        label: group.name,
        group,
        selected: group.id === Number(selectedGroupId),
        render: () => <GroupRender {...group} />,
      }));
      setSelectedGroup(groups.filter((group) => group.id === selectedGroupId));
      setGroupOpts(mappedGroups);
    } catch (e) {
      UiMsg.error('Error', e);
    }
  };

  const createGroup = async (groupName) => {
    try {
      if (!isValidGroupName(groupName)) {
        // no emojis or whitespace-only strings allowed
        UiMsg.warn(msg.t('enter.valid.name'));
        return;
      }
      await Api.Group.createGroup(groupName, project.id);
      await fetchGroups();
    } catch (e) {
      UiMsg.error(null, e.response.data.message);
    }
  };

  const defaultCustomButton = ({ openDropdown }) => {
    return <BngIconButton icon="styles" onClick={openDropdown} className="selectedGroupRender" />;
  };

  return (
    <BngDropdownTags
      beforeOpen={fetchGroups}
      onCreate={createGroup}
      onApply={applyGroup}
      items={groupOpts}
      customButton={customButton || defaultCustomButton}
    />
  );
};

export async function fetchAndProcessGroups(projectId, msg) {
  let groups = await Api.Group.findForProject(projectId);
  groups = _.orderBy(groups, [(groups) => groups.name.toLowerCase()], ['asc']);
  groups.unshift({
    id: null,
    name: msg.t('no_tag'),
    color: '#000000',
  });
  groups = groups.map((group) => {
    const className = 'FilterByTag';
    return { ...group, className };
  });
  return FilterOpts({
    options: groups,
    hasIcon: true,
    customIcon: (obj) => <ColorDot color={obj.color} />,
    useIdAsValue: true,
  });
}

export default ContextEnhancer(BngDropdownTagsWrapper);
