import "./ManageEnvironment.css";

import React from "react";
import PropTypes from "prop-types";

import ContextEnhancer from "components/ContextEnhancer";
import MenuButton from "components/ui/navbar/MenuButton";
import CreateObjectMenuLink from "components/ui/navbar/CreateObjectMenuLink";
import StatsPanel from "components/ui/navbar/StatsPanel";
import Api from "components/Api";
import {localStore} from "components/Storage";
import Utils from 'components/Utils'

async function openMenu(link, context, disableCockpit = false) {
    await Api.Bng.loadProjectInfo(context.project.name, disableCockpit);
    window.location.replace(`${Api.baseUrl()}${link}`);
}

const menuOpts = (context) => {
    const itemsLeft = [];
    const itemsRight = [];
    itemsLeft.push({
        icon: 'group',
        title: context.msg.t('user.and.groups'),
        type: 'userandgroups',
        label: context.msg.t('user.group.information.label'),
        onClick: () => {
            openMenu('/spr/bng/project/users', context)
        }
    });

    itemsLeft.push({
        icon: 'folder',
        title: context.msg.t('folder.and.permissions'),
        type: 'folderandpermissions',
        label: context.msg.t('folder.and.permissions.label'),
        onClick: () => {
            openMenu('/spr/bng/project/folders', context)
        }
    });
    itemsLeft.push({
        icon: Utils.Object.getObjectIcon('cockpit'),
        title: context.msg.t('cockpits'),
        type: 'editcockpits',
        label: context.msg.t('cockpit.edit.label'),
        onClick: () => {
            openMenu(
                '/spr/bng/project/cockpits',
                context
            )
        }
    });
    itemsRight.push({
        icon: 'cloud_upload',
        title: context.msg.t('data.and.integration'),
        type: 'dataandintegration',
        label: context.msg.t('data.and.integration.label'),
        alert: context.projectLoadLimitAlert ? context.msg.t('inMemoryLoadLimitAlert.navbar.title') : undefined,
        onClick: () => {
            openMenu(
                '/pages/project-selection/dataconfig.iface',
                context
            )
        }
    });
    itemsRight.push({
        icon: 'settings',
        title: context.msg.t('settings'),
        type: 'editsettings',
        label: context.msg.t('config.label'),
        onClick: () => {
            openMenu('/spr/bng/project/management', context)
        }
    });

    itemsRight.push({
        icon: 'delete',
        title: context.msg.t('dump'),
        type: 'dumpdelete',
        label: context.msg.t('dump.label'),
        beta: false,
        onClick: () => {
            openMenu(
                '/spr/bng/project/dump',
                context,
                true
            )
        }
    });

    return {
        itemsLeft, itemsRight
    }

};

const LOCAL_STORE_KEY = 'ManageEnvironment:projectStatsCache';

class ManageEnvironment extends React.Component {

    static propTypes = {
        status: PropTypes.object,
        projectId: PropTypes.number
    };

    static defaultProps = {};

    state = {
        popperRefUserDropdown: null,
        openUserDropdown: false,
        popperRefObjectDropdown: null,
        openObjectDropdown: false,
        uservalues: {},
        status: {},
        loading: false,
        cachedStats: null,
    };

    fetchProjectStats = async (invalidateCache = false) => {
        this.setState({loading: true});
        try {
            const values = await Api.Navbar.projectStats(this.props.projectId, invalidateCache);
            const cachedStats = {
                projectId: this.props.projectId,
                projectStats: values,
                createdAt: Date.now(),
            };
            localStore.put(LOCAL_STORE_KEY, cachedStats);
            this.setState({cachedStats});
        } catch (e) {
            console.error("Error on fetchProjectStats()", {projectId: this.props.projectId}, e);
        } finally {
            this.setState({loading: false});
        }
    };

    validateLastUpdateCache = () => {
        let cachedStats = localStore.get(LOCAL_STORE_KEY);
        if (cachedStats) {
            const dayInMs = 86400000;
            const cacheDiffInMs = Date.now() - cachedStats.createdAt;
            if (cacheDiffInMs > dayInMs || this.props.projectId !== cachedStats.projectId) {
                cachedStats = null;
                localStore.remove(LOCAL_STORE_KEY)
            }
        }

        if (cachedStats) {
            this.setState({
                cachedStats,
                loading: false
            });
        } else {
            this.fetchProjectStats();
        }
    }

    render() {
        const alertMessage = this.props.context.projectLoadLimitAlert ? this.props.context.msg.t('inMemoryLoadLimitAlert.navbar.title') : undefined;
        let userMenu = menuOpts(this.props.context);
        const {cachedStats} = this.state;
        return (
            <MenuButton className="manage-environment-menu"
                        icon="security"
                        title={this.props.context.msg.t('detail_manage_environment')}
                        alert={alertMessage}
                        onOpen={() => {
                            this.validateLastUpdateCache();
                            return true;
                        }}
            >

                <li className="manage-environment-itens-container">

                    <StatsPanel projectStats={cachedStats?.projectStats}
                                lastUpdate={cachedStats?.createdAt}
                                loading={this.state.loading}
                                fetchProjectStats={() => this.fetchProjectStats(true)}
                    />

                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <div className="manage-environment-itens-container-div">
                                    {userMenu.itemsLeft.map((menuItem, i) => {
                                        return <CreateObjectMenuLink key={i} {...menuItem}/>;
                                    })}
                                </div>
                            </td>
                            <td>
                                <div className="manage-environment-itens-container-div">
                                    {userMenu.itemsRight.map((menuItem, i) => {
                                        return <CreateObjectMenuLink key={i} {...menuItem}/>;
                                    })}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </li>
            </MenuButton>
        );
    }
}

export default ContextEnhancer(ManageEnvironment);