import styles from './MarketplaceItemPage.module.css'

import { useSearchParams } from 'react-router-dom';

import BimStorePage from 'components/ui/bimstore/BimStorePage';
import useTranslation from 'components/hooks/useTranslation';
import useBimNavigate from 'components/hooks/useBimNavigate';
import BngIconButton from 'components/bng/ui/BngIconButton';

function MarketplaceTemplateItemPage() {
  const [searchParams] = useSearchParams();
  const templateName = searchParams.get('templateName');

  return (
    <div className="MarketplaceTemplateItemPage">
      <BimStorePage template={templateName} />
    </div>
  );
}

export function MarketplaceTemplateItemTab() {
  const { t } = useTranslation();
  const navigate = useBimNavigate();

  return (
    <div className={`MarketplaceTemplateItemTab ${styles.MarketplaceItemTab}`}>
      <BngIconButton
        icon={'arrow_back'}
        text={t('back_button')}
        onClick={() => {
          navigate(-1);
        }}
        className={styles.marketplaceItemBackButton}
      />
      <MarketplaceTemplateItemPage />
    </div>
  );
}
