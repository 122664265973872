import React from "react";
import PropTypes from "prop-types";
import "./BngAddButton.css";
import Icon from "components/ui/common/Icon";

export class BngAddButton extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        onClick: () => {},
        disabled: false,
    };

    render() {
        const { className, children, onClick, disabled, ...props } = this.props;

        return (
            <div
                className={`bng-add-button ${className} ${disabled ? "disabled" : ""}`}
                {...props}
                onClick={disabled ? undefined : onClick}
            >
                <Icon icon="add_circle" className="add-button" />
                {children}
            </div>
        );
    }
}