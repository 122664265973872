import './AdminMenuButton.css';
import ContextEnhancer from "components/ContextEnhancer";
import MenuButton from "components/ui/navbar/MenuButton";
import MenuLink from "components/ui/navbar/MenuLink";
import React from "react";
import Api from "components/Api";
import SchemaUpdaterDialog from "components/ui/mondrian/SchemaUpdaterDialog";
import EmailBlacklistDialog from "components/bng/email/blacklist/EmailBlacklistDialog";
import TwoFactorAuthConfigDialog from "components/bng/twofactorauth/TwoFactorAuthConfigDialog";
import ObjectExecMonitorDialog from "components/bng/objectExecMonitor/ObjectExecMonitorDialog";
import AccountManagerDialog from "components/bng/accountManager/AccountManagerDialog";
import UiMsg from "components/ui/UiMsg";

const menuItems = (context) => [
    {
        icon: 'icon-tasks',
        label: context.msg.t('monitoring'),
        link: `${context.contextPath}/pages/admin/current-usage.iface`
    },
    {
        icon: 'icon-cog',
        label: context.msg.t('header_menuItem_applicationSettings'),
        link: `${context.contextPath}/pages/admin/applicationsettings.iface`
    },
    {
        icon: 'storage',
        label: context.msg.t('manage_projects'),
        onClick: async () => {
            await Api.executeExp(`#{projectMB.crud.refreshPage()}`);
            window.location.href = `${context.contextPath}/pages/admin/projects.iface`;
        }
    },
    {
        icon: 'icon-group',
        label: context.msg.t('manage_users'),
        link: `${context.contextPath}/pages/admin/users.iface`
    },
    {
        icon: 'icon-briefcase',
        label: context.msg.t('manage.practice.profiles'),
        link: `${context.contextPath}/pages/admin/practice-profiles.iface`
    },
    {
        icon: 'icon-bug',
        label: context.msg.t('olap.servers'),
        link: `${context.contextPath}/pages/admin/olap-servers/manage.iface`
    },
    {
        icon: 'icon-thumbs-up',
        label: context.msg.t('manage.accounts'),
        link: `${context.contextPath}/pages/admin/accounts/manage.iface`
    },
    {
        icon: 'icon-ticket',
        label: context.msg.t('manage.plans'),
        link: `${context.contextPath}/pages/admin/plans.iface`
    },
    {
        icon: 'event',
        label: context.msg.t('manage.events'),
        link: `${context.contextPath}/pages/admin/events.iface`
    },
    {
        icon: 'icon-beaker',
        label: context.msg.t('features.labs'),
        link: `${context.contextPath}/pages/admin/features.iface`
    },
    {
        icon: 'dataset',
        label: context.msg.t('orphan.dw.tables'),
        link: `${context.contextPath}/pages/admin/orphan-dw-tables.iface`
    },
    {
        icon: 'icon-refresh',
        label: context.msg.t('clear.application.cache'),
        onClick: async () => {
            try {
                Api.invalidateTranslations();
                await Api.AppCache.clearAppCache();
                window.location.reload();
                UiMsg.ok(context.msg.t('app.cache.clean.success'));
            } catch (e) {
                console.error('Error while cleaning app cache', e);
                UiMsg.ajaxError(null, e);
            }
        }
    },
    {
        icon: 'icon-bug',
        label: 'Mondrian',
        onClick: () => window.ReduxStore.dispatch(window.Actions.MODALS.open(SchemaUpdaterDialog))
    },
    {
        icon: 'icon-ban-circle',
        label: context.msg.t('email.blacklist'),
        onClick: () => window.ReduxStore.dispatch(window.Actions.MODALS.open(EmailBlacklistDialog))
    },
    {
        icon: 'icon-lock',
        label: context.msg.t('two.factor.authentication'),
        onClick: () => window.ReduxStore.dispatch(window.Actions.MODALS.open(TwoFactorAuthConfigDialog))
    },
    {
        icon: 'monitor_heart',
        label: context.msg.t('ObjectExecMonitorDialog.title'),
        onClick: () => window.ReduxStore.dispatch(window.Actions.MODALS.open(ObjectExecMonitorDialog))
    },
    {
        icon: 'admin_panel_settings',
        label: context.msg.t('user.priority.tile'),
        onClick: () => window.ReduxStore.dispatch(window.Actions.MODALS.open(AccountManagerDialog))
    },
    {
        icon: 'psychology',
        label: context.msg.t('artificial.intelligence.ada.ai'),
        onClick: () => window.location.replace(Api.buildUrl('/spr/bng/ada/ai'))
    },
];


const AdminMenuButton = ({context}) => {
    return (
        <MenuButton className={`AdminMenuButton`}
                    icon="settings"
                    title={context.msg.t('application.management')}>
            <div className="scrollbar-macosx"
                 ref={ref => {
                     if (ref) {
                         j(ref).scrollbar();
                     }
                 }}>
                {
                    menuItems(context).map((menuItem, i) =>
                        <MenuLink key={i} {...menuItem}/>
                    )
                }
            </div>
        </MenuButton>
    );
}
export default ContextEnhancer(AdminMenuButton);