import React from "react";
import {connect} from "react-redux";
import {NAVBAR} from "components/ui/redux/Actions";
import PublisherMenuButton from "components/ui/navbar/PublisherMenuButton";


const mapStateToProps = (state) => {
    return {
        publications: state.navbar.publications
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        findPublications: () => dispatch(NAVBAR.findPublications())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublisherMenuButton);