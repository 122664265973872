"use strict";

import BimEventBus from 'BimEventBus';
import { Axios } from 'commonUtils';

const $ = jQuery;

export const PUBLISHER_FULL_INTERACTION_EVENT = 'PublisherFull:Interaction';

class PublisherApi {

    static PUBLISHER_FULL_INTERACTION_EVENT = PUBLISHER_FULL_INTERACTION_EVENT;

    static findByPath(path) {
        const params = $.param({path});
        return $.getJSON(`/spr/publisher-rest?${params}`);
    }

    static publishSelectedItems(PublisherObjectsItems) {
        return Promise.resolve($.postJSON(`/spr/publisher-rest/createnew`, PublisherObjectsItems));
    }

    static getAllPublishers(maxresults, initial) {
        return Promise.resolve($.getJSON(`/spr/publisher-rest/paginate`, {maxresults, initial}));
    }

    static getAllPublishedPaths() {
        return Promise.resolve($.getJSON(`/spr/publisher-rest/getAllPaths`));
    }

    static getPublisherByPath(path) {
        return Promise.resolve($.getJSON(`/spr/publisher-rest/getinformationbypath`, {path}));
    }

    static removePublisher(id) {
        return Promise.resolve($.postJSON(`/spr/publisher-rest/remove/${id}`));
    }

    static savePublisher(config) {
        return Promise.resolve($.postJSON(`/spr/publisher-rest/saveconfig`, config));
    }

    static getAnalysisView(path) {
        return Promise.resolve($.getJSON(`/spr/publisher-rest/getviewanalysis`, {path}));
    }

    static pathIsPublished(path) {
        const params = $.param({path});
        return Promise.resolve(
            $.getJSON(`/spr/publisher-rest/isPublished?${params}`)
        );
    }

    static registerInteraction = (requestId = 0,
                                  data = { source: '' }) => {
        return Promise.resolve(
          $.postJSON('/spr/publisher-rest/interaction', {
              requestId,
              ...data
          })
        );
    };

    static enableInteractionListener(requestId = 0) {
        BimEventBus.on(PUBLISHER_FULL_INTERACTION_EVENT, function(data) {
            PublisherApi.registerInteraction(requestId, data);
        });
    }

    static async findRequestsForAccount(accountId = 0) {
        const {data} = await Axios.get(`/spr/publisher-rest/full/requests`, {
            params: {
                accountId
            }
        });
        return data;
    }

    static async updateConfig(path = '', config = {}) {
        return await Axios.post(`/spr/publisher-rest/config`, {path, config});
    }

    static async interactionsForPath(path) {
        const {data} = await Axios.get(`/spr/publisher-rest/interactions`, {
            params: {
                path
            }
        });
        return data;
    }

}

export default PublisherApi;