import './Checkbox.css';
import React from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.object,
        onChange: PropTypes.func,
        selected: PropTypes.bool,
        disabled: PropTypes.bool,
        style: PropTypes.object,
    };

    static defaultProps = {
        style: {}
    };

    render() {
        const {name, disabled, value, onChange, selected, className = ''} = this.props;
        const disableInput = disabled ? disabled : value && value.disabled;
        return (
            <div className={`NoFormCheckbox checkbox ${className} ${disableInput ? 'Disabled' : ''}`} style={this.props.style}>
                <label>
                    <input type="checkbox" className="ace"
                           name={name}
                           disabled={disableInput}
                           checked={selected}
                           onChange={onChange}
                    />
                    <span className="lbl">{value && value.label}</span>
                </label>
            </div>
        );
    }

};