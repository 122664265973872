import './BimIntegrationEulaDialog.css';

import React, { useEffect, useRef, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import DataOriginsDialog from 'components/ui/in-memory/DataOriginsDialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';

export default function BimIntegrationEulaDialog({ closeModal, onAgreement }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [check, setChecked] = useState(false);
  const [hasRead, setHasRead] = useState(true);
  const [terms, setTerms] = useState('');
  const bodyRef = useRef();

  const fetchTermsForLocale = async () => {
    const onEmptyEula = () => {
      onAgreement({ closeModal });
    };

    const preferredLanguage = context.user?.preferredLanguage;
    try {
      const { eula } = await Api.News.termsForLocale(preferredLanguage);
      if (eula) {
        setTerms(eula);
        setHasRead(false);
      } else {
        onEmptyEula();
      }
    } catch (e) {
      console.error(`Error on BimIntegrationEulaDialog.fetchTermsForLocale for language=[${preferredLanguage}]`, e);
      onEmptyEula();
    }
  };

  useEffect(() => {
    fetchTermsForLocale();
  }, []);

  const handleScroll = () => {
    const body = bodyRef.current;
    const bottom = body.scrollHeight - body.scrollTop === body.clientHeight;
    if (bottom) {
      setHasRead(true);
    } else if (body.scrollHeight === 0) {
      setHasRead(true);
    }
  };

  return (
    <Dialog
      title={context.msg.t('BimIntegrationEulaDialog.title')}
      className="BimIntegrationEulaDialog"
      onClose={closeModal}
      newDialogLayout={true}
      contentFullWidth={true}
      draggable={false}
    >
      <div className="Body">
        <div className="Container" ref={bodyRef} onScroll={handleScroll}>
          <span className="SpanText">
            <div className="ImageWrapper">
              <img src={`${Api.baseUrl()}/images/logo-new-dark.png`} alt={'Logo BIMachine'} />
            </div>
            <div
              style={{ whiteSpace: 'break-spaces', marginBottom: '25px' }}
              className="mt-2"
              dangerouslySetInnerHTML={{ __html: terms }}
            />
          </span>
        </div>
      </div>

      <Dialog.Footer>
        <div className="flex-center-items jc-center">
          <span key={hasRead ? 'read' : 'notRead'} style={{ width: '30%' }}>
            <BngCheckbox
              label={context.msg.t('agree.with.terms.label')}
              field={{ value: check, onChange: () => setChecked(!check) }}
              // disabled={!hasRead}
              // title={!hasRead ? context.msg.t('terms.read.until.bottom') : undefined}
            />
          </span>
          <DefaultDialogActions
            closeModal={() => {
              closeModal();
              dispatch(
                MODALS.open(DataOriginsDialog, {
                  projectId: context.project.id,
                })
              );
            }}
            context={context}
            okLabel={'continue'}
            title={context.msg.t('agree.with.terms.to.continue')}
            disabled={!check}
            onClickSaveButton={() => {
              onAgreement({ closeModal });
            }}
          />
        </div>
      </Dialog.Footer>
    </Dialog>
  );
}
