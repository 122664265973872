import "./CreateObjectMenuLink.css";

import React, {Component} from "react";
import PropTypes from "prop-types";

import BetaLabel from "components/BetaLabel";
import {BngIsNewLabel} from "components/bng/ui/BngHorizontalCard";
import Icon from "components/ui/common/Icon";

class CreateObjectMenuLink extends Component {

    static propTypes = {
        link: PropTypes.string,
        type: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.string,
        iconOutlined: PropTypes.bool,
        target: PropTypes.string,
        onClick: PropTypes.func,
        alert: PropTypes.string,
        beta: PropTypes.bool,
        title: PropTypes.string,
        linkhelp: PropTypes.string,
        linkhelpinformation: PropTypes.string,
        showlinkinformation: PropTypes.bool,
        material: PropTypes.bool,
        isNew: PropTypes.bool,
        isNewLabel: PropTypes.string,
    };

    static defaultProps = {
        link: '#',
        type: '',
        label: 'LABEL HERE',
        icon: '',
        iconOutlined: false,
        alert: undefined,
        beta: false,
        title: '',
        linkhelp: '',
        linkhelpinformation: '',
        showlinkinformation: true,
        material: false,
        isNew: false,
        isNewLabel: undefined,
    };

    constructor(props) {
        super(props);
    }

    clickOnLink (e) {
        e.stopPropagation();
    }

    render() {
        return (
            <div className={`create-object-item-container ${this.props.type}`} onClick={this.props.onClick}>
                <div className="menu_sttings">
                    <div>
                        <Icon icon={this.props.icon} outlined={this.props.iconOutlined}/>
                        {this.props.beta &&
                        <BetaLabel/>
                        }
                        <span className="title-type-object">{this.props.title}</span>
                        {this.props.alert &&
                        <div className="MenuAlertContainer" title={this.props.alert}>
                            <Icon icon="error"/>
                        </div>
                        }
                        {this.props.isNew &&
                        <div style={{float: 'right'}}>
                            <BngIsNewLabel text={this.props.isNewLabel} />
                        </div>
                        }

                    </div>
                    <div className="description-type-object">{this.props.label}</div>
                </div>
                {!!this.props.linkhelp &&
                <div className="linkhelp-type-object">
                    <a href={this.props.linkhelp} onClick={this.clickOnLink} target="_blank" rel="noreferrer">{this.props.linkhelpinformation}</a>
                </div>
                }
            </div>
        );
    }
}


export default CreateObjectMenuLink;