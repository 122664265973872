import {Axios} from "commonUtils";

export default class LoadLimitApi {

    async getGlobalLimits() {
        const {data} = await Axios.get(`/spr/load-limit/global`);
        return data;
    }

    async getAccountLimits(accountId) {
        const {data} = await Axios.get(`/spr/load-limit/account/${accountId}`);
        return data;
    }

    async save(accountId, loadLimits) {
        const {data} = await Axios.post(`/spr/load-limit/save`, {accountId, loadLimits});
        return data;
    }

    async delete(accountId) {
        const {data} = await Axios.delete(`/spr/load-limit/delete/${accountId}`);
        return data;
    }

}
