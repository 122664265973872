import ReactDOM from 'react-dom/client';

export const renderReactComponent = (component, container) => {
    if (container.__renderReactComponentRoot) {
        container.__renderReactComponentRoot.unmount();
        delete container.__renderReactComponentRoot;
    }

    const reactRoot = ReactDOM.createRoot(container);
    container.__renderReactComponentRoot = reactRoot;

    const root = {
        unmount() {
            reactRoot.unmount();
            delete container.__renderReactComponentRoot;
        },
    };

    if (_.isFunction(component)) {
        component = component({ root, container });
    }

    reactRoot.render(component);

    return root;
};
