import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import BreadcrumbToolbar from './BreadcrumbToolbar';

class MobileToolbarItem extends PureComponent {

    static propTypes = {
        path: PropTypes.string,
        inactive: PropTypes.bool,
        disabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        path: '',
        inactive: false,
        disabled: false,
        onClick: _.noop
    };

    state = {};

    constructor(props) {
        super(props);
    }

    render() {
        let {path, ...props} = this.props;
        if (!this.props.context.permissions.isAtLeastExplorer()
            || !this.props.context.permissions.isPathWritable(path)) {
            return null;
        }
        return (
            <BreadcrumbToolbar.Item icon="phone_android"
                                    title={this.props.context.msg.t('toggle.mobile.title')}
                                    {...props}
            />
        );
    }
}

export default ContextEnhancer(MobileToolbarItem);
