import React, {Component} from 'react';
import PropTypes from "prop-types";
import ContextEnhancer from "../../ContextEnhancer";
import {BngTable} from "../ui/BngTable";

class ApplicationKeysTab extends Component {

    static propTypes = {
        projects: PropTypes.array,
    };

    static defaultProps = {
        projects: []
    };

    state = {

    };

    projectsColumns = [
        {label: this.props.context.msg.t('id'), render: row => row.id},
        {label: this.props.context.msg.t('name'), render: row => row.name},
        {label: this.props.context.msg.t('caption'), render: row => row.caption},
        {label: this.props.context.msg.t('type'), render: row => this.renderProjectType(row.projectType)},
    ];

    renderProjectType = (projectType) => {
        return (
            <div className={`badge ${projectType.badge}`}>
                {this.props.context.msg.t(projectType.type)}
            </div>
        )
    };

    render(){
        return (
            <div className="projects-tab">
                <BngTable cols={this.projectsColumns} rows={this.props.projects}/>
            </div>
        )
    }
}

export default ContextEnhancer(ApplicationKeysTab);