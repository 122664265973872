import style from './BngAlert.module.css';

import React from 'react';

const BngAlert = ({ children, className = '', ...props }) => {
  return (
    <div className={`BngAlert ${style.main} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default BngAlert;
