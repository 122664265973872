import styles from './CustomHeader.module.css';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Icon from 'components/ui/common/Icon';

export default class CustomHeader extends Component {
  static propTypes = {
    column: PropTypes.any,
    api: PropTypes.any,
    enableSorting: PropTypes.bool,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string,
    displayName: PropTypes.string,
  };

  state = {
    sort: null,
    filtering: false,
    hover: false,
  };

  getHeaderAlignStyle() {
    if (this.props.column.colDef.custom) {
      switch (this.props.column.colDef.custom.align) {
        case 'left':
          return 'flex-start';
        case 'right':
          return 'flex-end';
        case 'center':
          return 'center';
      }
    } else {
      return 'flex-start';
    }
  }

  componentDidMount() {
    this.props.column.addEventListener('sortChanged', this.onSortChanged);
    this.props.api.addEventListener('filterChanged', this.onFilterChanged);
    this.onSortChanged();
    const isColumnFiltered = Object.keys(this.props.api.getFilterModel()).find(
      (key) => key === this.props.column.colId
    );
    this.setState({ filtering: isColumnFiltered });
  }

  componentWillUnmount() {
    this.props.column.removeEventListener('sortChanged', this.onSortChanged);
    this.props.api.removeEventListener('filterChanged', this.onFilterChanged);
  }

  onFilterChanged = (ev) => {
    const isColumnFiltered = Object.keys(ev.api.getFilterModel()).find((key) => key === this.props.column.colId);
    this.setState({ filtering: isColumnFiltered });
  };

  render() {
    const { hover, filtering } = this.state;
    const wrapHeader = this.props.column.colDef.wrapHeaderText;

    let sort = null;
    if (this.props.enableSorting) {
      if (this.state.sort === 'asc') {
        sort = (
          <span
            ref="eSortAsc"
            className="ag-header-icon ag-header-label-icon ag-sort-ascending-icon"
            aria-hidden="true"
          >
            <span
              className="ag-icon ag-icon-asc"
              style={{ fontSize: '18px' }}
              unselectable="on"
              title={this.props.context.msg.t('sort')}
            ></span>
          </span>
        );
      } else if (this.state.sort === 'desc') {
        sort = (
          <span
            ref="eSortDesc"
            className="ag-header-icon ag-header-label-icon ag-sort-descending-icon"
            aria-hidden="true"
          >
            <span
              className="ag-icon ag-icon-desc"
              style={{ fontSize: '18px' }}
              unselectable="on"
              title={this.props.context.msg.t('sort')}
            ></span>
          </span>
        );
      }
    }

    return (
      <div className={`${styles.HeaderWrapper}`}>
        <div
          className="ag-cell-label-container ag-header-cell-sorted-asc"
          role="presentation"
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          style={{ backgroundColor: this.props.backgroundColor }}
          onClick={this.onSortRequested}
          onTouchEnd={this.onSortRequested}
        >
          <div
            ref="eLabel"
            className="ag-header-cell-label"
            role="presentation"
            unselectable="on"
            style={{
              color: this.props.fontColor,
              justifyContent: this.getHeaderAlignStyle(),
            }}
          >
            <span
              ref="eText"
              className="ag-header-cell-text"
              style={{
                whiteSpace: !wrapHeader ? 'break-spaces' : 'nowrap',
                wordBreak: !wrapHeader ? 'break-word' : 'unset',
                display: !wrapHeader ? '-webkit-box' : '',
                WebkitLineClamp: !wrapHeader ? '2' : '',
                WebkitBoxOrient: !wrapHeader ? 'vertical' : '',
              }}
              role="columnheader"
              unselectable="on"
              title={this.props.displayName}
            >
              {this.props.displayName}
            </span>
            {sort}
          </div>
        </div>
        {this.props.enableMenu && (
          <span
            ref="eMenu"
            className="ag-header-icon ag-header-cell-menu-button"
            aria-hidden="true"
            style={{ opacity: 1, marginLeft: '5px' }}
            title={this.props.context.msg.t('filter')}
          >
            <span ref={(menuButton) => (this.menuButton = menuButton)} onClick={this.onMenuClicked}>
              <Icon style={{ fontSize: '18px', color: this.props.fontColor }} icon="filter_alt" outlined={!filtering} />
            </span>
          </span>
        )}
      </div>
    );
  }

  onMenuClicked = (e) => {
    e.stopPropagation();
    this.props.showColumnMenu(this.menuButton);
  };

  onSortChanged = () => {
    let sort = null;

    if (this.props.column.isSortAscending()) {
      sort = 'asc';
    }

    if (this.props.column.isSortDescending()) {
      sort = 'desc';
    }

    this.setState({ sort: sort });
  };

  onSortRequested = (event) => {
    let sort = this.state.sort;

    if (!this.props.enableSorting) {
      return;
    }

    if (sort === null) {
      this.props.setSort('asc', event.shiftKey);
    } else if (sort === 'asc') {
      this.props.setSort('desc', event.shiftKey);
    } else {
      this.props.setSort(null, event.shiftKey);
    }
  };
}
