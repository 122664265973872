import React, { useMemo, useRef, useState } from 'react';
import Utils from 'components/Utils';
import { useEventListener } from 'components/hooks/useEventListener';

export default function useQueryParams({ listenToEvents = false } = {}) {
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const $href = useRef(window.location.href);

  const deps = [];

  if (listenToEvents) {
    useEventListener(Utils.History.EVENT_NAME, () => {
      if (window.location.href === $href.current) {
        return;
      }

      $href.current = window.location.href;
      setLastUpdate(Date.now());
    });

    deps.push(lastUpdate);
  }

  return useMemo(() => {
    return {
      queryParams: Utils.History.currentUrlSearchParams(),
      href: $href.current,
      lastUpdate,
    };
  }, deps);
}
