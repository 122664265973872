import styles from './CheckupHeader.module.css';

import React from 'react';

export default function CheckupHeader({ className = '', title = '', description = '' }) {
  return (
    <div className={`CheckupHeader ${styles.headerContainer} ${className}`}>
      <span className={`${styles.title}`}>{title}</span>
      <span className={`Description ${styles.desc}`} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}
