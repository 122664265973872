import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import BreadcrumbToolbar from './BreadcrumbToolbar';
import {connect} from "react-redux";
import {MODALS} from "components/ui/redux/Actions";
import UiMsg from "../UiMsg";
import InformationDialog from "components/ui/common/InformationDialog";

class InformationToolbarItem extends PureComponent {

    static propTypes = {
        path: PropTypes.string,
        dataSourceCaption: PropTypes.string,
        title: PropTypes.string,
    };

    static defaultProps = {
        path: '',
        title: ''
    };

    state = {
        loading: false,
    };

    constructor(props) {
        super(props);
    }

    openDialog = async (event) => {
        this.setState({loading: true});
        try {
            const information = await Api.Bng.getObjectInformation(this.props.path);
            const dataSource = this.props.dataSourceCaption ? await Api.Project.cubeCaption({cube: this.props.dataSourceCaption}) : null;
            let lastDataUpdate = !!dataSource ? (await Api.Project.findLastUpdate(this.props.path)).lastUpdate : null;

            information.dataSource = dataSource;
            information.dataSourceCaption = this.props.dataSourceCaption;
            information.lastDataUpdate = lastDataUpdate;

            this.props.openInfoDialog(information, this.props.path, this.props.title);
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    render() {
        const {path, title, ...props} = this.props;

        return (
            <BreadcrumbToolbar.Item {...props}
                                    loading={this.state.loading}
                                    icon="info_outline"
                                    onClick={this.openDialog}
                                    title={this.props.context.msg.t('analysis_info')}
            />
        );
    }
}

export default connect(
    () => ({}),
    dispatch => ({
        openInfoDialog(information, path, title) {
            dispatch(
                MODALS.open(InformationDialog, {
                    ...information,
                    path,
                    title
                })
            );
        },
    })
)(ContextEnhancer(InformationToolbarItem));
