import './AccountManagerDialog.css';

import React, { useMemo } from 'react';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import { useLoadingListener } from 'components/bng/pages/newAnalysis/BngEnableAdvancedModeButton';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import OpConfirmation from 'components/ui/OpConfirmation';
import UiMsg from 'components/ui/UiMsg';
import { BngForm } from 'components/bng/form/BngForm';
import { BngField } from 'components/bng/form/BngField';
import Button from 'components/ui/Button';
import { bngYup } from 'components/bng/form/yup/BngYup';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import useTranslation from 'components/hooks/useTranslation';

const AccountManagerSchema = bngYup((yup) =>
  yup.object({
    email: yup.string().email().required().default(''),
  })
);

export default function AccountManagerDialog({ closeModal }) {
  const { t } = useTranslation();
  const { loading, listener } = useLoadingListener();
  const $accountManagers = useFetchData(async () => await Api.AccountManager.findAll());

  const cols = useMemo(() => {
    return [
      { label: t('email'), render: (row) => row },
      {
        size: '65px',
        render: (row) => (
          <BngIconButton
            icon={'delete'}
            onClick={() => {
              OpConfirmation({
                title: t('attention'),
                message: t('delete.confirmation.message'),
                onConfirm: async () => {
                  try {
                    await listener(Api.AccountManager.delete(row));
                    UiMsg.ok(t('account.manager.delete'));
                    $accountManagers.reload();
                  } catch (e) {
                    console.error(e);
                    UiMsg.ajaxError(null, e);
                  }
                },
              });
            }}
          />
        ),
      },
    ];
  }, []);

  return (
    <Dialog
      contentFullWidth={true}
      className="AccountManagerDialog large"
      title={t('user.priority.tile')}
      loading={loading || $accountManagers.isLoading}
      onClose={closeModal}
      newDialogLayout
    >
      <Dialog.Body>
        <div className="row-fluid mb-3">
          <Formik
            initialValues={AccountManagerSchema.default()}
            validationSchema={AccountManagerSchema}
            onSubmit={async (values, formikHelpers) => {
              await listener(async () => {
                try {
                  await Api.AccountManager.promoteToAccountManager(values.email);
                  UiMsg.ok(t('account.manager.success'));
                  formikHelpers.resetForm(AccountManagerSchema.default());
                  $accountManagers.reload();
                } catch (e) {
                  console.error(e);
                  UiMsg.ajaxError(t('account.manager.error'), e);
                }
              });
            }}
          >
            {() => {
              return (
                <BngForm>
                  <div className="d-flex jc-end">
                    <div style={{ width: '30%' }}>
                      <Field
                        id="priorityEmail"
                        name="email"
                        as={BngField}
                        rootClassName="m-0-force"
                        inline
                        showErrors={false}
                        placeholder="Digite um e-mail válido"
                      />
                    </div>
                    <Button type="submit" icon="add" className="ml-2" />
                  </div>
                </BngForm>
              );
            }}
          </Formik>
        </div>
        <div className="scroll-bar-tfa-table-wrapper">
          <BngTable
            rows={$accountManagers.data ?? []}
            cols={cols}
            className={'table-condensed scrollable'}
            stickyHeader={true}
          />
        </div>
      </Dialog.Body>
    </Dialog>
  );
}
