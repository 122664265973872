import './WizardDialog.css';
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/ui/Dialog';
import ContextEnhancer from 'components/ContextEnhancer';
import Button, { BngCancelButton } from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';

function WizardSteps({ steps = [], activeStep = 0 }) {
  let stepNumber = 0;

  return (
    <div className="WizardSteps">
      {steps.map((el, idx) => {
        if (el.infoOnly) return null;

        let status = 'Todo';
        const stepStyle = {};
        if (idx < activeStep) {
          status = 'Done';
          const distance = activeStep - idx;
          const brighten = Math.min(distance / 2, 2.5);
          stepStyle.backgroundColor = chroma('#3558EF').brighten(brighten).toString();
        } else if (idx === activeStep) {
          status = 'Active';
        }
        stepNumber++;
        return <WizardStep key={idx} step={stepNumber} title={el.title} status={status} stepStyle={stepStyle} />;
      })}
    </div>
  );
}

function WizardStep({ step, title, status, stepStyle = {} }) {
  const isDone = status === 'Done';
  return (
    <div className={`WizardStep ${status}`} style={stepStyle}>
      <div className="WizardStep--step" style={stepStyle}>
        {isDone && <Icon icon="check" />}
        {!isDone && <span>{step}</span>}
      </div>
      <div className="WizardStep--title">{title}</div>
    </div>
  );
}

class WizardDialog extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    closeModal: PropTypes.func,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        dialogTitle: PropTypes.string,
        title: PropTypes.string,
        render: PropTypes.func,
        onNextStep: PropTypes.func,
        infoOnly: PropTypes.bool,
        disableNext: PropTypes.bool,
        nextButtonLabel: PropTypes.string,
        dialogClassName: PropTypes.string,
        actionBtnClassName: PropTypes.string,
      })
    ),
    dialogProps: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    title: '',
    open: true,
    loading: false,
    closeModal: _.noop,
    steps: [],
    dialogProps: {},
  };

  state = {
    currentStepIdx: 0,
  };

  onPrevStep = () => {
    if (this.state.currentStepIdx === 0) {
      return;
    }
    this.setState({ currentStepIdx: this.state.currentStepIdx - 1 });
  };

  onNextStep = async () => {
    try {
      const currentStepIdx = this.state.currentStepIdx;
      const isLastStep = currentStepIdx === this.props.steps.length - 1;

      const currentStep = this.props.steps[currentStepIdx];

      if (currentStep.hasOwnProperty('onNextStep')) {
        const result = await currentStep.onNextStep({
          currentStepIdx,
          closeModal: this.props.closeModal,
        });
        if (!isLastStep) {
          let goToNextStep = _.isBoolean(result) ? result : result ? result.goToNextStep : true;
          if (!goToNextStep) {
            return;
          }
        }
      }

      if (!isLastStep) {
        this.setState({ currentStepIdx: currentStepIdx + 1 });
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { closeModal, context, steps, open, className } = this.props;
    const currentStep = steps[this.state.currentStepIdx];
    const isFirstStep = this.state.currentStepIdx === 0;
    const isLastStep = this.state.currentStepIdx === steps.length - 1;
    return (
      <Dialog
        open={open}
        loading={this.props.loading}
        className={`WizardDialog ${className} ${currentStep.dialogClassName ? currentStep.dialogClassName : ''}`}
        newDialogLayout={true}
        titleComponent={() => (
          <div className="WizardStepsContainer">
            <h5>{currentStep?.dialogTitle || this.props.title}</h5>
            {!currentStep.infoOnly && <WizardSteps steps={steps} activeStep={this.state.currentStepIdx} />}
          </div>
        )}
        onClose={currentStep.preventClose ? _.noop : closeModal}
        {...this.props.dialogProps}
      >
        <Dialog.Body>{currentStep.render()}</Dialog.Body>

        <Dialog.Footer>
          <div className="flex-center-items">
            <BngCancelButton onClick={closeModal}>{context.msg.t('cancel')}</BngCancelButton>

            <div className="ml-auto flex-center-items">
              {!isFirstStep && (
                <BngCancelButton className={`ml-2`} type="submit" disabled={isFirstStep} onClick={this.onPrevStep}>
                  {context.msg.t('previous')}
                </BngCancelButton>
              )}

              <Button
                className={`${currentStep.actionBtnClassName ?? 'BngPrimaryButton'} ml-2`}
                onClick={this.onNextStep}
                disabled={currentStep.disableNext}
              >
                {currentStep.nextButtonLabel || context.msg.t(isLastStep ? 'finish' : 'next')}
              </Button>
            </div>
          </div>
        </Dialog.Footer>
      </Dialog>
    );
  }
}

export default ContextEnhancer(WizardDialog);
