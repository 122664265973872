import { connect } from 'react-redux';
import { MENU } from 'components/ui/redux/Actions';
import ObjectSearch from 'components/ui/navbar/ObjectSearch';

const mapStateToProps = (state) => {
  return {
    items: state.menu.items,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMenuItems: () => dispatch(MENU.findItems())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ObjectSearch);