import './DashAddObjectMenu.css';

import React, { useState } from 'react';

import AccordionList from 'components/ui/AccordionList';
import Utils from 'components/Utils';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import RightMenuBreadcrumb from 'components/ui/right-menu/RightMenuBreadcrumb';

function OpSelection({
  breadcrumb,
  setBreadcrumb,
  onSelectExisting,
  createLabel,
  selectExistingLabel,
  createComponent: CreateComponent,
}) {
  const items = [
    {
      icon: 'add_circle',
      className: 'CreateNewObjectButton',
      label: createLabel,
      onClick: () => {
        setBreadcrumb([
          ...breadcrumb,
          {
            label: createLabel,
            render: ({ onClose }) => {
              return <CreateComponent onClose={onClose} />;
            },
          },
        ]);
      },
    },
    {
      icon: 'folder_open',
      className: 'SelectExistingObjectButton',
      label: selectExistingLabel,
      onClick: onSelectExisting,
    },
  ];

  return (
    <div className="p-3">
      <ul className="ListItemDashboard gap-1">
        {items.map((item, index) => {
          return (
            <li key={index} onClick={item.onClick} className={item.className}>
              <Icon icon={item.icon} />
              <div className="ItemDashboardDescription">{item.label}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default function DashAddObjectMenu({
  onClose = _.noop,
  label,
  opSelectionProps = {
    onSelectExisting: _.noop,
    createLabel: '',
    selectExistingLabel: '',
    createComponent: _.noop,
  },
}) {
  const { msg } = useBimContext();

  const [breadcrumb, setBreadcrumb] = useState([
    {
      key: 'Dashboard',
      icon: Utils.Object.getObjectIcon('dashboard'),
      label: msg.t('dashboard'),
      render: _.noop,
      onClick: onClose,
    },
    {
      key: 'OpSelection',
      label,
      render: () => {
        return <OpSelection breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} {...opSelectionProps} />;
      },
      onClick: () => setBreadcrumb(breadcrumb.slice(0, 2)),
    },
  ]);

  const currentElement = breadcrumb.at(-1);

  return (
    <AccordionList className="DashAddObjectMenu ObjectRightMenuAccordion AddItemToDashboard">
      <RightMenuBreadcrumb
        items={breadcrumb}
        onClose={onClose}
        onBack={() => {
          // If type is already selected go back to type selection screen
          if (!!document.querySelector('.BngAnalysisTypeSelected')) {
            bimEventBus.emit('BngAnalysisTypeSelected:goBack');
          } else {
            setBreadcrumb(breadcrumb.slice(0, -1));
          }
        }}
      />
      <div className="DashAddObjectMenuContent">{currentElement.render({ onClose })}</div>
    </AccordionList>
  );
}
