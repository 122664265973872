import './StyleMenu.css';

import React from 'react';

import ContextEnhancer from "components/ContextEnhancer";
import LineColorPicker from "components/bng/pages/bigTable/components/LineColorPicker";
import BngCheckbox from "components/bng/form/BngCheckbox";
import {BngSelectSearch} from "components/bng/form/BngSelectSearch";
import {backgroundColors} from "components/bng/pages/bigTable/constants";
import {pSBC, rgbToYIQ} from "components/bng/pages/bigTable/DefaultRenderer";

const StyleMenu = ContextEnhancer(
    class StyleMenuInner extends React.PureComponent {

        state = {};

        async componentDidMount() {
        }

        static getDerivedStateFromError(error) {
            return {hasError: true};
        }

        componentDidCatch(error, errorInfo) {
            console.error(error, errorInfo);
        }

        onGridConfigChange(field, value) {
            let next = {
                ...this.props.gridConfig,
                [field]: value
            };
            this.props.onGridConfigChange(next);
        }

        onTitleConfigChange(field, value) {
            let next = {
                ...this.props.titleConfig,
                [field]: value
            };
            this.props.onTitleConfigChange(next);
        }

        onHighlightColorChange(value) {
            let next = {
                ...this.props.gridConfig,
                headerBackgroundColor: value,
                headerFontColor: rgbToYIQ(value) >= 166 ? '#000000' : '#FFFFFF',
                selectedBackgroundColor: value,
                selectedTextColor: rgbToYIQ(value) >= 166 ? '#000000' : '#FFFFFF',
                pinnedColumnBackgroundColor: value,
                pinnedColumnStrippedBackgroundColor: pSBC(0.3, value)
            };
            this.props.onGridConfigChange(next);
        }

        onFontSizeChange(value) {
            let gridConfig = {
                ...this.props.gridConfig,
                generalFontSize: value
            };
            let totalizerConfig = {...this.props.totalizerConfig}
            let titleConfig = {...this.props.titleConfig};

            if (value === 'small') {
                gridConfig.fontSize = '11';
                gridConfig.rowHeight = '20';
                gridConfig.headerFontSize = '12';
                gridConfig.headerHeight = '20';
                titleConfig.titleFontSize = '16';
                titleConfig.descriptionFontSize = '10';
                totalizerConfig.fontSize = '12'
            } else if (value === 'medium') {
                gridConfig.fontSize = '15';
                gridConfig.rowHeight = '30';
                gridConfig.headerHeight = '30';
                gridConfig.headerFontSize = '14';
                titleConfig.titleFontSize = '20';
                titleConfig.descriptionFontSize = '12';
                totalizerConfig.fontSize = '14'
            } else if (value === 'big') {
                gridConfig.fontSize = '20';
                gridConfig.rowHeight = '40';
                gridConfig.headerHeight = '40';
                gridConfig.headerFontSize = '20';
                titleConfig.titleFontSize = '24';
                titleConfig.descriptionFontSize = '14';
                totalizerConfig.fontSize = '20'
            }

            this.props.onGridConfigChange(gridConfig);
            this.props.onTitleConfigChange(titleConfig);
            this.props.onTotalizerConfigChange(totalizerConfig)
        }

        getFontSizeOptions() {
            return [
                {value: 'small', label: this.props.context.msg.t('size.small')},
                {value: 'medium', label: this.props.context.msg.t('size.medium')},
                {value: 'big', label: this.props.context.msg.t('size.big')}
            ];
        }

        render() {
            const {titleConfig, gridConfig} = this.props;
            return (
                <div className="BngBigTable__StyleMenu">

                    <div className="BngForm">

                        <LineColorPicker
                            label={this.props.context.msg.t('highlight_color')}
                            options={backgroundColors}
                            showCustom={true}
                            value={gridConfig.headerBackgroundColor}
                            onChange={(t) => this.onHighlightColorChange(t)}
                        />

                        <BngCheckbox
                            label={this.props.context.msg.t('highlight_stripped_lines')}
                            field={{value: gridConfig.stripped}}
                            onChange={_ => this.onGridConfigChange('stripped', !gridConfig.stripped)}
                        />

                        <BngCheckbox
                            label={this.props.context.msg.t('auto_width')}
                            field={{value: gridConfig.sizeToFit}}
                            onChange={_ => this.onGridConfigChange('sizeToFit', !gridConfig.sizeToFit)}
                        />

                        <BngCheckbox
                            label={this.props.context.msg.t('header_filters')}
                            field={{value: gridConfig.headerFilters}}
                            onChange={_ => this.onGridConfigChange('headerFilters', !gridConfig.headerFilters)}
                        />

                        <BngCheckbox
                            label={this.props.context.msg.t('enable_text_slicing')}
                            field={{value: gridConfig.textSlice}}
                            onChange={_ => this.onGridConfigChange('textSlice', !gridConfig.textSlice)}
                        />

                        <BngCheckbox
                            label={this.props.context.msg.t('highlight_pinned_columns')}
                            field={{value: gridConfig.highlightPinnedColumns}}
                            onChange={_ => this.onGridConfigChange('highlightPinnedColumns', !gridConfig.highlightPinnedColumns)}
                        />

                        <label className="control-label"
                               style={{marginTop: 12}}>{this.props.context.msg.t('table_options')}</label>
                        <div className="BngBigTable__columnsForm__grid">
                            <div className="w-45">
                                <BngCheckbox
                                    label={this.props.context.msg.t('title')}
                                    field={{value: titleConfig.showTitle}}
                                    onChange={_ => this.onTitleConfigChange('showTitle', !titleConfig.showTitle)}
                                />
                            </div>
                            <div className="w-45">
                                <BngCheckbox
                                    label={this.props.context.msg.t('description')}
                                    field={{value: titleConfig.showDescription}}
                                    onChange={_ => this.onTitleConfigChange('showDescription', !titleConfig.showDescription)}
                                />
                            </div>
                        </div>

                        <div style={{marginTop: 10}}>
                            <label className="control-label">{this.props.context.msg.t('font.size')}</label>
                            <BngSelectSearch
                                options={this.getFontSizeOptions()}
                                form={{
                                    setFieldValue: (name, t) => this.onFontSizeChange(t)
                                }}
                                field={{value: gridConfig.generalFontSize, onChange: _.noop}}
                                clearable={false}
                            />
                        </div>

                    </div>

                </div>
            );
        }
    }
);


export default StyleMenu;
