import React from 'react';
import { Field, FieldArray } from 'formik';

import Icon from 'components/ui/common/Icon';
import BngSwitch from 'components/bng/form/BngSwitch';
import useBimContext from 'components/hooks/useBimContext';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import AddonActivateDialog from 'components/bng/accounts/addons/AddonActivateDialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import WhatsAppDialog from 'components/ui/whatsapp/WhatsAppDialog';
import EnabledPill from 'components/ui/accounts/EnabledPill';
import { MODALS } from 'components/ui/redux/Actions';
import ManageTraceabilityDialog from 'components/ui/traceability/ManageTraceabilityDialog';
import AddonType from 'components/bng/accounts/AddonType';
import BngButton, { Variant } from 'components/bng/ui/BngButton';

const ADDON_CONFIG_DIALOG = {
  [AddonType.WHATS_APP.key]: { component: WhatsAppDialog },
  [AddonType.TRACEABILITY.key]: { component: ManageTraceabilityDialog },
};

export function AddonComponent({
  addon,
  index,
  enabledInAccount,
  activationRequests,
  accountId,
  plan,
  fullWidth = false,
  withActionButtonFn,
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const currentRequests = activationRequests.filter((req) => req.addon === addon.id);

  function openHelpLink() {
    const url = context.msg.t(addon.linkKey);
    window.open(url, 'BIMachine', 'toolbar=0,status=0,width=800,height=600');
  }

  function openAddonDialog() {
    const dialogComponent = ADDON_CONFIG_DIALOG[addon.id]?.component;
    if (!dialogComponent) {
      return;
    }

    dispatch(
      MODALS.open(dialogComponent, {
        accountId,
        plan,
      })
    );
  }

  return (
    <div className={`addons-component ${fullWidth ? 'addons-component-full-width' : 'limited-addons-component-width'}`}>
      <div className="addons-details">
        <div className="addons-title-container">
          <Icon icon={addon.iconName} className="addons-icon" style={{ color: addon.iconColor }} />
          <span className="addons-title">{context.msg.t(addon.nameKey)}</span>
          <EnabledPill enabled={enabledInAccount} />
          {currentRequests.length > 0 && (
            <BngIconButton
              icon={'info'}
              className="activation-request-info"
              onClick={() => {
                dispatch(
                  window.Actions.MODALS.open(AddonActivateDialog, {
                    selectedAddon: addon,
                  })
                );
              }}
            />
          )}
        </div>
        <span className="addons-description">{context.msg.t(addon.descKey)}</span>
      </div>
      <div className="addons-btn-container">
        {addon.openModal && enabledInAccount ? (
          <a onClick={openAddonDialog} className="learn-more-label-container">
            <span className="learn-more-label">{context.msg.t('manage')}</span>
            <Icon icon={'icon-cog'} className="learn-more-label-icon" />
          </a>
        ) : (
          <a onClick={openHelpLink} className="learn-more-label-container">
            <span className="learn-more-label">{context.msg.t('know.more')}</span>
            <Icon icon={'arrow_right_alt'} className="learn-more-label-icon" />
          </a>
        )}
        {withActionButtonFn ? (
          <BngButton
            variant={enabledInAccount ? Variant.outline : Variant.default}
            onClick={() => withActionButtonFn(addon.id)}
          >
            {context.msg.t(enabledInAccount ? 'disable' : 'enable')}
          </BngButton>
        ) : (
          <div className="bng-switch-addon">
            <Field name={`account.addons.${index}.enabled`} component={BngSwitch} />
          </div>
        )}
      </div>
    </div>
  );
}

export default function AddonsTab({
  selectAccount,
  allAddons = [],
  accountAddons = [],
  activationRequests = [],
  selectPlan,
}) {
  return (
    <div className="addons-tab">
      <FieldArray
        key={selectAccount.id}
        name="account.addons"
        render={() =>
          allAddons.map((addon, index) => {
            const enabledInAccount = !!accountAddons?.find((a) => a.id === addon.id);
            return (
              <AddonComponent
                key={addon.id}
                addon={addon}
                index={index}
                enabledInAccount={enabledInAccount}
                activationRequests={activationRequests}
                accountId={selectAccount.id}
                plan={selectPlan}
              />
            );
          })
        }
      />
    </div>
  );
}
