import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class GaugeKpi extends BaseKpi {

    constructor(props) {
        super(props);
    }

    initComponent = () => {
        const {percentage} = this.props;
        const {color} = this.state;

        if (!this.$kpiGaugeEl) return;

        const target = this.$kpiGaugeEl;

        let val = 0;
        if (percentage !== Infinity) {
            val = percentage;
        }
        const pointerColor = this.props.shouldChangeColor ? '#FFFFFF' : '#000000';
        jQuery(function () {
            let opts = {
                lines: 12, // The number of lines to draw
                angle: 0.15, // The length of each line
                lineWidth: 0.44, // The line thickness
                pointer: {
                    length: 0.8, // The radius of the inner circle
                    strokeWidth: 0.035, // The rotation offset
                    color: pointerColor // Fill color
                },
                limitMax: 'true',   // If true, the pointer will not go past the end of the gauge
                colorStart: color,   // Colors
                colorStop: color,    // just experiment with them
                strokeColor: '#E0E0E0',   // to see which ones work best for you
                generateGradient: true
            };

            let $target = jQuery(target); // your canvas element
            let $targetWidth = $target.width();
            $target.attr('width', $targetWidth).attr('height', $targetWidth / 2);
            let gauge = new Gauge(target).setOptions(opts); // create sexy gauge!
            gauge.maxValue = 100; // set max gauge value
            gauge.animationSpeed = application.utils.disableAnimations() ? 1 : 32;// set animation speed (32 is default value)

            if (val > 100) {
                gauge.set(100);
            } else if (val < 0) {
                gauge.set(0);
            } else {
                gauge.set(val);
            }
        });
    };

    customResizeKpi = ({width}) => {
        const fontSize = 0.14;
        const minFontSize = 12;
        const $kpiInternalBody = this.$bodyEl;
        const left = this.props.align === 'left';

        this.props.div.style.padding = 0;

        const widthCanvas = width * 0.4;
        const heightCanvas = widthCanvas * 0.55;

        if (!left) {
            $kpiInternalBody.style.textAlign = "right";
        } else {
            $kpiInternalBody.style.textAlign = "";
        }

        Object.assign(
            this.$kpiGaugeEl.style, {
                width: `${widthCanvas}px`,
                height: `${heightCanvas}px`,
            }
        );

        if (this.props.growthInfoRender) {
            this.$bodyEl.querySelector('.KpiGrowth').style.width = left ? 'auto' : `${widthCanvas}px`;
        }

        const newFontSize = (heightCanvas * (fontSize)) < minFontSize ? `${minFontSize}px` : `${heightCanvas * (fontSize)}px`;

        Object.assign(
            this.$percentEl.style, {
                fontSize: newFontSize,
                lineHeight: newFontSize,
                width: left ? 'auto' : `${widthCanvas}px`,
                marginLeft: 'auto',
                textAlign: 'center',
            }
        );

    };

    render() {
        return (
            <div style={{textAlign: this.props.textAlign === 'center' || this.props.textAlign === '' ? '' : 'left'}}>
                <div className={`KpiBody Canvas`}>
                    <div ref={el => this.$bodyEl = el} className="KpiInternalBody">
                        <div>
                            <canvas ref={el => this.$kpiGaugeEl = el}/>
                            <div className="kpi-percent" ref={el => this.$percentEl = el}>
                                {this.state.percentage + "%"}
                            </div>
                        </div>
                        {this.useGrowth()}
                    </div>
                    {this.useRightFragment({renderTable: false, container: this.$bodyEl})}
                </div>
                <div ref={el => this.$containerBandsEl = el}>
                    {this.useBands({container: this.$containerBandsEl})}
                </div>
            </div>

        )
    }

}
