import { Axios, findContextPath } from 'commonUtils';

export function buildProgressListener(progressListener) {
  return progressListener
    ? (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressListener(percent);
      }
    : undefined;
}

class UploadApi {
  static TYPE = {
    IMAGE: 'IMAGE',
    TEXT: 'TEXT',
    ZIP: 'ZIP',
  };

  static async upload(
    files = [],
    opts = { progressListener: undefined, temp: false, allowedTypes: [UploadApi.TYPE.IMAGE] }
  ) {
    const fd = new FormData();
    files = _.isArray(files) ? files : [files];
    files.forEach((file) => fd.append('files', file));

    const { data } = await Axios.post('/spr/upload-handler', fd, {
      params: {
        allowedTypes: (opts?.allowedTypes ?? [UploadApi.TYPE.IMAGE]).join(','),
        temp: opts?.temp ?? false,
      },
      onUploadProgress: buildProgressListener(opts?.progressListener),
    });
    return data;
  }

  static async uploadAndResize(file, width, height, progressListener) {
    const fd = new FormData();
    fd.append('files', file);

    const { data } = await Axios.post('/spr/upload-handler/resize', fd, {
      params: {
        width,
        height,
      },
      onUploadProgress: buildProgressListener(progressListener),
    });
    return data;
  }

  static previewUrl(val) {
    if (_.isString(val)) {
      const contextPath = findContextPath();

      if (val.includes('content=')) {
        if (!val.startsWith(contextPath)) {
          val = `${contextPath}${val}`;
        }
        return val;
      }

      return Axios.getUri({
        url: `/upload`,
        params: {
          content: val,
        },
      });
    } else if (val) {
      return window.URL.createObjectURL(val);
    }
  }
}

export default UploadApi;
