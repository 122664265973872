import React from 'react';

import ContextEnhancer from "components/ContextEnhancer";
import { RightMenuItem } from "components/ui/right-menu/RightMenu";
import ColumnsTab from 'components/bng/pages/bigTable/tabs/ColumnsTab';

const BigTableColumnsMenuItem = ({
                                   toggleAccordion =_.noop,
                                   context,
                                   ...props
                               }) => {

    const toggleConfigAccordion = async (event) => {
        return toggleAccordion(ColumnsTab, 'BigTableColumnsMenuItem');
    };

    return (
        <RightMenuItem title={context.msg.t('bigTable.columns')}
                       icon="view_week"
                       className={`BigTableColumnsMenuItem`}
                       onClick={toggleConfigAccordion}
                       {...props}/>
    );
};

export default ContextEnhancer(BigTableColumnsMenuItem);