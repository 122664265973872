import './BaseErrorPage.css';

import React from 'react';

export default function BaseErrorPage({ title, description, image, children, className = '' }) {
  return (
    <div className={`BaseErrorPage ${className}`}>
      <div className="BaseError">
        <div className="Content text-center">
          {image && (
            <div className="mb-4">
              <img src={image} alt={`Server error image`} width={140} />
            </div>
          )}

          {title && <h1 className={`Title mt-0 mb-2`}>{title}</h1>}

          {description && <div className={`Description mb-4`}>{description}</div>}

          {children}
        </div>
      </div>
    </div>
  );
}
