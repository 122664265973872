import {Axios} from "commonUtils";


class CentralMonitoringApi {
    async testHttpAction(action) {
        return await Axios.post(`/spr/central-monitoring/ui/test-http-action`, action);
    }

    async updateActions(httpActions) {
        return await Axios.post(`/spr/central-monitoring/ui/actions`, httpActions);
    }
}

export default CentralMonitoringApi;