import React from 'react';
import Api from 'components/Api';

const t = new Date().getTime();

export default function Avatar({ className = '', userId = 0, style = {} }) {
  return (
    <div
      className={`avatar-component ${className}`}
      style={{
        backgroundImage: `url(${Api.baseUrl()}/avatar?user-id=${userId}&t=${t})`,
        backgroundSize: 'cover',
        ...style,
      }}
    />
  );
}
