const mergeClassNames = (...args) => args.reduce(
    (classList, arg) => (
        (typeof arg === 'string' || Array.isArray(arg))
            ? classList.concat(arg)
            : classList
    ),
    [],
).filter(Boolean).join(' ');

export default mergeClassNames;
