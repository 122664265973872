"use strict";

const $ = jQuery;


class GitHubApi {
    static validateToken(token, owner, repository) {
        return Promise.resolve(
            $.getJSON('/spr/origins/github/validate', {token, owner, repository})
        );
    }

    static validateName(gitHubData) {
        return Promise.resolve(
            $.postJSON('/spr/origins/github/validatename', gitHubData)
        );
    }

    static saveOrigin(gitHubData) {
        return Promise.resolve(
            $.postJSON('/spr/origins/github', gitHubData)
        );
    }


    static editOrigin(id, gitHubData) {
        return Promise.resolve(
            $.postJSON(`/spr/origins/github/${id}`, gitHubData)
        );
    }

    static getValuesWS(id) {
        return Promise.resolve(
            $.getJSON(`/spr/origins/github/values/${id}`)
        );
    }
}

export default GitHubApi;