import styles from './ConfigurationTab.module.css';

import React from 'react';
import { Field } from 'formik';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import BngField from 'components/bng/form/BngField';
import SelectIconDialog from 'components/ui/common/SelectIconDialog';
import { MODALS } from 'components/ui/redux/Actions';
import Icon from 'components/ui/common/Icon';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngDropdownTagsWrapper from 'components/bng/ui/BngDropdownTagsWrapper';
import BngRadio from 'components/bng/form/BngRadio';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngButton from 'components/bng/ui/BngButton';
import useTranslation from 'components/hooks/useTranslation';
import BngInputRichText from 'components/bng/form/BngInputRichText';
import BimQueryConnectionsMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConnectionsMenuItem';
import { RIGHT_MENU_TOGGLE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import bimEventBus from 'BimEventBus';
import BimQueryKeyRecyclingHierarchyMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryKeyRecyclingHierarchyMenuItem';

const structureTypeOpts = (t) => {
  return [
    {
      label: t('ANALYTICAL'),
      icon: 'equalizer',
      value: 'ANALYTICAL',
    },
    {
      label: t('data'),
      icon: 'dns',
      value: 'DATA',
    },
  ];
};

export default function ConfigurationTab({ isEditing, formikProps, options }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const { values, setFieldValue, setValues, setTouched, touched, errors } = formikProps;

  const fieldsHasError =
    errors.hasOwnProperty('displayName') ||
    errors.hasOwnProperty('structureDescription') ||
    errors.hasOwnProperty('structureIcon');

  const fieldsAreEmpty =
    values.structureName !== '' && values.structureDescription !== '' && values.structureIcon !== '';

  const stepIsValid = fieldsAreEmpty && !fieldsHasError;

  return (
    <AccordionList className={`ConfigurationTab ObjectRightMenuAccordion`}>
      <Accordion title={t('configuration')}>
        <div className={`${styles.NameIconWrapper}`}>
          <Field
            name="displayName"
            className={`${styles.StructureNameInput}`}
            labelClassName={`${styles.StructureNameInputLabelClassName}`}
            component={BngField}
            label={`${t('name')}:`}
            placeHoslder={t('bim.query.configuration.structure.name.placeholder')}
            required={true}
            form={formikProps}
            value={values.displayName}
            onChange={({ target }) => {
              setValues({
                ...values,
                displayName: target.value,
                structureName: target.value.normalize('NFD').replace(/([^a-zA-Z]|\s)/g, ''),
              });
              setTouched(
                {
                  ...touched,
                  displayName: true,
                  structureName: true,
                },
                true
              );
            }}
          />
          <div className={`${styles.IconSelectorWrapper}`}>
            {t('icon')}
            <Icon
              icon={values.structureIcon}
              onClick={() => {
                dispatch(
                  MODALS.open(SelectIconDialog, {
                    icon: { name: values.structureIcon },
                    onSelect: (event) => setFieldValue('structureIcon', event.name),
                  })
                );
              }}
            />
          </div>
        </div>

        <div className={`${styles.StructureDescriptionInput}`}>
          <Field
            name="structureDescription"
            label={t('description')}
            component={BngField}
            inputComponent={BngInputRichText}
            value={values.structureDescription}
            form={formikProps}
            required={true}
            editorOptions={{
              controls: 'bold italic underline strikethrough | bullets numbering',
            }}
          />
        </div>
        <div className={`${styles.StructureTypeTitle}`}>{t('bim.query.configuration.structure.type')}:</div>
        <div className={`${styles.StructureTypeWrapper}`}>
          {structureTypeOpts(t).map((type, idx) => {
            return (
              <div
                key={`${type.value}-${idx}`}
                className={`${styles.StructureType} ${type.value === values.structureType ? 'selected' : ''}`}
                onClick={() => setFieldValue('structureType', type.value)}
              >
                <Icon icon={type.icon} />
                {type.label}
                <BngRadio checked={type.value === values.structureType} field={{ value: type.value }} />
              </div>
            );
          })}
        </div>
        <div className={`${styles.checkboxAndTagsWrapper}`}>
          <BngCheckbox
            label={t('snapshot.structure')}
            className={`${styles.snapshotStructureCheckBox}`}
            field={{ value: false }}
            onClick={() => setFieldValue('fullLoads', !values.fullLoads)}
            checked={values.fullLoads}
          />
          <BngDropdownTagsWrapper
            selectedGroupId={values.structureTag}
            applyGroup={(group) => setFieldValue('structureTag', group?.group?.id || 0)}
            customButton={({ openDropdown }) => (
              <div onClick={openDropdown} className={`${styles.TagSelection}`}>
                <div />
                {t('bim.query.configuration.structure.add.tag')}
              </div>
            )}
          />
        </div>
      </Accordion>
      {!isEditing && (
        <>
          <BngButton
            className={`${styles.GoBackButton}`}
            onClick={() =>
              bimEventBus.emit(
                RIGHT_MENU_TOGGLE_SUBMENU,
                options.find((o) => o.key === BimQueryConnectionsMenuItem.KEY)
              )
            }
          >
            {t('bim.query.connection.configuration.go.back')}
          </BngButton>
          <BngButton
            className={`${styles.NextStepButton}`}
            disabled={!stepIsValid}            onClick={() => {
              if (stepIsValid)
                bimEventBus.emit(
                  RIGHT_MENU_TOGGLE_SUBMENU,
                  options.find((o) => o.key === BimQueryKeyRecyclingHierarchyMenuItem.KEY)
                );
            }}
          >
            {t('bim.query.connection.configuration.next.step')}
          </BngButton>
        </>
      )}
    </AccordionList>
  );
}
