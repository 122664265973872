import * as React from "react";
import Button from "components/ui/Button";
import ContextEnhancer from "components/ContextEnhancer";

export const DgMemberTree = ContextEnhancer(class extends React.Component {

    static defaultProps = {
        initialValues: {
            data: {
                members: []
            }
        },
        availableMembers: [],
        selected: {},
        onSelect: _.noop
    };

    state = {
        expanded: {},
        search: '',
    };

    componentDidMount() {
        this.initialExpand();
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this.props.availableMembers !== prevProps.availableMembers) {
            if (this.state.filteredMembers) {
                this.setState({filteredMembers: null})
            }
            const m = this.props.availableMembers.find(({node}) => node.all);
            if (m && !(m.node.uniqueName in this.state.expanded)) {
                this.state.expanded[m.node.uniqueName] = {};
                this.setState({expanded: this.state.expanded});
            }
        }

        if (this.props.initialValues !== prevProps.initialValues) {
            this.initialExpand();
        }
    }

    initialExpand() {
        let initialValues = this.props.initialValues.data.members;
        if(!initialValues.length) {
            initialValues = Object.keys(this.props.selected);
        }
        const expanded = {};
        initialValues.forEach(m => {
            let arr = m.split('.');
            expanded[arr[0] + '.[Todos]'] = {};
            while (arr.length > 2) {
                arr.pop();
                expanded[arr.join('.')] = {};
            }
        });
        this.setState({expanded});
    }

    doFilter = (e) => {
        if (!this.state.search) {
            this.setState({filteredMembers: null});
            return;
        }
        let filteredMembers = JSON.deepClone(this.props.availableMembers);
        filteredMembers = filteredMembers.filter(m => this.recursiveFilter(m, this.state.search));
        this.setState({filteredMembers});
    };

    render() {
        const members = this.state.filteredMembers || this.props.availableMembers;
        return (
            <div className="MemberTree">
                <div>
                <div className="input-append span11">
                    <input type="text"
                           className="fill-w"
                           placeholder={this.props.context.msg.t('search')}
                           onChange={e => this.setState({search: e.target.value})}
                           onKeyPress={e => {
                               if(e.key === 'Enter') {
                                   e.stopPropagation();
                                   e.preventDefault();
                                   this.doFilter(e);
                                   e.target.focus();
                                   e.target.select();
                               }
                           }}
                    />
                    <Button icon="icon-search" className="btn-inverse"
                            style={{height: '30px'}}
                            onClick={this.doFilter}
                    />
                </div>
                </div>
                <div className="members-container scrollbar-inner" ref={ref => {
                    if(ref) {
                        j(ref).scrollbar();
                    }
                }}>
                <ul>
                    {members.map(m => this.renderMember(m))}
                </ul>
                </div>
            </div>
        )
    }

    compareIgnoreCase(s1, s2) {
        return s1.toLowerCase().includes(s2.toLowerCase());
    }

    compareNode(member, search) {
        return this.compareIgnoreCase(member.node.uniqueName + member.node.caption, search);
    }

    recursiveFilter(member, search) {
        if (member.leaf) {
            return this.compareNode(member, search);
        }
        member.children = member.children.filter(m => this.recursiveFilter(m, search));
        return member.children.length > 0 || this.compareNode(member, search);
    }

    toggle = (id, name) => {
        const items = this.state[name];
        if (id in items) {
            delete items[id];
        } else {
            items[id] = {};
        }
        this.setState({[name]: items});
    };

    renderMember = ({node, leaf, children}) => {
        const gotChildren = !leaf && node.uniqueName in this.state.expanded;
        const selected = node.uniqueName in this.props.selected;

        return (
            <li key={node.uniqueName}>
                <div className={`member ${leaf ? 'leaf' : 'parent'}`}>
                    {!leaf &&
                    this.renderExpandToggler(node, gotChildren)
                    }

                    <label>
                        <input type="checkbox" checked={selected} onChange={(e) => {
                            e.stopPropagation();
                            this.props.onSelect(node);
                        }}/>
                        <span className="lbl">
                            <span>
                                {node.caption}
                            </span>
                        </span>
                    </label>
                </div>
                {gotChildren &&
                <ul>
                    {children.map(this.renderMember)}
                </ul>
                }
            </li>
        );
    };

    renderExpandToggler(node, gotChildren) {
        return (
            <a href="#" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.toggle(node.uniqueName, 'expanded');
            }}>
                <i className={`fa ${gotChildren ? 'fa-minus-square-o' : 'fa-plus-square-o'}`}
                   style={{cursor: 'pointer'}}/>
            </a>
        );
    }
});