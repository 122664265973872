import './ProjectExtractorsDialog.css';

import React, { useMemo, useState } from 'react';
import Api from 'components/Api';
import { BngTable } from 'components/bng/ui/BngTable';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import Dialog from 'components/ui/Dialog';
import OpConfirmation from 'components/ui/OpConfirmation';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import ProjectExtractorFormDialog, { SAVE_EVENT } from 'components/bng/pages/bimGateway/ProjectExtractorFormDialog';
import useEventBus from 'components/hooks/useEventBus';
import Utils from 'components/Utils';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import BngSearch from 'components/bng/ui/BngSearch';
import { BngDialogActionButton } from 'components/ui/Button';
import BimGatewayTutorialDialog from 'components/bng/pages/bimGateway/bimGatewayTutorial/BimGatewayTutorialDialog';
import useFetchData from 'components/hooks/useFetchData';
import ProjectExtractorDetailsDialog from 'components/bng/pages/bimGateway/ProjectExtractorDetailsDialog';
import { useGlobalLoader } from 'bng/common/GlobalLoader';

export default function ProjectExtractorsDialog({ className = '', closeModal }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const $globalLoader = useGlobalLoader();
  const { project, msg } = context;

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const $extractors = useFetchData(async () => {
    try {
      return await Api.ProjectExtractor.findByProject(project.id);
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  }, [project.id]);

  useEventBus(SAVE_EVENT, () => $extractors.reload(), []);

  const filteredExtractors = useMemo(() => {
    const extractors = $extractors.data ?? [];

    if (_.isEmpty(searchTerm)) {
      return extractors;
    }

    return extractors.filter((e) => {
      return Utils.Strings.includesIgnoreCase(e.name, searchTerm);
    });
  }, [$extractors.data, searchTerm]);

  return (
    <Dialog
      className={`ProjectExtractorsDialog large ${className}`}
      loading={loading}
      onClose={closeModal}
      title={msg.t('extractors')}
      contentFullWidth={true}
    >
      <div className={'conection-dialog-division top mt-1 mb-1'} />

      <div className="flex-center-items ml-5 mr-5 mb-1">
        <BngDialogActionButton
          icon="add_circle"
          onClick={async () => {
            dispatch(MODALS.open(BimGatewayTutorialDialog));
          }}
        >
          {msg.t('connect.gateway')}
        </BngDialogActionButton>
        <div className="ml-auto flex-center-items flex-grow-1">
          <BngSearch alwaysOpen={false} className="flex-grow-1 ml-4" onChange={setSearchTerm} />
          <BngIconButton
            icon="refresh"
            onClick={() => $extractors.reload()}
            className="RefreshBtn"
            title={msg.t('update')}
          />
        </div>
      </div>

      <div className="TableContainer">
        <BngTable
          className="sm bt-1 asd"
          rows={filteredExtractors}
          cols={[
            {
              colClassName: 'pl-6',
              rowClassName: 'pl-6',
              label: msg.t('name'),
              render: (row) => {
                return row.name ?? '';
              },
            },
            {
              label: 'OS',
              size: '150px',
              render: (row) => {
                const title = `
                  <div><b>${msg.t('version')}:</b> ${row.props?.osVersion}</div>
                  <div><b>Arch:</b> ${row.props?.osArch}</div>
                `;
                return <span title={title}>{row.props?.osName ?? '-'}</span>;
              },
            },
            {
              label: msg.t('version'),
              size: '150px',
              render: (row) => row.props?.version ?? '-',
            },
            {
              label: msg.t('lastCheck'),
              size: '150px',
              render: (row) => {
                const online = row.isOnline();
                return (
                  <div
                    key={`${row.id}-${online}`}
                    className={`${online ? '' : 'text-error'}`}
                    title={online ? '' : msg.t('extractor.offline.title')}
                  >
                    {Utils.Date.formatDateTime(row.lastCheckAt)}
                  </div>
                );
              },
            },
            {
              size: '65px',
              render: (row) => {
                return (
                  <BngDropdown
                    options={[
                      {
                        icon: 'network_ping',
                        label: msg.t('test.connection'),
                        onClick: async () => {
                          try {
                            const { success } = await Api.ProjectExtractor.ping(row.key);
                            if (success) {
                              UiMsg.ok(msg.t('extractor.ping.success'));
                            } else {
                              UiMsg.warn(msg.t('extractor.ping.warning'));
                            }
                          } catch (e) {
                            console.error('Error on pingExtractor()', { key: row.key }, e);
                          }
                        },
                      },
                      {
                        icon: 'edit',
                        label: msg.t('edit'),
                        onClick: () => {
                          dispatch(
                            MODALS.open(ProjectExtractorFormDialog, {
                              id: row.id,
                            })
                          );
                        },
                      },
                      {
                        icon: 'sensors',
                        label: msg.t('reconnect'),
                        onClick: async () => {
                          dispatch(
                            MODALS.open(BimGatewayTutorialDialog, {
                              id: row.id,
                            })
                          );
                        },
                        disabled: row.isOnline(),
                      },
                      {
                        icon: 'manage_search',
                        label: msg.t('view.logs'),
                        onClick: () => {
                          $globalLoader.runTask(async () => {
                            try {
                              const { log } = await Api.ProjectExtractor.fetchLog(row.key);
                              dispatch(
                                MODALS.open(ProjectExtractorDetailsDialog, {
                                  projectExtractor: row,
                                  title: msg.t('log'),
                                  content: log,
                                })
                              );
                            } catch (e) {
                              console.error('Error on fetchLog()', { row }, e);
                              UiMsg.ajaxError(null, e);
                            }
                          });
                        },
                      },
                      {
                        icon: 'info',
                        label: msg.t('details'),
                        onClick: () =>
                          dispatch(
                            MODALS.open(ProjectExtractorDetailsDialog, {
                              projectExtractor: row,
                              content: JSON.stringify(row.props ?? {}, null, 2),
                              title: msg.t('details'),
                            })
                          ),
                      },
                      {
                        icon: 'remove',
                        label: msg.t('remove'),
                        onClick: () =>
                          OpConfirmation({
                            msg,
                            title: msg.t('remove_confirmation_title'),
                            message: msg.t('remove_confirmation_message'),
                            onConfirm: async () => {
                              setLoading(true);
                              try {
                                await Api.ProjectExtractor.remove(row.id);
                                UiMsg.ok(msg.t('remove_success', [msg.t('extractor')]));
                                $extractors.reload();
                              } catch (e) {
                                console.error(e);
                                UiMsg.ajaxError(null, e);
                              } finally {
                                setLoading(false);
                              }
                            },
                          }),
                      },
                    ]}
                    overDialog
                  />
                );
              },
            },
          ]}
          stickyHeader
        />
      </div>
    </Dialog>
  );
}
