//import "./CockpitPanelItems.css";

import React, {Component} from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";

import ContextEnhancer from "components/ContextEnhancer";
import Icon from "components/ui/common/Icon";


const PAGE_SIZE = 20;


class BngCockpitPanelItems extends Component {

    static propTypes = {
        panels: PropTypes.array,
        currentPanelId: PropTypes.number,
        onPanelChange: PropTypes.func,
    };

    static defaultProps = {
        panels: [],
        currentPanelId: 0,
        onPanelChange: _.noop
    };

    state = {
        searchTerm: '',
        currentTab: 0,
    };

    componentDidMount() {
        // Find initial page of the selected panel
        const pages = _.chunk(this.props.panels, PAGE_SIZE);
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].some(panel => panel.id === this.props.currentPanelId)) {
                this.setState({currentTab: i});
                break;
            }
        }
    }

    onSearchChange = (event) => {
        const searchTerm = event.target.value || '';
        this.setState({
            searchTerm,
            currentTab: 0,
        });
    };

    filterPanels = memoizeOne((panels, searchTerm) => {
        searchTerm = (searchTerm || '').toUpperCase();
        return searchTerm
            ? panels.filter(panel => panel.name.toUpperCase().includes(searchTerm))
            : panels;
    });

    render() {
        const panels = this.filterPanels(this.props.panels, this.state.searchTerm);
        const pages = _.chunk(panels, PAGE_SIZE);

        const page = pages[this.state.currentTab];

        return (
            <div className="cockpit-panel-itens-popper cockpit-dropdown-popper">
                <div className="cockpit-panel-itens-search">
                    <div className="cockpit-panel-itens-search-container">
                        <div className="cockpit-panel-itens-search-container-input">
                            <input className="cockpit-panel-item-search-input"
                                   type="text"
                                   placeholder={this.props.context.msg.t('search.in.panels')}
                                   onChange={this.onSearchChange}
                                   value={this.state.searchTerm}
                            />
                        </div>
                        <div className="cockpit-panel-itens-search-container-icon">
                            <Icon icon="search"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className="cockpit-panel-itens-list">
                            <div className="cockpit-panel-nav-tab active">
                                {page && page.map(panel => {
                                    const isSelected = panel.id === this.props.currentPanelId;
                                    return (
                                        <div key={panel.id}
                                             className={`cockpit-panel-list-item ${isSelected ? 'current-panel' : ''}`}>
                                            <a href="#"
                                               title={panel.name}
                                               onClick={(e) => {
                                                   e.preventDefault();
                                                   this.props.onPanelChange(panel)
                                               }}>
                                                <Icon icon={panel.icon}></Icon>
                                                <span className="text-overflow-ellipsis">{panel.name}</span>
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="widget-footer-pg-buttons">
                        {_.range(0, Math.max(pages.length, 1)).map(pageIdx => {
                            const active = this.state.currentTab === pageIdx;
                            return (
                                <a key={pageIdx}
                                   className="cockpit-panel-item-nav-button"
                                   href="#"
                                   onClick={e => {
                                       e.preventDefault();
                                       this.setState({currentTab: pageIdx});
                                   }}>
                                    <div
                                        className={`widget-footer-pg-button widget-footer-pg-button-cockpit-panel ${active ? 'active' : ''}`}>

                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }


}

export default ContextEnhancer(BngCockpitPanelItems);