import React from 'react';
import BlockUi from 'react-block-ui';
import LoadingBox from 'components/ui/loading/LoadingBox';

export function UiBlocker({
  className = '',
  block = false,
  loader,
  renderChildrenWhileBlocking = true,
  children,
  ...props
}) {
  return (
    <BlockUi
      tag="div"
      blocking={block}
      className={`UiBlocker ${className} ${block ? 'Loading' : ''}`}
      loader={_.isNull(loader) ? undefined : loader ?? LoadingBox}
      {...props}
    >
      {(!block || renderChildrenWhileBlocking) && (_.isFunction(children) ? children({ block }) : children)}
    </BlockUi>
  );
}

export default UiBlocker;
