import React from 'react';
import * as PropTypes from "prop-types";

export class ScrollContainer extends React.PureComponent {

    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any
    };

    static defaultProps = {
        className: ''
    };


    onRef = (ref) => {
        if (ref) {
            this._scrollbar = ref;
            this.$scrollbar = j(ref).scrollbar();
        }
    };

    scrollToBottom = () => {
        this._scrollbar.scrollTop = this._scrollbar.scrollHeight;
    };

    render() {
        let {
            className,
            children,
            ...props
        } = this.props;
        return (
            <div className={`ScrollContainer scrollbar-macosx ${className}`}
                 ref={this.onRef}
                 {...props}>
                {children}
            </div>
        )
    }
}