import styles from './SelectItems.module.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'imports/ReactBootstrapImport';
import ContextEnhancer from 'components/ContextEnhancer';
import Checkbox from 'components/ui/Checkbox';
import Radio from 'components/ui/Radio';
import Icon from 'components/ui/common/Icon';

const sliceArray = function (array, page, maxMembers) {
  return array.slice((page - 1) * maxMembers, page * maxMembers);
};

class SelectItems extends Component {
  static propTypes = {
    value: PropTypes.array,
    items: PropTypes.array,
    mode: PropTypes.oneOf(['ONE', 'MANY']),
    maxElements: PropTypes.number,
    onChange: PropTypes.func,
    positionFilter: PropTypes.string,
    disableItemFn: PropTypes.func,
  };

  static defaultProps = {
    value: [],
    items: [],
    mode: 'ONE',
    maxElements: 20,
    onChange: () => console.error('Replace this function :D'),
    createMemberIfNotFound: true,
    positionFilter: '',
    disableItemFn: null,
  };

  state = {
    activePage: 1,
    selectedItems: this.props.value.slice(0),
    searchInput: '',
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value) {
      this.setState({ selectedItems: this.props.value.slice(0) });
    }
  }

  handlePagination = (event) => {
    this.setState({ activePage: event });
  };

  renderMember = (isModeOne, member, idx) => {
    let value = member;
    if (this.props.disableItemFn) {
      value = this.props.disableItemFn(member);
    }
    const props = {
      key: idx,
      name: 'member' + this.props.positionFilter,
      value,
      selected: this.isElementSelected(member),
      onChange: () => this.toggleSelection(member, isModeOne),
      className: isModeOne ? styles.radioSpan : styles.checkboxSpan,
    };
    if (isModeOne) {
      return <Radio {...props} />;
    } else {
      return <Checkbox {...props} />;
    }
  };

  isElementSelected = (member) => {
    let list = this.state.selectedItems;
    let found = list.find((item) => item.value === member.value) != null;
    if (this.isModeOne()) {
      found = found || (member.value === '' && this.state.selectedItems.length === 0);
    }
    return found;
  };

  toggleSelection = (member, isModeOne) => {
    if (isModeOne) {
      this.state.selectedItems = member.value.length === 0 ? [] : [member];
      this.props.onChange(this.state.selectedItems);
    } else {
      let indexOf = this.state.selectedItems.findIndex((item) => item.value === member.value);
      if (indexOf !== -1) {
        this.state.selectedItems.splice(indexOf, 1);
      } else {
        this.state.selectedItems.push(member);
      }
    }
    this.setState({ selectedItems: this.state.selectedItems }, () => {
      this.props.onChange(this.state.selectedItems);
    });
  };

  executeSearch = () => {
    this.setState({
      searchInput: this.refs.searchInput.value,
      activePage: 1,
    });
  };

  isModeOne = () => {
    return this.props.mode === 'ONE' || this.props.mode === 'RANGE';
  };

  render() {
    let filteredMembers = this.props.items;
    let isModeOne = this.isModeOne();

    filteredMembers.forEach((mem) => {
      if (mem.selectItems) {
        mem.visibleSelectItems = mem.selectItems.slice(0);
      }
    });
    if (this.state.searchInput.length > 0) {
      filteredMembers = filteredMembers.filter((member) => {
        let result = false;
        if (member.visibleSelectItems) {
          member.visibleSelectItems = member.selectItems.filter(
            (innerMember) => innerMember.label.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !== -1
          );
          result = member.visibleSelectItems.length > 0;
        } else {
          result = member.label.toLowerCase().indexOf(this.state.searchInput.toLowerCase()) !== -1;
        }
        return result;
      });
    }

    let paginationNumberOfItems = Math.ceil(filteredMembers.length / this.props.maxElements);
    let visibleMembers = sliceArray(filteredMembers, this.state.activePage, this.props.maxElements);

    if (this.props.createMemberIfNotFound) {
      if (this.state.selectedItems.length > 0) {
        for (const member of this.state.selectedItems) {
          if (
            !visibleMembers.find((el) => {
              if (el.visibleSelectItems) {
                return el.visibleSelectItems.find((iitm) => iitm.value === el.value) !== null;
              } else {
                return el.value === member.value;
              }
            })
          ) {
            visibleMembers.unshift(member);
          }
        }
      }
    }
    if (isModeOne) {
      visibleMembers.unshift({
        value: '',
        label: this.props.context.msg.t('no.filter.selected'),
      });
    }

    let showingMaxResults = this.state.activePage * this.props.maxElements;
    if (showingMaxResults > filteredMembers.length) {
      showingMaxResults = filteredMembers.length;
    }

    const paramsMsg = [
      (this.state.activePage - 1) * this.props.maxElements + 1,
      filteredMembers.length,
      '',
      showingMaxResults,
    ];
    const filterInfoResults = this.props.context.msg.t('select.items.component.filter.info', paramsMsg);

    return (
      <div className={`row-fluid select-items-one`}>
        <div className={`left-panel`}>
          <legend>
            <div className="search-input-box">
              <input
                className=""
                onChange={this.executeSearch}
                placeholder={this.props.context.msg.t('search') || 'Pesquisa'}
                type="text"
                autoFocus
                ref="searchInput"
              />
              <Icon icon="search" />
            </div>
          </legend>

          <div className={`select-items-component-items-container scrollbar-outer ${styles.itensContainer}`}>
            {visibleMembers.map((member, idx) => {
              if (member.visibleSelectItems) {
                return (
                  <fieldset key={idx}>
                    <legend>{member.label}</legend>
                    {member.visibleSelectItems.map((itm, index) => this.renderMember(isModeOne, itm, index))}
                  </fieldset>
                );
              } else {
                return this.renderMember(isModeOne, member, idx);
              }
            })}
          </div>
          <hr style={{ margin: '5px 0' }} />
          <div className="row-fluid">
            <div className="span8 select-items-desc-data">
              <span className="iceOutTxt">{filterInfoResults}</span>
            </div>
            <div className="span4 text-right select-items-pagination">
              <div className="pagination pagination-mini" style={{ margin: 0, padding: 0 }}>
                <Pagination bsSize="small" style={{ margin: 0, padding: 0, boxShadow: 'none' }}>
                  <Pagination.First onClick={() => this.handlePagination(1)} />
                  <Pagination.Prev
                    disabled={this.state.activePage === 1}
                    onClick={() => this.handlePagination(this.state.activePage > 1 ? this.state.activePage - 1 : 1)}
                  />
                  <Pagination.Item>{this.state.activePage}</Pagination.Item>
                  <Pagination.Next
                    disabled={this.state.activePage === paginationNumberOfItems}
                    onClick={() =>
                      this.handlePagination(
                        this.state.activePage < paginationNumberOfItems
                          ? this.state.activePage + 1
                          : paginationNumberOfItems
                      )
                    }
                  />
                  <Pagination.Last onClick={() => this.handlePagination(paginationNumberOfItems)} />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContextEnhancer(SelectItems);
