import { useEffect, useRef, useState } from 'react';

function useFetchData(fetcher, deps = []) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const $mounted = useRef(true);

  const reload = async (fetcherArgs, props) => {
    setLoading(true);
    setError(null);

    fetcherArgs = fetcherArgs ?? deps;
    props = props ?? {};
    try {
      const data = fetcher ? await fetcher(fetcherArgs, props) : null;
      if ($mounted.current) {
        setData(data);
      }
    } catch (e) {
      console.error('Error on useFetchData.reload()', { fetcherArgs, props }, e);
      if ($mounted.current) {
        setData(null);
        setError(e);
      }
    } finally {
      if ($mounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      $mounted.current = false;
    };
  }, []);

  useEffect(() => {
    reload();
  }, deps);

  return {
    data,
    setData,
    error,
    isLoading: loading,
    reload,
  };
}

export default useFetchData;
