import './BordersMenu.css';

import React from 'react';

import ContextEnhancer from "components/ContextEnhancer";
import {borderIconOptions} from 'components/bng/pages/bigTable/constants';
import BngInputColor from "components/bng/form/BngInputColor";
import ButtonGroup from "components/ui/button-group/ButtonGroup";
import BngInputSpinner from "components/bng/form/BngInputSpinner";

class BordersMenu extends React.PureComponent {

  state = {};

  async componentDidMount() {
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  onGridConfigChange(field, value) {
    let next = {
      ...this.props.gridConfig,
      [field]: value
    };
    this.props.onGridConfigChange(next);
  }

  render() {

    const {gridConfig} = this.props;

    return (
      <div className="BngBigTable__BordersMenu">
        <div className="BngForm">
          <ButtonGroup
            label={this.props.context.msg.t('border_type')}
            items={borderIconOptions}
            value={gridConfig.borderOptions}
            onChange={(val) => this.onGridConfigChange('borderOptions', val)}
          />

          <div className="BngBigTable__columnsForm__grid">
            <div className="w-45">
              <>
                <label className="control-label">{this.props.context.msg.t('border_color')}</label>
                <BngInputColor
                  form={{setFieldValue: (name, t) => this.onGridConfigChange('borderColor', t)}}
                  field={{value: gridConfig.borderColor, onChange: _ => _.noop()}}
                />
              </>
            </div>
            <div className="w-45">
              <label className="control-label">{this.props.context.msg.t('border_width')}</label>
              <BngInputSpinner
                min={0}
                max={10}
                allowNegative={false}
                value={gridConfig.borderWidth}
                onChange={val => this.onGridConfigChange('borderWidth', val)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContextEnhancer(BordersMenu);