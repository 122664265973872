import React, { useEffect, useState } from "react";

import './BngNewAnalysisHelp.css';
import BngAda from "components/bng/ui/BngAda/BngAda";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";

import AnalysisMultiStepInfo from "components/ui/analysis/info-new-analysis/AnalysisMultiStepInfo";

const ANALYSIS_HELP_PREFIX = 'NEW_ANALYSIS_HELP_';

export const BngNewAnalysisHelp = ContextEnhancer(({ context, showIntro = false, onFinishIntro = _.noop }) => {
    const projectId = context.findProjectIdFromContext();
    const [introProps, setIntroProps] = useState({ show: showIntro, props: { projectId, hasWatched: !showIntro } });
    const defaultOpts = [
        {
            key: Api.UserParam.Keys.Analysis.NewAnalysisHelpVideo,
            icon: 'play_circle_outline',
            label: context.msg.t('new.analysis.help.video'),
            onClick: () => {
                setIntroProps({
                    show: true,
                    props: {
                        projectId,
                        hasWatched: true
                    }
                });

            }
        },
        {
            label: context.msg.t('new.analysis.help.documentation'),
            onClick: () => window.open(context.msg.t('documentation.create.analysis'), "_blank")
        },
    ];
    const [userParamsKeys, setUserParamsKeys] = useState([]);
    const [adaButtonPressed, setAdaButtonPressed] = useState(false);

    const fetchAllUserParamsAndMap = () => {
        (async function () {
            const userParams = await Api.UserParam.findAllForUser()
            if (userParams) {
                const userParamsKeysTemp = userParams
                    .filter(up => up.key.includes(ANALYSIS_HELP_PREFIX))
                    .map(up => up.key)
                setUserParamsKeys(userParamsKeysTemp)
            }
        })()
    }

    useEffect(fetchAllUserParamsAndMap, []);

    useEffect(() => {
        let total = 0;
        for (let i = 0; i < defaultOpts.length; i++) {
            if (defaultOpts[i].key && !userParamsKeys.includes(defaultOpts[i].key)) {
                total++;
            }
        }
    },
        [userParamsKeys]
    );

    useEffect(() => {
        if (introProps.show) setAdaButtonPressed(true);
    })

    return (
        <>
            <BngAda
                className={`NewAnalysisHelp ${adaButtonPressed ? 'AdaVisible' : 'AdaHidden'}`}
                title={context.msg.t('new.analysis.help.title', [context.user.displayName])}
                options={defaultOpts}
                onClick={() => {
                    setAdaButtonPressed(!adaButtonPressed);
                }}
                buttonPressed={adaButtonPressed}
                showInfo={!introProps.show}
                disableBackground={true}
            />
            {introProps.show &&
                <AnalysisMultiStepInfo {...introProps.props}

                    onFinish={() => {
                        setIntroProps({ ...introProps, show: false });
                        fetchAllUserParamsAndMap();
                        onFinishIntro();
                        setAdaButtonPressed(!adaButtonPressed);
                    }}
                />
            }
        </>
    )
});