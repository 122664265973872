import React from 'react';
import { Field } from 'formik';

import { BngField } from 'components/bng/form/BngField';
import BngRadio from 'components/bng/form/BngRadio';
import useBimContext from 'components/hooks/useBimContext';

function RadioOpts({ className = '', field, form, options = [] }) {
  const { msg } = useBimContext();
  return (
    <div className={`RadioOpts ${className} flex-center-items gap-5`}>
      {options.map((opt, idx) => {
        const lbl = opt.label ?? opt.value;
        return (
          <BngRadio key={idx} field={field} form={form} value={opt.value} label={msg.translateIfHasKey(lbl) ?? lbl} />
        );
      })}
    </div>
  );
}

export default {
  render({ name, label, props }) {
    return (
      <Field
        name={name}
        label={label}
        component={BngField}
        inputComponent={RadioOpts}
        rootClassName={'ParamType Radio position-relative'}
        rootProps={{ 'data-test': 'Radio' }}
        {...props}
      />
    );
  },
};
