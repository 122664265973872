import './EchartsToolbar.css';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as ReactDOM from 'react-dom';

import { BngIconButton } from 'components/bng/ui/BngIconButton';
import useBimContext from 'components/hooks/useBimContext';
import DashGridContext from 'components/ui/dashboard/components/DashGridContext';
import UiMsg from 'components/ui/UiMsg';
import useEventBus from 'components/hooks/useEventBus';
import BimEventBus from 'BimEventBus';
import Utils from 'components/Utils';

export const ECHARTS_ZOOM_CLEAR_EVENT = 'EchartsToolbar:ZoomClear';

export default function EchartsToolbar({ className = '', chartInstance = null, isZoomOutEnabled = false, container }) {
  const context = useBimContext();
  const { editMode } = useContext(DashGridContext);

  const instanceId = useMemo(() => Utils.randomId(), []);

  const [disableZoomButton, setDisableZoomButton] = useState(false);
  const [isZoomEnabled, setIsZoomEnabled] = useState(null);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'dataZoomSelect',
        dataZoomSelectActive: isZoomEnabled,
      });

      const dropdownContainer = container?.closest('.DashboardItemMenuDropdown');

      if (dropdownContainer) {
        if (isZoomEnabled) {
          dropdownContainer.classList.add('active');
        } else {
          dropdownContainer.classList.remove('active');
        }
      }
    }
  }, [isZoomEnabled, chartInstance]);

  const temporaryDisableButton = () => {
    setDisableZoomButton(true);
    setTimeout(() => {
      setDisableZoomButton(false);
    }, 2000);
  };

  // This component can have N instances, each one with an event listener to zoom being enabled
  // Zoom can only be applied to one at time, so disable to every one except for the emitter
  useEventBus(
    ECHARTS_ZOOM_CLEAR_EVENT,
    ({ emitterId }) => {
      if (instanceId !== emitterId) {
        if (isZoomEnabled) {
          setIsZoomEnabled(false);
        }
        temporaryDisableButton();
      } else {
        setIsZoomEnabled(!isZoomEnabled);
        if (!isZoomEnabled) {
          UiMsg.ok(context.msg.t('bubble.chart.zoom.in'), '', {
            autoClose: false,
            toastId: 'bubbleChartToast',
          });
        } else {
          temporaryDisableButton();
        }
      }
    },
    [isZoomEnabled]
  );

  const renderResult = (
    <div className={`EchartsToolbar ${!container ? 'ContainerIsNotAvailable' : ''} ${className}`}>
      <BngIconButton
        iconProps={{ className: `DashboardItemOptsButton ${isZoomEnabled ? 'enabled' : ''}` }}
        icon="zoom_in"
        title={context.msg.t('ZOOM')}
        disabled={disableZoomButton || editMode}
        onClick={() => {
          BimEventBus.emit(ECHARTS_ZOOM_CLEAR_EVENT, { emitterId: instanceId });
          if (isZoomEnabled) {
            UiMsg.dismiss('bubbleChartToast');
          }
        }}
      />
      <BngIconButton
        iconProps={{ className: `DashboardItemOptsButton` }}
        icon="redo"
        title={context.msg.t('bubble.chart.zoom.out')}
        disabled={!isZoomOutEnabled || editMode}
        onClick={() => {
          chartInstance.dispatchAction({
            type: 'dataZoom',
            start: 0,
            end: 100,
          });
        }}
      />
    </div>
  );

  return container ? ReactDOM.createPortal(renderResult, container) : renderResult;
}
