import React, {useEffect, useState} from 'react';
import ContextEnhancer from 'components/ContextEnhancer';
import {Field, Formik} from 'formik';

import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import {bngYup} from 'components/bng/form/yup/BngYup';
import WsBar from 'components/ui/in-memory/WsBar';
import {BngForm} from 'components/bng/form/BngForm';
import {BngField} from 'components/bng/form/BngField';
import Button from 'components/ui/Button';
import BngCheckbox from "components/bng/form/BngCheckbox";

const pipeDriveFormSchema = bngYup((yup) =>
    yup.object({
        name: yup.string().required().default(''),
        token: yup.string().required().default(''),
        updateFields: yup.boolean().default(false),
        projectId: yup.number().required(),
    })
);

const PipeDriveForm = ({
                           context: {msg},
                           open,
                           closeModal,
                           inMemoryId,
                           projectId,
                           edit = false,
                           namecube = '',
                           onClose = _.noop,
                       }) => {
    const [loading, setLoading] = useState(false);
    const [validToken, setValidToken] = useState({token: '', valid: false});
    const [initialValues, setInitialValues] = useState({
        ...pipeDriveFormSchema.default(),
        projectId,
        inMemoryId,
    });

    const isEditing = (inMemoryId ?? 0) > 0;

    const validateToken = async (token, checkUpdateFields) => {
        setLoading(true);

        try {
            let result;
            try {
                result = await Api.Pipedrive.validateToken(token);
            } catch (e) {
                result = false;
            }

            setValidToken({
                token,
                valid: result,
            });
            if (result) {
                UiMsg.ok(msg.t('pipe.drive.origin.token.isvalid'));
                checkUpdateFields?.();
            } else {
                UiMsg.error(msg.t('pipe.drive.origin.token.invalid'));
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (!edit) return;
            setLoading(true);
            try {
                const values = await Api.Pipedrive.getValuesWS(inMemoryId);
                const data = {...initialValues, ...values};
                setInitialValues(data);
                await validateToken(data.token);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const save = async (pipeDriveData, formikHelpers) => {
        try {
            if (namecube !== pipeDriveData.name) {
                const result = await Api.Pipedrive.validateName(pipeDriveData);
                if (result) {
                    formikHelpers.setFieldError(
                        'name',
                        msg.t('pipe.drive.origin.name.invalid')
                    );
                    formikHelpers.setSubmitting(false);
                    return;
                }
            }

            if (edit) {
                await Api.Pipedrive.editOrigin(inMemoryId, pipeDriveData);
            } else {
                await Api.Pipedrive.saveOrigin(pipeDriveData);
            }
            UiMsg.ok(msg.t('pipe.drive.origin.saved'));
            Api.updateJsf();
            closeModal();
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
            formikHelpers.setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={pipeDriveFormSchema}
            onSubmit={save}
            enableReinitialize
        >
            {({values, isSubmitting, isValid, setFieldValue}) => {

                const tokenIsValid = values.token === validToken.token && validToken.valid;
                return (
                    <WsBar
                        open={open}
                        title={msg.t('pipe.drive.dialog.title')}
                        onClose={() => {
                            closeModal();
                            onClose();
                        }}
                        help={msg.t('pipe.drive.title.help')}
                        href={msg.t('pipe.drive.help.link')}
                        icon={`${Api.baseUrl()}/images/data/pipe-drive.png`}
                        className={'PipeDriveForm'}
                        loading={loading || isSubmitting}
                    >
                        <BngForm>
                            <fieldset className="ws-information-container">
                                <div className="ws-information-container-left">
                                    <div>
                                        <Field
                                            name="name"
                                            component={BngField}
                                            withLabel={false}
                                            placeholder={msg.t('pipe.drive.name.visualization')}
                                        />
                                    </div>
                                    <div>
                                        <div className="informationtitle">
                                            {msg.t('pipe.drive.information.title')}
                                        </div>
                                        <div className="information">
                                            <span className="fa fa-address-card"/>
                                            {msg.t('pipe.drive.information')}
                                        </div>
                                        <div className="ws-information-details">
                                            {msg.t('pipe.drive.information.description')}
                                        </div>
                                    </div>
                                    <div>
                                        <Field
                                            name="token"
                                            component={BngField}
                                            withLabel={false}
                                            placeholder={msg.t('pipe.drive.token')}
                                        />
                                    </div>
                                </div>
                                <div className="validate-token">
                                    <Button
                                        className="btn btn-success"
                                        id="pipe-btn-ok"
                                        style={{display: tokenIsValid ? 'inline' : 'none'}}
                                    >
                                        <span className="fa fa-check"/>
                                    </Button>
                                    <Button
                                        icon="icon-ok"
                                        id="btn-validade"
                                        onClick={() =>
                                            validateToken(
                                                values.token,
                                                () => setFieldValue('updateFields', true)
                                            )}
                                        className="pull-left"
                                        disabled={_.isEmpty(values.token)}
                                    >
                                        {msg.t('pipe.drive.validate.token')}
                                    </Button>
                                </div>
                                <div className="is-connected">
                                    <span
                                        id="circle-validate"
                                        className="fa fa-circle-o"
                                        style={{color: tokenIsValid ? 'green' : 'red'}}
                                    />
                                    {msg.t(
                                        tokenIsValid
                                            ? 'pipe.drive.connected'
                                            : 'pipe.drive.disconnected'
                                    )}
                                </div>
                                {isEditing &&
                                    <div className="flex-center-items jc-center mt-4">
                                        <Field name="updateFields"
                                               component={BngCheckbox}
                                               label={msg.t('update.fields')}
                                        />
                                    </div>
                                }
                            </fieldset>
                            <div className="spansubmit">
                                <Button
                                    id="submit"
                                    icon="check"
                                    type="submit"
                                    disabled={!tokenIsValid || !isValid}
                                    className="pull-right"
                                >
                                    {msg.t('pipe.drive.go')}
                                </Button>
                            </div>
                        </BngForm>
                    </WsBar>
                );
            }}
        </Formik>
    );
};
export default ContextEnhancer(PipeDriveForm);
