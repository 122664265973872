import styles from './ValidateMultipleUpdatesStep.module.css';
import React, { useMemo } from 'react';
import { BngTable } from 'components/bng/ui/BngTable';

import ProgressBar from 'components/ui/ProgressBar';
import Icon from 'components/ui/common/Icon';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import StackTraceDialog from 'components/bng/pages/errors/StackTraceDialog';

import AppInfoLogo from 'components/bng/project/updateApps/AppInfoLogo';
import UpdateActions, { CountComponent } from 'components/bng/project/updateApps/UpdateActions';
import { countActions, MODE } from 'components/bng/project/updateApps/projectUpdateAppsCommons';
import useBimContext from 'components/hooks/useBimContext';

export default function ValidateMultipleUpdatesStep({
  appsToUpdate,
  diffResults,
  appBeingUpdated,
  appsUpdateStatus,
  onCancel,
  runAppInstall,
  keepData,
  formActions,
  mode,
}) {
  const { msg } = useBimContext();
  const rows = appsToUpdate.map((appInstall) => {
    const appInstallId = appInstall.install.id;
    const updating = appBeingUpdated?.install.id === appInstallId;
    const { actions, dataSources } = keepData
      ? formActions
      : diffResults[appInstallId] ?? { actions: [], dataSources: [] };

    const { finished, error } = appsUpdateStatus[appInstallId] ?? {
      finished: Object.values(countActions(actions)).every((v) => v === 0) && dataSources.length === 0,
    };

    const errorMessage = error ? error.response?.data?.message || error.message || error : '';

    return {
      appInstall,
      canceled: appInstall.canceled ?? false,
      updating,
      actions,
      dataSources,
      finished,
      errorMessage,
    };
  });

  const isUnlinkProjectMode = mode === MODE.UNLINK_PROJECT;
  const tableCols = useMemo(() => {
    const cols = [
      {
        size: `${isUnlinkProjectMode ? 92 : 35}%`,
        label: msg.t('customer'),
        render: (row) => (
          <div className="AppInfo flex-center-items gap-2">
            <AppInfoLogo appInstall={row.appInstall} className={styles.tableAppInfoLogo} />
            <span className={styles.tableSep} />
            <b>
              <small>{row.appInstall.app.caption}</small>
            </b>
          </div>
        ),
      },
    ];
    if (isUnlinkProjectMode) {
      cols.push({
        size: '60px',
        render: (row) => {
          return (
            <CountComponent icon="link_off" className="warning" showCount={false} title={msg.t('remove.app.link')} />
          );
        },
      });
    } else {
      cols.push({
        size: '250px',
        label: msg.t('changes'),
        render: (row) => {
          return <UpdateActions actions={row.actions} dataSources={row.dataSources} />;
        },
      });
      cols.push({
        size: '200px',
        label: msg.t('progress'),
        render: (row) => {
          if (row.canceled) return null;
          const progressProps = {
            animated: false,
            progress: row.finished ? 100 : 0,
            type: row.finished ? 'success' : 'info',
          };

          let textType = row.finished ? 'text-success' : '';
          if (row.updating) {
            progressProps.animated = true;
            progressProps.progress = 50;
            progressProps.type = 'info';
            textType = 'text-info';
          }
          if (row.errorMessage) {
            progressProps.type = 'danger';
            textType = 'text-error';
          }
          return (
            <div className="flex-center-items gap-2">
              <ProgressBar
                animated={progressProps.animated}
                striped={progressProps.animated}
                progress={progressProps.progress}
                type={progressProps.type}
                style={{ width: '130px' }}
              />
              <span className={textType}>{row.errorMessage ? msg.t('error') : `${progressProps.progress}%`}</span>
            </div>
          );
        },
      });
      cols.push({
        size: '60px',
        render: (row) => {
          let body;
          if (row.finished && !row.errorMessage) {
            body = <Icon icon="check_circle" className={`${styles.progressIcon} text-success`} />;
          } else if (row.updating) {
            body = <Icon icon="refresh" className={`${styles.progressIcon} spin-element`} />;
          } else if (row.canceled) {
            body = <Icon icon="block" className={`${styles.progressIcon} text-error`} title={msg.t('cancelled')} />;
          } else {
            body = (
              <BngDropdown
                options={[
                  [
                    {
                      label: msg.t('try.again'),
                      icon: 'refresh',
                      onClick: () => {
                        runAppInstall(row.appInstall);
                      },
                      disabled: !(row.finished && row.errorMessage),
                    },
                  ],
                  [
                    {
                      label: msg.t('view.log'),
                      icon: 'info',
                      onClick: () => {
                        StackTraceDialog({
                          title: 'Log',
                          html: row.errorMessage,
                        });
                      },
                      disabled: !(row.finished && row.errorMessage),
                    },
                    {
                      label: msg.t('cancel'),
                      icon: 'block',
                      onClick: () => {
                        onCancel(row.appInstall);
                      },
                      disabled: rows.length < 2 || row.updating || row.finished,
                    },
                  ],
                ]}
                overDialog
              />
            );
          }
          return <div className="text-center">{body}</div>;
        },
      });
    }
    return cols;
  }, [mode]);

  return (
    <div className={`ValidateMultipleUpdatesStep flex-center-items flex-wrap gap-3`}>
      <BngTable rows={rows} cols={tableCols} />
    </div>
  );
}
