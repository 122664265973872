import 'components/ui/presentation/DropdownSelectVisualization.css';

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import Popper from "components/bng/ui/BngPopper";
import BngClickOutsideOverlay from "components/bng/ui/BngClickOutsideOverlay";
import Icon from "components/ui/common/Icon";


class DropdownSelectVisualization extends Component {

    static propTypes = {
        presentationValues: PropTypes.object,
        onUpdate: PropTypes.func,
        item: PropTypes.object,
    };

    static defaultProps = {
        presentationValues: {},
        onUpdate: () => {},
        item: {},
    };

    state = {
        displayMenu: false,
        open: false,
    };

    options = {
        "chart": {icon: "insert_chart", value: "CHART"},
        "table": {icon: "table_chart", value: "TABLE"},
    };

    updateValue = (value) => {
        this.props.onUpdate(value, this.props.item, 'visualization');
        this.setState({open: false})
    };

    openOptions = (event) => {
        this.setState({
            popperRef: event.currentTarget,
            open: true,
        });
    };

    renderEnableViewOption = ({type, render = this.props.item.enableViews}) => {
        if(!render || !render[type]) return null;

        const viewType = this.options[type];
        return (
            <li onClick={() => this.updateValue(viewType.value)} className="li-dropdown">
                <Button icon={viewType.icon}
                        className="btn-only-icon select-visualization">
                    {this.props.context.msg.t(viewType.value)}
                </Button>
            </li>
        )
    };

    render() {
        const container = j('.CreatePresentationDialog')[0];
        return (
            <div>
                <a className="dropdown-menu-tst">
                    <div className="button-menu-select-visualization" onClick={this.openOptions}>
                        <div title={this.props.context.msg.t('change.presentation.analysis.visualization.chart')}
                             className="button-edit-page-presentation">
                            <Icon icon="expand_more"/>
                            <div className="visualization-label">
                                {this.props.context.msg.t(this.props.item.visualization)}
                            </div>
                        </div>
                    </div>
                    <Popper container={container} className="bng-dropdown-parent"
                            open={this.state.open}
                            anchorEl={this.state.popperRef}
                            modifiers={{
                                preventOverflow: {
                                    boundariesElement: container,
                                }
                            }}>
                        <BngClickOutsideOverlay onClick={() => this.setState({open: false})} container={container}/>
                        <ul className="bng-dropdown select-visualization">
                            {this.renderEnableViewOption({type: "chart"})}
                            {this.renderEnableViewOption({type: "table"})}
                        </ul>
                    </Popper>
                </a>
            </div>
        );
    }
}

export default ContextEnhancer(connect()(DropdownSelectVisualization));