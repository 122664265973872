import './BngIconButton.css';

import React from 'react';
import Icon from 'components/ui/common/Icon';

export function BngIconButton({
  type = 'button',
  icon = 'save',
  className = '',
  size = '',
  text = '',
  iconProps = {},
  onClick = _.noop,
  ...props
}) {
  return (
    <button type={type} className={`BngIconButton ${className} ${size}`} onClick={onClick} {...props}>
      <Icon icon={icon} {...iconProps} />
      {text && <span>{text}</span>}
    </button>
  );
}

export default BngIconButton;
