import './BngForm.css';

import React from 'react';
import { Form } from 'formik';

export function BngForm({ className = '', noValidate = true, children, ...props }) {
  return (
    <Form className={`${className} BngForm`} noValidate={noValidate} {...props}>
      {children}
    </Form>
  );
}

export default BngForm;
