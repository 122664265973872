import './Tooltip.css';

import React from 'react';
import Tippy from '@tippyjs/react';
import {followCursor} from 'tippy.js';

export default function Tooltip({ allowHTML = true, delay = [300, 100], zIndex = 9381050, title, children, ...props }) {
  const wrappedTitle = title ? (
    <span className="tooltip" style={{ overflowWrap: 'break-word' }}>
      {title}
    </span>
  ) : undefined;
  return (
    <Tippy allowHTML={allowHTML} delay={delay} zIndex={zIndex} content={wrappedTitle} className="Tooltip" plugins={[followCursor]} {...props}>
      {children}
    </Tippy>
  );
}
