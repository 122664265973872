import "./NewsHighlight.css"

import React, {Component} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";

class NewsHighLight extends Component {

    static cardLimitSize = 292;

    static propTypes = {};

    static defaultProps = {};

    state = {
        news: [],
        limit: 5,
        open: true,
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.updateLimit = this.updateLimit.bind(this);
    }

    async componentDidMount(){
        this.updateLimit();
        window.addEventListener("resize", this.updateLimit);

        try {
            const news = (await Api.News.bimAcademy());
            this.setState({news: news})
        } catch (e) {
            console.warn('Failed to fetch News from service', e);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateLimit);
    }

    updateLimit() {
        this.setState({limit: Math.floor(document.getElementById("home-academy").offsetWidth/NewsHighLight.cardLimitSize)});
    }

    handleChange(){
        this.setState({open : !this.state.open});
    }

    render(){

        var pagination = '';
        var bodyRender = '';

        const hasContent = this.state.news.length > 0;

        const limit = this.state.limit;
        const divWidth = NewsHighLight.cardLimitSize * limit;

        if (hasContent) {
            jQuery.each(this.state.news, function (index, news) {

                if (index % limit == 0) {
                    var tab = index / limit;
                    var activeTab = tab == 0 ? 'active' : '';
                    bodyRender += `<div class="highlight-nav-tab highlight-nav-tab-${tab} ${activeTab}">`;
                    pagination += `<a href="#" onclick="j('.highlight-nav-tab').removeClass('active'); j('.highlight-nav-tab-${tab}').addClass('active'); j('.widget-footer-pg-button-highlights').removeClass('active'); j('.widget-footer-pg-button-highlights-tab-${tab}').addClass('active');"><div class="widget-footer-pg-button widget-footer-pg-button-highlights widget-footer-pg-button-highlights-tab-${tab} ${activeTab}"></div></a>`;
                }
                bodyRender += `<div class="highlight-note-content-item">
                                <a href=${news.link} target="_blank" rel="noreferrer nofollow">
                                    <div class="highlight-note-content-item-card">
                                            <div class="highlight-note-content-item-image">
                                                <img src=${news.image_local}/>
                                            </div>
                                            <div class="highlight-note-content-item-desc">
                                            <div class="highlight-note-content-item-title">${news.title}</div>
                                            <div class="highlight-note-content-item-description">${news.brief}</div>
                                        </div>
                                    </div>
                                </a>
                               </div>`;
                if (index % limit == limit-1) {
                    bodyRender += '</div>';
                }
            });
        }

        return (
            hasContent ?
            (<div className="widget-box">
                <div className="widget-header">
                    <div className="expandable-header">
                        {this.props.context.msg.t('bim.news.bimacademy')}
                        {/*<i className="material-icons arrow"
                           onClick={this.handleChange}>{this.state.open ? "keyboard_arrow_up" : "keyboard_arrow_down"}</i>*/}
                    </div>
                </div>
                <div className={this.state.open ? "widget-body" : "widget-body hide"}>
                    <div className="highlight-items">
                        <div style={{width: divWidth}} className="highlight-items-center" dangerouslySetInnerHTML={{__html: bodyRender}}>
                        </div>
                    </div>
                </div>
                <div className={this.state.open ? "widget-footer" : "widget-footer hide"}>
                    <div className="widget-footer-pg-buttons widget-footer-pg-button-highlights" dangerouslySetInnerHTML={{__html: pagination}}>
                    </div>
                </div>
            </div>)
            : null
        );
    }
}

export default ContextEnhancer(NewsHighLight);