import './RecentActivities.css';

import React, { Component } from 'react';
import ContextEnhancer from 'components/ContextEnhancer';
import { ScrollContainer } from 'components/ui/ScrollContainer';
import Activity from 'components/ui/recent-activities/Activity';
import Api from 'components/Api';
import UiBlocker from 'components/bng/ui/UiBlocker';
import UiMsg from 'components/ui/UiMsg';

class RecentActivities extends Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    activities: [],
    pagedActivities: [],
    page: 0,
    loading: true,
  };

  async componentDidMount() {
    const { project, user } = this.props.context;
    this.setState({ loading: true });
    try {
      const data = await Api.User.findLastActivities(project.id, user.id);
      const pagedActivities = _.chunk(data, 5);
      this.setState({ activities: data, pagedActivities });
    } catch (error) {
      console.error('Error fetching activities:', error);
      UiMsg.ajaxError(null, e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { context } = this.props;
    const { pagedActivities, page, loading } = this.state;
    const activities = pagedActivities[page] || [];

    return (
      <UiBlocker className="RecentActivities widget-box" block={loading} style={{ zIndex: 0 }}>
        <div className="widget-header">{context.msg.t('recent_activities')}</div>
        <div className="widget-body">
          <ScrollContainer className="widget-body-inner">
            {activities.map((activity) => (
              <Activity activity={activity} key={activity.id} />
            ))}
          </ScrollContainer>
        </div>

        <div className="widget-footer">
          <div className="widget-footer-pg-buttons widget-footer-pg-buttons-activity">
            {_.range(pagedActivities.length).map((pageNumber) => (
              <a
                href="#"
                key={pageNumber}
                onClick={(e) => {
                  e.preventDefault();
                  return this.setState({ page: pageNumber });
                }}
              >
                <div
                  className={`widget-footer-pg-button widget-footer-pg-button-activity ${
                    this.state.page === pageNumber ? 'active' : ''
                  }`}
                />
              </a>
            ))}
          </div>
        </div>
      </UiBlocker>
    );
  }
}

export default ContextEnhancer(RecentActivities);
