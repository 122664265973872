import { Axios } from 'commonUtils';

const endpoint = '/spr/accountManagers';

class AccountManagerApi {
  findAll = async () => {
    return await Axios.getData(endpoint);
  };

  promoteToAccountManager = async (email = '') => {
    await Axios.postData(endpoint, { email });
  };

  delete = async (email = '') => {
    return await Axios.delete(endpoint, { params: { email } });
  };
}

export default AccountManagerApi;
