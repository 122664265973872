import React, { useEffect, useMemo, useState } from 'react';
import { Axios } from 'commonUtils';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BngTable from 'components/bng/ui/BngTable';
import useBimContext from 'components/hooks/useBimContext';
import BngButton, { Size } from 'components/bng/ui/BngButton';
import BngIconButton from 'components/bng/ui/BngIconButton';
import UiMsg from 'components/ui/UiMsg';

const findOrphanTables = async () => {
  const { data } = await Axios.get('/spr/originMaintenances/orphans');

  const tables = Object.entries(data.olapServers).flatMap(([olap, schemas]) => {
    return Object.entries(schemas).flatMap(([schema, tables]) => {
      return tables.map((table) => {
        return {
          olap,
          schema,
          table,
        };
      });
    });
  });

  const schemas = Object.entries(data.schemas).map(([schema, olap]) => {
    return {
      olap,
      schema,
    };
  });

  return [...schemas, ...tables];
};

export default function OrphanDwTablesPage({ className = '' }) {
  const { msg } = useBimContext();

  const [loading, setLoading] = useState(false);
  const [orphanTables, setOrphanTables] = useState([]);

  const reload = async () => {
    setLoading(true);
    try {
      const data = await findOrphanTables();
      setOrphanTables(data);
    } catch (e) {
      console.error('Error on findOrphanTables()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reload();
  }, []);

  const tableCols = useMemo(
    () => [
      {
        label: msg.t('olap'),
        render(row) {
          return row.olap;
        },
        size: 200,
      },
      {
        label: msg.t('schema'),
        render(row) {
          return row.schema;
        },
        size: 200,
      },
      {
        label: msg.t('table'),
        render(row) {
          return row.table;
        },
      },
      {
        label: msg.t('actions'),
        render(row, idx) {
          return (
            <BngIconButton
              icon="delete"
              onClick={async () => {
                setLoading(true);
                try {
                  await Axios.delete('/spr/originMaintenances/orphans', { params: row });
                  await reload();
                } catch (e) {
                  console.error('Error while removing orphan table', { row }, e);
                  UiMsg.ajaxError(null, e);
                } finally {
                  setLoading(false);
                }
              }}
            />
          );
        },
        size: 100,
      },
    ],
    []
  );

  return (
    <UiBlocker className={`OrphanDwTablesPage mt-3 ${className}`} block={loading}>
      <div className="flex-center-items">
        <BngButton icon="refresh" onClick={reload} className="ml-auto" />
      </div>

      <div className="mt-3" style={{ height: 'calc(100vh - 125px)', overflow: 'auto' }}>
        <BngTable
          rows={orphanTables}
          cols={tableCols}
          emptyAlertProps={{ title: msg.t('orphan.dw.tables.empty.msg'), message: ' ' }}
          showEmptyAlert
          stickyHeader
        />
      </div>
    </UiBlocker>
  );
}
