import React from 'react';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import ConnectionsTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ConnectionsTab';
import useTranslation from 'components/hooks/useTranslation';

export default function BimQueryConnectionsMenuItem({ toggleAccordion = _.noop, ...props }) {
  const { t } = useTranslation();
  return (
    <RightMenuItem
      title={t('bim.query.menu.item.title.connections')}
      icon="cloud_upload"
      className={`BimQueryConnectionsMenuItem`}
      onClick={async () => {
        return toggleAccordion(ConnectionsTab, BimQueryConnectionsMenuItem.KEY);
      }}
      {...props}
    />
  );
}

BimQueryConnectionsMenuItem.KEY = 'BimQueryConnectionsMenuItem';
