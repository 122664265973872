import styles from './AddonActivateDialog.module.css';

import React, { useEffect, useMemo, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import LinkedObjects from 'components/ui/LinkedObjects';
import Avatar from 'components/ui/Avatar';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import Button from 'components/ui/Button';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import BngPasswordConfirmation from 'components/bng/ui/BngPasswordConfirmation';
import Utils from 'components/Utils';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

export const removeUserDuplicates = (requestArray) => {
  return Object.values(
    requestArray.reduce((acc, el) => {
      if (!acc.hasOwnProperty(el.user.email)) {
        acc[el.user.email] = el.user;
      }
      return acc;
    }, {})
  );
};

const removeAddonDuplicates = (requestArray) => {
  return Object.values(
    requestArray.reduce((acc, el) => {
      if (!acc.hasOwnProperty(el.addon)) {
        acc[el.addon] = el.addon;
      }
      return acc;
    }, {})
  );
};

export function ActivateAddonComponent({ currentAddon, userRequests, onSubmit = _.noop, projectId = null }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [passVerified, setPassVerified] = useState(false);

  const rejectRequest = async () => {
    try {
      await Api.Account.changeAddonRequestStatus(currentAddon.id, projectId || context.project.id, false);
      await onSubmit(true);
    } catch (e) {
      console.error('Error on rejectRequest()', e);
      if (e.response.status === 409) {
        UiMsg.error(context.msg.t('addon.request.conflict'));
      } else {
        UiMsg.error(context.msg.t('addon.request.error'));
      }
    }
  };

  const confirmEnableAddon = async () => {
    dispatch(
      window.Actions.MODALS.open(BngPasswordConfirmation, {
        onConfirm: async () => {
          await enableAddon();
          setPassVerified(true);
        },
      })
    );
  };

  const enableAddon = async () => {
    try {
      await Api.Account.changeAddonRequestStatus(currentAddon.id, projectId || context.project.id, true);
      UiMsg.ok(context.msg.t('addon.enabled.successfully'));
      await onSubmit(true);
    } catch (e) {
      console.error('Error on enableAddon()', e);
      if (e.response?.status === 409) {
        UiMsg.error(context.msg.t('addon.request.conflict'));
      } else {
        UiMsg.error(context.msg.t('addon.request.error'));
      }
    }
  };

  const userNamesString =
    userRequests.length === 1
      ? Utils.Users.displayName(userRequests[0])
      : `${Utils.Users.displayName(userRequests[0])}, ${Utils.Users.displayName(userRequests[1])} ${
          userRequests.length > 2 ? ` +${userRequests.length - 2}` : ''
        }`;

  return (
    <>
      <div className={styles.headerWrapper}>
        <LinkedObjects
          itemWidth={50}
          items={userRequests || []}
          itemNumber={5}
          render={(user) => {
            return (
              <div
                key={`${user.container ? 'GROUP' : 'USER'}-${user.id}`}
                className={`${styles.userAvatarOuter} ${!user.avatarLink ? styles.groupIcon : ''}`}
                title={Utils.Users.displayName(user)}
              >
                {user.avatarLink ? (
                  <Avatar userId={user.id} className={`${styles.userAvatar}`} />
                ) : (
                  <Icon icon="group" style={{ fontSize: '25px' }} />
                )}
              </div>
            );
          }}
        />

        <Icon icon="east" className={styles.arrowIcon} />
        <Icon icon={currentAddon?.iconName} className={styles.addonIcon} />
      </div>

      <div className={styles.addonActivateRequestTitleWrapper}>
        <span className={styles.addonActivateRequestTitle}>
          <span className="addonActivateRequestTitleBold">{userNamesString}</span>
          &ensp;
          {context.msg.t(userRequests.length === 1 ? 'activation.request.by.one' : 'activation.request.by.multiple')}
          &ensp;
          <span className="addonActivateRequestTitleBold">{context.msg.t(currentAddon?.nameKey)}</span>
        </span>
      </div>
      <div>
        <span
          className={styles.addonActivateRequestDescription}
          dangerouslySetInnerHTML={{ __html: context.msg.t(currentAddon?.longDescKey) }}
        />
      </div>
      <div className={styles.addonActionButtonWrapper}>
        <Button className={`${styles.addonsActionButtons} ${styles.rejectButton}`} onClick={rejectRequest}>
          {context.msg.t('reject')}
        </Button>
        <Button
          className={`${styles.addonsActionButtons} ${styles.activateButton}`}
          onClick={passVerified ? enableAddon : confirmEnableAddon}
        >
          {context.msg.t('enable')}
        </Button>
      </div>
    </>
  );
}

export default function AddonActivateDialog({ closeModal = _.noop, selectedAddon = null }) {
  const context = useBimContext();
  const [addons, setAddons] = useState([]);
  const [activationRequests, setActivationRequests] = useState([]);
  const [currentAddon, setCurrentAddon] = useState(selectedAddon);
  const [loading, setLoading] = useState(false);
  const [swipeAnimation, setSwipeAnimation] = useState(null);

  const currentRequests = useMemo(
    () => activationRequests.filter((request, idx) => request.addon === currentAddon?.id),
    [currentAddon, activationRequests]
  );
  const userRequests = useMemo(() => removeUserDuplicates(currentRequests), [currentAddon, activationRequests]);
  const requestedAddons = useMemo(() => removeAddonDuplicates(activationRequests), [currentAddon, activationRequests]);
  const currentAddonIndex = useMemo(() => requestedAddons.indexOf(currentAddon?.id), [
    currentAddon,
    activationRequests,
  ]);

  const fetchAddons = async (fullRefresh) => {
    setLoading(true);
    try {
      const addons = await Api.Account.findAccountAddons(context.accountId);

      if (addons.activationRequests.length === 0) {
        closeModal();
        window.location.reload();
        return;
      }

      setAddons(addons.allAddons);
      setActivationRequests(addons.activationRequests);

      if (!currentAddon || fullRefresh) {
        setCurrentAddon(addons.allAddons.find((addon) => addon.id === addons.activationRequests[0].addon));
      }
    } catch (e) {
      console.error('Error on fetchAddons()', e);
      UiMsg.error('error', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAddons();
    })();
  }, []);

  const changeAddon = async (changeToNext) => {
    setSwipeAnimation({ next: changeToNext });
    const nextId = currentAddonIndex + (changeToNext ? 1 : -1);
    if ((nextId < 0 && !changeToNext) || nextId > requestedAddons.length - 1) return;
    // Wait for the animation to change the addon
    await Utils.sleep(250);
    setCurrentAddon(addons.find((addon) => addon.id === requestedAddons[nextId]));
  };

  return (
    <>
      <BngIconButton icon="close" onClick={closeModal} className={styles.closeAddonDialogButton} />
      <Dialog
        className={`AddonActivateDialog ${styles.AddonActivateDialog}`}
        hideHeader={true}
        onClose={closeModal}
        backdropClassName={styles.AddonActivateDialogBackdrop}
        loading={loading}
      >
        {requestedAddons.length > 1 && (
          <div className={styles.changeAddonButtonWrapper}>
            <BngIconButton
              icon="chevron_left"
              className={`changeAddonButtonLeft ${styles.changeAddonButton} ${styles.leftButton} ${
                currentAddonIndex === 0 ? styles.disabled : ''
              }`}
              onClick={async () => {
                if (currentAddonIndex > 0) {
                  await changeAddon(false);
                }
              }}
            />
            <BngIconButton
              icon="chevron_right"
              className={`changeAddonButtonRight ${styles.changeAddonButton} ${styles.rightButton} ${
                currentAddonIndex === requestedAddons.length - 1 ? styles.disabled : ''
              }`}
              onClick={async () => {
                if (currentAddonIndex < requestedAddons.length - 1) {
                  await changeAddon(true);
                }
              }}
            />
          </div>
        )}
        <Dialog.Body>
          <div
            className={`
            ${styles.animationWrapper}
            ${
              swipeAnimation
                ? swipeAnimation.next
                  ? styles.addonChangeAnimationRight
                  : styles.addonChangeAnimationLeft
                : ''
            }
            `}
            onAnimationEndCapture={() => {
              setSwipeAnimation(null);
            }}
          >
            <ActivateAddonComponent currentAddon={currentAddon} userRequests={userRequests} onSubmit={fetchAddons} />
          </div>
        </Dialog.Body>
        {requestedAddons.length > 1 && (
          <div className={styles.selectAddonButtonWrapper}>
            {requestedAddons.map((addonKey, idx) => {
              const addonSelected = addonKey === currentAddon?.id;
              const addonValue = addons.find((addon) => addon.id === requestedAddons.at(idx));
              const next = idx > requestedAddons.indexOf(currentAddon?.id);

              return (
                <Button
                  className={`${styles.selectAddonButton} ${addonSelected ? styles.selected : ''}`}
                  onClick={() => {
                    if (addonSelected) return;
                    setSwipeAnimation({ next: next });
                    setCurrentAddon(addonValue);
                  }}
                />
              );
            })}
          </div>
        )}
      </Dialog>
    </>
  );
}
