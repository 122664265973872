import './ProfileDialog.css';
import React, {Component} from "react";
import PropTypes from "prop-types";
import UiMsg from "../UiMsg";
import Api from "components/Api";
import Icon from "components/ui/common/Icon";

class AvatarCarousel extends Component {

    static propTypes = {
        avatars: PropTypes.array,
        defaultImage: PropTypes.string,
        newUser: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        avatars: [],
        defaultImage: '',
        disabled: false,
    };

    state = {
        actualImage: -1
    };

    constructor(props) {
        super(props)
    }

    componentDidUpdate(nextProps){
        if(nextProps.newUser !== this.props.newUser && this.props.newUser){
            this.setImageFromAvatars();
        }
    }

    handleSelectFile = (e) => {
        const files = e.target.files;
        if (files[0].type.includes('image')) {
            this.handleChange({
                link: '',
                file: files[0],
            });
        } else {
            UiMsg.error('Somente arquivos do tipo imagem são suportados');
        }
    };

    handleCarouselBack = () => {
        const actualImageIdx = this.getAvatarIndex(this.props.field.value.link);
        const avatarIdx = (actualImageIdx > 0 ? actualImageIdx : this.props.avatars.length) - 1;
        this.setImageFromAvatars(avatarIdx);
    };

    handleCarouselFront = () => {
        const actualImageIdx = this.getAvatarIndex(this.props.field.value.link);
        const avatarIdx = actualImageIdx < this.props.avatars.length - 1 ? actualImageIdx + 1 : 0;
        this.setImageFromAvatars(avatarIdx);
    };

    setImageFromAvatars = (idx = 0) => {
        this.handleChange({
            link: `/resources/images${this.props.avatars[idx]}`,
            file: null,
        });
    } ;

    getAvatarIndex = (link = '') => {
        link = link.replace('/resources/images', '');
        return this.props.avatars.indexOf(link);
    };

    handleChange = (vals) => {
        this.props.form.setFieldValue(this.props.field.name, vals);
    };

    render() {
        const {field, disabled} = this.props;

        let isCustom = false;
        let imgSrc = '';

        if(typeof field.value === 'string'){
            imgSrc = Api.baseUrl() + field.value;
        } else if (field.value.file) {
            isCustom = true;
            imgSrc = window.URL.createObjectURL(field.value.file);
        } else {
            isCustom = this.getAvatarIndex(field.value.link) === -1;
            imgSrc = Api.baseUrl() + field.value.link;
        }


        return (
            <div className="profile-carousel">
                {!isCustom &&
                <div className="avatar-carousel-button left" onClick={this.handleCarouselBack}>
                    <Icon icon="keyboard_arrow_left" />
                </div>
                }
                <div className="profile-image">
                    <img alt="user" src={imgSrc} align="middle"/>
                    {!disabled &&
                        <React.Fragment>
                            <input type="file" accept="image/*" className="hidden-input-file"
                                   onClick={isCustom ? e => {
                                       e.preventDefault();
                                       this.setImageFromAvatars(0);
                                   } : undefined}
                                   onChange={this.handleSelectFile}/>
                            <span className="upload">{isCustom ? 'Alterar' : 'Upload'}</span>
                        </React.Fragment>
                    }
                </div>
                {!isCustom &&
                <div className="avatar-carousel-button right" onClick={this.handleCarouselFront}>
                    <Icon icon="keyboard_arrow_right" />
                </div>
                }
            </div>
        )
    }

}

export default AvatarCarousel;