import React, { useState } from 'react';
import BaseTableStep, { TableCheckbox } from 'components/bng/project/updateApps/BaseTableStep';
import { ACTION } from 'components/bng/project/updateApps/projectUpdateAppsCommons';
import useBimContext from 'components/hooks/useBimContext';

const toggleActionValue = (nextState, actions, row) => {
  if (nextState) {
    actions[row.sourcePath] = {
      object: row,
      action: ACTION.CREATE,
    };
  } else {
    delete actions[row.sourcePath];
  }
};

function AddObjectsWizardStep({ rows, actions = {}, onChange }) {
  const { msg } = useBimContext();
  const [allCheckbox, setAllCheckbox] = useState(true);

  const toggleItem = (nextState, row) => {
    const actionsCopy = { ...actions };
    toggleActionValue(nextState, actionsCopy, row);
    onChange(actionsCopy);
  };

  return (
    <BaseTableStep
      alertMessage={msg.t('project.update.app.dialog.add.alert')}
      rows={rows}
      cols={[
        {
          key: 'add',
          size: '125px',
          headerRender: () => {
            return (
              <TableCheckbox
                label={msg.t('check.all')}
                labelStyle={{ marginLeft: -26 + 'px' }}
                value={allCheckbox}
                onChange={() => {
                  const nextState = !allCheckbox;
                  const actionsCopy = { ...actions };
                  rows.forEach((row) => toggleActionValue(nextState, actionsCopy, row));
                  setAllCheckbox(nextState);
                  onChange(actionsCopy);
                }}
              />
            );
          },
          render: (row) => {
            const selected = row.sourcePath in actions;
            return (
              <TableCheckbox
                label={msg.t('add')}
                value={selected}
                onChange={() => toggleItem(!selected, row)}
                labelVisible={false}
              />
            );
          },
        },
      ]}
    />
  );
}

export default AddObjectsWizardStep;
