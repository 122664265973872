import React from "react";
import {ceData} from "components/CeData";

function isStateless(componentRef) {
    return _.isUndefined(componentRef?.prototype?.render);
}

const ContextEnhancer = ComposedComponent => {
    return class extends React.Component {

        constructor(props) {
            super(props);
        }

        render() {
            return (
                <ComposedComponent {...this.props}
                                   context={ceData.context}
                                   ref={isStateless(ComposedComponent) ? undefined : ref => this.wrappedComponent = ref}
                />
            )
        }

    };
};

export default ContextEnhancer;