import './EditObject.css';
import React, {useEffect, useRef} from 'react';
import ContextEnhancer from 'components/ContextEnhancer';

const EditObject = ({
  context = {},
  className = '',
  onChange = _.noop,
  loading = false,
  checked = false,
  disabled = false,
  title,
}) => {
  const handleChange = async (event) => {
    if (loading) return;
    event.persist();
    await onChange(event);
  };

  const $labelRef = useRef(null);
  const $labelContainerRef = useRef(null);

  useEffect(() => {
    if ($labelRef.current && $labelContainerRef.current) {
      if (checked) {
        $labelRef.current.style.left = '8px';
      } else {
        $labelRef.current.style.left = ($labelContainerRef.current.clientWidth - $labelRef.current.clientWidth) - 8 + 'px';
      }
    }
  }, [$labelRef, checked])

  return (
    <div
      id="react-render-edit-object"
      className={`EditObject floating-edit-object ${className} ${loading ? 'WaitingLoading' : ''} ${disabled ? 'Disabled' : ''}`}
    >
      <label style={{height: 'fit-content', width: '100%'}}>
        <input
          id="edit-object-btn"
          className="ace ace-switch edit-switch"
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={loading || disabled}
        />
        <span className="spanContainer lbl" ref={$labelContainerRef}>
          <span className="edit-object-label" ref={$labelRef}>{title ? title : context.msg.t('edit.button')}</span>
        </span>
      </label>
    </div>
  );
};

export default ContextEnhancer(EditObject);
