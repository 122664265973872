import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui/common/Icon';
import { ObjectErrorPopup } from 'components/ui/dashboard/components/ObjectErrorPopup';

function DefaultErrorComponent({ showErrorDialog }) {
  return (
    <Icon
      icon="error_outline"
      onClick={showErrorDialog}
      className="DefaultErrorComponent pointer"
      style={{ color: 'red' }}
    />
  );
}

class BngErrorBoundary extends React.Component {
  static propTypes = {
    errorComponent: PropTypes.any,
  };

  static defaultProps = {
    errorComponent: DefaultErrorComponent,
  };

  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.trace?.('Error trace', error);
    console.error('Error while rendering element: \n', error);
    console.error('More error info: \n', errorInfo);
  }

  render() {
    const ErrorComponent = this.props.errorComponent;
    if (this.state.hasError) {
      return (
        <ErrorComponent
          error={this.state.error}
          snackbarType="error"
          showErrorDialog={() => {
            console.error('Showing error', this.state.error);
            ObjectErrorPopup({ message: this.state.error ?? '' });
          }}
          {...this.props}
        />
      );
    }
    return this.props.children;
  }
}

export default BngErrorBoundary;
