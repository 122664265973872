import React from 'react';

import Api from 'components/Api';

const AppInfoLogo = ({ appInstall, className = '' }) => {
  let logo = appInstall?.app?.logo || '';
  if (!logo.startsWith('http')) {
    logo = `${Api.baseUrl()}/${logo}`;
  }
  return (
    <div className={className}>
      <img
        src={logo}
        style={{ maxWidth: '100%', maxHeight: 30 }}
        alt="project logo"
      />
    </div>
  );
};

export default AppInfoLogo;
