import ModalInformation from 'components/ui/ModalInformation';
import UiMsg from 'components/ui/UiMsg';

export function showQueryError(error, msg, additionalOpts = {}) {
  if (error?.status === 'NO_CONTENT') {
    UiMsg.warn(
      `${msg.t('attention')}!`,
      msg.t('invalid.date.filter.alert', [msg.t(error.periodicity)])
    );
    return;
  }

  const title = msg.t('map.query.error.title');
  let details = '';

  const isTimeout = ('' + error)
    .toLowerCase()
    .includes('uncheckedtimeoutexception');
  if (isTimeout) {
    details = msg.t('map.query.error.timeout');
  }

  const text = `
                <div>
                <p>${details}</p> 
                ${details ? '<br/>' : ''}
                <a href="#" class="btn btn-link" onclick="Swal.fire('', document.getElementById('map-query-error').innerHTML)">
                    <i class="fa fa-info-circle"></i> ${msg.t(
                      'view_unavaliable_error'
                    )}
                </a>
                <div id="map-query-error" style="display: none">${error}</div>
                </div>
            `;

  ModalInformation.ShowMessage({
    title,
    text: text,
    icon: 'warning',
    customClass: {
      container: 'MapQueryErrorDialog',
    },
    ...additionalOpts,
  });
}
