import React, {Component} from 'react';
import {
    BreadcrumbToolbar,
    DynamicTimeFilterToolbarItem,
    ExportToolbarItem,
    FavoriteToolbarItem,
    InformationToolbarItem,
    MobileToolbarItem,
    NotesToolbarItem,
    PublisherToolbarItem,
    ReloadToolbarItem,
    ResizeToolbarItem
} from "components/ui/breadcrumb-toolbar";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import HideHeaderToolbarItem, { HideHeaderPageOpts } from 'components/ui/breadcrumb-toolbar/HideHeaderToolbarItem';

class AnalysisBreadcrumbToolbar extends Component {

    static defaultProps = {
        isNotAnalysisPersisted: false,
        path: '',
        caption: '',
        mobile: true,
        dataSourceCaption: '',
        advancedMode: false,
        isEchartsModel: false,
    };

    state = {
        sourceFields: [],
        loading: true,
        onAjax: false,
        mobile: true,
    };

    constructor(props) {
        super(props);
        this.state.mobile = props.mobile;
    }

    resizeAnalysis = async (event) => {
        event.preventDefault();
        application.reports.analysisResizeManager.resizeAnalysisChart();
    };

    reloadAnalysis = async (event) => {
        this.props.context.execute(`#{analysisBean.reloadCurrentAnalysis()}`);
    };

    componentDidMount() {
        this.setState({mobile: this.props.mobile});
    }

    toggleMobile = async (event) => {
        this.setState({onAjax: true});
        try {
            const mobile = await Api.Analysis.toggleMobile();
            this.setState({mobile});
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({onAjax: false});
        }
    };

    updateDynamicFilter = async (props) => {
        this.setState({onAjax: true});
        try {
            await Api.Analysis.setDynamicPeriodicities(props);
            if(!_.isEmpty(props.dimension)) {
                UiMsg.ok(this.props.context.msg.t('dynamic.success.applying.filter'));
            }
            await Api.updateJsf();
        } catch (e) {
            UiMsg.ajaxError(this.props.context.msg.t('dynamic.error.applying.filter'), e);
        } finally {
            this.setState({onAjax: false});
        }
    };

    render() {
        const {onAjax, mobile} = this.state;
        const {isNotAnalysisPersisted, path, caption, dataSourceCaption, advancedMode, drillableChart, isEchartsModel} = this.props;

        return (
          <BreadcrumbToolbar loading={onAjax}>
            <ExportToolbarItem
              disabled={false}
              isNotAnalysisPersisted={isNotAnalysisPersisted}
              caption={caption}
              path={path}
            />

            {this.props.context.cockpitEnabled && <HideHeaderToolbarItem location={HideHeaderPageOpts.BREADCRUMBS} />}

            <ResizeToolbarItem onClick={this.resizeAnalysis} disabled={false} />

            <ReloadToolbarItem disabled={isNotAnalysisPersisted} onClick={this.reloadAnalysis} />

            <PublisherToolbarItem path={path} caption={caption} disabled={isNotAnalysisPersisted} />

            <NotesToolbarItem disabled={isNotAnalysisPersisted} path={path} />

            <FavoriteToolbarItem disabled={isNotAnalysisPersisted} path={path} />

            <MobileToolbarItem
              disabled={isNotAnalysisPersisted}
              inactive={!mobile}
              path={path}
              onClick={this.toggleMobile}
            />

            <InformationToolbarItem
              disabled={isNotAnalysisPersisted}
              title={this.props.context.msg.t('object.information.analysis')}
              dataSourceCaption={dataSourceCaption}
              path={path}
            />

            {!this.props.context.cockpitEnabled && (
              <DynamicTimeFilterToolbarItem
                path={path}
                dataSourceCaption={dataSourceCaption}
                onChange={this.updateDynamicFilter}
                timeFilter={this.findTimeFilter}
                advancedMode={advancedMode}
                disabled={!advancedMode && !drillableChart}
              />
            )}
          </BreadcrumbToolbar>
        );
    }

    findTimeFilter = async () => {
        try {
            return await Api.Analysis.getDynamicPeriodicities();
        } catch (e) {
            UiMsg.ajaxError(null, e);
            return null;
        }
    }
}

export default ContextEnhancer(AnalysisBreadcrumbToolbar);