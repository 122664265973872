import React, {Component} from "react";
import PropTypes from "prop-types";
import {Field, Form, Formik} from "formik";

import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import Dialog from "components/ui/Dialog";
import Api from "components/Api";
import {bngYup} from "components/bng/form/yup/BngYup";
import {BngField} from "components/bng/form/BngField";
import {DefaultDialogActions} from "components/ui/FormUtils";

export const passwordValidationFunction = (msgSupplier) => {
    const lowerCase = /(?=.*[a-z])/;
    const upperCase = /(?=.*[A-Z])/;
    const numeric = /(?=.*[0-9])/;

    return (value) => {
        const msg = msgSupplier();
        if (!lowerCase.test(value)) {
            return msg.t('password.validate.lowercase');
        } else if (!upperCase.test(value)) {
            return msg.t('password.validate.uppercase');
        } else if (!numeric.test(value)) {
            return msg.t('password.validate.numeric');
        } else if (value.length < 8) {
            return msg.t('password.validate.short');
        }
    }
}

export const passwordYupRule = bngYup(yup => {
    return yup.string().min(8).required().default('');
})

const ChangePasswordSchema = bngYup(yup => {
    return yup.object().shape({
        newPassword: passwordYupRule,
        confirmPassword: yup.string().required().default(''),
    });
});

class ChangePasswordDialog extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        userId: PropTypes.number,
    };

    initialFormValues = ChangePasswordSchema.default();

    constructor(props) {
        super(props);
    }

    handleSubmit = async (values, actions) => {
        try {
            if (values.newPassword === values.confirmPassword) {
                await Api.User.changePassword(
                    this.props.userId,
                    values.newPassword,
                    this.props.context.support.projectName
                );
                UiMsg.ok(this.props.context.msg.t('user.dialog.password.success'));
                this.props.closeModal();
            } else {
                actions.setFieldError('confirmPassword', this.props.context.msg.t('passwords_dont_match'));
                actions.setSubmitting(false);
            }
        } catch (e) {
            UiMsg.error(this.props.context.msg.t('user.dialog.password.error'), e);
            console.error(e);
        }
    };

    validatePassword = passwordValidationFunction(() => this.props.context.msg);

    render() {
        return (
            <Formik initialValues={this.initialFormValues}
                    validationSchema={ChangePasswordSchema}
                    onSubmit={this.handleSubmit}>
                <Dialog open={this.props.open} className="small" title={this.props.context.msg.t('editUser_page_title')}
                        onClose={this.props.closeModal}>
                    <Form>
                        <div>
                            <Field
                                label={this.props.context.msg.t('editUser_label_newPassword')}
                                name="newPassword"
                                component={BngField}
                                type="password"
                                validate={this.validatePassword}
                            />
                            <Field
                                label={this.props.context.msg.t('editUser_label_confirmPassword')}
                                name="confirmPassword"
                                component={BngField}
                                type="password"
                            />
                        </div>
                        <DefaultDialogActions {...this.props}/>
                    </Form>
                </Dialog>
            </Formik>
        );
    }
}

export default ContextEnhancer(ChangePasswordDialog);