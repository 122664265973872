import styles from './AccFeaturesTab.module.css';

import React, { useState } from 'react';
import { Field, Formik } from 'formik';

import useTranslation from 'components/hooks/useTranslation';
import BngForm from 'components/bng/form/BngForm';
import BngYup from 'components/bng/form/yup/BngYup';
import Icon from 'components/ui/common/Icon';
import BngSwitch from 'components/bng/form/BngSwitch';
import Api from 'components/Api';
import UiBlocker from 'components/bng/ui/UiBlocker';
import UiMsg from 'components/ui/UiMsg';

const AccountConfigSchema = BngYup((yup) => {
  return yup.object({
    canRemoveProject: yup.boolean().default(false),
    showBimAcademy: yup.boolean().default(true),
    showBimStore: yup.boolean().default(true),
  });
});

function FeatureField({ icon, color, name, label, onClick = _.noop }) {
  const { t } = useTranslation();

  return (
    <div className={`FeatureField ${styles.featureItem}`}>
      <Icon icon={icon ? icon : 'public'} className={styles.featureIcon} style={{ backgroundColor: color }} />

      <div className={styles.featureDetails}>
        <span className={styles.featureTitle}>{t(label)}</span>
        <span className={styles.featureDescription}>{t(`${label}.legend`)}</span>
      </div>

      <Field
        name={name}
        component={BngSwitch}
        className={styles.featureSwitch}
        title={t('enabled')}
        onClick={onClick}
        verticalTitle
      />
    </div>
  );
}

export default function AccFeaturesTab({ account }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <UiBlocker className={`AccFeaturesTabBlocker ${styles.AccFeaturesTabBlocker}`} block={loading}>
      <div className={`AccFeaturesTab ${styles.AccFeaturesTab}`}>
        <Formik
          initialValues={{ ...AccountConfigSchema.default(), ...account.config }}
          validationSchema={AccountConfigSchema}
          onSubmit={async (values) => {
            setLoading(true);
            try {
              await Api.Account.saveAccountConfig(account.id, values);
              window.location.reload();
            } catch (e) {
              console.error('Error on function saveAccountConfig()', e);
              UiMsg.error(t('error.updating.account.features'));
            } finally {
              setLoading(false);
            }
          }}
          enableReinitialize={true}
        >
          {({ submitForm }) => {
            return (
              <BngForm className={styles.featuresForm}>
                <FeatureField
                  icon={'lock'}
                  color={'#EDA200'}
                  name={'canRemoveProject'}
                  label={'can.remove.projects'}
                  onClick={submitForm}
                />
                <FeatureField
                  icon={'book'}
                  color={'#AC4FC6'}
                  name={'showBimAcademy'}
                  label={'show.bim.academy'}
                  onClick={submitForm}
                />
                <FeatureField
                  icon={'store'}
                  color={'#528DE3'}
                  name={'showBimStore'}
                  label={'show.marketplace'}
                  onClick={submitForm}
                />
              </BngForm>
            );
          }}
        </Formik>
      </div>
    </UiBlocker>
  );
}
