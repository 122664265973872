import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";


export class GaugeKpiPercent extends BaseKpi {

    constructor(props) {
        super(props);
    }

    initComponent = () => {
        const {color} = this.state;

        let targetValue = this.props.target === 0 ? 1 : this.props.target;

        let val = this.props.measureAsTarget || !this.props.targetIsPercent ?
            this.props.percentage :
            this.props.value / targetValue;

        if (this.props.percentage && !this.props.measureAsTarget && this.props.target === 0) {
            val *= 100;
        }

        if (!this.$kpiGaugeEl) return;

        const target = this.$kpiGaugeEl;

        const pointerColor = this.props.shouldChangeColor ? '#FFFFFF' : '#000000';
        jQuery(function () {
            let opts = {
                lines: 12, // The number of lines to draw
                angle: 0.15, // The length of each line
                lineWidth: 0.44, // The line thickness
                pointer: {
                    length: 0.8, // The radius of the inner circle
                    strokeWidth: 0.035, // The rotation offset
                    color: pointerColor, // Fill color
                },
                limitMax: 'true',   // If true, the pointer will not go past the end of the gauge
                colorStart: color,   // Colors
                colorStop: color,    // just experiment with them
                strokeColor: '#E0E0E0',   // to see which ones work best for you
                generateGradient: true,
            };
            let $target = jQuery(target);
            let $targetWidth = $target.width();
            $target.attr('width', $targetWidth).attr('height', $targetWidth / 2);
            let gauge = new Gauge(target).setOptions(opts); // create sexy gauge!
            gauge.maxValue = 100; // set max gauge value
            gauge.animationSpeed = application.utils.disableAnimations() ? 1 : 32;// set animation speed (32 is default value)

            if (val > 100) {
                gauge.set(100);
            } else if (val < 0) {
                gauge.set(0);
            } else {
                gauge.set(val);
            }
        });
    };

    customResizeKpi = ({height}) => {
        if ((this.props.useBands || this.props.target > 0) && this.props.viewBands) {
            height = height - (height * 0.05);
        }

        resizeEl(this.$kpiTitleEl, {
            fontSize: {minValue: 12, multiplier: 0.10, refSize: height},
            lineHeight: {minValue: 1.13, multiplier: 0.13, refSize: height},
        });

        if (this.$kpiTitleEl.offsetWidth >= this.props.div.offsetWidth) {
            const newFontSize = height * (this.props.div.offsetWidth / this.$kpiTitleEl.offsetWidth);

            resizeEl(this.$kpiTitleEl, {
                fontSize: {minValue: 12, multiplier: 0.10, refSize: newFontSize},
                lineHeight: {minValue: 1.13, multiplier: 0.13, refSize: newFontSize},
            });
        }

        resizeEl(this.$kpiGaugeEl, {
            width: {minValue: 60, multiplier: 0.8, refSize: height},
            height: {minValue: 32, multiplier: 0.4, refSize: height},
        });

        resizeEl(this.$percentEl, {
            fontSize: {minValue: 12, multiplier: 0.08, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.08, refSize: height},
        });

        resizeEl(this.$kpiDescriptionEl, {
            fontSize: {minValue: 8, multiplier: 0.05, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.10, refSize: height},
        });

        if (this.$kpiDescriptionEl.offsetWidth >= this.props.div.offsetWidth) {
            const newFontSize = height * (this.props.div.offsetWidth / this.$kpiDescriptionEl.offsetWidth);

            resizeEl(this.$kpiDescriptionEl, {
                fontSize: {minValue: 8, multiplier: 0.05, refSize: newFontSize},
                lineHeight: {minValue: 12, multiplier: 0.13, refSize: newFontSize},
            });
        }
    };

    render() {
        return (
            <table>
                <tbody>
                <tr className="title-kpi-percent">
                    <td>
                        <div className="kpititle" style={{textAlign: 'center'}}>
                            <span ref={el => this.$kpiTitleEl = el}>{this.props.title}</span>
                        </div>
                    </td>
                </tr>
                <tr className="title-kpi-canvas">
                    <td style={{textAlign: 'center'}}>
                        <canvas ref={el => this.$kpiGaugeEl = el}/>
                    </td>
                </tr>
                <tr className="title-kpi-percent">
                    <td>
                        <div title={this.state.value} className="kpi-percent" ref={el => this.$percentEl = el}
                             style={{textAlign: 'center'}}>
                            {this.state.value}
                        </div>
                    </td>
                </tr>
                <tr className="title-kpi-description">
                    <td>
                        <div style={{textAlign: 'center'}}>
                            <span className="kpidescription"
                                  ref={el => this.$kpiDescriptionEl = el}>{this.props.label}</span>
                        </div>

                    </td>
                </tr>
                <tr>
                    <td>
                        <center>
                            <div ref={el => this.$containerBandsEl = el}>
                                {this.useBands({container: this.$containerBandsEl})}
                            </div>
                        </center>
                    </td>
                </tr>
                </tbody>
            </table>
        )
    }

}

