import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './BngPasswordConfirmation.css';

import Api from "../../Api";
import ContextEnhancer from "../../ContextEnhancer";
import Dialog from "../../ui/Dialog";
import {Field, Form, Formik} from "formik";
import {BngField} from "../form/BngField";
import {DefaultDialogActions} from "../../ui/FormUtils";
import {bngYup} from "../form/yup/BngYup";
import UiMsg from "../../ui/UiMsg";

const PasswordConfirmationSchema = bngYup(yup => yup.object().shape({
    password: yup.string().required().default(''),
}));

class BngPasswordConfirmation extends Component {

    static propTypes = {
        onConfirm: PropTypes.func,
    };

    state = {
        loading: true,
    };

    componentDidMount() {
        this.setState({loading: false});
    }

    handleSubmit = async (values, actions) => {
        this.setState({loading: true});
        try {
            const correctPassword = await Api.User.confirmPassword(values.password);
            if (correctPassword){
                this.props.onConfirm();
                this.props.closeModal();
            } else {
                UiMsg.error(this.props.context.msg.t('password.do.not.match'));
                actions.setSubmitting(false);
                this.setState({loading: false});
            }
        } catch (e) {
            UiMsg.ajaxError('error', e);
            actions.setSubmitting(false);
            this.setState({loading: false});
        }
    };

    passwordConfirmationInitialValues = PasswordConfirmationSchema.default();

    render() {
        return(
            <Dialog title={this.props.context.msg.t('confirm.your.password.to.apply.changes')}
                    className={`PasswordConfirmationDialog`}
                    onClose={this.props.closeModal}
                    loading={this.state.loading}>
                <Formik initialValues={this.passwordConfirmationInitialValues}
                        validationSchema={PasswordConfirmationSchema}
                        onSubmit={this.handleSubmit}>
                        <Form>
                            <div className="password-confirmation-body">
                                <Field name="password"
                                       type="password"
                                       component={BngField}
                                       required={true}
                                       label={this.props.context.msg.t('password')}/>
                            </div>
                            <DefaultDialogActions contentFullWidth={true} {...this.props}/>
                        </Form>
                </Formik>
            </Dialog>
        )
    }
}

export default ContextEnhancer(BngPasswordConfirmation);