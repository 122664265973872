import './TicketForm.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from 'components/ui/Dialog';
import ContextEnhancer from 'components/ContextEnhancer';
import TicketFormBody from 'components/ui/support/TicketFormBody';

class TicketForm extends Component {
  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        className="large TicketForm"
        title={this.props.context.msg.t('support.dialog.title')}
        onClose={this.props.closeModal}
      >
        <TicketFormBody {...this.props} />
      </Dialog>
    );
  }
}

export default ContextEnhancer(TicketForm);
