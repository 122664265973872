import "components/ui/cockpit/CockpitNavigator.css";

import React, {Component} from "react";
import PropTypes from "prop-types";

import ContextEnhancer from "components/ContextEnhancer";
import {BngPopperButton, BngPopperWrapper} from "components/bng/ui/BngPopperWrapper";
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import BngSearch from "components/bng/ui/BngSearch";
import Icon from "components/ui/common/Icon";

class CockpitNavigator extends Component {

    static propTypes = {
        panels: PropTypes.any,
        currentPanel: PropTypes.any,
        indexPanel: PropTypes.any,
        onSelect: PropTypes.func,
    };

    baseView = {};

    state = {
        open: false,
        container: null,
        cockpits: [],
        viewCockpits: [],
        loading: false,
        view: {
            listView: false,
            preferredSize: null,
        },
    };

    constructor(props) {
        super(props);
        this.searchChild = React.createRef();
    }

    async componentDidMount() {
        let view = this.props.context.user.preferences.cockpitPreferences || {listView: false, preferredSize: null};
        this.baseView = view;
        const container = document.getElementsByClassName('main-content cockpit-index-page')[0];
        this.setState({view, container});
    }

    changeView = () => {
        const view = {
            listView: !this.state.view.listView,
            preferredSize: this.state.view.preferredSize
        };
        this.setState({view});
    };

    findCockpits = async () => {
        if (!_.isEmpty(this.state.cockpits)) {
            return;
        }

        this.setState({loading: true});
        try {
            const cockpits = await this.getCockpits();
            this.setState({cockpits, open: true})
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    handleFavorite = async (event, cockpit) => {
        this.setState({loading: true});
        try {
            await Api.Cockpit.toggleFavorite(cockpit.id);
            const cockpits = await this.getCockpits();
            this.setState({cockpits});
        } catch (e) {
            UiMsg.ajaxError(null, e);
        } finally {
            this.setState({loading: false});
        }
    };

    getCockpits = async () => {
        let cockpits = await Api.Cockpit.findCockpits(this.props.context.project.name);
        cockpits = _.sortBy(cockpits, (c) => !c.favorite);

        const searchTerm = this.searchChild.current.getSearchTerm();
        this.updateViewCockpits(searchTerm, cockpits);

        return cockpits;
    };

    updateViewCockpits = (searchTerm, cockpit = this.state.cockpits) => {
        const viewCockpits = cockpit.filter(cockpit => cockpit.name.toUpperCase().includes(searchTerm.toUpperCase()));
        this.setState({viewCockpits})
    };

    loadCockpit = async (event, cockpit) => {
        window.__RENDERABLE_PRELOAD_CLEAR_CACHE?.();
        //TODO remover após migrar react-router
        await this.saveCockpitPreferences();
        await Api.executeExp(`#{cockpitViewMB.loadCockpit(${cockpit.id})}`);
        await Api.updateJsf();
    };

    manageCockpits = async (event) => {
        try {
            event.preventDefault();
            await Api.Cockpit.manageCockpits();
        } catch (e) {
            console.error('Error on manageCockpits()', e);
            UiMsg.ajaxError(null, e);
        }
    };

    closeDropdown = async (event) => {
        //TODO remover após migrar react-router
        const view = await this.saveCockpitPreferences();
        this.setState({open: false, view});
    };

    async componentWillUnmount() {
        await this.saveCockpitPreferences();
    }

    saveCockpitPreferences = async () => {
        const preferences = {
            listView: this.state.view.listView,
            preferredSize: j('.cockpit-navigator-body').width()
        };

        if (!_.isEqual(preferences, this.baseView)) {
            try {
                await Api.Cockpit.savePreferences(this.props.context.user.id, preferences);
                this.baseView = preferences;
            } catch (e) {
                console.error(e);
            }
        }

        return preferences;
    };

    render() {
        const canManageCockpits = this.props.context.permissions.isAtLeastExpert() && !this.props.context.userIsOnlyCockpit;

        if (!this.state.container) return null;

        return (
            <div className="cockpit-toolbar-container">
                <BngPopperWrapper
                    onOpen={this.findCockpits}
                    onClose={this.closeDropdown}
                    className="cockpit-navigator-dropdown"
                    placement="bottom"
                    container={this.state.container}
                    modifiers={{
                        preventOverflow: {
                            boundariesElement: document.getElementsByClassName('main-content cockpit-index-page')[0],
                        }
                    }}>
                    <BngPopperButton className="cockpit-navigator-button">
                        <Icon icon={this.state.open ? 'close' : 'menu'} />
                    </BngPopperButton>

                    <UiBlocker block={this.state.loading} style={{height: '100%'}}>
                        <div
                            className={`cockpit-dropdown-popper ${this.state.view.listView ? 'navigator-list' : 'navigator-grid'}`}>

                            <div className="cockpit-dropdown-popper-header">
                                <span style={{width: '100%'}}>{this.props.context.msg.t('cockpits')}</span>
                                <BngSearch className="absolute"
                                           ref={this.searchChild}
                                           onChange={this.updateViewCockpits}
                                           placeholder=""
                                           name="cockpit-search"/>
                                <Icon className="change-view-cockpit"
                                      onClick={this.changeView}
                                      icon={this.state.view.listView ? 'view_list' : 'view_module'}/>
                            </div>

                            <div className="cockpit-navigator-body"
                                 style={!!this.state.view.preferredSize ? {width: this.state.view.preferredSize} : {}}>
                                {this.state.viewCockpits.map((cockpit, idx) => {
                                    const currentCockpit = cockpit.id === this.props.id;
                                    return (
                                        <div key={idx}
                                             className={`cockpit-navigator-item ${cockpit.favorite ? 'favorite' : ''} ${currentCockpit ? 'selected' : ''}`}>
                                            <Icon icon={cockpit.favorite ? 'star' : 'star_border'}
                                                  onClick={(e) => this.handleFavorite(e, cockpit)}
                                                  className="cockpit-navigator-favorite-icon"
                                                  title={this.props.context.msg.t(cockpit.favorite ? 'unmake.cockpit.favorite' : 'make.cockpit.favorite')}/>
                                            <div className="cockpit-data"
                                                 title={cockpit.name}
                                                 onClick={(e) => this.loadCockpit(e, cockpit)}>
                                                <Icon type={Icon.parseType(cockpit.icon)}
                                                      className="cockpit-navigator-item-icon"
                                                      icon={cockpit.icon}/>
                                                <span
                                                    className="cockpit-navigator-item-description">{cockpit.name}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className={`cockpit-dropdown-popper-action link ${canManageCockpits ? '' : 'disabled'}`}>
                                {canManageCockpits &&
                                  <a href="#" onClick={this.manageCockpits}>
                                    <span className="cockpit-generate-book-button">
                                        {this.props.context.msg.t('manage.cockpits')}
                                    </span>
                                  </a>
                                }
                            </div>
                        </div>
                    </UiBlocker>

                </BngPopperWrapper>
            </div>
        )
    }
}

export default ContextEnhancer(CockpitNavigator);