import {combineReducers} from "redux";
import { APP, BREADCRUMB, MENU, MODALS, NAVBAR, USER } from 'components/ui/redux/Actions';
import {processAsyncAction} from "components/ui/redux/structure/Async";

import Api from "components/Api";
import newmap from 'components/ui/redux/reducers/newmap-reducer';
import bigTable from 'components/bng/pages/bigTable/redux/reducers/index';
import reduxFormReducer from 'components/ui/redux/reducers/redux-form-reducer';

function context(state = {favorites: {}}, action) {
    switch (action.type) {
        case APP.RELOAD_PAGE: {
            location.reload(true);
            return state;
        }
        case APP.REFRESH_CURRENT_PROJECT: {
            return processAsyncAction(state,
                action, project => {
                    let copy = _.cloneDeep(state);
                    copy.project = project;
                    return copy;
                });
        }
        case APP.RELOAD_OBJECT: {
            if (!!window.__LOAD_OBJECT_URL) {
                const loadProps = window.__LOAD_OBJECT_URL;
                delete window.__LOAD_OBJECT_URL;
                window.location = Api.loadObjectUrl(loadProps);
            }
            return state;
        }
        case USER.SET_FAVORITE_PROJECT: {
            let copy = {...state};
            copy.user.preferences.defaultProjectId = action.payload;
            return copy;
        }
        case USER.FIND_FAVORITES: {
            return processAsyncAction(state,
                action, favorites => {
                    return {...state, favorites};
                });
        }
        case USER.USER_PREFERENCES_SAVED: {
            let copy = {...state};
            copy.user.preferences = action.payload.data;
            return copy;
        }
        case USER.USER_SAVED: {
            const hasBreakingChanges = (user, changeUser) => {
                const critical = ['preferredLanguage', 'timeZone', 'permissions', 'email', 'avatarLink', 'displayName'];
                for (let i = 0; i < critical.length; i++){
                    const c = critical[i];
                    if (user[c] !== changeUser[c]) {
                       return true;
                    }
                }
                return false;
            };
            if(hasBreakingChanges(state.user, action.payload)) {
                Api.invalidateTranslations();
                location.reload(true);
            }
            state.user = action.payload;
            return state;
        }
        case BREADCRUMB.UPDATE: {
            let copy = _.cloneDeep(state);
            copy.breadcrumb = action.payload.newBreadcrumbState;
            return copy;
        }
        default:
            return state;
    }
}

const expanded = (() => {
    return false;
})();

function menu(state = {expanded}, action) {
    if (!state.hasOwnProperty('expanded')) {
        state = {...state, expanded}
    }
    switch (action.type) {
        case MENU.FIND_ITEMS: {
            return processAsyncAction(state,
                action, items => {
                    return {...state, items};
                });
        }
        case MENU.FIND_ROOTS: {
            return processAsyncAction(state,
                action, roots => {
                    return {...state, roots};
                });
        }
        case MENU.TOGGLE_EXPAND_MENU: {
            let expanded = !state.expanded;
            return {...state, expanded}
        }
        default:
            return state;
    }
}

function navbar(state = {}, action) {
    switch (action.type) {
        case NAVBAR.GET_INFO: {
            return processAsyncAction(state,
                action, info => {
                    return {...state, info};
                });
        }
        case NAVBAR.FIND_PROJECTS: {
            return processAsyncAction(state,
                action, projects => {
                    return {...state, projects};
                });
        }
        case NAVBAR.FIND_PUBLICATIONS: {
            return processAsyncAction(state,
                action, publications => {
                    return {...state, publications};
                });
        }
        case NAVBAR.FIND_SCHEDULINGS: {
            return processAsyncAction(state,
                action, schedulings => {
                    return {...state, schedulings};
                });
        }
        case NAVBAR.FIND_MONITORS: {
            return processAsyncAction(state,
                action, events => {
                    let evts = events.content;
                    let db = {};
                    evts.forEach(e => {
                        if (_.isNumber(e.monitor)) {
                            e.monitor = db[e.monitor]
                        } else {
                            db[e.monitor.id] = e.monitor;
                        }
                    });
                    let copy = {...state};
                    copy.events = evts;
                    return copy;
                });
        }
        case NAVBAR.FIND_PRESENTATIONS: {
            return processAsyncAction(state,
                action, presentations => {
                    return {...state, presentations};
                });
        }
        case NAVBAR.FIND_NOTIFICATIONS: {
            return processAsyncAction(state,
                action, notifications => {
                    return {...state, notifications};
                });
        }
        case NAVBAR.MARK_ALL_NOTIFICATIONS_AS_READ: {
            let time = new Date().getTime();
            let copy = _.cloneDeep(state);
            copy.info.counts.notifications = 0;
            copy.notifications?.forEach(n => n.readDate = n.readDate ? n.readDate : time);
            return copy;
        }
        case NAVBAR.MARK_NOTIFICATION_AS_READ: {
            let copy = _.cloneDeep(state);
            let n = 0;
            copy.notifications.filter(n => n.id === action.payload).forEach(n => {
                n.readDate = new Date().getTime();
                n++;
            });
            copy.info.counts.notifications -= 1;
            if (copy.info.counts.notifications < 0) {
                copy.info.counts.notifications = 0;
            }
            return copy;
        }
        default:
            return state;
    }
}

function modals(state = {stack: []}, action) {

    switch (action.type) {
        case MODALS.OPEN: {
            state = _.cloneDeep(state);
            const current = {...action.payload, open: true};
            state.current = current;
            state.stack.push(current);
            break;
        }
        case MODALS.CLOSE: {
            state = _.cloneDeep(state);
            state.stack.pop();
            delete state.current;
            if (state.stack.length > 0) {
                state.current = state.stack[state.stack.length - 1];
            }
            break;
        }
    }
    return state;
}


const appReducers = combineReducers({
    context,
    navbar,
    menu,
    modals,
    newmap,
    form: reduxFormReducer,
    bigTable
});

export default appReducers;

