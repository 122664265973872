import styles from './HierarchyEditDialog.module.css';

import React, { useMemo } from 'react';
import { Field, Formik } from 'formik';

import BngTable from 'components/bng/ui/BngTable';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';
import Dialog from 'components/ui/Dialog';
import BngField from 'components/bng/form/BngField';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngButton from 'components/bng/ui/BngButton';
import bngYup from 'components/bng/form/yup/BngYup';
import { LevelSelectorDropdown } from 'components/ui/in-memory/dialogs/LevelSelectorDropdown';
import UiMsg from 'components/ui/UiMsg';

const schemaValidatorFactory = (nameIsUsed = _.noop) =>
  bngYup((yup) =>
    yup.object({
      name: yup
        .string()
        .test({
          async test(value) {
            const { schema } = this;
            const nameInUse = nameIsUsed(value, schema._default);
            return nameInUse ? this.createError({ message: nameInUse }) : true;
          },
        })
        .default(''),
      levels: yup.array(yup.string()).required().default([]),
    })
  );

export default function HierarchyEditDialog({
  closeModal,
  availableFields = [],
  hierarchies = [],
  hierarchy,
  onChange = _.noop,
}) {
  const { t } = useTranslation();

  const editing = !!hierarchy;

  const validationSchema = useMemo(() => {
    return schemaValidatorFactory((value, defaultValue) => {
      if ((editing && value === defaultValue) || (editing && value === hierarchy.name)) {
        return false;
      }
      if (hierarchies.find((h) => h.name === value)) {
        return t('inMemory_nameAlreadyInUse');
      }
    });
  }, [hierarchy, hierarchies]);

  const initialValues = useMemo(() => {
    const values = validationSchema.default();
    if (hierarchy) {
      Object.assign(values, _.cloneDeep(hierarchy));
    }
    return values;
  }, [hierarchies, hierarchy]);

  const nameIsValid = (name) => {
    if (editing) {
      if (name === hierarchy.name) {
        return true;
      }
    }
    const temp = hierarchies.find((h) => h.name === name);
    return !temp;
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          await onChange(values);
          closeModal();
        } catch (e) {
          console.error('Error on submit()', e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      {({ values, setFieldValue, isValid, submitForm }) => {
        const formIsValid = nameIsValid(values.name) && !_.isEmpty(values.levels) && isValid;

        return (
          <Dialog title={t('new.hierarchy')} onClose={closeModal} className="HierarchyEditDialog">
            <Dialog.Body>
              <Field name="name" label={t('name')} component={BngField} />

              <LevelSelectorDropdown
                availableFields={availableFields.filter((f) => !values.levels.includes(f.value.fieldName))}
                value={values.levels}
                onChange={(val) => {
                  setFieldValue('levels', val);
                }}
              />

              <div className={`${styles.bngTableWrapper}`}>
                <BngTable
                  onDropHandler={(result) => {
                    if (!result.destination) return;
                    const fromIdx = result.source.index;
                    const toIdx = result.destination.index;

                    if (fromIdx === toIdx) return;

                    let copy = values.levels.slice();
                    const tmp = copy[fromIdx];
                    copy[fromIdx] = copy[toIdx];
                    copy[toIdx] = tmp;
                    setFieldValue('levels', copy);
                  }}
                  stickyHeader
                  cols={[
                    {
                      render: (row, idx, rowProps, dragProps) => (
                        <div className={` ${styles.draggableIcon}`} {...dragProps.dragHandleProps}>
                          <Icon icon="drag_indicator" />
                        </div>
                      ),
                      rowClassName: styles.draggableTd,
                    },
                    {
                      label: t('level'),
                      render(row, idx, rowProps, dragProps) {
                        return (
                          <label className={`${styles.labelStyles}`} {...dragProps.dragHandleProps}>
                            {idx + 1}
                          </label>
                        );
                      },
                      rowClassName: styles.levelTd,
                    },
                    {
                      label: t('field'),
                      render(row, idx, rowProps, dragProps) {
                        const fieldOpt = availableFields.find((f) => f.value.fieldName === row);
                        return (
                          <label className={`${styles.labelStyles}`} {...dragProps.dragHandleProps}>
                            {fieldOpt?.label ?? row}
                          </label>
                        );
                      },
                      rowClassName: styles.fieldTd,
                    },
                    {
                      label: t('action'),
                      render(row, idx, rowProps, dragProps) {
                        return (
                          <BngIconButton
                            className={`${styles.removeAllFieldsButton}`}
                            icon="delete_forever"
                            onClick={() => {
                              const copy = values.levels.slice();
                              copy.splice(idx, 1);
                              setFieldValue('levels', copy);
                            }}
                          />
                        );
                      },
                      rowClassName: styles.actionTd,
                    },
                  ]}
                  rows={values.levels}
                />
              </div>
              <div className={`${styles.dialogButtonsWrapper}`}>
                <BngButton
                  disabled={!formIsValid}
                  className={`${styles.confirmationButton}`}
                  onClick={formIsValid ? async () => await submitForm() : undefined}
                >
                  {t('ok')}
                </BngButton>

                <BngButton className={`${styles.cancelButton}`} onClick={closeModal}>
                  {t('cancel')}
                </BngButton>
              </div>
            </Dialog.Body>
          </Dialog>
        );
      }}
    </Formik>
  );
}
