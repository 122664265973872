import React from 'react';
import HelpIcon from 'components/ui/common/HelpIcon';

export function LabelHelper({ label, helpLabel }) {
  return (
    <>
      <span>{label}</span>{' '}
      <HelpIcon style={{ fontSize: '14px', marginLeft: '2px', verticalAlign: 'text-bottom' }} title={helpLabel} />
    </>
  );
}