import { Axios } from 'commonUtils';

class ValidateMobileApi {
    async validatePhone(code, phoneNumber, userId) {
        const { data } = await Axios.post('/api/mobileValidate/validatePhone', {
            code,
            phoneNumber,
            userId,
        });
        return data;
    }

    async generateCode(phoneNumber, userId) {
        await Axios.post('/api/mobileValidate/generateCode', {
            phoneNumber,
            userId,
        });
    }
}
export default ValidateMobileApi;