import React, { useState } from 'react';
import useTranslation from 'components/hooks/useTranslation';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import AdaAiChat from 'components/bng/adaAi/AdaAiChat';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';

export default function DashboardItemMenuAdaAiInsights({ item, dashboardPath, assistantKey }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const [adaChat, setAdaChat] = useState({ messages: [] });

  return (
    <li
      className="DashboardItemMenuAdaAiInsights"
      onClick={async () => {
        try {
          const [assistant] = await Api.AdaAi.findAll(assistantKey);

          if (!assistant) {
            throw new Error(`Assistant with key "${assistantKey}" not found.`);
          }

          const metadataObject = await Api.Metadata.exportDashboardMetadata({
            content: dashboardPath,
            filters: item?.filters,
            itemId: item?.id,
            queryResultLimit: assistant.props.queryResultLimit,
          });

          dispatch(
            MODALS.open(AdaAiChat, {
              initialMessages: adaChat.messages,
              initialAssistantKey: assistantKey,
              metadataObject,
              onMessages: ({ messages = [] }) => {
                setAdaChat({
                  ...adaChat,
                  messages: messages.slice(),
                });
              },
            })
          );
        } catch (e) {
          console.error('Error while trying to get the metadata', e);
          UiMsg.warn(t('ada.ai.get.the.metadata.error'));
        }
      }}
    >
      <DashboardItemMenuTitle
        title={t('ada.ai.dashboard.item.menu.title')}
        icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon-insisghts.png')} alt="Ada AI" />}
        more={false}
      />
    </li>
  );
}
