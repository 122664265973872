import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Field, Formik } from 'formik';

import useTranslation from 'components/hooks/useTranslation';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngForm from 'components/bng/form/BngForm';
import BngField from 'components/bng/form/BngField';
import { BngCancelButton, BngPrimaryButton } from 'components/ui/Button';
import { TrialFragment } from 'bng/pages/login/LoginAuthForm';
import bngYup from 'components/bng/form/yup/BngYup';
import { useMemo } from 'react';

const FormSchema = bngYup((yup) =>
  yup.object({
    email: yup.string().email().required().ensure().default(''),
  }),
);

export default function LoginPasswordRecoveryForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const state = useMemo(() => _.cloneDeep(locationState), []);
  const { loadingCallback } = useOutletContext();

  const goBackToLogin = () => {
    navigate('/login');
  };

  return (
    <Formik
      initialValues={{ email: state?.credentials?.email ?? '' }}
      validationSchema={FormSchema}
      onSubmit={async (values) => {
        loadingCallback(true);
        try {
          await Api.User.changeUserPassword(values.email);
          UiMsg.ok(t('users.password.recovery.request.sent.to.email'));
          goBackToLogin();
        } catch (e) {
          console.error('Error while sending password recovery request', { values }, e);
          UiMsg.ajaxError(null, e);
        } finally {
          loadingCallback(false);
        }
      }}
    >
      <BngForm>
        <label className="mb-0 fw-400 text-base">{t('login_email_instructions')}</label>
        <Field component={BngField} name="email" rootClassName="mb-0 mt-2" floatingLabel autoFocus />

        <div className="flex-center-items jc-end mt-4">
          <BngCancelButton className={'fw-500'} onClick={goBackToLogin}>
            {t('login_back')}
          </BngCancelButton>
        </div>

        <BngPrimaryButton type="submit" className="mt-4">
          {t('login_email_send')}
        </BngPrimaryButton>

        <div className="mt-2">
          <TrialFragment />
        </div>
      </BngForm>
    </Formik>
  );
}
