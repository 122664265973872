import './ChartConf.css';
import React from 'react';
import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import BngInputColor from "components/bng/form/BngInputColor";


const enabledOpts = ['', true, false];
const orientationOpts = ['', 'HORIZONTAL', 'VERTICAL'];
const mapPositionOpts = ['', 'INTERNAL', 'EXTERNAL'];
const catPositionOpts = ['', 'CENTER_INSIDE', 'TOP_OUTSIDE', 'BOTTOM_INSIDE', 'BOTTOM_OUTSIDE', 'RIGHT_OUTSIDE', 'LEFT_OUTSIDE', 'LEFT_INSIDE'];
const rangeOpts = _.range(6, 33);

export const ChartMeasure = ContextEnhancer(
    class extends React.Component {


        constructor(props) {
            super(props);
        }

        componentDidMount() {
            if(this.props.advancedMode) {
                this.toggleDraggable(false);
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if(this.props.advancedMode && prevProps.advancedMode !== this.props.advancedMode && !this._el.classList.contains('ui-draggable')) {
                this.toggleDraggable(false);
            }
        }

        componentWillUnmount() {
            this.toggleDraggable(true);
        }

        toggleDraggable = open => {
            const $el = j(this._el);
            const isEnabled = $el.hasClass('ui-draggable');
            if (open) {
                if (isEnabled) {
                    $el.draggable('destroy');
                }
            } else {
                if (!isEnabled) {
                    $el.draggable({
                        helper: 'clone',
                        revert: "invalid",
                        containment: $el.closest('table')
                    });
                }
            }
        };

        render() {
            const {value, label, color, onChange, onChangeLabel, isCategory, advancedMode} = this.props;
            return (
                <span className={`ChartMeasure label label-info ${advancedMode ? 'draggable': ''}`}
                      data-val={value}
                      style={{marginRight: '5px', marginBottom: '2px', display: 'flex', alignItems: 'center'}}
                      ref={ref => this._el = ref}>
                    <span className="dd-handle limit-measure-tag-table" title={label}>{label}</span>

                    {isCategory &&
                    <LabelConfPopper
                        label={label}
                        labelConf={this.props.labelConf}
                        onChangeLabel={onChangeLabel}
                        isCategory={isCategory}
                        onToggleConf={this.toggleDraggable}
                    />
                    }
                    <span className="d-l-m"
                          style={{marginLeft: '8px', boxShadow: '0 0 3px white', borderRadius: '100%'}}>
                        <BngInputColor size={'xxs'}
                                       field={{name: 'color', value: color || 'white'}}
                                       form={{setFieldValue: (name, value) => onChange(value)}}
                                       addTransparentOptions={false}
                        />
                    </span>
                </span>
            )
        }
    }
);

const LabelConfPopper = ContextEnhancer(
    class InnerLabelConfPopper extends React.Component {

        state = {
            showLabelConf: false,
            labelConf: null,
        };


        constructor(props) {
            super(props);
        }

        toggleLabelConf = () => {
            const showLabelConf = !this.state.showLabelConf;
            let labelConf = null;

            if(showLabelConf) {
                labelConf = {
                    enabled: null,
                    orientation: null,
                    mapPosition: null,
                    catPosition: null,
                    fontSize: null,
                    color: '#000000',
                };

                if(this.props.labelConf) {
                    labelConf = _.mergeWith(
                        {}, labelConf, _.cloneDeep(this.props.labelConf),
                        (a, b) => b === null ? a : undefined
                    );
                }
            }

            this.setState({
                showLabelConf,
                labelConf,
            });

            this.props.onToggleConf(showLabelConf);
        };


        labelConfChanged = e => {
            const copy = this.state.labelConf;
            let name, value;
            if (e.target) {
                name = e.target.name;
                value = e.target.value;
            } else {
                name = 'color';
                value = e;
            }
            copy[name] = value || null;
            this.setState({labelConf: copy});
        };

        render() {
            const {label, onChangeLabel, isCategory} = this.props;
            let labelConf = this.state.labelConf;
            let isEnabled = false;
            for (let [k, v] of Object.entries(this.props.labelConf)) {
                if (!_.isEmpty(v) || v === false) {
                    isEnabled = true;
                    break;
                }
            }
            return (
                <span className='conf-drop-trigger' onClick={this.toggleLabelConf}>
                    <i className={`fa fa-comment${isEnabled ? '' : '-o'}`}
                       title={this.props.context.msg.t('label.config')}
                    />

                    {this.state.showLabelConf &&
                    <React.Fragment>
                        <div
                            className={'ChartConfDropListener inner-overlay'} onClick={this.toggleLabelConf}>

                        </div>
                        <div className={'drop-container open'}>
                            <div onClick={e => e.stopPropagation()} className={'dropdown-menu'}>
                                <div className={'header'}>
                                    <span>{this.props.context.msg.t('label')}: {label}</span>
                                    <i className={'fa fa-info-circle'}
                                       title={this.props.context.msg.t('label.config.hint')}
                                    />
                                </div>
                                <div className="fields">
                                    <div className={'row-fluid'}>
                                        <div className="span6">
                                            <div>
                                                {this.props.context.msg.t('visible')}
                                            </div>
                                            <select name="enabled"
                                                    value={'' + labelConf.enabled}
                                                    onChange={this.labelConfChanged}>
                                                {enabledOpts.map((val, idx) => {
                                                    return (
                                                        <option value={val}
                                                                key={idx}>{val.length === 0 ? this.props.context.msg.t('without.config') : this.props.context.msg.t(val)}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="span6">
                                            <div>
                                                {this.props.context.msg.t('orientation')}
                                            </div>
                                            <select name="orientation"
                                                    value={labelConf.orientation || ''}
                                                    onChange={this.labelConfChanged}
                                                    disabled={!isCategory}>
                                                {orientationOpts.map((val, idx) => {
                                                    return (
                                                        <option value={val}
                                                                key={idx}>{val.length === 0 ? this.props.context.msg.t('without.config') : this.props.context.msg.t(val)}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row-fluid">
                                        <div className="span6">
                                            <div>
                                                {this.props.context.msg.t('position')}
                                            </div>

                                            {!isCategory &&
                                            <select name="mapPosition"
                                                    value={labelConf.mapPosition || ''}
                                                    onChange={this.labelConfChanged}>
                                                {mapPositionOpts.map((val, idx) => {
                                                    return (
                                                        <option value={val}
                                                                key={idx}>{val.length === 0 ? this.props.context.msg.t('without.config') : this.props.context.msg.t(val)}</option>
                                                    )
                                                })}
                                            </select>
                                            }

                                            {isCategory &&
                                            <select name="catPosition"
                                                    value={labelConf.catPosition || ''}
                                                    onChange={this.labelConfChanged}>
                                                {catPositionOpts.map((val, idx) => {
                                                    return (
                                                        <option value={val}
                                                                key={idx}>{val.length === 0 ? this.props.context.msg.t('without.config') : this.props.context.msg.t(val)}</option>
                                                    )
                                                })}
                                            </select>
                                            }
                                        </div>
                                        <div className="span6">
                                            <div>
                                                {this.props.context.msg.t('font.size')}
                                            </div>
                                            <select name="fontSize"
                                                    value={labelConf.fontSize || ''}
                                                    onChange={this.labelConfChanged}>
                                                <option
                                                    value="">{this.props.context.msg.t('without.config')}</option>
                                                {rangeOpts.map((val, idx) => (
                                                    <option key={idx} value={val}>{val}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={'row-fluid'}>
                                        <div className="span6">
                                            <div>
                                                {this.props.context.msg.t('font.color')}
                                            </div>
                                            <div className={'ic-container'}>
                                                <BngInputColor size={'sm'}
                                                               field={{name: 'color', value: labelConf.color || ''}}
                                                               form={{setFieldValue: (name, value) => this.labelConfChanged(value)}}
                                                               addTransparentOptions={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="span6">
                                        </div>
                                    </div>
                                </div>

                                <hr/>

                                <div className={'text-right'}>
                                    <Button onClick={this.toggleLabelConf}
                                            className={'btn-mini btn-danger'}
                                            icon={'icon-remove'}>
                                        {this.props.context.msg.t('cancel')}
                                    </Button>
                                    {' '}
                                    <Button onClick={() => {
                                        this.setState({labelConf: {}});
                                    }}
                                            className={'btn-mini btn-warning'}
                                            icon={'icon-eraser'}>
                                        {this.props.context.msg.t('clear')}
                                    </Button>
                                    {' '}
                                    <Button onClick={() => {
                                        onChangeLabel(labelConf, true);
                                        this.toggleLabelConf();
                                    }}
                                            className={'btn-mini btn-inverse'}
                                            title={this.props.context.msg.t('apply.to.group.hint')}
                                            icon={'icon-copy'}>
                                        {this.props.context.msg.t('apply.to.group')}
                                    </Button>
                                    {' '}
                                    <Button onClick={() => {
                                        onChangeLabel(labelConf);
                                        this.toggleLabelConf();
                                    }}
                                            className={'btn-mini'}
                                            icon={'icon-ok'}>
                                        {this.props.context.msg.t('apply')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>

                    }
                </span>
            );
        }
    }
);