import { Axios } from 'commonUtils';

class OriginApi {
  async find(id) {
    const { data } = await Axios.get(`/spr/origins/${id}`);
    return data;
  }

  async updatePermissions(originId, permissions) {
    const { data } = await Axios.post(`/spr/origins/${originId}/permissions`, permissions);
    return data;
  }

  async findLabsData(id) {
    const { data } = await Axios.get(`/spr/origins/${id}/labsData`);
    return data;
  }

  async updateMdxRemoveUnusedMembersConfig(id, value) {
    await Axios.post(`/spr/origins/${id}/mdxRemoveUnusedMembers`, {
      value,
    });
  }

  async clearData(id, deleteCredentials) {
    await Axios.post(`/spr/origins/${id}/clearData`, { deleteCredentials: deleteCredentials });
  }

  async findLogs(id, limit = 100) {
    const { data } = await Axios.get(`/spr/origins/${id}/logs`, {
      params: {
        limit,
      },
    });
    return data;
  }

  findLog(inMemoryId, logId) {
    return Axios.getData(`/spr/origins/${inMemoryId}/logs/${logId}`);
  }

  async cancelDataLoad(id) {
    return await Axios.delete(`/spr/origins/schedulings/${id}/cancel-load`, {
      params: {
        isLog: true,
      }
    });
  }
}

export default OriginApi;
