import './BngMarkdownEditor.css';
import React, { useEffect, useRef } from 'react';
import ContextEnhancer from 'components/ContextEnhancer';

let TuiEditor = null;

const BngMarkdownEditor = ContextEnhancer(
  ({
    context: { msg },
    className = '',
    language = findDefaultLanguage(),
    field,
    form,
    value,
    onChange = _.noop,
    viewer = false,
    onTuiRef = _.noop,
    maxLength = null,
    height = '200px',
    ...props
  }) => {
    const containerRef = useRef();

    const currentValue = field?.value || value || '';

    useEffect(() => {
      if (!containerRef.current) return;

      const editorElement = containerRef.current.querySelector('.tui-editor-defaultUI');
      if (editorElement) return;

      (async () => {
        await initializeScript();
        const editor = new TuiEditor.factory({
          el: containerRef.current,
          initialValue: currentValue,
          viewer,
          usageStatistics: false,
          initialEditType: 'markdown',
          language: language,
          toolbarItems: TOOLBAR_ITEMS,
          events: {
            change: () => {
              let markdownContent = editor.getMarkdown() || '';
              if (maxLength && markdownContent.length > maxLength) {
                markdownContent = markdownContent.substring(0, maxLength);
                editor.setMarkdown(markdownContent);
              }
              if (field && form) {
                form?.setFieldValue(field?.name, markdownContent);
              }
            },
          },
          height,
          ...props,
        });
        onTuiRef(editor);
      })();
    }, [containerRef.current]);

    return (
      <div className={`BngMarkdownEditor ${className}`}>
        <div ref={containerRef} style={{ height }} />
        {maxLength && currentValue.length >= maxLength && (
          <div className={'SizeLimitAlert'}>
            <small>{msg.t('content.limit.reached')}</small>
          </div>
        )}
      </div>
    );
  },
);

const findDefaultLanguage = () => (window.__USER_LANG || 'pt-BR').split('-')[0];

const TOOLBAR_ITEMS = ['bold', 'italic', 'strike'];

const initializeScript = async () => {
  if (TuiEditor) return;

  const tuiEditorMod = require(/* webpackChunkName: "TuiEditor" */ './TuiEditorImport.js');
  TuiEditor = tuiEditorMod.default;
};

export default BngMarkdownEditor;
