import React, {Component} from 'react';
import {Field} from "formik";

import ContextEnhancer from "components/ContextEnhancer";
import BngSwitch from "components/bng/form/BngSwitch";
import Icon from "components/ui/common/Icon";

class FeaturesTab extends Component {

    renderFeature = (feature, index, style = {}, name = undefined) => {
        const identifier = name ? `account.${name}` : `account.features.${index}.enabled`;

        return (
            <div className="feature-item" key={index}>
                <Icon icon={feature.icon ? feature.icon : "public"} className="feature-icon" style={style}/>

                <div className="feature-details">
                    <span className="feature-title">{this.props.context.msg.t(feature.feature)}</span>
                    <span className="feature-description">{this.props.context.msg.t(`${feature.feature}.legend`)}</span>
                </div>

                <Field id={identifier}
                       name={identifier}
                       component={BngSwitch}
                       className="vertical-title features"
                       title={this.props.context.msg.t('enabled')} />
            </div>
        )
    };

    canAlterProjects = {icon: 'lock', feature: 'can.remove.projects'};
    showBimAcademy = {icon: 'book', feature: 'show.bim.academy'};
    showBimStore = {icon: 'store', feature: 'show.marketplace'};

    render(){
        return (
            <div className="features-tab">
                {this.props.context.user.superUser &&
                    this.renderFeature(this.canAlterProjects, -3, {backgroundColor: '#EDA200'}, "config.canRemoveProject")
                }
                {
                    this.renderFeature(this.showBimAcademy, -2, {backgroundColor: '#AC4FC6'}, "config.showBimAcademy")
                }
                {
                    this.renderFeature(this.showBimStore, -1, {backgroundColor: '#528DE3'}, "config.showBimStore")
                }
            </div>
        )
    }
}

export default ContextEnhancer(FeaturesTab);