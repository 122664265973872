import './BngNewKpiHelp.css';

import React, { useEffect, useState } from 'react';

import BngAda from 'components/bng/ui/BngAda/BngAda';
import Api from 'components/Api';
import KpiMultiStepInfo from 'components/ui/kpi/info-new-kpi/KpiMultiStepInfo';
import useBimContext from 'components/hooks/useBimContext';

export default function BngNewKpiHelp({ showIntro = false, onFinishIntro = _.noop }) {
  const context = useBimContext();
  const projectId = context.findProjectIdFromContext();
  const [introProps, setIntroProps] = useState({ show: showIntro, props: { hasWatched: !showIntro } });
  const [adaButtonPressed, setAdaButtonPressed] = useState(false);

  useEffect(() => {
    if (introProps.show) {
      setAdaButtonPressed(true);
    }
  }, [introProps.show]);

  const defaultOpts = [
    {
      key: Api.UserParam.Keys.Kpi.NewKpiHelpVideo,
      icon: 'play_circle_outline',
      label: context.msg.t('new.kpi.help.video'),
      onClick: () => {
        setIntroProps({
          show: true,
          props: {
            hasWatched: true,
          },
        });
      },
    },
    {
      label: context.msg.t('new.kpi.help.documentation'),
      onClick: () => window.open(context.msg.t('documentation.create.kpi'), '_blank'),
    },
  ];

  return (
    <>
      <BngAda
        className={`NewKpiHelp ${adaButtonPressed ? 'AdaVisible' : 'AdaHidden'}`}
        title={context.msg.t('new.kpi.help.title', [context.user.displayName])}
        options={defaultOpts}
        onClick={() => {
          setAdaButtonPressed(!adaButtonPressed);
        }}
        buttonPressed={adaButtonPressed}
        showInfo={!introProps.show}
        disableBackground={true}
      />
      {introProps.show && (
        <KpiMultiStepInfo
          projectId={projectId}
          {...introProps.props}
          onFinish={() => {
            setIntroProps({ ...introProps, show: false });
            onFinishIntro();
            setAdaButtonPressed(false);
          }}
        />
      )}
    </>
  );
}
