import {Axios} from "commonUtils";


class AdminApi {

    findUsers = async ({initial, maxResults, search}) => {
        const {data} = await Axios.get(`/spr/admin/users`, {params: {initial, maxResults, q: search}});
        return data;
    };

    deleteUser = async (id) => {
        const {data} = await Axios.delete(`/spr/admin/users/${id}`);
        return data;
    };

    clearReferences = async (id) => {
        const {data} = await Axios.post(`/spr/admin/users/${id}/clearReferences`);
        return data;
    };

}

export default AdminApi;