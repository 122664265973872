import styles from './InfoWizardStep.module.css';
import WomanSvg from './assets/Woman.svg';

import React, { useMemo } from 'react';
import { BngSelect } from 'components/bng/form/BngSelect';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { MODE } from 'components/bng/project/updateApps/projectUpdateAppsCommons';
import useBimContext from 'components/hooks/useBimContext';

const WomanLaptopSvg = () => (
  <img src={WomanSvg} alt={'Woman Laptop'} width={310} height={310} style={{ width: '310px', height: '310px' }} />
);

export default function InfoWizardStep({
  keepData,
  keepDataChanged,
  projectData,
  mode,
  onModeChange,
  updateSql,
  onUpdateSqlChanged,
  updateCockpitOrder,
  onUpdateCockpitOrderChanged,
}) {
  const { msg } = useBimContext();
  const containApps = projectData.installs.length > 0;
  const isApp = projectData.installedOn.length > 0;

  const modeOpts = useMemo(() => {
    const opts = [];
    if (containApps) {
      opts.push({ value: MODE.PROJECT, label: msg.t('update.this.project') });
      opts.push({ value: MODE.UNLINK_PROJECT, label: msg.t('remove.app.link') });
    }

    if (isApp) {
      opts.push({ value: MODE.CUSTOMERS, label: msg.t('update.customers') });
    }
    return opts;
  }, [containApps, isApp]);
  return (
    <div className={`d-flex ${styles.main}`}>
      <div className={`w-50 text-center`}>
        <WomanLaptopSvg />
      </div>
      <div className={`w-50 `}>
        <h1 className={`m-0`}>
          {msg.t(
            mode === MODE.PROJECT
              ? 'project.update.app.dialog.message'
              : mode === MODE.CUSTOMERS
              ? 'project.update.app.dialog.customers.message'
              : 'remove.app.link.message'
          )}
        </h1>
        <h3 className={`m-0 mt-4`}>
          {msg.t(
            mode !== MODE.UNLINK_PROJECT ? 'project.update.app.dialog.description' : 'remove.app.link.description'
          )}
        </h3>
        {modeOpts.length > 1 && (
          <div className={`mt-4`}>
            <BngSelect
              value={mode}
              onChange={(event) => {
                onModeChange(event.target.value);
              }}
              options={modeOpts}
              emptyOption={false}
            />
          </div>
        )}
        {mode !== MODE.UNLINK_PROJECT && (
          <>
            <div className={`mt-4`}>
              <BngCheckbox label={msg.t('keep.data')} field={{ value: keepData, onChange: keepDataChanged }} />
            </div>
            <div className={`mt-2`}>
              <BngCheckbox
                label={msg.t('update.queries')}
                field={{ value: updateSql, onChange: onUpdateSqlChanged }}
                title={msg.t('update.queries.hint')}
              />
            </div>
            <div className={`mt-2`}>
              <BngCheckbox
                label={msg.t('update.cockpit.order')}
                field={{ value: updateCockpitOrder, onChange: onUpdateCockpitOrderChanged }}
                title={msg.t('update.cockpit.order.hint')}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
