import React from 'react';
import {
  BreadcrumbToolbar,
  DynamicTimeFilterToolbarItem,
  ExportToolbarItem,
  FavoriteToolbarItem,
  InformationToolbarItem,
  MobileToolbarItem,
  NotesToolbarItem,
  PublisherToolbarItem,
  ReloadToolbarItem,
} from 'components/ui/breadcrumb-toolbar';
import HideHeaderToolbarItem, { HideHeaderPageOpts } from 'components/ui/breadcrumb-toolbar/HideHeaderToolbarItem';

export default function NewMapBreadcrumbToolbar({
  formValues,
  isNotMapPersisted,
  onAjax,
  source,
  updateDynamicFilter,
  sourceFields,
  toggleMobile = _.noop,
  reloadMap = _.noop,
  context,
}) {
  return (
    <BreadcrumbToolbar loading={onAjax}>
      {context.cockpitEnabled && <HideHeaderToolbarItem location={HideHeaderPageOpts.BREADCRUMBS} />}

      <ExportToolbarItem
        disabled={isNotMapPersisted}
        currentObject={formValues}
        caption={formValues.caption}
        path={formValues.path}
      />

      <ReloadToolbarItem
        disabled={isNotMapPersisted}
        onClick={async () => {
          await reloadMap();
        }}
      />

      <PublisherToolbarItem path={formValues.path} caption={formValues.caption} disabled={isNotMapPersisted} />

      <NotesToolbarItem disabled={isNotMapPersisted} path={formValues.path} />

      <FavoriteToolbarItem disabled={isNotMapPersisted} path={formValues.path} />

      <MobileToolbarItem
        disabled={isNotMapPersisted}
        inactive={!formValues.mobile}
        path={formValues.path}
        onClick={toggleMobile}
      />

      <InformationToolbarItem
        disabled={isNotMapPersisted}
        title={context.msg.t('object.information.map')}
        information={formValues.information}
        dataSource={formValues.datasourceName}
        dataSourceCaption={formValues.dataSourceCaption}
        path={formValues.path}
      />

      <DynamicTimeFilterToolbarItem
        dataSourceCaption={source}
        path={formValues.path}
        dimensions={sourceFields.filter((f) => f.type === 'TimeDimension')}
        onChange={updateDynamicFilter}
        timeFilter={formValues.query.timeFilter}
      />
    </BreadcrumbToolbar>
  );
}
