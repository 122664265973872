import React, { useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { localStore } from 'components/Storage';
import AdaCensus from 'components/bng/ui/Census/AdaCensus';
import UiBlocker from 'components/bng/ui/UiBlocker';

export const NPS_CENSUS_KEY = 'NpsCensusAnswerLater:NPS_CENSUS_STORAGE';

const CensusTypes = {
  NPS: 'NPS',
};

const NPSCensus = ({ closeModal }) => {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);

  const answerLaterButton = async () => {
    localStore.put(NPS_CENSUS_KEY, true);
    closeModal();
  };

  const saveCensusAnswer = async (values) => {
    setLoading(true);
    try {
      const censusResponse = {
        result: values,
        projectId: context.project.id,
        projectName: context.project.name,
        mobile: application.page.isMobile(),
        type: CensusTypes.NPS,
      };
      await Api.News.saveCensusResponse(censusResponse);
      localStore.put(NPS_CENSUS_KEY, true);

      // Fix: https://github.com/sol7/bi-machine/issues/8480
      context.nps.lastAvaliationDate = Date.now();

      UiMsg.ok(context.msg.t('census.thanks.for.feedback'));
    } catch (e) {
      if (e.response && e.response.status === 409) {
        UiMsg.error(context.msg.t('error.already.voted'), e);
      } else {
        console.error(e);
        UiMsg.error(context.msg.t('census.error.sending.feedback'), e);
      }
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  return (
    <UiBlocker block={loading} renderChildrenWhileBlocking={false} >
      <AdaCensus
        onSubmit={saveCensusAnswer}
        answerLaterButton={answerLaterButton}
        questionTitle={context.msg.t('census.nps.title')}
      />
    </UiBlocker>
  );
};

export default NPSCensus;
