import style from './AppSelectStep.module.css';

import React, { useState } from 'react';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import AppInfoLogo from 'components/bng/project/updateApps/AppInfoLogo';
import useBimContext from 'components/hooks/useBimContext';

function AppInfo({ appInstall, className = '', selected, onClick, keepData }) {
  const context = useBimContext();
  const path = appInstall?.install?.path;
  const updatedAt = appInstall?.install?.updatedAt;
  const caption = appInstall?.app?.caption || '';

  return (
    <div
      className={`AppInfo ${className} ${onClick ? 'Selectable' : ''} ${selected ? 'Selected' : ''}`}
      onClick={(e) => onClick(appInstall, e)}
    >
      {!keepData && <BngCheckbox field={{ value: selected, onChange: _.noop }} />}
      <div className={style.logoContainer}>
        <AppInfoLogo appInstall={appInstall} />
      </div>
      <div className="mt-2" title={path ? `${context.msg.t('folder')}: ${path}` : undefined}>
        <b>
          <small>{caption}</small>
        </b>
      </div>
      {updatedAt && (
        <div>
          <small className={style.appInfoTime}>
            {context.msg.t('last_update') + ': ' + updatedAt.format('DD/MM/YYYY HH:mm')}
          </small>
        </div>
      )}
    </div>
  );
}

function AppSelectStep({ availableInstalls, appsToUpdate, onSelect, keepData }) {
  const context = useBimContext();
  const [selectAll, setSelectAll] = useState(false);
  const installIsSelected = (i) => {
    return appsToUpdate.some((a) => a.install.id === i.install.id);
  };
  return (
    <div>
      <div className="flex-center-items">
        {!keepData && (
          <BngCheckbox
            className="ml-auto"
            label={context.msg.t('select_all')}
            field={{
              value: selectAll,
              onChange: () => {
                const newState = !selectAll;
                const val = [];
                for (const availableInstall of availableInstalls) {
                  const selected = installIsSelected(availableInstall);
                  if (newState && !selected) {
                    val.push(availableInstall);
                  } else if (!newState && selected) {
                    val.push(availableInstall);
                  }
                }
                setSelectAll(newState);
                onSelect(val);
              },
            }}
          />
        )}
      </div>
      <div className={`${style.appsToSelectContainer} d-flex flex-wrap gap-3 mt-4`}>
        {availableInstalls.map((i) => {
          const isSelected = installIsSelected(i);
          return (
            <AppInfo
              context={context}
              key={i.install.id}
              appInstall={i}
              selected={isSelected}
              onClick={onSelect}
              keepData={keepData}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AppSelectStep;
