import './DashboardItemMenuTitle.css';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from "components/ui/common/Icon";

class DashboardItemMenuTitle extends React.PureComponent {

    static propTypes = {
        title: PropTypes.string,
        icon: PropTypes.string,
        more: PropTypes.bool,
        shorted: PropTypes.string
    };

    static defaultProps = {
        title: "",
        icon: "",
        more: false,
        shorted: null
    };

    render() {
        const {icon, title, more, shorted, ...props} = this.props;
        return (
            <div {...props} className="DashboardItemMenuTitle">
                <Icon icon={icon}/>
                <span className="sub-container-dash-item-newmenu-title">{title}</span>
                {more &&
                <span className="container-dash-item-more">
                    <Icon icon="keyboard_arrow_right"/>
                </span>
                }
                {shorted &&
                <div className="lblShorted">{shorted}</div>
                }
            </div>
        )

    }
}

export default DashboardItemMenuTitle;