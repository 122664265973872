import * as ReactDOM from "react-dom";
import './BetaLabelContainer.css';

import React, {Component} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import BetaLabel from "components/BetaLabel";

class BetaLabelContainer extends Component {

    findContainer = () => {
        if (this.__container) {
            return this.__container;
        } else {
            let c = j('#floating-object-container').find('#BetaLabelContainer')[0];
            if (c) return c
        }

        this.__container = j('#floating-object-container')
            .removeClass('hide')
            .append(`<div id="BetaLabelContainer"/>`)
            .find('#BetaLabelContainer')[0];

        return this.__container;
    };


    render() {
        return ReactDOM.createPortal(
            <BetaLabel />,
            this.findContainer()
        );
    }

}

export default ContextEnhancer(BetaLabelContainer);