import Api from "components/Api";
import FSA from "components/ui/redux/structure/FSA";
import ActionContainer from "components/ui/redux/structure/ActionContainer";
import {ajaxToEvent} from "components/ui/redux/structure/Async";

export const APP = new ActionContainer('APP')
        .actionCreator('RELOAD_PAGE', 'reloadPage', (type) => () => FSA(type))
        .actionCreator('REFRESH_CURRENT_PROJECT', 'refreshCurrentProject', (type) => () => ajaxToEvent(type, () => Api.Navbar.getCurrentProject()))
        .actionCreator('RELOAD_OBJECT', 'reloadObject', (type) => () => FSA(type))
    ;

export const USER = new ActionContainer('USER')
        .actionCreator('FIND_FAVORITES', 'findFavorites', (type) => () => ajaxToEvent(type, Api.User.findFavorites))
        .actionCreator('SET_FAVORITE_PROJECT', 'setProjectAsFavorite', (type) => (projectId) => FSA(type, projectId))
        .actionCreator('USER_SAVED', 'userSaved', (type) => (state, payload) => (dispatch) => {
            dispatch(FSA(type, payload));
        })
        .actionCreator('USER_PREFERENCES_SAVED', 'userPreferencesSaved', (type) => () => ajaxToEvent(type, Api.User.getPreferences))
    ;

export const MENU = new ActionContainer('MENU')
    .actionCreator('FIND_ITEMS', 'findItems', (type) => () => ajaxToEvent(type, Api.Menu.findItems))
    .actionCreator('FIND_ROOTS', 'findRoots', (type) => () => ajaxToEvent(type, Api.Menu.findRoots))
    .actionCreator('TOGGLE_EXPAND_MENU', 'toggleExpandMenu', (type) => () => FSA(type))
    .actionCreator('CONTENT_CHANGED', 'contentChanged', (type) => (state) => async (dispatch) => {
        await Promise.allSettled([
            dispatch(FSA(type)),
            state.menu.items ? dispatch(MENU.findItems()) : Promise.resolve(),
            state.menu.roots ? dispatch(MENU.findRoots()) : Promise.resolve(),
        ]).then(()=> dispatch(APP.reloadObject()));
    });

export const NAVBAR = new ActionContainer('NAVBAR')
        .actionCreator('GET_INFO', 'findInfo', (type) => () => ajaxToEvent(type, Api.Navbar.navbarDetails))
        .actionCreator('FIND_PROJECTS', 'findProjects', (type) => () => ajaxToEvent(type, Api.Navbar.projectsMenuDetails))
        .actionCreator('FIND_PUBLICATIONS', 'findPublications', (type) => () => ajaxToEvent(type, Api.Navbar.publicationsMenuDetails))
        .actionCreator('FIND_SCHEDULINGS', 'findSchedulings', (type) => () => ajaxToEvent(type, Api.Navbar.schedulingsMenuDetails))
        .actionCreator('FIND_MONITORS', 'findMonitors', (type) => () => ajaxToEvent(type, Api.Navbar.monitoringMenuDetails))
        .actionCreator('FIND_PRESENTATIONS', 'findPresentations', (type) => () => ajaxToEvent(type, Api.Navbar.presentations))
        .actionCreator('FIND_NOTIFICATIONS', 'findNotifications', (type) => (params = []) => ajaxToEvent(type, () => Api.Navbar.notificationsMenuDetails(params)))
        .actionCreator('MARK_ALL_NOTIFICATIONS_AS_READ', 'markAllNotificationsAsRead', (type) => () => FSA(type))
        .actionCreator('MARK_NOTIFICATION_AS_READ', 'markNotificationAsRead', (type) => (id) => FSA(type, id))
    ;

export const MODALS = new ActionContainer('MODALS')
        .actionCreator('OPEN', 'open', (type) => (dialog, params) => FSA(type, {"type": dialog, "params": params || {}}))
        .actionCreator('CLOSE', 'close', (type) => () => FSA(type))
        .actionCreator('CLOSE_ALL', 'closeAll', (type) => () => FSA(type))
    ;

export const BREADCRUMB = new ActionContainer("BREADCRUMB")
  .actionCreator("UPDATE", 'update', (type) => (newBreadcrumbState) => FSA(type, {newBreadcrumbState}))
