import './BngImageUpload.css';

import React from 'react';
import PropTypes from 'prop-types';

import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';

class BngImageUpload extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    height: PropTypes.any,
    width: PropTypes.any,
    useRelativeLink: PropTypes.bool,
  };

  static defaultProps = {
    width: 131,
    height: 72,
    useRelativeLink: false,
  };

  handleSelectFileRelative = async (e) => {
    const {
      form,
      field,
      context: {
        msg: { t },
      },
    } = this.props;
    const [file] = e.target.files;

    if (file.type.includes('image')) {
      try {
        const reader = new FileReader();
        const result = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result);
          reader.onerror = (e) => reject(e);
          reader.readAsDataURL(file);
        });

        const img = new Image();
        const relativeLink = await new Promise((resolve, reject) => {
          img.onload = () => resolve(img.currentSrc);
          img.onerror = (e) => reject(e);
          img.src = result;
        });

        form.setFieldValue(field.name, {
          relativeLink,
          file,
        });
      } catch (e) {
        console.error('BngImageUpload.handleSelectFile()', { e });
        UiMsg.ajaxError(t('project.whiteLabel.error.on.image.upload'), e);
      }
    } else {
      UiMsg.error(t('only.image.support'));
    }
  };

  handleSelectFile = (e) => {
    const [file] = e.target.files ?? [];
    if (file.type.includes('image')) {
      this.props.form.setFieldValue(this.props.field.name, file);
    } else {
      UiMsg.error(this.props.context.msg.t('only.image.support'));
    }
  };

  clearImageSelect = (e) => {
    this.props.form.setFieldValue(this.props.field.name, null);
    e.preventDefault();
  };

  render() {
    const { field, form, title, width, height, className = '', useRelativeLink, ...props } = this.props;
    const imgSrc = useRelativeLink
        ? field.value?.relativeLink ?? field.value
        : field.value && Api.Upload.previewUrl(field.value);

    return (
      <div className={`BngImageUpload imageupload ${className}`}>
        {!imgSrc ? (
          <label>
            <input
              type="file"
              accept="image/*"
              className="image-upload-hidden"
              onChange={useRelativeLink ? this.handleSelectFileRelative : this.handleSelectFile}
              {...props}
            />
            <div className="lbl-image-upload" style={{ width, height }} title={title}>
              <Icon icon="add" />
            </div>
          </label>
        ) : (
          <React.Fragment>
            <div className="button-cancel-image" onClick={this.clearImageSelect}>
              <Icon icon="close" />
            </div>
            <label className="label-container-image">
              <img alt="user" style={{ width, height }} src={imgSrc} align="middle" />
            </label>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ContextEnhancer(BngImageUpload);
