import React from 'react';

import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';

export default function GiveFeedbackLabel({ onClick = _.noop, className = '' }) {
  const { msg } = useBimContext();
  return (
    <a className={className} onClick={onClick}>
      {msg.t('give.feedback')}
      <Icon icon={'arrow_right_alt'} />
    </a>
  );
}
