import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';
import BngInput from 'components/bng/form/BngInput';

export const calculateUsagePercent = (currentUsage, capacity, additionalCapacity = 0, customizable = false) => {
  const totalCapacity = parseInt(capacity) + (customizable ? additionalCapacity : 0);
  if (totalCapacity > 0) {
    return Math.round((currentUsage * 100) / totalCapacity);
  } else {
    return 0;
  }
};

export const ConsumptionItem = ContextEnhancer(
  class ConsumptionItemInternal extends React.Component {
    componentDidMount() {
      this.applyEasyPieChart();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
      return (
        this.props.field.value !== nextProps.field.value ||
        this.props.consumption !== nextProps.consumption ||
        this.props.selectPlan !== nextProps.selectPlan ||
        this.props.context !== nextProps.context ||
        this.state !== nextState ||
        this.context !== this.context
      );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      this.applyEasyPieChart();
    }

    applyEasyPieChart = () => {
      if (this.isChart) {
        try {
          j(`.easy-pie-chart-${this.props.index}.EasyPieChart-initialized canvas`).remove();
          j(`.easy-pie-chart-${this.props.index}.percentage`).each(function () {
            const size = parseInt(j(this).data('size')) || 64;
            new EasyPieChart(this, {
              barColor: '#269C59',
              trackColor: '#E2E2E2',
              scaleColor: false,
              lineCap: 'round',
              lineWidth: parseInt(size / 9),
              animate: /msie\s*(8|7|6)/.test(navigator.userAgent.toLowerCase()) ? false : 1000,
              size: size,
            });
            j(this).addClass('EasyPieChart-initialized');
          });
        } catch (e) {
          console.error(e);
        }
      }
    };

    isChart = this.props.consumption.renderType === 'CHART';

    render() {
      const { consumption, index, field, form } = this.props;

      const additionalCapacity = field.value;

      const consumptionData =
        this.props.selectPlan?.resources.find((resource) => resource.resource === consumption.resource) || consumption;
      consumptionData.customizable = consumptionData.customizableInPlan
        ? consumption.customizableInPlan
        : consumptionData.customizable;
      consumptionData.percent = consumptionData.calculatedPercent
        ? consumption.calculatedPercent
        : calculateUsagePercent(
            consumption.currentUsage,
            consumptionData.capacity,
            additionalCapacity,
            consumptionData.customizable
          );

      return (
        <div className="consumption-item">
          <div className="consumption-illustration">
            {this.isChart ? (
              <div className="infobox-progress" title={consumption.detailHtml}>
                <div
                  className={`easy-pie-chart-${index} percentage easyPieChart easy-pie-chart force-inline-block`}
                  data-percent={consumptionData.percent}
                  data-size="64"
                >
                  <span className="percent">{consumptionData.percent}%</span>
                </div>
              </div>
            ) : (
              <div className="infobox-icon" title={consumption.detailHtml}>
                <div className="infobox-circle">
                  <i className={`${consumption.icon} infobox-iconcircle`}></i>
                </div>
              </div>
            )}
          </div>
          <div className="consumption-description">
            <span className="consumption-quantity">
              {consumption.formattedUsage} {this.isChart ? this.props.context.msg.t(consumption.label) : ''}
            </span>
            <span className="consumption-capacity">
              {this.isChart
                ? this.props.context.msg.t('plan.capacity') + ': ' + consumptionData.capacity
                : this.props.context.msg.t(consumption.label)}
            </span>
            <span className="consumption-capacity">
              {!consumptionData.customizable
                ? this.props.context.msg.t('plan.dont.support.this.additional')
                : additionalCapacity === 0
                ? this.props.context.msg.t('additional.not.hired')
                : '+ ' + this.props.context.msg.t('hired.additional') + ': ' + additionalCapacity}
            </span>
          </div>
          {consumptionData.customizable && (
            <div className="consumption-additional">
              <BngInput
                id={`account.accountMetrics.${index}.additionalCapacity`}
                name={`account.accountMetrics.${index}.additionalCapacity`}
                field={field}
                form={form}
                type="number"
                min={0}
                onInput={(e) => {
                  if (e.target.value < 0) {
                    e.target.setCustomValidity(this.props.context.msg.t('invalid.negative.value'));
                  } else if (e.target.value === '') {
                    e.target.setCustomValidity(this.props.context.msg.t('invalid.type.number'));
                  } else if (e.target.value % 1 !== 0) {
                    e.target.setCustomValidity(
                      this.props.context.msg.t('invalid.float.value', [
                        Math.floor(e.target.value),
                        Math.ceil(e.target.value),
                      ])
                    );
                  } else {
                    e.target.setCustomValidity('');
                  }
                }}
              />

              <span>{this.props.context.msg.t('additional')}</span>
            </div>
          )}
        </div>
      );
    }
  }
);

class ConsumptionTab extends Component {
  static propTypes = {
    accountMetrics: PropTypes.object,
    selectPlan: PropTypes.object,
  };

  static defaultProps = {};

  render() {
    return (
      <div className="consumption-board">
        <FieldArray
          name="account.accountMetrics"
          render={() =>
            this.props.accountMetrics.all.map((c, index) => (
              <Field
                key={index}
                id={`account.accountMetrics.${index}.additionalCapacity`}
                name={`account.accountMetrics.${index}.additionalCapacity`}
                render={({ field, form }) => (
                  <ConsumptionItem
                    consumption={c}
                    index={index}
                    field={field}
                    form={form}
                    selectPlan={this.props.selectPlan}
                  />
                )}
              />
            ))
          }
        />
      </div>
    );
  }
}

export default ConsumptionTab;
