import styles from './ConnectionsStep.module.css';
import stepsCommonsStyles from './StepsCommonStyles.module.css';

import React, { useMemo, useRef, useState } from 'react';

import { ContentContainer } from 'components/bng/ui/ContentContainer';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import Api from 'components/Api';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useBimContext from 'components/hooks/useBimContext';
import OpConfirmation from 'components/ui/OpConfirmation';
import BngSearch from 'components/bng/ui/BngSearch';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';
import Utils from 'components/Utils';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import DataOriginsDialog from 'components/ui/in-memory/DataOriginsDialog';
import { countConnections, fetchDatabases } from 'components/ui/in-memory/ConnectionsDialog';
import UiMsg from 'components/ui/UiMsg';
import DatabaseConnectionDialog from 'components/ui/in-memory/DatabaseConnectionDialog';
import IntegrationConnectionDialog from 'components/ui/in-memory/IntegrationConnectionDialog';
import UiBlocker from 'components/bng/ui/UiBlocker';

export default function ConnectionStep({ projectId = 0, connections = [], onChange = _.noop }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const context = useBimContext();

  const $endpointInfoCache = useRef({});
  const [search, setSearch] = useState('');
  const [databases, setDatabases] = useState([]);
  const [loading, setLoading] = useState(false);

  const globalConnections = _.isEmpty(context.project);

  const fetchEndpointInfo = async (name = '') => {
    let integration;
    if ($endpointInfoCache.current[name]) {
      integration = $endpointInfoCache.current[name];
    } else {
      integration = await Api.BimIntegration.findInfoEndpoint(name);
      $endpointInfoCache.current[name] = integration;
    }
    return integration;
  };

  const fetchConnections = async () => {
    setLoading(true);

    try {
      if (_.isEmpty(databases)) {
        const dbs = await fetchDatabases();
        setDatabases(dbs);
      }

      const projectConnections = await Api.Connection.findAll({ projectId });

      const newConnections = await Promise.all(
        projectConnections.map(async (connection) => {
          delete connection.id;
          connection._id = Utils.randomId();

          if (!connection.type) {
            try {
              const name = connection.props.name;
              if (name) {
                const integration = await fetchEndpointInfo(name);
                connection.database = integration.name;
              }
            } catch (e) {
              console.error(`Error fetching BimIntegration data for connection: ${connection._id}`, e);
            }
          }
          return connection;
        })
      );

      await onChange(newConnections);
    } finally {
      setLoading(false);
    }
  };

  useAsyncEffect({
    onMount: async () => {
      if (!_.isEmpty(connections)) {
        return;
      }

      try {
        await fetchConnections();
      } catch (e) {
        console.error('Error fetching connections', e);
        UiMsg.ajaxError(null, e);
      }
    },
  });

  const updateConnection = async (connection) => {
    connection = {
      ...connection,
      _id: connection._id ?? Utils.randomId(),
      name: connection.name,
      type: {
        name: connection?.type,
        jdbcUrl: connection?.url,
      },
    };

    if (connection?.props?.name) {
      const integration = await fetchEndpointInfo(connection.props.name);
      connection.database = integration.name;
    }

    const newConnections = connections.slice();
    if (!connection._id) {
      newConnections.push(connection);
    } else {
      const idx = newConnections.findIndex((c) => c._id === connection._id);
      if (idx === -1) {
        newConnections.push(connection);
      } else {
        newConnections.splice(idx, 1, connection);
      }
    }

    await onChange(newConnections);
  };

  const openDataOrigins = (row) => {
    dispatch(
      MODALS.open(DataOriginsDialog, {
        projectId: context.project.id,
        isConnections: true,
        connectionsProps: {
          connectionId: row?._id,
          onChange: updateConnection,
          databaseList: databases,
          globalConnection: globalConnections,
          connectionData: row,
          countConnection: countConnections(connections ?? []),
        },
      })
    );
  };

  const openDialogConnections = (row) => {
    if (_.isEmpty(row?.props)) {
      dispatch(
        MODALS.open(DatabaseConnectionDialog, {
          connectionId: row?.id,
          onChange: updateConnection,
          databaseList: databases,
          globalConnection: globalConnections,
          forReplication: true,
          connectionData: row,
        })
      );
    } else {
      const { props } = row;
      const initialValues = { name_connection: row.name, ...props };
      dispatch(
        MODALS.open(IntegrationConnectionDialog, {
          name: props.name,
          initialValues: initialValues,
          connectionId: row.id,
          onChange: updateConnection,
        })
      );
    }
  };

  const removeItem = (row) => {
    OpConfirmation({
      title: t('attention'),
      message: t('delete.confirmation.message'),
      onConfirm: () => deleteConnection(row),
    });
  };

  const deleteConnection = async (row) => {
    const temp = connections.filter((c) => c._id !== row._id);
    onChange(temp);
  };

  const colDefs = useMemo(() => {
    return [
      {
        label: t('name'),
        render: (row) => {
          return <div className="flex-center-items">{row.name}</div>;
        },
        size: '20%',
      },
      {
        label: t('url'),
        render: (row) => {
          return <div className="flex-center-items">{row.url}</div>;
        },
        size: '30%',
      },
      {
        label: t('DataOriginsDialog.connection.type'),
        render: (row) => {
          return <div className="flex-center-items">{row.database ?? row.type?.name}</div>;
        },
        size: '15%',
      },
      {
        label: t('action'),
        render: (row) => {
          return (
            <BngDropdown
              options={[
                {
                  icon: 'edit',
                  label: t('edit'),
                  onClick: () => openDialogConnections(row),
                },
                {
                  icon: 'remove',
                  label: t('remove'),
                  onClick: () => removeItem(row),
                },
              ]}
              overDialog
            />
          );
        },
        size: '8%',
      },
    ];
  }, [connections]);

  let filteredConnections = connections ?? [];

  if (search) {
    filteredConnections = filteredConnections.filter((item) => {
      const name = item.name.toUpperCase();
      const upperSearch = search.toUpperCase();
      const database = (item.database ?? item.type?.name ?? '').toUpperCase();
      return name.includes(upperSearch) || database.includes(upperSearch);
    });
  }

  return (
    <ContentContainer className={`ConnectionStep ${stepsCommonsStyles.ContentContainerStyle}`}>
      <div className={`Filter flex-center-items ${styles.FilterWrapper}`}>
        <BngSearch alwaysOpen={false} className="ConnectionStepSearch" onChange={(val) => setSearch(val ?? '')} />
        <div className={`new-connection-btn ${styles.NewConnectionButton}`} onClick={() => openDataOrigins()}>
          <Icon icon="add_circle" />
          <div style={{ padding: '0 5px' }}>{t('new.connection')}</div>
        </div>
      </div>
      <UiBlocker className={styles.tableContainer} block={loading}>
        {!_.isEmpty(filteredConnections) && (
          <BngTable
            className={'table-striped'}
            rowKeyBuilder={(row) => row._id}
            rows={filteredConnections}
            cols={colDefs}
            stickyHeader
          />
        )}
      </UiBlocker>
    </ContentContainer>
  );
}
